// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { MAMode } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iAC extends iBuildInIndicator {
    public ao: ExpandDoubleVector = new ExpandDoubleVector();
    public ma: ExpandDoubleVector = new ExpandDoubleVector();

    constructor () {
        super(1);
    }

    override get Name (): string { return 'iAC'; }

    override get Key (): string { return this.DefaultKey; }

    public override NextBar (callBound): void {
        super.NextBar(callBound);
        this.ma.Add(0.0);
        this.ao.Add(0.0);
    }

    public override Refresh (count, newThread): void {
        this.ma.Dispose();
        this.ao.Dispose();
        this.ma = new ExpandDoubleVector();
        this.ao = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }

    public override OnQuote (): void {
        const Parent = this.Parent;
        if (!Parent) {
            return;
        }
        const close = this.GetPrice(PriceType.Close, 0);
        this.ao[this.ao.Length - 1] = close;
        if (this.Count >= 39) {
            this.ma[this.ma.Length - 1] = IndicatorFunctions.AOFunction(this.ao);
            this.SetValue(0, 0, this.ma[this.ma.Length - 1] - IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.ma, 5, 1));
        }
    }
}
