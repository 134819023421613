// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// TODO. Multi select ItemALL refactoring.

import { TerceraInstrumentLookupDropDownForm } from './TerceraInstrumentLookupDropDownForm';
import { KeyCode } from '../../../Commons/KeyEventProcessor';
import { Resources } from '../../../Commons/properties/Resources';
import { LookupDropDownShowParams } from '../../UtilsClasses/LookupDropDownShowParams';
import { InstrumentLookupAutoCompleteQuickTableCell } from '../QuickTable/InstrumentLookupAutoCompleteQuickTableCell';
import { InstrumentLookupAutoCompleteQuickTableRow } from '../QuickTable/InstrumentLookupAutoCompleteQuickTableRow';
import { QuickTableColumn } from '../QuickTable/QuickTableColumn';
import { QuickTableRactive } from '../QuickTable/QuickTableRactive';
import { QuickTableUtils } from '../QuickTable/QuickTableUtils';
import { QuickTree } from '../QuickTree/QuickTree';
import { InstrumentLookupManager } from './InstrumentLookupManager';
import { SEPARATOR } from '../../../Utils/Enums/Constants';
import { InstrumentTypes } from '../../../Utils/Instruments/InstrumentTypes';
import { MathUtils } from '../../../Utils/MathUtils';
import { DataCache } from '../../../Commons/DataCache';
import { TerceraSymbolLookupBaseDataProvider } from '../../../Commons/NoNFixedListCore';
import { SessionSettings } from '../../../Commons/SessionSettings';
import { TerceraLookup, TerceraLookupEvents } from './TerceraLookup';
import { InstrumentLookupComparer } from '../../../Utils/Instruments/InstrumentLookupComparer';
import { type Instrument } from '../../../Commons/cache/Instrument.js';
import { type QuickTable } from '../QuickTable/QuickTable.js';
import { type ParamType } from '../../../Commons/cache/AllowedReportConstants';
import { InstrumentLookupProperties, InstrumentLookupType } from './InstrumentLookupManagerProps';

export class TerceraInstrumentLookup extends TerceraLookup {
    public LastFoundListCount: number;
    public CanMoveToNextIfHaveError: boolean;
    public ItemAll: _InstrumentItemAll;
    public dataProvider: TerceraSymbolLookupBaseDataProvider;
    public multiSelectDropDownItems: null;
    public isMultiSelectMode: any;
    public isShowSelectAll: any;
    public fakeQuickTree: QuickTree;
    public paramType: ParamType;

    constructor () {
        super();

        this.LastFoundListCount = 0;
        this.CanMoveToNextIfHaveError = true;
        this.ItemAll = new _InstrumentItemAll();
        this.dataProvider = new TerceraSymbolLookupBaseDataProvider();
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.repopulate();
    }

    public override getType (): string { return 'TerceraInstrumentLookup'; }

    public override setFocus (): void {
        super.setFocus();

        void this.set({ visibleBTN: true }); // #115434
    }

    public override onLostFocus (): void {
        super.onLostFocus();

        const selI = this.get('selectedItem');
        if (!isNullOrUndefined(selI)) {
            this.setLookUpText(selI.DisplayName());
        } // #113026
    }

    public getInstruments (): Record<string, Instrument> {
        const isOptionMasterMode: boolean = this.get('isOptionMasterMode');
        const instruments = DataCache.getInstruments(isOptionMasterMode);
        return instruments;
    }

    public getDefaultInstrument (): Instrument {
        let defaultInstrument = SessionSettings.getDefaultInstrument();
        const isOptionMasterMode: boolean = this.get('isOptionMasterMode');
        if (isOptionMasterMode && defaultInstrument.InstrType !== InstrumentTypes.OPTIONS) {
            defaultInstrument = DataCache.OptionsCache.getFirstOptionInstrument();
        }
        return defaultInstrument;
    }

    public override createAutoCompleteTable (): void {
        const autoCompleteTablesContainer = TerceraLookup.getAutoCompleteTablesContainer();
        const qTable = new QuickTableRactive();
        void qTable.set({
            additionalClass: 'quickTableOuter-position-absolute'
        });
        autoCompleteTablesContainer.addControl(qTable);

        qTable.quickTable.rowHeight = 35;

        void qTable.set({
            width: this.get('width') - 1,
            height: qTable.quickTable.rowHeight * this.AUTOCOMPLETE_MAX_ROWS_COUNT,
            visible: false,
            autocompleteTable: true
        });
        qTable.quickTable.columns.push(new QuickTableColumn());
        qTable.quickTable.UpdateSortedColumns();
        qTable.setShowColumnHeaders(false);
        qTable.enableRowHover(true);
        qTable.observe('visible', this.onQTVisibilityChanged.bind(this));

        this.autoCompliteQuickTable = qTable;
    }

    public selectItem (instrumentName: string): void {
        const ins = DataCache.getInstrumentByName(instrumentName);
        if (!isNullOrUndefined(ins)) {
            void this.set('selectedItem', ins);
        }
    }

    public repopulate (): void {
        const instruments = this.getInstruments();
        this.multiSelectDropDownItems = null;

        if (this.isMultiSelect) {
            const multiSelectItems = {};
            multiSelectItems[this.ItemAll.name] = this.ItemAll;
            for (const key in instruments) {
                multiSelectItems[key] = instruments[key];
            }

            this.setItems(multiSelectItems);
            void this.set('selectedItem', this.ItemAll);

            const multiSelectDropDownItems = this.items.slice();
            // Removing ItemAll from multiSelectDropDownItems;
            const itemAllIdx = multiSelectDropDownItems.indexOf(this.ItemAll);
            if (itemAllIdx !== -1) {
                multiSelectDropDownItems.splice(itemAllIdx, 1);
            }

            this.multiSelectDropDownItems = multiSelectDropDownItems;
        } else {
            this.setItems(instruments);
            // Default instrument.
            const defIns = this.getDefaultInstrument();
            const lastItem = this.get('selectedItem');
            if (isNullOrUndefined(lastItem) && !isNullOrUndefined(defIns)) {
                void this.set('selectedItem', defIns);
            }
        }
    }

    public override setItems (newItems): void {
        if (isNullOrUndefined(newItems)) {
            return;
        }

        const keys = Object.keys(newItems);
        const items = newItems;
        const len = keys.length;
        this.items = [];
        for (let j = 0; j < len; j++) {
            const curItem = items[keys[j]];
            if (curItem.isHide || curItem.NeedToHide()) {
                continue;
            }
            this.items.push(curItem);
        }
    }

    public override getButtonTooltip (): string {
        return Resources.getResource('chart.ChartPanel.symbolLookup.ToolTip');
    }

    public override showForm (): void {
        super.showForm();
        const params = new LookupDropDownShowParams();
        params.x = this.getX();
        params.y = this.getY();
        if (this.isMultiSelect) {
            params.items = this.multiSelectDropDownItems;
        }

        if (!isValidArray(params.items)) {
            // SET ITEMS HERE
            this.setItems(this.getInstruments());// GetAllInstrumentsExceptExpired());     // #113208 -> #113215 -> #113145
            params.items = this.items;
        }
        params.callBack = this.dropDownFormCallBack.bind(this);
        params.closeCallback = this.closeForm.bind(this);
        params.isMultiSelect = this.isMultiSelect;
        params.isMultiSelectMode = this.isMultiSelectMode;
        params.caller = this.get('parentContainerControl');
        if (this.selectedItem?.multiSelected) {
            params.selectedItem = this.selectedItem.arraySelected;
        } else if (this.selectedItem === this.ItemAll) {
            params.selectedItem = params.items;
        } else {
            params.selectedItem = this.selectedItem;
        }

        params.dataProvider = this.dataProvider;

        params.isShowSelectAll = this.isShowSelectAll;

        params.isOptionMasterMode = this.get('isOptionMasterMode');

        TerceraInstrumentLookupDropDownForm.ShowForm(params);
    }

    public override fillAutoCompliteTableByItems (): void {
        const qTable = this.autoCompliteQuickTable;

        let findStr = '';
        if (!isNullOrUndefined(this.currentNameFilter)) {
            findStr = this.currentNameFilter.toLowerCase();
        }
        const isOptionMasterMode: boolean = this.get('isOptionMasterMode');
        if (DataCache.NonFixedList) {
            if (isOptionMasterMode) {
                void this.dataProvider.getOptionsList(findStr, null)
                    .then(function (IInstrumentsList) {
                        if (this.currentNameFilter.toLowerCase() === findStr) {
                            const sortedInstrumentList = InstrumentLookupComparer.sortOptionList(IInstrumentsList, this.currentNameFilter);
                            this.listResult(sortedInstrumentList);
                        }
                    }.bind(this));
            } else {
                void this.dataProvider.getInstrumentsList(findStr, null, null, true, !this.get('mayContainTrees'))
                    .then(function (IInstrumentsList) {
                        if (this.currentNameFilter.toLowerCase() === findStr) {
                            const sortedInstrumentList = InstrumentLookupComparer.sortInstrumentList(IInstrumentsList, this.currentNameFilter);
                            this.listResult(sortedInstrumentList);
                        }
                    }.bind(this));
            }
            return;
        }

        let maxW = 0;

        const items = isOptionMasterMode ? InstrumentLookupComparer.sortOptionList(this.items, this.currentNameFilter) : InstrumentLookupComparer.sortInstrumentList(this.items, findStr);
        const len = items.length;
        const qt = qTable.quickTable;
        for (let j = 0; j < len; j++) {
            const curItem = items[j];

            if (this.currentNameFilter !== null) {
                let curItemFindStr = curItem.DisplayName();
                const descr = curItem.DescriptionValue();
                curItemFindStr = (curItemFindStr + (descr ? ' (' + descr + ')' : '')).toLowerCase();
                if (!curItemFindStr.toLowerCase().includes(findStr)) {
                    continue;
                }
            }

            let width = this.AddToTree(curItem, qt, true); // #111628
            if (width === 0) {
                width = this.MakeAndAddRowByItem({ curItem, id: j, qt });
            }

            if (width > maxW) {
                maxW = width;
            }
        }

        qTable.set({ width: 400 });

        this.updateQuickTableHeight();
    }

    public MakeAndAddRowByItem (props): number {
        let width = 0;
        const name = props.name;
        const descr = props.descr;
        const exchng = props.exchange;
        const drawFlag = MathUtils.IsNullOrUndefined(props.drawFlag) ? true : props.drawFlag;
        const curItem = props.curItem; const id = props.id;
        const qt = props.qt;
        const DoCollapse = props.DoCollapse;
        const indexToInsert = props.indexToInsert;
        const visible = MathUtils.IsNullOrUndefined(props.visible) ? true : props.visible;

        const r = new InstrumentLookupAutoCompleteQuickTableRow(curItem, id, qt, this.get('mayContainTrees'));
        r.id = id;
        r.visible = visible;
        r.DoCollapse = DoCollapse;
        r.terceraInstrumentLookup = this;

        const cell = new InstrumentLookupAutoCompleteQuickTableCell(r);
        cell.value = curItem;
        const d_name = MathUtils.IsNullOrUndefined(name) ? curItem.DisplayName() : name;
        cell.formattedValue = d_name;

        const context = qt.context;

        const IsFuckingHieroglyph = Resources.isHieroglyph();

        if (!IsFuckingHieroglyph) {
            cell.InstrumentDrawingFont = cell.InstrumentFontBold;
        }

        context.font = cell.InstrumentDrawingFont;
        cell.InstrumentNameText = d_name;
        cell.InstrumentNameTextWidth = Math.floor(QuickTableUtils.GetWidth(context, cell.InstrumentNameText));

        cell.InstrumentTradingExchangeText = exchng || curItem.TradingExchange;
        context.font = cell.InstrumentFont;
        cell.InstrumentTradingExchangeTextWidth = Math.floor(QuickTableUtils.GetWidth(context, cell.InstrumentTradingExchangeText));

        cell.InstrumentDescriptionText = MathUtils.IsNullOrUndefined(descr) ? (curItem.DescriptionValue() || '') : descr;
        if (cell.InstrumentDescriptionText) {
            context.font = cell.InstrumentDescriptionFont;
            cell.InstrumentDescriptionTextWidth = Math.floor(QuickTableUtils.GetWidth(context, cell.InstrumentDescriptionText));
        }

        cell.FlagImg = drawFlag ? DataCache.CountryCache.GetFlagImage(curItem.CountryId) : null;
        cell.InstrumentTypeImg = InstrumentLookupManager.getInstrumentTypeImage(curItem.InstrType, curItem.CFD);

        const max_name_descr_width = 300;
        const max_exchange_width = 150;

        this.defineTextFilterPos(context, cell);

        const max_textWidth = cell.InstrumentNameTextWidth;

        let final_name_textWidth = max_textWidth;
        let final_exchange_textWidth = cell.InstrumentTradingExchangeTextWidth;
        const toRemoveLetersCount = 5;
        // const points_length = 8.3; // померял

        if (max_textWidth > max_name_descr_width) {
            let available_size = 0;
            if (final_exchange_textWidth < max_exchange_width) {
                available_size = max_exchange_width - final_exchange_textWidth;
            }

            let final_available = max_name_descr_width - available_size;

            if (max_textWidth > final_available) {
                // кофициенты размера букв
                const n_c = Math.round(cell.InstrumentNameTextWidth / cell.InstrumentNameText.length);
                const d_c = Math.round(cell.InstrumentDescriptionTextWidth / cell.InstrumentDescriptionText.length);

                // количество букв - toRemoveLetersCount для точек
                const n_l_c = Math.floor(final_available / n_c) - toRemoveLetersCount;
                const d_l_c = Math.floor(final_available / d_c) - toRemoveLetersCount;

                const new_name_text = cell.InstrumentNameTextWidth < final_available ? cell.InstrumentNameText : cell.InstrumentNameText.substring(0, n_l_c) + '...';
                const new_descr_text = cell.InstrumentDescriptionTextWidth < final_available ? cell.InstrumentDescriptionText : cell.InstrumentDescriptionText.substring(0, d_l_c) + '...';

                cell.InstrumentNameText = new_name_text;
                context.font = cell.InstrumentDrawingFont;
                cell.InstrumentNameTextWidth = Math.floor(QuickTableUtils.GetWidth(context, cell.InstrumentNameText)); // new_name_width

                cell.InstrumentDescriptionText = new_descr_text;
                context.font = cell.InstrumentDescriptionFont;
                cell.InstrumentDescriptionTextWidth = Math.floor(QuickTableUtils.GetWidth(context, cell.InstrumentDescriptionText));
                final_available = cell.InstrumentNameTextWidth;
            }
            final_name_textWidth = final_available;
        } else if (cell.InstrumentDescriptionTextWidth > final_name_textWidth) {
            let final_available = max_name_descr_width;
            if (cell.InstrumentNameTextWidth < max_name_descr_width && final_exchange_textWidth > max_exchange_width) {
                const avaliblefromtext = max_name_descr_width - cell.InstrumentNameTextWidth;
                const needForExchange = final_exchange_textWidth - max_exchange_width;

                if (avaliblefromtext > needForExchange) {
                    final_available = avaliblefromtext - needForExchange + cell.InstrumentNameTextWidth;
                }
            } else if (final_exchange_textWidth < max_exchange_width) {
                final_available = max_exchange_width - final_exchange_textWidth + max_name_descr_width;
            }

            if (cell.InstrumentDescriptionTextWidth > final_available) {
                const d_c = Math.round(cell.InstrumentDescriptionTextWidth / cell.InstrumentDescriptionText.length);
                const d_l_c = Math.floor(final_available / d_c) - toRemoveLetersCount + 3;

                const new_descr_text = cell.InstrumentDescriptionText.substring(0, d_l_c) + '...';

                cell.InstrumentDescriptionText = new_descr_text;
                context.font = cell.InstrumentDescriptionFont;
                cell.InstrumentDescriptionTextWidth = Math.floor(QuickTableUtils.GetWidth(context, cell.InstrumentDescriptionText)); // new_descr_width;
                final_name_textWidth = final_available;
            }
            final_name_textWidth = cell.InstrumentDescriptionTextWidth;
        }

        if (final_exchange_textWidth > max_exchange_width) {
            const max_exchange_textWidth = final_exchange_textWidth;
            const max_Name_Part_TextWidth = cell.InstrumentNameTextWidth;

            let available_size = 0;
            if (max_Name_Part_TextWidth < max_name_descr_width) {
                available_size = max_name_descr_width - max_Name_Part_TextWidth;
            }

            let final_available = available_size + max_exchange_width;
            if (max_exchange_textWidth > final_available) {
                // кофициенты размера букв
                const e_c = final_exchange_textWidth / cell.InstrumentTradingExchangeText.length;
                // количество букв - toRemoveLetersCount для точек
                const e_l_c = Math.floor(final_available / e_c) - toRemoveLetersCount - 1;

                const new_exchange_text = cell.InstrumentTradingExchangeText.substring(0, e_l_c) + '...';

                cell.InstrumentTradingExchangeText = new_exchange_text;
                context.font = cell.InstrumentFont;
                final_available = cell.InstrumentTradingExchangeTextWidth = Math.floor(QuickTableUtils.GetWidth(context, cell.InstrumentTradingExchangeText));
            }

            if (final_available > final_exchange_textWidth) {
                final_exchange_textWidth = final_exchange_textWidth;
            } else {
                final_exchange_textWidth = final_available;
            }
        }

        width = final_exchange_textWidth + final_name_textWidth + 146;

        if (!isValidString(this.currentNameFilter) || MathUtils.IsNullOrUndefined(indexToInsert) || isValidString(cell.FilterNameText) || isValidString(cell.FilterDescrText)) {
            r.cells.push(cell);
            indexToInsert ? qt.InsertRow(r, indexToInsert) : qt.AddRow(r);
        } else {
            return null;
        } // #113013

        return width;
    }

    public defineTextFilterPos (ctx: CanvasRenderingContext2D, cell): void // texthighlight with text pattern in "small" lookup <-> quick search in lookup text field with dropdown list <-> autoCompliteQuickTable
    {
        if (isNullOrUndefined(cell) || !isValidString(this.currentNameFilter)) {
            return;
        }

        const upN = cell.InstrumentNameText.toUpperCase();
        const upD = cell.InstrumentDescriptionText.toUpperCase();
        const upF = this.currentNameFilter.toUpperCase();

        const indexN = upN.indexOf(upF);
        const indexD = upD.indexOf(upF);
        if (indexN !== -1) {
            const substrN = cell.InstrumentNameText.substring(0, indexN);
            ctx.font = cell.InstrumentDrawingFont;
            cell.FilterNamePos = QuickTableUtils.GetWidth(ctx, substrN);
            cell.FilterNameText = cell.InstrumentNameText.substring(indexN, indexN + this.currentNameFilter.length);
        }
        if (indexD !== -1) {
            const substrD = cell.InstrumentDescriptionText.substring(0, indexD);
            ctx.font = cell.InstrumentDescriptionFont;
            cell.FilterDescrPos = QuickTableUtils.GetWidth(ctx, substrD);
            cell.FilterDescrText = cell.InstrumentDescriptionText.substring(indexD, indexD + this.currentNameFilter.length);
        }
    }

    public listResult (foundList): void // "small" lookup <-> quick search in lookup text field with dropdown list <-> autoCompliteQuickTable
    {
        const qTable = this.autoCompliteQuickTable;
        let maxW = 0;
        const len = foundList.length;
        this.LastFoundListCount = len;
        const qt = qTable.quickTable;
        for (let j = 0; j < len; j++) {
            const curItem = foundList[j];

            let width = this.AddToTree(curItem, qt); // #111628
            if (!isValidNumber(width) || width === 0) {
                width = this.MakeAndAddRowByItem({ curItem, id: j, qt });
            }

            if (width > maxW) {
                maxW = width;
            }
        }

        const pW = this.get('width');
        qTable.set({ width: (maxW > pW ? maxW : pW) });

        this.updateQuickTableHeight();
    }

    public updateQuickTableHeight (): void {
        const qTable = this.autoCompliteQuickTable;
        const qt = qTable.quickTable;
        const rowHeight = qt.rowHeight;
        const rowsCount = qt.visibleRowCount();
        let visibleRowsCount = this.AUTOCOMPLETE_MAX_ROWS_COUNT;

        if (rowsCount < visibleRowsCount) {
            visibleRowsCount = rowsCount;
        }

        if (rowsCount > 0) {
            qTable.set({
                visible: true,
                height: rowHeight * visibleRowsCount + 2
            });
            qt.height = rowHeight * visibleRowsCount + 2;
            qt.updateScrollElementsCount();
        } else {
            qTable.set({
                visible: false
            });
        }
    }

    public override lookupErrorHideShow (isShow: boolean): void {

    }

    public override getTypingLookupValueForSet (textValueId: string, defaultRow): _InstrumentItemAll {
        if (textValueId === 'All') {
            return this.ItemAll;
        }

        if (!isNullOrUndefined(defaultRow?.cells)) {
            const cell = defaultRow.cells[0];
            const ins = cell ? cell.value : null;
            const insName = ins ? ins.DisplayName().toLowerCase() : null;

            if (isValidString(textValueId) && insName === textValueId.toLowerCase()) {
                return ins;
            }
        }

        // return DataCache.getInstrumentByName(textValueId);     // это точно работать не будет - текстовое имя вместо interiorID
    }

    public override async dropDownFormCallBack (result): Promise<void> {
        const len = Array.isArray(result) ? result.length : 0;
        if (len) {
            if (len === 1) {
                await this.setSelectValue(result[0]);
            } else if (len === this.items.length) {
                this.ItemAll.arraySelected = result;
                await this.setSelectValue(this.ItemAll);
            } else if (len < this.items.length) {
                await this.setSelectValue(new _InstrumentItemSelected(result));
            }
        } else {
            await this.setSelectValue(result);
        }
        // TODO mb
        // this.set('labelShow', len > 1 && len < this.items.length);
    }

    public override async setSelectValue (newSelectedItem, suppressEvents?): Promise<void> {
        if (isNullOrUndefined(newSelectedItem)) {
            return;
        }

        if (newSelectedItem.FullName === 'All') {
            await this.setSelectValueComplete(newSelectedItem);
        } else if (typeof newSelectedItem === 'string') {
            void this.dataProvider.getInstrumentByName(newSelectedItem);
        } else if (newSelectedItem.arraySelected) {
            await this.setSelectValueComplete(newSelectedItem);
        } else if (newSelectedItem.GetInteriorID) {
            await this.setSelectValueComplete(newSelectedItem);
        } else {
            const isOptionMasterMode: boolean = this.get('isOptionMasterMode');
            if (isOptionMasterMode) {
                void this.dataProvider.getOption(newSelectedItem);
            } else {
                void this.dataProvider.getInstrument(newSelectedItem);
            }
        }
    }

    public async setSelectValueComplete (newInstrumen, updateItems?): Promise<void> {
        if (isNullOrUndefined(newInstrumen)) return;

        this.needCloseTableAfterClick = true;
        if (this.needChangeTreeCollapseState()) // #111628
        {
            this.updateQuickTableHeight();
            this.needCloseTableAfterClick = false;
            return;
        }

        const newText = newInstrumen && !newInstrumen.IsEmpty ? newInstrumen.DisplayName() : '';
        this.setLookUpText(newText);

        if (this.selectedItem === newInstrumen) {
            return;
        }

        if (!this.CanMoveToNextIfHaveError && !(!!this.currentNameFilter && !!this.LastFoundListCount || !(KeyCode.UP === this.lastKeyCode || KeyCode.DOWN === this.lastKeyCode))) {
            // apply first instrument  in case when, we entered a non-existent instrument
            this.currentNameFilter = null;
            this.LastFoundListCount = 1;
            return;
        }
        const sameInstrument = this.get('selectedItem') === newInstrumen && !isNullOrUndefined(this.selectedItem);
        if (!sameInstrument) {
            const isOptionMasterMode: boolean = this.get('isOptionMasterMode');
            const instrumentProvider = isOptionMasterMode ? await this.dataProvider.getOption(newInstrumen) : await this.dataProvider.getInstrument(newInstrumen); // #113311 -> иногда нет в DataCache страйка для SymbolInfo
            if (!isNullOrUndefined(instrumentProvider)) {
                newInstrumen = instrumentProvider;
            }
            // For template data binding.
            this.selectedItem = newInstrumen;
            void this.set('selectedItem', newInstrumen);
            this.onChangeValue.Raise(newInstrumen);
            this.fire(TerceraLookupEvents.SelectedItemChanged, newInstrumen);
        } else {
            this.selectedItem = newInstrumen;
        }

        if (!isNullOrUndefined(updateItems)) {
            this.updateItemsAndSelectedItemIndex(newInstrumen);
        } else {
            this.updateSelectedItemIndex(newInstrumen);
        }
    }

    public updateItemsAndSelectedItemIndex (newSelectedItem): void {
        this.setItems(this.getInstruments);
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i] === newSelectedItem) {
                this.selectedItemIndex = i;
                break;
            }
        }
    }

    public override dispose (): void {
        this.dataProvider.dispose();
        super.dispose();
    }

    public getQuickTable (): QuickTable {
        const qtR = this.autoCompliteQuickTable;
        return qtR ? qtR.quickTable : null;
    }

    public getFakeQuickTree (): QuickTree {
        if (isNullOrUndefined(this.fakeQuickTree)) {
            this.fakeQuickTree = new QuickTree(document.createElement('canvas').getContext('2d'));
        } // либо так, либо править QuickTree чтоб он(о) мог(ло) жить без DrawingContext

        return this.fakeQuickTree;
    }

    public AddToTree (ins: Instrument, qt, strikeVisible = false, needInsert = false): number // #111628
    {
        if (!this.get('mayContainTrees')) {
            return 0;
        }

        if (isNullOrUndefined(qt) || isNullOrUndefined(ins) || ins.NeedToHide() || !ins.isOptionSymbol) // пока только для опционов
        {
            return 0;
        }

        const fakeQuickTree = this.getFakeQuickTree();
        const isOptionMasterMode: boolean = this.get('isOptionMasterMode');
        const properties = new InstrumentLookupProperties(InstrumentLookupType.Small, [ins], fakeQuickTree, this.currentNameFilter);
        properties.dataProvider = this.dataProvider;
        properties.isOptionMaster = isOptionMasterMode;
        InstrumentLookupManager.fillTree(properties);

        if (isOptionMasterMode) {
            return this.generateOptionsRows(ins, qt, fakeQuickTree);
        } else {
            return this.generateInstrumentsRows(ins, qt, strikeVisible, needInsert, fakeQuickTree);
        }
    }

    private generateOptionsRows (ins: Instrument, qt, fakeQuickTree): number {
        const exchangeNode = fakeQuickTree.nodeCollection[0]; //  first node (most top in "big" dropdown form) - exchange (like NYSE, NASDAQ etc.)

        if (isNullOrUndefined(exchangeNode)) {
            return 0;
        }

        const insTypeNode = exchangeNode.GetFirstChild();
        const underlierNode = insTypeNode.GetFirstChild();

        const isUnderlierFutures = ins.ForwardBaseInstrument.isFuturesSymbol;
        if (isUnderlierFutures) {
            let underlierRow = qt.getItemById(underlierNode.InstrumentNameText);
            if (isNullOrUndefined(underlierRow)) {
                this.MakeAndAddRowByItem({ name: underlierNode.InstrumentNameText, descr: underlierNode.InstrumentDescriptionText, exchange: underlierNode.InstrumentTradingExchangeText || ' ', id: underlierNode.InstrumentNameText, curItem: ins, qt });
                underlierRow = qt.getItemById(underlierNode.InstrumentNameText);
            }
            const indexOffset = qt.rowsArray.indexOf(underlierRow) + 1 + (isValidNumber(underlierRow.ChildNum) ? underlierRow.ChildNum : 0);
            const contractNode = underlierNode.childNodes.find(node => node.tag === ins);
            const contractWidth = this.MakeAndAddRowByItem({ name: contractNode.InstrumentNameText, descr: contractNode.InstrumentDescriptionText, exchange: contractNode.InstrumentTradingExchangeText || ' ', id: contractNode.InstrumentNameText, curItem: ins, qt, visible: false, indexToInsert: indexOffset });
            const contractRow = qt.getItemById(contractNode.InstrumentNameText);
            underlierRow.AddChild(contractRow);
            return contractWidth;
        } else {
            const exchange = ins.ForwardBaseInstrument.TradingExchange ?? underlierNode.InstrumentTradingExchangeText ?? ' ';
            return this.MakeAndAddRowByItem({ name: underlierNode.InstrumentNameText, descr: underlierNode.InstrumentDescriptionText, exchange, id: underlierNode.InstrumentNameText, curItem: ins, qt });
        }
    }

    private generateInstrumentsRows (ins, qt, strikeVisible, needInsert, fakeQuickTree): number {
        const exchangeNode = fakeQuickTree.nodeCollection[0]; //  first node (most top in "big" dropdown form) - exchange (like NYSE, NASDAQ etc.)

        if (isNullOrUndefined(exchangeNode)) {
            return 0;
        }

        const insTypeNode = exchangeNode.GetFirstChild(); //  instrument type node (text - Options, absent in "small" lookup list)
        const isInsTypeNotEmpty = !!(insTypeNode && (insTypeNode.InstrumentNameText || insTypeNode.nodeText));
        let underlierNode = isInsTypeNotEmpty ? insTypeNode.GetFirstChild() : insTypeNode.GetFirstChild().GetFirstChild(); //  fake option ins (highest level ~ most top in "small" lookup list) row
        let fakeOptionNode = underlierNode.GetFirstChild(); //  additional base ins underlier node  (may be absent)
        let seriesDateNode = fakeOptionNode.GetLastChild(); //  series option (contract date level) row
        let strikeNode = seriesDateNode ? seriesDateNode.GetLastChild() : null; //  strike row (lowest level == ins)

        if (isNullOrUndefined(strikeNode)) // => has no underlierNode (~ d)) => all childs need 1 level offset <=> data in underlierNode is fakeOptionNode (~ c)) and data in fakeOptionNode is seriesDateNode (~ b)) also data in seriesDateNode is strikeNode (~ a))
        {
            strikeNode = seriesDateNode; // a)
            seriesDateNode = fakeOptionNode; // b)
            fakeOptionNode = underlierNode; // c)
            underlierNode = null; // d)
        }

        const insID = ins.GetInteriorID(); // strike (lowest option level)
        const updIns = DataCache.getInstrumentByName(insID);

        if (!isNullOrUndefined(updIns)) {
            ins = updIns;
        }

        const parentID = InstrumentLookupAutoCompleteQuickTableRow.GetInsParentID(ins); // contract node id
        const baseOptionInsID = strikeNode ? strikeNode.tag.ForwardBaseInstrument.GetInteriorID() : parentID;
        const baseIns = ins.ForwardBaseInstrument; const baseInsID = baseIns ? baseIns.GetInteriorID() : null; // fake node id

        let w0 = 0; let w1 = 0; let w2 = 0; let w3 = 0; // ширина строк
        let id0 = baseOptionInsID !== parentID ? baseOptionInsID : (underlierNode ? underlierNode.nodeText : null);
        let id1 = baseInsID; let id2 = parentID; let id3 = insID;
        let r0 = qt.getItemById(id0); let r1 = qt.getItemById(id1); let r2 = qt.getItemById(id2); let r3 = qt.getItemById(id3);

        if (isNullOrUndefined(r2)) {
            const splArr = parentID.split(SEPARATOR);
            let new_id2 = baseOptionInsID + SEPARATOR + splArr[2];

            r2 = qt.getItemById(new_id2);
            if (isNullOrUndefined(r2) && !isNullOrUndefined(ins.ForwardBaseInstrument)) {
                new_id2 = ins.ForwardBaseInstrument.InstrumentTradableID + SEPARATOR + ins.Route + SEPARATOR + splArr[2];
            }
            r2 = qt.getItemById(new_id2);
            if (!isNullOrUndefined(r2)) {
                id2 = new_id2;
            }
        }

        let r0Visibility = true;
        if (id0 == id1) {
            r0Visibility = false;
            if (!isNullOrUndefined(underlierNode)) {
                id0 = underlierNode.nodeText;
                r0 = qt.getItemById(id0);
            } else if (!isNullOrUndefined(fakeOptionNode)) {
                id1 = fakeOptionNode.nodeText;
                r1 = qt.getItemById(id1);
            }
        }
        if (!isNullOrUndefined(underlierNode) && isNullOrUndefined(r0) && id0) {
            w0 = this.MakeAndAddRowByItem({ name: underlierNode.InstrumentNameText, descr: underlierNode.InstrumentDescriptionText, exchange: underlierNode.InstrumentTradingExchangeText || ' ', id: id0, curItem: baseIns, qt });
            r0 = qt.getItemById(id0);
        }

        if (!isNullOrUndefined(r0) && baseIns.isFutureOrOption()) {
            r0.visible = r0Visibility;
        }

        if (isNullOrUndefined(r1)) {
            w1 = this.MakeAndAddRowByItem({ name: fakeOptionNode.InstrumentNameText, descr: fakeOptionNode.InstrumentDescriptionText, exchange: fakeOptionNode.InstrumentTradingExchangeText || ' ', id: id1, curItem: baseIns, qt, visible: (!r0?.visible) });
            r1 = qt.getItemById(id1);
        }

        if (isNullOrUndefined(r2)) {
            const splArr = id2.split(SEPARATOR);
            if (splArr.length > 1) {
                splArr[1] = baseIns.TradableRoutes[0];
                const newID2 = splArr.join(SEPARATOR);
                if (qt.getItemById(newID2)) {
                    id2 = newID2;
                }
            }

            w2 = this.MakeAndAddRowByItem({ DoCollapse: seriesDateNode.DoCollapse, name: seriesDateNode.InstrumentNameText, descr: seriesDateNode.InstrumentDescriptionText, exchange: ' ', drawFlag: false, id: id2, curItem: baseIns, qt, visible: false }); // series option (contract date level) row
            r2 = qt.getItemById(id2);
        }

        if (!id3.indexOf('null')) {
            id3 += ' ' + strikeNode.tag.ContractID;
        }

        if (isNullOrUndefined(r3)) {
            const idx = qt && r2 && needInsert ? qt.rowsArray.indexOf(r2) + 1 : null; // index to insert (for additional option request[420] case)
            w3 = this.MakeAndAddRowByItem({ name: strikeNode.InstrumentNameText, descr: strikeNode.InstrumentDescriptionText, exchange: strikeNode.InstrumentTradingExchangeText, id: id3, curItem: ins, qt, visible: strikeVisible, indexToInsert: idx }); // strike row
            r3 = qt.getItemById(id3);
        }

        if (!isNullOrUndefined(r0) && !isNullOrUndefined(r1)) {
            r0.AddChild(r1);
        }

        if (!isNullOrUndefined(r1) && !isNullOrUndefined(r2)) {
            r1.AddChild(r2);
        }

        if (!isNullOrUndefined(r2) && !isNullOrUndefined(r3)) {
            r2.AddChild(r3);
        }

        if (!isNullOrUndefined(r3)) {
            r3.visible = strikeVisible;
        } // строка могла существовать, а видимость отличаться

        return Math.max(w0, w1, w2, w3);
    }

    public needChangeTreeCollapseState (): boolean // #111628
    {
        const visible: boolean = this.autoCompliteQuickTable.get('visible');
        if (!visible) // #113127
        {
            return false;
        }

        const qt = this.autoCompliteQuickTable.quickTable;
        const selectedRowId = qt.selectedRowIds.length ? qt.selectedRowIds[0] : null;
        const selectedRow = selectedRowId ? qt.rows[selectedRowId] : null;
        const needChange: boolean = selectedRow ? selectedRow.HasAnyChild : false;

        if (needChange) {
            selectedRow.ChangeTreeCollapseState();
        }

        return needChange;
    }

    get IsMultiSelect (): boolean { return this.isMultiSelect; }
    set IsMultiSelect (value) {
        this.isMultiSelect = value;
        this.repopulate();
    }
}

class _InstrumentItem {
    public FullName = 'Empty';

    public DisplayName (): string {
        return this.FullName;
    }

    public DescriptionValue (): string {
        return this.FullName;
    }

    public toString (): string {
        return this.FullName;
    }

    public toStringWithDescr (): string {
        return this.FullName;
    }
}

class _InstrumentItemAll extends _InstrumentItem {
    // Hide in instrument lookup dropdown.
    public isHide = true;
    public isLinked = false;
    public name: any;
    public arraySelected: Instrument[];

    constructor () {
        super();

        this.FullName = Resources.getResource('panel.TerceraSymbolLookupDropDownForm.All');
    }
}

export class _InstrumentItemSelected extends _InstrumentItem {
    public isHide = true;
    public isLinked = false;
    public multiSelected = true;
    public arraySelected: Instrument[];

    constructor (instruments: Instrument[]) {
        super();

        let name = '';
        const len = !isNullOrUndefined(instruments) ? instruments.length : 0;
        for (let i = 0; i < len; i++) {
            const ins = instruments[i];
            if (ins.FullName === 'All') continue;

            name += instruments[i].DisplayName() + ',';
        }
        // Removing comma.
        this.FullName = name.slice(0, -1);
        // Hide in instrument lookup dropdown.

        this.arraySelected = instruments;
    }
}

TerceraLookup.extendWith(TerceraInstrumentLookup, {
    data: function () {
        return {
            btnClass: 'js-lookup-show',
            name: 'TerceraInstrumentLookup',
            selectedAccount: null, // for symbol info fee (commissions and swaps depend on account selected in parent's panel account lookup)
            isInstrumentLookup: true,
            instrTypeImgConst: 0,
            isSymbolInfoOpened: false,
            mayContainTrees: false, // for options in autoCompliteQuickTable #111628
            isOptionMasterMode: false
        };
    }
});
