// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ProductType, getProductTypeKey } from '../../../Utils/Instruments/ProductType';
import { type Account } from '../Account';
import { type Instrument } from '../Instrument';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { SlTpHolder } from '../../../Utils/Trading/SlTpHolder';
import { InstrumentTypes } from '../../../Utils/Instruments/InstrumentTypes';
import { Resources } from '../../properties/Resources';
import { OrderUtils } from '../../../Utils/Trading/OrderUtils';
import { DateTimeConvertor } from '../../../Utils/Time/DateTimeConvertor';
import { DateTimeUtils } from '../../../Utils/Time/DateTimeUtils';
import { OrderTif, OrderTifMap } from '../../../Utils/Trading/OrderTifEnum';
import { OptionPutCall } from '../../../Utils/Instruments/OptionPutCall';
import { DataCache } from '../../DataCache';
import { MathUtils } from '../../../Utils/MathUtils';
import { EventEmitter } from 'events';
import { HistoryType } from '../../../Utils/History/HistoryType';
import { LinkedPriceType } from './SavedOrderEnums';
import { TradingOrderStatus } from '../../Trading/TradingOrderStatus';

export class SavedOrder {
    private readonly _eventEmitter: EventEmitter = new EventEmitter();

    private _instrument: Instrument;
    private _account: Account;

    public readonly Id: string;
    public OpenedId: string;

    public Active: boolean = false;

    public InstrumentName: string = '';
    public AccountName: string = '';

    public Operation: OperationType = OperationType.Buy;

    public QuantityLots: number = 0;
    public DisclosedQuantityLots: number = 0;

    public OrderType: number = OrderType.Limit;
    public TIF: number = OrderTif.Day;
    public TIFExpiration: Date | null = null;

    public LinkedPriceType: LinkedPriceType = LinkedPriceType.None;
    public LinkedPriceOffset: number = 0;

    public Price: number = NaN;
    public StopPrice: number = NaN;

    public readonly SLTPHolder = new SlTpHolder();

    public Status: TradingOrderStatus = TradingOrderStatus.None;

    public StatusError: string | null = null;

    public ProductType: ProductType = GeneralSettings.TradingDefaults?.ProductType ?? ProductType.General;
    public Leverage: number | null = null;

    constructor (id: string) {
        this.Id = id;
    }

    public get Instrument (): Instrument {
        return this._instrument;
    }

    public set Instrument (value: Instrument) {
        const hasSubscribers = this.hasSubscribers();
        if (!MathUtils.IsNullOrUndefined(this.Instrument) && hasSubscribers) {
            this.unsubscribe();
        }
        this._instrument = value;
        if (!MathUtils.IsNullOrUndefined(this._instrument)) {
            this.InstrumentName = this._instrument.DisplayName();
        }
        this.updateProductType();
        this.updateLeverage();

        if (hasSubscribers) {
            this.subscribe();
        }

        this._eventEmitter.emit('updateInstrument');
    }

    public get Account (): Account {
        return this._account;
    }

    public set Account (value: Account) {
        this._account = value;
        if (!MathUtils.IsNullOrUndefined(this._account)) {
            this.AccountName = this._account.toString();
        }
        this.updateProductType();
        this.updateLeverage();
    }

    public get AccountId (): string {
        if (MathUtils.IsNullOrUndefined(this.Account)) {
            return '';
        }
        return this.Account.userPin;
    }

    public get AccountStr (): string {
        if (MathUtils.IsNullOrUndefined(this.Account)) {
            return this.AccountName;
        }
        return this.Account.toString();
    }

    public get InstrumentStr (): string {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return this.InstrumentName;
        }
        return this.Instrument.DisplayName();
    }

    public get InstrumentTypeStr (): string {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return '';
        }
        return InstrumentUtils.getInstrumentTypeStringLocalized(this.Instrument);
    }

    public get OperationStr (): string {
        return Resources.getResource(`general.trading.${OrderUtils.getBuySellStr(this.Operation)}`);
    }

    public get OrderTypeStr (): string {
        return Resources.getResource(`property.${OrderUtils.getOrderTypeLocalizationKey(this.OrderType)}`);
    }

    public get TifStr (): string {
        if (isNullOrUndefined(this.TIF)) { return ''; }
        const tif: string = Resources.getResource(`property.${OrderTifMap[this.TIF]}`);
        let additionalString: string = '';
        if (this.TIF === OrderTif.GTD && !isNullOrUndefined(this.TIFExpiration)) {
            additionalString = ` ${DateTimeUtils.FormatToDate(this.TIFExpiration)}`;
        }
        return Resources.getResource(`${tif}${additionalString}`); ;
    }

    public get TrailingStopOffset (): number {
        if (this.OrderType !== OrderType.TrailingStop) {
            return NaN;
        }

        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return NaN;
        }
        const marketPrice = this.Instrument.GetMarketPrice(this.Account, this.Operation);
        const trailingStopTicks = this.StopPrice;
        return OrderUtils.ConvertTickOffset(this.Instrument, GeneralSettings.TradingDefaults?.ShowOffsetIn, marketPrice, trailingStopTicks);
    }

    public get Bid (): number {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return NaN;
        }
        return this.Instrument.Level1.GetBid(this.Account);
    }

    public get Ask (): number {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return NaN;
        }
        return this.Instrument.Level1.GetAsk(this.Account);
    }

    public get Last (): number {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return NaN;
        }
        return this.Instrument.Level1.GetLastPrice(this.Account);
    }

    public get TradingExchange (): string {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return '';
        }
        return this.Instrument.TradingExchange;
    }

    public get Route (): string {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return '';
        }
        return this.Instrument.routeName;
    }

    public get SymbolType (): number {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return InstrumentTypes.GENERAL;
        }
        return this.Instrument.InstrType;
    }

    public get SymbolTypeStr (): string {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return InstrumentUtils.getInstrumentTypeStringLocalized(this.SymbolType);
        } else {
            return this.Instrument.getTypeString();
        }
    }

    public get SlPrice (): number {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return NaN;
        }
        if (this.SLTPHolder.isSlEmpty()) {
            return NaN;
        }
        if (this.SLTPHolder.isSlAbsolute()) {
            return this.SLTPHolder.StopLossPriceValue;
        }
        const basePrice = this.getBasePrice();
        const slOffset = this.SLTPHolder.StopLossPriceValue;
        const slOffsetSign = this.Operation === OperationType.Buy ? -1 : 1;
        return this.Instrument.CalculatePrice(basePrice, slOffset * slOffsetSign, true);
    }

    public get SllPrice (): number | null {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return NaN;
        }
        if (this.SLTPHolder.isSlEmpty() || this.SLTPHolder.isSllEmpty()) {
            return NaN;
        }
        if (this.SLTPHolder.isSlAbsolute()) {
            return this.SLTPHolder.StopLossLimitPriceValue;
        }
        const basePrice = this.SlPrice;
        const sllOffset = this.SLTPHolder.StopLossLimitPriceValue;
        return this.Instrument.CalculatePrice(basePrice, sllOffset, true);
    }

    public get TpPrice (): number {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return NaN;
        }
        if (this.SLTPHolder.isTpEmpty()) {
            return NaN;
        }
        if (this.SLTPHolder.isTpAbsolute()) {
            return this.SLTPHolder.TakeProfitPriceValue;
        }
        const basePrice = this.getBasePrice();
        const tpOffset = this.SLTPHolder.TakeProfitPriceValue;
        const tpOffsetSign = this.Operation === OperationType.Buy ? 1 : -1;
        return this.Instrument.CalculatePrice(basePrice, tpOffset * tpOffsetSign, true);
    }

    public get SlOffset (): number {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return NaN;
        }
        if (this.SLTPHolder.isSlEmpty()) {
            return NaN;
        }
        if (!this.SLTPHolder.isSlAbsolute()) {
            return this.SLTPHolder.StopLossPriceValue;
        }
        const basePrice = this.getBasePrice();
        const absoluteOffset = this.SLTPHolder.StopLossPriceValue - basePrice;
        return this.Instrument.CalculateTicks(basePrice, absoluteOffset, true);
    }

    public get SllOffset (): number | null {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return NaN;
        }
        if (this.SLTPHolder.isSlEmpty() || this.SLTPHolder.isSllEmpty()) {
            return NaN;
        }
        if (!this.SLTPHolder.isSlAbsolute()) {
            return this.SLTPHolder.StopLossLimitPriceValue;
        }
        const basePrice = this.SlPrice;
        const absoluteOffset = this.SLTPHolder.StopLossLimitPriceValue - basePrice;
        let sign = 1;
        if (this.Operation === OperationType.Buy && absoluteOffset > 0) {
            sign = -1;
        } else if (this.Operation === OperationType.Sell && absoluteOffset < 0) {
            sign = -1;
        }
        return this.Instrument.CalculateTicks(basePrice, absoluteOffset, true) * sign;
    }

    public get TpOffset (): number {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return NaN;
        }
        if (this.SLTPHolder.isTpEmpty()) {
            return NaN;
        }
        if (!this.SLTPHolder.isTpAbsolute()) {
            return this.SLTPHolder.TakeProfitPriceValue;
        }
        const basePrice = this.getBasePrice();
        const absoluteOffset = this.SLTPHolder.TakeProfitPriceValue - basePrice;
        return this.Instrument.CalculateTicks(basePrice, absoluteOffset, true);
    }

    public get OptionsTypeStr (): string {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return '';
        } else if (this.Instrument.InstrType === InstrumentTypes.OPTIONS) {
            return this.Instrument.PutCall === OptionPutCall.OPTION_CALL_VANILLA ? 'Call' : 'Put';
        } else {
            return '';
        }
    }

    public get StrikePrice (): number {
        if (isNullOrUndefined(this.Instrument) || this.Instrument.InstrType !== InstrumentTypes.OPTIONS) {
            return undefined;
        } else {
            return this.Instrument.StrikePrice;
        }
    }

    public get StrikePriceStr (): string {
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return '';
        } else if (this.Instrument.InstrType === InstrumentTypes.OPTIONS) {
            return this.Instrument.formatPrice(this.Instrument.StrikePrice);
        } else {
            return '';
        }
    }

    public get ExpirityDateStr (): string {
        const instrument = this.Instrument;
        if (MathUtils.IsNullOrUndefined(instrument)) {
            return '';
        } else if ((instrument.isFutureOrOption() || instrument.InstrType === InstrumentTypes.FORWARD) && instrument.ExpDateReal.getFullYear() > 2000) {
            return DateTimeUtils.FormatToDate(DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(instrument.ExpDateReal));
        } else {
            return '';
        }
    }

    public get ProductTypeStr (): string {
        return InstrumentUtils.GetLocalizedProductType(this.Instrument, this.ProductType);
    }

    public get LegerageStr (): string {
        if (this.Leverage === null) {
            return Resources.getResource('general.N_A');
        } else {
            return this.Leverage.toString();
        }
    }

    public get StatusStr (): string {
        switch (this.Status) {
        case TradingOrderStatus.None:
            return '';
        case TradingOrderStatus.CancelledByUser:
            return Resources.getResource('general.trading.orderStatus.CancelledByUser');
        case TradingOrderStatus.Placing:
            return Resources.getResource('general.trading.orderStatus.PlacingOrder');
        case TradingOrderStatus.IllegalParameters:
            return Resources.getResource('general.trading.orderStatus.IllegalParameters');
        case TradingOrderStatus.Opened:
            return `${Resources.getResource('reports.Order')} ${this.OpenedId} ${Resources.getResource('general.trading.orderStatus.Opened')}`;
        case TradingOrderStatus.Filled:
            return `${Resources.getResource('reports.Position')} ${this.OpenedId} ${Resources.getResource('general.trading.orderStatus.Opened')}`;
        case TradingOrderStatus.Error:
            return `${Resources.getResource('property.Error')}: ${this.StatusError}`;
        default:
            return '';
        }
    }

    private hasSubscribers (): boolean {
        return this._eventEmitter.listenerCount('update') > 0;
    }

    private subscribe (): void {
        const instrument = this.Instrument;
        if (MathUtils.IsNullOrUndefined(instrument)) {
            return;
        }
        const quoteCache = DataCache.FQuoteCache;
        quoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        quoteCache.addListener(instrument, this, HistoryType.QUOTE_TRADES);
    }

    private unsubscribe (): void {
        const instrument = this.Instrument;
        if (MathUtils.IsNullOrUndefined(instrument)) {
            return;
        }
        const quoteCache = DataCache.FQuoteCache;
        quoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        quoteCache.removeListener(instrument, this, HistoryType.QUOTE_TRADES);
    }

    private getBasePrice (): number {
        switch (this.OrderType) {
        case OrderType.Stop:
            return this.StopPrice;
        case OrderType.TrailingStop:
            return this.Instrument.CalculatePrice(this.Operation === OperationType.Buy ? this.Ask : this.Bid, this.StopPrice * (this.Operation === OperationType.Buy ? 1 : -1), true);
        case OrderType.Market:
            return this.Operation === OperationType.Buy ? this.Ask : this.Bid;
        default:
            return this.Price;
        }
    }

    private updateProductType (): void {
        if (isNullOrUndefined(this.Account)) {
            return;
        }
        if (isNullOrUndefined(this.Instrument)) {
            return;
        }

        const availableProductTypes = this.Account.RiskPlan !== null && this.Account.RiskPlan !== undefined
            ? this.Account.RiskPlan.GetRisksForInstrument(this.Instrument.GetInteriorID()).availableProductTypes
            : InstrumentUtils.getAllowedProductTypeDict(this.Instrument);
        if (isValidArray(availableProductTypes)) {
            if (isNullOrUndefined(this.ProductType) || !availableProductTypes.includes(this.ProductType)) {
                this.ProductType = availableProductTypes[0];
            }
        } else {
            this.ProductType = ProductType.General;
        }
    }

    private updateLeverage (): void {
        if (MathUtils.IsNullOrUndefined(this.Account)) {
            return;
        }
        if (MathUtils.IsNullOrUndefined(this.Instrument)) {
            return;
        }

        const isLeverageVisible: boolean = this.Instrument.isLeverageVisible(this.Account, this.ProductType);
        if (isLeverageVisible) {
            const leverages: number[] = this.Instrument.getLeverages(this.Account, this.ProductType);
            if (this.Leverage === null) {
                this.Leverage = leverages[0];
            }
        } else {
            this.Leverage = null;
        }
    }

    public formatPrice (price: number): string {
        if (MathUtils.IsNullOrUndefined(this.Instrument) ||
            isNaN(price) || price === null) {
            return '';
        } else {
            return this.Instrument.formatPrice(price);
        }
    }

    public formatOffset (offset: number): string {
        if (MathUtils.IsNullOrUndefined(this.Instrument) ||
            isNaN(offset) || offset === null) {
            return '';
        } else {
            return this.Instrument.formatOffsetCorrect(offset);
        }
    }

    public formatAmount (amount: number): string {
        if (MathUtils.IsNullOrUndefined(amount)) {
            return '';
        } else if (this.Instrument === null || this.Instrument === undefined) {
            return amount.toString();
        } else {
            return InstrumentUtils.formatAmountValue(amount, this.Instrument, this.Account, this.ProductType);
        }
    }

    public setDefaultPrices (): void {
        if (isNullOrUndefined(this.Instrument)) {
            return;
        }

        this.Price = NaN;
        this.StopPrice = NaN;

        switch (this.OrderType) {
        case OrderType.Limit:
            this.Price = this.Instrument.GetMarketPrice(this.Account, this.Operation);
            if (!isValidNumber(this.Price)) {
                this.Price = this.Instrument.GetPointSize(null);
            }
            break;
        case OrderType.Stop:
            this.StopPrice = this.Instrument.GetMarketPrice(this.Account, this.Operation);
            if (!isValidNumber(this.StopPrice)) {
                this.StopPrice = this.Instrument.GetPointSize(null);
            }
            break;
        case OrderType.StopLimit:
        case OrderType.OCO:
            this.Price = this.Instrument.GetMarketPrice(this.Account, this.Operation);
            if (!isValidNumber(this.Price)) {
                this.Price = this.Instrument.GetPointSize(null);
            }
            this.StopPrice = this.Instrument.GetMarketPrice(this.Account, this.Operation);
            if (!isValidNumber(this.StopPrice)) {
                this.StopPrice = this.Instrument.GetPointSize(null);
            }
            break;
        case OrderType.TrailingStop:
            this.StopPrice = 1;
            break;
        }
        this._eventEmitter.emit('onUpdateDefaultPrices');
    }

    public setLinkedPrice (): void {
        if (this.LinkedPriceType === LinkedPriceType.None) {
            return;
        }

        let linkedPrice: number | undefined;
        switch (this.LinkedPriceType) {
        case LinkedPriceType.Bid:
            linkedPrice = this.Bid;
            break;
        case LinkedPriceType.Ask:
            linkedPrice = this.Ask;
            break;
        case LinkedPriceType.Last:
            linkedPrice = this.Last;
            break;
        default:
            linkedPrice = undefined;
            break;
        }

        if (linkedPrice === undefined) {
            return;
        }

        switch (this.OrderType) {
        case OrderType.Limit:
            this.Price = this.Instrument.CalculatePrice(linkedPrice, this.LinkedPriceOffset, true);
            break;
        case OrderType.Stop:
            this.StopPrice = this.Instrument.CalculatePrice(linkedPrice, this.LinkedPriceOffset, true);
            break;
        }
    }

    public SubscribeOnUpdate (callback: (savedOrder: SavedOrder) => void): void {
        const isSubscribed = this.hasSubscribers();
        this._eventEmitter.on('update', callback);
        if (!isSubscribed) {
            this.subscribe();
        }
    }

    public SubscribeOnUpdateInstrument (callback: () => void): void {
        this._eventEmitter.on('updateInstrument', callback);
    }

    public SubscribeOnUpdateDefaultPrices (callback: () => void): void {
        this._eventEmitter.on('onUpdateDefaultPrices', callback);
    }

    public UnSubscribeOnUpdateDefaultPrices (callback: () => void): void {
        this._eventEmitter.off('onUpdateDefaultPrices', callback);
    }

    public UnsubscribeOnUpdate (callback: (savedOrder: SavedOrder) => void): void {
        this._eventEmitter.off('update', callback);
        if (!this.hasSubscribers()) {
            this.unsubscribe();
        }
    }

    public UnsubscribeOnUpdateInstrument (callback: () => void): void {
        this._eventEmitter.off('updateInstrument', callback);
    }

    public newQuote (): void {
        this._eventEmitter.emit('update', this);
        this.setLinkedPrice();
    }
}
