// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ColumnParams } from './ColumnParams';
import { QuickTableColumnType, QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { DataCache } from '../../Commons/DataCache';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { RiskPlan } from '../../Commons/cache/RiskPlan';
import { DynProperty } from '../../Commons/DynProperty';
import { Resources } from '../../Commons/properties/Resources';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { OrderType } from '../../Utils/Trading/OrderType';
import { Quantity } from '../../Utils/Trading/Quantity';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { OffsetModeViewEnum } from '../../Utils/Trading/OffsetModeViewEnum';
import { LinkedPriceType } from '../../Commons/cache/SavedOrders/SavedOrderEnums';
import { SavedOrderItemBase } from './SavedOrderItemsBase';
import { type SavedOrder } from '../../Commons/cache/SavedOrders/SavedOrder';
import { ColumnData } from './BaseItem';

export class SavedOrderItem extends SavedOrderItemBase<SavedOrder> {
    public override initEditingInfo (): void {
        this.QuickTableEditingInfoMap[SavedOrderItem.ACTIVE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.BOOLEAN);
        const accountLookup = new QuickTableEditingInfo(DynProperty.ACCOUNT);
        accountLookup.IsEditable = !DataCache.EnableForceLinkingByAccount();
        accountLookup.GetDataHandler = this.GetCurrentAccount.bind(this);
        this.QuickTableEditingInfoMap[SavedOrderItem.ACCOUNT_COL_INDEX] = accountLookup;

        const instrumentLookup = new QuickTableEditingInfo(DynProperty.INSTRUMENT);
        instrumentLookup.GetDataHandler = this.GetCurrentInstrument.bind(this);
        this.QuickTableEditingInfoMap[SavedOrderItem.INSTRUMENT_COL_INDEX] = instrumentLookup;

        const operationCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX);
        operationCombobox.GetDataHandler = this.getOpeationComboboxItems;
        this.QuickTableEditingInfoMap[SavedOrderItem.OPERATION_COL_INDEX] = operationCombobox;

        const orderTypeCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX);
        orderTypeCombobox.GetDataHandler = this.getOrderTypeComboboxItems;
        this.QuickTableEditingInfoMap[SavedOrderItem.ORDER_TYPE_COL_INDEX] = orderTypeCombobox;

        const tifCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX_COMBOITEM_TIF);
        tifCombobox.GetDataHandler = this.getTifComboboxItems;
        this.QuickTableEditingInfoMap[SavedOrderItem.TIF_COL_INDEX] = tifCombobox;

        const productTypeCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX);
        productTypeCombobox.GetDataHandler = this.getProductTypeComboboxItems;
        this.QuickTableEditingInfoMap[SavedOrderItem.PRODUCT_TYPE_COL_INDEX] = productTypeCombobox;

        const linkedPriceTypeCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX);
        linkedPriceTypeCombobox.GetDataHandler = this.getLinkedPriceTypeComboboxItems;
        this.QuickTableEditingInfoMap[SavedOrderItem.LINK_COL_INDEX] = linkedPriceTypeCombobox;

        this.QuickTableEditingInfoMap[SavedOrderItem.QUANTITY_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[SavedOrderItem.DISCLOSED_QUANTITY_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);

        this.QuickTableEditingInfoMap[SavedOrderItem.PRICE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[SavedOrderItem.STOP_PRICE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[SavedOrderItem.SL_PRICE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[SavedOrderItem.SLL_PRICE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[SavedOrderItem.TP_PRICE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);

        this.QuickTableEditingInfoMap[SavedOrderItem.LINKED_PRICE_OFFSET_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[SavedOrderItem.TRAILING_STOP_COL_INDEX] = new QuickTableEditingInfo(DynProperty.BOOLEAN);
        this.QuickTableEditingInfoMap[SavedOrderItem.SL_OFFSET_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[SavedOrderItem.SLL_OFFSET_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        this.QuickTableEditingInfoMap[SavedOrderItem.TP_OFFSET_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);

        const leverageCombobox = new QuickTableEditingInfo(DynProperty.COMBOBOX);
        leverageCombobox.GetDataHandler = this.getLeverageComboboxItems;
        this.QuickTableEditingInfoMap[SavedOrderItem.LEVERAGE_COL_INDEX] = leverageCombobox;

        this.QuickTableEditingInfoMap[SavedOrderItem.REMOVE_ORDER_COL_INDEX] = new QuickTableEditingInfo(DynProperty.CLOSE_BUTTON);
        this.QuickTableEditingInfoMap[SavedOrderItem.SUBMIT_COL_INDEX] = new QuickTableEditingInfo(DynProperty.PLACE_BUTTON);
    }

    public override updateEditingInfo (): void {
        this.updatePriceNumeric(SavedOrderItem.PRICE_COL_INDEX);
        if (this.savedOrder.OrderType === OrderType.TrailingStop) {
            this.updateOffsetNumeric(SavedOrderItem.STOP_PRICE_COL_INDEX, false, true);
        } else {
            this.updatePriceNumeric(SavedOrderItem.STOP_PRICE_COL_INDEX);
        }
        this.updatePriceNumeric(SavedOrderItem.SL_PRICE_COL_INDEX, 0);
        this.updatePriceNumeric(SavedOrderItem.SLL_PRICE_COL_INDEX, 0);
        this.updatePriceNumeric(SavedOrderItem.TP_PRICE_COL_INDEX, 0);

        this.updateOffsetNumeric(SavedOrderItem.LINKED_PRICE_OFFSET_COL_INDEX, true);
        this.updateOffsetNumeric(SavedOrderItem.SL_OFFSET_COL_INDEX, false, false, 0);
        this.updateOffsetNumeric(SavedOrderItem.SLL_OFFSET_COL_INDEX, true, false, 0);
        this.updateOffsetNumeric(SavedOrderItem.TP_OFFSET_COL_INDEX, false, false, 0);

        this.updateAmountNumeric(SavedOrderItem.QUANTITY_COL_INDEX);
        this.updateAmountNumeric(SavedOrderItem.DISCLOSED_QUANTITY_COL_INDEX);
    }

    public ColumnCount (): number {
        return SavedOrderItem.columnsParams.length;
    }

    public GetColumnParams (index: number): any {
        const columnParams = SavedOrderItem.columnsParams[index];
        columnParams.MenuIndex = SavedOrderItem.GetMenuIndex(columnParams.HeaderKey);
        switch (index) {
        case SavedOrderItem.TRAILING_STOP_COL_INDEX:
            columnParams.Hidden = !DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRAILING_STOP);
            break;
        case SavedOrderItem.ROUTE_COL_INDEX:
            columnParams.Hidden = !DataCache.isAllowedForMyUser(RulesSet.FUNCTION_POSITIONSADMIN);
            break;
        case SavedOrderItem.SL_PRICE_COL_INDEX:
        case SavedOrderItem.TP_PRICE_COL_INDEX:
        case SavedOrderItem.SL_OFFSET_COL_INDEX:
        case SavedOrderItem.TP_OFFSET_COL_INDEX:
        case SavedOrderItem.SLL_PRICE_COL_INDEX:
        case SavedOrderItem.SLL_OFFSET_COL_INDEX:
            columnParams.Hidden = !DataCache.isAllowedForMyUser(RulesSet.FUNCTION_SLTP);
            break;
        case SavedOrderItem.PRODUCT_TYPE_COL_INDEX:
            columnParams.Hidden = !RiskPlan.showProductType;
            break;
        }
        return columnParams;
    }

    public getColumnData (index: number): ColumnData {
        const value = this.getColumnValue(index);
        let formattedValue: string = '';
        switch (index) {
        case SavedOrderItem.ACCOUNT_COL_INDEX:
            formattedValue = this.savedOrder.AccountStr;
            break;
        case SavedOrderItem.OPERATION_COL_INDEX:
            formattedValue = this.savedOrder.OperationStr;
            break;
        case SavedOrderItem.ORDER_TYPE_COL_INDEX:
            formattedValue = this.savedOrder.OrderTypeStr;
            break;
        case SavedOrderItem.QUANTITY_COL_INDEX:
        case SavedOrderItem.DISCLOSED_QUANTITY_COL_INDEX:
            formattedValue = this.savedOrder.formatAmount(value);
            break;
        case SavedOrderItem.TIF_COL_INDEX:
            formattedValue = this.savedOrder.TifStr;
            break;
        case SavedOrderItem.STATUS_COL_INDEX:
            formattedValue = this.savedOrder.StatusStr;
            break;
        case SavedOrderItem.LINK_COL_INDEX:
            if (this.savedOrder.OrderType === OrderType.Limit || this.savedOrder.OrderType === OrderType.Stop) {
                formattedValue = SavedOrderItemBase.LocalizeLinkPriceType(this.savedOrder.LinkedPriceType);
            } else {
                formattedValue = '';
            }
            break;
        case SavedOrderItem.LINKED_PRICE_OFFSET_COL_INDEX:
            if ((this.savedOrder.OrderType === OrderType.Limit || this.savedOrder.OrderType === OrderType.Stop) && this.savedOrder.LinkedPriceType !== LinkedPriceType.None) {
                formattedValue = this.savedOrder.formatOffset(this.savedOrder.LinkedPriceOffset);
            } else {
                formattedValue = '';
            }
            break;
        case SavedOrderItem.PRICE_COL_INDEX:
        case SavedOrderItem.BID_COL_INDEX:
        case SavedOrderItem.ASK_COL_INDEX:
        case SavedOrderItem.LAST_COL_INDEX:
        case SavedOrderItem.SL_PRICE_COL_INDEX:
        case SavedOrderItem.SLL_PRICE_COL_INDEX:
        case SavedOrderItem.TP_PRICE_COL_INDEX:
            formattedValue = this.savedOrder.formatPrice(value);
            break;
        case SavedOrderItem.STOP_PRICE_COL_INDEX:
            if (this.savedOrder.OrderType === OrderType.TrailingStop) {
                formattedValue = `${this.savedOrder.formatOffset(this.savedOrder.StopPrice)} (${Resources.getResource('panel.savedOrders.Trailing')})`;
            } else {
                formattedValue = this.savedOrder.formatPrice(this.savedOrder.StopPrice);
            }
            break;
        case SavedOrderItem.SL_OFFSET_COL_INDEX:
            formattedValue = this.savedOrder.formatOffset(this.savedOrder.SlOffset);
            break;
        case SavedOrderItem.SLL_OFFSET_COL_INDEX:
            formattedValue = this.savedOrder.formatOffset(this.savedOrder.SllOffset);
            break;
        case SavedOrderItem.TP_OFFSET_COL_INDEX:
            formattedValue = this.savedOrder.formatOffset(this.savedOrder.TpOffset);
            break;
        case SavedOrderItem.PRODUCT_TYPE_COL_INDEX:
            formattedValue = this.savedOrder.ProductTypeStr;
            break;
        case SavedOrderItem.SYMBOL_TYPE_COL_INDEX:
            formattedValue = this.savedOrder.SymbolTypeStr;
            break;
        case SavedOrderItem.LEVERAGE_COL_INDEX:
            formattedValue = this.savedOrder.LegerageStr;
            break;

        default:
            formattedValue = value.toString();
            break;
        }

        return new ColumnData(value, formattedValue);
    }

    public getColumnValue (index: number): any {
        switch (index) {
        case SavedOrderItem.ACTIVE_COL_INDEX:
            return this.savedOrder.Active;
        case SavedOrderItem.ACCOUNT_COL_INDEX:
            return this.savedOrder.AccountStr;
        case SavedOrderItem.INSTRUMENT_COL_INDEX:
            return this.savedOrder.InstrumentStr;
        case SavedOrderItem.OPERATION_COL_INDEX:
            return this.savedOrder.Operation;
        case SavedOrderItem.ORDER_TYPE_COL_INDEX:
            return this.savedOrder.OrderType;
        case SavedOrderItem.PRICE_COL_INDEX:
            return this.savedOrder.Price;
        case SavedOrderItem.BID_COL_INDEX:
            return this.savedOrder.Bid;
        case SavedOrderItem.ASK_COL_INDEX:
            return this.savedOrder.Ask;
        case SavedOrderItem.LAST_COL_INDEX:
            return this.savedOrder.Last;
        case SavedOrderItem.QUANTITY_COL_INDEX:
            if (!isNullOrUndefined(this.savedOrder.Instrument)) {
                return Quantity.convertLotsToAmount(this.savedOrder.QuantityLots, this.savedOrder.Instrument, true, this.savedOrder.Account, this.savedOrder.ProductType);
            } else {
                return this.savedOrder.QuantityLots;
            }
        case SavedOrderItem.DISCLOSED_QUANTITY_COL_INDEX:
            if (!isNullOrUndefined(this.savedOrder.Instrument)) {
                return Quantity.convertLotsToAmount(this.savedOrder.DisclosedQuantityLots, this.savedOrder.Instrument, true, this.savedOrder.Account, this.savedOrder.ProductType);
            } else {
                return this.savedOrder.DisclosedQuantityLots;
            }
        case SavedOrderItem.TIF_COL_INDEX:
            return this.savedOrder.TifStr;
        case SavedOrderItem.STATUS_COL_INDEX:
            return this.savedOrder.Status;
        case SavedOrderItem.TRAILING_STOP_COL_INDEX:
            return this.savedOrder.SLTPHolder.isTrailingStop();
        case SavedOrderItem.STOP_PRICE_COL_INDEX:
            if (this.savedOrder.OrderType === OrderType.TrailingStop && !isNullOrUndefined(this.savedOrder.Instrument)) {
                return OrderUtils.ConvertTickOffset(this.savedOrder.Instrument,
                    GeneralSettings.TradingDefaults.ShowOffsetIn,
                    this.savedOrder.Instrument.GetMarketPrice(this.savedOrder.Account, this.savedOrder.Operation),
                    this.savedOrder.StopPrice);
            } else {
                return this.savedOrder.StopPrice;
            }
        case SavedOrderItem.OPTIONS_TYPE_COL_INDEX:
            return this.savedOrder.OptionsTypeStr;
        case SavedOrderItem.STRIKE_PRICE_COL_INDEX:
            return this.savedOrder.StrikePriceStr;
        case SavedOrderItem.EXPIRY_DATE_COL_INDEX:
            return this.savedOrder.ExpirityDateStr;
        case SavedOrderItem.LINK_COL_INDEX:
            return this.savedOrder.LinkedPriceType;
        case SavedOrderItem.LINKED_PRICE_OFFSET_COL_INDEX:
            if (!isNullOrUndefined(this.savedOrder.Instrument)) {
                return OrderUtils.ConvertTickOffset(this.savedOrder.Instrument,
                    GeneralSettings.TradingDefaults.ShowOffsetIn,
                    this.savedOrder.Instrument.GetMarketPrice(this.savedOrder.Account, this.savedOrder.Operation),
                    this.savedOrder.LinkedPriceOffset,
                    true);
            } else {
                return this.savedOrder.LinkedPriceOffset;
            }
        case SavedOrderItem.TRADING_EXCHANGE_COL_INDEX:
            return this.savedOrder.TradingExchange;
        case SavedOrderItem.ROUTE_COL_INDEX:
            return this.savedOrder.Route;
        case SavedOrderItem.SL_PRICE_COL_INDEX:
            return this.savedOrder.SlPrice;
        case SavedOrderItem.SLL_PRICE_COL_INDEX:
            return this.savedOrder.SllPrice;
        case SavedOrderItem.TP_PRICE_COL_INDEX:
            return this.savedOrder.TpPrice;
        case SavedOrderItem.SL_OFFSET_COL_INDEX:
            if (!isNullOrUndefined(this.savedOrder.Instrument)) {
                return OrderUtils.ConvertTickOffset(this.savedOrder.Instrument,
                    GeneralSettings.TradingDefaults.ShowOffsetIn,
                    this.savedOrder.Instrument.GetMarketPrice(this.savedOrder.Account, this.savedOrder.Operation),
                    this.savedOrder.SlOffset);
            } else {
                return this.savedOrder.SlOffset;
            }
        case SavedOrderItem.SLL_OFFSET_COL_INDEX:
            if (!isNullOrUndefined(this.savedOrder.Instrument)) {
                return OrderUtils.ConvertTickOffset(this.savedOrder.Instrument,
                    this.savedOrder.SLTPHolder.isTrailingStop() && !GeneralSettings.TradingDefaults.SetSlTpValuesInOffset ? OffsetModeViewEnum.Ticks : GeneralSettings.TradingDefaults.ShowOffsetIn,
                    this.savedOrder.Instrument.GetMarketPrice(this.savedOrder.Account, this.savedOrder.Operation),
                    this.savedOrder.SllOffset,
                    true);
            } else {
                return this.savedOrder.SllOffset;
            }
        case SavedOrderItem.TP_OFFSET_COL_INDEX:
            if (!isNullOrUndefined(this.savedOrder.Instrument)) {
                return OrderUtils.ConvertTickOffset(this.savedOrder.Instrument,
                    GeneralSettings.TradingDefaults.ShowOffsetIn,
                    this.savedOrder.Instrument.GetMarketPrice(this.savedOrder.Account, this.savedOrder.Operation),
                    this.savedOrder.TpOffset);
            } else {
                return this.savedOrder.TpOffset;
            }
        case SavedOrderItem.PRODUCT_TYPE_COL_INDEX:
            return this.savedOrder.ProductType;
        case SavedOrderItem.SYMBOL_TYPE_COL_INDEX:
            return this.savedOrder.SymbolType;
        case SavedOrderItem.LEVERAGE_COL_INDEX:
            return this.savedOrder.Leverage;
        default:
            return '';
        }
    }

    static readonly ACTIVE_COL_INDEX = 0;
    static readonly ACCOUNT_COL_INDEX = 1;
    static readonly INSTRUMENT_COL_INDEX = 2;
    static readonly ROUTE_COL_INDEX = 3;
    static readonly SYMBOL_TYPE_COL_INDEX = 4;
    static readonly OPERATION_COL_INDEX = 5;
    static readonly ORDER_TYPE_COL_INDEX = 6;
    static readonly PRODUCT_TYPE_COL_INDEX = 7;
    static readonly PRICE_COL_INDEX = 8;
    static readonly QUANTITY_COL_INDEX = 9;
    static readonly DISCLOSED_QUANTITY_COL_INDEX = 10;
    static readonly STOP_PRICE_COL_INDEX = 11;
    static readonly TIF_COL_INDEX = 12;
    static readonly LINKED_PRICE_OFFSET_COL_INDEX = 13;
    static readonly TRAILING_STOP_COL_INDEX = 14;
    static readonly OPTIONS_TYPE_COL_INDEX = 15;
    static readonly STRIKE_PRICE_COL_INDEX = 16;
    static readonly EXPIRY_DATE_COL_INDEX = 17;
    static readonly LAST_COL_INDEX = 18;
    static readonly BID_COL_INDEX = 19;
    static readonly ASK_COL_INDEX = 20;
    static readonly STATUS_COL_INDEX = 21;
    static readonly LINK_COL_INDEX = 22;
    static readonly SL_PRICE_COL_INDEX = 23;
    static readonly SLL_PRICE_COL_INDEX = 24;
    static readonly TP_PRICE_COL_INDEX = 25;
    static readonly SL_OFFSET_COL_INDEX = 26;
    static readonly SLL_OFFSET_COL_INDEX = 27;
    static readonly TP_OFFSET_COL_INDEX = 28;
    static readonly TRADING_EXCHANGE_COL_INDEX = 29;
    static readonly LEVERAGE_COL_INDEX = 30;
    static readonly REMOVE_ORDER_COL_INDEX = 31;
    static readonly SUBMIT_COL_INDEX = 32;

    static columnsParams: ColumnParams[] =
        [
            new ColumnParams('panel.savedOrders.active', 70, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.savedOrders.account', 200, QuickTableColumnType.COL_DEFAULT, false, false, false, false, true),
            new ColumnParams('panel.savedOrders.instrument', 200, QuickTableColumnType.COL_DEFAULT, false, true, false, false, true),
            new ColumnParams('panel.savedOrders.route', 100, QuickTableColumnType.COL_DEFAULT, false, false, true),
            new ColumnParams('panel.savedOrders.SymbolType', 65, QuickTableColumnType.COL_DEFAULT, false, false, true),
            new ColumnParams('panel.savedOrders.operation', 100, QuickTableColumnType.COL_DEFAULT, false, true, false, false, true),
            new ColumnParams('panel.savedOrders.OrderType', 100, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.savedOrders.ProductType', 100, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.savedOrders.price', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
            new ColumnParams('panel.savedOrders.quantity', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
            new ColumnParams('panel.savedOrders.disclosedQty', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
            new ColumnParams('panel.savedOrders.stopPrice', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
            new ColumnParams('panel.savedOrders.tif', 100, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.savedOrders.LinkedPriceOffset', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
            new ColumnParams('panel.savedOrders.TrailingStop', 70, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.savedOrders.OptionsType', 100, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.savedOrders.StrikePrice', 100, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.savedOrders.ExpiryDate', 100, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.savedOrders.last', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
            new ColumnParams('panel.savedOrders.bid', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.savedOrders.ask', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.savedOrders.status', 200, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.savedOrders.Link', 100, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.savedOrders.SL_Price', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.savedOrders.SLL_Price', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.savedOrders.TP_Price', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.savedOrders.SL_Offset', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.savedOrders.SLL_Offset', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.savedOrders.TP_Offset', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.savedOrders.ExchangeTrading', 100, QuickTableColumnType.COL_DEFAULT, false, false, true),
            new ColumnParams('panel.savedOrders.Leverage', 100, QuickTableColumnType.COL_DATE_SORT, false, false, true),
            new ColumnParams('panel.savedOrders.RemoveOrder', 70, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.savedOrders.SubmitOrder', 70, QuickTableColumnType.COL_DEFAULT, false, true, false)
        ];

    static GetMenuIndex (headerKey: string): number {
        const index = SavedOrderItem.ListMenuIndex.indexOf(headerKey);
        return index !== -1 ? index : 1000;
    };

    static ListMenuIndex: string[] =
        [
            'panel.savedOrders.active',
            'panel.savedOrders.account',
            'panel.savedOrders.instrument',
            'panel.savedOrders.route',
            'panel.savedOrders.ExchangeTrading',
            'panel.savedOrders.SymbolType',
            'panel.savedOrders.operation',
            'panel.savedOrders.OrderType',
            'panel.savedOrders.ProductType',
            'panel.savedOrders.price',
            'panel.savedOrders.quantity',
            'panel.savedOrders.disclosedQty',
            'panel.savedOrders.stopPrice',
            'panel.savedOrders.tif',
            'panel.savedOrders.LinkedPriceOffset',
            'panel.savedOrders.TrailingStop',
            'panel.savedOrders.OptionsType',
            'panel.savedOrders.StrikePrice',
            'panel.savedOrders.ExpiryDate',
            'panel.savedOrders.last',
            'panel.savedOrders.bid',
            'panel.savedOrders.ask',
            'panel.savedOrders.status',
            'panel.savedOrders.Link',
            'panel.savedOrders.SL_Price',
            'panel.savedOrders.SLL_Price',
            'panel.savedOrders.TP_Price',
            'panel.savedOrders.SL_Offset',
            'panel.savedOrders.SLL_Offset',
            'panel.savedOrders.TP_Offset',
            'panel.savedOrders.Leverage',
            'panel.savedOrders.RemoveOrder',
            'panel.savedOrders.SubmitOrder'
        ];
}
