// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { PasswordTextBoxTemplate } from '../../templates';
import { type TerceraTextBox, TextBoxEvents } from '../../Controls/elements/TerceraTextBox';
import { Resources } from '../../Commons/properties/Resources';
import { KeyCode } from '../../Commons/KeyEventProcessor';
import { ContainerControl } from './ContainerControl';

export class PasswordTextBox extends ContainerControl {
    Controls: any;
    textBox: TerceraTextBox;
    wasPaste: boolean = false;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    getType (): string { return ControlsTypes.PasswordTextBox; }

    oninit (): void {
        super.oninit();

        super.on('showPassEye_btnClick', this.showPassEye_btnClick);
        super.on('showPassEye_btnHover', this.showPassEye_btnHover);
    }

    oncomplete (): void {
        super.oncomplete();

        this.subscribeControlsEvents();

        this.localize();
    }

    private isPasswordTextBox (control): boolean {
        const type = control.getType();
        const result = [ControlsTypes.TerceraTextBox, ControlsTypes.InvestingTextBox].includes(type as ControlsTypes);
        return result;
    }

    subscribeControlsEvents (): void {
        for (const ID in this.Controls) {
            const control = this.Controls[ID];
            if (this.isPasswordTextBox(control)) {
                this.textBox = control;
            }
        }
        this.textBox.on('keyDown', this.onKeyDown.bind(this));

        const input = this.textBox.getInputElement();
        input.addEventListener('input', () => {
            if (this.wasPaste) { input.value = input.value.trim(); }
            this.wasPaste = false;
        });
    }

    onKeyDown (context): void {
        const ev = context.event;
        if (ev.ctrlKey === true && ev.keyCode === KeyCode.V) {
            this.wasPaste = true;
        }
        super.fire(TextBoxEvents.KeyDown, context);
    }

    showPassEye_btnClick (): void {
        const eyeNotCrossed = Boolean(super.get('passwordHided'));
        void super.set('passwordHided', !eyeNotCrossed);
    }

    showPassEye_btnHover (context): void {
        const event = context.event;

        if (event.currentTarget === event.target) { return; }

        const newEvent = new MouseEvent(event.type);
        Object.defineProperty(newEvent, 'target', { value: event.currentTarget, writable: false });
        event.currentTarget.dispatchEvent(newEvent);
    }

    getInputElement (): HTMLElement {
        return this.textBox.getInputElement();
    }

    localize (): void {
        void super.set({
            showPassEyeTooltip: Resources.getResource('screen.LoginScreen.ShowPassEye.ToolTip'),
            hidePassEyeCrossedTooltip: Resources.getResource('screen.LoginScreen.ShowPassCrossed.ToolTip')
        });
    }
}

ContainerControl.extendWith(PasswordTextBox, {
    template: PasswordTextBoxTemplate,
    data: function () {
        return {
            showPasswordPreview: false,
            passwordHided: true,
            focused: false,
            showPassEyeTooltip: '',
            hidePassEyeCrossedTooltip: ''
        };
    }
});
