// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type AccountDetailsGroupItemsEnumType } from '../../../../Utils/Enums/AccountDetailsEnum';
import { type Account } from '../../../cache/Account';
import { type AssetBalance } from '../../../cache/AssetBalance';
import { type IBaseAccountGroupController } from '../../Interfaces/IBaseAccountGroupController';
import { type IBaseAssetBalanceGroupController } from '../../Interfaces/IBaseAssetBalanceGroupController';
import { BaseGroupController } from './BaseGroupController';

export class BaseAccountGroupController<GroupItemsType = AccountDetailsGroupItemsEnumType> extends BaseGroupController<GroupItemsType> implements IBaseAccountGroupController, IBaseAssetBalanceGroupController {
    private _account: Account = null;
    private _assetBalance: AssetBalance = null;

    constructor (account: Account, assetBalance: AssetBalance) {
        super();
        this.Account = account;
        this.AssetBalance = assetBalance;
    }

    get Account (): Account { return this._account; }
    set Account (value: Account) {
        this._account = value;
        this.AssetBalance = value.assetBalanceDefault;
    }

    get AssetBalance (): AssetBalance { return this._assetBalance; }
    set AssetBalance (value: AssetBalance) { this._assetBalance = value; }
}
