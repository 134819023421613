import { type Account } from '../../cache/Account';
import { type Instrument } from '../../cache/Instrument';
import { type OrderDurationMetaInfo } from '../charting_library';
import { OrderExecutorUtils } from '../../Trading/OrderExecutorUtils';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { SessionSettings } from '../../SessionSettings';
import { TIF } from '../../../Utils/Trading/OrderTif';
import { OrderTif, OrderTifMap } from '../../../Utils/Trading/OrderTifEnum';
import { Resources } from '../../properties/Resources';
import { TvOrderConvertor } from './TvOrderConvertor';

class _TvDurationConvertor {
    public getAllowedDurationsForInstrumentAccount (instrument: Instrument, account: Account): OrderDurationMetaInfo[] {
        const allowedOrderTypes = this.getAllowedOrderTypes(instrument, account);
        return this.getAllowedDurations(allowedOrderTypes, instrument);
    }

    public getAllAllowedDurations (): OrderDurationMetaInfo[] {
        const allAllowedOrderTypes = this.getAllAlowedOrderTypes();
        return this.getAllowedDurations(allAllowedOrderTypes);
    }

    private getAllowedDurations (allAllowedOrderTypes: number[], instrument?: Instrument): OrderDurationMetaInfo[] {
        const tifsMap = this.getAllowedOrderTypesByTif(allAllowedOrderTypes, instrument);

        const durations: OrderDurationMetaInfo[] = [];
        for (const tif of tifsMap.keys()) {
            const allowedOrderTypes = tifsMap.get(tif);
            if (allowedOrderTypes.length === 0) continue;
            const allowedTvOrderTypes = allowedOrderTypes.map(TvOrderConvertor.getTvOrderType);

            const duration: OrderDurationMetaInfo = this.convertTifToDuration(tif, allowedTvOrderTypes);
            durations.push(duration);
        }

        return durations;
    }

    private InvertMap (originalMap: Map<number, number[]>) {
        const invertedMap = new Map<number, number[]>();
        const allAllowedOrderTypes = this.getAllAlowedOrderTypes();

        originalMap.forEach((tifs, orderType) => {
            tifs.forEach(tif => {
                if (!invertedMap.has(tif)) {
                    invertedMap.set(tif, []);
                }

                if (!invertedMap.get(tif).includes(orderType) && allAllowedOrderTypes.includes(orderType)) {
                    invertedMap.get(tif).push(orderType);
                }
            });
        });

        return invertedMap;
    }

    public getAllowedTvOrderTypes (instrument: Instrument, account: Account): number[] {
        const rAllowedOrderTypes = this.getAllowedOrderTypes(instrument, account);
        return rAllowedOrderTypes.map(x => TvOrderConvertor.getTvOrderType(x));
    }

    public getAllowedOrderTypes (instrument: Instrument, account: Account): number[] {
        const obj = OrderExecutorUtils.getAllowedOrderTypeDict(account, instrument, []);
        const keys = Object.keys(obj);
        const numericKeys = keys.map(key => parseInt(key));
        const allowedOrderTypes = this.getAllAlowedOrderTypes();
        const filtered = numericKeys.filter(x => allowedOrderTypes.includes(x));
        return filtered;
    }

    private getAllowedOrderTypesByTif (allAllowedOrderTypes: number[], instrument?: Instrument): Map<number, number[]> {
        const tifsByOrderTypeMap = new Map<number, number[]>();
        for (const orderType of allAllowedOrderTypes) {
            let tifs;
            if (instrument !== undefined) {
                tifs = OrderExecutorUtils.getAllowedTIFArray(orderType, instrument);
            } else {
                tifs = SessionSettings.GetAllowedTifsForOrderTypes([orderType]);
            }
            tifsByOrderTypeMap.set(orderType, tifs);
        }

        const tifsMap = this.InvertMap(tifsByOrderTypeMap);
        return tifsMap;
    }

    public getAllAlowedOrderTypes (): number[] {
        const allowedOrderTypes: number[] =
         [
             OrderType.Market,
             OrderType.Stop,
             OrderType.Limit,
             OrderType.StopLimit
             // OrderType.TrailingStop // TrailingStop OrderType not exist in TV TradingPlatform
         ];

        return allowedOrderTypes;
    }

    public getProtectedOrderTypes (): number[] {
        const protectedOrderTypes: number[] =
         [
             OrderType.SLTPLimit,
             OrderType.SLTPStop,
             OrderType.TRStop
         ];

        return protectedOrderTypes;
    }

    private convertTifToDuration (tif: number, allowedTvOrderTypes: number[]): OrderDurationMetaInfo {
        const duration: OrderDurationMetaInfo = {
            hasDatePicker: tif === OrderTif.GTD,
            hasTimePicker: false,
            default: tif === OrderTif.Day,
            name: Resources.getResource(OrderTifMap[tif]),
            value: OrderTifMap[tif],
            supportedOrderTypes: allowedTvOrderTypes
        };
        return duration;
    }
}

export const TvDurationConvertor = new _TvDurationConvertor();
