// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { FractalsAggregationType, FractalsPeriod } from '../IndicatorConstants';
import { ChartDataType } from '../../../../Chart/Utils/ChartConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { Period } from '../IndicatorEnums';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class FRACTALS3 extends IndicatorScriptBase {
    public fractalDimension: number;
    public fPeriod: number;
    public agrType: number;
    public value: number;
    public oneAlert: boolean = false;

    constructor () {
        super();
        // #region Initialization
        this.ProjectName = 'Fractals3';
        // #endregion

        super.SetIndicatorLine('Fractals Long', Color.MediumBlue, 2, LineStyle.HorizontalLine);
        super.SetIndicatorLine('Fractals Short', Color.MediumBlue, 2, LineStyle.HorizontalLine);

        this.SeparateWindow = false;

        this.fractalDimension = 3;
        super.InputParameter(new InputParameterInteger('fractalDimension', 'Fractal Dimension', 0, 1, 99999, 0, 1));

        this.fPeriod = FractalsPeriod.Hour;
        super.InputParameter(new InputParameterCombobox('fPeriod', 'Period of fractals', 1,
            [
                { Second: FractalsPeriod.Second },
                { Minute: FractalsPeriod.Minute },
                { Hour: FractalsPeriod.Hour },
                { Day: FractalsPeriod.Day },
                { Week: FractalsPeriod.Week },
                { Month: FractalsPeriod.Month },
                { Year: FractalsPeriod.Year }
            ]));

        this.agrType = FractalsAggregationType.Current;
        super.InputParameter(new InputParameterCombobox('agrType', 'Aggregation type of fractals', 2,
            [
                { Current: FractalsAggregationType.Current },
                { Custom: FractalsAggregationType.Custom }
            ]));

        this.value = 3;
        super.InputParameter(new InputParameterInteger('value', 'Value of fractals', 3, 1, 99999, 0, 1));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'FRACTALS(' + this.fractalDimension + ')';
    }

    public override getCustomName (): string {
        return 'FRACTALS (' + this.ProjectName + ')';
    };

    public NextBar (): void {
        super.NextBar();
        if (this.oneAlert) { return; }

        if (this.fractalDimension % 2 === 0 || this.fractalDimension < 3) {
            this.oneAlert = true;
            alert('Fractal Dimension must be an odd number, greater than or equal to (' + 3 + ')');
        }

        if (this.CurrentData.cashItem.ChartDataType != ChartDataType.Default) {
            this.oneAlert = true;
            alert('Fractals can be built only on the type of aggregation Tick and Time');
        }

        let isHighest = true;
        let isLowest = true;

        const minutesInCurrentPeriod = this.CurrentData.Period;

        let dim = this.fractalDimension;

        let minutesInPeriod = 0;

        if (this.agrType == FractalsAggregationType.Custom) {
            switch (this.fPeriod) {
            case FractalsPeriod.Second:
                minutesInPeriod = 1;
                break;

            case FractalsPeriod.Minute:
                minutesInPeriod = Period.Min;
                break;

            case FractalsPeriod.Hour:
                minutesInPeriod = Period.Hour;
                break;

            case FractalsPeriod.Day:
                minutesInPeriod = Period.Day;
                break;

            case FractalsPeriod.Week:
                minutesInPeriod = Period.Week;
                break;

            case FractalsPeriod.Month:
                minutesInPeriod = Period.Month;
                break;

            case FractalsPeriod.Year:
                minutesInPeriod = Period.Year;
                break;

            default:
                minutesInPeriod = Period.Hour;
                break;
            }

            const minutesInMajorPeriod = minutesInPeriod * this.value;

            if (!this.oneAlert && minutesInCurrentPeriod > minutesInMajorPeriod) {
                this.oneAlert = true;

                alert('Please check custom period');
            }

            dim = this.fractalDimension * (minutesInMajorPeriod / minutesInCurrentPeriod);
        }

        if (this.CurrentData.Period < 0) {
            dim = this.value * (-this.CurrentData.Period);
        }

        if (this.CurrentData.Count < dim + 2) { return; }

        const position = (dim - 1) / 2;

        for (let i = dim; i >= 0; i--) {
            if (position !== i) {
                if (this.High(position + 1) <= this.High(i + 1)) {
                    isHighest = false;
                }

                if (this.Low(position + 1) >= this.Low(i + 1)) {
                    isLowest = false;
                }
            }
        }

        if (isHighest) {
            this.SetValue(0, position + 1, this.High(position + 1) + 5 * this.CurrentData.Instrument.PointSize);
        }

        if (isLowest) {
            this.SetValue(1, position + 1, this.Low(position + 1) - 5 * this.CurrentData.Instrument.PointSize);
        }
    }

    public High (i): any {
        return this.CurrentData.GetPrice(PriceType.High, i);
    }

    public Low (i): any {
        return this.CurrentData.GetPrice(PriceType.Low, i);
    }
}
