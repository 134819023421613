// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { TerceraFileUploaderTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';
import { ThemeManager } from '../misc/ThemeManager';
import $ from 'jquery';

export class TerceraFileUploader extends ContainerControl {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.on('selectFileClicked', this.onSelectFileClicked);
        this.on('newFileSelected', this.onNewFileSelected);
        void this.set('btnImg', ThemeManager.getImageFullUrl('j_algostudio/16x16_icon_browse.png'));
    }

    public onSelectFileClicked (): void {
        $(this.find('input')).click();
    }

    public onNewFileSelected (e): void {
        const file = e.original.target.files[0];
        if (!file) {
            void this.set('file', null);
            return;
        }

        if (!file.type.match(this.get('filter'))) {
            void this.set('file', null);
            alert(Resources.getResource('Wrong file type'));
            return;
        }

        void this.set('file', file);
    }
}

ContainerControl.extendWith(TerceraFileUploader, {
    data: function () {
        return {
            width: 180,
            height: 20,
            btnImg: '',
            filter: '',
            file: null
        };
    },
    computed: {
        message: function () {
            const file = this.get('file');
            return file ? file.name : Resources.getResource('Select a file');
        }
    },
    template: TerceraFileUploaderTemplate
});
