// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ConfirmationTypesEnum } from '../../Trading/ConfirmationTypesEnum';

export class ConfirmationSett {
    public GeneralSettings: any;

    public ConfirmOrderPlacement = true;
    public ConfirmOrderCancellation = true;
    public ConfirmOrderPositionsModification = true;
    public ConfirmPositionClosing = true;
    public ConfirmReversePosition = true;
    public ConfirmAlertRemove = true;
    public OpenDialTicketsOnWorkspace = false;
    public ShowMarginDealTicketOnWorkspace = true;
    public ShowDialTicketsInApplicationTray = true;
    public ShowInformationMessageAboutSubscribeUnsubscribe = true;

    public ConfirmPlaceAlert = false;
    public ConfirmCancelAlert = true;

    public ConfirmSubscriptions = true; //  Регулирует отображение скринов при подписке (скрин Subscription)/отказе от подписки (скрин Unsubscribe) на продукт. #104359
    public PositionSizeCalculatorInfoWindow = true;

    public WMConfrimOrderSending = true;
    public WMConfirmOrderModification = true;
    public WMConfrimOrderCancel = true;
    public WMConfirmOrderExecuting = true;
    public WMConfirmPositionModification = true;
    public WMConfirmPositionClosing = true;

    constructor (generalSetting) {
        this.GeneralSettings = generalSetting;
    }

    public useConfirmation (type: ConfirmationTypesEnum): boolean {
        const confirmTypes = ConfirmationTypesEnum;
        switch (type) {
        case confirmTypes.OrderCancel:
            return this.ConfirmOrderCancellation;
        case confirmTypes.OrderPlace:
            return this.ConfirmOrderPlacement;
        case confirmTypes.PositionClose:
            return this.ConfirmPositionClosing;
        case confirmTypes.Modify:
            return this.ConfirmOrderPositionsModification;
        case confirmTypes.DealTickets:
            return this.OpenDialTicketsOnWorkspace;
        case confirmTypes.ShowMarginDealTicketsOnWorkspace:
            return this.ShowMarginDealTicketOnWorkspace;
        case confirmTypes.OrderPlaceAlert:
            return this.ConfirmPlaceAlert;
        case confirmTypes.AlertCancel:
            return this.ConfirmCancelAlert;
        case confirmTypes.PositionSizeCalculatorInfoWindow:
            return this.PositionSizeCalculatorInfoWindow;
        case confirmTypes.SubscriptionsAndUnsubscriptions:
            return this.ConfirmSubscriptions;
        }

        throw new Error('Not supported');
    }

    public updateConfirmation (operationType, showConfirmation): void {
        if (operationType === undefined || showConfirmation === undefined) {
            return;
        }

        let saveSettings = false;

        switch (operationType) {
        case ConfirmationTypesEnum.OrderCancel:
            if (this.ConfirmOrderCancellation !== showConfirmation) {
                this.ConfirmOrderCancellation = showConfirmation;
                saveSettings = true;
            }
            break;
        case ConfirmationTypesEnum.OrderPlace:
            if (this.ConfirmOrderPlacement !== showConfirmation) {
                this.ConfirmOrderPlacement = showConfirmation;
                saveSettings = true;
            }
            break;
        case ConfirmationTypesEnum.OrderPlaceAlert:
            if (this.ConfirmPlaceAlert !== showConfirmation) {
                this.ConfirmPlaceAlert = showConfirmation;
                saveSettings = true;
            }
            break;
        case ConfirmationTypesEnum.AlertCancel:
            if (this.ConfirmCancelAlert !== showConfirmation) {
                this.ConfirmCancelAlert = showConfirmation;
                saveSettings = true;
            }
            break;
        case ConfirmationTypesEnum.PositionClose:
            if (this.ConfirmPositionClosing !== showConfirmation) {
                this.ConfirmPositionClosing = showConfirmation;
                saveSettings = true;
            }
            break;
        case ConfirmationTypesEnum.PositionReverse:
            if (this.ConfirmReversePosition !== showConfirmation) {
                this.ConfirmReversePosition = showConfirmation;
                saveSettings = true;
            }
            break;
        case ConfirmationTypesEnum.Modify:
            if (this.ConfirmOrderPositionsModification !== showConfirmation) {
                this.ConfirmOrderPositionsModification = showConfirmation;
                saveSettings = true;
            }
            break;
        case ConfirmationTypesEnum.DealTickets:
            if (this.OpenDialTicketsOnWorkspace !== showConfirmation) {
                this.OpenDialTicketsOnWorkspace = showConfirmation;
                saveSettings = true;
            }
            break;
        case ConfirmationTypesEnum.ShowMarginDealTicketsOnWorkspace:
            if (this.ShowMarginDealTicketOnWorkspace !== showConfirmation) {
                this.ShowMarginDealTicketOnWorkspace = showConfirmation;
                saveSettings = true;
            }
            break;
        case ConfirmationTypesEnum.PositionSizeCalculatorInfoWindow:
            if (this.PositionSizeCalculatorInfoWindow !== showConfirmation) {
                this.PositionSizeCalculatorInfoWindow = showConfirmation;
                saveSettings = true;
            }
            break;
        case ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions:
            if (this.ConfirmSubscriptions !== showConfirmation) {
                this.ConfirmSubscriptions = showConfirmation;
                saveSettings = true;
            }
            break;
        }

        if (saveSettings) {
            this.GeneralSettings.SettingsChangedRaise();
            this.GeneralSettings.SettingNeedSaveRaise();
        }
    }
}
