// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from './Geometry';

declare global {
    interface CanvasRenderingContext2D {
        DrawLine: (pen, X1, Y1, X2, Y2) => void
        DrawLines: (pen, points) => void
        DrawRect: (pen, X, Y, Width, Height) => void
        FillRect: (fillStyle: IGraphicsPrimitive, X: number, Y: number, Width: number, Height: number) => void
        FillRectWithRect: (fillStyle: IGraphicsPrimitive, rect: Rectangle) => void
        DrawPolygon: (pen, points) => void
        FillPolygon: (fillStyle, points) => void
        DrawString: (text, font, brush, X, Y, textAlign?, textBaseline?) => void
        DrawStringInRect: (text, font, brush, rectangle, isTrim) => void
        DrawEllipse: (pen, x, y, w, h) => void
        FillEllipse: (brush, x, y, w, h) => void
        DrawArc: (pen, centerX, centerY, radius, startAngleRad, endAngleRad) => void
        DrawCurve: (pen, points) => void
        DrawFilledCurve: (fillStyle, points) => void
        DrawClosedCurve: (pen, points) => void
        FillClosedCurve: (fillStyle, points) => void
        RotateByAngle: (angle) => void
        DrawRotatedImage: (image, x, y, angle) => void
        GetTextWidth: (text, font) => number
        GetTextSize: (text, font, isActualBounding) => { width: number, height: number }
        TruncateWithEllipsis: (x: number, y: number, width: number, height: number, xPadding: number, yPadding: number, cellBackColor) => void
        ToImage: () => HTMLImageElement
        Clear: () => void
        RenderButton: (brush, pen, rect) => void
        getPixelColor: (x, y) => void
        DrawPolyLine: (pen, polyLine) => void
        DrawPolyRect: (fillStyle, polyRect, penStyle) => void
        DrawPolyText: (polyText, font, brush, textAlign, textBaseline) => void
        RoundRectangle: (rect, rad, br, borderPen, showOutLine, filter) => void
        RoundRectangleGradient: (rect, rad, /* text, */ col1, col2, /* textColor, */ borderPen, showOutLine, /* sf, f, */ leftScip, rightScip) => void
        RoundRectangleFilter: (rect, rad, /* text, */ br, /* textColor, */ borderPen, showOutLine, /* sf, f, */ leftScip, rightScip) => void
        SimpleRoundRectangle: (X, Y, Width, Height, borderRadius, fillStyle, strokeStyle) => void
    }
 }

CanvasRenderingContext2D.prototype.DrawLine = function (pen, X1, Y1, X2, Y2) {
    this.beginPath();
    pen.applySettings(this);

    const crispCoef = this.lineWidth % 2 / 2;
    if (crispCoef) this.translate(crispCoef, crispCoef);

    this.moveTo(Math.round(X1), Math.round(Y1));
    this.lineTo(Math.round(X2), Math.round(Y2));
    this.stroke();

    if (crispCoef) this.translate(-crispCoef, -crispCoef);
};

CanvasRenderingContext2D.prototype.DrawLines = function (pen, points) {
    if (!points) return;

    const len = points.length;
    if (len < 2) return;

    this.beginPath();
    pen.applySettings(this);

    const crispCoef = this.lineWidth % 2 / 2;
    if (crispCoef) this.translate(crispCoef, crispCoef);

    const p0 = points[0];
    this.moveTo(Math.round(p0.X), Math.round(p0.Y));

    for (let i = 1; i < len; i++) {
        const p = points[i];
        this.lineTo(Math.round(p.X), Math.round(p.Y));
    }

    this.stroke();

    if (crispCoef) this.translate(-crispCoef, -crispCoef);
};

CanvasRenderingContext2D.prototype.DrawRect = function (pen, X, Y, Width, Height) {
    pen.applySettings(this);

    const crispCoef = this.lineWidth % 2 / 2;
    if (crispCoef) this.translate(crispCoef, crispCoef);

    this.strokeRect(
        Math.round(X),
        Math.round(Y),
        Math.round(Width),
        Math.round(Height));

    if (crispCoef) this.translate(-crispCoef, -crispCoef);
};

CanvasRenderingContext2D.prototype.FillRect = function (
    fillStyle: IGraphicsPrimitive,
    X: number,
    Y: number,
    Width: number,
    Height: number) {
    fillStyle.applySettings(this);
    this.fillRect(
        Math.round(X),
        Math.round(Y),
        Math.round(Width),
        Math.round(Height));
};

CanvasRenderingContext2D.prototype.FillRectWithRect = function (
    fillStyle: IGraphicsPrimitive,
    rect: Rectangle) {
    this.FillRect(fillStyle, rect.X, rect.Y, rect.Width, rect.Height);
};

CanvasRenderingContext2D.prototype.DrawPolygon = function (pen, points) {
    if (!points) return;

    const len = points.length;
    if (!len || len < 3) return;

    this.beginPath();
    pen.applySettings(this);

    const pStart = points[0];
    this.moveTo(pStart.X, pStart.Y);

    for (let i = 1; i < len; i++) {
        const p = points[i];
        this.lineTo(p.X, p.Y);
    }

    this.closePath();
    this.stroke();
};

CanvasRenderingContext2D.prototype.FillPolygon = function (fillStyle, points) {
    if (!points) return;

    const len = points.length;
    if (!len || len < 3) return;

    this.beginPath();
    fillStyle.applySettings(this);

    const pStart = points[0];
    this.moveTo(pStart.X, pStart.Y);

    for (let i = 1; i < len; i++) {
        const p = points[i];
        this.lineTo(p.X, p.Y);
    }

    this.closePath();
    this.fill();
};

CanvasRenderingContext2D.prototype.DrawString = function (text, font, brush, X, Y, textAlign?, textBaseline?) {
    this.textAlign = textAlign || 'left';
    this.textBaseline = textBaseline || 'top';

    font.applySettings(this);
    brush.applySettings(this);

    this.fillText(text, Math.floor(X), Math.floor(Y));
};

CanvasRenderingContext2D.prototype.DrawStringInRect = function (text, font, brush, rectangle, isTrim: boolean) {
    let measuredText = text;
    if (isTrim && this.GetTextSize(measuredText, font, true).width > rectangle.Width) {
        const ellipsis = '...';
        while (measuredText !== '' && this.GetTextSize(measuredText + ellipsis, font, true).width > rectangle.Width) {
            measuredText = measuredText.slice(0, -1);
        }
        measuredText += ellipsis;
    }

    this.textAlign = 'center';
    this.textBaseline = 'middle';

    font.applySettings(this);
    brush.applySettings(this);
    this.fillText(measuredText, rectangle.X + rectangle.Width / 2, rectangle.Y + rectangle.Height / 2);
};

CanvasRenderingContext2D.prototype.DrawEllipse = function (pen, x, y, w, h) {
    const kappa = 0.5522848;
    const ox = (w / 2) * kappa; // control point offset horizontal
    const oy = (h / 2) * kappa; // control point offset vertical
    const xe = x + w; // x-end
    const ye = y + h; // y-end
    const xm = x + w / 2; // x-middle
    const ym = y + h / 2; // y-middle

    this.beginPath();
    pen.applySettings(this);
    this.moveTo(x, ym);
    this.bezierCurveTo(x, ym - oy, xm - ox, y, xm, y);
    this.bezierCurveTo(xm + ox, y, xe, ym - oy, xe, ym);
    this.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
    this.bezierCurveTo(xm - ox, ye, x, ym + oy, x, ym);
    // this.closePath(); // not used correctly, see comments (use to close off open path)
    this.stroke();
};

CanvasRenderingContext2D.prototype.FillEllipse = function (brush, x, y, w, h) {
    const kappa = 0.5522848;
    const ox = (w / 2) * kappa; // control point offset horizontal
    const oy = (h / 2) * kappa; // control point offset vertical
    const xe = x + w; // x-end
    const ye = y + h; // y-end
    const xm = x + w / 2; // x-middle
    const ym = y + h / 2; // y-middle

    this.beginPath();
    brush.applySettings(this);
    this.moveTo(x, ym);
    this.bezierCurveTo(x, ym - oy, xm - ox, y, xm, y);
    this.bezierCurveTo(xm + ox, y, xe, ym - oy, xe, ym);
    this.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
    this.bezierCurveTo(xm - ox, ye, x, ym + oy, x, ym);
    // this.closePath(); // not used correctly, see comments (use to close off open path)
    this.fill();
    this.stroke();
};

CanvasRenderingContext2D.prototype.DrawArc = function (pen, centerX, centerY, radius, startAngleRad, endAngleRad) {
    this.beginPath();
    pen.applySettings(this);

    const crispCoef = this.lineWidth % 2 / 2;
    if (crispCoef) this.translate(crispCoef, crispCoef);

    this.arc(Math.round(centerX), Math.round(centerY), Math.round(radius), startAngleRad, endAngleRad);
    this.stroke();

    if (crispCoef) this.translate(-crispCoef, -crispCoef);
};

CanvasRenderingContext2D.prototype.DrawCurve = function (pen, points) {
    if (!points) return;

    const len = points.length;
    if (!len || len < 3) return;

    this.beginPath();
    pen.applySettings(this);

    const convPoints = [];
    for (let i = 0; i < len; i++) {
        const point = points[i];
        convPoints[i * 2] = point.X;
        convPoints[i * 2 + 1] = point.Y;
    }

    this.curve(convPoints);
    this.stroke();
};

CanvasRenderingContext2D.prototype.DrawFilledCurve = function (fillStyle, points) {
    if (!points) return;

    const len = points.length;
    if (!len || len < 3) return;

    this.beginPath();
    fillStyle.applySettings(this);

    const convPoints = [];
    for (let i = 0; i < len - 2; i++) {
        const point = points[i];
        convPoints[i * 2] = point.X;
        convPoints[i * 2 + 1] = point.Y;
    }
    var pStart = points[len - 2];
    this.moveTo(pStart.X, pStart.Y);
    var pStart = points[len - 1];
    this.lineTo(pStart.X, pStart.Y);
    this.curve(convPoints);

    this.fill();
};

CanvasRenderingContext2D.prototype.DrawClosedCurve = function (pen, points) {
    if (!points) return;

    const len = points.length;
    if (!len || len < 3) return;

    this.beginPath();
    pen.applySettings(this);

    const convPoints = [];
    for (let i = 0; i < len; i++) {
        const point = points[i];
        convPoints[i * 2] = point.X;
        convPoints[i * 2 + 1] = point.Y;
    }

    this.curve(convPoints, 0.5, 25, true);
    this.stroke();
};

CanvasRenderingContext2D.prototype.FillClosedCurve = function (fillStyle, points) {
    if (!points) return;

    const len = points.length;
    if (!len || len < 3) return;

    this.beginPath();
    fillStyle.applySettings(this);

    const convPoints = [];
    for (let i = 0; i < len; i++) {
        const point = points[i];
        convPoints[i * 2] = point.X;
        convPoints[i * 2 + 1] = point.Y;
    }

    this.curve(convPoints, 0.5, 25, true);
    this.fill();
};

export const TO_RADIANS = Math.PI / 180;

CanvasRenderingContext2D.prototype.RotateByAngle = function (angle) {
    this.rotate(angle * TO_RADIANS);
};

CanvasRenderingContext2D.prototype.DrawRotatedImage = function (image, x, y, angle) {
    if (!image) return;

    this.save();

    this.translate(x, y);
    this.rotate(angle * TO_RADIANS);

    this.drawImage(image, -(image.width / 2), -(image.height / 2));

    this.restore();
};

CanvasRenderingContext2D.prototype.GetTextWidth = function (text, font) {
    this.save();
    font.applySettings(this);
    const w = this.measureText(text).width;
    this.restore();
    return w;
};

CanvasRenderingContext2D.prototype.GetTextSize = function (text: string, font: Font, isActualBounding: boolean = true): { width: number, height: number } {
    this.save();
    font.applySettings(this);
    const textMetics = this.measureText(text);
    const width = Math.round(textMetics.width);
    const height = isActualBounding
        ? Math.round(textMetics.actualBoundingBoxAscent + textMetics.actualBoundingBoxDescent)
        : Math.round(textMetics.fontBoundingBoxAscent + textMetics.fontBoundingBoxDescent);
    this.restore();
    return { width, height };
};

const ELLIPSIS = '…'; // '...';

CanvasRenderingContext2D.prototype.TruncateWithEllipsis = function (x: number, y: number, width: number, height: number, xPadding: number, yPadding: number, cellBackColor = null): void {
    const ellipsisWidth = this.GetTextWidth(ELLIPSIS, Font.parseFontString(this.font));

    const ellipsisX = x + width - ellipsisWidth;
    const ellipsisY = y + yPadding + 3;

    const backup = this.fillStyle;
    if (cellBackColor != null) { this.fillStyle = cellBackColor; }
    this.fillRect(ellipsisX, y, ellipsisWidth, height);
    if (backup != null) { this.fillStyle = backup; }

    this.fillText(ELLIPSIS, ellipsisX, ellipsisY);
};

CanvasRenderingContext2D.prototype.ToImage = function () {
    const image = new Image();
    image.src = this.canvas.toDataURL('image/png');
    return image;
};

CanvasRenderingContext2D.prototype.Clear = function () {
    this.clearRect(0, 0, this.width, this.height);
};

CanvasRenderingContext2D.prototype.RenderButton = function (brush, pen, rect) {
    const cpy_rect = rect.copy();
    cpy_rect.Inflate(-2, -2);
    this.RoundRectangle(cpy_rect, 2, brush, pen, false, RectangleEdgeFilter.All);
};

CanvasRenderingContext2D.prototype.getPixelColor = function (x, y) {
    const data = this.getImageData(x, y, 1, 1).data;
    return Color.CreateColor(data[0], data[1], data[2], data[3] / 255);
};

CanvasRenderingContext2D.prototype.DrawPolyLine = function (pen, polyLine) {
    if (polyLine.lines.length === 0) { return; }

    this.beginPath();
    pen.applySettings(this);

    const crispCoef = this.lineWidth % 2 / 2;
    if (crispCoef) this.translate(crispCoef, crispCoef);

    const lines = polyLine.lines;
    let i;
    const len = lines.length;
    for (i = 0; i < len; i++) {
        const curLine = lines[i];
        this.moveTo(Math.round(curLine.X1), Math.round(curLine.Y1));
        this.lineTo(Math.round(curLine.X2), Math.round(curLine.Y2));
    }

    this.stroke();

    if (crispCoef) this.translate(-crispCoef, -crispCoef);
};

CanvasRenderingContext2D.prototype.DrawPolyRect = function (fillStyle, polyRect, penStyle) {
    if (polyRect.rects.length === 0) { return; }

    fillStyle.applySettings(this);

    const rects = polyRect.rects;
    let i;
    const len = rects.length;
    for (i = 0; i < len; i++) {
        var curRect = rects[i];
        this.fillRect(
            Math.round(curRect.X),
            Math.round(curRect.Y),
            Math.round(curRect.Width),
            Math.round(curRect.Height));
        // if (usePen)
        //    this.strokeRect(
        //        Math.round(curRect.X),
        //        Math.round(curRect.Y),
        //        Math.round(curRect.Width-1),
        //        Math.round(curRect.Height));
    }

    const usePen = penStyle !== undefined;
    if (usePen) {
        penStyle.applySettings(this);
        var crispCoef = this.lineWidth % 2 / 2;
        if (crispCoef) this.translate(crispCoef, crispCoef);
    }

    for (i = 0; i < len; i++) {
        var curRect = rects[i];
        // this.fillRect(
        //    Math.round(curRect.X),
        //    Math.round(curRect.Y),
        //    Math.round(curRect.Width),
        //    Math.round(curRect.Height));
        if (usePen) {
            this.strokeRect(
                Math.round(curRect.X),
                Math.round(curRect.Y),
                Math.round(curRect.Width - 1),
                Math.round(curRect.Height));
        }
    }
    if (usePen) { if (crispCoef) this.translate(-crispCoef, -crispCoef); }
};

CanvasRenderingContext2D.prototype.DrawPolyText = function (polyText, font, brush, textAlign, textBaseline) {
    if (polyText.textArray.length === 0) { return; }

    this.textAlign = textAlign || 'left';
    this.textBaseline = textBaseline || 'top';

    font.applySettings(this);
    brush.applySettings(this);

    const textItems = polyText.textArray;
    let i;
    const len = textItems.length;
    for (i = 0; i < len; i++) {
        const curTextItem = textItems[i];
        this.fillText(curTextItem.Text, Math.floor(curTextItem.X), Math.floor(curTextItem.Y));
    }
};

// #region RoundRectangle Methods.

CanvasRenderingContext2D.prototype.RoundRectangle = function (rect, rad, br, borderPen, showOutLine, filter) {
    const RW = rect.Width;
    const RH = rect.Height;

    if (!RW || !RH) { return; }

    const RX = rect.X;
    const RY = rect.Y;
    const RR = RX + RW;
    const RB = RY + RH;

    const gr = this;
    gr.beginPath();

    if (br) { br.applySettings(gr); }
    if (borderPen) { borderPen.applySettings(gr); }

    const crispCoef = gr.lineWidth % 2 / 2;
    if (crispCoef) gr.translate(crispCoef, crispCoef);

    const diameter = rad * 2;
    const arc = new Rectangle(RX, RY, diameter, diameter);

    const PI = Math.PI;
    const halfPI = 0.5 * PI;
    const oneAndHalfPI = 1.5 * PI;

    const leftArcX = Math.round(RX + rad);
    const topArcY = Math.round(RY + rad);
    const rightArcX = Math.round(RR - rad);
    const bottomArcY = Math.round(RB - rad);

    if (RectangleEdgeFilter.IsTopLeft(filter)) {
        gr.arc(leftArcX, topArcY, rad, PI, oneAndHalfPI);
    } else {
        var arcX = arc.X;
        var arcY = arc.Y;

        // gr.moveTo(arcX, arcY + arc.Height);
        gr.lineTo(arcX, arcY);
        gr.lineTo(arcX + arc.Width, arcY);
    }

    arc.X = RR - diameter;

    if (RectangleEdgeFilter.IsTopRight(filter)) {
        gr.arc(rightArcX, topArcY, rad, oneAndHalfPI, 0);
    } else {
        var arcX = arc.X;
        var arcY = arc.Y;
        var arcR = arcX + arc.Width;

        // gr.moveTo(arcX, arcY);
        gr.lineTo(arcR, arcY);
        gr.lineTo(arcR, arcY + arc.Height);
    }

    arc.Y = RB - diameter;

    if (RectangleEdgeFilter.IsBottomRight(filter)) {
        gr.arc(rightArcX, bottomArcY, rad, 0, halfPI);
    } else {
        var arcX = arc.X;
        var arcY = arc.Y;
        var arcR = arcX + arc.Width;
        var arcB = arcY + arc.Height;

        // gr.moveTo(arcR, arcY);
        gr.lineTo(arcR, arcB);
        gr.lineTo(arcX, arcB);
    }

    arc.X = RX;

    if (RectangleEdgeFilter.IsBottomLeft(filter)) {
        gr.arc(leftArcX, bottomArcY, rad, halfPI, PI);
    } else {
        var arcX = arc.X;
        var arcY = arc.Y;
        var arcB = arcY + arc.Height;

        // gr.moveTo(arcX + arc.Width, arcB);
        gr.lineTo(arcX, arcB);
        gr.lineTo(arcX, arcY);
    }

    gr.closePath();
    gr.fill();
    gr.stroke();

    if (crispCoef) gr.translate(-crispCoef, -crispCoef);

    //
    // Наводка белым цветом
    //
    // TODO REMOVE not USE
    // if (showOutLine)
    // {
    //     var wPen = Pens.White;

    //     gr.DrawLine(wPen, RX + rad / 2, RB - 1, RR - rad / 2, RB - 1);
    //     gr.DrawLine(wPen, RR - 1, RY + rad / 2, RR - 1, RB - rad / 2);
    //     gr.DrawArc(wPen, RR - rad - 1, RB - rad - 1, rad, 0, endingAngleRad);
    // }
};

CanvasRenderingContext2D.prototype.RoundRectangleGradient = function (rect, rad, /* text, */ col1, col2, /* textColor, */ borderPen, showOutLine, /* sf, f, */ leftScip, rightScip) {
    const rectX = rect.X;

    const br = new LinearGradientBrush(
        rectX, 0,
        rectX + rect.Width, 0,
        col1, col2);

    this.RoundRectangleFilter(rect, rad, /* text, */ br, /* textColor, */ borderPen, showOutLine, /* sf, f, */ leftScip, rightScip);
};

CanvasRenderingContext2D.prototype.RoundRectangleFilter = function (rect, rad, /* text, */ br, /* textColor, */ borderPen, showOutLine, /* sf, f, */ leftScip, rightScip) {
    let filter = RectangleEdgeFilter.All;
    if (leftScip) filter = RectangleEdgeFilter.All & (RectangleEdgeFilter.BottomRight | RectangleEdgeFilter.TopRight);
    if (rightScip) filter = RectangleEdgeFilter.All & (RectangleEdgeFilter.BottomLeft | RectangleEdgeFilter.TopLeft);

    this.RoundRectangle(rect, rad, /* text, */ br, /* textColor, */ borderPen, showOutLine, /* sf, f, */ filter);
};

CanvasRenderingContext2D.prototype.SimpleRoundRectangle = function (X, Y, Width, Height, borderRadius, fillStyle, strokeStyle) {
    if (fillStyle) { fillStyle.applySettings(this); };
    if (strokeStyle) { strokeStyle.applySettings(this); };

    this.beginPath();

    this.roundRect(Math.round(X), Math.round(Y), Math.round(Width), Math.round(Height), borderRadius); // borderRadius - a number or list specifying the radii of the circular arc to be used for the corners of the rectangle. The number and order of the radii function in the same way as the border-radius CSS property when width and height are positive

    this.closePath();

    if (fillStyle) { this.fill(); };
    if (strokeStyle) { this.stroke(); };
};

// #region Graphics primitives.

interface IGraphicsPrimitive {
    applySettings: (ctx: CanvasRenderingContext2D) => void
}

// ------------

export class LinearGradientBrush implements IGraphicsPrimitive {
    public x1: number;
    public y1: number;
    public x2: number;
    public y2: number;
    public ColorStart: string;
    public ColorEnd: string;

    constructor (x1: number, y1: number, x2: number, y2: number, colorStart: string, colorEnd: string) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;

        this.ColorStart = colorStart;
        this.ColorEnd = colorEnd;
    }

    public applySettings (ctx: CanvasRenderingContext2D): void {
        const grd = ctx.createLinearGradient(this.x1, this.y1, this.x2, this.y2);

        grd.addColorStop(0, this.ColorStart);
        grd.addColorStop(1, this.ColorEnd);

        ctx.fillStyle = grd;
    }
}

// ------------

export class SolidBrush implements IGraphicsPrimitive {
    public Color: string;

    constructor (color) {
        this.Color = color;
    }

    public applySettings (ctx: CanvasRenderingContext2D): void {
        ctx.fillStyle = this.Color;
    }
}

// ------------

export class Pen implements IGraphicsPrimitive {
    Color: any;
    Width: any;
    DashStyle: any;
    LineCap: any;

    constructor (color,
        width = undefined,
        dashStyle = undefined,
        lineCap = undefined) {
        this.Color = color;
        this.Width = width || 1;
        this.DashStyle = dashStyle || [];
        this.LineCap = lineCap || '';
    }

    applySettings (ctx: CanvasRenderingContext2D): void {
        ctx.strokeStyle = this.Color.Color || this.Color;
        ctx.lineWidth = this.Width;
        ctx.setLineDash(this.DashStyle);
        ctx.lineCap = this.LineCap;
    };

    copy (): Pen {
        return new Pen(
            this.Color,
            this.Width,
            this.DashStyle.slice(),
            '');
    };

    // #region Process Pen

    static csSimpleChart = 0; // сплошная линия
    static csDotChart = 1; // точка (узловая)
    static csDotLineChart = 2; // точка на непрерывной линии
    static csIsoDotChart = 3; // точка (свободная)
    static csShapedChart = 4; // штриховая (линия-пробел-линия)
    static csShapedDotChart = 5; // штрих-пунктирная (линия-пробел-точка-пробел-линия)
    static csShapedDoubleDotChart = 6; // штрих-пунктирная (линия-пробел-точка-пробел-точка-пробел-линия)
    static csHistogrammChart = 7; // график - гистограмма
    static csArrowChart = 8; // график - стрелки
    static csHorisontalLineChart = 9; // график - горизонтальная линия в ширину бара
    static csLadder = 10; // ступеньки - соединяем горизонтальные линии в ширину бара

    // Создать Pen с нужным стилем
    static ProcessPen (pen, chstyle) {
        if (!pen) return null;

        switch (chstyle) {
        // line
        case Pen.csSimpleChart:
            // линия
            pen.DashStyle = [];
            break;
            // Dots
        case Pen.csDotChart:
            pen.DashStyle = [1, 1];
            break;
            // Dotted line
        case Pen.csDotLineChart:
            pen.DashStyle = [2, 2, 10, 2];
            break;
            // Iso dot
        case Pen.csIsoDotChart:
            pen.DashStyle = [2, 4];
            break;
            // Shaped line
        case Pen.csShapedChart:
            pen.DashStyle = [4, 2];
            break;
            // Dotted line
        case Pen.csShapedDotChart:
            pen.DashStyle = [2, 4, 7, 4];
            break;
            // Dash
        case Pen.csShapedDoubleDotChart:
            pen.DashStyle = [4, 5];
            break;
        }

        const width = pen.Width;
        const dashStyleArr = pen.DashStyle;
        const len = dashStyleArr.length;
        for (let i = 0; i < len; i++) {
            dashStyleArr[i] *= width;
        }
        return pen;
    }
}

// ------------

export class Font implements IGraphicsPrimitive {
    Height: any;
    Family: any;
    FontWeight: any;

    constructor (height, family, fontWeight = undefined) {
        this.Height = height;
        this.Family = family;
        this.FontWeight = fontWeight;
    }

    toString () {
        let str = this.Height + 'px ' + this.Family;

        if (this.FontWeight) { str = this.FontWeight + ' ' + str; }

        return str;
    }

    copy () {
        return new Font(this.Height, this.Family, this.FontWeight);
    }

    applySettings (ctx: CanvasRenderingContext2D) {
        ctx.font = this.toString();
    }

    static parseFontString (font) {
        const splits = font.split(' ');
        let Font_name = '';
        for (let i = 1; i < splits.length; i++) { Font_name += splits[i] + ' '; }
        return new Font(parseInt(splits[0]), Font_name.trim(), undefined);
    }
}

export class PolyLine {
    lines = [];
}

export class PolyRect {
    rects = [];
}

export class Line {
    X1: number;
    Y1: number;
    X2: number;
    Y2: number;

    constructor (x1, y1, x2, y2) {
        this.X1 = x1;
        this.Y1 = y1;
        this.X2 = x2;
        this.Y2 = y2;
    }
}

export class PolyText {
    textArray = [];
}

export class PolyTextItem {
    Text: string;
    X: number;
    Y: number;

    constructor (text, x, y) {
        this.Text = text;
        this.X = x;
        this.Y = y;
    }
}
// #endregion

// ------------

// #region Graphics

export class Graphics {
    static calculationCanvas: any;
    static additionalCanvas: any;
    // For creating images etc.
    public static getAdditionalCanvas () {
        if (!Graphics.additionalCanvas) {
            const canvas = document.createElement('canvas');
            Graphics.additionalCanvas = canvas;
        }

        return Graphics.additionalCanvas;
    };

    public static getCalculationCanvas () {
        if (!Graphics.calculationCanvas) {
            const canvas = document.createElement('canvas');
            Graphics.calculationCanvas = canvas;
        }

        return Graphics.calculationCanvas;
    }

    static getPixelColorFromImage (image, x, y) {
        if (!image) return '';

        const canvas = Graphics.getAdditionalCanvas();
        canvas.width = image.width;
        canvas.height = image.height;

        const gr = canvas.getContext('2d');
        gr.drawImage(image, 0, 0);

        return gr.getPixelColor(x, y);
    }

    static makeGrayScale (src_image) {
        if (!src_image) return null;

        const canvas = Graphics.getAdditionalCanvas();
        canvas.width = src_image.width;
        canvas.height = src_image.height;

        const gr = canvas.getContext('2d');
        gr.drawImage(src_image, 0, 0);

        const imageData = gr.getImageData(0, 0, src_image.width, src_image.height);
        const data = imageData.data;
        const len = data.length;
        for (let i = 0; i < len; i += 4) {
            const brightness = 0.34 * data[i] + 0.5 * data[i + 1] + 0.16 * data[i + 2];
            // red
            data[i] = brightness;
            // green
            data[i + 1] = brightness;
            // blue
            data[i + 2] = brightness;
        }
        // overwrite original image
        gr.putImageData(imageData, 0, 0);

        return gr.ToImage();
    }

    static createImageWithAlpha (image, alpha) {
        if (!image) return null;

        const canvas = Graphics.getAdditionalCanvas();
        const gr = canvas.getContext('2d');

        const oldAlpha = gr.globalAlpha;

        canvas.width = image.width;
        canvas.height = image.height;

        gr.globalAlpha = alpha;

        gr.drawImage(image, 0, 0);

        const alphaImage = gr.ToImage();

        gr.globalAlpha = oldAlpha;

        return alphaImage;
    }

    static GetContrastColor (color, twoColors = true) {
        const channels = Color.getColorChannels(color);

        let r = channels.r;
        let g = channels.g;
        let b = channels.b;

        // Counting the perceptive luminance - human eye favors green color...
        const luminance = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        const coef = 0.33;

        if (luminance < 0.5) {
            // bright colors - black font
            if (twoColors) return 'rgb(0, 0, 0)';

            r = Graphics.GetContrastValue(r, 1 - coef);
            g = Graphics.GetContrastValue(g, 1 - coef);
            b = Graphics.GetContrastValue(b, 1 - coef);
        } else {
            // dark colors - white font
            if (twoColors) return 'rgb(255, 255, 255)';

            r = Graphics.GetContrastValue(r, 1 + coef);
            g = Graphics.GetContrastValue(g, 1 + coef);
            b = Graphics.GetContrastValue(b, 1 + coef);
        }

        return Color.CreateColor(Math.floor(r), Math.floor(g), Math.floor(b));
    };

    static GetContrastValue (i, coef) {
        // if (i < min) i = minValue;
        // else if (i > max) i = maxValue;
        // else i *= coef;

        return i;
    };
}

// #endregion

// ------------

// #region RectangleEdgeFilter

class _RectangleEdgeFilter {
    None = 0;
    TopLeft = 1;
    TopRight = 2;
    BottomLeft = 4;
    BottomRight = 8;
    readonly All: number;

    constructor () {
        this.All = this.TopLeft | this.TopRight | this.BottomLeft | this.BottomRight;
    }

    IsTopLeft (filter: number) {
        return (this.TopLeft & filter) === this.TopLeft;
    }

    IsTopRight (filter) {
        return (this.TopRight & filter) === this.TopRight;
    };

    IsBottomRight (filter) {
        return (this.BottomRight & filter) === this.BottomRight;
    };

    IsBottomLeft (filter) {
        return (this.BottomLeft & filter) === this.BottomLeft;
    };
}

export const RectangleEdgeFilter = new _RectangleEdgeFilter();

// #endregion

// ------------

// #region Color

export class Rgba {
    r: number;
    g: number;
    b: number;
    a: number | undefined;

    // constructor (r, g, b, a) {
    //     this.r = r;
    //     this.g = g;
    //     this.b = b;
    //     this.a = a;
    // }
}

export class _Color {
    // alpha: 0 - 255. For compatibility with C#.
// colorString: rgb/rgba formats.
    FromArgb (alpha, colorString) {
        const channels = Color.getColorChannels(colorString);

        const a = alpha / 255;

        return 'rgba(' +
            channels.r + ',' +
            channels.g + ',' +
            channels.b + ',' +
            a + ')';
    };

    FromHexToArgb (alpha, colorString) {
        let _colorString;
        if (this.IsHexColor(colorString)) {
            _colorString = this.HexToRgb(colorString);
        } else {
            _colorString = this.getColorChannels(colorString); ;
        }

        const a = alpha / 255;

        return 'rgba(' +
            _colorString.r + ',' +
            _colorString.g + ',' +
            _colorString.b + ',' +
            a + ')';
    };

    IsHexColor (hex) {
        return /^#([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$/i.test(hex);
    };

    HexToRgb (hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            }
            : null;
    };

    rgbaToHex (rgbaString: string): string {
        const rgbaArray = rgbaString.substring(5, rgbaString.length - 1).split(',').map(Number);
        const [r, g, b, a] = rgbaArray;
        return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}${Math.round(a * 255).toString(16).padStart(2, '0')}`;
    }

    // For rgb/rgba schemes only.
    // minAlpha: 0 - 1.
    GetLighterColor (min, max, value, colorString, minAlpha) {
        const overall = max - min;
        const correctionFactor = overall === 0 ? 1 : (value - min) / overall;

        const channels = this.getColorChannels(colorString);
        const srcA = channels.hasOwnProperty('a') ? channels.a : 1;

        let alpha = srcA * correctionFactor;

        if (alpha > 1) alpha = 1;
        else if (alpha < minAlpha) alpha = minAlpha;

        return 'rgba(' +
            Math.round(channels.r) + ',' +
            Math.round(channels.g) + ',' +
            Math.round(channels.b) + ',' +
            alpha + ')';
    };

    // For rgb/rgba schemes only.
    getColorChannels (colorString: string): Rgba {
        const rgba = new Rgba();
        if (this.IsHexColor(colorString)) {
            const channels = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorString).slice(1);
            rgba.r = parseInt(channels[0], 16);
            rgba.g = parseInt(channels[1], 16);
            rgba.b = parseInt(channels[2], 16);
            if (channels.length > 3) {
                rgba.a = parseInt(channels[3], 16);
            }
        } else {
            const channels = colorString.slice(colorString.indexOf('(') + 1).split(',');

            rgba.r = parseInt(channels[0]);
            rgba.g = parseInt(channels[1]);
            rgba.b = parseInt(channels[2]);
            if (channels.length > 3) {
                rgba.a = parseFloat(channels[3]);
            }
        }
        return rgba;
    };

    // a - alpha (0 - 1).
    CreateColor (r, g, b, a = undefined) {
        let color = (a === undefined || a === 1) ? 'rgb(' : 'rgba(';
        color += r + ',' + g + ',' + b;
        if (a !== undefined && a !== 1) {
            color += ',' + a;
        }
        color += ')';

        return color;
    };

    readonly Empty = null;
    // Color.Gray = 'gray';
    // Color.Violet = '#EE82EE';
    // Color.Wheat = '#F5DEB3';
    // Color.Red = '#FF0000';
    readonly Transparent = '#FFFFFF';
    readonly AliceBlue = '#F0F8FF';
    readonly AntiqueWhite = '#FAEBD7';
    readonly Aqua = '#00FFFF';
    readonly Aquamarine = '#7FFFD4';
    readonly Azure = '#F0FFFF';
    readonly Beige = '#F5F5DC';
    readonly Bisque = '#FFE4C4';
    readonly Black = '#000000';
    readonly BlanchedAlmond = '#FFEBCD';
    readonly Blue = '#0000FF';
    readonly BlueViolet = '#8A2BE2';
    readonly Brown = '#A52A2A';
    readonly BurlyWood = '#DEB887';
    readonly CadetBlue = '#5F9EA0';
    readonly Chartreuse = '#7FFF00';
    readonly Chocolate = '#D2691E';
    readonly Coral = '#FF7F50';
    readonly CornflowerBlue = '#6495ED';
    readonly Cornsilk = '#FFF8DC';
    readonly Crimson = '#DC143C';
    readonly Cyan = '#00FFFF';
    readonly DarkBlue = '#00008B';
    readonly DarkCyan = '#008B8B';
    readonly DarkGoldenrod = '#B8860B';
    readonly DarkGray = '#A9A9A9';
    readonly DarkGreen = '#006400';
    readonly DarkKhaki = '#BDB76B';
    readonly DarkMagenta = '#8B008B';
    readonly DarkOliveGreen = '#556B2F';
    readonly DarkOrange = '#FF8C00';
    readonly DarkOrchid = '#9932CC';
    readonly DarkRed = '#8B0000';
    readonly DarkSalmon = '#E9967A';
    readonly DarkSeaGreen = '#8FBC8B';
    readonly DarkSlateBlue = '#483D8B';
    readonly DarkSlateGray = '#2F4F4F';
    readonly DarkTurquoise = '#00CED1';
    readonly DarkViolet = '#9400D3';
    readonly DeepPink = '#FF1493';
    readonly DeepSkyBlue = '#00BFFF';
    readonly DimGray = '#696969';
    readonly DodgerBlue = '#1E90FF';
    readonly Firebrick = '#B22222';
    readonly FloralWhite = '#FFFAF0';
    readonly ForestGreen = '#228B22';
    readonly Fuchsia = '#FF00FF';
    readonly Gainsboro = '#DCDCDC';
    readonly GhostWhite = '#F8F8FF';
    readonly Gold = '#FFD700';
    readonly Goldenrod = '#DAA520';
    readonly Gray = '#808080';
    readonly Green = '#008000';
    readonly GreenYellow = '#ADFF2F';
    readonly Honeydew = '#F0FFF0';
    readonly HotPink = '#FF69B4';
    readonly IndianRed = '#CD5C5C';
    readonly Indigo = '#4B0082';
    readonly Ivory = '#FFFFF0';
    readonly Khaki = '#F0E68C';
    readonly Lavender = '#E6E6FA';
    readonly LavenderBlush = '#FFF0F5';
    readonly LawnGreen = '#7CFC00';
    readonly LemonChiffon = '#FFFACD';
    readonly LightBlue = '#ADD8E6';
    readonly LightCoral = '#F08080';
    readonly LightCyan = '#E0FFFF';
    readonly LightGoldenrodYellow = '#FAFAD2';
    readonly LightGreen = '#90EE90';
    readonly LightGray = '#D3D3D3';
    readonly LightPink = '#FFB6C1';
    readonly LightSalmon = '#FFA07A';
    readonly LightSeaGreen = '#20B2AA';
    readonly LightSkyBlue = '#87CEFA';
    readonly LightSlateGray = '#778899';
    readonly LightSteelBlue = '#B0C4DE';
    readonly LightYellow = '#FFFFE0';
    readonly Lime = '#00FF00';
    readonly LimeGreen = '#32CD32';
    readonly Linen = '#FAF0E6';
    readonly Magenta = '#FF00FF';
    readonly Maroon = '#800000';
    readonly MediumAquamarine = '#66CDAA';
    readonly MediumBlue = '#0000CD';
    readonly MediumOrchid = '#BA55D3';
    readonly MediumPurple = '#9370DB';
    readonly MediumSeaGreen = '#3CB371';
    readonly MediumSlateBlue = '#7B68EE';
    readonly MediumSpringGreen = '#00FA9A';
    readonly MediumTurquoise = '#48D1CC';
    readonly MediumVioletRed = '#C71585';
    readonly MidnightBlue = '#191970';
    readonly MintCream = '#F5FFFA';
    readonly MistyRose = '#FFE4E1';
    readonly Moccasin = '#FFE4B5';
    readonly NavajoWhite = '#FFDEAD';
    readonly Navy = '#000080';
    readonly OldLace = '#FDF5E6';
    readonly Olive = '#808000';
    readonly OliveDrab = '#6B8E23';
    readonly Orange = '#FFA500';
    readonly Orange2 = '#FF6600';
    readonly OrangeRed = '#FF4500';
    readonly Orchid = '#DA70D6';
    readonly PaleGoldenrod = '#EEE8AA';
    readonly PaleGreen = '#98FB98';
    readonly PaleTurquoise = '#AFEEEE';
    readonly PaleVioletRed = '#DB7093';
    readonly PapayaWhip = '#FFEFD5';
    readonly PeachPuff = '#FFDAB9';
    readonly Peru = '#CD853F';
    readonly Pink = '#FFC0CB';
    readonly Plum = '#DDA0DD';
    readonly PowderBlue = '#B0E0E6';
    readonly Purple = '#800080';
    readonly Red = '#FF0000';
    readonly RosyBrown = '#BC8F8F';
    readonly RoyalBlue = '#4169E1';
    readonly SaddleBrown = '#8B4513';
    readonly Salmon = '#FA8072';
    readonly SandyBrown = '#F4A460';
    readonly SeaGreen = '#2E8B57';
    readonly SeaShell = '#FFF5EE';
    readonly Sienna = '#A0522D';
    readonly Silver = '#C0C0C0';
    readonly SkyBlue = '#87CEEB';
    readonly SlateBlue = '#6A5ACD';
    readonly SlateGray = '#708090';
    readonly Snow = '#FFFAFA';
    readonly SpringGreen = '#00FF7F';
    readonly SteelBlue = '#4682B4';
    readonly Tan = '#D2B48C';
    readonly Teal = '#008080';
    readonly Thistle = '#D8BFD8';
    readonly Tomato = '#FF6347';
    readonly Turquoise = '#40E0D0';
    readonly Violet = '#EE82EE';
    readonly Wheat = '#F5DEB3';
    readonly White = '#FFFFFF';
    readonly WhiteSmoke = '#F5F5F5';
    readonly Yellow = '#FFFF00';
    readonly YellowGreen = '#9ACD32';
}
export const Color = new _Color();
// #endregion

// #region Brushes
class _Brushes {
    White = new SolidBrush('white');
    Gray = new SolidBrush('gray');
    Red = new SolidBrush('red');
    Green = new SolidBrush('green');
    Black = new SolidBrush(Color.Black);
}
export const Brushes = new _Brushes();

// #endregion

class _Pens {
    White = new Pen('white');
    Transparent = new Pen('transparent');
    Black = new Pen(Color.Black);
}
export const Pens = new _Pens();
