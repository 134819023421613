// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { TimeSpanPeriods } from '../../Utils/Time/TimeSpan';
import { Resources } from '../../Commons/properties/Resources';
import { RangeSelectPanelTemplate } from '../../templates.js';
import { TerceraPanel } from '../elements/TerceraPanel';

export class RangeSelectPanel extends TerceraPanel {
    public static readonly KEY_DAILY = 'panel.rangeSelect.daily';
    public static readonly KEY_24H = 'panel.rangeSelect.24h';
    public static readonly KEY_7D = 'panel.rangeSelect.7d';
    public static readonly KEY_30D = 'panel.rangeSelect.30d';
    public static readonly KEY_90D = 'panel.rangeSelect.90d';
    public static readonly KEY_RANGE = 'panel.rangeSelect.range';

    public static readonly rangeKeysArray = [RangeSelectPanel.KEY_DAILY, RangeSelectPanel.KEY_24H, RangeSelectPanel.KEY_7D, RangeSelectPanel.KEY_30D, RangeSelectPanel.KEY_90D, RangeSelectPanel.KEY_RANGE];

    public getReportEvent: CustomEvent;

    constructor () { super(); }

    public override getType (): string { return 'RangeSelectPanel'; }

    public override oninit (): void {
        super.oninit();

        this.getReportEvent = new CustomEvent();
    }

    public override oncomplete (): void {
        super.oncomplete();

        const comboBox = this.Controls.rangeComboBox;

        comboBox.observe('selectedItem', function (newValue) { this.selectRange(newValue); }.bind(this));

        const keys = RangeSelectPanel.rangeKeysArray;
        const items = [];
        keys.forEach((key) => {
            if (!Resources.isHidden(key)) {
                items.push({ text: Resources.getResource(key), value: key });
            }
        });
        comboBox.set({ items });

        this.on('applyBtnClick', () => {
            this.selectRange({ value: RangeSelectPanel.KEY_RANGE });
        });
    }

    public selectRange (selectedItem): void {
        if (isNullOrUndefined(selectedItem)) {
            return;
        }

        void this.set('dateRangeLookupVisibility', selectedItem.value === RangeSelectPanel.KEY_RANGE);

        let startTime: number, finishTime: number;

        const nowTime = new Date();

        const Y = nowTime.getFullYear();
        const M = nowTime.getMonth();
        const D = nowTime.getDate();
        const nowMS = nowTime.getTime();

        switch (selectedItem.value) {
        case RangeSelectPanel.KEY_DAILY:
            startTime = new Date(Y, M, D, 0, 0, 0).getTime();
            finishTime = new Date(Y, M, D, 23, 59, 59).getTime();
            break;
        case RangeSelectPanel.KEY_24H:
            startTime = nowMS - TimeSpanPeriods.TicksPerDay;
            finishTime = nowMS;
            break;
        case RangeSelectPanel.KEY_7D:
            startTime = nowMS - TimeSpanPeriods.TicksPerDay * 7;
            finishTime = nowMS;
            break;
        case RangeSelectPanel.KEY_30D:
            startTime = nowMS - TimeSpanPeriods.TicksPerDay * 30;
            finishTime = nowMS;
            break;
        case RangeSelectPanel.KEY_90D:
            startTime = nowMS - TimeSpanPeriods.TicksPerDay * 90;
            finishTime = nowMS;
            break;
        case RangeSelectPanel.KEY_RANGE:{
            const dateFrom: Date = this.get('dateFrom') ?? new Date();
            const dateTo: Date = this.get('dateTo') ?? new Date();

            dateFrom.setHours(0); dateFrom.setMinutes(0); dateFrom.setSeconds(0); dateFrom.setMilliseconds(0);
            dateTo.setHours(23); dateTo.setMinutes(59); dateTo.setSeconds(59);

            startTime = dateFrom.getTime();
            finishTime = dateTo.getTime();
            break;
        }
        }

        this.getReportEvent.Raise(startTime, finishTime);
    }

    public updateSelectedRange (): void {
        this.selectRange(this.Controls.rangeComboBox.get('selectedItem'));
    }

    public isDaily (): boolean {
        let key = RangeSelectPanel.KEY_DAILY;
        let item = null;
        if (!isNullOrUndefined(this.Controls.rangeComboBox)) {
            item = this.Controls.rangeComboBox.get('selectedItem');
        }
        if (!isNullOrUndefined(item)) {
            key = item.value;
        }

        return key === RangeSelectPanel.KEY_DAILY;
    }
}

TerceraPanel.extendWith(RangeSelectPanel, {
    template: RangeSelectPanelTemplate,
    data: function () {
        return {
            top: 5,
            left: 5,
            visible: true,
            cbTooltip: 'panel.rangeSelect.tooltip',
            dateRangeLookupVisibility: false,
            refreshBtnVisibility: false,
            refreshBtnTT: null,
            dateFrom: null,
            dateTo: null
        };
    }
});
