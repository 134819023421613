// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Instrument } from '../../Commons/cache/Instrument';
import { Resources } from '../../Commons/properties/Resources';
import { type OrderTif, OrderTifMap } from './OrderTifEnum';
import { OrderType } from './OrderType';

export class TIF {
    public type: OrderTif;
    public expirationTime: any;

    constructor (type: OrderTif, expirationTime: any = undefined) {
        this.type = type;
        this.expirationTime = expirationTime;
    }

    public static expireTimeTIF_Market = new Date();
    public static expireTimeTIF_Limit = new Date();
    public static expireTimeTIF_Stop = new Date();

    public static handleNewExpireTime (date): any {
        if (!date) return;

        const now = new Date();
        return date >= now ? date : now;
    }

    public static getDefaultExpireTimeForOrderType (ordType, instrument: Instrument): any {
        let date = null;

        switch (ordType) {
        case OrderType.Market:
            date = TIF.expireTimeTIF_Market;
            break;

        case OrderType.Limit:
        case OrderType.StopLimit:
            date = TIF.expireTimeTIF_Limit;
            break;

        case OrderType.Stop:
        case OrderType.TrailingStop:
            date = TIF.expireTimeTIF_Stop;
            break;
        }

        if (date &&
        instrument?.isFuturesSymbol &&
        instrument.LastTradeDate < date) {
            date = instrument.LastTradeDate;
        }

        return date ?? new Date();
    }

    public static createVariantsForTifs (array): any[] {
        const res = [];
        const len = array.length;
        for (let i = 0; i < len; i++) {
            const val = array[i];
            res.push({
                value: +val,
                text: Resources.getResource(OrderTifMap[val])
            });
        }
        return res;
    }
}
