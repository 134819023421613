// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { AliasesItem } from '../../../../Commons/cache/AliasesItem';

export class AliasesGroup extends PFixFieldSet {
    public Data: any;

    constructor (pfixFieldSet) {
        super(FieldsFactory.I18N_NAMES_GROUP, null, pfixFieldSet);

        this.Data = AliasesGroup.GetFromFieldSet(pfixFieldSet);
    }

    public static GetFromFieldSet (fieldSet): Record<string, AliasesItem> {
        const aliasesGroups = fieldSet.GetGroups(FieldsFactory.I18N_NAMES_GROUP);

        if (aliasesGroups.length === 0) {
            return null;
        }

        const aliases: Record<string, AliasesItem> = {};

        for (let i = 0; i < aliasesGroups.length; i++) {
            const alias = aliasesGroups[i];
            const aliasesItem = new AliasesItem();
            const lang = alias.GetValue(FieldsFactory.FIELD_LANG);
            aliasesItem.Name = alias.GetValue(FieldsFactory.FIELD_NAME);
            aliasesItem.Description = alias.GetValue(FieldsFactory.FIELD_DESCRIPTION);

            aliases[lang] = aliasesItem;
        }

        return aliases;
    }

    public static GetFromFieldSetOnlyName (fieldSet): Record<string, string> {
        const aliasesGroups = fieldSet.GetGroups(FieldsFactory.I18N_NAMES_GROUP);

        if (aliasesGroups.length === 0) {
            return null;
        }

        const aliases: Record<string, string> = {};

        for (let i = 0; i < aliasesGroups.length; i++) {
            const alias = aliasesGroups[i];
            const lang = alias.GetValue(FieldsFactory.FIELD_LANG);
            const name = alias.GetValue(FieldsFactory.FIELD_NAME);

            aliases[lang] = name;
        }

        return aliases;
    }
}
