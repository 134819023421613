// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { TradingToolViewBase } from '../../Chart/Tools/TradingTools/TradingToolViewBase';
import { TerceraChartTradingOperation } from '../../Chart/Utils/ChartConstants';
import { GeneralSettings } from '../GeneralSettings/GeneralSettings';
import { OperationType } from '../Trading/OperationType';
import { OrderType } from '../Trading/OrderType';
import { TradingViewBaseTool } from './TradingViewBaseTool';
import { type Account } from '../../Commons/cache/Account';
import { type ProductType } from '../Instruments/ProductType';
import { type Order } from '../../Commons/cache/Order';
import { ControlsTypes } from '../../Controls/UtilsClasses/FactoryConstants';

export class TradingViewOrderTool extends TradingViewBaseTool {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): string { return ControlsTypes.TradingViewOrderTool; }

    public override id (): any {
        const order = this.get('dataObject');
        return order != null ? order.OrderNumber : null;
    }

    public override getPrice (): number | null {
        const order = this.get('dataObject');
        if (order == null) {
            return null;
        }

        return order.OrderType === OrderType.StopLimit ? order.StopLimit : order.Price;
    }

    public getOrder (): Order | null {
        const order = this.get('dataObject');
        return isNullOrUndefined(order) ? null : order;
    }

    public getProductType (): ProductType | null {
        return this.getOrder()?.ProductType;
    }

    public getAccount (): Account | null {
        return this.getOrder()?.Account;
    }

    public override oninit (): void {
        super.oninit();

        this.observe('hovered', this.onhovered);
        this.observe('dataObject', this.updateToolImage);

        this.on('handleMainImageClick', (e) => { void this.set('uncollapsed', !this.get<boolean>('uncollapsed')); });
        // this.on('handleActionImageClick', (e) => { this.set('uncollapsed', !this.get('uncollapsed')) });
        this.on('handleCancelImageClick', this.onCancelBtnClick);
    }

    public onhovered (hovered): void {
        this.updateToolImage();
    }

    public updateToolImage (): void {
        const order = this.get('dataObject');
        if (order == null) {
            return;
        }

        const uncollapsed = this.get<boolean>('uncollapsed');
        const hovered = this.get<boolean>('hovered');
        const dragging = this.get<boolean>('dragging');
        const isBuy = order.BuySell === OperationType.Buy;

        const im = this.getMainImage(order, isBuy, uncollapsed, hovered, dragging);

        const backgroundBrush = isBuy ? (hovered ? TradingToolViewBase.buyBackgroundActiveBrush : TradingToolViewBase.buyBackgroundBrush) : (hovered ? TradingToolViewBase.sellBackgroundActiveBrush : TradingToolViewBase.sellBackgroundBrush);
        const backgroundPen = isBuy ? (hovered ? TradingToolViewBase.buyBackgroundActivePen : TradingToolViewBase.buyBackgroundPen) : (hovered ? TradingToolViewBase.sellBackgroundActivePen : TradingToolViewBase.sellBackgroundPen);

        const actionSectionText = this.getActionImgText();
        const cancelImg = this.getCancelImage(isBuy);

        if (im != null) {
            void this.set({
                mainImg: im,
                mainSrc: im.src,

                cancelImg,
                cancelSrc: cancelImg.src,

                actionSectionText,

                pricePlateColor: backgroundBrush.Color,
                priceLineColor: backgroundPen.Color
            });
        }
    }

    public getMainImage (order: Order, isBuy: boolean, uncollapsed: boolean, hovered: boolean, dragging: boolean): any {
        if (order == null) {
            return null;
        }

        switch (order.OrderType) {
        case OrderType.Stop:
            if (dragging) {
                return uncollapsed ? TradingToolViewBase.move_STP_openImage : TradingToolViewBase.move_STP_closeImage;
            } else {
                if (uncollapsed) {
                    return isBuy ? (hovered ? TradingToolViewBase.buy_STP_open_hoverImage : TradingToolViewBase.buy_STP_open_defaultImage) : (hovered ? TradingToolViewBase.sell_STP_open_hoverImage : TradingToolViewBase.sell_STP_open_defaultImage);
                } else {
                    return isBuy ? (hovered ? TradingToolViewBase.buy_STP_close_hoverImage : TradingToolViewBase.buy_STP_close_defaultImage) : (hovered ? TradingToolViewBase.sell_STP_close_hoverImage : TradingToolViewBase.sell_STP_close_defaultImage);
                }
            }

        case OrderType.Limit:
            if (dragging) {
                return uncollapsed ? TradingToolViewBase.move_LMT_openImage : TradingToolViewBase.move_LMT_closeImage;
            } else {
                if (uncollapsed) {
                    return isBuy ? (hovered ? TradingToolViewBase.buy_LMT_open_hoverImage : TradingToolViewBase.buy_LMT_open_defaultImage) : (hovered ? TradingToolViewBase.sell_LMT_open_hoverImage : TradingToolViewBase.sell_LMT_open_defaultImage);
                } else {
                    return isBuy ? (hovered ? TradingToolViewBase.buy_LMT_close_hoverImage : TradingToolViewBase.buy_LMT_close_defaultImage) : (hovered ? TradingToolViewBase.sell_LMT_close_hoverImage : TradingToolViewBase.sell_LMT_close_defaultImage);
                }
            }

        case OrderType.StopLimit:
            // if (this.isLinkingStyle)
            //     return hovered ? TradingToolViewBase.link_out_STP_hover_full : TradingToolViewBase.link_out_STP_default_full;
            // else
            if (dragging) { return uncollapsed ? TradingToolViewBase.move_STP_link_openImage : TradingToolViewBase.move_STP_link_closeImage; } else {
                if (uncollapsed) {
                    return isBuy ? (hovered ? TradingToolViewBase.buy_STP_link_open_hoverImage : TradingToolViewBase.buy_STP_link_open_defaultImage) : (hovered ? TradingToolViewBase.sell_STP_link_open_hoverImage : TradingToolViewBase.sell_STP_link_open_defaultImage);
                } else {
                    return isBuy ? (hovered ? TradingToolViewBase.buy_STP_link_close_hoverImage : TradingToolViewBase.buy_STP_link_close_defaultImage) : (hovered ? TradingToolViewBase.sell_STP_link_close_hoverImage : TradingToolViewBase.sell_STP_link_close_defaultImage);
                }
            }

        case OrderType.TrailingStop:
            if (dragging) {
                return uncollapsed ? TradingToolViewBase.move_Trs_openImage : TradingToolViewBase.move_Trs_closeImage;
            } else {
                if (uncollapsed) {
                    return isBuy ? (hovered ? TradingToolViewBase.buy_Trs_open_hoverImage : TradingToolViewBase.buy_Trs_open_defaultImage) : (hovered ? TradingToolViewBase.sell_Trs_open_hoverImage : TradingToolViewBase.sell_Trs_open_defaultImage);
                } else {
                    return isBuy ? (hovered ? TradingToolViewBase.buy_Trs_close_hoverImage : TradingToolViewBase.buy_Trs_close_defaultImage) : (hovered ? TradingToolViewBase.sell_Trs_close_hoverImage : TradingToolViewBase.sell_Trs_close_defaultImage);
                }
            }

        case OrderType.Market:
            if (dragging) {
                return uncollapsed ? TradingToolViewBase.move_MKT_openImage : TradingToolViewBase.move_MKT_closeImage;
            } else {
                if (uncollapsed) {
                    return isBuy ? (hovered ? TradingToolViewBase.buy_MKT_open_hoverImage : TradingToolViewBase.buy_MKT_open_defaultImage) : (hovered ? TradingToolViewBase.sell_MKT_open_hoverImage : TradingToolViewBase.sell_MKT_open_defaultImage);
                } else {
                    return isBuy ? (hovered ? TradingToolViewBase.buy_MKT_close_hoverImage : TradingToolViewBase.buy_MKT_close_defaultImage) : (hovered ? TradingToolViewBase.sell_MKT_close_hoverImage : TradingToolViewBase.sell_MKT_close_defaultImage);
                }
            }
        }
    }

    public getCancelImage (isBuy: boolean): any {
        const hovered = this.get<boolean>('hovered');
        const dragging = this.get<boolean>('dragging');

        const cancelButtonImgHovered: boolean = this.get('cancelImageHovered');

        let cancelImgName = null;

        if (dragging) {
            cancelImgName = 'moveOrder_cancelImage';
        } else {
            if (cancelButtonImgHovered) {
                cancelImgName = isBuy ? 'buy_cancel_mine_hoverImage' : 'sell_cancel_mine_hoverImage';
            } else {
                cancelImgName = isBuy ? (hovered ? 'buy_cancel_hoverImage' : 'buy_cancel_defaultImage') : (hovered ? 'sell_cancel_hoverImage' : 'sell_cancel_defaultImage');
            }
        }

        // if (threeDotsMenuVisible)        // #101864
        //     cancelOrderImgName += '_full'   // если есть меню с тремя точками, то кнопка закрытия согласно макету должна стоять чуть отчужденно и тогда рисоваться она должна без обрезанного левого края -> другая картинка

        // if (this.isLinkingStyle)
        //     cancelOrderImg = hovered ? TradingToolViewBase.cancel_Out_hover : TradingToolViewBase.cancel_Out_default

        const cancelOrderImg = TradingToolViewBase[cancelImgName];
        return cancelOrderImg;
    }

    public getActionImgText (): string {
        const order = this.getOrder();
        if (order == null) return null;

        let qtyValue = order.Amount;
        const inLots = GeneralSettings.View.displayAmountInLots();
        if (!inLots) {
            qtyValue *= order.Instrument.LotSize;
            return qtyValue.toString();
        } else {
            return qtyValue.toFixed(order.Instrument.getAmountPrecision(inLots, order.Account, order.ProductType));
        }
    }

    public onCancelBtnClick (): void {
        const core = this.get('chartTradingCoreRef');
        const order = this.get('dataObject');

        if (core != null && order != null) {
            core.ChartVisualTrading(order, { action: TerceraChartTradingOperation.CancelOrder });
        }
    }
}

TradingViewBaseTool.extendWith(TradingViewOrderTool, {
    data: function () {
        return {
            className: 'tradingView-order-tool',

            collapseByClick: true,
            uncollapsed: true,

            movable: true,

            mainCursor: 'grab',
            actionCursor: 'text',
            closeCursor: 'pointer',

            height: TradingToolViewBase.buy_LMT_close_defaultImage.height
        };
    }
});
