// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { type iBuildInIndicator } from '../iBuildInIndicator';

export class StopATR extends IndicatorScriptBase {
    public ExpirationDate: number;
    public Password: string;
    public Period: number;
    public Multiplier: number;

    private atr: iBuildInIndicator;
    private hi: any[];
    private stop: any[];

    constructor () {
        super();
        // #region Initialization
        this.Copyrights = '(c) TraderEvolution';
        this.DateOfCreation = '02.05.2020';
        this.ExpirationDate = 0;
        this.Version = '';
        this.Password = '66b4a6416f59370e942d353f08a9ae36';
        this.ProjectName = 'StopATR';
        // #endregion

        this.SetIndicatorLine('Neutral Line', Color.LightGray, 2, LineStyle.SimpleChart);

        this.SeparateWindow = false;

        this.hi = [];
        this.stop = [];

        this.Period = 20;
        super.InputParameter(new InputParameterInteger('Period', 'Period', 0, 1, 99999));

        this.Multiplier = 2;
        super.InputParameter(new InputParameterInteger('Multiplier', 'Multiplier', 1, 1, 99999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return 'StopATR(' + this.Period + ', ' + this.Multiplier + ')';
    };

    public override getCustomName (): string {
        return 'StopATR (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.atr = this.Indicators.iATR(this.CurrentData, this.Period);
        return [this.atr];
    };

    public OnQuote (): void {
        super.OnQuote();

        if (this.CurrentData.Count <= this.Period) { return; };

        const max = this.CurrentData.GetPrice(PriceType.Close, 1) + this.atr.GetValue(0, 1) * this.Multiplier;
        const min = this.CurrentData.GetPrice(PriceType.Close, 1) - this.atr.GetValue(0, 1) * this.Multiplier;

        const prevHi = this.hi[this.hi.length - 1 - 1];
        const prevHigh = this.CurrentData.GetPrice(PriceType.High, 1);
        const prevStop = this.stop[this.stop.length - 1 - 1];
        const prevLow = this.CurrentData.GetPrice(PriceType.Low, 1);

        this.hi[this.hi.length - 1] = prevHi ? !(prevHigh >= prevStop) : prevLow <= prevStop;

        const hi = this.hi[this.hi.length - 1];

        this.stop[this.stop.length - 1] = hi ? max : min;

        const stop = this.stop[this.stop.length - 1];

        this.stop[this.stop.length - 1] = hi ? prevHi == false ? stop : stop > prevStop ? prevStop : stop : prevHi ? stop : stop < prevStop ? prevStop : stop;

        this.SetValue(0, this.stop[this.stop.length - 1]);
        hi ? this.SetMarker(0, Color.Lime) : this.SetMarker(0, Color.Red);
    };

    public NextBar (): void {
        super.NextBar();

        this.hi.push(true);
        this.stop.push(0);
    };
}
