// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { Color } from '../../../Graphics';
import { MAMode, AlligatorMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { type iBuildInIndicator } from '../iBuildInIndicator';

export class ALLIGATOR extends IndicatorScriptBase {
    public JawMAPeriod: number;
    public JawMAShift: number;
    public JawMAType: number;
    public JawSourcePrice: number;
    public TeethMAPeiod: number;
    public TeethMAShift: number;
    public TeethMAType: number;
    public TeethSourcePrice: number;
    public LipsMAPeiod: number;
    public LipsMAShift: number;
    public LipsMAType: number;
    public LipsSourcePrice: number;

    private AGATORJAW: iBuildInIndicator;
    private AGATORTEETH: iBuildInIndicator;
    private AGATORLIPS: iBuildInIndicator;

    constructor () {
        super();
        this.ProjectName = 'ALLIGATOR';
        this.Comments = 'Three moving averages with different colors, periods and calculation methods';
        super.SetIndicatorLine('JAW_LINE', Color.Green, 2, LineStyle.SimpleChart);
        super.SetIndicatorLine('TEETH_LINE', Color.Red, 1, LineStyle.SimpleChart);
        super.SetIndicatorLine('LIPS_LINE', Color.Blue, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.JawMAPeriod = 13;
        super.InputParameter(new InputParameterInteger('JawMAPeriod', 'Period of Jaw Moving Average', 2, 1, 9999));

        this.JawMAShift = 8;
        super.InputParameter(new InputParameterInteger('JawMAShift', 'Shift of Jaw Moving Average', 3, 1, 9999));

        this.JawMAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('JawMAType', 'Type of Jaw Moving Average', 0,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.JawSourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('JawSourcePrice', 'Source price for Jaw Moving Average', 1,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.TeethMAPeiod = 8;
        super.InputParameter(new InputParameterInteger('TeethMAPeiod', 'Period of Teeth Moving Average', 6, 1, 9999));

        this.TeethMAShift = 5;
        super.InputParameter(new InputParameterInteger('TeethMAShift', 'Shift of Teeth Moving Average', 7, 1, 9999));

        this.TeethMAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('TeethMAType', 'Type of Teeth Moving Average', 4,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.TeethSourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('TeethSourcePrice', 'Source price for Teeth Moving Average', 5,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.LipsMAPeiod = 5;
        super.InputParameter(new InputParameterInteger('LipsMAPeiod', 'Period of Lips Moving Average', 10, 1, 9999));

        this.LipsMAShift = 3;
        super.InputParameter(new InputParameterInteger('LipsMAShift', 'Shift of Lips Moving Average', 11, 1, 9999));

        this.LipsMAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('LipsMAType', 'Type of Lips Moving Average', 8,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.LipsSourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('LipsSourcePrice', 'Source price for Lips Moving Average', 9,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return 'ALLIGATOR(' + this.JawMAPeriod + ',' + this.TeethMAPeiod + ',' + this.LipsMAPeiod + ';)';
    };

    public override getCustomName (): string {
        return 'ALLIGATOR (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.SetLineShift(AlligatorMode.Jaw, this.JawMAShift);
        this.SetLineShift(AlligatorMode.Teeth, this.TeethMAShift);
        this.SetLineShift(AlligatorMode.Lips, this.LipsMAShift);
        this.AGATORJAW = this.Indicators.iAlligator(this.CurrentData,
            this.JawMAPeriod,
            this.JawMAShift,
            this.TeethMAPeiod,
            this.TeethMAShift,
            this.LipsMAPeiod,
            this.LipsMAShift,
            this.JawMAType,
            this.JawSourcePrice);
        this.AGATORTEETH = this.Indicators.iAlligator(this.CurrentData,
            this.JawMAPeriod,
            this.JawMAShift,
            this.TeethMAPeiod,
            this.TeethMAShift,
            this.LipsMAPeiod,
            this.LipsMAShift,
            this.TeethMAType,
            this.TeethSourcePrice);
        this.AGATORLIPS = this.Indicators.iAlligator(this.CurrentData,
            this.JawMAPeriod,
            this.JawMAShift,
            this.TeethMAPeiod,
            this.TeethMAShift,
            this.LipsMAPeiod,
            this.LipsMAShift,
            this.LipsMAType,
            this.LipsSourcePrice);
        return [this.AGATORJAW, this.AGATORTEETH, this.AGATORLIPS];
    };

    public OnQuote (): void {
        super.OnQuote();
        this.SetValue(0, 0, this.AGATORJAW.GetValue());
        this.SetValue(1, 0, this.AGATORTEETH.GetValue(1, 0));
        this.SetValue(2, 0, this.AGATORLIPS.GetValue(2, 0));
    };
}
