// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
/// <summary>
/// Панель для вертикальных таблиц
/// </summary>

import { Resources } from '../../../Commons/properties/Resources';
import { Color, SolidBrush } from '../../../Commons/Graphics';
import { QuickTableEditingInfo } from '../../elements/QuickTable/QuickTableMisc';
import { DynProperty } from '../../../Commons/DynProperty';
import { SessionSettings } from '../../../Commons/SessionSettings';
import { ApplicationPanelWithTable } from '../../panels/ApplicationPanelWithTable';
import { type VerticalPanelDataProvider } from './VerticalPanelDataProvider';
import { type NewVerticalPanelTableItem } from './NewVerticalPanelTableItem';
import { AssetBalanceItem } from '../AssetBalanceItem';

export class VerticalApplicationPanelNew extends ApplicationPanelWithTable<NewVerticalPanelTableItem> {
    constructor () {
        super();
    }

    VerticalPanelDataProvider: VerticalPanelDataProvider;
    sett: any;
    // #region Properties

    /// <summary>
    ///
    /// </summary>
    /* public virtual Dictionary<string, RowPropertyClass> */

    get WrapperRows () {
        const tempList = new Object();// new Dictionary<string, RowPropertyClass>();
        for (let i = 0; i < this.VerticalPanelDataProvider.items.length; i++) {
            if (Resources.isHidden(this.VerticalPanelDataProvider.items[i].LocalizationKey) || this.VerticalPanelDataProvider.items[i].Hidden) { continue; }
            const wrapper = this.VerticalPanelDataProvider.items[i];
            tempList[wrapper.Id] = wrapper;
        }
        return tempList;
    }

    set WrapperRows (value) {
        if (value === null) { return; }
        for (let i = 0; i < this.VerticalPanelDataProvider.items.length; i++) {
            const dataItem = this.VerticalPanelDataProvider.items[i];
            const wr = value[dataItem.Id];
            if (wr) { VerticalApplicationPanelNew.Copy(wr, dataItem); }
        }
        //
        this.PopulateTable();
    }

    static Copy (wr, dataItem): void {
        dataItem.visible = wr.visible;
        dataItem.SortIndex = wr.SortIndex;
        dataItem.callBack(wr.Properties);
    };

    // #endregion

    oncomplete (): void {
        this.sett = SessionSettings;
        super.oncomplete();
        this.InitializeDataProvider();
    };

    /// <summary>
    /// Constructors
    /// </summary>
    // public VerticalApplicationPanelNew(BaseApplication baseApplication, SessionSettings sett)
    //                : base(baseApplication)
    // {
    //    this.sett = sett;
    //    propertiesForTemplate.Add("dataItems");
    // }

    // #region ApplicationPanelWithTable overrided

    protected override jbInit (): void {
        super.jbInit();
        const quickTable = this.getQuickTable();
        if (!isNullOrUndefined(quickTable)) {
            quickTable.isVertical = true;
        }
    }

    localize (): void {
        super.localize();
        this.PopulateTable();
    };

    // TODO. Optimize.
    TickAsync (): void {
        if (!this.get('visible')) { return; }
        // Update data
        if (this.VerticalPanelDataProvider) { this.VerticalPanelDataProvider.UpdateItems(); }

        super.TickAsync();
    };

    Properties () {
        const prop = super.Properties();
        // prop.push(new DynProperty("dataItems", Serializator.Serialize(VerticalPanelDataProvider.items), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        return prop;
    };

    /// <summary>
    ///
    /// </summary>
    callBack (properties): void {
        super.callBack(properties);

        // let prop = DynProperty.getPropertyByName(properties, "dataItems");
        // if (prop != null)
        //    VerticalApplicationPanelNew .CopyDataItems((List<VerticalPanelDataProviderItem>)Serializator.Deserialize(prop.Value.ToString()), VerticalPanelDataProvider.items);

        this.PopulateTable();
    };

    dispose (): void {
        if (this.VerticalPanelDataProvider) { this.VerticalPanelDataProvider.Source = null; } // вызов DisposeHandler

        super.dispose();
    };

    // #endregion

    // #region VerticalApplicationPanelNew functions

    /// <summary>
    /// Создание провайдера - описание все возможных итемов и групп
    /// </summary>
    InitializeDataProvider (force?): void {

    };

    /// <summary>
    /// Заполнение таблицы
    /// </summary>
    PopulateTable (): void {
        const quickTable = this.getQuickTable();
        if (quickTable === null || isNullOrUndefined(this.VerticalPanelDataProvider)) { return; }

        // Create new rows
        this.VerticalPanelDataProvider.GenerateItems(this.VerticalPanelDataProvider, this.sett);

        // Fill table
        // quickTable.SaveGroupsState();

        // quickTable.SuspendDrawing = true;
        quickTable.ClearAll();

        for (let i = 0; i < this.VerticalPanelDataProvider.generatedTableItems.length; i++) {
            const item = this.VerticalPanelDataProvider.generatedTableItems[i];
            const row = quickTable.AddItem(item);
            row.cells[0].xPadding = 21;// left padding
            row.cells[0].BackColorBrush = item.CellsColor[0] === Color.Empty ? null : new SolidBrush(item.CellsColor[0]);
            row.cells[0].ForeColorBrush = item.CellsForeColor[0] === Color.Empty ? null : new SolidBrush(item.CellsForeColor[0]);
            row.cells[1].BackColorBrush = item.CellsColor[1] === Color.Empty ? null : new SolidBrush(item.CellsColor[1]);
            row.cells[1].ForeColorBrush = item.CellsForeColor[1] === Color.Empty ? null : new SolidBrush(item.CellsForeColor[1]);

            //
            if (!item.ToolTipKey) { row.cells[1].ToolTip = row.cells[0].ToolTip = Resources.getResource(item.ToolTipKey); }

            // если не стринг то надо создать экшн-ячейку
            if (item.DynPropertyControltype != DynProperty.STRING) { this.SetEditingInfo(row, item.DynPropertyControltype); }
        }

        quickTable.setGroupByColumnIdx(
            this.VerticalPanelDataProvider.hideGroupHeaders ? -1 : 2);
    };

    SetEditingInfo (row, controlType): void {
        row.cells[1].ReadOnly = false;
        row.cells[1].QuickTableEditingInfo = new QuickTableEditingInfo(controlType);
    // при необходимости дополнить.
    };

    // #endregion

    // #region Misc

    /// <summary>
    ///
    /// </summary>
    static CopyDataItems (from, targets): void {
        const fromHash = new Object();// new Dictionary<string, VerticalPanelDataProviderItem>();
        for (let i = 0; i < from.length; i++) { fromHash[from[i].Id] = from[i]; }

        for (let i = 0; i < targets.length; i++) {
            const sourceItem = fromHash[targets[i].Id];
            if (sourceItem) { targets[i].Apply(sourceItem); }
        }
    };

    SetSource (multiCache, selectedAccs, currentGroupAcc = null): void {
        // #region Process selected account

        const processedAccounts = [];

        if (selectedAccs) {
        // режим всех пользователей
            if (selectedAccs === 'All') // hsa: to remove? I think it is not possible to show few accounts in AccountDetailsPanel
            {
            // получаем все аккаунты
                const accountsList = multiCache.getAccounts();

                // заполняем список
                for (let i = 0; i < accountsList.length; i++) {
                    const acc = accountsList[i];
                    if (acc !== null) {
                        if (acc !== null && acc.assetBalanceDefault !== null) {
                            const accItem = new AssetBalanceItem(acc, this.sett, acc.assetBalanceDefault, acc.assetBalanceDefault.Asset);
                            if (accItem !== null && accItem.Account !== null) { processedAccounts.push(accItem); }
                        }
                    }
                }
            }
            // few, not all
            else if (selectedAccs.indexOf(',') !== -1) {
                const accs = selectedAccs.split(',');
                // заполняем список
                for (const str in accs) {
                    const acc = multiCache.GetAccountById(str);
                    if (acc !== null) {
                        if (acc.assetBalanceDefault !== null) {
                            const accItem = new AssetBalanceItem(acc, this.sett, acc.assetBalanceDefault, acc.assetBalanceDefault.Asset);
                            if (accItem !== null && accItem.Account !== null) { processedAccounts.push(accItem); }
                        }
                    }
                }
            }
            // режим одного пользователя
            else {
            // проверка на аккаунт-группу
            // TODO
                const groupAcc = null;// multiCache.AccountsGroupManager.GetGroupByName(selectedAccs);
                if (groupAcc !== null) {
                    currentGroupAcc = groupAcc;
                    for (const it in groupAcc.Accounts) {
                        const item = groupAcc[it];
                        if (item.Acc !== null) {
                            if (item.Acc.assetBalanceDefault !== null) {
                                const accItem = new AssetBalanceItem(item.Acc, this.sett, item.Acc.assetBalanceDefault, item.Acc.assetBalanceDefault.Asset);
                                processedAccounts.push(accItem);
                            }
                        }
                    }
                } else {
                    const acc = multiCache.GetAccountById(selectedAccs);

                    // TODO
                    if (acc !== null /* && acc.assetBalanceDefault !== null */) {
                        const accItem = new AssetBalanceItem(acc, this.sett, acc.assetBalanceDefault, null /* acc.assetBalanceDefault.Asset */);

                        if (accItem !== null && accItem.Account !== null) { processedAccounts.push(accItem); }
                    }
                }
            }
        } else {
            const accountsList = multiCache.getAccounts();
            if (accountsList !== null && accountsList.length > 0 && accountsList[0] !== null /* && accountsList[0] is Account */) {
                const acc = accountsList[0];

                if (acc !== null && acc.assetBalanceDefault !== null) {
                    const accItem = new AssetBalanceItem(acc, this.sett, acc.assetBalanceDefault, acc.assetBalanceDefault.Asset);
                    if (accItem !== null && accItem.Account !== null) { processedAccounts.push(accItem); }
                }
            }
        }

        //
        if (!isNullOrUndefined(this.VerticalPanelDataProvider)) { this.VerticalPanelDataProvider.Source = processedAccounts; }

    // #endregion
    };

    // #endregion
}
ApplicationPanelWithTable.extendWith(VerticalApplicationPanelNew, {});
