import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';
import { type Instrument } from '../../Instrument';

class _OptionTraderUtils {
    public getDaysToExpirationForInstrument (instrument: Instrument): number {
        const daysToExpire = this.getDaysToExpirationForDate(instrument.ContractMonthDate);
        if (daysToExpire === 0) {
            return 1;
        } else {
            return daysToExpire;
        }
    }

    public getDaysToExpirationForDate (date: Date): number {
        const isExpired = date < DateTimeUtils.DateTimeNow();
        const timeDifference = Math.abs(date.getTime() - DateTimeUtils.DateTimeNow().getTime());
        const daysToExpire = Math.floor(timeDifference / (1000 * 3600 * 24));
        return isExpired ? daysToExpire * -1 : daysToExpire + 1;
    }

    public formatExpirationDate (date: Date): string {
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    }
}

export const OptionTraderUtils = new _OptionTraderUtils();
