// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CreateAlertOrderParametrsSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Commons/properties/Resources';

export class CreateAlertOrderParametrsSelector extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'CreateAlertOrderParametrsSelector'; }

    public override oninit (): void {
        super.oninit();

        this.observe('infoBtnTooltip', this.updateInfoText);
        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public updateInfoText (newValue): void {
        void this.set({ enabled: !!newValue });
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public localize (): void {
        void this.set({
            buttonText: Resources.getResource('panel.orderEntry'),
            label: Resources.getResource('screen.Alerts.Label.OrderParameters'),
            btnTooltip: Resources.getResource('screen.Alerts.Label.OrderParameters.Tooltip')
        });
    }
}

ContainerControl.extendWith(CreateAlertOrderParametrsSelector, {
    template: CreateAlertOrderParametrsSelectorTemplate,
    data: function () {
        return {
            label: '',
            buttonText: '',
            btnTooltip: '',

            instrument: null,

            items: null,
            selectedItem: null,
            defaultValue: null,
            enabled: false,
            panelLink: null,
            infoBtnTooltip: '',

            width: 177
        };
    }
});
