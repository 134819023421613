// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { PositionItem } from './PositionItem';
import { ThemeManager } from '../misc/ThemeManager';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { PriceFormatter } from '../../Utils/Instruments/PriceFormatter';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { Quantity } from '../../Utils/Trading/Quantity';
import { DataCache } from '../../Commons/DataCache';
import { type SessionSettingsType } from '../../Commons/SessionSettings';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type PositionsBalance } from '../../Commons/cache/PositionsBalance';
import { PositionsBalanceFormatter } from '../../Commons/cache/Formatters/PositionsBalanceFormatter';
import { RiskPlan } from '../../Commons/cache/RiskPlan';

export class PositionsBalanceItem extends BaseItem {
    public positionsBalance: PositionsBalance | null;
    public Currency: string;

    constructor (positionsBalance: PositionsBalance, sessionSettings: SessionSettingsType) {
        super(sessionSettings);

        this.positionsBalance = positionsBalance;

        const tm = ThemeManager.CurrentTheme;

        this.CellForeColorMap[0] = tm.TableGoldColor;
    }

    get Instrument (): Instrument | null {
        return this.positionsBalance ? this.positionsBalance.Instrument : null;
    }

    public override GetCurrentInstrument (): Instrument {
        return this.Instrument;
    }

    public override getColumnData (column: number): ColumnData {
        let value = null;
        let formattedValue = null;

        if (this.positionsBalance === null) {
            value = null;
            formattedValue = '';
        }

        value = this.getColumnValue(column);
        const ins = this.Instrument;

        switch (column) {
        case 1:
        case 5:
        case 7:{
            const acc = this.positionsBalance.getAccountWithMaxAmountPrecision();
            const pT = this.positionsBalance.ProductType;
            const showInLots = GeneralSettings.View.displayAmountInLots();
            if (!showInLots) {
                value = Quantity.convertQuantityValue(new Quantity(value, true), ins, showInLots, acc, pT);
            }

            formattedValue = !isNaN(value) ? DataCache.formatVolume(ins, value, showInLots, pT, acc) : PositionItem.NOT_AVAILABLE;
            break;
        }

        case 2:
            formattedValue = !isNaN(value) ? ins.formatPricePrecision(value, true, true) : PositionItem.NOT_AVAILABLE;
            break;
        case 3:
        case 6:
        case 8:
            formattedValue = !isNaN(value) ? ins.formatPrice(value, true, true) : PositionItem.NOT_AVAILABLE;
            break;

        case 4:
        case 9:
        case 10:
        case 13:
            formattedValue = !isNaN(value) ? PriceFormatter.formatPrice(value, 2, false) + ' ' + DataCache.getUserBaseCurrency() : PositionItem.NOT_AVAILABLE;
            break;

        case 12:
            formattedValue = value !== null ? DateTimeUtils.formatDate(value, 'DD.MM.YYYY') : '';
            break;

        default:
            formattedValue = value ? value.toString() : '';
            break;
        }

        return new ColumnData(value, formattedValue);
    }

    public override getColumnValue (column: number): any {
        const positionsBalance = this.positionsBalance;

        if (positionsBalance === null) {
            return null;
        }

        const ins = positionsBalance.Instrument;

        switch (column) {
        case 0:
            return PositionsBalanceFormatter.SymbolStr(positionsBalance);

        case 1:
            return positionsBalance.NetPositionQty;

        case 2:
            return positionsBalance.BreakEvenPoint;

        case 3:
            return positionsBalance.CurrentPrice;

        case 4:{
            const userBaseCurrency = DataCache.getUserBaseCurrency(); // первая по счету колонка с ассетом следит за соблюдением единого ассета во всей таблице
            if (userBaseCurrency !== this.Currency) {
                this.Currency = userBaseCurrency;
                this.isNeedFireUpdate = true;
            }

            return positionsBalance.GrossPnL;
        }

        case 5:
            return positionsBalance.LongQty;

        case 6:
            return positionsBalance.AverageLong;

        case 7:
            return positionsBalance.ShortQty;

        case 8:
            return positionsBalance.AverageShort;

        case 9:
            return positionsBalance.GrossExposure;

        case 10:
            return positionsBalance.NetExposure;

        case 11:
            return positionsBalance.StrikePrice;

        case 12:
            return positionsBalance.ExpDate;

        case 13:
            return positionsBalance.PositionValue;

        case 14:
            return InstrumentUtils.getInstrumentTypeStringLocalized(ins.InstrType);

        case 15:
            return PositionsBalanceFormatter.ProductTypeStr(positionsBalance);

        default:
            return null;
        }
    }

    public Recalc (): void {
        if (this.positionsBalance) {
            this.positionsBalance.RecalculatePositionBalance();
        }
    }

    // public override getFormatTotalValue  (column, value, precisionMode = false)
    // {
    //     let formattedValue = "";
    //     let dc = DataCache;

    //     switch (column)
    //     {
    //         case 5:
    //         case 8:
    //             if (precisionMode)
    //                 return 2;
    //             formattedValue = value.toFixed(2)
    //             break;
    //     }

    //     return formattedValue;
    // }

    public override ColumnCount (): number {
        return PositionsBalanceItem.columnsParams.length;
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = PositionsBalanceItem.columnsParams[column];
        const isHidden = Resources.isHidden(result.HeaderKey);
        result.Hidden = isHidden;
        result.MenuIndex = this.GetMenuIndex(result.HeaderKey);

        if (column === 15) { // Product type
            result.Hidden = !RiskPlan.showProductType;
        }

        return result;
    }

    public override getFormatTotalValue (column, value, precisionMode = false): string | number {
        let formattedValue = '';
        const dc = DataCache;

        switch (column) {
        case 4:
        case 9:
        case 10:
        case 13:
            if (precisionMode) { return 2; }
            formattedValue = value != null ? PriceFormatter.formatPrice(value, 2) + ' ' + dc.getUserBaseCurrency() : '';
            break;

        default:
            if (precisionMode) { return 2; }
            formattedValue = value != null ? PriceFormatter.formatPrice(value, 2) : '';
            break;
        }

        return formattedValue;
    }

    public GetMenuIndex (headerKey: string): number {
        if (PositionsBalanceItem.ListMenuIndex.Contains(headerKey)) {
            return PositionsBalanceItem.ListMenuIndex.indexOf(headerKey);
        } else {
            return 1000;
        }
    }

    /// <summary>
    /// Здесь хранятся настройки колонок (один экземпляр на класс)
    /// </summary>
    public static readonly columnsParams = [
        /* 0 */ new ColumnParams('panel.positionsBalance.Symbol', 75, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams('panel.positionsBalance.NetPositionQty', 120, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        new ColumnParams('panel.positionsBalance.BreakEvenPoint', 120, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.positionsBalance.CurrentPrice', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.positionsBalance.GrossPL', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        /* 5 */ new ColumnParams('panel.positionsBalance.LongQty', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.positionsBalance.AverageLong', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.positionsBalance.ShortQty', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.positionsBalance.AverageShort', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.positionsBalance.GrossExposure', 95, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        /* 10 */ new ColumnParams('panel.positionsBalance.NetExposure', 90, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        new ColumnParams('panel.positionsBalance.StrikePrice', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.positionsBalance.ExpDate', 75, QuickTableColumnType.COL_DATE_SORT, false, false, false),
        new ColumnParams('panel.positionsBalance.PositionValue', 100, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        new ColumnParams('panel.positionsBalance.SymbType', 75, QuickTableColumnType.COL_DEFAULT, false, false, true),
        /* 15 */ new ColumnParams('panel.positionsBalance.ProductType', 75, QuickTableColumnType.COL_DEFAULT, false, false, true)
    ];

    public static readonly ListMenuIndex =
        [
            'panel.positionsBalance.Symbol',
            'panel.positionsBalance.NetPositionQty',
            'panel.positionsBalance.BreakEvenPoint',
            'panel.positionsBalance.CurrentPrice',
            'panel.positionsBalance.GrossPL',
            'panel.positionsBalance.LongQty',
            'panel.positionsBalance.AverageLong',
            'panel.positionsBalance.ShortQty',
            'panel.positionsBalance.AverageShort',
            'panel.positionsBalance.GrossExposure',
            'panel.positionsBalance.NetExposure',
            'panel.positionsBalance.StrikePrice',
            'panel.positionsBalance.ExpDate',
            'panel.positionsBalance.PositionValue',
            'panel.positionsBalance.SymbType',
            'panel.positionsBalance.ProductType'
        ];
}
