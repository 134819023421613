// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraNumeric } from './TerceraNumeric';

export class QuickTableNumeric extends TerceraNumeric {
    public tag: any;
    public callBack: any;

    constructor (initProps?, data?, callback?, rect?) {
        super();

        const editingInfo = data.cell.QuickTableEditingInfo;
        void this.set({
            minValue: editingInfo.Min,
            maxValue: editingInfo.Max,
            step: editingInfo.Inc,
            decimalPrecision: editingInfo.DecimalPlaces
        });

        this.tag = data;
        this.callBack = callback;

        void this.set('value', data.cell.value);
        this.setBounds(rect.X, rect.Y, rect.Width, rect.Height);
        void this.set('isPosAbsolute', true);
        void this.set('zIndex', 200); // #118800
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.setFocus();
    }

    public override processEnterKeyDown (): void {
        this.lostFocus();
    }

    public override lostFocus (): void {
        super.lostFocus();
        this.callBack(this.get('value'), this.tag);
    }
}

TerceraNumeric.extendWith(QuickTableNumeric, { });
