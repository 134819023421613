// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class SD extends IndicatorScriptBase {
    public MAType: number;
    public SourcePrice: number;
    public MAPeriod: number;

    private ma: iBuildInIndicator;

    constructor () {
        super();
        this.ProjectName = 'Standard Deviation';
        this.Comments = 'Shows the difference of the volatility value from the average one';
        this.SetIndicatorLine('SD', Color.Red, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of Moving Average', 0,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Sources prices for MA', 1,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.MAPeriod = 20;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of Moving Average', 1, 2, 99999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return 'SD(' + this.MAPeriod + ')';
    };

    public override getCustomName (): string {
        return 'SD (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.ma = this.Indicators.iMA(this.CurrentData, this.MAPeriod, this.MAType, 0, PriceType.Close);
        return [this.ma];
    };

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count < this.MAPeriod) { return; }

        let dAmount = 0;
        const movingAverage = this.ma.GetValue();
        for (let i = 0; i < this.MAPeriod; i++) { dAmount += Math.pow(this.CurrentData.GetPrice(this.SourcePrice, i) - movingAverage, 2); }

        this.SetValue(0, 0, Math.sqrt(dAmount / this.MAPeriod));
    };
}
