// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataBaseWorkingClass } from '../../Commons/DataBaseWorkingClass';
import { DataBaseActions } from '../DBClasses/DBPrimitives';
import { SettingsDataResult } from './SettingsDataResult';

const DB_NAME: string = 'UserSettings';

class _SettingsDBManager extends DataBaseWorkingClass {
    private currentKey: string = 'Settings';
    private readonly storageKey: string = 'UserSettings';
    private StartWaiter = null;

    constructor () {
        super(DB_NAME);
    }

    public async InitUserStorage (userKey: string): Promise<IDBDatabase | null> {
        this.currentKey = userKey;
        this.ClearRefs();
        this.StartWaiter = this.CreateObjectStore(userKey, 'id');
        this.db = await this.StartWaiter;
        this.db.onversionchange = () => {
            this.ClearRefs();
        };
        return this.db;
    }

    private ClearRefs (): void {
        this.CloseDB();
        this.StartWaiter = null;
    }

    protected async GetStorage (objectStoreKey: string): Promise<IDBObjectStore> {
        if (this.db === null && this.StartWaiter !== null) { this.db = await this.StartWaiter; } else if (this.db === null && this.StartWaiter === null) { await this.InitUserStorage(this.currentKey); }
        return this.db.transaction(objectStoreKey, 'readwrite').objectStore(objectStoreKey);
    }

    public async SaveSettings (settingsArray: any): Promise<void> {
        const WS = await this.GetFromDB(this.storageKey, this.currentKey);
        if (WS === null) { return null; }

        if (isNullOrUndefined(WS.result)) { await this.AddSettings(settingsArray); } else { await this.UpdateSettings(settingsArray); }
    }

    private async AddSettings (settingsArray: any): Promise<IDBRequest> {
        const ws = {
            id: this.storageKey,
            settings: settingsArray,
            updateTimeSpan: +(new Date()),
            actionState: DataBaseActions.Update
        };
        return await this.AddToDB(ws, this.currentKey);
    };

    private async UpdateSettings (settingsArray: any): Promise<IDBRequest> {
        const request = await this.GetFromDB(this.storageKey, this.currentKey);
        if (isNullOrUndefined(request.result)) { return null; }
        const data = request.result;
        data.settings = settingsArray;
        data.updateTimeSpan = +(new Date());
        data.actionState = DataBaseActions.Update;
        const updateRequest = await this.UpdateEntryInDB(data, this.currentKey);
        return updateRequest;
    };

    public async GetSettings (): Promise<SettingsDataResult> {
        const settings = await this.GetFromDB(this.storageKey, this.currentKey);
        if (settings === null) { return null; }
        if (isNullOrUndefined(settings.result)) { return null; }
        return new SettingsDataResult(settings.result.settings, settings.result.updateTimeSpan);
    }

    public async GetSettingsForSend (): Promise<SettingsDataResult> {
        const settings = await this.GetFromDB(this.storageKey, this.currentKey);
        if (settings === null) { return null; }
        if (isNullOrUndefined(settings.result)) { return null; }
        if (settings.result.actionState === DataBaseActions.None) { return null; }
        void this.ResetState();
        return new SettingsDataResult(settings.result.settings, settings.result.updateTimeSpan);
    }

    private async ResetState (): Promise<IDBRequest> {
        const request = await this.GetFromDB(this.storageKey, this.currentKey);
        if (isNullOrUndefined(request.result)) { return null; }
        const data = request.result;
        data.actionState = DataBaseActions.None;
        const updateRequest = await this.UpdateEntryInDB(data, this.currentKey);
        return updateRequest;
    }
};

export const SettingsDBManager = new _SettingsDBManager();
