// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { MODE_UPPER, MODE_LOWER } from '../../IndicatorConstants';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iFractals extends iBuildInIndicator {
    constructor () {
        super(2);
    }

    override get Name () { return 'iFractals'; }

    public override OnQuote (): void {
        this.SetValue(MODE_UPPER, 0, 0);
        this.SetValue(MODE_LOWER, 0, 0);
        const Parent = this.Parent;
        if (!Parent || this.FCount < 5) {
            return;
        }

        const high = this.GetPrice(PriceType.High, 2);
        if (high > this.GetPrice(PriceType.High, 4) && high > this.GetPrice(PriceType.High, 3) && high > this.GetPrice(PriceType.High, 1) && high > this.GetPrice(PriceType.High, 0)) {
            this.SetValue(MODE_UPPER, 2, high);
        }

        const low = this.GetPrice(PriceType.Low, 2);
        if (low < this.GetPrice(PriceType.Low, 4) && low < this.GetPrice(PriceType.Low, 3) && low < this.GetPrice(PriceType.Low, 1) && low < this.GetPrice(PriceType.Low, 0)) {
            this.SetValue(MODE_LOWER, 2, low);
        }
    }
}
