// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCache } from '../../Commons/DataCache';
import { Algorithm, AlgorithmDataRange, AlgorithmType, AlgorithmSubscribeType } from '../../Commons/cache/Algorithm/Algorithm';
import { Behavior, GainerLoserData } from '../../Commons/cache/Algorithm/GainersLosers/GainerLoserData';
import { type Instrument } from '../../Commons/cache/Instrument';
import { Resources } from '../../Commons/properties/Resources';
import { GainersLosersPanelTemplate } from '../../templates';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { GainersLosersItem } from '../cache/GainersLosersItem';
import { Control } from '../elements/Control';
import { type QuickTable } from '../elements/QuickTable/QuickTable';
import { ColouringModes } from '../elements/QuickTable/QuickTableColumn';
import { QuickTableRactive } from '../elements/QuickTable/QuickTableRactive';
import { TerceraMenu } from '../elements/TerceraMenu';
import { LinkedSystem } from '../misc/LinkedSystem';
import { DynProperty } from '../../Commons/DynProperty';
import { type QuickTableGroupRow } from '../elements/QuickTable/QuickTableGroupRow';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';

export class GainersLosersPanel extends ApplicationPanelWithTable<GainersLosersItem> {
    public gainersQTR: QuickTableRactive<GainersLosersItem>;
    public losersQTR: QuickTableRactive<GainersLosersItem>;
    public isRangesPopulating: boolean;
    public lastAlgorithmTop: number | undefined;
    public lastLosersList: GainerLoserData[];
    public lastGainersList: GainerLoserData[];
    public menuTagDict: any;
    public deferredGainersSettings: any;
    public deferredLosersSettings: any;

    private _savedRange: AlgorithmDataRange;
    private _subscribedAlgorithmId: number;
    private _subscribedAlgorithmIdProp: number;
    private _algorithmChanged: boolean;
    private _savedExchangeStateDict = {};

    constructor () {
        super();

        this.Name = 'GainersLosersPanel';
        this.headerLocaleKey = 'panel.GainersLosersPanel';
        this.NeedCalculateRowCount = false;
    }

    getType (): PanelNames { return PanelNames.GainersLosersPanel; }

    get gainersTable (): QuickTable | undefined { return this.gainersQTR?.quickTable; }
    get losersTable (): QuickTable | undefined { return this.losersQTR?.quickTable; }

    oninit (): void {
        super.oninit();
        Control.Ticker.Subscribe(this.TickAsync, this);
    }

    oncomplete (): void {
        super.oncomplete();
        this.createContextMenu();
        this.repopulate();
        this.localize();

        this.gainersTable?.OnSelectionChanged.Subscribe(this.selectionChange.bind(this, true), this);
        this.losersTable?.OnSelectionChanged.Subscribe(this.selectionChange.bind(this, false), this);
    }

    dispose (): void {
        this.algorithmsComboBox.clearItems(); // <- unsubscribe algorithm
        Control.Ticker.UnSubscribe(this.TickAsync, this);
        this.gainersTable?.OnSelectionChanged.UnSubscribe(this.selectionChange, this);
        this.losersTable?.OnSelectionChanged.UnSubscribe(this.selectionChange, this);
        super.dispose();
    }

    localize (): void {
        super.localize();
        const exchangesCB = this.exchangeComboBox;
        if (exchangesCB != null) {
            const noExchText = Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges');
            exchangesCB.set({
                allItemsSelectedText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges'),
                noItemsSelectedText: noExchText,
                noItemsText: noExchText,
                fewItemsSelectedPostfixText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges')
            });
        }

        if (this.gainersTable != null) {
            if (this.gainersTable.needRedraw) { this.gainersTable.localize(); }
            this.localizeMenuItems(this.gainersTable.tableContextMenuItems);
        }
        if (this.losersTable != null) {
            if (this.losersTable.needRedraw) { this.losersTable.localize(); }
            this.localizeMenuItems(this.losersTable.tableContextMenuItems);
        }

        void this.set('noDataText', Resources.getResource('panel.AlgorithmMarketConsensusPanel.noData'));
    }

    repopulate (): void {
        super.repopulate();
        this.populateAlgorithmsComboBox();
    }

    TickAsync (): void {
        super.TickAsync();
        if (this.gainersTable != null && this.gainersTable.needRedraw) {
            this.gainersTable.Draw();
            this.gainersTable.needRedraw = false;
        }
        if (this.losersTable != null && this.losersTable.needRedraw) {
            this.losersTable.Draw();
            this.losersTable.needRedraw = false;
        }
    }

    ProcessAlgorithm (msg: any/* DirectAlgorithmGainersLosersMessage */): void { this.updateTableByMessage(msg); }

    protected readonly jbInit = (): void => {
        const gainersItems = new GainersLosersItem(new GainerLoserData(Behavior.Gainer));
        const losersItems = new GainersLosersItem(new GainerLoserData(Behavior.Loser));

        this.gainersQTR = this.createTable(gainersItems);
        this.losersQTR = this.createTable(losersItems);

        const qtGainers = this.gainersQTR.quickTable;
        const qtLosers = this.losersQTR.quickTable;

        if (qtGainers == null || qtLosers == null) { return; }

        this.applyDeferredXmlSettings();

        qtGainers.UpdateSortedColumns();
        qtLosers.UpdateSortedColumns();

        this.OnResize.Subscribe(this.layoutTable, this);

        super.on('selectedAlgorithmChanged', this.algorithmsCombobox_SelectedIndexChanged);
        super.on('exchangeFilterChanged', this.exchangeComboBox_ValuesChanged);
        super.on('selectedTimeRangeChanged', this.rangeComboBox_SelectedIndexChanged);

        qtGainers.beforeTableContextMenuShowing = super.preparePopup.bind(this);
        qtLosers.beforeTableContextMenuShowing = super.preparePopup.bind(this);

        qtGainers.isMoveColumnAllowed = qtLosers.isMoveColumnAllowed = false;

        this.SetColumnsColouringMode(qtGainers);
        this.SetColumnsColouringMode(qtLosers);

        this.localize();
        this.layoutTable();
    };

    private readonly createTable = (items: GainersLosersItem): QuickTableRactive<GainersLosersItem> => {
        const qtr = new QuickTableRactive<GainersLosersItem>();
        super.addControl(qtr, true);

        const qt = qtr.quickTable;
        qt.allowGroupBy = true;
        qt.InitializeDirect(items);
        qt.UpdateSortedColumns();

        return qtr;
    };

    protected readonly clearTable = (tableBehavior: Behavior): void => {
        const quickTableR = tableBehavior === Behavior.Gainer ? this.gainersQTR : this.losersQTR;
        if (quickTableR == null) { return; }

        const qt = quickTableR?.quickTable;
        if (qt == null) { return; }

        qt.ClearAll();
    };

    private readonly PopulateTables = (): void => {
        const gainersTable = this.gainersTable;
        const losersTable = this.losersTable;

        if (gainersTable == null || losersTable == null) { return; }

        const lastGainersSelectedInstrument = this.GetTableSelectedInstrument(gainersTable);
        const lastLosersSelectedInstrument = this.GetTableSelectedInstrument(losersTable);
        const gainersScrollPos = gainersTable.getFirstVisibleScrollIndex();
        const losersScrollPos = losersTable.getFirstVisibleScrollIndex();
        const gainersExpandCollapseState = this.GetTableExpandCollapseState(gainersTable);
        const losersExpandCollapseState = this.GetTableExpandCollapseState(losersTable);

        gainersTable.ClearAll();
        losersTable.ClearAll();

        const gainers = this.GetSortedTopItems(Behavior.Gainer, this.lastAlgorithmTop, this.lastGainersList, this.GetSelectedExchanges());
        const losers = this.GetSortedTopItems(Behavior.Loser, this.lastAlgorithmTop, this.lastLosersList, this.GetSelectedExchanges());

        void this.set('noDataForGainers', gainers.length === 0);
        void this.set('noDataForLosers', losers.length === 0);

        gainers.forEach(gainer => gainersTable.AddItem(new GainersLosersItem(gainer)));
        losers.forEach(loser => losersTable.AddItem(new GainersLosersItem(loser)));

        this.RestoreTableState(gainersTable, gainersExpandCollapseState, lastGainersSelectedInstrument, gainersScrollPos);
        this.RestoreTableState(losersTable, losersExpandCollapseState, lastLosersSelectedInstrument, losersScrollPos);
    };

    private readonly RestoreTableState = (table: QuickTable, expandCollapseState: Map<string, boolean>, lastSelectedItem: string | undefined, lastScrollPosition: number): void => {
        table.sortRowsArray();
        this.RestoreTableSelection(table, lastSelectedItem);
        table.scroll.moveScrollToElement(lastScrollPosition);
    };

    private readonly RestoreTableSelection = (table: QuickTable, instrumentName: string | undefined): void => {
        for (const row of table.rowsArray) {
            if (instrumentName === row.item.Data.InstrumentName) {
                table.selectById([row.id]);
                table.OnScroll(); // for redraw
                return;
            }
        }
    };

    private readonly selectionChange = (onGainersTable: boolean, fromMouseClick: boolean): void => {
        if (fromMouseClick == null) { return; }

        const selectTable = onGainersTable ? this.gainersTable : this.losersTable;
        const anotherTable = onGainersTable ? this.losersTable : this.gainersTable;

        if (selectTable == null || anotherTable == null) { return; }

        let selectedRowNum = selectTable.selectedRowIds.length;
        if (selectedRowNum > 1) {
            selectTable.selectedRowIds = [selectTable.selectedRowIds[selectedRowNum - 1]];
            selectedRowNum = 1;
            selectTable.OnScroll(); // for redraw
        }

        if (selectedRowNum === 1) {
            const selectedRow = selectTable.getItemById(selectTable.selectedRowIds[0]);
            const data = selectedRow.item.Data;
            // const selectedIns = DataCache.getInstrument(data.TradableId.toString(), data.RouteId.toString());
            void DataCache.getInstrumentByInstrumentTradableID_NFL(data.TradableId, data.RouteId).then((selectedIns: Instrument) => {
                this.symbolLink_Out(false, selectedIns?.GetInteriorID());
            });
        }

        if (anotherTable != null && anotherTable.selectedRowIds.length > 0) {
            anotherTable.selectedRowIds = [];
            anotherTable.OnScroll(); // for redraw
        }
    };

    private readonly GetTableSelectedInstrument = (table: QuickTable): string => {
        if (table.selectedRowIds.length > 0) {
            const row = table.rows[table.selectedRowIds[0]];
            const item = row != null ? row.item : null;
            return item?.Data?.InstrumentName;
        }

        return '';
    };

    private readonly GetTableExpandCollapseState = (table: QuickTable): Map<string, boolean> => {
        const pairs = new Map<string, boolean>();

        if (table == null) { return pairs; }

        for (let i = 0; i < table.rowsArray.length; i++) {
            const currentRow = table.rowsArray[i];
            if (currentRow.isGroupRow) {
                pairs.set((currentRow as QuickTableGroupRow<GainersLosersItem>).GroupValue, (currentRow as QuickTableGroupRow<GainersLosersItem>).collapsed);
            }
        }

        return pairs;
    };

    private readonly GetSortedTopItems = (behavior: Behavior, top: number | undefined, items: GainerLoserData[], exchanges: string[]): GainerLoserData[] => {
        if (this.rangeComboBox == null || !top) {
            return items;
        }

        // 1. Get filtered by exchange
        const selectedRange = this.rangeComboBox.get('selectedItem')?.value as string | undefined;
        let last = items.filter(item => exchanges.includes(item.MarketDataExchange) &&
        (item.Range == null || (selectedRange != null && item.Range.isEqual(AlgorithmDataRange.fromString(selectedRange)))));

        // 2. Order by Interest change and Market data
        last = behavior === Behavior.Gainer
            ? last.sort((a, b) => b.InterestChange - a.InterestChange || a.MarketDataExchange.localeCompare(b.MarketDataExchange))
            : last.sort((a, b) => a.InterestChange - b.InterestChange || a.MarketDataExchange.localeCompare(b.MarketDataExchange));

        // 3. Take TOP
        return last.slice(0, top);
    };

    private readonly isAllowByProductSubscription = (gainerLoserData: GainerLoserData): boolean => {
        const entitlmentSystemCache = DataCache.EntitlementManager;
        const instrumentId = gainerLoserData.InstrumentId?.toString();
        const instrumentGroupId = gainerLoserData.InstrumentGroupId;
        const tradingExchange = gainerLoserData.TradingExchange;

        if (gainerLoserData.IsDelayed) { // Don't have RealTime subscription and have Delayed
            return !entitlmentSystemCache.IsExistSubscription(false, instrumentId, instrumentGroupId, tradingExchange) &&
                entitlmentSystemCache.IsExistSubscription(true, instrumentId, instrumentGroupId, tradingExchange);
        } else {
            return entitlmentSystemCache.IsExistSubscription(false, instrumentId, instrumentGroupId, tradingExchange);
        }
    };

    private readonly GetSelectedExchanges = (): string[] => {
        const items = this.exchangeComboBox?.get('items');
        if (items == null || items.length === 0) {
            return [];
        } else {
            return items
                .filter(item => item?.checked)
                .map(item => item?.value);
        }
    };

    public readonly layoutTable = (): void => {
        if (this.gainersQTR != null) { super.layoutTableResize(this.gainersQTR); }
        if (this.losersQTR != null) { super.layoutTableResize(this.losersQTR); }
    };

    protected readonly symbolLink_Out = (newSubscriber, instrument): void => {
        if (instrument == null) { return; }

        const color = super.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) { LinkedSystem.setSymbol(color, instrument, newSubscriber); }
    };

    private get algorithmsComboBox (): any /* TerceraComboBox */ { return this.Controls?.AlgorithmsList; }
    private get exchangeComboBox (): any /* TerceraMultiComboBox */ { return this.Controls?.ExchangeFilter; }
    private get rangeComboBox (): any /* TerceraComboBox */ { return this.Controls?.TimeRange; }

    private readonly updateTableByMessage = (gainersLosersMessage: any/* DirectAlgorithmGainersLosersMessage */): void => {
        const gainers: GainerLoserData[] = [];
        const losers: GainerLoserData[] = [];
        const exchanges: string[] = [];

        for (const gainersLosersData of gainersLosersMessage.GainersLosersData) {
            if (!this.isAllowByProductSubscription(gainersLosersData)) { continue; }

            if (!exchanges.includes(gainersLosersData.MarketDataExchange)) {
                exchanges.push(gainersLosersData.MarketDataExchange);
            }

            if (gainersLosersData.Behavior === Behavior.Gainer) {
                gainers.push(gainersLosersData);
            } else { losers.push(gainersLosersData); }
        }

        this.lastGainersList = gainers;
        this.lastLosersList = losers;
        this.lastAlgorithmTop = gainersLosersMessage.AlgorithmTop;
        this.populateExchanges(exchanges);
        this.PopulateTables();
    };

    private readonly populateRanges = (availableRanges: any/* AlgorithmDataRange[] */): void => {
        this.isRangesPopulating = true;
        this.rangeComboBox.clearItems();

        let selectedValue: string = '';
        availableRanges.forEach((range) => {
            const rangeStr: string = range.toString();
            const comboItem = { text: Algorithm.getRangeString(range), value: rangeStr };
            if (this._savedRange != null && this._savedRange.toString() === rangeStr) {
                selectedValue = rangeStr;
            }
            this.rangeComboBox.addItem(comboItem);
        });

        this.rangeComboBox.set('visible', true);
        this.rangeComboBox.set('enabled', availableRanges.length > 1);

        if (selectedValue.length > 0) { this.rangeComboBox.setItembyValue(selectedValue); }
        this.isRangesPopulating = false;
    };

    private readonly populateAlgorithmsComboBox = (): void => {
        this.algorithmsComboBox?.clearItems();

        const algorithmCache = DataCache.AlgorithmCache;
        if (algorithmCache != null) {
            const algorithms = [
                ...algorithmCache.getAlgorithmsByAlgorithmType(AlgorithmType.GainersLosers),
                ...algorithmCache.getAlgorithmsByAlgorithmType(AlgorithmType.HistoricalGainersLosersTop)
            ];

            algorithms.sort((x, y) => x.AlgorithmName.localeCompare(y.AlgorithmName));

            for (const algorithm of algorithms) {
                const comboItem = { text: algorithm.AlgorithmName, value: algorithm.AlgorithmId };
                this.algorithmsComboBox.addItem(comboItem);
            }
        }

        const algoCBItems = this.algorithmsComboBox.get('items');

        this.algorithmsComboBox.set('visible', algoCBItems.length > 1);

        if (algoCBItems.length > 0) {
            if (this._subscribedAlgorithmIdProp == null) { this._subscribedAlgorithmIdProp = algoCBItems[0].value; }
            this.algorithmsComboBox.setItembyValue(this._subscribedAlgorithmIdProp);
        }
    };

    private readonly algorithmsCombobox_SelectedIndexChanged = (context: object, selectedValue: number): void => {
        const algorithmCache = DataCache.AlgorithmCache;

        if (this._subscribedAlgorithmId != null && this._subscribedAlgorithmId !== -1) {
            DataCache.AlgorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Unsubscribe, this._subscribedAlgorithmId, this);
        }

        this.clearTable(Behavior.Gainer);
        this.clearTable(Behavior.Loser);

        if (selectedValue != null && selectedValue !== -1) {
            this._subscribedAlgorithmId = selectedValue;

            const algorithm = algorithmCache.getAlgorithmByAlgorithmId(this._subscribedAlgorithmId);

            if (algorithm != null && algorithm.AlgorithmType === AlgorithmType.HistoricalGainersLosersTop) {
                this.populateRanges(algorithm.AvailableRanges);
            } else {
                this.rangeComboBox.set('visible', false);
            }

            DataCache.AlgorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Subscribe, this._subscribedAlgorithmId, this);
            this._algorithmChanged = true;
        }
    };

    private readonly rangeComboBox_SelectedIndexChanged = (context: object, selectedValue: AlgorithmDataRange): void => {
        if (this.isRangesPopulating) {
            return;
        }

        this._savedRange = selectedValue;

        this.PopulateTables();
    };

    private readonly exchangeComboBox_ValuesChanged = (context: object, newValue: string[]): void => {
        this._savedExchangeStateDict[this._subscribedAlgorithmId] = super.get('exchangeFilterItems').filter((e) => e.checked).map((e) => e.value);
        this.PopulateTables();
    };

    private readonly populateExchanges = (newExchanges: string[]): void => {
        if (this._savedExchangeStateDict[this._subscribedAlgorithmId] == null) {
            this._savedExchangeStateDict[this._subscribedAlgorithmId] = newExchanges;
        }

        let isRepopulateExchange = false;

        const exchangeCB = this.exchangeComboBox;

        if (exchangeCB == null) {
            return;
        }

        const CBItemsBeforePopulate = exchangeCB.get('items');
        const oldExchanges: any[] = [];

        if (this._algorithmChanged) {
            this._algorithmChanged = false;
            // задаем предыдущие значения
            exchangeCB.set('items', this._savedExchangeStateDict[this._subscribedAlgorithmId].map(e => ({ text: e, checked: true, value: e })));

            isRepopulateExchange = true;
        }

        const oldCBItems = exchangeCB.get('items');
        if (oldCBItems != null) {
            oldCBItems.forEach(element => oldExchanges.push(element.value));
        }

        newExchanges.sort();
        oldExchanges.sort();

        const boolValues: boolean[] = oldCBItems?.filter((e) => e.checked) || [];

        if (!isRepopulateExchange && newExchanges.toString() === oldExchanges.toString()) { return; }

        const valuesForPopulate: any[] = boolValues != null
            ? [...boolValues]
            : [];

        for (let i = 0; i < newExchanges.length; i++) {
            // New Exchange
            if ((oldExchanges.filter((e) => e === newExchanges[i])).length === 0) {
                valuesForPopulate.push({
                    value: newExchanges[i],
                    checked: this._savedExchangeStateDict[this._subscribedAlgorithmId].includes(newExchanges[i]) ||
                    CBItemsBeforePopulate.length === boolValues.length, // If all exchanges are selected, then the new exchange must also be selected #119172
                    text: newExchanges[i]
                });
            }
        }

        if (valuesForPopulate.length > 0) { exchangeCB.set({ items: valuesForPopulate, visible: newExchanges.length !== 1 }); }
    };

    protected readonly SetColumnsColouringMode = (table: QuickTable): void => {
        super.ProcessSetColumnsColouringMode(table, [1], ColouringModes.Signed);
        super.ProcessSetColumnsColouringMode(table, [4], ColouringModes.Previous);
        table.columnsIndexWithColoringByPrevValue = [4];
    };

    private readonly createContextMenu = (): void => {
        const menuItems = this.createMenuItems();
        this.menuTagDict = TerceraMenu.createTagDictionary(menuItems);
        this.gainersTable?.setTableContextMenuItems(menuItems);
        this.losersTable?.setTableContextMenuItems(menuItems);
    };

    private readonly localizeMenuItems = (items: any[]): void => {
        items?.forEach((item) => {
            this.localizeMenuItem(item);
            if (item.subitems != null) {
                item.subitems.forEach((subitem: any) => { this.localizeMenuItems(item.subitems); });
            }
        });
    };

    private readonly localizeMenuItem = (item: any): void => {
        if (item?.locKey != null) {
            item.text = Resources.getResource(item.locKey);
        };
    };

    private readonly createMenuItems = (): any[] => {
        const items: any[] = [];
        if (!Resources.isHidden('panel.GainersLosersPanel.menu.ShowToolbar')) {
            items.push(
                {
                    locKey: 'panel.positions.menu.View',
                    tag: 'View',
                    enabled: true,
                    subitems: [
                        {
                            locKey: 'panel.positions.menu.ShowToolbar',
                            tag: 'ShowToolbar',
                            checked: super.get('showToolbar'),
                            enabled: true,
                            canCheck: true,
                            event: this.ShowToolbarStateChange.bind(this)
                        }]
                });
        }
        return items;
    };

    private readonly ShowToolbarStateChange = (menuItem: any): void => {
        this.ShowToolbarStateUpdate(menuItem.checked);
    };

    private readonly ShowToolbarStateUpdate = (state: boolean): void => {
        void this.set('showToolbar', state);
        this.layoutTable();
    };

    applyDeferredXmlSettings = (): void => {
        if (this.gainersTable != null && this.deferredGainersSettings != null) {
            this.gainersTable.xmlSettingsTemplate = this.deferredGainersSettings;
            /* delete */ this.deferredGainersSettings = null;
        }

        if (this.losersTable != null && this.deferredLosersSettings != null) {
            this.losersTable.xmlSettingsTemplate = this.deferredLosersSettings;
            /* delete */ this.deferredLosersSettings = null;
        }
    };

    getXmlSettingsTemplate = (): any => {
        const xml = { GainersSettings: {}, LosersSettings: {} };

        if (this.gainersTable != null) { xml.GainersSettings = this.gainersTable.xmlSettingsTemplate; }
        if (this.losersTable != null) { xml.LosersSettings = this.losersTable.xmlSettingsTemplate; }

        return xml;
    };

    setXmlSettingsTemplate = (value: any): void => {
        const gainersSettings = value?.GainersSettings;
        const losersSettings = value?.LosersSettings;

        if (gainersSettings != null) {
            if (this.gainersTable != null) { this.gainersTable.xmlSettingsTemplate = value; } else { this.deferredGainersSettings = gainersSettings; }
        }

        if (losersSettings != null) {
            if (this.losersTable != null) { this.losersTable.xmlSettingsTemplate = value; } else { this.deferredLosersSettings = losersSettings; }
        }
    };

    Properties = (): DynProperty[] => {
        const props: DynProperty[] = super.Properties();

        let dp: DynProperty = new DynProperty('SelectedExchangesState', JSON.stringify(this._savedExchangeStateDict), DynProperty.STRING, DynProperty.HIDDEN_GROUP);
        props.push(dp);

        dp = new DynProperty('SelectedAlgorithm', this._subscribedAlgorithmId, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP);
        props.push(dp);

        dp = new DynProperty('SelectedRange', this._savedRange?.toString(), DynProperty.STRING, DynProperty.HIDDEN_GROUP);
        props.push(dp);
        return props;
    };

    callBack = (properties: DynProperty[]): void => {
        super.callBack(properties);

        let prop = DynProperty.getPropertyByName(properties, 'SelectedExchangesState');
        if (prop != null) { this._savedExchangeStateDict = JSON.parse(prop.value); }

        prop = DynProperty.getPropertyByName(properties, 'SelectedAlgorithm');
        if (prop != null) { this._subscribedAlgorithmIdProp = prop.value; }

        prop = DynProperty.getPropertyByName(properties, 'SelectedRange');
        if (prop?.value != null) {
            this._savedRange = AlgorithmDataRange.fromString(prop.value);
        }
    };
}

ApplicationPanelWithTable.extendWith(GainersLosersPanel, {
    partials: { bodyPartial: GainersLosersPanelTemplate },

    data: function () {
        return {
            canLinkByAccount: false,
            isSymbolLinkShow: true,
            exchangeFilterItems: [],
            showToolbar: true,
            noDataText: '',
            noDataForGainers: true,
            noDataForLosers: true,
            timeRangeFilterVisible: true
        };
    }
});
