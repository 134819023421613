// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectRiskPlanMessage } from '../../../../Utils/DirectMessages/DirectRiskPlanMessage';
import { MarginPriceBasedMultipleLeverage } from '../Groups/MarginPriceBasedMultipleLeverage';

import { RiskPlanDataKey } from '../../../../Utils/RiskPlan/RiskPlanDataKey';
import { RiskPlanData } from '../../../../Utils/RiskPlan/RiskPlanData';
import { MarginCoefficients } from '../../../../Utils/RiskPlan/MarginCoefficients';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { type RiskPlanItem } from '../../../../Utils/RiskPlan/RiskPlanItem';

export class RiskPlanMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectRiskPlanMessage[] {
        const message = new DirectRiskPlanMessage();

        // hsa: Дима Коваленко сказал, что для нефиксированного списка, при запросе инструмента
        // будет скидываться только ID + RISK_PLAN_GROUP
        message.Id = fieldSet.GetValue(FieldsFactory.FIELD_RISK_PLAN_ID);
        message.Name = fieldSet.GetValue(FieldsFactory.FIELD_NAME);

        const groups = fieldSet.GetGroups(FieldsFactory.RISK_PLAN_GROUP);
        const len = groups.length;
        for (let i = 0; i < len; i++) {
            const risk = groups[i];

            const dataPush = new RiskPlanData();

            const riskPlanDataKey = new RiskPlanDataKey();

            const fieldInstrumentId = risk.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID);
            if (fieldInstrumentId !== null) {
                const tickId = fieldInstrumentId;
                riskPlanDataKey.InstrumentId = tickId.toString();
            }

            const fieldRouteId = risk.GetValue(FieldsFactory.FIELD_ROUTE_ID);
            if (fieldRouteId !== null) {
                const routeId = fieldRouteId;

                riskPlanDataKey.Route = routeId;

                if (riskPlanDataKey.Route === null) {
                // неизвестный роут
                    continue;
                }
            }

            riskPlanDataKey.InstrumentType = risk.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID);

            const field_contract_id = risk.GetValue(FieldsFactory.FIELD_CONTRACT_ID);
            if (field_contract_id !== null) {
                riskPlanDataKey.ContractId = field_contract_id;
            }

            const field_tradable_instrument_id = risk.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
            if (field_tradable_instrument_id !== null) {
                riskPlanDataKey.TradableId = field_tradable_instrument_id;
            }

            dataPush.RiskPlanDataKey = riskPlanDataKey;

            this.FillItem(risk, dataPush.Item);

            message.Data.push(dataPush);
        }

        return [message];
    }

    private FillItem (fieldSet, item: RiskPlanItem): void {
        item.ProductType = fieldSet.GetValue(FieldsFactory.FIELD_PRODUCT_TYPE);
        item.MarginCoefficient = fieldSet.GetValue(FieldsFactory.FIELD_MARGIN_COEF);
        item.IsShortable = fieldSet.GetValue(FieldsFactory.FIELD_IS_SHORTABLE);
        item.CalculateMarginType = fieldSet.GetValue(FieldsFactory.FIELD_MARGIN_TYPE);
        item.IsPositionCloseOnly = fieldSet.GetValue(FieldsFactory.FIELD_POSITION_CLOSE_ONLY);
        item.CurrencyId = fieldSet.GetValue(FieldsFactory.FIELD_CURRENCY_ID);

        const fieldIsHedgedMarginSize = fieldSet.GetValue(FieldsFactory.FIELD_IS_HEDGED_MARGIN_SIZE);
        if (fieldIsHedgedMarginSize !== null) {
            item.HedgedMarginCallSize = fieldIsHedgedMarginSize === 1 ? 1 : -1;
        }

        item.HedgedMarginCallCoeff =
        fieldSet.GetValue(FieldsFactory.FIELD_HEDGED_MARGIN_CALL_COEFF);

        const leverageGroups = fieldSet.GetGroups(FieldsFactory.MARGIN_PRICE_BASED_MULTIPLE_LEVERAGE);
        if (leverageGroups) {
            item.Leverages = MarginPriceBasedMultipleLeverage.getLeverages(leverageGroups);
        }

        item.MarginCoeficientList = [];

        const marginGroups = fieldSet.GetGroups(FieldsFactory.MARGIN_COEFFICIENT_GROUP);
        if (marginGroups) {
            for (let i = 0; i < marginGroups.length; i++) {
                const fields = new MarginCoefficients();

                fields.Futures_WARN_MARGINGSIZE = marginGroups[i].GetValue(FieldsFactory.FIELD_WARN_MARGINGSIZE);
                fields.Futures_HOLD_MARGINGSIZE = marginGroups[i].GetValue(FieldsFactory.FIELD_HOLD_MARGINGSIZE);
                fields.Futures_INIT_MARGINGSIZE = marginGroups[i].GetValue(FieldsFactory.FIELD_INIT_MARGINGSIZE);
                fields.Futures_WARN_MARGINGSIZE_OVERNIGHT = marginGroups[i].GetValue(FieldsFactory.FIELD_WARN_MARGINGSIZE_OVERNIGHT);
                fields.Futures_INIT_MARGINGSIZE_OVERNIGHT = marginGroups[i].GetValue(FieldsFactory.FIELD_INIT_MARGINGSIZE_OVERNIGHT);
                fields.Futures_HOLD_MARGINGSIZE_OVERNIGHT = marginGroups[i].GetValue(FieldsFactory.FIELD_HOLD_MARGINGSIZE_OVERNIGHT);
                fields.Futures_WARN_MARGINGSIZE_SHORT = marginGroups[i].GetValue(FieldsFactory.FIELD_WARN_MARGINGSIZE_SHORT);
                fields.Futures_INIT_MARGINGSIZE_SHORT = marginGroups[i].GetValue(FieldsFactory.FIELD_INIT_MARGINGSIZE_SHORT);
                fields.Futures_HOLD_MARGINGSIZE_SHORT = marginGroups[i].GetValue(FieldsFactory.FIELD_HOLD_MARGINGSIZE_SHORT);
                fields.Futures_WARN_MARGINGSIZE_OVERNIGHT_SHORT = marginGroups[i].GetValue(FieldsFactory.FIELD_WARN_MARGINGSIZE_OVERNIGHT_SHORT);
                fields.Futures_INIT_MARGINGSIZE_OVERNIGHT_SHORT = marginGroups[i].GetValue(FieldsFactory.FIELD_INIT_MARGINGSIZE_OVERNIGHT_SHORT);
                fields.Futures_HOLD_MARGINGSIZE_OVERNIGHT_SHORT = marginGroups[i].GetValue(FieldsFactory.FIELD_HOLD_MARGINGSIZE_OVERNIGHT_SHORT);
                fields.Futures_VAR_COEFF = marginGroups[i].GetValue(FieldsFactory.FIELD_VAR_COEFF);
                fields.Futures_USE_VAR_ONLY_INTRADAY = marginGroups[i].GetValue(FieldsFactory.FIELD_USE_VAR_ONLY_INTRADAY);
                fields.Futures_VAR_COEFF_APPLYING = marginGroups[i].GetValue(FieldsFactory.FIELD_VAR_COEFF_APPLYING);

                item.MarginCoeficientList.push(fields);
            }
        }

        item.MarginMode = fieldSet.GetValue(FieldsFactory.FIELD_MARGIN_MODE);
        item.UseOvernightMargin = fieldSet.GetValue(FieldsFactory.FIELD_USE_OVERNIGHT_MARGIN);
        item.AllowOvernightTrading = fieldSet.GetValue(FieldsFactory.FIELD_ALLOW_OVERNIGHT_TRADING);
        item.UseLongShortMargin = fieldSet.GetValue(FieldsFactory.FIELD_USE_LONGSHORT_MARGIN);
        item.UseOpenPriceInMargin = fieldSet.GetValue(FieldsFactory.FIELD_IS_OPEN_PRICE_IN_MARGIN);
        item.MinLot = fieldSet.GetValue(FieldsFactory.FIELD_MINIMAL_LOT);
        item.MaxLot = fieldSet.GetValue(FieldsFactory.FIELD_MAX_LOT);
        item.LotStep = fieldSet.GetValue(FieldsFactory.FIELD_LOT_STEP); // #122150
        if (item.LotStep <= 0) { item.LotStep = null; } // looks like server send -1 if setting is disabled #122715
        item.IsCashTradingAllowed = fieldSet.GetValue(FieldsFactory.FIELD_ALLOW_CASH_TRADING); // #122150
        item.MaxPositionQtyPerSymbol = fieldSet.GetValue(FieldsFactory.FIELD_MAX_POSITION_QTY_PER_SYMBOL);
        item.UseSameCrossPriceForOpenClose = fieldSet.GetValue(FieldsFactory.FIELD_USE_SAME_CROSSPRICEFOR_OPEN_CLOSE);
        item.UseUnsettledProfitAndOpenProfitInMarginAvailable = fieldSet.GetValue(FieldsFactory.FIELD_USE_UNSETTLED_PROFIT);
        item.UseUnsettledCashForStocksInMarginAvailable = fieldSet.GetValue(FieldsFactory.FIELD_USE_UNSETTLED_PROFIT_FOR_STOCKS);
        item.UseUnsettledNegativeStockOperationInWA = fieldSet.GetValue(FieldsFactory.FIELD_USE_UNSETTLED_NEGATIVE_STOCK_OPERATION_IN_WA);
        item.UseUnsettledPositiveStockOperationInWA = fieldSet.GetValue(FieldsFactory.FIELD_USE_UNSETTLED_POSITIVE_STOCK_OPERATION_IN_WA);
        item.UseUnsettledProfitInWA = fieldSet.GetValue(FieldsFactory.FIELD_USE_UNSETTLED_PROFIT_IN_WA);
        item.UseUnsettledCollateralInMA = fieldSet.GetValue(FieldsFactory.FIELD_USE_UNSETTLED_COLLATERAL_IN_MA);
        item.UseUnsettledCollateralInWA = fieldSet.GetValue(FieldsFactory.FIELD_USE_UNSETTLED_COLLATERAL_IN_WA);
        item.UseSettledCashForStocksForOpenedPositionsInMA = fieldSet.GetValue(FieldsFactory.FIELD_USE_SETTLED_CASH_FOR_STOCKS_FOR_OPENED_POSITIONS_IN_MA);
        item.UseSettledCollateralForOpenedPositionInMA = fieldSet.GetValue(FieldsFactory.FIELD_USE_SETTLED_COLLATERAL_FOR_OPENED_POSITION_IN_MA);

        item.AllowToWithdrawUnrealizedProfit = fieldSet.GetValue(FieldsFactory.FIELD_ALLOW_WITHDRAW_UNREALIZED_PROFIT) || false;
    }
}
