// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { AlertUtils } from '../../../Utils/Alert/AlertUtils';
import { CreateAlertNotificationSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Commons/properties/Resources';
import { CustomEvent } from '../../../Utils/CustomEvents';
import { AlertNotification } from '../../../Utils/Alert/AlertConstants';
import { type IAlertNotificationItem } from '../../../Utils/Alert/AlertItems';

export class CreateAlertNotificationSelector extends ContainerControl {
    // for add from Code like PropertySetupScreen
    public ValueChanged: CustomEvent = new CustomEvent();

    constructor () { super(); }

    public override getType (): string { return 'CreateAlertNotificationSelector'; }

    public override oninit (): void {
        super.oninit();
        this.observe('selectedValue', this.repopulate);//, { init: false });
        this.on('selectionValueChange', this.McbValueChanged);

        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public repopulate (): void {
        const value = this.get('selectedValue');
        const items: IAlertNotificationItem[] = AlertUtils.GetAlertNotificationItems(value);

        void this.set({ items });
    }

    public McbValueChanged (): void {
        const items: IAlertNotificationItem[] = this.get('items');
        let res = 0;
        for (let i = 0; i < items.length; i++) {
            if (items[i].checked) {
                res += items[i].value;
            }
        }
        void this.set({ selectedValue: (res || AlertNotification.SHOW_POP_UP) });
        if (!res) {
            this.repopulate();
        }

        this.ValueChanged?.Raise(this, res || AlertNotification.SHOW_POP_UP);
    }

    public localize (): void {
        void this.set({
            label: Resources.getResource('screen.Alerts.Label.Notify'),
            // infoBtnTooltip: Resources.getResource('screen.Alerts.Label.Notify.InfoTooltip')
            allItemsSelectedText: Resources.getResource('screen.Alerts.Notify.All'),
            noItemsSelectedText: 'No notifications', // Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges'),
            fewItemsSelectedPostfixText: Resources.getResource('screen.Alerts.Notify.Few')
        });
    }
}

ContainerControl.extendWith(CreateAlertNotificationSelector, {
    template: CreateAlertNotificationSelectorTemplate,
    data: function () {
        return {
            label: '',
            instrument: null,

            items: null,
            selectedValue: AlertNotification.SHOW_POP_UP // 1+2+4+8=15
        };
    }
});
