// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { MAMode } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iDeMarker extends iBuildInIndicator {
    public FMaPeriod: any;
    public FMFIPeriod: any;
    public FDeMax: ExpandDoubleVector = new ExpandDoubleVector();
    public FDeMin: ExpandDoubleVector = new ExpandDoubleVector();

    constructor (maPeriod) {
        super(1);

        this.FMaPeriod = maPeriod;
    }

    override get Name (): string { return 'iDeMarker'; }

    override get Key (): string { return this.DefaultKey + this.FMFIPeriod; }

    public override NextBar (callBound): void {
        super.NextBar(callBound);
        this.FDeMax.Add(0.0);
        this.FDeMin.Add(0.0);
    }

    public override OnQuote (): void {
        this.FDeMax[this.FDeMax.Length - 1] = 0;
        this.FDeMin[this.FDeMin.Length - 1] = 0;
        const Parent = this.Parent;
        if (!Parent && this.FCount == 1) {
            return;
        }

        const deMax = this.GetPrice(PriceType.High, 0) - this.GetPrice(PriceType.High, 1);
        this.FDeMax[this.FDeMax.Length - 1] = deMax > 0.0 ? deMax : 0.0;

        const deMin = this.GetPrice(PriceType.Low, 1) - this.GetPrice(PriceType.Low, 0);
        this.FDeMin[this.FDeMin.Length - 1] = deMin > 0.0 ? deMin : 0.0;
        if (this.FCount >= this.FMaPeriod) {
            const smaDeMax = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FDeMax, this.FMaPeriod, 1);
            const smaDeMin = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FDeMin, this.FMaPeriod, 1);
            const sumDeMax = smaDeMax + smaDeMin;
            if (sumDeMax != 0.0) {
                this.SetValue(smaDeMax / sumDeMax);
            }
        }
    }

    public override Refresh (count, newThread): void {
        this.FDeMax.Dispose();
        this.FDeMin.Dispose();
        this.FDeMax = new ExpandDoubleVector();
        this.FDeMin = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }
}
