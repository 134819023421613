// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OESideSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Commons/properties/Resources';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';

export class OESideSelector extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'OESideSelector'; }

    public override oninit (): void {
        super.oninit.call(this);

        const buyItem = {
            type: 'buy',
            value: OperationType.Buy,
            checked: true
        };

        const sellItem = {
            type: 'sell',
            value: OperationType.Sell,
            checked: false
        };

        void this.set({
            items: [buyItem, sellItem],
            side: OperationType.Buy
        });

        this.orderItems();
        this.localize();

        this.on('sideChanged', this.onSideChanged);
        this.observe('side', function (n, o, k) {
            const rB = GeneralSettings.View.ReverseButtonsOrder;
            let nV = n;
            let oV = o;
            if (rB) {
                if (n) {
                    nV = 0;
                    oV = 1;
                } else {
                    nV = 1;
                    oV = 0;
                }
            }

            if (!isNullOrUndefined(o)) {
                void this.set('items.' + oV + '.checked', false);
            }

            const newStr = 'items.' + nV + '.checked';
            void this.set(newStr, true);
        });
        GeneralSettings.SettingsChanged.Subscribe(this.orderItems, this);
        Resources.onLocaleChange.Subscribe(this.localize, this);
    }

    public override dispose (): void {
        super.dispose();

        this.off('sideChanged');
        GeneralSettings.SettingsChanged.UnSubscribe(this.orderItems, this);
        Resources.onLocaleChange.UnSubscribe(this.localize, this);
    }

    public onSideChanged (context, idx: number): void {
        void this.set('side', this.get('items.' + idx + '.value'));
    }

    public orderItems (): void {
        const items = this.get('items');
        const buy = OperationType.Buy;
        // Meh.
        const buyItem = items[0].value === buy ? items[0] : items[1];
        const sellItem = items[0].value !== buy ? items[0] : items[1];

        void this.set('items',
            GeneralSettings.View.ReverseButtonsOrder
                ? [sellItem, buyItem]
                : [buyItem, sellItem]
        );
    }

    public localize (): void {
        void this.set('label', Resources.getResource('panel.newOrderEntry.Side'));

        this.get('items').forEach(function (item, idx) {
            item.text = Resources.getResource(
                item.value === OperationType.Buy
                    ? 'panel.newOrderEntry.buySideCheckBox'
                    : 'panel.newOrderEntry.sellSideCheckBox'
            );
        });

        void this.update('items');
    }
}

ContainerControl.extendWith(OESideSelector, {
    template: OESideSelectorTemplate,
    data: function () {
        return {
            label: null,
            items: null,
            side: null
        };
    }
});
