// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { AlertUtils } from '../../../Utils/Alert/AlertUtils';
import { CreateAlertTypeSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Commons/properties/Resources';
import { type IAlertTypeItem } from '../../../Utils/Alert/AlertItems';
import { type AlertType } from '../../../Utils/Alert/AlertConstants';

export class CreateAlertTypeSelector extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'CreateAlertTypeSelector'; }

    public override oninit (): void {
        super.oninit();

        this.repopulate();
        this.observe('selectedItem', this.updateAlertType);
        this.observe('value', this.updateAlertTypeValue);
        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public updateAlertType (selectedItem: IAlertTypeItem): void {
        if (!isNullOrUndefined(selectedItem)) {
            void this.set({ value: selectedItem.value });
        }
    }

    public updateAlertTypeValue (newV: AlertType, oldV): void {
        const items: IAlertTypeItem[] = this.get('items');
        if (!isValidArray(items)) { return; }

        for (let i = 0; i < items.length; i++) {
            if (items[i].value === newV) {
                void this.set({ selectedItem: items[i] });
                break;
            }
        }
    }

    public localize (): void {
        void this.set('label', Resources.getResource('screen.Alerts.Label.AlertType'));
    }

    public repopulate (): void {
        const items: IAlertTypeItem[] = AlertUtils.GetAlertTypeItems();
        void this.set({ items });
    }
}

ContainerControl.extendWith(CreateAlertTypeSelector, {
    template: CreateAlertTypeSelectorTemplate,
    data: function () {
        return {
            label: '',
            instrument: null,

            items: null,
            selectedItem: null,
            defaultValue: null,
            value: -1,

            width: 198
        };
    }
});
