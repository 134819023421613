// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraWarningMessageTemplate } from '../../templates.js';
import { Control } from './Control';

export class TerceraWarningMessage extends Control {
    constructor () { super(); }

    public override getType (): string { return 'TerceraWarningMessage'; }
}

Control.extendWith(TerceraWarningMessage, {

    template: TerceraWarningMessageTemplate,
    data: function () {
        return {
            warningType: 'warning',
            message: 'Warning!!!'
        };
    }
});
