// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataBaseWorkingClass } from '../../Commons/DataBaseWorkingClass';
import { DataBaseActions } from '../../Commons/DBClasses/DBPrimitives';
import { WorkSpaceMetaData } from './WorkSpaceMetaData';

const DB_NAME: string = 'WorkSpaces';

class _WorkSpaceDBManager extends DataBaseWorkingClass {
    private currentWorkspacesKey: string = 'WorkSpacesUser';
    private StartWaiter = null;

    constructor () {
        super(DB_NAME);
    }

    public async InitUserStorage (userKey: string): Promise<IDBDatabase | null> {
        this.currentWorkspacesKey = userKey;
        this.ClearRefs();
        this.StartWaiter = this.CreateObjectStore(userKey, 'id');
        this.db = await this.StartWaiter;
        this.db.onversionchange = () => {
            this.ClearRefs();
        };
        return this.db;
    }

    private ClearRefs (): void {
        this.CloseDB();
        this.StartWaiter = null;
    }

    protected async GetStorage (objectStoreKey: string): Promise<IDBObjectStore> {
        if (this.db === null && this.StartWaiter !== null) { this.db = await this.StartWaiter; } else if (this.db === null && this.StartWaiter === null) { await this.InitUserStorage(this.currentWorkspacesKey); }
        return this.db.transaction(objectStoreKey, 'readwrite').objectStore(objectStoreKey);
    }

    private async AddWS (key: string, wsData: any, fromServer: boolean = false): Promise<IDBRequest> {
        const ws = {
            id: key,
            isActive: false,
            data: wsData,
            updateTimeSpan: +(new Date()),
            actionState: fromServer ? DataBaseActions.Update : DataBaseActions.Add,
            locked: false,
            dataToSend: {}
        };
        return await this.AddToDB(ws, this.currentWorkspacesKey);
    };

    private async UpdateWS (key: string, updateHandler: (req: IDBRequest) => any, actionState: DataBaseActions = DataBaseActions.Update): Promise<IDBRequest> {
        const request = await this.GetFromDB(key, this.currentWorkspacesKey);
        if (isNullOrUndefined(request.result)) { return null; }
        if (request.result.actionState === DataBaseActions.ToRemove) { return request; }
        const data = updateHandler(request);
        data.updateTimeSpan = +(new Date());
        if (request.result.actionState !== DataBaseActions.Add || actionState === DataBaseActions.ToRemove) { data.actionState = actionState; }
        const updateRequest = await this.UpdateEntryInDB(data, this.currentWorkspacesKey);
        return updateRequest;
    };

    private changeActive (isActive: boolean, request: IDBRequest): any {
        const tmp = request.result;
        tmp.isActive = isActive;
        this.checkDataToSendExists(tmp);
        tmp.dataToSend.isactive = isActive;
        return tmp;
    }

    private changeWSData (wsData: any, request: IDBRequest): any {
        const tmp = request.result;
        tmp.data = wsData;
        this.checkDataToSendExists(tmp);
        tmp.dataToSend.data = wsData;
        return tmp;
    }

    private changeState (newState: DataBaseActions, request: IDBRequest): any {
        const tmp = request.result;
        tmp.actionState = newState;
        return tmp;
    }

    private changeLocked (locked: boolean, request: IDBRequest): any {
        const tmp = request.result;
        this.checkDataToSendExists(tmp);
        tmp.dataToSend.locked = locked;
        return tmp;
    }

    private cleardataToSend (request: IDBRequest): any {
        const tmp = request.result;
        this.checkDataToSendExists(tmp);
        tmp.dataToSend = {};
        return tmp;
    }

    private changeName (newName: string, request: IDBRequest): any {
        const tmp = request.result;
        this.checkDataToSendExists(tmp);
        tmp.dataToSend.name = newName;
        return tmp;
    }

    private checkDataToSendExists (data: any): void {
        if (isNullOrUndefined(data.dataToSend)) { data.dataToSend = {}; }
    }

    public async SaveWorkSpace (key: string, wsData: any, fromServer: boolean = false): Promise<void> {
        const WS = await this.GetFromDB(key, this.currentWorkspacesKey);
        if (WS === null) { return null; }

        if (isNullOrUndefined(WS.result)) { await this.AddWS(key, wsData, fromServer); } else { await this.UpdateWS(key, (req) => { return this.changeWSData(wsData, req); }); }
    }

    public async GetWorkSpace (key: string): Promise<any> {
        const WS = await this.GetFromDB(key, this.currentWorkspacesKey);
        if (WS === null) { return null; }
        if (isNullOrUndefined(WS.result)) { return null; }
        return WS.result.data;
    }

    public async SetActiveWorkSpace (key: string): Promise<void> {
        const allWSreqRes = await this.GetAllEntriesFromDB(this.currentWorkspacesKey);
        const allWS = allWSreqRes.result;

        for (const ws of allWS) {
            if (ws.isActive === true) {
                void this.UpdateWS(ws.id, (req) => { return this.changeActive(false, req); });
            }
        }
        const WS = await this.GetFromDB(key, this.currentWorkspacesKey);
        if (WS === null) { return null; }

        await this.UpdateWS(key, (req) => { return this.changeActive(true, req); });
    }

    public async RemoveWorkSpace (key: string): Promise<void> {
        const WS = await this.DeleteFromDB(key, this.currentWorkspacesKey);
        if (WS === null) { return null; }
    }

    public async MarkToRemoveWorkSpace (key: string): Promise<void> {
        await this.UpdateWS(key, (req) => { return this.changeState(DataBaseActions.ToRemove, req); }, DataBaseActions.ToRemove);
    }

    public async SetDataBaseActionstate (key: string, newState: DataBaseActions): Promise<void> {
        await this.UpdateWS(key, (req) => { return this.changeState(newState, req); });
    }

    public async SetWorkSpaceLocked (key: string, locked: boolean): Promise<void> {
        await this.UpdateWS(key, (req) => { return this.changeLocked(locked, req); });
    }

    public async SetWorkSpaceName (key: string, newName: string): Promise<void> {
        await this.UpdateWS(key, (req) => { return this.changeName(newName, req); });
    }

    public async ClearWorkSpaceDataToSend (key: string): Promise<void> {
        await this.UpdateWS(key, (req) => { return this.cleardataToSend(req); });
    }

    public async GetAllWorkSpaces (): Promise<any[]> {
        const allWSreqRes = await this.GetAllEntriesFromDB(this.currentWorkspacesKey);
        const allWS = allWSreqRes.result;
        return allWS;
    }

    public async GetAllWorkSpacesIds (): Promise<WorkSpaceMetaData[]> {
        const allWSreqRes = await this.GetAllEntriesFromDB(this.currentWorkspacesKey);
        const allWS = allWSreqRes.result;
        const IdsFromDB: WorkSpaceMetaData[] = [];
        if (allWS !== null) {
            for (const ws of allWS) {
                if (ws.actionState !== DataBaseActions.ToRemove) {
                    const data = new WorkSpaceMetaData();
                    data.ws_id = ws.id;
                    data.name = ws.data.name;
                    data.updateTimeSpan = ws.updateTimeSpan;
                    data.isActive = ws.isActive;
                    IdsFromDB.push(data);
                }
            }
        };
        return IdsFromDB;
    }

    private getDBWSActiveTime (wsDataArray, activeWsId: string): number {
        for (const wsItem of wsDataArray) {
            if (wsItem.ws_id === activeWsId) return wsItem.updateTimeSpan;
        }
        return -1;
    }

    public async FilterWorkSpacesIds (wsDataArray, activeWsId: string): Promise<any> {
        const IdsFromDB = [];
        let newWSidFromDb = null;
        let isNewActiveWsId = true;
        const dbUpdateTimeSpan = this.getDBWSActiveTime(wsDataArray, activeWsId);
        for (const wsItem of wsDataArray) {
            const wsR = await this.GetFromDB(wsItem.ws_id, this.currentWorkspacesKey);
            const ws = wsR.result;
            if (isNullOrUndefined(ws)) {
                if (wsItem.ws_id === activeWsId) { isNewActiveWsId = false; }
                const data = new WorkSpaceMetaData();
                data.FillByDataFromServer(wsItem, wsItem.ws_id === activeWsId);
                IdsFromDB.push(data);
                continue;
            }

            if (ws.actionState !== DataBaseActions.ToRemove) {
                if (ws.id === activeWsId && dbUpdateTimeSpan >= ws.updateTimeSpan) { isNewActiveWsId = false; ws.isActive = false; }
                const data = new WorkSpaceMetaData();
                data.ws_id = ws.id;
                data.name = ws.data.name;
                data.updateTimeSpan = ws.updateTimeSpan;
                data.isActive = ws.isActive;
                if (isNewActiveWsId && ws.isActive) { newWSidFromDb = ws.id; isNewActiveWsId = false; }
                IdsFromDB.push(data);
            }
        }
        return [IdsFromDB, isNewActiveWsId, newWSidFromDb];
    }

    public async CloneWorkSpace (curWsId: string, clonedWsId: string): Promise<boolean> {
        const WS = await this.GetFromDB(curWsId, this.currentWorkspacesKey);
        if (WS === null) { return false; }
        if (isNullOrUndefined(WS.result)) { return false; }

        const WSCloneRes = await this.AddWS(clonedWsId, WS.result.data);
        if (isNullOrUndefined(WSCloneRes.result)) { return false; }

        return true;
    }

    public async ClearAll (): Promise<boolean> {
        const workspaces = await this.GetAllWorkSpaces();
        const prArr = [];

        for (const ws of workspaces) {
            const pr = this.DeleteFromDB(ws.id, this.currentWorkspacesKey);
            prArr.push(pr);
        }

        await Promise.all(prArr);
        return true;
    }
};

export const WorkSpaceDBManager = new _WorkSpaceDBManager();
