// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { NumericLinks } from '../../Commons/cache/OrderParams/order-edit/OrderEditBase';
import { OrderEditBaseUtils } from '../../Utils/Trading/OrderEditBaseUtils';
import { DPTradingNumericTemplate } from '../../templates.js';
import { DynPropertyControl } from '../elements/DynPropertyControl';

// TODO. Mousewheel increment bug, value validation bug.
export class DPTradingNumeric extends DynPropertyControl {
    constructor () { super(); }

    public override oncomplete (): void {
        super.oncomplete();

        const numericLinkKey = this.get('name') === OrderEditBaseUtils.LIMIT_PRICE_PARAM ? NumericLinks.LimitPrice : NumericLinks.StopPrice;

        void this.set(numericLinkKey, this.Controls.TradingNumeric);
    }
}

DynPropertyControl.extendWith(DPTradingNumeric, {
    template: DPTradingNumericTemplate,
    data: function () {
        return {
            showLabel: true,
            PriceNumericLink: null,
            StopPriceNumericLink: null,
            width: 200,
            additionalClassName: ''
        };
    }
});
