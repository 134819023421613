// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum ConnectionStates {
    /// Подлючен
    CONNECTED = 'connected.ok',
    /// Подключается
    CONNECTING = 'connecting',
    /// Отключен
    DISCONNECTED = 'disconnected',
    /// Частично подключен
    HALFCONNECTED = 'halfconnected',
    /// Потеря соединения
    CONNECTION_LOST = 'connection.lost'
}

export enum DisconnectType {
    Unspecified = 0,
    DisconnectedByServer = 1,
    ConnectionLost = 2,
    DisconnectedByClient = 3,
}

export enum DisconnectLevel {
    Unspecified = 0,
    ConsoleToJava = 1,
    AspToConsole = 2,
    JavascriptToAsp = 3,
    CloseSocket = 4
};

export enum ConectionResultEnum {
    DisConnected = 0,
    Connected = 1,
    Error = 2,
    NeedActivation = 3,
    AdditionalVerification = 4,
    NeedChangePassword = 5,
    InvalidBrandingKey = 6
};

export enum ConnectionErrorsEnum {
    ERROR_NONE = 0,
    ERROR_NEED_ACTIVATION = 1,
    ERROR_WRONG_ACTIVATION = 2,
    ERROR_INTERNAL = 3,
    ERROR_MAINTENANCE = 4,
    ERROR_USER_BLOCKED = 5,
    ERROR_MULTI_POLICY = 6,
    ERROR_NOT_ALLOWED = 7,
    ERROR_USER_NOT_FOUND = 8,
    INVALID_LOGIN_PASSWORD_COMBINATION = 9,
    INVALID_BRANDING_KEY = 10,
    MAX_CONCURRENT_CONNECTIONS = 11,

    ERROR_UNKNOWN = 13,
    ERROR_NO_WORKING_SERVERS = 14
}

export enum RegistrationMode {
    Blocked = 0,
     Screen = 1,
     Link = 2
};
