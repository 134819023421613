// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources } from '../../Commons/properties/Resources';
import { QuickTableColumnType, QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { PositionItem } from './PositionItem';
import { ColumnParams } from './ColumnParams';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { OperationType } from '../../Utils/Trading/OperationType';
import { OrderType } from '../../Utils/Trading/OrderType';
import { ThemeManager } from '../misc/ThemeManager';
import { OrderTif, OrderTifMap } from '../../Utils/Trading/OrderTifEnum';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { DynProperty } from '../../Commons/DynProperty';
import { SLTPTrigger } from '../../Utils/SlTpTrigger';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { RiskPlan } from '../../Commons/cache/RiskPlan';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { IsAllowed } from '../../Commons/IsAllowed';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { OrderStatus } from '../../Utils/Trading/OrderStatus';
import { DataCache } from '../../Commons/DataCache';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { type SessionSettingsType } from '../../Commons/SessionSettings';
import { type Order } from '../../Commons/cache/Order';
import { type Account } from '../../Commons/cache/Account';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Position } from '../../Commons/cache/Position';

export class OrderItem extends BaseItem {
    public FOrder: Order;
    public userGroupName: string = '';
    public Account_CorrectAsset: any = null;
    public FPosition: any;

    constructor (order?: Order, sessionSettings?: SessionSettingsType) {
        super(sessionSettings);

        this.FOrder = order;
        this.sessionSettings = sessionSettings;
        if (order) {
            this.itemId = OrderItem.GetItemId(order);
        }

        this.QuickTableEditingInfoMap[46] = (function () {
            return new QuickTableEditingInfo(DynProperty.CLOSE_BUTTON);
        })();

        const tm = ThemeManager.CurrentTheme;
        this.CellForeColorMap[0] = tm.TableGoldColor;// "#EDC234";
        this.CellForeColorMap[5] = tm.TableGoldColor;// "#EDC234";
        if (order) {
            this.CellForeColorMap[1] = order.BuySell === OperationType.Buy ? tm.TableLongBuyColor : tm.TableShortSellColor;
        }// "#EDC234";
    }

    public static GetItemId (order: Order): string | null {
        return order.OrderNumber; /* Женя: убрали. ай-ди используется также в BuilderOrdersPanel */ // + ((string.IsNullOrEmpty(order.DataCache.VendorName)) ? "" : "(" + order.DataCache.VendorName + ")");
    }

    public override ColumnCount (): number {
        return OrderItem.columnsParams.length;
    }

    public override getColumnData (column: number): ColumnData {
        let o: any = null;
        try {
            o = this.getColumnValue(column);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            o = 'some Error';
        }
        let FormattedValue = null;
        switch (column) {
        case 1:
            FormattedValue = o.toString();
            break;

        case 2:
            // var time = o;
            // if (time.getFullYear() < 1000)
            //     return "";
            // FormattedValue = Utils.FormatToDate(o);
            // break;
            FormattedValue = DateTimeUtils.formatDate(o, 'DD.MM.YYYY  HH:mm:ss');
            break;

        case 3:
            FormattedValue = DateTimeUtils.FormatToTime(o);
            break;

        case 4:{
            // Проверка,для этого ордера разрешен стоп-лосс?

            let parentOrder = null;
            try {
                parentOrder = this.FOrder.DataCache.getPositionById(this.FOrder.BoundTo != '-1' ? this.FOrder.BoundTo : this.FOrder.PositionId);
                if (parentOrder == null) {
                    parentOrder = this.FOrder.DataCache.getOrderById(this.FOrder.BoundTo);
                }
            } catch (ex) {
                ErrorInformationStorage.GetException(ex);
            }
            if (this.FOrder.DataCache.isAllowedForAccount(RulesSet.FUNCTION_SLTP, this.FOrder.Account) || this.FOrder.IsGroupPart || (parentOrder != null /* && parentOrder.IsGroupPart */)) {
                if (parentOrder != null) {
                    if (parentOrder.SLOrder != null && parentOrder.SLOrder.OrderNumber == this.FOrder.OrderNumber && parentOrder.SLOrder.OrderType == OrderType.TrailingStop) {
                        FormattedValue = 'Trailing Stop for ' + parentOrder.PositionId;
                    } else if (parentOrder.SLOrder != null && parentOrder.SLOrder.OrderNumber == this.FOrder.OrderNumber) {
                        FormattedValue = 'S/L for ' + parentOrder.PositionId;
                    } else if (parentOrder.TPOrder != null && parentOrder.TPOrder.OrderNumber == this.FOrder.OrderNumber) {
                        FormattedValue = 'T/P for ' + parentOrder.PositionId;
                    }
                }
            }
            // Иначе просто возвращаем тип ордера
            break;
        }

        case 5:
            if (typeof o === 'number' && this.FOrder != null) {
                FormattedValue = this.FOrder.DataCache.formatVolume(this.FOrder.Instrument, o, GeneralSettings.View.DisplayQuantityInLots, this.FOrder.ProductType, this.FOrder.Account);
            }
            break;

        case 10:
        case 11:
            if (typeof o === 'number' && this.FOrder != null) {
                FormattedValue = this.FOrder.DataCache.formatVolume(this.FOrder.Instrument, o, GeneralSettings.View.DisplayQuantityInLots, this.FOrder.ProductType, this.FOrder.Account);
            }
            break;

        case 6:
            {
                const price = this.FOrder != null ? OrderUtils.GetCorrectPriceForOrderType(this.FOrder.Price, this.FOrder.StopLimit, OrderUtils.PriceType.LimitPrice, this.FOrder.OrderType) : -1;
                if (!isNaN(price)) {
                    FormattedValue = this.FOrder.Instrument.formatPrice(price);
                } else {
                    FormattedValue = '';
                }

                const trigger = this.FOrder.TriggerSLTP;
                if (FormattedValue && trigger) {
                    FormattedValue += ' ' + SLTPTrigger.ConvertBidAskRawValueToStr(trigger);
                }
            }
            break;

        case 7:
            {
                const stopPrice = this.FOrder != null ? OrderUtils.GetCorrectPriceForOrderType(this.FOrder.Price, this.FOrder.StopLimit, OrderUtils.PriceType.StopPrice, this.FOrder.OrderType) : -1;
                if (!isNaN(stopPrice)) {
                    FormattedValue = this.FOrder.Instrument.formatPrice(stopPrice);
                } else {
                    FormattedValue = '';
                }

                const trigger = this.FOrder.TriggerSLTP;
                if (FormattedValue && trigger) {
                    FormattedValue += ' ' + SLTPTrigger.ConvertBidAskRawValueToStr(trigger);
                }
            }
            break;

        case 8:
            if (this.FOrder != null && typeof o === 'number' && !isNaN(o)) { FormattedValue = this.FOrder.Instrument.formatPrice(o); } else {
                FormattedValue = Resources.getResource('general.trading.priceNotAvailable');
            }
            break;

        case 21:
            if (this.FOrder?.SLOrder != null && !isNaN(this.FOrder.SLOrder.Price)) {
                FormattedValue = this.FOrder.Instrument.formatPrice(this.FOrder.SLOrder.Price) + ((this.FOrder.SLOrder.OrderType === OrderType.TrailingStop) ? ' (Tr. stop)' : '');
            } else {
                FormattedValue = '';
            }
            break;

        case 22:
            if (this.FOrder?.TPOrder != null && !isNaN(this.FOrder.TPOrder.Price)) {
                FormattedValue = this.FOrder.Instrument.formatPrice(this.FOrder.TPOrder.Price);
            } else {
                FormattedValue = '';
            }
            break;

        case 23:{
            // под ван позишн, http://tp.pfsoft.net/Project/QA/Bug/View.aspx?BugID=16343&acid=74FA48A38CBEE17F6445F76C9BEC721B
            const parentOrder2 = null;
            // TODO
            // if (this.FOrder != null && this.FOrder.DataCache != null)
            // {
            //    parentOrder2 = this.FOrder.DataCache.getPositionById(this.FOrder.BoundTo);
            //    if (parentOrder2 == null)
            //        parentOrder2 = this.FOrder.DataCache.getOrderById(this.FOrder.BoundTo);
            // }
            FormattedValue = parentOrder2 != null ? parentOrder2.OrderNumber : o;
            break;
        }

        case 31:
            FormattedValue = OrderItem.localizeOrderStatus(o);
            break;

        case 32:
            if (this.FOrder?.Instrument != null && this.FOrder.Account != null) {
                FormattedValue = this.Account_CorrectAsset != null ? this.Account_CorrectAsset.formatPrice(this.FOrder.getInitMargin(true)) : this.FOrder.Account.formatPrice(this.FOrder.getInitMargin(true));
            } else {
                FormattedValue = '';
            }
            break;

        case 33: // StrikePrice
            if (o > 0) {
                if (this.FOrder?.Instrument != null) {
                    FormattedValue = this.FOrder.Instrument.formatPrice(o);
                } else { FormattedValue = o.toString(); }
            } else {
                FormattedValue = '';
            }
            break;

        case 34:
            if (o && (o as Date).getTime() > 0) {
                FormattedValue = DateTimeUtils.FormatToDate(o);
            } else {
                FormattedValue = '';
            }
            break;

        case 35:

            if (this.FOrder != null && !((this.FOrder.OrderType === OrderType.Market || this.FOrder.OrderType === OrderType.Limit) && this.FOrder.TimeInForce === OrderTif.Day &&
                this.FOrder.Instrument.InstrType !== InstrumentTypes.OPTIONS && this.FOrder.Instrument.InstrType !== InstrumentTypes.FUTURES && !this.FOrder.Instrument.CFD)) {
                FormattedValue = '';
            } else if (typeof o === 'number' && this.FOrder != null) {
                FormattedValue = this.FOrder.DataCache.formatVolume(this.FOrder.Instrument, o, GeneralSettings.View.DisplayQuantityInLots, this.FOrder.ProductType, this.FOrder.Account);
            }

            if (o === 0) {
                FormattedValue = '';
            }

            break;

        case 37:
            if (typeof o === 'number') {
                FormattedValue = Resources.getResource('panel.orders.ExternalOrderStatus_' + o);
            } else {
                FormattedValue = '';
            }
            break;

        case 39:
        {
            const price = this.FOrder != null ? this.FOrder.EverageFilledPrice : -1;
            if (!isNaN(price)) {
                FormattedValue = this.FOrder.Instrument.formatPrice(price);
            } else {
                FormattedValue = '';
            }
            break;
        }

        case 41:
        case 42:{
            if (this.FOrder?.Instrument != null) {
                FormattedValue = this.FOrder.Instrument.formatPrice(o);
            } else {
                FormattedValue = o != null ? o.toString() : '';
            }
            if (isNaN(o)) {
                FormattedValue = '';
            }

            const trigger = this.FOrder[column == OrderItem.SL_PRICE_COL_INDEX ? 'TriggerSL' : 'TriggerTP'] || this.FOrder.TriggerSLTP;
            if (FormattedValue && trigger) {
                FormattedValue += ' ' + SLTPTrigger.ConvertBidAskRawValueToStr(trigger);
            }

            break;
        }

        case 43:
        case 44:{
            if (this.FOrder?.Instrument != null) {
                FormattedValue = this.FOrder.Instrument.formatOffset(o);
            } else {
                FormattedValue = o != null ? o.toString() : '';
            }
            if (isNaN(o)) { FormattedValue = ''; }

            const tr = this.FOrder[column == OrderItem.SL_OFFSET_COL_INDEX ? 'TriggerSL' : 'TriggerTP'] || this.FOrder.TriggerSLTP;
            if (FormattedValue && tr) {
                FormattedValue += ' ' + SLTPTrigger.ConvertBidAskRawValueToStr(tr);
            }

            break;
        }

        case 47:{ // SLL price
            if (this.FOrder?.Instrument != null) {
                FormattedValue = this.FOrder.Instrument.formatPrice(o);
            } else {
                FormattedValue = o != null ? o.toString() : '';
            }
            if (o === null) {
                FormattedValue = '';
            }

            const trig = this.FOrder.TriggerSL || this.FOrder.TriggerSLTP;
            if (FormattedValue && trig) {
                FormattedValue += ' ' + SLTPTrigger.ConvertBidAskRawValueToStr(trig);
            }

            break;
        }

        // SLL offset
        case 48:{
            if (this.FOrder?.Instrument != null) {
                FormattedValue = this.FOrder.Instrument.formatOffset(o);
            } else {
                FormattedValue = o != null ? o.toString() : '';
            }
            if (o === null) {
                FormattedValue = '';
            }

            const trigg = this.FOrder.TriggerSL || this.FOrder.TriggerSLTP;
            if (FormattedValue && trigg) {
                FormattedValue += ' ' + SLTPTrigger.ConvertBidAskRawValueToStr(trigg);
            }

            break;
        }

        case 45:
            if (this.FOrder?.Instrument != null) {
                FormattedValue = InstrumentUtils.GetLocalizedProductType(this.FOrder.Instrument, o);
            }

            break;

        case 49:
            FormattedValue = OrderUtils.GetFormattedLeverage(o, true) || Resources.getResource('general.N_A');
            break;
        // default:
        // if (this.FOrder != null && this.FOrder.Instrument != null)
        // {
        //    if (o is String)
        //    return o.ToString();
        //    if (o is int)
        //    return this.FOrder.Instrument.formatPrice( o);
        //    if (o is double)
        //    return this.FOrder.Instrument.formatPrice( o);
        //    if (o is DateTime)
        //    return ( o).ToString();
        // }
        // return o != null ? o.ToString() : "";
        }

        if (FormattedValue === null) {
            if (this.FPosition?.Instrument != null) {
                if (typeof o === 'number') {
                    FormattedValue = this.FPosition.Instrument.formatPrice(o);
                } else { FormattedValue = o.toString(); }
            } else {
                FormattedValue = o != null ? o.toString() : '';
            }
        }

        return new ColumnData(o, FormattedValue);
    }

    public override getColumnValue (column: number): any {
        const orderParameterContainer = DataCache.OrderParameterContainer;

        switch (column) {
        case 0:
            return this.FOrder.Instrument.DisplayName();

        case 1:
            return this.FOrder != null ? this.FOrder.GetFullOperationName(false) : '';

        case 2:
            return this.FOrder != null ? DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(this.FOrder.UTCDateTime) : new Date();

        case 3:
            return this.FOrder != null ? DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(this.FOrder.UTCDateTime) : new Date();

        case 4:{
            // Потом надо завести тип ордере - Stop пока такая заплатка
            if (isNullOrUndefined(this.FOrder)) {
                return '';
            }

            const orderTypeObj = orderParameterContainer.GetOrderType(this.FOrder.OrderType);

            return this.FOrder.OrderType === OrderType.Market &&
    this.FOrder.BoundTo !== '-1' &&
    this.FOrder.BoundTo !== ''
                ? Resources.getResource('general.trading.marketCloseFor') + ' ' + this.FOrder.BoundTo
                : Resources.getResource('property.' + orderTypeObj.localizationKey());
        }

        case 5:
            if (this.FOrder != null) {
                // TODO. Shitty code.
                return PositionItem.GetPositionAmountText(this.FOrder as Position);
            } else {
                return 0;
            }

        case 6:
            return this.FOrder != null ? OrderUtils.GetCorrectPriceForOrderType(this.FOrder.Price, this.FOrder.StopLimit, OrderUtils.PriceType.LimitPrice, this.FOrder.OrderType) : NaN;

        case 7:
            return this.FOrder != null ? OrderUtils.GetCorrectPriceForOrderType(this.FOrder.Price, this.FOrder.StopLimit, OrderUtils.PriceType.StopPrice, this.FOrder.OrderType) : NaN;

        case 8:
            return this.FOrder != null ? this.FOrder.CurPriceOpen : NaN;

        case 9:
            // TODO
            if (this.FOrder != null) {
                let tif = Resources.getResource('property.' + OrderTifMap[this.FOrder.TimeInForce]);
                if (this.FOrder.TimeInForce === OrderTif.GTD) {
                    const dtString = DataCache.isAllowedForMyUser(RulesSet.FUNCTION_SHOW_TIME_FOR_GTD)
                        ? DateTimeUtils.formatDate(this.FOrder.ExpireAt, 'DD.MM.YYYY')
                        : this.FOrder.ExpireAt.toLocaleDateString();

                    tif += ' (' + dtString + ')';
                }
                return tif;
            } else { return ''; }

        case 10:
            if (this.FOrder != null) {
                if (!GeneralSettings.View.DisplayQuantityInLots) {
                    return this.FOrder.SharesFilled * this.FOrder.Instrument.LotSize;
                } else {
                    return this.FOrder.SharesFilled;
                }
            } else {
                return 0.0;
            }

        case 11:
            if (this.FOrder != null) {
                if (!GeneralSettings.View.DisplayQuantityInLots) {
                    return this.FOrder.AmountRemaining * this.FOrder.Instrument.LotSize;
                } else {
                    return this.FOrder.AmountRemaining;
                }
            } else {
                return 0.0;
            }

        case 12:
            return '';

        case 13:
            return this.FOrder != null ? this.FOrder.Route : '';

        case 14:
            return '';

        case 15:
            return this.FOrder != null ? this.FOrder.OrderNumber /* FOrder.OrderNumber */ : '';

        case 16:
            return this.FOrder != null ? this.FOrder.Account : null;

        case 17:
            return this.FOrder != null ? this.FOrder.OrderCreatedByUser : '';

        case 18:
            return this.FOrder != null ? this.FOrder.RemoteID : '';

        case 19:
            return '';

        case 20:
            if (this.FOrder?.Instrument?.LastQuote != null) {
                return this.FOrder.Instrument.LastQuote.Route;
            } else {
                return '';
            }

        case 21:
            if (this.FOrder?.SLOrder != null) {
                return (this.FOrder.SLOrder.Price);
            } else {
                return NaN;
            }

        case 22:
            if (this.FOrder?.TPOrder != null) {
                return (this.FOrder.TPOrder.Price);
            } else {
                return NaN;
            }

        case 23:
            if (this.FOrder != null) {
                return (this.FOrder.BoundTo != '-1') ? this.FOrder.BoundTo : ((this.FOrder.PositionId != '-1') ? this.FOrder.PositionId : '');
            } else {
                return '';
            }

        case 24:
            return this.FOrder.Comment;

        case 25:
            if (this.FOrder?.Instrument != null) {
                return InstrumentUtils.getInstrumentTypeStringLocalized(this.FOrder.Instrument.InstrType, this.FOrder.Instrument.CFD);
            }
            return '';

        // +++yura
        case 26:
            if (this.FOrder != null) {
                const account = this.FOrder.Account;
                if (account != null) {
                    return account.userLogin;
                } // TODO
                // return account.userID;
                else {
                    return '';
                }
            } else {
                return '';
            }

        case 27:
            return this.FOrder?.Instrument != null ? this.FOrder.Instrument.DescriptionValue() : '';

        case 28:
            if (this.FOrder.Instrument != null) {
                return this.FOrder.Instrument.TradingExchange;
            }
            return '';

        case 29:
            if (this.FOrder !== null) {
                if (this.FOrder.IsGroupPart) {
                    return this.FOrder.OrderGroupId.toString();
                } else {
                    return '';
                }
            } else { return ''; }

        case 30:
            if (this.userGroupName !== null) {
                return this.userGroupName;
            } else {
                return '';
            }

        case 31:
            return this.FOrder.Status;

        case 32:
            return (this.FOrder !== null)
                ? this.FOrder.getInitMargin(PositionItem.UseAccCurrency()) // FOrder.getInitMargin(true)
                : 0;
        case 33:
            if (this.FOrder !== null && this.FOrder.StrikePrice > 0) {
                return this.FOrder.StrikePrice;
            } else {
                return 0;
            }
        case 34:
            if (this.FOrder !== null && this.FOrder.Instrument !== null && this.FOrder.Instrument.ExpDateReal.getFullYear() > 2000) {
                return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(this.FOrder.Instrument.ExpDateReal);
            } else {
                return DateTimeUtils._ZeroTime;
            }
        case 35:
            if (this.FOrder != null && this.FOrder.TimeInForce == OrderTif.Day) {
                let sign = 1;
                // if (this.FOrder.BuySell != OperationType.Buy && this.sessionSettings.ShowAmountSign)
                //     sign = -1;
                if (this.FOrder.DisclosedQty < 0 && sign < 0) {
                    sign = 1;
                }
                if (!GeneralSettings.View.DisplayQuantityInLots) {
                    return (this.FOrder.DisclosedQty * this.FOrder.Instrument.LotSize * sign);
                } else {
                    return (this.FOrder.DisclosedQty * sign);
                }
            } else {
                return 0;
            }
        case 36:
            return this.FOrder !== null ? this.FOrder.ExternalOrderId : '';
        case 37:
            return this.FOrder !== null ? this.FOrder.ExternalStatus : '';
        case 38:
            return this.FOrder !== null ? this.FOrder.OCOGroupID : '';
        case 39:
            return this.FOrder !== null ? this.FOrder.EverageFilledPrice : NaN;
        case 40:
        {
            if (this.FOrder == null) { return ''; }

            // TODO
            const order = null;

            if (order == null) { return ''; }

            return order.GetOrderComment(this.FOrder);
        }

        case 41:
            if (this.FOrder != null && !isNaN(this.FOrder.StopLossPriceValue)) {
                return this.FOrder.GetStopLossInPriceValue();
            } else {
                return NaN;
            }

        case 42:
            if (this.FOrder != null && !isNaN(this.FOrder.TakeProfitPriceValue)) {
                return this.FOrder.GetTakeProfitInPriceValue();
            } else {
                return NaN;
            }

        case 43:
            if (this.FOrder != null && !isNaN(this.FOrder.StopLossPriceValue)) {
                return this.FOrder.GetStopLossInOffsetValue();
            } else {
                return NaN;
            }

        case 44:
            if (this.FOrder != null && !isNaN(this.FOrder.TakeProfitPriceValue)) {
                return this.FOrder.GetTakeProfitInOffsetValue();
            } else {
                return NaN;
            }

        case 45:{
            let productType = ProductType.General;
            if (this.FOrder?.ProductType) {
                productType = this.FOrder.ProductType;
            }

            return productType;
        }

        case 47:
            if (this.FOrder != null && this.FOrder.StopLossLimitPriceValue !== null && this.FOrder.StopLossLimitPriceValue != null) {
                return this.FOrder.GetStopLossLimitInPriceValue();
            } else {
                return null;
            }

        case 48:
            if (this.FOrder != null && this.FOrder.StopLossLimitPriceValue !== null && this.FOrder.StopLossLimitPriceValue != null) {
                return this.FOrder.GetStopLossLimitInOffsetValue();
            } else {
                return null;
            }

        case 49: // Leverage
            return this.FOrder?.Leverage ? this.FOrder.Leverage : null;

        default:
            return '';
        }
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = OrderItem.columnsParams[column];
        const isHidden = Resources.isHidden(result.HeaderKey);
        result.MenuIndex = this.GetMenuIndex(result.HeaderKey);

        result.Hidden = isHidden;
        if (column === 3 || column === 13 || column === 32 || column === 30 || column === 24 || column === 21 || column === 22 /* какое-то правило №3 */ || column === 12 || column === 14 || (column >= 17 && column <= 20)) {
            result.Hidden = true;
        }

        // Product type
        if (column === 45) {
            result.Hidden = !RiskPlan.showProductType;
        }

        if ((column === 43 || column === 44 || column === 47 || column === 48) && IsAllowed.IsProtectiveOrders()) {
            result.Hidden = true;
        } // #108200 doc 4.2

        return result;
    }

    public override fireUpdate (): void {

    }

    public override GetCurrentAccount (): Account {
        return this.FOrder.Account;
    }

    public override GetCurrentInstrument (): Instrument {
        return this.FOrder.Instrument;
    }

    public GetOrderType (): OrderType {
        return this.FOrder.OrderType;
    }

    public override InstrumentName (): string {
        return this.FOrder.Instrument.GetInteriorID();
    }

    public DisplayInstrumentName (): string {
        return this.FOrder.Instrument.DisplayName();
    }

    public override isModifyAllowed (columnIndex: number): boolean {
        const order = this.FOrder;

        let modifyAllow = IsAllowed.IsOrderModifyingAllowed(order).Allowed;

        if (!modifyAllow) {
            return modifyAllow;
        }

        const acc = this.GetCurrentAccount();
        const ins = this.GetCurrentInstrument();
        const orderType = this.GetOrderType();
        const isSLTP = order.isClosingOrder() && (order.SLOrder !== null || order.TPOrder !== null); // #88431 isSLTP != order.isClosingOrder() для частично зафиленных ордеров, добавил доп проверку

        if (columnIndex == OrderItem.QUANTITY_COL_INDEX) {
            modifyAllow = !isSLTP;
        } else
            if (columnIndex == OrderItem.SL_PRICE_COL_INDEX || columnIndex == OrderItem.SL_OFFSET_COL_INDEX) {
                modifyAllow = IsAllowed.IsSLTPAllowed(ins, acc, true).Allowed && !isSLTP && !(order.isClosingOrder() && order.IsActivated);
            } else
                if (columnIndex == OrderItem.TP_PRICE_COL_INDEX || columnIndex == OrderItem.TP_OFFSET_COL_INDEX) {
                    modifyAllow = IsAllowed.IsSLTPAllowed(ins, acc, false).Allowed && !isSLTP;
                } else
                    if (columnIndex == OrderItem.PRICE_COL_INDEX) {
                        modifyAllow = orderType !== OrderType.Stop && orderType !== OrderType.TrailingStop && orderType !== OrderType.Market;
                    } else
                        if (columnIndex == OrderItem.STOP_PRICE_COL_INDEX) {
                            modifyAllow = orderType == OrderType.Stop || // #92963
                            (orderType == OrderType.StopLimit && !order.IsActivated) ||
                            orderType == OrderType.TrailingStop;
                        }

        return modifyAllow;
    }

    /// <summary>
    /// Здесь хранятся настройки колонок (один экземпляр на класс)
    /// </summary>
    public static readonly columnsParams = [

        //   /headerKey /width /columnType /showTotal /visible /allowGrouping /hidden /beginGroupFlag /emptyValue
        /* 0 */ new ColumnParams('panel.orders.symbol', 65, 0, true, true, true),
        new ColumnParams('panel.orders.action', 65, 0, false, true, true),
        new ColumnParams('panel.orders.date_time', 65, QuickTableColumnType.COL_DATE_SORT, false, false, true),
        new ColumnParams('panel.orders.time', 65, QuickTableColumnType.COL_TIME_SORT, false, false, false),
        new ColumnParams('panel.orders.type', 65, 0, false, true, true),
        /* 5 */ new ColumnParams('panel.orders.quantity', 65, QuickTableColumnType.COL_MORELESS_NUMERIC, true, true, false, null, null, null, true),
        new ColumnParams('panel.orders.price', 65, 1, false, true, false, null, null, null, true),
        new ColumnParams('panel.orders.StopPrice', 65, 1, false, true, false, null, null, null, true),
        new ColumnParams('panel.orders.CurrentPrice', 65, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.orders.TIF', 65, 0, false, true, false),
        /* 10 */ new ColumnParams('panel.orders.QuantityFilled', 65, 1, true, false, false),
        new ColumnParams('panel.orders.QuantityRemaining', 65, 1, true, false, false),
        new ColumnParams('panel.orders.Event', 65, 0, false, false, false),
        new ColumnParams('panel.orders.Route', 65, 0, false, false, false),
        new ColumnParams('panel.orders.Token', 65, 0, false, false, false),
        /* 15 */ new ColumnParams('panel.orders.Reference', 65, 0, false, false, false),
        new ColumnParams('panel.orders.Account', 65, 0, false, false, true),
        new ColumnParams('panel.orders.TraderID', 65, 0, false, false, false),
        new ColumnParams('panel.orders.RemoteID', 65, 0, false, false, false),
        new ColumnParams('panel.orders.Ct_Ahead', 65, 0, false, false, false),
        /* 20 */ new ColumnParams('panel.orders.Market', 65, 0, false, false, false),
        new ColumnParams('panel.orders.SL', 65, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, true),
        new ColumnParams('panel.orders.TP', 65, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, true),
        new ColumnParams('panel.orders.BindTo', 65, 0, false, false, false),
        new ColumnParams('panel.orders.Comments', 65, 0, false, false, false),
        /* 25 */ new ColumnParams('panel.orders.Instr_Type', 70, 0, false, false, true),
        new ColumnParams('panel.orders.Login', 70, 0, false, false, false),
        new ColumnParams('panel.orders.symbolDescription', 65, 0, true, false, false),
        new ColumnParams('panel.orders.ExchangeTrading', 65, 0, false, true),
        new ColumnParams('panel.orders.GroupId', 70, 0, false, false, false),
        /* 30 */ new ColumnParams('panel.orders.UserGroup', 70, 0, false, false, false),
        new ColumnParams('panel.orders.Status', 70, 0, false, false, false),
        new ColumnParams('panel.orders.InitReq', 70, 1, true, false, false),
        new ColumnParams('panel.orders.StrikePrice', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.orders.ExpirationDate', 40, QuickTableColumnType.COL_DATE_SORT, false, false, false),
        /* 35 */ new ColumnParams('panel.orders.disclosedQty', 65, QuickTableColumnType.COL_MORELESS_NUMERIC, true, false, false),
        new ColumnParams('panel.orders.ExternalOrderId', 65, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.orders.ExternalOrderStatus', 65, 0, false, false, true),
        new ColumnParams('panel.orders.OCOGrouID', 65, 0, false, false, false),
        new ColumnParams('panel.orders.AvgFilledPrice', 65, 0, false, false, false),
        /* 40 */ new ColumnParams('panel.orders.Comment', 65, 0, false, false, false),
        new ColumnParams('panel.orders.SL_Price', 65, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, null, null, null, true),
        new ColumnParams('panel.orders.TP_Price', 65, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, null, null, null, true),
        new ColumnParams('panel.orders.SL_Offset', 65, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, null, null, null, true),
        new ColumnParams('panel.orders.TP_Offset', 65, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, null, null, null, true),
        /* 45 */ new ColumnParams('panel.orders.ProductType', 65, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.orders.Cancel', 70, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams('panel.orders.SLL_Price', 65, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, null, null, null, true),
        new ColumnParams('panel.orders.SLL_Offset', 65, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, null, null, null, true),
        new ColumnParams('panel.orders.Leverage', 65, QuickTableColumnType.COL_DEFAULT, false, false, true)
    /* 50 */
    ];

    public static readonly QUANTITY_COL_INDEX = 5;
    public static readonly PRICE_COL_INDEX = 6;
    public static readonly STOP_PRICE_COL_INDEX = 7;
    public static readonly SL_PRICE_COL_INDEX = 41;
    public static readonly TP_PRICE_COL_INDEX = 42;
    public static readonly SL_OFFSET_COL_INDEX = 43;
    public static readonly TP_OFFSET_COL_INDEX = 44;

    public override NeedSortMenu (): boolean {
        return true;
    }

    public GetMenuIndex (headerKey: string): number {
        if (OrderItem.ListMenuIndex.Contains(headerKey)) {
            return OrderItem.ListMenuIndex.indexOf(headerKey);
        } else {
            return 1000;
        }
    }

    // TODO. Refactor.
    public static localizeOrderStatus (status: OrderStatus): string {
        if (isNullOrUndefined(status)) {
            return '';
        }

        const s = OrderStatus;
        let key = 'UNKNOWN';
        switch (status) {
        case s.UNKNOWN:
            key = 'UNKNOWN';
            break;
        case s.REPLACED:
            key = 'REPLACED';
            break;
        case s.PENDING_NEW:
            key = 'PENDING_NEW';
            break;
        case s.PENDING_EXECUTION:
            key = 'PENDING_EXECUTION';
            break;
        case s.PENDING_CANCEL:
            key = 'PENDING_CANCEL';
            break;
        case s.PENDING_REPLACE:
            key = 'PENDING_REPLACE';
            break;
        case s.PENDING_REPLACE_NOT_ACTIVE:
            key = 'PENDING_REPLACE_NOT_ACTIVE';
            break;
        case s.NEW:
            key = 'NEW';
            break;
        case s.ACCEPTED:
            key = 'ACCEPTED';
            break;
        case s.PART_FILLED:
            key = 'PART_FILLED';
            break;
        case s.FILLED:
            key = 'FILLED';
            break;
        case s.CANCELED:
            key = 'CANCELED';
            break;
        case s.REFUSED:
            key = 'REFUSED';
            break;
        case s.WAITING_MARKET:
            key = 'WAITING_MARKET';
            break;
        case s.OFF_MARKET:
            key = 'OFF_MARKET';
            break;
        case s.UNPLACED:
            key = 'UNPLACED';
            break;
        }

        return Resources.getResource('Order.OrderStatus.STATUS_' + key);
    }

    public static readonly ListMenuIndex = [
        'panel.orders.Account',
        'panel.orders.Login',
        'panel.orders.Route',
        'panel.orders.symbol',
        'panel.orders.symbolDescription',
        'panel.orders.Instr_Type',
        'panel.orders.Reference',
        'panel.orders.GroupId',
        'panel.orders.action',
        'panel.orders.type',
        'panel.orders.price',
        'panel.orders.CurrentPrice',
        'panel.orders.quantity',
        'panel.orders.disclosedQty',
        'panel.orders.date_time',
        'panel.orders.date',
        'panel.orders.time',
        'panel.orders.QuantityRemaining',
        'panel.orders.QuantityFilled',
        'panel.orders.TIF',
        'panel.orders.StopPrice',
        'panel.orders.SL',
        'panel.orders.TP',
        'panel.orders.InitReq',
        'panel.orders.Status',
        'panel.orders.BindTo',
        'panel.orders.StrikePrice',
        'panel.orders.ExpirationDate',
        'panel.orders.ExternalOrderId',
        'panel.orders.ExternalOrderStatus',
        'panel.orders.SL_Price',
        'panel.orders.TP_Price',
        'panel.orders.SL_Offset',
        'panel.orders.TP_Offset',
        'panel.orders.SLL_Price',
        'panel.orders.SLL_Offset',
        'panel.orders.Leverage',
        'panel.orders.OCOGrouID',
        'panel.orders.ExchangeTrading',

        'panel.orders.Cancel'
    ];
}
