// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../Commons/properties/Resources';
import { MainWindowManager } from './UtilsClasses/MainWindowManager';
import { PanelLocKeys } from './UtilsClasses/FactoryConstants';
import { AUTO_HIDE_AVALIABLE_PANELS_COUNT, AUTO_HIDE_BLOCK_WIDTH, BOTTOM_WINDOWS_MARGIN, PADDING_OFFSET_LEFT, SIDE_BAR_PANEL, TOP_BOTTOM_PADDING_OFFSET, TOP_WINDOWS_MARGIN } from './UtilsClasses/SizeConstants';
import { CustomEvent } from '../Utils/CustomEvents';
import $ from 'jquery';

declare global{
    interface Window {
        dockspawn: any
        DockSystem: any
    }
}

export class DockSystem {
    public static divForFloatWindow: any = null;
    public static hiddenPanelsContainer: any = null;

    public DivForFloatWindow: any = null;
    public HiddenPanelsContainer: any = null;
    public dockChangedEvent = new CustomEvent();
    public dockRebuildEvent = new CustomEvent();

    public dockManager: any = null;
    public documentNode: any = null;
    public divDockManager: any = null;
    public emptyState: any = null;
    public parentNodeID: any = null;
    public parentNodeDOM: any = null;
    public currentPageDockedWindowsForRestore: any = {};
    public currentPageDockedWindowsNeedFocusAfterRestore: any = [];
    public procPool: any = {};
    public AutoHidePanelsPool: any = {};
    public AutoHide: boolean = false;
    public AutoHideContainer: any = null;
    public documentManagerContainerWasFound: boolean;
    public toRemoveHash: any[];

    private lastChangedPanel: string;

    constructor () {
        this.dockChangedEvent.Subscribe(this.dockChangedCallback, this);
    }

    public static InitSystem (parentNode): void {
        const parentNodeDOM = document.getElementById(parentNode);
        const innerContainer = document.createElement('div');
        innerContainer.style.display = 'inline-block';

        // TODO fix exception when /myportfolio is loading
        if (!parentNodeDOM) return;

        const divDockManager = parentNodeDOM.appendChild(innerContainer);
        const dockManager = new window.dockspawn.DockManager(divDockManager);
        dockManager.initialize();

        const DS = DockSystemInstance;

        DS.dockManager = dockManager;
        DS.divDockManager = divDockManager;
        DS.documentNode = dockManager.context.model.documentManagerNode;
        // DS.emptyState = DockSystemInstance.getState();
        DS.parentNodeID = parentNode;
        DS.parentNodeDOM = parentNodeDOM;

        window.onresize = function () {
            DockSystemInstance.procPool.resize = DockSystemInstance.resizeDockSystem.bind(DockSystemInstance);
        };
        window.onresize(null);
        DockSystem.divForFloatWindow = DS.DivForFloatWindow = document.getElementById('AttachedPanelsContainer');
        DockSystem.hiddenPanelsContainer = DS.HiddenPanelsContainer = document.getElementById('HiddenPanelsContainer');
    }

    public resizeDockSystem (): void {
        if (this.divDockManager && this.dockManager) {
            const w = window.innerWidth - (this.divDockManager.clientLeft + 2 * PADDING_OFFSET_LEFT + SIDE_BAR_PANEL) - (this.AutoHide ? AUTO_HIDE_BLOCK_WIDTH : 0);
            const h = window.innerHeight - (this.divDockManager.clientTop + TOP_WINDOWS_MARGIN + BOTTOM_WINDOWS_MARGIN + 2 * TOP_BOTTOM_PADDING_OFFSET);
            this.dockManager.resize(w, h);
            if (this.AutoHideContainer) {
                this.AutoHideContainer.style.height = h + 'px';
            }
        }
    }

    public proccess (): void {
        if (this.procPool.resize) {
            this.procPool.resize();
            delete this.procPool.resize;
        }
    }

    public addPanelOnDock (panel, dockPlace, lockedWorkspace): void {
        const newDOM_Element = panel.find('*');
        newDOM_Element._ractiveObject = panel;
        const newPanel = new window.dockspawn.PanelContainer(newDOM_Element, this.dockManager, panel.get('header'));
        panel.setTitle = newPanel.setTitle.bind(newPanel);
        panel.updateLinkVisible = newPanel.updateLinkVisible.bind(newPanel);
        panel.containerSetFocus = newPanel.setFocus.bind(newPanel);
        panel.PanelContainer = newPanel;

        let documentManagerNode = this.dockManager.context.model.documentManagerNode;
        let newDockedPanelNode = null;

        const referenceNode = this.findReferenceNodeForDocking(
            this.dockManager.context.model.rootNode,
            null,
            this.getDockPlaceInfo(dockPlace));

        newPanel.dockPlace = dockPlace;

        if (referenceNode) {
            const newNode = this.dockManager.CreateDockNode(newPanel);
            documentManagerNode = referenceNode;
        } else {
            if (dockPlace === DockPlaceConst.Middle && // #88009
            documentManagerNode.container &&
            !documentManagerNode.container.isDocumentManagerContainer) // if need correction
            {
                this.documentManagerContainerWasFound = false;
                this.correctDocumentManagerContainer(this.dockManager.context.model.rootNode);
                documentManagerNode = this.dockManager.context.model.documentManagerNode; // update after correction
            }
        }

        const referenceNodeHasSameDockPlace = referenceNode && referenceNode.container.dockPlace == dockPlace;
        if (referenceNodeHasSameDockPlace) {
            dockPlace = DockPlaceConst.Middle;
        }

        switch (dockPlace) {
        case DockPlaceConst.Middle:
            newDockedPanelNode = this.dockManager.dockFill(documentManagerNode, newPanel);
            break;
        case DockPlaceConst.Up:
            newDockedPanelNode = this.dockManager.dockUp(documentManagerNode, newPanel, 0.3);
            break;
        case DockPlaceConst.Down:
            newDockedPanelNode = this.dockManager.dockDown(documentManagerNode, newPanel, 0.3);
            break;
        case DockPlaceConst.Left:
            newDockedPanelNode = this.dockManager.dockLeft(documentManagerNode, newPanel, 0.3);
            break;
        case DockPlaceConst.Right:
            newDockedPanelNode = this.dockManager.dockRight(documentManagerNode, newPanel, 0.3);
            break;
        }
        this.tryPermanentlyHideCloseButton(newPanel, panel);
    }

    public addAutoHideContainer (): void {
        const newNode = document.createElement('div');
        newNode.classList.add('oe_vertical_autohide_container');
        this.AutoHideContainer = newNode;
        this.parentNodeDOM.appendChild(newNode);
    }

    public RegisterPanel (panel): void {
        this.AutoHidePanelsPool[panel._guid] = panel;
    }

    public UnRegisterPanel (panel): void {
        delete this.AutoHidePanelsPool[panel._guid];
    }

    public UpdatePanelsAutoHideSate (): void {
        const isAv = this.isAutoHideAvaliable();
        for (const panel_g in this.AutoHidePanelsPool) {
            const panel = this.AutoHidePanelsPool[panel_g];
            if (panel?.UpdateAutoHideSate) {
                panel.UpdateAutoHideSate(isAv);
            }
        }
    }

    public ForcedHideOthers (iniPanel): void {
        const panel_ID = iniPanel._guid;
        for (const panel_g in this.AutoHidePanelsPool) {
            if (panel_g === panel_ID) {
                continue;
            }

            const panel = this.AutoHidePanelsPool[panel_g];
            if (panel?.ForcedAutoHide) {
                panel.ForcedAutoHide();
            }
        }
    }

    public isAutoHideAvaliable (): boolean {
        if (!this.AutoHideContainer) {
            return true;
        }

        return DockSystemInstance.AutoHideContainer.childElementCount < AUTO_HIDE_AVALIABLE_PANELS_COUNT;
    }

    public addToAutoHide (panel): void {
        const newNode = document.createElement('div');
        newNode.classList.add('oe_vertical_autohide');
        newNode.innerText = panel.get('header');

        this.AutoHide = true;
        if (!this.AutoHideContainer) { this.addAutoHideContainer(); }
        this.AutoHideContainer.appendChild(newNode);

        panel._myNodeAutoHide = newNode;
        panel.AutoHideHeaderUpdateHandler = function (headerText) { this._myNodeAutoHide.innerText = headerText; };
        const me = this;
        newNode.addEventListener('mouseenter', function (event) {
            panel.onMouseEnter();
            me.ForcedHideOthers(panel);
        });
        newNode.addEventListener('mouseleave', function (event) {
            panel.onMouseLeave();
        });
        window.onresize(null);
        this.UpdatePanelsAutoHideSate();
    }

    public removeFromAutoHide (panel): void {
        const node = panel._myNodeAutoHide;
        panel.AutoHideHeaderUpdateHandler = null;
        if (node) {
            node.parentNode.removeChild(node);
        }
        if (!this.AutoHideContainer) {
            this.AutoHide = false;
        }
        if (this.AutoHideContainer && this.AutoHideContainer.childElementCount === 0) {
            this.AutoHide = false;
            this.AutoHideContainer.parentNode.removeChild(this.AutoHideContainer);
            this.AutoHideContainer = null;
        }

        window.onresize(null);
        this.UpdatePanelsAutoHideSate();
    }

    public tryPermanentlyHideCloseButton (panelContainer, panel): void {
    // +++
        const panelType = panel.getType();
        if (panelType) {
            const panelTypeLocKey = PanelLocKeys[panelType];
            if (panelTypeLocKey && Resources.isHidden(panelTypeLocKey + '.allowPanelCloseFromButton')) {
                panel.set('isCloseButtonAlwaysHidden', true);
                panelContainer.hideCloseButton(true);
            }
        }
    };

    public addToArrRestore (panel): void {
        const newDOM_Element = panel.find('*');
        newDOM_Element._ractiveObject = panel;

        const id = panel.get('workSpaceId');
        if (id !== null) {
            this.currentPageDockedWindowsForRestore[id] = newDOM_Element;
        }
    }

    // isDock: if true - panel dock, false - panel undock
    public dockChangedCallback (panelName: string, isDock: boolean): void {
        if (this.lastChangedPanel === panelName && isDock) {
            return;
        }

        this.lastChangedPanel = panelName;

        this.dockRebuildEvent.Raise();
    }

    public clearDocking (): void {
        $(this.parentNodeDOM).empty();
        $(DockSystem.divForFloatWindow).empty();
        DockSystem.InitSystem(this.parentNodeID);
        this.currentPageDockedWindowsForRestore = {};
        this.dockRebuildEvent.clearSubscribers();

        this.AutoHidePanelsPool = {};
        this.AutoHide = false;
        this.AutoHideContainer = null;
    }

    public getState (): any {
        const state = this.dockManager.saveState();
        // console.log(state);
        return state;
    }

    public refresh (): void {
        this.dockManager.rebuildLayout(this.dockManager.context.model.documentManagerNode);
    }

    public setState (state): void {
        if (state) {
            this.dockManager.loadState(state);
            this.MigationProcess();
        }
    }

    public getPanelForDocking (id): any {
        const panel = this.currentPageDockedWindowsForRestore[id];
        if (!panel) {
            return null;
        }
        delete this.currentPageDockedWindowsForRestore[id];
        return panel;
    }

    public goInProcces (panel, defaultDockPlace): void {
        if (!panel) return;

        panel.dockPlace = defaultDockPlace;
        panel.append = true;
        panel.render(DockSystem.hiddenPanelsContainer);
        MainWindowManager.MainWindow.registerControl(panel);
    }

    public setPlace (id, dockPlace): void {
        const panel = this.getPanelForDocking(id);

        const toRestoreContainer = this.dockManager.restoredHashContainers[id];
        if (!panel?._ractiveObject || !toRestoreContainer) {
            return;
        }

        panel._ractiveObject.setSize(toRestoreContainer.elementContentHost.clientWidth, toRestoreContainer.elementContentHost.clientHeight);
        panel._ractiveObject.setTitle = toRestoreContainer.setTitle.bind(toRestoreContainer);
        panel._ractiveObject.containerSetFocus = toRestoreContainer.setFocus.bind(toRestoreContainer);
        panel._ractiveObject.setTitle(panel._ractiveObject.get('header'));
        panel._ractiveObject.updateLinkVisible = toRestoreContainer.updateLinkVisible.bind(toRestoreContainer);
        toRestoreContainer.elementContent.parentElement.replaceChild(panel, toRestoreContainer.elementContent);

        toRestoreContainer.elementContent = panel;
        toRestoreContainer.dockPlace = dockPlace;
        toRestoreContainer.createHeaderMenuButton();
        toRestoreContainer.createFullscreenModeButton();
        toRestoreContainer.createLinkElements();

        this.tryPermanentlyHideCloseButton(toRestoreContainer, panel._ractiveObject);

        if (toRestoreContainer.restoredActiveFlag) {
            this.currentPageDockedWindowsNeedFocusAfterRestore.push(panel._ractiveObject);
        }

        if (Object.keys(this.currentPageDockedWindowsForRestore).length === 0) {
            let i = 0;
            const len = this.currentPageDockedWindowsNeedFocusAfterRestore.length;
            for (i = 0; i < len; i++) {
                this.currentPageDockedWindowsNeedFocusAfterRestore[i].setFocus();
            }
            this.currentPageDockedWindowsNeedFocusAfterRestore = [];
        }
    }

    // Ignores newPanelNode.
    public findReferenceNodeForDocking (startNode, newPanelNode, dockPlaceInfo) {
        if (startNode === newPanelNode || !dockPlaceInfo) {
            return null;
        }

        const children = startNode.children;
        const len = children ? children.length : 0;

        const containerType = startNode.container.containerType;
        const useFirstMatchedNode = dockPlaceInfo.useFirstMatchedNode;
        const chosenContainerType = dockPlaceInfo.containerType;
        if (chosenContainerType === containerType) {
            if (len) {
                return this.getFirstPanel(
                    children[useFirstMatchedNode ? 0 : len - 1],
                    newPanelNode);
            } else {
                return null;
            }
        } else {
            for (let i = useFirstMatchedNode ? 0 : len - 1;
                useFirstMatchedNode ? i < len : i >= 0;
                useFirstMatchedNode ? i++ : i--) {
                const result = this.findReferenceNodeForDocking(
                    children[i],
                    newPanelNode,
                    dockPlaceInfo);

                if (result) {
                    return result;
                }
            }
        }

        return null;
    }

    // Ignores newPanelNode.
    public getFirstPanel (node, newPanelNode) {
        if (!node || node === newPanelNode) {
            return null;
        }

        if (node.container.containerType === 'panel' || (node.container.containerType === 'fill') && !node.children.length) {
            return node;
        }

        const children = node.children;
        const len = children ? children.length : 0;
        for (let i = 0; i < len; i++) {
            const result = this.getFirstPanel(children[i], newPanelNode);
            if (result) {
                return result;
            }
        }

        return null;
    }

    public getDockPlaceInfo (dockPlace: DockPlaceConst): { containerType: string, useFirstMatchedNode: boolean } {
        switch (dockPlace) {
        case DockPlaceConst.Middle:
            return null;
        case DockPlaceConst.Up:
            return { containerType: 'vertical', useFirstMatchedNode: true };
        case DockPlaceConst.Down:
            return { containerType: 'vertical', useFirstMatchedNode: false };
        case DockPlaceConst.Left:
            return { containerType: 'horizontal', useFirstMatchedNode: true };
        case DockPlaceConst.Right:
            return { containerType: 'horizontal', useFirstMatchedNode: false };
        }

        return null;
    }

    public correctDocumentManagerContainer (curNode): void // #88009
    {
        if (this.documentManagerContainerWasFound) {
            return;
        }

        if (curNode.container?.isDocumentManagerContainer) {
            this.dockManager.context.model.documentManagerNode = curNode;
            this.documentManagerContainerWasFound = true;
            return;
        }

        const childs = curNode.children;

        for (let i = 0; i < childs.length; i++) {
            this.correctDocumentManagerContainer(childs[i]);
        }
    }

    public deletePanelFromDockSystemState (id): void {
        if (!this.toRemoveHash) {
            this.toRemoveHash = [];
        }

        this.toRemoveHash.push(id);
    }

    public MigationProcess (): void {
        if (!this.toRemoveHash) {
            return;
        }

        for (let i = 0; i < this.toRemoveHash.length; i++) {
            const id = this.toRemoveHash[i];
            const toRestoreContainer = this.dockManager.restoredHashContainers[id];
            if (!toRestoreContainer) {
                return;
            }
            toRestoreContainer.onCloseButtonClicked();
        }
        this.toRemoveHash.length = 0;
    }
}

export enum DockPlaceConst {
    Middle = 0,
    Up = 1,
    Down = 2,
    Left = 3,
    Right = 4
};

// DockSystem.divForFloatWindow;

// need forTest Dock system
// function hierarhyTest () {
//     DockSystemInstance.dockManager.layoutEngine.logHierarhy(DockSystemInstance.dockManager.context.model.rootNode, 0);
// }

export const DockSystemInstance = new DockSystem();

// window HACK
window.DockSystem = DockSystemInstance;
