// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { messageBoxHandler } from '../../../Utils/AppHandlers';
import { PositionSizeButtonTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { LOCALE_EN, Resources } from '../../../Commons/properties/Resources';
import { AccountType } from '../../../Utils/Account/AccountType';
import { RulesSet } from '../../../Utils/Rules/RulesSet';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { IsAllowed } from '../../../Commons/IsAllowed';
import { ConfirmationTypesEnum } from '../../../Utils/Trading/ConfirmationTypesEnum';
import { DataCache } from '../../../Commons/DataCache';
import { type Account } from '../../../Commons/cache/Account.js';

export class PositionSizeButton extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'PositionSizeButton'; }

    public override oninit (): void {
        super.oninit();

        this.on('onClick', this.onClick);
        Resources.onLocaleChange.Subscribe(this.localize, this);
        DataCache.OnTradingSessionStatusUpdated.Subscribe(this.updateEnability, this);
        GeneralSettings.SettingsChanged.Subscribe(this.updateEnability, this);

        this.observe('account instrument', this.updateEnability);

        this.localize();
    }

    public override onClick (): void {
        if (!this.get('positionSizingEnabled')) { return; }

        const checked: boolean = this.get('checked');
        const newCheckedState = !checked;

        void this.set('checked', newCheckedState);

        const confirmationType = ConfirmationTypesEnum.PositionSizeCalculatorInfoWindow;
        if (newCheckedState && GeneralSettings.Confirmations.useConfirmation(confirmationType)) {
            const OkCb = (showNextTime): void => {
                GeneralSettings.Confirmations.updateConfirmation(confirmationType, showNextTime);
            };

            let infoText = Resources.getResource('positionSizeCalculator.infoText');

            if (!Resources.isHidden('positionSizeCalculator.helpLink')) {
                const linkTxt = Resources.getResource('positionSizeCalculator.infoText.linkPart');
                const linkUrl = Resources.getResourceLang('positionSizeCalculator.helpLink', LOCALE_EN);
                const linkPartSplitted = linkTxt.split('{0}');
                infoText += ' ' + (linkPartSplitted[0] ? linkPartSplitted[0] : '') +
                    // '<div class="link-container-in-pos-sizing-info">' + (linkPartSplitted[0] ? linkPartSplitted[0] : '') +  // https://tp.traderevolution.com/entity/107977 не удалял насовсем вариант с переносом на новую строку ибо он мне нравится больше, пускай хоть комментарием останется :)
                    '<a target="_blank" class="link-in-pos-sizing-info" rel="noopener noreferrer" href="' + linkUrl + '">' +
                    (linkPartSplitted[1] ? linkPartSplitted[1] : '') +
                    '</a>' + (linkPartSplitted[2] ? linkPartSplitted[2] : '');
                // + '</div>'
            }

            messageBoxHandler.Show(Resources.getResource('positionSizeCalculator.header'),
                infoText,
                messageBoxHandler.msgType.Info, OkCb, null, true, true, null);
        }
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);
        DataCache.OnTradingSessionStatusUpdated.UnSubscribe(this.updateEnability, this);
        GeneralSettings.SettingsChanged.UnSubscribe(this.updateEnability, this);

        super.dispose();
    }

    public localize (): void {
        const tooltipLocKey = this.isAllowedToUse()
            ? 'positionSizeCalculator.button.tt'
            : 'positionSizeCalculator.buttonDisabled.tt';

        void this.set('tooltip', Resources.getResource(tooltipLocKey));
    }

    public updateEnability (): void {
        const enabled = this.isAllowedToUse();
        void this.set('positionSizingEnabled', enabled);

        if (!enabled) { void this.set('checked', false); }

        this.localize();
    }

    public isAllowedToUse (): boolean {
        const acc: Account = this.get('account');
        if (isNullOrUndefined(acc)) return false;

        let isSLAllowed = DataCache.isAllowedForAccount(RulesSet.FUNCTION_SLTP, acc) &&
            IsAllowed.IsSLTPAllowed(this.get('instrument'), acc, true).Allowed;

        if (acc.AccountType === AccountType.MultiAsset) {
            isSLAllowed = false;
        }

        return isSLAllowed;
    }
}

ContainerControl.extendWith(PositionSizeButton, {
    template: PositionSizeButtonTemplate,
    data: function () {
        return {
            checked: false,
            instrument: null,
            account: null,
            positionSizingEnabled: true
        };
    }
});
