// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iSAR extends iBuildInIndicator {
    public FStep: any;
    public FMaximum: any;

    public first: boolean = false;
    public dirlong: boolean = false;
    public start: number = 0;
    public last_high: number = 0;
    public last_low: number = 0;
    public ep: number = 0;
    public sar: number = 0;
    public price_low: number = 0;
    public price_high: number = 0;

    constructor (step, maximum) {
        super(1);

        this.FStep = step;
        this.FMaximum = maximum;
    }

    override get Name (): string { return 'iSAR'; }

    override get Key (): string { return this.DefaultKey + this.FStep + this.FMaximum; }

    public override OnQuote (): void {
        if (this.FCount == 1) {
            return;
        }

        if (this.first) {
            this.price_low = this.GetPrice(PriceType.Low, 0);
            this.price_high = this.GetPrice(PriceType.High, 0);
            if (this.last_low > this.price_low) {
                this.last_low = this.price_low;
            }
            if (this.last_high < this.price_high) {
                this.last_high = this.price_high;
            }

            if (this.price_high > this.GetPrice(PriceType.High, 1) && this.price_low > this.GetPrice(PriceType.Low, 1)) {
                this.SetValue(0, 0, this.GetPrice(PriceType.Low, 1));
                this.first = false;
                return;
            }
            if (this.price_high < this.GetPrice(PriceType.High, 1) && this.price_low < this.GetPrice(PriceType.Low, 1)) {
                this.dirlong = false;
                this.SetValue(0, 0, this.GetPrice(PriceType.High, 1));
                this.first = false;
                return;
            }
        }

        this.price_low = this.GetPrice(PriceType.Low, 0);
        this.price_high = this.GetPrice(PriceType.High, 0);
        const price = this.GetValue(1);
        // --- check for reverse
        if (this.dirlong && this.price_low < price) {
            this.start = this.FStep; this.dirlong = false;
            this.ep = this.price_low;
            this.last_low = this.price_low;
            this.SetValue(this.last_high);
            return;
        }
        if (!this.dirlong && this.price_high > price) {
            this.start = this.FStep; this.dirlong = true;
            this.ep = this.price_high;
            this.last_high = this.price_high;
            this.SetValue(this.last_low);
            return;
        }
        // --- calculate current value
        this.sar = price + this.start * (this.ep - price);
        // ---- check long direction
        if (this.dirlong) {
            if (this.ep < this.price_high && (this.start + this.FStep) <= this.FMaximum) {
                this.start += this.FStep;
            }
            if (this.price_high < this.GetPrice(PriceType.High, 1) && this.FCount == 2) {
                this.sar = price;
            }
            if (this.sar > this.GetPrice(PriceType.Low, 1)) {
                this.sar = this.GetPrice(PriceType.Low, 1);
            }
            if (this.sar > this.GetPrice(PriceType.Low, 2)) {
                this.sar = this.GetPrice(PriceType.Low, 2);
            }
            if (this.sar > this.price_low) {
                this.start = this.FStep; this.dirlong = false; this.ep = this.price_low;
                this.last_low = this.price_low;
                this.SetValue(this.last_high);
                return;
            }
            if (this.ep < this.price_high) {
                this.last_high = this.price_high; this.ep = this.price_high;
            }
        } else {
            if (this.ep > this.price_low && (this.start + this.FStep) <= this.FMaximum) {
                this.start += this.FStep;
            }
            if (this.price_low < this.GetPrice(PriceType.Low, 1) && this.FCount == 2) {
                this.sar = price;
            }
            if (this.sar < this.GetPrice(PriceType.High, 1)) {
                this.sar = this.GetPrice(PriceType.High, 1);
            }
            if (this.sar < this.GetPrice(PriceType.High, 2)) {
                this.sar = this.GetPrice(PriceType.High, 2);
            }
            if (this.sar < this.price_high) {
                this.start = this.FStep; this.dirlong = true; this.ep = this.price_high;
                this.last_high = this.price_high;
                this.SetValue(this.last_low);
                return;
            }
            if (this.ep > this.price_low) {
                this.last_low = this.price_low; this.ep = this.price_low;
            }
        }
        this.SetValue(this.sar);
    }

    public override Refresh (count, newThread): void {
        this.first = true;
        this.dirlong = true;
        this.start = this.FStep;
        this.last_high = -10000000.0;
        this.last_low = 10000000.0;
        this.sar = 0;
        super.Refresh(count, newThread);
    }
}
