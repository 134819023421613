// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { InstrumentTypes } from '../../../Utils/Instruments/InstrumentTypes';
import { PriceFormatter } from '../../../Utils/Instruments/PriceFormatter';
import { MathUtils } from '../../../Utils/MathUtils';
import { DateTimeConvertor } from '../../../Utils/Time/DateTimeConvertor';
import { DateTimeUtils } from '../../../Utils/Time/DateTimeUtils';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { OrderExecutionType } from '../../../Utils/Trading/OrderExecutionType';
import { OrderTifMap } from '../../../Utils/Trading/OrderTifEnum';
import { OrderUtils } from '../../../Utils/Trading/OrderUtils';
import { GeneralSettingsWrapper } from '../../../WebMobileReact/Models/GeneralSettingsWrapper';
import { type OrderType } from '../../../Utils/Trading/OrderType';
import { Resources } from '../../properties/Resources';
import { type OrderHistory } from '../OrderHistory';
import { type Instrument } from '../Instrument';
import { type Account } from '../../../Commons/cache/Account';
import { DataCache } from '../../DataCache';
import { BoughtSoldUtils } from '../../../Utils/Instruments/BoughtSoldUtils';

class _HistoryOrderFormatter {
    IdStr (historyOrder: OrderHistory): string { return historyOrder.OrderNumber; }

    IsLong (historyOrder: OrderHistory): boolean { return historyOrder.BuySell === OperationType.Buy; }

    Quantity (historyOrder: OrderHistory): number {
        if (GeneralSettingsWrapper.isQuantityInLots) {
            return historyOrder.Quantity;
        } else {
            const instrument: Instrument | null | undefined = historyOrder.Instrument;
            if (instrument !== null && instrument !== undefined) {
                return historyOrder.Quantity * instrument.LotSize;
            } else {
                return historyOrder.Quantity;
            }
        }
    }

    QuantityStr (historyOrder: OrderHistory): string {
        const amount = this.Quantity(historyOrder);
        let amountStr = amount.toString();
        if (historyOrder.Instrument !== null) { amountStr = DataCache.formatVolume(historyOrder.Instrument, Math.abs(amount), GeneralSettingsWrapper.isQuantityInLots, historyOrder.ProductType, historyOrder.Account); }
        return amountStr;
    }

    AccountStr (historyOrder: OrderHistory): string {
        const account: Account | null | undefined = historyOrder.Account;
        if (account !== null && account !== undefined) {
            return account.toString();
        } else {
            return historyOrder.AccountStr ?? '';
        }
    }

    SymbolStr (historyOrder: OrderHistory): string {
        const instrument: Instrument | null | undefined = historyOrder.Instrument;
        if (instrument !== null && instrument !== undefined) {
            return instrument.DisplayShortName();
        } else {
            return historyOrder.InstrumentStr ?? '';
        }
    }

    OrderType (historyOrder: OrderHistory): OrderType { return historyOrder.OrderType; }
    OrderTypeStr (historyOrder: OrderHistory): string {
        return Resources.getResource('property.' + OrderUtils.getOrderTypeLocalizationKey(historyOrder.OrderType));
    }

    ValidityStr (historyOrder: OrderHistory): string {
        const instrument: Instrument | null | undefined = historyOrder.Instrument;
        if (instrument === null || instrument === undefined || instrument.InstrType === InstrumentTypes.FORWARD) {
            return '';
        }
        const tif: any = historyOrder.TimeInForce;
        if (tif?.type) {
            const type = tif.type;
            const expirationTime = tif.expirationTime ? ` ${DateTimeUtils.formatDate(tif.expirationTime, 'DD.MM.YYYY  HH:mm:ss')}` : '';
            return Resources.getResource('property.' + OrderTifMap[type]) + expirationTime;
        } else {
            return '';
        }
    }

    Price (historyOrder: OrderHistory): number { return historyOrder.Price; }
    PriceStr (historyOrder: OrderHistory): string {
        const price = this.Price(historyOrder);
        if (isNaN(price)) {
            return '';
        }
        const instrument: Instrument | null | undefined = historyOrder.Instrument;
        if (instrument !== null && instrument !== undefined) {
            return instrument.formatPricePrecision(price, false, false);
        } else {
            return PriceFormatter.formatPrice(price, 2);
        }
    }

    StopPrice (historyOrder: OrderHistory): number { return historyOrder.StopLimit; }
    StopPriceStr (historyOrder: OrderHistory): string {
        const stopPrice = this.StopPrice(historyOrder);
        if (isNaN(stopPrice)) {
            return '';
        }
        const instrument: Instrument | null | undefined = historyOrder.Instrument;
        if (instrument !== null && instrument !== undefined) {
            return instrument.formatPricePrecision(stopPrice, false, false);
        } else {
            return PriceFormatter.formatPrice(stopPrice, 2);
        }
    }

    ExecutionTypeStr (historyOrder: OrderHistory): string {
        return this.localizeOrderExecutionType(historyOrder.eventTypeRaw);
    }

    GetBought (historyOrder: OrderHistory): number {
        return BoughtSoldUtils.Bought(historyOrder);
    }

    GetSold (historyOrder: OrderHistory): number {
        return BoughtSoldUtils.Sold(historyOrder);
    }

    GetBoughtStr (historyOrder: OrderHistory): string {
        return BoughtSoldUtils.BoughtStr(historyOrder);
    }

    GetSoldStr (historyOrder: OrderHistory): string {
        return BoughtSoldUtils.SoldStr(historyOrder);
    }

    Date (historyOrder: OrderHistory): Date {
        return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(historyOrder.Date);
    }

    DateStr (historyOrder: OrderHistory): string {
        const date = this.Date(historyOrder);
        return DateTimeUtils.formatDate(date, 'DD.MM.YYYY  HH:mm:ss');
    }

    TradingExchangeStr (historyOrder: OrderHistory): string {
        const instrument: Instrument | null | undefined = historyOrder.Instrument;
        if (instrument !== null && instrument !== undefined) {
            return instrument.TradingExchange;
        } else {
            return historyOrder.TradingExchange ?? '';
        }
    }

    private localizeOrderExecutionType (executionType: OrderExecutionType): string {
        if (MathUtils.IsNullOrUndefined(executionType)) { return ''; }

        let key = 'UNKNOWN';
        switch (executionType) {
        case OrderExecutionType.UNKNOWN:
            key = 'UNKNOWN';
            break;
        case OrderExecutionType.PENDING_NEW:
            key = 'PENDING_NEW';
            break;
        case OrderExecutionType.PENDING_EXECUTION:
            key = 'PENDING_EXECUTION';
            break;
        case OrderExecutionType.PENDING_CANCEL:
            key = 'PENDING_CANCEL';
            break;
        case OrderExecutionType.PENDING_REPLACE:
            key = 'PENDING_REPLACE';
            break;
        case OrderExecutionType.PENDING_REPLACE_NOT_ACTIVE:
            key = 'PENDING_REPLACE_NOT_ACTIVE';
            break;
        case OrderExecutionType.NEW:
            key = 'NEW';
            break;
        case OrderExecutionType.ACCEPTED:
            key = 'ACCEPTED';
            break;
        case OrderExecutionType.REPLACED:
            key = 'REPLACED';
            break;
        case OrderExecutionType.PART_FILLED:
            key = 'PART_FILLED';
            break;
        case OrderExecutionType.FILLED:
            key = 'FILLED';
            break;
        case OrderExecutionType.CANCELED:
            key = 'CANCELED';
            break;
        case OrderExecutionType.REFUSED:
            key = 'REFUSED';
            break;
        case OrderExecutionType.RESTATED:
            key = 'RESTATED';
            break;
        case OrderExecutionType.ACTIVATED:
            key = 'ACTIVATED';
            break;
        }
        return Resources.getResource('Order.ExecutionType.' + key);
    }
}

export const HistoryOrderFormatter: _HistoryOrderFormatter = new _HistoryOrderFormatter();
