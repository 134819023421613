// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { en } from './application_en_properties';
import { ru } from './application_ru_properties';
import { pt } from './application_pt_properties';
import { ar } from './application_ar_properties';
import { zh_sm } from './application_zh_sm_properties';
import { zh_tr } from './application_zh_tr_properties';
import { es } from './application_es_properties';
import { de } from './application_de_properties';
import { ja } from './application_ja_properties';
import { pl } from './application_pl_properties';
import { he } from './application_he_properties';
import { ApplicationInfo } from '../ApplicationInfo';
import { LocalStorage } from '../LocalStorage';

class _Resources {
    public onLocaleChange = new CustomEvent();

    constructor () {
        this.setLocalizationWithoutBranding();
    }

    public setNeedForceUpdateLocale (): void {
        needForceUpdateLocale = true;
    }

    public AddStaticLocalization (func): void {
        StaticLocalizationArr.push(func);
    }

    public getResource (key): string {
        if (!key) {
            return '';
        }

        if (keysOpened) {
            return key;
        }
        let result = locale[key];
        if (result === undefined || result === '') {
            result = Resources.getResourceLang(key, LOCALE_EN);
        }
        return result || key;
    }

    // TODO. Implement.
    public getResourceLang (key, lang): string {
        const locale = Localization[lang];
        if (!locale) return key;

        if (keysOpened) {
            return key;
        }

        return locale[key] || key;
    }

    public setLocale (newLang): void {
        if (CurrentLang === newLang && !needForceUpdateLocale) {
            return;
        }

        if (needForceUpdateLocale) {
            needForceUpdateLocale = false;
        }

        CurrentLang = newLang;
        this.applyNewLocale(Localization[newLang]);
    }

    public hasResource (key): boolean {
        return locale.hasOwnProperty(key);
    }

    public applyNewLocale (newLocaleValues): void {
        locale = newLocaleValues;
        this.LocaleChanged();
        for (const func of StaticLocalizationArr) {
            func();
        }
    }

    public ShowAllLocalizationKeys (needShow): void {
        keysOpened = !!needShow;
        this.LocaleChanged();
    }

    public LocaleChanged (): void {
        LocalStorage.setLanguage(CurrentLang);
        this.onLocaleChange.Raise();
    }

    public IsResourcePresent (key): boolean {
        return locale.hasOwnProperty(key);
    }

    public IsResourcePresentEN (key): boolean {
        return Localization[LOCALE_EN].hasOwnProperty(key);
    }

    // TODO.
    public isHidden (key): boolean {
        const text = Resources.getResourceLang(key, LOCALE_EN);
        return text === ISHIDDEN;
    }

    public isHieroglyph (): boolean {
        const curCode = CurrentLang;
        switch (curCode) {
        case LOCALE_CH_TR:
        case LOCALE_CH_SM:
        case LOCALE_JA:
        case LOCALE_AR:
            return true;
        default:
            return false;
        }
    }

    public getLangNameByLocale (loc): string {
        const langNames = LangNames;
        const localeNames = LocaleNames;

        for (let i = 0; i < localeNames.length; i++) {
            if (localeNames[i] == loc) {
                return langNames[i];
            }
        }
        return '';
    }

    public getLanguagesCBItems (): any[] {
        const arr = [];
        const locs = LocaleNames;
        const len = locs.length;
        for (let i = 0; i < len; i++) {
            const l = locs[i];
            if (!Resources.isHidden('locale.' + l)) {
                arr.push({
                    value: l,
                    text: this.getLangNameByLocale(l)
                });
            }
        }
        return arr;
    }

    public GetCurrentAppLang (): string {
        let defLang = ApplicationInfo.LoginLanguage;
        if (defLang) {
            defLang.toLocaleLowerCase();
        }

        if (!defLang) {
            defLang = LOCALE_EN;
        } else if (!LocaleNames.includes(defLang)) {
            defLang = LOCALE_EN;
        }

        let storagelang = LocalStorage.getLanguage();
        if (!LocaleNames.includes(storagelang)) {
            storagelang = LOCALE_EN;
        }

        return storagelang || defLang;
    }

    public InitStartLanguage (): void {
        let lang = this.GetCurrentAppLang();
        LocalStorage.setLanguage(lang);
        if (!lang || lang === 'null') {
            lang = LOCALE_EN;
        }

        this.setLocale(lang);
    }

    public GetToolTipKey (HeaderKey): string {
        if (this.IsResourcePresent(HeaderKey + '.descr')) {
            return HeaderKey + '.descr';
        } else {
            return HeaderKey;
        }
    }

    public setLocalizationWithoutBranding (): void {
        Localization[LOCALE_EN] = en;
        Localization[LOCALE_RU] = ru;
        Localization[LOCALE_PT] = pt;
        Localization[LOCALE_AR] = ar;
        Localization[LOCALE_CH_SM] = zh_sm;
        Localization[LOCALE_CH_TR] = zh_tr;
        Localization[LOCALE_ES] = es;
        Localization[LOCALE_DE] = de;
        Localization[LOCALE_JA] = ja;
        Localization[LOCALE_PL] = pl;
        Localization[LOCALE_HE] = he;
    };
}

export const LOCALE_EN = 'en';
export const LOCALE_RU = 'ru';
export const LOCALE_PT = 'pt';
export const LOCALE_AR = 'ar';
export const LOCALE_CH_SM = 'zh_sm';
export const LOCALE_CH_TR = 'zh_tr';
export const LOCALE_ES = 'es';
export const LOCALE_DE = 'de';
export const LOCALE_JA = 'ja';
export const LOCALE_PL = 'pl';
export const LOCALE_HE = 'he';
export const LOCALE_SHOWALLLOCALIZATIONKEYS = 'ShowAllLocalizationKeys';

let needForceUpdateLocale = false;
let keysOpened = false;
let locale = {};
export let CurrentLang = LOCALE_EN;
export const Localization = {};

const StaticLocalizationArr = [];

export const ISHIDDEN = '#hidden#';
// TODO. TMP until support all languages
// Resources.langNames = ["English", "Deutsch", "Français", "Suomi", "Polski", "Português", "Türkçe", "Русский",  /*"Українська",*/ "日本語", "中文简体", "中文正體", "한글", "اللغة العربية", "Español"];
// Resources.localeNames = ["en", "gr", "fr", "fi", "pl", "pt", "tr", "ru", /*"ua",*/ "jp", "ch", "ch_tr", "kr", "ar", "sp"];
export const LangNames = ['English', 'Português', 'Русский', 'اللغة العربية', '中文简体', '中文正體', 'Español', 'Deutsche', '日本語', 'Polski', 'עִברִית'];
export const LocaleNames = ['en', 'pt', 'ru', 'ar', 'zh_sm', 'zh_tr', 'es', 'de', 'ja', 'pl', 'he'];
export const AdditionalProperty = 'AdditionalProperty';

// create instance

export const ChangeValidityToTIFDict = {
    en: {
        'onBoarding.slide5.text2': 'You can set both the Quantity and Time in Force in the widget which pops out after activation of the feature.',
        'IsAllowedResponceReason.NotAllowedRouteOrderType': 'Order cannot be created, since selected TIF and Order type are not supported.',
        'panel.newOrderEntry.tifPanel': 'TIF',
        'panel.OrderBook.TIF': 'TIF',
        'panel.orders.TIF': 'TIF',
        'panel.savedOrders.TIF': 'TIF',
        'panel.optionPaperPositions.TIF': 'TIF',
        'property.TIF': 'Market TIF',
        'property.TIF_Limit_And_Stop_Limit': 'Limit TIF (Stop limit TIF)',
        'property.TIF_Stop': 'Stop TIF',
        'reports.Order TIF is forbidden by trade session': 'Order TIF is forbidden by trade session',
        'reports.order_book_tif': 'TIF',
        'reports.tif': 'TIF',
        'reports.TIF': 'TIF'
    },
    de: {
        'IsAllowedResponceReason.NotAllowedRouteOrderType': 'Die Auftrag kann nicht erstellt werden, da das ausgewählte TIF und der Auftragstyp nicht unterstützt werden.',
        'panel.newOrderEntry.tifPanel': 'TIF',
        'panel.OrderBook.TIF': 'TIF',
        'panel.orders.TIF': 'TIF',
        'panel.savedOrders.TIF': 'TIF',
        'panel.optionPaperPositions.TIF': 'TIF',
        'property.TIF': 'Market TIF',
        'property.TIF_Limit_And_Stop_Limit': 'Limit TIF (Stop limit TIF)',
        'property.TIF_Stop': 'Stop TIF'
    },
    es: {
        'IsAllowedResponceReason.NotAllowedRouteOrderType': 'No se puede crear la orden ya que TIF y tipo de orden seleccionados no son compatibles.',
        'panel.newOrderEntry.tifPanel': 'TIF',
        'panel.OrderBook.TIF': 'TIF',
        'panel.orders.TIF': 'TIF',
        'panel.savedOrders.TIF': 'TIF',
        'panel.optionPaperPositions.TIF': 'TIF',
        'property.TIF': 'Mercado TIF',
        'property.TIF_Limit_And_Stop_Limit': 'Límite TIF (Stop limit TIF)',
        'property.TIF_Stop': 'Stop TIF'
    }

};

export const Resources = new _Resources();
