// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from '../Commons/ErrorInformationStorage';
import { Resources } from '../Commons/properties/Resources';
import { Cursors } from '../Commons/Cursors';
import { HistoryType } from '../Utils/History/HistoryType';
import { DataCacheToolFactory } from '../Commons/cache/DataCacheToolFactory';
import { ToolsCache } from '../Commons/cache/ToolsCache';
import { KeyCode, KeyEventProcessor } from '../Commons/KeyEventProcessor';
import { MouseButtons } from '../Controls/UtilsClasses/ControlsUtils';
import { PortfolioChartReturnsRenderer } from '../Portfolio/Chart/PortfolioChartReturnsRenderer';
import { TerceraChartIndicatorRenderer } from './Renderers/Indicators/TerceraChartIndicatorRenderer';
import { TerceraChartIndicatorStorageRenderer } from './Renderers/Indicators/TerceraChartIndicatorStorageRenderer';
import { TerceraChartPriceScaleRenderer, TerceraChartPriceScaleRendererAligment, TerceraChartPriceScaleRendererSettings, TerceraChartPriceScaleRendererWindowType } from './Renderers/Scales/TerceraChartPriceScaleRenderer';
import { TerceraChartTimeScaleRenderer, TerceraChartTimeScaleRendererSettings } from './Renderers/Scales/TerceraChartTimeScaleRenderer';
import { TerceraChartBordersRenderer } from './Renderers/TerceraChartBordersRenderer';
import { TerceraChartCrossHairRenderer } from './Renderers/TerceraChartCrossHairRenderer';
import { TerceraChartDayHighLowRenderer } from './Renderers/TerceraChartDayHighLowRenderer';
import { TerceraChartHighlightExtendedSessionRenderer } from './Renderers/TerceraChartHighlightExtendedSessionRenderer';
import { TerceraChartInfoWindowRenderer } from './Renderers/TerceraChartInfoWindowRenderer';
import { TerceraChartMainPriceRenderer } from './Renderers/TerceraChartMainPriceRenderer';
import { TerceraChartNewAlertRenderer } from './Renderers/TerceraChartNewAlertRenderer';
import { TerceraChartOverlayStorageRenderer } from './Renderers/TerceraChartOverlayStorageRenderer';
import { TerceraChartPlateRenderer } from './Renderers/TerceraChartPlateRenderer';
import { TerceraChartScalingByRectangleRenderer } from './Renderers/TerceraChartScalingByRectangleRenderer';
import { TerceraChartSpreadRenderer } from './Renderers/TerceraChartSpreadRenderer';
import { TerceraChartTimeToNextBarRenderer } from './Renderers/TerceraChartTimeToNextBarRenderer';
import { TerceraChartVolumeBarsRenderer } from './Renderers/TerceraChartVolumeBarsRenderer';
import { TerceraChartWatermarkRenderer } from './Renderers/TerceraChartWatermarkRenderer';
import { TerceraChartEraserRenderer } from './Renderers/Tools/TerceraChartEraserRenderer';
import { TerceraChartNewToolRenderer } from './Renderers/Tools/TerceraChartNewToolRenderer';
import { TerceraChartSelectionRenderer } from './Renderers/Tools/TerceraChartSelectionRenderer';
import { TerceraChartToolInstanceRenderer } from './Renderers/Tools/TerceraChartToolInstanceRenderer';
import { TerceraChartToolRenderer } from './Renderers/Tools/TerceraChartToolRenderer';
import { TerceraChartTradingToolsRenderer } from './Renderers/Tools/TerceraChartTradingToolsRenderer';
import { TradingCentralLinesRenderer } from './Renderers/TradingCentralLinesRenderer';
import { TerceraChartCashItemSeries } from './Series/TerceraChartCashItemSeries';
import { TerceraChartCashItemSeriesSettings } from './Series/TerceraChartCashItemSeriesSettings';
import { TerceraChartOverlaySeries } from './Series/TerceraChartOverlaySeries';
import { TerceraChartAction, TerceraChartActionEnum, TerceraChartToolbarsEnum, CancelActionContext } from './TerceraChartAction';
import { TerceraChartActionProcessor } from './TerceraChartActionProcessor/TerceraChartActionProcessor';
import { ModelDataType, TerceraChartMVCCommand, TerceraChartMVCController, TerceraChartMVCModel, type TerceraChartMVCModelItem } from './TerceraChartMVC';
import { TerceraChartOverlay } from './TerceraChartOverlay';
import { ChartHistoryType } from './Utils/ChartConstants';
import { TerceraChartWindow } from './Windows/TerceraChartWindow';
import { NewToolClickStrategy } from './Tools/NewToolClickStrategy';
import { TerceraChartWindowsContainer } from './TerceraChartWindowsContainer';
import { CustomEvent } from '../Utils/CustomEvents';
import { ReloadHistoryParams } from '../Utils/History/ReloadHistoryParams';
import { Periods, type TFInfo } from '../Utils/History/TFInfo';
import { CashItemPointsCoverter } from './Utils/PointsConverter/CashItemPointsConverter';
import { ArrayUtils } from '../Utils/ArrayUtils';
import { DynProperty } from '../Commons/DynProperty';
import { IndicatorManager } from '../Commons/cache/indicators/IndicatorManager';
import { DataCache } from '../Commons/DataCache';
import { TerceraChartAutoLoadHistoryManager } from './TerceraChartAutoLoadHistoryManager';
import { CashItem } from '../Commons/cache/History/CashItem';
import { type Instrument } from '../Commons/cache/Instrument';
import { type Account } from '../Commons/cache/Account';
import { type Indicator } from '../Commons/cache/indicators/Indicator';
import { type DataCacheToolType } from '../Commons/cache/DataCacheToolEnums';
import { type DataCacheTool } from '../Commons/cache/DataCacheTool';
import { type ToolView } from './Tools/ToolView';
import { TerceraChartBase } from './TerceraChartBase';
import { LayersEnum } from './Renderers/TerceraChartBaseRenderer';
import { TerceraChartMouseState } from './Utils/TerceraChartMouseState';
import { TerceraChartPriceScaleLayoutInfo } from './Utils/TerceraChartPriceScaleLayoutInfo';
import { type TerceraChartBaseScaleRendererSettings } from './Renderers/Scales/TerceraChartBaseScaleRenderer';
import { type TerceraChartWindowBase } from './Windows/TerceraChartWindowBase';
import { type TerceraChartAdvancedParams } from './Utils/TerceraChartAdvancedParams';
import { TerceraChartScrollerRenderer } from './Renderers/TerceraChartScrollerRenderer';
import { TerceraChartZoomRenderer } from './Renderers/TerceraChartZoomRenderer';
import { type Rectangle } from '../Commons/Geometry';
import { TerceraChartToBeginRenderer } from './Renderers/TerceraChartToBeginRenderer';

//
// Чарт, аналог чарт на клиенте
//
export class TerceraChart extends TerceraChartBase {
    public LoadingNow = false;
    public mainPriceRenderer: TerceraChartMainPriceRenderer | null = null;
    public volumeBarsRenderer: TerceraChartVolumeBarsRenderer | null = null;

    public RefreshIsRequired = new CustomEvent();

    public model: TerceraChartMVCModel;

    public chartController: TerceraChartMVCController;

    public cashItemSeriesSettings: TerceraChartCashItemSeriesSettings;

    public TerceraChartScalingByRectangleRenderer: TerceraChartScalingByRectangleRenderer;
    public TerceraChartTradingToolsRenderer: TerceraChartTradingToolsRenderer;

    public SelectionRenderer: any = null;
    public EraserRenderer: any = null;

    public DayHighLowRenderer: any = null;
    public SpreadRenderer: any = null;
    public PortfolioChartReturnsRenderer: any = null;
    public TerceraChartTimeToNextBarRenderer: any = null;
    public TerceraChartCrossHairRenderer: any = null;

    public NewToolStrategy: NewToolClickStrategy;
    public ChartID = 13;

    public overlayStorageRenderer: any = null;
    public Overlays: TerceraChartOverlay[] = [];

    public activeOverlay: TerceraChartOverlay | null = null;
    public FocusedOverlay: TerceraChartOverlay | null = null;

    public PortfolioAssetReturnVisible = false;

    public autoLoadManager: TerceraChartAutoLoadHistoryManager;

    public loadHistoryAbortController: any = null;
    public loadOverlayHistoryAbortController: any = null;

    public TerceraChartWatermarkRenderer: TerceraChartWatermarkRenderer;
    public HighlightExtendedSessionRenderer: TerceraChartHighlightExtendedSessionRenderer;
    public TerceraChartNewAlertRenderer: TerceraChartNewAlertRenderer;
    public TerceraChartInfoWindowRenderer: TerceraChartInfoWindowRenderer;
    public TerceraChartBordersRenderer: TerceraChartBordersRenderer;
    public TradingCentralRenderer: TradingCentralLinesRenderer;
    public toBeginRenderer: TerceraChartToBeginRenderer;

    public NeedSetDafaultsDataRage: boolean;
    public refreshDelayTimeOut: any;
    public HeaderHeight: number;
    public AllWindowsSelectedTools: any;

    constructor (ctx: CanvasRenderingContext2D, terceraChartPanelContext) {
        super(ctx, terceraChartPanelContext);

        this.model = new TerceraChartMVCModel(DataCache);
        this.model.ModelItemChanged.Subscribe(this.MVCModelItemChanged, this);

        this.chartController = new TerceraChartMVCController(this);
        this.chartController.SuspendRefreshChart = true; // UnSuspendAfter populate

        this.cashItemSeriesSettings = new TerceraChartCashItemSeriesSettings(this.model);
        this.yScaleRendererSettings = this.GetChartPriceScaleRendererSettings();

        this.TerceraChartActionProcessor = new TerceraChartActionProcessor(this);
        this.xScaleRendererSettings = this.GetChartTimeScaleRendererSettings();

        this.NewToolStrategy = new NewToolClickStrategy(this.CreateTool.bind(this), this);

        ToolsCache.OnChangeTool.Subscribe(this.ProcessChangeTool, this);

        this.autoLoadManager = new TerceraChartAutoLoadHistoryManager(this);
    }

    public override getType (): string {
        return 'TerceraChart';
    }

    // #region ThemeChanged/Localize
    public ThemeChanged (): void {
        super.ThemeChanged();
        this.yScaleRendererSettings.ThemeChanged();
        this.xScaleRendererSettings.ThemeChanged();
    }
    // #endregion

    // #region Properties/Callback
    public override Properties (): DynProperty[] {
        let properties: DynProperty[] = super.Properties();

        // Scale type
        const SeparatorGroup = '#1#' + Resources.getResource('property.SeparatorGroup.Zoom');
        const mainWindow = this.mainWindow as TerceraChartWindow;

        let prop = new DynProperty('autoYScale', mainWindow.AutoScale, DynProperty.RADIOBUTTON_COMBOITEM, DynProperty.PRICE_SCALE_GROUP);
        prop.assignedProperty = ['FitIndicator', 'FitOrders', 'FitHighLow', 'FitDrawings', 'FitAlerts'];
        prop.objectVariants = [
            { text: Resources.getResource('property.autoYScale.Auto'), value: true },
            { text: Resources.getResource('property.autoYScale.Manual'), value: false }
        ];
        prop.COMBOBOX_TYPE = DynProperty.INTEGER;
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 0;
        properties.push(prop);

        // xscale
        prop = new DynProperty('xScale', mainWindow.XScale, DynProperty.DOUBLE, DynProperty.HIDDEN_GROUP);
        properties.push(prop);

        if (this.TerceraChartActionProcessor.GetTerceraChartActionState(
            TerceraChartAction.Create(TerceraChartActionEnum.FitIndicators)).Visible) {
            prop = new DynProperty('FitIndicator', mainWindow.indicatorRendererSettings.UseInAutoscale, DynProperty.BOOLEAN, DynProperty.PRICE_SCALE_GROUP);
            prop.enabilityValue = true;
            prop.enabled = mainWindow.AutoScale;
            prop.sortIndex = 1;
            prop.separatorGroup = SeparatorGroup;

            properties.push(prop);
        }

        if (this.TerceraChartActionProcessor.GetTerceraChartActionState(
            TerceraChartAction.Create(TerceraChartActionEnum.FitOrders)).Visible) {
            prop = new DynProperty('FitOrders', this.TerceraChartTradingToolsRenderer.OrderScale, DynProperty.BOOLEAN, DynProperty.PRICE_SCALE_GROUP);
            prop.enabilityValue = true;
            prop.enabled = mainWindow.AutoScale;
            prop.sortIndex = 2;
            prop.separatorGroup = SeparatorGroup;

            properties.push(prop);
        }

        if (this.TerceraChartActionProcessor.GetTerceraChartActionState(
            TerceraChartAction.Create(TerceraChartActionEnum.FitDayHighLow)).Visible &&
            typeof TerceraChartDayHighLowRenderer === 'function') {
            const highLowRenderer = mainWindow.GetRenderer(TerceraChartDayHighLowRenderer);
            if (highLowRenderer) {
                prop = new DynProperty('FitHighLow', highLowRenderer.UseInAutoscale, DynProperty.BOOLEAN, DynProperty.PRICE_SCALE_GROUP);
                prop.enabilityValue = true;
                prop.enabled = mainWindow.AutoScale;
                prop.sortIndex = 3;
                prop.separatorGroup = SeparatorGroup;

                properties.push(prop);
            }
        }

        const toolsRenderer = mainWindow.GetRenderer(TerceraChartToolRenderer);
        prop = new DynProperty('FitDrawings', toolsRenderer.UseInAutoscale, DynProperty.BOOLEAN, DynProperty.PRICE_SCALE_GROUP);
        prop.enabilityValue = true;
        prop.enabled = mainWindow.AutoScale;
        prop.sortIndex = 4;
        prop.separatorGroup = SeparatorGroup;

        properties.push(prop);

        if (this.TerceraChartActionProcessor.GetTerceraChartActionState(
            TerceraChartAction.Create(TerceraChartActionEnum.FitAlerts)).Visible) {
            prop = new DynProperty('FitAlerts', this.TerceraChartTradingToolsRenderer.AlertScale, DynProperty.BOOLEAN, DynProperty.PRICE_SCALE_GROUP);
            prop.enabilityValue = true;
            prop.enabled = mainWindow.AutoScale;
            prop.sortIndex = 5;
            prop.separatorGroup = SeparatorGroup;

            properties.push(prop);
        }

        properties = properties.concat(this.model.Properties());
        properties = properties.concat(this.yScaleRendererSettings.Properties());
        properties = properties.concat(this.xScaleRendererSettings.Properties());

        return properties;
    }

    public override callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const mainWindow = this.mainWindow as TerceraChartWindow;

        let dp = DynProperty.getPropertyByName(properties, 'autoYScale');
        if (dp) {
            this.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(
                dp.value ? TerceraChartActionEnum.AutoScale : TerceraChartActionEnum.ManualScale,
                mainWindow));
        }

        dp = DynProperty.getPropertyByName(properties, 'xScale');
        if (dp) mainWindow.XScale = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'FitHighLow');
        if (dp) {
            const highLowRenderer = mainWindow.GetRenderer(TerceraChartDayHighLowRenderer);
            if (highLowRenderer) highLowRenderer.UseInAutoscale = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, 'FitIndicator');
        if (dp) mainWindow.indicatorRendererSettings.UseInAutoscale = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'FitOrders');
        if (dp) this.TerceraChartTradingToolsRenderer.OrderScale = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'FitDrawings');
        if (dp) {
            const toolRenderer = mainWindow.GetRenderer(TerceraChartToolRenderer);
            toolRenderer.UseInAutoscale = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, 'FitAlerts');
        if (dp) this.TerceraChartTradingToolsRenderer.AlertScale = dp.value;

        this.yScaleRendererSettings.callBack(properties);
        this.xScaleRendererSettings.callBack(properties);

        // в самом конце загрузка пропертей модели и перегруз чарта
        this.model.callBack(properties);

        this.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.ShowAllSession), this.ShowAllSessions);
        this.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.ShowEmptyBars), this.cashItemSeriesSettings.ShowEmptyBars);
        this.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.HistoryType), this.model.GetChartHistoryType());

        this.windowsContainer.LayoutWindows();
        this.IsDirty();
    }
    // #endregion

    // #region EventsProcessors
    public override ProcessMouseDown (e): boolean {
        const mouseDownProcessed = super.ProcessMouseDown(e);
        if (mouseDownProcessed && this.MouseState === TerceraChartMouseState.Moving) {
            this.autoLoadManager.processMouseDown();
        }
        return mouseDownProcessed;
    }

    public override ProcessMouseUp (e): boolean {
        let mouseProcessed = false;
        try {
            // Cancel action on RightButton
            if (e.Button === MouseButtons.Right && this.CancelAction(CancelActionContext.RightButton)) {
                return true;
            }
            mouseProcessed = super.ProcessMouseUp(e);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
        return mouseProcessed;
    }

    public override ProcessMouseClick (e): boolean {
        let processed: boolean = super.ProcessMouseClick(e);
        if (!processed) {
            processed = this.autoLoadManager.processMouseClick();
        }
        return processed;
    }

    public override OnKeyDown (): void {
        const keyProc = KeyEventProcessor;
        const currentButton = keyProc.currentButton;

        if (keyProc.isCtrlModOnly() && currentButton === KeyCode.A) {
            const toolsRenderer = this.mainWindow.GetRenderer(TerceraChartToolRenderer);
            toolsRenderer.SelectAllTools();
            this.IsDirty(LayersEnum.Tools);
            return;
        }

        if (keyProc.isCtrlModOnly() && currentButton === KeyCode.L) {
            if (Resources.isHidden('property.hotkeyManager.ShowVolume')) { return; }
            this.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.Volume));
            this.IsDirty();
            return;
        }

        switch (currentButton) {
        case KeyCode.ESC:
            this.CancelAction(CancelActionContext.Esc);
            break;
        case KeyCode.DELETE:
            this.RemoveSelectedAndHoveredToolsFromAllWindows();
            this.IsDirty(LayersEnum.Tools);
            break;
        }
    }

    // #endregion

    // #region Cursor
    public override GetCursor (e): Cursors {
        if (this.MouseState === TerceraChartMouseState.Moving && this.HasCorrectData()) {
            const activeWindow = this.ActiveOverlayGet() != null ? this.ActiveOverlayGet().window : this.HoverWindow;
            if (activeWindow != null) {
                return activeWindow.AutoScale ? Cursors.SizeWE : Cursors.SizeAll;
            }
        }
        return super.GetCursor(e);
    }
    // #endregion

    // #region Window
    public override CreateWindowsContainer (): TerceraChartWindowsContainer {
        const scrollerRenderer = new TerceraChartScrollerRenderer(this);
        const xScaleRenderer = new TerceraChartTimeScaleRenderer(this.TerceraChartActionProcessor, this.xScaleRendererSettings, this);
        const zoomRenderer = new TerceraChartZoomRenderer(this, this.TerceraChartActionProcessor);
        const windowsContainer = new TerceraChartWindowsContainer(scrollerRenderer, xScaleRenderer, zoomRenderer);
        windowsContainer.AfterResizeWindows.Subscribe(this.windowsContainer_AfterResizeWindows, this);
        return windowsContainer;
    }

    public override CreateMainWindow (): TerceraChartWindowBase {
        const priceScaleRender = this.GetChartPriceScaleRenderer(this, this.yScaleRendererSettings);
        const leftPriceScaleRender = this.GetChartPriceScaleRenderer(this, this.yScaleRendererSettings);
        leftPriceScaleRender.Aligment = TerceraChartPriceScaleRendererAligment.Left;
        const window = this.GetChartWindow(priceScaleRender, leftPriceScaleRender);
        window.PointsConverter = new CashItemPointsCoverter(window, null);
        return window;
    }

    public override InitializeRenderers (): void {
        const newToolRenderer = this.GetChartNewToolRenderer();
        const toolsRenderer = this.GetChartToolRenderer(newToolRenderer);
        if (!isNullOrUndefined(toolsRenderer)) {
            toolsRenderer.InstanceWindow = this.mainWindow;
            this.mainWindow.Renderers.push(toolsRenderer);
        }
        this.mainPriceRenderer = new TerceraChartMainPriceRenderer(this);
        this.mainPriceRenderer.cashSettings = this.cashItemSeriesSettings;

        // there was an overlay in the wrong order
        this.volumeBarsRenderer = new TerceraChartVolumeBarsRenderer(this.cashItemSeriesSettings, this);
        this.mainWindow.Renderers.push(this.volumeBarsRenderer);

        this.overlayStorageRenderer = new TerceraChartOverlayStorageRenderer(this);
        this.mainWindow.Renderers.push(this.overlayStorageRenderer);
        const indicatorStorageRenderer = new TerceraChartIndicatorStorageRenderer(this, false);
        this.mainWindow.Renderers.push(indicatorStorageRenderer);
        // mainWindow.Renderers.Add(customDrawingRenderer);//1
        this.mainWindow.Renderers.push(this.mainPriceRenderer);
        this.mainWindow.Renderers.push(newToolRenderer); // помещаю ньютуллзрендерер после майнпрайса, чтобы тулзы поверх баров рисовались
        this.mainWindow.Renderers.push(toolsRenderer); // Один рендерер кладем два раза - до mainPriceRenderer и после. Реализуем IsBackground у тулзы.
        this.mainWindow.Renderers.push(indicatorStorageRenderer); // Один рендерер кладем два раза - до mainPriceRenderer и после. Реализуем OnTop у индикаторов.
        this.mainWindow.Renderers.push(new TerceraChartScalingByRectangleRenderer(this));
        this.TerceraChartWatermarkRenderer = new TerceraChartWatermarkRenderer(this);
        this.mainWindow.Renderers.splice(0, 0, this.TerceraChartWatermarkRenderer);

        this.DayHighLowRenderer = new TerceraChartDayHighLowRenderer(this);
        this.mainWindow.Renderers.push(this.DayHighLowRenderer);

        this.TerceraChartTimeToNextBarRenderer = this.GetTerceraChartTimeToNextBarRenderer();
        this.mainWindow.Renderers.push(this.TerceraChartTimeToNextBarRenderer);

        this.toBeginRenderer = this.GetChartToBeginRenderer(this);
        this.mainWindow.Renderers.push(this.toBeginRenderer);

        this.SpreadRenderer = new TerceraChartSpreadRenderer(this);
        this.mainWindow.Renderers.push(this.SpreadRenderer);

        this.PortfolioChartReturnsRenderer = new PortfolioChartReturnsRenderer(this);
        this.HighlightExtendedSessionRenderer = this.GetChartHighlightExtendedSessionRenderer(this);

        this.mainWindow.Renderers.splice(0, 0, this.HighlightExtendedSessionRenderer, this.PortfolioChartReturnsRenderer);

        this.TerceraChartTradingToolsRenderer = this.GetChartTradingToolsRenderer(newToolRenderer);
        this.mainWindow.Renderers.push(this.TerceraChartTradingToolsRenderer);
        // mainWindow.Renderers.Add(customDrawingRenderer);//2

        const toolRenderer = this.mainWindow.GetRenderer(TerceraChartToolRenderer);
        toolRenderer.SelectedToolsListChanged.Subscribe(this.ToolsRenderer_SelectedToolsListChanged, this);

        /// /
        // mainWindow.MainThreadRenderers.Add(new TerceraChartInfoWindowRenderer(mainWindow));
        // TerceraChartLineikaRenderer = new TerceraChartLineikaRenderer();
        // mainWindow.MainThreadRenderers.Add(TerceraChartLineikaRenderer);

        // TODO. Change to main thread renderers.
        this.TerceraChartCrossHairRenderer = this.GetTerceraChartCrossHairRenderer();
        this.mainWindow./* MainThread */Renderers.push(this.TerceraChartCrossHairRenderer);

        if (DataCache.AlertManager.AlertsVisible) {
            this.TerceraChartNewAlertRenderer = new TerceraChartNewAlertRenderer(this);
            this.mainWindow.Renderers.push(this.TerceraChartNewAlertRenderer);
        }
        // mainThreadDrawingRenderers.Add(new TerceraChartCrossHairRenderer(this));

        /// /windowsContainer.Windows.Add(new TerceraChartWindow());

        this.SelectionRenderer = new TerceraChartSelectionRenderer(this);
        this.EraserRenderer = new TerceraChartEraserRenderer(this);
        this.mainWindow.Renderers.push(this.SelectionRenderer);
        this.windowsContainer.Renderers.push(this.EraserRenderer);
        // windowsContainer.Renderers.Insert(0, new TerceraChartHighlightSessionRenderer());

        this.TerceraChartInfoWindowRenderer = new TerceraChartInfoWindowRenderer(this, this.mainWindow);
        this._forceUpdateInitiators.push(this.TerceraChartInfoWindowRenderer);
        // TODO. Change to main thread renderers.
        this.mainWindow./* MainThread */Renderers.push(this.TerceraChartInfoWindowRenderer);
        this.TerceraChartBordersRenderer = this.GetTerceraChartBordersRenderer();
        this.mainWindow.Renderers.push(this.TerceraChartBordersRenderer);

        this.TradingCentralRenderer = new TradingCentralLinesRenderer(this); // #97276 for TradingCentral only, Visible = false by default
        this.mainWindow.Renderers.push(this.TradingCentralRenderer);
    }

    public override GetActiveWindow (): TerceraChartWindowBase {
        return this.FocusedOverlay != null ? this.FocusedOverlay.window : super.GetActiveWindow();
    }

    public override GetActiveWindowFromEvent (e): TerceraChartWindowBase {
        return this.FocusedOverlay != null ? this.FocusedOverlay.window : super.GetActiveWindowFromEvent(e);
    }

    public override GetLastMouseDownWindow (): TerceraChartWindowBase {
        return this.FocusedOverlay != null ? this.FocusedOverlay.window : super.GetLastMouseDownWindow();
    }

    public override moveScreenByX (newI1: number, allowChangeBarsToRight: boolean = false): boolean {
        const mainCashItemSeries = this.mainPriceRenderer.Series;
        if (mainCashItemSeries === null) {
            return false;
        }

        const mainWindow = this.mainWindow;
        if (newI1 < Math.floor(mainWindow.im())) {
            this.autoLoadManager.processDragLeft(newI1);
        }

        return super.moveScreenByX(newI1, allowChangeBarsToRight);
    }

    public override BarsCount (): number {
        const mainCashItemSeries = this.mainPriceRenderer?.Series;
        if (!isNullOrUndefined(mainCashItemSeries)) {
            return mainCashItemSeries.Count();
        } else {
            return super.BarsCount();
        }
    }

    public override HasCorrectData (): boolean {
        return super.HasCorrectData() && !this.LoadingNow;
    }

    public override CalculateMinMax (): void {
        super.CalculateMinMax();
        if (this.overlayStorageRenderer != null) {
            this.overlayStorageRenderer.CalculateMinMax();
        }
    }

    public override FindIntervalExactly (dataX: number): number {
        return this.mainPriceRenderer.Series.FindIntervalExactly(dataX);
    }

    protected override calulateNewI1FromDelta (deltaX: number): number {
        const correctedDeltaX = deltaX / (this.mainWindow.ClientRectangle.Width / this.mainWindow.im());
        return Math.round(this.lastMouseDownI1 - correctedDeltaX);
    }
    // #endregion

    // #region Context menu
    public override GetContextMenuItems (e, chart): any {
        let menuItems = super.GetContextMenuItems(e, chart);
        if (menuItems === null || menuItems.length === 0) {
            menuItems = this.TerceraChartActionProcessor.CreateMenu(this.ContextMenuItems);
        }
        return menuItems;
    }
    // #endregion

    // #region  Drawing
    public override GetAdvancedParamsForRenderer (gr: CanvasRenderingContext2D): TerceraChartAdvancedParams {
        const params = super.GetAdvancedParamsForRenderer(gr);
        params.Instrument = this.Instrument();
        params.Account = this.Account();
        params.mainPriceRenderer = this.mainPriceRenderer;
        return params;
    }

    public GetTerceraChartPriceScaleRectangles (): Rectangle[] {
        const res: Rectangle[] = [];
        // Process main chart windows
        this.windowsContainer.Windows.forEach(window => {
            if (window.leftYScaleRenderer.Rectangle.Width > 0) {
                res.push(window.leftYScaleRenderer.Rectangle);
            }
            if (window.rightYScaleRenderer.Rectangle.Width > 0) {
                res.push(window.rightYScaleRenderer.Rectangle);
            }
        });
        // Process overlays
        this.Overlays.forEach(overlay => {
            if (overlay.window.leftYScaleRenderer.Rectangle.Width > 0) {
                res.push(overlay.window.leftYScaleRenderer.Rectangle);
            }
        });
        return res;
    }

    public override GetTerceraChartPriceScaleLayoutInfo (gr: CanvasRenderingContext2D): TerceraChartPriceScaleLayoutInfo {
        const res = new TerceraChartPriceScaleLayoutInfo();
        // Right scale
        let rightMaxWidth = 0;
        for (let i = 0; i < this.windowsContainer.Windows.length; i++) {
            const curW = this.windowsContainer.Windows[i].rightYScaleRenderer.GetPreferredWidth(gr, this.FormatYScaleValue.bind(this));
            if (rightMaxWidth < curW) {
                rightMaxWidth = curW;
            }
        }
        // Celing to 5 pixels
        const ceilingVal = Math.floor(Math.ceil(rightMaxWidth / 5.0) * 5);
        res.PreferredWidthScales = ceilingVal > TerceraChartPriceScaleRenderer.MIN_WIDTH ? ceilingVal : TerceraChartPriceScaleRenderer.MIN_WIDTH;

        for (let i = 1; i < this.windowsContainer.Windows.length; i++) {
            // Indicator digits
            const priceScaleRend = this.windowsContainer.Windows[i].rightYScaleRenderer as TerceraChartPriceScaleRenderer;
            let digitsBasedOnIndicator = -1;
            const renderer = this.windowsContainer.Windows[i].GetRenderer(TerceraChartIndicatorStorageRenderer);
            for (let j = 0; j < renderer.Indicators.length; j++) {
                const rend = renderer.Indicators[j];
                // Get maximum from all indicators for current window
                if (rend?.PTLIndicator != null && rend.PTLIndicator.Digits != -1 && rend.PTLIndicator.Digits < 8 && rend.PTLIndicator.Digits > digitsBasedOnIndicator) {
                    digitsBasedOnIndicator = rend.PTLIndicator.Digits;
                }
            }
            priceScaleRend.DigitsBasedOnIndicator = digitsBasedOnIndicator;
        }
        // Left scale
        // 1. Наличие оверлея включает левую шкалу для всего чарта
        let leftMaxWidth = 0;
        if (this.Overlays.length > 0) {
            leftMaxWidth = 0;
            for (let i = 0; i < this.Overlays.length; i++) {
                // Not loaded yet
                if (this.Overlays[i].mainPriceRenderer?.Series == null || !this.Overlays[i].separateScale) {
                    continue;
                }
                const activeInstrument = this.Overlays[i].ActiveInstrument;
                const curW = this.Overlays[i].window.leftYScaleRenderer.GetPreferredWidth(gr, activeInstrument.formatPrice.bind(activeInstrument));
                if (leftMaxWidth < curW) {
                    leftMaxWidth = curW;
                }
            }

            // Celing to 5 pixels
            res.PreferredWidthScalesLeft = Math.floor(Math.ceil(leftMaxWidth / 5.0) * 5);
            res.HasOverlays = res.PreferredWidthScalesLeft > 0;

            // Аналитик попросил одинаковую ширину
            if (res.HasOverlays) {
                res.PreferredWidthScalesLeft = res.PreferredWidthScales = Math.max(res.PreferredWidthScales, res.PreferredWidthScalesLeft);
            }
        } else {
            res.HasOverlays = false;
        }
        return res;
    }

    public override SynchronizeBeforeDraw (): void {
        super.SynchronizeBeforeDraw();
        const mainCashItemSeries = this.mainPriceRenderer.Series;
        if (mainCashItemSeries != null) {
            const firstBarOnTheScreenIndex = Math.floor(this.mainWindow.i1 - this.mainWindow.im()) + 1;
            // Cache cashitem data to array for quick acces
            mainCashItemSeries.CacheScreenData(firstBarOnTheScreenIndex, this.mainWindow.i1, this.Instrument());
            // Overlays:
            // - new logic: automatically set ActiveOverlay if null
            const Overlays = this.Overlays;
            if (this.ActiveOverlayGet() == null || !this.ActiveOverlayGet().SeparateScaleGet()) {
                // need reset - search first correct overlay
                let newOverlay = null;
                for (let i = 0; i < Overlays.length; i++) {
                    if (Overlays[i].SeparateScaleGet()) {
                        newOverlay = Overlays[i];
                        break;
                    }
                }
                this.ActiveOverlaySet(newOverlay);
            }
            // Synronize by active overlays
            if (this.activeOverlay != null) {
                for (let i = 0; i < Overlays.length; i++) {
                    if (Overlays[i] == this.activeOverlay || !Overlays[i].SeparateScaleGet()) {
                        continue;
                    }

                    Overlays[i].cashItemSeriesSettings.DataType = this.activeOverlay.cashItemSeriesSettings.DataType;
                    Overlays[i].window.AutoScale = this.ActiveOverlayGet().window.AutoScale;
                }
            }

            // - Cache cashitem data for overlay
            // - Set visible to active PriceScaleRenderer
            this.mainWindow.leftYScaleRenderer.Visible = this.ActiveOverlayGet() == null;
            for (let i = 0; i < Overlays.length; i++) {
                const overlay = Overlays[i];
                overlay.window.XScale = this.mainWindow.XScale;
                overlay.PriceScaleRenderer.Visible = this.ActiveOverlayGet() === Overlays[i] && this.ActiveOverlayGet().SeparateScaleGet();
                if (overlay.mainPriceRenderer?.Series != null) {
                    overlay.mainPriceRenderer.Series.CacheScreenData(firstBarOnTheScreenIndex, this.mainWindow.i1, overlay.ActiveInstrument);
                }
            }
        }
    }

    public override DrawNoData (gr: CanvasRenderingContext2D): void {
        const instrument = this.Instrument();
        const timeFrameInfo = this.TimeFrameInfo();
        let insStr = '';
        if (!isNullOrUndefined(instrument)) {
            insStr = instrument.DisplayName();
        }

        if (this.LoadingNow && !isNullOrUndefined(instrument) && !isNullOrUndefined(timeFrameInfo)) {
            this.NoDataMessage = Resources.getResource('chart.loadingData') + ' ' + insStr + ' ' + Periods.getTicNameShort(timeFrameInfo.Periods) + ' ...      ';
        } else if (!isNullOrUndefined(instrument) && !isNullOrUndefined(timeFrameInfo)) {
            this.NoDataMessage = Resources.getResource('chart.noAvailableData') + ' ' + insStr + ' ' + Periods.getTicNameShort(timeFrameInfo.Periods) + ' ...      ';
        } else {
            this.NoDataMessage = Resources.getResource('chart.noAvailableData');
        }
        super.DrawNoData(gr);
    }

    public override Draw (): void {
        const mainPriceRenderer = this.mainPriceRenderer;
        if (mainPriceRenderer == null) {
            return;
        }
        super.Draw();
    }

    public FormatYScaleValue (value: number): string {
        const instrument = this.Instrument();
        if (!isNullOrUndefined(instrument)) {
            return instrument.formatPrice(value);
        } else {
            return '';
        }
    }

    public override AddForceUpdate (): void {
        super.AddForceUpdate();
        this.autoLoadManager.update();
    }
    // #endregion

    get ShowAllSessions (): boolean {
        return this.model.GetShowAllSessions();
    }

    public Instrument (): Instrument | null {
        return this.model.GetInstrument();
    }

    public Account (): Account | null {
        return this.model.GetAccount();
    }

    public TimeFrameInfo (): TFInfo | null {
        return this.model.GetTimeFrameInfo();
    }

    public ChartHistoryType (): ChartHistoryType {
        return this.model.GetChartHistoryType();
    }

    public MainCashItemSeries (): TerceraChartCashItemSeries {
        const mainPriceRenderer = this.mainPriceRenderer;
        return mainPriceRenderer ? mainPriceRenderer.Series : null;
    }

    public GetChartNewToolRenderer (): TerceraChartNewToolRenderer {
        return new TerceraChartNewToolRenderer(this, this.NewToolStrategy);
    }

    public GetChartToolRenderer (newToolRenderer: TerceraChartNewToolRenderer): TerceraChartToolRenderer {
        return new TerceraChartToolRenderer(this.ChartID, this, newToolRenderer);
    }

    public GetChartTradingToolsRenderer (newToolRenderer: TerceraChartNewToolRenderer): TerceraChartTradingToolsRenderer {
        return new TerceraChartTradingToolsRenderer(this.terceraChartPanelContext, this, this.mainWindow, newToolRenderer);
    }

    public GetTerceraChartTimeToNextBarRenderer (): TerceraChartTimeToNextBarRenderer {
        return new TerceraChartTimeToNextBarRenderer(this);
    }

    public GetChartPriceScaleRenderer (terceraChart, settings: TerceraChartBaseScaleRendererSettings, windowType = TerceraChartPriceScaleRendererWindowType.Main): TerceraChartPriceScaleRenderer {
        return new TerceraChartPriceScaleRenderer(terceraChart, settings, windowType);
    }

    public GetChartPriceScaleRendererSettings (): TerceraChartPriceScaleRendererSettings {
        return new TerceraChartPriceScaleRendererSettings();
    }

    public GetTerceraChartBordersRenderer (): TerceraChartBordersRenderer {
        return new TerceraChartBordersRenderer(this);
    }

    public GetTerceraChartCrossHairRenderer (): TerceraChartCrossHairRenderer {
        return new TerceraChartCrossHairRenderer(this);
    }

    public GetChartTimeScaleRendererSettings (): TerceraChartTimeScaleRendererSettings {
        return new TerceraChartTimeScaleRendererSettings();
    }

    public GetChartWindow (priceScaleRender: TerceraChartPriceScaleRenderer, leftPriceScaleRender: TerceraChartPriceScaleRenderer): TerceraChartWindow {
        return new TerceraChartWindow(priceScaleRender, leftPriceScaleRender);
    }

    public GetCharIndicatorRenderer (indicator: Indicator, terceraChart: TerceraChart): TerceraChartIndicatorRenderer {
        return new TerceraChartIndicatorRenderer(indicator, terceraChart);
    }

    public GetCharPlateIndicatorRenderer (terceraChart: TerceraChart): TerceraChartPlateRenderer {
        return new TerceraChartPlateRenderer(terceraChart);
    }

    public GetChartHighlightExtendedSessionRenderer (terceraChart: TerceraChart): TerceraChartHighlightExtendedSessionRenderer {
        return new TerceraChartHighlightExtendedSessionRenderer(terceraChart);
    }

    public GetChartToBeginRenderer (terceraChart): TerceraChartToBeginRenderer {
        return new TerceraChartToBeginRenderer(terceraChart);
    }

    // #region LoadingData

    public async LoadHistoryStart (): Promise<void> {
        this.LoadingNow = true;

        this.loadHistoryAbortController?.abort();
        this.loadHistoryAbortController = new AbortController();
        const signal = this.loadHistoryAbortController.signal;

        const historyParams = this.GetHistoryParams();
        const ins = this.Instrument();

        this.subscribeTrades(); // #103044

        const newCashItem = await CashItem.CreateWithHistory(ins, historyParams, signal);

        if (signal.aborted || newCashItem == null) {
            return;
        }

        this.LoadHistoryFinish(newCashItem);
        this.IsDirty();
    }

    public LoadHistoryFinish (newCashItem: CashItem, isAutoLoadHistory = false): void {
        this.ReplaceCashItem(newCashItem);

        if (!isAutoLoadHistory) {
            this.ToBegin();
        }

        // Не рисуем пока оверлеи - они не валидны
        for (let i = 0; i < this.Overlays.length; i++) {
            this.Overlays[i].mainPriceRenderer.InvalidState = true;
        }
        //
        // Replace indicators
        //
        const allIndicators = this.IndicatorRenderers();
        for (let i = 0; i < allIndicators.length; i++) {
            allIndicators[i].InvalidState = true;
        }

        this.ReplaceIndicators(allIndicators);

        // To set correct min/max
        if (!this.mainWindow.AutoScale) {
            this.mainWindow.AutoFit();
        }

        this.LoadingNow = false;

        this.UpdateOverlay();

        this.IsDirty();
    }

    // TODO. Refactor.
    public subscribeTrades (): void {
        const ins = this.Instrument();
        if (!ins) return;

        DataCache.FQuoteCache.addListener(ins, this, HistoryType.QUOTE_TRADES);
    }

    public unsubscribeTrades (): void {
        const ins = this.Instrument();
        if (!ins) return;

        DataCache.FQuoteCache.removeListener(ins, this, HistoryType.QUOTE_TRADES);
    }

    public unsubscribeCashItemFromInstrument (): void {
        const cashItemSeries = this.MainCashItemSeries();
        const cashItem = cashItemSeries ? cashItemSeries.CashItem : null;
        if (cashItem) {
            cashItem.UnSubscribeCurrentInstrument();
        }
    }

    // TODO. Refactor.
    // Syncs chart's mainPriceRenderer and overlay's mainPriceRenderer data for drawing series.
    // If overlay's data is updated faster than data from chart's mainPriceRenderer,
    // overlay's last bar won't be drawn on chart, since overlay's screen data depends on amount of data from main series.
    public recalculateOverlaysIndices (): void {
        try {
            const overlays = this.Overlays;
            for (let i = 0, len = overlays.length; i < len; i++) {
                const overlay = overlays[i];
                overlay.recalculateIndices();
            }
        } catch (e) {
            ErrorInformationStorage.GetException(e);
        // console.error(e)
        }
    }

    // TODO. Refactor.
    public async LoadOverlayCashItem (overlay: TerceraChartOverlay): Promise<void> {
        let tfInfo = this.TimeFrameInfo();
        const account = this.Account();
        const spreadPlan = DataCache.GetSpreadPlan(account);
        tfInfo = tfInfo.Copy({ historyType: overlay.HistoryType, spreadPlan });

        // TODO. Non fixed list?
        const ins = DataCache.getInstrumentByName(overlay.Instrument);
        if (!ins) {
            console.error('Instrument not found.');
            return;
        }

        const historyParams = this.GetHistoryParams(ins, tfInfo);

        this.loadOverlayHistoryAbortController?.abort();
        this.loadOverlayHistoryAbortController = new AbortController();
        const signal = this.loadOverlayHistoryAbortController.signal;

        const newCashItem = await CashItem.CreateWithHistory(ins, historyParams, signal);
        if (signal.aborted || newCashItem == null) // new load history started
        {
            return;
        }

        this.OnLoadOverlayCashItemFinish(overlay, newCashItem);
    }

    public OnLoadOverlayCashItemFinish (overlay: TerceraChartOverlay, newCashItem: CashItem): void {
    // Создаем серию
        const cashItemSeries = new TerceraChartOverlaySeries(
            newCashItem,
            this.model.HistoryFrom,
            this.model.GetHistoryTo(),
            overlay.cashItemSeriesSettings,
            this.mainPriceRenderer.Series);

        overlay.mainPriceRenderer.Series = cashItemSeries;

        if (cashItemSeries) {
            cashItemSeries.HistoryExpanded.Subscribe(this.IsDirtyHist, this);
        }

        // To set correct min/max
        if (overlay.window.FminFloatY === 0 && overlay.window.FmaxFloatY === 0) {
            overlay.window.AutoFit();
        }

        // Оверлей валиден - можно рисовать
        overlay.mainPriceRenderer.InvalidState = false;

        // Need for update OverlayWindow rectangle
        this.LayoutWindowsContainers();
    }

    // #endregion

    public UpdateNewOrderTool (data): void {
        const TerceraChartNewToolRenderer: TerceraChartTradingToolsRenderer | null = this.mainWindow.GetMainThreadRenderer('TerceraChartTradingToolsRenderer');

        if (TerceraChartNewToolRenderer) {
            TerceraChartNewToolRenderer.UpdateNewOrderTool(data);
        }
    }

    public RemoveSelectedAndHoveredToolsFromAllWindows (): void {
        const windows = this.windowsContainer.Windows;
        const len = windows.length;
        for (let i = 0; i < len; i++) {
            const toolsRenderer = windows[i].GetRenderer(TerceraChartToolRenderer);
            if (!isNullOrUndefined(toolsRenderer)) {
                toolsRenderer.RemoveSelectedAndHoverTools();
            }
        }
    }

    public MVCModelItemChanged (item: TerceraChartMVCModelItem): void {
        const model = this.model;
        const windowsContainer = this.windowsContainer;

        switch (item.ItemType) {
        case ModelDataType.Style:
            this.mainPriceRenderer.ChartDrawingType = model.GetChartDrawingType();
            break;

        case ModelDataType.Instrument:{
        // Inform Renderers about changing instrument
            const newInstrument = model.GetInstrument();
            if (windowsContainer) {
                for (let i = 0; i < windowsContainer.Windows.length; i++) {
                    const allRenderers = windowsContainer.Windows[i].GetAllRenderers();
                    for (let j = 0; j < allRenderers.length; j++) {
                        const renderer: any = allRenderers[j];
                        if (renderer.Instrument !== undefined) {
                            renderer.Instrument = newInstrument;
                        }
                    }
                }
            }
            this.NeedSetDafaultsDataRage = true;
            break;
        }
        case ModelDataType.Account:{
        // Inform Renderers about changing account
            const newAcc = model.GetAccount();
            if (windowsContainer) {
                for (let i = 0; i < windowsContainer.Windows.length; i++) {
                    const allRenderers = windowsContainer.Windows[i].GetAllRenderers();
                    for (let j = 0; j < allRenderers.length; j++) {
                        const renderer: any = allRenderers[j];
                        if (renderer.Account !== undefined) {
                            renderer.Account = newAcc;
                        }
                    }
                }
            }
            break;
        }
        }
    }

    public ReplaceCashItem (newCashItem: CashItem): void {
        if (this.model == null) {
            return;
        }

        const oldSeries = this.mainPriceRenderer != null ? this.mainPriceRenderer.Series : null;

        if (oldSeries !== null) {
            oldSeries.HistoryExpanded.UnSubscribe(this.onMainSeriesHistoryExpanded, this);
        }

        const cashItemSeries = new TerceraChartCashItemSeries(newCashItem, this.model.HistoryFrom, this.model.GetHistoryTo(), this.cashItemSeriesSettings);
        this.mainPriceRenderer.Series = cashItemSeries;
        const timeScaleRender = this.windowsContainer.xScaleRenderer as TerceraChartTimeScaleRenderer;
        timeScaleRender.Series = cashItemSeries;

        if (cashItemSeries !== null) {
            cashItemSeries.HistoryExpanded.Subscribe(this.onMainSeriesHistoryExpanded, this);
        }

        const len = this.windowsContainer.Windows.length;

        for (let i = 0; i < len; i++) {
            const window = this.windowsContainer.Windows[i];
            window.PointsConverter = new CashItemPointsCoverter(window, cashItemSeries);
        }

        if (oldSeries) {
            const allIndicators = this.IndicatorRenderers();
            for (let i = 0; i < allIndicators.length; i++) {
                oldSeries._cashItem.RemoveIndicator(allIndicators[i].PTLIndicator);
            }
        }

        // List<TerceraChartBaseIndicatorRenderer> allInds = IndicatorRenderers;
        // foreach (TerceraChartBaseIndicatorRenderer rend in allInds)
        //            rend.Series = cashItemSeries;

        // MB TODO fix refresh
        // https://tp.traderevolution.com/entity/102886
        // https://tp.traderevolution.com/entity/103018
        if (oldSeries != null && oldSeries._cashItem !== newCashItem) {
            oldSeries.Dispose();
        }

        this.IsDirty();
    }

    public onMainSeriesHistoryExpanded (hasNewBars): void {
    // TODO. Refactor.
        this.recalculateOverlaysIndices();
        this.IsDirtyHist(hasNewBars);
    }

    public IsDirtyHist (hasNewBars): void {
        if (hasNewBars) {
            this.IsDirty(true);
        } else {
            this.IsDirty(LayersEnum.Quotes);
            this.IsDirty(LayersEnum.CrossHair);
        }
    }

    public CancelAction (context): boolean {
        return this.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Cancel, context));
    }

    public RefreshChart (): void {
        clearTimeout(this.refreshDelayTimeOut);
        this.refreshDelayTimeOut = null;
        this.refreshDelayTimeOut = setTimeout(this.RefreshChartProcces.bind(this), 300);
        this.autoLoadManager.stopLoading();
        this.autoLoadManager.resetCurrentView();
    }

    public RefreshChartProcces (): void {
        if (this.Instrument() !== null && this.TimeFrameInfo() !== null) {
            void this.LoadHistoryStart();
            if (this.TerceraChartTradingToolsRenderer) {
                this.TerceraChartTradingToolsRenderer.RepopulateTools();
            }
        }
    }

    public CallOnRefreshIsRequired (): void {
        this.RefreshIsRequired.Raise();
    }

    // #region Indicators
    public AddIndicator (indicator, setupProperties = false): boolean {
        if (indicator === null) {
            return false;
        }
        indicator.Symbol = this.Instrument().GetInteriorID();
        indicator.TimeFrameInfo = this.TimeFrameInfo();
        indicator.MainChart = this;
        // Edit properties
        const indicatorRenderer = this.GetCharIndicatorRenderer(indicator, this);
        if (setupProperties) {
            indicatorRenderer.editProperties(this);
        } else {
            this.AddIndicatorProcess(indicatorRenderer);
        }
    }

    public AddIndicatorProcess (indicatorRenderer: TerceraChartIndicatorRenderer): void {
        const indicator = indicatorRenderer.Indicator();
        indicator.Symbol = this.Instrument().GetInteriorID();
        indicator.TimeFrameInfo = this.TimeFrameInfo();
        indicator.MainChart = this;
        const ind = this.mainPriceRenderer.Series._cashItem.AddIndicator(indicator);
        if (ind !== null) {
            ind.Refresh();
        }
        indicatorRenderer.Series = this.mainPriceRenderer.Series;
        const render = this.mainWindow.GetRenderer(TerceraChartIndicatorStorageRenderer);
        render.Indicators.push(indicatorRenderer);
        this.CorrectIndicatorWindows();
    }

    public ReplaceIndicators (indicators): void {
        const len = indicators.length;
        for (let i = 0; i < len; i++) {
            const indRenderer = indicators[i];
            let ind = indRenderer.Indicator();
            if (!isNullOrUndefined(indRenderer.Series?._cashItem)) {
                indRenderer.Series._cashItem.RemoveIndicator(ind);
            }
            indRenderer.Series = this.mainPriceRenderer.Series;
            ind = indRenderer.Series._cashItem.AddIndicator(ind);
            if (ind !== null) {
                ind.Refresh();
            }
            ind.MainChart = this;
            ind.TimeFrameInfo = this.TimeFrameInfo();
            ind.Symbol = this.Instrument().GetInteriorID();
            indRenderer.InvalidState = false;
        }
    }

    public RemoveIndicator (indicatorRenderer, callCorrectIndicatorWindows = true): void {
        if (indicatorRenderer != null) {
            const ind = indicatorRenderer.Indicator();
            this.mainPriceRenderer.Series._cashItem.RemoveIndicator(ind);
            if (indicatorRenderer.WindowsNumber < this.windowsContainer.Windows.length) {
                const renderer = this.windowsContainer.Windows[indicatorRenderer.WindowsNumber].GetRenderer(TerceraChartIndicatorStorageRenderer);
                const remIndex = renderer.Indicators.indexOf(indicatorRenderer);
                renderer.Indicators.splice(remIndex, 1);
            }
            indicatorRenderer.Dispose();
            if (callCorrectIndicatorWindows) {
                this.CorrectIndicatorWindows();
            }
            ind.Dispose();
        }
    }

    public RemoveAllIndicators (): void {
        const allIndicatorRenderers = this.IndicatorRenderers();
        for (let i = 0; i < allIndicatorRenderers.length; i++) {
            this.RemoveIndicator(allIndicatorRenderers[i], true);
        }
        this.CorrectIndicatorWindows();
        this.IsDirty();
    }

    public IndicatorRenderers (): any[] | null {
        if (this.windowsContainer == null) {
            return null;
        }
        const res = [];
        for (let i = 0; i < this.windowsContainer.Windows.length; i++) {
            const renderer = this.windowsContainer.Windows[i].GetRenderer(TerceraChartIndicatorStorageRenderer);
            const inds = renderer.Indicators;
            const l_ = inds.length;
            for (let j = 0; j < l_; j++) {
                res.push(inds[j]);
            }
        }
        return res;
    }

    public CorrectIndicatorWindows (): void {
        let winCount = this.windowsContainer.Windows.length;
        for (let i = 0; i < winCount; i++) {
            const renderer = this.windowsContainer.Windows[i].GetRenderer(TerceraChartIndicatorStorageRenderer);
            for (let j = 0; j < renderer.Indicators.length; j++) {
                // Нашли индюк не на своем месте
                const indRenderer = renderer.Indicators[j];
                if (indRenderer != null && indRenderer.WindowsNumber != i) {
                    const remIndex = renderer.Indicators.indexOf(indRenderer);
                    renderer.Indicators.splice(remIndex, 1);
                    // Есть окно с подходящим номером
                    if (indRenderer.WindowsNumber < this.windowsContainer.Windows.length) {
                        const childRenderer = this.windowsContainer.Windows[indRenderer.WindowsNumber].GetRenderer(TerceraChartIndicatorStorageRenderer);
                        childRenderer.Indicators.push(indRenderer);
                    } else {
                        // Создаем нужное количество окон
                        while (indRenderer.WindowsNumber >= this.windowsContainer.Windows.length) {
                            this.AddWindow();
                        }
                        const childRenderer = this.windowsContainer.Windows[indRenderer.WindowsNumber].GetRenderer(TerceraChartIndicatorStorageRenderer);
                        childRenderer.Indicators.push(indRenderer);
                    }
                }
            }
        }
        // Удаляем пустые окна
        winCount = this.windowsContainer.Windows.length;
        const windowsToRemove = [];
        for (let i = 1; i < winCount; i++) {
            const indRenderer = this.windowsContainer.Windows[i].GetRenderer(TerceraChartIndicatorStorageRenderer).Indicators[0];

            if (indRenderer?.Indicator == null) {
                windowsToRemove.push(this.windowsContainer.Windows[i]);
            }
        }
        for (let i = 0; i < windowsToRemove.length; i++) {
            this.RemoveWindow(windowsToRemove[i]);
        }
        // Корректировка индексов (http://tp.pfsoft.net/entity/38104)
        for (let i = 1; i < this.windowsContainer.Windows.length; i++) {
            const renderer = this.windowsContainer.Windows[i].GetRenderer(TerceraChartIndicatorStorageRenderer);
            for (let j = 0; j < renderer.Indicators.length; j++) {
                const rend = renderer.Indicators[j];
                rend.WindowsNumber = i;
            }
        }
        this.CorrectPlateRenderers();
        this.LayoutWindowsContainers();
        this.IsDirty();
    }

    public SaveIndicators (): any {
        const instrument = this.model?.GetInstrument();
        const currentSymbol = (instrument != null) ? instrument.GetInteriorID() : '';
        let index = 0;
        const indicators: any = {};
        indicators.indicator = [];
        const allIndicatorRenderers = this.IndicatorRenderers();
        for (let i = 0; i < allIndicatorRenderers.length; i++) {
            const indicatorRenderer = allIndicatorRenderers[i];
            if (indicatorRenderer == null) {
                index++;
                continue;
            }
            const p = indicatorRenderer.PTLIndicator;
            if (!currentSymbol.includes(p.Symbol)) {
                continue;
            }
            const indicatorDataToSave: any = {};
            if (!isNullOrUndefined(p.Script)) {
                indicatorDataToSave.ProjectName = p.Script.ProjectName;
            }

            const props = indicatorRenderer.Properties();
            const dp = DynProperty.getPropertyByName(props, 'Win num');
            if (dp != null) {
                dp.value = dp.value - index;
            }

            indicatorDataToSave.properties = DynProperty.serialize(props);
            indicatorDataToSave.LinesCount = p.LinesCount;
            indicatorDataToSave.SeparateWindow = p.SeparateWindow;
            indicators.indicator.push(indicatorDataToSave);
        }
        return indicators;
    }

    public LoadIndicators (xml): void {
        if (isNullOrUndefined(xml)) {
            return;
        }
        const indicatorArray = xml.indicator;
        const len = indicatorArray.length;
        if (!Array.isArray(indicatorArray)) {
            try {
                this.LoadIndicator(indicatorArray);
            } catch (ex) {
                ErrorInformationStorage.GetException(ex);
            }
        } else {
            try {
                for (let i = 0; i < len; i++) {
                    this.LoadIndicator(indicatorArray[i]);
                }
            } catch (ex) {
                ErrorInformationStorage.GetException(ex);
            }
        }
    }

    public LoadIndicator (xml): void {
        const indicator = IndicatorManager.GetIndicator(xml.ProjectName);
        const indicatorProperties = DynProperty.deserialize(xml.properties);
        const indicatorRenderer = this.GetCharIndicatorRenderer(indicator, this);
        indicatorRenderer.callBack(indicatorProperties);
        /// /добавляю на чарт
        const renderer = this.mainWindow.GetRenderer(TerceraChartIndicatorStorageRenderer);
        renderer.Indicators.push(indicatorRenderer);
        this.CorrectIndicatorWindows();
        // #50999
        if (isNullOrUndefined(indicator.MainChart)) {
            indicator.MainChart = this;
        }
    }
    // #endregion

    // #region Overlays

    // Активный оверлей, если установлен для него рисуется шкала на главном окне
    public ActiveOverlayGet (): TerceraChartOverlay {
        return this.activeOverlay;
    }

    public ActiveOverlaySet (value: TerceraChartOverlay): void {
        this.activeOverlay = value;
        if (!isNullOrUndefined(this.FocusedOverlay)) {
            this.FocusedOverlay = value;
        }
    }

    public AddOverlayInstrument (instrumentName, callUpdateOverlays, style, historyType): TerceraChartOverlay {
        const overlay = new TerceraChartOverlay(this);
        overlay.Instrument = instrumentName;
        overlay.HistoryType = historyType;
        overlay.ChartDrawingTypeSet(style);
        this.overlayStorageRenderer.OverlaysWindows.push(overlay.window);
        this.Overlays.push(overlay);
        this.CorrectPlateRenderers();
        // Need for update OverlayWindow rectangle
        this.LayoutWindowsContainers();
        // Обновляем оверлеи
        if (callUpdateOverlays) {
            this.UpdateOverlay();
        }

        this.IsDirty();
        return overlay;
    }

    public SaveOverlays (): { overlays: any[] } {
        const overlayArr = [];

        const overlays = this.Overlays;
        const len = isValidArray(overlays) ? overlays.length : 0;
        for (let i = 0; i < len; i++) {
            const overlay = overlays[i];
            overlayArr.push({
                attributes: {
                    instrument: overlay.ActiveInstrument
                        ? overlay.ActiveInstrument.GetInteriorID()
                        : overlay.Instrument,
                    historyType: overlay.HistoryType,
                    style: overlay.ChartDrawingTypeGet(),
                    showOnChart: overlay.mainPriceRenderer.Visible ? 1 : 0,
                    showMarker: overlay.ShowMarker ? 1 : 0,
                    separateScale: overlay.SeparateScaleGet() ? 1 : 0
                },
                mainPriceRendererProperties: DynProperty.serialize(overlay.mainPriceRenderer.Properties())
            });
        }
        return { overlays: overlayArr };
    }

    public LoadOverlays (xml): void {
        if (isNullOrUndefined(xml)) {
            return;
        }

        try {
            this.RemoveAllOverlays();
            const overlaysJsonArr = xml.overlays;
            if (Array.isArray(overlaysJsonArr)) {
                const len = overlaysJsonArr ? overlaysJsonArr.length : 0;
                for (let i = 0; i < len; i++) {
                    this.LoadOverlay(overlaysJsonArr[i]);
                }
            } else this.LoadOverlay(overlaysJsonArr);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
    }

    public LoadOverlay (jsonItem): void {
        const attributes = jsonItem.attributes;

        const overlay = this.AddOverlayInstrument(
            attributes.instrument,
            false,
            parseInt(attributes.style),
            parseInt(attributes.historyType));

        if (isNullOrUndefined(overlay)) {
            return;
        }

        overlay.mainPriceRenderer.Visible = attributes.showOnChart == '1';
        overlay.ShowMarker = attributes.showMarker == '1';
        overlay.SeparateScaleSet(attributes.separateScale == '1');

        const properties = DynProperty.deserialize(jsonItem.mainPriceRendererProperties);
        overlay.mainPriceRenderer.callBack(properties);
    }

    public UpdateOverlay (): void {
        try {
            if (DataCache == null || !DataCache.Loaded) {
                return;
            }
            setTimeout(this.UpdateOverlaysAsync.bind(this), 1);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public async UpdateOverlaysAsync (): Promise<void> {
        try {
            for (let i = 0; i < this.Overlays.length; i++) {
                const overlay = this.Overlays[i];
                await this.LoadOverlayCashItem(overlay);
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public RemoveOverlay (over): void {
        if (this.ActiveOverlayGet() === over) {
            this.ActiveOverlaySet(null);
        }

        ArrayUtils.RemoveElementFromArray(this.overlayStorageRenderer.OverlaysWindows, over.window);
        ArrayUtils.RemoveElementFromArray(this.Overlays, over);
        this.CorrectPlateRenderers();
        over.Dispose();
        this.IsDirty();
    }

    public RemoveAllOverlays (): void {
        const overlays = this.Overlays;
        let i = overlays.length;
        while (i--) this.RemoveOverlay(overlays[i]);
    }
    // #endregion

    public AddWindow (): TerceraChartWindow {
        const priceScaleRender = this.GetChartPriceScaleRenderer(this, this.yScaleRendererSettings, TerceraChartPriceScaleRendererWindowType.Indicator);
        const leftPriceScaleRender = this.GetChartPriceScaleRenderer(this, this.yScaleRendererSettings, TerceraChartPriceScaleRendererWindowType.Indicator);
        leftPriceScaleRender.Aligment = TerceraChartPriceScaleRendererAligment.Left;

        const ww = this.GetChartWindow(priceScaleRender, leftPriceScaleRender);
        ww.PointsConverter = new CashItemPointsCoverter(ww, this.mainPriceRenderer.Series);
        const toolInstanceRenderer = new TerceraChartToolInstanceRenderer(this.ChartID, this);
        toolInstanceRenderer.Instrument = this.Instrument();
        toolInstanceRenderer.InstanceWindow = ww;
        ww.Renderers.push(toolInstanceRenderer);
        ww.Renderers.splice(1, 0, this.mainWindow.GetMainThreadRenderer('TerceraChartNewToolRenderer'));
        ww.Renderers.push(new TerceraChartIndicatorStorageRenderer(this));

        this.CalculateNewWindowHeightKoef(ww);
        this.windowsContainer.Windows.push(ww);
        priceScaleRender.window = ww;
        leftPriceScaleRender.window = ww;

        const toolRenderer = ww.GetRenderer(TerceraChartToolRenderer);
        toolRenderer.SelectedToolsListChanged.Subscribe(this.ToolsRenderer_SelectedToolsListChanged, this);

        return ww;
    }

    public CalculateNewWindowHeightKoef (ww): void {
    // normalize HeightKoef
        const normalizeKoef = 100 / this.mainWindow.HeightKoef;
        for (let i = 0; i < this.windowsContainer.Windows.length; i++) {
            this.windowsContainer.Windows[i].HeightKoef *= normalizeKoef;
        }
        // add first sub window
        if (this.windowsContainer.Windows.length === 1) {
            ww.HeightKoef = this.mainWindow.HeightKoef / 2;
        }
        // add second or third window
        if (this.windowsContainer.Windows.length >= 2) {
            let koefSum = 0;
            for (let i = 0; i < this.windowsContainer.Windows.length; i++) {
                if (!this.windowsContainer.Windows[i].IsMainWindow) {
                    koefSum += this.windowsContainer.Windows[i].HeightKoef;
                }
            }
            ww.HeightKoef = koefSum / (this.windowsContainer.Windows.length - 1);
            koefSum += ww.HeightKoef;

            // max height for sun windows
            let maxSubWindowHeight = 0;

            if (this.windowsContainer.Windows.length === 2) {
                maxSubWindowHeight = this.mainWindow.HeightKoef;
            }

            if (this.windowsContainer.Windows.length > 2) {
                maxSubWindowHeight = 2 * this.mainWindow.HeightKoef;
            }

            // recalculate all HeightKoef
            if (koefSum > maxSubWindowHeight) {
                for (let i = 0; i < this.windowsContainer.Windows.length; i++) {
                    if (!this.windowsContainer.Windows[i].IsMainWindow) {
                        this.windowsContainer.Windows[i].HeightKoef *= maxSubWindowHeight / koefSum;
                    }
                }

                ww.HeightKoef *= maxSubWindowHeight / koefSum;
            }
        }
    }

    public RemoveWindow (ww: TerceraChartWindow): void {
        const toolsRenderer = ww.GetRenderer(TerceraChartToolRenderer);
        toolsRenderer.SelectedToolsListChanged.UnSubscribe(this.ToolsRenderer_SelectedToolsListChanged, this);

        const remIndex = this.windowsContainer.Windows.indexOf(ww);
        this.windowsContainer.Windows.splice(remIndex, 1);
        ww.Dispose();
    }

    public ToolsRenderer_SelectedToolsListChanged (): void {
        this.TerceraChartActionProcessor.StateChanged.Raise();
    }

    /// <summary>
    /// определяем для всех вложенных окон нужно ли место под рисование плашек индюков/оверлеев
    /// </summary>
    public CorrectPlateRenderers (): void {
    //
    // определяем для всех вложенных окон нужно ли место под рисование плашек индюков/оверлеев
    //
        const winCount = this.windowsContainer.Windows.length;
        for (let i = 0; i < winCount; i++) {
            let plateRenderer: TerceraChartPlateRenderer = this.windowsContainer.Windows[i].GetMainThreadRenderer('TerceraChartPlateRenderer');

            if (i === 0) {
                const listOv = [];
                if (this.Overlays.length > 0) {
                    if (plateRenderer == null) {
                        plateRenderer = new TerceraChartPlateRenderer(this);
                        this.windowsContainer.Windows[i].Renderers.push(plateRenderer);
                    }
                    for (let j = 0; j < this.Overlays.length; j++) {
                        const overl = plateRenderer.GetPlateOverlay(this.Overlays[j], true);
                        listOv.push(overl);
                    }
                }
                if (plateRenderer != null) {
                    plateRenderer.UpdatePlateOverlays(listOv);
                }
            }

            const listInd = [];
            const renderer = this.windowsContainer.Windows[i].GetRenderer(TerceraChartIndicatorStorageRenderer);
            for (let j = 0; j < renderer.Indicators.length; j++) {
                try {
                    if (plateRenderer == null) {
                        plateRenderer = this.GetCharPlateIndicatorRenderer(this);
                        this.windowsContainer.Windows[i].Renderers.push(plateRenderer);
                    }
                    if (renderer.Indicators[j].Indicator() != null) {
                        const indic = plateRenderer.GetPlateIndicator(renderer.Indicators[j], true);
                        listInd.push(indic);
                    }
                } catch (ex) {
                    ErrorInformationStorage.GetException(ex);
                }
            }
            if (plateRenderer != null) {
                plateRenderer.UpdatePlateIndicators(listInd);
                plateRenderer.WinNum = i;
            }
            const terceraChartWindow = this.windowsContainer.Windows[i] as TerceraChartWindow;
            if ((plateRenderer != null && !plateRenderer.IsEmpty()) || terceraChartWindow.TerceraChartOverlayWindow) {
                this.windowsContainer.Windows[i].HeaderVisible = true;
                this.windowsContainer.Windows[i].PaddingTop = this.windowsContainer.Windows[i].HeaderHeight + 1;
            } else {
                this.windowsContainer.Windows[i].PaddingTop = this.HeaderHeight + 1;
                this.windowsContainer.Windows[i].HeaderVisible = false;
            }
        }
        // TODO MB SC2 Optimization ++13th
        this.IsDirty(LayersEnum.CrossHair);
        this.Draw();
    }

    public getAllIndicatorRenderers (): any[] {
        const res = [];
        const winCount = this.windowsContainer.Windows.length;
        for (let i = 0; i < winCount; i++) {
            const indicatorsStorage = this.windowsContainer.Windows[i].GetRenderer(TerceraChartIndicatorStorageRenderer).Indicators;
            for (const element of indicatorsStorage) {
                res.push(element);
            }
        }
        return res;
    }

    /// <summary>
    /// Конструктор тулзовин
    /// </summary>
    public CreateTool (toolType: DataCacheToolType): any {
        const tool = DataCacheToolFactory.Create(toolType, DataCache);
        tool.AssignedSymbol = this.Instrument().GetInteriorID();
        tool.Instrument = this.Instrument();
        return tool;
    }

    public ProcessChangeTool (tool: DataCacheTool): void {
        if (tool != null && this.Instrument() != null && tool.AssignedSymbol === this.Instrument().GetInteriorID()) {
            this.IsDirty(LayersEnum.Tools);
        }
    }

    // #region Tools

    // TODO. Optimize?
    get AllWindowsHoverAndSelectedTools (): ToolView[] {
        let allTools: ToolView[] = [];

        const wc = this.windowsContainer;
        if (!wc) return allTools;

        const windows = wc.Windows;
        if (windows) {
            const len = windows.length;
            for (let i = 0; i < len; i++) {
                const toolsRenderer = windows[i].GetRenderer(TerceraChartToolRenderer);
                if (!isNullOrUndefined(toolsRenderer)) {
                    allTools = allTools.concat(toolsRenderer.GetHoverAndSelectedToolsList());
                }
            }
        }

        return allTools;
    }

    // #endregion

    public repopulate (): void {
        const allIndicators = this.IndicatorRenderers();

        const len = allIndicators.length;
        for (let i = 0; i < len; i++) {
            const curInd = allIndicators[i].Indicator();
            curInd.repopulate();
        }

        this.RefreshChart();
    }

    public newQuote (): void {
        const mainPriceRenderer = this.mainPriceRenderer;
        const ins = this.Instrument();

        if (mainPriceRenderer && ins) {
        /* TODO.
        mainPriceRenderer.DayHigh = ins.OHLCCalculator.HighSpread(Account, TerceraChartHistoryType.GetOriginalHistoryType(ChartHistoryType, ins))
        mainPriceRenderer.DayLow = ins.OHLCCalculator.LowSpread(Account, TerceraChartHistoryType.GetOriginalHistoryType(ChartHistoryType, ins))
        */
        }

        this.IsDirty();
    }

    public windowsContainer_AfterResizeWindows (): void {
        if (this.overlayStorageRenderer != null) {
            this.overlayStorageRenderer.LayoutWindows();
        }
    }

    /// <summary>
    /// Current order plasing type is OCO
    /// </summary>
    public getIsOCOMode (): boolean {
        return this.TerceraChartTradingToolsRenderer.IsOCOMode;
    }

    public setIsOCOMode (value: boolean): void {
        this.TerceraChartTradingToolsRenderer.IsOCOMode = value;
    }

    public override Dispose (): void {
        clearTimeout(this.refreshDelayTimeOut);
        this.loadHistoryAbortController?.abort();
        this.loadOverlayHistoryAbortController?.abort();
        ToolsCache.OnChangeTool.UnSubscribe(this.ProcessChangeTool, this);

        const curSeries = this.mainPriceRenderer != null ? this.mainPriceRenderer.Series : null;
        if (curSeries !== null) {
            curSeries.HistoryExpanded.UnSubscribe(this.onMainSeriesHistoryExpanded, this);
            curSeries.Dispose();
        }

        this.unsubscribeTrades();

        this.chartController.Dispose();

        this.autoLoadManager.dispose();

        super.Dispose();
    }

    public GetHistoryParams (ins = null, TimeFrameInfo = null): ReloadHistoryParams {
        const instrument = ins ?? this.model.GetInstrument();
        let account = this.model.GetAccount();

        if (account == null || !account.IsOwnedByUser) {
            account = DataCache.getPrimaryAccount();
        }

        TimeFrameInfo = TimeFrameInfo ?? this.TimeFrameInfo();

        /// если выбран тип истории default тут true, иначе НЕЛЬЗЯ менять тип, т.к. юзер задал свой любимый. юзать historyType
        const historyType = TimeFrameInfo.HistoryType ?? this.ChartHistoryType();
        const UseDefaultInstrumentHistoryType = historyType === ChartHistoryType.Default;

        const FromTime = this.model.HistoryFrom; // Utils.DateTimeToUTC(this.model.HistoryFrom);
        const ToTime = this.model.GetHistoryTo(); // Utils.DateTimeToUTC(this.model.GetHistoryTo());

        const historyParams = ReloadHistoryParams.createFrom({ account, instrument, TimeFrameInfo, UseDefaultInstrumentHistoryType, FromTime, ToTime });

        return historyParams;
    }

    get DataExtenderAmount (): number {
        const series = this.MainCashItemSeries();
        if (!series) {
            return 0;
        }

        const list = series.FIndexList;
        return list.length > 0 ? list[0] : 0;
    }

    public setPortfolioReturnsRendererProperty (key, value): void {
        const portfolioRenderer = this.PortfolioChartReturnsRenderer;

        if (!portfolioRenderer) return;

        portfolioRenderer[key] = value;
    }

    // #endregion

    public setDataForTradingCentralRenderer (data): void {
        const tradingCentralRenderer = this.TradingCentralRenderer;
        if (!tradingCentralRenderer) return;

        tradingCentralRenderer.setLinesValues(data);
    }

    public setAutoLoadHistory (value): void {
        this.autoLoadManager.allowAutoLoad = value;
    }

    public resetCurrentView (): void {
        this.autoLoadManager.resetCurrentView();
    }
}
