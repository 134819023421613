// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../Commons/properties/Resources';
import { Message } from '../Utils/DirectMessages/Message';
import { PFSVendor } from '../Vendors/PFSVendor/PFSVendor';
import { CustomEvent } from '../Utils/CustomEvents';
import { ErrorInformationStorage } from './ErrorInformationStorage';
import { ConectionResultEnum, ConnectionStates, ConnectionErrorsEnum } from './ConnectionEnums';
import { type ConnectionInfoItem } from './ConnectionInfoItem';
import { EventEmitter } from 'events';
import { ConnectionAttemptResponse } from './ConnectionAttemptResponse';

class _Connection {
    public vendor: PFSVendor = null;
    public vendorMsgListener: any = null;
    public dataCache: any;
    public connectionState = ConnectionStates.DISCONNECTED;
    public onConnectStateChange = new CustomEvent();
    public onConnectProcessError = new CustomEvent();
    public onGetToken = new CustomEvent();
    public ConnectResultData: any = null;
    public Reconnecting = false;
    public lastConnectionAttemptResponse: any;
    public RefreshAccessTokenInterval: any = null;
    public funcBeforeDisconnectCallback: any;
    private readonly _eventEmitter: EventEmitter = new EventEmitter();

    constructor (dataCache, funcBeforeDisconnectCallback) {
        this.dataCache = dataCache;

        this.lastConnectionAttemptResponse = { text: '' };

        this.funcBeforeDisconnectCallback = funcBeforeDisconnectCallback;
    }

    public connect (connectParams, fromReconnect: any = undefined): any {
        let vendor = null;

        if (!this.Reconnecting) {
            this.ConnectResultData = null;
        }

        vendor = this.createPFSVendor();

        if (this.vendor) {
            this.ClearPreviousVendorRefs();
        }

        this.vendor = vendor;

        if (this.vendorMsgListener) {
            this.SubscribeOnFullLoging(this.vendorMsgListener);
        } else {
            this.UnSubscribeOnFullLoging();
        }

        const dc = this.dataCache;
        // dc.cleanup();

        vendor.Test = _Connection.Test++;
        const qc = dc.FQuoteCache;
        qc.onRequestQuotesSubscription.Subscribe(vendor.SubscribeSymbol, vendor);
        qc.onCancelQuotesSubscription.Subscribe(vendor.UnsubscribeSymbol, vendor);

        vendor.AddMessageListener(dc.NewMessage, dc);
        vendor.AddConnectedListener(this.OnVendorConnected, this);
        vendor.AddDisconnectedListener(this.OnVendorDisconnected, this);
        vendor.AddConnectionLostListener(this.OnConnectionLost, this);
        vendor.AddOnErrorListener(this.OnError, this);

        this.lastConnectionAttemptResponse = { text: '' };

        if (this.Reconnecting) {
            return this.RefreshAccessToken(fromReconnect)
                .then(function (ConnectResultData) {
                    if (!this.Reconnecting) {
                        return Promise.resolve(true);
                    }

                    if (ConnectResultData.Valid && ConnectResultData.StreamOpened) {
                        return this._connectProcess(vendor, connectParams);
                    } else if (!ConnectResultData.Valid && ConnectResultData.StreamOpened) {
                        this.lastConnectionAttemptResponse = { text: Resources.getResource('Session has expired. Please login.') };
                        this.disconnect();
                        return Promise.resolve(false);
                    }

                    if (fromReconnect && !ConnectResultData.StreamOpened) {
                        return Promise.resolve([false, true]);
                    }
                }.bind(this));
        } else {
            return this._connectProcess(vendor, connectParams);
        }
    }

    public _connectProcess (vendor, connectParams): any {
        return vendor.Connect(connectParams).then(function (ConnectResultData) {
            const data: any = {};
            this.Reconnecting = false;

            data.ConnectParams = connectParams;
            data.ConnectResultData = ConnectResultData;

            // value in seconds
            if (ConnectResultData.accessTokenLifeTime) {
                if (this.RefreshAccessTokenInterval) { clearInterval(this.RefreshAccessTokenInterval); }
                this.RefreshAccessTokenInterval = null;

                // 15 seconds before
                const timeout = (ConnectResultData.accessTokenLifeTime - 15) * 1000;
                this.RefreshAccessTokenInterval = setTimeout(this.RefreshAccessToken.bind(this), timeout);
            }
            this.ConnectResultData = data;
            if (!isNullOrUndefined(ConnectResultData.accessToken)) {
                this.onGetToken.Raise(this);
            }
            return ConnectResultData.Connected;
        }.bind(this));
    }

    public OnVendorConnected (connectedArguments): void {
        if (connectedArguments.isPasswordExpired) {
            this.lastConnectionAttemptResponse = { text: '', UserID: connectedArguments.UserID, isPasswordExpired: connectedArguments.isPasswordExpired, ConnectReason: connectedArguments.ConnectionStateCode };
            this.SetConnectionState(ConnectionStates.HALFCONNECTED);
        } else {
            this.SetConnectionState(ConnectionStates.CONNECTED);
        }
    }

    public disconnect (isNoNActivity = false): void {
        if (this.funcBeforeDisconnectCallback) {
            this.funcBeforeDisconnectCallback();
        }

        this.Reconnecting = false;
        if (this.vendor) {
            this.vendor.Disconnect({ isNoNActivity });
        }
    }

    public ClearPreviousVendorRefs (): void {
        const vendor = this.vendor;

        const dc = this.dataCache;
        const qc = dc.FQuoteCache;

        dc.cleanup(); // не сможем отписаться от котировок (например в алертах) во время очистки dataCache если до этого отпишемся от vendor.UnsubscribeSymbol

        qc.onRequestQuotesSubscription.UnSubscribe(vendor.SubscribeSymbol, vendor);
        qc.onCancelQuotesSubscription.UnSubscribe(vendor.UnsubscribeSymbol, vendor);

        vendor.RemoveMessageListener(dc.NewMessage, dc);
        vendor.RemoveConnectedListener(this.OnVendorConnected, this);
        vendor.RemoveDisconnectedListener(this.OnVendorDisconnected, this);
        vendor.RemoveConnectionLostListener(this.OnConnectionLost, this);
        vendor.RemoveOnErrorListener(this.OnError, this);
    }

    public OnError (): void {
        const disconectArguments = {
            ErrorInConnection: true,
            Text: Resources.getResource('connect.connection.unknown.error')
        };

        this.OnVendorDisconnected(disconectArguments); // предотвращаем зависание на Connecting
    }

    public OnVendorDisconnected (disconectArguments): void {
        this.ClearPreviousVendorRefs();
        let forseConnectionStateChange = !isNullOrUndefined(disconectArguments) ? disconectArguments.ErrorInConnection : false;
        if (!isNullOrUndefined(disconectArguments)) {
            if (disconectArguments.LogoutType === 1 && (this.connectionState === ConnectionStates.CONNECTION_LOST || this.connectionState === ConnectionStates.CONNECTED)) {
                this.OnConnectionLost();
                return;
            }

            if (!isNullOrUndefined(disconectArguments.verificationMode)) {
                this.lastConnectionAttemptResponse = { text: '', connectReason: ConectionResultEnum.AdditionalVerification, verificationModeCode: disconectArguments.verificationMode };
                this.SetConnectionState(ConnectionStates.DISCONNECTED, true);
                return;
            }
            switch (disconectArguments.ErrorCode) {
            case ConnectionErrorsEnum.ERROR_MULTI_POLICY:

                this.onConnectProcessError.Raise(new ConnectionAttemptResponse(disconectArguments.Text, ConnectionErrorsEnum.ERROR_MULTI_POLICY));
                this._eventEmitter.emit('errorMultiPolicy', disconectArguments);
                forseConnectionStateChange = true;
                break;

            case ConnectionErrorsEnum.ERROR_NEED_ACTIVATION:
                this.lastConnectionAttemptResponse = { text: disconectArguments.Text, connectReason: ConectionResultEnum.NeedActivation };
                break;

            case ConnectionErrorsEnum.INVALID_BRANDING_KEY:
                this.lastConnectionAttemptResponse = { text: disconectArguments.Text, ErrorCode: disconectArguments.ErrorCode, connectReason: ConectionResultEnum.InvalidBrandingKey };
                break;

            case ConnectionErrorsEnum.MAX_CONCURRENT_CONNECTIONS:
                this.onConnectProcessError.Raise(new ConnectionAttemptResponse(disconectArguments.Text, ConnectionErrorsEnum.MAX_CONCURRENT_CONNECTIONS));
                forseConnectionStateChange = true;
                break;

            default:
                if (!isNullOrUndefined(disconectArguments.Text)) {
                    this.lastConnectionAttemptResponse = { text: disconectArguments.Text, ErrorCode: disconectArguments.ErrorCode };
                }
            }
        }

        this.SetConnectionState(ConnectionStates.DISCONNECTED, forseConnectionStateChange);
    }

    public SubscribeOnErrorMultiPolicy (callback: (disconectArguments) => void): void {
        this._eventEmitter.on('errorMultiPolicy', callback);
    }

    public UnsubscribeOnErrorMultiPolicy (callback: (disconectArguments) => void): void {
        this._eventEmitter.off('errorMultiPolicy', callback);
    }

    public OnConnectionLost (): void {
        this.Reconnecting = true;
        if (this.RefreshAccessTokenInterval) {
            clearInterval(this.RefreshAccessTokenInterval);
        }
        this.RefreshAccessTokenInterval = null;
        this.SetConnectionState(ConnectionStates.CONNECTION_LOST);
    }

    public SetConnectionState (state, forse: any = undefined): void {
        const needEvent = this.connectionState !== state;
        this.connectionState = state;
        if (needEvent || forse) {
            this.onConnectStateChange.Raise(this);
        }
    }

    public getState (): ConnectionStates {
        return this.connectionState;
    }

    public isDisconnected (): boolean {
        return this.connectionState === ConnectionStates.DISCONNECTED;
    }

    public isConnectionCorrect (): boolean {
        return this.connectionState !== ConnectionStates.DISCONNECTED && this.connectionState !== ConnectionStates.CONNECTION_LOST;
    }

    public getPackIn (): number {
        if (this.vendor) {
            return this.vendor.in;
        } else {
            return 0;
        }
    }

    public getPackOut (): number {
        if (this.vendor) {
            return this.vendor.out;
        } else {
            return 0;
        }
    }

    public SubscribeOnFullLoging (handler): void {
        if (this.vendor?.SubscribeOnFullLoging) {
            this.vendor.SubscribeOnFullLoging(handler);
        }

        this.vendorMsgListener = handler;
    }

    public UnSubscribeOnFullLoging (): void {
        if (this.vendor?.UnSubscribeOnFullLoging) {
            this.vendor.UnSubscribeOnFullLoging();
        }

        this.vendorMsgListener = null;
    }

    public GetMessagesList (): Record<string, string> {
    // TODO
        return Message.CodesString;
    // if (this.vendor && this.vendor.GetMessagesList)
    //    return this.vendor.GetMessagesList();
    }

    public SendPing (): any {
        const startTime = new Date().getTime();
        if (this.vendor) {
            return this.vendor.SendPing().then(function () {
                const finishTime = new Date().getTime();
                const diff = finishTime - startTime;
                return diff;
            });
        }
    }

    public RefreshAccessToken (fromReconnect): any {
        if (!this.ConnectResultData) {
            return Promise.resolve(false);
        }

        return this.vendor.RefreshAccessToken(this.ConnectResultData, fromReconnect)
            .then(function (TokenDataObj) {
                if (!TokenDataObj.StreamOpened) {
                    return TokenDataObj;
                }

                if (!TokenDataObj.Valid) {
                    return TokenDataObj;
                }

                const data = this.ConnectResultData;
                const CP = data.ConnectParams;
                const CRD = data.ConnectResultData;

                CP.accessToken = CRD.accessToken = TokenDataObj.accessToken;
                CRD.accessTokenLifeTime = TokenDataObj.accessTokenLifeTime;
                CRD.refreshToken = TokenDataObj.refreshToken;
                CRD.refreshTokenLifeTime = TokenDataObj.refreshTokenLifeTime;

                // value in seconds
                if (TokenDataObj.accessTokenLifeTime && !this.Reconnecting) {
                // 15 seconds before
                    let timeout = (TokenDataObj.accessTokenLifeTime - 15) * 1000;
                    // anti Ira defence
                    if (timeout >= 2147483647) // limits it to (2^31)-1 ms (2147483647 ms)
                    {
                        timeout = 2147483640;
                    } // well, to be sure

                    this.RefreshAccessTokenInterval = setTimeout(this.RefreshAccessToken.bind(this), timeout);
                }

                return { Valid: true, StreamOpened: true };
            }.bind(this)).catch(function () {
                console.log('Refresh Access Token FAILED!!!!!!!');
                return { Valid: false, StreamOpened: false, FatalError: true };
            });
    }

    public AccountOperationRequest (data): any {
        if (this.vendor) {
            return this.vendor.AccountOperationRequest(data);
        }
        return Promise.resolve(null);
    }

    public ChangePassword (curPwd, newPwd, UserID, verificationPassword): any {
        const accessToken = this.ConnectResultData?.ConnectParams ? this.ConnectResultData.ConnectParams.accessToken : '';

        if (this.vendor) {
            return this.vendor.ChangePassword(curPwd, newPwd, UserID, verificationPassword, accessToken);
        }
        return Promise.resolve(null);
    }

    public ChangeTradingPassword (curPwd, newPwd): any {
        if (this.vendor) {
            return this.vendor.ChangeTradingPassword(curPwd, newPwd);
        }
        return Promise.resolve(null);
    }

    public async SendSOAPRequest (request, connectTo): Promise<any> {
        return await PFSVendor.SendSOAPRequest(request, connectTo, this.vendorMsgListener);
    }

    // msgType - name of method. like SendRequestRecoveryPassword, SendPasswordRecoveryChangeRequest etc
    public async SendMessageToVendor (url, isSSL, msgType, dataForMsg): Promise<any> {
        return await PFSVendor.SendMessageToVendor(url, isSSL, msgType, dataForMsg, this.vendorMsgListener);
    }

    public GetFirstTradeStreamData (curPwd, newPwd) {
        if (this.vendor) {
            return this.vendor.GetFirstTradeStreamData();
        }
        return null;
    }

    // #region Vendor Factory

    public createPFSVendor (): PFSVendor {
        return new PFSVendor();
    }

    // #endregion Vendor Factory

    public async GetHistoryPromise (historyParams, signal): Promise<any[]> {
        const vndr = this.vendor;
        if (!vndr) {
            return [];
        }

        const intervals = await vndr.GetHistoryPromise(historyParams, this.ConnectResultData, signal);
        return intervals;
    }

    public static Test = 1;

    /// <summary>
    /// пользователь заблокирован после брутфорс атаки, время до авторазблокировки НЕ доступно (авторазблокировка не отработает) - обычно после рестарта
    /// </summary>
    public static ERROR_LOCKED_AFTER_BRUTEFORCE_PERMANENT = 195;

    /// <summary>
    /// пользователь заблокирован после брутфорс атаки, время до авторазблокировки доступно
    /// </summary>
    public static ERROR_LOCKED_AFTER_BRUTEFORCE_TIME = 215;

    /// <summary>
    /// у пользователя установлена блокировка Locked в БО
    /// </summary>
    public static ERROR_LOCKED_BACKOFFICE = 214;

    public getLocalizedConnectionErrorString (LoginName?): string {
        let rawString = this.lastConnectionAttemptResponse.text;
        // let code = null;
        let JSONParsed = null;
        if (rawString) {
            try {
                JSONParsed = JSON.parse(rawString);
                rawString = JSONParsed.message;
            // code = rawString.code || null
            } catch (e) {
                JSONParsed = null;
                ErrorInformationStorage.GetException(e);
            }
        }
        if (rawString === 'password.expired') { return ''; }
        if (rawString === 'additional.verification') {
            return Resources.getResource('screen.licensing.statusMessages.connecting');
        }
        let locString = '';

        locString = Resources.getResource(rawString);
        const att_index = rawString.indexOf('Attempt');
        if (att_index !== -1) {
            const att_c = rawString.substring(att_index + 8).trim();
            locString = Resources.getResource('general.connection.Login/password combination is not valid. Attempt').replace('{0}', att_c);
        } else {
            let tmp_rawString = 'general.connection.' + rawString;
            if (Resources.IsResourcePresent(tmp_rawString)) {
                locString = Resources.getResource(tmp_rawString);
            } else {
                tmp_rawString = 'general.connection.' + rawString + '.';
                if (Resources.IsResourcePresent(tmp_rawString)) {
                    locString = Resources.getResource(tmp_rawString);
                }
            }
        }

        if (JSONParsed?.code) {
            const code = parseInt(JSONParsed.code);
            switch (code) {
            case _Connection.ERROR_LOCKED_AFTER_BRUTEFORCE_PERMANENT:
                locString = Resources.getResource('User is locked after brute force!');
                break;
            case _Connection.ERROR_LOCKED_AFTER_BRUTEFORCE_TIME:
                locString = Resources.getResource('screen.LoginScreen.responceErrors.bruteforceBlockedMinutes').replace('{1}', LoginName).replace('{2}', JSONParsed.minutes);
                break;
            case _Connection.ERROR_LOCKED_BACKOFFICE:
                locString = Resources.getResource('User is blocked. Please contact support.');
                break;
            }
        }
        return locString;
    }
}

export let Connection: _Connection | null = null;

export function ConnectionInstanceCreator (dataCache, funcBeforeDisconnectCallback: any = undefined): void {
    Connection = new _Connection(dataCache, funcBeforeDisconnectCallback);
}

class _ConnectionInfo {
    public ConnectionInfoArray: ConnectionInfoItem[] = [];
    public OnConnectionInfoArrayChanged = new CustomEvent();
}

export const ConntionInfo = new _ConnectionInfo();
