// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from '../../Commons/Connection';
import { CustomErrorClass, ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources, CurrentLang } from '../../Commons/properties/Resources';
import { ControlsUtils } from '../UtilsClasses/ControlsUtils';
import { FilesHelper } from '../../Utils/FilesHelper';
import { TerceraReportsPanelTemplate } from '../../templates.js';
import { TerceraDateTimePicker } from '../elements/DateTimePicker/TerceraDateTimePicker';
import { TerceraAssetComboBox } from '../elements/TerceraAssetComboBox';
import { TerceraCheckBox } from '../elements/TerceraCheckBox';
import { TerceraCheckBoxGroup } from '../elements/TerceraCheckBoxGroup';
import { TerceraLabel } from '../elements/TerceraLabel';
import { TerceraTextBox } from '../elements/TerceraTextBox';
import { MessageBoxType, TerceraMessageBox } from '../screen/TerceraMessageBox';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { DirectReportGroupResponse, ReportTable, type DirectReportResponseMessage } from '../../Utils/DirectMessages/DirectMessagesImport';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { QuickTableComparingType } from '../../Utils/QuickTableMisc/QuickTableComparingType';
import { AllowedReport, type ReportParam } from '../../Commons/cache/AllowedReport';
import { ParamType } from '../../Commons/cache/AllowedReportConstants';
import { DataCache } from '../../Commons/DataCache';
import { TerceraAccountLookup, type _AccountItemAll, _AccountItemSelected } from '../elements/Lookup/TerceraAccountLookup';
import { TerceraInstrumentLookup } from '../elements/Lookup/TerceraInstrumentLookup';
import { TerceraGroupPanel } from '../elements/TerceraGroupPanel';
import { type Account } from '../../Commons/cache/Account';
import { ApplicationPanel } from './ApplicationPanel';

interface IParsedTable {
    success: boolean
    tableName: string
    cols: Array<{ Type: number, Name: string }>
    rows: Array<{ Cells: Array<{ Label: string, Value: string }> }>
}

export class ReportsPanel extends ApplicationPanel {
    public static readonly CSV = 'screen.reports.csv';
    public static readonly EXCEL = 'screen.reports.excel';
    public static readonly HTML = 'screen.reports.html';

    public static readonly exportFormats = [ReportsPanel.CSV, ReportsPanel.EXCEL, ReportsPanel.HTML];

    public static readonly REPORT_LIST_PADDING = 20;
    public static readonly REPORT_LIST_MIN_WIDTH = 200;
    public static readonly REPORT_LIST_MAX_WIDTH = 400;
    public static readonly REPORT_LIST_FONT = '12px "Trebuchet MS"';

    public reportParamProcessDict: Record<number, () => { labelControl?: TerceraLabel, mainControl }> | null = null; // Record<ParamType, () => { labelControl?: TerceraLabel, mainControl }>

    public paramControlArr: any[] = [];
    public accountControl: TerceraAccountLookup | null = null;
    public generateReportPromise: any = null;

    constructor () {
        super();

        const procDict = {};

        procDict[ParamType.TextBox] = this.processTextBoxReportType;
        procDict[ParamType.DateBox] = this.processDateBoxReportType;

        if (DataCache.getNumberOfAccounts() !== 1) {
            procDict[ParamType.AccountLookup] = this.processAccountLookupReportType;
        }

        procDict[ParamType.InstrumentsLookup] = this.processInstrumentsLookupReportType;
        procDict[ParamType.CheckBox] = this.processCheckBoxReportType;
        procDict[ParamType.CheckBoxGroup] = this.processCheckBoxGroupReportType;
        procDict[ParamType.AssetComboBox] = this.processAssetComboBoxReportType;
        procDict[ParamType.AssetComboBoxWithoutAll] = this.processAssetComboBoxWithoutAllReportType;

        this.reportParamProcessDict = procDict;
    }

    public override getType (): PanelNames { return PanelNames.ReportsPanel; }

    public override oninit (): void {
        super.oninit();

        if (DataCache.EnableForceLinkingByAccount()) { // https://tp.traderevolution.com/entity/119990
            void this.set('canLinkByAccount', false);
        }
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.on('onExportToClick', this.onExportToClick.bind(this));
        this.on('cancelPromise', this.cancelReportPromise.bind(this));

        this.observe('selectedReportComboItem', this.onSelectedReportComboItemChanged);

        this.localize();
        this.center();
    }

    public clearParamControlsArr (): void {
        const paramControlArr = this.paramControlArr;
        if (!isValidArray(paramControlArr)) return;

        let i = paramControlArr.length - 1;
        while (i >= 0) {
            this.Controls.paramsPanel.removeControl(paramControlArr[i]);
            i--;
        }

        this.paramControlArr = [];
    }

    public override localize (): void {
        super.localize();
        void this.set({
            header: Resources.getResource('screen.reports.Reports'),
            reportTypeString: Resources.getResource('screen.reports.reportType'),
            noDataErrorText: Resources.getResource('screen.reports.reports.noData'),
            cancelBtnText: Resources.getResource('screen.export.Cancel')
        });

        const items = [];

        ReportsPanel.exportFormats.forEach(function (el) {
            items.push({ text: Resources.getResource(el), value: el });
        });

        void this.set('exportToCBItems', items);

        void this.set('exportToTitle', Resources.getResource('screen.reports.exportToButton'));
        void this.set('exportToTitleItem', { text: this.get('exportToTitle') });
    }

    public override onMouseDown (event, isHeader: boolean): void {
        super.onMouseDown(event, isHeader);

        void this.set('noDataErrorVisibility', false);
    }

    public override populate (): void {
        super.populate(true);

        const reportArr = [];

        const reportDict = DataCache.getAllowedReportDict();
        for (const key in reportDict) {
            const report = reportDict[key];
            if (!report.key.includes(AllowedReport.TECHNICAL_REPORT_KEY_PREFIX)) {
                reportArr.push(report);
            }
        }

        reportArr.sort(function (r1, r2) {
            return r1.toString().localeCompare(r2.toString());
        });

        let maxReportNameLength = 0;
        const cbItemArr = reportArr.map(function (report) {
            const reportName = report.toString();
            const textW = ControlsUtils.GetTextWidth(reportName, ReportsPanel.REPORT_LIST_FONT) + ReportsPanel.REPORT_LIST_PADDING;

            maxReportNameLength = Math.max(maxReportNameLength, textW); // #102705

            return {
                text: reportName,
                value: report
            };
        });

        const reportListWidth = Math.max(ReportsPanel.REPORT_LIST_MIN_WIDTH, Math.min(ReportsPanel.REPORT_LIST_MAX_WIDTH, maxReportNameLength));

        void this.set({
            reportsComboBoxList: cbItemArr,
            reportsComboBoxListItemsWidth: reportListWidth
        });
    }

    public onSelectedReportComboItemChanged (item): void {
        if (isNullOrUndefined(item)) {
            return;
        }

        this.populateParamsControls(item.value);
    }

    public populateParamsControls (report: AllowedReport): void {
        this.clearParamControlsArr();

        const procDict = this.reportParamProcessDict;

        const reportParams = report.params.slice();
        reportParams.sort(ReportsPanel.compareReportParams);

        const len = reportParams.length;

        for (let i = 0; i < len; i++) {
            const rp = reportParams[i];

            if (rp.type !== ParamType.DateBox) // #93255 <- скрываем все фильтры кроме фильтра по дате
            {
                if (rp.type !== ParamType.AccountLookup) // #99187 <- показываем аккаунты
                {
                    continue;
                }
            }

            const handler = procDict[rp.type];
            if (isNullOrUndefined(handler)) continue;

            const res = handler.call(this, rp);
            if (isNullOrUndefined(res)) continue;

            const groupControl = new TerceraGroupPanel();
            this.paramControlArr.push(groupControl);
            this.Controls.paramsPanel.addControl(groupControl);

            const labelControl = res.labelControl;
            if (!isNullOrUndefined(labelControl)) {
                groupControl.addControl(labelControl);
            }

            const mainControl = res.mainControl;
            if (!isNullOrUndefined(mainControl)) {
                groupControl.paramType = ParamType.GroupPanel;
                groupControl.ReportParamName = rp.name;
                groupControl.addControl(mainControl, true);
            }
        }
    }

    // #region Process Report Controls

    public processTextBoxReportType (reportParam: ReportParam): { labelControl: TerceraLabel, mainControl: TerceraTextBox } {
        const label = new TerceraLabel();
        void label.set({
            text: reportParam.toString()
        });

        const tBox = new TerceraTextBox();
        tBox.paramType = ParamType.TextBox;
        void tBox.set('text', '');

        return {
            labelControl: label,
            mainControl: tBox
        };
    }

    public processCheckBoxReportType (reportParam: ReportParam): { mainControl: TerceraCheckBox } {
        const checkBox = new TerceraCheckBox();
        checkBox.paramType = ParamType.CheckBox;
        void checkBox.set({
            text: reportParam.toString(),
            checked: false
        });

        return {
            mainControl: checkBox
        };
    }

    public processCheckBoxGroupReportType (reportParam: ReportParam): { labelControl: TerceraLabel, mainControl: TerceraCheckBoxGroup } {
        const label = new TerceraLabel();
        void label.set({
            text: reportParam.toString()
        });

        const cboxGroup = new TerceraCheckBoxGroup();
        cboxGroup.paramType = ParamType.CheckBoxGroup;

        const dataArray = [];
        const values = reportParam.options;
        const len = isValidArray(values) ? values.length : 0;
        for (let i = 0; i < len; i++) {
            const val = values[i];
            dataArray.push({
                text: ReportsPanel.getParamText(val.text),
                value: val.value,
                checked: true
            });
        }
        void cboxGroup.set('dataArray', dataArray);

        return {
            labelControl: label,
            mainControl: cboxGroup
        };
    }

    public processDateBoxReportType (reportParam: ReportParam): { labelControl: TerceraLabel, mainControl: TerceraDateTimePicker } {
        const dateFromType = reportParam.name.indexOf('from') > -1;

        const label = new TerceraLabel();
        void label.set({
            text: reportParam.toString()
        });

        const dtPicker = new TerceraDateTimePicker();
        dtPicker.paramType = ParamType.DateBox;
        void dtPicker.set('dateFromStyle', dateFromType);

        const dt = new Date();
        dt.setUTCMilliseconds(0);
        if (reportParam.name === 'toDate') {
            dt.setHours(23);
            dt.setMinutes(59);
            dt.setSeconds(59);
        } else {
            dt.setHours(0);
            dt.setMinutes(0);
            dt.setSeconds(0);
        }

        void dtPicker.set('dateTime', dt);

        return {
            labelControl: label,
            mainControl: dtPicker
        };
    }

    public processAccountLookupReportType (reportParam: ReportParam): { labelControl: TerceraLabel, mainControl: TerceraAccountLookup } {
        const label = new TerceraLabel();
        void label.set({
            text: reportParam.toString()
        });

        const accLookup = new TerceraAccountLookup();
        accLookup.oninit();
        accLookup.IsMultiSelect = true;
        accLookup.isMultiSelectMode = true;
        accLookup.paramType = ParamType.AccountLookup;
        accLookup.observe('selectedItem', this.onAccountChanged.bind(this));

        let selItem = null;
        if (!isNullOrUndefined(this.accountControl)) {
            selItem = this.accountControl.get('selectedItem');
        }

        void accLookup.set('ignoreForceLinkingByAccount', true);

        if (!isNullOrUndefined(selItem)) {
            void accLookup.set('selectedItem', selItem);
        }

        this.accountControl = accLookup;

        return {
            labelControl: label,
            mainControl: accLookup
        };
    }

    public processInstrumentsLookupReportType (reportParam: ReportParam): { labelControl: TerceraLabel, mainControl: TerceraInstrumentLookup } {
        const label = new TerceraLabel();
        void label.set({
            text: reportParam.toString()
        });

        const insLookup = new TerceraInstrumentLookup();
        insLookup.oninit();
        insLookup.IsMultiSelect = true;
        // insLookup.isMultiSelectMode = true;
        insLookup.paramType = ParamType.InstrumentsLookup;

        return {
            labelControl: label,
            mainControl: insLookup
        };
    }

    public processAssetComboBoxReportType (reportParam: ReportParam): { labelControl: TerceraLabel, mainControl: TerceraAssetComboBox } {
        const label = new TerceraLabel();
        void label.set({
            text: reportParam.toString()
        });

        const assetComboBox = new TerceraAssetComboBox();
        assetComboBox.paramType = ParamType.AssetComboBox;

        return {
            labelControl: label,
            mainControl: assetComboBox
        };
    }

    public processAssetComboBoxWithoutAllReportType (reportParam: ReportParam): { labelControl: TerceraLabel, mainControl: TerceraAssetComboBox } {
        const res = this.processAssetComboBoxReportType(reportParam);
        void res.mainControl.set('allItemVisible', false);
        return res;
    }

    // #endregion

    public getReport (): any {
        if (!this.checkTimeParameters()) {
            return TerceraMessageBox.Show(
                Resources.getResource('screen.reports.error'),
                Resources.getResource('screen.reports.rangeError'),
                MessageBoxType.Error,
                null, null, false, true);
        }

        // TODO. Ugly.
        const reportTypeObj = this.get('selectedReportComboItem.value');
        const paramDict = this.getAdvancedParamDict(reportTypeObj);

        void this.set('reportResponseMessage', null);

        void this.set('loading', true);

        const self = this;

        this.generateReportPromise = Connection.vendor.GenerateReportPromise(reportTypeObj.keyForServer, paramDict)
            .then(function (reportResponseMessage: DirectReportResponseMessage | DirectReportGroupResponse) {
                void self.set('noDataErrorVisibility', false);
                void self.set('reportResponseMessage', reportResponseMessage);
                const success = self.parseReportResponceMessage();

                if (!success) {
                    void self.set('noDataErrorVisibility', true);
                }
            })
            .catch(function () {
                void self.set('noDataErrorVisibility', true);
                const ex = new CustomErrorClass('ReportsPanel error', 'ReportsPanel.getReport', 'getReport -> GenerateReportPromise');
                ErrorInformationStorage.GetException(ex);

            // console.error('Report fetch error.')
            })
            .then(function () { self.restoreAfterLoading(); });
    }

    public getAdvancedParamDict (reportTypeObj): '' | { locale: string, reportTypeObj: any, mainParamDict: {} } {
        const controls = this.paramControlArr;
        if (!isValidArray(controls)) return '';

        const mainParamDict = {};

        const controlsLen = controls.length;
        for (let i = 0; i < controlsLen; i++) {
            let control = controls[i];
            const paramName = control.ReportParamName;
            let paramType: ParamType = control.paramType;

            if (paramType === ParamType.GroupPanel) {
                const groupControls = control.Controls;
                for (const p in groupControls) {
                    if (groupControls[p].paramType != null) {
                        control = groupControls[p];
                        continue;
                    }
                }
                paramType = !isNullOrUndefined(control) ? control.paramType : null;
            }

            if (isNullOrUndefined(paramType)) continue;

            let value = '';

            switch (paramType) {
            case ParamType.TextBox:
                value = control.get('text');
                break;
            case ParamType.DateBox:
                value = control.get('dateTime').getTime().toString();
                break;
            case ParamType.InstrumentsLookup:
                value = control.get('selectedItem').FullName;
                if (value === 'All') value = '';
                break;
            case ParamType.AccountLookup:
                value = control.get('selectedItem').FullAccString ?? control.get('selectedItem').AcctNumber;
                if (value === 'All') value = '';
                break;
            case ParamType.AssetComboBox:
                value = control.get('selectedItem').value.toString();
                if (value === 'All') value = '';
                break;
            case ParamType.CheckBox:
                value = control.get('checked').toString();
                break;
            case ParamType.CheckBoxGroup:{
                const dataArray: any[] = control.get('dataArray');
                if (dataArray?.length > 0) {
                    const len = dataArray.length;
                    for (let i = 0; i < len; i++) {
                        const item = dataArray[i];
                        const checked: boolean = item.checked;
                        const val = item.value;
                        if (checked && val !== undefined) {
                            value += val + ',';
                        }
                    }
                    if (isValidString(value)) {
                        value = value.slice(0, -1);
                    }
                }
                break;
            }
            }

            mainParamDict[paramName] = value;
        }

        // TODO.
        return {
            locale: CurrentLang,
            reportTypeObj,
            mainParamDict
        };
    }

    public checkTimeParameters (): boolean {
        const controls = this.paramControlArr;
        if (!isValidArray(controls)) {
            return false;
        }

        let to: Date;
        let from: Date;

        const len = controls.length;
        for (let i = 0; i < len; i++) {
            const control = controls[i];
            const paramType = control.paramType;

            if (isNullOrUndefined(paramType) || paramType !== ParamType.DateBox) {
                continue;
            }

            if (control.ReportParamName === 'toDate') {
                to = control.get('dateTime');
            } else {
                from = control.get('dateTime');
            }
        }

        return !isNullOrUndefined(to) && !isNullOrUndefined(from) ? to > from : true;
    }

    public onExportToClick (context, newVal): void {
        void this.set('exportFormat', newVal.tag);

        this.getReport();

    // this.set('exportToTitleItem', {text: this.get('exportToTitle') })
    }

    public parseXMLStringForTables (): string[] {
        const reportResponseMessage = this.get('reportResponseMessage');

        const resultArr: string[] = reportResponseMessage.xmlString.split('</table>');

        resultArr.splice(resultArr.length - 1, 1);

        for (let i = 0; i < resultArr.length; i++) {
            resultArr[i] += '</table>';
        }

        return resultArr;
    }

    public parseReportResponceMessage (): boolean {
        const msg: DirectReportGroupResponse | DirectReportResponseMessage = this.get('reportResponseMessage');

        if (isNullOrUndefined(msg)) return false;

        const isReportGroupResponse = msg instanceof DirectReportGroupResponse;

        const msgTables = isReportGroupResponse ? msg.Tables : this.parseXMLStringForTables();
        const parser = isReportGroupResponse ? null : new DOMParser();
        const resultTables = [];
        let atLeastOneSuccess = false;

        for (let tableI = 0; tableI < msgTables.length; tableI++) {
            const table = msgTables[tableI];
            const tableParseResult = table instanceof ReportTable ? this.parseOneTable(table) : this.parseOneTableFromXML(parser, table);

            if (tableParseResult.success) {
                atLeastOneSuccess = true;
            }

            resultTables.push(tableParseResult);
        }

        if (atLeastOneSuccess) {
            this.createReportFile(resultTables);
        }

        return atLeastOneSuccess;
    }

    public parseReportResponceMessageXML (): boolean // old scheme
    {
        const xmlStringTables = this.parseXMLStringForTables();
        const parser = new DOMParser();
        const resultTables = [];
        let atLeastOneSuccess = false;

        for (let tableI = 0; tableI < xmlStringTables.length; tableI++) {
            const tableParseResult = this.parseOneTableFromXML(parser, xmlStringTables[tableI]);

            if (tableParseResult.success) {
                atLeastOneSuccess = true;
            }

            resultTables.push(tableParseResult);
        }

        if (atLeastOneSuccess) {
            this.createReportFile(resultTables);
        }

        return atLeastOneSuccess;
    }

    public parseOneTable (table: ReportTable): IParsedTable {
        const result: IParsedTable = {
            success: false,
            tableName: table.ReportName,
            cols: [],
            rows: []
        };

        const columns = [];

        for (let i = 0, len = table.ReportColumns.length; i < len; i++) {
            const column = table.ReportColumns[i];

            columns.push({
                Type: column.Type,
                Name: column.Name
            });
        }

        const rows = [];

        for (let i = 0, len = table.ReportRows.length; i < len; i++) {
            const tr = table.ReportRows[i];
            const cells = [];
            rows.push({ Cells: cells });

            for (let j = 0; j < tr.length; j++) {
                const td = tr[j];
                const rawVal = td.trim();
                const val = this.getValueFromRawValue(rawVal, columns[j].Type);

                cells.push({ Label: rawVal.toString(), Value: val });
            }
        }

        result.success = true;
        result.cols = columns;
        result.rows = rows;

        return result;
    }

    public parseOneTableFromXML (parser, xmlStringTable): IParsedTable {
        const xml = parser.parseFromString(xmlStringTable, 'text/xml');

        const table = xml.childNodes[0];

        const th = table.getElementsByTagName('th')[0];
        const trArr = table.getElementsByTagName('tr');

        const result: IParsedTable = {
            success: false,
            tableName: table.getAttribute('name'),
            cols: [],
            rows: []
        };

        const columns = [];

        if (isNullOrUndefined(th)) {
            result.success = false;

            return result;
        }

        const thChildren = th.childNodes;
        for (let i = 0, len = thChildren.length; i < len; i++) {
            const headerItem = thChildren[i];
            let type = null;

            if (isNullOrUndefined(headerItem.getAttribute)) {
                continue;
            }

            switch (headerItem.getAttribute('type')) {
            case 'FLOAT':
            case 'NUMBER':
                type = QuickTableComparingType.Double;
                break;
            case 'INTEGER':
                type = QuickTableComparingType.Int;
                break;
            case 'DATE':
                type = QuickTableComparingType.DateTime;
                break;
            default:
                type = QuickTableComparingType.String;
            }
            columns.push({
                Type: type,
                Name: headerItem.textContent
            });
        }

        const rows = [];

        for (let i = 0, len = trArr.length; i < len; i++) {
            const tr = trArr[i];
            const cells = [];
            rows.push({ Cells: cells });

            const tdArr = tr.childNodes;
            for (let j = 0, jLen = tdArr.length; j < jLen; j++) {
                const td = tdArr[j];
                const rawVal = td.textContent.trim();
                const val = this.getValueFromRawValue(rawVal, columns[j].Type);

                cells.push({ Label: rawVal.toString(), Value: val });
            }
        }

        result.success = true;
        result.cols = columns;
        result.rows = rows;

        return result;
    }

    public getValueFromRawValue (rawVal: string, type: QuickTableComparingType): string | null {
        let val: string | null = null;

        switch (type) {
        case QuickTableComparingType.Double:
            val = rawVal === '' ? '' : parseFloat(rawVal).toString();
            break;

        case QuickTableComparingType.Int:
            val = rawVal === '' ? '' : parseInt(rawVal).toString();
            break;

        // case QuickTableComparingType.Long:
        case QuickTableComparingType.DateTime:{
            const parsedVal = parseInt(rawVal);
            val = isValidNumber(parsedVal) && parsedVal !== 0 ? DateTimeUtils.formatDate(new Date(parseInt(rawVal)), 'DD.MM.YYYY HH:mm:ss') : '';
            break;
        }
        default:
            val = rawVal;
        }

        if (rawVal === 'N/A') {
            val = rawVal;
        }

        if (isNaN(Number(val)) && rawVal === 'Total') // 93089 какого хера Total приходит с типом 1 = INT?????
        {
            val = rawVal;
        }

        return val;
    }

    public createReportFile (tables: IParsedTable[]): void {
        let content = '';
        let filename = '';
        const selectedReportComboItem = this.get('selectedReportComboItem');

        if (isValidString(selectedReportComboItem?.text)) {
            filename = selectedReportComboItem.text + ' ';
        }

        filename += DateTimeUtils.formatDate(new Date(), 'DD.MM.YYYY HH-mm-ss');

        const formatType: string = this.get('exportFormat');

        if (formatType === ReportsPanel.EXCEL) // thanks to 93681
        {
            this.createXLSFile(filename, tables);
            return;
        }

        for (let tableI = 0; tableI < tables.length; tableI++) {
            const tableData = tables[tableI];
            const reportName = tableData.tableName;
            const rows = tableData.rows;
            const columns = tableData.cols;

            content += formatType === ReportsPanel.HTML
                ? this.createHTMLTable(reportName, columns, rows)
                : '\uFEFF' + this.createCSVTable(reportName, columns, rows); // #103325 specify as UTF8 with BOM for correct polish characters
        }

        switch (formatType) {
        case ReportsPanel.HTML:
            filename += '.html';
            break;
        case ReportsPanel.CSV:
            filename += '.csv';
            break;
        }

        FilesHelper.saveFile(filename, content);
    }

    public createHTMLTable (tableTitle: string, cols: any[], rows: any[]): string {
        let tableHTML = '';

        tableHTML += '<table border="1" width="100%" cellpadding="5"> <caption><h2>' + tableTitle + '</h2></caption><tr>';
        for (let i = 0; i < cols.length; i++) {
            const curColumn = cols[i];
            tableHTML += '<th>' + curColumn.Name + '</th>';
        }

        if (cols.length === 0) {
            tableHTML += '<th>' + Resources.getResource('screen.reports.reports.noItemAvailable') + '</th>';
        }

        tableHTML += '</tr>';

        for (let i = 0; i < rows.length; i++) {
            const row = rows[i]; const cells = row.Cells;

            tableHTML += '<tr>';
            for (let j = 0; j < cells.length; j++) {
                tableHTML += '<td>' + cells[j].Value.toString().trim() + '</td>';
            }
            tableHTML += '</tr>';
        }
        tableHTML += '</table>';

        return tableHTML;
    }

    public createCSVTable (tableTitle: string, columns: any[], rows: any[]): string {
        const formatType: string = this.get('exportFormat');

        let content = tableTitle + '\r\n';

        if (columns.length === 0) {
            content += Resources.getResource('screen.reports.reports.noItemAvailable');
        }

        const separator = formatType === ReportsPanel.CSV ? ';' : '\t';

        for (let j = 0; j < columns.length; j++) {
            content += columns[j].Name + separator;
        }
        content += '\r\n';

        for (let i = 0; i < rows.length; i++) {
            const row = rows[i]; const cells = row.Cells;
            for (let j = 0; j < cells.length; j++) {
                content += cells[j].Value.toString().trim() + separator;
            }
            content += '\r\n';
        }

        return content + '\r\n';
    }

    public createXLSFile (filename: string, tables: IParsedTable[]): void {
        const preparedTables = [];

        for (let tableI = 0; tableI < tables.length; tableI++) {
            const table = tables[tableI];

            preparedTables.push({
                Data: this.createXLSTable(table.cols, table.rows),
                TableName: table.tableName
            });
        }

        FilesHelper.saveExcelFile(filename, preparedTables);
    }

    public createXLSTable (columns: any[], rows: any[]): any[] {
        const sheetData = [];
        const rowToAdd = [];

        if (columns.length === 0) {
            sheetData.push([Resources.getResource('screen.reports.reports.noItemAvailable')]);
        }

        for (let j = 0; j < columns.length; j++) {
            rowToAdd.push(columns[j].Name);
        }

        sheetData.push(rowToAdd);

        for (let i = 0; i < rows.length; i++) {
            const row = rows[i]; const cells = row.Cells; const rowToAdd = [];
            for (let j = 0; j < cells.length; j++) {
                rowToAdd.push(cells[j].Value.toString().trim());
            }

            sheetData.push(rowToAdd);
        }

        return sheetData;
    }

    public cancelReportPromise (): void {
        const promise = this.generateReportPromise;

        if (isNullOrUndefined(promise)) {
            return;
        }

        promise.cancel();
        this.restoreAfterLoading();

        console.log('promise canceled');
    }

    public restoreAfterLoading (): void {
        void this.set('loading', false);
        void this.set('exportToTitleItem', { text: this.get('exportToTitle') });
    }

    public override accountLink_In (accountId, fromLinkedSystem: boolean): boolean {
        if (fromLinkedSystem && !this.OnAccountLinking()) {
            return false;
        }
        if (DataCache.EnableForceLinkingByAccount()) {
            return false;
        }
        if (!accountId) {
            return false;
        }

        const acc = DataCache.Accounts[accountId];

        if (acc) {
            void this.set('account', acc);
            if (!isNullOrUndefined(this.accountControl)) {
                void this.accountControl.set('selectedItem', acc);
            }
        }

        this.localizeAccountLinkTooltip();

        return true;
    }

    public override accountLink_Out (newSubscriber, account: _AccountItemAll | _AccountItemSelected | Account): void {
        if (DataCache.EnableForceLinkingByAccount()) {
            return;
        }

        if (isNullOrUndefined(account) || (account.AcctNumber === 'All')) {
            const acc = this.get('account');
            if (isNullOrUndefined(acc)) return;
            account = acc;
        }

        if (account instanceof _AccountItemSelected) {
            account = account.arraySelected[0];
        }

        super.accountLink_Out(newSubscriber, account as Account);
    }

    public onAccountChanged (newAcc: Account, oldAcc: Account): void {
        if (isNullOrUndefined(newAcc) || newAcc === oldAcc) {
            return;
        }

        this.accountLink_Out(false, newAcc);
    }

    public override getXmlSettingsTemplate (): string { return ''; }

    public override setXmlSettingsTemplate (value): void { }

    public static compareReportParams (rp0, rp1): number {
        return ReportsPanel.getReportParamOrderNumber(rp0) -
        ReportsPanel.getReportParamOrderNumber(rp1);
    }

    public static getReportParamOrderNumber (rp): number {
        if (rp.type === ParamType.InstrumentsLookup ||
        rp.type === ParamType.UsersLookup ||
        rp.type === ParamType.AccountLookup) {
            return 1;
        }

        if (rp.type === ParamType.AssetComboBox) {
            return 2;
        }

        if (rp.type === ParamType.DateBox) {
            if (rp.name.indexOf('from') !== -1) {
                return 5;
            } else {
                return 6;
            }
        }

        if (rp.type === ParamType.CheckBox) {
            return 10;
        }

        return 100;
    }

    public static getParamText (key: string): string // #88497 возвращает значения ключа, в случае отсутствия ключа в файлах локализации возвращает суффикс после точки (reports.TaskType->TaskType)
    {
        let cbText = Resources.getResource(key);
        if (!Resources.IsResourcePresent(key)) {
            const splitArr = key.split('.');
            if (splitArr.length > 1) {
                cbText = splitArr[1];
            }
        }

        return cbText;
    }
}

ApplicationPanel.extendWith(ReportsPanel, {
    Name: 'ReportsPanel',
    partials:
    {
        bodyPartial: TerceraReportsPanelTemplate
    },
    data: function () {
        return {
            resizable: false,
            reportsComboBoxList: [],
            selectedReportComboItem: undefined,
            dockablePanel: false,
            showHeader: true,
            showFooter: false,
            canLinkByAccount: true,
            reportTypeString: '',
            account: null,

            loading: false,
            reportResponseMessage: null,

            exportFormat: null,
            exportToTitle: '',
            exportToTitleItem: null,
            exportToCBItems: [],

            noDataErrorText: '',
            noDataErrorVisibility: false,
            cancelBtnText: '',
            style_addition_header: 'js-ExportScreen-AdditionalHeader'
        };
    }
});
