// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DataCache } from '../../Commons/DataCache';
import { TradingNumericErrorChecker } from '../../Commons/Trading/TradingNumericErrorChecker';
import { MainWindowManager } from '../../Controls/UtilsClasses/MainWindowManager';
import { ContainerControl } from '../../Controls/elements/ContainerControl';
import { EditPositionScreen } from '../../Controls/screen/EditPositionScreen';
import { ModifyOrderScreen } from '../../Controls/screen/ModifyOrderScreen';
import { TerceraMessageBox } from '../../Controls/screen/TerceraMessageBox';
import { TradingViewToolsContainerTemplate } from '../../templates.js';
import { contextMenuHandler } from '../AppHandlers';
import { ChartTradingCore } from '../Trading/ChartTradingCore';
import { TradingViewAlertTool } from './TradingViewAlertTool';
import { TradingViewOrderTool } from './TradingViewOrderTool';
import { TradingViewPositionTool } from './TradingViewPositionTool';

export class TradingViewToolsContainer extends ContainerControl {
    public ChartTradingCore: any = null;
    public currentDraggingToolID: any = null; // id of moving tool (to not conflict with others on intersection)
    public tools: any = {};
    public lastZeroPriceCoord: any;
    public myTradeNumeric: any;
    public myVisualWidget: any;
    public chartOE: any;
    public terceraChartRactive: any;

    public override oninit (): void {
        this.initChartTradingCore();
        this.on('containerMouseUp', this.onMouseUp);
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.observe('instrument', this.onInstrumentChanged);
        this.observe('account', this.onAccountChanged);

        this.observe('panelWidth', (panelWidth) => {
            for (const tID in this.tools) {
                this.tools[tID].set('containerWidth', panelWidth);
            }
        });

        this.toolsUpdateEventsSubscribe();
    }

    public TickAsync (): void {
        const zeroPriceCoord = this.priceToCoordinate(0);
        if (this.lastZeroPriceCoord === null || this.lastZeroPriceCoord != zeroPriceCoord) // need update tools in this case
        {
            this.lastZeroPriceCoord = zeroPriceCoord;

            const tools = this.getTools();
            for (const tID in tools) {
                const tool = tools[tID];
                tool.set('top', this.getToolsTop(tool));
            }
        }
    }

    public onMouseMove (event): void {
        if (this.currentDraggingToolID) {
            const tool = this.tools[this.currentDraggingToolID];
            const offset = 84;

            const y = event?.original?.clientY - offset;
            const newPrice = this.coordinateToPrice(y - 0.4 * offset);

            tool.set({ top: y, priceValue: newPrice, price: tool.getInstrument().formatPrice(newPrice) });
        }
    }

    public onMouseUp (event): void {
        if (this.currentDraggingToolID) {
            const tool = this.tools[this.currentDraggingToolID];
            tool.fire('dragend');
        }
    }

    public initChartTradingCore (): void {
        const ctc = new ChartTradingCore();
        this.ChartTradingCore = ctc;

        ctc.myTradeNumeric = this.myTradeNumeric;
        ctc.myVisualWidget = this.myVisualWidget;
        ctc.chartOE = this.chartOE;
        ctc.terceraChartRactive = this.terceraChartRactive;
        ctc.TradingNumericErrorChecker = TradingNumericErrorChecker;
        ctc.EditPositionScreen = EditPositionScreen;
        ctc.ModifyOrderScreen = ModifyOrderScreen;
        ctc.TerceraMessageBox = TerceraMessageBox;
        ctc.contextMenuHandler = contextMenuHandler;
        ctc.MainWindowManager = MainWindowManager;
    }

    public toolsUpdateEventsSubscribe (): void { // alerts
        DataCache.AlertManager.OnAlertAdded.Subscribe(this.addTool, this);
        DataCache.AlertManager.OnAlertUpdated.Subscribe(this.resetTools, this);
        DataCache.AlertManager.OnAlertRemoved.Subscribe(this.resetTools, this);
        // orders
        DataCache.OnAddOrder.Subscribe(this.addTool, this);
        DataCache.OnRemoveOrder.Subscribe(this.resetTools, this);
        // DataCache.OnAddSLOrderToPosition.Subscribe(this.AddSLOrderToPositionEvent, this);
        // DataCache.OnAddTPOrderToPosition.Subscribe(this.AddTPOrderToPositionEvent, this);
        // DataCache.OnRemoveSLOrderFromPosition.Subscribe(this.RemoveSLOrderFromPositionEvent, this);
        // DataCache.OnRemoveTPOrderFromPosition.Subscribe(this.RemoveTPOrderFromPositionEvent, this);
        // positions
        DataCache.OnAddPosition.Subscribe(this.addTool, this);
        DataCache.OnUpdatePosition.Subscribe(this.resetTools, this);
        DataCache.OnRemovePosition.Subscribe(this.resetTools, this);
        // CorporateAction
        DataCache.OnAddCorporateAction.Subscribe(this.addTool, this);
        DataCache.OnUpdateCorporateAction.Subscribe(this.resetTools, this);
        DataCache.OnRemoveCorporateAction.Subscribe(this.resetTools, this);
    }

    public toolsUpdateEventsUnSubscribe (): void { // alerts
        DataCache.AlertManager.OnAlertAdded.UnSubscribe(this.addTool, this);
        DataCache.AlertManager.OnAlertUpdated.UnSubscribe(this.resetTools, this);
        DataCache.AlertManager.OnAlertRemoved.UnSubscribe(this.resetTools, this);
        // orders
        DataCache.OnAddOrder.UnSubscribe(this.addTool, this);
        DataCache.OnRemoveOrder.UnSubscribe(this.resetTools, this);
        // DataCache.OnAddSLOrderToPosition.UnSubscribe(this.AddSLOrderToPositionEvent, this);
        // DataCache.OnAddTPOrderToPosition.UnSubscribe(this.AddTPOrderToPositionEvent, this);
        // DataCache.OnRemoveSLOrderFromPosition.UnSubscribe(this.RemoveSLOrderFromPositionEvent, this);
        // DataCache.OnRemoveTPOrderFromPosition.UnSubscribe(this.RemoveTPOrderFromPositionEvent, this);
        // positions
        DataCache.OnAddPosition.UnSubscribe(this.addTool, this);
        DataCache.OnUpdatePosition.UnSubscribe(this.resetTools, this);
        DataCache.OnRemovePosition.UnSubscribe(this.resetTools, this);
        // CorporateAction
        DataCache.OnAddCorporateAction.UnSubscribe(this.addTool, this);
        DataCache.OnUpdateCorporateAction.UnSubscribe(this.resetTools, this);
        DataCache.OnRemoveCorporateAction.UnSubscribe(this.resetTools, this);
    }

    public override dispose (): void {
        this.toolsUpdateEventsUnSubscribe();
        super.dispose();
    }

    public getTools (): any { return this.tools; }

    public addTool (dataObject): TradingViewAlertTool | TradingViewPositionTool | TradingViewOrderTool | null {
        const tool = this.createTypedTool(dataObject);
        void tool.set({ dataObject, chartTradingCoreRef: this.ChartTradingCore });

        const toolID = tool.id();

        if (!this.tools[toolID]) {
            const top = this.getToolsTop(tool);
            void tool.set({ left: 70, top, containerWidth: this.get('panelWidth') });

            this.tools[toolID] = tool;
            this.addControl(tool);

            tool.toolsContainerRef = this;

            return tool;
        }

        return null;
    }

    public removeTool (toolToRemove): void {
        const toolID = toolToRemove.id();
        const tool = this.tools[toolID];
        if (tool) {
            this.removeControl(tool);
            this.tools[toolID] = null;
        }
    }

    public clearTools (): void {
        for (const toolID in this.tools) {
            this.removeTool(this.tools[toolID]);
        }
        this.tools = {};
    }

    public resetTools (): void {
        this.clearTools();
        this.addAllTools();
    }

    public createTypedTool (dataObject): TradingViewAlertTool | TradingViewPositionTool | TradingViewOrderTool {
        let tool = null;
        if (dataObject.AlertId) {
            tool = new TradingViewAlertTool();
        } else {
            tool = dataObject.isPosition ? new TradingViewPositionTool() : new TradingViewOrderTool();
        }

        return tool;
    }

    public onInstrumentChanged (): void {
        this.resetTools();
    }

    public onAccountChanged (acc): void {
        if (!acc) {
            return;
        }
        this.resetTools();
    }

    public addAllTools (): void {
        const ins = this.get('instrument');
        const acc = this.get('account');
        if (ins && acc) {
            this.addAlertsTools(ins, acc);
            this.addPositionTools(ins, acc);
            this.addOrderTools(ins, acc);
        }
    }

    public addAlertsTools (instrument, account): void {
        const alerts = DataCache.AlertManager.GetFilteredAlerts(instrument, account);
        for (let i = 0; i < alerts.length; i++) {
            this.addTool(alerts[i]);
        }
    }

    public addPositionTools (instrument, account): void {
        const positions = DataCache.getPositionsByInstrumentAndAccount(instrument, account);
        for (const posID in positions) {
            this.addTool(positions[posID]);
        }
    }

    public addOrderTools (instrument, account): void {
        const orders = DataCache.getOrdersByInstrumentAndAccount(instrument, account);
        for (const ordID in orders) {
            this.addTool(orders[ordID]);
        }
    }

    public getToolsTop (tool): number {
        return this.priceToCoordinate(tool.getPrice()) + tool.getSelfHeightOffset();
    }

    public priceToCoordinate (price: number): any {
        const pane = this.getPane();
        const priceScale = pane ? pane.getMainSourcePriceScale()._priceScale : null;
        return priceScale ? priceScale.priceToCoordinate(price) : null;
    }

    public coordinateToPrice (coordY): any {
        const pane = this.getPane();
        const priceScale = pane ? pane.getRightPriceScales() : null;
        return priceScale?.[0] ? priceScale[0].coordinateToPrice(coordY) : null;
    }

    public getPane (): any {
        const TVwidget = (this.parent as any)?.tvWidget;
        const TVchart = TVwidget?._innerAPI() ? TVwidget.chart() : null;
        if (TVchart) {
            const panes = TVchart.getPanes();
            return panes?.[0] ? panes[0] : null;
        }

        return null;
    }
    // getClientPanel () { return this.el?.children?.toolsContainer; }
}

ContainerControl.extendWith(TradingViewToolsContainer, {
    data: function () {
        return {
            panelWidth: null,
            account: null, // account from accountDetails even if account linking turned off
            instrument: null
        };
    },
    template: TradingViewToolsContainerTemplate
});
