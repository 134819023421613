// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { AlertUtils } from '../../../Utils/Alert/AlertUtils';
import { CreateAlertExecuteSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Commons/properties/Resources';
import { AlertAfterExecute } from '../../../Utils/Alert/AlertConstants';
import { type IAlertAfterExecuteItem } from '../../../Utils/Alert/AlertItems';

export class CreateAlertExecuteSelector extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'CreateAlertExecuteSelector'; }

    public override oninit (): void {
        super.oninit();

        this.repopulate();
        this.observe('selectedItem', this.updateAlertExecute);
        this.observe('value', this.updateAlertExecuteValue);

        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public updateAlertExecute (selectedItem: IAlertAfterExecuteItem): void {
        if (!isNullOrUndefined(selectedItem)) {
            void this.set({ value: selectedItem.value });
        }
    }

    public updateAlertExecuteValue (newV: AlertAfterExecute, oldV): void {
        const items = this.get('items');
        if (!isValidArray(items)) { return; }

        for (let i = 0; i < items.length; i++) {
            if (items[i].value === newV) {
                void this.set({ selectedItem: items[i] });
                break;
            }
        }
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public repopulate (): void {
        const items: IAlertAfterExecuteItem[] = AlertUtils.GetAlertAfterExecuteItems();
        void this.set({ items, selectedItem: items[AlertAfterExecute.REMOVE] });
    }

    public localize (): void {
        void this.set('label', Resources.getResource('screen.Alerts.Label.AfterExecute'));
    }
}

ContainerControl.extendWith(CreateAlertExecuteSelector, {
    template: CreateAlertExecuteSelectorTemplate,
    data: function () {
        return {
            label: '',
            instrument: null,

            items: null,
            selectedItem: null,
            defaultValue: null,

            width: 198
        };
    }
});
