// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CreateAlertExpirationSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Commons/properties/Resources';

export class CreateAlertExpirationSelector extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'CreateAlertExpirationSelector'; }

    public override oninit (): void {
        super.oninit();

        this.repopulate();
        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public repopulate (): void {
        const DataTime = new Date();
        DataTime.setHours(23);
        DataTime.setMinutes(59);
        DataTime.setSeconds(0);
        DataTime.setDate(DataTime.getDate() + 30);

        void this.set('expirationTime', DataTime);
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public localize (): void {
        void this.set({
            label: Resources.getResource('screen.Alerts.Label.Expiration'),
            infoBtnTooltip: Resources.getResource('screen.Alerts.Label.Expiration.InfoTooltip')
        });
    }
}

ContainerControl.extendWith(CreateAlertExpirationSelector, {
    template: CreateAlertExpirationSelectorTemplate,
    data: function () {
        return {
            label: '',
            btnTooltip: '',
            visibleInfoBtn: true,
            expirationTime: null,
            width: 177
        };
    }
});
