// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { HtmlScrollXY } from '../../Commons/HtmlScroll';
import { EntitlementSubscribeDocumentScreenTemplate } from '../../templates.js';
import { TerceraWindowBase } from './TerceraWindowBase';
import { entitlementSubscribeDocumentScreenHandler } from '../../Utils/AppHandlers';
import { EntitlementManager } from '../../Commons/cache/Entitlement/EntitlementManager';

export class EntitlementSubscribeDocumentScreen extends TerceraWindowBase {
    public ProductID: any = null;
    public DocumentArray: any[] = [];
    public doneWasClicked: boolean = false;
    public needSendRequestOnDoneClick: boolean = true;
    public customDisposeHandler: any = null;

    public override getType (): string { return 'EntitlementSubscribeDocumentScreen'; }

    public override oncomplete (): void {
        super.oncomplete();

        this.OnResize.Subscribe(this.resizingProcess, this);

        this.on('nextClick', this.nextClick);
        this.on('backClick', this.backClick);
        this.on('doneClick', this.doneClick);
        this.observe('text_middle_obs', (newValue) => {
            void this.set({ text_middle: newValue });
        });

        this.addScroll();
        this.localize();
        this.updateHeader();
        this.updateProgressBar();
        this.center();
        this.getContentDocument();
        this.getInputDocument();
    }

    public resizingProcess (): void {
        const width: number = this.get('width');
        const height: number = this.get('height');
        const middleHeight: number = height - 25 - 48 - 97; // header - js-products-document-top - js-products-document-bottom

        void this.set({
            widthPanel: width,
            heightPanel: middleHeight
        });

        this.updateProgressBar();
    }

    public setDocumentParam (): void {
        const arr = this.DocumentArray;
        const index: number = this.get('lastindex');

        void this.set({
            lengthindex: arr.length - 1,
            text_top: arr[index].Name,
            text_middle_obs: arr[index].Content
        });
    }

    public nextClick (): void {
        const arr = this.DocumentArray;
        let index = this.get('lastindex');
        ++index;

        this.SaveDocument();
        void this.set({
            lastindex: index,
            text_top: arr[index].Name,
            text_middle_obs: arr[index].Content
        });

        this.getContentDocument();
        this.getInputDocument();
        this.updateHeader();
        this.updateProgressBar();
        this.resetScroll();
    }

    public backClick (): void {
        const arr = this.DocumentArray;
        let index = this.get('lastindex');
        index--;

        this.SaveDocument();
        void this.set({
            lastindex: index,
            text_top: arr[index].Name,
            text_middle_obs: arr[index].Content
        });

        this.getContentDocument();
        this.getInputDocument();
        this.updateHeader();
        this.updateProgressBar();
        this.resetScroll();
    }

    public doneClick (): void {
        this.doneWasClicked = true;
        this.SaveDocument();

        if (this.needSendRequestOnDoneClick) {
            EntitlementManager.ProductSubscribeRequest(this.ProductID, this.DocumentArray);
        }

        this.close();
    }

    public override dispose (): void {
        if (!isNullOrUndefined(this.customDisposeHandler)) {
            this.customDisposeHandler(this.doneWasClicked);
        }

        this.OnResize.UnSubscribe(this.resizingProcess, this);

        super.dispose();
    }

    public SaveDocument (): void {
        const arr = this.DocumentArray;
        const index = this.get('lastindex');

        arr[index].Content = this.Controls.windowPanelBody.el.getElementsByClassName('js-label-document-middle')[0].innerHTML;
    }

    public getContentDocument (): void {
        const arr = this.DocumentArray;
        const index = this.get('lastindex');
        let sumH = 0;

        if (arr[index].Content.indexOf('<style>') != -1) {
            const iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            const doc = iframe.contentDocument;
            doc.write(arr[index].Content);
            doc.close();
            const children = doc.body.children;

            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                sumH += child.clientHeight;
            }

            document.body.removeChild(iframe);
        }

        void this.set({ heightContent: sumH });
    }

    public addScroll (): void {
        HtmlScrollXY.addScroll(this.find('.js-products-document-middle'), 'xy');
    }

    public removeScroll (): void {
        HtmlScrollXY.removeScroll(this.find('.js-products-document-middle'));
    }

    public resetScroll (): void {
        this.removeScroll();
        this.addScroll();
    }

    public checkNextBTNEnability (radio, text, checkbox): void {
        const keys = Object.keys(radio);
        let r = true;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const arr = radio[key];
            let sR = false;
            for (let j = 0; j < arr.length; j++) {
                if (arr[j].checked) {
                    sR = true;
                    break;
                }
            }
            r = r && sR;
        }

        let t = true;
        for (let i = 0; i < text.length; i++) {
            const value = text[i].value;
            t = t && !!value;
        }

        let c = true;
        for (let i = 0; i < checkbox.length; i++) {
            c = c && checkbox[i].checked;
        }

        const enabled = r && t && c;
        const tooltip = this.getBTNTooltip(enabled);

        void this.set({
            canEnabledBTN: enabled,
            btnTooltip: tooltip
        });
    }

    public getBTNTooltip (enabled: boolean): string {
        if (!enabled) {
            return Resources.getResource('screen.documets.Button.ToolTip');
        } else {
            return '';
        }
    }

    public getInputDocument (): void {
        const me = this;
        const rootElement = this.Controls.windowPanelBody.el.getElementsByClassName('js-label-document-middle')[0];
        if (isNullOrUndefined(rootElement)) {
            return;
        }

        const imputDocument = rootElement.getElementsByTagName('input'); // найти инпуты все
        const comboBoxDocument = rootElement.getElementsByTagName('select'); // найти комбо-боксы
        const linkDocument = rootElement.getElementsByTagName('a'); // найти линки
        const radioInput = {};
        const textInput = [];
        const checkInput = [];

        for (let i = 0; i < imputDocument.length; i++) {
            const ipD = imputDocument[i];
            if (ipD.type === 'radio') {
                ipD.oninput = function () {
                    const arr = radioInput[this.name] ?? [];
                    for (i = 0; i < arr.length; i++) {
                        arr[i].removeAttribute('checked');
                    }

                    this.setAttribute('checked', '');
                    me.checkNextBTNEnability(radioInput, textInput, checkInput);
                };

                if (!isValidArray(radioInput[ipD.name])) {
                    radioInput[ipD.name] = [];
                }

                radioInput[ipD.name].push(ipD);
            }

            if (ipD.type === 'text') {
                ipD.oninput = function () {
                    this.setAttribute('value', this.value);
                    me.checkNextBTNEnability(radioInput, textInput, checkInput);
                };

                textInput.push(ipD);
            }

            if (ipD.type === 'checkbox') {
                ipD.oninput = function () {
                    this.setAttribute('checked', this.checked);
                    me.checkNextBTNEnability(radioInput, textInput, checkInput);
                };

                checkInput.push(ipD);
            }
        }

        for (let i = 0; i < comboBoxDocument.length; i++) {
            const comboBoxD = comboBoxDocument[i];
            comboBoxD.onchange = function () {
                const option = this.options;
                for (i = 0; i < option.length; i++) {
                    option[i].removeAttribute('selected');
                }

                this.options[this.selectedIndex].setAttribute('selected', 'selected');
            };
        }

        for (let i = 0; i < linkDocument.length; i++) {
            const linkD = linkDocument[i];
            linkD.setAttribute('target', '_blank');
            linkD.setAttribute('rel', 'noopener noreferrer');
        }

        this.checkNextBTNEnability(radioInput, textInput, checkInput);
    }

    public updateHeader (): void {
        const header = Resources.getResource('screen.documets.Header');
        const currentDoc = this.get('lastindex') + 1;
        const commonDoc = this.DocumentArray.length;
        const createText = header + ' ' + currentDoc + '/' + commonDoc;

        void this.set('header', createText);
    }

    public updateProgressBar (): void {
        const len = this.DocumentArray.length;
        const wBar = this.get('width') - 50;
        const indent = Math.floor(wBar / (len - 1));

        const arr = [];
        for (let i = 0; i < len; i++) {
            const left = indent * i;
            arr.push(left);
        }

        const index = this.get('lastindex');
        const value = arr[index];
        void this.set({
            paramLeft: arr,
            progressValue: value
        });
    }

    public override localize (): void {
        void this.set({
            nextButtonText: Resources.getResource('pageNavigation.nextButton'),
            backButtonText: Resources.getResource('pageNavigation.backButton'),
            doneButtonText: Resources.getResource('pageNavigation.doneButton')
        });
    }

    public static Show (id, data): EntitlementSubscribeDocumentScreen {
        const DocumentScreen = new EntitlementSubscribeDocumentScreen();
        DocumentScreen.DocumentArray = data;
        DocumentScreen.ProductID = id;
        DocumentScreen.setDocumentParam();

        MainWindowManager.MainWindow.addControl(DocumentScreen);
        return DocumentScreen;
    }

    public static entitlementSubscribeDocumentScreenHandlerInitialize (): void {
        entitlementSubscribeDocumentScreenHandler.Show = EntitlementSubscribeDocumentScreen.Show;
    }
}

TerceraWindowBase.extendWith(EntitlementSubscribeDocumentScreen, {
    data: function () {
        return {
            movable: true,
            resizable: true,
            showHeader: true,
            showFooter: false,
            focused: true,

            width: 650,
            height: 517,
            minWidth: 650,
            minHeight: 517,
            zIndex: 290,
            progressValue: 0,
            heightContent: 0,

            header: '',
            text_top: '',
            text_middle: '',
            lastindex: 0,
            lengthindex: null,
            canEnabledBTN: false,
            btnTooltip: ''
        };
    },
    partials: { bodyPartial: EntitlementSubscribeDocumentScreenTemplate }
});
