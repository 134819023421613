// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MODE_UPPER, MODE_LOWER } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iEnvelopes extends iBuildInIndicator {
    public FMaPeriod: any;
    public FMaMethod: any;
    public FDeviation: any;
    public ma: ExpandDoubleVector = new ExpandDoubleVector();
    public MaPeriod: any;
    public MaMethod: any;
    public Deviation: any;

    constructor (maPeriod, maMethod, ma_shift, deviation, priceType) {
        super(3);

        this.FPriceType = priceType;
        this.FMaPeriod = maPeriod;
        this.FMaMethod = maMethod;
        this.FDeviation = deviation;
        this.FData.FArray[0].FShift = ma_shift;
        this.FData.FArray[1].FShift = ma_shift;
        this.FData.FArray[2].FShift = ma_shift;
    }

    override get Name (): string { return 'iEnvelopes'; }

    override get Key (): string { return this.DefaultKey + this.MaPeriod + this.MaMethod + this.Deviation + this.FPriceType; }

    public override NextBar (callBound): void {
        super.NextBar(callBound);
        this.ma.Add(0.0);
    }

    public override Refresh (count, newThread): void {
        this.ma.Dispose();
        this.ma = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }

    public override OnQuote (): void {
        const price = this.GetPrice(this.FPriceType, 0);
        this.ma[this.ma.Length - 1] = price;
        const Parent = this.Parent;
        if (!Parent || this.FCount < this.FMaPeriod || this.FMaPeriod == 0) {
            return;
        }

        const maValue = IndicatorFunctions.CallMovingFunction(this.FMaMethod, this.ma, this.FMaPeriod, 1);

        // Setting value
        this.SetValue(MODE_UPPER, 0, (1.0 + this.FDeviation * 0.01) * maValue);
        this.SetValue(MODE_LOWER, 0, (1.0 - this.FDeviation * 0.01) * maValue);
        this.SetValue(2, 0, maValue);
    }
}
