// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { contextMenuHandler } from '../../Utils/AppHandlers';
import { TerceraTabStripTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';
import $ from 'jquery';

export class TerceraTabStrip extends ContainerControl {
    public onTabChange: CustomEvent | null = null;
    public onTabRemove: CustomEvent | null = null;
    public onTabCreate: CustomEvent | null = null;
    public onTabLock: CustomEvent | null = null;
    public onTabRemovePrepare: CustomEvent | null = null;
    public onContextMenuShow: CustomEvent;
    public onTabStripShowMoreActionMenu: CustomEvent;
    public onTabStripDoubleClick: CustomEvent;
    public timerId: any = null;
    public updateNOW: boolean;
    public hasHidden: boolean;

    constructor () { super(); }

    public override getType (): string { return 'TerceraTabManagerRactive'; }

    public override oninit (): void {
        super.oninit();
        this.on('tabStripItem', this.tabStripItemChange);
        this.on('tabStripNewItem', this.createNewTabStripItem);
        this.on('tabStripShowMore', this.tabStripShowMoreAction);
        this.on('tabStripDeleteItem', this.deletTabStripItemPrepare);
        this.on('tabStripLockItem', this.lockTabStripItem);
        this.on('onContextMenu', this.onContextMenu);
        this.on('tabStripShowMoreActionMenu', this.tabStripShowMoreActionMenu);
        this.on('tabStripDoubleClick', this.tabStripDoubleClick);
        this.onTabChange = new CustomEvent();
        this.onTabRemove = new CustomEvent();
        this.onTabCreate = new CustomEvent();
        this.onTabLock = new CustomEvent();
        this.onTabRemovePrepare = new CustomEvent();
        this.onContextMenuShow = new CustomEvent();
        this.onTabStripShowMoreActionMenu = new CustomEvent();
        this.onTabStripDoubleClick = new CustomEvent();

        this.updateView = this.updateView.bind(this);
        this.delayUpdateView = this.delayUpdateView.bind(this);

        $(window).on('resize', this.delayUpdateView);
        this.observe('items selectedItem', this.delayUpdateView, { init: false, defer: true });
    }

    public deletTabStripItemPrepare (context, index: number): void {
        if (this.onTabRemovePrepare.Subscribers.length < 0) {
            this.deletTabStripItem(event, index, true);
        } else {
            const items: TerceraTabStripItem[] = this.get('items');
            const curItem = items[index];
            this.onTabRemovePrepare.Raise(this.deletTabStripItem.bind(this, context, index, true), curItem.data);
        }
    }

    public deletTabStripItem (context, index: number, skipConfirm): void {
        const items: TerceraTabStripItem[] = this.get('items');
        const activeItem: TerceraTabStripItem = this.get('selectedItem');
        const curItem = items[index];
        items.splice(index, 1);
        if (curItem === activeItem && items.length !== 0) {
            let newIndex = index;
            if (index !== 0 && items.length >= index) {
                newIndex = index - 1;
            }

            const newActiveItem = items[newIndex];
            newActiveItem.active = true;
            void this.set({ items, selectedItem: newActiveItem });
        } else {
            void this.set({ items });
        }

        this.onTabRemove.Raise(curItem.data, skipConfirm);
    }

    public lockTabStripItem (context, index: number): void {
        const activeItem: TerceraTabStripItem = this.get('selectedItem');
        const items: TerceraTabStripItem[] = this.get('items');
        activeItem.locked = !activeItem.locked;
        this.onTabLock.Raise(items[index].data);
    }

    public tabStripItemChange (context, index: number): void {
        const items: TerceraTabStripItem[] = this.get('items');
        const selectedItem: TerceraTabStripItem = this.get('selectedItem');

        const newSelectedItem = items[index];
        if (newSelectedItem === selectedItem || !newSelectedItem.enabled) {
            return;
        }

        if (!isNullOrUndefined(selectedItem)) {
            selectedItem.active = false;
        }

        newSelectedItem.active = true;

        void this.set({
            items,
            selectedItem: newSelectedItem
        });

        this.onTabChange.Raise(newSelectedItem.data);
    }

    public onContextMenu (context, index: number): void {
        this.tabStripItemChange(context, index);
        this.onContextMenuShow.Raise(context);
    }

    public tabStripShowMoreActionMenu (context, index): void {
        this.onTabStripShowMoreActionMenu.Raise(context);
    }

    public tabStripDoubleClick (context, index): void {
        this.onTabStripDoubleClick.Raise(context);
    }

    public createNewTabStripItem (context, index): void {
        const selectedItem = this.get('selectedItem');
        if (!isNullOrUndefined(selectedItem)) {
            selectedItem.active = false;
        }

        const items = this.get('items');
        const maxElementCount = this.get('maxElementCount');
        if (maxElementCount !== -1 && items.length >= maxElementCount) {
            this.fire('maxElementsCountReached');
            return;
        }
        const intitValue = new TerceraTabStripItem();
        intitValue.active = true;
        items.push(intitValue);
        void this.set({ items, selectedItem: items[items.length - 1] });
        this.onTabCreate.Raise();
    };

    public tabStripShowMoreAction (context, index): void {
        const items: TerceraTabStripItem[] = this.get('items');
        const len = items.length;
        const hiddenArray = [];
        for (let i = 0; i < len; i++) {
            if (!items[i].visible) {
                hiddenArray.push({
                    text: items[i].text,
                    tag: i,
                    enabled: true,
                    event: this.showMoreContextMenuItemSelect.bind(this)
                });
            }
        }

        const offset = $(this.find('div')).children('.tabStrip-showMoreItem').offset();
        const posY = offset.top;// - $(window).scrollTop() + 19;
        const posX = offset.left;// - $(window).scrollLeft() - 2;
        contextMenuHandler.Show(hiddenArray, posX, posY);
    }

    public showMoreContextMenuItemSelect (data): void {
        this.tabStripItemChange(null, data.tag);
    }

    public selectItem (data): void {
        const items = this.get('items');
        const len = items.length;
        for (let i = 0; i < len; i++) {
            const curItem = items[i];
            if (curItem.data === data) {
                curItem.active = true;
                void this.set({ items, selectedItem: curItem });
                break;
            }
        }
    }

    public setLockedStateForSelectedItem (locked): void {
        const selectedItem = this.get('selectedItem');
        const items = this.get('items');
        if (isNullOrUndefined(selectedItem) || !isValidArray(items)) {
            return;
        }

        const selectedItemIdx = items.indexOf(selectedItem);
        if (selectedItemIdx === -1) return;

        void this.set('items.' + selectedItemIdx + '.locked', locked);
    }

    public setTabNameByData (searchData, newName: string): void {
        const items: TerceraTabStripItem[] = this.get('items');
        const len = items.length;
        for (let i = 0; i < len; i++) {
            const item = items[i];
            if (item.hasOwnProperty('data') && item.data === searchData) {
                void this.set('items.' + i + '.text', newName);
                return;
            }
        }
    }

    public override oncomplete (): void {
        super.oncomplete();

        const items: TerceraTabStripItem[] = this.get('items');

        if (isValidArray(items)) {
            void this.set({ selectedItem: items[0] });
        }
    }

    public override dispose (): void {
        $(window).off('resize', this.delayUpdateView);
        clearTimeout(this.timerId);
    }

    public delayUpdateView (): void {
        if (this.updateNOW) {
            return;
        }
        // clearTimeout(this.timerId);
        this.updateView();
    // this.timerId = setTimeout(this.updateView, 50);
    }

    public getActiveElementSizeParams (): { offset: number, width: number, height: number } {
        const strip = $(this.find('div'));
        const items = $(strip.children('.tabStrip-item'));
        let offset: number;
        let width: number;
        let height: number;
        for (let i = 0; i < items.length; i++) {
            const currentElement = items[i];
            if (currentElement.getAttribute('active') !== null) {
                offset = $(currentElement).offset();
                width = $(currentElement).width();
                height = $(currentElement).height();
                break;
            }
        }
        return { offset, width, height };
    }

    public updateView (): void {
        this.updateNOW = true;
        if (!isValidArray(this.get('items'))) {
            this.updateNOW = false;
            this.hasHidden = false;
            void this.set({ showMoreItem: false/* todo to false */ });
            return;
        }

        const items: TerceraTabStripItem[] = this.get('items');
        const itemsLength = items.length;
        const selItem: TerceraTabStripItem = this.get('selectedItem');
        const selItemIdx = items.indexOf(selItem);
        const visibleHideMap = [];

        const $root = $(this.find('div'));
        const $tItems = $root.children('.tabStrip-item');

        const tabStripW = $root.width();
        if (tabStripW === 0) {
            setTimeout(this.updateView, 150);
            return;
        }
        // else
        // return;

        let totalTItemsW = 40;// 40pixels for selection
        const margin = 2;
        const narrowBtnW = margin + 20;

        let poweredByLinkMargin = 0; let poweredByLinkMarginPlus = 0;
        if (this.get<boolean>('poweredByVisible')) // # 88323
        {
            poweredByLinkMargin = poweredByLinkMarginPlus = 130;
            poweredByLinkMarginPlus++;
        }

        // "+" button.
        totalTItemsW += narrowBtnW;
        // "Show all items" button.
        totalTItemsW += narrowBtnW;
        let indexOfLastVisible = 0;
        for (let i = 0; i < itemsLength; i++) {
            totalTItemsW += margin + $tItems.eq(i).outerWidth();
            if (totalTItemsW >= tabStripW - poweredByLinkMargin) {
                indexOfLastVisible = i - 1;
                break;
            }
            indexOfLastVisible = itemsLength - 1;
        }

        if (totalTItemsW <= tabStripW - poweredByLinkMarginPlus) {
            for (let i = 0; i < itemsLength; i++) { items[i].visible = true; }
            void this.set({ items, showMoreItem: false/* todo to false */ });
            this.updateNOW = false;
            this.hasHidden = false;
            return;
        }

        const startIndex = (selItemIdx > indexOfLastVisible) ? (selItemIdx - indexOfLastVisible) : 0;
        const endIndex = (selItemIdx > indexOfLastVisible) ? selItemIdx : indexOfLastVisible;

        for (let i = 0; i < itemsLength; i++) {
            if (startIndex <= i && i <= endIndex) {
                visibleHideMap.push(true);
            } else {
                visibleHideMap.push(false);
            }
        }

        for (let i = 0; i < itemsLength; i++) {
            items[i].visible = visibleHideMap[i];
        }

        void this.set({ items, showMoreItem: true });
        this.hasHidden = true;
        this.updateNOW = false;
    }

    public setPoweredByTextAndLink (text: string, url): void {
        void this.set({
            poweredByText: text,
            poweredByVisible: !!text,
            poweredByURL: url
        });
    }
}

export class TerceraTabStripItem {
    public text: string = '';
    public data: string = '';
    public locked: boolean = false;
    public visible: boolean = true;
    public active: boolean = false;
    public enabled: boolean = true;
}

ContainerControl.extendWith(TerceraTabStrip, {

    template: TerceraTabStripTemplate,
    data: function () {
        return {
            items: [],
            selectedItem: null,
            maxElementCount: -1,
            allowUserEdit: true,
            allowMoreActionMenu: false,
            allowCloseTab: true,
            allowAddNewTab: true,
            showMoreItem: false, /* todo to false */

            poweredByText: null, // #88323
            poweredByURL: null,
            poweredByVisible: false
        };
    }
});
