import { Control } from './Control';
import { ComboboxTemplate } from '../../templates';
import { contextMenuHandler } from '../../Utils/AppHandlers';

export class Combobox extends Control {
    private _isMenuShown: boolean = false;
    private _menuItems: ComboboxContextMenuItem[] = [];

    public override getType (): string { return 'Combobox'; }

    constructor () {
        super();
        this.onComboboxClicked = this.onComboboxClicked.bind(this);
        this.onContextMenuItemClicked = this.onContextMenuItemClicked.bind(this);
    }

    public override oninit (): void {
        super.oninit();
        this.on('onComboboxClick', this.onComboboxClicked);
        this.observe('items', this.onItemsChanged);
        this.observe('selectedItem', this.onSelectedItemChanged);
    }

    public override onteardown (): void {
        this.off('onComboboxClick', this.onComboboxClicked);
        super.onteardown();
    }

    public override lostFocus (): void {
        if (this._isMenuShown) {
            this.hideMenu();
        }
        super.lostFocus();
    }

    private async onItemsChanged (): Promise<void> {
        await this.set({ selectedItem: null });
        this._menuItems = this.createMenuItems();
    }

    private onSelectedItemChanged (newValue: ComboboxItem): void {
        if (!isValidArray(this._menuItems)) {
            return;
        }
        for (let i = 0; i < this._menuItems.length; i++) {
            const item = this._menuItems[i];
            item.selected = item.tag === newValue.value;
        }
    }

    private onComboboxClicked (): void {
        const isEnabled: boolean = this.get('enabled');
        if (!isEnabled) {
            return;
        }
        if (!this._isMenuShown) {
            this.showMenu();
        } else {
            this.hideMenu();
        }
    }

    private onContextMenuItemClicked (menuItem: ComboboxContextMenuItem): void {
        const items = this.get('items');
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.value === menuItem.tag) {
                void this.set({ selectedItem: item });
                break;
            }
        }
        this._isMenuShown = false;
    }

    private createMenuItems (): ComboboxContextMenuItem[] {
        const items = this.get('items');
        const selectedItem = this.get('selectedItem');
        const menuItems: ComboboxContextMenuItem[] = [];

        const len = items.length;
        for (let i = 0; i < len; i++) {
            const item = items[i];
            const comboboxContextMenuItem = new ComboboxContextMenuItem();
            comboboxContextMenuItem.text = item.text;
            comboboxContextMenuItem.style = item.style;
            comboboxContextMenuItem.tag = item.value;
            comboboxContextMenuItem.event = this.onContextMenuItemClicked;
            if (!isNullOrUndefined(selectedItem)) {
                comboboxContextMenuItem.selected = item.value === selectedItem.value;
            }
            menuItems.push(comboboxContextMenuItem);
        }

        return menuItems;
    }

    private showMenu (): void {
        const selectedItem = this.get('selectedItem');
        const elementBounds = this.find('*').getBoundingClientRect();
        const additionalParams = {
            width: elementBounds.width,
            isComboboxMenu: true,
            initialSelectedValue: !isNullOrUndefined(selectedItem) ? selectedItem.value : null
        };

        this._isMenuShown = true;
        contextMenuHandler.Show(this._menuItems, elementBounds.left, elementBounds.top + elementBounds.height, additionalParams);
    }

    private hideMenu (): void {
        this._isMenuShown = false;
        contextMenuHandler.Hide();
    }
}

export class ComboboxItem {
    public text: string;
    public value: string;
    public selected: boolean;
}

export class ComboboxContextMenuItem {
    public text: string;
    public style: string;
    public tag: any;
    public event: any;
    public selected: boolean;
}

Control.extendWith(Combobox, {

    template: ComboboxTemplate,
    data: function () {
        return {
            items: [],
            selectedItem: null,
            tooltip: '',
            listWidth: 0
        };
    }
});
