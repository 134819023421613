// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { Point, Rectangle } from '../../Commons/Geometry';
import { TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { Color, Font, Pen, RectangleEdgeFilter, SolidBrush } from '../../Commons/Graphics';
import { ModelDataType } from '../TerceraChartMVC';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { InstrumentSpecificType } from '../../Utils/Instruments/InstrumentSpecificType';
import { DynProperty, DynPropertyRelationType } from '../../Commons/DynProperty';
import { DataCache } from '../../Commons/DataCache';
import { WatermarkRendererViewMode } from '../../Utils/History/ChartEnums';
import { type TerceraChart } from '../TerceraChart';

export class TerceraChartWatermarkRenderer extends TerceraChartBaseRenderer<TerceraChart> {
    public static MAX_FONT_HEIGHT = 141;
    public static MIN_MINOR_FONT_HEIGHT = 8;

    public CurrentViewMode: any = WatermarkRendererViewMode.SYMBOL; // Default
    public ForeBrush: SolidBrush | null = null;
    public watermarkFont: Font = ThemeManager.Fonts.Font_12F_regular;

    // cachedParams:
    public fontSize: number | null = null; // шрифт main (major) watermark-а
    public chartRect: Rectangle | null = null; // кэшируемый Rectangle чарта
    public context: any | null = null; // кэшируемый контекст рисования
    public majorTextWidth: number | null = null;

    public minorFont: Font | null = null; // уменьшенный шрифт доп строки watermark-а
    public firstMinorRow: string | null = null; // текст первой доп строки
    public secondMinorRow: string | null = null; // текст второй доп строки
    public firstMinorTextSize: number | null = null; // ширина
    public secondMinorTextSize: number | null = null;
    public firstMinorTextLocation: Point | null = null; // Point
    public secondMinorTextLocation: Point | null = null;
    WatermarkLogo: any | null;
    LogoLocation: any | null;
    majorTextLocation: any;
    minorFontSize: any;

    constructor (chart: TerceraChart) {
        super(chart);

        if (chart) {
            if (chart.windowsContainer) chart.windowsContainer.AfterResizeWindows.Subscribe(this.UpdateCache, this);

            if (chart.model) chart.model.ModelItemChanged.Subscribe(this.UpdateCache, this);
        }

        this.SetClassName('TerceraChartWatermarkRenderer');
        this.WatermarkLogo = null;
        this.LogoLocation = null;
    }

    public Dispose () {
        const chart = this.chart;
        if (chart) {
            if (chart.windowsContainer) chart.windowsContainer.AfterResizeWindows.UnSubscribe(this.UpdateCache, this);

            if (chart.model) chart.model.ModelItemChanged.UnSubscribe(this.UpdateCache, this);
        }
    }

    public Draw (gr: any, window: any, windowsContainer: any) {
        if (!this.Visible) return;

        if (!this.context) {
            this.context = gr;
            this.UpdateCache();
        }

        if (this.CurrentViewMode === WatermarkRendererViewMode.LOGO) this.Draw_Logo(gr, window, windowsContainer);
        else this.Draw_Text(gr, window, windowsContainer);
    }

    public Draw_Logo (gr: any, window: any, windowsContainer: any) {
        if (!this.WatermarkLogo || !this.LogoLocation) return;

        gr.drawImage(this.WatermarkLogo, this.LogoLocation.X, this.LogoLocation.Y);
    }

    public Draw_Text (gr: any, window: any, windowsContainer: any) {
        const majorWatermarkText = this.GetMajorWatermarkText();
        const minorWatermarkText = this.GetMinorWatermarkText();

        if (majorWatermarkText) {
            const fontSize = this.fontSize;
            const chartRect = this.chartRect;

            if (fontSize === null) { return; }

            const majorY = chartRect?.Height / 2 - fontSize / 2;
            const location = this.majorTextLocation;

            gr.DrawString(majorWatermarkText, this.watermarkFont, this.ForeBrush, location.X, location.Y);

            if (minorWatermarkText && fontSize > 0) this.DrawMinorWatermarkText(gr, chartRect, minorWatermarkText, fontSize, majorY);
        }
    }

    public DrawMinorWatermarkText (gr: any, chartRect: any, minorWatermarkText: string, majorFontSize: number, majorY: number) {
        if (!minorWatermarkText || !this.minorFont) return;

        const minorFont = this.minorFont;
        const fontHeight = this.minorFontSize;

        let textSize = this.firstMinorTextSize;
        let location = this.firstMinorTextLocation;
        let textToDraw = this.firstMinorRow;

        gr.DrawString(textToDraw, minorFont, this.ForeBrush, location?.X, location?.Y);

        if (this.secondMinorRow && this.secondMinorRow.length) {
            textToDraw = this.secondMinorRow;
            textSize = this.secondMinorTextSize;
            location = this.secondMinorTextLocation;
            gr.DrawString(textToDraw, minorFont, this.ForeBrush, location?.X, location?.Y);
        }
    }

    public UpdateCache (type?: any) {
    // for resize
        if (type !== undefined && type.ItemType !== ModelDataType.Instrument) return;

        if (this.CurrentViewMode === WatermarkRendererViewMode.LOGO) {
            this.WatermarkLogo = null;
            this.UpdateLogo();
            return;
        }

        this.UpdateCacheForMajorLabel();
        this.UpdateCacheForMinorLabel();
    }

    public UpdateLogo () {
        const ins = this.chart.Instrument();
        if (!ins) {
            this.WatermarkLogo = null;
            return;
        }

        if (!ins.LogoAddress) {
            this.WatermarkLogo = this.MakeLogoPlug();
            return;
        }

        const res = this.GetLogoSizeAndLocation();
        const size = res.size;
        this.LogoLocation = res.location;

        let alpha = Color.getColorChannels(this.ForeBrush?.Color).a;
        if (alpha === undefined) alpha = 0.25;
        ThemeManager.ImagesManager.GetImg(ins.LogoAddress, size, size, alpha).then((img: any) => {
            if (!img) img = this.MakeLogoPlug();
            this.WatermarkLogo = img;
            this.chart.IsDirty(this.assignLayer);
        });
    }

    public GetLogoSizeAndLocation () {
        const chart = this.chart;
        const gr = this.context;
        const result = { size: 1, location: new Point(1, 1) };
        if (!chart || !gr) return result;

        const height = chart.mainWindow.Rectangle.Height;
        let width = chart.mainWindow.Rectangle.Width;

        const layoutInfo = chart.GetTerceraChartPriceScaleLayoutInfo(gr);
        width -= layoutInfo.PreferredWidthScalesLeft;
        width -= layoutInfo.PreferredWidthScales;

        let size = height;
        if (size > width) size = width;
        size = Math.trunc(size * 0.6); // 0.6 magic number -> so decided

        // size equal to img future size
        const left = width / 2 - size / 2;
        const top = height / 2 - size / 2;

        result.size = Math.abs(size);
        result.location = new Point(left, top);
        return result;
    }

    private MakeLogoPlug () {
        const res = this.GetLogoSizeAndLocation();
        const size = res.size;
        this.LogoLocation = res.location;
        let alpha = Color.getColorChannels(this.ForeBrush?.Color).a;
        if (alpha === undefined) alpha = 0.25;

        const radius = size * 6 / 32;

        const ins = this.chart.Instrument();

        const name = ins.DisplayName();
        const descr = ins.DescriptionValue();

        // фон #77C1E5
        // let color = `rgba(119, 193, 229, ${alpha})`;
        const color = '#77C1E5';
        const color_text = '#FFF';
        const font_size = size * 0.7;
        const font = new Font(font_size, ThemeManager.customFontFamily);
        let text = '';

        if (descr) text = descr[0];
        else {
            text = name[0];
            if (ins.InstrumentSpecificType === InstrumentSpecificType.ContinuousContract) text = name[1];
        }

        text = text.toLocaleUpperCase();
        const canvas = document.createElement('canvas');

        canvas.width = size;
        canvas.height = size;

        const ctx: any = canvas.getContext('2d');
        if (ctx) {
            ctx.globalAlpha = alpha;
            const selectedRectFilter = RectangleEdgeFilter.All;
            const text_width = ctx.GetTextWidth(text, font);
            const text_left = size / 2 - text_width * 0.7;
            const text_top = size / 2 - size / 2.5;

            ctx.RoundRectangle(new Rectangle(0, 0, size - radius, size - radius), radius, new SolidBrush(color), new Pen(color), false, selectedRectFilter);
            ctx.DrawString(text, font, new SolidBrush(color_text), text_left, text_top);
        }

        const img = new Image();
        img.src = canvas.toDataURL();
        img.onload = () => {
            this.chart.IsDirty(this.assignLayer);
        };
        return img;
    }

    private UpdateCacheForMajorLabel () {
        const chart = this.chart;
        if (!chart) return;

        const gr = this.context;
        if (!gr) return;

        const layoutInfo = chart.GetTerceraChartPriceScaleLayoutInfo(gr);
        const chartRect = chart.mainWindow.Rectangle.copy();

        this.chartRect = chartRect;
        chartRect.X += layoutInfo.PreferredWidthScalesLeft;
        chartRect.Width -= layoutInfo.PreferredWidthScalesLeft;
        chartRect.Width -= layoutInfo.PreferredWidthScales;

        const fontSizeByHeight = Math.min(TerceraChartWatermarkRenderer.MAX_FONT_HEIGHT, chartRect.Height / 4);
        const majorWatermarkText = this.GetMajorWatermarkText();
        const fontSizeByWidth = chartRect.Width / majorWatermarkText.length;

        const fontSize = fontSizeByHeight < fontSizeByWidth ? fontSizeByHeight : fontSizeByWidth;
        if (fontSize !== this.fontSize) {
            this.fontSize = fontSize;
            this.watermarkFont = new Font(fontSize, this.watermarkFont.Family);
        }

        this.majorTextWidth = gr.GetTextWidth(majorWatermarkText, this.watermarkFont);

        const majorY = chartRect.Height / 2 - fontSize / 2;

        if (this.majorTextWidth) { this.majorTextLocation = new Point(chartRect.Width / 2 - this.majorTextWidth / 2 + chartRect.X, majorY); }
    }

    private UpdateCacheForMinorLabel () {
        const majorFontSize = this.fontSize;
        const context = this.context;

        if (!majorFontSize || !context) return;

        let minorFontSize = Math.max(TerceraChartWatermarkRenderer.MIN_MINOR_FONT_HEIGHT, Math.floor(majorFontSize / 2.5));

        if (this.minorFontSize != minorFontSize) {
            this.minorFontSize = minorFontSize;
            this.minorFont = new Font(minorFontSize, this.watermarkFont.Family);
        }

        const minorText = this.GetMinorWatermarkText();

        if (!minorText) return;

        const symbol = minorText?.length ? minorText[0] : '';
        const symbolWidth = context.GetTextWidth(symbol, this.minorFont);
        const textWidth = context.GetTextWidth(minorText, this.minorFont);

        let firstMinorRow = '';
        let secondMinorRow = '';

        const chartRect = this.chartRect;

        if (chartRect?.Width - 2 * symbolWidth < textWidth) {
            if (!minorText.includes(' ')) {
                let newTextWidth;
                let newSymbolWidth;
                do {
                    minorFontSize -= 0.5;
                    const font = new Font(minorFontSize, this.watermarkFont.Family);
                    newSymbolWidth = context.GetTextWidth(symbol, font);
                    newTextWidth = context.GetTextWidth(minorText, font);
                } while (chartRect?.Width - 2 * newSymbolWidth < newTextWidth && minorFontSize > TerceraChartWatermarkRenderer.MIN_MINOR_FONT_HEIGHT);

                if (chartRect?.Width - 2 * newSymbolWidth < newTextWidth) {
                    this.minorFontSize = TerceraChartWatermarkRenderer.MIN_MINOR_FONT_HEIGHT;
                    this.minorFont = new Font(this.minorFontSize, this.watermarkFont.Family);
                    firstMinorRow = minorText.substring(0, minorText.length / 2);
                    this.firstMinorTextSize = context.GetTextWidth(firstMinorRow, this.minorFont);
                    secondMinorRow = minorText.substring(minorText.length / 2, minorText.length);
                    this.secondMinorTextSize = context.GetTextWidth(secondMinorRow, this.minorFont);
                } else {
                    this.minorFontSize = minorFontSize;
                    this.minorFont = new Font(minorFontSize, this.watermarkFont.Family);
                    this.firstMinorTextSize = newTextWidth;
                    firstMinorRow = minorText;
                }
            } else {
                const s1 = minorText.substring(0, minorText.length / 2);
                const s2 = minorText.substring(minorText.length / 2, minorText.length);
                const s1LastSpace = s1.lastIndexOf(' ');
                const i1 = s1.length - s1LastSpace - 1;
                const i2 = s2.indexOf(' ');

                if (i1 < i2 || i2 == -1) {
                    firstMinorRow = minorText.substring(0, s1LastSpace);
                    secondMinorRow = minorText.substring(s1LastSpace + 1, minorText.length);
                } else {
                    const index = i2 + Math.floor(minorText.length / 2);
                    firstMinorRow = minorText.substring(0, index);
                    secondMinorRow = minorText.substring(index + 1, minorText.length);
                }

                this.firstMinorTextSize = context.GetTextWidth(firstMinorRow, this.minorFont);
                this.secondMinorTextSize = context.GetTextWidth(secondMinorRow, this.minorFont);
            }
        } else {
            this.firstMinorTextSize = textWidth;
            firstMinorRow = minorText;
        }

        this.firstMinorRow = firstMinorRow;
        this.secondMinorRow = secondMinorRow;

        const majorY = chartRect?.Height / 2 - majorFontSize / 2;
        if (this.firstMinorTextSize) { this.firstMinorTextLocation = new Point(chartRect?.Width / 2 - this.firstMinorTextSize / 2 + chartRect?.X, majorY + majorFontSize); }

        if (this.secondMinorTextSize && secondMinorRow?.length) { this.secondMinorTextLocation = new Point(chartRect?.Width / 2 - this.secondMinorTextSize / 2 + chartRect?.X, majorY + majorFontSize + this.minorFontSize); }
    }

    private GetMajorWatermarkText (): any | null {
        if (!this.chart) return null;

        const ins = this.chart.Instrument();
        if (!ins) return null;

        const viewModes = WatermarkRendererViewMode;
        let majorWatermarkText: any = null;

        switch (this.CurrentViewMode) {
        case viewModes.NONE:
            majorWatermarkText = '';
            break;
        case viewModes.SYMBOL:
            majorWatermarkText = ins.DisplayName();
            break;
        case viewModes.DESCRIPTION:
            majorWatermarkText = ins.DescriptionValue();
            break;
        case viewModes.SYMBOLANDDESCRIPTION:
            majorWatermarkText = ins.DisplayName();
            break;
        }

        return majorWatermarkText;
    }

    GetMinorWatermarkText () {
        if (!this.chart) return null;

        const ins = this.chart.Instrument();
        if (!ins) return null;

        if (this.CurrentViewMode == WatermarkRendererViewMode.SYMBOLANDDESCRIPTION) { return ins.DescriptionValue(); }

        return null;
    }

    public Properties () {
        const properties = TerceraChartBaseRenderer.prototype.Properties.call(this);
        const SeparatorGroup = '#-2#' + Resources.getResource('property.SeparatorGroup.Watermark');

        let prop = new DynProperty('WatermarkMode', this.CurrentViewMode, DynProperty.COMBOBOX, DynProperty.VISUAL_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 1;
        prop.objectVariants = this.GetAllowedWatermarkModes();
        prop.assignedProperty = ['WatermarkColor'];
        prop.DynPropertyRelationType = DynPropertyRelationType.Enability;
        prop.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop);

        prop = new DynProperty('WatermarkColor', this.ForeBrush?.Color, DynProperty.COLOR, DynProperty.VISUAL_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 2;
        prop.enabilityValue = [WatermarkRendererViewMode.SYMBOL, WatermarkRendererViewMode.DESCRIPTION, WatermarkRendererViewMode.SYMBOLANDDESCRIPTION, WatermarkRendererViewMode.LOGO];
        prop.enabled = this.CurrentViewMode != WatermarkRendererViewMode.NONE;
        properties.push(prop);

        return properties;
    }

    public callBack (properties: any[]) {
        TerceraChartBaseRenderer.prototype.callBack.call(this, properties);

        let dp = DynProperty.getPropertyByName(properties, 'WatermarkMode');
        if (dp) {
            if (!DataCache.BrandingVisibleInstrumentLogo && dp.value === WatermarkRendererViewMode.LOGO) { dp.value = WatermarkRendererViewMode.SYMBOL; }
            this.CurrentViewMode = dp.value;
            if (this.CurrentViewMode === WatermarkRendererViewMode.LOGO) { this.UpdateLogo(); }
        }

        dp = DynProperty.getPropertyByName(properties, 'WatermarkColor');
        if (dp) {
            this.ForeBrush = new SolidBrush(dp.value);
        }
    }

    public ThemeChanged () {
        super.ThemeChanged();
        this.ForeBrush = new SolidBrush(ThemeManager.CurrentTheme.ChartWatermarkColor);
    }

    public GetAllowedWatermarkModes () {
        const comboBoxItems = [
            {
                text: Resources.getResource('watermarkMode.None'),
                value: WatermarkRendererViewMode.NONE
            },
            {
                text: Resources.getResource('watermarkMode.Symbol'),
                value: WatermarkRendererViewMode.SYMBOL
            },
            {
                text: Resources.getResource('watermarkMode.Description'),
                value: WatermarkRendererViewMode.DESCRIPTION
            },
            {
                text: Resources.getResource('watermarkMode.SymbolAndDescription'),
                value: WatermarkRendererViewMode.SYMBOLANDDESCRIPTION
            }
        ];

        if (DataCache.BrandingVisibleInstrumentLogo) {
            comboBoxItems.push({
                text: Resources.getResource('watermarkMode.Logo'),
                value: WatermarkRendererViewMode.LOGO
            });
        }

        return comboBoxItems;
    }
}
