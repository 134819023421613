// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { OperationType } from '../../Utils/Trading/OperationType';
import { ThemeManager } from '../misc/ThemeManager';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { RiskPlan } from '../../Commons/cache/RiskPlan';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { type NewTrade } from '../../Commons/cache/NewTrade';
import { type Account } from '../../Commons/cache/Account';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type DataCache } from '../../Commons/DataCache';
import { FilledOrderFormatter } from '../../Commons/cache/Formatters/FilledOrderFormatter';

export class FilledOrderItem extends BaseItem {
    public trade: NewTrade;

    constructor (trade: NewTrade) {
        super();

        this.trade = trade;

        if (trade?.InstrumentStr != null) {
            const [instrumentStr] = trade.InstrumentStr.split(':');
            trade.InstrumentStr = instrumentStr;
        }

        const tm = ThemeManager.CurrentTheme;
        this.CellForeColorMap[FilledOrderItem.INSTRUMENT_COL_INDEX] = tm.TableGoldColor;
        this.CellForeColorMap[FilledOrderItem.AMOUNT_COL_INDEX] = tm.TableGoldColor;
        if (trade != null) {
            this.CellForeColorMap[FilledOrderItem.OPERATION_COL_INDEX] = FilledOrderFormatter.IsLong(trade) ? tm.TableLongBuyColor : tm.TableShortSellColor;
        }
    }

    public override ColumnCount (): number {
        return FilledOrderItem.columnsParams.length;
    }

    public override getColumnData (column: number): ColumnData {
        const trade = this.trade;
        const instrument = this.GetCurrentInstrument();
        const value = this.getColumnValue(column);

        let formattedValue: string = null;

        switch (column) {
        case FilledOrderItem.TRADE_ID_COL_INDEX:
            formattedValue = trade.TradeId;
            break;

        case FilledOrderItem.OPERATION_COL_INDEX:
            if (trade.BuySell === OperationType.Buy) {
                formattedValue = Resources.getResource('panel.watchlist.Buy');
            } else if (trade.BuySell === OperationType.Sell) {
                formattedValue = Resources.getResource('panel.watchlist.Sell');
            } else {
            //    formattedValue =  Const.getBuySellStr(trade.BuySell);
            // TODO
                formattedValue = trade.BuySell;
            }
            break;

        case FilledOrderItem.AMOUNT_COL_INDEX:
            formattedValue = value.toString();
            break;

        case FilledOrderItem.PRICE_COL_INDEX:{
            formattedValue = instrument !== null ? instrument.formatPricePrecision(value) : value.toString();
            break;
        }

        case FilledOrderItem.COMMISSION_COL_INDEX:
            formattedValue = FilledOrderFormatter.ExecutionFeeStr(trade);
            break;

        case FilledOrderItem.REALIZED_PL_COL_INDEX:
            formattedValue = FilledOrderFormatter.GrossPnLStr(trade);
            break;

        case FilledOrderItem.TIME_COL_INDEX:
            formattedValue = DateTimeUtils.FormatToTime(value);// .ToLongTimeString();
            break;

        case FilledOrderItem.DATE_COL_INDEX:
            formattedValue = DateTimeUtils.formatDate(value, 'DD.MM.YYYY  HH:mm:ss');// .ToShortDateString();
            break;

        case FilledOrderItem.EXTERNAL_PRICE_COL_INDEX:
            if (isNaN(value)) {
                formattedValue = '';
            } else {
                if (instrument !== null) {
                    formattedValue = instrument.formatPrice(value);
                } else { // TODO
                    formattedValue = value != null ? value.toString() : '';
                }
            }
            break;

        case FilledOrderItem.PURCHASE_PRICE_COL_INDEX:
            formattedValue = FilledOrderFormatter.ExposureStr(trade);
            break;

        case FilledOrderItem.NET_PL_COL_INDEX:
            formattedValue = FilledOrderFormatter.NetPnLStr(trade);
            break;

        case FilledOrderItem.BOUGHT_COL_INDEX:
            formattedValue = FilledOrderFormatter.GetBoughtStr(trade);
            break;

        case FilledOrderItem.SOLD_COL_INDEX:
            formattedValue = FilledOrderFormatter.GetSoldStr(trade);
            break;

        case FilledOrderItem.REBATES_COL_INDEX:
            formattedValue = FilledOrderFormatter.RebatesStr(trade);
            break;

        case FilledOrderItem.STRIKE_PRICE_COL_INDEX:
            if (value > 0) {
                if (instrument != null) {
                    formattedValue = instrument.formatPrice(value);
                } else {
                    formattedValue = value.toString();
                }
            } else {
                formattedValue = '';
            }
            break;

        case FilledOrderItem.EXPIRATION_DATE_COL_INDEX:
            if (value?.getTime() > 0) {
                formattedValue = DateTimeUtils.FormatToDate(value);
            } else {
                formattedValue = '';
            }
            break;

        case FilledOrderItem.TRADE_ID_LABEL_COL_INDEX:
            formattedValue = trade.TradeIdLabel ?? trade.TradeId ?? '';
            break;

        case FilledOrderItem.COMMISSION_IN_QUOTING_CURRENCY_COL_INDEX:
        case FilledOrderItem.REALIZED_PL_IN_QUOTING_CURRENCY_COL_INDEX:
        case FilledOrderItem.NET_PL_IN_QUOTING_CURRENCY_COL_INDEX:
            if (column === FilledOrderItem.COMMISSION_IN_QUOTING_CURRENCY_COL_INDEX && trade?.AdvancedTradeParams?.CommissionAssetID != null) {
                const asset = instrument !== null ? instrument.DataCache.GetAssetById(trade.AdvancedTradeParams.CommissionAssetID.Value) : null;
                formattedValue = asset !== null ? asset.formatPrice(value) : value.toString();
            }

            if (instrument !== null) {
                if (value != null) {
                    const exp2Asset = instrument.DataCache.GetAssetByName(instrument.Exp2);
                    formattedValue = exp2Asset !== null ? exp2Asset.formatPrice(value) : (value.toString() + ' ' + instrument.Exp2);
                } else {
                    formattedValue = instrument.Exp2;
                }
            } else {
                // TODO
                if (value != null) {
                    formattedValue = value.toString();
                } else { // TODO// System.String.Format("{0:N2}", value);
                    formattedValue = '';
                }
            }
            break;

        case FilledOrderItem.EXECUTION_VENUE_COL_INDEX:
            if (trade.ExecutionVenue != null) {
                formattedValue = trade.ExecutionVenue;
            } else {
                if (instrument?.TradingExchange != null) { // #89156
                    formattedValue = instrument.TradingExchange;
                } else {
                    formattedValue = '';
                }
            }
            break;

        case FilledOrderItem.LEVERAGE_COL_INDEX:
            formattedValue = OrderUtils.GetFormattedLeverage(value, true) ?? Resources.getResource('general.N_A');
            break;

        default:
            formattedValue = value != null ? value.toString() : '';
            break;
        }

        return new ColumnData(value, formattedValue);
    }

    public override getColumnValue (column: number, useAccCurrency: boolean = null): any {
        const trade = this.trade;
        if (trade == null) { return null; }

        const ins = this.GetCurrentInstrument();

        switch (column) {
        case FilledOrderItem.TRADE_ID_COL_INDEX:
            return FilledOrderFormatter.TradeId(trade);

        case FilledOrderItem.ORDER_ID_COL_INDEX:
            return FilledOrderFormatter.IdStr(trade);

        case FilledOrderItem.INSTRUMENT_COL_INDEX:
            return FilledOrderFormatter.SymbolStr(trade);

        case FilledOrderItem.ACCOUNT_COL_INDEX:
            return FilledOrderFormatter.AccountStr(trade);

        case FilledOrderItem.OPERATION_COL_INDEX:
            if (trade.BuySell === OperationType.Buy) {
                return Resources.getResource('panel.watchlist.Buy');
            } else if (trade.BuySell === OperationType.Sell) {
                return Resources.getResource('panel.watchlist.Sell');
            }
            // else
            // TODO
            //    return Const.getBuySellStr(trade.BuySell);
            return trade.BuySell;

        case FilledOrderItem.AMOUNT_COL_INDEX:
            return FilledOrderFormatter.Quantity(trade);

        case FilledOrderItem.PRICE_COL_INDEX:
            return FilledOrderFormatter.Price(trade);

        case FilledOrderItem.COMMISSION_COL_INDEX:
            return FilledOrderFormatter.ExecutionFee(trade, useAccCurrency);

        case FilledOrderItem.REALIZED_PL_COL_INDEX:
            return FilledOrderFormatter.GrossPnL(trade, useAccCurrency);

        case FilledOrderItem.TIME_COL_INDEX:
            return trade.Time;
            // TODO
            // return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(trade.Time, BaseApplication.App.MultiDataCache !== null ? BaseApplication.App.MultiDataCache.DCache : trade.DataCache);

        case FilledOrderItem.DATE_COL_INDEX:
            return trade.Time;
            // TODO
            // return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(trade.Time, BaseApplication.App.MultiDataCache !== null ? BaseApplication.App.MultiDataCache.DCache : trade.DataCache);

        case FilledOrderItem.EXTERNAL_TRADE_ID_COL_INDEX:
            return trade.ExternalTradeId;

        case FilledOrderItem.LOGIN_COL_INDEX:
            return trade.Login;

        case FilledOrderItem.ROUTE_COL_INDEX:
            return trade.RouteID;

        case FilledOrderItem.EXTERNAL_PRICE_COL_INDEX:
            return trade.ExternalPrice;

        case FilledOrderItem.PURCHASE_PRICE_COL_INDEX:
            return FilledOrderFormatter.Exposure(trade, useAccCurrency);

        case FilledOrderItem.EXCHANGE_TRADING_COL_INDEX:
            return FilledOrderFormatter.TradingExchangeStr(trade) ?? ins?.TradingExchange;

        case FilledOrderItem.ORDER_TYPE_COL_INDEX:
            return FilledOrderFormatter.OrderTypeStr(trade);

        case FilledOrderItem.INSTR_TYPE_COL_INDEX:
            if (ins != null) {
                return InstrumentUtils.getInstrumentTypeStringLocalized(ins.InstrType);
            } else {
                return trade.SymbolType ?? '';
            }

        case FilledOrderItem.NET_PL_COL_INDEX:
            return FilledOrderFormatter.NetPnL(trade, useAccCurrency);

        case FilledOrderItem.BOUGHT_COL_INDEX:
            return ins != null ? FilledOrderFormatter.GetBought(trade) : 0;

        case FilledOrderItem.SOLD_COL_INDEX:
            return ins != null ? FilledOrderFormatter.GetSold(trade) : 0;

        case FilledOrderItem.REBATES_COL_INDEX:
            return FilledOrderFormatter.Rebates(trade, useAccCurrency);

        case FilledOrderItem.STRIKE_PRICE_COL_INDEX:
            return trade?.StrikePrice > 0 ? trade.StrikePrice : 0;

        case FilledOrderItem.EXPIRATION_DATE_COL_INDEX:
            return ins?.ExpDateReal.getFullYear() > 2000 ? DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(ins.ExpDateReal) : DateTimeUtils._ZeroTime;

        case FilledOrderItem.EXTERNAL_ORDER_ID_COL_INDEX:
            return trade.ExternalOrderId ?? '';

        case FilledOrderItem.TRADE_ID_LABEL_COL_INDEX:
            return parseInt(trade.TradeIdLabel?.split('-')[0] ?? trade.TradeId.split('-')[0]);

        case FilledOrderItem.COMMISSION_IN_QUOTING_CURRENCY_COL_INDEX:
            return -trade.ComissionInInstrumentQuotingCurrency;

        case FilledOrderItem.REALIZED_PL_IN_QUOTING_CURRENCY_COL_INDEX:
            return trade.PnLInInstrumentQuotingCurrency;

        case FilledOrderItem.NET_PL_IN_QUOTING_CURRENCY_COL_INDEX:
            if (trade !== null) {
                return ((trade.PnLInInstrumentQuotingCurrency - trade.ComissionInInstrumentQuotingCurrency + trade.Swap));
            } else {
                return 0;
            }

        case FilledOrderItem.PRODUCT_TYPE_COL_INDEX:
            return FilledOrderFormatter.ProductTypeStr(trade);

        case FilledOrderItem.EXECUTION_VENUE_COL_INDEX:
            return trade.ExecutionVenue ?? ins?.TradingExchange ?? '';

        case FilledOrderItem.DESCRIPTION_COL_INDEX:
            return trade.Description ?? ins?.DescriptionValue() ?? null;

        case FilledOrderItem.LEVERAGE_COL_INDEX:
            return trade?.Leverage ?? null;

        default:
            return null;
        }
    }

    public override getFormatTotalValue (column: number, value, precisionMode = false, totalCurrencyAfterFiltration = null): string | number {
        const asset = this.GetDataCache().GetAssetByName(totalCurrencyAfterFiltration);

        let formattedValue = '';

        switch (column) {
        case FilledOrderItem.AMOUNT_COL_INDEX :
            // case FilledOrderItem.BOUGHT_COL_INDEX :
            // case FilledOrderItem.SOLD_COL_INDEX :
            if (precisionMode) {
                return 2;
            }
            formattedValue = value.toFixed(2);
            break;

        case FilledOrderItem.COMMISSION_COL_INDEX:
        case FilledOrderItem.REALIZED_PL_COL_INDEX:
        case FilledOrderItem.NET_PL_COL_INDEX:
        case FilledOrderItem.PURCHASE_PRICE_COL_INDEX:
        case FilledOrderItem.REBATES_COL_INDEX:
            if (precisionMode) {
                return asset?.Point;
            }
            formattedValue = asset?.formatPrice(value);
            break;
        }

        return formattedValue;
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = FilledOrderItem.columnsParams[column];
        const isHidden = Resources.isHidden(result.HeaderKey);
        result.Hidden = isHidden;
        result.MenuIndex = this.GetMenuIndex(result.HeaderKey);

        if (column === FilledOrderItem.TIME_COL_INDEX ||
            column === FilledOrderItem.ROUTE_COL_INDEX ||
            column === FilledOrderItem.EXTERNAL_PRICE_COL_INDEX ||
            column === FilledOrderItem.EXECUTION_VENUE_COL_INDEX) {
            result.Hidden = true;
        }

        if (column === FilledOrderItem.PRODUCT_TYPE_COL_INDEX) {
            result.Hidden = !RiskPlan.showProductType;
        }

        return result;
    }

    public override fireUpdate (): void {}

    public override GetCurrentAccount (): Account {
        return this.trade?.Account ?? null;
    }

    public override GetCurrentInstrument (): Instrument {
        return this.trade?.Instrument ?? null;
    }

    public override InstrumentName (): string {
        return this.trade?.Instrument?.GetInteriorID() ?? null;
    }

    public static readonly TRADE_ID_COL_INDEX = 0;
    public static readonly ORDER_ID_COL_INDEX = 1;
    public static readonly INSTRUMENT_COL_INDEX = 2;
    public static readonly ACCOUNT_COL_INDEX = 3;
    public static readonly OPERATION_COL_INDEX = 4;
    public static readonly AMOUNT_COL_INDEX = 5;
    public static readonly PRICE_COL_INDEX = 6;
    public static readonly COMMISSION_COL_INDEX = 7;
    public static readonly REALIZED_PL_COL_INDEX = 8;
    public static readonly TIME_COL_INDEX = 9;
    public static readonly DATE_COL_INDEX = 10;
    public static readonly EXTERNAL_TRADE_ID_COL_INDEX = 11;
    public static readonly LOGIN_COL_INDEX = 12;
    public static readonly ROUTE_COL_INDEX = 13;
    public static readonly EXTERNAL_PRICE_COL_INDEX = 14;
    public static readonly PURCHASE_PRICE_COL_INDEX = 15;
    public static readonly EXCHANGE_TRADING_COL_INDEX = 16;
    public static readonly ORDER_TYPE_COL_INDEX = 17;
    public static readonly INSTR_TYPE_COL_INDEX = 18;
    public static readonly NET_PL_COL_INDEX = 19;
    public static readonly BOUGHT_COL_INDEX = 20;
    public static readonly SOLD_COL_INDEX = 21;
    public static readonly REBATES_COL_INDEX = 22;
    public static readonly STRIKE_PRICE_COL_INDEX = 23;
    public static readonly EXPIRATION_DATE_COL_INDEX = 24;
    public static readonly EXTERNAL_ORDER_ID_COL_INDEX = 25;
    public static readonly TRADE_ID_LABEL_COL_INDEX = 26;
    public static readonly COMMISSION_IN_QUOTING_CURRENCY_COL_INDEX = 27;
    public static readonly REALIZED_PL_IN_QUOTING_CURRENCY_COL_INDEX = 28;
    public static readonly NET_PL_IN_QUOTING_CURRENCY_COL_INDEX = 29;
    public static readonly PRODUCT_TYPE_COL_INDEX = 30;
    public static readonly EXECUTION_VENUE_COL_INDEX = 31;
    public static readonly DESCRIPTION_COL_INDEX = 32;
    public static readonly LEVERAGE_COL_INDEX = 33;

    public static readonly columnsParams = [
        /* 0 */ new ColumnParams('panel.NewTrades.TradeID', 75, 0, false, false, false),
        new ColumnParams('panel.NewTrades.OrderId', 75, 0, false, false, false),
        new ColumnParams('panel.NewTrades.Instrument', 75, 0, false, true, true),
        new ColumnParams('panel.NewTrades.Account', 75, 0, false, false, true),
        new ColumnParams('panel.NewTrades.Operation', 75, 0, false, true, true),
        new ColumnParams('panel.NewTrades.Amount', 75, 3, true, true, false),

        /* 6 */ new ColumnParams('panel.NewTrades.Price', 75, 3, false, true, false),
        new ColumnParams('panel.NewTrades.Commission', 75, 3, true, true, false),
        new ColumnParams('panel.NewTrades.RealizedProfitLoss', 75, 3, true, false, false),
        new ColumnParams('panel.NewTrades.Time', 75, QuickTableColumnType.COL_DATE_SORT, false, true, false),
        /* 10 */ new ColumnParams('panel.NewTrades.Date', 70, QuickTableColumnType.COL_DATE_SORT, false, true, true),
        new ColumnParams('panel.NewTrades.ExternalTradeId', 75, 0, false, false, false),

        new ColumnParams('panel.NewTrades.Login', 75, 0, false, false, false),
        new ColumnParams('panel.NewTrades.Route', 75, 0, false, false, false),
        new ColumnParams('panel.NewTrades.ExternalPrice', 75, 3, false, false, false),
        /* 15 */ new ColumnParams('panel.NewTrades.PurchasePrice', 75, 3, true, false, false),
        new ColumnParams('panel.NewTrades.ExchangeTrading', 75, 0, false, true),
        new ColumnParams('panel.NewTrades.OrderType', 65, 0, false, false, true),
        new ColumnParams('panel.NewTrades.instrType', 70, 0, false, true, true),
        new ColumnParams('panel.NewTrades.Net_PL', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        /* 20 */ new ColumnParams('panel.NewTrades.Bought', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.NewTrades.Sold', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.NewTrades.Rebates', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.NewTrades.StrikePrice', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.NewTrades.ExpirationDate', 70, QuickTableColumnType.COL_DATE_SORT, false, false, false),
        /* 25 */ new ColumnParams('panel.NewTrades.ExternalOrderId', 75, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.NewTrades.TradeIDLabel', 75, 0, false, true, false),
        new ColumnParams('panel.NewTrades.Commission.InInstrumentQuotingCurrency', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.NewTrades.Net_PL.InInstrumentQuotingCurrency', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        /* 30 */ new ColumnParams('panel.NewTrades.ProductType', 75, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.NewTrades.ExecutionVenue', 75, 0, false, false, false),
        new ColumnParams('panel.NewTrades.Description', 70, 0, false, false, false),
        new ColumnParams('panel.NewTrades.Leverage', 70, 0, false, false, true)
    ];

    public override NeedSortMenu (): boolean {
        return true;
    }

    public GetMenuIndex (headerKey: string): number {
        if (FilledOrderItem.ListMenuIndex.Contains(headerKey)) {
            return FilledOrderItem.ListMenuIndex.indexOf(headerKey);
        } else {
            return 1000;
        }
    }

    public static readonly ListMenuIndex =
        [
            'panel.NewTrades.Account',
            'panel.NewTrades.Login',
            'panel.NewTrades.Route',
            'panel.NewTrades.Instrument',
            'panel.NewTrades.instrType',
            'panel.NewTrades.TradeID',
            'panel.NewTrades.OrderId',
            'panel.NewTrades.ExternalTradeId',
            'panel.NewTrades.Operation',
            'panel.NewTrades.OrderType',
            'panel.NewTrades.Price',
            'panel.NewTrades.Amount',
            'panel.NewTrades.Date',
            'panel.NewTrades.Time',
            'panel.NewTrades.Commission',
            'panel.NewTrades.Rebates',
            'panel.NewTrades.RealizedProfitLoss',
            'panel.NewTrades.Net_PL',
            'panel.NewTrades.PurchasePrice',
            'panel.NewTrades.Bought',
            'panel.NewTrades.Sold',
            'panel.NewTrades.StrikePrice',
            'panel.NewTrades.ExpirationDate',
            'panel.NewTrades.ExternalOrderId'
        ];

    private GetDataCache (): DataCache {
        const ins = this.GetCurrentInstrument();
        return ins != null ? ins.DataCache : this.GetCurrentAccount()?.DataCache;
    }
}
