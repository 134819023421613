// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { Point, Rectangle } from '../../Commons/Geometry';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { Color, Line, LinearGradientBrush, Pen, PolyLine, PolyRect, SolidBrush } from '../../Commons/Graphics';
import { ProfilePOCTypesEnum, ProfileVATypesEnum, type TerceraChartCashItemSeriesSettings } from '../Series/TerceraChartCashItemSeriesSettings';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { Periods } from '../../Utils/History/TFInfo';
import { DynProperty, PairColor } from '../../Commons/DynProperty';
import { TerceraChartDrawingType, TerceraChartRendererDrawingAdvancedParamsEnum, TerceraChartToolRendererDrawingType } from '../Utils/ChartConstants';
import { type TerceraChart } from '../TerceraChart';
import { type TerceraChartCashItemSeries } from '../Series/TerceraChartCashItemSeries';
import { type ISeriesRenderer } from './Utils/ISeriesRenderer';

/// Рендерер для исторических данных из кешитема
export class TerceraChartMainPriceRenderer extends TerceraChartBaseRenderer<TerceraChart> implements ISeriesRenderer<TerceraChartCashItemSeries> {
    public Series: TerceraChartCashItemSeries = null;

    public ChartDrawingType: any;

    public barsLineColorPen: any;

    private readonly currentChartDataType: any;
    public cashSettings: TerceraChartCashItemSeriesSettings;

    private readonly clusterTextFont: any = ThemeManager.Fonts.Font_12F_regular;
    private readonly marketProfileTextFont: any = ThemeManager.Fonts.Font_12F_regular;

    private barsHiLowColorPen: any;
    private wickUpBorderColorPen: any;
    private wickDownBorderColorPen: any;
    private histogramLineColorPen: any;

    /// <summary>
    /// Попросили не рисовать оверлеи, когда уже поменялся основной инструмент, но оверлей еще не обновился (#43259)
    /// </summary>
    // TerceraChartMainPriceRenderer.prototype.InvalidState = false;

    private readonly colorSchemeBrush: any = new SolidBrush('transparent');

    private barsUpColorBrush: any;
    private barsDownColorBrush: any;

    private barsDownBorderColorPen: any;
    private barsUpBorderColorPen: any;

    private readonly DayHigh: any;
    private readonly DayLow: any;

    protected solidPriceColorBrush: any;

    TEST_CHART_TYPES: number[];
    TEST_CURRENT_CHART_TYPE_IDX: number;
    clusterRegularBrush: SolidBrush;
    clusterMaxTextBrush: SolidBrush;
    clusterBorderPen: Pen;
    clusterColorSchemeDeltaSellSourceBrush: SolidBrush;
    clusterColorSchemeDeltaBuySourceBrush: SolidBrush;
    clusterMaxBackBrush: SolidBrush;
    clusterTradesSchemeSourceBrush: SolidBrush;
    clusterVolumeSchemeSourceBrush: SolidBrush;
    marketProfileRegularBrush: SolidBrush;
    marketProfileVAVerticalPen: Pen;
    marketProfileVAHorisontalPen: Pen;
    marketProfilePocPen: Pen;
    marketProfilePocTriangleBrush: SolidBrush;
    marketProfileSinglesPen: Pen;
    marketProfilePositiveBrush: SolidBrush;
    marketProfileNegativeBrush: SolidBrush;
    marketProfileVolumeColor: string;
    marketProfileGradientColor: string;
    InvalidState: boolean;
    clusterDownPen: any;
    clusterUpPen: any;
    clusterHighlightMax: any;

    // this.SpreadAskBidSessionFlag;   // #100139
    // this.SpreadLastSessionFlag;

    constructor (terceraChart: TerceraChart) {
        super(terceraChart);
        this.ChartDrawingType = TerceraChartDrawingType.Candle;
        this.UseInAutoscale = true;
        this.ThemeChanged();

        // TEST.
        this.TEST_CHART_TYPES = [
            TerceraChartDrawingType.Candle,
            TerceraChartDrawingType.Bar,
            TerceraChartDrawingType.LinesBreak,
            TerceraChartDrawingType.Renko,
            TerceraChartDrawingType.Line,
            TerceraChartDrawingType.Dot,
            TerceraChartDrawingType.DotLine,
            TerceraChartDrawingType.Solid,
            TerceraChartDrawingType.Forest,
            TerceraChartDrawingType.Kagi,
            TerceraChartDrawingType.TicTac,
            TerceraChartDrawingType.Profile,
            TerceraChartDrawingType.Cluster
        ];
        this.TEST_CURRENT_CHART_TYPE_IDX = 0;
        this.SetClassName('TerceraChartMainPriceRenderer');
    }

    static MULTIPLIER_SIGN: string = 'x';
    static CLUSTER_TEXT_MARGIN_PX: number = 2;

    get SolidPriceBrushColor (): string {
        return this.solidPriceColorBrush.ColorStart;
    }

    set SolidPriceBrushColor (value: string) {
        this.solidPriceColorBrush = new LinearGradientBrush(
            0, 0, 1, 1,
            Color.FromArgb(127, value),
            Color.FromArgb(0, value)
        );
    }

    ThemeChanged (): void {
    // if (Utils.ResetLayouts)
    // {
        this.wickUpBorderColorPen = new Pen(ThemeManager.CurrentTheme.Chart_WickUpBorderColor);
        this.wickDownBorderColorPen = new Pen(ThemeManager.CurrentTheme.Chart_WickDownBorderColor);
        this.barsHiLowColorPen = new Pen(ThemeManager.CurrentTheme.Chart_BarsHiLowColor);
        this.histogramLineColorPen = new Pen(ThemeManager.CurrentTheme.Chart_HistogramLineColor);
        this.barsLineColorPen = new Pen(ThemeManager.CurrentTheme.Chart_SolidPriceColor, 2);
        this.solidPriceColorBrush = new LinearGradientBrush(
            0, 0, 1, 1,
            ThemeManager.CurrentTheme.Chart_SolidPriceColor_Gradient_Start,
            ThemeManager.CurrentTheme.Chart_SolidPriceColor_Gradient_End
        );

        this.barsUpColorBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_BarsUpColor);
        this.barsDownColorBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_BarsDownColor);
        this.barsUpBorderColorPen = new Pen(ThemeManager.CurrentTheme.Chart_BarsUpBorderColor);
        this.barsDownBorderColorPen = new Pen(ThemeManager.CurrentTheme.Chart_BarsDownBorderColor);

        this.clusterRegularBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_ClusterRegularFontColor);
        this.clusterMaxTextBrush = new SolidBrush('white');

        this.clusterBorderPen = new Pen(ThemeManager.CurrentTheme.Chart_ClusterBorderColor);
        this.clusterColorSchemeDeltaSellSourceBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_ClusterColorSchemeDeltaSellSourceColor);
        this.clusterColorSchemeDeltaBuySourceBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_ClusterColorSchemeDeltaBuySourceColor);

        this.clusterMaxBackBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_ClusterMaxBackColor);
        this.clusterMaxTextBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_ClusterMaxTextColor);

        this.clusterTradesSchemeSourceBrush = this.clusterVolumeSchemeSourceBrush =
            new SolidBrush(ThemeManager.CurrentTheme.Chart_ClusterVolumeSchemeColor);

        this.marketProfileRegularBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_MarketProfileRegularColor);

        this.marketProfileVAVerticalPen = this.marketProfileVAHorisontalPen =
            new Pen(ThemeManager.CurrentTheme.Chart_MarketProfileVAColor, 3);

        this.marketProfilePocPen = new Pen(ThemeManager.CurrentTheme.Chart_MarketProfilePocColor);
        this.marketProfilePocTriangleBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_MarketProfilePocColor);

        this.marketProfileSinglesPen = new Pen(ThemeManager.CurrentTheme.Chart_MarketProfileSingleColor);

        this.marketProfilePositiveBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_MarketProfilePositiveColor);
        this.marketProfileNegativeBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_MarketProfileNegativeColor);

        this.marketProfileVolumeColor = ThemeManager.CurrentTheme.Chart_MarketProfileVolumeColor;
        this.marketProfileGradientColor = ThemeManager.CurrentTheme.Chart_MarketProfileGradientColor;
    // }
    }

    IsNeedDraw (numberOfLayer: number): boolean {
        return this.assignLayer === numberOfLayer || LayersEnum.Quotes === numberOfLayer;
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null): void {
        if (!this.Visible || this.Series == null || this.InvalidState/* || !(this.Series is TerceraChartCashItemSeries) */) { return; }

        advParams.exitAfterFirst = advParams.layerId === LayersEnum.Quotes;

        //
        // После рисования - режим Foreground
        // TerceraChartRendererDrawingAdvancedParams param = (TerceraChartRendererDrawingAdvancedParams)advParams;
        advParams.Tag[TerceraChartRendererDrawingAdvancedParamsEnum.CurrentDrawingType] = TerceraChartToolRendererDrawingType.Foreground;

        // bool Additional = this is TerceraChartOverlayRenderer;

        /// /
        /// / Режим редактирования
        /// /
        // if (pro.EditMode && pro.ActiveMainPriceInd == this)
        // {
        //    editMode = true;
        // }

        //
        // общий набор для всех рисовалок
        //
        const clientRect = window.ClientRectangle;

        gr.save();
        gr.beginPath();
        gr.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
        gr.clip();

        const cashItemSeries = this.Series;// s as TerceraChartCashItemSeries;
        const scX = window.XScale;
        const curX = clientRect.X + clientRect.Width - scX;
        let leftBorder = 0;
        let barW = 1;
        const procData = TerceraChartMainPriceRenderer.ProcessBarWidth(window);
        leftBorder = procData.leftBorder;
        barW = procData.barW;

        // this.SpreadAskBidSessionFlag = advParams.Instrument.Level1.getBidAskSessionFlag();
        // this.SpreadLastSessionFlag = cashItemSeries.SyncronizedSessionFlag ? cashItemSeries.SyncronizedSessionFlag : advParams.Instrument.Level1.getLastSessionFlag();

        switch (this.ChartDrawingType) {
        //
        // Candles
        //
        case TerceraChartDrawingType.Candle:
        case TerceraChartDrawingType.Bar:
        // Draw zero line
            TerceraChartMainPriceRenderer.DrawZeroLineForRelative(gr, window, clientRect, cashItemSeries);
            this.DrawCandleChart(gr, window, scX, curX, leftBorder, barW, cashItemSeries, advParams);
            break;

        case TerceraChartDrawingType.LinesBreak:
        case TerceraChartDrawingType.Renko:
            this.DrawCandleChart(gr, window, scX, curX, leftBorder, barW, cashItemSeries);
            break;
        //
        // Рисование линий
        //
        case TerceraChartDrawingType.Line:
        case TerceraChartDrawingType.Dot:
        case TerceraChartDrawingType.DotLine:
        // Draw zero line
            TerceraChartMainPriceRenderer.DrawZeroLineForRelative(gr, window, clientRect, cashItemSeries);
            //
            this.DrawLineChart(gr, window, scX, curX, leftBorder, barW, cashItemSeries, advParams);
            break;

        //
        // Рисование Solid
        //
        case TerceraChartDrawingType.Solid:
        // Draw zero line
            TerceraChartMainPriceRenderer.DrawZeroLineForRelative(gr, window, clientRect, cashItemSeries);
            //
            this.DrawLineChart(gr, window, scX, curX, leftBorder, barW, cashItemSeries, advParams);
            break;

        case TerceraChartDrawingType.Forest:
        // Draw zero line
            TerceraChartMainPriceRenderer.DrawZeroLineForRelative(gr, window, clientRect, cashItemSeries);
            //
            this.DrawForestChart(gr, window, scX, curX, leftBorder, barW, cashItemSeries);
            break;

        case TerceraChartDrawingType.Kagi:
            this.DrawKagiChart(gr, window, scX, curX, leftBorder, barW, cashItemSeries);
            // Draw zero line
            TerceraChartMainPriceRenderer.DrawZeroLineForRelative(gr, window, clientRect, cashItemSeries);
            break;
        case TerceraChartDrawingType.TicTac:
        // Draw zero line
            TerceraChartMainPriceRenderer.DrawZeroLineForRelative(gr, window, clientRect, cashItemSeries);
            this.DrawTicTacChart(gr, window, scX, curX, leftBorder, barW, cashItemSeries);
            break;

            //   case TerceraChartDrawingType.Cluster:
            //     this.DrawClusterChart(gr, window, scX, curX, leftBorder, barW, cashItemSeries);
            //     break;

        case TerceraChartDrawingType.Profile:
            this.DrawProfileChart(gr, window, scX, curX, leftBorder, barW, cashItemSeries);
            break;
        }

        // Reset clip.
        gr.restore();
    }

    DrawCandleChart (
        gr: any, window: any, scX: number,
        curX: number, leftBorder: number, barW: number, cashItemSeries: any,
        advParams?: any
    ) {
        const screenData = cashItemSeries.ChartScreenData.Storage;
        const clientRect = window.ClientRectangle;
        let i: number;
        const len: number = screenData.length;
        let firstObject: boolean = true;
        const exitAfterFirst: boolean = advParams.exitAfterFirst;
        const wickPolyLines = new PolyLine();
        const fallenPolyLines = new PolyLine();
        const greenPolyLines = new PolyLine();
        const fallenPolyRects = new PolyRect();
        const greenPolyRects = new PolyRect();

        for (i = len - 1; i >= 0; i--) {
            const scrDataItem = screenData[i];
            const closePrice = scrDataItem.Close;
            const openPrice = scrDataItem.Open;
            const highPrice = scrDataItem.High;
            const lowPrice = scrDataItem.Low;

            if (screenData[i].Hole || screenData[i].NotShowExtendedSession) {
                curX -= scX;
                continue;
            }

            let closeY = window.PointsConverter.GetScreenY(closePrice);
            let openY = window.PointsConverter.GetScreenY(openPrice);
            let highY = window.PointsConverter.GetScreenY(highPrice);
            let lowY = window.PointsConverter.GetScreenY(lowPrice);

            const res = TerceraChartMainPriceRenderer.FitBarByClientRectangle(
                clientRect, closeY, openY, highY, lowY
            );

            if (!res.boolValue) {
                curX -= scX;
                continue;
            }

            if (firstObject && !exitAfterFirst) {
                curX -= scX;
                firstObject = false;
                continue;
            }

            closeY = res.closeY;
            openY = res.openY;
            highY = res.highY;
            lowY = res.lowY;

            const fallenBar = openPrice > closePrice;
            const wickBar = closePrice - openPrice === 0;
            const addBarH = closeY - openY === 0 ? 1 : 0;
            let emptyRect: boolean = true;
            let barMIddle: number = (curX + leftBorder + barW / 2) - 1;

            if (barW === 1) {
                barMIddle = curX;
            }

            const barBrush: any = null;
            const barPen: any = null;

            if (wickBar) {
                wickPolyLines.lines.push(new Line(barMIddle, highY, barMIddle, lowY));
                let leftBorderX: number = curX + leftBorder;
                let richtBorderX: number = curX + leftBorder + barW;

                if ((richtBorderX - leftBorderX) % 2 !== 0) {
                    richtBorderX--;
                }

                if (leftBorderX === richtBorderX) {
                    richtBorderX++;
                }

                if (barW === 1) {
                    leftBorderX = richtBorderX;
                }

                wickPolyLines.lines.push(new Line(leftBorderX, openY, richtBorderX, openY));
            } else if (fallenBar) {
                switch (this.ChartDrawingType) {
                case TerceraChartDrawingType.Bar:
                    fallenPolyLines.lines.push(new Line(barMIddle, highY, barMIddle, lowY));
                    fallenPolyLines.lines.push(new Line(curX + leftBorder, openY, barMIddle, openY));
                    let wid: number = curX + leftBorder + barW;

                    if (barW === 1) {
                        wid = barMIddle;
                    }

                    fallenPolyLines.lines.push(new Line(barMIddle, closeY, wid, closeY));
                    break;

                default:
                    fallenPolyLines.lines.push(new Line(barMIddle, highY, barMIddle, openY));
                    fallenPolyLines.lines.push(new Line(barMIddle, lowY, barMIddle, closeY));

                    if (barW === 1) {
                        fallenPolyLines.lines.push(new Line(curX + leftBorder, openY, curX + leftBorder, closeY));
                    } else {
                        const h: number = closeY - openY + addBarH;
                        fallenPolyRects.rects.push(new Rectangle(curX + leftBorder, openY, barW, h));
                        emptyRect = false;
                    }
                    break;
                }
            } else {
                switch (this.ChartDrawingType) {
                case TerceraChartDrawingType.Bar:
                    greenPolyLines.lines.push(new Line(barMIddle, highY, barMIddle, lowY));
                    greenPolyLines.lines.push(new Line(curX + leftBorder, openY, barMIddle, openY));
                    let wid: number = curX + leftBorder + barW;

                    if (barW === 1) {
                        wid = barMIddle;
                    }

                    greenPolyLines.lines.push(new Line(barMIddle, closeY, wid, closeY));
                    break;

                default:
                    greenPolyLines.lines.push(new Line(barMIddle, highY, barMIddle, openY));
                    greenPolyLines.lines.push(new Line(barMIddle, lowY, barMIddle, closeY));

                    if (barW === 1) {
                        greenPolyLines.lines.push(new Line(curX + leftBorder, openY, curX + leftBorder, closeY));
                    } else {
                        const h: number = closeY - openY + addBarH;
                        greenPolyRects.rects.push(new Rectangle(curX + leftBorder, openY, barW, h));
                        emptyRect = false;
                    }
                    break;
                }
            }

            //
            // Drawing
            //
            // if (!emptyRect)
            // {
            //    if (barBrush)
            //    {
            //        gr.FillRect(barBrush, barRect.X, barRect.Y, barRect.Width, barRect.Height);
            //    };
            //    if (barPen)
            //    {
            //        var barBorderRect = barRect.copy();
            //        barBorderRect.Width -= 1;
            //        gr.DrawRect(
            //            barPen,
            //            barBorderRect.X, barBorderRect.Y, barBorderRect.Width, barBorderRect.Height);
            //    }
            // }

            curX -= scX;
            if (exitAfterFirst) { break; }
        }
        gr.DrawPolyLine(this.barsHiLowColorPen, wickPolyLines);
        gr.DrawPolyLine(this.ChartDrawingType === TerceraChartDrawingType.Bar ? this.barsDownBorderColorPen : this.wickDownBorderColorPen, fallenPolyLines);
        gr.DrawPolyLine(this.ChartDrawingType === TerceraChartDrawingType.Bar ? this.barsUpBorderColorPen : this.wickUpBorderColorPen, greenPolyLines);
        gr.DrawPolyRect(this.barsDownColorBrush, fallenPolyRects, this.barsDownBorderColorPen);
        gr.DrawPolyRect(this.barsUpColorBrush, greenPolyRects, this.barsUpBorderColorPen);
    }

    DrawLineChart (
        gr: any, // Replace 'any' with the actual type for 'gr'.
        window: any, // Replace 'any' with the actual type for 'window'.
        scX: number, // Replace 'number' with the appropriate data type.
        curX: number, // Replace 'number' with the appropriate data type.
        leftBorder: number, // Replace 'number' with the appropriate data type.
        barW: number, // Replace 'number' with the appropriate data type.
        cashItemSeries: any, // Replace 'any' with the actual type for 'cashItemSeries'.
        advParams: any // Replace 'any' with the actual type for 'advParams'.
    ) {
        const screenData = cashItemSeries.ChartScreenData.Storage;
        const period = cashItemSeries.CashItem.FPeriod;

        const points: Point[] = [];
        const points2: Point[] = [];
        const rects: Rectangle[] = [];
        const barDirection: boolean[] = [];

        const dotW = barW > 1 ? 2 : 1;
        const dotDelta = barW > 1 ? 1 : 0;

        const pConverter = window.PointsConverter;
        const firstObject = true;
        const isTic = this.Series._cashItem.FPeriod === Periods.TIC;
        const exitAfterFirst = advParams.exitAfterFirst;
        if (!exitAfterFirst) {
            return;
        }
        // if (isTic && exitAfterFirst)
        //     return;
        //
        // Iterate through all bars
        //
        const len = screenData.length;
        for (let i = len - 1; i >= 0; i--) {
            const scrDataItem = screenData[i];

            /* if (scrDataItem.Hole) {
                      DrawPartLines(gr, window, points, points2, period, rects, barDirection);
                      points.Clear();
                      rects.Clear();
                  } */

            const closePrice = scrDataItem.Close;
            const openPrice = scrDataItem.Open;

            //
            // Only one line is not drawn for ticks.
            // BUG #29345  (HDM) Only Bid or only Ask tick line does not drawing
            // 1. When one of them is missing, draw the other.
            if (!openPrice && !closePrice) {
                curX -= scX;
                continue;
            }
            // if (!isTic && firstObject && !exitAfterFirst)
            // {
            //     curX -= scX;
            //     firstObject = false;
            //     continue;
            // }

            const closeY = pConverter.GetScreenY(closePrice);
            const openY = pConverter.GetScreenY(openPrice);

            // Explicitly incorrect values will cause an exception during drawing
            if (
                (closePrice && (closeY < -1000 || closeY > 10000)) ||
                (openPrice && (openY < -1000 || openY > 10000))
            ) {
                curX -= scX;
                continue;
            }

            const barMIddle = barW === 1 ? curX : curX + leftBorder + barW / 2;

            //
            // Only one line is not drawn for ticks.
            // BUG #29345  (HDM) Only Bid or only Ask tick line does not drawing
            // 2. Don't filter out the one that is missing
            if (closePrice) {
                points.push(new Point(barMIddle, closeY));
            }
            if (openPrice && period === Periods.TIC) {
                points2.push(new Point(barMIddle, openY));
            }

            const ChartDrawingType = this.ChartDrawingType;

            if (
                ChartDrawingType === TerceraChartDrawingType.Dot ||
                ChartDrawingType === TerceraChartDrawingType.DotLine
            ) {
                if (closePrice) {
                    rects.push(new Rectangle(barMIddle - dotDelta, closeY, dotW, dotW));
                    barDirection.push(openPrice > closePrice);
                }
            } else if (ChartDrawingType === TerceraChartDrawingType.Solid) {
                if (points2.length === 0) {
                    points2.push(new Point(barMIddle, closeY));
                } else if (closeY < points2[0].Y) {
                    points2[0] = new Point(barMIddle, closeY);
                }
            }
            curX -= scX;
            // if (exitAfterFirst && points.length > 1)
            //     break;
        }

        this.DrawPartLines(gr, window, points, points2, period, rects, barDirection);
    }

    DrawPartLines (
        gr: any, // Replace 'any' with the actual type for 'gr'.
        window: any, // Replace 'any' with the actual type for 'window'.
        points: Point[], // Define the appropriate type for 'points'.
        points2: Point[], // Define the appropriate type for 'points2'.
        period: number, // Define the appropriate type for 'period'.
        rects: Rectangle[], // Define the appropriate type for 'rects'.
        barDirection: boolean[] // Define the appropriate type for 'barDirection'.
    ) {
    // var mode = gr.SmoothingMode;
    // gr.SmoothingMode = SmoothingMode.HighQuality;

        const p_len = points.length;
        const p2_len = points2.length;

        //
        // Drawing lines
        //
        if (period !== Periods.TIC) {
            if (p_len > 1) {
                const rects_len = rects.length;

                switch (this.ChartDrawingType) {
                case TerceraChartDrawingType.Line:
                    gr.DrawCurve(this.barsLineColorPen, points);
                    break;

                case TerceraChartDrawingType.Dot:
                    for (let i = 0; i < rects_len; i++) {
                        const rect = rects[i];

                        gr.FillEllipse(
                            barDirection[i] ? this.barsDownColorBrush : this.barsUpColorBrush,
                            rect.X - 2.5, rect.Y - 3, rect.Width + 5, rect.Height + 5
                        );
                    }
                    break;

                case TerceraChartDrawingType.DotLine:
                    for (let i = 0; i < rects_len; i++) {
                        if (i > 0) {
                            const pen = barDirection[i - 1]
                                ? this.barsDownBorderColorPen
                                : this.barsUpBorderColorPen;
                            const prevPoint = points[i - 1];
                            const currPoint = points[i];

                            gr.DrawLine(
                                pen,
                                prevPoint.X - 1, prevPoint.Y,
                                currPoint.X - 1, currPoint.Y
                            );
                        }

                        const rect = rects[i];

                        gr.DrawEllipse(
                            barDirection[i] ? this.barsDownBorderColorPen : this.barsUpBorderColorPen,
                            rect.X - 2.5, rect.Y - 3, rect.Width + 5, rect.Height + 5
                        );
                        gr.FillEllipse(
                            barDirection[i] ? this.barsDownColorBrush : this.barsUpColorBrush,
                            rect.X - 2.5, rect.Y - 3, rect.Width + 5, rect.Height + 5
                        );
                    }
                    break;

                case TerceraChartDrawingType.Solid:
                    const CR = window.ClientRectangle;
                    const bottom = CR.Y + CR.Height;
                    // to close polygon
                    points.push(new Point(points[p_len - 1].X, bottom));
                    points.push(new Point(points[0].X, bottom));

                    let solidPriceColorBrush = this.solidPriceColorBrush;

                    const firstP2Point = points2[0];

                    solidPriceColorBrush = new LinearGradientBrush(
                        firstP2Point.X, firstP2Point.Y, firstP2Point.X, bottom,
                        solidPriceColorBrush.ColorStart,
                        solidPriceColorBrush.ColorEnd
                    );

                    gr.DrawFilledCurve(solidPriceColorBrush, points);

                    // remove temp points
                    points.splice(p_len, 2);

                    gr.DrawCurve(this.barsLineColorPen, points);
                    break;
                }
            }
        }
        //
        // Drawing ticks
        //
        else {
            if (p_len > 1) gr.DrawLines(this.wickDownBorderColorPen, points);
            if (p2_len > 1) gr.DrawLines(this.wickUpBorderColorPen, points2);
        }

    // gr.SmoothingMode = mode;
    }

    DrawForestChart (
        gr: any, // Replace 'any' with the actual type for 'gr'.
        window: any, // Replace 'any' with the actual type for 'window'.
        scX: number, // Replace 'number' with the appropriate data type.
        curX: number, // Replace 'number' with the appropriate data type.
        leftBorder: number, // Replace 'number' with the appropriate data type.
        barW: number, // Replace 'number' with the appropriate data type.
        cashItemSeries: any // Replace 'any' with the actual type for 'cashItemSeries'.
    ) {
        const screenData = cashItemSeries.ChartScreenData.Storage;
        // Find the last close price
        let lastClose = 0;
        let lclose = screenData.length - 1;
        while (lclose > 0 && lastClose === 0) {
            lastClose = screenData[lclose].Close;
            lclose--;
        }
        // Draw it on the chart
        const curIntY = window.PointsConverter.GetScreenY(lastClose);
        if (!(curIntY < -1000 || curIntY > 10000)) {
            const clientRect = window.ClientRectangle;
            const clientRectX = clientRect.X;
            gr.DrawLine(this.histogramLineColorPen, clientRectX, curIntY, clientRectX + clientRect.Width, curIntY);
        }
        //
        // Iterate through all bars
        //
        for (let i = screenData.length - 1; i >= 0; i--) {
            const closePrice = screenData[i].Close;

            if (!closePrice) {
                curX -= scX;
                continue;
            }

            const closeY = window.PointsConverter.GetScreenY(closePrice);

            // Explicitly incorrect values will cause an exception during drawing
            if (closePrice && (closeY < -1000 || closeY > 10000)) {
                curX -= scX;
                continue;
            }

            const barMIddle = barW === 1
                ? curX
                : curX + leftBorder + barW / 2;

            gr.DrawLine(this.histogramLineColorPen, barMIddle, curIntY, barMIddle, closeY);
            curX -= scX;
        }
    }

    DrawKagiChart (
        gr: any, // Replace 'any' with the actual type for 'gr'.
        window: any, // Replace 'any' with the actual type for 'window'.
        scX: number, // Replace 'number' with the appropriate data type.
        curX: number, // Replace 'number' with the appropriate data type.
        leftBorder: number, // Replace 'number' with the appropriate data type.
        barW: number, // Replace 'number' with the appropriate data type.
        cashItemSeries: any // Replace 'any' with the actual type for 'cashItemSeries'.
    ) {
        const screenData = cashItemSeries.ChartScreenData.Storage;
        const scrData_len = screenData.length;

        const boldLines: Record<number, boolean> = {};

        const barsUpBorderColorPen = this.barsUpBorderColorPen;
        const restoreWidth = barsUpBorderColorPen.Width;

        try {
            if (scrData_len > 0) {
                const scrDataFirst = screenData[0];
                boldLines[0] = scrDataFirst.Close > scrDataFirst.Open;
            }
            for (let i = 1; i < scrData_len; i++) {
                const prevScrDataItem = screenData[i - 1];
                const close = screenData[i].Close;

                if (close > prevScrDataItem.High) { boldLines[i] = true; } else if (close < prevScrDataItem.Low) { boldLines[i] = false; } else { boldLines[i] = boldLines[i - 1]; }
            }

            barsUpBorderColorPen.Width *= 4;
            const barsDownPen = this.barsDownBorderColorPen;

            const pConverter = window.PointsConverter;
            let list: Point[] = [];
            let isBoldState = boldLines[scrData_len - 1];
            for (let i = scrData_len - 1; i >= 0; i--) {
                const scrDataItem = screenData[i];

                const dv1 = scrDataItem.Close;

                if (dv1) {
                    const dv0 = scrDataItem.Open;
                    const dv2 = scrDataItem.High;
                    const dv3 = scrDataItem.Low;

                    const cv0 = pConverter.GetScreenY(dv0);
                    const cv1 = pConverter.GetScreenY(dv1);
                    const cv2 = pConverter.GetScreenY(dv2);
                    const cv3 = pConverter.GetScreenY(dv3);

                    const curBoldState = boldLines[i];
                    if (isBoldState !== curBoldState) {
                        // If barstyle is used, the list might be empty
                        // Change the bold state anyway; otherwise, the last line will be drawn poorly
                        if (list.length > 0) {
                            const p1 = list[list.length - 1];
                            list.splice(list.length - 1);

                            const changePoint = new Point(p1.X, cv0);
                            list.push(changePoint);

                            gr.DrawLines(isBoldState ? barsUpBorderColorPen : barsDownPen, list);

                            list = [changePoint, p1];
                        }
                        isBoldState = curBoldState;
                    }

                    const x = curX + scX;
                    if (x < 0) {
                        if (list.length === 0) { continue; }

                        const prev = list[list.length - 1];
                        list.push(new Point(1, prev.Y));
                    } else list.push(new Point(x, cv1));// c

                    if (dv0 - dv1 > 0.0) {
                        // Falling market
                        list.push(new Point(curX, cv3));// l
                        list.push(new Point(curX, cv2));// h
                    } else {
                        // Rising market
                        list.push(new Point(curX, cv2));// h
                        list.push(new Point(curX, cv3));// l
                    }
                }
                curX -= scX;
            }
            if (list.length > 0) { gr.DrawLines(isBoldState ? barsUpBorderColorPen : barsDownPen, list); }
        } finally {
            barsUpBorderColorPen.Width = restoreWidth;
        }
    }

    DrawTicTacChart (
        gr: any, // Replace 'any' with the actual type for 'gr'.
        window: any, // Replace 'any' with the actual type for 'window'.
        scX: number, // Replace 'number' with the appropriate data type.
        curX: number, // Replace 'number' with the appropriate data type.
        leftBorder: number, // Replace 'number' with the appropriate data type.
        barW: number, // Replace 'number' with the appropriate data type.
        cashItemSeries: any // Replace 'any' with the actual type for 'cashItemSeries'.
    ) {
        const tt = cashItemSeries.CashItem;
        if (!tt) return;

        const ttHeight = tt.Height;

        const pConverter = window.PointsConverter;

        const screenData = cashItemSeries.ChartScreenData.Storage;
        const scrData_len = screenData.length;

        for (let i = scrData_len - 1; i >= 0; i--) {
            const scrDataItem = screenData[i];

            const dv1 = scrDataItem.Close;

            if (dv1) {
                const dv0 = scrDataItem.Open;
                const dv2 = scrDataItem.High;
                const dv3 = scrDataItem.Low;

                const curCx = curX;

                const count = Math.round((dv2 - dv3) / ttHeight);
                for (let j = 0; j < count; j++) {
                    const y_h = dv2 - ttHeight * j;
                    const y_l = dv2 - ttHeight * (j + 1);

                    const cv2 = pConverter.GetScreenY(y_h); // h
                    const cv3 = pConverter.GetScreenY(y_l); // l

                    if (dv0 > dv1) {
                        // +++denis #13818
                        const barsDownBorderColorPen = this.barsDownBorderColorPen;
                        if (barsDownBorderColorPen) {
                            const ticTacH = Math.abs(cv2 - cv3);
                            if (ticTacH < 2) { gr.DrawLine(barsDownBorderColorPen, curCx, cv2, curCx + barW, cv2); } else { gr.DrawEllipse(barsDownBorderColorPen, curCx, cv2, barW, ticTacH); }
                        }
                    } else {
                        const barsUpBorderColorPen = this.barsUpBorderColorPen;
                        if (barsUpBorderColorPen) {
                            const ticTacH = Math.abs(cv2 - cv3);
                            if (ticTacH < 2) {
                                gr.DrawLine(barsUpBorderColorPen, curCx, cv2, curCx + barW, cv2);
                            } else {
                                gr.DrawLine(barsUpBorderColorPen, curCx, cv2, curCx + barW, cv3);
                                gr.DrawLine(barsUpBorderColorPen, curCx, cv3, curCx + barW, cv2);
                            }
                        }
                    }
                }
            }
            curX -= scX;
        }
    }

    // TODO. Profile data is required.
    DrawProfileChart (gr: any, window: any, scX: number, curX: number, leftBorder: number, barW: number, cashItemSeries: any) {
        const pConverter = window.PointsConverter;

        const height = this.marketProfileTextFont.Height;
        this.cashSettings.profileMinimumStep = pConverter.GetDataY(0) - pConverter.GetDataY(height);

        const screenData = cashItemSeries.ChartScreenData.Storage;
        const scrData_len = screenData.length;

        const settings = cashItemSeries.settings;

        for (let i = scrData_len - 1; i >= 0; i--) {
            const data = screenData[i];

            /* if (data.Hole || data.profileData == null) {
                      curX -= scX;
                      continue;
                  } */

            const profileData = data.profileData;
            const x = curX;

            const isColorsByElement = settings.ProfileIsColorsByElement;

            // POC
            if (settings.profilePOCType === ProfilePOCTypesEnum.Line) {
                const pocLevel = pConverter.GetScreenY(profileData.maxPrice);

                gr.DrawLine(this.marketProfilePocPen, x, pocLevel, x + scX - 15, pocLevel);

                const pocPoints = [
                    new Point(x + scX - 10, pocLevel),
                    new Point(x + scX - 5, pocLevel - 4),
                    new Point(x + scX - 5, pocLevel + 3)
                ];

                gr.FillPolygon(this.marketProfilePocTriangleBrush, pocPoints);
            }

            // VA
            if (settings.profileVAType !== ProfileVATypesEnum.None) {
                const y1 = pConverter.GetScreenY(profileData.va1) - height / 2;
                const y2 = pConverter.GetScreenY(profileData.va2) + height / 2;

                switch (settings.profileVAType) {
                case ProfileVATypesEnum.Vertical:
                    gr.DrawLine(this.marketProfileVAVerticalPen, x, y2, x, y1);
                    break;
                case ProfileVATypesEnum.Horizontal:
                    gr.DrawLine(this.marketProfileVAVerticalPen, x, y2, x, y1);
                    gr.DrawLine(this.marketProfileVAHorisontalPen, x, y1, x + scX - 5, y1);
                    gr.DrawLine(this.marketProfileVAHorisontalPen, x, y2, x + scX - 5, y2);
                    break;
                case ProfileVATypesEnum.ToNextProfile:
                    gr.DrawLine(this.marketProfileVAVerticalPen, x, y2, x, y1);
                    gr.DrawLine(this.marketProfileVAHorisontalPen, x, y1, x + 2 * scX - 5, y1);
                    gr.DrawLine(this.marketProfileVAHorisontalPen, x, y2, x + 2 * scX - 5, y2);
                    break;
                }

                if (
                    settings.profileVAType === ProfileVATypesEnum.Horizontal ||
                    settings.profileVAType === ProfileVATypesEnum.ToNextProfile
                ) {
                    const gradientBrush = new LinearGradientBrush(
                        x,
                        y1,
                        x + scX,
                        y1,
                        this.marketProfileGradientColor,
                        'transparent'
                    );
                    gr.FillRect(gradientBrush, x, y1, scX, y2 - y1);
                }
            }

            // Singles
            if (profileData.singles) {
                const singles = profileData.singles;
                const singles_len = singles.length;
                for (let singles_i = 0; singles_i < singles_len; singles_i++) {
                    const y = pConverter.GetScreenY(singles[singles_i]);
                    gr.DrawLine(this.marketProfileSinglesPen, x, y, x + scX - 5, y);
                }
            }

            // Profile
            const profile = profileData.profile;
            const profile_keys = Object.keys(profile);
            const profile_len = profile_keys.length;
            for (let profile_i = 0; profile_i < profile_len; profile_i++) {
                const profile_key = profile_keys[profile_i];
                const textValue = profile[profile_key];
                const price = profile_key;
                const level = pConverter.GetScreenY(price);

                if (isColorsByElement) {
                    const marketProfileTextFont = this.marketProfileTextFont;
                    let textElementPosition = curX;
                    const textValueSplits = textValue('');
                    const txtVal_len = textValueSplits.length;
                    for (let txtVal_i = 0; txtVal_i < txtVal_len; txtVal_i++) {
                        const textElement = textValueSplits[txtVal_i];
                        const brush = this.ProfileGetBrush(settings, profileData, parseFloat(price), txtVal_i);

                        gr.DrawString(textElement, marketProfileTextFont, brush, textElementPosition, level - height / 2);

                        textElementPosition += gr.measureText(textElement).width;
                    }
                } else {
                    const brush = this.ProfileGetBrush(settings, profileData, parseFloat(price), 0);
                    gr.DrawString(textValue, this.marketProfileTextFont, brush, curX, level - height / 2);
                }
            }

            curX -= scX;
        }
    }

    ProfileGetBrush (settings: any, data: any, price: number, index: number): any {
        if (!settings || !data) {
            return this.marketProfileRegularBrush;
        }

        if (!data.colors) {
            return this.marketProfileRegularBrush;
        }

        const values: any[] = [];
        const mode = settings.profileVolumeType;
        return '';
        // switch (mode) {
        //     case TerceraChartCashItemSeriesSettings.ProfileColoringTypes.Single:
        //         // "бесцветная" схема
        //         return this.marketProfileRegularBrush;

        //     case TerceraChartCashItemSeriesSettings.ProfileColoringTypes.DeltaProfile:
        //     case TerceraChartCashItemSeriesSettings.ProfileColoringTypes.UpdownProfile:
        //         // Red/Green бары
        //         const deltaProfile = data.colors;
        //         return deltaProfile <= 0 ? this.marketProfileNegativeBrush : this.marketProfilePositiveBrush;

        //     case TerceraChartCashItemSeriesSettings.ProfileColoringTypes.DeltaPrice:
        //         // Red/Green уровни цен
        //         const deltaPrices = data.colors;
        //         if (deltaPrices.hasOwnProperty(price)) {
        //             return deltaPrices[price] <= 0 ? this.marketProfileNegativeBrush : this.marketProfilePositiveBrush;
        //         } else {
        //             return this.marketProfileRegularBrush;
        //         }

        //     case TerceraChartCashItemSeriesSettings.ProfileColoringTypes.VolumePrice:
        //         // уровни с прозрачностью
        //         const volumePrices = data.colors; // There's a variable name missing here; replace with the correct one
        //         if (volumePrices.hasOwnProperty(price)) {
        //             return new SolidBrush(Color.FromArgb(volumePrices[price], this.marketProfileVolumeColor);
        //         } else {
        //             return this.marketProfileRegularBrush;
        //         }

        //     case TerceraChartCashItemSeriesSettings.ProfileColoringTypes.DeltaBars:
        //         // Red/Green поэлементно
        //         let deltaBars = data.colors;
        //         values = deltaBars[price] || [];

        //         if (!deltaBars.hasOwnProperty(price) || index >= values.length) {
        //             return this.marketProfileRegularBrush;
        //         }

        //         const deltaBarVal = values[index];

        //         return new SolidBrush(Color.FromArgb(
        //             Math.abs(deltaBarVal),
        //             deltaBarVal <= 0 ? this.marketProfileNegativeBrush.Color : this.marketProfilePositiveBrush.Color
        //         ));

        //     case TerceraChartCashItemSeriesSettings.ProfileColoringTypes.UpdownBars:
        //         // Red/Green поэлементно
        //         let deltaBars = data.colors; // Same variable name as above; replace with the correct one
        //         values = deltaBars[price] || [];

        //         if (!deltaBars.hasOwnProperty(price)) {
        //             return this.marketProfileRegularBrush;
        //         }

        //         if (index >= values.length) {
        //             return this.marketProfileRegularBrush;
        //         }

        //         return values[index] <= 0 ? this.marketProfileNegativeBrush : this.marketProfilePositiveBrush;

        //     case TerceraChartCashItemSeriesSettings.ProfileColoringTypes.VolumeBars:
        //         // поэлементно с прозрачностью
        //         const volumeBars = data.colors; // There's a variable name missing here; replace with the correct one
        //         values = volumeBars[price] || [];

        //         if (!volumeBars.hasOwnProperty(price)) {
        //             return this.marketProfileRegularBrush;
        //         }

        //         if (index >= values.length) {
        //             return this.marketProfileRegularBrush;
        //         }

        //         return new SolidBrush(Color.FromArgb(
        //             values[index],
        //             this.marketProfileVolumeColor
        //         ));

    //     default:
    //         return this.marketProfileRegularBrush;
    // }
    }

    // #endregion

    // #region Cluster Chart

    // TODO. Cluster data, VolumeInfo are required.
    //   DrawClusterChart (
    //     gr: any, // Replace 'any' with the actual type for 'gr'.
    //     window: any, // Replace 'any' with the actual type for 'window'.
    //     scX: number, // Replace 'number' with the appropriate data type.
    //     curX: number, // Replace 'number' with the appropriate data type.
    //     leftBorder: number, // Replace 'number' with the appropriate data type.
    //     barW: number, // Replace 'number' with the appropriate data type.
    //     cashItemSeries: any // Replace 'any' with the actual type for 'cashItemSeries'.
    //   ) {
    //     const screenData = cashItemSeries.ChartScreenData.Storage;
    //     const scrData_len = screenData.length;

    //     const pConverter = window.PointsConverter;

    //     const clientRect = window.ClientRectangle;
    //     const MULTIPLIER_SIGN = TerceraChartMainPriceRenderer.MULTIPLIER_SIGN;

    //     const clusterTextFont = this.clusterTextFont;
    //     clusterTextFont.applySettings(gr);

    //     const mulSignWidth = gr.measureText(MULTIPLIER_SIGN).width;
    //     const textHeight = clusterTextFont.Height;
    //     const textHeightHalf = textHeight / 2;

    //     const cashSettings = this.cashSettings;

    //     cashSettings.clusterMinimumStep = pConverter.GetDataY(0) - pConverter.GetDataY(textHeight);
    //     const ci = cashItemSeries.CashItem;
    //     const tickData = ci && ci.FPeriod === Periods.TIC;

    //     const clusterBorderPen = this.clusterBorderPen;
    //     const clusterDownPen = this.clusterDownPen;
    //     const clusterUpPen = this.clusterUpPen;

    //     for (let i = scrData_len - 1; i >= 0; i--) {
    //       const data = screenData[i];

    //       /* if (data.Hole) {
    //                 curX -= scX;
    //                 continue;
    //             } */

    //       const closePrice = VolumeInfo.GetPrice(data.Close, cashSettings.clusterCurrentStep);
    //       const openPrice = VolumeInfo.GetPrice(data.Open, cashSettings.clusterCurrentStep);
    //       const highPrice = VolumeInfo.GetPrice(tickData ? closePrice : data.High, cashSettings.clusterCurrentStep);
    //       const lowPrice = VolumeInfo.GetPrice(tickData ? closePrice : data.Low, cashSettings.clusterCurrentStep);

    //       const closeY = pConverter.GetScreenY(closePrice);
    //       const openY = pConverter.GetScreenY(openPrice);
    //       const highY = pConverter.GetScreenY(highPrice);
    //       const lowY = pConverter.GetScreenY(lowPrice);

    //       const res = TerceraChartMainPriceRenderer.FitBarByClientRectangle(clientRect, closeY, openY, highY, lowY);
    //       if (!res.boolValue) {
    //         curX -= scX;
    //         continue;
    //       }

    //       const boxHeight = pConverter.GetScreenY(closePrice) - pConverter.GetScreenY(closePrice + cashSettings.clusterCurrentStep);
    //       const boxHeightHalf = Math.round(boxHeight / 2) + 1;

    //       const fallenBar = openPrice > closePrice;
    //       const grownBar = openPrice < closePrice;

    //       const clusters = data.clusterData;

    //       if (clusters && clusters.length > 0) {
    //         const left = curX + leftBorder;
    //         const prices = this.DrawBarClusterData(gr, window, barW, clusters, textHeightHalf, boxHeight, mulSignWidth, left);

    //         // #region корректировка цен

    //         // корректирую рамку бара, чтобы гарантировано вместились все трейды
    //         if (prices.length > 0) {
    //           const max = prices[0];
    //           const min = prices[prices.length - 1];

    //           if (max > highPrice) { highY = pConverter.GetScreenY(max); }

    //           if (min < lowPrice) { lowY = pConverter.GetScreenY(min); }
    //         }
    //         // корректирую OHLC, чтобы не пересекали цены уровня
    //         highY -= boxHeightHalf - 1;
    //         lowY += boxHeightHalf;
    //         if (fallenBar) {
    //           openY -= boxHeightHalf;
    //           closeY += boxHeightHalf;
    //         } else if (grownBar) {
    //           openY += boxHeightHalf;
    //           closeY -= boxHeightHalf;
    //         }

    //         // #endregion

    //         gr.DrawRect(clusterBorderPen, left, highY, barW, lowY - highY);
    //         if (!tickData) {
    //           if (fallenBar) { gr.DrawLine(clusterDownPen, left, openY, left, closeY); } else if (grownBar) { gr.DrawLine(clusterUpPen, left, openY, left, closeY); }
    //         }
    //       }

    //       curX -= scX;
    //     }
    //   }

    //   DrawBarClusterData (
    //     gr: any, // Replace 'any' with the actual type for 'gr'.
    //     window: any, // Replace 'any' with the actual type for 'window'.
    //     barW: number, // Replace 'number' with the appropriate data type.
    //     clusters: any[], // Replace 'any[]' with the actual type for 'clusters'.
    //     textHeightHalf: number, // Replace 'number' with the appropriate data type.
    //     boxHeight: number, // Replace 'number' with the appropriate data type.
    //     mulSignWidth: number, // Replace 'number' with the appropriate data type.
    //     left: number // Replace 'number' with the appropriate data type.
    //   ) {
    //     const pConverter = window.PointsConverter;
    //     // Prices are the same for every "clusters" item.
    //     const prices = Object.keys(clusters[0].StrValues);
    //     prices.sort(TerceraChartMainPriceRenderer.DrawBarClusterDataPriceComparator);
    //     const prices_len = prices.length;

    //     const CLUSTER_TEXT_MARGIN_PX = TerceraChartMainPriceRenderer.CLUSTER_TEXT_MARGIN_PX;
    //     const MULTIPLIER_SIGN = TerceraChartMainPriceRenderer.MULTIPLIER_SIGN;

    //     for (let i = 0; i < prices_len; i++) {
    //       const price = prices[i];

    //       const scrPriceY = pConverter.GetScreenY(price);
    //       const textYTop = scrPriceY - textHeightHalf;
    //       const boxYTop = scrPriceY - boxHeight / 2;

    //       const valuesWidthList: number[] = [];

    //       const res = this.GetClusterTextWidth(gr, clusters, price, mulSignWidth, valuesWidthList);
    //       let textWidth = res.textWidth;
    //       let valueWidth = res.clusterTextWidth;

    //       const drawElipsis = barW - 2 < textWidth;
    //       if (drawElipsis) {
    //         const res = this.GetClusterTextWidth(gr, clusters, price, mulSignWidth, valuesWidthList, true);
    //         textWidth = res.textWidth;
    //         valueWidth = res.clusterTextWidth;
    //       }

    //       const textPadding = (barW - textWidth) / 2;
    //       let startTextX = left + textPadding + (drawElipsis ? 0 : (valueWidth - valuesWidthList[0]));

    //       const clusters_len = clusters.length;
    //       for (let c_i = 0; c_i < clusters_len; c_i++) {
    //         const cluster = clusters[c_i];

    //         // Value background.
    //         const valueBackBrush = this.GetValueBackBrush(cluster, price, clusterColorScheme);
    //         if (valueBackBrush) {
    //           if (clusters_len === 1) {
    //             // Fill the entire bar width.
    //             gr.FillRect(valueBackBrush, left, boxYTop, barW, boxHeight);
    //           } else {
    //             if (c_i === 0) { gr.FillRect(valueBackBrush, left, boxYTop, valueWidth + (startTextX - left), boxHeight); } else if (c_i === clusters_len - 1) { gr.FillRect(valueBackBrush, startTextX, boxYTop, valueWidth + barW - (startTextX + valueWidth - left), boxHeight); } else { gr.FillRect(valueBackBrush, startTextX, boxYTop, valueWidth, boxHeight); }
    //           }
    //         }
    //         // Value text.
    //         gr.DrawString(drawElipsis ? '...' : cluster.StrValues[price],
    //           clusterTextFont, this.GetValueBrush(cluster, price), startTextX, textYTop);

    //         startTextX += drawElipsis ? valueWidth : valuesWidthList[c_i];

    //         // Space between values.
    //         if (c_i + 1 < clusters_len) {
    //           startTextX += CLUSTER_TEXT_MARGIN_PX;
    //           gr.DrawString(MULTIPLIER_SIGN, clusterTextFont, clusterRegularBrush, startTextX, textYTop);
    //           startTextX += mulSignWidth;
    //           startTextX += CLUSTER_TEXT_MARGIN_PX;
    //         }
    //       }
    //     }

    //     return prices;
    //   }

    //   GetClusterTextWidth (
    //     gr: any, // Replace 'any' with the actual type for 'gr'.
    //     clusters: any[], // Replace 'any[]' with the actual type for 'clusters'.
    //     price: number, // Replace 'number' with the appropriate data type.
    //     mulSignWidth: number, // Replace 'number' with the appropriate data type.
    //     valuesWidthList: number[],
    //     elipsis?: boolean
    //   ) {
    //     let textWidth = 0;
    //     let clusterTextWidth = 0;

    //     if (elipsis) {
    //       clusterTextWidth = gr.measureText('...').width;
    //     } else {
    //       const len = clusters.length;
    //       for (let i = 0; i < len; i++) {
    //         const cluster = clusters[i];

    //         const formattedValue = cluster.StrValues[price];
    //         const valueWidth = gr.measureText(formattedValue).width;

    //         valuesWidthList.push(valueWidth);

    //         if (clusterTextWidth < valueWidth) { clusterTextWidth = valueWidth; }
    //       }
    //     }

    //     textWidth =
    //             clusterTextWidth * len +
    //             (TerceraChartMainPriceRenderer.CLUSTER_TEXT_MARGIN_PX + mulSignWidth) *
    //             (len - 1);

    //     return {
    //       textWidth,
    //       clusterTextWidth
    //     };
    //   }

    //   GetValueBrush (cluster: any, price: number) {
    //     return this.clusterHighlightMax && cluster.MaxList.hasOwnProperty(price)
    //       ? this.clusterMaxTextBrush
    //       : this.clusterRegularBrush;
    //   }

    //   GetValueBackBrush (cluster: any, price: number, colorScheme: any) {
    //     if (this.clusterHighlightMax && cluster.MaxList.hasOwnProperty(price)) {
    //       return this.clusterMaxBackBrush;
    //     } else {
    //       let sourceColor = '';
    //       let min = 0; let max = 0;
    //       let curValue = 0;

    //       switch (colorScheme) {
    //         case ClusterColorScheme.ByDelta: {
    //           const clusterDelta = cluster.ClusterDelta;
    //           if (clusterDelta) {
    //             curValue = clusterDelta.Values[price];
    //             const buySide = curValue >= 0;

    //             min = 0;
    //             max = buySide ? clusterDelta.MaxValue : Math.abs(clusterDelta.MinValue);

    //             sourceColor = buySide
    //               ? this.clusterColorSchemeDeltaBuySourceBrush.Color
    //               : this.clusterColorSchemeDeltaSellSourceBrush.Color;

    //             if (!buySide) curValue = Math.abs(curValue);
    //           }
    //           break;
    //         }

    //         case ClusterColorScheme.ByVolume: {
    //           const clusterVolume = cluster.ClusterVolume;
    //           if (clusterVolume) {
    //             curValue = clusterVolume.Values[price];

    //             min = clusterVolume.MinValue;
    //             max = clusterVolume.MaxValue;

    //             sourceColor = this.clusterVolumeSchemeSourceBrush.Color;
    //           }
    //           break;
    //         }

    //         case ClusterColorScheme.ByTrades: {
    //           const clusterTrades = cluster.ClusterTrades;
    //           if (clusterTrades) {
    //             curValue = clusterTrades.Values[price];

    //             min = clusterTrades.MinValue;
    //             max = clusterTrades.MaxValue;

    //             sourceColor = this.clusterTradesSchemeSourceBrush.Color;
    //           }
    //           break;
    //         }
    //       }

    //       if (!sourceColor) { return null; }

    //       this.colorSchemeBrush.Color = Color.GetLighterColor(
    //         min, max, curValue, sourceColor, 0.2);

    //       return this.colorSchemeBrush;
    //     }
    //   }

    static DrawBarClusterDataPriceComparator (p1: number, p2: number) {
        return p2 - p1;
    }

    static DrawZeroLineForRelative (
        gr: any, // Replace 'any' with the actual type for 'gr'.
        window: any, // Replace 'any' with the actual type for 'window'.
        clientRect: any, // Replace 'any' with the actual type for 'clientRect'.
        cashItemSeries: any // Replace 'any' with the actual type for 'cashItemSeries'.
    ) {
        if (cashItemSeries.settings.DataType === TerceraChartCashItemSeriesDataType.Relative) {
            const zeroY = window.PointsConverter.GetScreenY(0);
            const clientRectX = clientRect.X;
            gr.DrawLine(new Pen('red'), clientRectX, zeroY, clientRectX + clientRect.Width, zeroY);
        }
    }

    static ProcessBarWidth (window: any /* Replace 'any' with the actual type */) {
        const barWidth = window.XScale;
        let barW = 1;
        let leftBorder = 0;

        switch (barWidth) {
        case 0:
        case 1:
        case 2:
            barW = 1;
            break;
        case 3:
        case 4:
            barW = 3;
            break;
        case 5:
        case 6:
            leftBorder = 1;
            barW = 3;
            break;
        case 8:
            leftBorder = 1;
            barW = 5;
            break;
        case 12:
            leftBorder = 2;
            barW = 7;
            break;
        case 16:
            leftBorder = 4;
            barW = 9;
            break;
        case 25:
            leftBorder = 4;
            barW = 17;
            break;
        case 50:
            leftBorder = 6;
            barW = 39;
            break;
        case 100:
            leftBorder = 6;
            barW = 89;
            break;
        case 200:
            leftBorder = 6;
            barW = 189;
            break;
        default:
            if (barWidth < 50) {
                if (barWidth % 2 === 0) {
                    barW = barWidth - 1;
                } else {
                    leftBorder = 1;
                    barW = barWidth - 2;
                }
            } else {
                if (barWidth % 2 === 0) {
                    leftBorder = 5;
                    barW = barWidth - 5 - 4;
                } else {
                    leftBorder = 5;
                    barW = barWidth - 5 - 5;
                }
            }
            break;
        }

        return { barW, leftBorder };
    }

    static FitBarByClientRectangle (
        clientRect: any, // Replace 'any' with the actual type for 'clientRect'.
        closeY: number, // Replace 'number' with the appropriate data type.
        openY: number, // Replace 'number' with the appropriate data type.
        highY: number, // Replace 'number' with the appropriate data type.
        lowY: number // Replace 'number' with the appropriate data type.
    ) {
        const top = clientRect.Y;
        const bottom = clientRect.Y + clientRect.Height;

        if (highY < top || lowY > bottom) {
            if (lowY < top || highY > bottom) {
                return { boolValue: false };
            } else {
                if (closeY < top && openY < top) {
                    // Handle this case if needed.
                } else {
                    if (closeY < top) closeY = top;
                    if (openY < top) openY = top;
                }
                if (highY < top) highY = top;

                if (closeY > bottom) closeY = bottom;
                if (openY > bottom) openY = bottom;
                if (lowY > bottom) lowY = bottom;
            }
        }

        return { boolValue: true, highY, closeY, openY, lowY };
    }

    Properties (): DynProperty[] {
        const properties: DynProperty[] = super.Properties();
        let prop: DynProperty | null;

        // if (this.ChartDrawingType === this.TerceraChartDrawingType.Cluster && this.cashSettings !== null) {
        //     //#region Cluster properties
        //     // ... (Translated cluster properties here)
        //     //#endregion
        // } else if (this.ChartDrawingType === TerceraChartDrawingType.Profile && this.cashSettings !== null) {
        //     //#region Market Profile properties
        //     // ... (Translated Market Profile properties here)
        //     //#endregion
        // } else {
        const dp = new DynProperty('MainPriceVisibility', this.Visible, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 0;
        properties.push(dp);

        // Add colors
        const bodyColor = new PairColor(this.barsUpColorBrush.Color, this.barsDownColorBrush.Color, Resources.getResource('propery.MainPriceRenderer.Up'), Resources.getResource('propery.MainPriceRenderer.Down'));
        prop = new DynProperty('bodyColor', bodyColor, DynProperty.PAIR_COLOR, DynProperty.DATA_STYLE_GROUP);
        prop.enabilityValue = [TerceraChartDrawingType.Candle, TerceraChartDrawingType.Dot, TerceraChartDrawingType.DotLine];
        prop.sortIndex = 2;
        properties.push(prop);

        const borderColor = new PairColor(this.barsUpBorderColorPen.Color, this.barsDownBorderColorPen.Color, Resources.getResource('propery.MainPriceRenderer.Up'), Resources.getResource('propery.MainPriceRenderer.Down'));
        prop = new DynProperty('borderColor', borderColor, DynProperty.PAIR_COLOR, DynProperty.DATA_STYLE_GROUP);
        prop.enabilityValue = [TerceraChartDrawingType.Bar, TerceraChartDrawingType.Candle];
        prop.sortIndex = 3;
        properties.push(prop);

        const wickColor = new PairColor(this.wickUpBorderColorPen.Color, this.wickDownBorderColorPen.Color, Resources.getResource('propery.MainPriceRenderer.Up'), Resources.getResource('propery.MainPriceRenderer.Down'));
        prop = new DynProperty('wickColor', wickColor, DynProperty.PAIR_COLOR, DynProperty.DATA_STYLE_GROUP);
        prop.enabilityValue = [TerceraChartDrawingType.Candle];
        prop.sortIndex = 4;
        properties.push(prop);

        prop = new DynProperty('barsHiLowColor', this.barsLineColorPen.Color, DynProperty.COLOR, DynProperty.DATA_STYLE_GROUP);
        prop.enabilityValue = [TerceraChartDrawingType.Line, TerceraChartDrawingType.Solid];
        prop.sortIndex = 7;
        properties.push(prop);

        prop = new DynProperty('solidPriceColor', this.solidPriceColorBrush.ColorStart, DynProperty.COLOR, DynProperty.DATA_STYLE_GROUP);
        prop.enabilityValue = [TerceraChartDrawingType.Solid];
        prop.sortIndex = 8;
        properties.push(prop);

        prop = new DynProperty('HistogramLineColor', this.histogramLineColorPen.Color, DynProperty.COLOR, DynProperty.DATA_STYLE_GROUP);
        prop.enabilityValue = [TerceraChartDrawingType.Forest];
        prop.sortIndex = 6;
        properties.push(prop);

        prop = new DynProperty('Doji', this.barsHiLowColorPen.Color, DynProperty.COLOR, DynProperty.DATA_STYLE_GROUP);
        prop.enabilityValue = [TerceraChartDrawingType.Bar, TerceraChartDrawingType.Candle];
        prop.sortIndex = 5;
        properties.push(prop);
        // }
        return properties;
    }

    callBack (properties: any) {
    // Cluster chart properties
    // ...

        // Market Profile
        // ...

        let prop = DynProperty.getPropertyByName(properties, 'MainPriceVisibility');
        if (prop) this.Visible = prop.value;

        // Apply colors
        prop = DynProperty.getPropertyByName(properties, 'bodyColor');
        if (prop !== null) {
            this.barsUpColorBrush = new SolidBrush(prop.value.Color1);
            this.barsDownColorBrush = new SolidBrush(prop.value.Color2);
        }

        prop = DynProperty.getPropertyByName(properties, 'borderColor');
        if (prop !== null) {
            this.barsUpBorderColorPen = new Pen(prop.value.Color1);
            this.barsDownBorderColorPen = new Pen(prop.value.Color2);
        }

        prop = DynProperty.getPropertyByName(properties, 'wickColor');
        if (prop !== null) {
            this.wickUpBorderColorPen = new Pen(prop.value.Color1);
            this.wickDownBorderColorPen = new Pen(prop.value.Color2);
        }

        prop = DynProperty.getPropertyByName(properties, 'barsHiLowColor');
        if (prop !== null) {
            this.barsLineColorPen.color = prop.value;
        }

        prop = DynProperty.getPropertyByName(properties, 'solidPriceColor');
        if (prop !== null) {
            this.solidPriceColorBrush.colorStart = prop.value;
        }

        prop = DynProperty.getPropertyByName(properties, 'HistogramLineColor');
        if (prop !== null) {
            this.histogramLineColorPen.color = prop.value;
        }

        prop = DynProperty.getPropertyByName(properties, 'Doji');
        if (prop !== null) {
            this.barsHiLowColorPen.color = prop.value;
        }
    }

    /**
  * Renderer participates in automatic scaling calculation.
  */
    FindMinMax (refObj: any, window: any) {
        refObj.tMin = Number.MAX_VALUE;
        refObj.tMax = -Number.MAX_VALUE;

        if (this.Series === null) {
            return false;
        }

        // Use cached data
        refObj.tMin = this.Series.ChartScreenData.MinLow;
        refObj.tMax = this.Series.ChartScreenData.MaxHigh;

        return true;
    }
}
