// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Indicator } from './Indicator';
import { iBuildInIndicatorFactory } from './iBuildInIndicatorFactory';

import { ALLIGATOR } from './indicators/ALLIGATOR';
import { AROON } from './indicators/AROON';
import { ATR } from './indicators/ATR';
import { ADL } from './indicators/ADL';
import { BB } from './indicators/BB';
import { BBF } from './indicators/BBF';
import { BBW } from './indicators/BBW';
import { CCI } from './indicators/CCI';
import { CHANNEL } from './indicators/CHANNEL';
import { DonchianChannel } from './indicators/DonchianChannel';
import { CMO } from './indicators/CMO';
import { Didi } from './indicators/Didi';
import { EMA } from './indicators/EMA';
import { EMACROSS } from './indicators/EMACROSS';
import { FRACTALS3 } from './indicators/Fractals3';
import { FRACTALS5 } from './indicators/Fractals5';
import { HiloEscandina } from './indicators/HiloEscandina';
import { KAMA } from './indicators/KAMA';
import { KELTNER } from './indicators/KELTNER';
import { KRI } from './indicators/KRI';
import { LWMA } from './indicators/LWMA';
import { MACD } from './indicators/MACD';
import { MAE } from './indicators/MAE';
import { MAS3 } from './indicators/MAS3';
import { TEMA } from './indicators/TEMA';
import { TRIX } from './indicators/TRIX';
import { PVT } from './indicators/PVT';
import { VO } from './indicators/VOLUMEOSCILLATOR';
import { DPO } from './indicators/DPO';
import { MD } from './indicators/MD';
import { MovingAverages } from './indicators/MMA';
import { MOMENTUM } from './indicators/MOMENTUM';
import { MultiEMA } from './indicators/MultiEMA';
import { MULTIMOVAVG } from './indicators/MULTIMOVAVG';
import { OBV } from './indicators/OBV';
import { PivotPoint } from './indicators/PivotPoint';
import { PO } from './indicators/PO';
import { PPMA } from './indicators/PPMA';
import { PPO } from './indicators/PPO';
import { QSTICK } from './indicators/QSTICK';
import { REGRESSION } from './indicators/REGRESSION';
import { RLW } from './indicators/RLW';
import { ROC } from './indicators/ROC';
import { RSI } from './indicators/RSI';
import { RSI_LAGUERRE } from './indicators/RSI_LAGUERRE';
import { SAR } from './indicators/SAR';
import { SD } from './indicators/SD';
import { SI } from './indicators/SI';
import { SMA } from './indicators/SMA';
import { WMA } from './indicators/WMA';
import { SMMA } from './indicators/SMMA';
import { STOCHASTIC } from './indicators/STOCHASTIC';
import { StopATR } from './indicators/StopATR';
import { SUPERTREND } from './indicators/SUPERTREND';
import { SUPERTRENDMTV3 } from './indicators/SUPERTRENDMTV3';
import { TSI } from './indicators/TSI';
import { UO } from './indicators/UO';
import { VOLUME } from './indicators/VOLUME';
import { VWAP } from './indicators/VWAP';
import { EOM } from './indicators/EOM';
import { MI } from './indicators/MI';
import { Resources } from '../../properties/Resources';
import { type IIndicator } from './IIndicator';
import { UUID } from '../../../Utils/UUID';
import { ClientType } from '../../../Utils/ClientType';

class _IndicatorManager {
    public IndicatorStorage: Record<string, any> = {};
    constructor () {
        this.InitIndicatorsStorage();
    }

    public GetIndicator (ProjectName: string): Indicator {
        let NewIndicator: Indicator = null;

        if (Resources.isHidden(ProjectName)) {
            return NewIndicator;
        }

        if (!this.IndicatorStorage[ProjectName]) {
            return NewIndicator;
        }

        NewIndicator = new Indicator();
        NewIndicator.Script = new this.IndicatorStorage[ProjectName].ctor();

        // TODO. Ugly.
        NewIndicator.Script.Indicators = this;
        NewIndicator.Script.iKey = UUID.generateUUIDv5();
        NewIndicator.FillFields();

        return NewIndicator;
    }

    public InitIndicatorsStorage (): void {
        this.IndicatorStorage['McGinley Dynamic'] = { ctor: MD, ShortName: 'MD', FullName: 'McGinley Dynamic', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage.Regression = { ctor: REGRESSION, ShortName: 'REGRESSION', FullName: 'Regression', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage['Smoothed Moving Average'] = { ctor: SMMA, ShortName: 'SMMA', FullName: 'Smoothed Moving Average', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage['Pivot Point Moving Average'] = { ctor: PPMA, ShortName: 'PPMA', FullName: 'Pivot Point Moving Average', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage['Simple Moving Average'] = { ctor: SMA, ShortName: 'SMA', FullName: 'Simple Moving Average', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage['Weighted Moving Average'] = { ctor: WMA, ShortName: 'WMA', FullName: 'Weighted Moving Average', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage['Exponential Moving Average'] = { ctor: EMA, ShortName: 'EMA', FullName: 'Exponential Moving Average', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage['Moving Average/Convergence Divergence'] = { ctor: MACD, ShortName: 'MACD', FullName: 'Moving Average/Convergence Divergence', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['Bollinger Bands Flat'] = { ctor: BBF, ShortName: 'BBF', FullName: 'Bollinger Bands Flat', DisplayGroup: 'Channels' };
        this.IndicatorStorage['Bollinger Bands Width'] = { ctor: BBW, ShortName: 'BBW', FullName: 'Bollinger Bands Width', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['Detrended Price Oscillator'] = { ctor: DPO, ShortName: 'DPO', FullName: 'Detrended Price Oscillator', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['Bollinger Bands'] = { ctor: BB, ShortName: 'BB', FullName: 'Bollinger Bands', DisplayGroup: 'Channels' };
        this.IndicatorStorage.Volume = { ctor: VOLUME, ShortName: 'Volume', FullName: 'Volume', DisplayGroup: 'Volume' };
        this.IndicatorStorage['Volume Weighted Average Price'] = { ctor: VWAP, ShortName: 'VWAP', FullName: 'Volume Weighted Average Price', DisplayGroup: 'Volume' };
        this.IndicatorStorage['Relative Strength Index'] = { ctor: RSI, ShortName: 'RSI', FullName: 'Relative Strength Index', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['Price Oscillator'] = { ctor: PO, ShortName: 'PO', FullName: 'Price Oscillator', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage.Momentum = { ctor: MOMENTUM, ShortName: 'Momentum', FullName: 'Momentum', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['Commodity Channel Index'] = { ctor: CCI, ShortName: 'CCI', FullName: 'Commodity Channel Index', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage.Aroon = { ctor: AROON, ShortName: 'Aroon', FullName: 'Aroon', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['Kairi Relative Index'] = { ctor: KRI, ShortName: 'KRI', FullName: 'Kairi Relative Index', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['Percentage Price Oscillator'] = { ctor: PPO, ShortName: 'PPO', FullName: 'Percentage Price Oscillator', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['%R Larry Williams'] = { ctor: RLW, ShortName: 'RLW', FullName: '%R Larry Williams', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['Rate of Change'] = { ctor: ROC, ShortName: 'ROC', FullName: 'Rate of Change', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage.QStick = { ctor: QSTICK, ShortName: 'QStick', FullName: 'QStick', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['True Strength Index'] = { ctor: TSI, ShortName: 'TSI', FullName: 'True Strength Index', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['Ultimate Oscillator'] = { ctor: UO, ShortName: 'UO', FullName: 'Ultimate Oscillator', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage.TRIX = { ctor: TRIX, ShortName: 'TRIX', FullName: 'TRIX', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage.PVT = { ctor: PVT, ShortName: 'PVT', FullName: 'PVT', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage.VO = { ctor: VO, ShortName: 'VO', FullName: 'VO', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['Pivot point'] = { ctor: PivotPoint, ShortName: 'Pivot point', FullName: 'Pivot point', DisplayGroup: 'Trend' };
        this.IndicatorStorage['Swing Index'] = { ctor: SI, ShortName: 'SI', FullName: 'Swing Index', DisplayGroup: 'Trend' };
        this.IndicatorStorage.ALLIGATOR = { ctor: ALLIGATOR, ShortName: 'ALLIGATOR', FullName: 'ALLIGATOR', DisplayGroup: 'Trend' };
        this.IndicatorStorage['3MASignal'] = { ctor: MAS3, ShortName: 'MAS3', FullName: '3MASignal', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage['Triple EMA'] = { ctor: TEMA, ShortName: 'TEMA', FullName: 'Triple EMA', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage['Modified Moving Average'] = { ctor: MovingAverages, ShortName: 'MMA', FullName: 'Modified Moving Average', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage['Stochastic Slow'] = { ctor: STOCHASTIC, ShortName: 'STOCHASTIC', FullName: 'Stochastic Slow', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['On Balance Volume'] = { ctor: OBV, ShortName: 'OBV', FullName: 'On Balance Volume', DisplayGroup: 'Volume' };
        this.IndicatorStorage['Ease of Movement'] = { ctor: EOM, ShortName: 'EOM', FullName: 'Ease of Movement', DisplayGroup: 'Volume' };
        this.IndicatorStorage['Mass Index'] = { ctor: MI, ShortName: 'MI', FullName: 'Mass Index', DisplayGroup: 'Oscillators' };
        this.IndicatorStorage['Average True Range'] = { ctor: ATR, ShortName: 'ATR', FullName: 'Average True Range', DisplayGroup: 'Volatility' };
        this.IndicatorStorage['Chande Momentum Oscillator'] = { ctor: CMO, ShortName: 'CMO', FullName: 'Chande Momentum Oscillator', DisplayGroup: 'Volatility' };
        this.IndicatorStorage['Standard Deviation'] = { ctor: SD, ShortName: 'SD', FullName: 'Standard Deviation', DisplayGroup: 'Volatility' };
        this.IndicatorStorage['Parabolic Time/Price System'] = { ctor: SAR, ShortName: 'SAR', FullName: 'Parabolic Time/Price System', DisplayGroup: 'Trend' };
        // this.IndicatorStorage['Average Directional Index'] = { ctor: ADX, ShortName: 'ADX', FullName: 'Average Directional Index', DisplayGroup: 'Trend' };
        this.IndicatorStorage['Accumulation Distribution Line'] = { ctor: ADL, ShortName: 'ADL', FullName: 'Accumulation Distribution Line', DisplayGroup: 'Trend' };
        this.IndicatorStorage['Keltner Channel'] = { ctor: KELTNER, ShortName: 'KELTNER', FullName: 'Keltner Channel', DisplayGroup: 'Channels' };
        this.IndicatorStorage['Price Channel'] = { ctor: CHANNEL, ShortName: 'CHANNEL', FullName: 'Price Channel', DisplayGroup: 'Channels' };
        this.IndicatorStorage['Moving Average Envelope'] = { ctor: MAE, ShortName: 'MAE', FullName: 'Moving Average Envelope', DisplayGroup: 'Channels' };
        this.IndicatorStorage['Donchian Channel'] = { ctor: DonchianChannel, ShortName: 'Donchian Channel', FullName: 'Donchian Channel', DisplayGroup: 'Channels' };
        this.IndicatorStorage['Linear Weighted Moving Average'] = { ctor: LWMA, ShortName: 'LWMA', FullName: 'Linear Weighted Moving Average', DisplayGroup: 'Moving Average' };

        this.IndicatorStorage.Didi = { ctor: Didi, ShortName: 'Didi', FullName: 'Didi', DisplayGroup: 'Extra' };
        this.IndicatorStorage.HiloEscandina = { ctor: HiloEscandina, ShortName: 'Hilo', FullName: 'HiloEscandina', DisplayGroup: 'Extra' };
        this.IndicatorStorage.StopATR = { ctor: StopATR, ShortName: 'StopATR', FullName: 'StopATR', DisplayGroup: 'Extra' };
        this.IndicatorStorage.Fractals3 = { ctor: FRACTALS3, ShortName: 'Fractals3', FullName: 'Fractals3', DisplayGroup: 'Trend' };
        this.IndicatorStorage.Fractals5 = { ctor: FRACTALS5, ShortName: 'Fractals5', FullName: 'Fractals5', DisplayGroup: 'Trend' };
        this.IndicatorStorage['Kaufman adaptive moving average'] = { ctor: KAMA, ShortName: 'KAMA', FullName: 'Kaufman adaptive moving average', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage['Multi EMA'] = { ctor: MultiEMA, ShortName: 'MultiEMA', FullName: 'Multi EMA', DisplayGroup: 'Moving Average' };
        this.IndicatorStorage['Self-Adjusting Alpha with Fractals Energy'] = { ctor: RSI_LAGUERRE, ShortName: 'RSI Laguerre', FullName: 'Self-Adjusting Alpha with Fractals Energy', DisplayGroup: 'Oscillators' };

        let tag = 'SUPER TREND';
        this.IndicatorStorage[tag] = { ctor: SUPERTREND, ShortNameLocKey: `${tag}.ShortName`, FullNameLocKey: `${tag}.FullName`, ShortName: tag, FullName: tag, DisplayGroup: 'Custom' };

        tag = 'EMA CROSS';
        this.IndicatorStorage[tag] = { ctor: EMACROSS, ShortNameLocKey: `${tag}.ShortName`, FullNameLocKey: `${tag}.FullName`, ShortName: tag, FullName: tag, DisplayGroup: 'Custom' };

        tag = 'MULTIMOV AVG';
        this.IndicatorStorage[tag] = { ctor: MULTIMOVAVG, ShortNameLocKey: `${tag}.ShortName`, FullNameLocKey: `${tag}.FullName`, ShortName: tag, FullName: tag, DisplayGroup: 'Custom' };

        tag = 'SUPERTREND MTV3';
        this.IndicatorStorage[tag] = { ctor: SUPERTRENDMTV3, ShortNameLocKey: `${tag}.ShortName`, FullNameLocKey: `${tag}.FullName`, ShortName: tag, FullName: tag, DisplayGroup: 'Custom' };
    };

    public GetIndicatorItemsList (): any[] {
        const itemsArray = [];

        const indObjs = Object.keys(this.IndicatorStorage);
        const len = indObjs.length;

        for (let i = 0; i < len; i++) {
            const cItem = this.IndicatorStorage[indObjs[i]];

            if (Resources.isHidden(cItem.FullName)) {
                continue;
            }

            itemsArray.push({ FullName: cItem.FullName, FullNameLocKey: cItem.FullNameLocKey, DisplayGroup: cItem.DisplayGroup, ShortName: cItem.ShortName, ShortNameLocKey: cItem.ShortNameLocKey });
        }
        return itemsArray;
    }

    // TODO. Ugly.
    public createBuiltInIndicator (name, args?, options?): any {
        const historicalData = options ? options.historicalData : null;
        const dataOnArrayFunc = options ? options.dataOnArrayFunc : null;

        const ind = iBuildInIndicatorFactory.CreateIndicator(name, args);
        if (ind && dataOnArrayFunc && historicalData?.Indicator) {
            ind.PriceData = new FuncWrapper(dataOnArrayFunc, historicalData.Indicator);
        }

        return ind;
    }

    // TODO.
    // #region Built-in Indicator

    // TODO. Ugly.
    public iMA (par0, par1, par2, par3, par4): any {
        if (par0 !== undefined &&
        par1 !== undefined &&
        par2 !== undefined &&
        par3 === undefined &&
        par4 === undefined) {
            return this.createBuiltInIndicator('iMA', [par1, par2]);
        }

        if (par0 !== undefined &&
        par1 !== undefined &&
        par2 !== undefined &&
        par3 !== undefined &&
        par4 !== undefined) {
            return this.createBuiltInIndicator('iMAEx', [par1, par2, par3, par4]);
        }
    }

    // TODO. Ugly.
    public iMAOnArray (historicalData, dataOnArrayFunc, period, mode): any {
        const ima = this.createBuiltInIndicator(
            'iMA',
            [period, mode],
            {
                historicalData,
                dataOnArrayFunc
            });

        return ima;
    }

    // TODO. Ugly.
    public iOBV (data, priceType): any {
        return this.createBuiltInIndicator('iOBV', [priceType]);
    }

    // TODO. Ugly.
    public iAD (data): any {
        return this.createBuiltInIndicator('iAD');
    }

    // TODO. Ugly.
    public iATR (data, maPeriod, maMode): any {
        return this.createBuiltInIndicator('iATR', [maPeriod, maMode]);
    }

    // TODO. Ugly.
    public iAO (data): any {
        return this.createBuiltInIndicator('iAO');
    }

    public iAC (data): any {
        return this.createBuiltInIndicator('iAC');
    }

    // TODO. Ugly.
    public iWPR (data, priceType): any {
        return this.createBuiltInIndicator('iWPR', [priceType]);
    }

    // TODO. Ugly.
    public iSTO (data, kPeriod, dPeriod, slowing, maMode, priceField): any {
        return this.createBuiltInIndicator('iSTO', [kPeriod, dPeriod, slowing, maMode, priceField]);
    }

    // TODO. Ugly.
    public iStdDev (data, period, maMethod, shift, priceType): any {
        return this.createBuiltInIndicator('iStdDev', [period, maMethod, shift, priceType]);
    }

    // TODO. Ugly.
    public iSAR (data, step, maximum): any {
        return this.createBuiltInIndicator('iSAR', [step, maximum]);
    }

    // TODO. Ugly.
    public iRVI (data, maPeriod, maMode): any {
        return this.createBuiltInIndicator('iRVI', [maPeriod, maMode]);
    }

    // TODO. Ugly.
    public iMFI (data, mfiPeriod): any {
        return this.createBuiltInIndicator('iMFI', [mfiPeriod]);
    }

    // TODO. Ugly.
    public iBearsPower (data, period, priceType): any {
        return this.createBuiltInIndicator('iBearsPower', [period, priceType]);
    }

    // TODO. Ugly.
    public iMACD (data, fastMaPeriod, slowMaPeriod, signalPeriod): any {
        return this.createBuiltInIndicator('iMACD', [fastMaPeriod, slowMaPeriod, signalPeriod]);
    }

    // TODO. Ugly.
    public iMom (data, momPeriod, priceType): any {
        return this.createBuiltInIndicator('iMom', [momPeriod, priceType]);
    }

    // TODO. Ugly.
    public iOsMA (data, fastMAPeriod, slowMaPeriod, signalPeriod, priceType): any {
        return this.createBuiltInIndicator('iOsMA', [fastMAPeriod, slowMaPeriod, signalPeriod, priceType]);
    }

    // TODO. Ugly.
    public iADX (data, maPeriod, priceType, maMode): any {
        return this.createBuiltInIndicator('iADX', [maPeriod, priceType, maMode]);
    }

    // TODO. Ugly.
    public iAlligator (data,
        jawPeriod, jawShift,
        teethPeriod, teethShift,
        lipsPeriod, lipsShift,
        maMode, priceType): any {
        return this.createBuiltInIndicator('iAlligator', [
            jawPeriod, jawShift,
            teethPeriod, teethShift,
            lipsPeriod, lipsShift,
            maMode, priceType]);
    }

    // TODO. Ugly.
    public iGator (data,
        jawPeriod, jawShift,
        teethPeriod, teethShift,
        lipsPeriod, lipsShift,
        maMode, priceType): any {
        return this.createBuiltInIndicator('iGator', [
            jawPeriod, jawShift,
            teethPeriod, teethShift,
            lipsPeriod, lipsShift,
            maMode, priceType]);
    }

    // TODO. Ugly.
    public iBands (data, maPeriod, maMode, deviation, bandsShift): any {
        return this.createBuiltInIndicator('iBands', [maPeriod, maMode, deviation, bandsShift]);
    }

    // TODO. Ugly.
    public iBullsPower (data, maPeriod, priceType): any {
        return this.createBuiltInIndicator('iBullsPower', [maPeriod, priceType]);
    }

    // TODO. Ugly.
    public iCCI (data, maPeriod, maMode): any {
        return this.createBuiltInIndicator('iCCI', [maPeriod, maMode]);
    }

    // TODO. Ugly.
    public iBWMFI (data): any {
        return this.createBuiltInIndicator('iBWMFI');
    }

    // TODO. Ugly.
    public iDeMarker (data, maPeriod): any {
        return this.createBuiltInIndicator('iDeMarker', [maPeriod]);
    }

    // TODO. Ugly.
    public iEnvelopes (data, maPeriod, maMethod, ma_shift, deviation, priceType): any {
        return this.createBuiltInIndicator('iEnvelopes', [maPeriod, maMethod, ma_shift, deviation, priceType]);
    }

    // TODO. Ugly.
    public iForce (data, maPeriod, maMethod, priceType): any {
        return this.createBuiltInIndicator('iForce', [maPeriod, maMethod, priceType]);
    }

    // TODO. Ugly.
    public iFractals (data): any {
        return this.createBuiltInIndicator('iFractals');
    }

    // TODO. Ugly.
    public iIchimoku (data, tenkanSen, kijunSen, senkouSpanB): any {
        return this.createBuiltInIndicator('iIchimoku', [tenkanSen, kijunSen, senkouSpanB]);
    }
// #endregion Built-in Indicator
}

// #region FuncWrapper

class FuncWrapper {
    private _func: any;
    private _indicator: any;

    constructor (func, indicator) {
        this._func = func;
        this._indicator = indicator;
    }

    // #region IDoubleVector Members
    public get (index): any {
        return this._func(this.Length - index - 1);
    }

    public set (index, value): void { }

    get Length (): number {
    // лайв стратегия имеет каунт 0, беру каунт кешайтема.
    // Для БТ стратегии они равны, а для индикатора нужно брать его каунт.
    // TODO. Strategy.
        return this._indicator.FCount;
    }

    // #endregion IDoubleVector Members

    public Dispose (): void {
        this._func = null;
        this._indicator = null;
    }
}

// #endregion FuncWrapper

export const IndicatorManager = new _IndicatorManager();
