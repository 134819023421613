export enum TooltipType {
    Connection = 0,
    Trade = 1,
    Refuse = 2,
    Chat = 3,
    RunTradingSystem = 4,
    StopTradingSystem = 5,
    System = 6,
    Idea = 7,
    AccountOperation = 8,
    CAInfo = 9, // #114238
    CAAccepted = 10,
    CARejected = 11,
    CACanceled = 12
}

export const TooltipTypeName = Object.keys(TooltipType);
