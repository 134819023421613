// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import EventEmitter from 'events';
import { type MarginInfoParameters } from './MarginInfoParameters';
import { MarginInfoGroup } from './MarginInfoGroup';
import { MarginInfoItem } from './MarginInfoItem';
import { CommissionOperationType, CommissionTypes } from '../../../Utils/Commission/CommissionEnums';
import { PriceFormatter } from '../../../Utils/Instruments/PriceFormatter';
import { type MarginInfoGroupType, MarginInfoItemType } from './MarginInfoEnums';
import { MarginInfoUtils } from './MarginInfoUtils';
import { QuotingType } from '../../../Utils/Instruments/QuotingType';
import { QuoteValid } from '../../../Utils/Quotes/QuoteValid';
import { AccountFeature } from '../../../Utils/Account/AccountFeature';
import { type Timeout } from 'react-number-format/types/types';
import { Account } from '../../cache/Account';
import { OperationType } from '../../../Utils/Trading/OperationType';

export class MarginInfoData {
    value: number;
    formattedValue: string;
    constructor (value: number, formattedValue: string) {
        this.value = value;
        this.formattedValue = formattedValue;
    }
}

export class MarginInfoWrapper {
    private readonly FULL_REFRESH_TIMER: number = 30000;
    private readonly TICK_REFRESH_TIMER: number = 500;

    private _isWorking: boolean = false;
    private _fullRefreshTimer: string | number | Timeout;
    private _tickRefreshTimer: string | number | Timeout;

    private readonly _eventEmitter: EventEmitter = new EventEmitter();
    private _message: any;

    public _parameters: MarginInfoParameters;

    constructor () {
        this.onFullRefresh = this.onFullRefresh.bind(this);
        this.onTickRefresh = this.onTickRefresh.bind(this);
        this.onSuccessRequest = this.onSuccessRequest.bind(this);
        this.onErrorRequest = this.onErrorRequest.bind(this);
    }

    dispose () { this.stop(); }
    run () {
        if (this._isWorking) { return; }
        this._isWorking = true;
        this._fullRefreshTimer = setInterval(this.onFullRefresh, this.FULL_REFRESH_TIMER);
        this._tickRefreshTimer = setInterval(this.onTickRefresh, this.TICK_REFRESH_TIMER);
        this.onFullRefresh();
    }

    stop () {
        if (this._fullRefreshTimer) {
            clearInterval(this._fullRefreshTimer);
            this._fullRefreshTimer = null;
        }
        if (this._tickRefreshTimer) {
            clearInterval(this._tickRefreshTimer);
            this._tickRefreshTimer = null;
        }
        this._isWorking = false;
    }

    private onFullRefresh () { this.fullRefresh(); }
    private onTickRefresh () { this.tickRefresh(); }

    subscribeToGroupUpdate (callback: (groups: MarginInfoGroup[]) => void) { this._eventEmitter.addListener('groupUpdate', callback); }
    unsubscribeFromGroupUpdate (callback: (groups: MarginInfoGroup[]) => void) { this._eventEmitter.removeListener('groupUpdate', callback); }
    subscribeToItemUpdate (callback: (groupType: MarginInfoGroupType, item: MarginInfoItem) => void) { this._eventEmitter.addListener('itemUpdate', callback); }
    unsubscribeFromItemUpdate (callback: (groupType: MarginInfoGroupType, item: MarginInfoItem) => void) { this._eventEmitter.removeListener('itemUpdate', callback); }

    public async getInfoAsync (): Promise<MarginInfoGroup[]> {
        try {
            const results = await this.sendMarginRequest();
            const params = this._parameters;
            const groups = this.ProcessRequest(results, params);
            return groups;
        } catch (error) {
            return [];
        }
    }

    public async GetTotalFeeFromMarginRequest (parameters: MarginInfoParameters): Promise<number> {
        if (isNullOrUndefined(parameters)) {
            return NaN;
        }

        const commissionOperationType = parameters.isLong ? CommissionOperationType.BUY : CommissionOperationType.SHORT;
        const msgArr = await parameters.instrument.DataCache.SendMarginRequest(parameters);
        if (isValidArray(msgArr)) {
            const lastMessage = msgArr[msgArr.length - 1];
            this._message = lastMessage;
            const totalFee = this.getTotalFeeValue(commissionOperationType);
            return totalFee;
        }

        return NaN;
    }

    public async GetAfterTradeCashFromMarginRequest (parameters: MarginInfoParameters, needNewRequest: boolean = false): Promise<number> {
        if (isNullOrUndefined(parameters)) {
            return NaN;
        }

        if (needNewRequest || isNullOrUndefined(this._message)) {
            const msgArr = await parameters.instrument.DataCache.SendMarginRequest(parameters);
            if (isValidArray(msgArr)) {
                const lastMessage = msgArr[msgArr.length - 1];
                this._message = lastMessage;
            }
        }

        if (!isNullOrUndefined(this._message.AfterTradeCash)) {
            return this._message.AfterTradeCash;
        }

        return NaN;
    }

    public getAfterTradeCash (): number {
        const message = this._message;
        if (isNullOrUndefined(message) || isNullOrUndefined(message.AfterTradeCash)) {
            return null;
        };

        return message.AfterTradeCash;
    }

    public fullRefresh () {
        const params = this._parameters;
        if (!params) { return; }
        this.sendMarginRequest()
            .then((results: any) => { this.onSuccessRequest(results, params); })
            .catch(this.onErrorRequest);
    }

    private async sendMarginRequest (): Promise<any> {
        const params = this._parameters;
        if (isNullOrUndefined(params)) {
            return;
        }
        return await params.instrument.DataCache.SendMarginRequest(params);
    }

    private onSuccessRequest (results: any, parameters: MarginInfoParameters): void {
        const groups: MarginInfoGroup[] = this.ProcessRequest(results, parameters);
        this._eventEmitter.emit('groupUpdate', groups);
    }

    private ProcessRequest (results: any, parameters: MarginInfoParameters): MarginInfoGroup[] {
        const lastMessage = results[results.length - 1];
        this._message = lastMessage;

        const groupDict = new Map<MarginInfoGroupType, MarginInfoGroup>();
        const itemTypes = Object.values(MarginInfoItemType);
        for (const element of itemTypes) {
            const itemType = Number(element);
            const data = this.getDataByType(itemType, parameters, lastMessage);

            if (!data) { continue; }

            const groupType = MarginInfoUtils.getGroupForItem(itemType);
            if (!groupDict.has(groupType)) {
                const group = new MarginInfoGroup();
                group.id = groupType;
                group.title = MarginInfoUtils.getGroupText(groupType, parameters.account.assetName);
                groupDict.set(groupType, group);
            }
            const group = groupDict.get(groupType);
            if (!group.items) { group.items = new Array<MarginInfoItem>(); }

            const item = new MarginInfoItem();
            item.id = itemType;
            item.isColored = MarginInfoUtils.isColoredItem(itemType);
            item.title = MarginInfoUtils.getItemText(itemType);
            item.formattedValue = data.formattedValue;
            item.value = data.value;
            group.items.push(item);
        }

        return Array.from(groupDict.values());
    }

    private onErrorRequest (error: any) {
        // TODO handle error
    }

    private tickRefresh () {
        const params = this._parameters;
        if (!params) { return; }

        const itemsByGroup = new Map<MarginInfoGroupType, MarginInfoItem[]>();
        const itemTypes = Object.values(MarginInfoItemType);
        for (const element of itemTypes) {
            const itemType = Number(element);
            if (MarginInfoUtils.isAccountFeatureType(itemType)) {
                const data = this.getDataByType(itemType, params, this._message);

                if (!data) { continue; }

                const group = MarginInfoUtils.getGroupForItem(itemType);
                if (!itemsByGroup.has(group)) { itemsByGroup.set(group, new Array<MarginInfoItem>()); }
                const items = itemsByGroup.get(group);
                const item = new MarginInfoItem();
                item.id = itemType;
                item.isColored = MarginInfoUtils.isColoredItem(itemType);
                item.title = MarginInfoUtils.getItemText(itemType);
                item.formattedValue = data.formattedValue;
                item.value = data.value;
                items.push(item);
            }
        }
        itemsByGroup.forEach((items, groupType) => {
            items.forEach(item => {
                this._eventEmitter.emit('itemUpdate', groupType, item);
            });
        });
    }

    private getDataByType (type: MarginInfoItemType, parameters: MarginInfoParameters, message: any): MarginInfoData | null {
        let value: number = NaN;
        let valueStr: string = '';

        const operationType = parameters.isLong ? CommissionOperationType.BUY : CommissionOperationType.SHORT;
        const side = parameters.isLong ? 'Buy' : 'Sell';

        switch (type) {
        case MarginInfoItemType.KEY_BALANCE:
            value = Account.GetAccountFeature(AccountFeature.Balance, parameters.account, parameters.account.assetBalanceDefault);
            valueStr = Account.GetAccountFeatureString(value, AccountFeature.Balance, parameters.account, parameters.account.assetBalanceDefault, null, true);
            return new MarginInfoData(value, valueStr);
        case MarginInfoItemType.KEY_AVAILABLE_FUNDS:
            value = Account.GetAccountFeature(AccountFeature.AvailableFunds, parameters.account, parameters.account.assetBalanceDefault);
            valueStr = Account.GetAccountFeatureString(value, AccountFeature.AvailableFunds, parameters.account, parameters.account.assetBalanceDefault, null, true);
            return new MarginInfoData(value, valueStr);
        case MarginInfoItemType.KEY_INCOMING_FUNDS:
            value = Account.GetAccountFeature(AccountFeature.IncomingFunds, parameters.account, parameters.account.assetBalanceDefault);
            valueStr = Account.GetAccountFeatureString(value, AccountFeature.IncomingFunds, parameters.account, parameters.account.assetBalanceDefault, null, true);
            return new MarginInfoData(value, valueStr);
        case MarginInfoItemType.KEY_MARGIN_AVAILABLE:
            value = Account.GetAccountFeature(AccountFeature.MarginAvailable, parameters.account, parameters.account.assetBalanceDefault);
            valueStr = Account.GetAccountFeatureString(value, AccountFeature.MarginAvailable, parameters.account, parameters.account.assetBalanceDefault, null, true);
            return new MarginInfoData(value, valueStr);
        case MarginInfoItemType.KEY_INIT_MARGIN:
            value = this.getValue(message, `InitMargin${side}`);
            return !isNaN(value) ? new MarginInfoData(value, parameters.account.formatPrice(value, true)) : null;
        case MarginInfoItemType.KEY_MAINT_MARGIN:
            value = this.getValue(message, `MaintMargin${side}`);
            return !isNaN(value) ? new MarginInfoData(value, parameters.account.formatPrice(value, true)) : null;
        case MarginInfoItemType.KEY_WARN_MARGIN:
            value = this.getValue(message, `WarnMargin${side}`);
            return !isNaN(value) ? new MarginInfoData(value, parameters.account.formatPrice(value, true)) : null;
        case MarginInfoItemType.KEY_IMPACT_ON_PORTFOLIO:
            value = this.getImpactOnProtfolioValue(this.getValue(message, `AfterTradeFunds${side}`), parameters.account);
            return !isNaN(value) ? new MarginInfoData(value, parameters.account.formatPrice(value, true)) : null;
        case MarginInfoItemType.KEY_AFTER_TRADE_FUNDS:
            value = this.getAfterTradeFundsValue(this.getValue(message, `AfterTradeFunds${side}`), operationType);
            return !isNaN(value) ? new MarginInfoData(value, parameters.account.formatPrice(value, true)) : null;
        case MarginInfoItemType.KEY_BLOCKED_FOR_STOCKS:
            value = Account.GetAccountFeature(AccountFeature.BlockedForStocks, parameters.account, parameters.account.assetBalanceDefault);
            valueStr = Account.GetAccountFeatureString(value, AccountFeature.BlockedForStocks, parameters.account, parameters.account.assetBalanceDefault, null, true);
            return new MarginInfoData(value, valueStr);
        case MarginInfoItemType.KEY_SPREAD_INIT_LOSS:
            value = this.getSpreadInitLossValue(parameters);
            valueStr = value ? parameters.account.formatPrice(value, true) : MarginInfoUtils.EMPTY_STRING;
            return new MarginInfoData(value, valueStr);
        case MarginInfoItemType.KEY_PL_PER_TICK:
            value = this.getPLPerTickValue(parameters);
            return !isNaN(value) ? new MarginInfoData(value, parameters.account.formatPrice(value, true)) : null;
        case MarginInfoItemType.KEY_ALLOW_SHORT_POSITIONS:
            valueStr = parameters.instrument.IsAllowShortPositions(parameters.productType) ? MarginInfoUtils.ALLOW_SHOW_POSITIONS : MarginInfoUtils.NOT_ALLOW_SHORT_POSITIONS;
            return new MarginInfoData(1, valueStr);
        case MarginInfoItemType.KEY_AFTER_TRADE_CASH:
            value = this.getAfterTradeCash();
            valueStr = !isNullOrUndefined(value) ? parameters.account.formatPrice(value) : MarginInfoUtils.EMPTY_STRING;
            return !isNullOrUndefined(value) ? new MarginInfoData(value, valueStr) : null;
        case MarginInfoItemType.KEY_FILL_PER_LOT:
            value = this.getAbsFeeValue(CommissionTypes.PerLot, operationType);
            return !isNaN(value) ? new MarginInfoData(value, this.formatValue(value)) : null;
        case MarginInfoItemType.KEY_ORDER_PER_LOT:
            value = this.getAbsFeeValue(CommissionTypes.OrderPerLot, operationType);
            return !isNaN(value) ? new MarginInfoData(value, this.formatValue(value)) : null;
        case MarginInfoItemType.KEY_PER_FILL:
            value = this.getAbsFeeValue(CommissionTypes.PerFill, operationType);
            return !isNaN(value) ? new MarginInfoData(value, this.formatValue(value)) : null;
        case MarginInfoItemType.KEY_PER_TRANSACTION:
            value = this.getAbsFeeValue(CommissionTypes.PerTransaction, operationType);
            return !isNaN(value) ? new MarginInfoData(value, this.formatValue(value)) : null;
        case MarginInfoItemType.KEY_PER_PHONE_TRANSACTION:
            // Unused
            return null;
        case MarginInfoItemType.KEY_VAT:
            value = this.getAbsFeeValue(CommissionTypes.VAT, operationType);
            return !isNaN(value) ? new MarginInfoData(value, this.formatValue(value)) : null;
        case MarginInfoItemType.KEY_FILL_VOLUME:
            value = this.getAbsFeeValue(CommissionTypes.PerVolume, operationType);
            return !isNaN(value) ? new MarginInfoData(value, this.formatValue(value)) : null;
        case MarginInfoItemType.KEY_ORDER_VOLUME:
            value = this.getAbsFeeValue(CommissionTypes.PerOrderVolume, operationType);
            return !isNaN(value) ? new MarginInfoData(value, this.formatValue(value)) : null;
        case MarginInfoItemType.KEY_FILL_VOLUME_WITH_MIN_PD:
            value = this.getAbsFeeValue(CommissionTypes.VolumeWithMinPD, operationType);
            return !isNaN(value) ? new MarginInfoData(value, this.formatValue(value)) : null;
        case MarginInfoItemType.KEY_SHORT_SWAP_INFO:
            valueStr = this.getValueStr(message, 'ShortSwapInfo');
            if (!isValidString(valueStr)) {
                return null;
            }
            return new MarginInfoData(NaN, this.formatValue(value, valueStr));
        case MarginInfoItemType.KEY_LONG_SWAP:
            value = this.getValue(message, 'SwapBuy');
            return new MarginInfoData(value, this.formatValue(value, MarginInfoUtils.EMPTY_STRING));
        case MarginInfoItemType.KEY_SHORT_SWAP:
            value = this.getValue(message, 'SwapSell');
            return new MarginInfoData(value, this.formatValue(value, MarginInfoUtils.EMPTY_STRING));
        case MarginInfoItemType.KEY_TOTAL_FEES:
            value = this.isTotalFeeVisible() ? this.getTotalFeeValue(operationType) : NaN;
            return !isNaN(value) ? new MarginInfoData(value, this.formatValue(Math.abs(value))) : null;
        default:
            return null;
        }
    }

    private getValue (message: any, propName: string): number {
        // for Init, Maint, Warn Margin fields
        let value = NaN;
        if (message?.hasOwnProperty(propName)) { value = parseFloat(message[propName]); }
        return value;
    }

    private getValueStr (message: any, propName: string): string {
        let value = '';
        if (message?.hasOwnProperty(propName)) { value = message[propName]; }
        return value;
    }

    private getAbsFeeValue (commissionType: CommissionTypes, operationMode: CommissionOperationType): number {
        const value = this.getFeeValue(commissionType, operationMode);
        if (value) { return Math.abs(value); } else { return value; }
    }

    private getFeeValue (commissionType: CommissionTypes, operationMode: CommissionOperationType): number {
        const commissionGroups = this.getCommissionGroups(commissionType, operationMode);
        if (!commissionGroups?.length) { return NaN; };

        let sumByOperationMode = 0;
        for (const element of commissionGroups) { sumByOperationMode += element.Amount; };

        return sumByOperationMode;
    }

    private getTotalFeeValue (operationMode: CommissionOperationType): number {
        let totalFee = 0;
        for (const commissionType in CommissionTypes) {
            const commissionTypeNumber = Number(commissionType);
            if (isNaN(commissionTypeNumber)) { continue; }
            const value = this.getFeeValue(commissionTypeNumber, operationMode);
            if (value) {
                totalFee += value;
            }
        }
        return totalFee;
    }

    private formatValue (value: number, defaultValue: string = '') {
        if (isNaN(value)) { return defaultValue; }
        return PriceFormatter.formatPrice(value, 2);
    }

    private getSpreadInitLossValue (parameters: MarginInfoParameters): number {
        const instrument = parameters.instrument;
        const account = parameters.account;
        const amountLots = parameters.amountLots;
        const spreadPlan = account?.DataCache.GetSpreadPlan(account);
        const side = parameters.isLong ? OperationType.Buy : OperationType.Sell;

        if (!account || !instrument || !amountLots || !instrument.LastQuote) { return NaN; }

        const crossPrice = account?.DataCache.CrossRateCache.GetCrossPriceInsSideExp2(instrument, side, account.BaseCurrency);
        const ask = instrument.LastQuote.AskSpread_SP_Ins(spreadPlan, instrument);
        const bid = instrument.LastQuote.BidSpread_SP_Ins(spreadPlan, instrument);

        let result = -(ask - bid) * amountLots * crossPrice;
        result *= instrument.getLotSize();
        result *= instrument.GetTickCost();

        return result && result !== 0 ? result : NaN;
    }

    private getPLPerTickValue (parameters: MarginInfoParameters): number {
        const instrument = parameters.instrument;
        const account = parameters.account;
        const amountLots = parameters.amountLots;
        const side = parameters.isLong ? OperationType.Buy : OperationType.Sell;

        if (!account || !instrument || !amountLots) { return NaN; }

        const crossPrice = account?.DataCache.CrossRateCache.GetCrossPriceInsSideExp2(instrument, side, account.BaseCurrency);
        let result: number;
        if (MarginInfoUtils.TICKCOST_INS_TYPES.includes(instrument.InstrType) &&
            instrument.QuotingType === QuotingType.TickCost_TickSize) {
            result = instrument.FuturesTickCoast * amountLots * crossPrice;
        } else {
            let price = parameters.limitPrice || parameters.stopPrice;
            if (!price) {
                const quote = instrument.GetLastQuote(QuoteValid.Valid);
                const isLong = parameters.isLong;
                if (quote) { price = isLong ? quote.Ask : quote.Bid; };
            }
            const tickSize = instrument.LotSize * instrument.GetPointSize(price);
            result = tickSize * amountLots * crossPrice;
        }
        return result;
    }

    private getAfterTradeFundsValue (afterTradeFunds: number, operationType: CommissionOperationType): number {
        if (afterTradeFunds) {
            const absTotalFee = Math.abs(this.getTotalFeeValue(operationType));
            return afterTradeFunds - absTotalFee;
        }
        return NaN;
    }

    private getImpactOnProtfolioValue (afterTradeFunds: number, account: Account): number {
        if (!afterTradeFunds) { return NaN; }
        const availableFunds = Account.GetAccountFeature(AccountFeature.AvailableFunds, account, account.assetBalanceDefault);
        return afterTradeFunds - availableFunds;
    }

    private getCommissionGroups (commissionType: CommissionTypes, operationMode: CommissionOperationType): any {
        const message = this._message;
        if (!message?.CommissionsByOperationMode) { return null; };

        const commissionGroups = message.CommissionsByOperationMode[operationMode];
        const resultArr = [];
        if (commissionGroups) {
            for (const element of commissionGroups) {
                const commissionGroup = element;
                if (commissionGroup.CommissionType == commissionType) { resultArr.push(commissionGroup); }
            }
        }
        return resultArr;
    }

    private isTotalFeeVisible (): boolean {
        const operationMode = CommissionOperationType.BUY;
        let comissionTypesCount = 0;
        const commissionTypes = Object.values(CommissionTypes);
        for (const commissionType in commissionTypes) {
            const value = this.getFeeValue(Number(commissionType), operationMode);
            if (value) {
                comissionTypesCount++;
            }
            if (comissionTypesCount > 1) { return true; }
        }
        return false;
    }
}
