// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectNonFixedInstumentListResponseMessage, NonFixedInstrumentInfo } from '../../../../Utils/DirectMessages/DirectNonFixedInstumentListResponseMessage';
import { AliasesGroup, OptionContractGroup } from '../Groups/GroupsImport';
import { InstrumentMessageProcessor } from './InstrumentMessageProcessor';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { OptionContract } from '../../../../Utils/Instruments/OptionContract';
import { InstrumentTypes } from '../../../../Utils/Instruments/InstrumentTypes';
import { InstrDateSettings } from '../../../../Utils/Instruments/InstrDateSettings';

export class NonFixedInstumentListResponseMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectNonFixedInstumentListResponseMessage[] {
        const message = new DirectNonFixedInstumentListResponseMessage();

        const instrumentInfogroups = fieldSet.GetGroups(FieldsFactory.INSTRUMENT_INFO_GROUP);
        for (let i = 0, len = instrumentInfogroups.length; i < len; i++) {
            const infoGroup = instrumentInfogroups[i];
            const nonFixedInfo = new NonFixedInstrumentInfo();

            nonFixedInfo.ID = infoGroup.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID).toString();

            const TradableId = infoGroup.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
            nonFixedInfo.InstrumentTradableID = TradableId;

            nonFixedInfo.Name = infoGroup.GetValue(FieldsFactory.FIELD_NAME);
            nonFixedInfo.InstrType = infoGroup.GetValue(FieldsFactory.FIELD_TYPE);
            nonFixedInfo.GroupId = infoGroup.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID);
            nonFixedInfo.ExchangeId = infoGroup.GetValue(FieldsFactory.FIELD_EXCHANGE_ID);
            nonFixedInfo.TradingExchange = infoGroup.GetValue(FieldsFactory.FIELD_TRADING_EXCHANGE);
            nonFixedInfo.MarketDataExchange = infoGroup.GetValue(FieldsFactory.FIELD_MARKET_DATA_EXCHANGE);
            nonFixedInfo.CountryId = infoGroup.GetValue(FieldsFactory.FIELD_INSTRUMENT_COUNTRY);
            nonFixedInfo.Underlier = infoGroup.GetValue(FieldsFactory.FIELD_UNDERLIER);
            nonFixedInfo.UnderlierContractTradableId = infoGroup.GetValue(FieldsFactory.FIELD_UNDERLIER_CONTRACT_TRADABLE_INSTRUMENT_ID);
            nonFixedInfo.UnderlierDescr = infoGroup.GetValue(FieldsFactory.FIELD_UNDERLIER_DESCRIPTION);
            nonFixedInfo.UnderlierCountryId = infoGroup.GetValue(FieldsFactory.FIELD_UNDERLIER_COUNTRY_ID);
            nonFixedInfo.UnderlierTradingExchange = infoGroup.GetValue(FieldsFactory.FIELD_UNDERLIER_TRADING_EXCHANGE);
            nonFixedInfo.PointSize = infoGroup.GetValue(FieldsFactory.FIELD_POINTSIZE);
            nonFixedInfo.UseOptionDescription = infoGroup.GetValue(FieldsFactory.FIELD_OPTIONS_USE_DESC);
            nonFixedInfo.LanguageAliases = AliasesGroup.GetFromFieldSet(infoGroup);
            // #63673
            nonFixedInfo.UnderlierID = infoGroup.GetValue(FieldsFactory.FIELD_BASE_INSTRUMENT_ID);
            nonFixedInfo.Routes = [];

            const routes = infoGroup.GetGroups(FieldsFactory.ROUTE_ID_GROUP);
            for (let j = 0, j_len = routes.length; j < j_len; j++) {
                const rt = routes[j];
                nonFixedInfo.Routes.push(rt.GetValue(FieldsFactory.FIELD_ROUTE_ID));
            }
            // #84094
            nonFixedInfo.Description = infoGroup.GetValue(FieldsFactory.FIELD_DESCRIPTION);

            // !не кешировать. будет неверная тайм зона
            nonFixedInfo.InstrDateSettingsList = InstrumentMessageProcessor.ParseGroupsFromMessage(
                infoGroup, this.decoder.TimeZoneId, nonFixedInfo.PointSize);

            if (nonFixedInfo.InstrDateSettingsList.length === 0 &&
                    nonFixedInfo.InstrType === InstrumentTypes.OPTIONS &&
                    isValidNumber(nonFixedInfo.UnderlierContractTradableId) &&
                    isValidString(nonFixedInfo.Underlier)) {
                nonFixedInfo.InstrDateSettingsList = [];
                const fakeDs = new InstrDateSettings();
                fakeDs.UnderlierTradableId = nonFixedInfo.UnderlierContractTradableId;
                fakeDs.Underlier = nonFixedInfo.Underlier;
                nonFixedInfo.InstrDateSettingsList.push(fakeDs);
            }

            message.NonFixedInstrumentInfoList.push(nonFixedInfo);
        }

        const optionContractList = OptionContractGroup.GetNewOptionContractGroup(fieldSet);
        if (optionContractList && (optionContractList.length > 0)) {
            for (let i = 0, len = message.NonFixedInstrumentInfoList.length; i < len; i++) {
                const nonFixedInfo = message.NonFixedInstrumentInfoList[i];
                for (let j = 0, j_len = nonFixedInfo.InstrDateSettingsList.length; j < j_len; j++) {
                    const ids = nonFixedInfo.InstrDateSettingsList[j];
                    OptionContract.MergeOptionContractGroup(ids, optionContractList);
                }
            }
        }

        return [message];
    }
}
