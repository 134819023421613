// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterDouble } from '../InputParamaterClasses/InputParameterDouble';
import { InputParameterBoolean } from '../InputParamaterClasses/InputParameterBoolean';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class RSI_LAGUERRE extends IndicatorScriptBase {
    public alpha: number;
    public highlightCrossovers: boolean;
    public applyFractalsEnergy: boolean;
    public feLength: number;
    public applyNormalization: boolean;
    public src: number;

    private L0: number[];
    private L1: number[];
    private L2: number[];
    private L3: number[];
    private ob: number;
    private middle: number;
    private os: number;

    constructor () {
        super();
        this.ProjectName = 'Self-Adjusting Alpha with Fractals Energy';
        this.SetIndicatorLine('alpha line', Color.CornflowerBlue, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('fe alpha line', Color.Purple, 1, LineStyle.SimpleChart);

        this.SetIndicatorLine('Overbought', Color.Red, 5, LineStyle.HorizontalLine);
        this.SetIndicatorLine('Oversold', Color.Lime, 5, LineStyle.HorizontalLine);

        this.SeparateWindow = true;

        this.alpha = 0.7;
        super.InputParameter(new InputParameterDouble('alpha', 'Alpha:', 0, 0.1, 99999, 1, 0.1));

        this.highlightCrossovers = true;
        super.InputParameter(new InputParameterBoolean('highlightCrossovers', 'Highlight Bands Crossovers ?', 1));

        this.applyFractalsEnergy = true;
        super.InputParameter(new InputParameterBoolean('applyFractalsEnergy', 'Apply Fractals Energy ?', 2));

        this.feLength = 13;
        super.InputParameter(new InputParameterInteger('feLength', 'Fractals Energy Length:', 3, 1, 99999));

        this.applyNormalization = true;
        super.InputParameter(new InputParameterBoolean('applyNormalization', 'Apply Normalization to [0, 100] ?', 4));

        this.src = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('src', 'Source prices', 0,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
        this.L0 = [0, 0];
        this.L1 = [0, 0];
        this.L2 = [0, 0];
        this.L3 = [0, 0];

        this.ob = 0;
        this.middle = 0;
        this.os = 0;
    }

    public override GetIndicatorShortName (): string {
        return 'RSI_LAGUERRE(' + this.feLength + ')';
    };

    public override getCustomName (): string {
        return 'RSI_LAGUERRE (' + this.ProjectName + ')';
    };

    public Init (): void {
        const mult = (this.applyNormalization ? 100 : 1);

        this.ob = 0.8 * mult;
        this.middle = 0.5 * mult;
        this.os = 0.2 * mult;

        this.SetLevelLine('OB', this.ob, Color.Gray, 1, LineStyle.SimpleChart);
        this.SetLevelLine('MIDDLE', this.middle, Color.Gray, 1, LineStyle.SimpleChart);
        this.SetLevelLine('OS', this.os, Color.Gray, 1, LineStyle.SimpleChart);

        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public async RefreshPromise (): Promise<any> {
        await super.RefreshPromise()
            .then(async () => {
                this.L0 = [0, 0];
                this.L1 = [0, 0];
                this.L2 = [0, 0];
                this.L3 = [0, 0];
                await Promise.resolve();
            });
    };

    public ParametersRecalculationCallBack (): void {
        const mult = (this.applyNormalization ? 100 : 1);

        this.ob = 0.8 * mult;
        this.middle = 0.5 * mult;
        this.os = 0.2 * mult;

        this.UpdateIndicatorLevelLineValueHandler(4, this.ob); // TODO: UpdateIndicatorLevelLineValueHandler присваюється в Indicator
        this.UpdateIndicatorLevelLineValueHandler(5, this.middle);
        this.UpdateIndicatorLevelLineValueHandler(6, this.os);
    };

    public OnQuote (): void {
        super.OnQuote();
    };

    public Lowest (index): any {
        let element = Number.MAX_VALUE;

        for (let i = index; i < index + this.feLength; i++) {
            const temp = element > this.Low(i) ? this.Low(i) : element;
            element = temp;
        }

        return element;
    };

    public Highest (index): number {
        let element = Number.MIN_VALUE;

        for (let i = index; i < index + this.feLength; i++) {
            const temp = element < this.High(i) ? this.High(i) : element;
            element = temp;
        }

        return element;
    };

    public High (i): number {
        return this.CurrentData.GetPrice(PriceType.High, i);
    };

    public Low (i): number {
        return this.CurrentData.GetPrice(PriceType.Low, i);
    };

    public Sum (x, length): number {
        let sum = 0;
        for (let z = 0; z < length; z++) { sum += x(z); }

        return sum;
    };

    public HC (index): number {
        return Math.max(this.CurrentData.GetPrice(PriceType.High, index), this.CurrentData.GetPrice(PriceType.Close, index + 1));
    };

    public LC (index): number {
        return Math.min(this.CurrentData.GetPrice(PriceType.Low, index), this.CurrentData.GetPrice(PriceType.Close, index + 1));
    };

    public NextBar (): void {
        super.NextBar();

        if (this.CurrentData.Count - 1 < this.feLength * 2) {
            return;
        }

        const OC = (this.CurrentData.GetPrice(PriceType.Open) + this.CurrentData.GetPrice(PriceType.Close, 1)) / 2;
        const HC = this.HC(0);
        const LC = this.LC(0);

        const feSrc = (OC + HC + LC + this.CurrentData.GetPrice(PriceType.Close)) / 4;

        const feAlpha = Math.log(this.Sum((index) => { return (this.HC(index) - this.LC(index)) / (this.Highest(index) - this.Lowest(index)); }, this.feLength)) / Math.log(this.feLength);

        const lrsiAlpha = this.applyFractalsEnergy ? feAlpha : this.alpha;

        this.L0[1] = this.L0[0];
        this.L0[0] = lrsiAlpha * (this.applyFractalsEnergy ? feSrc : this.CurrentData.GetPrice(this.src)) + (1 - lrsiAlpha) * this.L0[1];

        this.L1[1] = this.L1[0];
        this.L1[0] = -(1 - lrsiAlpha) * this.L0[0] + this.L0[1] + (1 - lrsiAlpha) * this.L1[1];

        this.L2[1] = this.L2[0];
        this.L2[0] = -(1 - lrsiAlpha) * this.L1[0] + this.L1[1] + (1 - lrsiAlpha) * this.L2[1];

        this.L3[1] = this.L3[0];
        this.L3[0] = -(1 - lrsiAlpha) * this.L2[0] + this.L2[1] + (1 - lrsiAlpha) * this.L3[1];

        let CU = 0.0;
        CU = (this.L0[0] >= this.L1[0] ? this.L0[0] - this.L1[0] : 0) + (this.L1[0] >= this.L2[0] ? this.L1[0] - this.L2[0] : 0) + (this.L2[0] >= this.L3[0] ? this.L2[0] - this.L3[0] : 0);

        let CD = 0.0;
        CD = (this.L0[0] >= this.L1[0] ? 0 : this.L1[0] - this.L0[0]) + (this.L1[0] >= this.L2[0] ? 0 : this.L2[0] - this.L1[0]) + (this.L2[0] >= this.L3[0] ? 0 : this.L3[0] - this.L2[0]);

        const lrsi = CU + CD != 0
            ? this.applyNormalization ? 100 * CU / (CU + CD) : CU / (CU + CD)
            : 0;

        this.SetValue(0, lrsi);

        lrsi > this.ob ? null : lrsi < this.os ? this.SetMarker(0, Color.Red) : this.SetMarker(0, Color.Lime);

        if (lrsi < this.ob && this.GetValue(0, 1) > this.ob) {
            this.SetValue(2, this.ob);
        } else if (lrsi > this.os && this.GetValue(0, 1) < this.os) {
            this.SetValue(3, this.os);
        }

        if (this.applyFractalsEnergy) {
            this.SetValue(1, this.applyNormalization ? 100 * feAlpha : feAlpha);
        }
    };
}
