// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DirectAlgorithmMessageBase } from './DirectAlgorithmMessageBase';
import { Message } from '../Message';
import { type MarketConsensusData } from '../../../Commons/cache/MarketConsensus';

export class DirectAlgorithmMarketConsensusMessage extends DirectAlgorithmMessageBase {
    constructor () {
        super(Message.CODE_PFIX_ALGORITHM_MARKET_CONSENSUS_MESSAGE);
    }

    TopLongList: MarketConsensusData[] = [];
    TopShortList: MarketConsensusData[] = [];
}
