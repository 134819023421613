import { type SessionSettings } from '../../../Commons/SessionSettings';
import { GreeksFormatter } from '../../../Commons/cache/OptionMaster/OptionTrader/GreeksFormatter';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { type Position } from '../../../Commons/cache/Position';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { type ColumnData } from '../BaseItem';
import { type ColumnParams } from '../ColumnParams';
import { PositionItem } from '../PositionItem';

export class OptionPositionItem extends PositionItem {
    private readonly _optionTrader: OptionTrader;
    constructor (optionTrader: OptionTrader, position: Position, sessionSettings: typeof SessionSettings, isNetQty?: boolean) {
        super(position, sessionSettings, isNetQty);
        this._optionTrader = optionTrader;
    }

    public override GetColumnParams (column: number): ColumnParams {
        const columnParams = super.GetColumnParams(column);
        switch (column) {
        case PositionItem.DELTA_COL_INDEX:
        case PositionItem.GAMMA_COL_INDEX:
        case PositionItem.VEGA_COL_INDEX:
        case PositionItem.THETA_COL_INDEX:
        case PositionItem.IV_COL_INDEX:
        case PositionItem.RHO_COL_INDEX:
            columnParams.Hidden = false;
            columnParams.ShowTotal = true;
            break;
        }
        return columnParams;
    }

    public override getColumnValue (column: number, useAccCurrency: boolean = null): any {
        let value = super.getColumnValue(column, useAccCurrency);
        const sign = this.FPosition.BuySell === OperationType.Buy ? 1 : -1;
        switch (column) {
        case PositionItem.DELTA_COL_INDEX:
            value = GreeksFormatter.roundGreek(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument, this.FPosition.CurPriceClose).delta * this.FPosition.Amount * sign);
            break;
        case PositionItem.GAMMA_COL_INDEX:
            value = GreeksFormatter.roundGreek(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument, this.FPosition.CurPriceClose).gamma * this.FPosition.Amount * sign);
            break;
        case PositionItem.VEGA_COL_INDEX:
            value = GreeksFormatter.roundGreek(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument, this.FPosition.CurPriceClose).vega * this.FPosition.Amount * sign);
            break;
        case PositionItem.THETA_COL_INDEX:
            value = GreeksFormatter.roundGreek(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument, this.FPosition.CurPriceClose).theta * this.FPosition.Amount * sign);
            break;
        case PositionItem.IV_COL_INDEX:
            value = GreeksFormatter.roundIV(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument, this.FPosition.CurPriceClose).ivPercent);
            break;
        case PositionItem.RHO_COL_INDEX:
            value = GreeksFormatter.roundGreek(this._optionTrader.getGreeks(this.FPosition.Instrument.PutCall, this.FPosition.Instrument, this.FPosition.CurPriceClose).rho * this.FPosition.Amount * sign);
            break;
        }
        return value;
    }

    public override getColumnData (column: number): ColumnData {
        const data = super.getColumnData(column);
        switch (column) {
        case PositionItem.DELTA_COL_INDEX:
        case PositionItem.GAMMA_COL_INDEX:
        case PositionItem.VEGA_COL_INDEX:
        case PositionItem.THETA_COL_INDEX:
        case PositionItem.RHO_COL_INDEX:
            data.FormattedValue = GreeksFormatter.formatGreek(data.Value);
            break;

        case PositionItem.IV_COL_INDEX:
            data.FormattedValue = GreeksFormatter.formatIV(data.Value);
            break;
        }
        return data;
    }

    public override getFormatTotalValue (column: number, value: number, precisionMode: boolean = false, totalCurrencyAfterFiltration: string = null): string | number {
        switch (column) {
        case PositionItem.DELTA_COL_INDEX:
        case PositionItem.GAMMA_COL_INDEX:
        case PositionItem.VEGA_COL_INDEX:
        case PositionItem.THETA_COL_INDEX:
        case PositionItem.RHO_COL_INDEX:
            return precisionMode ? GreeksFormatter.GREEKS_PERCISION : GreeksFormatter.formatGreek(value);
        case PositionItem.IV_COL_INDEX:
            return precisionMode ? GreeksFormatter.IV_PERCISION : GreeksFormatter.formatIV(value);
        default:
            return super.getFormatTotalValue(column, value, precisionMode, totalCurrencyAfterFiltration);
        }
    }
}
