// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MathUtils } from './MathUtils';
import { type OperationType } from './Trading/OperationType';
import { OrderUtils } from './Trading/OrderUtils';

export class SLTPTrigger {
    public BuyTPbyBid: any = null;
    public SellTPbyBid: any = null;
    public BuySLbyBid: any = null;
    public SellSLbyBid: any = null;
    public SelectSLTPTriggerInOE = false;

    constructor (defaultValue: any = undefined) {
        this.FillFromShort(defaultValue);
    }

    public GetCopy (): SLTPTrigger {
        const keys = SLTPTrigger.PROP_KEYS_FOR_SHORTNING;
        const selfCopy = new SLTPTrigger();

        for (const key of keys) {
            selfCopy[key] = this[key];
        }

        return selfCopy;
    }

    public CopyValueFrom (sltpTrigger: SLTPTrigger): void {
        if (!sltpTrigger) return;

        const keys = SLTPTrigger.PROP_KEYS_FOR_SHORTNING;
        for (const key of keys) {
            this[key] = sltpTrigger[key];
        }
    }

    public GetShort (): number // переводим состояние всех радиобатонов и комбобокса из Settings в натуральное число
    {
        const keys = SLTPTrigger.PROP_KEYS_FOR_SHORTNING;
        let result = 0;

        for (let i = 0; i < keys.length; i++) {
            result += (this[keys[i]] ? 1 : 0) * SLTPTrigger.POWERS_OF_TWO[i];
        }

        return result;
    }

    public FillFromShort (short: number): SLTPTrigger // переводим состояние всех радиобатонов и комбобокса в Settings из числа=SLTPTrigger.GetShort() в обьект SLTPTrigger с соотв. сост. свойств из PROP_KEYS_FOR_SHORTNING
    { // возвращает указатель this
        if (MathUtils.IsNullOrUndefined(short)) {
            return this;
        }

        const keys = SLTPTrigger.PROP_KEYS_FOR_SHORTNING;
        let keyInd = keys.length - 1;

        while (short) {
            const p = SLTPTrigger.POWERS_OF_TWO[keyInd];
            this[keys[keyInd--]] = short >= p;
            if (short >= p) {
                short -= p;
            }
        }

        while (keyInd >= 0) {
            this[keys[keyInd--]] = false;
        }

        return this;
    }

    public GetShortString (): string // промежуточно хранил в монго как // return this.BuyTPbyBid + '' + this.SellTPbyBid + '' + this.BuySLbyBid + '' + this.SellSLbyBid // OLD WAY
    { // оставлю для дебага
        const keys = SLTPTrigger.PROP_KEYS_FOR_SHORTNING; let res = '';
        for (const key of keys) {
            res += this[key] ? '1' : '0';
        }

        return res;
    }

    public FillFromShortString (str): SLTPTrigger // возвращает указатель this
    {
        const keys = SLTPTrigger.PROP_KEYS_FOR_SHORTNING;

        if (!str || str.length != keys.length) {
            return this;
        }

        for (let i = 0; i < keys.length; i++) {
            this[keys[i]] = str[i] == '1';
        }

        return this;
    }

    public static CanSelectInOE (short): boolean {
        return short > 15;
    } // получить значение комбобокса SelectSLTPTriggerInOE из shortValue (поскольку если SelectSLTPTriggerInOE=true, то short >= 2^4=16, так как за значение SelectSLTPTriggerInOE отвечает пятая цифра в двоичном представлении short; можно нагляднее но гораздо медлительнее:// return (new SLTPTrigger()).FillFromShort(short).SelectSLTPTriggerInOE

    public static CreateFromShortString (str): SLTPTrigger {
        const tr = new SLTPTrigger();
        return tr.FillFromShortString(str);
    }

    public static CreateFromShort (short): SLTPTrigger {
        const tr = new SLTPTrigger();
        return tr.FillFromShort(short);
    }

    public static GetDefaultValueForProp (isBuy/*, isSL */): boolean {
        return !isBuy; // Buy TP/SL trigger price - ASK ; Sell TP/SL trigger price - BID
    }

    // returns "SL" or "TP" depending on isForSL value
    public static GetSLTPStr (isSL): string {
        return isSL ? 'SL' : 'TP';
    }

    // returns "Buy" or "Sell" depending on the side
    public static GetSideStr (side: OperationType): string {
        return OrderUtils.getBuySellStr(side);
    }

    public static GetPropertyName (side, isSL): string // соответствующее side и isSL свойство из SLTPTrigger обьекта
    {
        return SLTPTrigger.GetSideStr(side) + SLTPTrigger.GetSLTPStr(isSL) + 'byBid';
    }

    public static GetReversed (short): number // за замовченням для Buy ордера треба брати SellSL/TPbyBid, для Sell ордера - BuySL/TPbyBid
    { // тому використовуємо цей метод для отримання значень зворотної сторони
        const tr = SLTPTrigger.CreateFromShort(short);
        const rTr = new SLTPTrigger();

        rTr.SelectSLTPTriggerInOE = true; // в інших місцях валідується видимість RB, а тут завжди треба true бо інакше буде як в #110842 //tr.SelectSLTPTriggerInOE
        rTr.BuySLbyBid = tr.SellSLbyBid;
        rTr.SellSLbyBid = tr.BuySLbyBid;
        rTr.BuyTPbyBid = tr.SellTPbyBid;
        rTr.SellTPbyBid = tr.BuyTPbyBid;

        return rTr.GetShort();
    }

    public static GetRawValue (short, side, isSL): number | null {
        const tr = SLTPTrigger.CreateFromShort(short);
        const propName = SLTPTrigger.GetPropertyName(side, isSL);

        if (!MathUtils.IsNullOrUndefined(tr[propName]) /* && tr.SelectSLTPTriggerInOE */) {
            return tr[propName]
                ? SLTPTrigger.RAW_VALUE_TO_SERVER.BY_BID
                : SLTPTrigger.RAW_VALUE_TO_SERVER.BY_ASK;
        }

        return null;
    }

    public static ConvertBidAskRawValueToStr (val): string | null {
        const vars = SLTPTrigger.RAW_VALUE_TO_SERVER;
        switch (val) {
        case vars.BY_ASK:
            return 'Ask';
        case vars.BY_BID:
            return 'Bid';
        default:
            return null;
        }
    }

    public static GetTextValue (short, side, isSL): string // returns string 'Bid' or 'Ask' depending on args (e. g. for Event log request msg)
    {
        const rV = SLTPTrigger.GetRawValue(short, side, isSL);
        return SLTPTrigger.ConvertBidAskRawValueToStr(rV);
    }

    public static readonly RAW_VALUE_TO_SERVER = { BY_ASK: 1, BY_BID: 2 };
    public static readonly HIDDEN_DYN_PROPERTY_NAME = 'SLTPTrgShort';
    public static readonly BUY_TP_DYN_PROPERTY_NAME = 'property.BuyTPTrigger';
    public static readonly BUY_SL_DYN_PROPERTY_NAME = 'property.BuySLTrigger';
    public static readonly SELL_TP_DYN_PROPERTY_NAME = 'property.SellTPTrigger';
    public static readonly SELL_SL_DYN_PROPERTY_NAME = 'property.SellSLTrigger';
    public static readonly TRIGGER_IN_OE_DYN_PROPERTY_NAME = 'property.SLTPTriggerInOE';
    public static readonly PROP_KEYS_FOR_SHORTNING = ['BuyTPbyBid', 'SellTPbyBid', 'BuySLbyBid', 'SellSLbyBid', 'SelectSLTPTriggerInOE'];
    public static readonly POWERS_OF_TWO = [1, 2, 4, 8, 16, 32, 64]; // 32=2^5 - самое большое составляющее - значение комбобокса SelectSLTPTriggerInOE
}
