// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export class AccountWidgetCoordinates {
    public positionX: number;
    public positionY: number;

    constructor (positionX: number, positionY: number) {
        this.positionX = positionX;
        this.positionY = positionY;
    }
}
