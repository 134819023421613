import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { HeatmapPanelTemplate } from '../../templates.js';
import { type HeatmapRactive } from '../elements/Canvas/HeatmapRactive';
import { type HeatMapDrawer } from '../elements/Canvas/HeatmapDrawer';
import { HeatmapSymbolTitleBy, HeatmapSymbolFirstValue, HeatmapSymbolSecondValue, HeatmapSymbolSizeBy, HeatmapSymbolColorBy } from '../../Commons/Heatmap/Enums';
import { HeatmapData } from '../../Commons/Heatmap/Models/HeatmapData';
import { DataCache } from '../../Commons/DataCache';
import { type Instrument } from '../../Commons/cache/Instrument';
import { CustomInstrumentListTypeEnum } from '../../Utils/CustomInstrumentList/CustomInstrumentListTypeEnum';
import { type DirectCustomListMessage } from '../../Utils/DirectMessages/DirectCustomListMessage';
import { type HeatmapSymbolInfo } from './Heatmap/HeatmapSymbolInfo';
import { type PartialInterval } from '../../Commons/Heatmap/PartialIntervals/PartialInterval';
import { contextMenuHandler } from '../../Utils/AppHandlers';
import { PanelSettingsScreen } from '../screen/PanelSettingsScreen';
import { Resources } from '../../Commons/properties/Resources';
import { DynProperty, DynPropertyRelationType, TrioColor } from '../../Commons/DynProperty';
import { type IHeatmapViewSettings } from '../../Commons/Heatmap/Settings/IHeatmapViewSettings';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { LinkedSystem } from '../misc/LinkedSystem';
import { CustomerAccess } from '../../Commons/CustomerAccess/CustomerAccess';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { SessionSettings } from '../../Commons/SessionSettings';
import { ApplicationPanel } from './ApplicationPanel';
import { type CustomInstrumentList } from '../../Commons/cache/CustomInstrumentList';

export class HeatmapPanel extends ApplicationPanel {
    private static readonly HEADER_LIST_ITEMS_COUNT = 5;
    private _selectedListId: number = undefined;
    private _callbackProperties: DynProperty[];
    private _heatmapDrawer: HeatMapDrawer;
    private readonly _marketCapCache = new Map<Instrument, number>();

    constructor () {
        super();

        this.Name = 'HeatmapPanel';
        this.headerLocaleKey = 'panel.HeatmapPanel';
    }

    getType (): PanelNames { return PanelNames.HeatmapPanel; }

    oninit (): void {
        super.oninit();
        super.on('onListItemClicked', this.listItemClicked);
        super.on('scaleFilterChanged', this.onScaleFilterChanged);
        super.on('scaleMultiplierChanged', this.onScaleMultiplierChanged);
        super.on('onMoreButtonClicked', this.onMoreButtonClick);
        super.observe('selectedListItem', this.selectedListItemChanged);
    }

    onteardown (): void {
        DataCache.OnUpdateInstrument.UnSubscribe(this.onUpdateInstrument, this);
        this._heatmapDrawer.unsubscribeOnHoveredSymbolChanged(this.onHoveredSymbolChanged);
        this._heatmapDrawer.unsubscribeOnSymbolClicked(this.onSymbolClicked);
    }

    oncomplete (): void {
        super.oncomplete();
        const heatmapControl = this.findComponent<HeatmapRactive>('heatmapRactive');
        this._heatmapDrawer = heatmapControl.getDrawer() as HeatMapDrawer;
        this._heatmapDrawer.subscribeOnHoveredSymbolChanged(this.onHoveredSymbolChanged);
        this._heatmapDrawer.subscribeOnSymbolClicked(this.onSymbolClicked);
        DataCache.OnUpdateInstrument.Subscribe(this.onUpdateInstrument, this);
        this.repopulate();
    }

    repopulate (): void {
        if (!isNullOrUndefined(this._callbackProperties)) {
            this.callBack(this._callbackProperties);
            this._callbackProperties = undefined;
        }
        this.populateListItems();
        this.populateIntervals();
    }

    localize (): void {
        super.localize();
        const heatmapSymbolInfo = super.findComponent<HeatmapSymbolInfo>('heatmapSymbolInfo');
        if (!isNullOrUndefined(heatmapSymbolInfo)) {
            heatmapSymbolInfo.localize();
        }
    }

    onMouseDown (event: any): void {
        super.onMouseDown(event);

        if (event.original.button === 2) {
            this.showContextMenu(event);
        }
    }

    Properties (): DynProperty[] {
        const props = super.Properties();
        const viewSettings: IHeatmapViewSettings = this._heatmapDrawer.getSettings().viewSettings;

        let dp: DynProperty;
        let separatorGroup: string = `#0#${Resources.getResource('panel.HeatmapPanel.Settings.SectorLevel')}`;
        dp = new DynProperty('sectorBackColor', viewSettings.sectorLevelBackgroundColor, DynProperty.COLOR, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.Color';
        dp.separatorGroup = separatorGroup;
        props.push(dp);
        dp = new DynProperty('sectorForeColor', viewSettings.sectorLevelFontColor, DynProperty.COLOR, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.FontColor';
        dp.separatorGroup = separatorGroup;
        props.push(dp);

        separatorGroup = `#1#${Resources.getResource('panel.HeatmapPanel.Settings.IndustryLevel')}`;
        dp = new DynProperty('industryBackColor', viewSettings.industryLevelBackgroundColor, DynProperty.COLOR, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.Color';
        dp.separatorGroup = separatorGroup;
        props.push(dp);
        dp = new DynProperty('industryForeColor', viewSettings.industryLevelFontColor, DynProperty.COLOR, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.FontColor';
        dp.separatorGroup = separatorGroup;
        props.push(dp);

        separatorGroup = `#2#${Resources.getResource('panel.HeatmapPanel.Settings.SymbolLevel')}`;
        dp = new DynProperty('symbolScaleColor', new TrioColor(viewSettings.minColor, viewSettings.midColor, viewSettings.maxColor), DynProperty.TRIO_COLOR, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.ScaleColors';
        dp.separatorGroup = separatorGroup;
        dp.assignedProperty = ['symbolScaleExample'];
        dp.DynPropertyRelationType = DynPropertyRelationType.Value;
        props.push(dp);

        dp = new DynProperty('symbolScaleExample', new TrioColor(viewSettings.minColor, viewSettings.midColor, viewSettings.maxColor), DynProperty.TRIO_COLOR_GRADIENT, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.Example';
        dp.separatorGroup = separatorGroup;
        props.push(dp);

        dp = new DynProperty('symbolTitle', viewSettings.symbolLevelTitleBy, DynProperty.COMBOBOX, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.Title';
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.Symbol'), value: HeatmapSymbolTitleBy.Symbol },
            { text: Resources.getResource('property.Description'), value: HeatmapSymbolTitleBy.Description }
        ];
        props.push(dp);
        dp = new DynProperty('symbolFirstValue', viewSettings.symbolLevelFirstValue, DynProperty.COMBOBOX, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.FirstValue';
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.None'), value: HeatmapSymbolFirstValue.None },
            { text: Resources.getResource('property.ChangePercent'), value: HeatmapSymbolFirstValue.ChangePercentage }
        ];
        props.push(dp);
        dp = new DynProperty('symbolSecondValue', viewSettings.symbolLevelSecondValue, DynProperty.COMBOBOX, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.SecondValue';
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.None'), value: HeatmapSymbolSecondValue.None },
            { text: Resources.getResource('property.LastPrice'), value: HeatmapSymbolSecondValue.LastPrice },
            { text: Resources.getResource('property.MarketCap'), value: HeatmapSymbolSecondValue.MarketCap }
        ];
        props.push(dp);
        dp = new DynProperty('symbolForeColor', viewSettings.symbolLevelFontColor, DynProperty.COLOR, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.Color';
        dp.separatorGroup = separatorGroup;
        props.push(dp);
        dp = new DynProperty('isShowSymbolLogo', viewSettings.logoVisible, DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.Logo';
        dp.separatorGroup = separatorGroup;
        props.push(dp);
        dp = new DynProperty('symbolSizeBy', viewSettings.symbolLevelSizeBy, DynProperty.COMBOBOX, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.SizeBy';
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.MarketCap'), value: HeatmapSymbolSizeBy.MarketCap }
        ];
        props.push(dp);
        dp = new DynProperty('symbolColorBy', viewSettings.symbolLevelColorBy, DynProperty.COMBOBOX, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.ColorBy';
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.ChangePercent'), value: HeatmapSymbolColorBy.ChangePercentage }
        ];
        props.push(dp);

        separatorGroup = `#3#${Resources.getResource('panel.HeatmapPanel.Settings.GradationScale')}`;
        dp = new DynProperty('isShowGradationScale', viewSettings.gradationScaleVisible, DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
        dp.localizationKey = 'property.Visible';
        dp.separatorGroup = separatorGroup;
        props.push(dp);

        dp = new DynProperty('selectedListId', this._selectedListId, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP);
        props.push(dp);

        return props;
    }

    callBack (newProperties: DynProperty[]): void {
        super.callBack(newProperties);
        if (isNullOrUndefined(this._heatmapDrawer)) {
            this._callbackProperties = newProperties;
            return;
        }
        const viewSettings: IHeatmapViewSettings = this._heatmapDrawer.getSettings().viewSettings;
        let dp: DynProperty = DynProperty.getPropertyByName(newProperties, 'sectorBackColor');
        if (!isNullOrUndefined(dp)) {
            viewSettings.sectorLevelBackgroundColor = dp.value;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'sectorForeColor');
        if (!isNullOrUndefined(dp)) {
            viewSettings.sectorLevelFontColor = dp.value;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'industryBackColor');
        if (!isNullOrUndefined(dp)) {
            viewSettings.industryLevelBackgroundColor = dp.value;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'industryForeColor');
        if (!isNullOrUndefined(dp)) {
            viewSettings.industryLevelFontColor = dp.value;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'symbolScaleColor');
        if (!isNullOrUndefined(dp)) {
            viewSettings.minColor = dp.value.Color1;
            viewSettings.midColor = dp.value.Color2;
            viewSettings.maxColor = dp.value.Color3;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'symbolTitle');
        if (!isNullOrUndefined(dp)) {
            viewSettings.symbolLevelTitleBy = dp.value;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'symbolFirstValue');
        if (!isNullOrUndefined(dp)) {
            viewSettings.symbolLevelFirstValue = dp.value;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'symbolSecondValue');
        if (!isNullOrUndefined(dp)) {
            viewSettings.symbolLevelSecondValue = dp.value;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'symbolForeColor');
        if (!isNullOrUndefined(dp)) {
            viewSettings.symbolLevelFontColor = dp.value;
            void this.set('filterForegroundColor', dp.value);
        }
        dp = DynProperty.getPropertyByName(newProperties, 'isShowSymbolLogo');
        if (!isNullOrUndefined(dp)) {
            viewSettings.logoVisible = dp.value;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'symbolSizeBy');
        if (!isNullOrUndefined(dp)) {
            viewSettings.symbolLevelSizeBy = dp.value;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'symbolColorBy');
        if (!isNullOrUndefined(dp)) {
            viewSettings.symbolLevelColorBy = dp.value;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'isShowGradationScale');
        if (!isNullOrUndefined(dp)) {
            viewSettings.gradationScaleVisible = dp.value;
        }
        dp = DynProperty.getPropertyByName(newProperties, 'selectedListId');
        if (!isNullOrUndefined(dp)) {
            this._selectedListId = dp.value;
        }
        this._heatmapDrawer.applySettings();

        void this.set('isShowHeatmapFilter', viewSettings.gradationScaleVisible);
        this.populateIntervals();
    }

    symbolLink_Out (newSubscriber, instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }
        const color = super.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber);
        }
    };

    private showContextMenu (event: any): void {
        const hoveredInstrument = this._heatmapDrawer.getHoveredInstrument();
        const contextMenuItems = [];
        if (!isNullOrUndefined(hoveredInstrument)) {
            if (CustomerAccess.panelAllowed(PanelNames.SymbolInfoPanel)) {
                contextMenuItems.push({
                    text: Resources.getResource('panel.watchlist.menu.SymbolInfo'),
                    enabled: true,
                    event: () => MainWindowManager.MainWindow.ShowSymbolInfo(hoveredInstrument)
                });
            }

            contextMenuItems.push({
                text: Resources.getResource('panel.neworder'),
                enabled: true,
                event: () => MainWindowManager.MainWindow.ShowOrderEntry(hoveredInstrument)
            });

            if (CustomerAccess.panelAllowed(PanelNames.ChartPanel)) {
                contextMenuItems.push({
                    text: Resources.getResource('panel.chart'),
                    enabled: true,
                    event: () => MainWindowManager.MainWindow.ShowChart(hoveredInstrument)
                });
            }
            contextMenuItems.push({ separator: true });

            if (CustomerAccess.panelAllowed(PanelNames.InformerPanel)) {
                const addToWatchlistItem = {
                    text: Resources.getResource('general.addToWatchlist'),
                    enabled: true,
                    subitems: []
                };

                SessionSettings.createDefaultWatchlistListIfNeeded();
                const watchlistSheetsData = SessionSettings.watchlistSheets.itemsValue;
                for (let i = 0; i < watchlistSheetsData.length; i++) {
                    addToWatchlistItem.subitems.push({
                        text: watchlistSheetsData[i].name,
                        enabled: true,
                        event: () => {
                            if (!isNullOrUndefined(hoveredInstrument)) {
                                const interiorId = hoveredInstrument.GetInteriorID();
                                if (watchlistSheetsData[i].itemsStr.indexOf(interiorId) === -1) {
                                    watchlistSheetsData[i].itemsStr += interiorId + ';';
                                    SessionSettings.setSheetsData('watchlistSheets', watchlistSheetsData);
                                }
                            }
                        }
                    });
                }

                contextMenuItems.push(addToWatchlistItem);
                contextMenuItems.push({ separator: true });
            }
        }
        contextMenuItems.push({
            text: Resources.getResource('general.Settings'),
            enabled: true,
            event: () => {
                PanelSettingsScreen.EditProperties(this, null, Resources.getResource('panel.HeatmapPanel.Settings.Header'));
            }
        });
        contextMenuHandler.Show(contextMenuItems, event.original.clientX, event.original.clientY);
    }

    private populateListItems (): void {
        const heatmapCustomLists = DataCache.GetCustomListsByType(CustomInstrumentListTypeEnum.MarketHeatMap);
        void this.set('heatmapListItems', heatmapCustomLists);
        if (heatmapCustomLists.length > 0) {
            let selectedList = heatmapCustomLists[0];
            if (isValidNumber(this._selectedListId)) {
                for (let i = 0; i < heatmapCustomLists.length; i++) {
                    if (heatmapCustomLists[i].id === this._selectedListId) {
                        selectedList = heatmapCustomLists[i];
                        break;
                    }
                }
            }
            void this.set('selectedListItem', selectedList);
        } else {
            void this.set('selectedListItem', null);
        }
        this.updateListItemsVisiblity();
    }

    private populateIntervals (): void {
        this._heatmapDrawer.populateIntervals();
        void this.set('intervals', this._heatmapDrawer.getIntervals());
        void this.set('scaleMultiplier', this._heatmapDrawer.getStepMultiplier());
        void this.updateModel();
    }

    private listItemClicked (_event: any, id: number): void {
        const heatmapListItems = this.get<CustomInstrumentList[]>('heatmapListItems');
        for (const item of heatmapListItems) {
            if (item.id === id) {
                this._selectedListId = id;
                void this.set('selectedListItem', item);
                if (isNullOrUndefined(_event)) {
                    this.updateListItemsVisiblity();
                }
                break;
            }
        }
    }

    private onScaleFilterChanged (): void {
        this._heatmapDrawer.applySettings();
    }

    private onScaleMultiplierChanged (_sender: any, scaleMultiplier: number): void {
        if (isNullOrUndefined(this._heatmapDrawer)) {
            return;
        }
        this._heatmapDrawer.setStepMultiplier(scaleMultiplier);
        this.populateIntervals();
    }

    private onMoreButtonClick (_sender: any): void {
        const heatmapContextMenuListItems = this.get<CustomInstrumentList[]>('heatmapContextMenuListItems');
        const selectedListItem = this.get<CustomInstrumentList>('selectedListItem');
        const contextMenuItems = [];
        for (let i = 0; i < heatmapContextMenuListItems.length; i++) {
            contextMenuItems.push({
                text: heatmapContextMenuListItems[i].name,
                canCheck: true,
                checked: heatmapContextMenuListItems[i] === selectedListItem,
                event: () => { this.listItemClicked(undefined, heatmapContextMenuListItems[i].id); }
            });
        }
        const rect = this.findAll('.terceraButton.js-more-button')[0].getBoundingClientRect();
        contextMenuHandler.Show(contextMenuItems, rect.left, rect.bottom);
    }

    private selectedListItemChanged (newValue: CustomInstrumentList, oldValue: CustomInstrumentList): void {
        if (isNullOrUndefined(this._heatmapDrawer)) {
            return;
        }

        if (!isNullOrUndefined(oldValue)) {
            DataCache.HeatmapCache.processSubscription(oldValue.id, false, this.onUpdateItems);
        }

        this._marketCapCache.clear();

        const selectedListItem = this.get<CustomInstrumentList>('selectedListItem');
        const data: HeatmapData[] = [];
        if (!isNullOrUndefined(selectedListItem) && !isNullOrUndefined(selectedListItem.tradableIds)) {
            const ids = selectedListItem.tradableIds;
            for (const tradableId of ids) {
                const instr: Instrument = DataCache.getInstrumentByTradable_ID_first(tradableId);
                if (!isNullOrUndefined(instr) && instr.MarketCap !== 0) {
                    this._marketCapCache.set(instr, instr.MarketCap);
                    const heatmapData = new HeatmapData(instr);
                    data.push(heatmapData);
                }
            }
        }
        this._heatmapDrawer.populate(data);

        if (!isNullOrUndefined(newValue)) {
            DataCache.HeatmapCache.processSubscription(newValue.id, true, this.onUpdateItems);
        }
    }

    private readonly onUpdateItems = (): void => {
        const selectedListItem: CustomInstrumentList = this.get('selectedListItem');
        if (isNullOrUndefined(selectedListItem)) {
            return;
        }
        const data = this._heatmapDrawer.getData();
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const algorithmData = DataCache.HeatmapCache.getData(selectedListItem.id, item.Instrument);
            item.heatmapAlgorithmData = algorithmData;
        }
        this._heatmapDrawer.updateMap();
    };

    private onUpdateInstrument (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const oldMarketCap = this._marketCapCache.get(instrument);
        if (!isValidNumber(oldMarketCap)) {
            return;
        }

        if (oldMarketCap !== instrument.MarketCap) {
            this._marketCapCache.set(instrument, instrument.MarketCap);
            this._heatmapDrawer.updateMap();
        }
    }

    private readonly onHoveredSymbolChanged = (hoveredHeatmapData: HeatmapData): void => {
        if (isNullOrUndefined(hoveredHeatmapData)) {
            void this.set('hoveredHeatmapData', null);
        } else {
            void this.set('hoveredHeatmapData', hoveredHeatmapData);
        }
    };

    private readonly onSymbolClicked = (instrument: Instrument): void => {
        this.symbolLink_Out(false, instrument);
    };

    private updateListItemsVisiblity (): void {
        const listItems = this.get<CustomInstrumentList[]>('heatmapListItems');
        const selectedListItem = this.get<CustomInstrumentList>('selectedListItem');

        if (isNullOrUndefined(listItems) || isNullOrUndefined(selectedListItem)) {
            void this.set({
                heatmapHeaderListItems: [],
                heatmapContextMenuListItems: []
            });
            return;
        }

        let startHeaderIndex = 0;
        let endHeaderIndex = listItems.length - 1;
        const selectedIndex = listItems.indexOf(selectedListItem);
        const nextAfterSelectedItemsCount = listItems.length - selectedIndex - 1;
        if (selectedIndex <= HeatmapPanel.HEADER_LIST_ITEMS_COUNT - 1) {
            endHeaderIndex = HeatmapPanel.HEADER_LIST_ITEMS_COUNT - 1;
        } else if (nextAfterSelectedItemsCount >= HeatmapPanel.HEADER_LIST_ITEMS_COUNT) {
            startHeaderIndex = selectedIndex;
            endHeaderIndex = selectedIndex + HeatmapPanel.HEADER_LIST_ITEMS_COUNT - 1;
        } else {
            startHeaderIndex = listItems.length - HeatmapPanel.HEADER_LIST_ITEMS_COUNT;
            endHeaderIndex = listItems.length - 1;
        }

        const headerListItems: CustomInstrumentList[] = [];
        const contextMenuListItems: CustomInstrumentList[] = [];

        for (let i = 0; i < listItems.length; i++) {
            const listItem = listItems[i];
            if (i >= startHeaderIndex && i <= endHeaderIndex) {
                headerListItems.push(listItem);
            } else {
                contextMenuListItems.push(listItem);
            }
        }

        void this.set({
            heatmapHeaderListItems: headerListItems,
            heatmapContextMenuListItems: contextMenuListItems
        });
    }
}

ApplicationPanel.extendWith(HeatmapPanel, {
    data: function () {
        return {
            showFullscreenModeButton: true,
            canLinkByAccount: false,
            isSymbolLinkShow: true,
            heatmapListItems: [],
            heatmapHeaderListItems: [],
            heatmapContextMenuListItems: [],
            selectedListItem: null,
            intervals: [],
            isShowHeatmapFilter: true,
            scaleMultiplier: undefined,
            hoveredHeatmapData: null,
            filterForegroundColor: ''
        };
    },
    partials: {
        bodyPartial: HeatmapPanelTemplate
    }
});
