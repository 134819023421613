// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { AssetTradingMode, CalculationMethod } from '../../Utils/Asset/AssetConst';
import { type Account } from './Account';
import { DataCacheHolder } from './DataCacheHolder';

export class AssetInfoData {
    // Asset name
    public Name = '';
    // Total qty
    public TotalQuantity = 0;
    // Qty for margin available
    public TotalQuantityForMarginAvailable = 0;
    public AssetTradingMode = AssetTradingMode.Delivery;
    // Calculation method (0 - LAST, 1 - PREV_CLOSE)
    public CalculationMethod = CalculationMethod.Last;
    // Today traded qty (передает один раз при логине, лдальше должны расчитывать сами)
    public TodayTradedQty = 0;

    public GetCurrentValue (qty, account: Account): number {
        const asset = DataCacheHolder.getDataCache().GetAssetByName(this.Name);

        let lastPrice = NaN;
        let crossPrice = NaN;
        if (asset?.Instrument) {
            lastPrice = asset.Instrument.Level1.GetLastPrice(account);
            crossPrice = asset.Instrument.DataCache.CrossRateCache.GetCrossPriceExp1Exp2(asset.Instrument.Exp2, account.BaseCurrency);
        }

        if (isNaN(lastPrice) || isNaN(crossPrice)) {
            return NaN;
        }

        return qty * lastPrice * crossPrice;
    }

    public GetAvailableQty (): number {
        return this.TodayTradedQty >= 0
            ? this.TotalQuantity
            : this.TotalQuantity - Math.abs(this.TodayTradedQty);
    }

    // Current qty = Start day qty + Today traded qty. Причем оба слагаемых могут быть с разными знаками.
    public GetCurrentQty (): number {
        return this.TotalQuantity + this.TodayTradedQty;
    }
}
