// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { AdvancedOrderEntry } from './AdvancedOrderEntry';
import { MarketConsensusPanel } from './MarketConsensusPanel';
import { AssetBalancesPanel } from './AssetBalancesPanel';
import { CreateAlertPanel } from './CreateAlertPanel';
import { EventsPanel } from './EventsPanel';
import { FilledOrdersPanel } from './FilledOrdersPanel';
import { InformerPanel } from './InformerPanel';
import { OrderHistoryPanel } from './OrderHistoryPanel';
import { OrdersPanel } from './OrdersPanel';
import { PositionsPanel } from './PositionsPanel';
import { ProductsPanel } from './ProductsPanel';
import { ReportsPanel } from './ReportsPanel';
import { RiskCalculator } from './RiskCalculator';
import { TimeSalesPanel } from './TimeSalesPanel';
import { SymbolInfoPanel } from './SymbolInfoPanel';
import { MarginOEControl } from './MarginOEControl';
import { MarketDepthPanel } from './MarketDepthPanel';
import { ChartPanel } from './ChartPanel';
import { GridPanel } from './GridPanel';
import { FullLogsPanel } from './FullLogsPanel';
import { InstrumentHolidaysScreen } from './InstrumentHolidaysScreen';
import { SavedOrdersPanel } from './SavedOrdersPanel';
import { StatementPanel } from './StatementPanel';
import { TradingCentral } from './TradingCentral';
import { ExternalScreen } from '../screen/ExternalScreen';
import { AssetsPanel } from './AssetsPanel';
import { PositionsBalancePanel } from './PositionsBalancePanel';
import { NewsPanel } from './NewsPanel';
import { TerceraClosePositionScreen } from '../screen/TerceraClosePositionScreen';
import { ModifyPositionScreen } from '../screen/ModifyPositionScreen';
import { ModifyOrderScreen } from '../screen/ModifyOrderScreen';
import { EditPositionScreen } from '../screen/EditPositionScreen';
import { PositionExerciseRequestScreen } from '../screen/PositionExerciseRequestScreen';
import { DockPlaceConst, DockSystemInstance } from '../DockSystem';
import { MessageBoxType, TerceraMessageBox } from '../screen/TerceraMessageBox';
import { PanelLocKeys, PanelNames, ScreensNames } from '../UtilsClasses/FactoryConstants';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { DataCache } from '../../Commons/DataCache';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';
import { ClosePositionsMutually } from './ClosePositionsMutually';
import { TradingViewChartPanel } from './TradingViewChartPanel';
import { GainersLosersPanel } from './GainersLosersPanel';
import { HighLowPanel } from './HighLowPanel';
import { HeatmapPanel } from './HeatmapPanel';
import { OptionMasterPanel } from './OptionMaster/OptionMasterPanel';
import { type ApplicationPanel } from './ApplicationPanel';
import { NewAccountDetailsPanel } from './VerticalPanels/NewAccountDetailsPanel';

class _Factory {
    //
    // Создание всех панелей
    //
    public addPanel (type: PanelNames, from?: FactoryAddPanelFrom, afterCreationCallback?): ApplicationPanel {
        if (isNullOrUndefined(WorkSpaceManager.currentWorkspace)) {
            return;
        }

        const fromRestore = from === FactoryAddPanelFrom.Restore;
        const value = this.GetThrottlingOperationValue(type);

        if (!fromRestore && value.throttlingOperation !== ThrottlingOperation.CanAddPanel) {
            return;
        }

        const newPanel = this.createPanel(type);

        const skipLock = type === PanelNames.ReportsPanel || type === PanelNames.OrderEntry ||
        type === PanelNames.AdvancedOrderEntry || type === PanelNames.RiskCalculator || type === PanelNames.NewAccountDetailsPanel || type === PanelNames.SymbolInfoPanel;

        if (WorkSpaceManager.currentWorkspace.locked && !skipLock && !fromRestore) {
            WorkSpaceManager.processUndock();
            return;
        }

        this.dockSystemOperations(newPanel, fromRestore);

        this.addPanelToWs(newPanel);

        if (!isNullOrUndefined(afterCreationCallback)) {
            afterCreationCallback(newPanel);
        }

        return newPanel;
    }

    public createPanel (type: PanelNames): ApplicationPanel {
        let newPanel;
        switch (type) {
        case PanelNames.PositionsPanel:
            newPanel = new PositionsPanel();
            break;

        case PanelNames.OrdersPanel:
            newPanel = new OrdersPanel();
            break;

        case PanelNames.EventsPanel:
            newPanel = new EventsPanel();
            break;

        case PanelNames.OrderHistoryPanel:
            newPanel = new OrderHistoryPanel();
            break;

        case PanelNames.FilledOrdersPanel:
            newPanel = new FilledOrdersPanel();
            break;

        case PanelNames.AssetBalancesPanel:
            newPanel = new AssetBalancesPanel();
            break;

        case PanelNames.InformerPanel:
            newPanel = new InformerPanel();
            break;

        case PanelNames.MarketConsensusPanel:
            newPanel = new MarketConsensusPanel();
            break;

        case PanelNames.GainersLosersPanel:
            newPanel = new GainersLosersPanel();
            break;

        case PanelNames.HighLowPanel:
            newPanel = new HighLowPanel();
            break;

        case PanelNames.CreateAlertPanel:
            newPanel = new CreateAlertPanel();
            break;

        case PanelNames.AdvancedOrderEntry:
            newPanel = new AdvancedOrderEntry();
            break;

        case PanelNames.RiskCalculator:
            newPanel = new RiskCalculator();
            break;

        case PanelNames.TimeSalesPanel:
            newPanel = new TimeSalesPanel();
            break;

        case PanelNames.ReportsPanel:
            newPanel = new ReportsPanel();
            break;

        case PanelNames.ProductsPanel:
            newPanel = new ProductsPanel();
            break;

        case PanelNames.NewAccountDetailsPanel:
            newPanel = new NewAccountDetailsPanel();
            break;

        case PanelNames.SymbolInfoPanel:
            newPanel = new SymbolInfoPanel();
            break;

        case PanelNames.MarginOEControl:
            newPanel = new MarginOEControl();
            break;

        case PanelNames.MarketDepthPanel:
            newPanel = new MarketDepthPanel();
            break;

        case PanelNames.ChartPanel:
            newPanel = new ChartPanel();
            break;

        case PanelNames.TradingViewChartPanel:
            newPanel = new TradingViewChartPanel();
            break;

        case PanelNames.GridPanel:
            newPanel = new GridPanel();
            break;

        case PanelNames.FullLogsPanel:
            newPanel = new FullLogsPanel();
            break;

        case PanelNames.InstrumentHolidaysScreen:
            newPanel = new InstrumentHolidaysScreen();
            break;

        case PanelNames.SavedOrdersPanel:
            newPanel = new SavedOrdersPanel();
            break;

        case PanelNames.StatementPanel:
            newPanel = new StatementPanel();
            break;

        case PanelNames.TradingCentral:
            newPanel = new TradingCentral();
            break;

        case PanelNames.ExternalScreen:
            newPanel = new ExternalScreen();
            break;

        case PanelNames.AssetsPanel:
            newPanel = new AssetsPanel();
            break;

        case PanelNames.PositionsBalancePanel:
            newPanel = new PositionsBalancePanel();
            break;

        case PanelNames.NewsPanel:
            newPanel = new NewsPanel();
            break;

        case PanelNames.ClosePositionsMutually:
            newPanel = new ClosePositionsMutually();
            break;

        case PanelNames.HeatmapPanel:
            newPanel = new HeatmapPanel();
            break;

        case PanelNames.OptionMasterPanel:
            newPanel = new OptionMasterPanel();
            break;

        default:
        {
            // мы это не создаём пока или type не указан
            break;
        }
        }

        return newPanel;
    }

    public dockSystemOperations (newPanel: ApplicationPanel, fromRestore: boolean): void {
        newPanel.CustomPreparation();

        const dockPlace = this.getDefaultPlace(newPanel.getType());
        if (!fromRestore || !newPanel.get<boolean>('dockablePanel')) {
            MainWindowManager.MainWindow.addControl(newPanel);
        } else {
            DockSystemInstance.goInProcces(newPanel, dockPlace);
            newPanel.on('complete', function () {
                if (this.get('workSpaceId') !== null) {
                    DockSystemInstance.setPlace(this.get('workSpaceId'), dockPlace);
                }
            });
        }

        if (!fromRestore && newPanel.get<boolean>('dockablePanel')) {
            DockSystemInstance.addPanelOnDock(
                newPanel,
                dockPlace,
                WorkSpaceManager.currentWorkspace.locked);
        }
    }

    public addPanelToWs (newPanel: ApplicationPanel): void {
    // save
        WorkSpaceManager.currentWorkspace.addPanel(newPanel);
    }

    public addScreen (type: ScreensNames): any {
        let newControl = null;
        switch (type) {
        // case ScreensNames.ModifyScreen:
        //     newControl = new TerceraModifyScreen()
        //     break
        case ScreensNames.ClosePositionScreen:
            newControl = new TerceraClosePositionScreen();
            break;
        case ScreensNames.ModifyPositionScreen:
            newControl = new ModifyPositionScreen();
            break;
        case ScreensNames.ModifyOrderScreen:
            newControl = new ModifyOrderScreen();
            break;
        case ScreensNames.EditPositionScreen:
            newControl = new EditPositionScreen();
            break;
        case ScreensNames.PositionExerciseRequestScreen:
            newControl = new PositionExerciseRequestScreen();
            break;
        }

        if (type !== ScreensNames.ModifyPositionScreen && type !== ScreensNames.ClosePositionScreen) // this screens are attached to EditPositionScreen
        {
            MainWindowManager.MainWindow.addControl(newControl);
        }

        return newControl;
    }

    public getDefaultPlace (type: PanelNames): any {
        return _Factory.PanelPlaces[type];
    }

    // тут указываем кто куда по умолчанию дочится
    public static readonly PanelPlacesMask = [
        {
            place: DockPlaceConst.Middle,
            panels: [
                PanelNames.ChartPanel,
                PanelNames.TradingViewChartPanel,
                PanelNames.TradingCentral,
                PanelNames.GridPanel,
                PanelNames.HeatmapPanel,
                PanelNames.OptionMasterPanel
            ]
        },
        {
            place: DockPlaceConst.Up,
            panels: [

            ]
        },
        {
            place: DockPlaceConst.Down,
            panels: [
                PanelNames.OrderHistoryPanel,
                PanelNames.PositionsPanel,
                PanelNames.OrdersPanel,
                PanelNames.EventsPanel,
                PanelNames.AssetBalancesPanel,
                PanelNames.FilledOrdersPanel,
                PanelNames.SavedOrdersPanel,
                PanelNames.StatementPanel,
                PanelNames.FullLogsPanel,
                PanelNames.PositionsBalancePanel,
                PanelNames.NewsPanel,
                PanelNames.AssetsPanel,
                PanelNames.ProductsPanel
            ]
        },
        {
            place: DockPlaceConst.Left,
            panels: [
                PanelNames.OrderEntry,
                PanelNames.AdvancedOrderEntry,
                PanelNames.RiskCalculator,
                PanelNames.ReportsPanel,
                PanelNames.NewAccountDetailsPanel,
                PanelNames.InformerPanel,
                PanelNames.SymbolInfoPanel,
                PanelNames.MarketConsensusPanel,
                PanelNames.GainersLosersPanel,
                PanelNames.HighLowPanel
            ]
        },
        {
            place: DockPlaceConst.Right,
            panels: [
                PanelNames.TimeSalesPanel,
                PanelNames.MarketDepthPanel,
                PanelNames.ExternalScreen
            ]
        }];

    public static readonly PanelPlaces = (function () {
        const result = {};
        const len = _Factory.PanelPlacesMask.length;
        for (let i = 0; i < len; i++) {
            const curPlaceObj = _Factory.PanelPlacesMask[i];
            const curPlace = curPlaceObj.place;
            const curPanels = curPlaceObj.panels;
            const panelsLen = curPanels.length;
            for (let j = 0; j < panelsLen; j++) {
                result[curPanels[j]] = curPlace;
            }
        }

        return result;
    })();

    public GetThrottlingOperationValue (panelType: PanelNames, aboutRows: boolean = false): { throttlingOperation: ThrottlingOperation, rowsNumber: number } {
        const result = { throttlingOperation: ThrottlingOperation.CanAddPanel, rowsNumber: -1 };
        let ruleStringValue: number;
        switch (panelType) {
        case PanelNames.ChartPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_THROTLING_PANEL_LIMIT_CHART_COUNT, -1);
            break;
        case PanelNames.MarketDepthPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_THROTLING_PANEL_LIMIT_MARKETDEPTH_COUNT, -1);
            break;
        case PanelNames.TimeSalesPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_THROTLING_PANEL_LIMIT_TIMEANDSALES_COUNT, -1);
            break;
        case PanelNames.InformerPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(aboutRows ? RulesSet.VALUE_THROTLING_PANEL_LIMIT_WATCHLIST_ROWS : RulesSet.VALUE_THROTLING_PANEL_LIMIT_WATCHLIST_COUNT, -1);
            break;
        case PanelNames.SavedOrdersPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(aboutRows ? RulesSet.VALUE_THROTLING_PANEL_LIMIT_SAVEDORDERS_ROWS : RulesSet.VALUE_THROTLING_PANEL_LIMIT_SAVEDORDERS_COUNT, -1);
            break;
        case PanelNames.TradingViewChartPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_PANEL_LIMIT_CHART_TV_COUNT, -1);
            break;
        case PanelNames.GridPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_THROTLING_PANEL_LIMIT_GRID_COUNT, -1);
            break;
        case PanelNames.OptionMasterPanel:
            ruleStringValue = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_THROTLING_PANEL_LIMIT_OPTIONMASTER_COUNT, -1);
            break;
        }

        if (aboutRows) {
            result.throttlingOperation = ThrottlingOperation.CanAddRows;
            result.rowsNumber = ruleStringValue;
            ruleStringValue = -1;
        }

        const panelNum = WorkSpaceManager.currentWorkspace.numberOfPanelByType(panelType);

        if (ruleStringValue !== -1 && panelNum >= ruleStringValue) {
            result.throttlingOperation = ThrottlingOperation.Reject;

            TerceraMessageBox.Show(Resources.getResource('workspace.information'), Resources.getResource('Throtling.Count.firstPart') + ' ' + Resources.getResource(PanelLocKeys[panelType]) + ' ' + Resources.getResource('Throtling.Count.secondPart'), MessageBoxType.Info, null, null, false, true);
        }

        return result;
    }
}

export enum FactoryAddPanelFrom {
    Restore = 0,
    Throttling = 1
}

// create instance

export class UpdatePackageInfo {
    public static readonly ADD_ROW = 0;
    public static readonly REMOVE_ROW = 1;

    public addedRemovedRows: any[] = [];
    public updatedRows: any[] = [];
    public additionalRowsData: any[] = [];
    public additionalTableData: any = null;
}

export enum ThrottlingOperation {
    CanAddPanel = 0,
    Reject = 1,
    ForbidPanel = 2,
    CanAddRows = 3
}

export const Factory = new _Factory();
