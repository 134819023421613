// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraImagePreviewScreenTemplate } from '../../templates.js';
import { TerceraWindowBase } from './TerceraWindowBase';

// TODO. Duplicated code/template taken from TerceraPictureBox.ts

let instance = null;
export class TerceraImagePreviewScreen extends TerceraWindowBase {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.on('imageLoaded', this.layout);
    }

    public layout (): void {
        const img: any = this.find('img');
        if (!img) return;

        const margin = 5;
        const headerH = 20;

        void this.set({
            width: (img.naturalWidth || 118) + 2 * margin,
            height: (img.naturalHeight || 98) + 2 * margin + headerH
        });

        this.center();
    }

    public override close (): void {
        void this.set('visible', false);
    }

    public static show (imageUrl, imageName): void {
        const scr = TerceraImagePreviewScreen.getInstance();
        void scr.set({
            imageUrl,
            header: imageName,
            visible: true
        });
        // If image is loaded already event won't fire, thus make a forced call.
        scr.layout();
        scr.setFocus();
    }

    public static getInstance (): TerceraImagePreviewScreen {
        if (!instance) {
            const scr = new TerceraImagePreviewScreen();
            instance = scr;
            MainWindowManager.MainWindow.addControl(scr);
        }

        return instance;
    }
}

TerceraWindowBase.extendWith(TerceraImagePreviewScreen, {
    data: function () {
        return {
            okText: 'OK',
            imageUrl: '',
            movable: false,
            resizable: false,
            showFooter: false,
            visible: false,
            zIndex: 1000
        };
    },
    partials: { bodyPartial: TerceraImagePreviewScreenTemplate }
});
