// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class PPMA extends IndicatorScriptBase {
    public PPMAPeriod: number;

    constructor () {
        super();
        // #region Initialization
        this.ProjectName = 'Pivot Point Moving Average';
        this.Comments = 'Uses the pivot point calculation as the input a simple moving average';
        // #endregion

        this.SetIndicatorLine('PPMA', Color.Blue, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('PPMA_MA', Color.Yellow, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.PPMAPeriod = 5;
        super.InputParameter(new InputParameterInteger('PPMAPeriod', 'PPMA Period:', 0, 1, 100, 0, 1));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        this.UpdateIndexDrawBegin();
    };

    public override GetIndicatorShortName (): string {
        return 'PPMA(' + this.PPMAPeriod + ')';
    };

    public override getCustomName (): string {
        return 'PPMA (' + this.ProjectName + ')';
    };

    public override UpdateIndexDrawBegin (): void {
        this.SetIndexDrawBegin(0, this.PPMAPeriod);
        this.SetIndexDrawBegin(1, this.PPMAPeriod);
    };

    public OnQuote (): void {
        super.OnQuote();

        if (this.CurrentData.Count < this.PPMAPeriod) { return; };

        const pivot = (this.CurrentData.GetPrice(PriceType.High, 0) +
            this.CurrentData.GetPrice(PriceType.Low, 0) +
            this.CurrentData.GetPrice(PriceType.Close, 0)) / 3.0;
        this.SetValue(0, 0, pivot);

        let sum = pivot;

        for (let i = 1; i < this.PPMAPeriod; i++) {
            sum += (this.CurrentData.GetPrice(PriceType.High, i) +
                this.CurrentData.GetPrice(PriceType.Low, i) +
                this.CurrentData.GetPrice(PriceType.Low, i)) / 3.0;
        };

        this.SetValue(1, 0, sum / this.PPMAPeriod);
    };
}
