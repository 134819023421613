export class DataBaseWorkingClass {
    protected db: IDBDatabase | null = null;
    protected readonly DBName: string;
    constructor (databaseName: string) {
        this.DBName = databaseName;
    }

    public async OpenDB (objectStoreKey: string, keyPathValue: string): Promise<boolean> {
        this.db = await this.CreateObjectStore(objectStoreKey, keyPathValue);
        return this.db !== null;
    }

    private async getDBLastVersion (): Promise<number> {
        return await new Promise((resolve) => {
            const openRequest = indexedDB.open(this.DBName);
            openRequest.onerror = function () {
                resolve(1);
            };
            openRequest.onsuccess = function () {
                openRequest.result.close();
                resolve(openRequest.result.version);
            };
        });
    }

    private async isKeyPresent (objectStoreKey: string, version: number): Promise<boolean> {
        const checkPromise = new Promise<boolean>((resolve) => {
            const openRequest = indexedDB.open(this.DBName, version);
            openRequest.onerror = function () {
                resolve(false);
            };
            openRequest.onsuccess = function () {
                const isPresent = openRequest.result.objectStoreNames.contains(objectStoreKey);
                openRequest.result.close();
                resolve(isPresent);
            };
        });

        const result = await checkPromise;
        return result;
    }

    protected async CreateObjectStore (objectStoreKey: string, keyPathValue: string): Promise<IDBDatabase | null> {
        let version = await this.getDBLastVersion();
        // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
        return await new Promise(async (resolve) => {
            const isPresent = await this.isKeyPresent(objectStoreKey, version);
            if (!isPresent) { version++; }
            const openRequest = indexedDB.open(this.DBName, version);
            openRequest.onerror = function () {
                resolve(null);
            };
            openRequest.onsuccess = function () {
                resolve(openRequest.result);
            };
            openRequest.onupgradeneeded = function () {
                const db = openRequest.result;
                if (!db.objectStoreNames.contains(objectStoreKey)) {
                    db.createObjectStore(objectStoreKey, { keyPath: keyPathValue }); // создаем хранилище
                }
            };
        });
    }

    protected async GetStorage (objectStoreKey: string): Promise<IDBObjectStore> {
        return this.db.transaction(objectStoreKey, 'readwrite').objectStore(objectStoreKey);
    }

    public async AddToDB (data: any, objectStoreKey: string): Promise<IDBRequest> {
        const objectStore = await this.GetStorage(objectStoreKey);
        return await new Promise((resolve) => {
            const request = objectStore.put(data);
            request.onsuccess = function () {
                resolve(request);
            };
            request.onerror = function () {
                resolve(request);
            };
        });
    };

    // The same content as AddToDB, but name UpdateEntryInDB is necessary for us to distinguish between actions
    // Or some improves
    protected async UpdateEntryInDB (data: any, objectStoreKey: string): Promise<IDBRequest> {
        const objectStore = await this.GetStorage(objectStoreKey);
        return await new Promise((resolve) => {
            const request = objectStore.put(data);
            request.onsuccess = function () {
                resolve(request);
            };
            request.onerror = function () {
                resolve(request);
            };
        });
    };

    public async GetFromDB (key: string, objectStoreKey: string): Promise<IDBRequest> {
        const objectStore = await this.GetStorage(objectStoreKey);
        return await new Promise((resolve) => {
            const request = objectStore.get(key);
            request.onsuccess = function () {
                resolve(request);
            };
            request.onerror = function () {
                resolve(null);
            };
        });
    };

    protected async GetAllEntriesFromDB (objectStoreKey: string): Promise<IDBRequest> {
        const objectStore = await this.GetStorage(objectStoreKey);
        return await new Promise((resolve) => {
            const request = objectStore.getAll();
            request.onsuccess = function () {
                resolve(request);
            };
            request.onerror = function () {
                resolve(null);
            };
        });
    };

    protected async DeleteFromDB (key: string, objectStoreKey: string): Promise<IDBRequest> {
        const objectStore = await this.GetStorage(objectStoreKey);
        return await new Promise((resolve) => {
            const request = objectStore.delete(key);
            request.onsuccess = function () {
                resolve(request);
            };
            request.onerror = function () {
                resolve(null);
            };
        });
    };

    public IsOpened (): boolean {
        return this.db !== null;
    }

    public CloseDB (): void {
        if (this.db !== null) {
            this.db.close();
            this.db = null;
        }
    }
}
