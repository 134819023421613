import { MODE_UPPER, MODE_LOWER, type MAMode } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iBandsEx extends iBuildInIndicator {
    public FMaPeriod: any;
    public FMaMode: MAMode;
    public FDeviation: any;
    public ma: ExpandDoubleVector = new ExpandDoubleVector();
    public FBegin: any;

    constructor (maPeriod, maMode: MAMode, deviation, bandsShift, priceType) {
        super(3);
        this.FMaPeriod = maPeriod;
        this.FMaMode = maMode;
        this.FDeviation = deviation;
        this.FPriceType = priceType;
        this.FBegin = maMode == 1 ? 1 : maPeriod;
        this.FData.FArray[0].FShift = bandsShift;
        this.FData.FArray[1].FShift = bandsShift;
        this.FData.FArray[2].FShift = bandsShift;
    }

    override get Name () { return 'iBandsEx'; }

    override get Key () { return this.DefaultKey + this.FMaPeriod + this.FMaMode + this.FDeviation + this.FPriceType; }

    public override NextBar (callBound): void {
        super.NextBar(callBound);
        this.ma.Add(0.0);
    }

    public override Refresh (count, newThread): void {
        this.ma.Dispose();
        this.ma = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }

    public override OnQuote (): void {
        const price = this.GetPrice(this.FPriceType, 0);
        this.ma[this.ma.Length - 1] = price;
        const Parent = this.Parent;
        if (!Parent) {
            return;
        }
        if (this.FCount < this.FBegin) {
            return;
        }

        const maValue = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.ma, this.FMaPeriod, 1);
        let summa = 0.0;
        for (let i = 0; i < this.FMaPeriod; i++) {
            const add = this.GetPrice(this.FPriceType, i) - maValue;
            summa += (add * add);
        }
        // Calculation of deviation value
        summa = this.FDeviation * Math.sqrt(summa / this.FMaPeriod);

        // Setting value
        this.SetValue(MODE_UPPER, 0, maValue + summa);
        this.SetValue(MODE_LOWER, 0, maValue - summa);
        this.SetValue(2, 0, maValue);
    }
}
