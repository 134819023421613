// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources } from '../../Commons/properties/Resources';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { MathUtils } from '../../Utils/MathUtils';
import { AccountType, getAccountTypeStr } from '../../Utils/Account/AccountType';
import { QuickTableComparingType } from '../../Utils/QuickTableMisc/QuickTableComparingType';
import { AccountTradeStatus } from '../../Utils/Account/AccountTradeStatus';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { type Asset, AssetType } from '../../Commons/cache/Asset';
import { Account } from '../../Commons/cache/Account';
import { AccountFeature } from '../../Utils/Account/AccountFeature';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { DataCache } from '../../Commons/DataCache';
import { type SessionSettingsType } from '../../Commons/SessionSettings';
import { type AssetBalance } from '../../Commons/cache/AssetBalance';
import { AccountUtils } from '../../Commons/cache/AccountUtils';

export class AssetBalanceItem extends BaseItem {
    public static currentLocale;

    public static activeStatus;
    public static closedStatus;
    public static suspendStatus;
    public static autoClosedStatus;
    public static liquidationOnlyStatus;
    public static maxGrosslossReachedStatus;
    public static maxDayVolumeReachedStatus;
    public static orderFlowLimitStatus;
    public static disabledByRiskRules;
    public static weeklyLostLimit;
    public static trailingDrawdownLimit;
    public static maxUnrealizedLoss;
    public static maxOrdersPerDay;
    public static relativeDrawDownLevelReachedStatus: string;
    public static finraDayTraderPatternStatus: string;
    public static eodTrailingDrawdownReachedStatus: string;
    public static relativeDailyLossLimitReachedStatus: string;

    public readonly AVAILABLE_CASH: number = 134;
    public readonly TOTAL_POSITIONS_VALUE: number = 135;

    public Account: Account;
    public AssetBalance: AssetBalance | null;
    public EstimatedCurrency: Asset | null;
    public userGroupName: string;

    constructor (account: Account, sessionSettings: SessionSettingsType, assetBalance?: AssetBalance, EstimatedCurrency?: Asset | null) {
        super(sessionSettings);

        this.Account = account;
        this.AssetBalance = null;
        this.EstimatedCurrency = EstimatedCurrency;
        try {
            if (!isNullOrUndefined(assetBalance)) {
                this.AssetBalance = assetBalance;
            } else if (!isNullOrUndefined(this.Account)) {
                this.AssetBalance = this.Account.assetBalanceDefault;
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        // Utils.log(ex);
        }

        if (!isNullOrUndefined(account)) {
            this.itemId = this.Account.AcctNumber + ':' + assetBalance.Asset.Id;
        } else {
            this.itemId = 0;
        }
        this.userGroupName = '';
    }

    get UseAccountCurrency (): boolean { return true; /* TODO. DataCache.AccountsAC.UseAccCurr */ }

    get CrossPrice (): number {
        return !this.UseAccountCurrency && !isNullOrUndefined(this.AssetBalance)
            ? DataCache.CrossRateCache.GetCrossPriceExp1Exp2(
                this.AssetBalance.Asset.Name,
                DataCache.baseCurrency)
            : 1;
    }

    public override ColumnCount (): number {
        return AssetBalanceItem.columnsParams.length;
    }

    public override GetCurrentAccount (): Account {
        return this.Account;
    }

    // done
    public override getColumnData (column: number): ColumnData {
        const curAccount = this.Account;
        const AssetBalance = this.AssetBalance;

        const value = this.getColumnValue(column);
        let formattedValue = '';

        switch (column) {
        case 0:
            formattedValue = Resources.getResource('panel.accounts.accountDetails.' + value.toString());
            break;
        case 2:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.Balance, curAccount, AssetBalance);
            break;
        case 3:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.BlockedBalance, curAccount, AssetBalance);
            break;

        case 4:
            formattedValue = !isNaN(curAccount.ProfitNet) !== null ? AssetBalance.formatPrice(curAccount.ProfitNet) : Resources.getResource('general.Calculating');
            break;

        case 5:
            formattedValue = AssetBalance.formatPrice(curAccount.Profit); // ????
            break;

        case 6:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.ProjectedBalance, curAccount, AssetBalance);
            break;

        case 14:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.MarginUsedPercent, curAccount, AssetBalance);
            break;

        case 15:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.RiskLevelPercent, curAccount, AssetBalance);
            break;

        case 17:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.MarginWarningLevel, curAccount, AssetBalance);
            break;
        case 19:
            formattedValue = MathUtils.TruncateDouble(curAccount.MarginLevel, 2) + ' %';
            break;

        case 20:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.AvailableFunds, curAccount, AssetBalance);
            break;

        case 21:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.WarningMargin, curAccount, AssetBalance);
            break;

        case 23:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.TotalMaintReq, curAccount, AssetBalance);
            break;
        case 24:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.WithdrawalAvailable, curAccount, AssetBalance);
            break;
        case 29:
            formattedValue = AssetBalance.formatPriceExactly(value);
            break;
        case 30:
            formattedValue = AssetBalance.formatPriceExactly(value);
            break;
        case 31:
            formattedValue = AssetBalance.formatPriceExactly(value);
            break;

        case 32:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.TodayVolume, curAccount, AssetBalance);
            break;
        case 33:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.TodayTrades, curAccount, AssetBalance);
            break;
        case 34:
            formattedValue = AssetBalance.formatPriceExactly(value);
            break;
        case 36:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.CashBalance, curAccount, AssetBalance);
            break;
        case 37:
            formattedValue = AssetBalance.formatPriceExactly(value);
            break;
        case 39:
            formattedValue = AssetBalance.formatPriceExactly(value);
            break;
        case 40:
            formattedValue = AssetBalance.formatPriceExactly(0);
            break;
        case 42:
            // MarginUsed text
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.MarginUsed, curAccount, AssetBalance);
            break;
        case 43:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.BalancePlusAllRisks, curAccount, AssetBalance);
            break;

        case 48:
            formattedValue = value;
            break;

        case 50:
            if (value == -1) {
                formattedValue = '';
            } else {
                formattedValue = this.AssetBalance.formatPrice(value);
            }

            break;

        case 52:
            formattedValue = value > 0 ? value : '';
            break;

        case 53:
            if (value > 0) { formattedValue = value; }
            break;

        case 54:
            if (value > 0) { formattedValue = value; } // или по умолч.
            break;

        case 55:
            if (value <= 0) {
                formattedValue = '';
            } // или по умолч.
            // else if (value == 0)  // commented out:#115409
            //     formattedValue = Resources.getResource("panel.accounts.accountDetails.RiskManagement.MaxOrderCapital.Unlimited"); //FXCM
            else {
                formattedValue = AssetBalance.formatPrice(value);
            }
            break;

        case 58:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.MarginAvailable, curAccount, AssetBalance);
            break;

        case 61:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.CreditValue, curAccount, AssetBalance);
            break;
        case 62:
            formattedValue = Resources.getResource(value ? 'panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled' : 'panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled');
            break;
        case 63:{
            const modePostfix = AccountUtils.GetAccountModeText(curAccount);
            formattedValue = Resources.getResource('panel.accounts.accountDetails.DemoOrLive.' + modePostfix);
            break;
        }

        case 68:
            if (curAccount.AccountType != AccountType.MultiAsset) {
                formattedValue = '';
            } else {
                formattedValue = AssetBalance.formatPrice(AssetBalance.TodayTurnover);
            }
            break;
        case 69:
            formattedValue = Resources.getResource('enum.AssetType.' + AssetType[AssetBalance.Asset.Type]);
            break;
        case 70:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.InterestRatePercent, curAccount, AssetBalance);
            break;
        case 71:
            formattedValue = AssetBalance.formatPriceExactly(value);
            break;
        case 72: // #38604
            formattedValue = AssetBalance.formatPrice(curAccount.BlockedForStocksAccountCurrency);
            break;
        case 73: // #38604
            formattedValue = AssetBalance.formatPrice(value * curAccount.getCrossPrice());
            break;
        case 74:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.BlockedForStocks, curAccount, AssetBalance);
            break;
        case 83:
            formattedValue = AssetBalance.formatPriceExactly(AssetBalance.TodaySwaps);
            break;
        case 84:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.UnsettledCash, curAccount, AssetBalance);
            break;

        case 86:
            if (curAccount.TrailingDrawdownLevel == -1) {
                formattedValue = '';
            } else {
                formattedValue = this.AssetBalance.formatPrice(curAccount.TrailingDrawdownLevel);
            }
            break;
        case 87:
            if (curAccount.TotalMaxPositionsQty == -1) {
                formattedValue = '';
            } else {
                formattedValue = curAccount.TotalMaxPositionsQty.toString();
            }
            break;
        case 88:
            if (curAccount.MaxWeeklyLossLimit == -1) {
                formattedValue = '';
            } else {
                formattedValue = this.AssetBalance.formatPrice(curAccount.MaxWeeklyLossLimit);
            }
            break;
        // эта проверка не баг: по доке отображаем только если включен рул Daily loss limit!=-1.
        case 89:
            if (curAccount.MaxDailyLossLimit == -1) {
                formattedValue = '';
            } else {
                formattedValue = this.AssetBalance.formatPrice(curAccount.CurrentDailyLoss);
            }
            break;
        // аналогично предыдущему, ориентируемся на значение лимита, чтобы отображать/не отображать значение лосса
        case 90:
            if (curAccount.MaxWeeklyLossLimit == -1) {
                formattedValue = '';
            } else {
                formattedValue = this.AssetBalance.formatPrice(curAccount.CurrentWeeklyLoss);
            }
            break;
        case 91:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.StocksOrdersReq, curAccount, AssetBalance);
            break;
        case 92:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.OptionValue, curAccount, AssetBalance);
            break;
        case 93:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.StocksLiquidity, curAccount, AssetBalance);
            break;
        case 94:
            if (curAccount.MaxUrealizedLossLimit == -1) {
                formattedValue = '';
            } else {
                formattedValue = this.AssetBalance.formatPrice(curAccount.ProfitBase > 0 ? 0 : -curAccount.ProfitBase);
            }
            break;
        case 95:
            if (curAccount.MaxUrealizedLossLimit == -1) {
                formattedValue = '';
            } else {
                formattedValue = this.AssetBalance.formatPrice(curAccount.MaxUrealizedLossLimit);
            }
            break;
        case 96:
            if (value != null && value >= 0) {
                formattedValue = AssetBalance.formatPrice(value);
            } else {
                formattedValue = '';
            }
            break;
        // case 97: // <- next 3 cases regrouped below after case 118:
        //     formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForEquities, Account, AssetBalance);
        //     break;
        // case 98:
        //     formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForFutures, Account, AssetBalance);
        //     break;
        // case 99:
        //     formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForOptions, Account, AssetBalance);
        //     break;
        case 100:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.PowerOfAttorney, curAccount, AssetBalance);
            break;
        case 101:
            formattedValue = curAccount.MaxOrderAmount == -1 ? '' : curAccount.MaxOrderAmount.toString();
            break;
        case 102:
            formattedValue = AssetBalance.formatPrice(AssetBalance.OptionPremiumReq);
            break;
        case 103:
            formattedValue = AssetBalance.formatPrice(AssetBalance.Collateral);
            break;
        case 104:
            formattedValue = /* Account.AccountType != AccountType.MultiAsset ? */ // #88247
                (value != null && value != -1 ? AssetBalance.formatPrice(value) : Resources.getResource('general.N_A')); // : '';
            break;
        case 105:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.WarnMarginReq, curAccount, AssetBalance);
            break;
        case 106:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.WarnMarginReqPercent, curAccount, AssetBalance);
            break;
        case 107:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.MarginBeforeWarning, curAccount, AssetBalance);
            break;
        case 108:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.InitialMarginWithoutWaived, curAccount, AssetBalance);
            break;
        case 109:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.BuyingPower, curAccount, AssetBalance);
            break;
        case 110:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.DayTraderPatternProtection, curAccount, AssetBalance);
            break;
        case 111:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.AvailableDayTrades, curAccount, AssetBalance);
            break;
        case 112:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.GivenLeverage, curAccount, AssetBalance);
            break;
        case 113:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.OpenBalance, curAccount, AssetBalance);
            break;
        case 114:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.MaxRelativeDrawDownLevel, curAccount, AssetBalance);
            break;
        case 115:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.StopOut, curAccount, AssetBalance);
            break;
        case 116:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.LossLimitPerTrade, curAccount, AssetBalance);
            break;

        case 117:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.IncomingFunds, curAccount, AssetBalance);
            break;

        case 118:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForALL, curAccount, AssetBalance);
            break;
        case 119:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForFOREX, curAccount, AssetBalance);
            break;
        case 97:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForEQUITIES, curAccount, AssetBalance);
            break;
        case 98:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForFUTURES, curAccount, AssetBalance);
            break;
        case 99:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForOPTIONS, curAccount, AssetBalance);
            break;
        case 120:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForEQUITIES_CFD, curAccount, AssetBalance);
            break;
        case 121:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForFORWARD, curAccount, AssetBalance);
            break;
        case 122:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForCFD_FUTURES, curAccount, AssetBalance);
            break;
        case 123:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForINDICIES, curAccount, AssetBalance);
            break;
        case 124:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForCRYPTO, curAccount, AssetBalance);
            break;
        case 125:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForSPREADBET, curAccount, AssetBalance);
            break;
        case 126:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForBOND, curAccount, AssetBalance);
            break;
        case 127:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForETF, curAccount, AssetBalance);
            break;
        case 128:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForTBILL, curAccount, AssetBalance);
            break;
        case 129:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForSPOT, curAccount, AssetBalance);
            break;
        case 130:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.VolumeLimitForCORPORATE, curAccount, AssetBalance);
            break;
        case 131:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.EODTrailingDrawdownLevel, curAccount, AssetBalance);
            break;
        case 132:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.RelativeDailyLossLimit, curAccount, AssetBalance);
            break;
        case 133 :
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.LiveLeverage, curAccount, AssetBalance);
            break;

        case this.AVAILABLE_CASH:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.AvailableCash, curAccount, AssetBalance);
            break;
        case this.TOTAL_POSITIONS_VALUE:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.TotalPositionsValue, curAccount, AssetBalance);
            break;

        default:
            formattedValue =
                value === null || value === undefined
                    ? ''
                    : value.toString();
        }

        return new ColumnData(value, formattedValue);
    }

    // done
    public override getColumnValue (column: number): any {
        const curAccount = this.Account;
        const AssetBalance = this.AssetBalance;
        const UseAccountCurrency = true;
        const UseAccCurrency = true;// fApp.MultiDataCache.UseAccCurrency;
        switch (column) {
        case 0:
            return /* (Account.User.Status == 0) ? "Offline" : */'Online';
        case 1:
            return curAccount.FullAccString;
        case 2:
            return Account.GetAccountFeature(AccountFeature.Balance, curAccount, AssetBalance);

        case 3:
            return Account.GetAccountFeature(AccountFeature.BlockedBalance, curAccount, AssetBalance);

        case 4:
            return UseAccountCurrency
                ? (curAccount.ProfitNet ? curAccount.ProfitNet : 0)
                : (curAccount.ProfitNetBase ? curAccount.ProfitNetBase : 0);

        case 5:
            return UseAccountCurrency ? curAccount.Profit : curAccount.ProfitBase;

        case 6:
            return Account.GetAccountFeature(AccountFeature.ProjectedBalance, curAccount, AssetBalance);

        case 8:
            if (curAccount.isMasterAccount) {
                // return PammBase.GetNameElementByValue(BackOfficeObjectType.PammFundType, curAccount.FundType.toString()); - there is no such functionality
                return null;
            } else {
                return ' - ';
            }

        case 9:
            // TODO
            return '';
            // if (Account.User.Status == 0) //если offline - ничего не показываем
            //    return "";
            // else
            //    return AssetBalanceItem.GetClientTypeStr(Account.User.ClientType);

        case 10:
            return curAccount.AccountType === AccountType.MultiAsset ? AssetBalance.OpenOrdNumber : curAccount.OpenOrdNumber;

        case 11:
            return curAccount.AccountType === AccountType.MultiAsset ? AssetBalance.OpenPosNumber : curAccount.OpenPosNumber;

        case 14:
            return Account.GetAccountFeature(AccountFeature.MarginUsedPercent, curAccount, AssetBalance);

        case 15:
            return Account.GetAccountFeature(AccountFeature.RiskLevelPercent, curAccount, AssetBalance);

        case 17:
            return Account.GetAccountFeature(AccountFeature.MarginWarningLevel, curAccount, AssetBalance);

        case 19:
            return curAccount.MarginLevel;

        case 20:
            return Account.GetAccountFeature(AccountFeature.AvailableFunds, curAccount, AssetBalance);

        case 21:
            return Account.GetAccountFeature(AccountFeature.WarningMargin, curAccount, AssetBalance);

        case 23:
            return Account.GetAccountFeature(AccountFeature.TotalMaintReq, curAccount, AssetBalance);

        case 24:
            return Account.GetAccountFeature(AccountFeature.WithdrawalAvailable, curAccount, AssetBalance);

        case 25:
            return curAccount.userID;
        case 26:
            return curAccount.userLogin;
        case 27:
            return curAccount.Owner;
        case 28:
            return curAccount.Email;
        case 29:
            return UseAccountCurrency ? AssetBalance.TodayGrossPNL : AssetBalance.TodayGrossPNLCrossPrice;

        case 30:
            return UseAccountCurrency ? AssetBalance.TodayFees : AssetBalance.TodayFeesCrossPrice;

        case 31:
            return this.UseAccountCurrency
                ? AssetBalance.TodayRealizedPNL
                : AssetBalance.TodayRealizedPNLCrossPrice;
        case 32:
            return Account.GetAccountFeature(AccountFeature.TodayVolume, curAccount, AssetBalance);

        case 33:
            return Account.GetAccountFeature(AccountFeature.TodayTrades, curAccount, AssetBalance);

        case 34:
            return UseAccCurrency ? curAccount.StockValue : curAccount.StockValueCrossPrice;

        case 36:
            return Account.GetAccountFeature(AccountFeature.CashBalance, curAccount, AssetBalance);

        case 37:
            return UseAccountCurrency ? (/* AssetBalance.BlockedForPAMM + */ curAccount.StartPammCapital) : (/* AssetBalance.BlockedForPammCrossPrice + */ curAccount.StartPammCapitalCrossPrice);

        case 38:
            if (this.userGroupName != null) {
                return this.userGroupName;
            } else {
                return '';
            }

        case 39:
            return UseAccountCurrency ? (curAccount.CurrPammCapital /* + Account.StartPammCapital */) : (curAccount.CurrPammCapitalCrossPrice/* + Account.StartPammCapitalCrossPrice */);

        case 40:{
            return 0.0;
        }

        case 41:
            return !curAccount.TelephoneNumber ? Resources.getResource('panel.accounts.N/A_TelephoneNumber') : curAccount.TelephoneNumber;

        case 42:
            return Account.GetAccountFeature(AccountFeature.MarginUsed, curAccount, AssetBalance);

        case 43:
            return Account.GetAccountFeature(AccountFeature.BalancePlusAllRisks, curAccount, AssetBalance);

        case 48:
            return this.GetCurrentTradingStatusLocalized(curAccount);
        case 50:
            return curAccount.MaxDailyLossLimit;

        case 52:
            return DataCache.getRuleNumberValueForAccount(RulesSet.VALUE_MAX_DAY_ORDERS, curAccount, -1); // hsa: FIELD_MAX_ORDERS_PER_DAY?

        case 53:
            return curAccount.MaxPositions >= 0 ? curAccount.MaxPositions : 0;

        case 54:
            return curAccount.MaxPendingOrders >= 0 ? curAccount.MaxPendingOrders : 0;

        case 55:
            return curAccount.MaxOrderCapital != -1 ? curAccount.MaxOrderCapital : 0;

        case 58:
            return Account.GetAccountFeature(AccountFeature.MarginAvailable, curAccount, AssetBalance);

        case 61:
            return Account.GetAccountFeature(AccountFeature.CreditValue, curAccount, AssetBalance);

        case 62:
            return curAccount.AllowOvernightTrading;

        case 63:
            return curAccount.AccountMode;

        case 64:
            return AssetBalance.Asset.Name;

        case 65:
            return AssetBalance.Asset.Description;

        case 67:
            return Resources.getResource('enum.AccountType.' + getAccountTypeStr(curAccount.AccountType));

        case 68:
            return AssetBalance.TodayTurnover;

        case 69:
            return AssetBalance.Asset.Type;

        case 70:
            return Account.GetAccountFeature(AccountFeature.InterestRatePercent, curAccount, AssetBalance);

        case 71:
            return UseAccountCurrency ? AssetBalance.TodayRebates : AssetBalance.TodayRebatesCrossPrice;

        case 72: // #38604
            return curAccount.BlockedForStocksAccountCurrency / curAccount.getCrossPrice(); // в валюте сервера

        case 73: // #38604
            const marginUsed = Account.GetAccountFeature(AccountFeature.MarginUsed, curAccount, AssetBalance);
            return ((marginUsed - curAccount.BlockedForStocksAccountCurrency) / curAccount.getCrossPrice());

        case 74:
            return Account.GetAccountFeature(AccountFeature.BlockedForStocks, curAccount, AssetBalance);
        case 82:
            return curAccount.AccountDescription;

        case 83:
            return UseAccCurrency ? AssetBalance.TodaySwaps : AssetBalance.TodaySwapsCrossPrice;

        case 84:
            return Account.GetAccountFeature(AccountFeature.UnsettledCash, curAccount, AssetBalance);

        case 86:
            if (curAccount.TrailingDrawdownLevel === -1) { return -1; }

            return curAccount.TrailingDrawdownLevel;

        case 87:
            return curAccount.TotalMaxPositionsQty;

        case 88:
            if (curAccount.MaxWeeklyLossLimit == -1) { return 0; }

            return curAccount.MaxWeeklyLossLimit;

        case 89:
            if (curAccount.MaxDailyLossLimit == -1) { return 0; }

            return curAccount.CurrentDailyLoss;

        case 90:
            if (curAccount.MaxWeeklyLossLimit == -1) { return 0; }

            return curAccount.CurrentWeeklyLoss;

        case 91:
            return Account.GetAccountFeature(AccountFeature.StocksOrdersReq, curAccount, AssetBalance);

        case 92:
            return Account.GetAccountFeature(AccountFeature.OptionValue, curAccount, AssetBalance);

        case 93:// #62325
            return Account.GetAccountFeature(AccountFeature.StocksLiquidity, curAccount, AssetBalance);

        case 94:
            if (curAccount.MaxUrealizedLossLimit == -1) { return 0; }

            return curAccount.ProfitBase > 0 ? 0 : -curAccount.ProfitBase;

        case 95:
            if (curAccount.MaxUrealizedLossLimit == -1) { return 0; }

            return curAccount.MaxUrealizedLossLimit;

        case 96:
            return 'TODO';// RulesSet.GetRuleValue(RulesSet.VALUE_DAILY_PROFIT_TARGET, Account);

        case 97:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForEQUITIES, curAccount, AssetBalance);

        case 98:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForFUTURES, curAccount, AssetBalance);

        case 99:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForOPTIONS, curAccount, AssetBalance);

        case 100:
            return Account.GetAccountFeature(AccountFeature.PowerOfAttorney, curAccount, AssetBalance);

        case 101:
            return curAccount.MaxOrderAmount;

        case 102:
            return AssetBalance.OptionPremiumReq;

        case 103:
            return AssetBalance.Collateral;

        case 104:
            return curAccount.WaivedMargin;

        case 105:
            return Account.GetAccountFeature(AccountFeature.WarnMarginReq, curAccount, AssetBalance);

        case 106:
            return Account.GetAccountFeature(AccountFeature.WarnMarginReqPercent, curAccount, AssetBalance);

        case 107:
            return Account.GetAccountFeature(AccountFeature.MarginBeforeWarning, curAccount, AssetBalance);

        case 108:
            return Account.GetAccountFeature(AccountFeature.InitialMarginWithoutWaived, curAccount, AssetBalance);

        case 109:
            return Account.GetAccountFeature(AccountFeature.BuyingPower, curAccount, AssetBalance);

        case 110:
            return Account.GetAccountFeature(AccountFeature.DayTraderPatternProtection, curAccount, AssetBalance);

        case 111:
            return Account.GetAccountFeature(AccountFeature.AvailableDayTrades, curAccount, AssetBalance);

        case 112:
            return Account.GetAccountFeature(AccountFeature.GivenLeverage, curAccount, AssetBalance);

        case 113:
            return Account.GetAccountFeature(AccountFeature.OpenBalance, curAccount, AssetBalance);

        case 114:
            return Account.GetAccountFeature(AccountFeature.MaxRelativeDrawDownLevel, curAccount, AssetBalance);

        case 115:
            return Account.GetAccountFeature(AccountFeature.StopOut, curAccount, AssetBalance);

        case 116:
            return Account.GetAccountFeature(AccountFeature.LossLimitPerTrade, curAccount, AssetBalance);

        case 117:
            return Account.GetAccountFeature(AccountFeature.IncomingFunds, curAccount, AssetBalance);

        case 118:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForALL, curAccount, AssetBalance);

        case 119:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForFOREX, curAccount, AssetBalance);

        case 120:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForEQUITIES_CFD, curAccount, AssetBalance);

        case 121:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForFORWARD, curAccount, AssetBalance);

        case 122:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForCFD_FUTURES, curAccount, AssetBalance);

        case 123:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForINDICIES, curAccount, AssetBalance);

        case 124:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForCRYPTO, curAccount, AssetBalance);

        case 125:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForSPREADBET, curAccount, AssetBalance);

        case 126:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForBOND, curAccount, AssetBalance);

        case 127:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForETF, curAccount, AssetBalance);

        case 128:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForTBILL, curAccount, AssetBalance);

        case 129:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForSPOT, curAccount, AssetBalance);

        case 130:
            return Account.GetAccountFeature(AccountFeature.VolumeLimitForCORPORATE, curAccount, AssetBalance);

        case 131:
            return Account.GetAccountFeature(AccountFeature.EODTrailingDrawdownLevel, curAccount, AssetBalance);

        case 132:
            return Account.GetAccountFeature(AccountFeature.RelativeDailyLossLimit, curAccount, AssetBalance);

        case 133:
            return Account.GetAccountFeature(AccountFeature.LiveLeverage, curAccount, AssetBalance);

        case this.AVAILABLE_CASH:
            return Account.GetAccountFeature(AccountFeature.AvailableCash, curAccount, AssetBalance);

        case this.TOTAL_POSITIONS_VALUE:
            return Account.GetAccountFeature(AccountFeature.TotalPositionsValue, curAccount, AssetBalance);

        default:
            return null;
        }
    }

    public override GetColumnParams (i: number): ColumnParams {
        const result = AssetBalanceItem.columnsParams[i];

        result.MenuIndex = this.GetMenuIndex(result.HeaderKey);
        result.TooltipKey = result.HeaderKey + '.descr';

        const dc = DataCache;
        // let sess = SessionSettings

        if (!dc) {
            return result;
        }

        // TODO. User obj. Refactor.
        // let checkRuleUser = sess.LoginUser || dc.LoginUser

        // TODO.
        // #22483 Admin и видимость полей
        // TODO. UserAdminRule.
        const isUSERSADMIN = false;
        // bool isUSERSADMIN = dc.isAllowedForUser(RulesSet.FUNCTION_USERSADMIN, checkRuleUser)

        if (!isUSERSADMIN && (i === 0 || i === 7 || i === 8 || i === 9 || i === 38)) {
            result.Hidden = true;
            return result;
        }

        const Account = this.Account;
        if (Account && dc) {
            if ((i === 0 || i === 7 || i === 8 || i === 9) && !isUSERSADMIN) {
                result.Hidden = true;
            } else if (i === 7) {
                result.Hidden = true || Account.isMasterAccount;
            } else {
                result.Hidden = false;
            }

            if (i === 68) // #33209
            {
                result.Hidden = Account.AccountType !== AccountType.MultiAsset;
            }

            if (i === 110 || i === 111) {
                result.Hidden = !Account.DayTraderPattern;
            }
        } else {
            if ((i === 0) && !isUSERSADMIN) {
                result.Hidden = true;
            } else {
                result.Hidden = i === 7;
            }

            if (i === 68) {
            // #33209, #33495
                let hasMultiAcc = false;
                const accDict = dc.getAccounts();
                for (const accId in accDict) {
                    const acc = accDict[accId];
                    if (acc.AccountType === AccountType.MultiAsset) {
                        hasMultiAcc = true;
                        break;
                    }
                }
                result.Hidden = !hasMultiAcc;
            }
        }

        // ну, и в любом случае если ключ колонки захайден
        if (Resources.isHidden(result.HeaderKey)) {
            result.Hidden = true;
        }

        if (i === 66 || i === 10 || i === 11 || i === 6) {
            result.TextCompareColumn = true;
        }

        if (AssetBalanceItem.HiddenTable[i]) {
            result.Hidden = true;
        }

        // все колонки кроме этих нужно скрыть если приходит рул
        if (i !== 31 && i !== 27 && i !== 26 && i !== 63) {
            if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_EXT_RISK)) {
                result.Hidden = true;
            }
        }

        return result;
    }

    public override fireUpdate (): void {

    }

    public override GetDataType (columnIndex): QuickTableComparingType {
        if (columnIndex < AssetBalanceItem.dataTypes.length) {
            return AssetBalanceItem.dataTypes[columnIndex];
        } else {
            return QuickTableComparingType.Double;
        }
    }

    public static readonly dataTypes =
        [
            QuickTableComparingType.String, // 0
            QuickTableComparingType.String,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.String,
            QuickTableComparingType.String,
            QuickTableComparingType.String,
            QuickTableComparingType.Int, // 10
            QuickTableComparingType.Int,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double, // 20
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.String,
            QuickTableComparingType.String,
            QuickTableComparingType.String,
            QuickTableComparingType.String,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double, // 30
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Int,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.String,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double, // 40
            QuickTableComparingType.String,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double, // 45
            QuickTableComparingType.Double,

            QuickTableComparingType.String,
            QuickTableComparingType.String,
            QuickTableComparingType.String,
            QuickTableComparingType.Double, // 50
            QuickTableComparingType.Double,
            QuickTableComparingType.Int,
            QuickTableComparingType.Int,
            QuickTableComparingType.Int,
            QuickTableComparingType.Double, // 55
            QuickTableComparingType.Int,
            QuickTableComparingType.Int,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double, // 60
            QuickTableComparingType.Double,
            QuickTableComparingType.String, // 62
            QuickTableComparingType.Int,
            QuickTableComparingType.String,
            QuickTableComparingType.String,
            QuickTableComparingType.Double,
            QuickTableComparingType.String,
            QuickTableComparingType.Double,
            QuickTableComparingType.Int,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double, // 71
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            // #43336
            QuickTableComparingType.String, // 75
            QuickTableComparingType.String, // 76
            QuickTableComparingType.String, // 77
            QuickTableComparingType.String, // 78
            QuickTableComparingType.String, // 79
            QuickTableComparingType.String, // 80
            QuickTableComparingType.String,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double, // 85
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double, // 90
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double, // 95
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.String, // 100
            QuickTableComparingType.Int,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double,
            QuickTableComparingType.Double
        ];

    // Здесь хранятся настройки колонок (один экземпляр на класс)
    public static readonly columnsParams = [
        /* 0 */ new ColumnParams('panel.accounts.Status', 60, 0, false, false, true),
        new ColumnParams('panel.accounts.Login', 100, 0, true, true, true),
        new ColumnParams('panel.accounts.Balance', 60, 2, true, true, false),
        new ColumnParams('panel.accounts.LockedBalance', 60, 2, true, false, false),
        new ColumnParams('panel.accounts.ProfitNet', 75, 2, true, false, false),
        /* 5 */ new ColumnParams('panel.accounts.Profit', 60, 2, true, false, false),
        new ColumnParams('panel.accounts.CurBalance', 60, 2, true, false, false),
        new ColumnParams('panel.accounts.LockedBy', 60, 0, false, false, true),
        new ColumnParams('panel.accounts.Role', 60, 0, false, false, true),
        new ColumnParams('panel.accounts.ClientType', 60, 0, false, false, true),
        /* 10 */ new ColumnParams('panel.accounts.Orders', 60, 1, true, false, false),
        new ColumnParams('panel.accounts.Positions', 60, 1, true, false, false),
        new ColumnParams('panel.accounts.MinMargin', 60, 1, true, false, false),
        new ColumnParams('panel.accounts.BlockedByOrders', 75, 2, true, false, false),
        new ColumnParams('panel.accounts.CurMargin', 60, 1, false, false, false),
        /* 15 */ new ColumnParams('panel.accounts.MaintMargin', 60, 1, false, false, false),
        new ColumnParams('panel.accounts.Leverage', 60, 1, false, false, false),
        new ColumnParams('panel.accounts.marginWarnPercent', 75, 2, false, false, false),
        new ColumnParams('panel.accounts.stopTradingPercent', 75, 2, false, false, false),
        new ColumnParams('panel.accounts.stopOutPercent', 75, 2, false, false, false),
        /* 20 */ new ColumnParams('panel.accounts.AvailableMargin', 60, 1, true, true, false),
        new ColumnParams('panel.accounts.MarginWarning', 60, 1, true, false, false),
        new ColumnParams('panel.accounts.MarginStopTrade', 60, 1, false, false, false),
        new ColumnParams('panel.accounts.MarginStopOut', 60, 1, true, false, false),
        new ColumnParams('panel.accounts.WithdrawalAvailable', 60, 1, true, true, false),
        /* 25 */ new ColumnParams('panel.accounts.UserID', 75, 0, false, false, true),
        new ColumnParams('panel.accounts.UserLogin', 75, 0, false, true, true),
        new ColumnParams('panel.accounts.Owner', 75, 0, false, false, true, false, true),
        new ColumnParams('panel.accounts.Email', 75, 0, false, false, true),
        new ColumnParams('panel.accounts.GrossPNL', 75, 1, true, false, false, false, true),
        /* 30 */ new ColumnParams('panel.accounts.TodayFees', 75, 1, true, false, false),
        new ColumnParams('panel.accounts.BeginBalance', 75, 1, true, false, false),
        new ColumnParams('panel.accounts.TodayVolume', 75, 2, true, true, false),
        new ColumnParams('panel.accounts.TodayTrades', 75, 2, true, true, false),
        new ColumnParams('panel.accounts.StocksValue', 75, 1, true, false, false),
        /* 35 */ new ColumnParams('panel.accounts.AccountValue', 75, 1, true, false, false),
        new ColumnParams('panel.accounts.CashBalance', 75, 2, true, false, false),
        new ColumnParams('panel.accounts.BlockedByPamm', 75, 2, true, false, false),
        new ColumnParams('panel.accounts.UserGroup', 70, 0, false, false, true),
        new ColumnParams('panel.accounts.CurrentFundCapital', 75, 2, true, false, false, false, true),
        /* 40 */ new ColumnParams('panel.accounts.FundCapitalGain', 75, 2, true, false, false),
        new ColumnParams('panel.accounts.TelephoneNumber', 75, 0, false, false, true),
        new ColumnParams('panel.accounts.MarginUsedNew', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        new ColumnParams('panel.accounts.BalancePlusAllRisks', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.MarginRequirement', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        /* 45 */ new ColumnParams('panel.accounts.MarginDeficiency', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.MarginSurplus', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),

        /* 47 */ new ColumnParams('panel.accounts.IntradayRiskManagement', 60, 0, true, false, false),
        new ColumnParams('panel.accounts.CurrentStatus', 60, 0, false, false, false, false, true),
        /* 49 */ new ColumnParams('panel.accounts.AllowTradingPrePost', 60, 0, true, false, false),
        // в этом блоке value возвращает -1, если считать тоталы, надо учесть
        /* 50 */ new ColumnParams('panel.accounts.MaxDailyLossLimit', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, false, false, -1.0),
        new ColumnParams('panel.accounts.MaxDayVolume', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, false, false, -1.0),
        /* 52 */ new ColumnParams('panel.accounts.MaxOrdersPerDay', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.accounts.MaxPositions', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.accounts.MaxPendingOrders', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        /* 55 */ new ColumnParams('panel.accounts.MaxOrderCapital', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, false, false, -1.0),
        new ColumnParams('panel.accounts.MaximumLot', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.accounts.MaxPositionQtyPerSymbol', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.accounts.MarginAvailableReal', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.FundsUsed', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false, false, true),
        /* 60 */ new ColumnParams('panel.accounts.FundsUsedPercent', 60, 2, false, false, false),
        new ColumnParams('panel.accounts.CreditValue', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.AllowOvernightTrading', 60, QuickTableColumnType.COL_BOOLEAN, false, false, false),
        new ColumnParams('panel.accounts.AccountMode', 60, 0, false, false, false),
        new ColumnParams('panel.accounts.Asset', 60, 0, false, true, false),
        new ColumnParams('panel.accounts.AssetDescription', 60, 0, true, false, false),
        new ColumnParams('panel.accounts.Estimate value', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        /* 67 */ new ColumnParams('panel.accounts.AccountType', 60, 0, true, true, false),
        new ColumnParams('panel.accounts.TodayTurnover', 60, 0, true, false, false),
        new ColumnParams('panel.accounts.AssetType', 60, 0, true, false, false),
        new ColumnParams('panel.accounts.InterestRate', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        /* 71 */ new ColumnParams('panel.accounts.TodayRebates', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.BlockedForStocks', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.accounts.MarginForOthers', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.accounts.BlockedForStocksNew', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.accounts.Commissions', 60, QuickTableColumnType.COL_DEFAULT, true, false, true),
        new ColumnParams('panel.accounts.Swap', 60, QuickTableColumnType.COL_DEFAULT, true, false, true),
        new ColumnParams('panel.accounts.Spreads', 60, QuickTableColumnType.COL_DEFAULT, true, false, true),
        new ColumnParams('panel.accounts.Murkups', 60, QuickTableColumnType.COL_DEFAULT, true, false, true),
        new ColumnParams('panel.accounts.MarketDataCharging', 60, QuickTableColumnType.COL_DEFAULT, true, false, true),
        /* 80 */ new ColumnParams('panel.accounts.MarginAndRisks', 60, QuickTableColumnType.COL_DEFAULT, true, false, true),
        new ColumnParams('panel.accounts.InsertOnBalance', 60, QuickTableColumnType.COL_DEFAULT, true, false, true),
        // Колонка спциально заведена для запихивания всякого мусора по аккаунту
        new ColumnParams('panel.accounts.AccountDescription', 60, 0, true, false, false),
        new ColumnParams('panel.accounts.TodaySwaps', 60, 0, true, false, false),
        new ColumnParams('panel.accounts.UnsettledCash', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        /* 85 */ new ColumnParams('panel.accounts.MaxPendingOrdersValue', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.accounts.TrailingDrawdownLevel', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false, false, false, -1.0),
        new ColumnParams('panel.accounts.TotalMaxPositionsQty', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false, false, false, -1.0),
        new ColumnParams('panel.accounts.MaxWeekLostLimit', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false, false, false, -1.0),
        new ColumnParams('panel.accounts.CurrentDailyLoss', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        /* 90 */ new ColumnParams('panel.accounts.CurrentWeeklyLoss', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.StocksOrdersReq', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.OptionValue', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.StocksLiquidity', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.UnrealizedLoss', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        /* 95 */ new ColumnParams('panel.accounts.UnrealizedLossLimit', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false, false, false, -1.0),
        new ColumnParams('panel.accounts.MaxDailyProfit', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForEQUITIES', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForFUTURES', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForOPTIONS', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        /* 100 */ new ColumnParams('panel.accounts.PowerOfAttorney', 60, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.accounts.MaxOrderAmount', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false, false, false, -1),
        new ColumnParams('panel.accounts.OptionPremiumReq', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.Collateral', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        new ColumnParams('panel.accounts.WaivedMargin', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        /* 105 */ new ColumnParams('panel.accounts.WarningMarginReq', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.WarningMarginReqPercent', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.MarginBeforeWarning', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.InitialMarginWithoutWaived', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.BuyingPower', 60, QuickTableColumnType.COL_DEFAULT, true, true, false),
        /* 110 */ new ColumnParams('panel.accounts.DayTraderPatternProtection', 60, QuickTableColumnType.COL_DEFAULT, true, true, false),
        new ColumnParams('panel.accounts.AvailableDayTrades', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        new ColumnParams('panel.accounts.GivenLeverage', 60, QuickTableColumnType.COL_DEFAULT, true, true, false),
        new ColumnParams('panel.accounts.OpenBalance', 60, QuickTableColumnType.COL_DEFAULT, true, true, false),
        new ColumnParams('panel.accounts.MaxRelativeDrawDownLevel', 60, QuickTableColumnType.COL_DEFAULT, true, true, false),
        /* 115 */ new ColumnParams('panel.accounts.StopOut', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.LossLimitPerTrade', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.accounts.IncomingFunds', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.accounts.VolumeLimitForALL', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForFOREX', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        /* 120 */ new ColumnParams('panel.accounts.VolumeLimitForEQUITIES_CFD', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForFORWARD', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForCFD_FUTURES', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForINDICIES', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForCRYPTO', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        /* 125 */ new ColumnParams('panel.accounts.VolumeLimitForSPREADBET', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForBOND', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForETF', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForTBILL', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.VolumeLimitForSPOT', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        /* 130 */ new ColumnParams('panel.accounts.VolumeLimitForCORPORATE', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.EODTrailingDrawdownLevel', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.RelativeDailyLossLimit', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.LiveLeverage', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
        new ColumnParams('panel.accounts.AvailableCash', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.accounts.TotalPositionsValue', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false)
    ];

    public override NeedSortMenu (): boolean {
        return true;
    }

    public GetMenuIndex (headerKey: string): number {
        if (AssetBalanceItem.ListMenuIndex[headerKey]) {
            return AssetBalanceItem.ListMenuIndex[headerKey];
        } else {
            return 10000;
        }
    }

    public static readonly ListMenuIndex = {
    // sorted
        'panel.accounts.Balance': 0,
        'panel.accounts.CurBalance': 10,
        'panel.accounts.BalancePlusAllRisks': 20,
        'panel.accounts.AccountValue': 30,
        'panel.accounts.CreditValue': 40,
        'panel.accounts.AvailableMargin': 50,
        'panel.accounts.stopOutPercent': 60,
        'panel.accounts.LockedBalance': 70,
        'panel.accounts.CashBalance': 80,
        'panel.accounts.UnsettledCash': 90,
        'panel.accounts.IncomingFunds': 91,
        'panel.accounts.WithdrawalAvailable': 100,
        'panel.accounts.InterestRate': 110,
        'panel.accounts.Estimate value': 120,
        'panel.accounts.FundsUsed': 130,
        'panel.accounts.FundsUsedPercent': 140,
        'panel.accounts.MarginUsedNew': 150,
        'panel.accounts.CurMargin': 160,
        'panel.accounts.MaintMargin': 170,
        'panel.accounts.Leverage': 180,
        'panel.accounts.MarginAvailableReal': 190,
        'panel.accounts.marginWarnPercent': 200,
        'panel.accounts.MarginWarning': 210,
        'panel.accounts.MarginStopOut': 220,
        'panel.accounts.MarginRequirement': 230,
        'panel.accounts.MinMargin': 240,
        'panel.accounts.BlockedByOrders': 250,
        'panel.accounts.BlockedForStocksNew': 260,
        'panel.accounts.BlockedForStocks': 270,
        'panel.accounts.StocksValue': 280,
        'panel.accounts.OptionValue': 281,
        'panel.accounts.MarginForOthers': 290,
        'panel.accounts.StocksOrdersReq': 300,
        'panel.accounts.StocksLiquidity': 305,
        'panel.accounts.OptionPremiumReq': 306,
        'panel.accounts.WaivedMargin': 307,
        'panel.accounts.Collateral': 308,
        'panel.accounts.BuyingPower': 309,
        'panel.accounts.GivenLeverage': 310,

        'panel.accounts.CurrentFundCapital': 310,
        'panel.accounts.FundCapitalGain': 320,
        'panel.accounts.Profit': 330,
        'panel.accounts.ProfitNet': 340,
        'panel.accounts.Positions': 350,
        'panel.accounts.Orders': 360,
        'panel.accounts.StopOut': 361,
        // Unsettled cash -  not found
        'panel.accounts.BlockedByPamm': 370,
        'panel.accounts.GrossPNL': 380,
        'panel.accounts.BeginBalance': 390,
        'panel.accounts.TodayFees': 400,
        'panel.accounts.TodayVolume': 410,
        'panel.accounts.TodayTrades': 420,
        'panel.accounts.TodayTurnover': 430,
        'panel.accounts.TodayRebates': 440,
        'panel.accounts.CurrentStatus': 450,
        'panel.accounts.AllowTradingPrePost': 460,
        'panel.accounts.MaxDailyLossLimit': 470,
        'panel.accounts.LossLimitPerTrade': 471,

        'panel.accounts.MaxWeekLostLimit': 480,
        'panel.accounts.TotalMaxPositionsQty': 490,
        'panel.accounts.TrailingDrawdownLevel': 500,
        'panel.accounts.CurrentDailyLoss': 510,
        'panel.accounts.CurrentWeeklyLoss': 520,
        'panel.accounts.EODTrailingDrawdownLevel': 530,

        // "panel.accounts.MaxDayVolume": 530,
        'panel.accounts.MaxRelativeDrawDownLevel': 535,
        'panel.accounts.MaxOrdersPerDay': 540,
        'panel.accounts.MaxPositions': 550,
        'panel.accounts.MaxPendingOrders': 560,
        'panel.accounts.MaxOrderAmount': 565,
        'panel.accounts.MaxOrderCapital': 570,
        'panel.accounts.MaximumLot': 580,
        'panel.accounts.MaxPositionQtyPerSymbol': 590,
        'panel.accounts.AllowOvernightTrading': 600,

        'panel.accounts.VolumeLimitForALL': 602,
        'panel.accounts.VolumeLimitForFOREX': 603,
        'panel.accounts.VolumeLimitForEQUITIES': 604,
        'panel.accounts.VolumeLimitForFUTURES': 605,
        'panel.accounts.VolumeLimitForOPTIONS': 606,
        'panel.accounts.VolumeLimitForEQUITIES_CFD': 607,
        'panel.accounts.VolumeLimitForFORWARD': 608,
        'panel.accounts.VolumeLimitForCFD_FUTURES': 609,
        'panel.accounts.VolumeLimitForINDICIES': 610,
        'panel.accounts.VolumeLimitForCRYPTO': 611,
        'panel.accounts.VolumeLimitForSPREADBET': 612,
        'panel.accounts.VolumeLimitForBOND': 613,
        'panel.accounts.VolumeLimitForETF': 614,
        'panel.accounts.VolumeLimitForTBILL': 615,
        'panel.accounts.VolumeLimitForSPOT': 616,
        'panel.accounts.VolumeLimitForCORPORATE': 617,

        'panel.accounts.Owner': 620,
        'panel.accounts.UserID': 625,
        'panel.accounts.UserLogin': 630,
        'panel.accounts.UserGroup': 640,
        'panel.accounts.Email': 650,
        'panel.accounts.Login': 660,
        'panel.accounts.AccountType': 670,
        'panel.accounts.LockedBy': 680,
        'panel.accounts.AccountMode': 690,
        'panel.accounts.ClientType': 700,
        'panel.accounts.Status': 710,
        'panel.accounts.TelephoneNumber': 720,
        'panel.accounts.Asset': 730,
        'panel.accounts.AssetDescription': 740,
        'panel.accounts.AssetType': 750,

        // end sorted

        'panel.accounts.Role': 760,
        /* hidden */ 'panel.accounts.stopTradingPercent': 770,

        /* hidden */ 'panel.accounts.MarginStopTrade': 780,
        /* 45 */ 'panel.accounts.MarginDeficiency': 790,
        'panel.accounts.MarginSurplus': 800,
        'panel.accounts.IntradayRiskManagement': 810,
        'panel.accounts.WarningMarginReq': 820,
        'panel.accounts.WarningMarginReqPercent': 825,
        'panel.accounts.MarginBeforeWarning': 830,
        'panel.accounts.InitialMarginWithoutWaived': 835,
        'panel.accounts.LiveLeverage': 840
    };

    public static readonly HiddenTable = {
        // 1 : true,
        7: true,

        12: true,
        13: true,
        16: true,
        18: true,

        22: true,

        35: true,
        37: true,
        39: true,

        40: true,
        44: true,
        45: true,
        46: true,
        47: true,
        49: true,

        // 52 : true,
        56: true,
        57: true,
        59: true,

        60: true,
        66: true,

        75: true,
        76: true,
        77: true,
        78: true,
        79: true,

        80: true,
        81: true,
        85: true,

        // InfoFields
        69: true,
        65: true,
        // 64 : true,
        41: true,
        63: true,
        // 67 : true,
        28: true,
        25: true,
        26: true,
        27: true
    };

    public static GetClientTypeStr (ClientType): string {
    // TODO MB
    // if (ClientType == User.CLIENT_NET)
    //    return ".Net";
    // else if (ClientType == User.CLIENT_HTML)
    //    return "Html";
    // else if (ClientType == User.CLIENT_WEB)
        return 'Web';
    // else if (ClientType == User.CLIENT_API)
    //    return "Api";
    // else if (ClientType == User.CLIENT_JAVA)
    //    return "Java";
    // else if (ClientType == User.CLIENT_POCKET)
    //    return "Pocket";
    // else if (ClientType == User.CLIENT_MOBILE)
    //    return "Mobile";
    // else
    //    return "";
    }

    public static Localize (locale): void {
        AssetBalanceItem.activeStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Active');
        AssetBalanceItem.closedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed');
        AssetBalanceItem.suspendStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend');
        AssetBalanceItem.autoClosedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed');
        AssetBalanceItem.finraDayTraderPatternStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.FinraDayTraderStatus');
        AssetBalanceItem.liquidationOnlyStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.LiquidationOnlyStatus');
        AssetBalanceItem.maxGrosslossReachedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached');
        AssetBalanceItem.maxDayVolumeReachedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached');
        AssetBalanceItem.orderFlowLimitStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)');
        AssetBalanceItem.disabledByRiskRules = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules');
        AssetBalanceItem.weeklyLostLimit = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached');
        AssetBalanceItem.trailingDrawdownLimit = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached');
        AssetBalanceItem.maxUnrealizedLoss = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached');
        AssetBalanceItem.maxOrdersPerDay = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules.maxOrdersPerDay');
        AssetBalanceItem.relativeDrawDownLevelReachedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Max relative drawdown level was reached');
        AssetBalanceItem.eodTrailingDrawdownReachedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.EOD trailing drawdown level was reached');
        AssetBalanceItem.relativeDailyLossLimitReachedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Relative daily loss limit was reached');
        AssetBalanceItem.currentLocale = locale;
    }

    public GetCurrentTradingStatusLocalized (acc: Account, locale = null): string {
        const curlocale = locale != null ? locale : GeneralSettings.General.Language;
        if (curlocale != AssetBalanceItem.currentLocale) {
            AssetBalanceItem.Localize(curlocale);
        }

        let status = '';
        switch (acc.AccountTradeStatus) {
        case AccountTradeStatus.ACTIVE_STATUS:
            status = AssetBalanceItem.activeStatus;
            break;

        case AccountTradeStatus.CLOSED_STATUS:
            status = AssetBalanceItem.closedStatus;
            break;

        case AccountTradeStatus.SUSPEND_STATUS:
            status = AssetBalanceItem.suspendStatus;
            break;
        case AccountTradeStatus.TRADING_DISABLED_RISK_STATUS:
            status = AssetBalanceItem.autoClosedStatus;
            break;

        case AccountTradeStatus.FINRA_DAY_TRADER_PATTERN_STATUS:
            status = AssetBalanceItem.finraDayTraderPatternStatus;
            break;

        case AccountTradeStatus.LIQUIDATION_ONLY_STATUS:
            status = AssetBalanceItem.liquidationOnlyStatus;
            break;
        }

        let reason = '';
        switch (acc.StopTradingReason) {
        case Account.STOP_TRADING_NONE:
        case Account.STOP_TRADING_UNKNOWN:
            reason = '---';
            break;

        case Account.STOP_TRADING_MAX_LOSS:
            reason = AssetBalanceItem.maxGrosslossReachedStatus;
            break;

        case Account.STOP_TRADING_MAX_DAY_VOL:
            reason = AssetBalanceItem.maxDayVolumeReachedStatus;
            break;

        case Account.STOP_TRADING_WEEKLY_LOST_LIMIT:
            reason = AssetBalanceItem.weeklyLostLimit;
            break;

        case Account.STOP_TRADING_TRAILING_DRAWDOWN_LIMIT:
            reason = AssetBalanceItem.trailingDrawdownLimit;
            break;

        case Account.STOP_TRADING_MAX_UNREALIZED_LOSS:
            reason = AssetBalanceItem.maxUnrealizedLoss;
            break;

        case Account.STOP_TRADING_MAX_ORDERS_COUNT_PER_DAY:
            reason = AssetBalanceItem.maxOrdersPerDay;
            break;

        case Account.STOP_TRADING_RELATIVE_DRAWDOWN_LEVEL:
            reason = AssetBalanceItem.relativeDrawDownLevelReachedStatus;
            break;

        case Account.STOP_TRADING_EOD_TRAILING_DRAWDOWN:
            reason = AssetBalanceItem.eodTrailingDrawdownReachedStatus;
            break;

        case Account.STOP_TRADING_RELATIVE_DAILY_LOSS_LIMIT:
            reason = AssetBalanceItem.relativeDailyLossLimitReachedStatus;
            break;
        }

        if (acc.AccountTradeStatus === AccountTradeStatus.TRADING_DISABLED_RISK_STATUS) {
            if (!status) {
                status = reason;
            } else if (reason !== '---') // #34231
            {
                status += ' : ' + reason;
            } else {
                status = AssetBalanceItem.disabledByRiskRules;
            }
        }

        return status;
    }
}

export enum AssetBalanceItemRowsNumber {
    Balance = 2,
    CurBalance = 6,
    MaintMargin = 15
}
