// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { Resources } from '../../Commons/properties/Resources';
import { TerceraRadioButtonComponent } from './TerceraRadioButtonComponent';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';

export enum WinNum {
     winAdd = -1,
      Chart = 0,
      win1 = 1,
      win2 = 2,
       win3 = 3,
       win4 = 4
    };

/// <summary>
/// Определение окон на чарте для размещения индикаторов
/// </summary>
export class ChartWindow extends TerceraRadioButtonComponent {
    public minimum: number = 0;
    public maximum: number = 0;

    public maximumAvailable: number = 4;// Максимально доступное кол-во окон на чарте

    public value: number = 0;// значение текущего окна(DynProperty)

    public ValueChanged: CustomEvent = new CustomEvent(); // изменение значения - выбор окна

    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.ChartWindow; }

    public override oninit (): void {
        super.oninit();
        this.ValueChanged = new CustomEvent();
    }

    public override onRbchange (event, rindex): void {
        super.onRbchange(event, rindex);
        this.ValueChanged.Raise(this, rindex);
    }

    public MaximumAvailable (): number {
        return this.maximumAvailable;
    }

    public GetMaximum (): number {
        return this.maximum;
    }

    public SetMaximum (value: number): void {
        if (value <= this.maximumAvailable) {
            this.maximum = value;
        } else {
            this.maximum = this.maximumAvailable;
        }

        this.PopulateBtns();
    }

    public PopulateBtns (): void {
        const result = [];
        // кнопка "Chart" - для размещения на чарте
        // createRadioButton(WinNum.Chart);
        result.push({ text: this.getButtonText(WinNum.Chart), checked: true, type: WinNum.Chart });

        // кнопки 1,2,3... - для задания номера окна
        for (let i = 1; i < this.maximum; i++) {
            result.push({ text: this.getButtonText(i), checked: false, type: i });
        }

        // кнопка "Add Win" - для создания нового окна
        if (this.maximum !== this.MaximumAvailable()) {
            result.push({ text: this.getButtonText(WinNum.winAdd), checked: false, type: WinNum.winAdd });
        }

        void this.set({ radioItems: result });
    }

    // public  PopulateBtns  ()
    // {

    // }

    public GetMinimum (): number {
        return this.minimum;
    }

    public SetMinimum (value: number): void {
        if (value >= 0) {
            this.minimum = value;
        }
    }

    public GetValue (): number {
        return this.value;
    }

    public SetValue (value: number): void {
        this.value = value;

        // флаг установки значения
        let isSet = false;

        // выделяем по заданному значению
        const myItems = this.get('radioItems');
        let rbChartWinNum = null;
        const clickedItem = value;
        const len = myItems.length;
        for (let i = 0; i < len; i++) {
            if (clickedItem === i) {
                myItems[i].checked = true;
                isSet = true;
            } else { myItems[i].checked = false; }

            if (myItems[i].type === WinNum.Chart) {
                rbChartWinNum = myItems[i];
            }
        }

        // если не нашли - устанавливаем по умолчанию
        if (!isSet && rbChartWinNum !== null) {
            rbChartWinNum.checked = true;
        }

        void this.set({ radioItems: myItems });
    }

    /// <summary>
    /// получение title для кнопки по заданному индексу
    /// </summary>
    /// <param name="num"></param>
    /// <returns></returns>
    public getButtonText (num: WinNum): string {
        switch (num) {
        case WinNum.Chart:
            return Resources.getResource('property.Win num.main'); // "Chart"
        case WinNum.winAdd:
            return Resources.getResource('property.Win num.addWin');
        case WinNum.win1:
            return '1';
        case WinNum.win2:
            return '2';
        case WinNum.win3:
            return '3';
        case WinNum.win4:
            return '4';

        default:
            return '';
        }
    }
}

/// <summary>
/// событие click кнопки - выбор окна
/// </summary>
/// <param name="sender"></param>
/// <param name="e"></param>
// private void rb_Click(object sender, EventArgs e)
// {
//    //снимаем ранние выдиления
//    //SelectAllButton(Color.Empty);

//    //выделяем кнопку
//    //SelectButton((A)sender, colorSel);
//    if (sender == null)
// return;

/// /идентификатор кнопки
// WinNum num = (WinNum)((RadioButton)sender).Tag;

// switch (num)
// {
//    case WinNum.Chart:
//        value = 0;
//        break;
//    case WinNum.win1:
//        value = 1;
//        break;
//    case WinNum.win2:
//        value = 2;
//        break;
//    case WinNum.win3:
//        value = 3;
//        break;
//    case WinNum.win4:
//        value = 4;
//        break;
//    case WinNum.winAdd:
//        value = maximum;
//        break;
// }
// if(ValueChanged != null)
//    ValueChanged(this, e);

// }
TerceraRadioButtonComponent.extendWith(ChartWindow, {
    data: function () {
        return { isHorisontal: true };
    }
});
