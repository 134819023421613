// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type CustomEvent } from '../Utils/CustomEvents';
import { type MessageBoxType, type TerceraMessageBox } from '../Controls/screen/TerceraMessageBox';
import { type ClosePositionsMutually } from '../Controls/panels/ClosePositionsMutually';
import { type AlertMessageBox, type AlertMessageBoxType } from '../Controls/screen/AlertMessageBox';
import { type DirectReportMessage } from './DirectMessages/DirectReportMessage';
import { type PlacedFrom } from './Trading/PlacedFrom';
import { type ExternalScreen } from '../Controls/screen/ExternalScreen';
import { type EntitlementSubscribeDocumentScreen } from '../Controls/screen/EntitlementSubscribeDocumentScreen';
import { type ConfirmationScreen } from '../Commons/Trading/ConfirmationScreen';
import { type ConfirmationTypesEnum } from './Trading/ConfirmationTypesEnum';

class Handler {
    public _Handler: any = null;

    public setHandler (handler: any): void {
        this._Handler = handler;
    }

    public getHandler (): any {
        return this._Handler;
    }
}

class ContextMenuHandler extends Handler {
    Show: any;
    Hide: any;
    SetCallerControl: any;
    CloseMenu: CustomEvent;
    on: any;
    off: any;
    isShowed: () => boolean;
}

export const contextMenuHandler = new ContextMenuHandler();

class ContextMenuWithOverHandler extends ContextMenuHandler {
    isMouseOnMenu: () => boolean;
    handlerOnMainBtnOver: any;
    TryToCloseMenu: any;
    Close: any;
}

export const contextMenuWithOverHandler = new ContextMenuWithOverHandler();

class DatePickerDropDownHandler extends Handler {
    Show: (date: any, callback: any, x: number, y: number, additionalParams?: any) => void;
    get: (key?) => any;
    Hide: () => boolean;
    localize: () => boolean;
    Init: () => void;
}

export const datePickerDropDownHandler = new DatePickerDropDownHandler();

class ButtonsPopupHandler extends Handler {
    set: (key: any) => Promise<void>;
    get: (key: any) => any;
    Hide: any;
    show: any;
    onButtonClicked: any;
    addControl: any;
}

export const buttonsPopupHandler = new ButtonsPopupHandler();

class PopupErrorHandler extends Handler {
    Show: any;
    Hide: any;
    set: (key: any) => void;
    isShowed: () => boolean;
}

export const popupErrorHandler = new PopupErrorHandler();

class MessageBoxHandler extends Handler {
    Show: (headerText: string, bodyText: string, type: MessageBoxType, okCallBack?: any, cancelCallBack?: any, showNextTimeChB?: boolean, hideCancel?: any, errorText?: string, addData?: any, skipOnTeardown?: boolean) => TerceraMessageBox;
    msgType: typeof MessageBoxType;
}

export const messageBoxHandler = new MessageBoxHandler();

class ConfirmationScreenHandler extends Handler {
    Show: (confirmationType: ConfirmationTypesEnum, reqData: any, type: MessageBoxType, okCallBack?: any, cancelCallBack?: any, showNextTimeChB?: boolean, hideCancel?: any, errorText?: string, addData?: any, skipOnTeardown?: boolean) => ConfirmationScreen;
    msgType: typeof MessageBoxType;
}

export const confirmationScreenHandler = new ConfirmationScreenHandler();

class GenerateReportMessageHandler extends Handler {
    GenerateReportMessage: (msg: any) => DirectReportMessage | DirectReportMessage[];
}

export const generateReportMessageHandler = new GenerateReportMessageHandler();

class RiskWarningMessageScreenHandler extends Handler {
    Show: (messageInfo: any) => void;
}

export const riskWarningMessageScreenHandler = new RiskWarningMessageScreenHandler();

class SnapshotScreenHandler extends Handler {
    show: (ins: any, msg: any) => void;
}

export const snapshotScreenHandler = new SnapshotScreenHandler();

class BrokerMessageScreenHandler extends Handler {
    Show: (messageInfo: any) => void;
}

export const brokerMessageScreenHandler = new BrokerMessageScreenHandler();

class AlertMessageScreenHandler extends Handler {
    Show: (headerText: string, bodyText: string, type: MessageBoxType | AlertMessageBoxType) => AlertMessageBox;
}

export const alertMessageScreenHandler = new AlertMessageScreenHandler();

class WorkspaceManagerHandler extends Handler {
    numberOfPanelByType: (panelType: string) => number;
    RefreshPanelsWithInstrumentDescriptions: (insArray: any) => void;
}

export const workspaceManagerHandler = new WorkspaceManagerHandler();

class PositionExerciseRequestScreenHandler extends Handler {
    show: (position: any, placedFrom: any) => void;
}

export const positionExerciseRequestScreenHandler = new PositionExerciseRequestScreenHandler();

class PositionsMutualCloseScreenHandler extends Handler {
    show: (positionsIdArray: any, placedFrom: any, closeFunction: any) => ClosePositionsMutually;
}

export const positionsMutualCloseScreenHandler = new PositionsMutualCloseScreenHandler();

class ModifyOrderScreenHandler extends Handler {
    show: (orderId: any, placedFrom: any, numericLinkName: any) => void;
}

export const modifyOrderScreenHandler = new ModifyOrderScreenHandler();

class EditPositionScreenHandler extends Handler {
    show: (positionID: any, placedFrom: PlacedFrom, numericLinkName: string, isModify: boolean, location?: any, notSkipNextTime?: boolean) => void;
}

export const editPositionScreenHandler = new EditPositionScreenHandler();

class ExternalScreenHandler extends Handler {
    Show: (messageInfo: any, cleanUrl?: boolean) => ExternalScreen;
}

export const externalScreenHandler = new ExternalScreenHandler();

class EntitlementSubscribeDocumentScreenHandler extends Handler {
    Show: (id: any, data: any) => EntitlementSubscribeDocumentScreen;
}

export const entitlementSubscribeDocumentScreenHandler = new EntitlementSubscribeDocumentScreenHandler();

class TerceraNumericHandler extends Handler {
    OnHasErrorsChanged: CustomEvent;
}

export const terceraNumericHandler = new TerceraNumericHandler();

class PropertySetupScreenForIndicatorHandler extends Handler {
    editProperty: (caller: any, callBack: any, headerKey: any) => void;
}

export const propertySetupScreenForIndicatorHandler = new PropertySetupScreenForIndicatorHandler();

class AddOverlayScreenHandler extends Handler {
    editOverlay: (chart: any, overlay: any) => void;
}

export const addOverlayScreenHandler = new AddOverlayScreenHandler();

class TooltipHandler extends Handler {
    showTooltip: any;
    setTooltipVisibility: any;
}

export const tooltipHandler = new TooltipHandler();

class MultiComboBoxPopupMenuHandler extends Handler {
    Init: () => void;
    Show: any;
    Hide: any;
    ProcessOnMouseDown: any;
    isShowed: () => any;
}

export const multiComboBoxPopupMenuHandler = new MultiComboBoxPopupMenuHandler();
