// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TrioColorGradientTemplate } from '../../templates';
import { ContainerControl } from './ContainerControl';

export class TrioColorGradient extends ContainerControl {
    constructor () { super(); }
}

ContainerControl.extendWith(TrioColorGradient, {
    data: function () {
        return {
            value: null
        };
    },
    template: TrioColorGradientTemplate
});
