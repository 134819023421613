// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ConfirmationScreenTemplate } from '../../templates';
import { confirmationScreenHandler } from '../../Utils/AppHandlers';
import { ScreensNames } from '../../Controls/UtilsClasses/FactoryConstants';
import { MainWindowManager } from '../../Controls/UtilsClasses/MainWindowManager';
import { MessageBoxType, TerceraMessageBox } from '../../Controls/screen/TerceraMessageBox';
import { TerceraWindowBase } from '../../Controls/screen/TerceraWindowBase';
import { KeyEventProcessor } from '../KeyEventProcessor';
import { Resources } from '../properties/Resources';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { OrderFormatter } from '../cache/Formatters/OrderFormatter';
import { ConfirmationTypesEnum } from '../../Utils/Trading/ConfirmationTypesEnum';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { DataCacheHolder } from '../cache/DataCacheHolder';
import { OrderEditRequestData } from '../../Utils/Trading/OrderEditBaseUtils';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { OffsetModeViewEnum } from '../../Utils/Trading/OffsetModeViewEnum';
import { SlTpPriceType } from '../../Utils/Enums/Constants';
import { SLTPTrigger } from '../../Utils/SlTpTrigger';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { DataCache } from '../DataCache';

export class ConfirmationScreen extends TerceraWindowBase {
    static get DataCache (): any {
        return DataCacheHolder.getDataCache();
    }

    public static Controls: any;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    public constructor () { super(); }

    public override getType (): ScreensNames {
        return ScreensNames.ConfirmationScreen;
    }

    public override getClientPanel (): HTMLElement {
        return this.find('.confirmation-screen-container');
    }

    public override oninit (): void {
        super.oninit();
    }

    public override oncomplete (): void {
        super.oncomplete();

        void this.updateWithRequestData(this.params.reqData).then(() => {
            this.updateMsgBox();
            this.setFocus();
            this.center();
        });

        TerceraMessageBox.PlaySound(this.params.type);
    }

    public override dispose (): void {
        super.dispose();
    }

    private params: {
        type: MessageBoxType
        okCallBack
        cancelCallBack
        showNextTimeChB: boolean
        hideCancel: boolean
        errorText: string
        addData: Record<string, any>
        skipOnTeardown: boolean
        reqData: OrderEditRequestData
        confirmationType: ConfirmationTypesEnum
    };

    private updateHeaderAndMsgBoxText (): void {
        const locKey = this.getHeaderLocalizationKey();
        void this.set({
            header: Resources.getResource(locKey),
            msgBoxText: Resources.getResource(locKey + '.Message') // 'screen.confirmation.CancelOrder.Message' 'screen.confirmation.ModifyOrder.Message' 'screen.confirmation.ModifyPosition.Message' 'screen.confirmation.PlaceOrder.Message'
        });
    }

    private getHeaderLocalizationKey (): string {
        const type: ConfirmationTypesEnum = this.params.confirmationType;
        switch (type) {
        case ConfirmationTypesEnum.OrderPlace:
            return 'screen.confirmation.PlaceOrder';
        case ConfirmationTypesEnum.OrderCancel:
            return 'screen.confirmation.CancelOrder';
        case ConfirmationTypesEnum.Modify:
            return this.params?.reqData?.position != null ? 'screen.confirmation.ModifyPosition' : 'screen.confirmation.ModifyOrder';
        }
    }

    private updateMsgBox (): void {
        const msgBox = this.Controls.OldMsgBox;
        if (msgBox != null) {
            msgBox.updateWithParams(
                '',
                '',
                this.params.type,
                this.params.okCallBack,
                this.params.cancelCallBack,
                this.params.showNextTimeChB,
                this.params.hideCancel,
                this.params.errorText
            );
            msgBox.updateWithAdditionalData(this.params.addData);

            msgBox.set({
                top: 0,
                left: 0,
                isRelativeStyle: true,
                showBorder: false,
                showHeader: false
            });

            KeyEventProcessor.OnKeyDown.Subscribe(msgBox.onGlobalKeyDown, msgBox);
            msgBox.forceCloseOnLogout = !this.params.skipOnTeardown;
            msgBox.OK_Handler = new Promise<void>((resolve) => {
                msgBox.OkClicked = () => {
                    resolve();
                    this.close();
                };
            });
            msgBox.NO_Handler = new Promise<void>((resolve) => {
                msgBox.CancelClicked = () => {
                    resolve();
                    this.close();
                };
            });
        }

        this.updateHeaderAndMsgBoxText();
    }

    private async updateWithRequestData (reqData: OrderEditRequestData): Promise<void> {
        const entries = [];

        if (this.params.confirmationType === ConfirmationTypesEnum.OrderCancel) {
            reqData = this.params.reqData = OrderEditRequestData.GetDataFromOrderDict(reqData);
        }

        const ins = reqData.instrument;
        const account = reqData.account;
        const productType = reqData.TryGetProductType();
        if (ins == null || account == null) { return; }

        // #region OrderID/PositionID
        const modifEntity = reqData.position ?? reqData.order;
        const isPos = modifEntity?.isPosition === true;
        if (modifEntity != null) {
            entries.push({
                key: isPos ? 'screen.modifyOrder.number' : 'screen.modifyOrder.orderNumber',
                text: modifEntity.OrderNumber
            });
        }
        // #endregion

        const isModifyPosition = this.params.confirmationType === ConfirmationTypesEnum.Modify && isPos;
        if (!isModifyPosition) {
        // #region Side, Order type, Validity(TIF), Quantity
            entries.push({ key: 'panel.newOrderEntry.Side', text: OrderUtils.getBuySellStr(reqData.side) });
            entries.push({ key: 'panel.newOrderEntry.typePanel', text: Resources.getResource(OrderUtils.getOrderTypeLocalizationKey(reqData.orderTypeId)) });

            let tifText: string | undefined;
            if (!isNullOrUndefined(reqData.tif)) {
                tifText = OrderFormatter.GetFormattedValidityStr(reqData.tif.type, reqData.tif.expirationTime);
            } else if (modifEntity != null) { // hsa: I'm not sure about this part
                tifText = OrderFormatter.ValidityStr(modifEntity);
            }

            if (!isNullOrUndefined(tifText)) {
                entries.push({ key: 'panel.newOrderEntry.tifPanel', text: tifText });
            }

            const leverage = reqData.leverageValue;
            if (!isNullOrUndefined(leverage)) {
                entries.push({ key: 'panel.newOrderEntry.Leverage', text: OrderUtils.GetFormattedLeverage(leverage) });
            }

            const amountStr = reqData.quantity == null ? OrderFormatter.AmountStr(modifEntity) : InstrumentUtils.formatAmountValue(reqData.quantity.value, ins, account, productType);
            entries.push({ key: 'panel.newOrderEntry.amountLabel', text: amountStr });
        // #endregion
        }

        // #region Price, Limit price, Stop price, SL/TP/Trailing stop
        // No such line on TE Desktop, so commented out for now
        // entries.push({ key: 'InstrumentDetailsPanel.Price', text: '0.00' });

        const limitPrice = reqData.parameterDict?.limitPrice;
        if (limitPrice != null) {
            entries.push({ key: 'panel.newOrderEntry.limitPricePanel', text: ins.formatPrice(limitPrice) });
        }
        const sltp = reqData.parameterDict?.sltp;
        if (sltp != null) {
            // entries.push({ key: '', text: ins.formatPrice(limitPrice) });

            const usePoint = !!(ins && GeneralSettings.TradingDefaults.ShowOffsetIn === OffsetModeViewEnum.Points);

            let stopLossPriceValue = sltp.StopLossPriceValue;
            let stopLossLimitPriceValue = sltp.StopLossLimitPriceValue;
            let takeProfitPriceValue = sltp.TakeProfitPriceValue;
            const triggersShV = sltp.SLTPTriggerShortValue;

            if (GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
                const fractTicksMode = OffsetModeViewEnum.TicksFractionalForForex;

                if (sltp.StopLossPriceType !== SlTpPriceType.Absolute && !isNaN(stopLossPriceValue)) { stopLossPriceValue = OrderUtils.ConvertTickOffset(ins, fractTicksMode, null, stopLossPriceValue); };

                if (sltp.StopLossPriceType !== SlTpPriceType.Absolute && stopLossLimitPriceValue !== null) { stopLossLimitPriceValue = OrderUtils.ConvertTickOffset(ins, fractTicksMode, null, stopLossLimitPriceValue) * Math.sign(stopLossLimitPriceValue); };

                if (sltp.TakeProfitPriceType !== SlTpPriceType.Absolute && !isNaN(takeProfitPriceValue)) { takeProfitPriceValue = OrderUtils.ConvertTickOffset(ins, fractTicksMode, null, takeProfitPriceValue); };
            }

            let slLabel = null;
            let sllLabel = null;
            let tpLabel = null;
            let slTrigger = null;
            let tpTrigger = null;
            if (!isNaN(stopLossPriceValue)) {
                if (sltp.StopLossPriceType === SlTpPriceType.Absolute) { slLabel = { key: 'SL price', text: ins ? ins.formatPrice(stopLossPriceValue) : stopLossPriceValue.toString() }; } else { slLabel = { key: sltp.StopLossPriceType === SlTpPriceType.Offset ? 'SL offset' : 'Tr. SL offset', text: ins ? ins.formatOffset(stopLossPriceValue) : stopLossPriceValue.toString() + (ins && usePoint ? ' points' : '') }; }

                slTrigger = { key: 'SL trigger', text: SLTPTrigger.GetTextValue(triggersShV, reqData.side, true) };
            }
            if (stopLossLimitPriceValue !== null) {
                if (sltp.StopLossPriceType === SlTpPriceType.Absolute) { sllLabel = { key: 'SL limit price', text: ins ? ins.formatPrice(stopLossLimitPriceValue).toString() : stopLossLimitPriceValue.toString() }; } else { sllLabel = { key: 'SL limit offset', text: ins ? ins.formatOffset(stopLossLimitPriceValue).toString() : stopLossLimitPriceValue.toString() + (ins && usePoint ? ' points' : '') }; }
            }
            if (!isNaN(takeProfitPriceValue)) {
                if (sltp.TakeProfitPriceType === SlTpPriceType.Absolute) { tpLabel = { key: 'TP price', text: ins ? ins.formatPrice(takeProfitPriceValue) : takeProfitPriceValue.toString() }; } else { tpLabel = { key: 'TP offset', text: ins ? ins.formatOffset(takeProfitPriceValue) : takeProfitPriceValue.toString() + (ins && usePoint ? ' point' : '') }; }

                tpTrigger = { key: 'TP trigger', text: SLTPTrigger.GetTextValue(triggersShV, reqData.side, false) };
            }

            if (slLabel != null) { entries.push(slLabel); }
            if (sllLabel != null) { entries.push(sllLabel); }
            if (tpLabel != null) { entries.push(tpLabel); }
            if (slTrigger?.text != null) { entries.push(slTrigger); }
            if (tpTrigger?.text != null) { entries.push(tpTrigger); }

            // if (paramDict.RealTrStopPrice && !stopPrice.text) { stopPrice.text = paramDict.RealTrStopPrice; };

            if (reqData.parameterDict?.trailingStop != null) {
                entries.push({ key: 'Tr. stop', text: ins.formatOffset(reqData.parameterDict.trailingStop) + ' (offset)' });
            }
        }

        // #endregion

        if (!isModifyPosition) {
        // #region Product type
            if (productType != null && productType !== ProductType.General) {
                entries.push({ key: 'panel.newOrderEntry.ProductType', text: InstrumentUtils.GetLocalizedProductType(ins, productType) });
            }
            // #endregion

            // #region Symbol, Symbol type, Route, Trading Exchange, Account
            entries.push({ key: 'panel.newOrderEntry.instrumentLabel', text: ins.DisplayName() });
            entries.push({ key: 'reports.instrumenttype', text: ins.getTypeString() });
            if (!ins.isHideRouteMode) {
                entries.push({ key: 'panel.orders.Route', text: ConfirmationScreen.DataCache.getRouteById(ins.Route)?.Name });
            }
            entries.push({ key: 'panel.orders.ExchangeTrading', text: ins.TradingExchange });
        }
        entries.push({ key: 'AdditionalProperty.Account', text: account.toString() });
        // #endregion

        // #region Estimated Fee
        if (!isNullOrUndefined(reqData.placedFrom)) {
            const [totalFee, afterTradeCash] = await reqData.MarginRequest();
            const assetBalance = account.assetBalanceDefault;

            let totalFeeStr = '-';
            if (!isNaN(totalFee) && !isNullOrUndefined(totalFee)) {
                totalFeeStr = assetBalance.formatPrice(Math.abs(totalFee));
            }

            entries.push({ key: 'screen.confirmation.EstimatedFee', text: totalFeeStr });

            // #region After Trade Cash
            if (DataCache.isAllowedForMyUser(RulesSet.LEVERAGE_USAGE_WARN)) {
                let afterTradeCashStr = '-';
                if (!isNaN(afterTradeCash) && !isNullOrUndefined(afterTradeCash)) {
                    afterTradeCashStr = assetBalance.formatPrice(afterTradeCash);
                }

                entries.push({ key: 'screen.confirmation.AfterTradeCash', text: afterTradeCashStr });
            }
            // #endregion
        }
        // #endregion

        entries.forEach((e) => {
            e.caption = `${Resources.getResource(e.key)}:`;
        });

        void this.set({ entries });
    }

    public static MessageBoxHandlerInitialize (): void {
        confirmationScreenHandler.Show = ConfirmationScreen.Show;
        confirmationScreenHandler.msgType = MessageBoxType;
    }

    public static Show (
        confirmationType: ConfirmationTypesEnum,
        reqData: OrderEditRequestData,
        type: MessageBoxType,
        okCallBack?,
        cancelCallBack?,
        showNextTimeChB?: boolean,
        hideCancel?: boolean,
        errorText?: string,
        addData?: Record<string, any>,
        skipOnTeardown: boolean = false
    ): ConfirmationScreen {
        const confirmScreen = new ConfirmationScreen();
        // Save parameters for later use in oncomplete
        confirmScreen.params = {
            type,
            okCallBack,
            cancelCallBack,
            showNextTimeChB,
            hideCancel,
            errorText,
            addData,
            skipOnTeardown,
            reqData,
            confirmationType
        };

        MainWindowManager.MainWindow.addControl(confirmScreen);

        return confirmScreen;
    }
}

TerceraWindowBase.extendWith(ConfirmationScreen, {
    data: function () {
        return {
            showFooter: false,
            msgBoxText: '',
            entries: []
        };
    },
    partials: { bodyPartial: ConfirmationScreenTemplate }
});
