// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { ExpandDoubleVector } from '../DoubleMatrix';
import { IndicatorFunctions } from '../IndicatorFunctions';

export class MI extends IndicatorScriptBase {
    public EmaPeriod: number;
    public MiPeriod: number;

    private emaRation: number[] = [];
    private highLowSeries: ExpandDoubleVector;
    private emaHighLowSeries: ExpandDoubleVector;
    private emaHighLowSeries2: ExpandDoubleVector;

    constructor () {
        super();
        // #region Initialization
        this.ProjectName = 'Mass Index';
        this.Comments = 'Mass Index';
        // #endregion

        this.SetIndicatorLine('mi', Color.Blue, 2, LineStyle.SimpleChart);
        this.SetLevelLine('27', 27, Color.Pink, 1, LineStyle.ShapedChart);
        this.SetLevelLine('26.5', 26.5, Color.Red, 1, LineStyle.ShapedChart);
        this.SeparateWindow = true;

        this.EmaPeriod = 9;
        super.InputParameter(new InputParameterInteger('EmaPeriod', 'Ema Period', 0, 1, 99999, 0, 1));

        this.MiPeriod = 25;
        super.InputParameter(new InputParameterInteger('MiPeriod', 'Mi Period', 0, 1, 99999, 0, 1));

        this.highLowSeries = new ExpandDoubleVector();
        this.emaHighLowSeries = new ExpandDoubleVector();
        this.emaHighLowSeries2 = new ExpandDoubleVector();
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        this.UpdateIndexDrawBegin();
    }

    private setHighLow (): void {
        this.highLowSeries[this.highLowSeries.Length - 1] = this.CurrentData.GetPrice(PriceType.High) - this.CurrentData.GetPrice(PriceType.Low);
    }

    private setHighLowEMA (): void {
        const result = IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.highLowSeries, this.EmaPeriod, 1);
        this.emaHighLowSeries[this.emaHighLowSeries.Length - 1] = Number.isNaN(result) ? 0 : result;
    }

    private setHighLowEMA2 (): void {
        const result = IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.emaHighLowSeries, this.EmaPeriod, 1);
        this.emaHighLowSeries2[this.emaHighLowSeries2.Length - 1] = Number.isNaN(result) ? 0 : result;
    }

    public override GetIndicatorShortName (): string {
        return 'MI(' + this.MiPeriod + ')';
    }

    public override getCustomName (): string {
        return 'MI (' + this.ProjectName + ')';
    };

    public override UpdateIndexDrawBegin (): void {
        this.SetIndexDrawBegin(0, this.MiPeriod);
    }

    public OnQuote (): void {
        super.OnQuote();

        this.setHighLow();

        if (this.CurrentData.Count - 1 > this.EmaPeriod) {
            this.setHighLowEMA();
        }

        if (this.CurrentData.Count - 1 > this.EmaPeriod * 2) {
            this.setHighLowEMA2();

            this.emaRation[this.emaRation.length - 1] = this.emaHighLowSeries[this.emaHighLowSeries.Length - 1] / this.emaHighLowSeries2[this.emaHighLowSeries2.Length - 1];
        }

        if (this.CurrentData.Count - 1 > this.MiPeriod) {
            super.SetValue(0, 0, this.emaRation.slice(-this.MiPeriod).reduce((acc, val) => acc + val));
        }
    }

    public NextBar (): void {
        this.highLowSeries.Add(0.0);
        this.emaHighLowSeries.Add(0.0);
        this.emaHighLowSeries2.Add(0.0);
        this.emaRation.push(0);
        super.NextBar();
    }
}
