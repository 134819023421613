// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraBrokerMessageScreenTemplate, TerceraBrokerMessageScreenFooterTemplate } from '../../templates.js';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';
import { TerceraOnboardingScreen } from './TerceraOnboardingScreen';
import { TerceraWindowBase } from './TerceraWindowBase';
import { BrokerMessageResponseType } from '../../Utils/Notifications/BrokerMessageResponseType';
import { UrlUtils } from '../../Utils/UrlUtils';
import { ThemeManager } from '../misc/ThemeManager';
import { brokerMessageScreenHandler } from '../../Utils/AppHandlers';
import { TextMessageType } from '../../Utils/Notifications/TextMessageType';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { Connection } from '../../Commons/Connection';

export class TerceraBrokerMessageScreen extends TerceraWindowBase {
    public CloseHandler: any = null;
    public CustomButtonsXCloseHandler: any = null;
    public AsyncClosePromise: Promise<any>;

    public override getType (): ScreensNames { return ScreensNames.BrokerMessageScreen; }

    public override oninit (): void {
        super.oninit();

        this.on('onOKClick', this.onOKClick);
        this.on('onCancelClick', this.onCancelClick);
        this.on('onIframeLoad', this.onIframeLoad);

        this.observe('messageInfo', this.onMessageInfoChange);

        this.localize();
    }

    public onIframeLoad (): void {
        const elID = 'iframe_' + this.get('brokerMsgScreenID');
        const el: any = document.getElementById(elID);
        const messageInfo = this.get('messageInfo');
        const HTMLdisplayMethod = messageInfo ? messageInfo.IsHTMLDisplayMethod() : null; // #114121 цвет текста меняем для DisplayMethod == TextDisplayMethod.Text
        const changeIFrameTextColor = el && !HTMLdisplayMethod;

        if (changeIFrameTextColor) // пока не нашел css способа поменять цвет текста внутри iframe
        {
            el.contentWindow.document.body.style.color = ThemeManager.CurrentTheme.TableAllHistogramTextColor;
        }
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.center();
        this.addScroll();

        this.OnResize.Subscribe(this.onResize, this);
        this.onResize(); // otherwise -> #110897
    }

    public override dispose (): void {
        this.OnResize.UnSubscribe(this.onResize, this);

        super.dispose();
    }

    public override localize (): void {
        void this.set({
            header: Resources.getResource('BrokerMessage.Text'),
            noHtmlMessage: Resources.getResource('chart.NoData'),
            okButtonText: Resources.getResource('BrokerMessage.OK'),
            cancelButtonText: Resources.getResource('BrokerMessage.Cancel')
        });
    }

    public onOKClick (): void {
        const msgInfo = this.get('messageInfo');

        if (msgInfo.TextMessageType === TextMessageType.BROKER_MESSAGE_URGENT_TYPE && !msgInfo.ResponseCases) {
            msgInfo.Date = new Date();
            DataCache.SendBrokerMessageReport(msgInfo); // закоментовано, згодом відновлено згідно з #110904 (для urgent BM було додано логіювання у #84587, судячи з усього зараз ця логіка вже є застарілою і непотрібною)
        }

        this.processResponseCasesIfNeed(); // #92056 send answer if msg has cases

        this.close();
    }

    public onCancelClick (): void {
        this.close();
        Connection.disconnect();
    }

    // Close button (cross icon).
    public override onCloseButtonClick (): void {
        if (this.canCloseFromButton()) {
            this.close(true);
        }
    }

    public override close (fromCloseBTN = false): void {
        super.close();

        this.processNotificationWasRead();

        if (this.CloseHandler) {
            this.CloseHandler();
        }

        if (!isNullOrUndefined(this.CustomButtonsXCloseHandler) && fromCloseBTN) {
            this.CustomButtonsXCloseHandler();
        }
    }

    public onMessageInfoChange (messageInfo): void {
        if (!messageInfo) {
            return;
        }

        void this.set('style_addition_footer', '');

        const msgType = messageInfo.TextMessageType;

        if (msgType === TextMessageType.BROKER_MESSAGE_URGENT_TYPE ||
        msgType === TextMessageType.BROKER_MESSAGE_DAILY_WELCOME_TYPE ||
        msgType === TextMessageType.BROKER_MESSAGE_NOTIFICATION_TYPE) // если в (51) месседже не передано доп инфо ни для одной кнопки, то логика будет текущей, просто показываем стандартный брокер месседж с кнопкой ОК
        {
            void this.set({
                cancelButtonVisible: false
            });
        }

        if (msgType === TextMessageType.BROKER_MESSAGE_NOTIFICATION_TYPE) {
            const cBtns = messageInfo.CustomButtons;
            if (cBtns?.length) {
                const m = TerceraBrokerMessageScreen.BTN_MARGIN;
                const fH = m + (TerceraBrokerMessageScreen.BTN_HEIGHT + m) * cBtns.length;

                void this.set({
                    okButtonVisible: false,
                    closeBtnVisible: true,
                    footer_height: fH,
                    style_addition_footer: 'js-TerceraBrokerMessageScreen-AdditionalFooter',
                    customButtons: cBtns
                });

                this.createCustomButtonsClickEvents();

                this.CustomButtonsXCloseHandler = () => {
                    this.processResponseCustomButtons(TerceraBrokerMessageScreen.BM_CLOSED);
                };
            }
        }
    }

    public createCustomButtonsClickEvents (): void {
        const clickEvent = (url, customBtnTitle): void => {
            UrlUtils.openFixedURL(url);
            this.processResponseCustomButtons(customBtnTitle);
            this.close();
        };

        const cbs = this.get('customButtons');
        for (let i = 0; i < cbs.length; i++) {
            this.on('onCustomButton' + i + 'Click', clickEvent.bind(this, cbs[i].URL, cbs[i].TitleForResponse));
        }
    }

    public processResponseCasesIfNeed (): void // https://tp.traderevolution.com/entity/92056 send BrokerResponseMessage if need
    {
        const msgInfo = this.get('messageInfo');
        if (!msgInfo) return;

        if (msgInfo.ResponseCases) {
            const userID = DataCache.getUserID();
            const userName = DataCache.getUserName();
            const brokerMsgID = msgInfo.ID;
            const screenID = TerceraBrokerMessageScreen.ID_CONST_PREFIX + brokerMsgID;
            const iframeContainer = document.getElementById(screenID); // iframeContainer
            const iframe: any = iframeContainer ? iframeContainer.childNodes[0] : null; // iframe
            const htmlContent = iframe ? iframe.contentWindow.document : null;

            if (htmlContent) {
                const inputArr = htmlContent.getElementsByTagName('input');
                for (let i = 0; i < inputArr.length; i++) {
                    const inp = inputArr[i];
                    const checked = inp.checked;
                    let value = inp.getAttribute('value');

                    if (checked) {
                        value = (value == 'yes').toString(); // 'true' or 'false'
                        DataCache.SendBrokerResponseMessage(brokerMsgID, value, userID, userName, BrokerMessageResponseType.RADIO_BTNS, msgInfo.ClusterNode);

                        return; // на десктопе предусмотрено и для checkBox-ов, но в доке явно сказано о radioButton-ах
                    }
                }
            }
        }
    }

    public processResponseCustomButtons (cBtnTitle): void // https://tp.traderevolution.com/entity/109652 send BrokerResponseMessage when custom buttons clicked
    {
        const msgInfo = this.get('messageInfo');
        if (!msgInfo) return;

        const userID = DataCache.getUserID();
        const userName = DataCache.getUserName();
        const brokerMsgID = msgInfo.ID;
        const value = cBtnTitle.toString();

        DataCache.SendBrokerResponseMessage(brokerMsgID, value, userID, userName, BrokerMessageResponseType.CUSTOM_BTNS, msgInfo.ClusterNode);
    }

    public processNotificationWasRead (): void {
        const msgInfo = this.get('messageInfo');
        if (!msgInfo) return;

        if (msgInfo.TextMessageType !== TextMessageType.BROKER_MESSAGE_NOTIFICATION_TYPE || msgInfo.ShowPopUp === false) {
            return;
        }

        const userID = DataCache.getUserID();
        const userName = DataCache.getUserName();
        const brokerMsgID = msgInfo.ID;

        DataCache.SendBrokerResponseMessage(brokerMsgID, '', userID, userName, BrokerMessageResponseType.WAS_READ, msgInfo.ClusterNode);
    }

    public onResize (): void {
        const width = this.get('width');
        const height = this.get('height');
        const fHeight = this.get('footer_height');

        void this.set({
            brokerMSGContentWidth: width,
            brokerMSGContentHeight: height - fHeight - TerceraBrokerMessageScreen.HEADER
        });
    }

    public addScroll (): void {
        // reworked with iframe in 114020 -> no need to add scroll (iframe has its own internal one)

    // var div = this.find('.js-broker-message-content')
    // if (div) HtmlScroll.addScroll(div)
    }

    public static Show (messageInfo): void {
        if (!SessionSettings.OnBoardingWasShown()) { // #110922 если нужно показать OnBoarding то BrokerMessage покажем после его завершения
            TerceraOnboardingScreen.AddOnShownSubscribe(TerceraBrokerMessageScreen.Show.bind(null, messageInfo));
            return;
        }

        const scr = new TerceraBrokerMessageScreen();
        void scr.set({
            messageInfo,
            brokerMsgScreenID: messageInfo ? TerceraBrokerMessageScreen.ID_CONST_PREFIX + messageInfo.ID : null
        });

        if (messageInfo.Name) {
            scr.set({ header: messageInfo.Name });
        }

        MainWindowManager.MainWindow.addControl(scr);
        scr.setFocus();
        scr.AsyncClosePromise = new Promise(function (resolve) { this.CloseHandler = resolve; }.bind(scr));
        MainWindowManager.AsyncCloseObject = scr;
    }

    public static readonly ID_CONST_PREFIX = 'BrokerScreenID=';
    public static readonly BM_CLOSED = 'BM closed';
    public static readonly HEIGHTFOOTER = 47;
    public static readonly BTN_HEIGHT = 25;
    public static readonly BTN_MARGIN = 10;
    public static readonly HEADER = 25;

    public static brokerMessageScreenHandlerInitialize (): void {
        brokerMessageScreenHandler.Show = TerceraBrokerMessageScreen.Show;
    }
}

TerceraWindowBase.extendWith(TerceraBrokerMessageScreen, {
    data: function () {
        return {
            style_addition_footer: '',
            width: 500,
            height: 300,
            minWidth: 300,
            minHeight: 300,
            showFooter: true,
            showModalMask: true,
            resizable: true,
            brokerMsgScreenID: null,
            messageInfo: null,
            noHtmlMessage: '',
            customButtonWidth: 280,
            brokerMSGContentWidth: 500,
            brokerMSGContentHeight: 233,
            customButtons: [],
            okButtonVisible: true,
            cancelButtonVisible: true,
            closeBtnVisible: false
        };
    },
    partials: {
        bodyPartial: TerceraBrokerMessageScreenTemplate,
        footerPartial: TerceraBrokerMessageScreenFooterTemplate
    }
});
