// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class CustomEvent {
    public Subscribers: any[] = [];

    public Subscribe (method, context?): void {
        this.Subscribers.push({
            method,
            context
        });
    }

    public UnSubscribe (method, context): void {
        let idxToRemove;
        const subs = this.Subscribers;
        const len = subs.length;

        for (let i = 0; i < len; i++) {
            const sub = subs[i];
            if (sub.method === method && sub.context === context) {
                idxToRemove = i;
                break;
            }
        }

        if (idxToRemove === undefined) { return; }

        subs.splice(idxToRemove, 1);
    }

    public Raise (q: any = undefined, w: any = undefined, e: any = undefined, r: any = undefined, t: any = undefined): void {
        const subs = this.Subscribers;
        const len = subs.length;
        for (let i = 0; i < len; i++) {
            const sub = subs[i];
            sub.method.call(sub.context, q, w, e, r, t);
        }
    }

    public clearSubscribers (): void {
        this.Subscribers = [];
    }
}
