// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { AlertItem } from '../cache/AlertItem';
import { MarketConsensusItem } from '../cache/MarketConsensusItem';
import { AssetBalanceItem } from '../cache/AssetBalanceItem';
import { AssetsItem } from '../cache/AssetsItem';
import { BaseInformer } from '../cache/BaseInformer';
import { EntitlementProductsItem } from '../cache/EntitlementProductsItem';
import { EventItem } from '../cache/EventItem';
import { FilledOrderItem } from '../cache/FilledOrderItem';
import { FullLogsItem } from '../cache/FullLogsItem';
import { HolidayItem } from '../cache/HolidayItem';
import { Level2ItemQ } from '../cache/Level2ItemQ';
import { NewsItem } from '../cache/NewsItem';
import { OrderHistoryItem } from '../cache/OrderHistoryItem';
import { OrderItem } from '../cache/OrderItem';
import { PositionItem } from '../cache/PositionItem';
import { PositionsBalanceItem } from '../cache/PositionsBalanceItem';
import { StatementItem } from '../cache/StatementItem';
import { TimeSalesItem } from '../cache/TimeSalesItem';
import { TradingCentralItem } from '../cache/TradingCentralItem';
import { NewVerticalPanelTableItem } from '../cache/VerticalPanel/NewVerticalPanelTableItem';
import { ClosePositionsMutuallyItem } from '../cache/ClosePositionsMutuallyItem';
import { SavedOrderItem } from '../cache/SavedOrderItem';
import { GainersLosersItem } from '../cache/GainersLosersItem';
import { HighLowItem } from '../cache/HighLowItem';
import { OptionPositionItem } from '../cache/OptionMaster/OptionPositionItem';
import { type BaseItem } from '../cache/BaseItem';

class _PanelItemsFactory {
    public PanelsInfoDirect: Record<string, new (...args: any[]) => BaseItem> = {};

    constructor () {
        this.PanelsInfoDirect = {};
        this.PanelsInfoDirect[PanelNames.AssetBalancesPanel] = AssetBalanceItem;
        this.PanelsInfoDirect[PanelNames.PositionsPanel] = PositionItem;
        this.PanelsInfoDirect[PanelNames.OptionPositionsPanel] = OptionPositionItem;
        this.PanelsInfoDirect[PanelNames.OrdersPanel] = OrderItem;
        this.PanelsInfoDirect[PanelNames.OptionOrdersPanel] = OrderItem;
        this.PanelsInfoDirect[PanelNames.EventsPanel] = EventItem;
        this.PanelsInfoDirect[PanelNames.InformerPanel] = BaseInformer;
        this.PanelsInfoDirect[PanelNames.AlertPanel] = AlertItem;
        this.PanelsInfoDirect[PanelNames.FilledOrdersPanel] = FilledOrderItem;
        this.PanelsInfoDirect[PanelNames.OrderHistoryPanel] = OrderHistoryItem;
        this.PanelsInfoDirect[PanelNames.SymbolInfoPanel] = NewVerticalPanelTableItem;
        this.PanelsInfoDirect[PanelNames.AccountDetailsPanel] = NewVerticalPanelTableItem;
        this.PanelsInfoDirect[PanelNames.MarginOEControl] = NewVerticalPanelTableItem;
        this.PanelsInfoDirect[PanelNames.MarketDepthPanel] = Level2ItemQ;
        this.PanelsInfoDirect[PanelNames.InstrumentHolidaysScreen] = HolidayItem;
        this.PanelsInfoDirect[PanelNames.StatementPanel] = StatementItem;
        this.PanelsInfoDirect[PanelNames.AssetsPanel] = AssetsItem;
        this.PanelsInfoDirect[PanelNames.PositionsBalancePanel] = PositionsBalanceItem;
        this.PanelsInfoDirect[PanelNames.NewsPanel] = NewsItem;

        this.PanelsInfoDirect[PanelNames.TradingCentral] = TradingCentralItem;
        this.PanelsInfoDirect[PanelNames.FullLogsPanel] = FullLogsItem;
        this.PanelsInfoDirect[PanelNames.ProductsPanel] = EntitlementProductsItem;

        this.PanelsInfoDirect[PanelNames.ClosePositionsMutually] = ClosePositionsMutuallyItem;

        // Algorithm
        this.PanelsInfoDirect[PanelNames.MarketConsensusPanel] = MarketConsensusItem;
        this.PanelsInfoDirect[PanelNames.GainersLosersPanel] = GainersLosersItem;
        this.PanelsInfoDirect[PanelNames.HighLowPanel] = HighLowItem;

        this.PanelsInfoDirect[PanelNames.SavedOrdersPanel] = SavedOrderItem;
        this.PanelsInfoDirect[PanelNames.TimeSalesPanel] = TimeSalesItem;
    }

    public GetPanelItem (panelName: PanelNames | string): new (...args: any[]) => BaseItem {
        return this.PanelsInfoDirect[panelName];
    }
}

export const PanelItemsFactory = new _PanelItemsFactory();
