import { ContainerControl } from './ContainerControl';
import { TerceraGroupButtonsTemplate } from '../../templates';

export class TerceraGroupButtons extends ContainerControl {
    constructor () {
        super();
    }

    private onBtnClick (buttonInfo): void {
        const buttonsInfo = this.get('buttonsInfo');
        for (let i = 0; i < buttonsInfo.length; i++) {
            buttonsInfo[i].isChecked = buttonsInfo[i].name === buttonInfo.name;
        }
        this.fire('groupBtnClick', this, buttonInfo);
        void this.update();
    }
}

export class TerceraButtonInfo {
    public readonly name: string;
    public text: string;
    public isChecked: boolean;
    constructor (name: string, text: string, isChecked: boolean) {
        this.name = name;
        this.text = text;
        this.isChecked = isChecked;
    }
}

ContainerControl.extendWith(TerceraGroupButtons, {
    template: TerceraGroupButtonsTemplate,
    data: function () {
        return {
            buttonsInfo: []
        };
    }
});
