// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { VideoWidgetPanelSideBarTemplate } from '../../../../templates.js';
import { ControlsTypes } from '../../../UtilsClasses/FactoryConstants';
import { Resources } from '../../../../Commons/properties/Resources';
import { HtmlScroll } from '../../../../Commons/HtmlScroll';
import { DataCache } from '../../../../Commons/DataCache';
import { VideoWidgetItemSideBar } from '../SideBarItems/VideoWidgetItemSideBar';
import { CustomEvent } from '../../../../Utils/CustomEvents';
import { PanelSideBarBase } from './PanelSideBarBase';

export class VideoWidgetPanelSideBar extends PanelSideBarBase {
    public static OnChangeBtnStateEvents = new CustomEvent();
    broadcastingLiveNowNumber = 0; // number of LIVE streams in the widget at the moment
    skipOnSave = true;
    externalLinksArrayLengthWasSet = null;
    torndown = null;
    visibilityBeforeTorndown = null;

    constructor () { super(); }

    public getType (): ControlsTypes {
        return ControlsTypes.VideoWidgetPanelSideBar;
    }

    oncomplete (): void {
        super.oncomplete();

        this.init();
        this.observe('cacheReference', this.onCacheReferenceChanged);
        this.observe('externalLinks', this.onExternalLinksSet);
    }

    private init (): void {
        VideoWidgetItemSideBar.OnWidgetCardEvents.Subscribe(this.widgetCardEvents, this);

        void this.set({
            cacheReference: DataCache.ExternalLinksCache,
            externalLinks: DataCache.GetYouTubeExternalLinks()
        });
    }

    private updateCallerBtnState (): void {
        VideoWidgetPanelSideBar.OnChangeBtnStateEvents.Raise(this.broadcastingLiveNowNumber);
    }

    onteardown (): void {
        VideoWidgetItemSideBar.OnWidgetCardEvents.UnSubscribe(this.widgetCardEvents, this);
        this.allCardsEventsUnSubscribe();

        void this.set('cacheReference', null); // To unsubscribe from the UpdateAllLinks process
    }

    private UpdateAllLinks (): void {
        if (this.torndown) { // #115375
            return;
        }

        this.visibilityBeforeTorndown = this.get('visible');

        this.dispose();
    }

    private onCardFullDataFetched (card): void {
        if (card) {
            const cards = this.get('cards');
            const eL = card.get('externalLink');

            const needUpdateOnStreamStart = eL.IsUpcomingStream && eL.IsLiveStream === false && card.StreamHasNotStartedYet === false;
            const needUpdateOnStreamEnd = eL.IsEndedStream === false && eL.IsLiveStream && card.StreamHasBeenAlreadyEnded;

            if (eL) {
                if (!eL.DateForSorting || needUpdateOnStreamStart || needUpdateOnStreamEnd) {
                    const cacheReference = this.get('cacheReference');
                    const cache = cacheReference ? cacheReference.CachedYoutubeResponseData : null;

                    if (needUpdateOnStreamStart) {
                        cache[card.VideoID].isLIVE = eL.IsLiveStream = true;
                        cache[card.VideoID].streamHasNotStartedYet = eL.IsUpcomingStream = false;
                    }

                    if (needUpdateOnStreamEnd) {
                        cache[card.VideoID].streamHasBeenAlreadyEnded = eL.IsEndedStream = true;
                        cache[card.VideoID].isLIVE = eL.IsLiveStream = eL.liveStream = false;
                    }

                    this.UpdateAllLinks(); return;
                }

                if (eL.id) {
                    cards[eL.id] = card;
                    void this.set('cards', cards);
                }
            }
        }

        if (this.allCardsFetched()) {
            this.finishLoadingProcess();
        }
    }

    private finishLoadingProcess (): void {
        const loaded = this.allCardsFetched();
        const cards = this.get('cards');
        let visibleCardsNum = 0;
        let liveNum = 0;
        for (const id in cards) {
            const card = cards[id];
            const visibleCard = card?.gotFetchedResponse && !card.get('expired') && card.ParsedSuccessfully;
            if (visibleCard) {
                if (card.IsLIVE) { liveNum++; }

                card.set('visible', loaded);
                visibleCardsNum++;
            }
        }

        if (liveNum !== this.broadcastingLiveNowNumber) {
            this.broadcastingLiveNowNumber = liveNum;
            this.updateCallerBtnState();
        }

        void this.set({
            visibleCardsNum,
            loaded
        });

        if (loaded && visibleCardsNum > 0) {
            this.allVideosScrollAdd();
        }
    }

    private onExternalLinksSet (newValue): void {
        this.externalLinksArrayLengthWasSet = newValue ? newValue.length : 0;

        void this.set({
            visibleCardsNum: this.externalLinksArrayLengthWasSet,
            loaded: !newValue?.length
        });

        if (this.allCardsFetched()) {
            this.finishLoadingProcess();
        }
    }

    private allCardsFetched (): boolean {
        return this.getCardsFetchedNum() === this.externalLinksArrayLengthWasSet;
    }

    private getCardsFetchedNum (): number {
        const cards = this.get('cards');
        let fetchedNum = 0;
        for (const id in cards) {
            const card = cards[id];
            if (card?.gotFetchedResponse) {
                fetchedNum++;
            }
        }

        return fetchedNum;
    }

    private OnCardIsLIVEStatusChanged (card): void {
        this.broadcastingLiveNowNumber += card.IsLIVE ? 1 : -1;

        if (!this.broadcastingLiveNowNumber || this.broadcastingLiveNowNumber === 1) { // if need turn blink OFF or ON
            VideoWidgetPanelSideBar.OnChangeBtnStateEvents.Raise(this.broadcastingLiveNowNumber);
        }
    }

    private onLinkAvailableUntilExpired (expiredExternalLink): void {
        const id = expiredExternalLink ? expiredExternalLink.id : null;
        const cards = this.get('cards');
        const expiredCard = cards[id];
        if (expiredCard) {
            expiredCard.set({ expired: true, visible: false });
        }

        const links = this.get('externalLinks');
        if (links) {
            links.splice(links.indexOf(expiredExternalLink), 1);
            void this.set('externalLinks', links).then(() => {
                this.finishLoadingProcess();
            });
        }
    }

    private allCardsEventsUnSubscribe (): void {
        const cards = this.get('cards');
        for (const id in cards) {
            const card = cards[id];
            this.unsubscribeWidgetFromCardEvents(card);
        }
    }

    private widgetCardEvents (item: VideoWidgetItemSideBar, isSubscribe: boolean): void {
        if (isSubscribe) {
            this.subscribeWidgetToCardEvents(item);
        } else {
            this.unsubscribeWidgetFromCardEvents(item);
        }
    }

    private subscribeWidgetToCardEvents (card): void {
        if (!card) {
            return;
        }

        card.OnFullDataFetched.Subscribe(this.onCardFullDataFetched, this);
        card.OnIsLIVEChanged.Subscribe(this.OnCardIsLIVEStatusChanged, this);

        const extLink = card.get('externalLink');
        if (extLink) {
            extLink.onAvailableUntilExpired.Subscribe(this.onLinkAvailableUntilExpired, this);
            extLink.onStreamNeedUpdate.Subscribe(this.UpdateAllLinks, this);
        }
    }

    private unsubscribeWidgetFromCardEvents (card): void {
        if (!card) {
            return;
        }

        card.OnFullDataFetched.UnSubscribe(this.onCardFullDataFetched, this);
        card.OnIsLIVEChanged.UnSubscribe(this.OnCardIsLIVEStatusChanged, this);

        const extLink = card.get('externalLink');
        if (extLink) {
            extLink.onAvailableUntilExpired.UnSubscribe(this.onLinkAvailableUntilExpired, this);
            extLink.onStreamNeedUpdate.UnSubscribe(this.UpdateAllLinks, this);
        }
    }

    /**
     * Handle the change in the external links cache subscription.
     *
     * @param {null|undefined|import('../../../Commons/cache/ExternalLinksCache.ts').ExternalLinksCache} cacheReference - The new cache reference.
     * @param {null|undefined|import('../../../Commons/cache/ExternalLinksCache.ts').ExternalLinksCache} oldCacheReference - The old cache reference for unsubscription.
     */

    private onCacheReferenceChanged (cacheReference, oldCacheReference): void {
        if (cacheReference) {
            cacheReference.OnAddLink.Subscribe(this.UpdateAllLinks, this);
            cacheReference.OnUpdateLink.Subscribe(this.UpdateAllLinks, this);
            cacheReference.OnDeleteLink.Subscribe(this.UpdateAllLinks, this);
        } else if (oldCacheReference) {
            oldCacheReference.OnAddLink.UnSubscribe(this.UpdateAllLinks, this);
            oldCacheReference.OnUpdateLink.UnSubscribe(this.UpdateAllLinks, this);
            oldCacheReference.OnDeleteLink.UnSubscribe(this.UpdateAllLinks, this);
        }
    }

    private allVideosScrollAdd (): void {
        const allVideos = this.find('.videoWidgetPanelSideBar');
        if (!isNullOrUndefined(allVideos)) {
            HtmlScroll.addScroll(allVideos);
        }
    }

    public localize (): void {
        void this.set({
            _noDataText: Resources.getResource('panel.VideoWidget.noDataText'),
            isLoadingText: Resources.getResource('panel.VideoWidget.isLoadingText')
        });
    }
};

PanelSideBarBase.extendWith(VideoWidgetPanelSideBar,
    {
        template: VideoWidgetPanelSideBarTemplate,
        data: function () {
            return {
                externalLinks: [],
                visibleCardsNum: null, // number of visible external links (videos) in widget
                cards: {},
                loaded: false, // Determines whether the loading of data from the API or MongoDB has fully completed yet

                _noDataText: '', // localized text of noData info
                isLoadingText: '', // localized text of IsLoading info

                cacheReference: null, // reference to DataCache.ExternalLinksCache
                noDataImg: 'videoNoData'
            };
        },
        computed: {
            noDataText:
            {
                get: function () {
                    const visibleCardsNum = this.get('visibleCardsNum');
                    const isLoadingText = this.get('isLoadingText');
                    const _noDataText = this.get('_noDataText');
                    return visibleCardsNum ? isLoadingText : _noDataText;
                },
                set: function (value) { }
            }
        }
    });
