import { type Instrument } from '../../Instrument';

export class Option {
    public readonly strikePrice: number;
    public readonly put: Instrument;
    public readonly call: Instrument;
    public readonly putEnabled: boolean;
    public readonly callEnabled: boolean;

    constructor (strikePrice: number, put: Instrument, call: Instrument, putEnabled: boolean, callEnabled: boolean) {
        this.strikePrice = strikePrice;
        this.put = put;
        this.call = call;
        this.putEnabled = putEnabled;
        this.callEnabled = callEnabled;
    }
}
