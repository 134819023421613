import { HistoryType } from './HistoryType';

class _HistoryTypeConverter {
    public GetLocalHistoryType (serverType: number): number {
        const res = this.ServerHistoryTypes[serverType];
        if (!isNullOrUndefined(res)) { return res; }
        return HistoryType.QUOTE_LEVEL1;
    }

    private readonly ServerHistoryTypes: Record<number, number> = {
        0: HistoryType.QUOTE_LEVEL1,
        1: HistoryType.QUOTE_TRADES,
        2: HistoryType.QUOTE_ASK
    };

    private readonly LocalHistoryTypes = ((): Record<number, number> => {
        const rec: Record<number, number> = {};
        for (const serv in this.ServerHistoryTypes) {
            rec[this.ServerHistoryTypes[serv]] = parseFloat(serv);
        }
        return rec;
    })();

    public GetServerHistoryType (localType: number): number {
        const res = this.LocalHistoryTypes[localType];
        if (!isNullOrUndefined(res)) { return res; }
        return 0;
    }
}
export const HistoryTypeConverter = new _HistoryTypeConverter();
