import { ColorStyleWidth, type DynProperty } from '../Commons/DynProperty';
import { Pen } from '../Commons/Graphics';
import { type AnalyzerChartData } from '../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerChartData';
import { type AnalyzerInfoData } from '../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerInfoData';
import { AnalyzerProbabilityCalculationMode } from '../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerProbabilityCalculationMode';
import { type OptionTrader } from '../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { Resources } from '../Commons/properties/Resources';
import { TerceraChartAnalyzerInfoWindowRenderer } from './Renderers/InfoWindow/TerceraChartAnalyzerInfoWindowRenderer';
import { type TerceraChartNumberScaleRenderer } from './Renderers/Scales/TerceraChartNumberScaleRenderer';
import { TerceraChartBaseCrossHairRenderer } from './Renderers/TerceraChartBaseCrossHairRenderer';
import { TerceraChartRangeRenderer } from './Renderers/TerceraChartRangeRenderer';
import { LineStyle } from './Renderers/TerceraChartStyledLineRenderer';
import { TerceraChartStyledVerticalLineRenderer } from './Renderers/TerceraChartStyledVerticalLineRenderer';
import { TerceraChartAggregatedXYSeries, TerceraChartXYSeries, XYSeriesData } from './Series/TerceraChartXYSeries';
import { TerceraXYChart } from './TerceraXYChart';
import { type TerceraChartXYWindow } from './Windows/TerceraChartXYWindow';

export class OptionAnalyzerChart extends TerceraXYChart {
    private static readonly ZERO_LINE = 0;
    private static readonly TIME_LINE = 1;
    private static readonly INTRINSIC_LINE = 2;

    private _optionTrader: OptionTrader;
    private _basePriceLineRenderer: TerceraChartStyledVerticalLineRenderer;
    private _infoWindowRenderer: TerceraChartAnalyzerInfoWindowRenderer;
    private _rangeRenderer: TerceraChartRangeRenderer;

    constructor (ctx: CanvasRenderingContext2D, terceraChartPanelContext) {
        super(ctx, terceraChartPanelContext);
    }

    public override CreateMainWindow (): TerceraChartXYWindow {
        const mainWindow = super.CreateMainWindow();
        mainWindow.leftYScaleRenderer.Step = 0.01;
        mainWindow.leftYScaleRenderer.Precision = 2;
        mainWindow.rightYScaleRenderer.Step = 0.01;
        mainWindow.rightYScaleRenderer.Precision = 2;
        return mainWindow;
    }

    public override InitializeRenderers (): void {
        super.InitializeRenderers();
        this._basePriceLineRenderer = new TerceraChartStyledVerticalLineRenderer(this);
        this._infoWindowRenderer = new TerceraChartAnalyzerInfoWindowRenderer(this);
        this._rangeRenderer = new TerceraChartRangeRenderer(this);
        this._forceUpdateInitiators.push(this._infoWindowRenderer);
        this.mainWindow.Renderers.push(this._basePriceLineRenderer);
        this.mainWindow.Renderers.push(new TerceraChartBaseCrossHairRenderer(this));
        this.mainWindow.Renderers.push(this._infoWindowRenderer);
        this.mainWindow.Renderers.push(this._rangeRenderer);
    }

    public override DrawNoData (gr: CanvasRenderingContext2D): void {
        this.NoDataMessage = Resources.getResource('panel.optionAnalyzer.chart.NoData');
        super.DrawNoData(gr);
    }

    public override ThemeChanged (): void {
        super.ThemeChanged();
        this.refreshVolatilityLines();
    }

    public override Localize (): void {
        super.Localize();
        const rangeRenderer = this._rangeRenderer;
        rangeRenderer.leftBorder.Label = rangeRenderer.rightBorder.Label = Resources.getResource('property.Probability');
    }

    public override Properties (): DynProperty[] {
        return this._infoWindowRenderer.Properties();
    }

    public override callBack (properties: DynProperty[]): void {
        this._infoWindowRenderer.callBack(properties);
    }

    public attachOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
    }

    public detachOptionTrader (): void {
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        this._optionTrader = null;
    }

    public set (chartData: AnalyzerChartData): void {
        this.updateBasePriceLines(chartData.underlierPrice);
        const series = this.createSeries(chartData);
        this.setSeries(series);
        this.refreshVolatilityLines();
    }

    public update (chartData: AnalyzerChartData): void {
        this.updateBasePriceLines(chartData.underlierPrice);
        const series = this.createSeries(chartData);
        super.updateSeries(series);
        this.refreshVolatilityLines();
        this.IsDirty(true);
    }

    private updateBasePriceLines (price: number): void {
        const basePriceLineSeries = new TerceraChartXYSeries();
        basePriceLineSeries.data.push(new XYSeriesData(price, 0));
        this._basePriceLineRenderer.Series = basePriceLineSeries;
        this._infoWindowRenderer.setDefaultData(price, 0);
    }

    private createSeries (chartData: AnalyzerChartData): TerceraChartAggregatedXYSeries {
        const series = new TerceraChartAggregatedXYSeries();
        const zeroLineSeries = new TerceraChartXYSeries();
        const timeLineSeries = new TerceraChartXYSeries();
        const intrinsicSeries = new TerceraChartXYSeries();
        const volatilitySeries: TerceraChartXYSeries[] = [];
        for (let i = 0; i < chartData.zeroLineSeries.length; i++) {
            zeroLineSeries.data.push(new XYSeriesData(chartData.zeroLineSeries[i].x, chartData.zeroLineSeries[i].y));
        }
        for (let i = 0; i < chartData.timeLineSeries.length; i++) {
            timeLineSeries.data.push(new XYSeriesData(chartData.timeLineSeries[i].x, chartData.timeLineSeries[i].y));
        }
        for (let i = 0; i < chartData.intrinsicSeries.length; i++) {
            intrinsicSeries.data.push(new XYSeriesData(chartData.intrinsicSeries[i].x, chartData.intrinsicSeries[i].y));
        }
        for (let i = 0; i < chartData.volatilitySeries.length; i++) {
            const volatilitySeriesItem = new TerceraChartXYSeries();
            for (let j = 0; j < chartData.volatilitySeries[i].length; j++) {
                volatilitySeriesItem.data.push(new XYSeriesData(chartData.volatilitySeries[i][j].x, chartData.volatilitySeries[i][j].y));
            }
            volatilitySeries.push(volatilitySeriesItem);
        }
        series.data.push(zeroLineSeries, timeLineSeries, intrinsicSeries, ...volatilitySeries);
        return series;
    }

    public refreshVolatilityLines (): void {
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        const optionAnalyzer = this._optionTrader.optionAnalyzer;
        const basePriceLineRenderer = this.mainWindow.GetRenderer(TerceraChartStyledVerticalLineRenderer);
        if (!isNullOrUndefined(basePriceLineRenderer)) {
            basePriceLineRenderer.isCurve = false;
            basePriceLineRenderer.ColorStyleWidth = new ColorStyleWidth(optionAnalyzer.underlierPriceColor, optionAnalyzer.underlierPriceStyle, optionAnalyzer.underlierPriceWidth);
        }
        const zeroLineRenderer = this.getLineRendererByIndex(OptionAnalyzerChart.ZERO_LINE);
        if (!isNullOrUndefined(zeroLineRenderer)) {
            zeroLineRenderer.LineStyle = LineStyle.Line;
            zeroLineRenderer.ColorStyleWidth = new ColorStyleWidth(optionAnalyzer.zeroLineColor, optionAnalyzer.zeroLineStyle, optionAnalyzer.zeroLineWidth);
        }
        const timeLineRenderer = this.getLineRendererByIndex(OptionAnalyzerChart.TIME_LINE);
        if (!isNullOrUndefined(timeLineRenderer)) {
            timeLineRenderer.ColorStyleWidth = new ColorStyleWidth(optionAnalyzer.timeLineColor, optionAnalyzer.timeLineStyle, optionAnalyzer.timeLineWidth);
        }
        const intrinsicLineRenderer = this.getLineRendererByIndex(OptionAnalyzerChart.INTRINSIC_LINE);
        if (!isNullOrUndefined(intrinsicLineRenderer)) {
            intrinsicLineRenderer.LineStyle = LineStyle.Line;
            intrinsicLineRenderer.ColorStyleWidth = new ColorStyleWidth(optionAnalyzer.intrinsicColor, optionAnalyzer.intrinsicStyle, optionAnalyzer.intrinsicWidth);
        }
        const volatilityLineIndex = OptionAnalyzerChart.INTRINSIC_LINE + 1;
        for (let i = 0; i < optionAnalyzer.volatilityLines.length; i++) {
            const lineRenderer = this.getLineRendererByIndex(volatilityLineIndex + i);
            if (!isNullOrUndefined(lineRenderer)) {
                lineRenderer.Visible = optionAnalyzer.volatilityLines[i].isActive;
                lineRenderer.ColorStyleWidth = new ColorStyleWidth(optionAnalyzer.volatilityLines[i].color, Pen.csSimpleChart, optionAnalyzer.volatilityLines[i].width);
            }
        }
        this._infoWindowRenderer.isNeedForceUpdate = true;
        this.IsDirty(true);
    }

    public refreshProbabilityLines (): void {
        const rangeRenderer = this._rangeRenderer;
        const optionTrader = this._optionTrader;
        const underierPrice = this._optionTrader.underlier.Level1.GetLastPrice(this._optionTrader.account);
        rangeRenderer.leftBorder.AdditionalLabel = rangeRenderer.rightBorder.AdditionalLabel = '';
        switch (optionTrader.optionAnalyzer.probabilityCalculationMode) {
        case AnalyzerProbabilityCalculationMode.None:
            rangeRenderer.Visible = false;
            break;
        case AnalyzerProbabilityCalculationMode.Single:
            rangeRenderer.leftBorder.Value = underierPrice;
            rangeRenderer.leftBorder.isVisible = true;
            rangeRenderer.rightBorder.Value = NaN;
            rangeRenderer.rightBorder.isVisible = false;
            rangeRenderer.Visible = true;
            break;
        case AnalyzerProbabilityCalculationMode.OR:
        case AnalyzerProbabilityCalculationMode.AND:
            rangeRenderer.leftBorder.Value = underierPrice - underierPrice * 0.01;
            rangeRenderer.leftBorder.isVisible = true;
            rangeRenderer.rightBorder.Value = underierPrice + underierPrice * 0.01;
            rangeRenderer.rightBorder.isVisible = true;
            rangeRenderer.Visible = true;
            break;
        default:
            rangeRenderer.leftBorder.Value = NaN;
            rangeRenderer.leftBorder.isVisible = false;
            rangeRenderer.rightBorder.Value = NaN;
            rangeRenderer.rightBorder.isVisible = false;
            rangeRenderer.Visible = false;
            break;
        }
        this.IsDirty(true);
    }

    public async refreshProbabilityValuesAsync (): Promise<void> {
        const optionTrader = this._optionTrader;
        const rangeRenderer = this._rangeRenderer;
        const probability: { p1: number, p2: number } = await optionTrader.getOptionAnalyzerProbabilityDataAsync(rangeRenderer.leftBorder.Value, rangeRenderer.rightBorder.Value);
        const p1FormattedValue = isValidNumber(probability.p1) ? `${(probability.p1 * 100).toFixed(2)}%` : '';
        const p2FormattedValue = isValidNumber(probability.p2) ? `${(probability.p2 * 100).toFixed(2)}%` : '';
        switch (optionTrader.optionAnalyzer.probabilityCalculationMode) {
        case AnalyzerProbabilityCalculationMode.None:
            rangeRenderer.leftBorder.AdditionalLabel = rangeRenderer.rightBorder.AdditionalLabel = '';
            break;
        case AnalyzerProbabilityCalculationMode.Single:
            rangeRenderer.leftBorder.AdditionalLabel = `: ${p1FormattedValue}`;
            rangeRenderer.rightBorder.AdditionalLabel = '';
            break;
        case AnalyzerProbabilityCalculationMode.OR:
            rangeRenderer.leftBorder.AdditionalLabel = `: ${p1FormattedValue}`;
            rangeRenderer.rightBorder.AdditionalLabel = `: ${p1FormattedValue}`;
            break;
        case AnalyzerProbabilityCalculationMode.AND:
            rangeRenderer.leftBorder.AdditionalLabel = `1: ${p1FormattedValue}`;
            rangeRenderer.rightBorder.AdditionalLabel = `2: ${p2FormattedValue}`;
            break;
        }
        this.IsDirty(true);
    }

    public resetXScale (): void {
        const instrument = this._optionTrader.underlier;
        const xScaleRenderer = this.windowsContainer.xScaleRenderer as TerceraChartNumberScaleRenderer;
        xScaleRenderer.Step = instrument.PointSize;
        xScaleRenderer.Precision = instrument.Precision;
        this.updateMinMaxXScaleBorders();
        this.IsDirty(true);
    }

    public resetYScale (): void {
        this.updateMinMaxYScaleBorders();
    }

    public resetScales (): void {
        this.resetXScale();
        this.resetYScale();
    }

    public getOptionAnalyzerInfoData (x: number): AnalyzerInfoData {
        return this._optionTrader.getOptionAnalyzerInfoData(x);
    }

    public getIntrinsicLineColor (): string {
        return this._optionTrader.optionAnalyzer.intrinsicColor;
    }

    public getTimeLineColor (): string {
        return this._optionTrader.optionAnalyzer.timeLineColor;
    }

    public getVolatilityLineColor (index: number): string {
        return this._optionTrader.optionAnalyzer.volatilityLines[index].color;
    }

    public getVolatilityLineVisibility (index: number): boolean {
        return this._optionTrader.optionAnalyzer.volatilityLines[index].isActive;
    }
}
