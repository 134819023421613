// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { Resources } from '../../Commons/properties/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { KeyCode, KeyEventProcessor } from '../../Commons/KeyEventProcessor';
import { InformerPanelTopTemplate } from '../../templates.js';
import { type BaseInformer, Infor } from '../cache/BaseInformer';
import { TerceraInstrumentLookupDropDownForm } from '../elements/Lookup/TerceraInstrumentLookupDropDownForm';
import { ColouringModes } from '../elements/QuickTable/QuickTableColumn';
import { TerceraQuickTreeEvents } from '../elements/QuickTree/TerceraQuickTree';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TerceraMenu } from '../elements/TerceraMenu';
import { ExternalScreen } from '../screen/ExternalScreen';
import { MessageBoxType, TerceraMessageBox } from '../screen/TerceraMessageBox';
import { TerceraQuickInstrumentLookupScreen } from '../screen/TerceraQuickInstrumentLookupScreen';
import { PanelLocKeys, PanelNames } from '../UtilsClasses/FactoryConstants';
import { LookupDropDownShowParams } from '../UtilsClasses/LookupDropDownShowParams';
import { OrderType } from '../../Utils/Trading/OrderType';
import { DynProperty } from '../../Commons/DynProperty';
import { InterTraderBtnStatus } from '../../Utils/Instruments/InterTraderBtnStatus';
import { DataCache } from '../../Commons/DataCache';
import { TerceraSymbolLookupBaseDataProvider } from '../../Commons/NoNFixedListCore';
import { SessionSettings } from '../../Commons/SessionSettings';
import { CustomerAccess } from '../../Commons/CustomerAccess/CustomerAccess';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { TerceraLookup } from '../elements/Lookup/TerceraLookup';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type QuickTable } from '../elements/QuickTable/QuickTable';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { type TerceraEditableListComboBox } from '../elements/TerceraEditableListComboBox';
import { type SearchHelper } from '../../Commons/SearchHelper';
import { Md5 } from 'ts-md5';
import $ from 'jquery';

export class InformerPanel extends ApplicationPanelWithTable<BaseInformer> {
    public static readonly REMOVESYMBOL = 'removeSymbol';
    public static readonly REMOVE_ALL = 'REMOVE_ALL';
    public static readonly SYMBOL_LISTS = 'SYMBOL_LISTS';

    public headerLocaleKey: string = 'panel.informer';
    public SymbolInfoPanelAllowed: boolean = false;
    public OnInstrumentChange: CustomEvent | null = null;

    public moreThanOneTTKey: string = 'panel.watchlist.menu.MoreThanOneSymbolSelected.tt'; // #95439
    public noOneTTKey: string = 'panel.watchlist.menu.NoSymbolSelected.tt';

    public quickInstrumentSelector: any = null;
    public promiseHandler: any = null;
    public dataProvider: TerceraSymbolLookupBaseDataProvider;

    constructor () {
        super();

        this.dataProvider = new TerceraSymbolLookupBaseDataProvider();
        this.OnInstrumentChange = new CustomEvent();
    }

    public override getType (): PanelNames { return PanelNames.InformerPanel; }

    public override oninit (): void {
        super.oninit();
        this.on('onAdd', this.onAdd);
        this.observe('isAccountLinkShow', (link: boolean) => {
            if (!link) {
                void this.set('account', DataCache.getPrimaryAccount());
            }
        });
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.SymbolInfoPanelAllowed = CustomerAccess.panelAllowed(PanelNames.SymbolInfoPanel);
        this.populateTableContextMenu();

        const qt = this.getQuickTable();
        qt.OnSelectionChanged.Subscribe(this.selectionChange, this);
        qt.OnPaintedPictureButtonClick.Subscribe(this.onPaintedPictureButtonClick, this);
        this.quickTableRactive.on(TerceraQuickTreeEvents.DoubleClick, this.onDoubleClick.bind(this));

        const editCB: TerceraEditableListComboBox = this.Controls.editableCB;
        editCB.OnSelectList.Subscribe(this.selectInstrumentList, this);
        editCB.observe('items checkedMenuItem', this.updateSymbolLists.bind(this)); // context menu's lists update

        KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
        this.quickInstrumentSelector = null;

        editCB.InitByDefaultOrFirst();
        if (!isNullOrUndefined(this.deferredCallbackProps)) {
            this.callBack(this.deferredCallbackProps);
            delete this.deferredCallbackProps;
        }
    }

    public override repopulate (): void {
        const qt = this.getQuickTable();
        this.visibleColumnChanged(qt.getVisibleColumn());

        const rows = qt.rowsArray;
        const len = rows.length;
        let resArr = '';
        for (let i = 0; i < len; i++) {
            resArr = resArr + rows[i].item.InstrumentName() + ';';
        }
        qt.ClearAll();
        void this.addInstrumentsFromString(resArr);
    }

    public onGlobalKeyDown (): void {
        if (!this.get<boolean>('focused')) return;

        const keyProc = KeyEventProcessor;
        if (keyProc.currentButton !== KeyCode.DELETE || keyProc.WithModButtons()) {
            return;
        }

        this.onRemoveSelected();
    }

    public override dispose (): void {
        KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);

        this.Controls.editableCB.OnSelectList.UnSubscribe(this.selectInstrumentList, this);

        super.dispose();
    }

    public onAdd (): void {
        const obj: any = {};
        (TerceraLookup.prototype.setItems.bind(obj))(DataCache.Instruments);

        const params = new LookupDropDownShowParams();
        // SET ITEMS HERE
        params.items = obj.items;
        params.callBack = this.instrumentsDropDownCallback.bind(this);
        params.isMultiSelect = true;
        // params.isMultiSelectMode = true;
        params.isCentered = true;
        params.autoClose = false;
        params.parentPanel = this;
        params.dataProvider = this.dataProvider;
        TerceraInstrumentLookupDropDownForm.ShowForm(params);
    }

    public override preparePopup (): void {
        super.preparePopup();
        const removeAllMenuItem = this.menuTagDict[InformerPanel.REMOVE_ALL];
        removeAllMenuItem.enabled = this.getQuickTable().rowsArray.length > 0;
    }

    public override updatePanelHeader (): void {
        void this.set({ header: Resources.getResource(this.headerLocaleKey) + (this.NeedCalculateRowCount ? ('  (' + this.rowCount + ')') : '') });
    }

    public AddInstrumentToTable (instrument: Instrument): void {
        const qtRactive = this.quickTableRactive;
        const qt = !isNullOrUndefined(qtRactive) ? qtRactive.quickTable : null;
        if (isNullOrUndefined(qt)) {
            return;
        }

        const dc = DataCache;
        const sess = SessionSettings;
        const rowDict = qt.rows;

        const insFullName = instrument.GetInteriorID();
        const row = rowDict[insFullName];
        if (isNullOrUndefined(row)) {
            const item = new Infor(insFullName, dc, sess, false, this);
            qt.AddItem(item);
        }
    }

    public OnQuickTableEditingInfoMapChanged (itemID, newQuickTableEditingInfo: QuickTableEditingInfo): void {
        const qt = this.getQuickTable();
        const row = !isNullOrUndefined(qt) ? qt.getItemById(itemID) : null;

        const cell = !isNullOrUndefined(row) && (row.cells.length > 0) ? row.cells[0] : null; // ячейка нулевой колонки - Symbol для родительской строки текущего айтема
        const controlTypeChanged = !isNullOrUndefined(cell) && cell.ReadOnly === !!newQuickTableEditingInfo;
        if (controlTypeChanged) {
            cell.QuickTableEditingInfo = newQuickTableEditingInfo;

            const item = row.item;
            const ins = !isNullOrUndefined(item) ? item.GetCurrentInstrument() : null;
            if (!isNullOrUndefined(cell.QuickTableEditingInfo) && !isNullOrUndefined(ins)) {
                cell.QuickTableEditingInfo.GetDataHandler = ins.GetDelayedIconTooltip.bind(ins);
            }

            cell.ReadOnly = !newQuickTableEditingInfo;
            cell.isDirty = true;
        }
    }

    public selectInstrumentList (InstrStr: string): void {
        this.onRemoveAll(true);
        void this.addInstrumentsFromString(InstrStr);
    }

    public onInstrumentListChange (): void {
        const editableCB: TerceraEditableListComboBox = this.Controls.editableCB;
        const rows = this.getQuickTable().rowsArray;
        if (!editableCB?.get<boolean>('selectedItem')) {
            return;
        }

        const currentList = editableCB.get('selectedItem');
        const allLists = editableCB.get('valueOfLists');
        const list = allLists[currentList.value];

        list.itemsStr = '';
        for (let i = 0; i < rows.length; i++) {
            if (!isNullOrUndefined(rows[i].item)) {
                list.itemsStr += rows[i].item.InstrumentName(true);
                if (i + 1 < rows.length) {
                    list.itemsStr += ';';
                }
            }
        }

        editableCB.updateListsValue(null, false);
    }

    public onRemoveSelected (): void {
        const qtRactive = this.quickTableRactive;
        const qt = !isNullOrUndefined(qtRactive) ? qtRactive.quickTable : null;
        if (!isNullOrUndefined(qt)) {
            qt.RemoveSelectedItems();
        }

        this.onInstrumentListChange();
    }

    public override getSelectedInstrumentID (): string {
        const ins = this.getInstrument();
        let result = '';

        if (!isNullOrUndefined(ins)) {
            result = ins.GetInteriorID();
        }

        return result;
    }

    public onRemoveAll (skipUpdate: boolean): void {
        const qtRactive = this.quickTableRactive;
        const qt = !isNullOrUndefined(qtRactive) ? qtRactive.quickTable : null;
        if (isNullOrUndefined(qt)) return;

        qt.ClearRows();
        this.updatePanelHeader();

        if (!skipUpdate) {
            this.onInstrumentListChange();
        }
    }

    public override onDoubleClick (): void {
        const ins = this.getSelectedInstrumentID();
        if (!isNullOrUndefined(ins))
        // Open OE.
        {
            MainWindowManager.Factory.addPanel(PanelNames.AdvancedOrderEntry, null, function (panel) {
                panel.symbolLink_In(ins);
            });
        }
        // Open Lookup.
        else {
            this.onAdd();
        }
    }

    public updateSymbolLists (): void {
        const editableCB: TerceraEditableListComboBox = this.Controls.editableCB;
        const newMenuItems = editableCB.createMenuItems();

        const symbolListsMenuItem = this.menuTagDict[InformerPanel.SYMBOL_LISTS];
        symbolListsMenuItem.subitems = newMenuItems;
    }

    public populateTableContextMenu (): void {
        let items = [];

        items.push({
            locKey: 'panel.watchlist.menu.AddSymbol',
            event: this.onAdd.bind(this)
        });

        this.AddSymbolInfoContextMenuItemIfNeed(items, false);

        items = items.concat([
            {
                locKey: 'panel.watchlist.menu.RemoveInstrument',
                enabled: false,
                event: this.onRemoveSelected.bind(this),
                tag: InformerPanel.REMOVESYMBOL
            },
            {
                locKey: 'panel.watchlist.menu.clearAll',
                event: this.onRemoveAll.bind(this, false),
                enabled: false,
                tag: InformerPanel.REMOVE_ALL
            },
            { separator: true },
            {
                locKey: 'panel.watchlist.menu.SymbolLists',
                subitems: this.Controls.editableCB.menuItems,
                advParams: { isComboboxMenu: true, isEditableListComboBoxMenu: true },
                tag: InformerPanel.SYMBOL_LISTS
            }
        ]);

        items = this.AddOpeningPanelsCM(items);

        this.menuTagDict = TerceraMenu.createTagDictionary(items);
        this.getQuickTable().setTableContextMenuItems(items);

        this.localize();
    }

    public override GetOpeningPanelsCMLocKeysSet (): Record<string, string> {
        const keysObj: Record<string, string> = {};

        keysObj[ApplicationPanelWithTable.OPEN_CHART] = 'panel.watchlist.menu.Chart';
        keysObj[ApplicationPanelWithTable.OPEN_MD] = 'panel.watchlist.menu.MarketDepth';
        keysObj[ApplicationPanelWithTable.OPEN_TS] = 'panel.watchlist.menu.TimeSales';
        keysObj[ApplicationPanelWithTable.OPEN_OE] = 'panel.watchlist.menu.OrderEntry';

        return keysObj;
    }

    public override themeChange (): void {
        if (!isNullOrUndefined(this.quickTableRactive)) {
            this.quickTableRactive.themeChange();
        }
    }

    public override localize (): void {
        super.localize();

        const qt = this.getQuickTable();
        const contextItems = qt.tableContextMenuItems;

        if (!isValidArray(contextItems)) {
            return;
        }

        for (let i = 0; i < contextItems.length; i++) {
            const item = contextItems[i];
            if (isValidString(item?.locKey)) {
                item.text = Resources.getResource(item.locKey);
            }
        }

        qt.tableContextMenuItems = contextItems;

        const symbolListsMenuItem = this.menuTagDict[InformerPanel.SYMBOL_LISTS];
        const subItems = symbolListsMenuItem.subitems;
        subItems[subItems.length - 1].text = Resources.getResource('editableComboBox.CreateNew');
        this.Controls.editableCB.localize();
    }

    public selectionChange (): void {
        const qt = this.getQuickTable();
        const selectedRowsId = qt.selectedRowIds;
        if (!isValidArray(selectedRowsId)) {
            return;
        }

        let ins = '';
        const ids = selectedRowsId;
        if (ids.length > 0) {
            const row = qt.rows[ids[0]];
            ins = row.item.InstrumentName();
        }
        this.symbolLink_Out(this.isNewSubscriber(), ins);

        const enabled = !!ins;
        const menuTagDict = this.menuTagDict;

        const removeMenuItem = menuTagDict[InformerPanel.REMOVESYMBOL];
        removeMenuItem.enabled = enabled;
        this.SetOpeningPanelsCMEnability(menuTagDict, enabled);
    }

    public onPaintedPictureButtonClick (data, event): void {
    // if (data.controlType === DynProperty.DELAYED_PICTURE_RIGHT_AND_TEXT)    // #112155 -> при клики на delayed icon пока никаких действий, только отображение тултипа по ховеру
    // {
    // //     // App.mainWindow.ShowSymbolInfo(data.row.item.instrument, { X: event.pageX, Y: event.pageY })

        //     return
        // }

        if (data.controlType === DynProperty.ITCHART_ADVANCED) {
            const link = this.getITChartAdvancedLink();

            if (isValidString(link)) {
                if (DataCache.ExternalLinksCache.ITChartAdvancedExternalTool.useInternalBrowser) {
                    ExternalScreen.Show({ Text: link, GetRefreshURL: this.getITChartAdvancedLink.bind(this), ScreenName: 'ITChart Advanced', Aliases: null, Tool: DataCache.ExternalLinksCache.ITChartAdvancedExternalTool });
                } else {
                    window.open(link);
                }
            }

            return;
        }

        if (data.controlType === DynProperty.ORDER_BUTTON || data.controlType === DynProperty.TRADE_BUTTON) {
            const ins = this.getInstrument();
            const insStr = !isNullOrUndefined(ins) ? ins.GetInteriorID() : '';
            const isTrade = data.controlType === DynProperty.TRADE_BUTTON;

            if (!isNullOrUndefined(ins) && ins.GetInterTraderButtonStatus(isTrade).status !== InterTraderBtnStatus.Open) {
                return;
            }

            const orderType = OrderType[isTrade ? 'Market' : 'Limit'];

            MainWindowManager.Factory.addPanel(PanelNames.AdvancedOrderEntry, null, function (panel) {
                panel.symbolLink_In(insStr);
                panel.selectOrderType(orderType);
            });
        }
    }

    public isNewSubscriber (): boolean {
        const color = this.get('symbolLinkValue');
        if (color === TerceraLinkControlConstants.STATE_NONE) {
            return false;
        }

        return !isValidString(LinkedSystem.getSymbol(color));
    }

    public override symbolLink_Out (newSubscriber, instrumentName: string): void {
        if (isNullOrUndefined(instrumentName)) {
            return;
        }

        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrumentName, newSubscriber);
        }

        this.OnInstrumentChange.Raise(instrumentName);
    }

    public override callBack (newProperties: DynProperty[]): void {
        super.callBack(newProperties);

        if (isNullOrUndefined(this.deferredCallbackProps)) {
            this.deferredCallbackProps = newProperties;
            return;
        }

        const dp = DynProperty.getPropertyByName(newProperties, 'selectedTemplate');
        const cb: TerceraEditableListComboBox = this.Controls.editableCB;
        if (isValidString(dp?.value) && !isNullOrUndefined(cb)) {
            cb.restoreCorrectSelectItem(dp.value);
        }
    }

    public override Properties (): DynProperty[] {
        const properties = super.Properties();

        const cb: TerceraEditableListComboBox = this.Controls.editableCB;
        if (!isNullOrUndefined(cb)) {
            const tmpItem = cb.get('selectedItem');
            const tmp = !isNullOrUndefined(tmpItem) ? tmpItem.text : null;
            if (isValidString(tmp)) {
                properties.push(new DynProperty('selectedTemplate', tmp, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
            }
        }

        return properties;
    }

    public async handleGetInstrumentPromises (promises, skipEventRaiseParameter: boolean): Promise<void> {
        if (!isNullOrUndefined(this.promiseHandler)) {
            this.promiseHandler.cancel();
        }

        this.promiseHandler = Promise.all(promises).then((instruments) => { this.responseFillRows(skipEventRaiseParameter, instruments); });
    }

    public async addInstrumentsFromString (instrumentsString: string): Promise<void> {
        // '' need for refresh quickTable
        if (isNullOrUndefined(instrumentsString)) {
            return;
        }

        const instruments = instrumentsString.split(';').filter(Boolean);
        void this.handleGetInstrumentPromises(instruments.map(async insName => await this.dataProvider.getInstrumentByName(insName)).filter(Boolean), true);
    }

    public async instrumentsDropDownCallback (listHelpers: SearchHelper[]): Promise<void> {
        if (!Array.isArray(listHelpers)) {
            return;
        }

        void this.handleGetInstrumentPromises(listHelpers.map(async helper => await this.dataProvider.getInstrument(helper)), false);
    }

    public responseFillRows (skip: boolean, instruments: Instrument[]): TerceraMessageBox | void {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) {
            return;
        }

        const allowedRowsNum = MainWindowManager.Factory.GetThrottlingOperationValue(this.getType(), true).rowsNumber;
        let alreadyAddedNum = isValidArray(qt.rowsArray) ? qt.rowsArray.length : 0;

        for (let i = 0; i < instruments.length; i++) {
            const ins = instruments[i];
            if (!isNullOrUndefined(ins) && !ins.NeedToHide()) {
                if (alreadyAddedNum >= allowedRowsNum && allowedRowsNum !== -1) {
                    return TerceraMessageBox.Show(Resources.getResource('workspace.information'),
                        Resources.getResource('Rows.firstPart') + ' ' + Resources.getResource(PanelLocKeys[this.getType()]) + ' ' + Resources.getResource('Rows.secondPart'),
                        MessageBoxType.Info, null, null, false, true);
                }

                if (isNullOrUndefined(qt.rows[ins.GetInteriorID()])) {
                    alreadyAddedNum++;
                }

                this.AddInstrumentToTable(ins);
            }
        }

        if (!skip) {
            this.onInstrumentListChange();
        }

        this.promiseHandler = null;
    }

    public override workWithToolBar (showHide: boolean): void {
        void this.set({ showHide });
        this.layoutTable();
    }

    public override _onKeyDownProcess (event): void {
        const DOMElement = $(event.node.parentNode.parentNode);
        const w = DOMElement.width();
        const h = DOMElement.height();
        const pos = DOMElement.position();
        let posX = w / 2 + pos.left - TerceraQuickInstrumentLookupScreen.WIDTH / 2;
        posX = posX < 5 ? 5 : posX;
        const posY = h / 2 + pos.top - TerceraQuickInstrumentLookupScreen.HEIGHT / 2;
        this.quickInstrumentSelector = TerceraQuickInstrumentLookupScreen.Show(posX, posY, event, this.instrumentsDropDownCallback.bind(this), this.onAdd.bind(this), this);
    }

    public getITChartAdvancedLink (): string | null {
        const tool = DataCache.ExternalLinksCache.ITChartAdvancedExternalTool;

        if (isNullOrUndefined(tool)) {
            return null;
        }

        const ins = this.getInstrument();

        if (isNullOrUndefined(ins)) {
            return null;
        }

        const insTradableID = ins.InstrumentTradableID;
        const acc = DataCache.getUserByLogin(DataCache.UserLogin).Accounts[0];
        const userID = acc.ExtendedFields?.ITChartAdvanced ? acc.ExtendedFields.ITChartAdvanced : acc.userID;
        const locale = 'en_GB';
        const MD5Key = Md5.hashStr(tool.privateKey + userID + locale + insTradableID);
        const link = tool.url + '?uid=' + userID + '&key=' + MD5Key + '&locale=' + locale + '&id=' + insTradableID + '&gmt=' + DateTimeConvertor.getClientTimeZoneOffset() + '&theme=night';

        return link;
    }

    public override SetColumnsDefaultDisplayIndex (table: QuickTable): void {
        table.columns[13].displayedIndex = 1;
    }

    public override SetColumnsColouringMode (table: QuickTable): void {
        this.ProcessSetColumnsColouringMode(table, [3], ColouringModes.Previous);
        table.columnsIndexWithColoringByPrevValue = [3];
        this.ProcessSetColumnsColouringMode(table, [12, 13], ColouringModes.Signed);
    }
}

// ​​Symbol+
// Change+
// Change%+
// Last +

ApplicationPanelWithTable.extendWith(InformerPanel, {
    data: function () {
        return {
            isSymbolLinkShow: true,
            isAccountLinkShow: true,
            canFilterByAccount: false,
            isEnabledCombobox: true,
            editableCBtooltip: 'editableComboBox.tooltip',
            items: []
        };
    },
    partials: {
        bodyPartial: InformerPanelTopTemplate
    }
});
