// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { OrderHistoryItem } from '../cache/OrderHistoryItem';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TerceraMenu } from '../elements/TerceraMenu';
import { ExportScreen } from '../screen/ExportScreen';
import { MessageBoxType, TerceraMessageBox } from '../screen/TerceraMessageBox';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { DynProperty } from '../../Commons/DynProperty';
import { DataCache } from '../../Commons/DataCache';
import { OrderHistoryPanelTemplate } from '../../templates.js';

export class OrderHistoryPanel extends ApplicationPanelWithTable<OrderHistoryItem> {
    public rangeSelectPanel: any = null;
    public InitShowTotals: any;

    constructor () {
        super();

        this.headerLocaleKey = 'panel.orderHistory';
        this.moreThanOneTTKey = 'panel.orders.menu.MoreOneSymbolInfoDisabled.tt'; // #95439
        this.noOneTTKey = 'panel.orders.menu.NoSymbolInfoDisabled.tt';
    }

    public override getType (): PanelNames { return PanelNames.OrderHistoryPanel; }

    public override oncomplete (): void {
        super.oncomplete();

        this.rangeSelectPanel = this.Controls.orderHistoryRangeSelect;
        this.rangeSelectPanel.getReportEvent.Subscribe(this.getOrderHistoryReport, this);
        this.rangeSelectPanel.updateSelectedRange();

        this.populateTableContextMenu();

        const qt = this.getQuickTable();
        qt.AddToEnd = false;
        qt.OnSelectionChanged.Subscribe(this.selectionChange, this);
        DataCache.AddOrderHistoryEvent.Subscribe(this.addNewOrderHistory, this);
        DataCache.RemovedOrderHistoryEvent.Subscribe(this.RemovedOrderHistory, this);

        this.UpdateShowTotalsStateChange(!!this.InitShowTotals);
        if (this.menuTagDict?.ShowTotals) {
            this.menuTagDict.ShowTotals.checked = !!this.InitShowTotals;
        }
    }

    public override dispose (): void {
        DataCache.AddOrderHistoryEvent.UnSubscribe(this.addNewOrderHistory, this);
        DataCache.RemovedOrderHistoryEvent.UnSubscribe(this.RemovedOrderHistory, this);
        this.rangeSelectPanel.getReportEvent.UnSubscribe(this.getOrderHistoryReport, this);

        super.dispose();
    }

    public selectionChange (): void {
        const qt = this.getQuickTable();
        const selectedRowsId = qt.selectedRowIds;
        if (isNullOrUndefined(selectedRowsId)) return;

        let insId = '';
        // let accId = ''

        if (selectedRowsId.length > 0) {
            const row = qt.rows[selectedRowsId[0]];
            const item = row ? row.item : null;
            if (!isNullOrUndefined(item)) {
                const ins = item.GetCurrentInstrument();
                if (!isNullOrUndefined(ins)) {
                    insId = ins.GetInteriorID();
                }

            // let acc = item.GetCurrentAccount()
            // if (acc) accId = acc.BstrAccount
            }
            if (!insId) {
                TerceraMessageBox.Show(Resources.getResource('screen.reports.info'), Resources.getResource('general.InstrumentIsNotAvailableNow'), MessageBoxType.Info, null, null, false, true, null, { cantActionBeforeClose: true });
            }
        }

        this.symbolLink_Out(false, insId);
    // this.accountLink_Out(false, accId)
    }

    public override symbolLink_Out (newSubscriber, instrument): void {
        if (instrument) { return; }

        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrument, newSubscriber);
        }
    }

    public populateItemsDirect (): void {
        super.populateItemsDirect();
        const orderhistoryArr = DataCache.getOrderHistoryArray();
        for (let i = 0, len = orderhistoryArr.length; i < len; i++) {
            this.addNewOrderHistory(orderhistoryArr[i]);
        }
    }

    public populateTableContextMenu (): void {
        const items = [];

        if (!Resources.isHidden('screen.export.visibility')) {
            items.push(
                {
                    text: Resources.getResource('screen.export.contextMenu'),
                    event: ExportScreen.show.bind(null, this)
                }
            );
        }

        if (!Resources.isHidden('panel.orderHistory.print')) {
            items.push(
                {
                    text: Resources.getResource('screen.reports.print'),
                    event: this.printTable.bind(this)
                }
            );
        }

        if (!Resources.isHidden('contextMenu.Totals.visibility')) { // переделать в случае появления новых subitems
            items.push(
                {
                    text: Resources.getResource('panel.positions.menu.View'),
                    tag: 'View',
                    enabled: true,
                    subitems: [
                        {
                            text: Resources.getResource('panel.positions.menu.ShowTotals'),
                            tag: 'ShowTotals',
                            checked: false,
                            enabled: true,
                            canCheck: true,
                            event: this.ShowTotalsStateChange.bind(this)
                        }
                    ]
                }
            );
        }

        this.AddSymbolInfoContextMenuItemIfNeed(items);

        this.getQuickTable().setTableContextMenuItems(items);
        this.menuTagDict = TerceraMenu.createTagDictionary(items);
    }

    public async getOrderHistoryReport (startTime: number, finishTime: number): Promise<void> {
        await this.set('loading', true);
        DataCache.OrderHistoryReportCompleted = false;

        await DataCache.getReportOrderBook(startTime, finishTime).then((orderHistory) => {
            const qt = this.getQuickTable();
            if (isNullOrUndefined(qt)) return;
            qt.ClearRows();

            for (const oH of orderHistory) {
                qt.AddItem(new OrderHistoryItem(oH));
            }

            if (LinkedSystem.accLinkingActive) {
                qt.filterByAccountNumber(LinkedSystem.getAccount()); // отфильтруем по аккаунту позиции, если раскрылся лишний трейд
            }
        }).finally(() => { void this.set('loading', false); });
    }

    public addNewOrderHistory (orderHistory): void {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        // TODO. Ugly.
        // qt.AddToEnd = false

        qt.AddItem(new OrderHistoryItem(orderHistory));
    }

    public RemovedOrderHistory (): void {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        qt.ClearAll();
        this.populateItemsDirect();
    }

    public override Properties (): DynProperty[] {
        const properties = super.Properties();

        properties.push(new DynProperty('ShowTotals', this.getQuickTable().ShowTotals, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    public override callBack (newProperties): void {
        super.callBack(newProperties);

        this.InitShowTotals = DynProperty.getPropValue(newProperties, 'ShowTotals');
    }

    public override SetColumnsDefaultDisplayIndex (table): void {
        table.columns[8].displayedIndex = 9;
        table.columns[9].displayedIndex = 10;
        table.columns[10].displayedIndex = 11;
        table.columns[11].displayedIndex = 12;
        table.columns[12].displayedIndex = 13;
        table.columns[13].displayedIndex = 14;
        table.columns[14].displayedIndex = 15;
        table.columns[15].displayedIndex = 8;
        table.columns[12].displayedIndex = 1;
        table.columns[11].displayedIndex = 2;
        table.columns[1].displayedIndex = 3;
        table.columns[2].displayedIndex = 4;
        table.columns[3].displayedIndex = 16;
    }

    public override SetColumnsColouringMode (table): void {

    }
}

ApplicationPanelWithTable.extendWith(OrderHistoryPanel,
    {
        data: function () {
            return {
                isAccountLinkShow: true,
                isSymbolLinkShow: true
            };
        },

        partials: {
            bodyPartial: OrderHistoryPanelTemplate
        }
    }
);
