// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// TODO. Move.

import { TimeZoneInfo } from './TimeZoneInfo';

declare global{
    interface Window {
        TimeZoneJsonArray: any[]
    }
}

function getTimeZoneInfoSet (): Record<string, TimeZoneInfo> {
    const timeZoneJsonArray = window.TimeZoneJsonArray;
    const timeZoneInfoSet = {};
    const len = timeZoneJsonArray.length;
    for (let i = 0; i < len; i++) {
        const tzInfo = new TimeZoneInfo(timeZoneJsonArray[i]);
        timeZoneInfoSet[tzInfo.id] = tzInfo;
    }

    const localOffset = (new Date()).getTimezoneOffset() / -60;
    timeZoneInfoSet[TimeZoneInfo.LOCAL] = new TimeZoneInfo({ value: TimeZoneInfo.LOCAL, offset: localOffset });
    timeZoneInfoSet[TimeZoneInfo.SERVER] = new TimeZoneInfo({ value: TimeZoneInfo.SERVER, offset: localOffset });

    return timeZoneInfoSet;
}

// Key - utc offset in millis
export const TimeZoneInfoSet = getTimeZoneInfoSet();
