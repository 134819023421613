import { type TerceraSymbolLookupBaseDataProvider } from '../../../Commons/NoNFixedListCore';
import { type Instrument } from '../../../Commons/cache/Instrument';
import { type InstrumentTypes } from '../../../Utils/Instruments/InstrumentTypes';
import { type QuickTree } from '../QuickTree/QuickTree';

export class InstrumentLookupProperties {
    public readonly instrumentLookupType: InstrumentLookupType;
    public readonly instruments: Instrument[] = [];
    public readonly quickTree: QuickTree;
    public readonly currentNameFilter: string;
    // Only for strikes request???
    public dataProvider: TerceraSymbolLookupBaseDataProvider;

    public selectedInstruments: Instrument | Instrument[];
    // Only for Instrument dafaults???
    public isInstrumentDefaults: boolean = false;
    public existingInstrumentTypes: InstrumentTypes[] = [];

    public isOptionMaster: boolean = false;

    constructor (instrumentLookupType: InstrumentLookupType, instruments: Instrument[], quickTree: QuickTree, filterStr: string) {
        this.instrumentLookupType = instrumentLookupType;
        this.instruments = instruments;
        this.quickTree = quickTree;
        this.currentNameFilter = filterStr;
    }
}

export enum InstrumentLookupType {
    Big,
    Small
}
