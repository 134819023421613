// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../Commons/properties/Resources';
import { Rectangle } from '../../Commons/Geometry';
import { TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { SolidBrush } from '../../Commons/Graphics';
import { MouseButtons } from '../../Controls/UtilsClasses/ControlsUtils';
import { TerceraChartAction, TerceraChartActionEnum } from '../TerceraChartAction';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { type TerceraChartBase } from '../TerceraChartBase';

export class TerceraChartZoomRenderer extends TerceraChartBaseRenderer {
    zoomInRect: Rectangle;
    zoomOutRect: Rectangle;
    scalingByRectRect: Rectangle;
    Rectangle: Rectangle;
    CurrentVisibleButtons: number;
    CurrentHoverButtonType: number;
    chartActionProcessor: any;
    backBrushZoomBtns: SolidBrush;

    static zoomInDefaultImage: any;
    static zoomInHoverImage: any;
    static zoomInDisableImage: any;
    static zoomOutDefaultImage: any;
    static zoomOutHoverImage: any;
    static zoomOutDisableImage: any;

    constructor (chart: TerceraChartBase, chartActionProcessor: any) {
        super(chart);

        this.zoomInRect = new Rectangle();
        this.zoomOutRect = new Rectangle();
        this.scalingByRectRect = new Rectangle();
        this.Rectangle = new Rectangle();
        this.CurrentVisibleButtons = TerceraChartZoomRenderer_ButtonType.All;
        this.CurrentHoverButtonType = TerceraChartZoomRenderer_ButtonType.None;

        this.chartActionProcessor = chartActionProcessor;
        this.ThemeChanged();
        this.SetClassName('TerceraChartZoomRenderer');
    }

    Width (): number {
        let width = 0;

        if ((this.CurrentVisibleButtons & TerceraChartZoomRenderer_ButtonType.ZoomOut) !== 0) {
            width += 24;
        }
        if ((this.CurrentVisibleButtons & TerceraChartZoomRenderer_ButtonType.ZoomIn) !== 0) {
            width += 24;
        }

        return width;
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null): void {
        if (!this.Visible) return;

        let x = this.Rectangle.X;
        const y = this.Rectangle.Y;
        const w = this.Rectangle.Width;
        const h = this.Rectangle.Height;

        let state;
        let im;

        if ((this.CurrentVisibleButtons & TerceraChartZoomRenderer_ButtonType.ZoomOut) !== 0) {
            this.zoomOutRect.X = x;
            this.zoomOutRect.Y = this.Rectangle.Y - 5;
            this.zoomOutRect.Width = 24;
            this.zoomOutRect.Height = 24;
            x += 24;

            im = TerceraChartZoomRenderer.zoomOutDefaultImage;
            state = this.chartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, TerceraChartZoomRenderer_ButtonType.ZoomOut));
            if (!state.Enabled) im = TerceraChartZoomRenderer.zoomOutDisableImage;
            else if (this.CurrentHoverButtonType === TerceraChartZoomRenderer_ButtonType.ZoomOut) im = ThemeManager.CurrentTheme.zoomOutHoverImage;

            gr.FillRect(this.backBrushZoomBtns, this.zoomOutRect.X, this.zoomOutRect.Y + 4, this.zoomOutRect.Width, this.zoomOutRect.Height - 4);

            if (im) gr.drawImage(im, this.zoomOutRect.X, this.zoomOutRect.Y);
        }

        if ((this.CurrentVisibleButtons & TerceraChartZoomRenderer_ButtonType.ZoomIn) !== 0) {
            this.zoomInRect.X = x;
            this.zoomInRect.Y = this.Rectangle.Y - 5;
            this.zoomInRect.Width = 24;
            this.zoomInRect.Height = 24;
            x += 24;

            im = TerceraChartZoomRenderer.zoomInDefaultImage;
            state = this.chartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, TerceraChartZoomRenderer_ButtonType.ZoomIn));
            if (!state.Enabled) im = TerceraChartZoomRenderer.zoomInDisableImage;
            else if (this.CurrentHoverButtonType === TerceraChartZoomRenderer_ButtonType.ZoomIn) im = ThemeManager.CurrentTheme.zoomInHoverImage;

            gr.FillRect(this.backBrushZoomBtns, this.zoomInRect.X, this.zoomInRect.Y + 4, this.zoomInRect.Width, this.zoomInRect.Height - 4);

            if (im) gr.drawImage(im, this.zoomInRect.X, this.zoomInRect.Y);
        }

        if (!this.CurrentVisibleButtons) {
            gr.FillRect(this.backBrushZoomBtns, x, y, w, h);
        }
    }

    ThemeChanged (): void {
        super.ThemeChanged();

        this.setBackGroundBrush(ThemeManager?.CurrentTheme?.scrollBackgroundColor);
        TerceraChartZoomRenderer.ThemeChangedStatic();
    }

    setBackGroundBrush (color: any): void {
        this.backBrushZoomBtns = new SolidBrush(color);
    }

    static ThemeChangedStatic (): void {
        TerceraChartZoomRenderer.zoomInDefaultImage = ThemeManager.CurrentTheme.zoomInDefaultImage;
        TerceraChartZoomRenderer.zoomInHoverImage = ThemeManager.CurrentTheme.zoomInHoverImage;
        TerceraChartZoomRenderer.zoomInDisableImage = ThemeManager.CurrentTheme.zoomInDisableImage;
        TerceraChartZoomRenderer.zoomOutDefaultImage = ThemeManager.CurrentTheme.zoomOutDefaultImage;
        TerceraChartZoomRenderer.zoomOutHoverImage = ThemeManager.CurrentTheme.zoomOutHoverImage;
        TerceraChartZoomRenderer.zoomOutDisableImage = ThemeManager.CurrentTheme.zoomOutDisableImage;
    }

    GetCurrentHoverButton (pt: any): number {
        if (this.zoomInRect.Contains(pt.X, pt.Y)) return TerceraChartZoomRenderer_ButtonType.ZoomIn;
        else if (this.zoomOutRect.Contains(pt.X, pt.Y)) return TerceraChartZoomRenderer_ButtonType.ZoomOut;
        else if (this.scalingByRectRect.Contains(pt.X, pt.Y)) return TerceraChartZoomRenderer_ButtonType.ScalingByRectangle;
        else return TerceraChartZoomRenderer_ButtonType.None;
    }

    ProcessMouseDown (e: any): boolean {
        if (e.Button !== MouseButtons.Left) return false;
        const newHoverButtonType = this.GetCurrentHoverButton(e.Location);
        if (newHoverButtonType === TerceraChartZoomRenderer_ButtonType.None) return false;
        return this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, newHoverButtonType));
    }

    ProcessMouseMove (e: any): boolean {
        const newHoverButtonType = this.GetCurrentHoverButton(e.Location);
        if (newHoverButtonType !== this.CurrentHoverButtonType) {
            this.CurrentHoverButtonType = newHoverButtonType;
            e.NeedRedraw = true;
        }
        return super.ProcessMouseMove(e);
    }

    ProcessMouseWheel (e: any): boolean {
        const step = e.Delta / 120;
        if (step > 0) this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, TerceraChartZoomRenderer_ButtonType.ZoomIn));
        else this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, TerceraChartZoomRenderer_ButtonType.ZoomOut));
        return true;
    }

    Do_ZoomIn (): void {
        this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, TerceraChartZoomRenderer_ButtonType.ZoomIn));
    }

    Do_ZoomOut (): void {
        this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, TerceraChartZoomRenderer_ButtonType.ZoomOut));
    }

    ProcessMouseLeave (e: any): void {
        super.ProcessMouseLeave(e);
        this.CurrentHoverButtonType = TerceraChartZoomRenderer_ButtonType.None;
    }

    Dispose (): void {
        this.chartActionProcessor = null;
        super.Dispose();
    }

    GetTooltip (e: any): string | null {
        if (!this.Visible || this.CurrentHoverButtonType === TerceraChartZoomRenderer_ButtonType.None) return null;
        const state = this.chartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, this.CurrentHoverButtonType));
        if (state?.ToolTiplocKey != null) {
            return Resources.getResource(state.ToolTiplocKey);
        }
        return null;
    }
}

export const TerceraChartZoomRenderer_ButtonType = {
    None: 0,
    ZoomIn: 2,
    ZoomOut: 4,
    ScalingByRectangle: 8,
    All: 15
};
