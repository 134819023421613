// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { confirmationScreenHandler, messageBoxHandler } from '../../Utils/AppHandlers';
import { DirectReportMessage } from '../../Utils/DirectMessages/DirectReportMessage';
import { SlTpPriceType } from '../../Utils/Enums/Constants';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { type ConfirmationSett } from '../../Utils/GeneralSettings/SubSettings/ConfirmationSett';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { ORDER_PARAMETER_PRODUCT_TYPE, ProductType } from '../../Utils/Instruments/ProductType';
import { MathUtils } from '../../Utils/MathUtils';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { SLTPTrigger } from '../../Utils/SlTpTrigger';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { ConfirmationTypesEnum } from '../../Utils/Trading/ConfirmationTypesEnum';
import { OffsetModeViewEnum } from '../../Utils/Trading/OffsetModeViewEnum';
import { OrderTif, OrderTifMap } from '../../Utils/Trading/OrderTifEnum';
import { OrderType } from '../../Utils/Trading/OrderType';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { PlacedFrom } from '../../Utils/Trading/PlacedFrom';
import { Quantity } from '../../Utils/Trading/Quantity';
import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { LOCALE_EN, Resources } from '../properties/Resources';

class _OrderConfrimation {
    public readonly CHANGE_TO_MARKET: number = 0;
    public readonly PLACE_OR_REPLACE: number = 1;
    public readonly REVERSE_POSITION: number = 2;

    public readonly processPlaceOrderConfrimationAsync = async (orderEditObj): Promise<boolean> => {
        const confirmationData = await orderEditObj.getConfirmationData();
        const reqData = orderEditObj.getDataForRequest();

        const warningText = this.getWarningText(confirmationData);

        if (!this.isUseConfirmation(ConfirmationTypesEnum.OrderPlace) && warningText === '') {
            this.createRequestEventMessage(reqData, this.PLACE_OR_REPLACE);
            return await Promise.resolve(true);
        }

        const result = await this.showMessageBoxAsync(reqData, warningText, ConfirmationTypesEnum.OrderPlace, orderEditObj.needToShowWarningForcefully());

        if (result) {
            this.createRequestEventMessage(reqData, this.PLACE_OR_REPLACE);
        }
        return result;
    };

    private readonly getWarningText = (confirmationData): string => {
        const warningSettings = GeneralSettings.Warnings;
        if (warningSettings === undefined) {
            return '';
        }

        const isShowWarning: boolean = warningSettings.needToShowWarning(confirmationData);
        if (isShowWarning) {
            const warningArray: string[] = confirmationData.warningArray;
            if (MathUtils.IsNullOrUndefined(warningArray)) {
                return '';
            }
            let warningText = '';
            for (let i = 0; i < warningArray.length; i++) {
                warningText += Resources.getResource(warningArray[i]);
                if (i + 1 < warningArray.length) {
                    warningText += '\n' + '<br>';
                };
            }
            return warningText;
        } else {
            return '';
        }
    };

    private readonly isUseConfirmation = (confirmationType: ConfirmationTypesEnum): boolean => {
        const confSettings = GeneralSettings.Confirmations;
        if (confSettings !== undefined) {
            return confSettings.useConfirmation(confirmationType);
        } else {
            return false;
        }
    };

    private readonly showMessageBoxAsync = async (reqData: any, warning: string, confrimationType: ConfirmationTypesEnum, needToShowWarningForcefully: boolean): Promise<boolean> => {
        return await new Promise(
            function (resolve) {
                const okCallback = function (showNextTime: boolean): void {
                    const confrimationSettings: ConfirmationSett | undefined = GeneralSettings.Confirmations;
                    if (confrimationSettings !== undefined) {
                        confrimationSettings.updateConfirmation(confrimationType, showNextTime);
                    }
                    resolve(true);
                };

                const cancelCallback = function (showNextTime: boolean): void {
                    const confrimationSettings: ConfirmationSett | undefined = GeneralSettings.Confirmations;
                    if (confrimationSettings !== undefined) {
                        confrimationSettings.updateConfirmation(confrimationType, showNextTime);
                    }
                    resolve(false);
                };

                confirmationScreenHandler.Show(
                    confrimationType,
                    reqData,
                    messageBoxHandler.msgType.Question,
                    okCallback,
                    cancelCallback,
                    !needToShowWarningForcefully, // showNextTimeChB parameter (if need to show warning forcefully then no need in showNextTime CheckBox)
                    false,
                    warning);
            }
        );
    };

    public readonly createRequestEventMessage = (reqData: any, modifier?: number): void => {
        try {
            const objArr: any = [];
            let header: any;
            let quantity; let panelId; let paramDict;
            let accProp; let insProp; let sideProp; let tifProp; // для доступа к разным названиям свойств одних и тех же сущностей
            if (reqData.length > 0) {
                const args: any = reqData[0];
                accProp = 'Account'; insProp = 'Instrument'; sideProp = 'BuySell'; tifProp = 'TimeInForce';
                if (reqData[2] === undefined) {
                    panelId = reqData[1];
                    quantity = null;
                } else {
                    panelId = reqData[2];
                    quantity = reqData[1];
                }
                if (!args.hasOwnProperty('isPosition')) { // несколько ордеров(или позиций)
                    const keys = Object.keys(args);
                    for (let i = 0; i < keys.length; i++) { objArr.push(args[keys[i]]); };
                } else {
                    objArr.push(args);
                }
            } else { // edit Data (placing, modifying)
                accProp = 'account'; insProp = 'instrument'; sideProp = 'side'; tifProp = 'tif';
                panelId = reqData.placedFrom ? reqData.placedFrom : (reqData.hasOwnProperty('position') ? PlacedFrom.WEB_POSITIONS_PANEL_DB_CLICK : (reqData.hasOwnProperty('order') ? PlacedFrom.WEB_ORDERS_PANEL_DB_CLICK : PlacedFrom.WEB_OTHER));
                quantity = reqData.quantity ? reqData.quantity : null;
                paramDict = reqData.parameterDict;
                objArr.push(reqData);
            }

            for (let i = 0; i < objArr.length; i++) {
                const obj: any = objArr[i];
                const acc = obj[accProp];
                const ins = obj[insProp];
                let orderType = obj.hasOwnProperty('OrderType') ? obj.OrderType : (obj.hasOwnProperty('orderTypeId') ? obj.orderTypeId : obj.getOrderTypeId());
                let ordTypeStr = OrderUtils.getOrderTypeLocalizationKey(orderType);
                const tifType = tifProp != 'tif' ? obj[tifProp] : (obj[tifProp] ? obj[tifProp].type : (obj.position ? obj.position.TimeInForce : null));
                let tifStr = OrderTifMap[tifType];
                const prodType = obj.productType;
                const productTypeStr = obj.hasOwnProperty('productType') && !isNullOrUndefined(prodType) ? (prodType !== ProductType.General ? InstrumentUtils.GetLocalizedProductType(ins, prodType) : null) : null;

                let qtValue = null;
                if (quantity && ins) {
                    qtValue = Quantity.toLots(quantity, ins);
                }

                if (modifier === this.CHANGE_TO_MARKET || obj.isPosition) { // replace to market action (orderType -> Market)
                    orderType = OrderType.Market; // TODO МБ ^
                    ordTypeStr = OrderUtils.getOrderTypeLocalizationKey(orderType);
                }

                if (tifType === OrderTif.GTD) { // добавляем дату к GTD tif-у
                    const expTime = tifProp != 'tif' ? obj.ExpireAt : (obj[tifProp] ? obj[tifProp].expirationTime : obj.position.ExpireAt);
                    const format = ins.DataCache.isAllowedForMyUser(RulesSet.FUNCTION_SHOW_TIME_FOR_GTD) ? 'MM.DD.YYYY HH:mm' : 'MM.DD.YYYY'; // нужен именно формат с месяцами на первом месте для корректного парсинга в ReportMessageTooltip.fixEntries:707 #98285
                    const dtString = DateTimeUtils.formatDate(expTime, format);
                    tifStr += ' ' + dtString;
                }

                const user = { Label: 'User', Value: acc ? acc.userLogin : null };
                const account = { Label: 'Account', Value: acc ? acc.FullAccString : null };
                const operation = { Label: 'Operation', Value: OrderUtils.getBuySellStr(obj[sideProp]) };
                const orderId = { Label: 'Order number', Value: obj.hasOwnProperty('PositionId') && obj.PositionId != -1 ? '' : (obj.OrderNumber ? obj.OrderNumber : (obj.order ? obj.order.OrderNumber : '')) };
                const positionId = { Label: 'Position ID', Value: obj.hasOwnProperty('PositionId') && obj.PositionId != -1 ? obj.PositionId : '' };
                const instrument = { Label: 'Instrument', Value: ins ? ins.DisplayShortName() : '' };
                const instrType = { Label: 'Symbol type', Value: ins ? InstrumentUtils.getInstrumentTypeStringLocalized(ins.InstrType) : '' };
                const tradingExchange = { Label: 'Trading exchange', Value: ins ? ins.TradingExchange : '' };
                const route = { Label: 'Route', Value: ''/* obj.Route ? DataCache.getRouteById(obj.Route).Name : (ins ? DataCache.getRouteById(ins.Route).Name : "") */ }; // #85093
                const amount = { Label: 'Amount', Value: qtValue !== null ? qtValue : (obj.position ? obj.position.Amount : obj.Amount) };
                const price = { Label: 'Price', Value: obj.Price ? obj.Price : (paramDict.limitPrice ? (ins ? ins.formatPrice(paramDict.limitPrice) : paramDict.limitPrice) : null) };
                const stopPrice = { Label: 'Stop price', Value: paramDict?.stopPrice ? (ins ? ins.formatPrice(paramDict.stopPrice) : paramDict.stopPrice) : null };
                const limitPriceForSecondOrder = { Label: 'Limit price', Value: reqData.limitPriceForStopLimitOrder ? (ins ? ins.formatPrice(reqData.limitPriceForStopLimitOrder) : reqData.limitPriceForStopLimitOrder) : null };
                const stopPriceForSecondOrder = { Label: 'Stop price', Value: null };
                const type = { Label: 'Order type', Value: !ordTypeStr.includes('type') ? ordTypeStr : '' };
                const tif = { Label: 'TIF', Value: tifStr };
                const time = { Label: 'Time', Value: Date.now().toString() };
                const clientPanelId = { Label: 'Send from', Value: 'reports.' + this.getKeyByValue(PlacedFrom, panelId) };
                const operation2: any = { Label: 'Operation', Value: null }; // for OCO
                const tif2 = { Label: 'TIF', Value: null }; // for OCO
                const boundTo = { Label: 'Bound to', Value: null };
                let slLabel = null;
                let sllLabel = null;
                let tpLabel = null;
                let slTrigger = null;
                let tpTrigger = null;
                const productType = { Label: ORDER_PARAMETER_PRODUCT_TYPE, Value: productTypeStr };
                const leverage = { Label: 'Leverage', Value: OrderUtils.GetFormattedLeverage(obj.leverageValue) };

                if (orderType === OrderType.Market) { price.Value = null; }

                if (orderType === OrderType.OCO) {
                    if (obj.ocoCustomOrdersData) { // from Chart
                        const dateFormat = ins.DataCache.isAllowedForMyUser(RulesSet.FUNCTION_SHOW_TIME_FOR_GTD) ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
                        const OCOData = obj.ocoCustomOrdersData;
                        operation.Value = OrderUtils.getBuySellStr(OCOData.firstOrderSide);
                        operation2.Value = OrderUtils.getBuySellStr(OCOData.secondOrderSide);
                        let tifStr1 = OrderTifMap[OCOData.firstOrderTif.type]; let tifStr2 = OrderTifMap[OCOData.secondOrderTif.type];
                        if (OCOData.firstOrderTif.type === OrderTif.GTD) // с избытком учитывая что указать TIF при торговле с Chart нельзя
                        { tifStr1 += ' ' + DateTimeUtils.formatDate(OCOData.firstOrderTif.expirationTime, dateFormat); };
                        if (OCOData.secondOrderTif.type === OrderTif.GTD) { tifStr2 += ' ' + DateTimeUtils.formatDate(OCOData.secondOrderTif.expirationTime, dateFormat); };
                        tif.Value = tifStr1;
                        tif2.Value = tifStr2;

                        if (OCOData.secondOrderLimitPriceForStopLimit) {
                            limitPriceForSecondOrder.Label = 'Limit price';
                            limitPriceForSecondOrder.Value = ins ? ins.formatPrice(OCOData.secondOrderLimitPriceForStopLimit) : OCOData.secondOrderLimitPriceForStopLimi;

                            stopPriceForSecondOrder.Label = 'Stop price';
                            stopPriceForSecondOrder.Value = stopPrice.Value;
                        } else {
                            if (OCOData.secondOrderType == OrderType.Limit) {
                                limitPriceForSecondOrder.Label = 'Price';
                                limitPriceForSecondOrder.Value = paramDict?.stopPrice ? (ins ? ins.formatPrice(paramDict.stopPrice) : paramDict.stopPrice) : null;
                            }
                        }

                        if (OCOData.firstOrderLimitPriceForStopLimit) {
                            price.Label = 'Stop price';

                            stopPrice.Label = 'Limit price';
                            stopPrice.Value = ins ? ins.formatPrice(OCOData.firstOrderLimitPriceForStopLimit) : OCOData.firstOrderLimitPriceForStopLimit;
                        } else {
                            if (OCOData.firstOrderType == OrderType.Limit) {
                                price.Label = 'Price';
                                price.Value = paramDict.limitPrice ? (ins ? ins.formatPrice(paramDict.limitPrice) : paramDict.limitPrice) : null;
                                stopPrice.Value = null;
                            }
                        }
                    } else { // from OE
                        operation2.Value = operation.Value;
                        tif2.Value = tif.Value;
                    }
                }
                if (paramDict) { // SL/TP Data
                    const usePoint = !!(ins && GeneralSettings.TradingDefaults.ShowOffsetIn === OffsetModeViewEnum.Points);
                    const sltp = paramDict.sltp;
                    let stopLossPriceValue = sltp.StopLossPriceValue;
                    let stopLossLimitPriceValue = sltp.StopLossLimitPriceValue;
                    let takeProfitPriceValue = sltp.TakeProfitPriceValue;
                    const triggersShV = sltp.SLTPTriggerShortValue;

                    if (GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
                        const fractTicksMode = OffsetModeViewEnum.TicksFractionalForForex;

                        if (sltp.StopLossPriceType !== SlTpPriceType.Absolute && !isNaN(stopLossPriceValue)) { stopLossPriceValue = OrderUtils.ConvertTickOffset(ins, fractTicksMode, null, stopLossPriceValue); };

                        if (sltp.StopLossPriceType !== SlTpPriceType.Absolute && stopLossLimitPriceValue !== null) { stopLossLimitPriceValue = OrderUtils.ConvertTickOffset(ins, fractTicksMode, null, stopLossLimitPriceValue) * Math.sign(stopLossLimitPriceValue); };

                        if (sltp.TakeProfitPriceType !== SlTpPriceType.Absolute && !isNaN(takeProfitPriceValue)) { takeProfitPriceValue = OrderUtils.ConvertTickOffset(ins, fractTicksMode, null, takeProfitPriceValue); };
                    }

                    if (!isNaN(stopLossPriceValue)) {
                        if (sltp.StopLossPriceType === SlTpPriceType.Absolute) { slLabel = { Label: 'SL price', Value: ins ? ins.formatPrice(stopLossPriceValue) : stopLossPriceValue.toString() }; } else { slLabel = { Label: sltp.StopLossPriceType === SlTpPriceType.Offset ? 'SL offset' : 'Tr. SL offset', Value: ins ? ins.formatOffset(stopLossPriceValue, true) : stopLossPriceValue.toString() + (ins && usePoint ? ' points' : '') }; }

                        slTrigger = { Label: 'SL trigger', Value: SLTPTrigger.GetTextValue(triggersShV, obj[sideProp], true) };
                    }
                    if (stopLossLimitPriceValue !== null) {
                        if (sltp.StopLossPriceType === SlTpPriceType.Absolute) { sllLabel = { Label: 'SL limit price', Value: ins ? ins.formatPrice(stopLossLimitPriceValue).toString() : stopLossLimitPriceValue.toString() }; } else { sllLabel = { Label: 'SL limit offset', Value: ins ? ins.formatOffset(stopLossLimitPriceValue, true).toString() : stopLossLimitPriceValue.toString() + (ins && usePoint ? ' points' : '') }; }
                    }
                    if (!isNaN(takeProfitPriceValue)) {
                        if (sltp.TakeProfitPriceType === SlTpPriceType.Absolute) { tpLabel = { Label: 'TP price', Value: ins ? ins.formatPrice(takeProfitPriceValue) : takeProfitPriceValue.toString() }; } else { tpLabel = { Label: 'TP offset', Value: ins ? ins.formatOffset(takeProfitPriceValue, true) : takeProfitPriceValue.toString() + (ins && usePoint ? ' point' : '') }; }

                        tpTrigger = { Label: 'TP trigger', Value: SLTPTrigger.GetTextValue(triggersShV, obj[sideProp], false) };
                    }

                    if (paramDict.RealTrStopPrice && !stopPrice.Value) { stopPrice.Value = paramDict.RealTrStopPrice; };

                    if (orderType === OrderType.TrailingStop && !stopPrice.Value) { stopPrice.Value = ins.formatOffset(paramDict.trailingStop, true) + ' (offset)'; }
                }

                let selectedLabels = [];

                if (modifier !== undefined) { // есть параметр - ключ локализации, дающий представление о том, какое действие было совершено
                    if (modifier === this.PLACE_OR_REPLACE || modifier === this.CHANGE_TO_MARKET) {
                        if (obj.position != null) {
                            boundTo.Value = obj.position.PositionId;
                            header = 'reports.Position modify request'; // если удастся ниже уточним что за модификация
                            if (!isNaN(obj.parameterDict.sltp.StopLossPriceValue) || obj.position.SLOrder) { // SL
                                stopPrice.Value = !isNaN(obj.parameterDict.sltp.StopLossPriceValue) ? obj.parameterDict.sltp.StopLossPriceValue : obj.position.SLOrder.Price;
                                if (!isNaN(obj.parameterDict.sltp.StopLossPriceValue) && obj.parameterDict.sltp.StopLossPriceType === SlTpPriceType.TrOffset) {
                                    if (obj.parameterDict.sltp.RealTrStopPrice) {
                                        stopPrice.Value = obj.parameterDict.sltp.RealTrStopPrice;
                                    } else {
                                        stopPrice.Value += ' (offset)';
                                    }
                                }
                                tpLabel = null; slLabel = null; // вместо SL Price будет отображаться только Price
                                type.Value = 'reports.SL';
                                if (isNaN(obj.parameterDict.sltp.StopLossPriceValue)) { // cancel SL
                                    header = obj.position.SLOrder.OrderType == OrderType.TrailingStop ? 'reports.Tr stop loss order cancel request' : 'reports.SL order cancel request';
                                    orderId.Value = obj.position.SLOrder.OrderNumber;
                                } else if (obj.position.SLOrder) { // replace SL
                                    orderId.Value = obj.position.SLOrder.OrderNumber;
                                    if (obj.parameterDict.sltp.StopLossPriceType == SlTpPriceType.TrOffset || obj.position.SLOrder.TrStopOffset > 0) {
                                        type.Value = 'reports.tr. stop';
                                        if (obj.parameterDict.sltp.StopLossPriceType == SlTpPriceType.TrOffset && obj.position.SLOrder.TrStopOffset > 0) {
                                            header = 'reports.Tr stop loss order replace request';
                                        } else if (obj.parameterDict.sltp.StopLossPriceType != SlTpPriceType.TrOffset) {
                                            header = 'reports.Tr stop loss order cancel request';
                                        } else {
                                            header = 'reports.Tr stop loss order placing request';
                                        }
                                    } else {
                                        header = 'reports.SL order replace request';
                                    }
                                } else // create SL
                                {
                                    header = 'reports.SL order placing request';
                                }
                            }
                            if ((!isNaN(obj.parameterDict.sltp.TakeProfitPriceValue) || obj.position.TPOrder) && // TP
                            !(obj.position.TPOrder && obj.position.TPOrder.Price == obj.parameterDict.sltp.TakeProfitPriceValue)) { // была модификация SL, TP - не изменился
                                price.Value = !isNaN(obj.parameterDict.sltp.TakeProfitPriceValue) ? obj.parameterDict.sltp.TakeProfitPriceValue : obj.position.TPOrder.Price;
                                if (!isNaN(obj.parameterDict.sltp.TakeProfitPriceValue) && obj.parameterDict.sltp.TakeProfitPriceValue === SlTpPriceType.TrOffset) {
                                    price.Value += ' (offset)';
                                };
                                tpLabel = null; slLabel = null; // вместо TP Price будет отображаться только Price
                                type.Value = 'reports.TP';
                                if (obj.position.TPOrder) {
                                    orderId.Value = obj.position.TPOrder.OrderNumber;
                                }
                                if (isNaN(obj.parameterDict.sltp.TakeProfitPriceValue)) {
                                    header = 'reports.TP order cancel request'; // cancel TP
                                } else if (obj.position.TPOrder) {
                                    header = 'reports.TP order replace request'; // replace TP
                                } else {
                                    header = 'reports.TP order placing request';// create TP
                                }
                            }
                        } else {
                            header = 'reports.' + Resources.getResourceLang('property.' + OrderUtils.getOrderTypeLocalizationKey(orderType), LOCALE_EN) + ' order ' + (obj.hasOwnProperty('order') ? 'replace' : 'placing') + ' request';
                        }
                    } else if (modifier === this.REVERSE_POSITION) {
                        header = 'reports.PositionReverseRequest';
                        const reverseOperation = { Label: Resources.getResource('reports.operation'), Value: Resources.getResource('AdditionalProperty.Reverse') };
                        selectedLabels = [account, instrument, amount, reverseOperation, time, clientPanelId];
                    }
                } else {
                    // closing
                    header = obj.hasOwnProperty('isPosition') ? (obj.isPosition ? 'reports.Position close request' : 'reports.' + Resources.getResourceLang('property.' + OrderUtils.getOrderTypeLocalizationKey(orderType), LOCALE_EN) + ' order cancel request') : 'reports.' + Resources.getResourceLang('property.' + OrderUtils.getOrderTypeLocalizationKey(orderType), LOCALE_EN) + ' order placing request';
                }

                if (selectedLabels.length === 0) {
                    selectedLabels = [user, account, instrument, instrType, tradingExchange, route, amount, orderId, positionId, boundTo, type, operation, price, leverage, stopPrice, tif, productType, operation2, stopPriceForSecondOrder, limitPriceForSecondOrder, tif2, slLabel, sllLabel, slTrigger, tpLabel, tpTrigger, time, clientPanelId];
                }

                const resultDataArr = []; const len = selectedLabels.length;
                for (let i = 0; i < len; i++) {
                    const lbl = selectedLabels[i];
                    if (lbl && (lbl.Value || lbl.Value === 0)) { resultDataArr.push([lbl.Label, lbl.Value]); };
                }

                const reportMessage = new DirectReportMessage(header, resultDataArr);
                reportMessage.SkipOnTicketViewer = true; // чтобы не показывать в TicketViewer
                reportMessage.Instrument = ins;
                reportMessage.account = acc;
                reportMessage.productType = prodType;
                ins.DataCache.NewMessage(reportMessage);
            }
        } catch (e) {
            ErrorInformationStorage.GetException(e);
        }
    };

    private readonly getKeyByValue = (obj: any, val: any): any => {
        for (const key in obj) {
            if (obj[key] === val) {
                return key;
            }
        };
        return null;
    };
}

export const OrderConfrimation: _OrderConfrimation = new _OrderConfrimation();
