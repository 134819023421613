// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { TerceraInstrumentLookupTextBoxTemplate } from '../../../templates.js';
import { ControlsTypes, PanelNames } from '../../UtilsClasses/FactoryConstants';
import { TerceraTextBox } from '../TerceraTextBox';
import { CustomerAccess } from '../../../Commons/CustomerAccess/CustomerAccess';
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { InstrumentLookupManager } from './InstrumentLookupManager';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { InstrumentTypes } from '../../../Utils/Instruments/InstrumentTypes';

export class TerceraInstrumentLookupTextBox extends TerceraTextBox {
    public symbolInfoAllowed: boolean = false;

    public override getType (): ControlsTypes { return ControlsTypes.TerceraInstrumentLookupTextBox; }

    public override oninit (): void {
        super.oninit();

        this.on('onInsTypeImgMouseDown', this.onInsTypeImgMouseDown);
        this.observe('currentInstrument', this.onInstrumentChanged);
        this.observe('instrTypeImgConst', this.onInstrTypeImgConstChanged);

        this.symbolInfoAllowed = CustomerAccess.panelAllowed(PanelNames.SymbolInfoPanel);
    }

    public override oncomplete (): void {
        super.oncomplete();
        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override onteardown (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);
        super.onteardown();
    }

    public localize (): void {
        void this.set('insTypeImgTT', Resources.getResource('TerceraInstrumentLookup.InstrTypeImg.tt'));

        this.updateDelayTooltip();
    }

    public onInsTypeImgMouseDown (): void {
        let instrument = this.get('currentInstrument');
        if (isNullOrUndefined(instrument)) return;

        void this.set('isSymbolInfoOpened', true);
        const onSymbolInfoCloseCallback = (): void => {
            void this.set('isSymbolInfoOpened', false);
        };

        if (instrument.InstrumentTradableID == null) { // when click on underlier in OptionMaster's instrument lookup
            instrument = instrument.ForwardBaseInstrument; // #122997
        }

        MainWindowManager.MainWindow.ShowSymbolInfo(instrument, this.get('selectedAccount'), onSymbolInfoCloseCallback);
    }

    public onInstrumentChanged (instrument, lastInstrument): void {
        if (instrument != lastInstrument) {
            this.subscribeRiskSettingsUpdate(instrument, lastInstrument);
        }

        this.updateDelayTooltip();

        void this.set('showInsTypeImg', this.symbolInfoAllowed && instrument?.isHide == null);
        if (isNullOrUndefined(instrument)) {
            return;
        }
        if (InstrumentUtils.UseFuturesInsteadSpot() && instrument.InstrType === InstrumentTypes.SPOT) {
            void this.set('instrTypeImgConst', InstrumentTypes.FUTURES);
        } else {
            void this.set('instrTypeImgConst', instrument.InstrType);
        }
    }

    public updateDelayTooltip (): void {
        const instrument = this.get('currentInstrument');
        if (isNullOrUndefined(instrument) || instrument.isHide) { return; }

        void this.set({
            hasDelay: instrument.HasDelay(),
            delayedTooltip: instrument.GetDelayedIconTooltip()
        });
    }

    public subscribeRiskSettingsUpdate (instrument, lastInstrument): void {
        if (!isNullOrUndefined(lastInstrument) && !lastInstrument.isHide) {
            lastInstrument.RiskSettingsUpdated.UnSubscribe(this.instrumentSettingsUpdate, this);
        }

        if (!isNullOrUndefined(instrument) && !instrument.IsEmpty && !instrument.isHide) {
            instrument.RiskSettingsUpdated.Subscribe(this.instrumentSettingsUpdate, this);
        }
    }

    public instrumentSettingsUpdate (): void {
        const instrument = this.get('currentInstrument');
        if (isNullOrUndefined(instrument)) { return; }

        this.onInstrumentChanged(instrument, instrument); // for hasDelay/delayedTooltip update
    }

    public onInstrTypeImgConstChanged (value): void {
        const instrument = this.get('currentInstrument');
        if (isNullOrUndefined(instrument)) {
            return;
        }
        if (value > 0) {
            const imgIdInSprite = InstrumentLookupManager.getInstrumentTypeImageFileName(value, instrument.CFD);
            if (imgIdInSprite) {
                void this.set('instrTypeSpriteID', imgIdInSprite);
            }
        }
    }
}

TerceraTextBox.extendWith(TerceraInstrumentLookupTextBox, {
    template: TerceraInstrumentLookupTextBoxTemplate,
    data: function () {
        return {
            instrTypeImgConst: 0,
            instrTypeSpriteID: '',
            currentInstrument: null,
            selectedAccount: null,
            showInsTypeImg: false,
            hasDelay: false, // #112155
            delayedTooltip: '',
            insTypeImgTT: ''
        };
    }
});
