import { Control } from './Control';
import { PanelsContainerControlTemplate } from '../../templates';
import { TerceraWindowBase } from '../screen/TerceraWindowBase';
import { Resources } from '../../Commons/properties/Resources';

class PanelsContainerControlTabItem {
    public text: string;
    public isVisible: boolean;
    public panel: TerceraWindowBase;

    constructor (text: string, isVisible: boolean, panel: TerceraWindowBase) {
        this.text = text;
        this.isVisible = isVisible;
        this.panel = panel;
    }
}

export class PanelsContainerControl extends Control {
    private _visiblePanelsCount: number = 0;
    constructor () {
        super();
        this.onTabItemClicked = this.onTabItemClicked.bind(this);
        this.onCollapseButtonClicked = this.onCollapseButtonClicked.bind(this);
        this.onCollapsedChanged = this.onCollapsedChanged.bind(this);
        this.onSelectedTabItemChanged = this.onSelectedTabItemChanged.bind(this);
        this.onPanelHeaderChanged = this.onPanelHeaderChanged.bind(this);
        this.onPanelForbiddenChanged = this.onPanelForbiddenChanged.bind(this);
    }

    public override oninit (): void {
        super.oninit();
        super.on('onTabItemClick', this.onTabItemClicked);
        super.on('onCollapseButtonClick', this.onCollapseButtonClicked);
        super.observe('isCollapsed', this.onCollapsedChanged);
        super.observe('selectedTabItem', this.onSelectedTabItemChanged);
    }

    public override async oncomplete (): Promise<void> {
        super.oncomplete();
        const panels = super.findAllComponents().filter(component => component instanceof TerceraWindowBase);
        for (let i = 0; i < panels.length; i++) {
            panels[i].observe('header', this.onPanelHeaderChanged);
            panels[i].observe('forbiddenPanel', this.onPanelForbiddenChanged);
        }
        const tabItems: PanelsContainerControlTabItem[] = [];
        for (let i = 0; i < panels.length; i++) {
            const isForbiddenPanel: boolean = panels[i].get('forbiddenPanel');
            const tabItem = new PanelsContainerControlTabItem(panels[i].get('header'), !isForbiddenPanel, panels[i]);
            tabItems.push(tabItem);
        }
        await super.set('tabItems', tabItems);
        await this.onTabItemClicked(null, tabItems.find(tabItem => tabItem.isVisible));
        this.updateVisiblePanelsCount();
    }

    public localize (): void {
        this.updateTooltip();
    }

    private async onPanelHeaderChanged (): Promise<void> {
        const tabItems = super.get('tabItems');
        for (let i = 0; i < tabItems.length; i++) {
            const panel = tabItems[i].panel;
            tabItems[i].text = panel.get('header');
        }
        await super.set('tabItems', tabItems);
    }

    private async onPanelForbiddenChanged (): Promise<void> {
        const tabItems: PanelsContainerControlTabItem[] = super.get('tabItems');
        if (!isValidArray(tabItems)) {
            return;
        }
        for (let i = 0; i < tabItems.length; i++) {
            const panel = tabItems[i].panel;
            const isForbidden: boolean = panel.get('forbiddenPanel');
            tabItems[i].isVisible = !isForbidden;
        }
        await super.set('tabItems', tabItems);
        const selectedTabItem: PanelsContainerControlTabItem = super.get('selectedTabItem');
        if (!isNullOrUndefined(selectedTabItem) && !selectedTabItem.isVisible) {
            const visibleTabItem = tabItems.find(tabItem => tabItem.isVisible);
            await this.onTabItemClicked(null, visibleTabItem);
        }
        this.updateVisiblePanelsCount();
    }

    private async onTabItemClicked (event: any, tabItem: PanelsContainerControlTabItem): Promise<void> {
        const isCollapsed: boolean = super.get('isCollapsed');
        if (isCollapsed) {
            return;
        }
        void super.set('selectedTabItem', tabItem);
    }

    private async onSelectedTabItemChanged (): Promise<void> {
        const tabItems = super.get('tabItems');
        if (!isValidArray(tabItems)) {
            return;
        }
        const selectedTabItem = super.get('selectedTabItem');
        for (let i = 0; i < tabItems.length; i++) {
            if (tabItems[i] !== selectedTabItem) {
                await tabItems[i].panel.set('visible', false);
            }
        }
        if (!isNullOrUndefined(selectedTabItem)) {
            await selectedTabItem.panel.set('visible', true);
        }
        this.fire('activePanelChange');
    }

    private onCollapseButtonClicked (): void {
        const isCollapsed: boolean = super.get('isCollapsed');
        void super.set('isCollapsed', !isCollapsed);
    }

    private onCollapsedChanged (): void {
        const isCollapsed: boolean = super.get('isCollapsed');
        const selectedTabItem = super.get('selectedTabItem');
        if (!isNullOrUndefined(selectedTabItem)) {
            selectedTabItem.panel.set('visible', !isCollapsed);
        }
        this.fire('collapsedChange', isCollapsed);
        this.updateTooltip();
    }

    private updateTooltip (): void {
        const isCollapsed: boolean = super.get('isCollapsed');
        const tooltip = isCollapsed ? Resources.getResource('property.ShowAdditionalPanels') : Resources.getResource('property.HideAdditionalPanels');
        void super.set('infoBtnTooltip', tooltip);
    }

    private updateVisiblePanelsCount (): void {
        const tabItems: PanelsContainerControlTabItem[] = super.get('tabItems');
        if (!isValidArray(tabItems)) {
            return;
        }
        let visiblePanelsCount = 0;
        for (let i = 0; i < tabItems.length; i++) {
            const tabItem = tabItems[i];
            if (tabItem.isVisible) {
                visiblePanelsCount++;
            }
        }
        if (this._visiblePanelsCount !== visiblePanelsCount) {
            this._visiblePanelsCount = visiblePanelsCount;
            this.fire('visiblePanelsCountChange', this._visiblePanelsCount);
        }
    }
}

Control.extendWith(PanelsContainerControl, {
    template: PanelsContainerControlTemplate,
    data () {
        return {
            isVisibleTabs: true,
            infoBtnTooltip: '',
            isShowCollapsedButton: false,
            isCollapsed: false,
            selectedTabItem: null,
            tabItems: []
        };
    }
});
