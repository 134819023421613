// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { TerceraCheckBox } from './TerceraCheckBox';

export class TerceraCheckBoxEvent extends TerceraCheckBox {
    public BeforeChangeEvent: any = null;

    public override getType (): ControlsTypes { return ControlsTypes.TerceraCheckBoxEvent; }

    public async private_CheckBoxClick (sender): Promise<any> {
        if (!this.get<boolean>('enabled')) {
            return;
        }

        return await new Promise(function (resolve, reject) {
            if (!isNullOrUndefined(this.BeforeChangeEvent)) {
                this.BeforeChangeEvent(!this.get('checked'), resolve);
            } else {
                this.toggle('checked');
            }
        }.bind(this))
            .then(function (ans) {
                this.set('checked', !!ans);
            }.bind(this));
    }
}

TerceraCheckBox.extendWith(TerceraCheckBoxEvent, {
    data: function () {
        return {
            checked: false,
            text: ''
        };
    }
});
