// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from '../../../Commons/ErrorInformationStorage';
import { Resources } from '../../../Commons/properties/Resources';
import { Rectangle } from '../../../Commons/Geometry';
import { Cursors } from '../../../Commons/Cursors';
import { DrawPointer, DrawPointerTypeEnum } from '../../Utils/DrawPointer';
import { LayersEnum } from '../../Renderers/TerceraChartBaseRenderer';
import { Color, Brushes, Pen, Pens } from '../../../Commons/Graphics';
import { ProMath, ToolView, MovingType } from '../ToolView';
import { SelectionState, ToolSelectionType } from '../Selection';
import { TerceraChartAction, TerceraChartActionEnum } from '../../TerceraChartAction';
import { contextMenuHandler } from '../../../Utils/AppHandlers';
import { TerceraChartTradingOperation } from '../../Utils/ChartConstants';
import { TradingToolViewBase, TradingToolViewType } from './TradingToolViewBase';

import { OperationType } from '../../../Utils/Trading/OperationType';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { OffsetModeViewEnum } from '../../../Utils/Trading/OffsetModeViewEnum';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { TradingLockUtils } from '../../../Utils/TradingLockUtils';
import { IsAllowed } from '../../../Commons/IsAllowed';
import { MathUtils } from '../../../Utils/MathUtils';
import { OrderUtils } from '../../../Utils/Trading/OrderUtils';
import { type TerceraChartCashItemSeries } from '../../Series/TerceraChartCashItemSeries';
import { type Order } from '../../../Commons/cache/Order';

export class OrderToolView extends TradingToolViewBase {
    public static TRADETOOL_Y_DELTA = 8;

    public Order: any;
    public GetToolViewByOrderId: any;
    public terceraChartPanelContext: any;

    public errorMode = false;

    /// <summary>
    /// ордер был сдвинут с места (или был создан сл/тп ордер) - нужен модифай
    /// </summary>
    public TradeWasModified = false;

    // visual trading order/position place SL/TP
    public slRect = new Rectangle();
    public tpRect = new Rectangle();

    public slPlased = false;
    public tpPlased = false;
    public slPriceValue = NaN;
    public tpPriceValue = NaN;
    public slPriceY = -1;
    public tpPriceY = -1;
    public priceValue = NaN;
    public lastValidPriceValue = NaN;
    public priceY = -1;
    public stopLimitY = -1;

    public tpTrade = null;
    public slTrade = null;
    public sllTrade = null;
    public isOrderToolView = true;

    public modifyInProgress = false;
    public activeQtyValue = null;
    public isLinkingStyle: boolean;
    MouseDownHover: ToolSelectionType;

    constructor (order: Order, getToolViewByOrderId, terceraChartPanelContext, renderer) {
        super();

        this.Order = order;
        /// <summary>
        /// получить трейд-тулзу по ай-ди из рендерера тулз
        /// </summary>
        this.GetToolViewByOrderId = getToolViewByOrderId;
        /// <summary>
        /// интерфейс чарт панели для модифая
        /// </summary>
        this.terceraChartPanelContext = terceraChartPanelContext;
        this.myRenderer = renderer;

        this.movingType = MovingType.OnlyY;
        this.Updated(order);

        this.collapsed = false;

        this.ToolViewType = TradingToolViewType.OrderToolView;
    }

    // #region properties

    public AllowCancelOrder (): boolean {
        return IsAllowed.IsOrderCancelingAllowed(this.Order).Allowed;
    }

    public AllowChangeToMarket (): boolean {
        return IsAllowed.IsOrderExecutingAllowed(this.Order).Allowed;
    }

    public IsPositionToolView (): boolean {
        return this.Order ? this.Order.isPosition : false;
    }

    public AllowModify (): boolean {
    // TODO
    // var route = this.Order.DataCache.getRouteByName(Order.Route);
    // var allowModifyPrice = this.Order.DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_MODIFY_PRICE) && (route == null || route.AllowPriceModify);

        return IsAllowed.IsOrderModifyingAllowed(this.Order).Allowed;// && allowModifyPrice;
    }

    public AllowSLTP (isSL?): boolean {
        const o = this.Order;
        const isSLParamComes = !(isNaN(isSL) || isSL === null);
        let result = IsAllowed.IsOrderSLTPAllowed(o).Allowed;

        if (isSLParamComes) {
            result = result && IsAllowed.IsSLTPAllowed(o.Instrument, o.Account, isSL).Allowed;
        }

        return result;
    }

    public AllowClosePosition (): boolean {
        return false;
    }

    override get Collapsed (): boolean { return this.collapsed; }
    override set Collapsed (value) {
        if (this.collapsed != value) {
            this.collapsed = value;

            // установка коллапсед у дочерних сл/тп ордеров
            if (this.Order.SLOrder !== null) {
                this.GetToolViewByOrderId(this.Order.SLOrder.OrderNumber, TradingToolViewType.SLTPOrderToolView).Collapsed = this.collapsed;
            } else if (!isNaN(this.Order.StopLossPriceValue)) {
                this.GetToolViewByOrderId(this.Order.OrderNumber + '-SL', TradingToolViewType.SLTPOrderToolView).Collapsed = this.collapsed;
            }

            if (this.Order.TPOrder !== null) {
                this.GetToolViewByOrderId(this.Order.TPOrder.OrderNumber, TradingToolViewType.SLTPOrderToolView).Collapsed = this.collapsed;
            } else if (!isNaN(this.Order.TakeProfitPriceValue)) {
                this.GetToolViewByOrderId(this.Order.OrderNumber + '-TP', TradingToolViewType.SLTPOrderToolView).Collapsed = this.collapsed;
            }
        }
    }

    override get ID (): string {
        return this.Order != null ? this.Order.OrderNumber : '-2';
    }

    override get Price (): number {
        return this._getPrice();
    }

    public _getPrice (): number {
        if (isNullOrUndefined(this.Order)) {
            return -1;
        }

        return this.Order.OrderType === OrderType.StopLimit ? this.Order.StopLimit : this.Order.Price;
    }

    public SlComparePrice (): number {
        return this.Order.Price;
    }

    public TpComparePrice (): number {
        return this.Order.Price;
    }

    public getLeverageText (): string {
        return OrderUtils.GetFormattedLeverage(this.Order.Leverage);
    }

    // #endregion

    /// <summary>
    /// пришло с сервера - тулза обновилась сбрасываем серую ожидалку
    /// </summary>
    public override Updated (newOrder?: Order, isOrderDataUpdate?): void {
        if (this.Order == null || !newOrder) {
            return;
        }

        this.Order = newOrder;

        this.dataCacheTool.Points[0][0] = this.Order.UTCDateTime;
        this.dataCacheTool.Points[0][1] = this.Price;
        if (!this.CurrentMovement.IsMovingRightNow && !isOrderDataUpdate) {
            this.CancelMoving();
        }

        this.myRenderer.chart.IsDirty(LayersEnum.Tools);
    }

    public IsDirty (): void {
        this.myRenderer.chart.IsDirty(LayersEnum.Tools);
    }

    public override CancelMoving (): void {
        this.slPlased = false;
        this.tpPlased = false;

        if (this.tpTrade) {
            this.tpTrade.ResetMove();
            this.tpTrade.CancelMoving();
        }
        if (this.slTrade) {
            this.slTrade.ResetMove();
            this.slTrade.CancelMoving();
        }
        if (this.sllTrade) {
            this.sllTrade.ResetMove();
            this.sllTrade.CancelMoving();
        }
        this.ResetMouse();
        this.TradeWasModified = false;
    }

    // TODO. Rename. Refactor.
    public undoChanges (): void {
        this.CancelMove();
        this.Updated(this.Order, false);
    }

    /// <summary>
    /// текст инфо для расхлопнутой тулзы
    /// </summary>
    public override DescriptionText (): string {
        let qtyValue = this.Order.Amount;

        if (this.modifyInProgress && this.activeQtyValue !== null) {
            qtyValue = this.activeQtyValue;
        }

        const inLots = GeneralSettings.View.displayAmountInLots();
        if (!inLots) {
            qtyValue *= this.Order.Instrument.LotSize;
            return qtyValue.toString();
        } else {
            return qtyValue.toFixed(this.Order.Instrument.getAmountPrecision(null, this.Order.Account, this.Order.ProductType));
        }
    }

    public override Draw (gr, window, param): void {
        this.MainOrderVisibility = false;

        if ((!this.myRenderer.ShowPositions && !this.isOrderToolView) ||
        (!this.myRenderer.ShowOrders && this.isOrderToolView)) {
            return;
        }

        if (!this.Order?.Price) {
            return;
        }

        const CR = window.ClientRectangle;

        const minX = CR.X;
        const maxX = minX + CR.Width;
        try {
            let screenPoints00 = this.screenPoints[0][0];
            if (screenPoints00 < minX + 9) {
                screenPoints00 = minX + 9;
            }

            if (this.myRenderer.ShowVisualTradingOnLeftSide) {
                screenPoints00 = minX + 15;
            }

            const screenPoints01 = this.screenPoints[0][1];

            // Ловим ошибки
            if ((screenPoints00 < 0 && maxX < 0) || screenPoints01 >= ProMath.infinity || screenPoints01 >= ProMath.infinity || screenPoints01 <= ProMath.infinityMinus || screenPoints01 <= ProMath.infinityMinus) {
                return;
            }

            // оптимизация - вне не рисуем ордер и не добавляем его плашку
            if (!CR.Contains(screenPoints00, screenPoints01)) {
                return;
            }

            this.MainOrderVisibility = true;
            //
            const hoveredOrSelected = this.CurrentSelection.CurrentState != SelectionState.None;
            const isBuy = this.Order.BuySell === OperationType.Buy;
            const isPosition = this.Order.isPosition;

            let p = isPosition ? this.myRenderer.positionBuyPen : this.myRenderer.orderBuyPen;
            if (!isBuy) {
                p = isPosition ? this.myRenderer.positionSellPen : this.myRenderer.orderSellPen;
            }

            if (this.TradeWasModified) {
                p = TradingToolViewBase.orderGrayPen;
            }

            let im = null;
            // if (isPosition)
            // {
            if (this.TradeWasModified) {
                im = this.Collapsed ? TradingToolViewBase.gray_close_positionImage : TradingToolViewBase.gray_open_positionImage;
            } else {
                if (!this.Collapsed) {
                    im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_open_position_hoverImage : TradingToolViewBase.buy_open_position_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_open_position_hoverImage : TradingToolViewBase.sell_open_position_defaultImage);
                } else {
                    im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_close_position_hoverImage : TradingToolViewBase.buy_close_position_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_close_position_hoverImage : TradingToolViewBase.sell_close_position_defaultImage);
                }
            }
            // }
            // else
            // {
            if (this.Order.OrderType === OrderType.Stop) {
                if (this.TradeWasModified) {
                    im = this.Collapsed ? TradingToolViewBase.move_STP_closeImage : TradingToolViewBase.move_STP_openImage;
                } else {
                    if (!this.Collapsed) {
                        im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_STP_open_hoverImage : TradingToolViewBase.buy_STP_open_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_STP_open_hoverImage : TradingToolViewBase.sell_STP_open_defaultImage);
                    } else {
                        im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_STP_close_hoverImage : TradingToolViewBase.buy_STP_close_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_STP_close_hoverImage : TradingToolViewBase.sell_STP_close_defaultImage);
                    }
                }
            } else if (this.Order.OrderType === OrderType.Limit) {
                if (this.TradeWasModified) {
                    im = this.Collapsed ? TradingToolViewBase.move_LMT_closeImage : TradingToolViewBase.move_LMT_openImage;
                } else {
                    if (!this.Collapsed) {
                        im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_LMT_open_hoverImage : TradingToolViewBase.buy_LMT_open_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_LMT_open_hoverImage : TradingToolViewBase.sell_LMT_open_defaultImage);
                    } else {
                        im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_LMT_close_hoverImage : TradingToolViewBase.buy_LMT_close_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_LMT_close_hoverImage : TradingToolViewBase.sell_LMT_close_defaultImage);
                    }
                }
            } else if (this.Order.OrderType === OrderType.StopLimit) {
                if (this.isLinkingStyle) {
                    im = hoveredOrSelected ? TradingToolViewBase.link_out_STP_hover_full : TradingToolViewBase.link_out_STP_default_full;
                } else
                    if (this.TradeWasModified) {
                        im = this.Collapsed ? TradingToolViewBase.move_STP_link_closeImage : TradingToolViewBase.move_STP_link_openImage;
                    } else {
                        if (!this.Collapsed) {
                            im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_STP_link_open_hoverImage : TradingToolViewBase.buy_STP_link_open_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_STP_link_open_hoverImage : TradingToolViewBase.sell_STP_link_open_defaultImage);
                        } else {
                            im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_STP_link_close_hoverImage : TradingToolViewBase.buy_STP_link_close_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_STP_link_close_hoverImage : TradingToolViewBase.sell_STP_link_close_defaultImage);
                        }
                    }
            } else if (this.Order.OrderType === OrderType.TrailingStop) {
                if (this.TradeWasModified) {
                    im = this.Collapsed ? TradingToolViewBase.move_Trs_closeImage : TradingToolViewBase.move_Trs_openImage;
                } else {
                    if (!this.Collapsed) {
                        im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_Trs_open_hoverImage : TradingToolViewBase.buy_Trs_open_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_Trs_open_hoverImage : TradingToolViewBase.sell_Trs_open_defaultImage);
                    } else {
                        im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_Trs_close_hoverImage : TradingToolViewBase.buy_Trs_close_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_Trs_close_hoverImage : TradingToolViewBase.sell_Trs_close_defaultImage);
                    }
                }
            }
            if (this.Order.OrderType == OrderType.Market) {
                if (this.TradeWasModified) {
                    im = this.Collapsed ? TradingToolViewBase.move_MKT_closeImage : TradingToolViewBase.move_MKT_openImage;
                } else {
                    if (!this.Collapsed) {
                        im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_MKT_open_hoverImage : TradingToolViewBase.buy_MKT_open_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_MKT_open_hoverImage : TradingToolViewBase.sell_MKT_open_defaultImage);
                    } else {
                        im = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_MKT_close_hoverImage : TradingToolViewBase.buy_MKT_close_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_MKT_close_hoverImage : TradingToolViewBase.sell_MKT_close_defaultImage);
                    }
                }
            }
            // }

            this.iconRect.X = screenPoints00 - 8;
            this.iconRect.Y = screenPoints01 - 11;

            this.imageRect = new Rectangle(screenPoints00 - 8, screenPoints01 - 11, im.width, im.height);

            const threeDotsMenuVisible = this.AllowChangeToMarket() && !isPosition; // todo: выяснить почему у .Net позиции отсекаются в IsAllowed.IsOrderExecutingAllowed а у нас нет

            let infoRectDelta = 0;
            if (!this.Collapsed) {
                const shortDescriptionText = this.DescriptionText();
                this.infoRect = new Rectangle(this.imageRect.X + this.imageRect.Width, this.imageRect.Y, Math.floor(gr.GetTextWidth(shortDescriptionText, TradingToolViewBase.tradeFont)) + 10, 22);

                let backgroundBrush = isBuy ? (hoveredOrSelected ? TradingToolViewBase.buyBackgroundActiveBrush : TradingToolViewBase.buyBackgroundBrush) : (hoveredOrSelected ? TradingToolViewBase.sellBackgroundActiveBrush : TradingToolViewBase.sellBackgroundBrush);
                let backgroundPen = isBuy ? (hoveredOrSelected ? TradingToolViewBase.buyBackgroundActivePen : TradingToolViewBase.buyBackgroundPen) : (hoveredOrSelected ? TradingToolViewBase.sellBackgroundActivePen : TradingToolViewBase.sellBackgroundPen);

                if (this.isLinkingStyle) {
                    backgroundBrush = hoveredOrSelected ? TradingToolViewBase.linkedStyleHoverBrush : TradingToolViewBase.linkedStyleDefaultBrush;
                    backgroundPen = Pens.Black;
                }

                if (this.TradeWasModified) {
                    backgroundBrush = TradingToolViewBase.grayBackgroundBrush;
                    backgroundPen = TradingToolViewBase.grayBackgroundPen;
                }

                let TextBrush = Brushes.White;
                if (this.isLinkingStyle) {
                    TextBrush = Brushes.Black;
                }
                // gr.RenderButton(backgroundBrush, backgroundPen, this.infoRect);
                gr.FillRect(backgroundBrush, this.infoRect.X, this.infoRect.Y, this.infoRect.Width, this.infoRect.Height);
                gr.DrawLine(backgroundPen, this.infoRect.X, this.infoRect.Y, this.infoRect.X, this.infoRect.Y + this.infoRect.Height);
                gr.DrawString(shortDescriptionText, TradingToolViewBase.tradeFont, TextBrush,
                    this.infoRect.X + this.infoRect.Width / 2, this.infoRect.Y + this.infoRect.Height / 2 + 1, 'center', 'middle');

                const leverage = this.getLeverageText();
                if (leverage) {
                    this.leverageRect = new Rectangle(this.infoRect.X + this.infoRect.Width, this.infoRect.Y, Math.floor(gr.GetTextWidth(leverage, TradingToolViewBase.tradeFont)) + 10, 22);
                    gr.FillRect(backgroundBrush, this.leverageRect.X, this.leverageRect.Y, this.leverageRect.Width, this.leverageRect.Height);
                    gr.DrawLine(backgroundPen, this.leverageRect.X, this.leverageRect.Y, this.leverageRect.X, this.leverageRect.Y + this.leverageRect.Height);
                    gr.DrawString(leverage, TradingToolViewBase.tradeFont, TextBrush,
                        this.leverageRect.X + this.leverageRect.Width / 2, this.leverageRect.Y + this.leverageRect.Height / 2 + 1, 'center', 'middle');
                } else {
                    this.leverageRect = Rectangle.Empty();
                }

                if (threeDotsMenuVisible) {
                    const infoRect = leverage ? this.leverageRect : this.infoRect;
                    this.actionButton = infoRect.copy();
                    this.actionButton.X += infoRect.Width;
                    this.actionButton.Width = 16;

                    let actionButtonImg = null;

                    if (this.isLinkingStyle) {
                        actionButtonImg = hoveredOrSelected ? TradingToolViewBase.threeDotsOutHover : TradingToolViewBase.threeDotsOutDefault;
                    } else
                        if (this.TradeWasModified) {
                            actionButtonImg = TradingToolViewBase.threeDots_gray_Image;
                        } else {
                            if (this.CurrentSelection.CurrentHover === ToolSelectionType.Action) {
                                actionButtonImg = isBuy ? TradingToolViewBase.threeDots_buy_activeImage : TradingToolViewBase.threeDots_sell_activeImage;
                            } else {
                                actionButtonImg = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.threeDots_buy_hoverImage : TradingToolViewBase.threeDots_buy_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.threeDots_sell_hoverImage : TradingToolViewBase.threeDots_sell_defaultImage);
                            }
                        }

                    if (actionButtonImg) {
                        gr.drawImage(actionButtonImg, this.actionButton.X, this.actionButton.Y);
                    }

                    gr.DrawLine(backgroundPen, this.actionButton.X, this.actionButton.Y, this.actionButton.X, this.actionButton.Y + this.actionButton.Height);
                }

                if (!param.TerceraChart.TerceraChartTradingToolsRenderer.NotTradingContext && (this.AllowCancelOrder() || this.AllowClosePosition()) && !TradingLockUtils.TradingLock.tradingLocked) {
                    const infoRect = leverage ? this.leverageRect : this.infoRect;
                    this.removeButton = infoRect.copy();
                    this.removeButton.X += infoRect.Width + (threeDotsMenuVisible ? this.actionButton.Width : 0);
                    this.removeButton.Width = 19;
                    // gr.RenderButton(backgroundBrush, backgroundPen, this.removeButton);

                    let cancelOrderImgName = null;

                    if (this.TradeWasModified) {
                        cancelOrderImgName = 'moveOrder_cancelImage';
                    } else {
                        if (this.CurrentSelection.CurrentHover === ToolSelectionType.Remove) {
                            cancelOrderImgName = isBuy ? 'buy_cancel_mine_hoverImage' : 'sell_cancel_mine_hoverImage';
                        } else {
                            cancelOrderImgName = (isBuy) ? (hoveredOrSelected ? 'buy_cancel_hoverImage' : 'buy_cancel_defaultImage') : (hoveredOrSelected ? 'sell_cancel_hoverImage' : 'sell_cancel_defaultImage');
                        }
                    }

                    if (threeDotsMenuVisible) // #101864
                    {
                        cancelOrderImgName += '_full';
                    } // если есть меню с тремя точками, то кнопка закрытия согласно макету должна стоять чуть отчужденно и тогда рисоваться она должна без обрезанного левого края -> другая картинка

                    let cancelOrderImg = TradingToolViewBase[cancelOrderImgName];

                    if (this.isLinkingStyle) {
                        cancelOrderImg = hoveredOrSelected ? TradingToolViewBase.cancel_Out_hover : TradingToolViewBase.cancel_Out_default;
                    }

                    if (cancelOrderImg) {
                        gr.drawImage(cancelOrderImg, this.removeButton.X, this.removeButton.Y);
                    }

                    if (!threeDotsMenuVisible) // #101864 если есть меню с тремя точками доп линия не нужна <- кнопка закрытия расположена отдельно от плашки согласно макету
                    {
                        gr.DrawLine(backgroundPen, this.removeButton.X, this.removeButton.Y, this.removeButton.X, this.removeButton.Y + this.removeButton.Height);
                    }
                } else {
                    this.removeButton = Rectangle.Empty();
                }
                // limit price for stop limit order
                if (this.Order.OrderType !== OrderType.StopLimit) {
                    this.DrawSLTP(gr, param, maxX, true);
                    this.DrawSLTP(gr, param, maxX, false);
                }
            } else {
                this.infoRect = Rectangle.Empty();
                this.leverageRect = Rectangle.Empty();
                this.removeButton = Rectangle.Empty();
                this.actionButton = Rectangle.Empty();
                this.slRect = Rectangle.Empty();
                this.tpRect = Rectangle.Empty();
            }

            infoRectDelta = this.removeButton.Width + this.infoRect.Width + this.leverageRect.Width + im.width - 8;

            if (threeDotsMenuVisible) {
                infoRectDelta += this.actionButton.Width + 2;
            } // #101864

            // this.imageRect = new Rectangle(screenPoints00 - 8, screenPoints01 - 11, im.width, im.height);
            const skipDrawingLine = screenPoints00 + infoRectDelta > maxX - 3;

            // Линия
            if (!skipDrawingLine) {
                gr.DrawLine(p, screenPoints00 + infoRectDelta, screenPoints01, maxX, screenPoints01);
            }

            // +++ add right line
            if (!isNaN(this.priceValue)) {
                gr.DrawLine(p, screenPoints00 - 8 - 5, screenPoints01, 0, screenPoints01);
            }

            // Иконка
            if (im) {
                gr.drawImage(im, this.imageRect.X, this.imageRect.Y);
            }

            // //Oco order connection line
            // if (Order != null && Order.ParentOrder is Order pOrder)
            // {
            //     OrderToolView parentOrder = myRenderer.GetToolViewById(pOrder.Id, pOrder.OrderType == Const.VALUE_STOP_LIMIT ? typeof(StopLimitOrderToolView) : typeof(OrderToolView)) as OrderToolView;
            //     bool isTop = Order.Price > pOrder.Price;
            //     int parentOrderY = parentOrder != null && parentOrder.MainOrderVisibility ? (int)window.PointsConverter.GetScreenY(pOrder.Price) : (int)(isTop ? gr.ClipBounds.Bottom - iconRect.Height + 5 : gr.ClipBounds.Top + iconRect.Height - 5);
            //     gr.DrawLine(orderGrayPen, iconRect.Left + 5, isTop ? iconRect.Bottom : iconRect.Top, iconRect.Left + 5, isTop ? parentOrderY - iconRect.Height / 2 : parentOrderY + iconRect.Height / 2);
            // }

            // плашка
            if (!this.Collapsed || this.TradeWasModified) {
                let backGroundImage = this.Order.BuySell == OperationType.Buy ? TradingToolViewBase.buyBrush : TradingToolViewBase.sellBrush;
                if (this.TradeWasModified) {
                    backGroundImage = TradingToolViewBase.grayBrush;
                }

                // var price = 0;
                // if (this.Order.OrderType == OrderType.StopLimit)
                //    price = this.Order.StopLimit;
                // else

                const price = this.TradeWasModified ? (!isNaN(this.priceValue) ? this.priceValue : this.lastValidPriceValue) : this.Price;
                const text = this.myRenderer.Instrument.formatPrice(price, true, true);
                param.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.VisualTrading, price, backGroundImage, text));
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
    }

    public DrawSLTP (gr, param, maxX: number, isSL): void {
        const deltaLineY = this.iconRect.Y + 11;
        const hoveredOrSelected = this.CurrentSelection.CurrentState !== SelectionState.None;

        const isBuy = this.Order.BuySell === OperationType.Buy;
        let SLTPRectDelta = 25;
        if ((isSL && !isBuy) || (!isSL && isBuy)) {
            SLTPRectDelta = -25;
        }

        const SLTPOrder = isSL ? this.Order.SLOrder : this.Order.TPOrder;
        const PriceValue = isSL ? this.Order.StopLossPriceValue : this.Order.TakeProfitPriceValue;

        let workRectangle = isSL ? this.slRect : this.tpRect;
        const sltpPriceY = isSL ? this.slPriceY : this.tpPriceY;
        let orderSlTpPrice = isSL ? this.slPriceValue : this.tpPriceValue;
        const isSLTPAllowed = this.AllowSLTP(isSL);

        if (SLTPOrder == null && isNaN(PriceValue) && isSLTPAllowed && !param.TerceraChart.TerceraChartTradingToolsRenderer.NotTradingContext) {
            workRectangle = this.iconRect.copy();
            workRectangle.X += 24;
            if (isNaN(orderSlTpPrice)) {
                workRectangle.Y += SLTPRectDelta;
            } else {
                workRectangle.Y = Math.floor(sltpPriceY) - OrderToolView.TRADETOOL_Y_DELTA;
            }

            let img = null;
            if (isSL) {
                img = hoveredOrSelected ? TradingToolViewBase.slGrayImageActive : TradingToolViewBase.slGrayImage;
            } else {
                img = hoveredOrSelected ? TradingToolViewBase.tpGrayImageActive : TradingToolViewBase.tpGrayImage;
            }
            if (img) {
                gr.drawImage(img, workRectangle.X, workRectangle.Y);
            }

            if (this.Order.BuySell === OperationType.Sell) {
                if (isSL) {
                    gr.DrawLine(TradingToolViewBase.orderGrayPen, workRectangle.X - 10, workRectangle.Y + workRectangle.Height / 2, workRectangle.X - 10, deltaLineY - img.height / 2);
                } else {
                    gr.DrawLine(TradingToolViewBase.orderGrayPen, workRectangle.X - 10, workRectangle.Y + workRectangle.Height / 2, workRectangle.X - 10, deltaLineY + img.height / 2);
                }

                gr.DrawLine(TradingToolViewBase.orderGrayPen, workRectangle.X - 10, workRectangle.Y + workRectangle.Height / 2, workRectangle.X, workRectangle.Y + workRectangle.Height / 2);
            } else {
                if (isSL) {
                    gr.DrawLine(TradingToolViewBase.orderGrayPen, workRectangle.X - 10, workRectangle.Y + workRectangle.Height / 2, workRectangle.X - 10, deltaLineY + img.height / 2);
                } else {
                    gr.DrawLine(TradingToolViewBase.orderGrayPen, workRectangle.X - 10, workRectangle.Y + workRectangle.Height / 2, workRectangle.X - 10, deltaLineY - img.height / 2);
                }

                gr.DrawLine(TradingToolViewBase.orderGrayPen, workRectangle.X - 10, workRectangle.Y + workRectangle.Height / 2, workRectangle.X, workRectangle.Y + workRectangle.Height / 2);
            }
            // sl info
            if (!isNaN(orderSlTpPrice)) {
            // calc data
                const ins = this.myRenderer.instrument;
                orderSlTpPrice = ins.RoundPriceToNearestPointSize(orderSlTpPrice);
                const offset = ins.roundPrice(orderSlTpPrice - this.Order.Price);
                const roundedPriceValue = ins.RoundPriceToNearestPointSize(this.Order.Price); // #50368
                const offsetTicks = ins.CalculateTicks(roundedPriceValue, orderSlTpPrice - roundedPriceValue);

                let pnl = ''; // TradingToolViewBase.FuturesSlTp(this.Order, offset);

                pnl = this.Order.Account.formatPrice(TradingToolViewBase.FuturesSlTp(this.Order, offset));
                const shortDescriptionText = ins.formatOffset(offsetTicks) + (GeneralSettings.TradingDefaults.ShowOffsetIn == OffsetModeViewEnum.Points ? 'p' : 't');
                let shortDescriptionTextSl = pnl + ' ' + shortDescriptionText;

                if (this.errorMode) {
                    pnl = '';
                    shortDescriptionTextSl = Resources.getResource('chart.visualTrading.Invalid price');
                }

                // calc rect
                let totalW = Math.floor(gr.GetTextWidth(shortDescriptionTextSl, TradingToolViewBase.tradeFont)) + 10;
                if (totalW < 30) {
                    totalW = 30;
                }

                // const deltaX = totalW;
                // totalW += Math.floor(gr.GetTextWidth(pnl, TradingToolViewBase.tradeFont)) + 10;

                const sltpInfoRect = workRectangle.copy();
                sltpInfoRect.X += workRectangle.Width + 4;
                sltpInfoRect.Width = totalW;

                // drawing
                gr.FillRect(TradingToolViewBase.grayBackgroundBrush, sltpInfoRect.X, sltpInfoRect.Y, sltpInfoRect.Width, sltpInfoRect.Height);
                gr.DrawString(shortDescriptionTextSl, TradingToolViewBase.tradeFont, Brushes.White, sltpInfoRect.X + 5, sltpInfoRect.Y + sltpInfoRect.Height / 2 + 1, 'left', 'middle');
                // gr.DrawString(pnl, TradingToolViewBase.tradeFont, Brushes.White, sltpInfoRect.X + deltaX + 5, sltpInfoRect.Y + sltpInfoRect.Height / 2 + 1, 'left', 'middle');

                // Линия
                gr.DrawLine(new Pen(Color.DarkGray), sltpInfoRect.X + sltpInfoRect.Width + 5, sltpInfoRect.Y + sltpInfoRect.Height / 2, maxX, sltpInfoRect.Y + sltpInfoRect.Height / 2);

                const price = orderSlTpPrice;
                const text = this.myRenderer.Instrument.formatPrice(price);
                param.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.VisualTrading, price, TradingToolViewBase.grayBrush, text));
            }
        } else {
            workRectangle = Rectangle.Empty();
            orderSlTpPrice = NaN;
        }
        // Применяем измененные значения
        if (isSL) {
            this.slPriceY = sltpPriceY;
            this.slPriceValue = orderSlTpPrice;
            this.slRect = workRectangle;
        } else {
            this.tpPriceY = sltpPriceY;
            this.tpPriceValue = orderSlTpPrice;
            this.tpRect = workRectangle;
        }
    }

    // #region mouse
    /// <summary>
    /// Является ли тулза выделенной мышью (сейчас по линиям, но можно и весь полигон)
    /// </summary>
    public override IsSelectCheck (x: number, y: number): boolean {
        if (this.TradeWasModified ||
        (!this.myRenderer.ShowPositions && !this.isOrderToolView) ||
        (!this.myRenderer.ShowOrders && this.isOrderToolView)
        ) // тулза смещена и с сервера не пришло подтверждение не двигаем её
        {
            return false;
        }

        const newSelection = this.GetCurrentSelection(x, y);
        return newSelection !== ToolSelectionType.None;
    }

    public override GetCurrentSelection (x: number, y: number): ToolSelectionType {
        let newSelection = ToolSelectionType.None;

        if (this.infoRect.Contains(x, y)) {
            newSelection = ToolSelectionType.Info;
        } else if (this.leverageRect.Contains(x, y)) {
            newSelection = ToolSelectionType.Leverage;
        } else if (this.imageRect.Contains(x, y)) {
            newSelection = ToolSelectionType.Image;
        } else if (this.iconRect.Contains(x, y)) {
            newSelection = ToolSelectionType.Icon;
        } else if (this.actionButton.Contains(x, y)) {
            newSelection = ToolSelectionType.Action;
        } else if (this.removeButton.Contains(x, y)) {
            newSelection = ToolSelectionType.Remove;
        } else if (this.slRect.Contains(x, y)) {
            newSelection = ToolSelectionType.SL;
        } else if (this.tpRect.Contains(x, y)) {
            newSelection = ToolSelectionType.TP;
        } else if (Math.abs(y - this.screenPoints[0][1]) < ToolView.POINT_DX) {
            newSelection = ToolSelectionType.Line;
        }

        return newSelection;
    }

    public override CheckMinDistanceForStartMoving (x: number, y: number): boolean {
        const mouseDownHover = this.CurrentSelection.MouseDownHover;
        if ((!TradingLockUtils.TradingLock.tradingLocked) && (((mouseDownHover === ToolSelectionType.Image || mouseDownHover === ToolSelectionType.Info) && this.AllowModify()) || ((mouseDownHover === ToolSelectionType.SL || mouseDownHover === ToolSelectionType.TP) && this.AllowSLTP()))) {
            return Math.abs(this.CurrentMovement.PrevY - y) >= this.MinDistanceForStartMoving();
        }

        return false;
    }

    // start move
    public override ProcessMoveStart (x: number, y: number): void {
        super.ProcessMoveStart(x, y);

        this.tpTrade = isNaN(this.Order.TakeProfitPriceValue) ? null : this.GetToolViewByOrderId(this.Order.OrderNumber + '-TP', TradingToolViewType.SLTPOrderToolView);// : this.GetToolViewByOrderId(this.Order.TPOrder.OrderNumber, TradingToolViewType.SLTPOrderToolView);
        this.slTrade = isNaN(this.Order.StopLossPriceValue) ? null : this.GetToolViewByOrderId(this.Order.OrderNumber + '-SL', TradingToolViewType.SLTPOrderToolView);// : this.GetToolViewByOrderId(this.Order.SLOrder.OrderNumber, TradingToolViewType.SLTPOrderToolView);
        this.sllTrade = this.slTrade ? this.slTrade.sllTool : null;
    }

    // move
    public override ProcessNewPosition (window, x: number, y: number, cashItemSeries: TerceraChartCashItemSeries = null): void {
        const mouseDownHover = this.CurrentSelection.MouseDownHover;
        this.TradeWasModified = true;
        this.errorMode = false;
        let data = 0;
        data = window.PointsConverter.GetDataY(y);

        // move sl
        if (mouseDownHover === ToolSelectionType.SL) {
            this.slPriceValue = data;
            this.priceValue = this.Price;
            this.slPriceY = y;
            const roundedSL = this.myRenderer.Instrument.RoundPriceToNearestPointSize(this.slPriceValue);
            const equalToEpsilon = MathUtils.equalToEpsilon(roundedSL, this.SlComparePrice());
            this.errorMode = equalToEpsilon || (this.Order.BuySell == OperationType.Buy ? roundedSL >= this.SlComparePrice() : roundedSL <= this.SlComparePrice());
        }
        // move tp
        else if (mouseDownHover === ToolSelectionType.TP) {
            this.tpPriceValue = data;
            this.priceValue = this.Price;
            this.tpPriceY = y;
            const roundedTP = this.myRenderer.Instrument.RoundPriceToNearestPointSize(this.tpPriceValue);
            const equalToEpsilon = MathUtils.equalToEpsilon(roundedTP, this.TpComparePrice());
            this.errorMode = equalToEpsilon || (this.Order.BuySell == OperationType.Buy ? roundedTP <= this.TpComparePrice() : roundedTP >= this.TpComparePrice());
        }
        // move order
        else {
            if (this.AllowModify()) {
                super.ProcessNewPosition(window, x, y, cashItemSeries); // обновляем главного
                this.priceValue = data;
                this.priceY = y;

                // дочерние сл/тп тулзы ставим на новые координаты когда двигается родительская
                if ((/* this.Order.SLOrder != null || this.Order.TPOrder != null || */ !isNaN(this.Order.StopLossPriceValue) || !isNaN(this.Order.TakeProfitPriceValue)) && this.AllowSLTP()) {
                // Move child trades
                    if (/* this.Order.SLOrder != null && */ this.slTrade != null) {
                        const orderPrice = this.Order.Price;
                        const slTicks = this.Order.Instrument.CalculateTicks(orderPrice, this.slTrade.Price - orderPrice);

                        const correctPriceValue = this.Order.OrderType === OrderType.StopLimit ? this.priceValue - (this.Order.StopLimit - this.Order.Price) : this.priceValue;

                        const d = this.slTrade.Price - orderPrice;
                        const sign = d === 0 ? 0 : (d > 0 ? 1 : -1);
                        this.slTrade.priceValue = this.Order.Instrument.CalculatePrice(this.Order.Instrument.RoundPriceToNearestPointSize(correctPriceValue), slTicks * sign);
                        this.slTrade.priceY = Math.floor(Math.round(window.PointsConverter.GetScreenY(this.slTrade.priceValue)));
                        this.slTrade.TradeWasModified = true;
                        this.slTrade.parentValue = correctPriceValue;
                        this.slTrade.parentY = y;
                    }
                    if (this.sllTrade != null) {
                        const orderPrice = this.Order.Price;
                        const slTicks = this.Order.Instrument.CalculateTicks(orderPrice, this.sllTrade.Price - orderPrice);

                        const correctPriceValue = this.Order.OrderType === OrderType.StopLimit ? this.priceValue - (this.Order.StopLimit - this.Order.Price) : this.priceValue;

                        const d = this.sllTrade.Price - orderPrice;
                        const sign = d === 0 ? 0 : (d > 0 ? 1 : -1);
                        this.sllTrade.priceValue = this.Order.Instrument.CalculatePrice(this.Order.Instrument.RoundPriceToNearestPointSize(correctPriceValue), slTicks * sign);
                        this.sllTrade.priceY = Math.floor(Math.round(window.PointsConverter.GetScreenY(this.sllTrade.priceValue)));
                        this.sllTrade.TradeWasModified = true;
                        if (this.slTrade != null) {
                            this.sllTrade.parentValue = this.slTrade.priceValue;
                            this.sllTrade.parentY = this.slTrade.priceY;
                        }
                    }
                    if (/* this.Order.TPOrder != null && */this.tpTrade != null) {
                        const orderPrice = this.Order.Price;
                        const tpTicks = this.Order.Instrument.CalculateTicks(orderPrice, this.tpTrade.Price - orderPrice);

                        const correctPriceValue = this.Order.OrderType === OrderType.StopLimit ? this.priceValue - (this.Order.StopLimit - this.Order.Price) : this.priceValue;

                        const d = this.tpTrade.Price - orderPrice;
                        const sign = d === 0 ? 0 : (d > 0 ? 1 : -1);
                        this.tpTrade.priceValue = this.Order.Instrument.CalculatePrice(this.Order.Instrument.RoundPriceToNearestPointSize(correctPriceValue), tpTicks * sign);
                        this.tpTrade.priceY = Math.floor(Math.round(window.PointsConverter.GetScreenY(this.tpTrade.priceValue)));
                        this.tpTrade.TradeWasModified = true;
                        this.tpTrade.parentValue = correctPriceValue;
                        this.tpTrade.parentY = y;
                    }
                }
            }
        }
    }

    // mouse finish
    public override ProcessMoveFinish (): void {
        try {
            super.ProcessMoveFinish();
            const mouseDownHover = this.CurrentSelection.MouseDownHover;

            if (this.errorMode) {
                this.slPlased = false;
                this.tpPlased = false;
                this.TradeWasModified = false;
                this.CancelMoving();
                return;
            }
            if (mouseDownHover === ToolSelectionType.Image || mouseDownHover === ToolSelectionType.Info || mouseDownHover == ToolSelectionType.Line || mouseDownHover === ToolSelectionType.SL || mouseDownHover === ToolSelectionType.TP) {
            // new SL
                if (mouseDownHover === ToolSelectionType.SL) {
                    this.slPriceValue = this.myRenderer.Instrument != null ? this.myRenderer.Instrument.roundPrice(this.slPriceValue) : this.slPriceValue;
                    this.terceraChartPanelContext.ChartVisualTrading(
                        this.Order,
                        {
                            price: this.slPriceValue,
                            action: TerceraChartTradingOperation.PlaceSL,
                            CancelCallback: this.undoChanges.bind(this)
                        });

                    this.TradeWasModified = false;
                    this.slPlased = true;
                }
                // new TP
                else if (mouseDownHover === ToolSelectionType.TP) {
                    this.tpPriceValue = this.myRenderer.Instrument != null ? this.myRenderer.Instrument.roundPrice(this.tpPriceValue) : this.tpPriceValue;
                    this.terceraChartPanelContext.ChartVisualTrading(
                        this.Order,
                        {
                            price: this.tpPriceValue,
                            action: TerceraChartTradingOperation.PlaceTP,
                            CancelCallback: this.undoChanges.bind(this)
                        });
                    this.TradeWasModified = false;
                    this.tpPlased = true;
                }
                // Modify order price, and child sl/tp orders
                else {
                    const newPrice = this.myRenderer.Instrument != null ? this.myRenderer.Instrument.roundPrice(this.priceValue) : this.priceValue;
                    const cancel = new CancelCB();
                    cancel.add(this.undoChanges.bind(this));
                    if (this.slTrade) {
                        this.slTrade.lastValidParentValue = newPrice;
                        cancel.add(this.slTrade.Updated.bind(this.slTrade));
                    }
                    if (this.tpTrade) {
                        this.tpTrade.lastValidParentValue = newPrice;
                        cancel.add(this.tpTrade.Updated.bind(this.tpTrade));
                    }
                    if (this.sllTrade) {
                        this.sllTrade.lastValidParentValue = this.sllTrade.priceValue;
                        cancel.add(this.sllTrade.Updated.bind(this.sllTrade));
                    }

                    this.lastValidPriceValue = newPrice;
                    this.terceraChartPanelContext.ChartVisualTrading(
                        this.Order,
                        {
                            price: newPrice,
                            isLimitModify: false,
                            action: TerceraChartTradingOperation.MoveOrder,
                            CancelCallback: cancel.Run.bind(cancel)
                        });
                }
            } else {
                super.ProcessMoveFinish();
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);

            this.ResetMouse();
            super.ProcessMoveFinish();
        }
    }

    public CancelMove (): void {
        this.FinishMove();
        this.Updated();
    }

    public ResetMove (): void {
        if (this.slTrade != null) {
            this.slTrade.lastValidPriceValue = NaN;
            this.slTrade.lastValidParentValue = NaN;
        }
        if (this.sllTrade != null) {
            this.sllTrade.lastValidPriceValue = NaN;
            this.sllTrade.lastValidParentValue = NaN;
        }
        if (this.tpTrade != null) {
            this.tpTrade.lastValidPriceValue = NaN;
            this.tpTrade.lastValidParentValue = NaN;
        }

        this.lastValidPriceValue = NaN;
        this.ResetMouse();
        this.tpTrade = null;
        this.slTrade = null;
        this.sllTrade = null;
    }

    public FinishMove (): void {
        if (this.errorMode) {
            this.slPlased = false;
            this.tpPlased = false;
        }
        if (this.CurrentSelection.MouseDownHover === ToolSelectionType.SL) {
        // slPlased = true;
        // TradeWasModified = false;
        } else if (this.CurrentSelection.MouseDownHover === ToolSelectionType.TP) {
        // tpPlased = true;
        // TradeWasModified = false;
        } else {
            if (this.Order?.SLOrder != null && this.slTrade != null) {
                this.slTrade.TradeWasModified = false;
                this.slTrade.priceValue = NaN;

                if (this.sllTrade != null) {
                    this.sllTrade.TradeWasModified = false;
                    this.sllTrade.priceValue = NaN;
                }
            }

            if (this.Order?.TPOrder != null && this.tpTrade != null) {
                this.tpTrade.TradeWasModified = false;
                this.tpTrade.priceValue = NaN;
            }
        }

        this.TradeWasModified = false;
        super.ProcessMoveFinish();
        this.ResetMove();
        this.MouseDownHover = ToolSelectionType.None;
    }

    // mouse click
    public override ProcessClick (e): void {
        if (this.CurrentSelection.MouseDownHover === this.CurrentSelection.CurrentHover) {
            switch (this.CurrentSelection.MouseDownHover) {
            case ToolSelectionType.Image:
            case ToolSelectionType.Leverage:
                this.Collapsed = !this.Collapsed;
                break;
            case ToolSelectionType.Info:
                if (this.Order.OrderType === OrderType.StopLimit) {
                    this.Collapsed = !this.Collapsed;
                } else if (!TradingLockUtils.TradingLock.tradingLocked && !this.Collapsed) {
                    this.terceraChartPanelContext.ChartVisualTrading(this.Order, { action: TerceraChartTradingOperation.ModifyOrderQuantity, rect: this.infoRect, quantity: this.Order.Amount, orderToolView: this });
                }
                break;
            case ToolSelectionType.Action:{
                // show menu
                const menuItems = [];
                if (!TradingLockUtils.TradingLock.tradingLocked) {
                    menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.ChangeToMarket, this.Order) });
                }
                const contextMenuItems = this.terceraChartPanelContext.terceraChartRactive.terceraChart.TerceraChartActionProcessor.CreateMenu(menuItems);

                if (contextMenuItems) {
                    contextMenuHandler.Show(contextMenuItems, e.ClientLocation.X, e.ClientLocation.Y);
                }
                // if (!Utils.TradingLock.tradingLocked)
                //     this.terceraChartPanelContext.ChartVisualTrading(this.Order, { action: TerceraChartTradingOperation.ActionButton, isPosition: false });
                break;
            }
            case ToolSelectionType.Remove:
                if (!TradingLockUtils.TradingLock.tradingLocked) {
                    this.terceraChartPanelContext.ChartVisualTrading(this.Order, { action: TerceraChartTradingOperation.CancelOrder });
                }
                break;

            case ToolSelectionType.Line:
            case ToolSelectionType.SL:
            case ToolSelectionType.TP:
            case ToolSelectionType.None:
            case ToolSelectionType.Trailing:
            default:
                break;
            }
        }
        this.ResetMouse();
    }

    public override ResetMouse (): void {
        this.CurrentSelection.MouseDownHover = ToolSelectionType.None;

        this.errorMode = false;
        this.priceValue = NaN;

        // 4. Дергание при торговле
        if (!this.slPlased) {
            this.slPriceValue = this.slPriceY = NaN;
        }
        if (!this.tpPlased) {
            this.tpPriceValue = this.tpPriceY = NaN;
        }

        if (this.slTrade != null) {
            this.slTrade.parentValue = NaN;
        }
        if (this.sllTrade != null) {
            this.sllTrade.parentValue = NaN;
        }
        if (this.tpTrade != null) {
            this.tpTrade.parentValue = NaN;
        }
    }

    public override GetCursor (e): Cursors {
        const curElement = this.CurrentSelection.MouseDownHover === ToolSelectionType.None ? this.CurrentSelection.CurrentHover : this.CurrentSelection.MouseDownHover;

        switch (curElement) {
        case ToolSelectionType.Image:
            return this.AllowModify() ? Cursors.NoMoveVert : null;
        case ToolSelectionType.Info:
            return Cursors.Hand;
        case ToolSelectionType.SL:
        case ToolSelectionType.TP:
            return this.AllowSLTP() ? (this.errorMode ? Cursors.No : Cursors.NoMoveVert) : null;
        case ToolSelectionType.Action:
        case ToolSelectionType.Remove:
        case ToolSelectionType.Line:
        case ToolSelectionType.None:
        case ToolSelectionType.Trailing:
        default:
            return null;
        }
    }

    public override GetTooltip (e): string | null {
        let res = '';
        switch (this.CurrentSelection.CurrentHover) {
        case ToolSelectionType.Image:
            res = Resources.getResource(this.IsPositionToolView() ? 'TradeTool.Position' : 'TradeTool.Order');
            break;
        case ToolSelectionType.Info:
            res = Resources.getResource(this.IsPositionToolView() ? 'TradeTool.Position P/L' : 'TradeTool.Amount of order');
            break;
        case ToolSelectionType.Remove:
            res = Resources.getResource(this.IsPositionToolView() ? 'TradeTool.Close position' : 'TradeTool.Cancel order');
            break;
        case ToolSelectionType.Action:
            res = Resources.getResource('TradeTool.ChangeToMarket');
            break;
        case ToolSelectionType.Leverage:
            res = Resources.getResource('TradeTool.Leverage');
            break;
        default:
            res = null;
            break;
        }
        return res || super.GetTooltip(e);
    }

    // #endregion

    public override Dispose (): void {
        this.slTrade = null;
        this.sllTrade = null;
        this.tpTrade = null;
        this.GetToolViewByOrderId = null;
        this.Order = null;

        super.Dispose();
    }
}

export class CancelCB {
    public methodArr = [];

    public add (method): void {
        this.methodArr.push(method);
    }

    public Run (): void {
        for (let i = 0; i < this.methodArr.length; i++) {
            this.methodArr[i]();
        }
    }
}
