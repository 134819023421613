// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { ThemeManager } from '../misc/ThemeManager';
import { OrderTifMap } from '../../Utils/Trading/OrderTifEnum';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { PriceFormatter } from '../../Utils/Instruments/PriceFormatter';
import { RiskPlan } from '../../Commons/cache/RiskPlan';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { type OrderHistory } from '../../Commons/cache/OrderHistory';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Account } from '../../Commons/cache/Account';
import { HistoryOrderFormatter } from '../../Commons/cache/Formatters/HistoryOrderFormatter';
import { OrderHistoryUtils } from '../../Utils/Trading/OrderHistoryUtils';

export class OrderHistoryItem extends BaseItem {
    public orderHistory: OrderHistory;

    constructor (orderHistory) {
        super();

        this.orderHistory = orderHistory;

        if (orderHistory?.InstrumentStr != null) {
            const [instrumentStr] = orderHistory.InstrumentStr.split(':');
            orderHistory.InstrumentStr = instrumentStr;
        }

        const tm = ThemeManager.CurrentTheme;
        this.CellForeColorMap[1] = tm.TableGoldColor;
        this.CellForeColorMap[8] = tm.TableGoldColor;
        if (orderHistory != null) {
            this.CellForeColorMap[2] = HistoryOrderFormatter.IsLong(orderHistory) ? tm.TableLongBuyColor : tm.TableShortSellColor;
        }
    }

    public override ColumnCount (): number {
        return OrderHistoryItem.columnsParams.length;
    }

    // TODO. Refactor. Optimize.
    public override getColumnData (column: number): ColumnData {
        const val = this.getColumnValue(column);
        let fVal = null;

        const orderHistory = this.orderHistory;
        const ins = this.GetCurrentInstrument();

        switch (column) {
        case 1:
            if (ins) {
                fVal = ins.DisplayShortName();
            }
            break;
        case 5:
            fVal = orderHistory.OrderNumber;
            break;
        case 7:
        case 15:
            fVal = !isNaN(val) && val !== null
                ? (ins ? ins.formatPricePrecision(val) : PriceFormatter.formatPrice(val, 2))
                : '';
            break;
        case 11:
            fVal = DateTimeUtils.FormatToTime(val);
            break;
        case 12:
            fVal = DateTimeUtils.formatDate(val, 'DD.MM.YYYY  HH:mm:ss');
            break;
        case 18:
            fVal = HistoryOrderFormatter.GetBoughtStr(orderHistory);
            break;
        case 19:
            fVal = HistoryOrderFormatter.GetSoldStr(orderHistory);
            break;
        case 20:
            fVal = ins && val ? ins.formatPrice(val) : '';
            break;
        case 21:
            if (val && val.getTime() > 0) {
                fVal = DateTimeUtils.FormatToDate(val);
            } else {
                fVal = '';
            }
            break;
        case 29:
            fVal = val ? OrderUtils.GetFormattedLeverage(val, true) : Resources.getResource('general.N_A');

            break;
        default:
            if (val instanceof Date) {
                fVal = DateTimeUtils.FormatToDateAndTime(val);
            } else if (val instanceof Number && ins) {
                fVal = ins.formatPrice(val as number);
            }
        }

        if (!fVal && fVal !== '') {
            fVal = val ? val.toString() : '';
        }

        return new ColumnData(val, fVal);
    }

    public override getColumnValue (column: number): any {
        const orderHistory = this.orderHistory;
        const buy = HistoryOrderFormatter.IsLong(orderHistory);

        switch (column) {
        case 0:
        {
            return orderHistory.Account != null
                ? orderHistory.Account.toString()
                : orderHistory.AccountStr;
        }
        case 1:
        {
            return orderHistory.Instrument != null
                ? orderHistory.Instrument.DisplayShortName()
                : orderHistory.InstrumentStr ?? '';
        }
        // TODO. Ugly.
        case 2:
        {
            return Resources.getResource('general.trading.' + (buy ? 'Buy' : 'Sell'));
        }

        case 3:
            return OrderHistoryUtils.localizeOrderExecutionType(orderHistory.eventTypeRaw);

        case 4:
        {
            return Resources.getResource(orderHistory.Message);
        }
        case 5:
        {
            const orderNumber = orderHistory.OrderNumber.split('-')[0];
            return parseFloat(orderNumber) || 0;
        }
        case 6:
        {
            return Resources.getResource(
                'property.' + OrderUtils.getOrderTypeLocalizationKey(orderHistory.OrderType));
        }
        case 7:
        {
            return !isNaN(orderHistory.Price)
                ? OrderUtils.GetCorrectPriceForOrderType(orderHistory.Price, orderHistory.StopLimit, OrderUtils.PriceType.LimitPrice, orderHistory.OrderType)
                : NaN;
        }
        case 8:
        {
            let sign = 1;
            // if (!buy && sess.ShowAmountSign)
            //     sign = -1
            if (orderHistory.Quantity < 0 && sign < 0) { sign = 1; }

            return GeneralSettings.View.displayAmountInLots() || !orderHistory.Instrument
                ? orderHistory.Quantity * sign
                : orderHistory.Quantity * sign * orderHistory.Instrument.LotSize;
        }
        case 9:
        {
            return orderHistory.Route;
        }
        case 10:
        {
            return orderHistory.RemoteId;
        }
        case 11:
        {
            return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(orderHistory.Time);
        }
        case 12:
        {
            return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(orderHistory.Date);
        }
        // при экспорте нам нужно знать значение в виде строки
        case 13:
        {
            if (orderHistory.Instrument && orderHistory.Instrument.InstrType === InstrumentTypes.FORWARD) {
                return '';
            } else {
                const tif = orderHistory.TimeInForce;
                const type = tif ? tif.type : null;
                const expirationTime = tif?.expirationTime ? ' ' + tif.expirationTime : '';

                return Resources.getResource('property.' + OrderTifMap[type]) + expirationTime;
            }
        }
        case 14:
        {
            return orderHistory.TraderId;
        }
        case 15:
        {
            return orderHistory
                ? OrderUtils.GetCorrectPriceForOrderType(orderHistory.Price, orderHistory.StopLimit, OrderUtils.PriceType.StopPrice, orderHistory.OrderType)
                : NaN;
        }
        case 16:
        {
            const account = this.GetCurrentAccount();
            return account ? account.userLogin : '';
        }
        case 17:
        {
            if (orderHistory && orderHistory.Instrument) {
                return orderHistory.Instrument.getTypeString();
            } else if (orderHistory?.SymbolType) {
                return orderHistory.SymbolType;
            }
            return '';
        }
        case 18:
        {
            if (orderHistory && orderHistory.Instrument) {
                return HistoryOrderFormatter.GetBought(orderHistory);
            } else { return 0; }
        }
        case 19:
        {
            if (orderHistory && orderHistory.Instrument) {
                return HistoryOrderFormatter.GetSold(orderHistory);
            } else { return 0; }
        }
        case 20:
        {
            if (orderHistory && orderHistory.StrikePrice > 0) {
                return orderHistory.StrikePrice;
            } else {
                return 0;
            }
        }
        case 21:
        {
            if (!isNullOrUndefined(orderHistory?.Instrument) && orderHistory.Instrument.ExpDateReal.getFullYear() > 2000) {
                return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(orderHistory.Instrument.ExpDateReal);
            } else {
                return DateTimeUtils._ZeroTime;
            }
        }
        case 22:
        {
            let sign = 1;
            // if (!buy && sess.ShowAmountSign)
            //     sign = -1
            if (orderHistory.DisclosedQty < 0 && sign < 0) { sign = 1; }

            if (GeneralSettings.View.displayAmountInLots() || !orderHistory.Instrument) {
                return orderHistory.DisclosedQty * sign;
            } else {
                return orderHistory.DisclosedQty * sign * orderHistory.Instrument.LotSize;
            }
        }
        case 23:
        {
            if (!GeneralSettings.View.displayAmountInLots() && orderHistory.Instrument) {
                return orderHistory.RemainingQuantity * orderHistory.Instrument.LotSize;
            } else {
                return orderHistory.RemainingQuantity;
            }
        }
        case 24:
        {
            if (!GeneralSettings.View.displayAmountInLots() && orderHistory.Instrument) {
                return orderHistory.FilledQuantity * orderHistory.Instrument.LotSize;
            } else {
                return orderHistory.FilledQuantity;
            }
        }
        case 25:
        {
            if (orderHistory.Instrument) {
                return InstrumentUtils.GetLocalizedProductType(orderHistory.Instrument, orderHistory.ProductType);
            } else {
                return '';
            }
        }
        case 26:
        {
            return orderHistory?.ExternalOrderId ? orderHistory.ExternalOrderId : '';
        }

        case 27:
        {
            if (orderHistory.Instrument) {
                return orderHistory.Instrument.TradingExchange;
            } else if (orderHistory.TradingExchange) {
                return orderHistory.TradingExchange;
            }
            return '';
        }

        case 28:{
            if (!orderHistory) return '';

            let descr = orderHistory.Description;
            const ins = orderHistory.Instrument;

            if (!descr && ins) {
                descr = ins.DescriptionValue();
            }

            return descr;
        }

        case 29:
            if (!orderHistory) return NaN;
            return +orderHistory.Leverage;

        default:
            return '';
        }
    }

    public override getFormatTotalValue (column: number, value, precisionMode = false): string | number {
        let formattedValue = '';
        // let asset = dc.GetAssetByName(dc.userBaseCurrency)

        switch (column) {
        case 8:
            // case 18:
            // case 19:
            if (precisionMode) { return 2; }
            formattedValue = value.toFixed(2);
            break;

        // formattedValue = asset.formatPrice(value)
        // break
        }

        return formattedValue;
    }

    // TODO. Refactor.
    public override GetColumnParams (column: number): ColumnParams {
        const result = OrderHistoryItem.columnsParams[column];
        result.MenuIndex = this.GetMenuIndex(result.HeaderKey);
        // Форматирование цены
        if (column === 7) { result.FormatPrice = true; }

        if (column === 4 || column === 9 || column === 10 || column === 14 || column === 11) {
            result.Hidden = true;
        }

        // роут
        /* TODO. Rules.
    if (column === 9)
        result.Hidden = !this.dataCache.isAllowed(RulesSet.FUNCTION_POSITIONSADMIN, null);
    */

        // Product type
        if (column === 25) {
            result.Hidden = !RiskPlan.showProductType;
        }

        return result;
    }

    public GetMenuIndex (headerKey: string): number {
        const idx = OrderHistoryItem.ListMenuIndex.indexOf(headerKey);
        return idx === -1 ? 100 : idx;
    }

    // TODO. Remove?
    public override fireUpdate (): void {

    }

    public override GetCurrentAccount (): Account {
        const orderHistory = this.orderHistory;
        return orderHistory ? orderHistory.Account : null;
    }

    public override GetCurrentInstrument (): Instrument {
        const orderHistory = this.orderHistory;
        return orderHistory ? orderHistory.Instrument : null;
    }

    public static readonly ListMenuIndex =
        [
            'panel.OrderBook.Account',
            'panel.OrderBook.Login',
            'panel.OrderBook.Route',
            'panel.OrderBook.Instrument',
            'panel.OrderBook.instrType',
            'panel.OrderBook.OrderNumber',
            'panel.OrderBook.Action',
            'panel.OrderBook.Type',
            'panel.OrderBook.Price',
            'panel.OrderBook.Quantity',
            'panel.OrderBook.Date',
            'panel.OrderBook.Time',
            'panel.OrderBook.Event',
            'panel.OrderBook.TIF',
            'panel.OrderBook.StopPrice',
            'panel.OrderBook.TraderID',
            'panel.OrderBook.Bought',
            'panel.OrderBook.Sold',
            'panel.OrderBook.StrikePrice',
            'panel.OrderBook.ExpirationDate',
            'panel.OrderBook.ReminingQuantity',
            'panel.OrderBook.FilledQuantity',
            'panel.OrderBook.ExternalOrderID',
            'panel.OrderBook.ExchangeTrading',
            'panel.OrderBook.Description',
            'panel.OrderBook.Leverage'
        ];

    public static readonly columnsParams =
        [
            /* 0 */ new ColumnParams('panel.OrderBook.Account', 70, 0, false, false, true),
            new ColumnParams('panel.OrderBook.Instrument', 70, 0, false, true, true, false, true),
            new ColumnParams('panel.OrderBook.Action', 70, 0, false, true, true),
            new ColumnParams('panel.OrderBook.Event', 70, 0, false, true, true),
            new ColumnParams('panel.OrderBook.Message', 70, 0, false, false, true),
            /* 5 */ new ColumnParams('panel.OrderBook.OrderNumber', 70, 0, false, false, false, false, true),
            new ColumnParams('panel.OrderBook.Type', 70, 0, false, true, true),
            new ColumnParams('panel.OrderBook.Price', 70, 1, false, true, false),
            new ColumnParams('panel.OrderBook.Quantity', 70, QuickTableColumnType.COL_MORELESS_NUMERIC, true, true, false),
            new ColumnParams('panel.OrderBook.Route', 70, 0, false, false, false),
            /* 10 */ new ColumnParams('panel.OrderBook.RemoteID', 70, 0, false, false, false),
            new ColumnParams('panel.OrderBook.Time', 70, QuickTableColumnType.COL_TIME_SORT, false, true, false),
            new ColumnParams('panel.OrderBook.Date', 70, QuickTableColumnType.COL_DATE_SORT, false, true, true),
            new ColumnParams('panel.OrderBook.TIF', 70, 0, false, false, false),
            new ColumnParams('panel.OrderBook.TraderID', 70, 0, false, false, false),
            /* 15 */ new ColumnParams('panel.OrderBook.StopPrice', 70, 1, false, true, false),
            new ColumnParams('panel.OrderBook.Login', 70, 0, false, false, true),
            new ColumnParams('panel.OrderBook.instrType', 70, 0, false, true, true),
            new ColumnParams('panel.OrderBook.Bought', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, true),
            new ColumnParams('panel.OrderBook.Sold', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, true),
            /* 20 */ new ColumnParams('panel.OrderBook.StrikePrice', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.OrderBook.ExpirationDate', 40, QuickTableColumnType.COL_DATE_SORT, false, false, false),
            new ColumnParams('panel.OrderBook.DislocedQty', 40, QuickTableColumnType.COL_MORELESS_NUMERIC, false, false, false),
            new ColumnParams('panel.OrderBook.ReminingQuantity', 70, QuickTableColumnType.COL_MORELESS_NUMERIC, false, true, false),
            new ColumnParams('panel.OrderBook.FilledQuantity', 70, QuickTableColumnType.COL_MORELESS_NUMERIC, false, true, false),
            /* 25 */ new ColumnParams('panel.OrderBook.ProductType', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
            new ColumnParams('panel.OrderBook.ExternalOrderID', 70, 0, false, false, false),
            new ColumnParams('panel.OrderBook.ExchangeTrading', 70, QuickTableColumnType.COL_DEFAULT, false, true),
            new ColumnParams('panel.OrderBook.Description', 70, 0, false, false, false),
            new ColumnParams('panel.OrderBook.Leverage', 70, 0, false, false, true)
        /* 30 */
        ];
}
