// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode, MAModeMap } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterDouble } from '../InputParamaterClasses/InputParameterDouble';

export class BB extends IndicatorScriptBase {
    public SourcePrices: number;
    public MAType: number;
    public MAPeriod: number;
    public D: number;

    private ma: iBuildInIndicator;
    private readonly LOWER_BAND: number = 0;
    private readonly UPPER_BAND: number = 1;
    private readonly MIDDLE_BAND: number = 2;

    constructor () {
        super();

        this.ProjectName = 'Bollinger Bands';
        this.Comments = 'Provides a relative definition of high and low based on standard deviation and a simple moving average';
        super.SetIndicatorLine('Line 1', Color.Green, 1, LineStyle.SimpleChart);
        super.SetIndicatorLine('Line 2', Color.Red, 1, LineStyle.SimpleChart);
        super.SetIndicatorLine('Line 3', Color.Gray, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.SourcePrices = PriceType.Low;
        super.InputParameter(new InputParameterCombobox('SourcePrices', 'Sources prices for MA', 0,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of moving average', 1,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.MAPeriod = 5;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of MA for envelopes', 2, 1, 9999));

        this.D = 1;
        super.InputParameter(new InputParameterDouble('D', 'Value of confidence interval', 3, -2147483647, 2147483647, 1, 0.1));
    }

    public override GetIndicatorShortName (): string {
        return 'BB(' + this.MAPeriod + ';' + this.D + ';' + MAModeMap[this.MAType] + ')';
    };

    public override getCustomName (): string {
        return 'BB (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.ma = this.Indicators.iMA(this.CurrentData, this.MAPeriod, this.MAType, 0, this.SourcePrices);
        return [this.ma];
    };

    public OnQuote (): void {
        super.OnQuote();
        // Checking, if current amount of bars
        // more, than period of moving average. If it is
        // then the calculation is possible
        const CurrentData = this.CurrentData;
        const MAPeriod = this.MAPeriod;
        const D = this.D;
        if (CurrentData.Count <= MAPeriod) { return; };

        const maValue = this.ma.GetValue();
        const SourcePrices = this.SourcePrices;

        // Calulation of the summa
        let summa = 0;
        for (let i = 0; i < MAPeriod; i++) { summa += Math.pow(CurrentData.GetPrice(SourcePrices, i) - maValue, 2); };

        // Calculation of deviation value
        summa = D * Math.sqrt(summa / MAPeriod);

        // Setting values
        this.SetValue(this.MIDDLE_BAND, 0, maValue);
        this.SetValue(this.LOWER_BAND, 0, maValue - summa);
        this.SetValue(this.UPPER_BAND, 0, maValue + summa);
    };
}
