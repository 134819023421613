import { TerceraPanel } from './TerceraPanel';
import { TerceraAutoHidePanelTemplate } from '../../templates';
import { MathUtils } from '../../Utils/MathUtils';
import { contextMenuHandler } from '../../Utils/AppHandlers';

export class TerceraAutoHidePanel extends TerceraPanel {
    private _observer: ResizeObserver;

    constructor () { super(); }

    public override getType (): string { return 'TerceraAutoHidePanel'; }

    public override oncomplete (): void {
        this.onMoreButtonClick = this.onMoreButtonClick.bind(this);
        super.oncomplete();
        this.subscribe();
    }

    public override dispose (): void {
        this.unsubscribe();
        super.dispose();
    }

    private subscribe (): void {
        this._observer = new ResizeObserver(() => {
            const hiddenElements = this.getHiddenElements();
            void this.set('isShowMoreButton', hiddenElements.length > 0);
        });

        const container = super.find('.auto-hide-panel-container');
        if (!MathUtils.IsNullOrUndefined(container)) {
            this._observer.observe(container);
        }

        this.on('onMoreButtonClicked', this.onMoreButtonClick);
    }

    private unsubscribe (): void {
        const container = this.find('.auto-hide-panel-container');
        if (!MathUtils.IsNullOrUndefined(container)) {
            this._observer.unobserve(container);
        }
        this.off('onMoreButtonClicked', this.onMoreButtonClick);
    }

    private onMoreButtonClick (): void {
        const hiddenElements = this.getHiddenElements();
        const contextMenuItems: any [] = [];
        for (let i = 0; i < hiddenElements.length; i++) {
            const element = hiddenElements[i] as any;
            // Get Ractive component from HtmlElement - potentially unsafe
            const component = element._ractive?.proxy?.ractive;
            if (MathUtils.IsNullOrUndefined(component) || MathUtils.IsNullOrUndefined(component.getContextMenuItem)) {
                continue;
            }
            const getContextMenuItem = component.getContextMenuItem.bind(component);
            const contextMenuItem = getContextMenuItem();
            if (!MathUtils.IsNullOrUndefined(contextMenuItem)) {
                contextMenuItems.push(contextMenuItem);
            }
        }
        const rect = this.findAll('.terceraButton.js-more-button')[0].getBoundingClientRect();
        contextMenuHandler.Show(contextMenuItems, rect.left, rect.bottom);
    }

    private getHiddenElements (): HTMLElement[] {
        const hiddenElements: HTMLElement[] = [];
        const container = this.find('.auto-hide-panel-container');
        if (MathUtils.IsNullOrUndefined(container)) {
            return hiddenElements;
        }
        for (let i = 0; i < container.children.length; i++) {
            const child: HTMLElement = container.children[i] as HTMLElement;
            if (child.offsetTop >= container.offsetHeight) {
                hiddenElements.push(container.children[i] as HTMLElement);
            }
        }
        return hiddenElements;
    }
}

TerceraPanel.extendWith(TerceraAutoHidePanel, {
    template: TerceraAutoHidePanelTemplate,
    data: function () {
        return {
            isShowMoreButton: false
        };
    }
});
