// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCache } from '../Commons/DataCache';
import { type Account } from '../Commons/cache/Account';
import { type Instrument } from '../Commons/cache/Instrument';
import { OrderTypeBaseParameters } from '../Commons/cache/OrderParams/order-type/OrderTypeBaseParameters';
import { type OrderTif } from '../Utils/Trading/OrderTifEnum';
import { type OrderType } from '../Utils/Trading/OrderType';
import { ApplicationPanel } from './panels/ApplicationPanel';

export class OrderEntryBase extends ApplicationPanel {
    constructor () {
        super();
    }

    public static PopulateOrderTypes (account: Account, instrument: Instrument, forbiddenOrderTypes, lastSelectedOrderType, mamMode): OrderType[] {
        mamMode = !!mamMode;
        const allowedOrderTypes: OrderType[] = [];

        if (DataCache.OrderParameterContainer === null) {
            return allowedOrderTypes;
        }

        const orderTypes = DataCache.OrderParameterContainer.OrderTypes;

        const supportedParamObj = new OrderTypeBaseParameters(instrument, account);

        for (const key in orderTypes) {
            const orderType = orderTypes[key];
            const id = orderType.id();

            if (forbiddenOrderTypes?.[id]) {
                continue;
            }

            if (!orderType.IsSupported(supportedParamObj)) {
                continue;
            }

            allowedOrderTypes.push(id);
        }

        return allowedOrderTypes;
    }

    public static PopulateTifs (orderType: OrderType, instrument: Instrument): OrderTif[] {
        let tifs: OrderTif[] = [];
        if (DataCache.OrderParameterContainer === null) {
            return tifs;
        }

        const supportedParamObj = new OrderTypeBaseParameters(instrument);

        const currentOrderType = DataCache.OrderParameterContainer.GetOrderType(orderType);
        if (isNullOrUndefined(currentOrderType) || !currentOrderType.IsSupported(supportedParamObj)) {
            return tifs;
        }

        const route = instrument.DataCache.getRouteByName(instrument.Route);
        const allowedTifs = route.DictOrderTypeTifs[orderType];
        if (isValidArray(allowedTifs)) {
            tifs = allowedTifs;
        }

        return tifs;
    }
}

ApplicationPanel.extendWith(OrderEntryBase, {});
