// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { MathUtils } from '../../Utils/MathUtils';
import { SnapshotScreenTemplate } from '../../templates.js';
import { TerceraWindowBase } from './TerceraWindowBase';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { Account } from '../../Commons/cache/Account';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { snapshotScreenHandler } from '../../Utils/AppHandlers';
import { DataCache } from '../../Commons/DataCache';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type InitOpts } from 'ractive';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';

let instance = null;

export class SnapshotScreen extends TerceraWindowBase {
    public static VALUES_NAMES_LIST = ['Ask', 'AskSize', 'Bid', 'BidSize', 'Change', 'ChangePercent', 'High', 'Last', 'LastSize', 'Low', 'Volume'];

    public message: any = null;
    public instrument: any = null;
    public needCenterLocation: boolean = false;
    public headerAdditionalColor: string = '#3EA7DE';

    constructor (initProps: InitOpts<SnapshotScreen> = null, data?: { instrument: Instrument, message: any, needCenterLocation: boolean }) {
        super(initProps);

        if (data != null) {
            this.instrument = data.instrument;
            this.message = data.message;
            this.needCenterLocation = data.needCenterLocation;
        }
    }

    public override getType (): string { return ScreensNames.SnapshotScreen; }

    public override oninit (): void {
        super.oninit();

        Resources.onLocaleChange.Subscribe(this.localize, this);

        void this.set('displayAmountInLots', GeneralSettings.View.displayAmountInLots());
        GeneralSettings.SettingsChanged.Subscribe(this.settingsChanged, this);

        DataCache.OnSnapshotResponseReceived.Subscribe(this.updateSnapshotBtn, this);
        Account.TradeStatusChanged.Subscribe(this.updateSnapshotBtn, this);
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.on('getSnapshotBtnClick', this.getSnapshotBtnClick.bind(this));
        this.on('closeBtnClick', this.closeBtnClick.bind(this));

        this.IncreaseWidthIfNeed();
        this.localize();

        if (this.needCenterLocation) {
            this.center();
        }
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);
        GeneralSettings.SettingsChanged.UnSubscribe(this.settingsChanged, this);

        DataCache.OnSnapshotResponseReceived.UnSubscribe(this.updateSnapshotBtn, this);
        Account.TradeStatusChanged.UnSubscribe(this.updateSnapshotBtn, this);

        super.dispose();
    }

    public override localize (): void {
        this.updateSnapshotBtn();

        void this.set({
            header: Resources.getResource('screen.snapshot.header') + ' ' + SnapshotScreen.getColoredTimeForHeader(this.ReceivedAt, this.headerAdditionalColor),
            closeBtnText: Resources.getResource('screen.snapshot.closeBtnText'),
            strNA: Resources.getResource('general.N_A'),
            lastLabelText: Resources.getResource('screen.snapshot.lastLabel'),
            bidLabelText: Resources.getResource('screen.snapshot.bidLabel'),
            askLabelText: Resources.getResource('screen.snapshot.askLabel'),
            priceLabelText: Resources.getResource('screen.snapshot.priceLabel'),
            sizeLabelText: Resources.getResource('screen.snapshot.sizeLabel'),
            changeLabelText: Resources.getResource('screen.snapshot.changeLabel'),
            highLabelText: Resources.getResource('screen.snapshot.highLabel'),
            lowLabelText: Resources.getResource('screen.snapshot.lowLabel'),
            changePercentLabelText: Resources.getResource('screen.snapshot.changePercentLabel'),
            volumeLabelText: Resources.getResource('screen.snapshot.volumeLabel')
        });
    }

    public getSnapshotBtnClick (): void {
        if (this.get('getSnapshotBtnDisabled') === true) {
            return;
        }

        SnapshotScreen.SendSnapshotRequestMessage(this.instrument);
    }

    public closeBtnClick (): void {
        this.close();
    }

    public updateSnapshotBtn (): void {
        let text = Resources.getResource('screen.snapshot.getSnapshotBtnText');
        let tt = '';

        const availableNum = DataCache.getAvailableNumberOfSnapshotRequests();
        if (availableNum >= 0) {
            text += ' (' + availableNum + ')';
            tt = Resources.getResource('screen.snapshot.getSnapshotBtnText.' + (availableNum > 0 ? 'moreZero' : 'Zero') + '.tt');
        } else {
            tt = Resources.getResource('screen.snapshot.getSnapshotBtnText.minusOne.tt');
        }

        void this.set({
            getSnapshotBtnText: text,
            getSnapshotBtnTooltip: tt,
            getSnapshotBtnDisabled: availableNum === 0
        });
    }

    public IncreaseWidthIfNeed (): void {
        const message = this.message;
        if (message == null) return;

        const extraSymbolsNum = this.MaxStringLength - SnapshotScreen.MAX_STANDARD_VALUE_LENGTH;
        if (extraSymbolsNum > 0) {
            void this.set('width', SnapshotScreen.STANDARD_SCREEN_WIDTH + extraSymbolsNum * SnapshotScreen.EXTRA_SYMBOL_WIDTH);
        }
    }

    public settingsChanged (): void {
        const newSettingsInLots = GeneralSettings.View.displayAmountInLots();
        if (this.get('displayAmountInLots') === newSettingsInLots) {
            return;
        }

        void this.set('displayAmountInLots', newSettingsInLots);
    }

    public static readonly STANDARD_SCREEN_WIDTH = 329;
    public static readonly EXTRA_SYMBOL_WIDTH = 16;
    public static readonly MAX_STANDARD_VALUE_LENGTH = 9;

    public static show (ins, msg): void {
        let x = null;
        let y;
        const oldScreen = instance;
        if (oldScreen != null) {
            x = oldScreen.getX();
            y = oldScreen.getY();
            oldScreen.close();
        }
        const needSetLocation = x !== null;

        instance = new SnapshotScreen(null, { instrument: ins, message: msg, needCenterLocation: !needSetLocation });
        instance.on('teardown', function () { instance = null; });
        MainWindowManager.MainWindow.addControl(instance);

        if (needSetLocation) {
            instance.setLocation(x, y);
        }

        instance.setFocus();
    }

    public static SendSnapshotRequestMessage (ins): void {
        if (ins == null) return;
        const subsRoute = DataCache.getRouteById(ins.Route);
        const route = subsRoute != null ? subsRoute.QuoteRouteId : null;

        if (route != null) {
            DataCache.SendSnapshotRequestMessage(ins.InstrumentTradableID, route);
        }
    }

    public static getColoredTimeForHeader (dateObj, color): string {
        const timeFormatted = DateTimeUtils.formatDate(dateObj, 'HH:mm:ss');

        if (color == null) {
            return timeFormatted;
        }

        return '<span style="color:' + color + '">' + timeFormatted + '</span>';
    }

    get MaxStringLength (): number {
        let maxLen = 0;
        const properties = SnapshotScreen.VALUES_NAMES_LIST;
        for (let i = 0; i < properties.length; i++) {
            const stringValue = String(this.get(properties[i]));
            if (stringValue != null) {
                maxLen = Math.max(maxLen, stringValue.length);
            }
        }
        return maxLen;
    }

    get ReceivedAt (): any {
        return this.message != null ? this.message.ReceivedAt : this.get('strNA');
    }

    public static SnapshotScreenHandlerInitialize (): void {
        snapshotScreenHandler.show = SnapshotScreen.show;
    }
}

TerceraWindowBase.extendWith(SnapshotScreen, {
    partials: { bodyPartial: SnapshotScreenTemplate },
    data: function () {
        return {
            name: 'snapshotScreen',
            movable: true,
            resizable: false,
            showFooter: false,
            focused: true,
            zIndex: 300,

            width: 343,
            height: 293,

            lastLabelText: 'Last',
            bidLabelText: 'Bid',
            askLabelText: 'Ask',
            priceLabelText: 'Price:',
            sizeLabelText: 'Size:',
            changeLabelText: 'Change:',
            highLabelText: 'Today high:',
            lowLabelText: 'Today low:',
            changePercentLabelText: 'Change, %:',
            volumeLabelText: 'Today volume:',

            strNA: '---',
            displayAmountInLots: null,

            getSnapshotBtnText: 'Get snapshot (99)',
            getSnapshotBtnTooltip: 'Snapshot requests limit has been reached',
            getSnapshotBtnDisabled: false,
            closeBtnText: 'Close'
        };
    },
    computed: {
        Ask: function () {
            if (this.message == null || this.instrument == null) return this.get('strNA');
            return InstrumentUtils.getFormatPrice(this.instrument, this.message.Ask, this.get('strNA'));
        },
        AskSize: function () {
            if (this.message == null || this.instrument == null) return this.get('strNA');
            return InstrumentUtils.getFormatVolume(this.instrument, this.message.AskSize, this.get('displayAmountInLots'), this.get('strNA'));
        },
        Bid: function () {
            if (this.message == null || this.instrument == null) return this.get('strNA');
            return InstrumentUtils.getFormatPrice(this.instrument, this.message.Bid, this.get('strNA'));
        },
        BidSize: function () {
            if (this.message == null || this.instrument == null) return this.get('strNA');
            return InstrumentUtils.getFormatVolume(this.instrument, this.message.BidSize, this.get('displayAmountInLots'), this.get('strNA'));
        },
        Change: function () {
            if (this.message?.Last == null || this.message.Close == null) return this.get('strNA');
            const last = this.message.Last;
            const close = this.message.Close;
            if (isNaN(last) || isNaN(close)) return this.get('strNA');
            return InstrumentUtils.getFormatPrice(this.instrument, last - close, this.get('strNA'));
        },
        ChangePercent: function () {
            if (this.message?.Last == null || this.message.Close == null) return this.get('strNA');
            const last = this.message.Last;
            const close = this.message.Close;
            if (isNaN(last) || isNaN(close)) return this.get('strNA');
            const value = ((last - close) / close) * 100;
            return InstrumentUtils.getFormatPrice(this.instrument, value, this.get('strNA'));
        },
        ChangeStyle: function () {
            if (this.message?.Last == null || this.message.Close == null) return '';
            const last = this.message.Last;
            const close = this.message.Close;
            if (isNaN(last) || isNaN(close) || MathUtils.equalToEpsilon(last, close)) return '';
            const color = last > close ? '#49BE49' : '#FF453A';
            return 'color:' + color + ';';
        },
        High: function () {
            if (this.message == null || this.instrument == null) return this.get('strNA');
            return InstrumentUtils.getFormatPrice(this.instrument, this.message.High, this.get('strNA'));
        },
        Last: function () {
            if (this.message == null || this.instrument == null) return this.get('strNA');
            return InstrumentUtils.getFormatPrice(this.instrument, this.message.Last, this.get('strNA'));
        },
        LastSize: function () {
            if (this.message == null || this.instrument == null) return this.get('strNA');
            return InstrumentUtils.getFormatVolume(this.instrument, this.message.LastSize, this.get('displayAmountInLots'), this.get('strNA'));
        },
        Low: function () {
            if (this.message == null || this.instrument == null) return this.get('strNA');
            return InstrumentUtils.getFormatPrice(this.instrument, this.message.Low, this.get('strNA'));
        },
        Volume: function () {
            if (this.message == null || this.instrument == null) return this.get('strNA');
            return InstrumentUtils.getFormatVolume(this.instrument, this.message.Volume, false, this.get('strNA'));
        }
    }
});
