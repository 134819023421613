// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { TerceraEventLogTemplate } from '../../templates.js';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { WINDOW_SIDE_BORDERS_WIDTH } from '../UtilsClasses/SizeConstants';
import { Control } from './Control';
import { ContainerControl } from './ContainerControl';
import { PanelDisplayMode } from '../../Commons/CustomerAccess/PanelDisplayMode';
import { CustomerAccess } from '../../Commons/CustomerAccess/CustomerAccess';
import { Factory } from '../panels/Factory';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';
import $ from 'jquery';

export class TerceraEventLog extends ContainerControl {
    public eventLogOpen: boolean = false;
    public visible: boolean = true;
    public myEventLog: any;
    public completed: boolean;
    public panelOriginalWidth: number;
    public panelOriginalHeight: number;

    public override getType (): string { return 'TerceraEventLog'; }

    public override oncomplete (): void {
        super.oncomplete();

        WorkSpaceManager.OnWorkSpaceChanged.Subscribe(this.eventLogReinit, this);

        this.on('eventLogClick', () => {
            if (isNullOrUndefined(this.myEventLog) || this.myEventLog?.torndown) {
                this.myEventLog = Factory.createPanel(PanelNames.EventsPanel);
                this.addControl(this.myEventLog);
                this.setEventPanelPos();
                Control.Ticker.Subscribe(this.myEventLog.TickAsync, this.myEventLog);
            }

            if (!this.eventLogOpen) {
                this.myEventLog.set({ visible: true });
                this.setFocus();
                this.eventLogOpen = true;
                this.checkSize();
            } else {
                this.eventLogOpen = false;
            }
        });

        this.observe('this.activeControl', (newValue, oldValue, keypath) => {
            if (newValue != null) {
                this.eventLogOpen = false;
            }
        });

        $(window).on('resize', this.checkSize.bind(this));

        this.completed = true;
    };

    public override lostFocus (): void {
        this.myEventLog.set({ visible: false });
    }

    public eventLogReinit (): void {
        void this.set({ visible: CustomerAccess.GetDisplayMode(PanelNames.EventsPanel) === PanelDisplayMode.Allowed });
    }

    public setEventPanelPos (): void {
        if (isNullOrUndefined(this.panelOriginalWidth)) { // разовое запоминание оригинальных размеров Event Log Panel
            this.panelOriginalWidth = this.myEventLog.get('width');
            this.panelOriginalHeight = this.myEventLog.get('height');
        }
        const panel = this.find('*');
        const m = WINDOW_SIDE_BORDERS_WIDTH;
        const w = panel.offsetLeft + panel.offsetWidth - this.myEventLog.get('width') + m / 2;
        const h = panel.offsetTop - this.myEventLog.get('height') + m / 2;
        this.myEventLog.set({ left: w, top: h });
    }

    public setOriginalSize (): void {
        if (isNullOrUndefined(this.myEventLog)) {
            return;
        }

        this.myEventLog.set('width', this.panelOriginalWidth);
        this.myEventLog.set('height', this.panelOriginalHeight);
        this.setEventPanelPos();
    }

    public checkSize (): void {
        if (isNullOrUndefined(this.myEventLog)) {
            return;
        }

        this.setOriginalSize();
        const top = this.myEventLog.get('top');
        const left = this.myEventLog.get('left');
        const m = WINDOW_SIDE_BORDERS_WIDTH;
        const qt = this.myEventLog.quickTableRactive?.quickTable;

        let newWidth = this.myEventLog.get('width');
        let newHeight = this.myEventLog.get('height');

        if (left < 0) {
            newWidth += left - m / 2;
            this.myEventLog.set('width', newWidth);
        }

        if (top < 0) {
            newHeight += top - m / 2;
            this.myEventLog.set('height', newHeight);
        }

        if (!isNullOrUndefined(qt)) {
            qt.width = newWidth;
            qt.height = newHeight;

            qt.onResize();
            this.myEventLog.quickTableRactive.setSizes();
        }

        this.setEventPanelPos();
    }
}

ContainerControl.extendWith(TerceraEventLog, {
    template: TerceraEventLogTemplate,
    data: function () {
        return {
            tooltip: Resources.getResource('panel.log')
        };
    }
});
