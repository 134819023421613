// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Pen } from '../Graphics';
import { Resources } from '../properties/Resources';

export class LineStyleUtils {
    public static getCBItems (): Array<{ value: number, style: string }> {
        const arr = [];
        if (!Resources.isHidden('chart.tool.line')) {
            arr.push({ value: Pen.csSimpleChart, style: 'js-StyleLine-line' });
        }

        if (!Resources.isHidden('chart.tool.dot')) {
            arr.push({ value: Pen.csDotChart, style: 'js-StyleLine-dot' });
        }

        if (!Resources.isHidden('chart.tool.dotline')) {
            arr.push({ value: Pen.csDotLineChart, style: 'js-StyleLine-dotted_line' });
        }

        if (!Resources.isHidden('chart.tool.IsoDot')) {
            arr.push({ value: Pen.csIsoDotChart, style: 'js-StyleLine-iso_dot' });
        }

        if (!Resources.isHidden('chart.tool.ShapedLine')) {
            arr.push({ value: Pen.csShapedChart, style: 'js-StyleLine-shaped_line' });
        }

        if (!Resources.isHidden('chart.tool.DottedLine')) {
            arr.push({ value: Pen.csShapedDotChart, style: 'js-StyleLine-loosely_dotted_line' });
        }

        if (!Resources.isHidden('chart.tool.Dash')) {
            arr.push({ value: Pen.csShapedDoubleDotChart, style: 'js-StyleLine-Dash' });
        }

        return arr;
    }
}
