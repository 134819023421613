// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectReportMessage } from '../../Utils/DirectMessages/DirectMessagesImport';
import { Connection } from '../Connection';
import { Resources, LOCALE_EN } from '../properties/Resources';
import { OperationType } from '../../Utils/Trading/OperationType';
import { OrderType } from '../../Utils/Trading/OrderType';
import { TIF } from '../../Utils/Trading/OrderTif';
import { OrderTif } from '../../Utils/Trading/OrderTifEnum';
import { AccountType } from '../../Utils/Account/AccountType';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { TradingLockUtils } from '../../Utils/TradingLockUtils';
import { IsAllowed, IsAllowedResponce } from '../IsAllowed';
import { IsAllowedResponceReason } from '../IsAllowedResponceReason';
import { Quantity } from '../../Utils/Trading/Quantity';
import { OrderExecutorUtils } from './OrderExecutorUtils';
import { ConfirmationTypesEnum } from '../../Utils/Trading/ConfirmationTypesEnum';
import { confirmationScreenHandler, editPositionScreenHandler, messageBoxHandler, modifyOrderScreenHandler, positionExerciseRequestScreenHandler, positionsMutualCloseScreenHandler } from '../../Utils/AppHandlers';
import { PositionActionEnum } from '../../Utils/Trading/PositionActionEnum';
import { OrderActionEnum } from '../../Utils/Trading/OrderActionEnum';
import { ApplicationInfo } from '../ApplicationInfo';
import { OrderConfrimation } from './OrderConfirmation';
import { type Account } from '../cache/Account';
import { type Instrument } from '../cache/Instrument';
import { type Position } from '../cache/Position';
import { ClosePositionData } from './ClosePositionData';
import { Enum } from '../../Utils/Enum';
import { type PlacedFrom } from '../../Utils/Trading/PlacedFrom';
import { type Route } from '../cache/Route';
import { type OrderEditBase } from '../cache/OrderParams/order-edit/OrderEditBase';
import { type Order } from '../cache/Order';
import { type MultiplyPlaceOrderWrapper } from './MultiplyPlaceOrderWrapper';
import { type DataCache } from '../DataCache';

export class OrderExecutor {
    public FDataCache: DataCache;

    constructor (dataCache) {
        this.FDataCache = dataCache;
    }

    public static BAD_PARAMETERS = 'bad parameters';

    public async changeOrdersToMarketPromise (orderIdArray, confirm, placedFrom): Promise<any> {
        return await OrderExecutor.processVendorActionPromise(
            function () { this.changeOrdersToMarketWork(orderIdArray, placedFrom); }.bind(this),
            ConfirmationTypesEnum.Modify,
            'panel.orders.menu.ExecuteAsMarket',
            'panel.orders.menu.ExecuteAsMarket.question',
            confirm);
    }

    // TODO. Rename.
    public changeOrdersToMarketWork (orderIdArray, placedFrom): void {
        const vendor = Connection.vendor;
        const orderDict = this.FDataCache.getOrdersById(orderIdArray);
        for (const key in orderDict) {
            const order = orderDict[key];
            // TODO. Refactor. Remove.
            // Can't change position's closing order type to market.
            if (order.isClosingOrder()) {
                continue;
            }

            OrderConfrimation.createRequestEventMessage([order, placedFrom], OrderConfrimation.CHANGE_TO_MARKET); // Generate and add request event(s) to Event Log
            // TODO. Refactor. Use modifyOrder()?
            vendor.changeOrderToMarket(order, placedFrom);
        }
    }

    public async modifyOrderPromiseWithCallbacks (orderEditObj, yesClickCB?, noClickCB?, onlyAction = false): Promise<any> {
        return await OrderExecutor.processOrderEditObjectPromise(
            orderEditObj,
            Connection.vendor.modifyOrder,
            ConfirmationTypesEnum.Modify,
            yesClickCB, noClickCB, onlyAction);
    }

    public async modifyOrderPromise (orderEditObj: OrderEditBase): Promise<any> {
        if (orderEditObj.account.AccountType !== AccountType.MultiAsset) {
            if (!orderEditObj?.valid() || !orderEditObj.validSLTP()) {
                return await Promise.reject(new Error('bad parameters'));
            } else if (!orderEditObj?.valid()) {
                return await Promise.reject(new Error('bad parameters'));
            }
        }

        const reqData = orderEditObj.getDataForRequest();
        const vendorActionCallback = Connection.vendor.modifyOrder.bind(Connection.vendor);
        return await Promise.resolve(vendorActionCallback(reqData));
    }

    public async modifyPositionPromise (positionEditObj, onlyAction = false): Promise<any> {
        return await OrderExecutor.processOrderEditObjectPromise(
            positionEditObj,
            Connection.vendor.modifyPosition,
            ConfirmationTypesEnum.Modify,
            null,
            null,
            onlyAction);
    }

    public async WMmodifyPositionPromise (orderEditObj): Promise<any> {
        if (orderEditObj.account.AccountType !== AccountType.MultiAsset) {
            if (!orderEditObj?.valid() || !orderEditObj.validSLTP()) {
                return await Promise.reject('bad parameters');
            } else if (!orderEditObj?.valid()) {
                return await Promise.reject('bad parameters');
            }
        }

        const reqData = orderEditObj.getDataForRequest();
        // TODO. Ugly.
        Connection.vendor.modifyPosition = Connection.vendor.modifyPosition.bind(Connection.vendor);

        OrderConfrimation.createRequestEventMessage(reqData, OrderConfrimation.PLACE_OR_REPLACE);// Generate and add request event(s) to Event Log
        return await Promise.resolve(Connection.vendor.modifyPosition(reqData));
    }

    public async placeOrderPromise (orderEditObj, confirmationType?, yesCallBack?, noCallBack?, onlyAction = false): Promise<any> {
        return await OrderExecutor.processOrderEditObjectPromise(
            orderEditObj,
            Connection.vendor.placeOrder,
            (confirmationType || ConfirmationTypesEnum.OrderPlace),
            yesCallBack, noCallBack, onlyAction);
    }

    public placeOrderAsync (orderEditObj): any {
        if (!orderEditObj) {
            return Promise.reject(OrderExecutor.BAD_PARAMETERS);
        }

        if (orderEditObj.account.AccountType !== AccountType.MultiAsset && (!orderEditObj.valid() || !orderEditObj.validSLTP())) {
            return Promise.reject(OrderExecutor.BAD_PARAMETERS);
        }

        const requestData = orderEditObj.getDataForRequest();
        return Connection.vendor.placeOrderAsync(requestData);
    }

    public multiplyPlaceOrdersAsync (multiplyOrdersWrapper: MultiplyPlaceOrderWrapper): any {
        return Connection.vendor.multiplyPlaceOrdersAsync(multiplyOrdersWrapper);
    }

    // TODO. Rename. Refactor.
    public static async processOrderEditObjectPromise (
        orderEditObj,
        vendorActionCallback,
        confirmationType,
        yesClickCB = null,
        noClickCB = null,
        onlyAction = false): Promise<any> {
        if (orderEditObj.account.AccountType !== AccountType.MultiAsset) {
            if (!orderEditObj?.valid() || !orderEditObj.validSLTP()) {
                return await Promise.reject('bad parameters');
            } else if (!orderEditObj?.valid()) {
                return await Promise.reject('bad parameters');
            }
        }

        const reqData = orderEditObj.getDataForRequest();
        vendorActionCallback = vendorActionCallback.bind(Connection.vendor);
        if (onlyAction) {
            return await Promise.resolve(vendorActionCallback(reqData));
        }

        const confirmationData = await orderEditObj.getConfirmationData();

        // TODO. Ugly.

        const warningText =
        GeneralSettings.Warnings.needToShowWarning(confirmationData)
            ? OrderExecutor.toWarningText(confirmationData.warningArray)
            : '';

        if (!GeneralSettings.Confirmations.useConfirmation(confirmationType) && !warningText) {
            OrderConfrimation.createRequestEventMessage(reqData, OrderConfrimation.PLACE_OR_REPLACE); // Generate and add request event(s) to Event Log
            if (yesClickCB) {
                yesClickCB();
            }
            return await Promise.resolve(vendorActionCallback(reqData));
        }

        const needToShowWarningForcefully = orderEditObj.needToShowWarningForcefully(); // when warning was shown forcefully it can't be changed in Confirmations Settings #110665
        // TODO. Refactor. Promisify MessageBox.
        return await new Promise(
            function (resolve, reject) {
                const okCallback = (showNextTime) => {
                    GeneralSettings.Confirmations.updateConfirmation(confirmationType, showNextTime);
                    OrderConfrimation.createRequestEventMessage(reqData, OrderConfrimation.PLACE_OR_REPLACE); // Generate and add request event(s) to Event Log
                    if (yesClickCB) { yesClickCB(); }
                    resolve(vendorActionCallback(reqData));
                };

                const cancelCallback = (showNextTime) => {
                    GeneralSettings.Confirmations.updateConfirmation(confirmationType, showNextTime);
                    if (noClickCB) { noClickCB(); }
                    reject('cancell clicked');
                };

                confirmationScreenHandler.Show(
                    confirmationType,
                    reqData,
                    messageBoxHandler.msgType.Question,
                    okCallback,
                    cancelCallback,
                    !needToShowWarningForcefully, // showNextTimeChB parameter (if need to show warning forcefully then no need in showNextTime CheckBox)
                    false,
                    warningText);
            });
    }

    // TODO. Rename. Refactor. Ugly.
    public static async processVendorActionPromise (
        vendorActionCallback,
        confirmationType,
        confirmationHeaderKey,
        confirmationText,
        confirmNeeded = true,
        eventLogRequstHandler?,
        orderDict?): Promise<any> {
        if (!confirmNeeded || !GeneralSettings.Confirmations.useConfirmation(confirmationType)) {
            if (eventLogRequstHandler) {
                eventLogRequstHandler();
            }

            vendorActionCallback();
            return await Promise.resolve(true);
        }

        // TODO. Refactor. Promisify MessageBox.
        return await new Promise(
            function (resolve, reject) {
                const okCallback = (showNextTime): void => {
                    GeneralSettings.Confirmations.updateConfirmation(confirmationType, showNextTime);
                    if (eventLogRequstHandler) {
                        eventLogRequstHandler();
                    }

                    vendorActionCallback();
                    resolve(true);
                };

                const cancelCallback = (showNextTime): void => {
                    resolve(false);
                    GeneralSettings.Confirmations.updateConfirmation(confirmationType, showNextTime);
                };

                if (orderDict == null) {
                    messageBoxHandler.Show(
                        Resources.getResource(confirmationHeaderKey),
                        Resources.getResource(confirmationText),
                        messageBoxHandler.msgType.Question,
                        okCallback,
                        cancelCallback,
                        true,
                        false);
                } else {
                    confirmationScreenHandler.Show(
                        confirmationType,
                        orderDict,
                        messageBoxHandler.msgType.Question,
                        okCallback,
                        cancelCallback,
                        true,
                        false);
                }
            });
    }

    // TODO. Refactor.
    public async cancelOrdersByIdPromise (orderIdArray, confirm, placedFrom): Promise<string> {
        const orderDict = this.FDataCache.getOrdersById(orderIdArray);
        const keys = Object.keys(orderDict);
        const question =
        keys.length === 1
            ? await OrderExecutorUtils.buildCancelOrderConfirmationText(orderDict[keys[0]])
            : Resources.getResource('orders.confirm.all.question1') +
            keys.length +
            Resources.getResource('orders.confirm.all.question2');

        if (keys.length === 0) {
            await Promise.resolve();
            return;
        }

        return await OrderExecutor.processVendorActionPromise(
            () => { this.cancelSelectedOrders(orderDict, placedFrom); },
            ConfirmationTypesEnum.OrderCancel,
            'panel.orders.menu.Remove',
            question,
            confirm,
            null,
            orderDict);
    }

    public async cancelOrdersByAccountPromise (account: Account, confirm, placedFrom): Promise<any> {
        const orderDict = this.FDataCache.getOrdersByAccount(account);
        return await OrderExecutor.processVendorActionPromise(
            () => { this.cancelSelectedOrders(orderDict, placedFrom); },
            ConfirmationTypesEnum.OrderCancel,
            'panel.orders.menu.Remove',
            'chart.menu.cancelByAccount',
            confirm,
            null,
            orderDict);
    }

    public async cancelOrdersByInstrumentPromise (instrument: Instrument, confirm, placedFrom): Promise<any> {
        const orderDict = this.FDataCache.getOrdersByInstrument(instrument);
        return await OrderExecutor.processVendorActionPromise(
            () => { this.cancelSelectedOrders(orderDict, placedFrom); },
            ConfirmationTypesEnum.OrderCancel,
            'panel.orders.menu.Remove',
            'orders.confirm.cancel.question1',
            confirm,
            null,
            orderDict);
    }

    public async cancelOrdersByInstrumentAndAccountPromise (instrument: Instrument, account: Account, confirm, placedFrom): Promise<any> {
        const orderDict = this.FDataCache.getOrdersByInstrumentAndAccount(instrument, account);
        return await OrderExecutor.processVendorActionPromise(
            () => { this.cancelSelectedOrders(orderDict, placedFrom); },
            ConfirmationTypesEnum.OrderCancel,
            'panel.orders.menu.Remove',
            'orders.confirm.cancel.question1',
            confirm,
            null,
            orderDict);
    }

    public async cancelAllOrdersPromise (confirm, placedFrom): Promise<any> {
        const orderDict = this.FDataCache.getAllOrders();
        return await OrderExecutor.processVendorActionPromise(
            () => { this.cancelSelectedOrders(orderDict, placedFrom); },
            ConfirmationTypesEnum.OrderCancel,
            'panel.orders.menu.Remove',
            'orders.confirm.all.title',
            confirm,
            null,
            orderDict);
    }

    // TODO. Rename.
    public cancelSelectedOrders (orderDict, placedFrom): void {
        if (!orderDict) return;

        OrderConfrimation.createRequestEventMessage([orderDict, placedFrom]); // Generate and add request event(s) to Event Log

        const vendor = Connection.vendor;
        for (const key in orderDict) {
            vendor.cancelOrder(orderDict[key], placedFrom);
        }
    }

    public async closePositionsByIdPromise (positionIdArray, confirm, placedFrom): Promise<void> {
        if (confirm &&
        GeneralSettings.Confirmations.useConfirmation(ConfirmationTypesEnum.PositionClose)) {
            for (let i = 0, len = positionIdArray.length; i < len; i++) {
                editPositionScreenHandler.show(positionIdArray[i], placedFrom, null, false);
            }
        } else {
            this.closeSelectedPositions(this.FDataCache.getPositionsById(positionIdArray), placedFrom);
        }

        await Promise.resolve();
    }

    // TODO. Rename.
    public closeSelectedPositions (positionDict, placedFrom, mutualPositionID?): void {
        if (!positionDict) return;

        OrderConfrimation.createRequestEventMessage([positionDict, mutualPositionID, placedFrom]); // Generate and add request event(s) to Event Log

        void Connection.vendor.closePositions(new ClosePositionData(
            positionDict,
            null,
            this.getTifForClosingOrderOrDictionary(positionDict),
            false,
            mutualPositionID),
        placedFrom);
    }

    public closePositionQuantity (position: Position, quantityToClose, placedFrom): void {
        OrderConfrimation.createRequestEventMessage([position, quantityToClose, placedFrom]); // Generate and add request event(s) to Event Log
        void Connection.vendor.closePositions(new ClosePositionData(
            position,
            quantityToClose,
            this.getTifForClosingOrderOrDictionary(position)),
        placedFrom);
    }

    public getTifForClosingOrderOrDictionary (oneOrDict): any {
        const dict = OrderExecutor.convertToPositionsDictionary(oneOrDict);
        const result = {};
        for (const d in dict) {
            result[d] = this.getTifForClosingOrder(dict[d]);
        }

        return result;
    }

    public getTifForClosingOrder (position: Position): TIF {
        const route: Route = this.FDataCache.getRouteByName(position.Route);

        for (const tif of [OrderTif.Day, OrderTif.IOC, OrderTif.GTC, OrderTif.GTD]) {
            if (route?.IsAllowableTif(tif, OrderType.Market)) {
                if (tif === OrderTif.GTD) {
                    const expirationDate = new Date();
                    expirationDate.setUTCFullYear(expirationDate.getUTCFullYear() + 1);
                    return new TIF(tif, expirationDate);
                } else {
                    return new TIF(tif);
                }
            }
        }

        return new TIF(OrderTif.Day);
    }

    public async orderAction (action: number, isFiltered: boolean, selectedOrderIdArray, allOrderIdArray, confirm, placedFrom, numericLinkName?): Promise<void> {
        selectedOrderIdArray = selectedOrderIdArray || [];
        const dc = this.FDataCache;
        const firstSelectedOrderId = selectedOrderIdArray[0] || null;
        const firstSelectedOrder = dc.getOrderById(firstSelectedOrderId);
        const acc = firstSelectedOrder ? firstSelectedOrder.Account : null;
        const ins = firstSelectedOrder ? firstSelectedOrder.Instrument : null;

        const actions = OrderActionEnum;
        switch (action) {
        case actions.ModifyOrder:
            this.orderActionModifyOrder(selectedOrderIdArray, placedFrom, numericLinkName);
            break;
        case actions.All:
            isFiltered ? await this.cancelOrdersByIdPromise(allOrderIdArray, confirm, placedFrom) : await this.cancelAllOrdersPromise(confirm, placedFrom);
            break;
        case actions.ByIds:
            await this.cancelOrdersByIdPromise(selectedOrderIdArray, confirm, placedFrom);
            break;
        case actions.ByAccountAndInstrument:
            await this.cancelOrdersByInstrumentAndAccountPromise(ins, acc, confirm, placedFrom);
            break;
        case actions.ByInstrument:
            await this.cancelOrdersByInstrumentPromise(ins, confirm, placedFrom);
            break;
        case actions.ByAccount:
            await this.cancelOrdersByAccountPromise(acc, confirm, placedFrom);
            break;
        // TODO. Refactor. Confirmation isn't needed for these actions yet.
        case actions.Sell:
            this.cancelSelectedOrders(this.filterOrders(dc.getOrdersBySide(OperationType.Sell), isFiltered, allOrderIdArray), placedFrom);
            break;
        case actions.Buy:
            this.cancelSelectedOrders(this.filterOrders(dc.getOrdersBySide(OperationType.Buy), isFiltered, allOrderIdArray), placedFrom);
            break;
        case actions.Stops:
            this.cancelSelectedOrders(this.filterOrders(dc.getOrdersByType(OrderType.Stop), isFiltered, allOrderIdArray), placedFrom);
            break;
        case actions.Limits:
            this.cancelSelectedOrders(this.filterOrders(dc.getOrdersByType(OrderType.Limit), isFiltered, allOrderIdArray), placedFrom);
            break;
        case actions.Days:
            this.cancelSelectedOrders(this.filterOrders(dc.getOrdersByTIF(OrderTif.Day), isFiltered, allOrderIdArray), placedFrom);
            break;
        case actions.GTCs:
            this.cancelSelectedOrders(this.filterOrders(dc.getOrdersByTIF(OrderTif.GTC), isFiltered, allOrderIdArray), placedFrom);
            break;
        case actions.ChangeToMarket:
            await this.changeOrdersToMarketPromise(selectedOrderIdArray, confirm, placedFrom);
            break;
        default:
            console.error('missing order action');
        }
    }

    private filterOrders (orders: Record<string, Order>, isFiltered: boolean, filteredOrdersId: string[]): Record<string, Order> {
        if (!isFiltered) {
            return orders;
        }

        const filteredOrders: Record<string, Order> = {};
        for (const id of filteredOrdersId) {
            if (!isNullOrUndefined(orders[id])) { filteredOrders[id] = orders[id]; }
        }
        return filteredOrders;
    }

    public orderActionModifyOrder (selectedOrderIdArray, placedFrom, numericLinkName): void {
        const dc = this.FDataCache;
        const len = selectedOrderIdArray.length;
        for (let i = 0; i < len; i++) {
            const orderId = selectedOrderIdArray[i];
            const order = dc.getOrderById(orderId);
            if (!order) continue;

            if (order.Active) {
                modifyOrderScreenHandler.show(orderId, placedFrom, numericLinkName);
            } else {
                editPositionScreenHandler.show(order.PositionId, placedFrom, numericLinkName, true);
            }
        }
    }

    // TODO. Pass PlacedFrom to a vendor.
    public async positionAction (action: number, isFiltered: boolean, selectedPositionIdArray, allPositionsIdArray, confirm, placedFrom, numericLinkName?): Promise<void> {
        selectedPositionIdArray = selectedPositionIdArray || [];
        const dc = this.FDataCache;
        const firstSelectedPositionId = selectedPositionIdArray[0] || null;
        const firstSelectedPos = dc.getPositionById(firstSelectedPositionId);
        const acc = firstSelectedPos ? firstSelectedPos.Account : null;
        const ins = firstSelectedPos ? firstSelectedPos.Instrument : null;

        const actions = PositionActionEnum;
        switch (action) {
        case actions.PositionModifying:
            this.orderActionModifyPosition(selectedPositionIdArray, placedFrom, numericLinkName);
            break;
        case actions.ModifyProductType:
            this.orderActionModifyPosition([firstSelectedPositionId], placedFrom, numericLinkName);
            break;
        case actions.ExerciseOption:
            this.exercisePositionRequestScreenShow(firstSelectedPos, placedFrom);
            break;
        case actions.ExerciseCancel:
            this.exercisePositionCancel(firstSelectedPos, placedFrom);
            break;
        case actions.ByIds:
            await this.closePositionsByIdPromise(selectedPositionIdArray, confirm, placedFrom);
            break;
        case actions.All:
            this.closeSelectedPositions(this.filterPositions(dc.getAllPositions(), isFiltered, allPositionsIdArray), placedFrom);
            break;
        case actions.CLXAll:
            this.closeSelectedPositions(dc.getAllPositions(), placedFrom);
            this.cancelSelectedOrders(dc.getAllOrders(), placedFrom);
            break;
        case actions.CLXByAccountAndInstrument:
            this.closeSelectedPositions(dc.getPositionsByInstrumentAndAccount(ins, acc), placedFrom);
            this.cancelSelectedOrders(dc.getOrdersByInstrumentAndAccount(ins, acc), placedFrom);
            break;
        case actions.ByAccountAndInstrument:
            this.closeSelectedPositions(dc.getPositionsByInstrumentAndAccount(ins, acc), placedFrom);
            break;
        case actions.Short:
            this.closeSelectedPositions(this.filterPositions(dc.getPositionsBySide(OperationType.Sell), isFiltered, allPositionsIdArray), placedFrom);
            break;
        case actions.Long:
            this.closeSelectedPositions(this.filterPositions(dc.getPositionsBySide(OperationType.Buy), isFiltered, allPositionsIdArray), placedFrom);
            break;
        case actions.Negative:
            this.closeSelectedPositions(this.filterPositions(dc.getPositionsByNetPL(false), isFiltered, allPositionsIdArray), placedFrom);
            break;
        case actions.Positive:
            this.closeSelectedPositions(this.filterPositions(dc.getPositionsByNetPL(true), isFiltered, allPositionsIdArray), placedFrom);
            break;
        case actions.Mutual:
            this.mutualClosingPositions(selectedPositionIdArray, placedFrom);
            break;
        case actions.ReverseByInstrument:
            void this.reversePositions(dc.getPositionsByInstrumentAndAccount(ins, acc), placedFrom);
            break;
        case actions.ReverseByIds:
            void this.reversePositions(dc.getPositionsById(selectedPositionIdArray), placedFrom);
            break;
        default:
            console.error('missing position action');
        }
    }

    private filterPositions (positions: Record<string, Position>, isFiltered: boolean, filteredPositionsId: string[]): Record<string, Position> {
        if (!isFiltered) {
            return positions;
        }

        const filteredPositions: Record<string, Position> = {};
        for (const id of filteredPositionsId) {
            if (!isNullOrUndefined(positions[id])) { filteredPositions[id] = positions[id]; }
        }
        return filteredPositions;
    }

    public orderActionModifyPosition (selectedPositionIdArray, placedFrom, numericLinkName): void {
        const len = selectedPositionIdArray.length;
        for (let i = 0; i < len; i++) {
            editPositionScreenHandler.show(selectedPositionIdArray[i], placedFrom, numericLinkName, true);
        }
    }

    public async reversePositions (positions: Record<string, Position>, placedFrom: PlacedFrom): Promise<any[]> {
        const promises = Object.values(positions).map(async position =>
            await this.reversePosition(position, placedFrom)
        );

        return await Promise.all(promises);
    }

    public async reversePosition (position: Position, placedFrom: PlacedFrom): Promise<any> {
        const { DataCache, Account: acc, Instrument: ins } = position;
        OrderConfrimation.createRequestEventMessage([position, placedFrom], OrderConfrimation.REVERSE_POSITION); // Generate and add request event(s) to Event Log

        const data = position.GetOrderEditRequestData();
        data.orderTypeId = OrderType.Market;
        data.tif = this.getTifForClosingOrder(position);
        data.placedFrom = placedFrom;

        if (ins.LastQuote != null) {
            const spreadPlan = DataCache.GetSpreadPlan(acc);
            const bid = ins.LastQuote.BidSpread_SP_Ins(spreadPlan, ins);
            const ask = ins.LastQuote.AskSpread_SP_Ins(spreadPlan, ins);
            const isBuy = position.BuySell === OperationType.Buy;
            data.parameterDict.limitPrice = isBuy ? bid : ask;
            data.parameterDict.stopPrice = isBuy ? ask : bid;
        } else {
            data.parameterDict.limitPrice = position.CurPriceClose;
            data.parameterDict.stopPrice = position.Price;
        }

        return await Connection.vendor.reversePosition(data);
    }

    public async modifyPositionProductType (positionEditObj): Promise<any> {
        const confirm = GeneralSettings.Confirmations.useConfirmation(ConfirmationTypesEnum.Modify);
        const pos = positionEditObj.position;
        let quantity = positionEditObj.getQuantity();
        const newProductType = positionEditObj.getNewProductType();

        if (!pos || pos.ProductType === undefined) {
            return;
        }

        const eventloghandler = () => { this.createRequestEventMessageModifyProductType(positionEditObj); };

        const inLots = GeneralSettings.View.displayAmountInLots();

        quantity = Quantity.toLots(new Quantity(quantity, inLots), pos.Instrument);

        return await OrderExecutor.processVendorActionPromise(
            function () { Connection.vendor.modifyPositionProductType(pos, newProductType, quantity); },
            ConfirmationTypesEnum.Modify,
            'general.trading.confirmation',
            OrderExecutorUtils.buildPositionModifyProductTypeConfirmationText(pos, newProductType, quantity),
            confirm,
            eventloghandler);
    }

    public mutualClosingPositions (positionsIdArray, placedFrom): void {
        positionsMutualCloseScreenHandler.show(positionsIdArray, placedFrom, this.closeSelectedPositions.bind(this));
    }

    public exercisePositionRequestScreenShow (pos: Position | null, placedFrom): void {
        if (!pos) {
            return;
        }

        positionExerciseRequestScreenHandler.show(pos, placedFrom);
    }

    public exercisePositionCancel (pos: Position | null, placedFrom): void {
        if (!pos) {
            return;
        }

        const okCallback = (showNextTime) => {
            this.FDataCache.CancelExerciseByPosition(pos, placedFrom);
        };

        const cancelCallback = (showNextTime) => {
        // -- nothing
        };

        const confirmLocKey = 'screen.PositionExerciseCancelConfirm.text';
        const confirmText = Resources.getResource(confirmLocKey);

        messageBoxHandler.Show(
            '',
            confirmText.replace('{1}', pos.PositionId),
            messageBoxHandler.msgType.Question,
            okCallback,
            cancelCallback,
            false, // show next time cb
            false);
    }

    public createRequestEventMessageModifyProductType (reqData): void {
        const header = Resources.getResource('reports.Product type modification request');
        // let panelId = reqData.placedFrom

        const acc = reqData.account;
        const ins = reqData.instrument;
        const pos = reqData.position;
        const qty = reqData.getQuantity();

        const productType = pos.ProductType;
        const productTypeInitStr = InstrumentUtils.GetLocalizedProductType(ins, productType);
        const productTypeTargetStr = InstrumentUtils.GetLocalizedProductType(ins, reqData.getNewProductType());
        const inLots = GeneralSettings.View.displayAmountInLots();
        const quantity = new Quantity(qty, inLots);

        let qtValue = null;
        if (quantity && ins) {
            qtValue = Quantity.toLots(quantity, ins);
        }

        const user = { Label: 'User', Value: acc ? acc.userLogin : null };
        const account = { Label: 'Account', Value: acc ? acc.FullAccString : null };
        const positionId = { Label: 'Position ID', Value: pos.PositionId != -1 ? pos.PositionId : '' };
        const instrument = { Label: 'Instrument', Value: ins ? ins.DisplayShortName() : '' };
        // route = { Label: 'Route', Value: ''/*obj.Route ? DataCache.getRouteById(obj.Route).Name : (ins ? DataCache.getRouteById(ins.Route).Name : "")*/ },      // #85093
        const amount = { Label: 'Amount', Value: qtValue !== null ? qtValue : '' };
        const time = { Label: 'Time', Value: Date.now().toString() };
        // clientPanelId = { Label: 'Send from', Value: 'reports.' + Utils.getKeyByValue(OrderExecutor.PlacedFrom, panelId) },
        const productTypeInit = { Label: 'Initial product type', Value: productTypeInitStr };
        const productTypeTarget = { Label: 'Target product type', Value: productTypeTargetStr };

        const selectedLabels = [user, account, positionId, instrument, /* route */, amount, productTypeInit, productTypeTarget, /* clientPanelId */, time];

        const resultDataArr = []; const len = selectedLabels.length;
        for (let i = 0; i < len; i++) {
            const lbl = selectedLabels[i];
            if (lbl && (lbl.Value || lbl.Value === 0)) {
                resultDataArr.push([lbl.Label, lbl.Value]);
            }
        }

        const reportMessage = new DirectReportMessage(header, resultDataArr);
        reportMessage.SkipOnTicketViewer = true; // чтобы не показывать в TicketViewer
        reportMessage.Instrument = ins;
        reportMessage.productType = productType;
        reportMessage.account = acc;
        this.FDataCache.NewMessage(reportMessage);
    }

    public createEventMessageProductTypeModified (position: Position, modifiedAmount?): void {
        const header = Resources.getResource('reports.Product type modified');
        // let panelId = reqData.placedFrom
        const acc = position.Account;
        const ins = position.Instrument;

        const productType = position.ProductType;
        const productTypeStr = InstrumentUtils.GetLocalizedProductType(ins, productType);
        const qty = modifiedAmount || position.Amount;
        const quantity = new Quantity(qty, true);

        let qtValue = null;
        if (quantity && ins) {
            qtValue = Quantity.toLots(quantity, ins);
        }

        const user = { Label: 'User', Value: acc ? acc.userLogin : null };
        const account = { Label: 'Account', Value: acc ? acc.FullAccString : null };
        const positionId = { Label: 'Position ID', Value: position.PositionId != -1 ? position.PositionId : '' };
        const instrument = { Label: 'Instrument', Value: ins ? ins.DisplayShortName() : '' };
        // route = { Label: 'Route', Value: ''/*obj.Route ? DataCache.getRouteById(obj.Route).Name : (ins ? DataCache.getRouteById(ins.Route).Name : "")*/ },      // #85093
        const amount = { Label: 'Amount', Value: qtValue !== null ? qtValue : '' };
        // time = { Label: 'Time', Value: Date.now().toString() },
        // clientPanelId = { Label: 'Send from', Value: 'reports.' + Utils.getKeyByValue(OrderExecutor.PlacedFrom, panelId) },
        const productTypeRow = { Label: 'Product type', Value: productTypeStr };

        const selectedLabels = [user, account, positionId, instrument, /* route */, amount, productTypeRow];

        const resultDataArr = []; const len = selectedLabels.length;
        for (let i = 0; i < len; i++) {
            const lbl = selectedLabels[i];
            if (lbl && (lbl.Value || lbl.Value === 0)) {
                resultDataArr.push([lbl.Label, lbl.Value]);
            }
        }

        const reportMessage = new DirectReportMessage(header, resultDataArr);
        // reportMessage.SkipOnTicketViewer = true          // чтобы не показывать в TicketViewer
        reportMessage.Instrument = ins;
        reportMessage.account = acc;
        reportMessage.productType = productType;
        this.FDataCache.NewMessage(reportMessage);
    }

    public static toWarningText (warningArray): string {
        let warningText = '';
        const len = warningArray ? warningArray.length : 0;

        for (let i = 0; i < len; i++) {
            warningText += Resources.getResource(warningArray[i]);
            if (i + 1 < len) { warningText += '\n' + '<br>'; }
        }

        return warningText;
    }

    // TODO. Refactor?
    public getAllowedOrderActionSet (selectedOrderIdArray: string[] = [], allRowsIds: string[] = [], isFiltered: boolean = false): Record<number, IsAllowedResponce> {
        const resActionSet: Record<number, IsAllowedResponce> = {};
        const actions = Enum.TakeKeysFromEnum(OrderActionEnum);

        if (TradingLockUtils.TradingLock.tradingLocked) {
            for (const key of actions) {
                const notAllowedReason = ApplicationInfo.isExploreMode ? IsAllowedResponceReason.NotAllowedByExploreMode : IsAllowedResponceReason.LockTrading;
                resActionSet[OrderActionEnum[key]] = IsAllowedResponce.NotAllowedResponce(notAllowedReason);
            }
            return resActionSet;
        }

        const dc = this.FDataCache;
        const orderDict = isFiltered ? dc.getOrdersById(allRowsIds) : dc.getAllOrders();
        const firstSelectedOrder = dc.getOrderById(selectedOrderIdArray[0]);

        for (const orderId in orderDict) {
            const order = orderDict[orderId];
            if (order.BuySell === OperationType.Buy) {
                resActionSet[OrderActionEnum.Buy] = IsAllowedResponce.AllowedResponce();
            }

            if (order.BuySell === OperationType.Sell) {
                resActionSet[OrderActionEnum.Sell] = IsAllowedResponce.AllowedResponce();
            }

            if (order.OrderType === OrderType.Stop) {
                resActionSet[OrderActionEnum.Stops] = IsAllowedResponce.AllowedResponce();
            }

            if (order.OrderType === OrderType.Limit) {
                resActionSet[OrderActionEnum.Limits] = IsAllowedResponce.AllowedResponce();
            }

            if (order.TimeInForce === OrderTif.GTC) {
                resActionSet[OrderActionEnum.GTCs] = IsAllowedResponce.AllowedResponce();
            }

            if (order.TimeInForce === OrderTif.Day) {
                resActionSet[OrderActionEnum.Days] = IsAllowedResponce.AllowedResponce();
            }
        }

        if (Object.keys(orderDict).length > 0) {
            resActionSet[OrderActionEnum.All] = IsAllowed.IsCloseAllAllowed(false);
        }

        if (!isNullOrUndefined(firstSelectedOrder)) {
            const allowedCancel = IsAllowed.IsOrderCancelingAllowed(firstSelectedOrder);
            resActionSet[OrderActionEnum.ByIds] = allowedCancel;
            resActionSet[OrderActionEnum.ByAccount] = IsAllowed.IsOrderCancelingAllowed(firstSelectedOrder, undefined, undefined, true); // ignoreCheckBySymbol
            resActionSet[OrderActionEnum.ByInstrument] = IsAllowed.IsOrderCancelingAllowed(firstSelectedOrder, undefined, true); // ignoreCheckByAccount
            resActionSet[OrderActionEnum.ByAccountAndInstrument] = allowedCancel;
            resActionSet[OrderActionEnum.ModifyOrder] = IsAllowed.IsOrderModifyingAllowed(firstSelectedOrder);
        }

        const changeToMarket = !(selectedOrderIdArray.length === 0);
        if (changeToMarket) {
            resActionSet[OrderActionEnum.ChangeToMarket] = IsAllowed.IsOrderExecutingAllowed(firstSelectedOrder);
        }

        return resActionSet;
    }

    // TODO. Refactor?
    public getAllowedPositionActionSet (selectedPositionIdArray: string[] = [], allRowsIds: string[] = [], isFiltered: boolean = false): Record<number, IsAllowedResponce> {
        const resActionSet: Record<number, IsAllowedResponce> = {};
        const actions = Enum.TakeKeysFromEnum(PositionActionEnum);

        if (TradingLockUtils.TradingLock.tradingLocked) {
            for (const key of actions) {
                const notAllowedReason = ApplicationInfo.isExploreMode ? IsAllowedResponceReason.NotAllowedByExploreMode : IsAllowedResponceReason.LockTrading;
                resActionSet[PositionActionEnum[key]] = IsAllowedResponce.NotAllowedResponce(notAllowedReason);
            }
            return resActionSet;
        }

        const dc = this.FDataCache;
        const posDict = isFiltered ? dc.getPositionsById(allRowsIds) : dc.getAllPositions();
        const forCLXPosDict = dc.getAllPositions();
        const ordDict = dc.getAllOrders();
        const selectedPosDict = dc.getPositionsById(selectedPositionIdArray);
        const firstSelectedPos = dc.getPositionById(selectedPositionIdArray[0]);

        for (const posId in posDict) {
            const pos = posDict[posId];
            pos.PnLCalculator.NetPL < 0 ? resActionSet[PositionActionEnum.Negative] = IsAllowedResponce.AllowedResponce() : resActionSet[PositionActionEnum.Positive] = IsAllowedResponce.AllowedResponce();

            if (pos.BuySell === OperationType.Buy) {
                resActionSet[PositionActionEnum.Long] = IsAllowedResponce.AllowedResponce();
            }

            if (pos.BuySell === OperationType.Sell) {
                resActionSet[PositionActionEnum.Short] = IsAllowedResponce.AllowedResponce();
            }
        }

        const posLen = Object.keys(posDict).length;
        const CLXPosLen = isFiltered ? Object.keys(forCLXPosDict).length : posLen;
        const ordLen = Object.keys(ordDict).length;

        if (posLen !== 0) {
            resActionSet[PositionActionEnum.All] = IsAllowedResponce.AllowedResponce();
        }

        if ((CLXPosLen !== 0) || (ordLen !== 0)) {
            resActionSet[PositionActionEnum.CLXAll] = IsAllowedResponce.AllowedResponce();
        }

        if (!isNullOrUndefined(firstSelectedPos)) {
            resActionSet[PositionActionEnum.ByIds] = IsAllowed.IsPositionClosingAllowed(firstSelectedPos);
            resActionSet[PositionActionEnum.ByAccountAndInstrument] = IsAllowedResponce.AllowedResponce();
            resActionSet[PositionActionEnum.CLXByAccountAndInstrument] = IsAllowedResponce.AllowedResponce();
            resActionSet[PositionActionEnum.PositionModifying] = IsAllowed.IsPositionModifyingAllowed(firstSelectedPos);
            resActionSet[PositionActionEnum.ModifyProductType] = IsAllowed.IsPositionProductTypeModifyingAllowed(selectedPosDict);

            // return ExerciseCancelAllowStruct
            resActionSet[PositionActionEnum.ExerciseCancel] = IsAllowed.IsPositionExerciseCancelAllowed(selectedPosDict);
            resActionSet[PositionActionEnum.ExerciseCancelVisibility] = IsAllowed.IsPositionExerciseCancelVisible(selectedPosDict);
            resActionSet[PositionActionEnum.ExerciseOption] = IsAllowed.IsPositionExerciseOptionAllowed(selectedPosDict);
        }

        resActionSet[PositionActionEnum.ReverseByInstrument] = IsAllowed.IsPositionReversingAllowed(firstSelectedPos, true); // IsAllowed.IsPositionsReversingAllowed(dc.getPositionsByInstrument(firstSelectedPos?.Instrument));
        resActionSet[PositionActionEnum.ReverseByIds] = IsAllowed.IsPositionsReversingAllowed(selectedPosDict);

        return resActionSet;
    }

    public static getBuySell (bs) {
        bs = bs.toLowerCase();
        if (!bs) {
            return -1;
        }

        switch (bs) {
        case 'buy':
            return OperationType.Buy;
        case 'sell':
            return OperationType.Sell;
        // case "sell short":
        //    return VALUE_SELLSHT;
        }
        if (bs.indexOf('buy') > -1) {
            return OperationType.Buy;
        }

        if (bs.indexOf('sell') > -1) {
            return OperationType.Sell;
        }

        return 0;
    }

    public static getOrderTypeByName (name): number {
        let typeInt = 0;
        name = name.toLowerCase();
        if (OrderExecutor.orderTypeDictionary[name]) {
            typeInt = OrderExecutor.orderTypeDictionary[name];
        }

        return typeInt;
    }

    public static convertToPositionsDictionary (posOrDict): any {
        let dict = {};
        if (posOrDict) {
            if (posOrDict.PositionId) {
                dict[posOrDict.PositionId] = posOrDict;
            } else {
                dict = posOrDict;
            }
        }

        return dict;
    }

    public static createClusterNodeDictionaryByOrders (dict): any {
        const result = {};

        for (const ID in dict) {
            const pos = dict[ID];
            const cN = pos.Account.ClusterNode;

            if (!result[cN]) {
                result[cN] = {};
            }

            result[cN][ID] = pos;
        }

        return result;
    }

    public static orderTypeDictionary = {};
    public static Localize (): void {
        if (Object.keys(OrderExecutor.orderTypeDictionary).length > 0) {
            return;
        }

        OrderExecutor.orderTypeDictionary[Resources.getResourceLang('Limit', LOCALE_EN).toLowerCase()] = OrderType.Limit;
        OrderExecutor.orderTypeDictionary[Resources.getResourceLang('Manual', LOCALE_EN).toLowerCase()] = OrderType.Manual;
        OrderExecutor.orderTypeDictionary[Resources.getResourceLang('Market', LOCALE_EN).toLowerCase()] = OrderType.Market;
        OrderExecutor.orderTypeDictionary[Resources.getResourceLang('OCO', LOCALE_EN).toLowerCase()] = OrderType.OCO;
        OrderExecutor.orderTypeDictionary[Resources.getResourceLang('Stop', LOCALE_EN).toLowerCase()] = OrderType.Stop;
        OrderExecutor.orderTypeDictionary[Resources.getResourceLang('Stop Limit', LOCALE_EN).toLowerCase()] = OrderType.StopLimit;
        OrderExecutor.orderTypeDictionary[Resources.getResourceLang('Tr. stop', LOCALE_EN).toLowerCase()] = OrderType.TrailingStop;
        OrderExecutor.orderTypeDictionary[Resources.getResourceLang('Stop-loss', LOCALE_EN).toLowerCase()] = OrderType.SLTPStop;
        OrderExecutor.orderTypeDictionary[Resources.getResourceLang('Take-profit', LOCALE_EN).toLowerCase()] = OrderType.SLTPLimit;
        OrderExecutor.orderTypeDictionary[Resources.getResourceLang('tr. stop', LOCALE_EN).toLowerCase()] = OrderType.TRStop;
    }
}

Resources.AddStaticLocalization(OrderExecutor.Localize);
