// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Event } from '../../Commons/cache/Event';
import { EventSource, EventType } from '../../Commons/cache/EventConstants';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { Resources } from '../properties/Resources';
import { CustomEvent } from '../../Utils/CustomEvents';
import { ReportMessageImportanceLevel } from '../../Utils/Enums/Constants';
import { BusinessRejectMessagesKey, BusinessRejectTextCodeDict } from '../BusinessRejectUtils/BusinessRejectUtils';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { ORDER_PARAMETER_PRODUCT_TYPE } from '../../Utils/Instruments/ProductType';
import { type DirectReportMessage } from '../../Utils/DirectMessages/DirectReportMessage';

class _EventCache {
    public Cache = [];
    public LastTradingEvent: any = null;

    public ToSerialize: any[] = [];

    public OnAddEvent = new CustomEvent();
    public OnRemoveEvent = new CustomEvent();
    public OnClearEvents = new CustomEvent();

    public Clear (): void {
        this.Cache = [];
        this.LastTradingEvent = null;

        this.ToSerialize = [];

        this.OnClearEvents.Raise();
    }

    public GetEvents (): Event[] {
        return this.Cache.slice();
    }

    public static AddTradingToLog = true;

    public Add (ev: Event): void {
        // +++yura - если операция торговая - добавляем в лог в соответствии с настройкой
        if (ev.EventType === EventType.Trading && !_EventCache.AddTradingToLog) { return; }

        if (ev.EventType === EventType.Trading) { this.LastTradingEvent = ev; }

        this.Cache.push(ev);
        // ставим в очередь на запись
        this.ToSerialize.push(ev);

        this.OnAddEvent.Raise(ev);
    }

    public Save (): any {
        // TODO.
    }

    public AddAndSave (ev): void {
        this.Add(ev);
        this.Save();
    }

    public isDailyLimitExist (ev: Event): boolean {
        if (ev.BusinessRejectCode !== BusinessRejectTextCodeDict[BusinessRejectMessagesKey.DailyLimit]) {
            return false;
        }

        const events = this.GetEvents();
        for (const event of events) {
            if (event.BusinessRejectCode === ev.BusinessRejectCode) {
                return true;
            }
        }

        return false;
    }

    public FromReportMessage (msg: DirectReportMessage): Event {
        let str = '';
        let acc = '';
        // let productTypeString = '';

        const data = msg.Data;
        const len = data.length;
        for (let i = 0; i < len; i++) {
            const row = data[i];

            const cell0 = row[0];
            const cell1 = Resources.getResource(row[1]);

            if (cell0 === 'Account') {
                acc = cell1;
            }

            // if (cell0 === ORDER_PARAMETER_PRODUCT_TYPE) {
            //     productTypeString = cell1;
            // }

            if (!Resources.isHidden('reports.' + cell0)) {
                str += cell0 + ':' + cell1 + ';';
            }
        }

        let source = msg.ImportanceLevel === ReportMessageImportanceLevel.Critical
            ? EventSource.MarginCall
            : EventSource.In;

        if (msg.reject && msg.BusinessRejectCode != 392) {
            source = EventSource.Refused;
        }

        return new Event({
            DateTime: DateTimeUtils.DateTimeUtcNow(),
            Action: Resources.getResource(msg.Name),
            Description: str,
            // TODO. UGLY. Refactor. Remove when BusinessRejectMessage is treated correctly.
            EventType: EventType.Trading,
            EventSource: source,
            Account: acc,
            ProductType: msg.productType,
            // TODO. Ok? Pass instrument id maybe?
            Instrument: msg.Instrument,
            Roundtrip: msg.Roundtrip,
            BusinessRejectCode: msg.BusinessRejectCode
        });
    }

    // #region Logging

    public log (action, description: any = undefined, eventType: any = undefined, eventSource: any = undefined): void {
        if (!action) return;

        this.AddAndSave(new Event({
            DateTime: DateTimeUtils.DateTimeUtcNow(),
            Action: action,
            Description: description,
            EventType: eventType,
            EventSource: eventSource
        }));
    }

    // #endregion
}

export const EventCache = new _EventCache();
