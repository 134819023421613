import { type DirectInstrumentMessage } from '../../Utils/DirectMessages/DirectInstrumentMessage';
import { type InstrDateSettings } from '../../Utils/Instruments/InstrDateSettings';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { type UnderlierOptionInfo } from '../../Utils/Instruments/UnderlierOptionInfo';
import { Instrument } from './Instrument';

export class OptionsCache {
    private readonly FAKE_OPTION_ROUTE = -1;
    private readonly _dataCache: any;
    private readonly _optionsByInstrumentIdRouteId = new Map<string, Map<number, Instrument>>();

    constructor (dataCache) {
        this._dataCache = dataCache;
    }

    public addOption (mess: DirectInstrumentMessage, route: number, underlier: Instrument): void {
        if (mess.TradableRoutes.length === 1 && mess.RoutesSupported.length === 1 &&
            mess.TradableRoutes[0] !== mess.RoutesSupported[0] && route === mess.RoutesSupported[0]) {
            return;
        }

        const optionMessage = structuredClone(mess);
        optionMessage.Name = underlier.ShortName;
        optionMessage.LanguageAliases = Instrument.CloneLangAliases(underlier.FLanguageAliases);
        optionMessage.Descr = underlier.Description;

        const option = this.getOptionInternal(optionMessage.Id, route);
        if (isNullOrUndefined(option)) {
            this.deleteFakeOption(optionMessage.Id);
            this.addOptionInternal(optionMessage, route, underlier, false);
        } else {
            option.UpdateByMessage(optionMessage);
        }
    }

    public addFakeOption (mess: DirectInstrumentMessage, optionInfo: UnderlierOptionInfo, underlier: Instrument): void {
        const instrumentId = optionInfo.instrumentId.toString();
        const option = this.getFirstOptionById(instrumentId);
        if (!isNullOrUndefined(option)) {
            return;
        }
        const fakeOptionMess = structuredClone(mess);
        fakeOptionMess.Id = optionInfo.instrumentId.toString();
        fakeOptionMess.InstrType = InstrumentTypes.OPTIONS;
        if (isValidNumber(optionInfo.instrumentGroupId)) {
            fakeOptionMess.TypeId = optionInfo.instrumentGroupId;
        }
        fakeOptionMess.Name = underlier.ShortName;
        fakeOptionMess.LanguageAliases = Instrument.CloneLangAliases(underlier.FLanguageAliases);
        fakeOptionMess.Descr = underlier.Description;
        this.addOptionInternal(fakeOptionMess, this.FAKE_OPTION_ROUTE, underlier, true);
    }

    public clear (): void {
        this._optionsByInstrumentIdRouteId.clear();
    }

    public getFirstOption (fakeInstrument: Instrument): Instrument {
        const optionInstrument = this.getFirstOptionById(fakeInstrument.Id.toString());
        if (fakeInstrument.ForwardBaseInstrument.isFuturesSymbol) {
            const underlierId = fakeInstrument.ForwardBaseInstrument.Id.toString();
            const underlierTradableId = fakeInstrument.ForwardBaseInstrument.InstrumentTradableID.toString();
            const contractUnderlier = this._dataCache.FindUnderlier(underlierId, optionInstrument.Route, underlierTradableId);
            return this.generateContractOption(optionInstrument, contractUnderlier);
        } else {
            return optionInstrument;
        }
    }

    public getOptionFromCache (instrument: Instrument): Instrument {
        const instrumentId = instrument.Id.toString();
        const route = instrument.Route;
        const option = this.getOptionInternal(instrumentId, route);
        if (isNullOrUndefined(option) || option.isFakeOption) {
            return undefined;
        }
        if (instrument.ForwardBaseInstrument.isFuturesSymbol) {
            const underlierId = instrument.ForwardBaseInstrument.Id.toString();
            const underlierTradableId = instrument.ForwardBaseInstrument.InstrumentTradableID.toString();
            const contractUnderlier = this._dataCache.FindUnderlier(underlierId, option.Route, underlierTradableId);
            if (isNullOrUndefined(contractUnderlier)) {
                return undefined;
            }
            return this.generateContractOption(option, contractUnderlier);
        } else {
            return option;
        }
    }

    public getOptionInstruments (filter: OptionsFilter): Record<string, Instrument> {
        const optionInstruments = {};
        for (const instrumentId of this._optionsByInstrumentIdRouteId.keys()) {
            const instrumentsByRouteId = this._optionsByInstrumentIdRouteId.get(instrumentId);
            for (const route of instrumentsByRouteId.keys()) {
                const instrument = instrumentsByRouteId.get(route);
                let isAddOption = false;
                switch (filter) {
                case OptionsFilter.All:
                    isAddOption = true;
                    break;
                case OptionsFilter.Real:
                    isAddOption = !instrument.isFakeOption;
                    break;
                case OptionsFilter.Fake:
                    isAddOption = instrument.isFakeOption;
                    break;
                }
                if (isAddOption) {
                    if ((instrument.ForwardBaseInstrument.isFuturesSymbol)) {
                        const optionsByContract = this.generateContractOptions(instrument);
                        for (let i = 0; i < optionsByContract.length; i++) {
                            const optionByContract = optionsByContract[i];
                            const id = optionByContract.ForwardBaseInstrument.InstrumentTradableID;
                            optionInstruments[`${id}:${route}`] = optionByContract;
                        }
                    } else {
                        optionInstruments[`${instrumentId}:${route}`] = instrument;
                    }
                }
            }
        }

        return optionInstruments;
    }

    public getFirstOptionInstrument (): Instrument {
        const mapIteratorInstrumentIdKey = this._optionsByInstrumentIdRouteId.keys();
        const firstInstrumentIdKey = mapIteratorInstrumentIdKey.next()?.value;
        if (isNullOrUndefined(firstInstrumentIdKey)) {
            return undefined;
        }
        const mapIteratorRouteIdKey = this._optionsByInstrumentIdRouteId.get(firstInstrumentIdKey).keys();
        const firstRouteIdKey = mapIteratorRouteIdKey.next()?.value;
        if (isNullOrUndefined(firstRouteIdKey)) {
            return undefined;
        }
        return this._optionsByInstrumentIdRouteId.get(firstInstrumentIdKey).get(firstRouteIdKey);
    }

    public getFirstOptionById (instrumentID: string): Instrument {
        if (!isValidString(instrumentID)) {
            return undefined;
        }
        const instrumentsByInstrumentId = this._optionsByInstrumentIdRouteId.get(instrumentID);
        if (isNullOrUndefined(instrumentsByInstrumentId)) {
            return undefined;
        }
        return Array.from(instrumentsByInstrumentId.values())[0];
    }

    private generateContractOptions (option: Instrument): Instrument[] {
        const result: Instrument[] = [];
        const underlier: Instrument = option.ForwardBaseInstrument;

        for (let i = 0; i < underlier.InstrDateSettingsList.length; i++) {
            const instrDateSettingsList: InstrDateSettings = underlier.InstrDateSettingsList[i];
            const contractTradableId = instrDateSettingsList.InstrumentTradableID.toString();
            const contractUnderlier = this._dataCache.FindUnderlier(underlier.Id.toString(), option.Route, contractTradableId);
            result.push(this.generateContractOption(option, contractUnderlier));
        }
        return result;
    }

    private generateContractOption (option: Instrument, contractUnderlier: Instrument): Instrument {
        const instrumentMessage = contractUnderlier.CreateInstrumentMessage();
        instrumentMessage.InstrType = InstrumentTypes.OPTIONS;
        instrumentMessage.Id = option.Id;
        instrumentMessage.TypeId = option.TypeId;
        instrumentMessage.InstrDateSettingsList = [option.InstrDateSettingsList.find((instrDateSettings: InstrDateSettings) => instrDateSettings.UnderlierTradableId === contractUnderlier.InstrumentTradableID)];
        const instrument = new Instrument(this._dataCache, instrumentMessage, option.Route);
        instrument.ForwardBaseInstrument = contractUnderlier;
        instrument.isFakeOption = option.isFakeOption;
        return instrument;
    }

    private addOptionInternal (mess: DirectInstrumentMessage, route: number, underlier: Instrument, isFake: boolean): Instrument {
        const instrumentID = mess.Id;
        let instrumentsByInstrumentId = this._optionsByInstrumentIdRouteId.get(instrumentID);
        if (isNullOrUndefined(instrumentsByInstrumentId)) {
            instrumentsByInstrumentId = new Map<number, Instrument>();
            this._optionsByInstrumentIdRouteId.set(instrumentID, instrumentsByInstrumentId);
        }
        const instrument = new Instrument(this._dataCache, mess, route);
        instrument.ForwardBaseInstrument = underlier;
        instrumentsByInstrumentId.set(route, instrument);
        if (isFake) {
            instrument.isFakeOption = true;
            instrument.isHideRouteMode = true;
        }
        return instrument;
    }

    private getOptionInternal (instrumentId: string, route: number): Instrument {
        if (!isValidString(instrumentId) || !isValidNumber(route)) {
            return undefined;
        }
        const instrumentsByInstrumentId = this._optionsByInstrumentIdRouteId.get(instrumentId);
        if (isNullOrUndefined(instrumentsByInstrumentId)) {
            return undefined;
        }
        return instrumentsByInstrumentId.get(route);
    }

    private deleteFakeOption (instrumentId: string): void {
        if (!isValidString(instrumentId)) {
            return;
        }
        const instrumentsByInstrumentId = this._optionsByInstrumentIdRouteId.get(instrumentId);
        if (isNullOrUndefined(instrumentsByInstrumentId)) {
            return;
        }
        const fakeOption = instrumentsByInstrumentId.get(this.FAKE_OPTION_ROUTE);
        if (isNullOrUndefined(fakeOption)) {
            return;
        }
        instrumentsByInstrumentId.delete(this.FAKE_OPTION_ROUTE);
    }
}

export enum OptionsFilter {
    All,
    Real,
    Fake
}
