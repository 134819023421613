import { type HeatmapAlgorithmData } from './HeatmapAlgorithmData';
import { type DirectAlgorithmHeatmapMessage } from '../../../../Utils/DirectMessages/Algorithm/DirectAlgorithmHeatmapMessage';
import { AlgorithmSubscribeType, AlgorithmType } from '../Algorithm';
import { type Instrument } from '../../Instrument';
import { type DataCache } from '../../../DataCache';

export class HeatmapCache {
    private readonly _dataCache: DataCache;
    private readonly _storageByAlgorithmId = new Map<number, StorageByInstrumentListId>();
    private readonly _subscribers = new Map<number, Array<() => void>>();

    constructor (dataCache: DataCache) {
        this._dataCache = dataCache;
    }

    ProcessAlgorithm (algorithmMessage: DirectAlgorithmHeatmapMessage): void {
        const algorithmId: number = algorithmMessage.AlgorithmId;
        let storageByInstrumentListId: StorageByInstrumentListId = this._storageByAlgorithmId.get(algorithmId);
        if (isNullOrUndefined(storageByInstrumentListId)) {
            storageByInstrumentListId = new StorageByInstrumentListId();
            this._storageByAlgorithmId.set(algorithmId, storageByInstrumentListId);
        }

        let storageByInstrumentTradableId: StorageByInstrumentTradableId = storageByInstrumentListId.get(algorithmMessage.InstrumentListId);
        if (isNullOrUndefined(storageByInstrumentTradableId)) {
            storageByInstrumentTradableId = new StorageByInstrumentTradableId();
            storageByInstrumentListId.set(algorithmMessage.InstrumentListId, storageByInstrumentTradableId);
        }

        for (let i = 0; i < algorithmMessage.HeatmapAlgorithmData.length; i++) {
            const data: HeatmapAlgorithmData = algorithmMessage.HeatmapAlgorithmData[i];
            if (data.IsDelayed) {
                storageByInstrumentTradableId.setDelayedData(data.TradableId, data);
            } else {
                storageByInstrumentTradableId.setRealTimeData(data.TradableId, data);
            }
        }

        const subscribers = this._subscribers.get(algorithmId);
        if (!isNullOrUndefined(subscribers)) {
            for (let i = 0; i < subscribers.length; i++) {
                subscribers[i]();
            }
        }
    }

    Clear (): void {
        this._storageByAlgorithmId.clear();
    }

    processSubscription (instrumentListId: number, isSubscribe: boolean, update: () => void): void {
        const algorithmId = this.findAlgorithmId(instrumentListId);
        if (!isNullOrUndefined(algorithmId)) {
            if (isSubscribe) {
                let subscribers = this._subscribers.get(algorithmId);
                if (isNullOrUndefined(subscribers)) {
                    subscribers = [];
                    this._subscribers.set(algorithmId, subscribers);
                }
                subscribers.push(update);
            } else {
                const subscribers = this._subscribers.get(algorithmId);
                if (!isNullOrUndefined(subscribers)) {
                    for (let i = 0; i < subscribers.length; i++) {
                        if (subscribers[i] === update) {
                            subscribers.splice(i, 1);
                            break;
                        }
                    }
                }
            }
            this._dataCache.AlgorithmCache.SubscriptionHandler(isSubscribe ? AlgorithmSubscribeType.Subscribe : AlgorithmSubscribeType.Unsubscribe, algorithmId, this);
        }
    }

    getData (instrumentListId: number, instrument: Instrument): HeatmapAlgorithmData {
        const algorithmId = this.findAlgorithmId(instrumentListId);
        if (isNullOrUndefined(algorithmId)) {
            return undefined;
        }
        const storageByInstrumentListId: StorageByInstrumentListId = this._storageByAlgorithmId.get(algorithmId);
        if (isNullOrUndefined(storageByInstrumentListId)) {
            return undefined;
        }
        const storageByTradableId: StorageByInstrumentTradableId = storageByInstrumentListId.get(instrumentListId);
        if (isNullOrUndefined(storageByTradableId)) {
            return undefined;
        }

        const entitlmentSystemCache = this._dataCache.EntitlementManager;

        const rt: boolean = entitlmentSystemCache.IsExistSubsctiption(false, instrument.Id, instrument.getInstrumentGroupId(), instrument.TradingExchange);// TODO
        const delayed: boolean = entitlmentSystemCache.IsExistSubsctiption(true, instrument.Id, instrument.getInstrumentGroupId(), instrument.TradingExchange);// TODO

        if (rt) {
            return { ...storageByTradableId.getRealTimeData(instrument.InstrumentTradableID) };
        } else if (delayed) {
            return { ...storageByTradableId.getDelayedData(instrument.InstrumentTradableID) };
        } else {
            return undefined;
        }
    }

    private findAlgorithmId (instrumentListId: number): number {
        if (isNullOrUndefined(instrumentListId)) {
            return undefined;
        }

        const algorithmCache = this._dataCache.AlgorithmCache;
        if (isNullOrUndefined(algorithmCache)) {
            return undefined;
        }
        const algorithms = algorithmCache.getAlgorithmsByAlgorithmType(AlgorithmType.Heatmap);
        for (let i = 0; i < algorithms.length; i++) {
            if (algorithms[i].InstrumentGroupIDs.indexOf(instrumentListId) !== -1) {
                return algorithms[i].AlgorithmId;
            }
        }
        return undefined;
    }
}

class StorageByInstrumentListId {
    private readonly _storageByInstrumentListId = new Map<number, StorageByInstrumentTradableId>();

    get (instrumentListId: number): StorageByInstrumentTradableId {
        return this._storageByInstrumentListId.get(instrumentListId);
    }

    set (instrumentListId: number, storageByInstrumentTradableId: StorageByInstrumentTradableId): void {
        this._storageByInstrumentListId.set(instrumentListId, storageByInstrumentTradableId);
    }
}
class StorageByInstrumentTradableId {
    private readonly _delayedDataByTradableId = new Map<number, HeatmapAlgorithmData>();
    private readonly _realTimeDataByTradableId = new Map<number, HeatmapAlgorithmData>();

    getDelayedData (tradableId: number): HeatmapAlgorithmData {
        return this._delayedDataByTradableId.get(tradableId);
    }

    setDelayedData (tradableId: number, data: HeatmapAlgorithmData): void {
        this._delayedDataByTradableId.set(tradableId, data);
    }

    getRealTimeData (tradableId: number): HeatmapAlgorithmData {
        return this._realTimeDataByTradableId.get(tradableId);
    }

    setRealTimeData (tradableId: number, data: HeatmapAlgorithmData): void {
        this._realTimeDataByTradableId.set(tradableId, data);
    }
}
