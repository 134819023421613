// Copyright TraderEvolution Global LTD. � 2017-2024. All rights reserved.
import { AccountWidgetDragAndDropTemplate } from '../../../templates';
import { type IGroupController } from '../../../Commons/VerticalGroupedPanels/Interfaces/IGroupController';
import { Control } from './../Control';
import { type AccountDetailsGroupItemsEnumType } from '../../../Utils/Enums/AccountDetailsEnum';
import { RowItemDataColorPair, type RowItemDataNumberPair, type RowItemDataStringPair } from '../../../Commons/cache/VerticalPanel/AccountDetails/RowItemData';

export class AccountWidgetDragAndDrop extends Control {
    private readonly LEFTTOPOFFSET: number = 5;
    private _itemType: AccountDetailsGroupItemsEnumType = null;
    private _controller: IGroupController<AccountDetailsGroupItemsEnumType> = null;

    constructor () {
        super();
    }

    public getType (): string { return 'AccountWidgetDragAndDrop'; };

    public setData (itemType: AccountDetailsGroupItemsEnumType, controller: IGroupController<AccountDetailsGroupItemsEnumType>): void {
        this._itemType = itemType;
        this._controller = controller;
        const values = controller.getItemFormattedValue(itemType);
        const colors = this.getColor();

        void this.set({
            HeaderText: controller.getItemName(itemType),
            firstValue: values.firstValue,
            firstValueColor: colors.firstValue,
            secondValue: values.secondValue,
            secondValueColor: colors.secondValue
        });
    }

    private getColor (): RowItemDataColorPair {
        const itemType = this._itemType;
        const controller = this._controller;

        const value: RowItemDataNumberPair = controller.getItemValue(itemType);
        const strValue: RowItemDataStringPair = controller.getItemFormattedValue(itemType);

        if (strValue.isNAString()) {
            return new RowItemDataColorPair(null, controller.getColoringMethod(itemType));
        }

        return new RowItemDataColorPair(value, controller.getColoringMethod(itemType));
    }

    public updatePosition (event): void {
        const evt = event.event;

        this.MoveTo(evt.clientX + this.LEFTTOPOFFSET, evt.clientY + this.LEFTTOPOFFSET);
    }

    private MoveTo (x: number, y: number): void {
        void this.set({
            left: x,
            top: y
        });
    }
}

Control.extendWith(AccountWidgetDragAndDrop, {
    template: AccountWidgetDragAndDropTemplate,
    data: function () {
        return {
            HeaderText: '',
            firstValue: '',
            firstValueColor: '',
            secondValue: '',
            secondValueColor: ''
        };
    }
});
