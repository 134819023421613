// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DataCache } from '../../Commons/DataCache';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { TerceraSideBarButton } from './SideBar/TerceraSideBarButton';

export class AlertsButton extends TerceraSideBarButton {
    override readonly tooltipKey: string = 'screen.Alerts';
    constructor () {
        super();
    }

    getType (): ControlsTypes {
        return ControlsTypes.AlertsButton;
    }

    oncomplete (): void {
        super.oncomplete();
        DataCache.AlertManager.OnAlertAdded.Subscribe(this.onAlertsNumberChanged, this);
        DataCache.AlertManager.OnAlertRemoved.Subscribe(this.onAlertsNumberChanged, this);
    };

    dispose (): void {
        DataCache.AlertManager.OnAlertAdded.UnSubscribe(this.onAlertsNumberChanged, this);
        DataCache.AlertManager.OnAlertRemoved.UnSubscribe(this.onAlertsNumberChanged, this);
        super.dispose();
    }

    onAlertsNumberChanged (): void {
        const count = DataCache.AlertManager.GetActiveCount();
        void super.set('counter', count);
    }
}

TerceraSideBarButton.extendWith(AlertsButton, {});
