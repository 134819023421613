import { type CustomInstrumentListTypeEnum } from '../../Utils/CustomInstrumentList/CustomInstrumentListTypeEnum';
import { type DirectCustomListMessage } from '../../Utils/DirectMessages/DirectCustomListMessage';
import { type AliasesItem } from './AliasesItem';

export class CustomInstrumentList {
    public id: number;
    public name: string;
    public destription: string;
    public imageBytes: number[] | null; // byte[]
    public langAliases: Record<string, AliasesItem> | null;
    public tradableIds: number[] | null;
    public type: CustomInstrumentListTypeEnum;

    constructor (mess: DirectCustomListMessage) {
        this.Update(mess);
    }

    public Update (mess: DirectCustomListMessage): void {
        this.id = mess.Id;
        this.name = mess.Name;
        this.destription = mess.Description;
        this.imageBytes = mess.RawBytes;
        this.langAliases = mess.Aliases;
        this.tradableIds = mess.TradableInstrumentId;
        this.type = mess.ListType;
    }
}
