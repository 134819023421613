// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class EOM extends IndicatorScriptBase {
    public Period: number;
    public Divisor: number;

    private emv: number[] = [];

    constructor () {
        super();
        this.ProjectName = 'Ease of Movement';
        this.Comments = 'Ease of Movement';
        super.SetIndicatorLine('Line', Color.Blue, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.Period = 14;
        super.InputParameter(new InputParameterInteger('Period', 'Period', 0, 1, 9999));

        this.Divisor = 10000;
        super.InputParameter(new InputParameterInteger('Divisor', 'Divisor', 0, 1, 1000000));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return `EOM ${this.Period} ${this.Divisor}`;
    };

    public override getCustomName (): string {
        return 'EOM (' + this.ProjectName + ')';
    };

    public NextBar (): void {
        this.emv.push(0.0);
        super.NextBar();
    }

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count <= 1) { return; };
        const highCurrent = this.CurrentData.GetPrice(PriceType.High, 0);
        const lowCurrent = this.CurrentData.GetPrice(PriceType.Low, 0);
        const highPrevious = this.CurrentData.GetPrice(PriceType.High, 1);
        const lowPrevious = this.CurrentData.GetPrice(PriceType.Low, 1);
        const distanceMoved = ((highCurrent + lowCurrent) / 2) - ((highPrevious + lowPrevious) / 2);
        const boxRatio = (this.CurrentData.Volume(0) / this.Divisor) / (highCurrent - lowCurrent);
        this.emv[this.emv.length - 1] = distanceMoved / boxRatio;
        if (this.CurrentData.Count <= this.Period) { return; };
        let emv = 0;
        for (let i = 0; i < this.Period; i++) { emv += this.emv[this.emv.length - 1 - i]; };

        this.SetValue(0, emv * this.Divisor);
    };
}
