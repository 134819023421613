import { DynProperty } from '../../Commons/DynProperty';

class _DynPropertyGroupComparer {
    public Compare (obj1, obj2): number {
        if (!obj1 || !obj2) {
            return 0;
        }

        // Сначала сортируем по группе
        let result =
            DynPropertyGroupComparer.GetGroupIndex(obj1.group) -
            DynPropertyGroupComparer.GetGroupIndex(obj2.group);

        if (result) {
            return result;
        } else {
            result = String.CompareOrdinal(obj1.group, obj2.group);
        }

        // по sep group
        if (result) {
            return result;
        } else {
            result = String.CompareOrdinal(obj1.separatorGroup, obj2.separatorGroup);
        }

        // по индексу
        if (result) {
            return result;
        } else {
            result = obj1.sortIndex - obj2.sortIndex;
        }

        // Потом по типу
        if (result) {
            return result;
        } else {
            result =
            DynPropertyGroupComparer.GetPropertyIndex(obj1.type) -
            DynPropertyGroupComparer.GetPropertyIndex(obj2.type);
        }

        return result;
    }

    // Индексы для определнных групп - чтобы сортировать
    public GetGroupIndex (group: string): number {
        switch (group) {
        case DynProperty.SCRIPT_PARAMETERS_GROUP:
            return -1;
        case DynProperty.PARAM_GROUP:
            return 0;

        // case DynProperty.GENERAL_OPTIONS_GROUP:
        //     return 5;
        case DynProperty.DATA_STYLE_GROUP:
            return 6;
        case DynProperty.VIEW_GROUP:
            return 7;
        case DynProperty.SOUNDS_GROUP:
            return 10;

        case DynProperty.PORTFOLIO_RETURN_GROUP:
            return 14;
        case DynProperty.ASSET_RETURN_GROUP:
            return 15;
        case DynProperty.PERCENT_SCALE_GROUP:
            return 16;

        // case DynProperty.HOTKEY_GROUP:
        //     return 20;
        case DynProperty.TIME_SCALE_GROUP:
            return 21;
        case DynProperty.PORTFOLIO_BALANCE_SCALE:
        case DynProperty.PRICE_SCALE_GROUP:
            return 22;
        case DynProperty.ALERTS_GROUP:
            return 23;
        case DynProperty.VISUAL_TRADING_GROUP:
            return 24;
        case DynProperty.CHART_OE_GROUP:
            return 25;
        case DynProperty.SEPARATOR_GROUP1:
            return 26;
        case DynProperty.DATA_BOX_GROUP:
            return 27;

        case DynProperty.TRADING_DEFAULTS_GROUP:
            return 30;
        case DynProperty.CONFIRMATIONS_GROUP:
            return 40;
        case DynProperty.WARNINGS_GROUP:
            return 50;

        case DynProperty.SEPARATOR_GROUP2:
            return 55;
        case DynProperty.NOTIFICATIONS_GROUP:
            return 56;

        // case DynProperty.CHART_GROUP:
        //     return 60;
        case DynProperty.FXCELL_GROUP:
            return 70;
            // case DynProperty.SCRIPTS_SUBGROUP:
            //     return 80;

        case DynProperty.SEPARATOR_GROUP3:
            return 85;

        case DynProperty.CONNECTION_GROUP:
            return 90;
            // case DynProperty.PROXY_GROUP:
            //     return 100;

            // case DynProperty.LAYOUT_GROUP:
            //     return 110;

            // case DynProperty.TRADING_GROUP:
            //     return 140;

        case DynProperty.INSTRUMENT_GROUP:
            return 150;

            // case DynProperty.EMULATOR_GROUP:
            //     return 170;

        case DynProperty.VISUAL_GROUP:
            return 180;

            // case DynProperty.ORDER_GROUP:
            //     return 190;

            // case DynProperty.INFORMER_GROUP:
            //     return 200;

            // case DynProperty.PANELS_GROUP:
            //     return 210;

        case DynProperty.OPTION_CHAIN_GROUP:
            return 220;

        case DynProperty.ANALYZER_GROUP:
            return 230;

        case DynProperty.VOLATILITY_LAB_GROUP:
            return 240;

        case DynProperty.ANALYZER_INFO_WINDOW_GROUP:
            return 250;

        default:
            return 1000;
        }
    }

    // Индексы для определнных типов проперти - чтобы сортировать
    public GetPropertyIndex (type: number): number {
        switch (type) {
        case DynProperty.BOOLEAN:
            return 0;

        case DynProperty.FONT:
            return 2;

        case DynProperty.FILENAME:
        case DynProperty.FOLDERNAME:
            return 40;

        case DynProperty.COLOR:
            return 3;

        default:
            return 30;
        }
    }
}

export const DynPropertyGroupComparer = new _DynPropertyGroupComparer();
