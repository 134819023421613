// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraRiskWarningMessageScreenTemplate } from '../../templates.js';
import { TerceraWindowBase } from './TerceraWindowBase';
import { riskWarningMessageScreenHandler } from '../../Utils/AppHandlers';
import { TerceraButtonStyle } from '../../Utils/Enums/ButtonEnums';

export class TerceraRiskWarningMessageScreen extends TerceraWindowBase {
    constructor () { super(); }

    public override getType (): string { return 'TerceraRiskWarningMessageScreen'; }

    public override oninit (): void {
        super.oninit();
        this.on('okClick', this.okClick);
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.center();
    }

    public okClick (): void {
        this.close();
    }

    public static Show (messageInfo): void {
        const scr = new TerceraRiskWarningMessageScreen();

        const userNameText = Resources.getResource(messageInfo.Data[0][0]) + ': ' + messageInfo.Data[0][1];
        const accountNameText = Resources.getResource(messageInfo.Data[1][0]) + ': ' + messageInfo.Data[1][1];
        const msgText = messageInfo.Data[2][1];

        void scr.set({
            header: Resources.getResource(messageInfo.Name),
            okText: Resources.getResource('general.messageBox.ok'),
            UserName: userNameText,
            AccName: accountNameText,
            msgText
        });

        MainWindowManager.MainWindow.addControl(scr);
        scr.setFocus();
    }

    public static RiskWarningMessageScreenHandlerInitialize (): void {
        riskWarningMessageScreenHandler.Show = TerceraRiskWarningMessageScreen.Show;
    }
}

TerceraWindowBase.extendWith(TerceraRiskWarningMessageScreen, {
    data: function () {
        return {
            movable: false,
            showHeader: true,
            showFooter: false,
            resizable: false,
            showFullscreenCloud: true,
            closeBtnVisible: false,

            zIndex: 300,
            width: 400,
            height: 245,
            errorTop: 0,

            header: '',
            okText: '',
            UserName: '',
            AccName: '',
            msgText: '',

            btnStyle: TerceraButtonStyle.Standard
        };
    },
    partials: { bodyPartial: TerceraRiskWarningMessageScreenTemplate }
});
