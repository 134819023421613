// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { TerceraLevel1PanelTemplate } from '../../templates.js';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { Control } from './Control';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { SessionSettings } from '../../Commons/SessionSettings';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Account } from '../../Commons/cache/Account';
import { Level1CalculatorViewModelHelper } from '../../Commons/cache/Level1Utils/Level1CalculatorViewModelHelper';
import { Level1CalculatorUtils } from '../../Commons/cache/Level1Utils/Level1CalculatorUtils';

export class TerceraLevel1Panel extends Control {
    constructor () { super(); }

    public getType (): ControlsTypes {
        return ControlsTypes.TerceraLevel1Panel;
    }

    public override oncomplete (): void {
        super.oncomplete();
        Control.Ticker.Subscribe(this.TickAsync, this);
    }

    public dispose (): void {
        Control.Ticker.UnSubscribe(this.TickAsync, this);
        super.dispose();
    }

    // TODO. Ugly. Optimize.
    public TickAsync (): void {
        const instrument: Instrument = super.get('instrument');
        const account: Account = super.get('account');
        if (isNullOrUndefined(instrument) || isNullOrUndefined(account)) {
            return;
        }

        const strNA = Resources.getResource('general.N_A');
        const sess = SessionSettings;
        const lvl1 = instrument.Level1;
        const instDayInfo = instrument.InstrumentDayInfo;
        const ShowLots = GeneralSettings.View.displayAmountInLots();

        if (isNullOrUndefined(lvl1)) {
            return;
        }

        const itemArray = [];

        const level1Items = super.get('level1Items');
        for (let i = 0; i < level1Items.length; i++) {
            switch (level1Items[i]) {
            case Level1ItemType.Bid:
                itemArray.push(new Level1PanelItem('panel.detail.bid', lvl1.strBid(account, false)));
                break;
            case Level1ItemType.Ask:
                itemArray.push(new Level1PanelItem('panel.detail.ask', lvl1.strAsk(account, false)));
                break;
            case Level1ItemType.Last:
                {
                    const changeColor = Level1CalculatorUtils.getChangeColoring(lvl1.GetChange(account));
                    itemArray.push(new Level1PanelItem('panel.detail.last', lvl1.StrLastPrice(account), Level1CalculatorViewModelHelper.getDesktopColor(changeColor)));
                }
                break;
            case Level1ItemType.Change:
                {
                    const changeColor = Level1CalculatorUtils.getChangeColoring(lvl1.GetChangePercent(account));
                    itemArray.push(new Level1PanelItem('panel.detail.change', lvl1.strChange(account), Level1CalculatorViewModelHelper.getDesktopColor(changeColor)));
                }
                break;
            case Level1ItemType.ChangePercent:
                {
                    const changeColor = Level1CalculatorUtils.getChangeColoring(lvl1.GetChangePercent(account));
                    itemArray.push(new Level1PanelItem('panel.detail.changePr', lvl1.strChangePercent(account), Level1CalculatorViewModelHelper.getDesktopColor(changeColor)));
                }
                break;
            case Level1ItemType.Volume:
                if (!Resources.isHidden('panel.detail.volume')) {
                    itemArray.push(new Level1PanelItem('panel.detail.volume', lvl1.strVolume(ShowLots)));
                }
                break;
            case Level1ItemType.OpenInterest:
                if (!Resources.isHidden('panel.detail.OpenInterest')) {
                    itemArray.push(new Level1PanelItem('panel.detail.OpenInterest', instrument.OpenInterest > 0 ? instrument.OpenInterest : strNA));
                }
                break;
            case Level1ItemType.Open:
                itemArray.push(new Level1PanelItem('panel.detail.open', instDayInfo.strOpen(account)));
                break;
            case Level1ItemType.High:
                itemArray.push(new Level1PanelItem('panel.detail.high', instDayInfo.strHigh(account)));
                break;
            case Level1ItemType.Low:
                itemArray.push(new Level1PanelItem('panel.detail.low', instDayInfo.strLow(account)));
                break;
            case Level1ItemType.PrevClose:
                itemArray.push(new Level1PanelItem('panel.detail.prevClose', lvl1.strPrevClose(account)));
                break;
            case Level1ItemType.CurrentSession:
                if (!Resources.isHidden('panel.detail.CurrentSession')) {
                    itemArray.push(new Level1PanelItem('panel.detail.CurrentSession', instrument.ExchangeSessionName));
                }
                break;
            case Level1ItemType.TradingStatus:
                if (!Resources.isHidden('panel.detail.TradingStatus')) {
                    itemArray.push(new Level1PanelItem('panel.detail.TradingStatus', InstrumentUtils.GetTradingStatus(instrument)));
                }
                break;
            case Level1ItemType.TradedValue:
                if (!Resources.isHidden('panel.detail.TradedValue')) {
                    itemArray.push(new Level1PanelItem('panel.detail.TradedValue', lvl1.strTradedValue));
                }
                break;
            case Level1ItemType.OffExchangeVolume:
                if (!Resources.isHidden('panel.detail.OffExchangeVolume')) {
                    itemArray.push(new Level1PanelItem('panel.detail.OffExchangeVolume', lvl1.strOffExchangeVolume(ShowLots)));
                }
                break;
            case Level1ItemType.OffExchangeValue:
                if (!Resources.isHidden('panel.detail.OffExchangeValue')) {
                    itemArray.push(new Level1PanelItem('panel.detail.OffExchangeValue', lvl1.strOffExchangeValue));
                }
                break;
            case Level1ItemType.NormalMarketSize:
                if (!Resources.isHidden('panel.detail.NormalMarketSize')) {
                    itemArray.push(new Level1PanelItem('panel.detail.NormalMarketSize', lvl1.getNormalMarketSize));
                }
                break;
            case Level1ItemType.LimitHigh:
                if (!Resources.isHidden('panel.detail.Limit.High')) {
                    itemArray.push(new Level1PanelItem('panel.detail.Limit.High', instrument.Limits.HighLimitPriceByMeasureString(sess)));
                }
                break;
            case Level1ItemType.LimitLow:
                if (!Resources.isHidden('panel.detail.Limit.Low')) {
                    itemArray.push(new Level1PanelItem('panel.detail.Limit.Low', instrument.Limits.LowLimitPriceByMeasureString(sess)));
                }
                break;
            case Level1ItemType.AuctionStart:
                if (!Resources.isHidden('panel.detail.CBAuctionStart')) {
                    itemArray.push(new Level1PanelItem('panel.detail.CBAuctionStart', lvl1.strCbAuctionStart(sess)));
                }
                break;
            case Level1ItemType.AuctionEnd:
                if (!Resources.isHidden('panel.detail.AuctionEndTime')) {
                    itemArray.push(new Level1PanelItem('panel.detail.AuctionEndTime', lvl1.strAuctionEndTime));
                }
                break;
            case Level1ItemType.RemainingQty:
                if (!Resources.isHidden('panel.detail.RemainingQty')) {
                    itemArray.push(new Level1PanelItem('panel.detail.RemainingQty', lvl1.strRemainingQty));
                }
                break;
            case Level1ItemType.RemainingQtySide:
                if (!Resources.isHidden('panel.detail.RemainingQtySide')) {
                    itemArray.push(new Level1PanelItem('panel.detail.RemainingQtySide', lvl1.strRemainingQtySide));
                }
                break;
            case Level1ItemType.TotalBuyQty:
                if (!Resources.isHidden('panel.detail.TotalBuyQty')) {
                    const value = lvl1.GetTotalBuyQty();
                    itemArray.push(new Level1PanelItem('panel.detail.TotalBuyQty', !isNaN(value) ? value : strNA));
                }
                break;
            case Level1ItemType.TotalSellQty:
                if (!Resources.isHidden('panel.detail.TotalSellQty')) {
                    const value = lvl1.GetTotalSellQty();
                    itemArray.push(new Level1PanelItem('panel.detail.TotalSellQty', !isNaN(value) ? value : strNA));
                }
                break;
            case Level1ItemType.AvgTradedPrice:
                if (!Resources.isHidden('panel.detail.AvgTradedPrice')) {
                    const value = lvl1.GetAvgTradedPrice();
                    itemArray.push(new Level1PanelItem('panel.detail.AvgTradedPrice', !isNaN(value) ? value : strNA));
                }
                break;
            case Level1ItemType.LastTradedTime:
                if (!Resources.isHidden('panel.detail.LastTradedTime')) {
                    const value = lvl1.GetLastTradedTime();
                    itemArray.push(new Level1PanelItem('panel.detail.LastTradedTime', value !== -1 ? value : strNA));
                }
                break;
            case Level1ItemType.Countdown:
                if (!Resources.isHidden('panel.detail.Countdown')) {
                    itemArray.push(new Level1PanelItem('panel.detail.Countdown', lvl1.StrNextFundingSettlement()));
                }
                break;
            case Level1ItemType.Funding:
                if (!Resources.isHidden('panel.detail.Funding')) {
                    itemArray.push(new Level1PanelItem('panel.detail.Funding', lvl1.GetSpreadedFundingRateValueFormatted(account)));
                }
                break;
            case Level1ItemType.FiftyTwoWeekHighPrice:
                if (!Resources.isHidden('panel.detail.FiftyTwoWeekHighPrice')) {
                    const value = lvl1.GetFiftyTwoWeekHighPrice();
                    itemArray.push(new Level1PanelItem('panel.detail.FiftyTwoWeekHighPrice', !isNaN(value) ? value : strNA));
                }
                break;
            case Level1ItemType.FiftyTwoWeekLowPrice:
                if (!Resources.isHidden('panel.detail.FiftyTwoWeekLowPrice')) {
                    const value = lvl1.GetFiftyTwoWeekLowPrice();
                    itemArray.push(new Level1PanelItem('panel.detail.FiftyTwoWeekLowPrice', !isNaN(value) ? value : strNA));
                }
                break;
            case Level1ItemType.NSEValue:
                if (!Resources.isHidden('panel.detail.NSEValue')) {
                    const value = lvl1.GetNSEValue();
                    itemArray.push(new Level1PanelItem('panel.detail.NSEValue', !isNaN(value) ? value : strNA));
                }
                break;
            case Level1ItemType.DPR:
                if (!Resources.isHidden('panel.detail.DPR')) {
                    const value = lvl1.GetDPRValue();
                    itemArray.push(new Level1PanelItem('panel.detail.DPR', value !== -1 ? value : strNA));
                }
                break;
            case Level1ItemType.LastUpdateTime:
                if (!Resources.isHidden('panel.detail.LastUpdateTime')) {
                    itemArray.push(new Level1PanelItem('panel.detail.LastUpdateTime', lvl1.GetLastUpdate()));
                }
                break;
            case Level1ItemType.BidSource:
                if (!Resources.isHidden('panel.detail.BidSource')) {
                    const value = lvl1.GetBidSourceName();
                    itemArray.push(new Level1PanelItem('panel.detail.BidSource', value !== '-1' ? value : strNA));
                }
                break;
            case Level1ItemType.AskSource:
                if (!Resources.isHidden('panel.detail.AskSource')) {
                    const value = lvl1.GetAskSourceName();
                    itemArray.push(new Level1PanelItem('panel.detail.AskSource', value !== '-1' ? value : strNA));
                }
                break;
            case Level1ItemType.LastSource:
                if (!Resources.isHidden('panel.detail.LastSource')) {
                    const value = lvl1.GetLastSourceName();
                    itemArray.push(new Level1PanelItem('panel.detail.LastSource', value !== '-1' ? value : strNA));
                }
                break;
            }
        }

        if (itemArray.length % 2 !== 0) {
            itemArray.push(new Level1PanelItem('', '', ''));
        }

        const itemMatrix = this.createItemMatrix(itemArray);
        void super.set('itemMatrix', itemMatrix);
    }

    private createItemMatrix (itemArray: Level1PanelItem[]): any[] {
        const rowCount = super.get('rowCount');
        const itemMatrix = [];

        const len = itemArray.length;
        const columnCount = Math.ceil(len / rowCount);

        for (let i = 0; i < len; i++) {
            const item = itemArray[i];

            if ((i + 1) % columnCount === 1) {
                itemMatrix.push([]);
            }

            itemMatrix[itemMatrix.length - 1].push(item);
        }

        return itemMatrix;
    }

    public repopulate (): any { };
}

class Level1PanelItem {
    public label: string;
    public value: any;
    public color: string;

    constructor (key: string, value, color?: string) {
        this.label = Resources.getResource(key);
        this.value = value;
        this.color = color || '';
    }
}

export enum Level1ItemType {
    Bid,
    Ask,
    Last,
    Change,
    ChangePercent,
    Volume,
    OpenInterest,
    Open,
    High,
    Low,
    PrevClose,
    CurrentSession,
    TradingStatus,
    TradedValue,
    OffExchangeVolume,
    OffExchangeValue,
    NormalMarketSize,
    LimitHigh,
    LimitLow,
    AuctionStart,
    AuctionEnd,
    RemainingQty,
    RemainingQtySide,
    TotalBuyQty,
    TotalSellQty,
    AvgTradedPrice,
    LastTradedTime,
    Countdown,
    Funding,
    FiftyTwoWeekHighPrice,
    FiftyTwoWeekLowPrice,
    NSEValue,
    DPR,
    LastUpdateTime,
    BidSource,
    AskSource,
    LastSource,
}

Control.extendWith(TerceraLevel1Panel, {

    template: TerceraLevel1PanelTemplate,
    data: function () {
        return {
            itemMatrix: null,
            account: null,
            instrument: null,
            rowCount: 2,
            level1Items: []
        };
    }
});
