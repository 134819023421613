// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CustodialPlan } from './CustodialPlan';
import { CustomEvent } from '../../Utils/CustomEvents';
import { type Instrument } from './Instrument';
import { type DirectCustodialPlanMessage } from '../../Utils/DirectMessages/DirectCustodialPlanMessage';
import { type CustodialData } from '../../Utils/Instruments/CustodialData';

export class CustodialPlans {
    public UpdateEvent = new CustomEvent();
    public _fCustodialPlans: Record<string, CustodialPlan> = {};

    public GetPlanById (id): CustodialPlan | null {
        return this._fCustodialPlans[id] ?? null;
    }

    public Add (message: DirectCustodialPlanMessage): void {
        let plan = this.GetPlanById(message.Id);
        if (isNullOrUndefined(plan)) {
            plan = this._fCustodialPlans[message.Id] = new CustodialPlan(message.Id);
        }
        plan.Update(message);
        this.UpdateEvent.Raise(plan);
    }

    public GetCustodialDataByInstrument (planId, instr: Instrument | null): CustodialData {
        if (instr === null || planId === null) {
            return null;
        }

        const plan = this.GetPlanById(planId);
        return plan != null && !plan.IsZero() ? plan.GetData(instr) : null;
    }

    public Clear (): void {
        this._fCustodialPlans = {};
    }
}
