// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Account } from '../../Commons/cache/Account';
import { type Instrument } from '../../Commons/cache/Instrument';
import { GeneralSettings } from '../GeneralSettings/GeneralSettings';
import { type ProductType } from '../Instruments/ProductType';
import { MathUtils } from '../MathUtils';

export class Quantity {
    public value: any;
    public inLots: boolean;

    constructor (value, inLots) {
        this.value = value;
        this.inLots = inLots;
    }

    // TODO. Refactor.
    public static toLots (quantityObject: Quantity, instrument: Instrument, acc?: Account, pT?: ProductType): number {
        if (quantityObject.inLots) {
            return quantityObject.value;
        }

        return MathUtils.fixFloatPrecision(
            quantityObject.value / instrument.LotSize,
            instrument.getLotStepPrecision(pT, acc));
    }

    // TODO. Refactor.
    public static convertQuantityValue (quantityObject: Quantity, instrument: Instrument, resultInlots: boolean, acc?: Account, pT?: ProductType): number {
        if (resultInlots) {
            return Quantity.toLots(quantityObject, instrument, acc, pT);
        }

        if (!quantityObject.inLots) {
            return quantityObject.value;
        }

        return MathUtils.fixFloatPrecision(
            quantityObject.value * instrument.LotSize,
            MathUtils.getPrecision(instrument.getLotStep(pT, acc)));
    }

    public toString (instrument: Instrument | null): string {
        if (instrument == null) {
            return this.value.toString();
        }

        const inLots = GeneralSettings.View.displayAmountInLots();
        const result = Quantity.convertQuantityValue(this, instrument, inLots); // no references of method .toString(instrument) were found
        return result.toString();
    }

    public static getQuantityNumericSettings (instrument: Instrument, account: Account, inLots: boolean, productType: ProductType = undefined): QuantityNumericSettings {
        if (isNullOrUndefined(instrument)) {
            return new QuantityNumericSettings(undefined, undefined, undefined, undefined, undefined, undefined);
        }

        const instrumentDefaultSettings = GeneralSettings.InsDefSettingsStorage.GetInstrumentSettings(instrument);

        const minLot = instrument.getMinLot(productType, account);
        const maxLot = instrument.getMaxLot(productType, account);

        const lotsMultiplier = inLots ? 1 : instrument.LotSize;
        let minValue = minLot * lotsMultiplier;

        const prec = instrument.getAmountPrecision(inLots, account, productType);
        if (prec === 0 && Math.round(minValue) <= 0) {
            minValue = 1;
        }

        let value;
        let step;
        let incrementCoefficient;

        if (instrumentDefaultSettings != null) {
            let minDefLotVal = null;
            const defaultLots = instrumentDefaultSettings.DefaultLots;
            const len = defaultLots.length;
            for (let i = 0; i < len; i++) {
                const defLot = defaultLots[i];
                if (!defLot.Selected) continue;

                const defLotVal = defLot.Lots;
                if (minDefLotVal === null || parseFloat(defLotVal) < parseFloat(minDefLotVal)) {
                    minDefLotVal = defLotVal;
                }
            }

            if (minDefLotVal !== null) { value = minDefLotVal * lotsMultiplier; }

            step = instrument.getLotStep(productType, account) * lotsMultiplier;
            incrementCoefficient = instrumentDefaultSettings.UserQuantityCoef;
        } else {
            value = minLot * lotsMultiplier;
            step = instrument.getLotStep(productType, account) * lotsMultiplier;
            incrementCoefficient = 1;
        }

        // +++ http://tp.pfsoft.net/entity/21325
        value = MathUtils.RoundTo(value, step);
        if (value < minValue) {
            value = minValue;
        }
        return new QuantityNumericSettings(value, minValue, maxLot * lotsMultiplier, step, incrementCoefficient, prec);
    }

    public static convertAmountToLots (amountValue, instrument: Instrument, useSettings: boolean = true, acc?: Account, pT?: ProductType): number {
        if (useSettings && GeneralSettings.View.displayAmountInLots()) {
            return amountValue;
        } else {
            return MathUtils.fixFloatPrecision(amountValue / instrument.LotSize, instrument.getLotStepPrecision(pT, acc));
        }
    }

    public static convertLotsToAmount (lotsValue: number, instrument: Instrument, useSettings: boolean = true, acc?: Account, pT?: ProductType): number {
        if (useSettings && GeneralSettings.View.displayAmountInLots()) {
            return lotsValue;
        } else {
            return MathUtils.fixFloatPrecision(lotsValue * instrument.LotSize, MathUtils.getPrecision(instrument.getLotStep(pT, acc)));
        }
    }
}

export class QuantityNumericSettings {
    value: number;
    minValue: number;
    maxValue: number;
    step: number;
    incrementCoefficient: number;
    decimalPrecision: number;

    constructor (value: number, minValue: number, maxValue: number, step: number, incrementCoefficient: number, decimalPrecision: number) {
        this.value = value;
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.step = step;
        this.incrementCoefficient = incrementCoefficient;
        this.decimalPrecision = decimalPrecision;
    }
}
