// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { MathUtils } from '../../../Utils/MathUtils';
import { Intervals } from '../../../Utils/Instruments/Intervals';
import { CreateAlertValueSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { TerceraNumeric } from '../../elements/TerceraNumeric';
import { AlertType } from '../../../Utils/Alert/AlertConstants';
import { NumericUtils } from '../../../Utils/NumericUtils';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { Quantity } from '../../../Utils/Trading/Quantity';
import { type Instrument } from '../../../Commons/cache/Instrument';

export class CreateAlertValueSelector extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'CreateAlertValueSelector'; }

    public override oninit (): void {
        super.oninit();

        this.observe('instrument selectedItem', this.onInstrumentChanged);
        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public onInstrumentChanged (n, o): void {
        const instrument: Instrument = this.get('instrument');
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const Level1 = instrument.Level1;
        if (isNullOrUndefined(Level1)) {
            return;
        }

        const selectedItem = this.get('selectedItem');
        const account = this.get('account');
        const settings = NumericUtils.getNumericsOffsetModeViewParams(instrument);
        let value: number | null = null;
        let allowMinus = true;
        let minValue = settings.step;
        let isTradingNumeric = true;
        let step = settings.step;
        let decimalPrecision = settings.numericsPrec;

        let intervals = Intervals.GetIntervalsFromInstrument(instrument, TerceraNumeric.NUMERIC_MAXVALUE);
        if (isValidArray(intervals)) {
            minValue = intervals[0].Increment;
        }

        if (AlertType.ASK === selectedItem.value) {
            value = Level1.GetAsk(account);
            if (isNaN(value)) {
                value = instrument.PointSize;
            }

            minValue = -TerceraNumeric.NUMERIC_MAXVALUE;
        }

        if (AlertType.BID === selectedItem.value) {
            value = Level1.GetBid(account);
            if (isNaN(value)) {
                value = instrument.PointSize;
            }

            minValue = -TerceraNumeric.NUMERIC_MAXVALUE;
        }

        if (AlertType.LAST === selectedItem.value) {
            value = Level1.GetLastPrice(account);
            if (isNaN(value)) {
                value = instrument.PointSize;
            }

            minValue = -TerceraNumeric.NUMERIC_MAXVALUE;
        }

        if (AlertType.CHANGE === selectedItem.value) {
            value = Level1.GetChangePercent(account);
            if (!isValidNumber(value)) {
                value = 0.01;
            }

            step = 0.01;
            decimalPrecision = 2;
            minValue = -TerceraNumeric.NUMERIC_MAXVALUE;
            intervals = null;
            isTradingNumeric = false;
        }

        if (AlertType.VOLUME === selectedItem.value) {
            value = instrument.TotalVolume;
            const inLots = GeneralSettings.View.displayAmountInLots();
            const qtyNumericSettings = Quantity.getQuantityNumericSettings(instrument, account, inLots);
            step = qtyNumericSettings.step;// instrument.LotStep
            minValue = qtyNumericSettings.step;// minValue = 0;
            decimalPrecision = MathUtils.getPrecision(step);
            intervals = null;
            allowMinus = false;
            if (isNaN(value)) {
                value = minValue;
            }
        }

        void this.set({
            numericValue: value,
            step,
            decimalPrecision,
            allowMinus,
            minValue,
            intervals,
            isTradingNumeric
        });
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public localize (): void {
        void this.set('label', Resources.getResource('screen.Alerts.Label.Value'));
    }
}

ContainerControl.extendWith(CreateAlertValueSelector, {
    template: CreateAlertValueSelectorTemplate,
    data: function () {
        return {
            label: '',
            instrument: null,
            width: 200,

            numericValue: 0.01,
            minValue: 0,
            maxValue: TerceraNumeric.NUMERIC_MAXVALUE,
            step: 0.01,
            decimalPrecision: 2,

            allowMinus: false
        };
    }
});
