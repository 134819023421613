// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OEInstrumentLookupTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Commons/properties/Resources';

export class OEInstrumentLookup extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'OEInstrumentLookup'; }

    public override oninit (): void {
        super.oninit();
        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public override setFocus (): void {
        const enabled: boolean = this.get('enabled');
        if (!enabled) {
            return;
        }
        super.setFocus();
        if (!isNullOrUndefined(this.Controls.TerceraInstrumentLookup)) {
            this.Controls.TerceraInstrumentLookup.setFocus();
        }
    }

    public localize (): void {
        void this.set('label', Resources.getResource('panel.newOrderEntry.instrumentLabel'));
    }
}

ContainerControl.extendWith(OEInstrumentLookup, {
    template: OEInstrumentLookupTemplate,
    data: function () {
        return {
            label: '',
            account: null,
            instrument: null
        };
    }
});
