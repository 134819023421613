// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CrossRateCalculationType } from './CrossRateCalculationType';

export class CrossRateDataValue {
    public IsReverse: boolean = false;
    public Price: number = NaN;
    public Bid: number = NaN;
    public Ask: number = NaN;
    public Bid_Ask: number = NaN;
    public DefaultPriceType: CrossRateCalculationType = CrossRateCalculationType.BID_ASK_2;
}
