// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DataCache } from '../../../DataCache';
import { Account } from '../../../cache/Account';
import { AccountFeature } from '../../../../Utils/Account/AccountFeature';
import { AccountTradeStatus } from '../../../../Utils/Account/AccountTradeStatus';
import { RiskManagementGroupEnum } from '../../../../Utils/Enums/AccountDetailsEnum';
import { GeneralSettings } from '../../../../Utils/GeneralSettings/GeneralSettings';
import { RulesSet } from '../../../../Utils/Rules/RulesSet';
import { BaseAccountGroupController } from '../BaseControllers/BaseAccountGroupController';
import { type IGroupController } from '../../Interfaces/IGroupController';
import { Resources } from '../../../properties/Resources';
import { RowItemDataNumberPair, RowItemDataStringPair } from '../../../cache/VerticalPanel/AccountDetails/RowItemData';
import { VerticalPanelColoringTypes } from '../../VerticalPanelColoringTypes';

export class RiskManagementGroupController<GroupItemsType = RiskManagementGroupEnum> extends BaseAccountGroupController<GroupItemsType> implements IGroupController<GroupItemsType> {
    private _activeStatus: string;
    private _closedStatus: string;
    private _suspendStatus: string;
    private _autoClosedStatus: string;
    private _finraDayTraderPatternStatus: string;
    private _liquidationOnlyStatus: string;
    private _maxGrosslossReachedStatus: string;
    private _maxDayVolumeReachedStatus: string;
    private _disabledByRiskRules: string;
    private _weeklyLostLimit: string;
    private _trailingDrawdownLimit: string;
    private _maxUnrealizedLoss: string;
    private _maxOrdersPerDay: string;
    private _relativeDrawDownLevelReachedStatus: string;
    private _eodTrailingDrawdownReachedStatus: string;
    private _relativeDailyLossLimitReachedStatus: string;
    private _currentLocale: string;

    public getGroupItemsTypes (): GroupItemsType[] {
        return [
            RiskManagementGroupEnum.CurrentStatus,
            // RiskManagementGroupEnum.AllowTradingPrePost,
            RiskManagementGroupEnum.DailyLoss_MaxDailyLossLimit,
            RiskManagementGroupEnum.CurrentOrdersCountPerDay_MaxOrdersPerDay,
            RiskManagementGroupEnum.CurrentPositions_MaxPositions,
            RiskManagementGroupEnum.CurrentPendingOrders_MaxPendingOrders,
            // RiskManagementGroupEnum.MaxPendingOrdersValue,
            RiskManagementGroupEnum.MaxOrderCapital,
            RiskManagementGroupEnum.ProjectedBalance_MaxDrawdownLevel,
            // RiskManagementGroupEnum.MaximumLot,
            // RiskManagementGroupEnum.MaxPositionQtyPerSymbol,
            RiskManagementGroupEnum.AllowOvernightTrading,
            RiskManagementGroupEnum.ProjectedBalance_TrailingDrawdownLevel,
            RiskManagementGroupEnum.CurrentTotalPositionQty_TotalMaxPositionsQty,
            RiskManagementGroupEnum.CurrentWeeklyLoss_MaxWeekLostLimit,
            RiskManagementGroupEnum.UnrealizedLoss_UnrealizedLossLimit,
            RiskManagementGroupEnum.MaxDailyProfit,
            RiskManagementGroupEnum.VolumeLimitForALL,
            RiskManagementGroupEnum.VolumeLimitForFOREX,
            RiskManagementGroupEnum.VolumeLimitForEQUITIES,
            RiskManagementGroupEnum.VolumeLimitForFUTURES,
            RiskManagementGroupEnum.VolumeLimitForOPTIONS,
            RiskManagementGroupEnum.VolumeLimitForEQUITIES_CFD,
            RiskManagementGroupEnum.VolumeLimitForFORWARD,
            RiskManagementGroupEnum.VolumeLimitForCFD_FUTURES,
            RiskManagementGroupEnum.VolumeLimitForINDICIES,
            RiskManagementGroupEnum.VolumeLimitForCRYPTO,
            RiskManagementGroupEnum.VolumeLimitForSPREADBET,
            RiskManagementGroupEnum.VolumeLimitForBOND,
            RiskManagementGroupEnum.VolumeLimitForETF,
            RiskManagementGroupEnum.VolumeLimitForTBILL,
            RiskManagementGroupEnum.VolumeLimitForSPOT,
            RiskManagementGroupEnum.VolumeLimitForCORPORATE,
            RiskManagementGroupEnum.MaxOrderAmount,
            RiskManagementGroupEnum.DayTraderPatternProtection,
            RiskManagementGroupEnum.AvailableDayTrades,
            RiskManagementGroupEnum.ProjectedBalance_MaxRelativeDrawDownLevel,
            RiskManagementGroupEnum.LossLimitPerTrade,
            RiskManagementGroupEnum.EODTrailingDrawdownLevel,
            RiskManagementGroupEnum.DailyLoss_RelativeDailyLossLimit
        ] as GroupItemsType[];
    }

    public getItemValue (type: GroupItemsType): RowItemDataNumberPair {
        const curAccount = this.Account;
        const assetBalance = this.AssetBalance;

        switch (type) {
        case RiskManagementGroupEnum.CurrentStatus:
            return new RowItemDataNumberPair(NaN);
        case RiskManagementGroupEnum.AllowTradingPrePost:
            return new RowItemDataNumberPair(NaN);
        case RiskManagementGroupEnum.DailyLoss_MaxDailyLossLimit:
            return new RowItemDataNumberPair(curAccount.CurrentDailyLoss, curAccount.MaxDailyLossLimit);
        case RiskManagementGroupEnum.CurrentOrdersCountPerDay_MaxOrdersPerDay:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.OpenOrdersNumber, curAccount, assetBalance), DataCache.getRuleNumberValueForAccount(RulesSet.VALUE_MAX_DAY_ORDERS, curAccount, -1));
        case RiskManagementGroupEnum.CurrentPositions_MaxPositions:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.OpenPostionsNumber, curAccount, assetBalance), curAccount.MaxPositions >= 0 ? curAccount.MaxPositions : 0);
        case RiskManagementGroupEnum.CurrentPendingOrders_MaxPendingOrders:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.OpenOrdersNumber, curAccount, assetBalance), curAccount.MaxPendingOrders >= 0 ? curAccount.MaxPendingOrders : 0);
        case RiskManagementGroupEnum.MaxPendingOrdersValue:
            return new RowItemDataNumberPair(NaN);
        case RiskManagementGroupEnum.MaxOrderCapital:
            return new RowItemDataNumberPair(curAccount.MaxOrderCapital !== -1 ? curAccount.MaxOrderCapital : 0);
        case RiskManagementGroupEnum.ProjectedBalance_MaxDrawdownLevel:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.ProjectedBalance, curAccount, assetBalance), curAccount.MaxDrawdownLevel);
        case RiskManagementGroupEnum.MaximumLot:
            return new RowItemDataNumberPair(NaN);
        case RiskManagementGroupEnum.MaxPositionQtyPerSymbol:
            return new RowItemDataNumberPair(NaN);
        case RiskManagementGroupEnum.AllowOvernightTrading:
            return new RowItemDataNumberPair(curAccount.AllowOvernightTrading);
        case RiskManagementGroupEnum.ProjectedBalance_TrailingDrawdownLevel:
            if (curAccount.TrailingDrawdownLevel === -1) {
                return new RowItemDataNumberPair(0, -1);
            } else {
                return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.ProjectedBalance, curAccount, assetBalance), curAccount.TrailingDrawdownLevel);
            }
        case RiskManagementGroupEnum.CurrentTotalPositionQty_TotalMaxPositionsQty:
            return new RowItemDataNumberPair(curAccount.OpenPosAmount, curAccount.TotalMaxPositionsQty);
        case RiskManagementGroupEnum.CurrentWeeklyLoss_MaxWeekLostLimit:{
            if (curAccount.MaxWeeklyLossLimit === -1) {
                return new RowItemDataNumberPair(0, 0);
            } else {
                return new RowItemDataNumberPair(curAccount.CurrentWeeklyLoss, curAccount.MaxWeeklyLossLimit);
            }
        }
        case RiskManagementGroupEnum.UnrealizedLoss_UnrealizedLossLimit:
            if (curAccount.MaxUrealizedLossLimit === -1) {
                return new RowItemDataNumberPair(0);
            } else {
                return new RowItemDataNumberPair(curAccount.ProfitBase > 0 ? 0 : -curAccount.ProfitBase, curAccount.MaxUrealizedLossLimit);
            }
        case RiskManagementGroupEnum.MaxDailyProfit:
            return new RowItemDataNumberPair(null);// 'TODO');// RulesSet.GetRuleValue(RulesSet.VALUE_DAILY_PROFIT_TARGET, Account));
        case RiskManagementGroupEnum.VolumeLimitForALL:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForALL, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForFOREX:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForFOREX, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForEQUITIES:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForEQUITIES, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForFUTURES:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForFUTURES, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForOPTIONS:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForOPTIONS, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForEQUITIES_CFD:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForEQUITIES_CFD, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForFORWARD:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForFORWARD, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForCFD_FUTURES:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForCFD_FUTURES, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForINDICIES:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForINDICIES, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForCRYPTO:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForCRYPTO, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForSPREADBET:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForSPREADBET, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForBOND:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForBOND, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForETF:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForETF, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForTBILL:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForTBILL, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForSPOT:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForSPOT, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForCORPORATE:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.VolumeLimitForCORPORATE, curAccount, assetBalance));
        case RiskManagementGroupEnum.MaxOrderAmount:
            return new RowItemDataNumberPair(curAccount.MaxOrderAmount);
        case RiskManagementGroupEnum.DayTraderPatternProtection:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.DayTraderPatternProtection, curAccount, assetBalance));
        case RiskManagementGroupEnum.AvailableDayTrades:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.AvailableDayTrades, curAccount, assetBalance));
        case RiskManagementGroupEnum.ProjectedBalance_MaxRelativeDrawDownLevel:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.ProjectedBalance, curAccount, assetBalance), Account.GetAccountFeature(AccountFeature.MaxRelativeDrawDownLevel, curAccount, assetBalance));
        case RiskManagementGroupEnum.LossLimitPerTrade:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.LossLimitPerTrade, curAccount, assetBalance));
        case RiskManagementGroupEnum.EODTrailingDrawdownLevel:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.EODTrailingDrawdownLevel, curAccount, assetBalance));
        case RiskManagementGroupEnum.DailyLoss_RelativeDailyLossLimit:
            return new RowItemDataNumberPair(curAccount.CurrentDailyLoss, Account.GetAccountFeature(AccountFeature.RelativeDailyLossLimit, curAccount, assetBalance));

        default: return new RowItemDataNumberPair(NaN);
        }
    }

    public getItemFormattedValue (type: GroupItemsType): RowItemDataStringPair {
        const curAccount = this.Account;
        const assetBalance = this.AssetBalance;
        const value = this.getItemValue(type);

        if (!value.isValid()) {
            return new RowItemDataStringPair('');
        }

        switch (type) {
        case RiskManagementGroupEnum.CurrentStatus:
            return new RowItemDataStringPair(this.getCurrentTradingStatusLocalized(curAccount));
        case RiskManagementGroupEnum.AllowTradingPrePost:
            return new RowItemDataStringPair('');
        case RiskManagementGroupEnum.DailyLoss_MaxDailyLossLimit:
            if (value.secondValue === -1) {
                return new RowItemDataStringPair('');
            } else {
                return new RowItemDataStringPair(assetBalance.formatPrice(value.firstValue), assetBalance.formatPrice(value.secondValue));
            }
        case RiskManagementGroupEnum.CurrentOrdersCountPerDay_MaxOrdersPerDay:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.OpenOrdersNumber, curAccount, assetBalance), value.secondValue > 0 ? value.secondValue.toString() : '');
        case RiskManagementGroupEnum.CurrentPositions_MaxPositions:
            if (value.secondValue > 0) {
                return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.OpenPostionsNumber, curAccount, assetBalance), value.secondValue.toString());
            } else {
                return new RowItemDataStringPair('', '');
            }
        case RiskManagementGroupEnum.CurrentPendingOrders_MaxPendingOrders:
            if (value.secondValue > 0) {
                return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.OpenOrdersNumber, curAccount, assetBalance), value.secondValue.toString());
            } else {
                return new RowItemDataStringPair('');
            }
        case RiskManagementGroupEnum.MaxPendingOrdersValue:
            return new RowItemDataStringPair('');
        case RiskManagementGroupEnum.MaxOrderCapital:
            if (value.firstValue <= 0) {
                return new RowItemDataStringPair('');
            } else {
                return new RowItemDataStringPair(assetBalance.formatPrice(value.firstValue));
            }
        case RiskManagementGroupEnum.ProjectedBalance_MaxDrawdownLevel:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.ProjectedBalance, curAccount, assetBalance), assetBalance.formatPrice(value.secondValue));
        case RiskManagementGroupEnum.MaximumLot:
            return new RowItemDataStringPair('');
        case RiskManagementGroupEnum.MaxPositionQtyPerSymbol:
            return new RowItemDataStringPair('');
        case RiskManagementGroupEnum.AllowOvernightTrading:
            return new RowItemDataStringPair(Resources.getResource(value.firstValue ? 'panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled' : 'panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled'));
        case RiskManagementGroupEnum.ProjectedBalance_TrailingDrawdownLevel:
            if (curAccount.TrailingDrawdownLevel === -1) {
                return new RowItemDataStringPair('', '');
            } else {
                return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.ProjectedBalance, curAccount, assetBalance), assetBalance.formatPrice(value.secondValue));
            }
        case RiskManagementGroupEnum.CurrentTotalPositionQty_TotalMaxPositionsQty:
            if (curAccount.TotalMaxPositionsQty === -1) {
                return new RowItemDataStringPair('', '');
            } else {
                return new RowItemDataStringPair(value.firstValue.toString(), value.secondValue.toString());
            }
        case RiskManagementGroupEnum.CurrentWeeklyLoss_MaxWeekLostLimit:{
            if (curAccount.MaxDailyLossLimit === -1) {
                return new RowItemDataStringPair('', '');
            } else {
                return new RowItemDataStringPair(assetBalance.formatPrice(value.firstValue), assetBalance.formatPrice(value.secondValue));
            }
        }
        case RiskManagementGroupEnum.UnrealizedLoss_UnrealizedLossLimit:
            if (curAccount.MaxUrealizedLossLimit === -1) {
                return new RowItemDataStringPair('', '');
            } else {
                return new RowItemDataStringPair(assetBalance.formatPrice(value.firstValue), assetBalance.formatPrice(value.secondValue));// assetBalance.formatPrice(curAccount.MaxUrealizedLossLimit));
            }
        case RiskManagementGroupEnum.MaxDailyProfit:
            if (value.firstValue != null && value.firstValue >= 0) {
                return new RowItemDataStringPair(assetBalance.formatPrice(value.firstValue));
            } else {
                return new RowItemDataStringPair('');
            }
        case RiskManagementGroupEnum.VolumeLimitForALL:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForALL, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForFOREX:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForFOREX, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForEQUITIES:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForEQUITIES, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForFUTURES:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForFUTURES, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForOPTIONS:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForOPTIONS, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForEQUITIES_CFD:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForEQUITIES_CFD, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForFORWARD:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForFORWARD, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForCFD_FUTURES:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForCFD_FUTURES, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForINDICIES:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForINDICIES, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForCRYPTO:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForCRYPTO, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForSPREADBET:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForSPREADBET, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForBOND:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForBOND, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForETF:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForETF, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForTBILL:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForTBILL, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForSPOT:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForSPOT, curAccount, assetBalance));
        case RiskManagementGroupEnum.VolumeLimitForCORPORATE:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.VolumeLimitForCORPORATE, curAccount, assetBalance));
        case RiskManagementGroupEnum.MaxOrderAmount:
            return new RowItemDataStringPair(curAccount.MaxOrderAmount === -1 ? '' : curAccount.MaxOrderAmount.toString());
        case RiskManagementGroupEnum.DayTraderPatternProtection:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.DayTraderPatternProtection, curAccount, assetBalance));
        case RiskManagementGroupEnum.AvailableDayTrades:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.AvailableDayTrades, curAccount, assetBalance));
        case RiskManagementGroupEnum.ProjectedBalance_MaxRelativeDrawDownLevel:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.ProjectedBalance, curAccount, assetBalance), Account.GetAccountFeatureString(value.secondValue, AccountFeature.MaxRelativeDrawDownLevel, curAccount, assetBalance));
        case RiskManagementGroupEnum.LossLimitPerTrade:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.LossLimitPerTrade, curAccount, assetBalance));
        case RiskManagementGroupEnum.EODTrailingDrawdownLevel:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.EODTrailingDrawdownLevel, curAccount, assetBalance));
        case RiskManagementGroupEnum.DailyLoss_RelativeDailyLossLimit:
            return new RowItemDataStringPair(assetBalance.formatPrice(value.secondValue), Account.GetAccountFeatureString(value.secondValue, AccountFeature.RelativeDailyLossLimit, curAccount, assetBalance));

        default: return new RowItemDataStringPair('');
        }
    }

    public getItemNameKey (type: GroupItemsType): string {
        switch (type) {
        case RiskManagementGroupEnum.CurrentStatus:
            return 'panel.accounts.CurrentStatus';
        case RiskManagementGroupEnum.AllowTradingPrePost:
            return 'panel.accounts.AllowTradingPrePost';
        case RiskManagementGroupEnum.DailyLoss_MaxDailyLossLimit:
            return 'panel.accounts.MaxDailyLossLimit';
        case RiskManagementGroupEnum.CurrentOrdersCountPerDay_MaxOrdersPerDay:
            return 'panel.accounts.MaxOrdersPerDay';
        case RiskManagementGroupEnum.CurrentPositions_MaxPositions:
            return 'panel.accounts.MaxPositions';
        case RiskManagementGroupEnum.CurrentPendingOrders_MaxPendingOrders:
            return 'panel.accounts.MaxPendingOrders';
        case RiskManagementGroupEnum.MaxPendingOrdersValue:
            return 'panel.accounts.MaxPendingOrdersValue';
        case RiskManagementGroupEnum.MaxOrderCapital:
            return 'panel.accounts.MaxOrderCapital';
        case RiskManagementGroupEnum.MaximumLot:
            return 'panel.accounts.MaximumLot';
        case RiskManagementGroupEnum.MaxPositionQtyPerSymbol:
            return 'panel.accounts.MaxPositionQtyPerSymbol';
        case RiskManagementGroupEnum.AllowOvernightTrading:
            return 'panel.accounts.AllowOvernightTrading';
        case RiskManagementGroupEnum.ProjectedBalance_TrailingDrawdownLevel:
            return 'panel.accounts.TrailingDrawdownLevel';
        case RiskManagementGroupEnum.CurrentTotalPositionQty_TotalMaxPositionsQty:
            return 'panel.accounts.TotalMaxPositionsQty';
        case RiskManagementGroupEnum.CurrentWeeklyLoss_MaxWeekLostLimit:
            return 'panel.accounts.MaxWeekLostLimit';
        case RiskManagementGroupEnum.UnrealizedLoss_UnrealizedLossLimit:
            return 'panel.accounts.UnrealizedLossLimit';
        case RiskManagementGroupEnum.MaxDailyProfit:
            return 'panel.accounts.MaxDailyProfit';
        case RiskManagementGroupEnum.VolumeLimitForALL:
            return 'panel.accounts.VolumeLimitForALL';
        case RiskManagementGroupEnum.VolumeLimitForFOREX:
            return 'panel.accounts.VolumeLimitForFOREX';
        case RiskManagementGroupEnum.VolumeLimitForEQUITIES:
            return 'panel.accounts.VolumeLimitForEQUITIES';
        case RiskManagementGroupEnum.VolumeLimitForFUTURES:
            return 'panel.accounts.VolumeLimitForFUTURES';
        case RiskManagementGroupEnum.VolumeLimitForOPTIONS:
            return 'panel.accounts.VolumeLimitForOPTIONS';
        case RiskManagementGroupEnum.VolumeLimitForEQUITIES_CFD:
            return 'panel.accounts.VolumeLimitForEQUITIES_CFD';
        case RiskManagementGroupEnum.VolumeLimitForFORWARD:
            return 'panel.accounts.VolumeLimitForFORWARD';
        case RiskManagementGroupEnum.VolumeLimitForCFD_FUTURES:
            return 'panel.accounts.VolumeLimitForCFD_FUTURES';
        case RiskManagementGroupEnum.VolumeLimitForINDICIES:
            return 'panel.accounts.VolumeLimitForINDICIES';
        case RiskManagementGroupEnum.VolumeLimitForCRYPTO:
            return 'panel.accounts.VolumeLimitForCRYPTO';
        case RiskManagementGroupEnum.VolumeLimitForSPREADBET:
            return 'panel.accounts.VolumeLimitForSPREADBET';
        case RiskManagementGroupEnum.VolumeLimitForBOND:
            return 'panel.accounts.VolumeLimitForBOND';
        case RiskManagementGroupEnum.VolumeLimitForETF:
            return 'panel.accounts.VolumeLimitForETF';
        case RiskManagementGroupEnum.VolumeLimitForTBILL:
            return 'panel.accounts.VolumeLimitForTBILL';
        case RiskManagementGroupEnum.VolumeLimitForSPOT:
            return 'panel.accounts.VolumeLimitForSPOT';
        case RiskManagementGroupEnum.VolumeLimitForCORPORATE:
            return 'panel.accounts.VolumeLimitForCORPORATE';
        case RiskManagementGroupEnum.MaxOrderAmount:
            return 'panel.accounts.MaxOrderAmount';
        case RiskManagementGroupEnum.DayTraderPatternProtection:
            return 'panel.accounts.DayTraderPatternProtection';
        case RiskManagementGroupEnum.AvailableDayTrades:
            return 'panel.accounts.AvailableDayTrades';
        case RiskManagementGroupEnum.ProjectedBalance_MaxRelativeDrawDownLevel:
            return 'panel.accounts.MaxRelativeDrawDownLevel';
        case RiskManagementGroupEnum.LossLimitPerTrade:
            return 'panel.accounts.LossLimitPerTrade';
        case RiskManagementGroupEnum.EODTrailingDrawdownLevel:
            return 'panel.accounts.EODTrailingDrawdownLevel';
        case RiskManagementGroupEnum.DailyLoss_RelativeDailyLossLimit:
            return 'panel.accounts.RelativeDailyLossLimit';
        case RiskManagementGroupEnum.ProjectedBalance_MaxDrawdownLevel:
            return 'panel.accounts.MaxDrawdownLevel';
        }
    }

    public isHiddenItemRow (type: GroupItemsType): boolean {
        const itemNameKey = this.getItemNameKey(type);
        if (Resources.isHidden(itemNameKey)) {
            return true;
        }

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_EXT_RISK)) {
            return true;
        }

        const account = this.Account;
        if (type === RiskManagementGroupEnum.DayTraderPatternProtection ||
            type === RiskManagementGroupEnum.AvailableDayTrades) {
            return !account.DayTraderPattern;
        }
    }

    private getCurrentTradingStatusLocalized (acc: Account): string {
        const curlocale = GeneralSettings.General.Language;
        if (curlocale !== this._currentLocale) {
            this.localize(curlocale);
        }

        let status = '';
        switch (acc.AccountTradeStatus) {
        case AccountTradeStatus.ACTIVE_STATUS:
            status = this._activeStatus;
            break;

        case AccountTradeStatus.CLOSED_STATUS:
            status = this._closedStatus;
            break;

        case AccountTradeStatus.SUSPEND_STATUS:
            status = this._suspendStatus;
            break;
        case AccountTradeStatus.TRADING_DISABLED_RISK_STATUS:
            status = this._autoClosedStatus;
            break;

        case AccountTradeStatus.FINRA_DAY_TRADER_PATTERN_STATUS:
            status = this._finraDayTraderPatternStatus;
            break;

        case AccountTradeStatus.LIQUIDATION_ONLY_STATUS:
            status = this._liquidationOnlyStatus;
            break;
        }

        let reason = '';
        switch (acc.StopTradingReason) {
        case Account.STOP_TRADING_NONE:
        case Account.STOP_TRADING_UNKNOWN:
            reason = '---';
            break;

        case Account.STOP_TRADING_MAX_LOSS:
            reason = this._maxGrosslossReachedStatus;
            break;

        case Account.STOP_TRADING_MAX_DAY_VOL:
            reason = this._maxDayVolumeReachedStatus;
            break;

        case Account.STOP_TRADING_WEEKLY_LOST_LIMIT:
            reason = this._weeklyLostLimit;
            break;

        case Account.STOP_TRADING_TRAILING_DRAWDOWN_LIMIT:
            reason = this._trailingDrawdownLimit;
            break;

        case Account.STOP_TRADING_MAX_UNREALIZED_LOSS:
            reason = this._maxUnrealizedLoss;
            break;

        case Account.STOP_TRADING_MAX_ORDERS_COUNT_PER_DAY:
            reason = this._maxOrdersPerDay;
            break;

        case Account.STOP_TRADING_RELATIVE_DRAWDOWN_LEVEL:
            reason = this._relativeDrawDownLevelReachedStatus;
            break;

        case Account.STOP_TRADING_EOD_TRAILING_DRAWDOWN:
            reason = this._eodTrailingDrawdownReachedStatus;
            break;

        case Account.STOP_TRADING_RELATIVE_DAILY_LOSS_LIMIT:
            reason = this._relativeDailyLossLimitReachedStatus;
            break;
        }

        if (acc.AccountTradeStatus === AccountTradeStatus.TRADING_DISABLED_RISK_STATUS) {
            if (!status) {
                status = reason;
            } else if (reason !== '---') // #34231
            {
                status += ' : ' + reason;
            } else {
                status = this._disabledByRiskRules;
            }
        }

        return status;
    }

    private localize (locale: string): void {
        this._activeStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Active');
        this._closedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed');
        this._suspendStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend');
        this._autoClosedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed');
        this._finraDayTraderPatternStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.FinraDayTraderStatus');
        this._liquidationOnlyStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.LiquidationOnlyStatus');
        this._maxGrosslossReachedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached');
        this._maxDayVolumeReachedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached');
        this._disabledByRiskRules = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules');
        this._weeklyLostLimit = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached');
        this._trailingDrawdownLimit = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached');
        this._maxUnrealizedLoss = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached');
        this._maxOrdersPerDay = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules.maxOrdersPerDay');
        this._relativeDrawDownLevelReachedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Max relative drawdown level was reached');
        this._eodTrailingDrawdownReachedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.EOD trailing drawdown level was reached');
        this._relativeDailyLossLimitReachedStatus = Resources.getResource('panel.accounts.accountDetails.RiskManagement.TradingStatus.Relative daily loss limit was reached');
        this._currentLocale = locale;
    }

    public getColoringMethod (type: GroupItemsType): VerticalPanelColoringTypes {
        return VerticalPanelColoringTypes.Default;
    }
}
