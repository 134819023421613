// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { PammBase } from '../../../Commons/cache/PammAccount';
import { Resources } from '../../../Commons/properties/Resources';
import { QuickTreeNode } from '../QuickTree/QuickTreeNode';
import { TerceraLookupDropDownForm } from './TerceraLookupDropDownForm';
import { ThemeManager } from '../../misc/ThemeManager';
import { RulesSet } from '../../../Utils/Rules/RulesSet';
import { TerceraAccountLookupDropDownFormTemplate } from '../../../templates.js';
import { DataCache } from '../../../Commons/DataCache';
import { type LookupDropDownShowParams } from '../../UtilsClasses/LookupDropDownShowParams';
import { type Account } from '../../../Commons/cache/Account';

export class TerceraAccountLookupDropDownForm extends TerceraLookupDropDownForm {
    public iconUser: any = null;
    public iconUserChecked: any = null;
    public iconUserAccount: any = null;
    public iconUserAccountChecked: any = null;
    public iconUserLinkedAccounts: any = null;
    public iconUserLinkedAccountsChecked: any = null;
    public iconFundOpen: any = null;
    public iconFundOpenChecked: any = null;
    public iconFundClosed: any = null;
    public iconFundClosedChecked: any = null;
    public noRaiseSelectAll: boolean = false;
    public modBtnText: string = 'Select';
    public iconUserGroup: string;
    public iconUserGroupChecked: string;
    public chBoxSelectAll: any;

    public override getType (): string { return 'TerceraAccountLookupDropDownForm'; };

    public override oninit (): void {
        super.oninit();
        this.on('onCheckedStateChange', this.onCheckAllStateChange);
    }

    public override initializationComponents (): void {
        this.textBox = this.Controls.textBox;
        this.chBoxSelectAll = this.Controls.chBoxSelectAll;

        const qtr = this.Controls.quickTree;
        if (!isNullOrUndefined(qtr?.quickTree)) {
            this.quickTree = qtr;
        }
    }

    public static async ShowForm (parametersStruct: LookupDropDownShowParams): Promise<void> {
        if (MainWindowManager.TerceraAccountLookupDropDownForm == null) {
            MainWindowManager.TerceraAccountLookupDropDownForm = await TerceraAccountLookupDropDownForm.createInstance();
        }

        void MainWindowManager.TerceraAccountLookupDropDownForm.showForm(parametersStruct);
    }

    public override fillTree (): void {
        const iconUser = this.iconUser;
        const iconUserChecked = this.iconUserChecked;
        const iconUserAccount = this.iconUserAccount;
        const iconUserAccountChecked = this.iconUserAccountChecked;
        const iconUserLinkedAccounts = this.iconUserLinkedAccounts;
        const iconUserLinkedAccountsChecked = this.iconUserLinkedAccountsChecked;
        const iconFundOpen = this.iconFundOpen;
        const iconFundOpenChecked = this.iconFundOpenChecked;
        const iconFundClosed = this.iconFundClosed;
        const iconFundClosedChecked = this.iconFundClosedChecked;
        const iconUserGroup = this.iconUserGroup;
        const iconUserGroupChecked = this.iconUserGroupChecked;

        const items = this.items;
        let selectedItem = [];
        if (Array.isArray(this.selectedItem)) {
            selectedItem = this.selectedItem;
        } else {
            selectedItem.push(this.selectedItem);
        }

        const selectedNodes = [];

        const nodeArr = [];
        const groupNodeDict = {};
        const userNodeDict = {};

        const loggedInUser = DataCache.UserLogin;

        const linkedAccountsNode = new QuickTreeNode(
            Resources.getResource('TerceraAccountLookup.Linked accounts'));
        linkedAccountsNode.collapsed = false;
        // #region Design Stuff
        linkedAccountsNode.defaultImage = iconUserLinkedAccounts;
        linkedAccountsNode.selectedImage = iconUserLinkedAccountsChecked;
        // #endregion

        const notLinkedFont = ThemeManager.CurrentTheme.quickTreeNodeFont.copy();
        notLinkedFont.FontWeight = 'bold';

        const isAdmin = RulesSet.TERMINAL_HTML;

        const len = items.length;
        for (let i = 0; i < len; i++) {
            const account = items[i];
            if (!this.showMAM && account.isMAM) { continue; }
            const userLogin = account.UserLogin;
            const groupName = account.UserGroupName;
            const accountToString = account.toString(true);

            // Filtering.
            if (this.currentNameFilter !== null) {
                const curItemFindStr = accountToString.toLowerCase();
                const filterString = this.currentNameFilter.toLowerCase();
                if (curItemFindStr.indexOf(filterString) === -1) {
                    continue;
                }
            }

            const accountNode = new QuickTreeNode(accountToString);

            const isAccountItemAll = selectedItem[0]?._AccountItemAll;
            if (selectedItem.includes(account) || isAccountItemAll) {
                selectedNodes.push(accountNode);
            }

            accountNode.tag = account;
            accountNode.visible = !account.isHide;
            // #region Design Stuff
            const fundType = account.fundType;
            if (fundType === PammBase.FUND_TYPE_OPEN) {
                accountNode.defaultImage = iconFundOpen;
                accountNode.selectedImage = iconFundOpenChecked;
            } else if (fundType === PammBase.FUND_TYPE_CLOSE) {
                accountNode.defaultImage = iconFundClosed;
                accountNode.selectedImage = iconFundClosedChecked;
            } else {
                accountNode.defaultImage = iconUserAccount;
                accountNode.selectedImage = iconUserAccountChecked;
            }
            if (!account.isLinked && userLogin === loggedInUser) {
                accountNode.font = notLinkedFont;
                accountNode.fontColor = ThemeManager.CurrentTheme.TreeSelection_Fill;
            }
            // #endregion

            let groupNode = null;
            if (isAdmin && groupName) {
                groupNode = groupNodeDict[groupName];
                if (!groupNode) {
                    groupNode = new QuickTreeNode(groupName);
                    groupNode.collapsed = false;
                    groupNode.defaultImage = iconUserGroup;
                    groupNode.selectedImage = iconUserGroupChecked;

                    groupNodeDict[groupName] = groupNode;
                    nodeArr.push(groupNode);
                }
            }

            if (account.isLinked) {
                linkedAccountsNode.AddChildNode(accountNode);
            } else if (userLogin) {
                let userNode = userNodeDict[userLogin];
                if (isNullOrUndefined(userNode)) {
                    userNode = new QuickTreeNode(userLogin);
                    userNode.collapsed = false;

                    userNodeDict[userLogin] = userNode;
                    if (!isNullOrUndefined(groupNode)) {
                        groupNode.AddChildNode(userNode);
                    } else {
                        nodeArr.push(userNode);
                    }

                    // #region Design Stuff
                    if (userLogin === loggedInUser) {
                        userNode.font = notLinkedFont;
                        userNode.fontColor = ThemeManager.CurrentTheme.TreeSelection_Fill;
                        userNode.defaultImage = iconUser;
                        userNode.selectedImage = iconUserChecked;
                    }
                // #endregion
                }

                userNode.AddChildNode(accountNode);
            } else {
                nodeArr.push(accountNode);
            }
        }

        TerceraAccountLookupDropDownForm.sortNodes(nodeArr);

        if (linkedAccountsNode.childNodes.length > 0) {
            nodeArr.push(linkedAccountsNode);
        }

        this.quickTree.quickTree.Clear();
        if (nodeArr.length === 1) {
            this.quickTree.quickTree.AddNodeRange(nodeArr[0].childNodes);
        } else {
            this.quickTree.quickTree.AddNodeRange(nodeArr);
        }

        const ims = this.quickTree.quickTree.isMultiSelectCheckboxUsing;
        for (let i = 0; i < selectedNodes.length; i++) {
            if (ims) {
                this.quickTree.quickTree.setCheckedNode(selectedNodes[i]);
            } else {
                this.quickTree.quickTree.setSelectedNode(selectedNodes[i], true);
            }
        }

        this.quickTree.quickTree.goToSelectedNode();
        this.quickTree.setSizes();
    }

    public static sortNodes (nodes): void {
        if (isNullOrUndefined(nodes)) return;

        nodes.sort(TerceraAccountLookupDropDownForm.sortItems);

        const len = nodes.length;
        for (let i = 0; i < len; i++) {
            TerceraAccountLookupDropDownForm.sortNodes(nodes[i].childNodes);
        }
    }

    public static sortItems (i1: QuickTreeNode, i2: QuickTreeNode): number {
        const acc1: Account = i1.tag;
        const acc2: Account = i2.tag;

        if (isNullOrUndefined(acc1) || isNullOrUndefined(acc2)) {
            return i1.nodeText.toLowerCase().localeCompare(i2.nodeText.toLowerCase());
        }

        if (acc1.isLinked && !acc2.isLinked) {
            return 1;
        } else if (!acc1.isLinked && acc2.isLinked) {
            return -1;
        } else {
            return acc1.FullAccString.toLowerCase().localeCompare(acc2.FullAccString.toLowerCase());
        }
    }

    public override localize (): void {
        super.localize();
        this.modBtnText = Resources.getResource('TerceraAccountLookup.Select');
    }

    public onCheckAllStateChange (sender, value): void {
        if (this.noRaiseSelectAll) {
            this.noRaiseSelectAll = false;
            return;
        }

        if (!isNullOrUndefined(this.quickTree)) {
            if (this.quickTree.quickTree.isMultiSelectCheckboxUsing) {
                this.quickTree.quickTree.CheckUnCheckAllMultiCheckbox(value);
            } else {
                this.quickTree.quickTree.CheckUnCheckAll(value);
            }
        }
    }

    public static override async createInstance (): Promise<TerceraAccountLookupDropDownForm> {
        const lookup = new TerceraAccountLookupDropDownForm();
        lookup.setBounds(0, 0, 320, 350);
        void lookup.set('visible', false);
        const currentMainWindow = MainWindowManager.MainWindow;
        currentMainWindow.addControl(lookup);

        lookup.observe('isMultiSelect', lookup.isMultiSelectObserver);

        return await lookup.OnCompliteWaiter;
    }

    private isMultiSelectObserver (newVal: boolean): void {
        void this.set({ isShowFooterBtn: newVal }).then(() => {
            this.resizeOnFooterChangeVisibility();
        });

        // Part of dark magic
        if (!isNullOrUndefined(this.quickTree?.quickTree)) {
            this.quickTree.quickTree.isMultiSelectCheckboxUsing = newVal;
        }
        if (!isNullOrUndefined(this.caller)) {
            this.caller.isMultiSelect = newVal;
            this.caller.items = this.caller.multiSelectDropDownItems;
            this.items = this.caller.multiSelectDropDownItems;
        }
        if (isValidArray(this.items)) {
            this.fillTree();
        }
    }

    public resizeOnFooterChangeVisibility (): void {
        if (isNullOrUndefined(this.quickTree)) {
            return;
        }

        void this.quickTree.resetSizes().then(() => { this.quickTree.setSizes(); });
    }

    public override selectionChanged (): void {
        super.selectionChanged();

        const qt = this.quickTree.quickTree;

        if (!isValidArray(this.items) || isNullOrUndefined(qt)) { return; }

        const selectedNum = Object.keys(qt.selectedNodesDict).length;
        const selectedAll = selectedNum === this.items.length;

        const selectAllCB = this.chBoxSelectAll;
        if (!isNullOrUndefined(selectAllCB)) {
            if (this.get('checkedSelectedAll') !== selectedAll) // select all cb checked state changed
            {
                this.noRaiseSelectAll = true;
            }

            void this.set({ checkedSelectedAll: selectedAll });
        }
    }

    public override themeChange (): void {
        this.iconUser = ThemeManager.CurrentTheme.iconUser;
        this.iconUserChecked = ThemeManager.CurrentTheme.iconUserChecked;
        this.iconUserAccount = ThemeManager.CurrentTheme.iconUserAccount;
        this.iconUserAccountChecked = ThemeManager.CurrentTheme.iconUserAccountChecked;
        this.iconUserLinkedAccounts = ThemeManager.CurrentTheme.iconUserLinkedAccounts;
        this.iconUserLinkedAccountsChecked = ThemeManager.CurrentTheme.iconUserLinkedAccountsChecked;
        this.iconFundOpen = ThemeManager.CurrentTheme.iconFundOpen;
        this.iconFundOpenChecked = ThemeManager.CurrentTheme.iconFundOpenChecked;
        this.iconFundClosed = ThemeManager.CurrentTheme.iconFundClosed;
        this.iconFundClosedChecked = ThemeManager.CurrentTheme.iconFundClosedChecked;
        this.iconUserGroup = ThemeManager.CurrentTheme.iconUserGroup;
        this.iconUserGroupChecked = ThemeManager.CurrentTheme.iconUserGroupChecked;
    }
}

TerceraLookupDropDownForm.extendWith(TerceraAccountLookupDropDownForm, {
    partials: { bodyPartial: TerceraAccountLookupDropDownFormTemplate }
});
