// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from '../../Commons/Geometry';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraBaseWindowTemplate, ColorPickerScreenTemplate } from '../../templates.js';
import { TerceraMenu } from '../elements/TerceraMenu';
import { TerceraWindowBase } from './TerceraWindowBase';
import { type ColorPicker } from '../elements/ColorPicker';

export class ColorPickerScreen extends TerceraWindowBase {
    public callBack: any;

    constructor () { super(); }

    public static ShowScreen (x: number, y: number, curColor, callBack): void {
        if (isNullOrUndefined(curColor)) {
            return;
        }

        const a = new ColorPickerScreen();
        const r = new Rectangle(x, y, 300, 330);
        const newLoc = TerceraMenu.CorrectPopupLocation(r);
        a.setBounds(newLoc.newX, newLoc.newY, r.Width, r.Height);
        MainWindowManager.MainWindow.addControl(a);
        a.Controls.cpControl.setColor(curColor);
        a.callBack = callBack;
        a.Controls.cpControl.onMouseDown.Subscribe(a.runCallBack, a);
        a.setFocus();
    }

    public override lostFocus (): void {
        const controol: ColorPicker = this.Controls.cpControl;
        if (!isNullOrUndefined(controol)) {
            if (controol.startValueA !== controol.valueA) {
                const newColor = controol.getColor();
                if (!isNullOrUndefined(this.callBack)) {
                    this.callBack(newColor);
                }
            }
        }
        super.lostFocus();
        this.dispose();
    }

    public runCallBack (): void {
        const newColor = this.Controls.cpControl.getColor();
        if (!isNullOrUndefined(this.callBack)) {
            this.callBack(newColor);
        }
        this.lostFocus();
    }
}

TerceraWindowBase.extendWith(ColorPickerScreen, {
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            movable: false,
            showHeader: false,
            showFooter: false,
            resizable: false,
            focused: true
        };
    },
    partials: { bodyPartial: ColorPickerScreenTemplate }
});
