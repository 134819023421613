// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DataCache } from '../../Commons/DataCache';
import { OrderType } from './OrderType';
import { type OrderTif } from './OrderTifEnum';
import { type Instrument } from '../../Commons/cache/Instrument';
import { OrderTypeBaseParameters } from '../../Commons/cache/OrderParams/order-type/OrderTypeBaseParameters';
import { SessionSettings } from '../../Commons/SessionSettings';
import { ProductType } from '../Instruments/ProductType';
import { InstrumentUtils } from '../Instruments/InstrumentUtils';
import { type Account } from '../../Commons/cache/Account';

class _TradingUtils {
    public getAllowedOrderTypes (): OrderType[] {
        const allowedOrderTypes: OrderType[] = new Array<OrderType>();
        const orderTypes = DataCache.OrderParameterContainer.OrderTypes;
        for (const key in orderTypes) {
            allowedOrderTypes.push(orderTypes[key].id());
        }
        return this.sortOrderTypes(allowedOrderTypes);
    }

    public getAllowedOrderTypesForTrading (account: Account, instrument: Instrument): OrderType[] {
        const allowedOrderTypes = new Array<OrderType>();
        if (!account || !instrument) {
            return allowedOrderTypes;
        }
        if (DataCache.OrderParameterContainer === null) {
            return allowedOrderTypes;
        }

        const orderTypes = DataCache.OrderParameterContainer.OrderTypes;
        const supportedParamObj = new OrderTypeBaseParameters(instrument, account);
        for (const key in orderTypes) {
            const orderType = orderTypes[key];
            const id = orderType.id();
            if (!orderType.IsSupported(supportedParamObj)) {
                continue;
            }
            allowedOrderTypes.push(id);
        }
        return this.sortOrderTypes(allowedOrderTypes);
    }

    public getAllowedTifs (): OrderTif[] {
        return SessionSettings.GetAllowedTifsForOrderTypes(this.getAllowedOrderTypes());
    }

    public getAllowedTifsForTrading (instrument: Instrument, orderType: OrderType): OrderTif[] {
        let allowedTifs = new Array<OrderTif>();
        if (!instrument || !orderType) {
            return allowedTifs;
        }

        if (DataCache.OrderParameterContainer === null) {
            return allowedTifs;
        }

        const supportedParamObj = new OrderTypeBaseParameters(instrument);
        const currentOrderType = DataCache.OrderParameterContainer.GetOrderType(orderType);
        if (!currentOrderType) {
            return allowedTifs;
        }

        if (!currentOrderType.IsSupported(supportedParamObj)) {
            return allowedTifs;
        }

        const route = DataCache.getRouteByName(instrument.Route);
        const tifs = route.DictOrderTypeTifs[orderType];
        if (tifs) {
            allowedTifs = tifs;
        }
        return allowedTifs;
    }

    public getAllowedProductTypes (): ProductType[] {
        const allowedProductTypes: ProductType[] = new Array<ProductType>();
        const productTypesCache = DataCache.productTypeEnabilityCache;
        for (const t in productTypesCache) {
            allowedProductTypes.push(+t);
        }
        return allowedProductTypes;
    }

    public getAllowedProductTypesForTrading (account: Account, instrument: Instrument): ProductType[] {
        const productTypes: ProductType[] = new Array<ProductType>();
        if (!account || !instrument) {
            return productTypes;
        }
        const dict = account.RiskPlan ? account.RiskPlan.GetRisksForInstrument(instrument.GetInteriorID()).availableProductTypes : InstrumentUtils.getAllowedProductTypeDict(instrument);

        if (dict.length === 0) {
            return productTypes;
        }

        if (dict.length === 1 && Number(dict[0]) === ProductType.General) {
            return productTypes;
        }

        for (const productType of dict) {
            productTypes.push(productType);
        }
        return productTypes;
    }

    public getStartOfDay (date: Date, dayOffset: number = 0): Date {
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + dayOffset);
        return date;
    }

    private sortOrderTypes (orderTypes: OrderType[]): OrderType[] {
        // Order type OCO Not supported
        const ocoIndex = orderTypes.indexOf(OrderType.OCO);
        if (ocoIndex > -1) {
            orderTypes.splice(ocoIndex);
        }

        const stop = orderTypes.indexOf(OrderType.Stop);
        const limit = orderTypes.indexOf(OrderType.Limit);
        if (stop > -1 && limit > -1) {
            const temp = orderTypes[stop];
            orderTypes[stop] = orderTypes[limit];
            orderTypes[limit] = temp;
        }
        return orderTypes;
    }
}

export const TradingUtils = new _TradingUtils();
