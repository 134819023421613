// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { HistoryType } from '../../Utils/History/HistoryType';
import { TerceraChartDrawingType } from '../../Chart/Utils/ChartConstants';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraAddOverlayScreenTemplate } from '../../templates.js';
import { TerceraMessageBox } from './TerceraMessageBox';
import { TerceraWindowBase } from './TerceraWindowBase';
import { ThemeManager } from '../misc/ThemeManager';
import { Periods } from '../../Utils/History/TFInfo';
import { PairColor } from '../../Commons/DynProperty';
import { DataCache } from '../../Commons/DataCache';
import { addOverlayScreenHandler } from '../../Utils/AppHandlers';

let instance = null;

export class TerceraAddOverlayScreen extends TerceraWindowBase {
    public callback: any = null;

    public override oninit (): void {
        super.oninit();

        void this.set('header', Resources.getResource('OverlayScreen.name'));
        this.on('okEvent', this.okClick);
        this.on('cancelEvent', this.close);
    // this.Icon = Resources.getIcon("resources.Panels.chartPanel.ico");
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.Controls.barsBodyPicker.set('pairColor', new PairColor(
            ThemeManager.CurrentTheme.Chart_BarsUpColor,
            ThemeManager.CurrentTheme.Chart_BarsDownColor));

        this.Controls.barsBorderPicker.set('pairColor', new PairColor(
            ThemeManager.CurrentTheme.Chart_BarsUpBorderColor,
            ThemeManager.CurrentTheme.Chart_BarsDownBorderColor));

        this.Controls.wickPicker.set('pairColor', new PairColor(
            ThemeManager.CurrentTheme.Chart_WickUpBorderColor,
            ThemeManager.CurrentTheme.Chart_WickDownBorderColor));

        this.Controls.dojiPicker.set('coloringButtonColor',
            ThemeManager.CurrentTheme.Chart_BarsHiLowColor);

        this.Controls.histogramPicker.set('coloringButtonColor',
            ThemeManager.CurrentTheme.Chart_HistogramLineColor);

        this.Controls.linePicker.set('coloringButtonColor',
            ThemeManager.CurrentTheme.Chart_SolidPriceColor);

        this.Controls.solidPricePicker.set('coloringButtonColor',
            ThemeManager.CurrentTheme.Chart_SolidPriceColor);

        this.populateSymbols();
        this.Controls.insLookup.onChangeValue.Subscribe(this.onInstrumentChanged, this);

        const styleCombo = this.Controls.chartStyleComboBox;
        const arr = [];
        if (!Resources.isHidden('chart.tool.line')) {
            arr.push({ text: Resources.getResource('chart.tool.line'), value: TerceraChartDrawingType.Line });
        }
        // ---------------------------------------------------------------------------------------------
        if (!Resources.isHidden('chart.tool.bar')) {
            arr.push({ text: Resources.getResource('chart.tool.bar'), value: TerceraChartDrawingType.Bar });
        }
        // ---------------------------------------------------------------------------------------------
        if (!Resources.isHidden('chart.tool.candle')) {
            arr.push({ text: Resources.getResource('chart.tool.candle'), value: TerceraChartDrawingType.Candle });
        }
        // ---------------------------------------------------------------------------------------------
        if (!Resources.isHidden('chart.tool.dot')) {
            arr.push({ text: Resources.getResource('chart.tool.dot'), value: TerceraChartDrawingType.Dot });
        }
        // ---------------------------------------------------------------------------------------------
        if (!Resources.isHidden('chart.tool.dotline')) {
            arr.push({ text: Resources.getResource('chart.tool.dotline'), value: TerceraChartDrawingType.DotLine });
        }
        // ---------------------------------------------------------------------------------------------
        if (!Resources.isHidden('chart.tool.forest')) {
            arr.push({ text: Resources.getResource('chart.tool.forest'), value: TerceraChartDrawingType.Forest });
        }
        // ---------------------------------------------------------------------------------------------
        if (!Resources.isHidden('chart.tool.solid')) {
            arr.push({ text: Resources.getResource('chart.tool.solid'), value: TerceraChartDrawingType.Solid });
        }

        styleCombo.set('items', arr);
        /* { text: Resources.getResource("chart.tool.line"), value: TerceraChartDrawingType.Line },
    { text: Resources.getResource("chart.tool.bar"), value: TerceraChartDrawingType.Bar },
    { text: Resources.getResource("chart.tool.candle"), value: TerceraChartDrawingType.Candle },
    { text: Resources.getResource("chart.tool.dot"), value: TerceraChartDrawingType.Dot },
    { text: Resources.getResource("chart.tool.dotline"), value: TerceraChartDrawingType.DotLine },
    { text: Resources.getResource("chart.tool.forest"), value: TerceraChartDrawingType.Forest },
    { text: Resources.getResource("chart.tool.solid"), value: TerceraChartDrawingType.Solid } */
        styleCombo.setItembyValue(TerceraChartDrawingType.Line);

        const priceScalingCombo = this.Controls.priceScalingComboBox;
        priceScalingCombo.set('items', [
            { text: Resources.getResource('OverlayScreen.Main scale'), value: false },
            { text: Resources.getResource('OverlayScreen.Separate scale'), value: true }
        ]);
        priceScalingCombo.setItembyValue(true);
        this.center();
        this.localize();
    }

    public override localize (): void {
        void this.set({
            okButtonText: Resources.getResource('OverlayScreen.okButton'),
            cancelButtonText: Resources.getResource('OverlayScreen.cancelButton')
        });
    }

    public override dispose (): void {
        super.dispose();

        const insLookup = this.Controls.insLookup;
        if (insLookup) {
            insLookup.onChangeValue.UnSubscribe(this.onInstrumentChanged, this);
        }

        this.callback = null;
    }

    public okClick (): void {
        if (this.callback) {
            this.callback({
                instrument: this.Controls.insLookup.get('selectedItem'),
                chartDrawingType: this.Controls.chartStyleComboBox.get('selectedItem').value,
                priceScaling: this.Controls.priceScalingComboBox.get('selectedItem').value,
                historyType: this.Controls.sourceTypeComboBox.get('selectedItem').value,
                showPriceMarker: this.Controls.showPriceMarkerCheckBox.get('checked'),
                barsBodyPairColor: this.Controls.barsBodyPicker.get('pairColor'),
                barsBorderPairColor: this.Controls.barsBorderPicker.get('pairColor'),
                wickPairColor: this.Controls.wickPicker.get('pairColor'),
                solidPriceColor: this.Controls.solidPricePicker.get('coloringButtonColor'),
                lineColor: this.Controls.linePicker.get('coloringButtonColor'),
                HistogramLineColor: this.Controls.histogramPicker.get('coloringButtonColor'),
                Doji: this.Controls.dojiPicker.get('coloringButtonColor')
            });
        }

        this.close();
    }

    // TODO.
    public populateSymbols (): void {
    /* symbolsLookup.SetPopulationFlags(false, true, true, true);
    symbolsLookup.Populate(); */
    }

    public onInstrumentChanged (newIns): void {
        if (!newIns) return;

        const sourceTypeCombo = this.Controls.sourceTypeComboBox;
        sourceTypeCombo.setItembyValue(newIns.DefaultChartHistoryType);
    }

    public populateSourceType (chart): void {
        if (!chart) return;

        const period = chart.TimeFrameInfo().Periods; // текущий период

        const types = DataCache.GetAllowedHistoryTypesByPeriod(period);
        types.sort(historyTypeComparer.compare);
        const types_len = types.length;

        const sourceTypeItems = [];

        for (let i = 0; i < types_len; i++) {
            const type = types[i];

            sourceTypeItems.push({

                text: ((period === Periods.TIC || period < 0) && type === 1)
                    ? Resources.getResource('chart.view.sourceBID/ASK')
                    : Resources.getResource('chart.view.source' + type),

                value: type
            });
        }

        const sourceTypeCombo = this.Controls.sourceTypeComboBox;
        sourceTypeCombo.set('items', sourceTypeItems);
        sourceTypeCombo.setItembyValue(sourceTypeItems[0].value);
    }

    public populate (overlay): void {
        if (overlay.ActiveInstrument) {
            this.Controls.insLookup.set('selectedItem', overlay.ActiveInstrument);
        }

        this.Controls.sourceTypeComboBox.setItembyValue(overlay.HistoryType);
        this.Controls.chartStyleComboBox.setItembyValue(overlay.ChartDrawingTypeGet());
        this.Controls.priceScalingComboBox.setItembyValue(overlay.separateScale);

        const mainPriceRenderer = overlay.mainPriceRenderer;

        this.Controls.barsBodyPicker.set('pairColor', new PairColor(
            mainPriceRenderer.barsUpColorBrush.Color,
            mainPriceRenderer.barsDownColorBrush.Color));

        this.Controls.barsBorderPicker.set('pairColor', new PairColor(
            mainPriceRenderer.barsUpBorderColorPen.Color,
            mainPriceRenderer.barsDownBorderColorPen.Color));

        this.Controls.wickPicker.set('pairColor', new PairColor(
            mainPriceRenderer.wickUpBorderColorPen.Color,
            mainPriceRenderer.wickDownBorderColorPen.Color));

        this.Controls.dojiPicker.set('coloringButtonColor',
            mainPriceRenderer.barsHiLowColorPen.Color);

        this.Controls.histogramPicker.set('coloringButtonColor',
            mainPriceRenderer.histogramLineColorPen.Color);

        this.Controls.linePicker.set('coloringButtonColor',
            overlay.MarkerAndLineColorGet());

        this.Controls.solidPricePicker.set('coloringButtonColor',
            mainPriceRenderer.SolidPriceBrushColor);

        this.Controls.showPriceMarkerCheckBox.set('checked', overlay.ShowMarker);
    }

    public static addNewOverlay (chart): void {
        if (chart && chart.Overlays.length > 4) {
            TerceraMessageBox.Show(
                Resources.getResource('OverlayScreen.Overlays'),
                Resources.getResource('OverlayScreen.numberOverlaysError'),
                null, null, null, false, true);
            return;
        }

        const callback = (params): void => { TerceraAddOverlayScreen.onAddNewOverlay(params, chart); };
        TerceraAddOverlayScreen.show(chart, callback);
    }

    public static onAddNewOverlay (params, chart): void {
        const instrument = params.instrument;
        const chartDrawingType = params.chartDrawingType;
        const historyType = params.historyType;
        const showPriceMarker = params.showPriceMarker;
        const priceScaling = params.priceScaling;
        const barsBodyPairColor = params.barsBodyPairColor;
        const barsBorderPairColor = params.barsBorderPairColor;
        const wickPairColor = params.wickPairColor;
        const solidPriceColor = params.solidPriceColor;
        const lineColor = params.lineColor;
        const HistogramLineColor = params.HistogramLineColor;
        const Doji = params.Doji;

        const overlay = chart.AddOverlayInstrument(
            instrument.GetInteriorID(),
            true,
            chartDrawingType,
            historyType);

        if (!overlay) return;

        const mainPriceRenderer = overlay.mainPriceRenderer;

        mainPriceRenderer.barsUpColorBrush.Color = barsBodyPairColor.Color1;
        mainPriceRenderer.barsDownColorBrush.Color = barsBodyPairColor.Color2;
        mainPriceRenderer.barsUpBorderColorPen.Color = barsBorderPairColor.Color1;
        mainPriceRenderer.barsDownBorderColorPen.Color = barsBorderPairColor.Color2;
        mainPriceRenderer.wickUpBorderColorPen.Color = wickPairColor.Color1;
        mainPriceRenderer.wickDownBorderColorPen.Color = wickPairColor.Color2;
        mainPriceRenderer.SolidPriceBrushColor = solidPriceColor;
        mainPriceRenderer.histogramLineColorPen.Color = HistogramLineColor;
        mainPriceRenderer.barsHiLowColorPen.Color = Doji;
        overlay.MarkerAndLineColorSet(lineColor);
        overlay.ShowMarker = showPriceMarker;
        overlay.separateScale = priceScaling;
    }

    public static editOverlay (chart, overlay): void {
        const callback = (params): void => { TerceraAddOverlayScreen.onEditOverlay(params, chart, overlay); };
        TerceraAddOverlayScreen.show(chart, callback);
        instance.on('complete', function () {
            instance.populate(overlay);
        });
    }

    public static onEditOverlay (params, chart, overlay): void {
        const instrument = params.instrument;
        const chartDrawingType = params.chartDrawingType;
        const historyType = params.historyType;
        const showPriceMarker = params.showPriceMarker;
        const priceScaling = params.priceScaling;
        const barsBodyPairColor = params.barsBodyPairColor;
        const barsBorderPairColor = params.barsBorderPairColor;
        const wickPairColor = params.wickPairColor;
        const solidPriceColor = params.solidPriceColor;
        const lineColor = params.lineColor;
        const HistogramLineColor = params.HistogramLineColor;
        const Doji = params.Doji;

        overlay.Instrument = instrument.GetInteriorID();
        overlay.HistoryType = historyType;
        overlay.ChartDrawingTypeSet(chartDrawingType);

        const mainPriceRenderer = overlay.mainPriceRenderer;

        mainPriceRenderer.barsUpColorBrush.Color = barsBodyPairColor.Color1;
        mainPriceRenderer.barsDownColorBrush.Color = barsBodyPairColor.Color2;
        mainPriceRenderer.barsUpBorderColorPen.Color = barsBorderPairColor.Color1;
        mainPriceRenderer.barsDownBorderColorPen.Color = barsBorderPairColor.Color2;
        mainPriceRenderer.wickUpBorderColorPen.Color = wickPairColor.Color1;
        mainPriceRenderer.wickDownBorderColorPen.Color = wickPairColor.Color2;
        mainPriceRenderer.SolidPriceBrushColor = solidPriceColor;
        mainPriceRenderer.histogramLineColorPen.Color = HistogramLineColor;
        mainPriceRenderer.barsHiLowColorPen.Color = Doji;
        overlay.MarkerAndLineColorSet(lineColor);
        overlay.ShowMarker = showPriceMarker;
        overlay.separateScale = priceScaling;

        chart.UpdateOverlay();
    }

    public static show (chart, callback): void {
        if (!instance) {
            instance = new TerceraAddOverlayScreen();
            instance.on('teardown', function () {
                instance = null;
            });
            MainWindowManager.MainWindow.addControl(instance);
        }

        instance.callback = callback;
        instance.populateSourceType(chart);

        instance.setParentPanel(chart.terceraChartPanelContext);
        instance.setFocus();
    }

    public static addOverlayScreenHandlerInitialize (): void {
        addOverlayScreenHandler.editOverlay = TerceraAddOverlayScreen.editOverlay;
    }
}

class HistoryTypeComparer {
    public compare (t1, t2): number {
        const cmpObj = historyTypeComparer;
        return cmpObj.getValue(t1) - cmpObj.getValue(t2);
    }

    public getValue (historyType: number): number {
        switch (historyType) {
        case HistoryType.DEFAULT: // default by instrument
            return -1;
        case HistoryType.BID:
            return 0;
        case HistoryType.ASK:
            return 1;
        case HistoryType.BIDASK_AVG:
            return 2;
        case HistoryType.BIDASK_SUM:
            return 3;
        case HistoryType.LAST:
            return 4;
        }
        return 0;
    }
}

const historyTypeComparer = new HistoryTypeComparer();

TerceraWindowBase.extendWith(TerceraAddOverlayScreen, {
    data: function () {
        return {
            width: 322,
            resizable: false,
            // For using in templates.
            Resources,
            showFooter: false
        };
    },
    partials: { bodyPartial: TerceraAddOverlayScreenTemplate }
});
