import { type PlacedFrom } from '../../Utils/Trading/PlacedFrom';
import { type Account } from '../cache/Account';
import { type OrderEditBase } from '../cache/OrderParams/order-edit/OrderEditBase';
import { MultiplyPlaceOrderWrapperItem } from './MultiplyPlaceOrderWrapperItem';

export class MultiplyPlaceOrderWrapper {
    private readonly orderEditsArr: MultiplyPlaceOrderWrapperItem[] = [];
    private readonly mapByAccount: Map<Account, MultiplyPlaceOrderWrapperItem[]> = new Map<Account, MultiplyPlaceOrderWrapperItem[]>();
    public placedFrom: PlacedFrom = null;

    public AddItem (orderId: string, orderEditBase: OrderEditBase): void {
        const curArray = this.GetArrayByAccount(orderEditBase.account);
        this.placedFrom = orderEditBase.placedFrom;
        const newItem = new MultiplyPlaceOrderWrapperItem(orderId, orderEditBase);
        curArray.push(newItem);
        this.orderEditsArr.push(newItem);
    }

    public GetArrayByAccount (account: Account): MultiplyPlaceOrderWrapperItem[] {
        if (!this.mapByAccount.has(account)) { this.mapByAccount.set(account, []); }
        return this.mapByAccount.get(account);
    }

    public GetAllOrderEditsArr (): OrderEditBase[] {
        const res: OrderEditBase[] = [];
        for (const item of this.orderEditsArr) { res.push(item.orderEditBase); }

        return res;
    }

    public GetAllAccounts (): Account[] {
        return [...this.mapByAccount.keys()];
    }

    public GetAllOrdersId (): string[] {
        const res: string[] = [];
        this.mapByAccount.forEach((items) => {
            for (const item of items) {
                res.push(item.orderId);
            }
        });
        return res;
    }
}
