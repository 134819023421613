// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type IHistoryBusinessObject } from './interface/IHistoryBusinessObject';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { NO_BIND_TO } from '../../Utils/Trading/OrderConstants';
import { OrderExecutionType } from '../../Utils/Trading/OrderExecutionType';
import { TIF } from '../../Utils/Trading/OrderTif';
import { OrderType } from '../../Utils/Trading/OrderType';
import { type Account } from './Account';
import { type Instrument } from './Instrument';

// TODO. Inherit from DataCacheBusinessObject.
export class OrderHistory implements IHistoryBusinessObject {
    public ProductType = ProductType.General;
    public FRemainingQuantity = 0;
    public FFilledQuantity = 0;
    public FPutCall = 0;
    public FExternalOrderId: any = null;
    public FExternalTradeId: any = null;
    public FExpYear = 0;
    public FExpMonth = 0;
    public FExpDay = 0;
    public FStrikePrice = 0;
    private id = '';
    public InstrumentStr: any = null;
    public AccountStr: any = null;
    private FBuySell = 0;
    private FDate: any = null;

    // Raw value ExecutionType.
    public eventTypeRaw = OrderExecutionType.UNKNOWN;

    private FMessage = '';
    public FOrderNumber = '';
    private FOrderType: OrderType = undefined;
    private FPrice = 0;
    private FQuantity = 0;
    private FDisclosedQty = 0;
    private FRoute = '';
    private FRemoteId = '';
    private FStopLimit = 0;
    private FTime: any = null;
    private FUTCDateTime: any = null;
    private FTimeInForce: TIF | null = null;
    private FTraderId = '';
    private boundToOrderId: string = '-1';
    private FPositionId: string = '-1';
    public Description: any = null;

    public boughtString = '';
    public soldString = '';

    public TradingExchange = '';
    public SymbolType: any;

    private FLeverage: any = null;

    private FAccount: Account | null = null;
    private FInstrument: Instrument | null = null;

    get Instrument (): Instrument | null { return this.FInstrument; }
    set Instrument (value: Instrument | null) {
        this.FInstrument = value;
        this.InstrumentStr = value ? value.DisplayName() : null;
    }

    get Account (): Account | null { return this.FAccount; }
    set Account (value: Account | null) {
        this.FAccount = value;
        this.AccountStr = value ? this.FAccount.toString() : null;
    }

    get Id (): any { return this.id; }

    // get Alive (): boolean { return this.FEvent === 'Live'; }

    get BuySell (): number { return this.FBuySell; }
    set BuySell (value) { this.FBuySell = value; }

    get Date (): any { return this.FDate; }
    set Date (value) { this.FDate = value; }

    get Message (): any { return this.FMessage; }
    set Message (value) { this.FMessage = value; }

    get OrderNumber (): any { return this.FOrderNumber; }
    set OrderNumber (value) { this.FOrderNumber = value; }

    get OrderType (): OrderType { return this.FOrderType; }
    set OrderType (value) { this.FOrderType = value; }

    get Price (): number { return this.FPrice; }
    set Price (value) { this.FPrice = value; }

    get Quantity (): number { return this.FQuantity; }
    set Quantity (value) { this.FQuantity = value; }

    get DisclosedQty (): number { return this.FDisclosedQty; }
    set DisclosedQty (value) { this.FDisclosedQty = value; }

    get Route (): string { return this.FRoute; }
    set Route (value) { this.FRoute = value; }

    get RemoteId (): string { return this.FRemoteId; }
    set RemoteId (value) { this.FRemoteId = value; }

    get StopLimit (): number { return this.FStopLimit; }
    set StopLimit (value) { this.FStopLimit = value; }

    get Time (): any { return this.FTime; }
    set Time (value) { this.FTime = value; }

    get UTCDateTime (): any { return this.FUTCDateTime; }
    set UTCDateTime (value) { this.FUTCDateTime = value; }

    get TimeInForce (): TIF | null { return this.FTimeInForce; }
    set TimeInForce (value) { this.FTimeInForce = value; }

    get TraderId (): string { return this.FTraderId; }
    set TraderId (value) { this.FTraderId = value; }

    get BoundToOrderId (): string { return this.boundToOrderId; }
    set BoundToOrderId (value) { this.boundToOrderId = value; }

    get PositionId (): string { return this.FPositionId; }
    set PositionId (value) { this.FPositionId = value; }

    get ExpYear (): number {
        const ins = this.Instrument;
        const date = ins ? ins.ExpDateReal : null;
        const year = date ? date.getUTCFullYear() : 0;
        return year > 2000 ? year : this.FExpYear;
    }

    set ExpYear (value) {
        this.FExpYear = value;
    }

    get ExpMonth (): number {
        const ins = this.Instrument;
        const date = ins ? ins.ExpDateReal : null;
        const month = date ? date.getUTCMonth() : 0;
        return month > 0 ? month : this.FExpMonth;
    }

    set ExpMonth (value) {
        this.FExpMonth = value;
    }

    get ExpDay (): number {
        const ins = this.Instrument;
        const date = ins ? ins.ExpDateReal : null;
        const day = date ? date.getUTCDate() : 0;
        return day > 0 ? day : this.FExpDay;
    }

    set ExpDay (value) {
        this.FExpDay = value;
    }

    get StrikePrice (): any {
        const ins = this.Instrument;
        return ins && ins.StrikePrice > 0
            ? ins.StrikePrice
            : this.FStrikePrice;
    }

    set StrikePrice (value) {
        this.FStrikePrice = value;
    }

    get PutCall (): number {
        const ins = this.Instrument;
        return ins && ins.PutCall > -1
            ? ins.PutCall
            : this.FPutCall;
    }

    set PutCall (value) {
        this.FPutCall = value;
    }

    get ExternalOrderId (): any { return this.FExternalOrderId; }
    set ExternalOrderId (value) { this.FExternalOrderId = value; }

    get ExternalTradeId (): any { return this.FExternalTradeId; }
    set ExternalTradeId (value) { this.FExternalTradeId = value; }

    get FilledQuantity (): number { return this.FFilledQuantity; }
    set FilledQuantity (value) { this.FFilledQuantity = value; }

    get RemainingQuantity (): number { return this.FRemainingQuantity; }
    set RemainingQuantity (value) { this.FRemainingQuantity = value; }

    get Leverage (): any { return this.FLeverage; }
    set Leverage (value) { this.FLeverage = value; }

    // TODO. Ugly as fck.
    public static OrderHistoryID = 100;

    public static fromMessage (message, dc): OrderHistory {
        // const res = new OrderHistory(dc);
        // if (!dc) {
        //     return res;
        // } Для цього немає поки логіки
        const res = new OrderHistory();

        res.id = (OrderHistory.OrderHistoryID++).toString();
        res.Account = dc.GetAccountById(message.Account);

        res.Instrument = dc.getInstrumentByTradable_ID(message.InstrumentTradableID, message.Route);

        if (res.Instrument) {
            res.Description = res.Instrument.DescriptionValue();
        }

        if (!res.Instrument) {
            res.InstrumentStr = message.Symbol;
        }

        res.FBuySell = message.BuySell;
        res.FDate = message.UTCDateTime;

        res.eventTypeRaw = message.EventType;

        res.FOrderNumber = message.OrderNumber;
        res.FOrderType = message.OrderType;

        // binding sl and tp currently occurs by positionId
        res.BoundToOrderId = message.BoundToOrderId;
        const positionId = res.FPositionId = message.PositionId;
        if (!message.Active && positionId !== NO_BIND_TO) {
            if (message.OrderType === OrderType.Stop) {
                res.FOrderType = OrderType.SLTPStop;
            } else if (message.OrderType === OrderType.Limit) {
                res.FOrderType = OrderType.SLTPLimit;
            }
        }

        res.FPrice = message.Price;
        res.FQuantity = message.Quantity;
        res.FRoute = message.Route;
        res.FRemoteId = message.RemoteId;
        res.FStopLimit = message.StopLimit;
        res.FTime = message.UTCDateTime;
        res.FUTCDateTime = message.UTCDateTime;
        const expTimeTicks = message.ExpireTime?.getTime() || 0;
        res.FTimeInForce = new TIF(message.TimeInForce, expTimeTicks ? message.ExpireTime : null);
        res.FTraderId = message.OrderCreatedByUser;

        // nicky: не показываем пустую дату, если она не ставится вендором,
        // пусть будет как раньше - дата ордера
        const lastUpdateTime = message.LastUpdateTime;
        if (res.Date === null && lastUpdateTime?.getTime() > 0) {
            res.FDate = lastUpdateTime;
            res.FTime = lastUpdateTime;
            res.FUTCDateTime = lastUpdateTime;
        }

        res.DisclosedQty = message.DisClosedQuantity;
        res.FilledQuantity = message.FilledQuantity;
        res.RemainingQuantity = message.RemainingQuantity;
        res.ProductType = message.ProductType;
        res.ExternalOrderId = message.ExternalOrderId;
        res.ExternalTradeId = message.ExternalTradeId;

        res.Leverage = message.Leverage;

        return res;
    }

    public getAmount (): number {
        return this.Quantity;
    }

    get Daily (): boolean {
        const today = new Date();
        const historyDate = this.Date;
        return (
            historyDate.getUTCDate() === today.getUTCDate() &&
            historyDate.getUTCMonth() === today.getUTCMonth() &&
            historyDate.getUTCFullYear() === today.getUTCFullYear()
        );
    }
}
