// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ImagesManager } from '../../Controls/misc/ImagesManager';

export class SymbolSvgLogoManager {
    static async LoadSvgImage (logoAddress: string): Promise<string | null> {
        if (!logoAddress) {
            return null;
        }
        return await ImagesManager.GetSvg(logoAddress);
    }

    static getDefaultImage (symbol: string): string {
        let firstLetter = '';
        if (symbol) {
            firstLetter = symbol.substring(0, 1).toUpperCase();
        }

        return '<svg width=\"32\" height=\"32\" viewBox=\"0 0 32 32\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">' +
            '<path d=\"M0 5.33333C0 2.38782 2.38781 0 5.33333 0H26.6667C29.6122 0 32 2.38781 32 5.33333V26.6667C32 29.6122 29.6122 32 26.6667 32H5.33333C2.38782 32 0 29.6122 0 26.6667V5.33333Z\" fill=\"#3EA7DE\"/>' +
            '<text fill=\"white\" xml:space=\"preserve\" style=\"white-space: pre\" font-family=\"Roboto\" font-size=\"24\" letter-spacing=\"0em\" x=\"50%\" y=\"25px\" text-anchor=\"middle\"><tspan>' +
            firstLetter +
            '</tspan></text></svg>';
    }

    static getDefaultImageInBase64 (symbol: string): string {
        const defaultImageSvg = this.getDefaultImage(symbol);
        const base64Svg = `data:image/svg+xml;base64,${btoa(defaultImageSvg)}`;
        return base64Svg;
    }
}
