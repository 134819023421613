// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export const he =
{
    'AccountDetailesPanel.quickTable.Name': 'שם',
    'AccountDetailesPanel.quickTable.Value': 'ערך',
    'accountDetailsWidget.DropdownButton.Tooltip': 'תפריט חשבון',
    'accountDetailsWidget.DropdownButton': 'חשבון',
    'additional.verification': '.אימות סיסמא נוספת נדרInstrumentDetailsPanel.Historical swap last updated onשת',
    'AdditionalProperty.Account': 'חשבון',
    'AdditionalProperty.Position closed because the loss limit was reached': '.פוזיציה {0} תיסגר כי מגבלת ההפסד הושגה',
    'AdditionalProperty.enum_StopOutType_AutoClose': 'קרוב אוטומטי',
    'AdditionalProperty.enum_StopOutType_CloseAllByInstruments': 'סגור את כל המכשירים על ידי',
    'AdditionalProperty.enum_StopOutType_CloseAllPositions': 'סגור את כל פוזיציות',
    'AdditionalProperty.enum_StopOutType_CloseLagestLoss': 'סגור פוזיציה ללא מספיק מרווח (הפסד הגדול הראשון)',
    'AdditionalProperty.enum_StopOutType_ClosePositionsByMarginExcess': 'פוזיציות בקרבת עודף המרווח',
    'AdditionalProperty.enum_StopOutType_DoNotClosePositions': 'אל תסגור פוזיציות',
    'AdditionalProperty.enum_StopOutType_ExternalLossLimit': 'הגבלת הפסד יומי',
    'AdditionalProperty.enum_StopOutType_FIFO': 'סגור פוזיציה ללא מספיק מרווח (FIFO)',
    'AdditionalProperty.enum_StopOutType_LargestFirst': 'סגור פוזיציה ללא מספיק מרווח (הגדול הראשון)',
    'AdditionalProperty.enum_StopOutType_LIFO': 'סגור פוזיציה ללא מספיק מרווח (LIFO)',
    'AdditionalProperty.Margin used': 'ביטחונות בשימוש',
    'AdditionalProperty.MarginCallMessage.header.MarginCallReached': 'שיחת ביטחונות הגיעה',
    'AdditionalProperty.MarginCallMessage.header.MarginWarning': 'אזהרה ביטחונות',
    'AdditionalProperty.MarginCallMessage.Message': 'אנא הפקד אל חשבונך',
    'AdditionalProperty.Message': 'הודעה',
    'AdditionalProperty.Projected balance': 'מאזן צפוי',
    'AdditionalProperty.StopOutType': 'Stop out סוג ',
    'AdditionalProperty.Time': 'תאריך שעה',
    'AdditionalProperty.User': 'משתמש',
    'AdditionalProperty.Unsubscribe': 'ביטול מנוי',
    'AdditionalProperty.Reverse': 'הפוך',
    Alligator: 'אליגטור',
    Aroon: 'Aroon',
    'Average Directional Index': 'מדד כיוונית ממוצע',
    'Average True Range': 'טווח אמיתי ממוצע',
    'Bollinger Band': 'רצועת בולינגר',
    'Bollinger Bands': 'רצועות בולינגר',
    'bonds.couponCycle.1m': 'חודש 1',
    'bonds.couponCycle.1y': 'שנה ‎1',
    'bonds.couponCycle.3m': 'חודשים ‎3',
    'bonds.couponCycle.6m': 'חודשים ‎6',
    'BrokerMessage.Cancel': 'בטל',
    'BrokerMessage.OK': 'בסדר',
    'BrokerMessage.Text': 'הודעת ברוקר',
    'BusinessRejectMessage.201': 'סיסמא חדשה מכילה סימולים אסורים',
    'BusinessRejectMessage.202': 'סיסמה לא יכולה להיות זהה לשם ההתחברות',
    'BusinessRejectMessage.203': 'נא להזין את הסיסמה החדשה',
    'BusinessRejectMessage.221': '.הסיסמה החדשה צריכה לכלול לפחות אות אחת גדולה, אות קטנה אחת, מספר 1 ואינה יכולה להכיל תו (&). אנא נסו שנית',
    'BusinessRejectMessage.222': '.לא ניתן לשנות סיסמה: הסיסמה החדשה צריכה להכיל לפחות תו גדול 1, תו קטן 1, מספר 1 ומכילה 8 תווים לפחות. בבקשה נסה שוב',
    'BusinessRejectMessage.223': 'על הסיסמה החדשה לכלול לפחות תו גדול אחד, תו קטן אחד, מספר אחד, תו מיוחד אחד (למשל @#S%), ואינה יכולה להכיל תו (&). אנא נסו שנית.',
    'BusinessRejectMessage.28': 'סיסמא נוכחית שגויה',
    'BusinessRejectMessage.31': 'סיסמא חדשה שגויה: אתה לא יכול להשתמש באותה סיסמא',
    'BusinessRejectMessage.338': '.סיסמא לביטול נעילת מסחר אסורה ידי היגיון ביטול נעילת המסחר',
    'BusinessRejectMessage.339': '.לא ניתן לשנות את סיסמת המסחר מכיוון שסיסמת המסחר הישנה אינה תקפה',
    'BusinessRejectMessage.433': '.המגבלה הכוללת של ההתראות הושגה',
    'BusinessRejectMessage.434': '.לא ניתן היה לקבל את ההתראה אנא צרו קשר עם צוות התמיכה',
    'BusinessRejectMessage.435': '.מחירי ביקוש/היצע אינם זמינים עבור כלי זה',
    'corporateActionType.BonusIssue': 'מניות בונוס',
    'corporateActionType.StockDividends': 'דיבידנדים במניה',
    'Chande Momentum Oscillator': 'Chande מתנד מומנטום ',
    'chart.agregationType.Button.ToolTip': 'השתמש בסוגי צבירה שהוגדרו מראש',
    'chart.AllowedPeriods.15M': '15m',
    'chart.AllowedPeriods.1D': '1D',
    'chart.AllowedPeriods.1H': '1H',
    'chart.AllowedPeriods.1M': '1M',
    'chart.AllowedPeriods.1S': '1S',
    'chart.AllowedPeriods.1W': '1W',
    'chart.AllowedPeriods.1Y': '1Y',
    'chart.AllowedPeriods.30M': '30m',
    'chart.AllowedPeriods.4H': '4H',
    'chart.AllowedPeriods.5M': '5M',
    'chart.AllowedPeriods.Month': '1M',
    'chart.AllowedPeriods.Range': 'טווח',
    'chart.AllowedPeriods.Ticks': 'טיקים',
    'chart.ChartPanel.accountLookup.ToolTip': 'בחר חשבון',
    'chart.chartScroll.zoomInButton': 'הצרה',
    'chart.chartScroll.zoomOutButton': 'הרחבה',
    'chart.ChartSymbolSelector.addIndicator.ButtonToolTip': 'אינדיקטור הוספה',
    'chart.ChartSymbolSelector.drawingButton.ToolTip': 'הצג/הסתר כלי ציור',
    'chart.ChartSymbolSelector.mouseTradeButton.ToolTip': 'מסחר בעכבר',
    'chart.ChartSymbolSelector.oeButton.ToolTip': 'תרשים כניסה להזמין',
    'chart.ChartSymbolSelector.oeButton': 'הכנסת הוראה',
    'chart.ChartSymbolSelector.OverlayTooltip': 'הוסף שכבה',
    'Chart.chartWindow.auto': 'אוטומטי',
    'Chart.chartWindow.manual': 'מדריך ל',
    'chart.chartWindowManager.SetAuto': 'החלף לקנה מידת מחיר אוטומטי',
    'chart.chartWindowManager.SetManual': 'החלף לקנה מידת מחיר ידני',
    'chart.head.addOverlay': '...הוסף שכבה ',
    'chart.head.charttype': 'סגנון',
    'chart.head.line': 'כלי ציור',
    'chart.head.overlayRemoveAll': 'הסר הכל',
    'chart.head.overlays': 'שכבות',
    'chart.indicator': 'אינדיקטור',
    'chart.indicatorAdd': 'אינדיקטור הוספה',
    'chart.indicatorRemoveAll': 'הסר הכל',
    'chart.indicators': 'אינדיקטורים',
    'chart.Infoline.days': 'ימים',
    'chart.Infoline.hours': 'שעה/ות',
    'chart.Infoline.min': 'מינימום',
    'chart.Infoline.price': 'מחיר',
    'chart.Infoline.sec': 'שניה',
    'chart.Infoline.time': 'זמן',
    'chart.infoWindow.Items.Ask': ':היצע',
    'chart.infoWindow.Items.Bars': '#Bars:',
    'chart.infoWindow.Items.Bid': ':ביקוש',
    'chart.infoWindow.Items.Change': '% ,שינוי',
    'chart.infoWindow.Items.Close': ':סגור',
    'chart.infoWindow.Items.Date': ':תאריך',
    'chart.infoWindow.Items.High': ':גבוה',
    'chart.infoWindow.Items.Low': ':נמוך',
    'chart.infoWindow.Items.Open': ':פתח',
    'chart.infoWindow.Items.Ticks': ':טיקים',
    'chart.infoWindow.Items.Time': ':זמן',
    'chart.infoWindow.Items.Volume': ':נפח',
    'chart.instrumentsLookup': 'חיפוש סימולים',
    'chart.layerManager': '...הגדרות ',
    'chart.loading': '...טוען',
    'chart.mainlayer.gridPrice': 'Grid מחיר ',
    'chart.mainlayer.gridTime': 'זמן גריד',
    'chart.mainlayer.volume': 'ברי נפח',
    'chart.menu.cancelByAccount': 'בטל הכל לפי חשבון',
    'chart.menu.cancelByInstrument': 'בטל הכל לפי סימול',
    'chart.menu.cancelOrder': 'בטל הוראה',
    'chart.menu.close': 'פוזיציה סגורה ',
    'chart.menu.closeall': '...סגור את כל פוזיציות ',
    'chart.menu.InfoWindowHide': 'להתחבא',
    'chart.menu.InfoWindowSettings': 'הגדרות',
    'chart.menu.InfoWindowShortMode': 'Short מצב ',
    'chart.menu.modify': 'שנה',
    'chart.menu.PlateIndicatorDublicate': 'Dublicate אינדיקטור ',
    'chart.menu.PlateIndicatorRemove': 'סגירה',
    'chart.menu.PlateIndicatorSettings': 'הגדרות',
    'chart.menu.PlateIndicatorVisible': 'גלוי',
    'chart.menu.PlateOverlay.Overlay': 'כיסוי',
    'chart.menu.PlateOverlayRemove': 'סגירה',
    'chart.menu.PlateOverlaySettings': 'הגדרות',
    'chart.menu.PlateOverlayVisible': 'גלוי',
    'chart.noAvailableData': 'אין נתונים זמינים עבור',
    'chart.NoData': 'אין נתונים',
    'chart.priceScaleContextMenu.AutoScale': 'אוטומטי',
    'chart.priceScaleContextMenu.FitAlerts': 'התאם התראות',
    'chart.priceScaleContextMenu.FitDay': 'התאם יום גבוה/נמוך',
    'chart.priceScaleContextMenu.FitDrawings': 'התאם ציורים',
    'chart.priceScaleContextMenu.FitIndicators': 'Fit אינדיקטורים ',
    'chart.priceScaleContextMenu.FitOrdersPositions': 'התאם הוראות/פוזיציות',
    'chart.priceScaleContextMenu.ManualScale': 'מדריך ל',
    'chart.quickTrading.Buy limit': 'Buy Limit',
    'chart.quickTrading.Buy stop': 'Buy Stop',
    'chart.quickTrading.Buy stop limit': 'Buy Stop Limit',
    'chart.quickTrading.Invalid price': 'המחיר אינו חוקי',
    'chart.quickTrading.Sell limit': 'Sell Limit',
    'chart.quickTrading.Sell stop': 'Stop מכירת ',
    'chart.quickTrading.Sell stop limit': 'Stop Limit מכירת ',
    'chart.refresh': 'רענן',
    'chart.Remove': 'הסר',
    'chart.shortPeriodName.m': 'M',
    'chart.shortPeriodName.ticks': 'טיקים',
    'chart.ShowHighLow.Close': 'סגירה',
    'chart.ShowHighLow.High': 'גבוה',
    'chart.ShowHighLow.Low': 'נמוך',
    'chart.StyleButton.ToolTip': 'סגנון',
    'chart.tic.short.15min.name': '15m',
    'chart.tic.short.1day.name': '1D',
    'chart.tic.short.1hour.name': '1H',
    'chart.tic.short.1min.name': '1M',
    'chart.tic.short.1tic.name': 'מדרגה',
    'chart.tic.short.30min.name': '30m',
    'chart.tic.short.4hour.name': '4H',
    'chart.tic.short.5min.name': '5M',
    'chart.tic.short.month.name': '1M',
    'chart.tic.short.range.name': 'טווח',
    'chart.tic.short.second.name': 'ים',
    'chart.tic.short.week.name': '1W',
    'chart.tic.short.year.name': '1Y',
    'chart.timeframeselector.rangeButton.ToolTip': 'בחר תקופת נתונים להצגה',
    'chart.timeframeselector.time': 'זמן',
    'chart.timeScaleContextMenu.ShowAllSessions': 'הצג אירועים מורחבים',
    'chart.timeScaleContextMenu.ShowDaySeparator': 'הצג מפריד ימים',
    'chart.timeScaleContextMenu.ShowEmptyBars': 'הצג חורים בהיסטוריה',
    'chart.timeScaleContextMenu.ShowMonthSeparator': 'הצג מפריד חודשים',
    'chart.timeScaleContextMenu.ShowTimePeriodsSeparator': 'צג מפרידי תקופות זמן',
    'chart.timeScaleContextMenu.ShowWeeksSeparator': 'הצג מפריד שבועות',
    'chart.timeScaleContextMenu.ShowYearSeparator': 'הצג מפריד שנים',
    'chart.tool.bar': 'עמודה',
    'chart.tool.candle': 'נר',
    'chart.tool.clone': 'שיבוט',
    'chart.tool.Dash': 'לזנק',
    'chart.tool.delete': 'הסר',
    'chart.tool.deleteAll': 'הסר הכל',
    'chart.tool.dot': 'נקודות',
    'chart.tool.dotline': 'קו מקווקו',
    'chart.tool.DottedLine': 'קו מקווקו',
    'chart.tool.forest': 'היסטוגרמה',
    'chart.tool.IsoDot': 'Iso Dot',
    'chart.tool.line': 'קו',
    'chart.tool.properties': 'הגדרות',
    'chart.tool.ShapedLine': 'קו בצורת',
    'chart.tool.solid': 'פינה',
    'chart.tools.ABCPatern': 'ABC דפוס ',
    'chart.tools.AndrewPitchfork': 'הקלשון של אנדרו',
    'chart.tools.ButterflyGartley': 'Gartley פרפר ',
    'chart.tools.Circle': 'מעגל',
    'chart.tools.default': 'פוינטר',
    'chart.tools.Ellipse': 'אֶלִיפְּסָה',
    'chart.tools.eraser': 'מחק',
    'chart.tools.FibArc': 'קשת פיבונאצ\'י',
    'chart.tools.FibEllipses': 'אליפסות פיבונאצ\'י',
    'chart.tools.FibExp': 'הרחבת פיבונאצ\'י',
    'chart.tools.FibFan': 'אוהדי פיבונאצ\'י',
    'chart.tools.FibPhiChannel': 'ערוצי Phi פיבונאצ\'י',
    'chart.tools.Fibretr': 'פיבונאצ\'י',
    'chart.tools.FibSpiral': 'ספירלת פיבונאצ\'י',
    'chart.tools.FibTga': 'ניתוח זמן המטרה פיבונאצ\'י',
    'chart.tools.FibTz': 'אזור זמן פיבונאצ\'י',
    'chart.tools.GannFan': 'אוהד גאן',
    'chart.tools.GannGrid': 'רשת גאן',
    'chart.tools.GannLine': 'קו גאן',
    'chart.tools.horizontal': 'קו אופקי',
    'chart.tools.infoline': 'סרגל',
    'chart.tools.Line': 'קו',
    'chart.tools.mirrored': 'מבט במראה הפעל',
    'chart.tools.points': 'נקודת ספירה',
    'chart.tools.Polygon': 'מצולע',
    'chart.tools.PPZ': ' (מחיר ציר אזור) PPZ',
    'chart.tools.PriceChannel': 'ערוץ מחיר',
    'chart.tools.Rectangle': 'מלבן',
    'chart.tools.RegressionChanel': 'ערוץ נסיגה',
    'chart.tools.ScalingByRect': 'קנה מידה של מלבן',
    'chart.tools.selection': 'בחירה',
    'chart.tools.snapToCandle': 'הצמד לנר',
    'chart.tools.StayDrawingMode': 'השאר במצב ציור',
    'chart.tools.symbol': 'סימן',
    'chart.tools.text': 'תגובות',
    'chart.tools.TopBottomPattern': 'תבנית עליון תחתון',
    'chart.tools.Triangle': 'משולש',
    'chart.tools.vertical': 'קו אנכי',
    'chart.view.analyseGroupMenu': 'כלי ניתוח',
    'chart.view.Day_HighLow': 'גבוה / נמוך יומי',
    'chart.view.drawingtoolbar': 'סרגל כלי ציור',
    'chart.view.landscapequestion': '?להשתמש בסידור נייר אופקי',
    'chart.view.orderEntry': 'תרשים כניסה להזמין',
    'chart.view.previousCloseLine': 'סגירה קודמת',
    'chart.view.PrevSettlementPriceLine': 'מחיר הסתדרות קודם',
    'chart.view.SettlementPriceLine': 'מחיר הסדר',
    'chart.view.source': 'סוג מידע',
    'chart.view.source0': 'סימול ברירת מחדל',
    'chart.view.source1': 'הצעת מחיר',
    'chart.view.source1000': 'היצע',
    'chart.view.source4': 'אחרון',
    'chart.view.sourceBID/ASK': 'לפי הצעה / שאל',
    'chart.view.TimeToNextBar': 'זמן לבר הבא',
    'chart.view.toolbar': 'סרגל כלים',
    'chart.view': 'הצג',
    'chart.visualTrading.Invalid price': 'המחיר אינו חוקי',
    'chart.volumeColoringMode.ByBar': 'על ידי בר',
    'chart.volumeColoringMode.ByDifference': 'לפי הפרש',
    'chart.volumeColoringMode.Fixed': 'תוקן',
    'chart.volumeColoringMode.ByMovingAverage': 'מעל / מתחת לממוצע נע',
    'Commodity Channel Index': 'ערוץ מדד סחורות ',
    'connect.connection.connect.error': 'פרמטרי חיבורים לא נכונים',
    Custom: 'מותאם אישית',
    Day: 'יום',
    'dealticket.RiskRuleWarning.AccountName': 'שם החשבון',
    'dealticket.RiskRuleWarning.UserName': 'שם משתמש',
    Deutsch: '#hidden#',
    'disconnectMessageBox.Header': 'חיבור',
    'disconnectMessageBox.Text': '.החיבור ירד בעוד {0} שניות עקב חוסר פעילות. לחץ "התנתקות" לוותר על החיבור כעת, או לחץ על "ביטול" כדי להישאר מחובר',
    'disconnectMessageBox.logoutButton': 'התנתק',
    'disconnectMessageBox.cancelButton': 'בטל',
    'disconnectMessageBox.logoutButton.descr': '.אם ילחצו החיבור ייפול כעת עקב חוסר פעילות',
    'disconnectMessageBox.cancelButton.descr': '.אם ילחצו החיבור ייפול כעת עקב חוסר פעילות',
    'dynProperty.inactive.tooltip': '!לא ניתן לשנות הגדרה במצב לא מחובר',
    'enum.AccountType.ClosedFund': 'קרן סגורה',
    'enum.AccountType.MultiAsset': 'רב נכס',
    'enum.AccountType.OpenedFund': 'הון פתוח',
    'enum.AccountType.SingleCCY': 'מטבע אחיד',
    'enum.AssetType.COMMODITIES': 'סחורות',
    'enum.AssetType.CRYPTO_CCY': 'מטבע קריפטו',
    'enum.AssetType.CURRENCY': 'מטבע',
    'enum.AssetType.SHARES': 'מניות',
    'ExchangeId.All': 'כל הבורסות',
    'Exponential Moving Average': 'ממוצע נע מעריכים',
    FOK: 'FOK',
    'FullLogs.Cluster': 'שרת',
    'FullLogs.DateTime': 'זמן',
    'FullLogs.Fields': 'שדות',
    'FullLogs.FieldsNew': 'שדות חדשים',
    'FullLogs.Message name': 'שם ההודעה',
    'FullLogs.Mode': 'מצב',
    'FullLogs.Session': 'שלב מסחר',
    'general.Calculating': '...חישוב ',
    'general.connection.CancelConnectRequested': 'בטל  בקשה לחיבור',
    'general.connection.fullInfo': 'מידע מלא',
    'general.connection.lastMessage': 'ההודעה האחרונה הייתה',
    'general.connection.Login/password combination is not valid. Attempt': '.{שילוב הכניסה / סיסמא אינו חוקי. ניסיון {0',
    'general.connection.Login/password combination is not valid.': '.שילוב הכניסה / סיסמא אינו חוקי',
    'general.connection.status.Connected': 'Connected',
    'general.connection.status.Disconnected': 'מנותק',
    'general.connection.status.Reconnecting': 'מתחבר מחדש',
    'general.connection.status': 'מצב',
    'general.connection.Verification password not valid.': 'סיסמת האימות אינה תקפה',
    'general.CUSTOM': 'מותאם אישית',
    'general.Friday': 'יום שישי',
    'general.Instrument': 'נכס',
    'general.messageBox.cancel': 'בטל',
    'general.messageBox.no': 'לא',
    'general.messageBox.ok': 'בסדר',
    'general.messageBox.showNextTime': 'הצג בפעם הבאה',
    'general.messageBox.yes': 'כן',
    'general.Monday': 'יום שני',
    'general.month.1': 'ינו',
    'general.month.10': 'אוקטובר',
    'general.month.11': 'נובמבר',
    'general.month.12': 'דצמבר',
    'general.month.2': 'פבואר',
    'general.month.3': 'מוּם',
    'general.month.4': 'אפריל',
    'general.month.5': 'מאי',
    'general.month.6': 'יוני',
    'general.month.7': 'יולי',
    'general.month.8': 'אוגוסט',
    'general.month.9': 'ספטמבר',
    'general.monthFullName.1': 'ינואר',
    'general.monthFullName.10': 'אוקטובר',
    'general.monthFullName.11': 'נובמבר',
    'general.monthFullName.12': 'דצמבר',
    'general.monthFullName.2': 'פברואר',
    'general.monthFullName.3': 'מרץ',
    'general.monthFullName.4': 'אפריל',
    'general.monthFullName.5': 'מאי',
    'general.monthFullName.6': 'יוני',
    'general.monthFullName.7': 'יולי',
    'general.monthFullName.8': 'אוגוסט',
    'general.monthFullName.9': 'ספטמבר',
    'general.N_A': 'N / A',
    'general.NoData...': 'אין נתונים',
    'general.OvernightMarginNotificationMessage': 'הצג הודעת התראה על ביטחונות למשך לילה',
    'general.Saturday': 'יום שבת',
    'general.Sunday': 'יום ראשון',
    'general.Thursday': 'יום חמישי',
    'general.trading.at': 'ב',
    'general.trading.Buy': 'קנייה',
    'general.trading.confirmation.prefix': '',
    'general.trading.confirmation': 'אישור',
    'general.trading.confirmationString': '{1} {2} {3} {4} {5} {6} {7} {8} {9} {10} {14} {13} {14} {15} {11} {12} {16}',
    'general.trading.for': 'ל',
    'general.trading.limitBuyLessAsk': '.מחיר סדר הגבל לקנות יותר מ השוק "תשאלו מחיר, ההוראה תבוצע מיד',
    'general.trading.limitSellMoreBid': '.מחיר הגבל מכירת סדר הוא פחות מ מחיר ההצעה בשוק, הוראה תבוצע מייד',
    'general.trading.marketCloseFor': 'סגור שוק עבור',
    'general.trading.pips': 'טיקים',
    'general.trading.points': 'נקודות',
    'general.trading.position.Buy': 'Long',
    'general.trading.position.Sell': 'Short',
    'general.trading.position': 'פוזיציה',
    'general.trading.qtyMoreTodayTradedValue': 'הערך לא יכול להיות גדול מהכמות הנסחרת היום',
    'general.trading.refused': 'סירב',
    'general.trading.Sell': 'מכירה',
    Stop: 'Stop',
    'general.trading.stopBuyMoreAsk': 'מחיר הוראת Stop Buy נמוך ממחיר הביקוש בשוק, ההוראה תתממש מיידית.',
    'general.trading.stopSellLessBid': 'מחיר הוראת Stop Sell נמוך ממחיר הביקוש בשוק, ההוראה תתממש מיידית.',
    'general.trading.with offset': 'עם קיזוז',
    'general.Tuesday': 'יום שלישי',
    'general.WarnIfOrderRepeat': 'הזהר אם בוצעה הוראה עוקבת זהה (שנייה)',
    'general.Wednesday': 'יום רביעי',
    GTC: 'GTC',
    Ichimoku: 'Ichimoku',
    'Instrument.Corporate.Securities': 'ניירות ערך',
    'Instrument.Futures.contract': 'החוזה',
    'Instrument.Level1.ExtendedFields.NormalMarketSize': 'גודל שוק רגיל',
    'Instrument.Spreadbet.bet': 'הימורים',
    'InstrumentDetailsPanel.(Indicative symbol)': '(סימול אינדיקטיבי)',
    'InstrumentDetailsPanel.1.GeneralInfo': 'מידע כללי',
    'InstrumentDetailsPanel.2.TradingInfo': 'מידע על מסחר',
    'InstrumentDetailsPanel.3.MarginReg': 'דרישות הביטחונות',
    'InstrumentDetailsPanel.4.Fees': 'עמלות',
    'InstrumentDetailsPanel.5.Rebates': 'הנחות',
    'InstrumentDetailsPanel.6.SessionInfo': 'לוח זמנים בשוק אינדיקטיבי',
    'InstrumentDetailsPanel.7.Plans': 'תוכניות',
    'InstrumentDetailsPanel.Account.descr': 'שם החשבון',
    'InstrumentDetailsPanel.AccruedInterest.descr': 'LOT ריבית נוכחית שנצברה שחושבה 1 ',
    'InstrumentDetailsPanel.AccruedInterest': 'ריבית שנצברה',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot.descr': 'כלומר עבור כל לוט בעסקה שבוצעה נוצר ערך ההנחה הקבוע יחויב / יוחזר (במטבע התכנית)',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot': 'יצרן הוראות לפי לוט',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot.descr': 'כלומר עבור כל לוט בעסקה שבוצעה נוצר ערך ההנחה הקבוע יחויב / יוחזר (במטבע התכנית)',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot': 'יצרן לפי לוט',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume.descr': 'כלומר חיוב/החזר משוכלל יחושב כאחוז מן נפח המסחר',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume': 'יצרן לפי ווליום',
    'InstrumentDetailsPanel.AFTER_MARKET': 'אחרי השוק',
    'InstrumentDetailsPanel.Allow short positions': 'כן',
    'InstrumentDetailsPanel.AllowedOperations.descr': 'מציג פעולות מאושרות לסימול הנתון',
    'InstrumentDetailsPanel.AllowedOperations': 'פעולות מותרות',
    'InstrumentDetailsPanel.AllowedOrderTypes.descr': 'מציג סוגי הוראה מאושרים לסימול הנתון',
    'InstrumentDetailsPanel.AllowedOrderTypes': 'סוגים מנת להביאם למלון',
    'InstrumentDetailsPanel.AllowOvernightTrading': 'אפשר מסחר הלילה',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery.descr': 'מציג האם מותר או לא לפתוח פוזיציות שורט לסימול הנתון',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery': 'משלוח לאפשר פוזיציות שורטות',
    'InstrumentDetailsPanel.AllowShortPositions.descr': 'מציג האם מותר או לא לפתוח פוזיציות שורט לסימול הנתון',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday.descr': 'מציג האם מותר או לא לפתוח פוזיציות שורט לסימול הנתון',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday': 'אפשר פוזיציות שורט תוך יומי ',
    'InstrumentDetailsPanel.AllowShortPositions': 'אפשר פוזיציות שורטות',
    'InstrumentDetailsPanel.Amount': '.כמות',
    'InstrumentDetailsPanel.AssetClass.descr': 'סוג סימול נסחרים בבורסה',
    'InstrumentDetailsPanel.AssetClass': 'סוג סימול',
    'InstrumentDetailsPanel.BEFORE_MARKET': 'לפני השוק',
    'InstrumentDetailsPanel.Cash': 'מזומן',
    'InstrumentDetailsPanel.Close': 'סגירה',
    'InstrumentDetailsPanel.Closed': 'סגירה',
    'InstrumentDetailsPanel.CloseOutDeadline.descr': 'תאריך כאשר הפוזיציות תהיינה סגורות על ידי כל חוזה ספציפי',
    'InstrumentDetailsPanel.CloseOutDeadline': 'מועד אחרון מקרוב החוצה',
    'InstrumentDetailsPanel.Commisions': 'commisions',
    'InstrumentDetailsPanel.ContactMonth.descr': '.תאריך פקיעת החוזה. זמין עבור אופציות וחוזים עתידיים',
    'InstrumentDetailsPanel.ContactMonth': 'חודש חוזה',
    'InstrumentDetailsPanel.ContractMultiplier': '#hidden#',
    'InstrumentDetailsPanel.CouponCycle': 'מחזור קופון',
    'InstrumentDetailsPanel.CouponRate': '% ,שיעור קופון',
    'InstrumentDetailsPanel.CurrentSession.descr': 'מידע על יום המסחר הנוכחי',
    'InstrumentDetailsPanel.CurrentSession': 'הפעלה נוכחית',
    'InstrumentDetailsPanel.CustodialFee': 'משמורת',
    'InstrumentDetailsPanel.CustodialFee.descr': 'דמי משמורת',
    'InstrumentDetailsPanel.DeliveryMethod.descr': 'סוג הצגת נכס',
    'InstrumentDetailsPanel.DeliveryMethod': 'שיטת אספקה',
    'InstrumentDetailsPanel.DeliveryStatus.descr': 'מעמד מסירת נכסים',
    'InstrumentDetailsPanel.DeliveryStatus': 'סטטוס הגשה',
    'InstrumentDetailsPanel.Depend from account': 'תלוי בחשבון',
    'InstrumentDetailsPanel.Description.descr': 'מידע מפורט על הסימול',
    'InstrumentDetailsPanel.Description': 'תיאור',
    'InstrumentDetailsPanel.ETB': '#hidden#',
    'InstrumentDetailsPanel.ETB.descr': 'ETB אומר שנמצא ברשימת המניות הקלות להשאלה',
    'InstrumentDetailsPanel.ExchangeMarketData': 'חילופי נתוני שוק',
    'InstrumentDetailsPanel.ExchangeTradeSession': 'שלב בורסה',
    'InstrumentDetailsPanel.ExchangeTrading': 'בורסת מסחר',
    'InstrumentDetailsPanel.Exp1': 'נכס',
    'InstrumentDetailsPanel.FaceValue': 'ערך נקוב',
    'InstrumentDetailsPanel.FirstTradeDate.descr': '.התאריך שבו ניתן לסחור בחוזה ראשית. זמין עבור אופציות וחוזים עתידיים',
    'InstrumentDetailsPanel.FirstTradeDate': 'תאריך מסחר ראשון',
    'InstrumentDetailsPanel.Futures': 'חוזים עתידיים',
    'InstrumentDetailsPanel.FuturesClass': 'קטגוריית חוזים עתידיים',
    'InstrumentDetailsPanel.HiLimit.descr': 'הגבול העליון של הלהקה במחיר המוותרת שמעליו הוראת עיכוב לא יתקבלו',
    'InstrumentDetailsPanel.HiLimit': 'מגבלה גבוהה',
    'InstrumentDetailsPanel.Historical swap last updated on': 'Swap היסטורי אחרון עודכן ב',
    'InstrumentDetailsPanel.Latest historical long swap': 'Long Swap מועד היסטורי אחרון של  ',
    'InstrumentDetailsPanel.Latest historical short swap': 'Short Swap מועד היסטורי אחרון של ',
    'InstrumentDetailsPanel.Holiday': 'חג',
    'InstrumentDetailsPanel.HolidayDate': 'תאריך',
    'InstrumentDetailsPanel.HolidayName': 'שם החג',
    'InstrumentDetailsPanel.Holidays list': 'רשימת חגים',
    'InstrumentDetailsPanel.HolidayType': 'לוח זמנים',
    'InstrumentDetailsPanel.immediate': 'מיידי',
    'InstrumentDetailsPanel.ISIN.descr': 'מספר זיהוי ניירות בינלאומיים',
    'InstrumentDetailsPanel.ISIN': 'ISIN',
    'InstrumentDetailsPanel.KEYNextHoliday.descr': 'מציג תאריך של החג הבא שבו מסחר לא מותר',
    'InstrumentDetailsPanel.KEYNextHoliday': 'החג הבא',
    'InstrumentDetailsPanel.LastTradeDate.descr': 'Forward תאריך כאשר החוזה ניתן לסחור בפעם האחרונה. זמין עבור אופציות, חוזים עתידיים ו ',
    'InstrumentDetailsPanel.LastTradeDate': 'תאריך מסחר אחרון',
    'InstrumentDetailsPanel.LotSize.descr': 'סכום נכס הבסיס עבור לוט אחד',
    'InstrumentDetailsPanel.LotSize': 'גודל לוט',
    'InstrumentDetailsPanel.LotStep.descr': 'שלב של שינויי לוט',
    'InstrumentDetailsPanel.LotStep': 'צעד לוט',
    'InstrumentDetailsPanel.LowLimit.descr': 'הגבול התחתון של הכיפוף במחיר המותרת שמעליו הוראות מעוכבות אינן מתקבלות',
    'InstrumentDetailsPanel.LowLimit': 'גבול נמוך',
    'InstrumentDetailsPanel.MAIN.descr': 'מידע על הגדרות עבור שלב מסחר ראשי',
    'InstrumentDetailsPanel.MAIN': 'ראשי',
    'InstrumentDetailsPanel.Margin.Delivery.descr': 'מציג דרישות ביטחונות אשר תלויות בסוג חישוב הביטחונות',
    'InstrumentDetailsPanel.Margin.Delivery': 'דרישת בטחונות שילוח',
    'InstrumentDetailsPanel.Margin.descr': 'מציג דרישות ביטחונות אשר תלויות בסוג חישוב הביטחונות',
    'InstrumentDetailsPanel.Margin.Intraday.descr': 'מציג דרישות ביטחונות אשר תלויות בסוג חישוב הביטחונות',
    'InstrumentDetailsPanel.Margin.Intraday': 'דרישת ביטחונות תוך יומית',
    'InstrumentDetailsPanel.Margin_ByAccount.descr': 'מציג דרישות ביטחונות מבוססים על מקדמי החשבון',
    'InstrumentDetailsPanel.Margin_ByAccount': 'ביטחונות לפי חשבון',
    'InstrumentDetailsPanel.Margin': '.ביטחונות דרושים',
    'InstrumentDetailsPanel.MarginBuy.descr': 'מציג דרישות מרווח עבור פוזיציות Long המשתמשות במרווח Long/Short שונה ואינן תלויות במרווח לילה',
    'InstrumentDetailsPanel.MarginBuy.Delivery': 'לקנות משלוח',
    'InstrumentDetailsPanel.MarginBuy.Intraday.descr': 'מציג דרישות מרווח עבור פוזיציות Long המשתמשות במרווח Long/Short שונה ואינן תלויות במרווח לילה',
    'InstrumentDetailsPanel.MarginBuy.Intraday': 'לקנות תוך יומי',
    'InstrumentDetailsPanel.MarginBuy': 'קנייה',
    'InstrumentDetailsPanel.MarginDay.Delivery.descr': 'Long/Short מציג דרישות ביטחונות אשר אינן תלויות בביטחונות של ',
    'InstrumentDetailsPanel.MarginDay.descr': 'Long/Short מציג דרישות ביטחונות ביום שאינן תלויות במרווח ',
    'InstrumentDetailsPanel.MarginDay.Intraday.descr': 'Long/Short מציג דרישות ביטחונות אשר אינן תלויות בביטחונות של ',
    'InstrumentDetailsPanel.MarginDay': 'יום',
    'InstrumentDetailsPanel.MarginDayBuy.Delivery.descr': 'מציג מקדמי יום התחלתיים ומרווח תחזוקה לפוזיציית לונג',
    'InstrumentDetailsPanel.MarginDayBuy.descr': 'מציג מקדמי יום של המרווח ההתחלתי ותחזוקה עבור פוזיציית לונג',
    'InstrumentDetailsPanel.MarginDayBuy.Intraday.descr': 'מציג מקדמי יום התחלתיים ומרווח תחזוקה לפוזיציית לונג',
    'InstrumentDetailsPanel.MarginDayBuy': 'קניה יומית',
    'InstrumentDetailsPanel.MarginDaySell.Delivery.descr': 'מציג מקדמי יום התחלתיים ומרווח תחזוקה לפוזיציית שורט',
    'InstrumentDetailsPanel.MarginDaySell.descr': 'מציג מקדמי יום של המרווח ההתחלתי ותחזוקה עבור פוזיציית השורט',
    'InstrumentDetailsPanel.MarginDaySell.Intraday.descr': 'מציג מקדמי יום התחלתיים ומרווח תחזוקה לפוזיציית שורט',
    'InstrumentDetailsPanel.MarginDaySell': 'מכירה יומית',
    'InstrumentDetailsPanel.MarginInAccountCurrency': '#hidden#',
    'InstrumentDetailsPanel.MarginOvernight.Delivery.descr': 'Long/Short מציג דרישות ביטחונות ללילה שאינן תלויות במרווח ',
    'InstrumentDetailsPanel.MarginOvernight.descr': 'Long/Short מציג דרישות ביטחונות ללילה שאינן תלויות במרווח ',
    'InstrumentDetailsPanel.MarginOvernight.Intraday.descr': 'Long/Short מציג דרישות ביטחונות ללילה שאינן תלויות במרווח ',
    'InstrumentDetailsPanel.MarginOvernight': 'בין לילה',
    'InstrumentDetailsPanel.MarginOvernightBuy.Delivery.descr': 'מציג את המקדמים של המרווח ההתחלתי ותחזוקה להעברת לילה בפוזיציית לונג',
    'InstrumentDetailsPanel.MarginOvernightBuy.descr': 'מציג את המקדמים של המרווח ההתחלתי ותחזוקה להעברת לילה בפוזיציית לונג',
    'InstrumentDetailsPanel.MarginOvernightBuy.Intraday.descr': 'מציג את המקדמים של המרווח ההתחלתי ותחזוקה להעברת לילה בפוזיציית לונג',
    'InstrumentDetailsPanel.MarginOvernightBuy': 'Buy למשך לילה',
    'InstrumentDetailsPanel.MarginOvernightSell.Delivery.descr': 'מציג את המקדמים של המרווח ההתחלתי ותחזוקה להעברת לילה בפוזיציית שורט',
    'InstrumentDetailsPanel.MarginOvernightSell.descr': 'מציג את המקדמים של המרווח ההתחלתי ותחזוקה להעברת לילה בפוזיציית שורט',
    'InstrumentDetailsPanel.MarginOvernightSell.Intraday.descr': 'מציג את המקדמים של המרווח ההתחלתי ותחזוקה להעברת לילה בפוזיציית שורט',
    'InstrumentDetailsPanel.MarginOvernightSell': 'Sell למשך לילה',
    'InstrumentDetailsPanel.MarginSell.Delivery.descr': 'מציג דרישות ביטחונות מרווח עבור פוזיציות Short המשתמשות במרווח Long/Short שונה ואינן תלויות במרווח לילה',
    'InstrumentDetailsPanel.MarginSell.Delivery': 'למכור משלוח',
    'InstrumentDetailsPanel.MarginSell.descr': 'מציג דרישות ביטחונות מרווח עבור פוזיציות Short המשתמשות במרווח Long/Short שונה ואינן תלויות במרווח לילה',
    'InstrumentDetailsPanel.MarginSell.Intraday.descr': 'מציג דרישות ביטחונות מרווח עבור פוזיציות Short המשתמשות במרווח Long/Short שונה ואינן תלויות במרווח לילה',
    'InstrumentDetailsPanel.MarginSell.Intraday': 'מכירה תוך יומית',
    'InstrumentDetailsPanel.MarginSell': 'מכירה',
    'InstrumentDetailsPanel.MaturityDate.descr': 'תאריך פקיעת החוזה',
    'InstrumentDetailsPanel.MaturityDate': 'מועד פירעון',
    'InstrumentDetailsPanel.MaximumLot.Delivery.descr': 'המסחר הגדול ביותר המותר בלוט',
    'InstrumentDetailsPanel.MaximumLot.Delivery': 'הרבה מרבי משלוח',
    'InstrumentDetailsPanel.MaximumLot.descr': 'המסחר הגדול ביותר המותר בלוט',
    'InstrumentDetailsPanel.MaximumLot.Intraday.descr': 'המסחר הגדול ביותר המותר בלוט',
    'InstrumentDetailsPanel.MaximumLot.Intraday': 'הרבה מקסימלית תוך יומי',
    'InstrumentDetailsPanel.MaximumLot': 'מקסימום לוט',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery.descr': 'כמות סכום מקסימלי של כל פוזיציות לכל כלי בלוט',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery': 'כמות משלוח עמדה מקס',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.descr': 'כמות סכום מקסימלי של כל פוזיציות לכל כלי בלוט',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday.descr': 'כמות סכום מקסימלי של כל פוזיציות לכל כלי בלוט',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday': 'כמות פוזיציות מקסימלית תוך יומית',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol': '.כמות פוזיציות מקסמלית',
    'InstrumentDetailsPanel.MinimalLot.Delivery.descr': 'החלק הקטן ביותר של לוט עבורו מסחר זמין',
    'InstrumentDetailsPanel.MinimalLot.Delivery': 'משלוח הרבה מינימלי',
    'InstrumentDetailsPanel.MinimalLot.descr': 'החלק הקטן ביותר של לוט עבורו מסחר זמין',
    'InstrumentDetailsPanel.MinimalLot.Intraday.descr': 'החלק הקטן ביותר של לוט עבורו מסחר זמין',
    'InstrumentDetailsPanel.MinimalLot.Intraday': 'כמות מינימאלית תוך יומי',
    'InstrumentDetailsPanel.MinimalLot': 'לוט מינימלי',
    'InstrumentDetailsPanel.NextPaymentDate.descr': 'מועד תשלום התשואה הבאה',
    'InstrumentDetailsPanel.NextPaymentDate': 'תשלום תשואה הבא',
    'InstrumentDetailsPanel.No': 'לא',
    'InstrumentDetailsPanel.non trading day': 'סגירה',
    'InstrumentDetailsPanel.None': 'אף אחד',
    'InstrumentDetailsPanel.NormalMarketSize.descr': 'גודל שוק רגיל',
    'InstrumentDetailsPanel.NormalMarketSize': '#hidden#',
    'InstrumentDetailsPanel.Not allow short positions': 'לא',
    'InstrumentDetailsPanel.NoticeDate.descr': '.תאריך במועד קבלת ההודעה על תאריך התפוגה באיביי תישלח. זמין עבור עתיד',
    'InstrumentDetailsPanel.NoticeDate': 'מועד ההודעה',
    'InstrumentDetailsPanel.onlyCloseOrders': 'רק הוראות סגירה',
    'InstrumentDetailsPanel.Open': 'פתיחה',
    'InstrumentDetailsPanel.OrderPerLot.descr': 'דמי תשלום עבור כל לוט אשר נסחרה',
    'InstrumentDetailsPanel.OrderPerLot': 'הוראה לפי לוט',
    'InstrumentDetailsPanel.OrderPerLotBUY.descr': 'דמי תשלום עבור כל לוט אשר נסחרה (Buy)',
    'InstrumentDetailsPanel.OrderPerLotBUY': '(Buy) הוראה לפי לוט ',
    'InstrumentDetailsPanel.OrderPerLotCLOSE.descr': '(Close) מחשבת עמלה על בסיס של הנפח של כל עסקה ',
    'InstrumentDetailsPanel.OrderPerLotCLOSE': '(Close) הוראה לפי לוט ',
    'InstrumentDetailsPanel.OrderPerLotCLOSEBUY.descr': '(Close Buy) דמי תשלום עבור כל לוט אשר נסחרה ',
    'InstrumentDetailsPanel.OrderPerLotCLOSEBUY': '(Close Buy) הוראה לפי לוט ',
    'InstrumentDetailsPanel.OrderPerLotCLOSESELL.descr': '(Sell Close) דמי תשלום עבור כל לוט אשר נסחרה (Close Sell)',
    'InstrumentDetailsPanel.OrderPerLotCLOSESELL': '(Close Sell) הוראה לפי לוט ',
    'InstrumentDetailsPanel.OrderPerLotOPEN.descr': '(Open) מחשבת עמלה על בסיס של הנפח של כל עסקה ',
    'InstrumentDetailsPanel.OrderPerLotOPEN': '(Open) הוראה לפי לוט ',
    'InstrumentDetailsPanel.OrderPerLotOPENBUY.descr': '(Open Buy) דמי תשלום עבור כל לוט אשר נסחרה ',
    'InstrumentDetailsPanel.OrderPerLotOPENBUY': '(Open Buy) הוראה לפי לוט ',
    'InstrumentDetailsPanel.OrderPerLotOPENSELL.descr': '(Open Sell) דמי תשלום עבור כל לוט אשר נסחרה ',
    'InstrumentDetailsPanel.OrderPerLotOPENSELL': '(Open Sell) הוראה לפי לוט ',
    'InstrumentDetailsPanel.OrderPerLotOPENSHORT.descr': '(Open short) דמי תשלום עבור כל לוט אשר נסחרה ',
    'InstrumentDetailsPanel.OrderPerLotOPENSHORT': '(Open Short) הוראה לפי לוט ',
    'InstrumentDetailsPanel.OrderPerLotSELL.descr': '(Sell) דמי תשלום עבור כל לוט אשר נסחרה ',
    'InstrumentDetailsPanel.OrderPerLotSELL': '(Sell) הוראה לפי לוט ',
    'InstrumentDetailsPanel.OrderPerLotSHORT': '(Short) הוראה לפי לוט ',
    'InstrumentDetailsPanel.OrderVolume.descr': 'דמי תשלום עבור כל חלק של היקף ההוראה שהתמלא',
    'InstrumentDetailsPanel.OrderVolume': 'נפח ההוראה',
    'InstrumentDetailsPanel.OptionExercise': 'עמלת מימוש אופציה',
    'InstrumentDetailsPanel.OptionExercise.descr': 'יחויב במקרה של מימוש אופציה לכל לוט של פוזיציה',
    'InstrumentDetailsPanel.OptionExerciseSELL': 'עמלת מימוש אפשרות (שורט)',
    'InstrumentDetailsPanel.OptionExerciseSELL.descr': 'Short יחויב במקרה של מימוש אופציה לכל לוט של פוזיציית ',
    'InstrumentDetailsPanel.OptionExerciseBUY': '(Long) דמי מימוש אופציה ',
    'InstrumentDetailsPanel.OptionExerciseBUY.descr': 'Long יחויב במקרה של מימוש אופציה לכל לוט של פוזיציית ',
    'InstrumentDetailsPanel.PerFill.descr': 'דמי תשלום עבור כל מילוי ההוראה',
    'InstrumentDetailsPanel.PerFill': 'לפי מילוי',
    'InstrumentDetailsPanel.PerLot.descr': 'דמי תשלום עבור כל לוט אשר נסחרה',
    'InstrumentDetailsPanel.PerLot': 'מימוש לכל לוט',
    'InstrumentDetailsPanel.PerLotBUY.descr': 'דמי תשלום עבור כל לוט אשר נסחרה (Buy)',
    'InstrumentDetailsPanel.PerLotBUY': '(Buy) מימוש לכל לוט  ',
    'InstrumentDetailsPanel.PerLotCLOSE.descr': '(Close) מחשבת עמלה על בסיס של הנפח של כל עסקה ',
    'InstrumentDetailsPanel.PerLotCLOSE': '(Close) מימוש לכל לוט  ',
    'InstrumentDetailsPanel.PerLotCLOSEBUY.descr': '(Close Buy) דמי תשלום עבור כל לוט אשר נסחרה ',
    'InstrumentDetailsPanel.PerLotCLOSEBUY': '(Close Buy) מימוש לכל לוט  ',
    'InstrumentDetailsPanel.PerLotCLOSESELL.descr': '(Sell Close) דמי תשלום עבור כל לוט אשר נסחרה (Close Sell)',
    'InstrumentDetailsPanel.PerLotCLOSESELL': '(Sell Close) מימוש לכל לוט  ',
    'InstrumentDetailsPanel.PerLotOPEN.descr': '(Open) מחשבת עמלה על בסיס של הנפח של כל עסקה ',
    'InstrumentDetailsPanel.PerLotOPEN': '(Open) מימוש לכל לוט  ',
    'InstrumentDetailsPanel.PerLotOPENBUY.descr': '(Open Buy) דמי תשלום עבור כל לוט אשר נסחרה ',
    'InstrumentDetailsPanel.PerLotOPENBUY': '(Open Buy) מימוש לכל לוט  ',
    'InstrumentDetailsPanel.PerLotOPENSELL.descr': '(Open Sell) דמי תשלום עבור כל לוט אשר נסחרה ',
    'InstrumentDetailsPanel.PerLotOPENSELL': '(Open Sell) מימוש לכל לוט  ',
    'InstrumentDetailsPanel.PerLotOPENSHORT.descr': '(Open short) דמי תשלום עבור כל לוט אשר נסחרה ',
    'InstrumentDetailsPanel.PerLotOPENSHORT': '(Open Short) מימוש לכל לוט  ',
    'InstrumentDetailsPanel.PerLotSELL.descr': '(Sell) דמי תשלום עבור כל לוט אשר נסחרה ',
    'InstrumentDetailsPanel.PerLotSELL': '(Sell) מימוש לכל לוט  ',
    'InstrumentDetailsPanel.PerLotSHORT.descr': '(Short) דמי תשלום עבור כל לוט אשר נסחרה ',
    'InstrumentDetailsPanel.PerLotSHORT': '(Short) מימוש לכל לוט  ',
    'InstrumentDetailsPanel.PerOrder': 'לפי הוראה',
    'InstrumentDetailsPanel.PerOrderVolume.descr': 'דמי תשלום עבור כל חלק של היקף ההוראה שהתמלא',
    'InstrumentDetailsPanel.PerOrderVolume': '% ,נפח ההוראה ',
    'InstrumentDetailsPanel.PerOrderVolumeBUY.descr': '% ,דמי תשלום עבור כל חלק של נפח בסדר הזה היה מלא (Buy)',
    'InstrumentDetailsPanel.PerOrderVolumeBUY': '% ,(Buy) נפח ההוראה ',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSE.descr': '%,(Close) דמי תשלום עבור כל חלק של נפח בסדר הזה היה מלא ',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSE': '%,(Close) נפח ההוראה ',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSEBUY.descr': '%,(Close buy) דמי תשלום עבור כל חלק של נפח בסדר הזה היה מלא ',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSEBUY': '%,(Close Buy) נפח ההוראה ',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSESELL.descr': '%,(sell Close) דמי תשלום עבור כל חלק של נפח בסדר הזה היה מלא ',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSESELL': '%,(Close Sell) נפח ההוראה ',
    'InstrumentDetailsPanel.PerOrderVolumeOPEN.descr': '%,(open) דמי תשלום עבור כל חלק של נפח בסדר הזה היה מלא ',
    'InstrumentDetailsPanel.PerOrderVolumeOPEN': '%,(Open) נפח ההוראה ',
    'InstrumentDetailsPanel.PerOrderVolumeOPENBUY.descr': '%,(Open buy) דמי תשלום עבור כל חלק של נפח בסדר הזה היה מלא ',
    'InstrumentDetailsPanel.PerOrderVolumeOPENBUY': '% ,(Open Buy) נפח ההוראה ',
    'InstrumentDetailsPanel.PerOrderVolumeOPENSELL.descr': '%,(Open sell) דמי תשלום עבור כל חלק של נפח בסדר הזה היה מלא (Open Sell),%',
    'InstrumentDetailsPanel.PerOrderVolumeOPENSELL': '%,(Open Sell) נפח ההוראה ',
    'InstrumentDetailsPanel.PerOrderVolumeOPENSHORT.descr': '%,(Short open) דמי תשלום עבור כל חלק של נפח בסדר הזה היה מלא ',
    'InstrumentDetailsPanel.PerOrderVolumeOPENSHORT': '%,(Open Short) נפח ההוראה ',
    'InstrumentDetailsPanel.PerOrderVolumeSELL.descr': '%,(sell) דמי תשלום עבור כל חלק של נפח בסדר הזה היה מלא ',
    'InstrumentDetailsPanel.PerOrderVolumeSELL': '%,(Sell) נפח ההוראה ',
    'InstrumentDetailsPanel.PerOrderVolumeSHORT.descr': '%,(Short) דמי תשלום עבור כל חלק של נפח בסדר הזה היה מלא ',
    'InstrumentDetailsPanel.PerOrderVolumeSHORT': '%,(Short) נפח ההוראה ',
    'InstrumentDetailsPanel.PerPhoneTransaction.descr': 'שכר הטרחה המשולם עבור כל עסקה אשר נעשתה על ידי סוחר טלפון',
    'InstrumentDetailsPanel.PerPhoneTransaction': 'לכל עסקה בטלפון',
    'InstrumentDetailsPanel.PerTransaction.descr': 'עמלה עבור כל עסקה. עסקאות: ביצוע הוראה בטל הוראה ושנה הוראה',
    'InstrumentDetailsPanel.PerTransaction': 'לפי עסקה',
    'InstrumentDetailsPanel.PerVolume.descr': '% ,עמלה מחשבת על בסיס של הנפח של כל עסקה',
    'InstrumentDetailsPanel.PerVolume': '% ,ביצוע כמות',
    'InstrumentDetailsPanel.PerVolumeBUY.descr': '% ,עמלה מחשבת על בסיס של הנפח של כל עסקה (Buy)',
    'InstrumentDetailsPanel.PerVolumeBUY': '% ,(Buy) מימוש נפח ',
    'InstrumentDetailsPanel.PerVolumeCLOSE.descr': '%,(Close) מחשבת עמלה על בסיס של הנפח של כל עסקה ',
    'InstrumentDetailsPanel.PerVolumeCLOSE': '% ,(Close) מימוש נפח ',
    'InstrumentDetailsPanel.PerVolumeCLOSEBUY.descr': '%,(Close Buy) מחשבת עמלה על בסיס של הנפח של כל עסקה ',
    'InstrumentDetailsPanel.PerVolumeCLOSEBUY': '%,(Close Buy) מימוש נפח (Close Buy), %',
    'InstrumentDetailsPanel.PerVolumeCLOSESELL.descr': '%,(Close Sell) עמלה מחשבת על בסיס של הנפח של כל עסקה ',
    'InstrumentDetailsPanel.PerVolumeCLOSESELL': '% ,(Close Sell) מימוש נפח ',
    'InstrumentDetailsPanel.PerVolumeOPEN.descr': '% ,עמלה מחשבת על בסיס של הנפח של כל עסקה (Open)',
    'InstrumentDetailsPanel.PerVolumeOPEN': '% ,(Open) מימוש נפח ',
    'InstrumentDetailsPanel.PerVolumeOPENBUY.descr': '% ,עמלה מחשבת על בסיס של הנפח של כל עסקה (Open Buy)',
    'InstrumentDetailsPanel.PerVolumeOPENBUY': '% ,(Open Buy) מימוש נפח ',
    'InstrumentDetailsPanel.PerVolumeOPENSELL.descr': '%,(Open Sell) מחשבת עמלה על בסיס של הנפח של כל עסקה ',
    'InstrumentDetailsPanel.PerVolumeOPENSELL': '% ,(Open Sell) מימוש נפח ',
    'InstrumentDetailsPanel.PerVolumeOPENSHORT.descr': '%,(Open Short) עמלה מחשבת על בסיס של הנפח של כל עסקה ',
    'InstrumentDetailsPanel.PerVolumeOPENSHORT': '% ,(Open Short) מימוש נפח ',
    'InstrumentDetailsPanel.PerVolumeSELL.descr': '% ,עמלה מחשבת על בסיס של הנפח של כל עסקה (Sell)',
    'InstrumentDetailsPanel.PerVolumeSELL': '% ,מימוש נפח (Sell) ',
    'InstrumentDetailsPanel.PerVolumeSHORT.descr': '%,(Short) עמלה מחשבת על בסיס של הנפח של כל עסקה ',
    'InstrumentDetailsPanel.PerVolumeSHORT': '% מימוש נפח(Short), ',
    'InstrumentDetailsPanel.Physically': 'פיזית',
    'InstrumentDetailsPanel.POSTCLOSE.descr': 'מידע על הגדרות עבור שלב מסחר פוסט-שוק',
    'InstrumentDetailsPanel.POSTCLOSE': 'לאחר-שוק',
    'InstrumentDetailsPanel.PREOPEN.descr': 'מידע על הגדרות עבור שלב מסחר טרום שוק',
    'InstrumentDetailsPanel.PREOPEN': 'טרום שוק',
    'InstrumentDetailsPanel.PreviousPaymentDate.descr': 'מועד תשלום תשואה קודמת',
    'InstrumentDetailsPanel.PreviousPaymentDate': 'תשלום תשואה קודם',
    'InstrumentDetailsPanel.Price': 'מחיר',
    'InstrumentDetailsPanel.ProductType': 'סוג המוצר',
    'InstrumentDetailsPanel.QuotiongCurrency.descr': 'מידע על המטבע בו ציטוט מחושב',
    'InstrumentDetailsPanel.QuotiongCurrency': 'מטבע של הציטוט',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot.descr': 'כלומר עבור כל לוט בעסקה שבוצעה נוצר ערך ההנחה הקבוע יחויב / יוחזר (במטבע התכנית)',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot': 'הוראת Taker לפי לוט',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot.descr': 'כלומר עבור כל לוט בעסקה שבוצעה נוצר ערך ההנחה הקבוע יחויב / יוחזר (במטבע התכנית)',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot': 'Taker לפי לוט',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume.descr': 'כלומר חיוב/החזר משוכלל יחושב כאחוז מן נפח המסחר',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume': 'Taker לפי נפח',
    'InstrumentDetailsPanel.SessionStatus.descr': 'Open, Close :מצב של האירוע, ערכים זמינים',
    'InstrumentDetailsPanel.SessionStatus': '#hidden#',
    'InstrumentDetailsPanel.SettlementDate.descr': 'תאריך כאשר יש להיערך משלוח',
    'InstrumentDetailsPanel.SettlementDate': 'תאריך הסדר',
    'InstrumentDetailsPanel.shortened': 'יום מקוצר',
    'InstrumentDetailsPanel.ShortPostionInterest': 'Short ריבית בפוזיציית ',
    'InstrumentDetailsPanel.StrikePrice.descr': 'רמת מחיר של underlier שבאמצעותו חוזה האופציה יבוצע',
    'InstrumentDetailsPanel.StrikePrice': 'מימוש',
    'InstrumentDetailsPanel.SwapBuy.descr': 'מציג את ערך ה-swap המייושם למשך לילה בפוזיציות לונג של הסימול',
    'InstrumentDetailsPanel.SwapBuy': 'החלפת לונג',
    'InstrumentDetailsPanel.SwapSell.descr': 'מציג את ערך ה-swap המייושם למשך לילה בפוזיציות שורט של הסימול',
    'InstrumentDetailsPanel.SwapSell': 'Short Swap',
    'InstrumentDetailsPanel.Symbol.descr': 'השם של הסימול נבחר בחיפוש',
    'InstrumentDetailsPanel.Symbol': 'סימול',
    'InstrumentDetailsPanel.T+0': ' (היום) T + 0',
    'InstrumentDetailsPanel.TickCoast.descr': 'סכום נכס הבסיס עבור מדרגה אחת',
    'InstrumentDetailsPanel.TickCoast': 'עלות מדרגה',
    'InstrumentDetailsPanel.TickSize.descr': 'שינוי מינימאלי של המחיר',
    'InstrumentDetailsPanel.TickSize': 'גודל מדרגה',
    'InstrumentDetailsPanel.TradindBlockedOnSession.descr': 'מראה אם ​​מסחר חסום עבור האירוע הנוכחי',
    'InstrumentDetailsPanel.TradindBlockedOnSession': 'מסחר חסום על שלב מסחר',
    'InstrumentDetailsPanel.TradingBalance.descr': ' T+x מחזור הסדר',
    'InstrumentDetailsPanel.TradingBalance': 'מערכת הסדר',
    'InstrumentDetailsPanel.TradingHalt': 'הפסקת מסחר',
    'InstrumentDetailsPanel.TradingStatus.descr': 'מעמד מסחר, ערכים זמינים: פתוח, סגור, הפסקת מסחר',
    'InstrumentDetailsPanel.TradingStatus': 'מצב סימול',
    'InstrumentDetailsPanel.Underlier.descr': '.שם של נכס אשר יימסר. זמין עבור אופציות וחוזים עתידיים',
    'InstrumentDetailsPanel.Underlier': 'Underlier',
    'InstrumentDetailsPanel.VAT.descr': 'מס ערך מוסף ששולם מכל פעולה בתשלום',
    'InstrumentDetailsPanel.VAT': 'מע"מ',
    'InstrumentDetailsPanel.Volume.descr': 'עמלה מחשבת על בסיס של הנפח של כל עסקה',
    'InstrumentDetailsPanel.Volume': 'מימוש נפח',
    'InstrumentDetailsPanel.VolumeWithMinPD': 'מימוש % נפח, עם מינימום לכל יום',
    'InstrumentDetailsPanel.VolumeWithMinPDCLOSE': '(Close), % מימוש נפח עם מינימום לכל יום ',
    'InstrumentDetailsPanel.VolumeWithMinPDOPEN': '% ,(Open) מימוש נפח עם מינימום לכל יום ',
    'InstrumentDetailsPanel.working': 'פתיחה',
    'InstrumentDetailsPanel.Yes': 'כן',
    'InstrumentDetailsPanel.Yield.descr': 'ערך ריבית נוכחי',
    'InstrumentDetailsPanel.Yield': 'תשואה',
    'InstrumentDetailsPanel.YTM.RefreshLink': 'רענן',
    'InstrumentDetailsPanel.YTM': 'YTM,%',
    IOC: 'IOC',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders': 'Execute כמו שוק אינו מותר עבור הוראות כבולות',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp': 'SL / TP  ביצוע במחיר שוק לא מורשה ב ',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType': 'שינוי סוג הוראה אסור',
    'IsAllowedResponceReason.InstrumentForAccountDelivery': 'כלי למסירה חשבון',
    'IsAllowedResponceReason.LockTrading': 'מסחר נעול בפלטפורמה',
    'IsAllowedResponceReason.LockTradingByPassword': 'נא הזן סיסמת מסחר בחלק התחתון של הטרמינל',
    'IsAllowedResponceReason.MoreThanOneAssetIsSelected': 'נבחר יותר מנכס אחד ',
    'IsAllowedResponceReason.NoAssetsSelected': 'לא נבחרו נכסים',
    'IsAllowedResponceReason.NoLastQuote': 'אין ציטוט האחרון',
    'IsAllowedResponceReason.NotAllowedByOrderStaus': 'פעולות אסורות על ידי סטאטוס הוראה',
    'IsAllowedResponceReason.NotAllowedCorporateActionPositions': 'שלב מסחרת לפוזיציות פעולה תאגידית',
    'IsAllowedResponceReason.NotAllowedRouteOrderType': 'ההוראה לא יכול להיווצר, מכיוון שסוג TIF וההוראה שנבחרו אינם נתמכים.',
    'IsAllowedResponceReason.NotClosablePosition': 'פוזיציה לא יכולה להיסגר',
    'IsAllowedResponceReason.NotLoadCache': 'מטמון לא טעון',
    'IsAllowedResponceReason.NotVisibleInstrumentRoute': 'כלי או נתיב לא גלוי',
    'IsAllowedResponceReason.OperationNotAllowedByOrderType': 'פעולה אסורה לפי סוג הוראה',
    'IsAllowedResponceReason.OperationNotAllowedBySession': 'פעולה אסורה על ידי הפעלה',
    'IsAllowedResponceReason.OrderTypeNotAllowedBySession': 'סוג ההוראה אסור על ידי הסשן',
    'IsAllowedResponceReason.RouteNotRunning': 'מסחר בנכס אסור',
    'IsAllowedResponceReason.RuleAdministration': 'הנהלת כלל',
    'IsAllowedResponceReason.RuleClosePositionDisable': 'סגירת פוזיציה אסור לפי חוק',
    'IsAllowedResponceReason.RuleFunctionSelfTrading': 'כלל פונקציית מסחר עצמי',
    'IsAllowedResponceReason.RuleFunctionTrading': '.המסחר אסור על פי כלל',
    'IsAllowedResponceReason.RuleOCO': 'OCO כלל ',
    'IsAllowedResponceReason.RuleSlTpNotAllowed': 'SL/TP אסור על פי כלל',
    'IsAllowedResponceReason.RuleTrStop': 'Tr. Stop כלל ',
    'IsAllowedResponceReason.Unknown': 'סיבה לא ידועה',
    'IsAllowedResponceReason.NotAllowedForTrades': 'אסור עבור עסקאות',
    'IsAllowedResponceReason.NotSupportedTIF': 'TIF לא נתמך',
    'IsAllowedResponceReason.WrongTraidingPositionType': 'סוג פוזיציית מסחר שגוי',
    'Kairi Relative Index': 'אינדקס Kairi יחסית',
    'Keltner Channel': 'Keltner ערוץ ',
    'Linear Weighted Moving Average': 'תזוזה ממוצעת לשיקלול ליניארי',
    LOC: 'LOC',
    LOO: 'LOO',
    'MarketDepthPanel.ColoringMethod.ByPriceLVL': 'לפי רמת מחיר',
    'MarketDepthPanel.ColoringMethod.RelativeToVolume': 'יחסית לנפח',
    'MarketDepthPanel.ColoringMethod.SizeHistogram': 'גודל של היסטוגמה',
    'McGinley Dynamic': 'Dynamic מקינלי ',
    'MDLotsMode.General': '({מחדל כללי ({1',
    'MDLotsMode.Lots': 'לוטים',
    'MDLotsMode.RealSize': 'גודל אמיתי',
    MOC: 'MOC',
    'Modified Moving Average': 'שונה תזוזה ממוצעת',
    Momentum: 'מומנטום',
    MOO: 'MOO',
    'Moving Average Envelope': 'מעטפת ממוצע נע',
    'Moving Average/Convergence Divergence': 'ממוצע נע / סטיית התכנסות',
    'NetTrader.SaveWorkspace.Save': 'שמור סביבת עבודה',
    'NumericComboCtrl.Edit': 'גדר ברירות מחדל',
    'On Balance Volume': 'על נפח מאזן',
    'onBoarding.slide1.header1': 'איזור שליטת מרחב עבודה',
    'onBoarding.slide1.text1': 'מרחב העבודה מורכב מחלונות ומפאנלים, התצורות\n שלהם ומיקומם על המסך. כל שינוי\n במרחב העבודה נשמר אוטומטית.',
    'onBoarding.slide2.header1': 'פעולות סביבת עבודה נוספות',
    'onBoarding.slide2.text1': 'שיבוט - יוצר העתק של מרחב העבודה הפעיל \nשינוי שם - מאפשר לכם לשנות את שם מרחב העבודה הפעיל',
    'onBoarding.slide2.text2': 'באפשרותך לשנות את שם מרחב העבודה\nעל ידי לחיצה כפולה על שמו',
    'onBoarding.slide3.header1': 'נעילת מרחב עבודה',
    'onBoarding.slide3.header2': 'סגירת סביבת עבודה ',
    'onBoarding.slide3.header3': 'הוספת סביבת עבודה ',
    'onBoarding.slide3.text1': 'באפשרותך לנעול ולבטל את נעילת מרחב העבודה שלך על ידי לחיצה על סימן המנעול. אם מרחב העבודה נעול,\n לא תוכל לסגור חלונות קיימים\n או להוסיף חלונות חדשים.',
    'onBoarding.slide3.text2': 'באפשרותך לסגור את מרחב העבודה על ידי לחיצה על הלחצן "X".',
    'onBoarding.slide3.text3': '."+" באפשרותך ליצור מרחב חדש על ידי לחיצה על הלחצן',
    'onBoarding.slide4.header1': 'קישור חלונות לפי צבע',
    'onBoarding.slide4.text1': 'באפשרותך  להחליף סימולים אוטומטית בכל החלונות\nעל ידי שינוי זה במקום אחד בלבד. על מנת לעשות זאת,\לחץ על סימול הקישור ובחר צבע תואם בכל\nהחלונות הרלוונטיים.',
    'onBoarding.slide5.header1': 'מסחר בעכבר',
    'onBoarding.slide5.text1': 'באפשרותך לבצע הוראות Limit, Stop, Stop-Limit או\ הוראות nOCO ישירות על התרשים על ידי שימוש בתכונת המסחר באמצעות העכבר. כדי להפעיל\nמסחר בעכבר, לחצו על סימול העכבר הכחול\nבפינה הימנית העליונה של התרשים.',
    'onBoarding.slide5.text2': 'באפשרותך להגדיר גם את תוקף הכמות וגם את תוקף ההוראה\nבווידג\'ט שיופיע לאחר הפעלת\nהתכונה.',
    'onBoarding.slide5.text3': 'השתמש בגרירה ושחרור כדי לשנות הוראות ופוזיציות, להוסיף Stop Loss או Take Profit או לשנות את הכמות.',
    'onBoarding.slide6.text1': 'לפני ביצוע ההוראה, באפשרותכם לעיין',
    'onBoarding.slide6.text2': '.לחצנים להציג / להסתיר את הפאנל',
    'onBoarding.slide7.header1': 'עגינת חלונות',
    'onBoarding.slide7.text1': 'באפשרותכם\nלהזיז כל חלון במרחב העבודה ולהצמיד אותו לחלונות אחרים.\nפשוט גררו כל חלון למקום שבו\nתרצו למקום אותו, עד שיופיעו לחצנים כחולים עם\nחצים.',
    'onBoarding.slide8.header1': 'מידע על נכס',
    'onBoarding.slide8.text1': 'באפשרותך למצוא מידע נוסף על כל מכשיר על ידי לחיצה על סימול סוג המכשיר',
    'onBoarding.slide9.header1': 'פרטי חשבון יישומון',
    'onBoarding.slide9.text1': 'גררו ושחררו כל שדה מהרשימה כדי\nלהתאים אישית את המידע המוצג בווידג\'ט.',
    'onBoarding.slide10.header1': 'קישור חלון על ידי חשבון',
    'onBoarding.slide10.text1': 'באפשרותך להפעיל את הקישור של\nחלונות לפי חשבון',
    'onBoarding.slide10.text2': 'באפשרותך להפעיל סינון הוראות ופוזיציות לפי חשבון',
    'onBoarding.slide11.header1': 'עזרה',
    'onBoarding.slide11.header2': 'הגדרות כלליות',
    'onBoarding.slide11.text1': 'למדריך מלא למשתמש,\nלחצו על כפתור העזרה.',
    'onBoarding.slide11.text2': 'כאן תוכלו לערוך הגדרות כלליות,\nמקשי קיצור, ברירות מחדל למסחר ועוד.',
    'onBoarding.slide12.header1': '!בוצע',
    OnClose: 'Close על ',
    OnOpen: 'Open על ',
    'Order.ExecutionType.ACCEPTED': 'מקובלים',
    'Order.ExecutionType.ACTIVATED': 'הופעל',
    'Order.ExecutionType.CANCELED': 'מבוטל',
    'Order.ExecutionType.FILLED': 'מומש',
    'Order.ExecutionType.NEW': 'נוצר',
    'Order.ExecutionType.PART_FILLED': 'בוצע באופן חלקי',
    'Order.ExecutionType.REFUSED': 'נדחה',
    'Order.ExecutionType.REPLACED': 'שונה',
    'Order.ExecutionType.RESTATED': 'הוצג מחדש',
    'Order.ExecutionType.UNKNOWN': 'לא ידוע',
    'Order.OrderStatus.STATUS_ACCEPTED': 'מקובלים',
    'Order.OrderStatus.STATUS_FILLED': 'מומש',
    'Order.OrderStatus.STATUS_NEW': 'נוצר',
    'Order.OrderStatus.STATUS_OFF_MARKET': 'מחוץ לזמן שוק',
    'Order.OrderStatus.STATUS_PENDING_CANCEL': 'בהמתנה לביטול',
    'Order.OrderStatus.STATUS_PENDING_EXECUTION': 'בהמתנה לביצוע',
    'Order.OrderStatus.STATUS_PENDING_NEW': 'ממתין חדש',
    'Order.OrderStatus.STATUS_PENDING_REPLACE': 'שינוי ממתין',
    'Order.OrderStatus.STATUS_REPLACED': 'שונה',
    'Order.OrderStatus.STATUS_UNKNOWN': 'לא ידוע',
    'Order.OrderStatus.STATUS_UNPLACED': 'לא ממוקם',
    'Order.OrderStatus.STATUS_WAITING_MARKET': 'ממתין לזמן שוק',
    'OrderEntry.InfoBlock.After trade funds': 'אחרי קרנות סחר',
    'OrderEntry.InfoBlock.Availible funds': 'קרנות זמינות',
    'OrderEntry.InfoBlock.Balance': 'יתרה',
    'OrderEntry.InfoBlock.Fee': 'עמלות',
    'OrderEntry.InfoBlock.Gross': 'דוחה',
    'OrderEntry.InfoBlock.Impact on portfolio': 'השפעה על תיק',
    'OrderEntry.InfoBlock.Init. margin': 'אתחול מינוף',
    'OrderEntry.InfoBlock.Maint. margin': 'תחזוקת ביטחונות',
    'OrderEntry.InfoBlock.Margin availible': 'ביטחונות זמינים',
    'OrderEntry.InfoBlock.Net': 'נט',
    'OrderEntry.InfoBlock.Spread in loss': 'מרווח הפסד תחילי',
    'OverlayScreen.barsBodyLabel': 'גוף',
    'OverlayScreen.barsBorderLabel': 'גבול',
    'OverlayScreen.barsHiLowLabel': 'קו',
    'OverlayScreen.cancelButton': 'בטל',
    'OverlayScreen.dataLabel': 'סוג מידע',
    'OverlayScreen.dojiLabel': 'Doji',
    'OverlayScreen.histogramLineLabel': 'קו היסטוגרמה ',
    'OverlayScreen.instrumentLabel': 'סימול',
    'OverlayScreen.Main scale': 'קנה מידה ראשי',
    'OverlayScreen.name': 'כיסוי',
    'OverlayScreen.numberOverlaysError': '.אתה לא יכול להוסיף יותר מ 5 שכבות',
    'OverlayScreen.okButton': 'בסדר',
    'OverlayScreen.Overlays': 'שכבות',
    'OverlayScreen.priceLabel': 'קנה מידה מחיר',
    'OverlayScreen.priceMarkerCheckbox': 'הצג סמן מחיר',
    'OverlayScreen.Separate scale': 'קנה מידה נפרד',
    'OverlayScreen.solidPriceLabel': 'פינה',
    'OverlayScreen.styleLabel': 'סגנון',
    'OverlayScreen.wickLabel': 'פְּתִילָה',
    'pageNavigation.backButton': 'חזור',
    'pageNavigation.doneButton': 'בוצע',
    'pageNavigation.nextButton': 'הבא',
    'panel.accountDetails.Cedro risks.AVBA': 'יתרה זמינה',
    'panel.accountDetails.Cedro risks.DAY_1_CREDITS': 'D1',
    'panel.accountDetails.Cedro risks.LMAL': 'סה"כ Limit (מסחר יום)',
    'panel.accountDetails.Cedro risks.PRBA': 'מאזן פרויקט',
    'panel.accountDetails.Cedro risks.LMDI': 'יתרה כוללת (מסחר יום)',
    'panel.accountDetails.Cedro risks.TOTL': 'סך הכל',
    'panel.accountDetails.Cedro risks.SLDTL': 'יתרה כוללת (פוזיציה)',
    'panel.accountDetails.Cedro risks.UNQL': 'סה"כ Limit (פוזיציה)',
    'panel.accountDetails.Groups.1.General': 'כללי',
    'panel.accountDetails.Groups.2.Margin': 'ביטחונות',
    'panel.accountDetails.Groups.3.AccountActivity': 'פעילות חשבון',
    'panel.accountDetails.Groups.4.TodayResults': 'תוצאות היום',
    'panel.accountDetails.Groups.5.RiskManagement': 'ניהול סיכונים',
    'panel.accountDetails.Groups.55.Plans': 'תוכניות',
    'panel.accountDetails.Groups.6.Info': 'מידע',
    'panel.accountDetails.Groups.AccountActivity': 'פעילות בחשבון ‎2.',
    'panel.accountDetails.Groups.Cedro risks': 'פרטי חשבון',
    'panel.accountDetails.Groups.General': '1. כללי',
    'panel.accountDetails.Groups.Info': 'מידע ‎5.',
    'panel.accountDetails.Groups.Kyrrex risks': 'פרטי חשבון',
    'panel.accountDetails.Groups.Rico risks': 'פרטי חשבון',
    'panel.accountDetails.Groups.RiskManagement': 'ניהול סיכונים ‎4.',
    'panel.accountDetails.Groups.TodayResults': 'תוצאות היום ‎3.',
    'panel.accountDetails.Groups.Portfolio': 'תיק עבודות',
    'panel.accountDetails.Groups.Stocks': 'מניות',
    'panel.accountDetails.Groups.Credit': 'אשראי',
    'panel.accountDetails.Groups.Cash and Buying Power': 'מזומן וכוח קנייה',
    'panel.accountDetails.Kyrrex risks.AvailableBalance': 'יתרה זמינה',
    'panel.accountDetails.Kyrrex risks.BlockedBalance': 'יתרה חסומה',
    'panel.accountDetails.Rico risks.AVLM': 'שול זמין',
    'panel.accountDetails.Rico risks.BCKA': 'סכום חסימה',
    'panel.accountDetails.Rico risks.BKBL': 'יתרה חסומה',
    'panel.accountDetails.Rico risks.BLNC': 'יתרה',
    'panel.accountDetails.Rico risks.BMBM': 'BMF חסם ביטחונות',
    'panel.accountDetails.Rico risks.BMFL': 'BMF גבול ',
    'panel.accountDetails.Rico risks.BOPT': 'אפשרויות יתרה',
    'panel.accountDetails.Rico risks.BUOP': 'יתרה הפעולה הלא מכוסה',
    'panel.accountDetails.Rico risks.CED0': 'כניסת אשראי ביום 0',
    'panel.accountDetails.Rico risks.CED1': 'כניסת אשראי יום 1',
    'panel.accountDetails.Rico risks.CED2': 'כניסת אשראי יום 2',
    'panel.accountDetails.Rico risks.CED3': 'כניסת אשראי יום 3',
    'panel.accountDetails.Rico risks.CRLM': 'מסגרת אשראי',
    'panel.accountDetails.Rico risks.CRLO': 'opt גבול אשראי',
    'panel.accountDetails.Rico risks.CULM': 'המגבלה הנוכחית',
    'panel.accountDetails.Rico risks.D1PB': 'D0  איזון צפוי',
    'panel.accountDetails.Rico risks.D1PD': 'D1 Proj. תאריך',
    'panel.accountDetails.Rico risks.D2PB': 'D1 מוקרן איזון',
    'panel.accountDetails.Rico risks.D3PD': 'D3 Proj. תאריך',
    'panel.accountDetails.Rico risks.DED0': 'כניסת חיוב ביום 0',
    'panel.accountDetails.Rico risks.DED1': 'כניסת חיוב יום 1',
    'panel.accountDetails.Rico risks.DED2': 'כניסת חיוב יום 2',
    'panel.accountDetails.Rico risks.DED3': 'כניסת חיוב יום 3',
    'panel.accountDetails.Rico risks.DLBL': 'יתרה נזילה יומית',
    'panel.accountDetails.Rico risks.DLMT': 'גבול יומי',
    'panel.accountDetails.Rico risks.EBVL': 'שווי קניה מבוצע',
    'panel.accountDetails.Rico risks.ESVL': 'שווי מכירה מבוצע',
    'panel.accountDetails.Rico risks.FBBL': 'BMF יתרה סופית ',
    'panel.accountDetails.Rico risks.FNBL': 'איזון סופי',
    'panel.accountDetails.Rico risks.FNLE': 'מינוף פיננסי',
    'panel.accountDetails.Rico risks.INBA': 'מאזן ראשוני',
    'panel.accountDetails.Rico risks.INBL': 'מאזן ראשוני',
    'panel.accountDetails.Rico risks.LEVE': 'מינוף',
    'panel.accountDetails.Rico risks.LILO': 'אפשרויות מינוף גבול',
    'panel.accountDetails.Rico risks.LVLM': 'מגבלת מינוף',
    'panel.accountDetails.Rico risks.NTEQ': 'הון עצמי נטו',
    'panel.accountDetails.Rico risks.OPBL': 'איזון אפשרויות',
    'panel.accountDetails.Rico risks.OVLT': 'גבול משיכת יתר',
    'panel.accountDetails.Rico risks.PBVL': 'ערך קנייה ממתין',
    'panel.accountDetails.Rico risks.POVA': 'פוטנציאל כמות משיכת יתר',
    'panel.accountDetails.Rico risks.PRBA': 'מאזן פרויקט',
    'panel.accountDetails.Rico risks.PSVL': 'ערך שנמכר ממתין',
    'panel.accountDetails.Rico risks.SKBL': 'מאזן מניות',
    'panel.accountDetails.Rico risks.WDBL': 'מאזן משיכה',
    'panel.accountDetails.Portfolio.TR_OAL': 'שווי תיק נטו',
    'panel.accountDetails.Portfolio.TR_T1B': 'T1 שווי תיק נטו',
    'panel.accountDetails.Portfolio.TR_T2B': 'T2 שווי תיק נטו',
    'panel.accountDetails.Portfolio.TR_ASN': 'קנייה מכירה יתרת נטו',
    'panel.accountDetails.Stocks.TR_CHS': 'מאזן מניות',
    'panel.accountDetails.Stocks.TR_CHST1': 'T1 מאזן מניות',
    'panel.accountDetails.Stocks.TR_CHST2': 'T2 מאזן מניות',
    'panel.accountDetails.Stocks.TR_CBK': 'מזומן',
    'panel.accountDetails.Stocks.TR_CBKT1': ' מזומן T1',
    'panel.accountDetails.Stocks.TR_CBKT2': ' מזומן T2',
    'panel.accountDetails.Credit.TR_KRD': 'מאזן יתרה',
    'panel.accountDetails.Credit.TR_KRDT1': ' מאזן אשראי T1',
    'panel.accountDetails.Credit.TR_KRDT2': ' מאזן אשראי T2',
    'panel.accountDetails.Credit.TR_OZK': 'יחס חוב להון עצמי',
    'panel.accountDetails.Credit.TR_OZ1': ' יחס חוב להון T1',
    'panel.accountDetails.Credit.TR_OZ2': ' יחס חוב להון T2',
    'panel.accountDetails.Cash and Buying Power.TR_NAK': 'מגבלת מזומנים',
    'panel.accountDetails.Cash and Buying Power.TR_ISL': 'כוח קנייה במסחר יומי',
    'panel.accountDetails.Cash and Buying Power.TR_LMT': 'מאזן יתרה',
    'panel.accountDetails.Cash and Buying Power.TR_KIL': 'יתרת אשראי זמינה',
    'panel.accountDetails.Cash and Buying Power.TR_ASL': ' זמינה Short Sell Limit',
    'panel.accountDetails.Cash and Buying Power.TR_GKZ': 'שינוי כולל',
    'panel.accountDetails.Cash and Buying Power.TR_TPLM': 'ערך הון עצמי כולל',
    'panel.accountDetails.Cash and Buying Power.TR_TPLMT1': ' שווי הון כולל T1',
    'panel.accountDetails.Cash and Buying Power.TR_TPLMT2': ' שווי הון כולל T2',
    'panel.AccountDetailsPanel.InfoTooltip': 'שנה את השדות יישומון, על ידי Drag & Drop מהשולחן',
    'panel.AccountDetailsPanel': 'פרטי חשבון',
    'panel.AccountLink.SymbolLink.ToolTip': 'קישור לסימול',
    'panel.AccountLink.ToolTip': 'קישור החשבון',
    'panel.accounts.AccountDescription': '#hidden#',
    'panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached': 'הגבלת הפסד יומית הושגה',
    'panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached': 'נפח יומי מקסימלי הושג',
    'panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)': 'הוראת Flow Limit (ליום)',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled': 'שלב מסחרת',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled': 'מופעל',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Active': 'פעיל',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed': 'סגירה אוטומטית',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed': 'סגירה',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules': 'המסחר מושבת על ידי כללי הסיכון',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.FinraDayTraderStatus': 'FINRA דפוס סוחר יומי של ',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached': 'נגרר הפסד מקסימלי הושג',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend': 'השהה',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached': 'מגבלת הפסד לא ממומש הושגה',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached': 'הגבלת הפסד שבועי הושג',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Max relative drawdown level was reached': 'יחס רמת גריעה מקסימלי הושג',
    'panel.accounts.AccountMode': 'מצב חשבון',
    'panel.accounts.AccountType': 'סוג החשבון',
    'panel.accounts.AccountValue.descr': 'סה"כ ערך של החשבון',
    'panel.accounts.AccountValue': 'ערך חשבון',
    'panel.accounts.AllowTradingPrePost': 'מסחר ב-לפני/אחרי זמן שוק',
    'panel.accounts.Asset.descr': 'שם נכס',
    'panel.accounts.Asset': 'נכס',
    'panel.accounts.AssetDescription': 'תיאור הנכס',
    'panel.accounts.AssetType': 'סוג נכס',
    'panel.accounts.AvailableMargin.descr': 'קרנות זמינות עבור חשבון זה',
    'panel.accounts.AvailableMargin': 'קרנות זמינות',
    'panel.accounts.Balance.descr': 'יתרת החשבון',
    'panel.accounts.Balance': 'יתרה',
    'panel.accounts.BalancePlusAllRisks.descr': 'יתרה וכל הסיכונים',
    'panel.accounts.BalancePlusAllRisks': 'יתרה וכל הסיכונים',
    'panel.accounts.BeginBalance.descr': 'רווח / הפסד נקי להיום',
    'panel.accounts.BeginBalance': 'נטו של היום',
    'panel.accounts.BlockedByOrders.descr': 'ביטחונות חסומים עבור הוראות',
    'panel.accounts.BlockedByOrders': 'ביטחונות המשומשים עבור הוראות',
    'panel.accounts.BlockedByPamm.descr': 'הון התחלתי הושקע עבור כספים',
    'panel.accounts.BlockedByPamm': 'סכום הון מושקע',
    'panel.accounts.BlockedForStocks': '#hidden#',
    'panel.accounts.BlockedForStocksNew': 'חסימה עבור מניות',
    'panel.accounts.BuyingPower': '#hidden#',
    'panel.accounts.CashBalance.descr': 'יתרת מזומנים',
    'panel.accounts.CashBalance': 'יתרת מזומנים',
    'panel.accounts.ClientType': 'סוג מסוף',
    'panel.accounts.Collateral.descr': 'Short ערך ביטחונות נוכחי לפוזיציות ',
    'panel.accounts.Collateral': '#hidden#',
    'panel.accounts.Commissions': 'עמלות',
    'panel.accounts.CreditValue.descr': 'קרנות אשראי זמינות למסחר',
    'panel.accounts.CreditValue': 'ערך אשראי',
    'panel.accounts.CurBalance.descr': 'ההון העצמי הנוכחי (איזון + רווח והפסד לא ממומש)',
    'panel.accounts.CurBalance': 'מאזן צפוי',
    'panel.accounts.CurMargin.descr': 'דרישת ביטחונות ראשונים. ביחס לסיכונים מאזנים & וכל הסיכונים',
    'panel.accounts.CurMargin': '% דרישת ביטחונות ראשונית ',
    'panel.accounts.CurrentDailyLoss.descr': 'ערך ההפסד מגבלה יומית נטו הנוכחי של חשבון',
    'panel.accounts.CurrentDailyLoss': 'הפסד יומי',
    'panel.accounts.CurrentFundCapital.descr': 'הון נוכחי מושקע בקרנות',
    'panel.accounts.CurrentFundCapital': 'קרן הון נוכחי',
    'panel.accounts.CurrentStatus': 'מעמד מסחר',
    'panel.accounts.CurrentWeeklyLoss.descr': 'ערך אובדן גבול שבועי הנקי הנוכחי של חשבון',
    'panel.accounts.CurrentWeeklyLoss': 'הפסד שבועי',
    'panel.accounts.Email.descr': 'כתובת דוא"ל',
    'panel.accounts.Email': 'אימייל',
    'panel.accounts.Estimate value.descr': 'הערך חושב מנכס אחר',
    'panel.accounts.Estimate value': 'ערך משוערך',
    'panel.accounts.FundCapitalGain.descr': 'הבדל בין הון התחלתי לנוכחי ',
    'panel.accounts.FundCapitalGain': 'רווח הון קרן',
    'panel.accounts.FundsUsed': 'יתרות בשימוש',
    'panel.accounts.FundsUsedPercent.descr': 'כספים בשימוש ביחס לסיכונים מאזנים & כל',
    'panel.accounts.FundsUsedPercent': '% ,כספים בשימוש',
    'panel.accounts.GivenLeverage': '#hidden#',
    'panel.accounts.GrossPNL.descr': 'רווח / הפסד גולמי להיום',
    'panel.accounts.GrossPNL': 'ברוטו של היום',
    'panel.accounts.InitialMarginWithoutWaived': '#hidden#',
    'panel.accounts.InitialMarginWithoutWaived.descr': 'דרישת ביטחונות ראשונית  מרווח התחלתי ללא ויתור',
    'panel.accounts.InsertOnBalance': 'ריבית על היתרה',
    'panel.accounts.InterestRate.descr': 'ריבית שנתית על פיקדון',
    'panel.accounts.InterestRate': '(%) גובה הריבית ',
    'panel.accounts.IntradayRiskManagement': 'מצב ניהול סיכונים',
    'panel.accounts.Leverage.descr': 'המינוף ביטחונות הראשוני של החשבון',
    'panel.accounts.Leverage': 'מינוף',
    'panel.accounts.LockedBalance.descr': 'בלנס נעול להמשך משיכה',
    'panel.accounts.LockedBalance': 'יתרה חסומה',
    'panel.accounts.LockedBy.descr': 'נעול על ידי ברוקר (בקשה לקבלת הצעת מחיר עבור סוחרים)',
    'panel.accounts.LockedBy': 'נעול',
    'panel.accounts.Login.descr': 'שם החשבון',
    'panel.accounts.Login': 'חשבון',
    'panel.accounts.MaintMargin.descr': ' ביטחונות תחזוקה דרושים ביחס לסיכונים & וכל המאזנים',
    'panel.accounts.MaintMargin': '% ,תחזוקת ביטחונות דרוש ',
    'panel.accounts.MarginAndRisks': 'ביטחונות וסיכונים',
    'panel.accounts.MarginAvailableReal.descr': 'ביטחונות זמינים עבור החשבון',
    'panel.accounts.MarginAvailableReal': 'ביטחונות זמינים',
    'panel.accounts.MarginDeficiency': 'חוסר ביטחונות',
    'panel.accounts.MarginForOthers': '#hidden#',
    'panel.accounts.MarginRequirement.descr': 'דרישת תחזוקה עבור כל הפוזיציות הפתוחות',
    'panel.accounts.MarginRequirement': 'שימור דרוש לפוזיציה',
    'panel.accounts.MarginStopOut.descr': 'ביטחונות תחזוקת חסום להוראות ופוזיציות',
    'panel.accounts.MarginStopOut': '.תחזוקת ביטחונות דרוש',
    'panel.accounts.MarginStopTrade.descr': 'חשבון מנעו מן המסחר אם היתרה היא פחות מ רמה זו',
    'panel.accounts.MarginStopTrade': 'עצור מסחר',
    'panel.accounts.MarginSurplus': 'עודף ביטחונות',
    'panel.accounts.MarginUsedNew.descr': 'ביטחונות ראשוני חסם לתפקידים והוראות',
    'panel.accounts.MarginUsedNew': 'דרישת ביטחונות ראשונית',
    'panel.accounts.MarginWarning.descr': 'הודעת אזהרה בנוגע לביטחונות מופיע אם היתרה נמוכה מהרמה הזאת',
    'panel.accounts.MarginWarning': 'אזהרת ביטחונות',
    'panel.accounts.marginWarnPercent.descr': '% ,רמת אזהרה ביטחונות ',
    'panel.accounts.marginWarnPercent': 'רמת אזהרה ביטחונות',
    'panel.accounts.MarketDataCharging': 'נתוני שוק בטעינה',
    'panel.accounts.MaxDailyLossLimit.descr': 'ערך מקסימאלי של הפסד נטו יומי עבור חשבון',
    'panel.accounts.MaxDailyLossLimit': 'הגבלת הפסד יומי',
    'panel.accounts.MaxDailyProfit.descr': 'ערך מקסימאלי של רווח נטו יומי עבור חשבון',
    'panel.accounts.MaxDailyProfit': 'יעד רווח יומי',
    'panel.accounts.MaxDrawdownLevel.descr': 'מציין ערך צפוי מינימאלי מותר שהחשבון יכול להיות בו',
    'panel.accounts.MaximumLot.descr': 'כמות מקסימלית עבור הוראה אחת',
    'panel.accounts.MaximumLot': 'מקסימום לוט',
    'panel.accounts.MaxOrderAmount.descr': 'סכום הוראה מקסימאלי',
    'panel.accounts.MaxOrderAmount': 'מקסימום כמות הוראה',
    'panel.accounts.MaxOrderCapital.descr': 'הון מקסימלי להוראה אחת',
    'panel.accounts.MaxOrderCapital': 'מקסימום הון הוראות',
    'panel.accounts.MaxOrdersPerDay.descr': 'מספר ההוראות שהמשתמש יכול ליישם ביום',
    'panel.accounts.MaxOrdersPerDay': 'כמות הוראות מקסימלית ליום',
    'panel.accounts.MaxPendingOrders.descr': 'כמות הוראות ממתינות לביצוע מקסימלי לספור',
    'panel.accounts.MaxPendingOrders': '# מקס. הוראות ממתינות',
    'panel.accounts.MaxPendingOrdersValue.descr': 'מקסימום הצטברות ערך של הוראות ממתינות לביצוע שזמינות למשתמש',
    'panel.accounts.MaxPendingOrdersValue': 'מקסימום ערך הוראות בהמתנה',
    'panel.accounts.MaxPositionQtyPerSymbol.descr': 'כמות מקסימלית לפוזיציה אחת לכל סימול',
    'panel.accounts.MaxPositionQtyPerSymbol': 'כמות מקסימלית. לכל סימול',
    'panel.accounts.MaxPositions.descr': 'פוזיציות מקסימליים לספור',
    'panel.accounts.MaxPositions': '# מקס. פוזיציות',
    'panel.accounts.MaxWeekLostLimit.descr': 'ערך מקסימאלי של הפסד נטו בשבוע על חשבון',
    'panel.accounts.MaxWeekLostLimit': 'הגבלת הפסד שבועי',
    'panel.accounts.MaxRelativeDrawDownLevel': 'יחס רמת גריעה מקסימלי',
    'panel.accounts.MaxRelativeDrawDownLevel.descr': '.מציין יתרה צפויה מינימלית מותרת (מחושב באופן יחסי ליתרה) שיכולה להיות בחשבון',
    'panel.accounts.MinMargin.descr': 'ביטחונות חסומים לפוזיציות',
    'panel.accounts.MinMargin': 'ביטחונות המשומשים לפוזיציות',
    'panel.accounts.Murkups': 'Markups',
    'panel.accounts.N/A_TelephoneNumber': 'N / A',
    'panel.accounts.OpenBalance': '#hidden#',
    'panel.accounts.OptionPremiumReq.descr': 'פרמית אפשרות להוראות קנייה',
    'panel.accounts.OptionPremiumReq': 'דרישת אופציה פרימיום',
    'panel.accounts.OptionValue.descr': 'ערך האופציה נוכחי בתיק',
    'panel.accounts.OptionValue': 'ערך האופציה',
    'panel.accounts.Orders.descr': 'מספר ההוראות',
    'panel.accounts.Orders': '# הוראות',
    'panel.accounts.Owner.descr': 'הבעלים של החשבון',
    'panel.accounts.Owner': 'בעלים',
    'panel.accounts.Positions.descr': 'מספר פוזיציות',
    'panel.accounts.Positions': '# פוזיציות',
    'panel.accounts.PowerOfAttorney': 'ייפוי כוח',
    'panel.accounts.Profit.descr': 'רווח/הפסד של כל הפוזיציות הפתוחות',
    'panel.accounts.Profit': 'P/L פתח ברוטו ',
    'panel.accounts.ProfitNet.descr': 'רווח או הפסד נטו על פוזיציות פתוחות',
    'panel.accounts.ProfitNet': 'P/L פתח נטו ',
    'panel.accounts.Role.descr': 'סוג הקרן',
    'panel.accounts.Role': 'סוג הקרן',
    'panel.accounts.Spreads': 'מרווחים',
    'panel.accounts.Status.descr': 'סטטוס חשבון משתמש בעל (באינטרנט, לא מקוון)',
    'panel.accounts.Status': 'מצב',
    'panel.accounts.StocksLiquidity': 'נזילות מניות',
    'panel.accounts.StocksOrdersReq.descr': 'דרישת הוראות לניירות',
    'panel.accounts.StocksOrdersReq': 'דרישת הוראות מניות',
    'panel.accounts.StocksValue.descr': 'ערך מניות נוכחי בתיק',
    'panel.accounts.StocksValue': 'ערך מניות',
    'panel.accounts.stopOutPercent.descr': '% תחזוקת ביטחונות דרוש כאשר סטופ אווט מופיע',
    'panel.accounts.stopOutPercent': 'Stop out רמת ',
    'panel.accounts.stopTradingPercent.descr': '% ,רמת עצירת מסחר ',
    'panel.accounts.stopTradingPercent': 'רמת עצירת מסחר',
    'panel.accounts.Swap': 'עסקת החלף',
    'panel.accounts.TelephoneNumber': 'מספר טלפון',
    'panel.accounts.TodayFees.descr': 'סכום העמלות המשולמות על ידי חשבון',
    'panel.accounts.TodayFees': 'עמלות היום',
    'panel.accounts.TodayRebates.descr': 'סכום כל הכספים שהתקבלו / שולמו על ידי הברוקר ליצירה / הסרה של נזילות ליום הנוכחי',
    'panel.accounts.TodayRebates': 'ההחזרים של היום',
    'panel.accounts.TodaySwaps': '#hidden#',
    'panel.accounts.TodayTrades.descr': 'ספירה של עסקאות היום',
    'panel.accounts.TodayTrades': '# מהעסקאות של היום',
    'panel.accounts.TodayTurnover.descr': 'מחזור הכספים ליום עבור חשבון הנוכחי',
    'panel.accounts.TodayTurnover': '#hidden#',
    'panel.accounts.TodayVolume.descr': 'הנפח שנסחר היום',
    'panel.accounts.TodayVolume': 'הנפח של היום',
    'panel.accounts.TotalMaxPositionsQty.descr': 'כמות סכום מקסימלי של כל פוזיציות בלוט',
    'panel.accounts.TotalMaxPositionsQty': 'סה"כ כמות פוזיציה מקסימלית',
    'panel.accounts.TrailingDrawdownLevel.descr': 'רמת הפסד מקסימלי עבור חשבון',
    'panel.accounts.TrailingDrawdownLevel': 'רמת גריעה נגררת',
    'panel.accounts.UnrealizedLoss.descr': 'ערך הפסד שטרם מומש נוכחי של חשבון',
    'panel.accounts.UnrealizedLoss': 'הפסד לא ממומש',
    'panel.accounts.UnrealizedLossLimit.descr': 'ערך מקסימלי של הפסד שטרם מומש על חשבון',
    'panel.accounts.UnrealizedLossLimit': 'הגבלת הפסד לא ממומש',
    'panel.accounts.UnsettledCash.descr': 'רווח/הפסד, אשר יזוכה לחשבון בהתאם T + n מחזור ערך',
    'panel.accounts.UnsettledCash': 'מזומן שלא הוסדר',
    'panel.accounts.UserGroup.descr': 'קבוצת משתמש',
    'panel.accounts.UserGroup': 'קבוצת משתמש',
    'panel.accounts.UserID.descr': 'מזהה של משתמשים',
    'panel.accounts.UserID': 'מזהה המשתמש',
    'panel.accounts.UserLogin.descr': 'כניסה של משתמשים',
    'panel.accounts.UserLogin': 'כניסת משתמש',
    'panel.accounts.WaivedMargin.descr': 'ביטחונות ויתר על חשבון',
    'panel.accounts.WaivedMargin': 'ביטחונות ויתרה',
    'panel.accounts.WithdrawalAvailable.descr': 'מאזן מקסימלי זמין למשיכה',
    'panel.accounts.WithdrawalAvailable': 'משיכה זמינה',
    'panel.accounts.MarginBeforeWarning': 'ביטחונות לפני האזהרה',
    'panel.accounts.MarginBeforeWarning.descr': '.ביטחונות זמינים לפני אזהרה מפעילה',
    'panel.accounts': 'חשבונות',
    'panel.assetBalances': 'חשבונות',
    'panel.Assets.Asset type': 'סוג נכס',
    'panel.Assets.Description': 'תיאור',
    'panel.Assets.Interest rate, %': '(%) גובה הריבית ',
    'panel.Assets.Menu.SellAsset': 'מכירת נכס',
    'panel.Assets.Menu.SellAssetOn': 'מכירת נכס על',
    'panel.Assets.Minimal change': 'שינוי מינימלי',
    'panel.Assets.Name': 'שם',
    'panel.Assets.QuickTable.Column.Account': 'חשבון',
    'panel.Assets.QuickTable.Column.AssetName': 'שם נכס',
    'panel.Assets.QuickTable.Column.AvailableQtySell': 'כמות זמינה. למכירה',
    'panel.Assets.QuickTable.Column.CurrentPrice': 'מחיר נוכחי',
    'panel.Assets.QuickTable.Column.CurrentQty': '#hidden#',
    'panel.Assets.QuickTable.Column.CurrentValue': '#hidden#',
    'panel.Assets.QuickTable.Column.CurrentValueSell': 'ערך נוכחי למכירה',
    'panel.Assets.QuickTable.Column.DefaultSymbol': 'סימול ברירה',
    'panel.Assets.QuickTable.Column.ExchangeTrading': 'בורסת מסחר',
    'panel.Assets.QuickTable.Column.LiquidityRate': '% ,שיעור נזילות ',
    'panel.Assets.QuickTable.Column.SellAsset.cellsTooltip': 'כניסה להזמין הפעלה כדי לנכס למכור',
    'panel.Assets.QuickTable.Column.SellAsset': 'מכירת נכס',
    'panel.Assets.QuickTable.Column.SellExchange': 'מכירת חליפין',
    'panel.Assets.QuickTable.Column.StartDayQty': 'כמות בתחילת היום',
    'panel.Assets.QuickTable.Column.StartDayQtyMargin': 'כמות בתחילת היום עבור ביטחונות זמינים',
    'panel.Assets.QuickTable.Column.TodayTradedQty.descr': 'כמות נטו היום של הנכס הנסחר',
    'panel.Assets.QuickTable.Column.TodayTradedQty': '...כמות שנסחרה היום',
    'panel.assets': 'נכסים',
    'panel.backoffice': 'BackOffice',
    'panel.caption.NotAllowedMode': 'הפאנל הנוכחי אינו מותר בכללים סט',
    'panel.caption.showHideToolbar': 'הצג את סרגל כלים',
    'panel.chart': 'תרשים',
    'panel.close': 'סגירה',
    'panel.closePosition.Gross_P/L_ToClose': 'רווח והפסד כולל לסגירה',
    'panel.closePosition.QunatityToClose': 'הכמות לסגור',
    'panel.copypanel': 'פנל כפול',
    'panel.detail.AuctionEndTime': '#hidden#',
    'panel.detail.AskSource': 'מקור היצע',
    'panel.detail.BidSource': 'מקור ביקוש',
    'panel.detail.CBAuctionStart': '#hidden#',
    'panel.detail.change': 'שינוי',
    'panel.detail.changePr': '% ,שינוי ',
    'panel.detail.CurrentSession': '#hidden#',
    'panel.detail.ETB': '#hidden#',
    'panel.detail.FiftyTwoWeekHighPrice': '#hidden#',
    'panel.detail.FiftyTwoWeekLowPrice': '#hidden#',
    'panel.detail.high': 'גבוה',
    'panel.detail.last': 'אחרון',
    'panel.detail.LastSource': 'מקור אחרון',
    'panel.detail.LastTradedTime': '#hidden#',
    'panel.detail.LastUpdateTime': '#hidden#',
    'panel.detail.Limit.High': '#hidden#',
    'panel.detail.Limit.Low': '#hidden#',
    'panel.detail.low': 'נמוך',
    'panel.detail.NormalMarketSize': '#hidden#',
    'panel.detail.OffExchangeValue': '#hidden#',
    'panel.detail.OffExchangeVolume': '#hidden#',
    'panel.detail.open': 'פתיחה',
    'panel.detail.OpenInterest': 'פתח ריבית',
    'panel.detail.TradedValue': '#hidden#',
    'panel.detail.TradingStatus': '#hidden#',
    'panel.detail.volume': 'נפח',
    'panel.detail': 'ציטוט מפורט',
    'panel.EventLog.Action': 'אירוע',
    'panel.EventLog.Date': 'תאריך',
    'panel.EventLog.eventType.alerts': 'התראות',
    'panel.EventLog.eventType.disconnect': 'נתק',
    'panel.EventLog.eventType.exception': 'יוצא מן הכלל',
    'panel.EventLog.eventType.info': 'מידע',
    'panel.EventLog.eventType.none': 'אף אחד',
    'panel.EventLog.eventType.system': 'מערכת',
    'panel.EventLog.eventType.trading': 'מסחר',
    'panel.EventLog.eventType.updater': 'מעדכן',
    'panel.EventLog.menu.Clear': 'נקה',
    'panel.EventLog.Message': 'פרטים',
    'panel.EventLog.NamePanel': 'יומן אירועים',
    'panel.EventLog.Roundtrip': 'הלוך ושוב, אלפית שנייה',
    'panel.EventLog.Type': 'סוג',
    'panel.GridPanel.refreshAllCharts.ToolTip': 'רענן כל התרשימים',
    'panel.GridPanel.resetMI.Text': 'איפוס גודל פאנלים',
    'panel.HeatmapPanel': 'מפת חום',
    'panel.HeatmapPanel.Settings.GradationScale': 'סולם הדרגות',
    'panel.HeatmapPanel.Settings.IndustryLevel': 'רמת התעשייה',
    'panel.HeatmapPanel.Settings.SectorLevel': 'רמת מגזר',
    'panel.HeatmapPanel.Settings.SymbolLevel': 'רמת סימול',
    'panel.informer': 'רשימת מעקב',
    'panel.instrumentInfo.AvgPrice': ':מחיר ממוצע',
    'panel.instrumentInfo.P/L': 'P/L:',
    'panel.instrumentInfo.Qty': ':כמות',
    'panel.instruments': 'סימולים',
    'panel.instrumentsInfo': 'מידע על הסימול',
    'panel.Level2.Age': '#hidden#',
    'panel.level2.Auction': 'AUC',
    'panel.Level2.AvgPrice': '.מחיר ממוצע ',
    'panel.Level2.Contr. CCY Total value': 'Contr. סה"כ ערך Ccy',
    'panel.Level2.Contr. CCY value': 'Contr Ccy ערך ',
    'panel.Level2.DayTradeVolume': 'נפח',
    'panel.Level2.menu.View.InstrInfo': 'בר הפוזיציה',
    'panel.Level2.menu.View.Level1': 'Level1 בר ',
    'panel.Level2.menu.View': 'הצג',
    'panel.Level2.MPID': 'MPID',
    'panel.Level2.Number': 'הוראה',
    'panel.Level2.NumberOfOrders': '#hidden#',
    'panel.Level2.oe.BuyButton': 'קנייה',
    'panel.Level2.oe.SellButton': 'מכירה',
    'panel.Level2.Price': 'מחיר',
    'panel.Level2.Size': 'גודל',
    'panel.Level2.Source': 'מקור',
    'panel.Level2.Time': 'זמן',
    'panel.Level2.TotalVol': 'סה"כ גודל',
    'panel.level2': 'עומק שוק',
    'panel.log': 'יומן אירועים',
    'panel.menu.GroupBy': 'קבוצה על ידי',
    'panel.menu.new': 'חדש',
    'panel.menu.Remove': 'הסר',
    'panel.menu.resetToDefault': 'אפס לברירת מחדל',
    'panel.neworder': 'הכנסת הוראה',
    'panel.newOrderEntry.accountLabel': 'חשבון',
    'panel.newOrderEntry.AddAskButton': 'מכירה לפי היצע',
    'panel.newOrderEntry.AddBitButton': 'לקנות בביקוש',
    'panel.newOrderEntry.amountLabel': 'כמות',
    'panel.newOrderEntry.BuyAskButton': 'לקנות בהיצע',
    'panel.newOrderEntry.BuyMarketButton': 'לקנות המחיר שוק',
    'panel.newOrderEntry.buySideCheckBox': 'קנייה',
    'panel.newOrderEntry.disclosedLabel': '#hidden#',
    'panel.newOrderEntry.instrumentLabel': 'סימול',
    'panel.newOrderEntry.limitPricePanel': 'מחיר לימיט',
    'panel.newOrderEntry.placeOrderButton': 'הצב הוראה',
    'panel.newOrderEntry.ProductType': 'סוג המוצר',
    'panel.newOrderEntry.RefreshMarginReq': 'לחץ כדי לרענן את הנתונים ביטחונותים, או לחכות 30 שניות.',
    'panel.newOrderEntry.riskDetailsLabel': 'ביטחונות',
    'panel.newOrderEntry.SellBidButton': 'מכירה לפי ביקוש',
    'panel.newOrderEntry.SellMarketButton': 'Sell Market',
    'panel.newOrderEntry.sellSideCheckBox': 'מכירה',
    'panel.newOrderEntry.Side': 'צד',
    'panel.newOrderEntry.singleOrderRadio': 'יחיד',
    'panel.newOrderEntry.slPriceRisk': 'SL מחיר ',
    'panel.newOrderEntry.slPriceRiskOffset': 'SL קיזוז ',
    'panel.newOrderEntry.stopLossCheckBox.offset': 'SL קיזוז ',
    'panel.newOrderEntry.stopLossCheckBox': 'SL מחיר ',
    'panel.newOrderEntry.stopLossNumeric.ToolTip.offset': 'הגדר Stop Loss בערכי קיזוז. כדי לעבור למחיר, גש אל הגדרות כלליות\ברירות מחדל המסחר',
    'panel.newOrderEntry.stopLossNumeric.ToolTip': 'הגדירו Stop Loss בערכי המחיר. כדי לעבור לערכי קיזוז, גש אל הגדרות כלליות\ברירות מחדל המסחר',
    'panel.newOrderEntry.stopPricePanel': 'Stop מחיר ',
    'panel.newOrderEntry.takeProfitCheckBox.offset': 'TP קיזוז ',
    'panel.newOrderEntry.takeProfitCheckBox': 'TP מחיר ',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip.offset': 'הגדר Take Profit בערכי קיזוז. כדי לעבור למחיר, גש אל הגדרות כלליות\ברירות מחדל המסחר',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip': 'הגדר Take Profit בערכי מחיר. כדי לעבור לערכי קיזוז, גש אל הגדרות כלליות\ברירות מחדל המסחר',
    'panel.newOrderEntry.tifPanel': 'TIF',
    'panel.newOrderEntry.tpPriceRisk': 'TP מחיר ',
    'panel.newOrderEntry.tpPriceRiskOffset': 'TP קיזוז ',
    'panel.newOrderEntry.trstopOffset': 'קיזוז Stop נגרר',
    'panel.newOrderEntry.typePanel': 'סוג הוראה',
    'panel.news': 'חדשות',
    'panel.NewTrades.Account': 'חשבון',
    'panel.NewTrades.Amount': 'כמות',
    'panel.NewTrades.Bought': 'קנה',
    'panel.NewTrades.Commission.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Commission': 'עמלת ביצוע',
    'panel.NewTrades.Date': 'תאריך שעה',
    'panel.NewTrades.Description': 'תיאור',
    'panel.NewTrades.ExchangeTrading': 'בורסת מסחר',
    'panel.NewTrades.ExpirationDate': 'Exp. תאריך',
    'panel.NewTrades.ExternalOrderId': '#hidden#',
    'panel.NewTrades.ExternalPrice': 'מחיר חיצוני',
    'panel.NewTrades.instrType': 'סוג סימול',
    'panel.NewTrades.Instrument': 'סימול',
    'panel.NewTrades.Login': 'התחברות',
    'panel.NewTrades.Net_PL.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Net_PL': 'P/L נטו ',
    'panel.NewTrades.Operation': 'צד',
    'panel.NewTrades.OrderId': 'מזהה הוראה',
    'panel.NewTrades.OrderType': 'סוג הוראה',
    'panel.NewTrades.Price': 'מחיר',
    'panel.NewTrades.ProductType': 'סוג המוצר',
    'panel.NewTrades.PurchasePrice': 'חשיפה',
    'panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.RealizedProfitLoss': 'רווח והפסד כולל',
    'panel.NewTrades.Rebates': 'הנחות',
    'panel.NewTrades.Route': 'נתיב',
    'panel.NewTrades.Sold': 'נמכר',
    'panel.NewTrades.StrikePrice': 'מימוש',
    'panel.NewTrades.Time': 'זמן',
    'panel.NewTrades.TradeID': 'ID תערוכות ',
    'panel.NewTrades.TradeIDLabel': '#hidden#',
    'panel.OrderBook.Account': 'חשבון',
    'panel.OrderBook.Action': 'צד',
    'panel.OrderBook.Bought': 'קנה',
    'panel.OrderBook.Date': 'תאריך שעה',
    'panel.OrderBook.Description': 'תיאור',
    'panel.OrderBook.DislocedQty': '#hidden#',
    'panel.OrderBook.Event': 'אירוע',
    'panel.OrderBook.ExchangeTrading': 'בורסת מסחר',
    'panel.OrderBook.ExpirationDate': 'Exp. תאריך',
    'panel.OrderBook.FilledQuantity': '#hidden#',
    'panel.OrderBook.instrType': 'סוג סימול',
    'panel.OrderBook.Instrument': 'סימול',
    'panel.OrderBook.Login': 'התחברות',
    'panel.OrderBook.Message': 'הודעה',
    'panel.OrderBook.OrderNumber': 'מזהה הוראה',
    'panel.OrderBook.Price': 'מחיר',
    'panel.OrderBook.ProductType': 'סוג המוצר',
    'panel.OrderBook.Quantity': 'כמות',
    'panel.OrderBook.ReminingQuantity': '#hidden#',
    'panel.OrderBook.RemoteID': 'מזהה מרחוק',
    'panel.OrderBook.Route': 'נתיב',
    'panel.OrderBook.Sold': 'נמכר',
    'panel.OrderBook.StopPrice': 'Stop מחיר ',
    'panel.OrderBook.StrikePrice': 'מימוש',
    'panel.OrderBook.TIF': 'TIF',
    'panel.OrderBook.Time': 'זמן',
    'panel.OrderBook.TraderID': 'שונה על ידי המשתמש',
    'panel.OrderBook.Type': 'סוג',
    'panel.orderEntry': 'הכנסת הוראה',
    'panel.orderHistory': 'היסטורית הוראות',
    'panel.orders.Account': 'חשבון',
    'panel.orders.action': 'צד',
    'panel.orders.AvgFilledPrice': '#hidden#',
    'panel.orders.BindTo': 'מחויב ל',
    'panel.orders.Cancel': 'בטל',
    'panel.orders.Comment': '#hidden#',
    'panel.orders.Comments': 'תגובות',
    'panel.orders.Ct_Ahead': 'Ct. קדימה',
    'panel.orders.CurrentPrice': 'מחיר נוכחי',
    'panel.orders.date': 'תאריך',
    'panel.orders.disclosedQty': '#hidden#',
    'panel.orders.Event': 'אירוע',
    'panel.orders.ExchangeTrading': 'בורסת מסחר',
    'panel.orders.ExpirationDate': 'Exp. תאריך',
    'panel.orders.ExternalOrderId': '#hidden#',
    'panel.orders.ExternalOrderStatus_0': 'ממתין חדש',
    'panel.orders.ExternalOrderStatus_1': 'חדש',
    'panel.orders.ExternalOrderStatus_10': 'שוק המתנה',
    'panel.orders.ExternalOrderStatus_2': 'בוצע באופן חלקי',
    'panel.orders.ExternalOrderStatus_3': 'מומש',
    'panel.orders.ExternalOrderStatus_4': 'נדחה',
    'panel.orders.ExternalOrderStatus_5': 'מבוטל',
    'panel.orders.ExternalOrderStatus_6': 'ביטול עבור שינוי',
    'panel.orders.ExternalOrderStatus_7': 'שינוי ממתין',
    'panel.orders.ExternalOrderStatus_8': 'בהמתנה לביטול',
    'panel.orders.ExternalOrderStatus_9': 'הקפא',
    'panel.orders.ExternalOrderStatus': '#hidden#',
    'panel.orders.GroupId': 'MAM מזהה קבוצת ',
    'panel.orders.InitReq': 'דרישות ראשונית',
    'panel.orders.Instr_Type': 'סוג סימול',
    'panel.orders.Login': 'התחברות',
    'panel.orders.Market': 'שוק',
    'panel.orders.menu.CancelAllByInstrument': 'בטל הכל לפי סימול שנבחר',
    'panel.orders.menu.CancelAllForAccount': 'בטל הכל עבור חשבון נבחר',
    'panel.orders.menu.CancelAllOrders': 'בטל את כל ההורואת',
    'panel.orders.menu.CancelOrder': 'בטל הוראה',
    'panel.orders.menu.ExecuteAsMarket.question': '?האם אתה בטוח שאתה רוצה לבצע את ההוראה במחיר השוק',
    'panel.orders.menu.ExecuteAsMarket': 'שינוי למחיר שוק',
    'panel.orders.menu.ModifyOrder': '...סדר שינוי ',
    'panel.orders.menu.Remove': 'בטל הוראה',
    'panel.orders.menu.ShowToolbar': 'הצג את סרגל כלים',
    'panel.orders.OCOGrouID': 'OCO מזהה קבוצת ',
    'panel.orders.price': 'מחיר',
    'panel.orders.ProductType': 'סוג המוצר',
    'panel.orders.quantity': 'כמות',
    'panel.orders.QuantityFilled': 'כמות בוצעה',
    'panel.orders.QuantityRemaining': 'כמות שנותרה',
    'panel.orders.Reference': 'מזהה הוראה',
    'panel.orders.RemoteID': 'מזהה מרחוק',
    'panel.orders.Route': 'נתיב',
    'panel.orders.showChartMI': '#hidden#',
    'panel.orders.showMDMI': '#hidden#',
    'panel.orders.showTSMI': '#hidden#',
    'panel.orders.showOEMI': '#hidden#',
    'panel.orders.SL_Offset': 'SL קיזוז ',
    'panel.orders.SLL_Offset': 'SL Limit קיזוז ',
    'panel.orders.SL_Price': 'SL מחיר ',
    'panel.orders.SLL_Price': 'SL Limit מחיר ',
    'panel.orders.SL': 'SL',
    'panel.orders.Status': 'מצב',
    'panel.orders.StopPrice': 'Stop מחיר ',
    'panel.orders.StrikePrice': 'מימוש',
    'panel.orders.symbol': 'סימול',
    'panel.orders.symbolDescription': 'תיאור סימול',
    'panel.orders.TIF': 'TIF',
    'panel.orders.time': 'זמן',
    'panel.orders.Token': 'מזהה הוראה',
    'panel.orders.TP_Offset': 'TP קיזוז ',
    'panel.orders.TP_Price': 'TP מחיר ',
    'panel.orders.TP': 'TP',
    'panel.orders.TraderID': 'מזהה הסוחר',
    'panel.orders.type': 'סוג',
    'panel.orders.UserGroup': 'קבוצת משתמש',
    'panel.orders': 'הוראות ממתינות למימוש',
    'panel.positions.Account': 'חשבון',
    'panel.positions.AvailableForSell': '#hidden#',
    'panel.positions.Basis': 'מחיר פתיחה',
    'panel.positions.Close': 'סגירה',
    'panel.positions.comments': 'תגובות',
    'panel.positions.commissions.InstrumentCurrency': '#hidden#',
    'panel.positions.commissions': 'עמלות',
    'panel.positions.confirmation.ModifyProductType': '?{4} ,{3} ,{2} סוג שינוי במוצר מ- {0} ל {1} עבור ',
    'panel.positions.confirmation.Reverse': 'הפוך',
    'panel.positions.confirmation.ReverseAllPositions': '?האם אתה בטוח שאתה רוצה בטל הכל פוזיציות',
    'panel.positions.confirmation.ReverseByInstrument': '?האם אתה בטוח שאתה רוצה בטל הכל פוזיציות על ידי סימול חשבון נבחרים',
    'panel.positions.confirmation.ReverseSelectedPositions': '?האם אתה בטוח שאתה רוצה לבטל פוזיציות שנבחרו',
    'panel.positions.cur_price': 'מחיר נוכחי',
    'panel.positions.date_time': 'תאריך שעה',
    'panel.positions.Delta': 'דלתא',
    'panel.positions.ExchangeTrading': 'בורסת מסחר',
    'panel.positions.ExpDate': 'Exp. תאריך',
    'panel.positions.exposition_usd': 'חשיפת פוזיציה',
    'panel.positions.Gamma': 'גמא',
    'panel.positions.GroupId': 'MAM מזהה קבוצת ',
    'panel.positions.InitReq': 'דרישות ראשונית',
    'panel.positions.IV': 'IV',
    'panel.positions.Login': 'התחברות',
    'panel.positions.MaintReq': '.תחזוקה דרושה',
    'panel.positions.MarginSuplus': 'Surpl./Def. ביטחונותם',
    'panel.positions.menu.Close': 'פוזיציה סגורה ',
    'panel.positions.menu.Modify': 'שנה פוזיציה',
    'panel.positions.menu.ModifyProductType': 'שנה סוג מוצר',
    'panel.positions.menu.ShowToolbar': 'הצג את סרגל כלים',
    'panel.positions.menu.ShowTotals': 'הצג סיכומים',
    'panel.positions.menu.View': 'הצג',
    'panel.positions.Net_PL.InstrumentCurrency': '#hidden#',
    'panel.positions.Net_PL': 'P/L נטו ',
    'panel.positions.Operation': 'צד',
    'panel.positions.PL_ticks.points': 'נקודות',
    'panel.positions.PL_ticks.ticks': 'טיקים',
    'panel.positions.PL_ticks': 'רווח/הפסד, קיזוז',
    'panel.positions.pos_number': 'מזהה פוזיצייה',
    'panel.positions.PositionValue': 'ערך פוזיציה',
    'panel.positions.ProductType': 'סוג המוצר',
    'panel.positions.profit_usd.InstrumentCurrency': '#hidden#',
    'panel.positions.profit_usd': 'רווח והפסד כולל',
    'panel.positions.Qunatity': 'כמות',
    'panel.positions.RealizedPnL.InstrumentCurrency': '#hidden#',
    'panel.positions.RealizedPnL': '#hidden#',
    'panel.positions.Rho': 'Rho',
    'panel.positions.route': 'נתיב',
    'panel.positions.showChartMI': '#hidden#',
    'panel.positions.showMDMI': '#hidden#',
    'panel.positions.showTSMI': '#hidden#',
    'panel.positions.showOEMI': '#hidden#',
    'panel.positions.SL_Value': ' ערך ,SL',
    'panel.positions.SL': 'SL מחיר ',
    'panel.positions.StartOfDayQty': '#hidden#',
    'panel.positions.strike': 'מימוש',
    'panel.positions.swaps': 'החלפות',
    'panel.positions.Symbol': 'סימול',
    'panel.positions.SymbolDescription': 'תיאור סימול',
    'panel.positions.Theta': 'תטא',
    'panel.positions.TP': 'TP מחיר ',
    'panel.positions.type': 'סוג סימול',
    'panel.positions.UsedMargin': 'ביטחונות משומשים',
    'panel.positions.UserGroup': 'קבוצת משתמש',
    'panel.positions.Vega': 'Vega',
    'panel.positions': 'פוזיציות',
    'panel.Products.Unsubscribe.PopupMessage': '?{0} לבטל את המנוי למוצר ',
    'panel.Products.Request.CancelRequest': 'בטל בקשה',
    'panel.Products.Request.CancelRequest.Message': '?האם ברצונכם לבטל את הבקשה',
    'panel.settings.Information': 'מידע',
    'panel.settings.saveDefault': '!נשמר בהצלחה',
    'panel.timeSales.Agressor flag': 'צד',
    'panel.timeSales.AgressorFlag.Auction': 'Auction',
    'panel.timeSales.AgressorFlag.Buy': 'קנייה',
    'panel.timeSales.AgressorFlag.CrossTrade': 'עסקה מוצלבת',
    'panel.timeSales.AgressorFlag.None': 'אף אחד',
    'panel.timeSales.AgressorFlag.RLP': 'RLP',
    'panel.timeSales.AgressorFlag.Sell': 'מכירה',
    'panel.timeSales.ask': 'היצע',
    'panel.timeSales.AskBidSize': 'BidxAsk גודל',
    'panel.timeSales.AskSource': 'MPID הצעות',
    'panel.timeSales.bid': 'הצעת מחיר',
    'panel.timeSales.BidSource': 'MPID ביקושי ',
    'panel.timeSales.BuyerSource': 'הקונה',
    'panel.timeSales.Exchange': 'מקור',
    'panel.timeSales.Instrument': 'סימול',
    'panel.timeSales.MenuName': 'זמן ומכירות',
    'panel.timeSales.Price': 'מחיר',
    'panel.timeSales.SellerSource': 'מוכר',
    'panel.timeSales.Size': 'גודל',
    'panel.timeSales.Time': 'זמן',
    'panel.timeSales.Type': 'סוג סימול',
    'panel.timeSales': 'זמן & מכירות',
    'panel.tooltiper.System': 'מערכת',
    'panel.trades': 'הוראות ממומשות',
    'panel.tradingCentral.ScreenButton': 'מסך',
    'panel.tradingCentral': 'מרכז מסחר',
    'panel.watchlist.Ask': 'היצע',
    'panel.watchlist.AskSize': 'גודל היצע',
    'panel.watchlist.AuctionEndTime': '#hidden#',
    'panel.watchlist.AuctionNumber': '#hidden#',
    'panel.watchlist.AuctionStatus': '#hidden#',
    'panel.watchlist.Bid': 'הצעת מחיר',
    'panel.watchlist.BidSize': 'גודל ביקוש',
    'panel.watchlist.Buy': 'קנייה',
    'panel.watchlist.CBAuctionStart': '#hidden#',
    'panel.watchlist.cell.cancel': 'בטל',
    'panel.watchlist.cell.modify': 'שינוי',
    'panel.watchlist.cell.Not allow': 'לא מורשה',
    'panel.watchlist.cell.sending': 'חדש',
    'panel.watchlist.cell.ticks': 'טיקים',
    'panel.watchlist.Change': 'שינוי',
    'panel.watchlist.ChangeFromOpen%': '% ,שינוי מהפתיחה',
    'panel.watchlist.ChangeFromOpen': 'שינוי מן הפתיחה',
    'panel.watchlist.ChangePr': '% ,שינוי ',
    'panel.watchlist.Close': 'סגירה',
    'panel.watchlist.ClosePostMarket': 'סגירה אחרי שוק',
    'panel.watchlist.Code': '#hidden#',
    'panel.watchlist.Comment': 'תגובה',
    'panel.watchlist.Company': 'חברה',
    'panel.watchlist.CurrentSession': 'הפעלה נוכחית',
    'panel.watchlist.Date': 'תאריך אחרון',
    'panel.watchlist.Descr': 'תיאור',
    'panel.watchlist.ExchangeMarketData': 'חילופי נתוני שוק',
    'panel.watchlist.ExchangeTrading': 'בורסת מסחר',
    'panel.watchlist.ExpDate': 'Exp. תאריך',
    'panel.watchlist.High': 'גבוה',
    'panel.watchlist.High13': '#hidden#',
    'panel.watchlist.High26': '#hidden#',
    'panel.watchlist.High52': '#hidden#',
    'panel.watchlist.HighOpenInterest': '#hidden#',
    'panel.watchlist.IndicativeAuctionPrice': 'Ind. PR הפומבית.',
    'panel.watchlist.InitiatorPrice': '#hidden#',
    'panel.watchlist.InitiatorType': '#hidden#',
    'panel.watchlist.Instrument': 'סימול',
    'panel.watchlist.InstrumentType': '#hidden#',
    'panel.watchlist.Last': 'אחרון',
    'panel.watchlist.LastSize': 'הגודל אחרון',
    'panel.watchlist.LastTradedTime': '#hidden#',
    'panel.watchlist.Limit.High': '#hidden#',
    'panel.watchlist.Limit.Low': '#hidden#',
    'panel.watchlist.Low': 'נמוך',
    'panel.watchlist.Low13': '#hidden#',
    'panel.watchlist.Low26': '#hidden#',
    'panel.watchlist.Low52': '#hidden#',
    'panel.watchlist.LowOpenInterest': '#hidden#',
    'panel.watchlist.Main Volume': 'נפח ראשי',
    'panel.watchlist.Market': 'שוק',
    'panel.watchlist.MarketCap': '.שווי שוק',
    'panel.watchlist.MarketType': '#hidden#',
    'panel.watchlist.Match Volume': 'נפח תואם',
    'panel.watchlist.menu.clearAll': 'נקה הכל',
    'panel.watchlist.menu.RemoveInstrument': 'הסר סימולים נבחרים',
    'panel.watchlist.NA': 'N / A',
    'panel.watchlist.noQuotes': 'העדכון אחרון',
    'panel.watchlist.NormalMarketSize': '#hidden#',
    'panel.watchlist.OffExchangeValue': '#hidden#',
    'panel.watchlist.OffExchangeVolume': '#hidden#',
    'panel.watchlist.Open': 'פתיחה',
    'panel.watchlist.OpenInterest': '#hidden#',
    'panel.watchlist.OpenPreMarket': 'פתוח לפני פתיחת המסחר',
    'panel.watchlist.Order': '#hidden#',
    'panel.watchlist.PreMarketChange%': '% ,השינוי לפני פתיחת המסחר',
    'panel.watchlist.PreMarketChange': 'השינוי לפני פתיחת המסחר',
    'panel.watchlist.PrevClose': 'סגירה קודמת',
    'panel.watchlist.PrevSettlementPrice': 'מחיר הסדר קודם',
    'panel.watchlist.ReferencePrice': 'מחיר הפניה',
    'panel.watchlist.Sell': 'מכירה',
    'panel.watchlist.Series': '#hidden#',
    'panel.watchlist.SettlementPrice': 'מחיר הסדר',
    'panel.watchlist.Spread': 'מרווח',
    'panel.watchlist.SpreadPr': '% ,מרווח ',
    'panel.watchlist.Ticks': 'טיקים',
    'panel.watchlist.TicksPostMarket': 'מדרגות אחרי השוק',
    'panel.watchlist.TicksPreMarket': 'מדרגות לפני פתיחת המסחר',
    'panel.watchlist.Time': 'פעם אחרונה',
    'panel.watchlist.TotalBuyQuantity': '#hidden#',
    'panel.watchlist.TotalSellQuantity': '#hidden#',
    'panel.watchlist.TradedValue': '#hidden#',
    'panel.watchlist.Type': 'סוג סימול',
    'panel.watchlist.Volume': 'נפח',
    'panel.watchlist.VolumePostMarket': 'ווליום לאחר השוק',
    'panel.watchlist.VolumePreMarket': 'נפח לפני פתיחת המסחר',
    'panel.watchlist.AskSource': 'מקור היצע',
    'panel.watchlist.BidSource': 'מקור ביקוש',
    'panel.watchlist.LastSource': 'מקור אחרון',
    'panel.watchlist': 'רשימת מעקב',
    'Panels.MarketDepth.CancelAll': 'בטל הכל',
    'panels.orders.buttons.AsMarket': 'שינוי למחיר שוק',
    'panels.orders.buttons.CancelAllButton': 'בטל הכל',
    'panels.orders.buttons.CancelBuyButton': 'בטל קניה',
    'panels.orders.buttons.CancelByInstrumentButton': 'בטל',
    'panels.orders.buttons.CancelDayButton': 'בטל ימים',
    'panels.orders.buttons.CancelGTCButton': 'GTCS בטל ',
    'panels.orders.buttons.CancelLimitsButton': 'בטל הגבולות',
    'panels.orders.buttons.CancelSelectedButton': 'בטל בחירה',
    'panels.orders.buttons.CancelSellButton': 'בטל מכירה',
    'panels.orders.buttons.CancelStopsButton': 'Stops בטל ',
    'panels.orders.buttons.ModifyOrder': 'שנה הוראה',
    'panels.positions.buttons.closeAll': 'סגור הכל',
    'panels.positions.buttons.closeForInstrument': 'סגירה',
    'panels.positions.buttons.closeLong': 'LONG סגירת פוזיציית ',
    'panels.positions.buttons.closeNegative': 'סגירה שלילית',
    'panels.positions.buttons.closePositive': 'סגירה חיובית',
    'panels.positions.buttons.closeSelected': 'Close סגור את שנבחרו',
    'panels.positions.buttons.closeShort': 'סגירת הוראת Short',
    'panels.positions.buttons.clxAll': 'CLX כל ',
    'panels.positions.buttons.clxForInstrument': 'CLX',
    'panels.positions.ButtonsPanel.helpLabel': 'לחץ לחיצה ימנית כדי להראות/להסתיר כפתורים',
    'PanelSettingsScreen.Text': 'הגדרות',
    'PanelSettingsScreen.TextSmall': 'הגדרות',
    'Parabolic Time/Price System': 'מערכת זמן/מחיר פרבולי',
    'Percentage Price Oscillator': 'אחוזי מתנד מחיר',
    'period.Day': 'יום',
    'period.Days': 'ימים',
    'period.Hour': 'שעה',
    'period.Hours': 'שעה/ות',
    'period.Min': 'מינימום',
    'period.Mins': 'דקות',
    'period.Month': 'חודש',
    'period.Months': 'חודשים',
    'period.Week': 'שבוע',
    'period.Weeks': 'שבועות',
    'period.Year': 'שנה',
    'period.Years': 'שנים',
    'Pivot Point Moving Average': 'Moving Average נקודת ציר',
    PivotPoint: 'נקודת הציר',
    'PopupMultiCkeckBox.All': 'את כל',
    Português: 'פורטוגזית (ברזיל)',
    'Price Channel': 'ערוץ מחיר',
    'Price Oscillator': 'מתנד מחיר',
    'ProductType.CarryForward': 'להעביר הלאה',
    'ProductType.CorporateAction': 'פעולה ארגונית',
    'ProductType.Delivery': 'משלוח',
    'ProductType.General': 'כללי',
    'ProductType.Intraday': 'תוך יומי',
    'property.%': '%',
    'property.Additional': 'נוסף',
    'property.Alerts': 'התראות',
    'property.AllPrice': 'סטטיסטיקות מחיר',
    'property.appearance': 'מראה חיצוני',
    'property.Ask': 'היצע',
    'property.Author': 'מחבר',
    'property.autosize': 'גודל אוטומטי של עמודות',
    'property.autoYScale.Auto': 'סולם אוטומטי',
    'property.autoYScale.Manual': 'קנה מידה ידני',
    'property.Average': 'ממוצע',
    'property.Backgr': 'צבע אחורי',
    'property.BarPattern': 'דפוס בר',
    'property.barsHiLowColor': 'קו',
    'property.BarStatistics': 'סטטיסטיקה בר',
    'property.Bid': 'הצעת מחיר',
    'property.bodyColor': 'גוף',
    'property.borderColor': 'גבול',
    'property.Browser': 'דפדפן',
    'property.Buy': 'קנייה',
    'property.BuyColor': 'קנייה',
    'property.cASK': 'משימה',
    'property.cASKHI': 'מעל ביקוש',
    'property.cBID': 'לפי ביקוש',
    'property.cBIDLO': 'להלן הצעה',
    'property.cellscin.useSellBuy': 'הפוך כפתורי הוראה',
    'property.Change': 'שינוי',
    'property.ChangePercent': '% ,שינוי',
    'property.Chart OE': 'תרשים כניסה להזמין',
    'property.chart.isBackground': 'עבור לרקע',
    'property.chart.line': 'קו',
    'property.chart.ray': 'RAY',
    'property.chart.rayStyle': 'סגנון ריי',
    'property.chart.showingOE': 'הצג הכנסת הוראה',
    'property.chart.stickTo': 'לדבוק ב',
    'property.chart.tools.back': 'ציור מילוי',
    'property.chart.tools.fibretr.font': 'סמן גופן',
    'property.chart.tools.fibretr.fontcolor': 'צבע גופן',
    'property.Chart': 'תרשים',
    'property.ChartDrawingToolsToolBar': 'סרגל כלי ציור',
    'property.Close': 'סגירה',
    'property.Cluster.ClusterUpDownColors': 'אשכול',
    'property.Cluster.ColorScheme.ByDelta.Colors.BuyColor': 'קנייה',
    'property.Cluster.ColorScheme.ByDelta.Colors.SellColor': 'מכירה',
    'property.Cluster.ColorScheme.ByDelta.Colors': 'צבע',
    'property.Cluster.ColorScheme.ByDelta': 'על ידי דלתא',
    'property.Cluster.ColorScheme.ByTrades': 'על ידי עסקאות',
    'property.Cluster.ColorScheme.ByVolume': 'לפי נפח',
    'property.Cluster.ColorScheme.Color': 'צבע',
    'property.Cluster.ColorScheme.None': 'אף אחד',
    'property.Cluster.ColorScheme': 'סכמת צבעים',
    'property.Cluster.CustomStep': 'צעד מותאם אישית',
    'property.Cluster.Down': 'מטה',
    'property.Cluster.FilterValue': 'ערך מסנן',
    'property.Cluster.FontColor': 'צבע גופן',
    'property.Cluster.HighlightMax.Colors': 'צבעים',
    'property.Cluster.HighlightMax': 'מקסימום מודגש',
    'property.Cluster.Operator': 'המפעיל',
    'property.Cluster.PriceStep.Custom': 'מותאם אישית',
    'property.Cluster.PriceStep.MinTick': 'מדרגה מינימלית',
    'property.Cluster.PriceStep': 'צעד מחיר',
    'property.Cluster.ShowOnlySimilarDelta': 'הצג רק דלתה דומה',
    'property.Cluster.ShowValueFilter': 'ערך מסנן',
    'property.Cluster.Up': 'למעלה',
    'property.cMIDLO': 'בין המרווח',
    'property.Color': 'צבע',
    'property.colors_param': 'צבעים',
    'property.Colors': 'צבעים',
    'property.colorScheme': 'סגנון',
    'property.ColorsSeparatorGroup': 'צבעים',
    'property.ColouringMode': 'מצב צביעה',
    'property.Comments': 'תגובות',
    'property.Company': 'חברה',
    'property.Confirmations': 'אישורים',
    'property.ConfirmationSubgroup.Other': 'אחר',
    'property.connection': 'חיבור',
    'property.Connection': 'חיבור',
    'property.ContentAlignment': 'יישור',
    'property.Copyrights': 'זכויות יוצרים',
    'property.cQUOTE': 'ציטוטים',
    'property.CreateColor': 'צור',
    'property.crossHairFontColor': 'צבע גופן',
    'property.currency': 'מטבע',
    'property.CustomYMarkingValue': 'צעד רשת מינימלי',
    'property.Data Box': 'חלון מידע',
    'property.Data Style': 'סגנון נתונים',
    'property.Day': 'יום',
    'property.DaySeparatorsVisibility': 'מפרידי יום',
    'property.dealTickets.openOnWorkspace': 'פתח כרטיסי עסקה על מרחב העבודה',
    'property.DefaultProductType': 'ברירת מחדל של סוג מוצר ',
    'property.Defaults': 'ברירות מחדל המסחר',
    'property.dK': 'DK',
    'property.Doji': 'Doji',
    'property.Downband deviation in %': 'סטיית Downband ב%',
    'property.email': 'כתובות דוא"ל\n(השתמש בפסיק או נקודה-פסיק)',
    'property.Emulator': 'Emulator',
    'property.DisableStartEndSounds': '#hidden#',
    'property.Enable Sounds': 'אפשר צלילים',
    'property.enable': 'אפשר',
    'property.Exponential': 'מעריכים',
    'property.fib.Arc': 'קשת פיבונאצ\'י',
    'property.Fibonacci': 'פיבונאצ\'י',
    'property.Filters': 'מסנן',
    'property.FitAlerts': 'התאם התראות',
    'property.FitDrawings': 'התאם ציורים',
    'property.FitHighLow': 'התאם יום גבוה/נמוך',
    'property.FitIndicator': 'Fit אינדיקטורים ',
    'property.FitOrders': 'הוראות Fit / פוזיציות',
    'property.FOK': 'FOK',
    'property.Font': 'גופן',
    'property.FontColor': 'צבע גופן',
    'property.Forex': 'מט"ח',
    'property.Forwards': 'ע"ע',
    'property.Futures': 'חוזים עתידיים',
    'property.FX Cell': 'תא מט"ח',
    'property.G': 'G',
    'property.general.defaultSymbol': 'סימול',
    'property.general.DisableInactivityPeriod.confirmation.caption': 'גילוי סיכון',
    'property.general.DisableInactivityPeriod.confirmation.message': 'השבת את תקופת חוסר הפעילות.\nאני יודע/ת, מבין/ה ומקבל/ת את הסיכונים הכרוכים בהשבתת תקופת חוסר הפעילות.',
    'property.general.showLotsFX': 'כמות תצוגה בכמות',
    'property.General': 'כללי',
    'property.gr_type': 'סגנון',
    'property.GraphicType.Absolute': 'מוחלט',
    'property.GraphicType.Log': 'לוגריתמי',
    'property.GraphicType.Relative': 'יחסי',
    'property.GridPrice': 'מחיר (אופקי)',
    'property.GridPriceHighLight': 'צבע רשת מודגש',
    'property.GridTime': 'זמן (אנכי)',
    'property.GTC': 'GTC',
    'property.GTD': 'GTD',
    'property.GTS': 'GTS',
    'property.HeaderFont': 'גופן',
    'property.HeaderText': 'טקסט כותרת',
    'property.High': 'גבוה',
    'property.HighlightMarkingsStep': 'צבע רשת מודגש',
    'property.HistogramLineColor': 'קו היסטוגרמה ',
    'property.HistoryType': 'סוג מידע',
    'property.HorizontalLine.LeftRay': 'הרחב לשמאל',
    'property.hostName': 'שם מארח',
    'property.hotkeyManager.ShowVolume': 'הצג נפח',
    'property.infoWindow_fore_back_color': 'צבע',
    'property.Instrument Types': 'ברירת מחדל של הוראה לפי סוג',
    'property.Intrinsic': 'Intrinsic',
    'property.IOC': 'IOC',
    'property.IsBackground': 'רקע',
    'property.IsBarsVisible': '#Bars',
    'property.IsChangeVisible': '% ,שינוי ',
    'property.IsCloseVisible': 'סגירה',
    'property.IsDateVisible': 'תאריך',
    'property.IsHighVisible': 'גבוה',
    'property.IsHighLowVisible': 'גבוה - נמוך',
    'property.IsLowVisible': 'נמוך',
    'property.IsOpenVisible': 'פתיחה',
    'property.IsRoundedAvgPrice': 'מחיר פתיחה מעוגל ממוצע',
    'property.isShowToolTips': 'הסברי כלים',
    'property.IsSyncActive': 'סנכרן עם תרשימים',
    'property.IsTicksVisible': 'טיקים',
    'property.IsTimeVisible': 'זמן',
    'property.IsVolumeVisible': 'נפח',
    'property.LabelColors': 'צבעי תווית',
    'property.Language': 'שפה',
    'property.Last': 'אחרון',
    'property.lastPrice_AskIndicatorColor': 'היצע',
    'property.lastPrice_BidIndicatorColor': 'הצעת מחיר',
    'property.lastPrice_LastIndicatorColor': 'אחרון',
    'property.lastPrice_SpreadIndicatorColor': 'צבע באיזור מרווח',
    'property.Left ray': 'הרחב לשמאל',
    'property.Level': 'רמה',
    'property.Limit': 'הגבל',
    'property.line.lineName': 'שם הקו',
    'property.line.plateVisible': '{0} הצג סמן קו',
    'property.line.timeShift': 'שינוי הזמן',
    'property.line.value': 'ערך',
    'property.line.Visible': 'גלוי',
    'property.line.yFactor': 'Y גורם ',
    'property.Line': 'קו',
    'property.Linear Weighted': 'ליניארי משוקלל',
    'property.lineProperties': 'מאפיינים',
    'property.logBase': 'יומן בסיס',
    'property.LotsMode': 'הצג את הגודל ב',
    'property.Low': 'נמוך',
    'property.Mail Server': 'שרת דואר',
    'property.Manual': 'מדריך ל',
    'property.Market': 'שוק',
    'property.MarketCap': '.שווי שוק',
    'property.MarketDepth.ColoringMethod': 'שיטת צביעה',
    'property.MarketProfile.ColorScheme.Color': 'צבע',
    'property.MarketProfile.ColorScheme.GradientColor': 'פינה',
    'property.MarketProfile.ColorScheme.PairColor.Color': 'צבע',
    'property.MarketProfile.ColorScheme.VolumeColor': 'צבע',
    'property.MarketProfile.ColorScheme': 'סכמת צבעים',
    'property.MarketProfile.Font': 'גופן',
    'property.MarketProfile.PointOfControl': 'נקודת שליטה',
    'property.MarketProfile.PointOfControlStyle': 'נקודת סגנון שליטה',
    'property.MarketProfile.ShowSingles': 'הצג סינגלים',
    'property.MarketProfile.SinglesStyle': 'סגנון יחידים',
    'property.MarketProfile.ValueArea': 'אזור ערך',
    'property.MarketProfile.ValueAreaStyleHorisontal': 'סגנון קו אופקי',
    'property.MarketProfile.ValueAreaStyleVertical': 'סגנון קו אנכי',
    'property.MarketProfile.ValuePercents': '% ,ערך ',
    'property.Medium': 'בינוני',
    'property.message': 'הודעה',
    'property.method': 'שיטה',
    'property.MirrorShow': 'ראיית מראה',
    'property.Mode.Trades': 'עסקאות',
    'property.Mode': 'מצב',
    'property.Modified': 'שונה',
    'property.MonthSeparatorsVisibility': 'מפרידי חודש',
    'property.name': 'שם',
    'property.Name': 'שם',
    'property.News': 'חדשות',
    'property.None': 'אף אחד',
    'property.Number': 'ספירה',
    'property.OCO': 'OCO',
    'property.oe.confirmClosePosition': 'סגירת עמדת אישור',
    'property.oe.confirmOrderCancel': 'אישור ביטול הוראה',
    'property.oe.confirmOrderChange': 'אישור שינוי הוראה / פוזיציות',
    'property.oe.confirmOrderCreate': 'אישור הכנסת הוראה',
    'property.oe.confirmReversePosition': 'עמדת אישור היפוך',
    'property.OE_AddBidAddAsk': 'לקנות הצעה / מכירה תשאל',
    'property.OE_ShowBuyMarketSellMarket': 'לקנות במחיר שוק / מכירה במחיר שוק',
    'property.OE_ShowSellBidBuyASk': 'מכור הצעה / לקנות שאל',
    'property.OE_ShowSLTP': '(SL / TP) הוראת סגירה ',
    'property.OffsetMode.Points': 'נקודות',
    'property.OffsetMode.Ticks': 'טיקים',
    'property.OffsetMode.TicksFractionalForForex': 'מדרגות (חלקי מדרגות עבור מט"ח)',
    'property.OffsetMode': 'הצג קיזוז ב',
    'property.OnClose': 'Close על ',
    'property.OnOpen': 'Open על ',
    'property.OnTop': 'בנוסף',
    'property.Open': 'פתיחה',
    'property.Order Entry': 'הכנסת הוראה',
    'property.OrderBuyStyleWidth': 'קנייה',
    'property.OrderSellStyleWidth': 'מכירה',
    'property.OverlayVisible': 'שכבה נראית לעין',
    'property.panel.accountDetails.Groups.1.General': 'כללי',
    'property.panel.accountDetails.Groups.2.Margin': 'ביטחונות',
    'property.panel.accountDetails.Groups.3.AccountActivity': 'פעילות חשבון',
    'property.panel.accountDetails.Groups.4.TodayResults': 'תוצאות היום',
    'property.panel.accountDetails.Groups.5.RiskManagement': 'ניהול סיכונים',
    'property.panel.accountDetails.Groups.6.Info': 'מידע',
    'property.Panels': 'פאנלים',
    'property.Parameters': 'פרמטרים',
    'property.password': 'סיסמה',
    'property.Period of indicator:': 'תקופת המחוון',
    'property.Period of indicator': 'תקופת המחוון',
    'property.Period of Linear Regression': 'תקופת רגרסיה ליניארית',
    'property.Period of MA for envelopes': 'תקופת MA עבור מעטפות',
    'property.Period of MA1': 'MA1 תקופת ',
    'property.Period of MA2': 'MA2 תקופת ',
    'property.Period of momentum:': 'תקופת המומנטום',
    'property.Period of Moving Average': 'תקופת ממוצע נע',
    'property.Period of price channel': 'תקופת ערוץ מחיר',
    'property.Period of Simple Moving Average': 'תקופת ממוצע נע פשוט',
    'property.Period of Smoothed Moving Average': 'Smoothed Moving Average תקופה',
    'property.period': 'פרק זמן',
    'property.Periods': 'תקופות',
    'property.pin': 'PIN',
    'property.POCTypes.Line': 'קו',
    'property.POCTypes.Marker': 'מדגש',
    'property.POCTypes.None': 'אף אחד',
    'property.Points': 'נקודות',
    'property.PositionBuyStyleWidth': 'Long',
    'property.PositionSellStyleWidth': 'Short',
    'property.Price Scale': 'מחיר בקנה מידה',
    'property.price': 'מחיר',
    'property.PriceIndicatorDrawingType.None': 'אף אחד',
    'property.PriceIndicatorDrawingType.ScaleMarker': 'סמן קנה מידה',
    'property.PriceIndicatorDrawingType.ScaleMarkerWithLine': 'סמן קנה מידה עם קו',
    'property.PriceIndicatorDrawingType': 'מחוון מחיר',
    'property.PriceScaleAxisColor': 'ציר',
    'property.PriceScaleBackColor': 'רקע ציר',
    'property.PriceScaleTextColor': 'צבע טקסט',
    'property.PriceType': 'סוג המחיר',
    'property.ProfileColoringTypes.DeltaBars': 'ברי דלתא',
    'property.ProfileColoringTypes.DeltaPrice': 'מחיר דלתא',
    'property.ProfileColoringTypes.DeltaProfile': 'פרופיל דלתא',
    'property.ProfileColoringTypes.Single': 'צבע יחיד',
    'property.ProfileColoringTypes.UpdownBars': 'ברים מעלה / מטה',
    'property.ProfileColoringTypes.UpdownProfile': 'פרופיל מעלה / מטה',
    'property.ProfileColoringTypes.VolumeBars': 'ברי נפח',
    'property.ProfileColoringTypes.VolumePrice': 'מחיר נפח',
    'property.Range': 'טווח',
    'property.Ray style': 'סגנון ריי',
    'property.Relative': 'יחסי',
    'property.relativeCalcType': 'מחיר ההתחלה',
    'property.RelativeCalculationType.BeginOfTheData': 'התחלת הנתונים',
    'property.RelativeCalculationType.BeginOfTheDay': 'התחלת היום',
    'property.RelativeCalculationType.BeginOfTheScreen': 'התחלת המסך',
    'property.relativeScaleType': 'סוג קנה מידה',
    'property.Reverse': 'הפוך',
    'property.Right ray': 'הרחב לימין',
    'property.routing.account': 'חשבון',
    'property.routing.orderType': 'סוג הוראה',
    'property.RSI Method:': 'RSI שיטת ',
    'property.RSI Period:': 'RSI תקופה',
    'property.secure': 'לבטח',
    'property.Secure': 'לבטח',
    'property.Selection': 'בחירה',
    'property.Sell': 'מכירה',
    'property.SellColor': 'מכירה',
    'property.SeparatorGroup.ActiveOrders': 'הוראות ממתינות למימוש',
    'property.SeparatorGroup.additional': 'נוסף',
    'property.SeparatorGroup.Additional': 'נוסף',
    'property.SeparatorGroup.Appearance': 'מראה חיצוני',
    'property.SeparatorGroup.CrossHair': 'crosshair',
    'property.SeparatorGroup.ExecutedOrders': 'הוראות ממומשות',
    'property.SeparatorGroup.Grid': 'רשת תרשימים',
    'property.SeparatorGroup.HighlightDate': 'תאריך דגש',
    'property.SeparatorGroup.HighlightPrices': 'הדגש מחירי ',
    'property.SeparatorGroup.OpenPositions': 'פוזיציות פתוחות',
    'property.SeparatorGroup.ScaleMarkings': 'סימוני קנה מידה',
    'property.SeparatorGroup.ScaleType': 'סוג קנה מידה',
    'property.SeparatorGroup.SeparatorOEWidth': 'הצג',
    'property.SeparatorGroup.Show': 'הצג',
    'property.SeparatorGroup.TimeToNextBar': 'זמן לבר הבא',
    'property.SeparatorGroup.Watermark': 'סימן מים',
    'property.SeparatorGroup.WindowColors': 'צבעי רקע',
    'property.SeparatorGroup.Zoom': 'תקריב',
    'property.SeparatorViewButtons': 'כפתורים חמים',
    'property.Sesonality': 'עונתיות',
    'property.ShowAlerts': 'הצג התראות',
    'property.showAllSessions': 'הצג אירוע מורחב',
    'property.ShowEmptyBars': 'חורים בהיסטוריה',
    'property.showEvents': 'גלוי',
    'property.ShowFullArc': 'הצג קשת מלא',
    'property.ShowHighLow': 'גבוה / נמוך יומי',
    'property.showOrders': 'גלוי',
    'property.showPositions': 'גלוי',
    'property.ShowPrevCloseLine': 'סגירה קודמת',
    'property.ShowTimeToNextBar': 'גלוי',
    'property.ShowTotal': 'הצג סך',
    'property.ShowVisualTradingOnLeftSide': 'Bound פוזיציות / הוראות פעילות לצד השמאל',
    'property.showVolume': 'גלוי',
    'property.showVolumeMarker': 'הצג סמן ערך על הרשת',
    'property.Simple': 'פשוט',
    'property.sltpOffsetMode': 'הגדר את ערכי SL/TP בקיזוז',
    'property.Smoothing': 'מחליק',
    'property.solidPriceColor': 'פינה',
    'property.Sources prices for MA': 'MA מקורות מחירי ',
    'property.SpreadType.Area': 'פינה',
    'property.SpreadType.Line': 'קו',
    'property.SpreadType.LineWithLabel': 'קו עם המחיר',
    'property.SpreadType.None': 'אף אחד',
    'property.SpreadType': 'סוג מרווח',
    'property.Step of parabolic SAR system': 'SAR-שלב פרבולי של מערכת ה',
    'property.Stick to': 'לדבוק ב',
    'property.Stocks': 'מניות ',
    'property.Stop Limit': 'Stop Limit',
    'property.Stop': 'Stop',
    'property.Stop-loss': 'Stop Loss',
    'property.style': ':סגנון',
    'property.Sum': 'סכום',
    'property.Symbol': 'סימול',
    'property.Symbols': 'סימולים',
    'property.T': 'T',
    'property.Take-profit': 'Take Profit',
    'property.text': 'טקסט',
    'property.TIF_Limit_And_Stop_Limit': 'לימיט TIF (סטופ לימיט TIF)',
    'property.TIF_Stop': 'TIF עצור ',
    'property.TIF': 'השוק TIF',
    'property.Time Scale': 'זמן בקנה מידה',
    'property.TimeLine': 'ציר זמן',
    'property.TimelinePreview': 'תצוגה מקדימה של תרשים בר גלילה',
    'property.TimeScaleAxisColor': 'ציר',
    'property.TimeScaleBackColor': 'רקע ציר',
    'property.TimeScaleTextColor': 'צבע גופן',
    'property.TimeToNextBarColor': 'צבע',
    'property.trackShowCrossHair': 'גלוי',
    'property.Trading Defaults': 'מסחר',
    'property.Trading': 'מסחר',
    'property.Type of moving average': 'סוג ממוצע נע',
    'property.Type of Moving Average': 'סוג ממוצע נע',
    'property.Typical': 'אופייני',
    'property.Upband deviation in %': 'סטיית Upband ב%',
    'property.Update': 'עדכון',
    'property.UseCustomYMarkings': 'רשת מותאמת אישית',
    'property.user': 'התחברות',
    'property.useSSL': 'לבטח',
    'property.Value of confidence interval': 'ערך של מרווח ביטחון',
    'property.ValueColors': 'צבעי ערך',
    'property.VATypes.Horizontal': 'אופקי',
    'property.VATypes.None': 'אף אחד',
    'property.VATypes.ToNextProfile': 'לפרופיל הבא',
    'property.VATypes.Vertical': 'אנכי',
    'property.vendorName': 'ספק',
    'property.View': 'הצג',
    'property.ViewSeparatorGroup': 'הצג',
    'property.Visibility': 'נראות',
    'property.VisibilitySeparatorGroup': 'נראות',
    'property.Visible': 'גלוי',
    'property.Visual Trading': 'מסחר חזותי',
    'property.Volume.FontColor': 'צבע גופן',
    'property.Volume.UseTicksData': 'השתמש בנפח מדרגה אמיתי',
    'property.Volume': 'ברי נפח',
    'property.VolumeColoringMode': 'גודל צביעת תוכנית',
    'property.VolumeDownColor': 'שלילי',
    'property.VolumeFixedColor': 'שווה',
    'property.VolumeHeightPercent': '% ,גובה',
    'property.VolumeMode.BuyVolume': 'נפח קניות',
    'property.VolumeMode.Delta': 'דלתא',
    'property.VolumeMode.MaxTickVolume': 'מקסימום מדרגת נפח',
    'property.VolumeMode.SellVolume': 'נפח מכירה',
    'property.VolumeMode.TotalVolume': 'נפח כולל',
    'property.VolumeMode.Trades': 'עסקאות',
    'property.VolumeOpacityPercent': 'שקיפות',
    'property.VolumeProfile': 'פרופיל נפח',
    'property.VolumeUpColor': 'חיובי',
    'property.VolumeBars.MovingAverage.Active': 'הצג ממוצע תזוזה',
    'property.VolumeBars.MovingAverage.Line': 'צבע קו',
    'property.VolumeBars.MovingAverage.Period': 'MA תקופת ',
    'property.VWAP': 'VWAP+',
    'property.Warnings': 'אזהרות',
    'property.WatermarkMode': 'מצב סימן מים',
    'property.WatermarkColor': 'צבע סימן מים',
    'property.WeekSeparatorsVisibility': 'מפרידי שבוע',
    'property.Weighted': 'משוקלל',
    'property.wickColor': 'פְּתִילָה',
    'property.width': ':רוחב',
    'property.Win num.addWin': 'חלון משני',
    'property.Win num.main': 'ראשי',
    'property.Win num': 'מספר חלון שימוש עבור המחוון',
    'property.Y factor': 'Y גורם ',
    'property.YearSeparatorsVisibility': 'מפרידי שנה',
    'property.ZeroLine': 'קו האפס',
    'propery.MainPriceRenderer.Down': 'מטה',
    'propery.MainPriceRenderer.Negative': 'שלילי',
    'propery.MainPriceRenderer.Positive': 'חיובי',
    'propery.MainPriceRenderer.Up': 'למעלה',
    QStick: 'QStick',
    'Rate of Change': 'שער השינוי',
    'Relative Strength Index': 'מדד העוצמה היחסית',
    Reserve: 'הפדרל',
    'ribbon.enviroment.help': 'עזרה',
    'ribbon.enviroment.logout': 'התנתק',
    'ribbon.enviroment.NotificationWindow.lockTrading': 'המסחר ננעל',
    'ribbon.enviroment.NotificationWindow.UnlockTrading': 'בוטלה נעילת מסחר',
    'ribbon.enviroment.Save': 'שמור סביבת עבודה',
    'ribbon.enviroment.settings': 'הגדרות',
    'ribbon.enviroment': 'סביבה',
    'ribbon.help.about': 'על אודות',
    'ribbon.help.help': 'יותר',
    'ribbon.help.home': 'דף הבית',
    'ribbon.help.onboarding': 'הדרכת משתמש',
    'ribbon.linking.linkBlue': 'כחול',
    'ribbon.linking.linkEmpty': 'אף אחד',
    'ribbon.linking.linkGreen': 'ירוק',
    'ribbon.linking.linkOrange': 'כתום',
    'ribbon.linking.linkPurple': 'סגול',
    'ribbon.linking.linkRed': 'אדום',
    'ribbon.linking.linkYellow': 'צהוב',
    'ribbon.tools.full_logs': 'הודעות שרת רישום מלא',
    'ribbon.tools.grid': 'רשת תרשימים',
    'ribbon.tools.reports': 'דיווחים',
    'ribbon.tools.withdrawal': 'משיכה',
    'ribbon.tools': 'כלים',
    'ribbon.tradingTerminal': 'מסוף',
    'RiskManagement.Messages.DailyLimit': 'הגבלת הפסד יומית הושגה',
    'RiskManagement.Messages.MaxDailyProfit': 'רווח יומי הושג',
    'RiskManagement.Messages.MaxRelativeDrawDownLevel': 'יחס רמת גריעה מקסימלי הושג',
    'RiskManagement.Messages.MaxTrailingLimit': 'נגרר הפסד מקסימלי הושג',
    'RiskManagement.Messages.MaxUnrealizedLossLimit': 'מגבלת הפסד לא ממומש הושגה',
    'RiskManagement.Messages.WeeklyLimit': 'הגבלת הפסד שבועי הושג',
    'screen.about.copyright': '© 2017-2024 TraderEvolution Global LTD.',
    'screen.about.dateBuild': 'תאריך בנייה:‏',
    'screen.about.title': 'על אודות',
    'screen.additionalVerification.comment': 'חיבור ({1}) נדרש אימות נוספת:',
    'screen.additionalVerification.Need activation.comment': 'נא להזין את קוד ההפעלה (נשלח לדואר שלך)',
    'screen.additionalVerification.Need activation.watermark': '<E-mail -זן קוד הפעלת מ>',
    'screen.additionalVerification.Need activation': 'נצרכת הפעלה',
    'screen.additionalVerification.title.Email': 'אימות נוספת (דואל לעבור חד פעמי)',
    'screen.additionalVerification.title.Fixed': 'אימות נוספת (מעבר קבוע)',
    'screen.additionalVerification.title.Sms': 'אימות נוספת (מעבר חד פעמי SMS)',
    'screen.additionalVerification.title': 'אימות נוספת',
    'screen.Alerts.Action.Edit': 'עריכה',
    'screen.Alerts.Action.Notify': 'תודיע',
    'screen.Alerts.Action.Start': 'התחל',
    'screen.Alerts.Action.PlaceOrder': 'הצב הוראה',
    'screen.Alerts.AddAlert.Tooltip': 'לחץ כדי ליצור התראה חדשה',
    'screen.Alerts.AddAlert': 'צור חדש',
    'screen.Alerts.AfterExecute.Remove': 'הסר',
    'screen.Alerts.AfterExecute.Stop': 'Stop',
    'screen.Alerts.Alert': 'התראה',
    'screen.Alerts.Button.Apply.Tooltip': 'לחץ כדי להחיל את השינויים',
    'screen.Alerts.Button.Create': 'צור',
    'screen.Alerts.Condition.Less': 'פחות',
    'screen.Alerts.Header.Create': 'צור התראה',
    'screen.Alerts.Header.Edit': 'עריכה',
    'screen.Alerts.Importance.High': 'גבוה',
    'screen.Alerts.Importance.Low': 'נמוך',
    'screen.Alerts.Importance.Medium': 'בינוני',
    'screen.Alerts.Label.Action': 'פעולה',
    'screen.Alerts.Label.AfterExecute.Tooltip': 'בחר התנהגות התראה לאחר ביצוע',
    'screen.Alerts.Label.AfterExecute': 'לאחר ביצוע',
    'screen.Alerts.Label.AlertType.Tooltip': 'הגדר סוג התראה',
    'screen.Alerts.Label.AlertType': 'סוג התראה',
    'screen.Alerts.Label.Condition.Tooltip': 'הגדר מצב כוננות',
    'screen.Alerts.Label.Condition': 'מצב',
    'screen.Alerts.Label.Expiration': 'תפוגה',
    'screen.Alerts.Label.Expiration.Tooltip': 'תאריך ושעת תפוגה של ההתראה',
    'screen.Alerts.Label.Importance': 'חשיבות',
    'screen.Alerts.Label.Symbol': 'סימול',
    'screen.Alerts.Label.Value': 'ערך',
    'screen.Alerts.Message': 'הודעת התראה',
    'screen.Alerts.RemoveAlertMessage': '?ההתראה יוסרה. האם אתה בטוח',
    'screen.Alerts.TableItem.Condition': 'מצב',
    'screen.Alerts': 'התראות',
    'screen.changepass.cancel': 'בטל',
    'screen.changepass.changed': 'סיסמה שונתה בהצלחה. נא להתחבר',
    'screen.changepass.confirm': 'אשר סיסמא חדשה',
    'screen.changepass.lockedAfterBrut': '!משתמש ננעל לאחר פעולה כפויה',
    'screen.changepass.wrongOldOrNewPassword': 'סיסמא נוכחית שגויה',
    'screen.changepass.wrongKey': 'מפתח שחזור שגוי',
    'screen.changepass.pwdMustBeDifferent': 'סיסמא חדשה שגויה: אתה לא יכול להשתמש באותה סיסמא',
    'screen.changepass.errorEmail': '.כתובת דוא"ל לא חוקית לשחזור הסיסמה שלכם',
    'screen.changepass.wrongLogin': '!כניסה שגויה',
    'screen.changepass.new': 'סיסמה חדשה',
    'screen.changepass.ok': 'בסדר',
    'screen.changepass.old': 'סיסמה נוכחית',
    'screen.changepass.title': 'שנה סיסמא',
    'screen.changepass.watermark_confirm': '<אשר סיסמא>',
    'screen.changepass.watermark_new': '<חדש סיסמה>',
    'screen.changepass.watermark_old': '<סיסמא ישנה>',
    'screen.changepass.wrongVerification': 'אימות שגוי',
    'screen.closePosition.cancel': 'בטל',
    'screen.closePosition.ok': 'סגירה',
    'screen.closePosition.title': 'פוזיציה סגורה ',
    'screen.error.title': 'שגיאה',
    'screen.licensing.emptyFieldErrors.password': 'שדה הסיסמה ריק',
    'screen.login.change_pass': 'הסיסמה אופסה או פג תוקפה והיא צריכה להשתנות',
    'screen.LoginScreen.Connecting': '...מתחבר ',
    'screen.LoginScreen.Error.loginIdIsEmpty': 'שדה מזהה הכניסה ריק',
    'screen.LoginScreen.Error.passwordIdIsEmpty': 'שדה הסיסמה ריק',
    'screen.LoginScreen.ForgotLinkLabel.ToolTip': 'שחזור סיסמה',
    'screen.LoginScreen.ForgotLinkLabel': 'שכחת את הסיסמא',
    'screen.LoginScreen.LoginButtonText': 'התחברות',
    'screen.LoginScreen.LoginButtonTooltip': 'התחברות לפלטפורמה',
    'screen.LoginScreen.LoginIDLabel': 'התחברות',
    'screen.LoginScreen.LoginIDTextBox.ToolTip': 'כניסת משתמש',
    'screen.LoginScreen.PasswordLabel': 'סיסמה',
    'screen.LoginScreen.PasswordTextBox.ToolTip': 'סיסמת משתמש',
    'screen.LoginScreen.RegistrationLinkLabel': 'רישום',
    'screen.LoginScreen.responceErrors.bruteforceBlockedMinutes': '.משתמש {1} חסום, אנא נסה שוב בעוד כ {2} דקות או פנה לצוות התמיכה שלך',
    'screen.LoginScreen.SavePassCheckBox.ToolTip': 'זכרו אישורים',
    'screen.LoginScreen.SavePassCheckBox': 'שמור סיסמה',
    'screen.LoginScreen.CustomerNo': 'מספר לקוח',
    'screen.lookup.scripts.noitems': 'אין פריטים תואמים',
    'screen.lookup.scripts.showing': 'מציג {1} פריטים',
    'screen.lookup.scripts.watermark': '<חיפוש>',
    'screen.modifyOrder.amount': ':כמות',
    'screen.modifyOrder.bind_to': ':מחויב ל',
    'screen.modifyOrder.created': ':תאריך שעה',
    'screen.modifyOrder.instrument': ':סימול',
    'screen.modifyOrder.market_price': ':מחיר נוכחי',
    'screen.modifyOrder.modify': 'שנה',
    'screen.modifyOrder.modifyPosition': 'שנה פוזיציה',
    'screen.modifyOrder.modifyPosition.ModifyQuantity': 'שנה כמות',
    'screen.modifyOrder.modifyPosition.ModifyQuantity.SlTpInfo': 'שים לב: הוראות SL/TP יבוטלו לאחר שינוי סוג המוצר',
    'screen.modifyOrder.modifyPosition.TargetProductType': 'סוג מוצר יעד',
    'screen.modifyOrder.number': ':מזהה פוזיצייה',
    'screen.modifyOrder.operation': ':צד',
    'screen.modifyOrder.orderNumber': ':מזהה הוראה',
    'screen.modifyOrder.ordertype': ':סוג הוראה',
    'screen.modifyOrder.price': ':מחיר פתיחה',
    'screen.modifyOrder.route': ':נתיב',
    'screen.MonthCalendarPopupForm.Apply': 'להגיש מופוזיציות',
    'screen.PanelSettingsScreen.applyButton': 'להגיש מופוזיציות',
    'screen.properties.cancel': 'סגירה',
    'screen.properties.common': 'מאפיינים',
    'screen.properties.ok': 'בסדר',
    'screen.properties.title': 'הגדרות כלליות',
    'screen.properties.unsavedChanges': '?יש לך שינויים שלא נשמרו. לצאת ולאבד את השינויים שלך',
    'screen.recconection.reconnecting': '...מתחבר מחדש ',
    'screen.recconection.seconds': '...שניות',
    'screen.recconection.waiting': 'ממתין',
    'screen.RecoveryPassForm.Confirm': 'אישור',
    'screen.RecoveryPassForm.Email': ':אימייל',
    'screen.RecoveryPassForm.HaveCode': 'יש לי מפתח',
    'screen.RecoveryPassForm.invalid_email': 'אימייל לא חוקי {1} עבור משתמש זה',
    'screen.RecoveryPassForm.invalid_login_or_email': 'התחברות או אימייל לא חוקי כדי לשחזר את הסיסמה שלך',
    'screen.RecoveryPassForm.invalid_login': 'התחברות לא חוקית כדי לשחזר את הסיסמה שלך',
    'screen.RecoveryPassForm.Key': 'מפתח',
    'screen.RecoveryPassForm.Login': ':התחברות',
    'screen.RecoveryPassForm.New password': 'סיסמה חדשה',
    'screen.RecoveryPassForm.Send': 'שחזר',
    'screen.RecoveryPassForm.Title': 'שחזור סיסמה',
    'screen.RecoveryPassForm.Your request for password recovering has sent successfully. Please check your email!': '!פנייתך לשחזור סיסמה נשלחה בהצלחה. אנא בדוק את האימייל שלך',
    'screen.registrationWizard.confirmPassword.watermark': '<אשרו את הסיסמה>',
    'screen.registrationWizard.confirmPassword': ':אשר סיסמה*',
    'screen.registrationWizard.email.watermark': '<דוא"ל>',
    'screen.registrationWizard.email': ':אימייל*',
    'screen.registrationWizard.firstName.watermark': '<דק 2 תווים>',
    'screen.registrationWizard.firstName': ':שם פרטי*',
    'screen.registrationWizard.lastName.watermark': '<דק 2 תווים>',
    'screen.registrationWizard.lastName': ':שם משפחה*',
    'screen.registrationWizard.login.watermark': '<השאר ריק הדור אוטומטי>',
    'screen.registrationWizard.login': ':התחברות',
    'screen.registrationWizard.password.watermark': '<הכנס את הסיסמה שלך>',
    'screen.registrationWizard.phone': ':מספר טלפון',
    'screen.registrationWizard.phoneNumber.watermark': '<(קוד מדינה) מספר טלפון>',
    'screen.registrationWizard.registrationStatus': 'מצב הרשמה',
    'screen.registrationWizard.successMessage': 'הרשמה מוצלחת \ nLogin:! {1} \ n סיסמא: {2}',
    'screen.renameScreen.cancel': 'בטל',
    'screen.renameScreen.illegalName': '!שם לא חוקי',
    'screen.renameScreen.ok': 'בסדר',
    'screen.ReportMessageTooltip.CreatedAt': 'נוצר ב',
    'screen.ReportMessageTooltip.FilledAt': 'בוצע ב',
    'screen.ReportMessageTooltip.MarginCall': 'שיחת ביטחונות',
    'screen.ReportMessageTooltip.Market': 'שוק',
    'screen.ReportMessageTooltip.ModifiedAt': 'שונה בתאריך',
    'screen.ReportMessageTooltip.RemovedAt': 'הוסר ב',
    'screen.reports.reportHeaders.date': ':תאריך',
    'screen.reports.reportHeaders.owner': ':בעלים',
    'screen.reports.reports.Canceled': 'פעולה בוטלה',
    'screen.reports.reports.ExpToCSVErr': '!CSV - ארעה שגיאה בזמן ייצוא ל',
    'screen.reports.reports.ExpToExcelErr': '!Excel - ארעה שגיאה בזמן ייצוא ל',
    'screen.reports.reports.ExpToHTMLErr': '!HTML ארעה שגיאה בזמן ייצוא מסמך ',
    'screen.reports.reports.noData': '.מצטערים, אין נתונים זמינים עבור בקשה זו. נסה להשתמש בפרמטרים אחרים של דו"ח',
    'screen.TimeZoneSelector.title': 'בחר אזורי זמן',
    'screen.UnlockTradingScreen.lockedLabel': 'המסחר ננעל',
    'screen.UnlockTradingScreen.unlockButton': 'ביטול נעילה',
    'screen.withdrawal.account': 'מתוך חשבון',
    'screen.withdrawal.Asset': 'נכס',
    'screen.withdrawal.avaliableNumeric.ToolTip': 'הגדר יתרת הון',
    'screen.withdrawal.Cancel': 'בטל',
    'screen.withdrawal.error.not_allowed': 'חשבון מבצע עם סוג משיכה אינה מותרת עבור המשתמש שלך, אנא פנה הברוקר שלך',
    'screen.withdrawal.error.not_enough_balance': 'מאזן לא מספיק',
    'screen.withdrawal.error.not_enough_blocked': 'כמות לא חוקית (לא ניתן להסיר את החסימה מעל חסום)',
    'screen.withdrawal.error.not_enough_margin': 'לא מספיק ביטחונות',
    'screen.withdrawal.error.UnableToBlock': '.שגיאה! אין אפשרות לחסום ',
    'screen.withdrawal.error.WrongSum': '.שגיאה: סכום שגוי',
    'screen.withdrawal.errorLabel': 'לא מספיק כסף עבורה משיכה',
    'screen.withdrawal.Fee': ':דמי משיכה',
    'screen.withdrawal.from': 'מ',
    'screen.withdrawal.Remains': 'שאריות',
    'screen.withdrawal.RequiredAmount': ':הסכום הנדרש',
    'screen.withdrawal.ReservedWithdrawal': 'משיכה',
    'screen.withdrawal.usersLookup.ToolTip': 'בחר חשבון',
    'screen.withdrawal.Withdraw': 'ביטול',
    'screen.withdrawal.Withdrawal': 'משיכה',
    'screen.withdrawal.withdrawalNumeric.ToolTip': 'הגדר משיכת הון',
    'Simple Moving Average': 'ממוצע נע פשוט',
    SixMonth: 'חודשים ‎6',
    'Smoothed Moving Average': 'ממוצע נע מוחלק',
    'Standard Deviation': 'סטיית תקן',
    'statusBar.passwordpanel.DetalTicket.Text': 'גישה למלון לפעילות מסחר',
    'statusBar.passwordPanel.passwordBox.invalidPass': '.סיסמת המסחר אינה תקפה',
    'Stochastic Slow': 'הסתברות איטית',
    Stochastic: 'הסתברותי',
    'Stop Limit': 'Stop Limit',
    'Stop-loss': 'Stop Loss',
    Suomi: '#hidden#',
    'Swing Index': 'מדד סווינג',
    'Take-profit': 'Take Profit',
    'textBoxWithValidating.ErrorText.NameContainsInvalidChar': 'שם מכיל תווים לא חוקיים',
    'textBoxWithValidating.ErrorText.NameExist': 'שם זה כבר קיים',
    ThreeMonth: 'חודשים ‎3',
    'TradeDefaultManager.Set defaults': 'גדר ברירות מחדל',
    'TradeDefaultManager.Types manager': 'מנהל סוגים',
    'TradeTool.Amount of order': 'כמות ההוראה',
    'TradeTool.Cancel order': 'בטל הוראה',
    'TradeTool.Cancel SL': 'SL בטל ',
    'TradeTool.Cancel TP': 'TP בטל ',
    'TradeTool.ChangeToMarket': 'שנה את סוג ההוראה לשוק',
    'TradeTool.Close position': 'פוזיציה סגורה ',
    'TradeTool.Drag to set SL order': 'גרור כדי SL סט',
    'TradeTool.Drag to set TP order': 'TP גרור כדי להגדיר ',
    'TradeTool.Order': 'הוראה',
    'TradeTool.Position P/L': 'רווח והפסד כולל',
    'TradeTool.Position': 'פוזיציה',
    'TradeTool.Tick and P/L': 'רווח/הפסד ומדרגות',
    'TradeTool.Trailing Stop': 'סטופ נגרר',
    'True Strength Index': 'מדד עצמה אמיתי',
    TTO: 'TTO',
    'TypesManagerScreen.DefaultLotsSetup': 'הגדרת כמות ברירת מחדל במנות',
    'TypesManagerScreen.NumericLabel.Price increment (arrow), ticks': 'תוספת מחיר (חץ), מדרגות',
    'TypesManagerScreen.NumericLabel.SL default offset, ticks': 'ברירת מחדל של קיזוז SL, מדרגות',
    'TypesManagerScreen.NumericLabel.TP default offset, ticks': 'ברירת מחדל קיזוז TP, מדרגות',
    'TypesManagerScreen.NumericLabel.User quantity coefficient': 'מקדם כמות משתמש',
    'TypesManagerScreen.QuickTable.Column.Default lots': 'כמות ברירת מחדל במנות',
    'TypesManagerScreen.QuickTable.Column.Enable': 'אפשר',
    'TypesManagerScreen.Types Manager': 'מנהל סוגים',
    'User is blocked. Please contact support.': '.המשתמש חסום. אנא צור קשר עם התמיכה',
    'User is locked after brute force!': '!משתמש ננעל לאחר פעולה כפויה',
    'Volume Weighted Average Price': 'מחיר ממוצע משוקלל נפח',
    Volume: 'נפח',
    VWAP: 'VWAP',
    'workspace.At first you should unlock workspace to rename it': '.בהתחלה אתה צריך לפתוח סביבת עבודה כדי לשנות את זה',
    'workspace.close_wsp': 'סגירת סביבת עבודה ',
    'workspace.Close': 'סגירה',
    'workspace.DefaultNameSpace': 'WRK #',
    'workspace.errorSavingFile': '.ארעה שגיאה בזמן שמירת הקובץ',
    'workspace.information': 'מידע',
    'workspace.Lock.Message': 'מרחב עבודה נעול',
    'workspace.menu.Clone': 'שיבוט',
    'workspace.menu.Close': 'סגירה',
    'workspace.menu.CloseAllButThis': 'סגור את כל חוץ מזה',
    'workspace.menu.Lock': 'לנעול',
    'workspace.newWorkspaceName': 'הזן שם סביבת עבודה חדשה',
    'workspace.numberOfWorkspace': 'מספר סביבות עבודה פתוחות גדול מהמקסימום המותר',
    'workspace.Rename': 'שינוי שם',
    'workspace.Unlock.Message': 'מרחב עבודה לא נעולה',
    'screen.reports.reportHeaders.from': ':מ',
    'screen.reports.reportHeaders.to': ':ל',
    'screen.reports.reports.ExpingToCSV': '...CSV ייצוא נתונים למסמך  ',
    'screen.reports.reports.ExpingToExcel': '… Excel ייצוא נתונים למסמך ',
    'screen.reports.reports.ExpingToHTML': '… HTML ייצוא נתונים למסמך ',
    'panel.accounts.DayTraderPatternProtection': 'הגנת דפוס סוחר יומי',
    'panel.accounts.AvailableDayTrades': 'עסקאות יומיות זמינות'
};
