import { BaseItem, ColumnData } from '../BaseItem';
import { ColumnParams } from '../ColumnParams';
import { QuickTableColumnType, QuickTableEditingInfo } from '../../elements/QuickTable/QuickTableMisc';
import { QuickTableComparingType } from '../../../Utils/QuickTableMisc/QuickTableComparingType';
import { type Instrument } from '../../../Commons/cache/Instrument';
import { DataCache } from '../../../Commons/DataCache';
import { HistoryType } from '../../../Utils/History/HistoryType';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { QuotingType } from '../../../Utils/Instruments/QuotingType';
import { Resources } from '../../../Commons/properties/Resources';
import { DateTimeConvertor } from '../../../Utils/Time/DateTimeConvertor';
import { DateTimeUtils } from '../../../Utils/Time/DateTimeUtils';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { TradingUtils } from '../../../Utils/Trading/TradingUtils';
import { DynProperty } from '../../../Commons/DynProperty';
import { Quantity } from '../../../Utils/Trading/Quantity';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { GreeksFormatter } from '../../../Commons/cache/OptionMaster/OptionTrader/GreeksFormatter';
import { type OptionPutCall } from '../../../Utils/Instruments/OptionPutCall';
import { type PaperPosition } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionPaperPosition/PaperPosition';
import { CustomerAccess } from '../../../Commons/CustomerAccess/CustomerAccess';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { PanelDisplayMode } from '../../../Commons/CustomerAccess/PanelDisplayMode';
import { OptionTraderUtils } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTraderUtils';

export class OptionChainTableItem extends BaseItem {
    private readonly _putCall: OptionPutCall;
    private readonly _optionTrader: OptionTrader;

    public readonly instrument: Instrument;
    public isNeedFireUpdate: boolean = false;

    public QuickTableEditingInfoMap: any;

    constructor (putCall: OptionPutCall, instrument: Instrument, optionTrader: OptionTrader) {
        super();

        this._putCall = putCall;
        this.instrument = instrument;
        this._optionTrader = optionTrader;

        this.subscribe();
        if (!isNullOrUndefined(instrument)) {
            this.QuickTableEditingInfoMap[OptionChainTableItem.PAPER_POSITION_COL_INDEX] = new QuickTableEditingInfo(DynProperty.DOUBLE);
        }
        this.updateEditingInfo();
    }

    public Dispose (): void {
        this.unsubscribe();
        super.Dispose();
    }

    public updateEditingInfo (): void {
        const optionTrader = this._optionTrader;
        if (isNullOrUndefined(optionTrader)) {
            return;
        }
        const account = this._optionTrader.account;
        const instrument = this.instrument;
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const paperPosition: PaperPosition = this._optionTrader.getPaperPosition(instrument);
        const productType = !isNullOrUndefined(paperPosition) ? paperPosition.ProductType : TradingUtils.getAllowedProductTypesForTrading(account, instrument)[0];
        const settings = Quantity.getQuantityNumericSettings(instrument, account, GeneralSettings.View.displayAmountInLots(), productType);
        const amountNumeric = this.QuickTableEditingInfoMap[OptionChainTableItem.PAPER_POSITION_COL_INDEX];
        amountNumeric.Min = -settings.maxValue;
        amountNumeric.Max = settings.maxValue;
        amountNumeric.Inc = settings.step;
        amountNumeric.DecimalPlaces = settings.decimalPrecision;
        amountNumeric.IsEditable = OptionTraderUtils.getDaysToExpirationForInstrument(instrument) >= 0;
    }

    private subscribe (): void {
        const instrument = this.instrument;
        if (isNullOrUndefined(instrument)) {
            return;
        }
        DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_TRADES);
        DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_INSTRUMENT_DAY_BAR);
    }

    private unsubscribe (): void {
        const instrument = this.instrument;
        if (isNullOrUndefined(instrument)) {
            return;
        }
        DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_TRADES);
        DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_INSTRUMENT_DAY_BAR);
    }

    private newQuote (quote): void {
        this.isNeedFireUpdate = true;
    }

    public GetItemId (): number {
        return this.ItemId as number;
    }

    private getMonetaryValue (): number {
        const lastPrice = this.instrument.Level1.GetLastPrice(this._optionTrader.account);
        if (isNaN(lastPrice)) {
            return NaN;
        }
        const account = this._optionTrader.account;
        const instrument = this.instrument;
        const crossPrice = DataCache.CrossRateCache.GetCrossPriceExp1Exp2(instrument.Exp2, account.assetBalanceDefault.Asset.Name);
        const tickCost = instrument.QuotingType === QuotingType.LotSize ? instrument.LotSize : instrument.GetTickCost();
        return lastPrice * tickCost * crossPrice;
    }

    private formatPrice (price: number): string {
        if (!isValidNumber(price) || price === Infinity) {
            return OptionChainTableItem.NOT_AVAILABLE;
        } else {
            return this.instrument.formatPrice(price);
        }
    }

    public getColumnValue (column: number): any {
        const account = this._optionTrader.account;
        const instrument = this.instrument;
        const hasInstrument = !isNullOrUndefined(instrument);
        const showLots = GeneralSettings.View.DisplayQuantityInLots;
        switch (column) {
        case OptionChainTableItem.LAST_PRICE_COL_INDEX:
            return hasInstrument ? instrument.Level1.GetLastPrice(account) : NaN;
        case OptionChainTableItem.IV_COL_INDEX:
            return hasInstrument ? GreeksFormatter.roundIV(this._optionTrader.getGreeks(this._putCall, instrument).ivPercent) : NaN;
        case OptionChainTableItem.PAPER_POSITION_COL_INDEX:
        {
            if (!hasInstrument) {
                return 0;
            }
            const paperPositon = this._optionTrader.getPaperPosition(instrument);
            if (isNullOrUndefined(paperPositon)) {
                return 0;
            } else {
                const amount = Quantity.convertLotsToAmount(paperPositon.QuantityLots, paperPositon.Instrument, true, paperPositon.Account, paperPositon.ProductType);
                return paperPositon.Operation === OperationType.Buy ? amount : -amount;
            }
        }
        case OptionChainTableItem.GAMMA_COL_INDEX:
            return hasInstrument ? GreeksFormatter.roundGreek(this._optionTrader.getGreeks(this._putCall, instrument).gamma) : NaN;
        case OptionChainTableItem.ASK_COL_INDEX:
            return hasInstrument ? instrument.Level1.GetAsk(account) : NaN;
        case OptionChainTableItem.BID_COL_INDEX:
            return hasInstrument ? instrument.Level1.GetBid(account) : NaN;
        case OptionChainTableItem.ASK_SIZE_COL_INDEX:
            return hasInstrument ? instrument.Level1.getAskSize(showLots) : NaN;
        case OptionChainTableItem.BID_SIZE_COL_INDEX:
            return hasInstrument ? instrument.Level1.getBidSize(showLots) : NaN;
        case OptionChainTableItem.DELTA_COL_INDEX:
            return hasInstrument ? GreeksFormatter.roundGreek(this._optionTrader.getGreeks(this._putCall, instrument).delta) : NaN;
        case OptionChainTableItem.THETA_COL_INDEX:
            return hasInstrument ? GreeksFormatter.roundGreek(this._optionTrader.getGreeks(this._putCall, instrument).theta) : NaN;
        case OptionChainTableItem.VEGA_COL_INDEX:
            return hasInstrument ? GreeksFormatter.roundGreek(this._optionTrader.getGreeks(this._putCall, instrument).vega) : NaN;
        case OptionChainTableItem.VOLUME_COL_INDEX:
            return hasInstrument ? instrument.Level1.GetVolume() : NaN;
        case OptionChainTableItem.MONETARY_VALUE_COL_INDEX:
            return hasInstrument ? this.getMonetaryValue() : NaN;
        case OptionChainTableItem.RHO_COL_INDEX:
            return hasInstrument ? GreeksFormatter.roundGreek(this._optionTrader.getGreeks(this._putCall, instrument).rho) : NaN;
        case OptionChainTableItem.CHANGE_COL_INDEX:
            return hasInstrument ? instrument.Level1.GetChange(account) : NaN;
        case OptionChainTableItem.CHANGE_PERCENT_COL_INDEX:
            return hasInstrument ? instrument.Level1.GetChangePercent(account) : NaN;
        case OptionChainTableItem.OPEN_COL_INDEX:
            return hasInstrument ? instrument.Level1.GetOpen(account) : NaN;
        case OptionChainTableItem.HIGH_COL_INDEX:
            return hasInstrument ? instrument.Level1.GetHigh(account) : NaN;
        case OptionChainTableItem.LOW_COL_INDEX:
            return hasInstrument ? instrument.Level1.GetLow(account) : NaN;
        case OptionChainTableItem.PREV_CLOSE_COL_INDEX:
            return hasInstrument ? instrument.Level1.GetPrevClose(account) : NaN;
        case OptionChainTableItem.LAST_TIME_COL_INDEX:
            return hasInstrument ? DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(instrument.Level1.lastMessageTime) : new Date();
        case OptionChainTableItem.OPEN_INTEREST_COL_INDEX:
            return hasInstrument ? instrument.Level1.openInterest : NaN;
        }
        return '';
    }

    public getColumnData (column: number): ColumnData {
        const account = this._optionTrader.account;
        const instrument = this.instrument;
        const value: any = this.getColumnValue(column);
        const showLots = GeneralSettings.View.DisplayQuantityInLots;
        let formattedValue = '';
        if (isNullOrUndefined(instrument)) {
            return new ColumnData(value, formattedValue);
        }
        switch (column) {
        case OptionChainTableItem.LAST_PRICE_COL_INDEX:
        case OptionChainTableItem.ASK_COL_INDEX:
        case OptionChainTableItem.BID_COL_INDEX:
        case OptionChainTableItem.OPEN_COL_INDEX:
        case OptionChainTableItem.HIGH_COL_INDEX:
        case OptionChainTableItem.LOW_COL_INDEX:
        case OptionChainTableItem.PREV_CLOSE_COL_INDEX:
        case OptionChainTableItem.CHANGE_COL_INDEX:
            formattedValue = this.formatPrice(value);
            break;
        case OptionChainTableItem.ASK_SIZE_COL_INDEX:
        case OptionChainTableItem.BID_SIZE_COL_INDEX:
        case OptionChainTableItem.VOLUME_COL_INDEX:
        case OptionChainTableItem.OPEN_INTEREST_COL_INDEX:
            formattedValue = value >= 0 ? value.toString() : OptionChainTableItem.NOT_AVAILABLE;
            break;
        case OptionChainTableItem.CHANGE_PERCENT_COL_INDEX:
            formattedValue = instrument.Level1.strChangePercent(account);
            break;
        case OptionChainTableItem.IV_COL_INDEX:
            formattedValue = GreeksFormatter.formatIV(value);
            break;
        case OptionChainTableItem.GAMMA_COL_INDEX:
        case OptionChainTableItem.DELTA_COL_INDEX:
        case OptionChainTableItem.THETA_COL_INDEX:
        case OptionChainTableItem.VEGA_COL_INDEX:
        case OptionChainTableItem.RHO_COL_INDEX:
            formattedValue = GreeksFormatter.formatGreek(value);
            break;
        case OptionChainTableItem.PAPER_POSITION_COL_INDEX:
            formattedValue = DataCache.formatVolume(instrument, value, showLots);
            break;
        case OptionChainTableItem.MONETARY_VALUE_COL_INDEX:
            formattedValue = value > 0 ? account.assetBalanceDefault.formatPrice(value) : Resources.getResource('general.N_A');
            break;
        case OptionChainTableItem.LAST_TIME_COL_INDEX:
            formattedValue = DateTimeUtils.FormatToTime(value);
            break;
        }

        return new ColumnData(value, formattedValue);
    }

    public GetTooltipKey (column: number): string {
        switch (column) {
        case OptionChainTableItem.MONETARY_VALUE_COL_INDEX:
            return 'panel.optionChain.monetaryValue.tt';
        }
        return '';
    }

    // #region ITableItem methods
    public ColumnCount (): number {
        return OptionChainTableItem.columnsParams.length;
    }

    public GetColumnParams (i: number): any {
        const params = OptionChainTableItem.columnsParams[i];
        switch (i) {
        case OptionChainTableItem.PAPER_POSITION_COL_INDEX:
            params.Hidden = CustomerAccess.GetDisplayMode(PanelNames.OptionPaperPositionsPanel) === PanelDisplayMode.NotAllowed;
            break;
        }
        return params;
    }

    public GetDataType (columnIndex: number): number {
        return OptionChainTableItem.dataTypes[columnIndex];
    }
    // #endregion

    // #region static table parameters
    static readonly NOT_AVAILABLE = '---';

    static readonly LAST_PRICE_COL_INDEX = 0;
    static readonly BID_SIZE_COL_INDEX = 1;
    static readonly IV_COL_INDEX = 2;
    static readonly PAPER_POSITION_COL_INDEX = 3;
    static readonly ASK_SIZE_COL_INDEX = 4;
    static readonly DELTA_COL_INDEX = 5;
    static readonly GAMMA_COL_INDEX = 6;
    static readonly VEGA_COL_INDEX = 7;
    static readonly THETA_COL_INDEX = 8;
    static readonly RHO_COL_INDEX = 9;
    static readonly VOLUME_COL_INDEX = 10;
    static readonly PREV_CLOSE_COL_INDEX = 11;
    static readonly OPEN_INTEREST_COL_INDEX = 12;
    static readonly MONETARY_VALUE_COL_INDEX = 13;
    static readonly CHANGE_COL_INDEX = 14;
    static readonly CHANGE_PERCENT_COL_INDEX = 15;
    static readonly OPEN_COL_INDEX = 16;
    static readonly HIGH_COL_INDEX = 17;
    static readonly LOW_COL_INDEX = 18;
    static readonly LAST_TIME_COL_INDEX = 19;
    static readonly ASK_COL_INDEX = 20;
    static readonly BID_COL_INDEX = 21;

    static columnsParams: ColumnParams[] =
        [
            new ColumnParams('panel.optionChain.lastPrice', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, true),
            new ColumnParams('panel.optionChain.bidSize', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, true),
            new ColumnParams('panel.optionChain.iv', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, true, true),
            new ColumnParams('panel.optionChain.paperPosition', 70, QuickTableColumnType.COL_DEFAULT, false, true, true),
            new ColumnParams('panel.optionChain.askSize', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, true),
            new ColumnParams('panel.optionChain.delta', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, true, true),
            new ColumnParams('panel.optionChain.gamma', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, true, true),
            new ColumnParams('panel.optionChain.vega', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, true, true),
            new ColumnParams('panel.optionChain.theta', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, true, true),
            new ColumnParams('panel.optionChain.rho', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, true),
            new ColumnParams('panel.optionChain.volume', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, true),
            new ColumnParams('panel.optionChain.prevClose', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, true),
            new ColumnParams('panel.optionChain.openInterest', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
            new ColumnParams('panel.optionChain.monetaryValue', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, true),
            new ColumnParams('panel.optionChain.change', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, true, true),
            new ColumnParams('panel.optionChain.changePercent', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, true),
            new ColumnParams('panel.optionChain.open', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, true),
            new ColumnParams('panel.optionChain.high', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, true),
            new ColumnParams('panel.optionChain.low', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, true),
            new ColumnParams('panel.optionChain.lastTime', 70, QuickTableColumnType.COL_DATE_SORT, false, false, true),
            new ColumnParams('panel.optionChain.ask', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, true, true),
            new ColumnParams('panel.optionChain.bid', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, true, true)

        ];

    private static readonly dataTypes = [
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.DateTime,
        QuickTableComparingType.Double
    ];

    public static signedColumnsIndexes = [
        OptionChainTableItem.PAPER_POSITION_COL_INDEX
    ];

    public static prevValueColumnsIndexes = [
        OptionChainTableItem.LAST_PRICE_COL_INDEX,
        OptionChainTableItem.IV_COL_INDEX,
        OptionChainTableItem.GAMMA_COL_INDEX,
        OptionChainTableItem.ASK_SIZE_COL_INDEX,
        OptionChainTableItem.BID_SIZE_COL_INDEX,
        OptionChainTableItem.DELTA_COL_INDEX,
        OptionChainTableItem.THETA_COL_INDEX,
        OptionChainTableItem.VEGA_COL_INDEX,
        OptionChainTableItem.MONETARY_VALUE_COL_INDEX,
        OptionChainTableItem.RHO_COL_INDEX
    ];

    // #endregion
}
