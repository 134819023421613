// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../../properties/Resources';
import { type IBaseGroupController } from '../../Interfaces/IBaseGroupController';

export class BaseGroupController<GroupItemsType> implements IBaseGroupController<GroupItemsType> {
    getItemName (type: GroupItemsType): string {
        const itemNameKey = this.getItemNameKey(type);
        return Resources.getResource(itemNameKey);
    }

    getItemTooltip (type: GroupItemsType): string {
        const itemNameKey = this.getItemNameKey(type);
        if (Resources.IsResourcePresent(itemNameKey + '.descr')) {
            return Resources.getResource(itemNameKey + '.descr');
        }

        return this.getItemName(type);
    }

    getItemNameKey (type: GroupItemsType): string {
        return '';
    }
}
