// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DPBoundToControlTemplate } from '../../templates.js';
import { DynPropertyControl } from '../elements/DynPropertyControl';

// TODO. Add 'set' functions to 'get-only' computed properties.
export class DPBoundToControl extends DynPropertyControl {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.observe('boundToItem', this.onBoundToItem);
    }

    public onBoundToItem (item): void {
        if (isNullOrUndefined(item)) return;

        void this.set('dp.value', item.value);
    }
}

DynPropertyControl.extendWith(DPBoundToControl, {
    template: DPBoundToControlTemplate,
    data: function () {
        return {
            boundToItem: null
        };
    }
});
