// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class WarningSett {
    public WarnIfWrongOrder = true;
    public WarnIfIdenticalSubsequentOrderIsPlaced = false;
    public WarnIfIdenticalOrderTime = 3;
    public WarnIfQtyToCloseMoreThanMaxLot = false;
    public OvernightMarginNotificationMessage = true;
    public WarnWhenEnteringIntoLeverage = true;

    public needToShowWarning (confirmData: any = undefined): boolean { // some warnings ignore the warnOnWrongOrder setting #110331
        return this.WarnIfWrongOrder || (confirmData?.needShowWarningForce);
    }
}
