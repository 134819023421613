// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { GenerateReportMessages } from '../../../../Commons/GenerateReportMessages'; // import { generateReportMessageHandler } from '../../../../Utils/AppHandlers';
import { DirectCloseAccountResponseMessage } from '../../../../Utils/DirectMessages/DirectCloseAccountResponseMessage';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class CloseAccountResponseMessageProcessor {
    decode (fieldSet: PFixFieldSet): DirectCloseAccountResponseMessage[] {
        const msg = new DirectCloseAccountResponseMessage();

        for (let i = 0; i < fieldSet.Count(); i++) {
            const fixField = fieldSet.fieldSet[i];
            const fieldID = fixField.FieldId;

            if (fieldID === FieldsFactory.FIELD_STATUS) {
                msg.status = fixField.Value;
            } else if (fieldID === FieldsFactory.FIELD_CLOSE_ACCOUNT_REQUEST_ID) {
                msg.closeAccountRequestID = fixField.Value;
            }
        }

        GenerateReportMessages.GenerateReportMessage(msg); // generateReportMessageHandler.GenerateReportMessage(msg);    // <- Property 'GenerateReportMessage' does not exist on type 'Handler'

        return [msg];
    }
}
