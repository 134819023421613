import { TerceraChartNumberHorizontalScaleRenderer } from './Renderers/Scales/TerceraChartNumberHorizontalScaleRenderer';
import { TerceraChartNumberScaleRendererSettings } from './Renderers/Scales/TerceraChartNumberScaleRenderer';
import { TerceraChartNumberVerticalScaleRenderer } from './Renderers/Scales/TerceraChartNumberVerticalScaleRenderer';
import { TerceraChartStyledLineRenderer } from './Renderers/TerceraChartStyledLineRenderer';
import { TerceraChartXYScrollerRenderer } from './Renderers/TerceraChartXYScrollerRenderer';
import { TerceraChartZoomRenderer } from './Renderers/TerceraChartZoomRenderer';
import { RendererDocking } from './Renderers/Utils/RendererDocking';
import { TerceraChartAggregatedXYSeries, XYSeriesData } from './Series/TerceraChartXYSeries';
import { TerceraChartXYActionProcessor } from './TerceraChartActionProcessor/TerceraChartXYActionProcessor';
import { TerceraChartBase } from './TerceraChartBase';
import { TerceraChartXYWindow } from './Windows/TerceraChartXYWindow';
import { TerceraChartWindowsContainer } from './TerceraChartWindowsContainer';

export class TerceraXYChart extends TerceraChartBase<TerceraChartXYWindow> {
    private _series: TerceraChartAggregatedXYSeries = new TerceraChartAggregatedXYSeries();
    private readonly _lineRenderers: TerceraChartStyledLineRenderer[] = [];

    constructor (ctx: CanvasRenderingContext2D, terceraChartPanelContext) {
        super(ctx, terceraChartPanelContext);
        this.TerceraChartActionProcessor = new TerceraChartXYActionProcessor(this);
        this.xScaleRendererSettings = new TerceraChartNumberScaleRendererSettings();
        this.yScaleRendererSettings = new TerceraChartNumberScaleRendererSettings();
    }

    public override CreateWindowsContainer (): TerceraChartWindowsContainer {
        const scrollerRenderer = new TerceraChartXYScrollerRenderer(this);
        const xScaleRenderer = new TerceraChartNumberHorizontalScaleRenderer(this.xScaleRendererSettings, this);
        const zoomRenderer = new TerceraChartZoomRenderer(this, this.TerceraChartActionProcessor);
        return new TerceraChartWindowsContainer(scrollerRenderer, xScaleRenderer, zoomRenderer);
    }

    public override CreateMainWindow (): TerceraChartXYWindow {
        return new TerceraChartXYWindow(this.CreateRightYScaleRenderer(), this.CreateLeftYScaleRenderer());
    }

    public override InitializeRenderers (): void {

    }

    public override BarsCount (): number {
        if (isNullOrUndefined(this._series)) {
            return 0;
        }
        let maxBarsCount = 0;
        for (let i = 0; i < this._series.Count(); i++) {
            maxBarsCount = Math.max(maxBarsCount, this._series.data[i].Count());
        }
        return maxBarsCount;
    }

    public override moveScreenByX (newI1: number, allowChangeBarsToRight?: boolean): boolean {
        const mouseDownWindow = this.GetLastMouseDownWindow();
        if (!isNullOrUndefined(mouseDownWindow)) {
            const delta = newI1 / (mouseDownWindow.ClientRectangle.Height / (mouseDownWindow.FmaxFloatX - mouseDownWindow.FminFloatX));
            mouseDownWindow.MoveX(this.lastMouseDownMinX - delta, this.lastMouseDownMaxX - delta);
        } else {
            const mainWindow = this.mainWindow;
            let delta = (newI1 - mainWindow.FmaxFloatX);
            if (mainWindow.FminFloatX + delta < mainWindow.FminXTotal || mainWindow.FmaxFloatX + delta > mainWindow.FmaxXTotal) {
                return false;
            }
            if (mainWindow.FminFloatX + delta < mainWindow.FminXTotal) {
                delta = mainWindow.FminXTotal - mainWindow.FminFloatX;
            }
            const newMinX = mainWindow.FminFloatX + delta;
            const newMaxX = mainWindow.FmaxFloatX + delta;
            mainWindow.MoveX(newMinX, newMaxX);
        }
        return true;
    }

    public override calulateNewI1FromDelta (deltaX: number): number {
        return deltaX;
    }

    protected setSeries (series: TerceraChartAggregatedXYSeries): void {
        this.removeLineRenderers();
        this._series.Dispose();
        this._series = series;
        this.addLineRenderers();
    }

    protected updateSeries (series: TerceraChartAggregatedXYSeries): void {
        this._series.Dispose();
        this._series = series;
        for (let i = 0; i < this._lineRenderers.length; i++) {
            this._lineRenderers[i].Series = this._series.data[i];
        }
    }

    protected CreateRightYScaleRenderer (): TerceraChartNumberVerticalScaleRenderer {
        const rightYScaleRenderer = new TerceraChartNumberVerticalScaleRenderer(this.yScaleRendererSettings, this);
        rightYScaleRenderer.Docking = RendererDocking.Right;
        return rightYScaleRenderer;
    }

    protected CreateLeftYScaleRenderer (): TerceraChartNumberVerticalScaleRenderer {
        const leftYScaleRenderer = new TerceraChartNumberVerticalScaleRenderer(this.yScaleRendererSettings, this);
        leftYScaleRenderer.Docking = RendererDocking.Left;
        return leftYScaleRenderer;
    }

    protected getLineRenderers (): TerceraChartStyledLineRenderer[] {
        return this._lineRenderers;
    }

    protected getLineRendererByIndex (index: number): TerceraChartStyledLineRenderer {
        if (index >= 0 && index < this._lineRenderers.length) {
            return this._lineRenderers[index];
        }
    }

    protected addLineRenderers (): void {
        for (let i = 0; i < this._series.Count(); i++) {
            const seriesData = this._series.data[i];
            const styleLine = new TerceraChartStyledLineRenderer(this);
            styleLine.Series = seriesData;
            this._lineRenderers.push(styleLine);
        }
        this.mainWindow.Renderers.push(...this._lineRenderers);
    }

    protected removeLineRenderers (): void {
        for (let i = 0; i < this._lineRenderers.length; i++) {
            const rendererToRemove = this._lineRenderers[i];
            const rendererToRemoveIndex = this.mainWindow.Renderers.indexOf(rendererToRemove);
            this.mainWindow.Renderers.splice(rendererToRemoveIndex, 1);
            rendererToRemove.Dispose();
        }
        this._lineRenderers.splice(0, this._lineRenderers.length);
    }

    protected updateMinMaxXScaleBorders (): void {
        let minX = Number.MAX_VALUE;
        let maxX = -Number.MAX_VALUE;
        if (this._series.data.length > 0) {
            for (let i = 0; i < this._series.data.length; i++) {
                const seriesData = this._series.data[i];
                if (seriesData.Count() === 0) {
                    continue;
                }
                minX = Math.min(minX, seriesData.GetValue(0, XYSeriesData.X_INDEX));
                maxX = Math.max(maxX, seriesData.GetValue(seriesData.Count() - 1, XYSeriesData.X_INDEX));
            }
        }
        let offset: number;
        if (maxX === minX) {
            offset = maxX !== 0 ? maxX : 5;
        } else {
            offset = (maxX - minX) * 0.02;
        }
        this.mainWindow.SetMinMaxXScale(minX - offset, maxX + offset);
    }

    protected updateMinMaxYScaleBorders (): void {
        let minY = Number.MAX_VALUE;
        let maxY = -Number.MAX_VALUE;
        if (this._series.data.length > 0) {
            for (let i = 0; i < this._series.data.length; i++) {
                const seriesData = this._series.data[i];
                if (seriesData.Count() === 0) {
                    continue;
                }
                for (let j = 0; j < seriesData.Count(); j++) {
                    minY = Math.min(minY, seriesData.GetValue(j, XYSeriesData.Y_INDEX));
                    maxY = Math.max(maxY, seriesData.GetValue(j, XYSeriesData.Y_INDEX));
                }
            }
        }
        let offset: number;
        if (maxY === minY) {
            offset = maxY !== 0 ? maxY : 5;
        } else {
            offset = (maxY - minY) * 0.02;
        }
        this.mainWindow.SetMinMaxYScale(minY - offset, maxY + offset);
    }
}
