// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export const zh_tr =
{
    'AdditionalProperty.Account': '帳戶',
    'AdditionalProperty.Reverse': '反向',
    'accountDetailsWidget.DropdownButton': '帳戶',
    'accountDetailsWidget.DropdownButton.Tooltip': '帳戶選項單',
    'additional.verification': '需要額外的密碼驗證。',
    Alligator: '鱷魚線',
    'Average Directional Index': '平均趨向指數',
    'Average True Range': '平均真實範圍',
    'Bollinger Band': '布林加通道',
    'Bollinger Bands': '布林加通道',
    'bonds.couponCycle.1m': '1 個月',
    'bonds.couponCycle.1y': '1 年',
    'bonds.couponCycle.3m': '3 個月',
    'bonds.couponCycle.6m': '6 個月',
    'BrokerMessage.Cancel': '取消',
    'BrokerMessage.OK': '確定',
    'BrokerMessage.Text': '經紀人訊息',
    'BusinessRejectMessage.28': '當前密碼不正確',
    'BusinessRejectMessage.31': '新密碼不正確：不能使用相同的密碼',
    'BusinessRejectMessage.201': '新密碼包含禁止的符號',
    'BusinessRejectMessage.202': '密碼不能與登入名相同',
    'BusinessRejectMessage.203': '請輸入新密碼',
    'BusinessRejectMessage.221': '無法變更密碼：新密碼至少應有 1 個大寫字元、1 個小寫字元和 1 個數字。請重試。',
    'BusinessRejectMessage.222': '無法變更密碼：新密碼至少應有 1 個大寫字元、1 個小寫字元和 1 個數字，且至少包含 8 個字元。請重試。',
    'BusinessRejectMessage.223': '無法變更密碼：新密碼至少應有 1 個大寫字元、1 個小寫字元、1 個數字、1 個特殊字元（如 @#$%），且至少包含 8 個字元。請重試。',
    'BusinessRejectMessage.338': '交易解鎖邏輯不允許該交易解鎖密碼。',
    'BusinessRejectMessage.339': '當前交易密碼不正確。',
    'Chande Momentum Oscillator': '錢德動量擺動指標',
    'chart.agregationType.Button.ToolTip': '使用預定義的彙總類型',
    'chart.AllowedPeriods.15M': '15 分鐘',
    'chart.AllowedPeriods.1D': '1 天',
    'chart.AllowedPeriods.1H': '1 小時',
    'chart.AllowedPeriods.1M': '1 分鐘',
    'chart.AllowedPeriods.1S': '1S',
    'chart.AllowedPeriods.1T': '1 個跳動點',
    'chart.AllowedPeriods.1W': '1 週',
    'chart.AllowedPeriods.1Y': '1 年',
    'chart.AllowedPeriods.30M': '30 分鐘',
    'chart.AllowedPeriods.4H': '4 小時',
    'chart.AllowedPeriods.5M': '5 分鐘',
    'chart.AllowedPeriods.Month': '1 個月',
    'chart.AllowedPeriods.Range': '範圍',
    'chart.AllowedPeriods.Ticks': '跳動點',
    'chart.ChartPanel.accountLookup.ToolTip': '選取帳戶',
    'chart.ChartPanel.symbolLookup.ToolTip': '選取股票代號',
    'chart.chartScroll.toBegin': '至最後報價',
    'chart.chartScroll.zoomInButton': '放大',
    'chart.chartScroll.zoomOutButton': '縮小',
    'chart.ChartSymbolSelector.addIndicator.ButtonToolTip': '加入指標',
    'chart.ChartSymbolSelector.drawingButton.ToolTip': '顯示/隱藏繪圖工具列',
    'chart.ChartSymbolSelector.mouseTradeButton.ToolTip': '滑鼠交易',
    'chart.ChartSymbolSelector.oeButton': '訂單登錄',
    'chart.ChartSymbolSelector.oeButton.ToolTip': '圖表訂單登錄',
    'chart.ChartSymbolSelector.OverlayTooltip': '加入覆蓋',
    'Chart.chartWindow.auto': '自動',
    'Chart.chartWindow.collapsed': '已折疊',
    'Chart.chartWindow.manual': '手動',
    'chart.chartWindowManager.SetAuto': '切換為自動價格標度',
    'chart.chartWindowManager.SetManual': '切換為手動價格標度',
    'chart.head.addOverlay': '加入覆蓋...',
    'chart.head.charttype': '樣式',
    'chart.head.line': '繪圖工具',
    'chart.head.overlayRemoveAll': '移除全部',
    'chart.head.overlays': '覆蓋',
    'chart.indicator': '指標',
    'chart.indicatorAdd': '加入指標',
    'chart.indicatorRemoveAll': '移除全部',
    'chart.indicators': '指標',
    'chart.Infoline.days': '天',
    'chart.Infoline.hours': '小時',
    'chart.Infoline.min': '分鐘',
    'chart.Infoline.price': '價格',
    'chart.Infoline.sec': '秒',
    'chart.Infoline.time': '時間',
    'chart.infoWindow.Items.Ask': '要價：',
    'chart.infoWindow.Items.Bars': '#長條圖：',
    'chart.infoWindow.Items.Bid': '出價：',
    'chart.infoWindow.Items.Change': '變動，%',
    'chart.infoWindow.Items.Close': '收盤：',
    'chart.infoWindow.Items.Date': '日期：',
    'chart.infoWindow.Items.High': '高：',
    'chart.infoWindow.Items.Low': '低：',
    'chart.infoWindow.Items.Open': '開盤：',
    'chart.infoWindow.Items.Ticks': '跳動點：',
    'chart.infoWindow.Items.Time': '時間：',
    'chart.infoWindow.Items.Volume': '成交量：',
    'chart.instrumentsLookup': '股票代號查詢',
    'chart.layerManager': '設定...',
    'chart.loading': '正在載入...',
    'chart.loadingData': '正在載入以下項目的資料：',
    'chart.mainlayer.gridPrice': '網格價格',
    'chart.mainlayer.gridTime': '網格時間',
    'chart.mainlayer.volume': '成交量長條圖',
    'chart.menu.cancelByAccount': '按帳戶取消全部',
    'chart.menu.cancelByInstrument': '按股票代號取消全部',
    'chart.menu.cancelOrder': '取消訂單',
    'chart.menu.close': '平倉',
    'chart.menu.closeall': '全部平倉...',
    'chart.menu.InfoWindowDock': '銜接站',
    'chart.menu.InfoWindowHide': '隱藏',
    'chart.menu.InfoWindowSettings': '設定',
    'chart.menu.InfoWindowShortMode': '精簡模式',
    'chart.menu.modify': '修改',
    'chart.menu.PlateIndicatorDublicate': '複製指標',
    'chart.menu.PlateIndicatorRemove': '關閉',
    'chart.menu.PlateIndicatorSettings': '設定',
    'chart.menu.PlateIndicatorVisible': '可見',
    'chart.menu.PlateOverlay.Overlay': '覆蓋',
    'chart.menu.PlateOverlayRemove': '收盤',
    'chart.menu.PlateOverlaySettings': '設定',
    'chart.menu.PlateOverlayVisible': '可見',
    'chart.menu.rollback': '復原部位',
    'chart.noAvailableData': '沒有以下對象的可用資料：',
    'chart.NoData': '沒有資料',
    'chart.priceScaleContextMenu.AutoScale': '自動',
    'chart.priceScaleContextMenu.FitDay': '適配日高位/低位',
    'chart.priceScaleContextMenu.FitDrawings': '適配繪圖',
    'chart.priceScaleContextMenu.FitIndicators': '適配指標',
    'chart.priceScaleContextMenu.FitOrdersPositions': '適配訂單/部位',
    'chart.priceScaleContextMenu.ManualScale': '手動',
    'chart.refresh': '重新整理',
    'chart.Remove': '移除',
    'chart.shortPeriodName.m': '月',
    'chart.shortPeriodName.ticks': '跳動點',
    'chart.ShowHighLow.Close': '收盤',
    'chart.ShowHighLow.High': '高',
    'chart.ShowHighLow.Low': '低',
    'chart.StyleButton.ToolTip': '樣式',
    'chart.templates.tooltip': '範本',
    'chart.tic.short.15min.name': '15 分鐘',
    'chart.tic.short.1day.name': '1 天',
    'chart.tic.short.1hour.name': '1 小時',
    'chart.tic.short.1min.name': '1 分鐘',
    'chart.tic.short.1tic.name': '跳動點',
    'chart.tic.short.30min.name': '30 分鐘',
    'chart.tic.short.4hour.name': '4 小時',
    'chart.tic.short.5min.name': '5 分鐘',
    'chart.tic.short.month.name': '1 個月',
    'chart.tic.short.range.name': '範圍',
    'chart.tic.short.second.name': '秒',
    'chart.tic.short.week.name': '1 週',
    'chart.tic.short.year.name': '1 年',
    'chart.timeframeselector.rangeButton.ToolTip': '選取資料顯示期間',
    'chart.timeframeselector.time': '時間',
    'chart.timeScaleContextMenu.ShowAllSessions': '顯示延伸的時段',
    'chart.timeScaleContextMenu.ShowDaySeparator': '顯示天數分隔符號',
    'chart.timeScaleContextMenu.ShowEmptyBars': '顯示歷程記錄中的漏洞',
    'chart.timeScaleContextMenu.ShowMonthSeparator': '顯示月數分隔符號',
    'chart.timeScaleContextMenu.ShowTimePeriodsSeparator': '顯示時期數分隔符號',
    'chart.timeScaleContextMenu.ShowWeeksSeparator': '顯示週數分隔符號',
    'chart.timeScaleContextMenu.ShowYearSeparator': '顯示年數分隔符號',
    'chart.tool.bar': '長條',
    'chart.tool.candle': '陰陽燭',
    'chart.tool.clone': '複製',
    'chart.tool.Dash': '短劃',
    'chart.tool.delete': '移除',
    'chart.tool.deleteAll': '移除全部',
    'chart.tool.dot': '點',
    'chart.tool.dotline': '點線',
    'chart.tool.DottedLine': '點線',
    'chart.tool.forest': '直方圖',
    'chart.tool.IsoDot': 'Iso 點',
    'chart.tool.line': '線',
    'chart.tool.properties': '設定',
    'chart.tool.ShapedLine': '形線',
    'chart.tool.solid': '區域',
    'chart.tools.ABCPatern': 'ABC 形態',
    'chart.tools.AndrewPitchfork': '安德魯的乾草叉',
    'chart.tools.backcolor': '背景顏色',
    'chart.tools.ButterflyGartley': '伽利蝴蝶',
    'chart.tools.Circle': '圓形',
    'chart.tools.default': '指標',
    'chart.tools.Ellipse': '橢圓形',
    'chart.tools.eraser': '橡皮檫',
    'chart.tools.FibArc': '斐波那契弧形',
    'chart.tools.FibEllipses': '斐波那契橢圓形',
    'chart.tools.FibExp': '斐波那契擴展',
    'chart.tools.FibFan': '斐波那契扇形',
    'chart.tools.FibPhiChannel': '斐波那契 phi 通道',
    'chart.tools.Fibretr': '斐波那契回調',
    'chart.tools.FibSpiral': '斐波那契螺旋',
    'chart.tools.FibTga': '斐波那契時間目標分析',
    'chart.tools.FibTz': '斐波那契時區',
    'chart.tools.GannFan': '江恩角度線',
    'chart.tools.GannGrid': '江恩網格',
    'chart.tools.GannLine': '江恩線',
    'chart.tools.horizontal': '水平線',
    'chart.tools.infoline': '尺規',
    'chart.tools.Line': '線',
    'chart.tools.mirrored': '啟用鏡像檢視',
    'chart.tools.points': '點計數',
    'chart.tools.Polygon': '多邊形',
    'chart.tools.PPZ': 'PPZ（價格樞軸區）',
    'chart.tools.PriceChannel': '價格通道',
    'chart.tools.Rectangle': '矩形',
    'chart.tools.RegressionChanel': '回歸通道',
    'chart.tools.ScalingByRect': '按矩形縮放',
    'chart.tools.selection': '選取',
    'chart.tools.snapToCandle': '貼齊陰陽燭',
    'chart.tools.StayDrawingMode': '留在繪圖模式',
    'chart.tools.symbol': '標記',
    'chart.tools.text': '註解',
    'chart.tools.TopBottomPattern': '自上而下形態',
    'chart.tools.Triangle': '三角形',
    'chart.tools.vertical': '垂直線',
    'chart.view': '檢視',
    'chart.view.analyseGroupMenu': '分析工具',
    'chart.view.analysetoolbar': '分析工具',
    'chart.view.barsToRight': '右邊的長條...',
    'chart.view.colors': '設定',
    'chart.view.dataInfo': '資訊視窗',
    'chart.view.Day_HighLow': '日高位/低位',
    'chart.view.drawingtoolbar': '繪圖工具列',
    'chart.view.font': '軸字型',
    'chart.view.landscapequestion': '是否使用風景紙安排？',
    'chart.view.merge': '連續線',
    'chart.view.orderEntry': '圖表訂單登錄',
    'chart.view.periods': '間隔...',
    'chart.view.previousCloseLine': '上一收盤線',
    'chart.view.PrevSettlementPriceLine': '上一結算價格',
    'chart.view.scroller': '捲軸',
    'chart.view.setOrder': '設定順序',
    'chart.view.SettlementPriceLine': '結算價格',
    'chart.view.show_cross': '追蹤游標',
    'chart.view.show_history': '顯示交易歷程記錄',
    'chart.view.show_set_zero': '顯示空長條',
    'chart.view.showEvents': '顯示交易',
    'chart.view.showNews': '顯示新聞',
    'chart.view.showOrders': '顯示訂單',
    'chart.view.showPositions': '顯示部位',
    'chart.view.source': '資料類型',
    'chart.view.source0': '按股票代號預設',
    'chart.view.source1': '出價',
    'chart.view.source1000': '要價',
    'chart.view.source1001': '買賣價差平均線',
    'chart.view.source1002': '最低值（出價）和最高值（要價）',
    'chart.view.source4': '最後',
    'chart.view.sourceBID/ASK': '按買賣價差',
    'chart.view.sourceTick1': '按買賣價差',
    'chart.view.TimeToNextBar': '到下一長條的時間',
    'chart.view.toolbar': '工具列',
    'chart.view.volume': '顯示成交量',
    'chart.visualTrading.Invalid price': '無效的價格',
    'chart.volumeColoringMode.ByBar': '按長條',
    'chart.volumeColoringMode.ByDifference': '按差額',
    'chart.volumeColoringMode.Fixed': '固定',
    'chart.volumeColoringMode.ByMovingAverage': '在移動平均線之上/之下',
    'chart.quickTrading.Buy limit': '買入限價',
    'chart.quickTrading.Buy stop': '買入止損',
    'chart.quickTrading.Invalid price': '無效的價格',
    'chart.quickTrading.Sell limit': '賣出限價',
    'chart.quickTrading.Sell stop': '賣出止損',
    'Commodity Channel Index': '商品通道指數',
    'connect.connection.connect.error': '錯誤的連線參數',
    'connect.connection.tradestream.error': '找不到交易串流！',
    'connect.connection.unknown.error': '未知錯誤！',
    Custom: '自訂',
    Day: '天',
    'dealticket.RiskRuleWarning.Header': '風險規則警告',
    Deutsch: '#hidden#',
    'enum.AccountType.ClosedFund': '封閉式基金',
    'enum.AccountType.MultiAsset': '多資產',
    'enum.AccountType.OpenedFund': '開放式基金',
    'enum.AccountType.SingleCCY': '單一貨幣',
    'enum.AssetType.COMMODITIES': '商品',
    'enum.AssetType.CRYPTO_CCY': '加密貨幣',
    'enum.AssetType.CURRENCY': '貨幣',
    'enum.AssetType.SHARES': '股份',
    'editableComboBox.CreateNew': '建立新',
    'editableComboBox.CreateNewTemplate': '建立範本',
    'editableComboBox.CreateNew.tooltip': '點選以建立新的工具列表',
    'editableComboBox.CreateNewTemplate.tooltip': '點選以建立新的範本',
    'editableComboBox.defaultList': '預設列表',
    'editableComboBox.tooltip': '儲存的列表',
    'ExchangeId.All': '全部交易所',
    'Exponential Moving Average': '指數移動平均線',
    FOK: 'FOK',
    'FullLogs.Cluster': '伺服器',
    'FullLogs.DateTime': '時間',
    'FullLogs.Fields': '欄位',
    'FullLogs.Message name': '訊息名稱',
    'FullLogs.Session': '對話',
    'general.Calculating': '正在計算...',
    'general.connection.restErrorMsg': '目前在儲存設定和工作區時發生問題。請聯絡支援部',
    'general.connection.Verification password not valid.': '驗證密碼無效',
    'general.CUSTOM': '自訂',
    'general.Friday': '星期五',
    'general.messageBox.cancel': '取消',
    'general.messageBox.no': '否',
    'general.messageBox.ok': '確定',
    'general.messageBox.showNextTime': '下次顯示',
    'general.messageBox.yes': '是',
    'general.Monday': '星期一',
    'general.day1.Mo': '週一',
    'general.day2.Tu': '週二',
    'general.day3.We': '週三',
    'general.day4.Th': '週四',
    'general.day5.Fr': '週五',
    'general.day6.Sa': '週六',
    'general.day7.Su': '週日',
    'general.month.1': '1 月',
    'general.month.10': '10 月',
    'general.month.11': '11 月',
    'general.month.12': '12 月',
    'general.month.2': '2 月',
    'general.month.3': '3 月',
    'general.month.4': '4 月',
    'general.month.5': '5 月',
    'general.month.6': '6 月',
    'general.month.7': '7 月',
    'general.month.8': '8 月',
    'general.month.9': '9 月',
    'general.monthFullName.1': '1 月',
    'general.monthFullName.10': '10 月',
    'general.monthFullName.11': '11 月',
    'general.monthFullName.12': '12 月',
    'general.monthFullName.2': '2 月',
    'general.monthFullName.3': '3 月',
    'general.monthFullName.4': '4 月',
    'general.monthFullName.5': '5 月',
    'general.monthFullName.6': '6 月',
    'general.monthFullName.7': '7 月',
    'general.monthFullName.8': '8 月',
    'general.monthFullName.9': '9 月',
    'general.N_A': '不適用',
    'general.NoData...': '沒有數據',
    'general.OvernightMarginNotificationMessage': '顯示隔夜保證金通知訊息',
    'general.Saturday': '星期六',
    'general.Settings': '設定',
    'general.Sunday': '星期日',
    'general.Thursday': '星期四',
    'general.trading.at': '在',
    'general.trading.Buy': '買入',
    'general.trading.confirmation': '確認',
    'general.trading.confirmation.prefix': '',
    'general.trading.confirmationString': '{1} {2} {3} {4} {5} {6} {7} {8} {9} {10} {14} {13} {14} {15} {11} {12} {16}',
    'general.trading.for': '為',
    'general.trading.limitBuyLessAsk': '限價買入訂單價格高於市場要價，訂單將被立即滿足。',
    'general.trading.limitSellMoreBid': '限價賣出訂單價格低於市場出價，訂單將被立即滿足。',
    'general.trading.marketCloseFor': '以下日期的收盤時間：',
    'general.trading.pips': '跳動點',
    'general.trading.points': '點',
    'general.trading.position': '部位',
    'general.trading.position.Buy': '長',
    'general.trading.position.Sell': '短',
    'general.trading.priceNotAvailable': '不可用',
    'general.trading.refused': '被拒',
    'general.trading.Sell': '賣出',
    'general.trading.stopBuyMoreAsk': '止損買入訂單價格低於市場要價，訂單將被立即滿足。',
    'general.trading.stopSellLessBid': '止損賣出訂單價格高於市場出價，訂單將被立即滿足。',
    'general.trading.with offset': '有互抵',
    'general.Tuesday': '星期二',
    'property.general.WarnIfWrongOrderParam': '在訂單錯誤時警告',
    'general.Wednesday': '星期三',
    GTC: 'GTC',
    'helpPage.link': 'https://guide.traderevolution.com/project/',
    'homePage.link': 'http://traderevolution.com/',
    Ichimoku: '一目均衡表',
    'Instrument.Futures.contract': '契約',
    'Instrument.Level1.ExtendedFields.NormalMarketSize': '正常市場規模',
    'Instrument.Spreadbet.bet': '投注',
    'InstrumentDetailsPanel.(Indicative symbol)': '（指示性符號）',
    'InstrumentDetailsPanel.1.GeneralInfo': '一般資訊',
    'InstrumentDetailsPanel.2.TradingInfo': '交易資訊',
    'InstrumentDetailsPanel.3.MarginReg': '保證金要求',
    'InstrumentDetailsPanel.4.Fees': '費用',
    'InstrumentDetailsPanel.5.Rebates': '回扣',
    'InstrumentDetailsPanel.6.SessionInfo': '指示性市場時間表',
    'InstrumentDetailsPanel.7.Plans': '計畫',
    'InstrumentDetailsPanel.AccruedInterest': '應計利息',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot': '加入每口的流動性回扣訂單',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot': '加入每口的流動性回扣',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume': '加入每成交量的流動性回扣',
    'InstrumentDetailsPanel.AFTER_MARKET': '售後市場',
    'InstrumentDetailsPanel.Allow short positions': '是',
    'InstrumentDetailsPanel.AllowedOperations': '允許的操作',
    'InstrumentDetailsPanel.AllowedOrderTypes': '允許的訂單類型',
    'InstrumentDetailsPanel.AllowShortPositions': '允許短部位',
    'InstrumentDetailsPanel.Amount': '數量',
    'InstrumentDetailsPanel.AssetClass': '股票代號類型',
    'InstrumentDetailsPanel.BEFORE_MARKET': '售前市場',
    'InstrumentDetailsPanel.Cash': '現金',
    'InstrumentDetailsPanel.Close': '收盤',
    'InstrumentDetailsPanel.Closed': '已關閉',
    'InstrumentDetailsPanel.CloseOutDeadline': '平倉期限',
    'InstrumentDetailsPanel.Commisions': '佣金',
    'InstrumentDetailsPanel.ContactMonth': '契約月份',
    'InstrumentDetailsPanel.ContractMultiplier': '#hidden#',
    'InstrumentDetailsPanel.ContractSize': '契約規模',
    'InstrumentDetailsPanel.CouponCycle': '票息週期',
    'InstrumentDetailsPanel.CouponRate': '票息率，%',
    'InstrumentDetailsPanel.CurrentSession': '當前時段',
    'InstrumentDetailsPanel.DeliveryMethod': '交割方式',
    'InstrumentDetailsPanel.DeliveryStatus': '交割狀態',
    'InstrumentDetailsPanel.Depend from account': '視帳戶而定',
    'InstrumentDetailsPanel.Description': '描述',
    'InstrumentDetailsPanel.Exchange': '交易所',
    'InstrumentDetailsPanel.ExchangeTradeSession': '交易所時段',
    'InstrumentDetailsPanel.Exp1': '資產',
    'InstrumentDetailsPanel.FaceValue': '面值',
    'InstrumentDetailsPanel.FirstTradeDate': '第一個交易日',
    'InstrumentDetailsPanel.Futures': '期貨',
    'InstrumentDetailsPanel.FuturesClass': '期貨類別',
    'InstrumentDetailsPanel.HiLimit': '上限',
    'InstrumentDetailsPanel.Holiday': '假日',
    'InstrumentDetailsPanel.HolidayDate': '日期',
    'InstrumentDetailsPanel.HolidayName': '假日名稱',
    'InstrumentDetailsPanel.Holidays list': '假日列表',
    'InstrumentDetailsPanel.HolidayType': '時間表',
    'InstrumentDetailsPanel.immediate': '立即',
    'InstrumentDetailsPanel.KEYNextHoliday': '下一個假日',
    'InstrumentDetailsPanel.LastTradeDate': '最後一個交易日期',
    'InstrumentDetailsPanel.LotSize': '每口大小',
    'InstrumentDetailsPanel.LotStep': '每口步長',
    'InstrumentDetailsPanel.LowLimit': '下限',
    'InstrumentDetailsPanel.MAIN': '主',
    'InstrumentDetailsPanel.Margin': '保證金要求',
    'InstrumentDetailsPanel.Margin_ByAccount': '按帳戶劃分的保證金',
    'InstrumentDetailsPanel.MarginBuy': '買入',
    'InstrumentDetailsPanel.MarginDay': '天',
    'InstrumentDetailsPanel.MarginDayBuy': '日間買入',
    'InstrumentDetailsPanel.MarginDaySell': '日間賣出',
    'InstrumentDetailsPanel.MarginInAccountCurrency': '#hidden#',
    'InstrumentDetailsPanel.MarginOvernight': '隔夜',
    'InstrumentDetailsPanel.MarginOvernightBuy': '隔夜買入',
    'InstrumentDetailsPanel.MarginOvernightSell': '隔夜賣出',
    'InstrumentDetailsPanel.MarginSell': '賣出',
    'InstrumentDetailsPanel.MaturityDate': '到期日',
    'InstrumentDetailsPanel.MaximumLot': '最大口數',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol': '最大部位數量',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday': '盤中最大部位數量',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery': '交割最大部位數量',
    'InstrumentDetailsPanel.MinimalLot': '最小口數',
    'InstrumentDetailsPanel.NextPaymentDate': '下一次收益付款',
    'InstrumentDetailsPanel.No': '否',
    'InstrumentDetailsPanel.non trading day': '收盤',
    'InstrumentDetailsPanel.None': '無',
    'InstrumentDetailsPanel.NormalMarketSize': '#hidden#',
    'InstrumentDetailsPanel.Not allow short positions': '否',
    'InstrumentDetailsPanel.NoticeDate': '通知日期',
    'InstrumentDetailsPanel.onlyCloseOrders': '僅關閉訂單',
    'InstrumentDetailsPanel.Open': '打開',
    'InstrumentDetailsPanel.OrderPerLot': '每口的訂單',
    'InstrumentDetailsPanel.PerFill': '每次滿足',
    'InstrumentDetailsPanel.PerLot': '滿足每口',
    'InstrumentDetailsPanel.PerOrder': '每份訂單',
    'InstrumentDetailsPanel.PerOrderVolume': '訂單成交量，%',
    'InstrumentDetailsPanel.PerPhoneTransaction': '每件電話交易事項',
    'InstrumentDetailsPanel.PerTransaction': '每件交易事項',
    'InstrumentDetailsPanel.PerVolume': '滿足成交量，%',
    'InstrumentDetailsPanel.Physically': '物質上',
    'InstrumentDetailsPanel.POSTCLOSE': '盤後',
    'InstrumentDetailsPanel.PREOPEN': '盤前',
    'InstrumentDetailsPanel.PreviousPaymentDate': '上一次收益付款',
    'InstrumentDetailsPanel.Price': '價格',
    'InstrumentDetailsPanel.ProductType': '產品類型',
    'InstrumentDetailsPanel.QuotiongCurrency': '報價貨幣',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot': '移除每口的流動性回扣訂單',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot': '移除每口的流動性回扣',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume': '移除每成交量的流動性回扣',
    'InstrumentDetailsPanel.SessionStatus': '#hidden#',
    'InstrumentDetailsPanel.SettlementDate': '結算日期',
    'InstrumentDetailsPanel.shortened': '縮短的日',
    'InstrumentDetailsPanel.StrikePrice': '執行價格',
    'InstrumentDetailsPanel.SwapBuy': '做多掉期',
    'InstrumentDetailsPanel.SwapSell': '做空掉期',
    'InstrumentDetailsPanel.Symbol': '股票代號',
    'InstrumentDetailsPanel.T+0': 'T + 0（本日）',
    'InstrumentDetailsPanel.TickCoast': '跳動點成本',
    'InstrumentDetailsPanel.TickSize': '跳動點規模',
    'InstrumentDetailsPanel.TradindBlockedOnSession': '交易在時段內被凍結',
    'InstrumentDetailsPanel.TradingBalance': '結算系統',
    'InstrumentDetailsPanel.TradingHalt': '交易暫停',
    'InstrumentDetailsPanel.TradingStatus': '交易代碼狀態',
    'InstrumentDetailsPanel.Underlier': '標的資產',
    'InstrumentDetailsPanel.VAT': 'VAT',
    'InstrumentDetailsPanel.Volume': '滿足成交量',
    'InstrumentDetailsPanel.VolumeWithMinPD': '滿足成交量 %，含最低 p/d',
    'InstrumentDetailsPanel.working': '開盤',
    'InstrumentDetailsPanel.Yes': '是',
    'InstrumentDetailsPanel.Yield': '收益率',
    'InstrumentDetailsPanel.YTM': '到期收益率，%',
    'InstrumentDetailsPanel.YTM.RefreshLink': '重新整理',
    IOC: 'IOC',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders': '不允許繫結的訂單作為市價單執行',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp': '不允許在市場中行使止損/獲利價格',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType': '不允許變更訂單類型或市價單類型',
    'IsAllowedResponceReason.InstrumentForAccountDelivery': '帳戶交割工具',
    'IsAllowedResponceReason.InstrumentTradingMode': '不允許進行工具交易',
    'IsAllowedResponceReason.LockTrading': '交易在平台中被鎖定',
    'IsAllowedResponceReason.LockTradingByPassword': '請在終端底部輸入交易密碼',
    'IsAllowedResponceReason.NoLastQuote': '沒有最後報價',
    'IsAllowedResponceReason.NotAllowedByOrderStaus': '不允許按訂單狀態執行操作',
    'IsAllowedResponceReason.NotAllowedPositionCloseOnly': '僅允許平倉',
    'IsAllowedResponceReason.NotAllowedRouteOrderType': '由於不支援選定的委託時間有效期和訂單類型，無法建立訂單。',
    'IsAllowedResponceReason.NotClosablePosition': '不可平倉的部位',
    'IsAllowedResponceReason.NotLoadCache': '沒有加載快取',
    'IsAllowedResponceReason.NotVisibleInstrumentRoute': '不可見的工具或路由',
    'IsAllowedResponceReason.OperationNotAllowedByOrderType': '不允許按訂單類型執行操作',
    'IsAllowedResponceReason.OperationNotAllowedBySession': '不允許按時段執行操作',
    'IsAllowedResponceReason.OrderTypeNotAllowedBySession': '不允許按時段劃分訂單類型',
    'IsAllowedResponceReason.RouteNotRunning': '不允許進行工具交易。',
    'IsAllowedResponceReason.RuleAdministration': '規則管理',
    'IsAllowedResponceReason.RuleClosePositionDisable': '不允許按規則平倉',
    'IsAllowedResponceReason.RuleFunctionSelfTrading': '自交易規則功能',
    'IsAllowedResponceReason.RuleFunctionTrading': '不允許按規則進行交易',
    'IsAllowedResponceReason.RuleOCO': '二擇一委託規則',
    'IsAllowedResponceReason.RuleSlTpNotAllowed': '不允許按規則止損/獲利',
    'IsAllowedResponceReason.RuleTrStop': '跟蹤止損規則',
    'IsAllowedResponceReason.Unknown': '未知原因',
    'Keltner Channel': '肯特納通道',
    Limit: '限價',
    'Linear Weighted Moving Average': '線性加權移動平均線',
    LOC: 'LOC',
    LOO: 'LOO',
    Manual: '手動',
    Market: '市價',
    'MarketDepthPanel.ColoringMethod.ByPriceLVL': '按價格水準',
    'MarketDepthPanel.ColoringMethod.RelativeToVolume': '相對於成交量',
    'MarketDepthPanel.ColoringMethod.SizeHistogram': '規模直方圖',
    'McGinley Dynamic': '麥金利動態指標',
    'MDLotsMode.General': '通用預設值 ({1})',
    'MDLotsMode.Lots': '口數',
    'MDLotsMode.RealSize': '實際規模',
    'MessageBox.ThemeChanged.Title': '警告',
    MOC: 'MOC',
    'Modified Moving Average': '經修改的移動平均線',
    Momentum: '動量',
    MOO: 'MOO',
    'Moving Average Envelope': '移動平均線圈',
    'Moving Average/Convergence Divergence': '移動平均/收斂散發指標',
    'NetTrader.SaveWorkspace.Save': '儲存工作區',
    'NumericComboCtrl.Edit': '設定預設值',
    OCO: 'OCO',
    'On Balance Volume': '平衡成交量',
    OnClose: '收盤時',
    OnOpen: '開盤時',
    'Order.ExecutionType.ACCEPTED': '已接受',
    'Order.ExecutionType.ACTIVATED': '已啟用',
    'Order.ExecutionType.CANCELED': '已取消',
    'Order.ExecutionType.FILLED': '已滿足',
    'Order.ExecutionType.NEW': '已建立',
    'Order.ExecutionType.PART_FILLED': '已部分滿足',
    'Order.ExecutionType.REFUSED': '已被拒',
    'Order.ExecutionType.REPLACED': '已修改',
    'Order.ExecutionType.RESTATED': '已重列',
    'Order.ExecutionType.UNKNOWN': '未知',
    'OrderEntry.InfoBlock.After trade funds': '交易後資金',
    'OrderEntry.InfoBlock.Availible funds': '可用資金',
    'OrderEntry.InfoBlock.Balance': '餘額',
    'OrderEntry.InfoBlock.Fee': '費用',
    'OrderEntry.InfoBlock.Gross': '總額',
    'OrderEntry.InfoBlock.Impact on portfolio': '對投資組合的影響',
    'OrderEntry.InfoBlock.Init. margin': '初始保證金',
    'OrderEntry.InfoBlock.Maint. margin': '維持保證金',
    'OrderEntry.InfoBlock.Margin availible': '可用保證金',
    'OrderEntry.InfoBlock.Net': '淨額',
    'OrderEntry.InfoBlock.Spread in loss': '價差初始損失',
    'OverlayScreen.barsBodyLabel': '燭體',
    'OverlayScreen.barsBorderLabel': '邊界',
    'OverlayScreen.barsHiLowLabel': '線',
    'OverlayScreen.cancelButton': '取消',
    'OverlayScreen.dataLabel': '資料類型',
    'OverlayScreen.dojiLabel': '十字線',
    'OverlayScreen.histogramLineLabel': '直方圖線',
    'OverlayScreen.instrumentLabel': '股票代號',
    'OverlayScreen.Main scale': '本尺',
    'OverlayScreen.name': '覆蓋',
    'OverlayScreen.numberOverlaysError': '無法加入 5 個以上的覆蓋。',
    'OverlayScreen.okButton': '確定',
    'OverlayScreen.Overlays': '覆蓋',
    'OverlayScreen.priceLabel': '價格比例',
    'OverlayScreen.priceMarkerCheckbox': '顯示價格標記',
    'OverlayScreen.Separate scale': '分開標度',
    'OverlayScreen.solidPriceLabel': '區域',
    'OverlayScreen.styleLabel': '樣式',
    'OverlayScreen.wickLabel': '燭心',
    'screen.Alerts.ChangePr': '變動，%',
    'screen.Alerts.Last': '最後',
    'screen.Alerts.Bid': '出價',
    'screen.Alerts.Ask': '要價',
    'screen.Alerts.Volume': '成交量',
    'panel.accountDetails.Groups.1.General': '一般',
    'panel.accountDetails.Groups.2.Margin': '保證金',
    'panel.accountDetails.Groups.3.AccountActivity': '帳戶活動',
    'panel.accountDetails.Groups.4.TodayResults': '本日業績',
    'panel.accountDetails.Groups.5.RiskManagement': '風險管理',
    'panel.accountDetails.Groups.55.Plans': '計畫',
    'panel.accountDetails.Groups.6.Info': '資訊',
    'panel.AccountDetailsPanel': '帳戶詳情',
    'panel.AccountDetailsPanel.InfoTooltip': '透過從表格拖放變更小工具內的欄位',
    'panel.accountDetails.Groups.Rico risks': '帳戶詳情',
    'panel.accountDetails.Groups.Kyrrex risks': '帳戶詳情',
    'panel.accountDetails.Groups.AccountActivity': '2.帳戶活動',
    'panel.accountDetails.Groups.General': '1.一般',
    'panel.accountDetails.Groups.Info': '5.資訊',
    'panel.accountDetails.Groups.RiskManagement': '4.風險管理',
    'panel.accountDetails.Groups.TodayResults': '3.本日業績',
    'panel.accountDetails.Kyrrex risks.AvailableBalance': '可用餘額',
    'panel.accountDetails.Kyrrex risks.BlockedBalance': '凍結餘額',
    'panel.accountDetails.Rico risks.AVLM': '可用保證金',
    'panel.accountDetails.Rico risks.BCKA': '凍結保證金',
    'panel.accountDetails.Rico risks.BKBL': '凍結餘額',
    'panel.accountDetails.Rico risks.BMBM': 'BMF 凍結保證金',
    'panel.accountDetails.Rico risks.BMFL': 'BMF 限額',
    'panel.accountDetails.Rico risks.BOPT': '餘額選項',
    'panel.accountDetails.Rico risks.BUOP': '餘額不涵蓋操作',
    'panel.accountDetails.Rico risks.CED0': '貸項登錄第 0 天',
    'panel.accountDetails.Rico risks.CED1': '貸項登錄第 1 天',
    'panel.accountDetails.Rico risks.CED2': '貸項登錄第 2 天',
    'panel.accountDetails.Rico risks.CED3': '貸項登錄第 3 天',
    'panel.accountDetails.Rico risks.CRLM': '貸項限額',
    'panel.accountDetails.Rico risks.CRLO': '貸項限額選項',
    'panel.accountDetails.Rico risks.CULM': '當期限額',
    'panel.accountDetails.Rico risks.D1PB': 'D1 預期餘額',
    'panel.accountDetails.Rico risks.D2PB': 'D2 預期餘額',
    'panel.accountDetails.Rico risks.D3PB': 'D3 預期餘額',
    'panel.accountDetails.Rico risks.D1PD': 'D1 預期日期',
    'panel.accountDetails.Rico risks.D2PD': 'D2 預期日期',
    'panel.accountDetails.Rico risks.D3PD': 'D3 預期日期',
    'panel.accountDetails.Rico risks.DED0': '借項登錄第 0 天',
    'panel.accountDetails.Rico risks.DED1': '借項登錄第 1 天',
    'panel.accountDetails.Rico risks.DED2': '借項登錄第 2 天',
    'panel.accountDetails.Rico risks.DED3': '借項登錄第 3 天',
    'panel.accountDetails.Rico risks.DLBL': '日間流動性餘額',
    'panel.accountDetails.Rico risks.DLMT': '每日限額',
    'panel.accountDetails.Rico risks.EBVL': '執行買入價值',
    'panel.accountDetails.Rico risks.ESVL': '執行賣出價值',
    'panel.accountDetails.Rico risks.FBBL': '最終 BMF 餘額',
    'panel.accountDetails.Rico risks.FNBL': '最終餘額',
    'panel.accountDetails.Rico risks.FNLE': '財務槓桿',
    'panel.accountDetails.Rico risks.INBA': '初始餘額',
    'panel.accountDetails.Rico risks.INBL': '初始餘額',
    'panel.accountDetails.Rico risks.LEVE': '槓桿',
    'panel.accountDetails.Rico risks.LILO': '槓桿期權限額',
    'panel.accountDetails.Rico risks.LVLM': '槓桿限額',
    'panel.accountDetails.Rico risks.OPBL': '期權餘額',
    'panel.accountDetails.Rico risks.OVLT': '透支限額',
    'panel.accountDetails.Rico risks.PBVL': '待決買入價值',
    'panel.accountDetails.Rico risks.POVA': '潛在透支金額',
    'panel.accountDetails.Rico risks.PRBA': '預期餘額',
    'panel.accountDetails.Rico risks.PSVL': '待決賣出價值',
    'panel.accountDetails.Rico risks.SKBL': '股票餘額',
    'panel.accountDetails.Rico risks.WDBL': '提款餘額',
    'panel.AccountLink.SymbolLink.ToolTip': '股票代號連結',
    'panel.AccountLink.ToolTip': '帳戶連結',
    'panel.AccountLink.CanFilterToolTip': '已按帳戶篩選面板中的資料',
    'panel.AccountLinkButton.ToolTip': '該功能按帳戶篩選您的部位和訂單。在所有面板中同步切換帳戶',
    'panel.accounts.AccountLink.ToolTip': '已啟用帳戶連結功能',
    'panel.accounts': '帳戶',
    'panel.accounts.AccountDescription': '#hidden#',
    'panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached': '已達到每日損失限額',
    'panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached': '已達到最大日間成交量',
    'panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)': '訂單流量限額（每日）',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled': '已停用',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled': '已啟用',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Active': '有效',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed': '已自動關閉',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed': '已關閉',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules': '交易已被風險規則停用',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.MarginWarning': '有關可能止損離場的警告',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached': '已達到最大跟蹤回撤',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend': '暫停',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached': '已達到未實現的損失限額',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached': '已達到每週損失限額',
    'panel.accounts.AccountMode': '帳戶模式',
    'panel.accounts.AccountType': '帳戶類型',
    'panel.accounts.AccountValue': '帳戶值',
    'panel.accounts.AllowOvernightTrading': '允許隔夜交易',
    'panel.accounts.AllowTradingPrePost': '盤前/盤後交易',
    'panel.accounts.Asset': '資產',
    'panel.accounts.AssetDescription': '資產描述',
    'panel.accounts.AssetType': '資產類型',
    'panel.accounts.AvailableMargin': '可用資金',
    'panel.accounts.Balance': '餘額',
    'panel.accounts.BalancePlusAllRisks': '餘額和全部風險',
    'panel.accounts.BeginBalance': '本日的淨額',
    'panel.accounts.BlockedByOrders': '用於訂單的保證金',
    'panel.accounts.BlockedByPamm': '投資基金資本',
    'panel.accounts.BlockedForStocks': '#hidden#',
    'panel.accounts.BlockedForStocksNew': '因股票而被凍結',
    'panel.accounts.CashBalance': '現金餘額',
    'panel.accounts.ClientType': '終端類型',
    'panel.accounts.Commissions': '佣金',
    'panel.accounts.CreditValue': '信用值',
    'panel.accounts.CurBalance': '預期餘額',
    'panel.accounts.CurMargin': '初始保證金要求，%',
    'panel.accounts.CurrentDailyLoss': '每日損失',
    'panel.accounts.CurrentFundCapital': '當前基金資本',
    'panel.accounts.CurrentStatus': '交易狀態',
    'panel.accounts.CurrentStatus.descr': '交易狀態',
    'panel.accounts.CurrentWeeklyLoss': '每週損失',
    'panel.accounts.Email': '電子郵件',
    'panel.accounts.Estimate value': '估計價值',
    'panel.accounts.FundCapitalGain': '基金資本收益',
    'panel.accounts.FundsUsed': '已用資金',
    'panel.accounts.FundsUsedPercent': '已用資金，%',
    'panel.accounts.GrossPNL': '本日的總額',
    'panel.accounts.InsertOnBalance': 'Insert on balance',
    'panel.accounts.InterestRate': '利率，%',
    'panel.accounts.IntradayRiskManagement': '風險管理狀態',
    'panel.accounts.Leverage': '槓桿',
    'panel.accounts.LockedBalance': '凍結餘額',
    'panel.accounts.LockedBy': '已鎖定',
    'panel.accounts.Login': '帳戶',
    'panel.accounts.MaintMargin': '維持保證金要求，%',
    'panel.accounts.MarginAndRisks': '保證金和風險',
    'panel.accounts.MarginAvailableReal': '可用保證金',
    'panel.accounts.MarginDeficiency': '保證金不足',
    'panel.accounts.MarginForOthers': '#hidden#',
    'panel.accounts.MarginRequirement': '部位維持要求',
    'panel.accounts.MarginStopOut': '維持保證金要求',
    'panel.accounts.MarginStopTrade': '止損交易',
    'panel.accounts.MarginSurplus': '保證金盈餘',
    'panel.accounts.MarginUsedNew': '初始保證金要求',
    'panel.accounts.MarginWarning': '警告保證金',
    'panel.accounts.marginWarnPercent': '保證金警告水準',
    'panel.accounts.MarketDataCharging': '市場資料收費',
    'panel.accounts.MaxDailyLossLimit': '每日損失限額',
    'panel.accounts.MaxDailyProfit': '每日利潤目標',
    'panel.accounts.MaxDayVolume': '#hidden#',
    'panel.accounts.MaximumLot': '最大口數',
    'panel.accounts.MaxOrderAmount': '最高訂單金額',
    'panel.accounts.MaxOrderCapital': '最高訂單資本',
    'panel.accounts.MaxOrdersPerDay': '# 訂單流量限額（每日）',
    'panel.accounts.MaxPendingOrders': '# 最大待決訂單數',
    'panel.accounts.MaxPendingOrdersValue': '最大待決訂單價值',
    'panel.accounts.MaxPositionQtyPerSymbol': '每個股票代號的最大數量',
    'panel.accounts.MaxPositions': '# 最大部位數',
    'panel.accounts.MaxWeekLostLimit': '每週損失限額',
    'panel.accounts.MinMargin': '用於部位的保證金',
    'panel.accounts.Murkups': '加成',
    'panel.accounts.N/A_TelephoneNumber': '不適用',
    'panel.accounts.OptionPremiumReq': '期權? ?溢價? ?要求',
    'panel.accounts.OptionValue': '期權價值',
    'panel.accounts.Orders': '# 訂單',
    'panel.accounts.Owner': '擁有者',
    'panel.accounts.Positions': '# 部位',
    'panel.accounts.PowerOfAttorney': '授權書',
    'panel.accounts.Profit': '建倉總損益',
    'panel.accounts.ProfitNet': '建倉淨損益',
    'panel.accounts.Role': '基金類型',
    'panel.accounts.Spreads': '價差',
    'panel.accounts.Status': '狀態',
    'panel.accounts.Collateral': '#hidden#',
    'panel.accounts.StocksLiquidity': '股票流動性',
    'panel.accounts.StocksOrdersReq': '股票訂單要求',
    'panel.accounts.StocksValue': '股票價值',
    'panel.accounts.stopOutPercent': '止損離場水準',
    'panel.accounts.stopTradingPercent': '停止交易水準',
    'panel.accounts.Swap': '掉期',
    'panel.accounts.TelephoneNumber': '電話號碼',
    'panel.accounts.TodayFees': '本日的費用',
    'panel.accounts.TodayRebates': '本日的回扣',
    'panel.accounts.TodaySwaps': '#hidden#',
    'panel.accounts.TodayTrades': '# 本日的交易',
    'panel.accounts.TodayTurnover': '本日的成交額',
    'panel.accounts.TodayVolume': '成本的成交量',
    'panel.accounts.TotalMaxPositionsQty': '最大部位總數',
    'panel.accounts.TrailingDrawdownLevel': '跟蹤回撤水準',
    'panel.accounts.UnrealizedLoss': '未實現的損失',
    'panel.accounts.UnrealizedLossLimit': '未實現的損失限額',
    'panel.accounts.UnsettledCash': '未結算的現金',
    'panel.accounts.UserGroup': '使用者群組',
    'panel.accounts.UserID': '使用者 ID',
    'panel.accounts.UserLogin': '使用者登入',
    'panel.accounts.VolumeLimitForEquities': '股票成交量限額',
    'panel.accounts.VolumeLimitForFutures': '期貨成交量限額',
    'panel.accounts.VolumeLimitForOptions': '期權成交量限額',
    'panel.accounts.WithdrawalAvailable': '可用提款',
    'panel.accounts.WaivedMargin': '已放棄的保證金',
    'panel.accounts.WaivedMargin.descr': '帳戶的已放棄保證金',
    'panel.assetBalances': '帳戶',
    'panel.backoffice': '後台',
    'panel.backoffice.You need to add connection!': '您需要加入連線！',
    'panel.positionsBalance': '部位餘額',
    'panel.caption.NotAllowedMode': '規則集中不允許當前的面板',
    'panel.caption.showHideToolbar': '顯示工具列',
    'panel.chart': '圖表',
    'panel.close': '收盤',
    'panel.closePosition.Gross_P/L_ToClose': '待結帳的總損益',
    'panel.closePosition.QunatityToClose': '待結帳的數量',
    'panel.copypanel': '複製面板',
    'panel.detail': '詳細報價',
    'panel.detail.AuctionEndTime': '#hidden#',
    'panel.detail.CBAuctionStart': '#hidden#',
    'panel.detail.change': '變動',
    'panel.detail.changePr': '變動，%',
    'panel.detail.CurrentSession': '#hidden#',
    'panel.detail.high': '高',
    'panel.detail.last': '最後',
    'panel.detail.Limit.High': '#hidden#',
    'panel.detail.Limit.Low': '#hidden#',
    'panel.detail.low': '低',
    'panel.detail.NormalMarketSize': '#hidden#',
    'panel.detail.OffExchangeValue': '#hidden#',
    'panel.detail.OffExchangeVolume': '#hidden#',
    'panel.detail.open': '開盤',
    'panel.detail.RemainingQty': '#hidden#',
    'panel.detail.RemainingQtySide': '#hidden#',
    'panel.detail.TradedValue': '#hidden#',
    'panel.detail.TradingStatus': '#hidden#',
    'panel.detail.volume': '成交量',
    'panel.EventLog.Action': '事件',
    'panel.EventLog.Date': '日期',
    'panel.EventLog.eventType.alerts': '提醒',
    'panel.EventLog.eventType.comment': '註解',
    'panel.EventLog.eventType.disconnect': '中斷連線',
    'panel.EventLog.eventType.exception': '例外情況',
    'panel.EventLog.eventType.info': '資訊',
    'panel.EventLog.eventType.none': '無',
    'panel.EventLog.eventType.system': '系統',
    'panel.EventLog.eventType.trading': '交易',
    'panel.EventLog.eventType.updater': '更新器',
    'panel.EventLog.menu.Clear': '清除',
    'panel.EventLog.Message': '詳情',
    'panel.EventLog.NamePanel': '事件日誌',
    'panel.EventLog.Roundtrip': '來回行程，毫秒',
    'panel.EventLog.Type': '類型',
    'panel.GridPanel.listButton.ToolTip': '選取面板的股票代號',
    'panel.GridPanel.refreshAllCharts.ToolTip': '重新整理全部圖表',
    'panel.GridPanel.resetMI.Text': '重設面板大小',
    'panel.informer': '關注清單',
    'panel.instrumentInfo.AvgPrice': '平均價格：',
    'panel.instrumentInfo.P/L': '損益：',
    'panel.instrumentInfo.Qty': '數量：',
    'panel.instruments': '股票代號',
    'panel.instrumentsInfo': '股票代號資訊',
    'panel.knowledgebase': '知識庫',
    'panel.level2': '市場深度',
    'panel.Level2.Age': '#hidden#',
    'panel.level2.Auction': ' 拍賣',
    'panel.Level2.AvgPrice': '平均價格',
    'panel.Level2.Contr. CCY Total value': '控制貨幣總值',
    'panel.Level2.Contr. CCY value': '控制貨幣價值',
    'panel.Level2.DayTradeVolume': '成交量',
    'panel.Level2.menu.View': '檢視',
    'panel.Level2.menu.View.InstrInfo': '部位列',
    'panel.Level2.menu.View.Level1': '層級 1 列',
    'panel.Level2.menu.View.Order Entry': '訂單登錄',
    'panel.Level2.MPID': 'MPID',
    'panel.Level2.Number': '訂單',
    'panel.Level2.oe.BuyButton': '買入',
    'panel.Level2.oe.SellButton': '賣出',
    'panel.Level2.Price': '價格',
    'panel.Level2.Size': '規模',
    'panel.Level2.Source': '來源',
    'panel.Level2.Time': '時間',
    'panel.Level2.TotalVol': '總規模',
    'panel.log': '事件日誌',
    'panel.menu.GroupBy': '分組依據：',
    'panel.menu.new': '新',
    'panel.menu.Remove': '移除',
    'panel.menu.resetToDefault': '重設為預設值',
    'panel.menu.renameList': '點選以重新命名工具列表',
    'panel.menu.removeList': '點選以移除工具列表',
    'panel.menu.renameTemplate': '點選以重新命名範本',
    'panel.menu.removeTemplate': '點選以移除範本',
    'panel.menu.saveList': '點選以重新儲存範本',
    'panel.neworder': '訂單登錄',
    'panel.newOrderEntry.accountLabel': '帳戶',
    'panel.newOrderEntry.AddAskButton': '按要價賣出',
    'panel.newOrderEntry.AddBitButton': '按出價買入',
    'panel.newOrderEntry.amountLabel': '數量',
    'panel.newOrderEntry.BuyAskButton': '按要價買入',
    'panel.newOrderEntry.BuyMarketButton': '買方市場',
    'panel.newOrderEntry.buySideCheckBox': '買入',
    'panel.newOrderEntry.instrumentLabel': '股票代號',
    'panel.newOrderEntry.limitPricePanel': '限制價格',
    'panel.newOrderEntry.placeOrderButton': '下單',
    'panel.newOrderEntry.ProductType': '產品類型',
    'panel.newOrderEntry.RefreshMarginReq': '點選以重新整理保證金資料，或等待 30 秒',
    'panel.newOrderEntry.riskDetailsLabel': '保證金',
    'panel.newOrderEntry.SellBidButton': '按出價賣出',
    'panel.newOrderEntry.SellMarketButton': '賣方市場',
    'panel.newOrderEntry.sellSideCheckBox': '賣出',
    'panel.newOrderEntry.Side': '側',
    'panel.newOrderEntry.singleOrderRadio': '簡單',
    'panel.newOrderEntry.slPriceRisk': '止損價格',
    'panel.newOrderEntry.slPriceRiskOffset': '止損互抵',
    'panel.newOrderEntry.stopLossCheckBox': '止損?價格',
    'panel.newOrderEntry.stopLossCheckBox.offset': '止損?互抵',
    'panel.newOrderEntry.stopPricePanel': '止損價格',
    'panel.newOrderEntry.takeProfitCheckBox': '獲利?價格',
    'panel.newOrderEntry.takeProfitCheckBox.offset': '獲利?互抵',
    'panel.newOrderEntry.tifPanel': '委託時間有效期',
    'panel.newOrderEntry.tpPriceRisk': '獲利價格',
    'panel.newOrderEntry.tpPriceRiskOffset': '獲利互抵',
    'panel.newOrderEntry.trstopOffset': '跟蹤止損互抵',
    'panel.newOrderEntry.typePanel': '訂單類型',
    'panel.newOrderEntry.stopLossNumeric.ToolTip.offset': '在互抵值中設定止損。如要切換為價格，請轉至「一般設定」\\「交易」預設值',
    'panel.newOrderEntry.stopLossNumeric.ToolTip': '在價格值中設定止損。如要切換為互抵，請轉至「一般設定」\\「交易」預設值',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip.offset': '在互抵值中設定獲利。如要切換為價格，請轉至「一般設定」\\「交易」預設值',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip': '在價格值中設定獲利。如要切換為互抵，請轉至「一般設定」\\「交易」預設值',
    'panel.NewTrades.Account': '帳戶',
    'panel.NewTrades.Amount': '數量',
    'panel.NewTrades.Bought': '已買入',
    'panel.NewTrades.Commission': '執行費用',
    'panel.NewTrades.Commission.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Date': '日期/時間',
    'panel.NewTrades.Exchange': '#hidden#',
    'panel.NewTrades.ExpirationDate': '到期日',
    'panel.NewTrades.ExternalOrderId': '#hidden#',
    'panel.NewTrades.ExternalPrice': '外部價格',
    'panel.NewTrades.ExternalTradeId': '外部 ID',
    'panel.NewTrades.instrType': '股票代號類型',
    'panel.NewTrades.Instrument': '股票代號',
    'panel.NewTrades.Login': '登入',
    'panel.NewTrades.Net_PL': '淨損益',
    'panel.NewTrades.Net_PL.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Operation': '側',
    'panel.NewTrades.OrderId': '訂單 ID',
    'panel.NewTrades.OrderType': '訂單類型',
    'panel.NewTrades.Price': '價格',
    'panel.NewTrades.ProductType': '產品類型',
    'panel.NewTrades.PurchasePrice': '暴險',
    'panel.NewTrades.RealizedProfitLoss': '總損益',
    'panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Rebates': '回扣',
    'panel.NewTrades.Route': '路由',
    'panel.NewTrades.Sold': '已賣出',
    'panel.NewTrades.StrikePrice': '執行價格',
    'panel.NewTrades.Time': '時間',
    'panel.NewTrades.TradeID': '交易 ID',
    'panel.NewTrades.TradeIDLabel': '#hidden#',
    'panel.OrderBook.Account': '帳戶',
    'panel.OrderBook.Action': '側',
    'panel.OrderBook.Bought': '已買入',
    'panel.OrderBook.Date': '日期/時間',
    'panel.OrderBook.DislocedQty': '#hidden#',
    'panel.OrderBook.Event': '事件',
    'panel.OrderBook.ExpirationDate': '到期日',
    'panel.OrderBook.ExternalOrderID': '#hidden#',
    'panel.OrderBook.FilledQuantity': '#hidden#',
    'panel.OrderBook.instrType': '股票代號類型',
    'panel.OrderBook.Instrument': '股票代號',
    'panel.OrderBook.Login': '登入',
    'panel.OrderBook.Message': '訊息',
    'panel.OrderBook.OrderNumber': '訂單 ID',
    'panel.OrderBook.Price': '價格',
    'panel.OrderBook.ProductType': '產品類型',
    'panel.OrderBook.Quantity': '數量',
    'panel.OrderBook.ReminingQuantity': '#hidden#',
    'panel.OrderBook.RemoteID': '遠端 ID',
    'panel.OrderBook.Route': '路由',
    'panel.OrderBook.Sold': '已賣出',
    'panel.OrderBook.StopPrice': '止損價格',
    'panel.OrderBook.StrikePrice': '執行價格',
    'panel.OrderBook.TIF': '委託時間有效期',
    'panel.OrderBook.Time': '時間',
    'panel.OrderBook.TraderID': '已由使用者修改',
    'panel.OrderBook.Type': '類型',
    'panel.orderEntry': '訂單登錄',
    'panel.orderHistory': '訂單歷程記錄',
    'panel.orderHistory.print': '#hidden#',
    'panel.orders': '待執行訂單',
    'panel.orders.Account': '帳戶',
    'panel.orders.action': '側',
    'panel.orders.AvgFilledPrice': '#hidden#',
    'panel.orders.BindTo': '繫結',
    'panel.orders.Cancel': '取消',
    'panel.orders.Comment': '#hidden#',
    'panel.orders.Comments': '註解',
    'panel.orders.Ct_Ahead': '向前計數',
    'panel.orders.CurrentPrice': '當前價格',
    'panel.orders.date': '日期',
    'panel.orders.date_time': '日期/時間',
    'panel.orders.disclosedQty': '#hidden#',
    'panel.orders.Event': '事件',
    'panel.orders.Exchange': '交易所',
    'panel.orders.ExpirationDate': '到期日',
    'panel.orders.ExternalOrderId': '#hidden#',
    'panel.orders.ExternalOrderStatus': '#hidden#',
    'panel.orders.GroupId': '多帳戶管理群組 ID',
    'panel.orders.InitReq': '初始要求',
    'panel.orders.Instr_Type': '股票代號類型',
    'panel.orders.Login': '登入',
    'panel.orders.Market': '市價',
    'panel.orders.menu.CancelAllByInstrument': '按選定的股票代號取消全部',
    'panel.orders.menu.CancelAllForAccount': '為選定的帳戶取消全部',
    'panel.orders.menu.CancelAllOrders': '取消全部訂單',
    'panel.orders.menu.CancelOrder': '取消訂單',
    'panel.orders.menu.ExecuteAsMarket': '市場變動',
    'panel.orders.menu.ExecuteAsMarket.question': '確定要按市價執行訂單嗎？',
    'panel.orders.menu.ModifyOrder': '修改訂單...',
    'panel.orders.menu.Remove': '取消訂單',
    'panel.orders.OCOGrouID': '多帳戶管理群組 ID',
    'panel.orders.price': '價格',
    'panel.orders.ProductType': '產品類型',
    'panel.orders.quantity': '數量',
    'panel.orders.QuantityFilled': '滿足的數量',
    'panel.orders.QuantityRemaining': '剩餘的數量',
    'panel.orders.Reference': '訂單 ID',
    'panel.orders.RemoteID': '遠端 ID',
    'panel.orders.Route': '路由',
    'panel.orders.SL': '止損',
    'panel.orders.SL_Offset': '止損互抵',
    'panel.orders.SL_Price': '止損價格',
    'panel.orders.Status': '狀態',
    'panel.orders.StopPrice': '止損價格',
    'panel.orders.StrikePrice': '執行價格',
    'panel.orders.symbol': '股票代號',
    'panel.orders.symbolDescription': '股票代號描述',
    'panel.orders.TIF': '委託時間有效期',
    'panel.orders.time': '時間',
    'panel.orders.Token': '訂單 ID',
    'panel.orders.TP': '獲利',
    'panel.orders.TP_Offset': '獲利互抵',
    'panel.orders.TP_Price': '獲利價格',
    'panel.orders.TraderID': '交易員 ID',
    'panel.orders.type': '類型',
    'panel.orders.UserGroup': '使用者群組',
    'panel.positions': '部位',
    'panel.positions.Account': '帳戶',
    'panel.positions.AvailableForSell': '#hidden#',
    'panel.positions.Basis': '開盤價',
    'panel.positions.Close': '平倉',
    'panel.positions.comments': '註解',
    'panel.positions.commissions': '費用',
    'panel.positions.commissions.InstrumentCurrency': '#hidden#',
    'panel.positions.confirmation.Reverse': '沖銷',
    'panel.positions.confirmation.ReverseAllPositions': '確定要沖銷所有部位嗎？',
    'panel.positions.confirmation.ReverseByInstrument': '確定要以選定的股票代號和帳戶沖銷所有部位嗎？',
    'panel.positions.confirmation.ReverseSelectedPositions': '確定要沖銷選定的部位嗎？',
    'panel.positions.cur_price': '當前價格',
    'panel.positions.date_time': '日期/時間',
    'panel.positions.Delta': 'Delta',
    'panel.positions.ExpDate': '到期日',
    'panel.positions.exposition_usd': '部位暴險',
    'panel.positions.Gamma': 'Gamma',
    'panel.positions.GroupId': '多帳戶管理群組 ID',
    'panel.positions.InitReq': '初始要求',
    'panel.positions.IV': 'IV',
    'panel.positions.Login': '登入',
    'panel.positions.MaintReq': '維持要求',
    'panel.positions.MarginSuplus': '保證金盈餘/不足',
    'panel.positions.menu.Close': '平倉',
    'panel.positions.menu.Modify': '修改部位',
    'panel.positions.menu.ShowToolbar': '顯示工具列',
    'panel.positions.menu.ShowTotals': '顯示總計',
    'panel.positions.menu.DisplayTrades': '顯示交易',
    'panel.positions.menu.View': '檢視',
    'panel.positions.Net_PL': '淨損益',
    'panel.positions.Net_PL.InstrumentCurrency': '#hidden#',
    'panel.positions.Operation': '側',
    'panel.positions.PL_ticks': '損益，互抵',
    'panel.positions.PL_ticks.points': '點',
    'panel.positions.PL_ticks.ticks': '跳動點',
    'panel.positions.pos_number': '部位 ID',
    'panel.positions.PositionValue': '部位價值',
    'panel.positions.ProductType': '產品類型',
    'panel.positions.profit_usd': '總損益',
    'panel.positions.profit_usd.InstrumentCurrency': '#hidden#',
    'panel.positions.Qunatity': '數量',
    'panel.positions.RealizedPnL': '#hidden#',
    'panel.positions.RealizedPnL.InstrumentCurrency': '#hidden#',
    'panel.positions.Rho': 'Rho',
    'panel.positions.route': '路由',
    'panel.positions.SL': '止損價格',
    'panel.positions.SL_Value': '止損，價值',
    'panel.positions.strike': '執行價格',
    'panel.positions.swaps': '掉期',
    'panel.positions.Symbol': '股票代號',
    'panel.positions.SymbolDescription': '股票代號描述',
    'panel.positions.Theta': 'Theta',
    'panel.positions.TP': '獲利價格',
    'panel.positions.type': '股票代號類型',
    'panel.positions.UsedMargin': '已用的保證金',
    'panel.positions.UserGroup': '使用者群組',
    'panel.positions.Vega': '織女星',
    'panel.settings.Information': '資訊',
    'panel.settings.saveDefault': '儲存成功！',
    'panel.statement': '報表',
    'panel.statement.print': '不隱藏',
    'panel.statement.Account': '帳戶',
    'panel.statement.Instrument': '工具名稱',
    'panel.statement.Date': '日期',
    'panel.statement.Operation_type': '操作類型',
    'panel.statement.Operation_ID': '操作 ID',
    'panel.statement.Amount': '金額',
    'panel.statement.Open_price': '開盤價',
    'panel.statement.Close_price': '收盤價',
    'panel.statement.Quantity': '數量',
    'panel.statement.Currency': '貨幣',
    'panel.statement.Trade_opening_date': '交易開放日期',
    'panel.statement.FX_rate': '匯率',
    'panel.statement.searchBtn.tt': '點選「搜尋」，以檢視「報表」面板中的資訊',
    'panel.statement.noData': '選定的時段和篩選器沒有帳戶歷程記錄。',
    'panel.terceraChart': '圖表',
    'panel.TerceraSymbolLookupDropDownForm.Add': '加入',
    'panel.TerceraSymbolLookupDropDownForm.All': '全部',
    'panel.TerceraSymbolLookupDropDownForm.Bonds': '債券',
    'panel.TerceraSymbolLookupDropDownForm.Cancel': '取消',
    'panel.TerceraSymbolLookupDropDownForm.CFDs': '價差契約',
    'panel.TerceraSymbolLookupDropDownForm.collapceAllButton': '折疊全部',
    'panel.TerceraSymbolLookupDropDownForm.crypto': '加密貨幣',
    'panel.TerceraSymbolLookupDropDownForm.Equities': '股票',
    'panel.TerceraSymbolLookupDropDownForm.ETFs': '指數股票型基金',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges': '全部交易所',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges': '交易所',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges': '沒有交易所',
    'panel.TerceraSymbolLookupDropDownForm.expandExchangesButton': '展開交易所',
    'panel.TerceraSymbolLookupDropDownForm.expandTypesButton': '展開類型',
    'panel.TerceraSymbolLookupDropDownForm.expandGroupsButton': '展開群組',
    'panel.TerceraSymbolLookupDropDownForm.Forex': '外匯',
    'panel.TerceraSymbolLookupDropDownForm.Forward': '遠期契約',
    'panel.TerceraSymbolLookupDropDownForm.Futures': '期貨',
    'panel.TerceraSymbolLookupDropDownForm.indices': '指數',
    'panel.TerceraSymbolLookupDropDownForm.Options': '期權',
    'panel.TerceraSymbolLookupDropDownForm.Portfolio': '合成',
    'panel.TerceraSymbolLookupDropDownForm.Select': '選取',
    'panel.TerceraSymbolLookupDropDownForm.Select all': '選取全部',
    'panel.TerceraSymbolLookupDropDownForm.Show additionl info': '顯示額外資訊',
    'panel.TerceraSymbolLookupDropDownForm.Spot': '即期',
    'panel.TerceraSymbolLookupDropDownForm.Spreadbet': '價差交易',
    'panel.TerceraSymbolLookupDropDownForm.TBill': '國庫券',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types': '全部類型',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types': '沒有類型',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.types': '類型',
    'panel.timeSales': '時間與銷售',
    'panel.timeSales.Agressor flag': '側',
    'panel.timeSales.AgressorFlag.Auction': '拍賣',
    'panel.timeSales.AgressorFlag.Buy': '買入',
    'panel.timeSales.AgressorFlag.CrossTrade': '交叉交易',
    'panel.timeSales.AgressorFlag.None': '無',
    'panel.timeSales.AgressorFlag.Sell': '賣出',
    'panel.timeSales.AgressorFlag.RLP': 'RLP',
    'panel.timeSales.ask': '要價',
    'panel.timeSales.AskBidSize': '買賣價差規模',
    'panel.timeSales.AskSource': '要價 MPID',
    'panel.timeSales.bid': '出價',
    'panel.timeSales.BidSource': '出價 MPID',
    'panel.timeSales.BuyerSource': '買方',
    'panel.timeSales.Exchange': '來源',
    'panel.timeSales.Instrument': '股票代號',
    'panel.timeSales.MenuName': '時間與銷售',
    'panel.timeSales.Price': '價格',
    'panel.timeSales.SellerSource': '賣方',
    'panel.timeSales.Size': '規模',
    'panel.timeSales.Time': '時間',
    'panel.timeSales.Type': '股票代號類型',
    'panel.tooltiper.System': '系統',
    'panel.trades': '已滿足的訂單',
    'panel.trades.print': '#hidden#',
    'panel.tradingCentral': 'Trading Central',
    'panel.tradingCentral.screenBtn.text': '螢幕',
    'panel.tradingCentral.chartBtn.text': '圖表',
    'panel.tradingCentral.ScreenButton': '不隱藏',
    'panel.tradingCentral.settings': '設定...',
    'panel.tradingCentral.settings.technicalAnalysis.term': '期限',
    'panel.tradingCentral.settings.technicalAnalysis.days': '天',
    'panel.tradingCentral.settings.technicalAnalysis.lastOnly': '僅最後',
    'panel.tradingCentral.settings.alerts.lastOnly': '僅最後',
    'panel.tradingCentral.settings.alerts.days': '天',
    'panel.tradingCentral.settings.candlestick.lastOnly': '僅最後',
    'panel.tradingCentral.settings.candlestick.days': '天',
    'panel.tradingCentral.term.All': '全部',
    'panel.tradingCentral.term.Intraday': '盤中',
    'panel.tradingCentral.term.Short term': '短期',
    'panel.tradingCentral.term.Middle term': '中期',
    'panel.watchlist': '關注清單',
    'panel.watchlist.Ask': '要價',
    'panel.watchlist.AskSize': '要價規模',
    'panel.watchlist.AuctionEndTime': '#hidden#',
    'panel.watchlist.Bid': '出價',
    'panel.watchlist.BidSize': '出價規模',
    'panel.watchlist.Buy': '買入',
    'panel.watchlist.CBAuctionStart': '#hidden#',
    'panel.watchlist.cell.cancel': '取消',
    'panel.watchlist.cell.modify': '修改',
    'panel.watchlist.cell.Not allow': '不允許',
    'panel.watchlist.cell.sending': '新',
    'panel.watchlist.cell.ticks': '跳動點',
    'panel.watchlist.Change': '變動',
    'panel.watchlist.ChangeFromOpen': '較開盤價的變動',
    'panel.watchlist.ChangeFromOpen%': '較開盤價的變動，%',
    'panel.watchlist.ChangePr': '變動，%',
    'panel.watchlist.Close': '收盤',
    'panel.watchlist.ClosePostMarket': '盤後平倉',
    'panel.watchlist.Comment': '註解',
    'panel.watchlist.Company': '公司',
    'panel.watchlist.ContractSize': '每口大小',
    'panel.watchlist.CurrentSession': '當前時段',
    'panel.watchlist.Date': '最後日期',
    'panel.watchlist.Descr': '描述',
    'panel.watchlist.Exchange': '交易所',
    'panel.watchlist.ExpDate': '到期日',
    'panel.watchlist.High': '高',
    'panel.watchlist.High13': '#hidden#',
    'panel.watchlist.High26': '#hidden#',
    'panel.watchlist.High52': '#hidden#',
    'panel.watchlist.IndicativeAuctionPrice': '指示性拍賣價格',
    'panel.watchlist.Instrument': '股票代號',
    'panel.watchlist.Last': '最後',
    'panel.watchlist.LastSize': '最後規模',
    'panel.watchlist.Limit.High': '#hidden#',
    'panel.watchlist.Limit.Low': '#hidden#',
    'panel.watchlist.Low': '低',
    'panel.watchlist.Low13': '#hidden#',
    'panel.watchlist.Low26': '#hidden#',
    'panel.watchlist.Low52': '#hidden#',
    'panel.watchlist.Main Volume': '主成交量',
    'panel.watchlist.Market': '市價',
    'panel.watchlist.MarketCap': '市值',
    'panel.watchlist.Match Volume': '匹配成交量',
    'panel.watchlist.menu.AddSymbol': '加入股票代號...',
    'panel.watchlist.menu.clearAll': '清除全部',
    'panel.watchlist.menu.SymbolLists': '股票代號列表',
    'panel.watchlist.menu.RemoveInstrument': '移除選定的股票代號',
    'panel.watchlist.menu.SymbolInfo': '股票代號資訊',
    'panel.watchlist.menu.Chart': '#hidden#',
    'panel.watchlist.menu.MarketDepth': '#hidden#',
    'panel.watchlist.menu.TimeSales': '#hidden#',
    'panel.watchlist.menu.OrderEntry': '#hidden#',
    'panel.watchlist.NA': '不適用',
    'panel.watchlist.noQuotes': '最後更新',
    'panel.watchlist.NormalMarketSize': '#hidden#',
    'panel.watchlist.OffExchangeValue': '#hidden#',
    'panel.watchlist.OffExchangeVolume': '#hidden#',
    'panel.watchlist.Open': '開盤',
    'panel.watchlist.OpenInterest': '#hidden#',
    'panel.watchlist.OpenPreMarket': '盤前建倉',
    'panel.watchlist.Order': '#hidden#',
    'panel.watchlist.Order.tt': '下達限價單',
    'panel.watchlist.Trade': '#hidden#',
    'panel.watchlist.Trade.tt': '下達市價單',
    'panel.watchlist.ITChartAdvanced': '進階圖表',
    'panel.watchlist.ITChartAdvanced.tt': '按資訊科技-金融劃分的進階圖表',
    'panel.watchlist.PreMarketChange': '盤前變動',
    'panel.watchlist.PreMarketChange%': '盤前變動，%',
    'panel.watchlist.PrevClose': '上一收盤價',
    'panel.watchlist.PrevSettlementPrice': '上一結算價格',
    'panel.watchlist.ReferencePrice': '參考價格',
    'panel.watchlist.RemainingQty': '#hidden#',
    'panel.watchlist.RemainingQtySide': '#hidden#',
    'panel.watchlist.Sell': '賣出',
    'panel.watchlist.SettlementPrice': '結算價格',
    'panel.watchlist.Spread': '價差',
    'panel.watchlist.SpreadPr': '價差，%',
    'panel.watchlist.Ticks': '跳動點',
    'panel.watchlist.TicksPostMarket': '盤後跳動點',
    'panel.watchlist.TicksPreMarket': '盤前跳動點',
    'panel.watchlist.Time': '上次',
    'panel.watchlist.TradedValue': '#hidden#',
    'panel.watchlist.Type': '股票代號類型',
    'panel.watchlist.Volume': '成交量',
    'panel.watchlist.VolumePostMarket': '盤後成交量',
    'panel.watchlist.VolumePreMarket': '盤前成交量',
    'Panels.MarketDepth.CancelAll': '取消全部',
    'panels.orders.buttons.AsMarket': '市場變動',
    'panels.orders.buttons.CancelAllButton': '取消全部',
    'panels.orders.buttons.CancelBuyButton': '取消買入',
    'panels.orders.buttons.CancelByInstrumentButton': '取消',
    'panels.orders.buttons.CancelDayButton': '取消日',
    'panels.orders.buttons.CancelGTCButton': '取消取消前有效',
    'panels.orders.buttons.CancelLimitsButton': '取消限價',
    'panels.orders.buttons.CancelSelectedButton': '取消選定',
    'panels.orders.buttons.CancelSellButton': '取消賣出',
    'panels.orders.buttons.CancelStopsButton': '取消止損',
    'panels.orders.buttons.ModifyOrder': '修改訂單',
    'panels.positions.buttons.closeAll': '全部平倉',
    'panels.positions.buttons.closeForInstrument': '收盤',
    'panels.positions.buttons.closeLong': '了結長',
    'panels.positions.buttons.closeNegative': '了結負',
    'panels.positions.buttons.closePositive': '了結正',
    'panels.positions.buttons.closeSelected': '了結選定',
    'panels.positions.buttons.closeShort': '了結短',
    'panels.positions.buttons.clxAll': '清算全部',
    'panels.positions.buttons.clxForInstrument': 'CLX',
    'panels.positions.ButtonsPanel.helpLabel': '右鍵點選以顯示/隱藏按鈕',
    'panel.rangeSelect.daily': '每日',
    'panel.rangeSelect.24h': '#hidden#',
    'panel.rangeSelect.7d': '#hidden#',
    'panel.rangeSelect.30d': '#hidden#',
    'panel.rangeSelect.90d': '#hidden#',
    'panel.rangeSelect.range': '範圍',
    'panel.rangeSelect.tooltip': '選取顯示期間',
    'PanelSettingsScreen.Text': '- 設定',
    'PanelSettingsScreen.TextSmall': '- 設定',
    'Parabolic Time/Price System': '拋物線時間/價格系統',
    'period.Day': '天',
    'period.Days': '天',
    'period.Hour': '小時',
    'period.Hours': '小時',
    'period.Min': '分鐘',
    'period.Mins': '分鐘',
    'period.Month': '月',
    'period.Months': '月',
    'period.Week': '週',
    'period.Weeks': '週',
    'period.Year': '年',
    'period.Years': '年',
    'Pivot Point Moving Average': '樞軸點移動平均線',
    PivotPoint: '樞軸點',
    'PopupMultiCkeckBox.All': '全部',
    Português: '葡萄牙語（英國）',
    'Price Channel': '價格通道',
    'Price Oscillator': '價格擺動指標',
    'ProductType.CarryForward': '遞轉',
    'ProductType.Delivery': '交割',
    'ProductType.General': '一般',
    'ProductType.Intraday': '盤中',
    'property.%': '%',
    'property.Additional': '額外',
    'property.AllPrice': '價格統計資料',
    'property.appearance': '外觀',
    'property.Ask': '要價',
    'property.Author': '作者',
    'property.autosize': '自動調整欄的大小',
    'property.autoYScale.Auto': '自動縮放',
    'property.autoYScale.Manual': '手動縮放',
    'property.Average': '平均值',
    'property.Backgr': '背面顏色',
    'property.Backgr. top': '背面頂部顏色',
    'property.BarPattern': '長條圖形態',
    'property.barsHiLowColor': '線',
    'property.BarStatistics': '長條圖統計資料',
    'property.Bid': '出價',
    'property.bodyColor': '燭體',
    'property.borderColor': '邊界',
    'property.BottomCenter': '底部居中',
    'property.BottomLeft': '底部居左',
    'property.BottomRight': '底部居右',
    'property.Browser': '瀏覽器',
    'property.Buy': '買入',
    'property.BuyColor': '買入',
    'property.cAsk': '要價規模',
    'property.cASK': '按要價',
    'property.cASKHI': '高於要價',
    'property.cBid': '出價規模',
    'property.cBID': '按出價',
    'property.cBIDLO': '低於出價',
    'property.cellscin.useSellBuy': '反轉按鈕順序',
    'property.Change': '漲跌',
    'property.ChangePercent': '漲跌%',
    'property.Chart': '圖表',
    'property.Chart OE': '圖表訂單登錄',
    'property.chart.isBackground': '移至背景',
    'property.chart.line': '線',
    'property.chart.ray': 'RAY',
    'property.chart.rayStyle': '射線樣式',
    'property.chart.showAngle': '顯示角度',
    'property.chart.showingOE': '可見',
    'property.chart.stickTo': '遵守',
    'property.chart.tools.back': '填充繪圖',
    'property.chart.tools.fibretr.font': '標籤字型',
    'property.chart.tools.fibretr.fontcolor': '字型顏色',
    'property.ChartAnalyseToolsToolBar': '分析工具列',
    'property.ChartDrawingToolsToolBar': '繪圖工具列',
    'property.cLimit': '限價單',
    'property.Close': '收盤',
    'property.Cluster.ClusterUpDownColors': '叢集',
    'property.Cluster.ColorScheme': '配色方案',
    'property.Cluster.ColorScheme.ByDelta': '按 delta 值',
    'property.Cluster.ColorScheme.ByDelta.Colors': '顏色',
    'property.Cluster.ColorScheme.ByDelta.Colors.BuyColor': '買入',
    'property.Cluster.ColorScheme.ByDelta.Colors.SellColor': '賣出',
    'property.Cluster.ColorScheme.ByTrades': '按交易',
    'property.Cluster.ColorScheme.ByVolume': '按成交量',
    'property.Cluster.ColorScheme.Color': '顏色',
    'property.Cluster.ColorScheme.None': '無',
    'property.Cluster.CustomStep': '自訂步長',
    'property.Cluster.Down': '向下',
    'property.Cluster.FilterValue': '篩選器值',
    'property.Cluster.Font': '字型',
    'property.Cluster.FontColor': '字型顏色',
    'property.Cluster.HighlightMax': '亮顯最大值',
    'property.Cluster.HighlightMax.Colors': '顏色',
    'property.Cluster.Operator': '操作者',
    'property.Cluster.PriceStep': '價格步長',
    'property.Cluster.PriceStep.Custom': '自訂',
    'property.Cluster.PriceStep.MinTick': '最小跳動點',
    'property.Cluster.ShowOnlySimilarDelta': '僅顯示類似的 delta 值',
    'property.Cluster.ShowValueFilter': '值篩選器',
    'property.Cluster.Up': '向上',
    'property.cMIDLO': '價差之間',
    'property.Color': '顏色',
    'property.ColoringMode': '配色模式',
    'property.Colors': '顏色',
    'property.colors_param': '顏色',
    'property.colorScheme': '樣式',
    'property.ColorsSeparatorGroup': '顏色',
    'property.ColouringMode': '配色模式',
    'property.Comments': '註解',
    'property.Company': '公司',
    'property.Confirmations': '確認',
    'property.connection': '連線',
    'property.Connection': '連線',
    'property.ContentAlignment': '對齊',
    'property.Copyrights': '版權',
    'property.cQUOTE': '報價',
    'property.CreateColor': '建立',
    'property.crossHairFontColor': '字型顏色',
    'property.cStop': '止損單',
    'property.currency': '貨幣',
    'property.CustomYMarkingValue': '最少網格步長',
    'property.Data Box': '資訊視窗',
    'property.Data Style': '資料樣式',
    'property.Day': '天',
    'property.DaySeparatorsVisibility': '日分隔符號',
    'property.DefaultProductType': '預設產品類型',
    'property.Defaults': '交易預設值',
    'property.deviation': '偏差',
    'property.dK': 'dK',
    'property.Doji': '十字線',
    'property.Downband deviation in %': '以百分比表示的下段偏差',
    'property.email': '電子郵件地址\n（用逗號或分號隔開）',
    'property.Emulator': '模擬器',
    'property.enable': '啟用',
    'property.Enable Sounds': '啟用聲音',
    'property.Exponential': '指數',
    'property.fib.Arc': '斐波那契弧形',
    'property.Fibonacci': '斐波那契',
    'property.Filters': '篩選器',
    'property.FitDrawings': '適配繪圖',
    'property.FitHighLow': '適配日高位/低位',
    'property.FitIndicator': '適配指標',
    'property.FitOrders': '適配訂單/部位',
    'property.FOK': 'FOK',
    'property.Font': '字型',
    'property.Forex': '外匯',
    'property.Forwards': '遠期契約',
    'property.Futures': '期貨',
    'property.FX Cell': '外匯欄框',
    'property.G': 'G',
    'property.General': '一般',
    'property.General.ToolTip': '一般設定',
    'property.general.defaultSymbol': '股票代號',
    'property.general.OvernightMarginNotificationMessage': '#hidden#',
    'property.general.showLotsFX': '顯示口中的數量',
    'property.gr_type': '樣式',
    'property.Gradient': '梯度',
    'property.GraphicType.Absolute': '絕對',
    'property.GraphicType.Log': '對數',
    'property.GraphicType.Relative': '相對',
    'property.GridPrice': '價格（水平）',
    'property.GridPriceHighLight': '亮顯網格顏色',
    'property.GridTime': '時間（垂直）',
    'property.GTC': 'GTC',
    'property.GTD': 'GTD',
    'property.HeaderFont': '字型',
    'property.HeaderText': '標頭文字',
    'property.High': '高',
    'property.HighlightMarkingsStep': '亮顯網格步長',
    'property.HistogramLineColor': '直方圖線',
    'property.HistoryType': '資料類型',
    'property.HorizontalLine.LeftRay': '延伸左側',
    'property.hostName': '主機名稱',
    'property.hotkeyManager.ShowVolume': '顯示成交量',
    'property.infowindow_font': '字型',
    'property.infoWindow_fore_back_color': '顏色',
    'property.infowindow_visible': '顯示資訊視窗',
    'property.Instrument Types': '按類型劃分的訂單預設值',
    'property.Intrinsic': '內建',
    'property.IOC': 'IOC',
    'property.IsBackground': '是背景',
    'property.IsBarsVisible': '#長條圖',
    'property.IsChangeVisible': '變動，%',
    'property.IsCloseVisible': '收盤',
    'property.IsDateVisible': '日期',
    'property.IsHighVisible': '高',
    'property.IsLowVisible': '低',
    'property.IsOpenVisible': '開盤',
    'property.IsRoundedAvgPrice': '四捨五入的平均開盤價',
    'property.isShowToolTips': '工具提示',
    'property.isShowToolTipsGrop': '工具提示',
    'property.ConfirmationSubgroup.Other': '其他',
    'property.dealTickets.openOnWorkspace': '在工作區打開交易票證',
    'property.IsSyncActive': '與圖表同步',
    'property.IsTicksVisible': '跳動點',
    'property.IsTimeVisible': '時間',
    'property.IsVolumeVisible': '成交量',
    'property.LabelColors': '標籤顏色',
    'property.Language': '語言',
    'property.Last': '最後',
    'property.lastPrice_AskIndicatorColor': '要價',
    'property.lastPrice_BidIndicatorColor': '出價',
    'property.lastPrice_LastIndicatorColor': '最後',
    'property.lastPrice_SpreadIndicatorColor': '價差區域顏色',
    'property.Left ray': '延伸左側',
    'property.Level': '水準',
    'property.Limit': '限價',
    'property.Line': '線',
    'property.line.lineName': '行名稱',
    'property.line.plateVisible': '顯示行 {0} 標記',
    'property.line.timeShift': '時間遷移',
    'property.line.value': '值',
    'property.line.Visible': '可見',
    'property.line.yFactor': 'Y 因素',
    'property.Linear Weighted': '線性加權',
    'property.lineProperties': '屬性',
    'property.LOC': 'LOC',
    'property.logBase': '對數底數',
    'property.LOO': 'LOO',
    'property.LotsMode': '在以下項目中顯示規模：',
    'property.Low': '低',
    'property.Mail Server': '郵件伺服器',
    'property.Manual': '手動',
    'property.Market': '市價',
    'property.MarketCap': '市值',
    'property.MarketDepth.ColoringMethod': '配色方法',
    'property.MarketProfile.ColorScheme': '配色方案',
    'property.MarketProfile.ColorScheme.Color': '顏色',
    'property.MarketProfile.ColorScheme.GradientColor': '區域',
    'property.MarketProfile.ColorScheme.PairColor.Color': '顏色',
    'property.MarketProfile.ColorScheme.VolumeColor': '顏色',
    'property.MarketProfile.Font': '字型',
    'property.MarketProfile.PointOfControl': '控制點',
    'property.MarketProfile.PointOfControlStyle': '控制點樣式',
    'property.MarketProfile.ShowSingles': '顯示單獨',
    'property.MarketProfile.SinglesStyle': '單獨樣式',
    'property.MarketProfile.ValueArea': '值區域',
    'property.MarketProfile.ValueAreaStyleHorisontal': '水平線樣式',
    'property.MarketProfile.ValueAreaStyleVertical': '垂直線樣式',
    'property.MarketProfile.ValuePercents': '值，%',
    'property.Medium': '中等',
    'property.message': '訊息',
    'property.method': '方法',
    'property.MirrorShow': '鏡像檢視',
    'property.MOC': 'MOC',
    'property.Mode': '模式',
    'property.Mode.Trades': '交易',
    'property.Modified': '已修改',
    'property.MonthSeparatorsVisibility': '月分隔符號',
    'property.MOO': 'MOO',
    'property.name': '名稱',
    'property.Name': '名稱',
    'property.News': '新聞',
    'property.None': '無',
    'property.Note': '附註',
    'property.NotSet': 'NotSet',
    'property.Number': '計數',
    'property.OCO': 'OCO',
    'property.OE_AddBidAddAsk': '按出價買入/按要價賣出',
    'property.OE_ShowBuyMarketSellMarket': '買方市場/賣方市場',
    'property.OE_ShowSellBidBuyASk': '按出價賣出/按要價買入',
    'property.OE_ShowSLTP': '收盤訂單（止損/獲利）',
    'property.OE_Small': '縮小訂單登錄',
    'property.OE_Small.ToolTip': '如果勾選，訂單登錄將顯示縮小檢視',
    'property.oe.confirmClosePosition': '確認平倉',
    'property.oe.confirmOrderCancel': '確認取消訂單',
    'property.oe.confirmOrderChange': '確認修改訂單/部位',
    'property.oe.confirmOrderCreate': '確認下單',
    'property.oe.confirmReversePosition': '確認沖銷部位',
    'property.OffsetMode': '在以下項目中顯示互抵：',
    'property.OffsetMode.Points': '點',
    'property.OffsetMode.Ticks': '跳動點',
    'property.OffsetMode.TicksFractionalForForex': '跳動點（外匯的分數跳動點）',
    'property.OnClose': '收盤時',
    'property.OnOpen': '開盤時',
    'property.Open': '開盤',
    'property.Order Entry': '訂單登錄',
    'property.OrderBuyStyleWidth': '買入',
    'property.OrderSellStyleWidth': '賣出',
    'property.OverlayVisible': '可見覆蓋',
    'property.Panel name': '面板名稱',
    'property.panel.accountDetails.Groups.1.General': '一般',
    'property.panel.accountDetails.Groups.2.Margin': '保證金',
    'property.panel.accountDetails.Groups.3.AccountActivity': '帳戶活動',
    'property.panel.accountDetails.Groups.4.TodayResults': '本日業績',
    'property.panel.accountDetails.Groups.5.RiskManagement': '風險管理',
    'property.panel.accountDetails.Groups.6.Info': '資訊',
    'property.Panels': '面板',
    'property.Parameters': '參數',
    'property.password': '密碼',
    'property.period': '期間',
    'property.Period of indicator': '指標期間',
    'property.Period of indicator:': '指標期間',
    'property.Period of Linear Regression': '線性回歸期間',
    'property.Period of MA for envelopes': '移動平均線圈期間',
    'property.Period of MA1': '移動平均線 1 期間',
    'property.Period of MA2': '移動平均線 2 期間',
    'property.Period of momentum:': '動量期間',
    'property.Period of Moving Average': '移動平均線期間',
    'property.Period of price channel': '價格通道期間',
    'property.Period of Simple Moving Average': '簡單移動平均線期間',
    'property.Period of Smoothed Moving Average': '平滑移動平均線期間',
    'property.Periods': '期間',
    'property.pin': 'PIN 碼',
    'property.pips': '種子',
    'property.POCTypes.Line': '線',
    'property.POCTypes.Marker': '標記',
    'property.POCTypes.None': '無',
    'property.points': '點',
    'property.Points': '點',
    'property.PositionBuyStyleWidth': '長',
    'property.PositionSellStyleWidth': '短',
    'property.Previous': '上一',
    'property.price': '價格',
    'property.Price Scale': '價格標尺',
    'property.PriceIndicatorDrawingType': '價格指標',
    'property.PriceIndicatorDrawingType.None': '無',
    'property.PriceIndicatorDrawingType.ScaleMarker': '標度標記',
    'property.PriceIndicatorDrawingType.ScaleMarkerWithLine': '帶線的標度標記',
    'property.PriceScaleAxisColor': '軸',
    'property.PriceScaleBackColor': '軸背景',
    'property.PriceScaleFont': '字型',
    'property.PriceScaleTextColor': '文字顏色',
    'property.PriceType': '價格類型',
    'property.ProfileColoringTypes.DeltaBars': 'Delta 長條圖',
    'property.ProfileColoringTypes.DeltaPrice': 'Delta 價格',
    'property.ProfileColoringTypes.DeltaProfile': 'Delta 設定檔',
    'property.ProfileColoringTypes.Single': '單色',
    'property.ProfileColoringTypes.UpdownBars': '上/下長條圖',
    'property.ProfileColoringTypes.UpdownProfile': '上/下設定檔',
    'property.ProfileColoringTypes.VolumeBars': '成交量長條圖',
    'property.ProfileColoringTypes.VolumePrice': '成交量價格',
    'property.protrader.showOrders': '訂單',
    'property.Range': '範圍',
    'property.Ray style': '射線樣式',
    'property.Relative': '相對',
    'property.relativeCalcType': '開始價格',
    'property.RelativeCalculationType.BeginOfTheData': '資料的開始',
    'property.RelativeCalculationType.BeginOfTheDay': '當日的開始',
    'property.RelativeCalculationType.BeginOfTheScreen': '螢幕的開始',
    'property.relativeScaleType': '標度類型',
    'property.Reserve': '保留',
    'property.Reverse': '沖銷',
    'property.Right ray': '延伸右側',
    'property.routing.account': '帳戶',
    'property.routing.orderType': '訂單類型',
    'property.RSI Method:': '相對強弱指數法',
    'property.RSI Period:': '相對強弱指數期間',
    'property.SCRIPTS_SUBGROUP': '指令碼',
    'property.ScrollBar': '捲軸',
    'property.secure': '安全',
    'property.Secure': '安全',
    'property.Selection': '選取',
    'property.Sell': '賣出',
    'property.SellColor': '賣出',
    'property.SeparatorGroup.ActiveOrders': '待執行訂單',
    'property.SeparatorGroup.additional': '額外',
    'property.SeparatorGroup.Additional': '額外',
    'property.SeparatorGroup.Appearance': '外觀',
    'property.SeparatorGroup.CrossHair': '十字線',
    'property.SeparatorGroup.ExecutedOrders': '已滿足的訂單',
    'property.SeparatorGroup.Grid': '網格',
    'property.SeparatorGroup.HighlightDate': '亮顯日期',
    'property.SeparatorGroup.HighlightPrices': '亮顯價格',
    'property.SeparatorGroup.OpenPositions': '建倉',
    'property.SeparatorGroup.ScaleMarkings': '標度標記',
    'property.SeparatorGroup.ScaleType': '標度類型',
    'property.SeparatorGroup.SeparatorOEWidth': '檢視',
    'property.SeparatorGroup.Show': '顯示',
    'property.SeparatorGroup.TimeToNextBar': '到下一長條的時間',
    'property.SeparatorGroup.WindowColors': '背景顏色',
    'property.SeparatorGroup.Zoom': '縮放',
    'property.SeparatorViewButtons': '快速交易',
    'property.Sesonality': '季節性',
    'property.shift': '遷移',
    'property.showAllSessions': '顯示延伸的時段',
    'property.ShowEmptyBars': '歷程記錄中的漏洞',
    'property.showEvents': '可見',
    'property.ShowFullArc': '顯示完整弧形',
    'property.ShowHighLow': '日高位/低位',
    'property.showOrders': '可見',
    'property.showPositions': '可見',
    'property.ShowPrevCloseLine': '上一收盤線',
    'property.ShowTimeToNextBar': '可見',
    'property.ShowTotal': '顯示總計',
    'property.ShowVisualTradingOnLeftSide': '將有效的部位/訂單繫結在左側',
    'property.showVolume': '可見',
    'property.showVolumeMarker': '在標度上顯示值標記',
    'property.Simple': '單一',
    'property.sltpOffsetMode': '在互抵中設定止損/獲利值',
    'property.Smoothing': '平滑',
    'property.solidPriceColor': '區域',
    'property.sounds_param': '聲音',
    'property.Sources prices for MA': '移動平均線的來源價格',
    'property.Specific': '特定',
    'property.SpreadType': '價差類型',
    'property.SpreadType.Area': '區域',
    'property.SpreadType.Line': '線',
    'property.SpreadType.LineWithLabel': '帶價格的行',
    'property.SpreadType.None': '無',
    'property.Step of parabolic SAR system': '拋物線停損轉向指標系統的步長',
    'property.Stick to': '遵守',
    'property.stickTo': '遵守：',
    'property.Stocks': '股票',
    'property.Stop': '止損',
    'property.Stop Limit': '止損限價',
    'property.Stop-loss': '止損',
    'property.style': '樣式：',
    'property.Sum': '總和',
    'property.Symbol': '商品',
    'property.Symbols': '股票代號',
    'property.T': 'T',
    'property.Take-profit': '獲利',
    'property.text': '文字',
    'property.TIF': '市價委託時間有效期',
    'property.TIF_Limit_And_Stop_Limit': '限價委託時間有效期（止損限價委託時間有效期）',
    'property.TIF_Stop': '止損委託時間有效期',
    'property.Time Scale': '時間標度',
    'property.TimeLine': '時間線',
    'property.TimelinePreview': '捲軸圖表預覽',
    'property.TimeScaleAxisColor': '軸',
    'property.TimeScaleBackColor': '軸背景',
    'property.TimeScaleFont': '字型',
    'property.TimeScaleImportentFont': '重要日期字型',
    'property.TimeScaleTextColor': '字型顏色',
    'property.TimeToNextBarColor': '顏色',
    'property.TimeToNextBarFont': '字型',
    'property.TopCenter': '頂部居中',
    'property.TopLeft': '頂部居左',
    'property.TopRight': '頂部居右',
    'property.Tr. stop': '跟蹤止損',
    'property.trackShowCrossHair': '可見',
    'property.Trading': '交易',
    'property.Trading Defaults': '交易',
    'property.Type of moving average': '移動平均線類型',
    'property.Type of Moving Average': '移動平均線類型',
    'property.Typical': '典型',
    'property.Upband deviation in %': '以百分比表示的上段偏差',
    'property.Update': '更新',
    'property.UseCustomYMarkings': '自訂網格',
    'property.user': '登入',
    'property.useSSL': '安全',
    'property.Value of confidence interval': '信賴區間的值',
    'property.ValueColors': '值顏色',
    'property.VATypes.Horizontal': '水平',
    'property.VATypes.None': '無',
    'property.VATypes.ToNextProfile': '至下一個設定檔',
    'property.VATypes.Vertical': '垂直',
    'property.vendorName': '供應商',
    'property.View': '檢視',
    'property.ViewSeparatorGroup': '檢視',
    'property.Visibility': '可見度',
    'property.VisibilitySeparatorGroup': '可見度',
    'property.Visible': '可見',
    'property.Visual Trading': '可視交易',
    'property.Volume': '成交量長條圖',
    'property.Volume.Font': '字型',
    'property.Volume.FontColor': '字型顏色',
    'property.Volume.UseTicksData': '使用實際的跳動點成交量',
    'property.VolumeColoringMode': '規模配色方案',
    'property.VolumeDownColor': '負',
    'property.VolumeFixedColor': '相等',
    'property.VolumeHeightPercent': '高度，%',
    'property.VolumeMode.BuyVolume': '買入量',
    'property.VolumeMode.Delta': 'Delta',
    'property.VolumeMode.MaxTickVolume': '最大跳動點成交量',
    'property.VolumeMode.SellVolume': '賣出量',
    'property.VolumeMode.TotalVolume': '總成交量',
    'property.VolumeMode.Trades': '交易',
    'property.VolumeOpacityPercent': '透明度',
    'property.VolumeProfile': '成交量設定檔',
    'property.VolumeUpColor': '正',
    'property.VWAP': 'VWAP',
    'property.Warnings': '警告',
    'property.WatermarkColor': '水印顏色',
    'property.WeekSeparatorsVisibility': '週分隔符號',
    'property.Weighted': '加權',
    'property.wickColor': '燭心',
    'property.width': '寬度：',
    'property.Win num': 'Win num on chart for indicator',
    'property.Win num.addWin': '子視窗',
    'property.Win num.main': '主',
    'property.Y factor': 'Y 因素',
    'property.YearSeparatorsVisibility': '年分隔符號',
    'property.ZeroLine': '零線',
    'property.ZoomValue': '標度步長',
    'propery.MainPriceRenderer.Down': '向下',
    'propery.MainPriceRenderer.Negative': '負',
    'propery.MainPriceRenderer.Positive': '正',
    'propery.MainPriceRenderer.Up': '向上',
    'Relative Strength Index': '相對強弱指數',
    'reports.#subgroup.Revenue plan.0': '佣金',
    'reports.#subgroup.Revenue plan.1': '價差',
    'reports.#subgroup.Revenue plan.2': '掉期',
    'reports.#subgroup.Revenue plan.3': '回扣',
    'reports.#subgroup.Revenue plan.4': 'Symbol parts',
    'reports.$broker': '經紀人',
    'reports.$broker_create_order': '經紀人建立訂單',
    'reports.$broker_refuse_with_answer': '經紀人有回覆地拒絕',
    'reports.$client': '客戶端',
    'reports.$client_request': '客戶端請求',
    'reports.$close_position': '平倉',
    'reports.$confirm_order_removed': '確認移除訂單',
    'reports.$enter_system': '進入系統',
    'reports.$executed': '已執行',
    'reports.$leave_system': '離開系統',
    'reports.$open_position': '建倉',
    'reports.$order': '訂單',
    'reports.$request_order_removed': '請求移除訂單',
    'reports.4CastNews': '4CastNews',
    'reports.Accepted': '已接受',
    'reports.accname': '帳戶名稱',
    'reports.accoperreqstatus': '狀態',
    'reports.account': '帳戶',
    'reports.Account': '帳戶',
    'reports.Account Name': '帳戶名稱',
    'reports.Account operation confirmation.': '帳戶操作確認',
    'reports.Account Operation Report': '帳戶操作報告',
    'reports.Account operation report (settled)': '帳戶操作報告（已解決）',
    'reports.Account Operation Report Summary': '帳戶操作報告摘要',
    'reports.Account operations report': '帳戶操作報告',
    'reports.Account Operations Report': '帳戶操作報告',
    'reports.Account Performance Report': '帳戶表現報告',
    'reports.Account Statement': '帳戶報表報告',
    'reports.Account statement in defined currency report': '在定義的貨幣報告中的帳戶報表',
    'reports.Account Statement on chosen moment report': '在選定的時刻報告中的帳戶報表',
    'reports.Account Statement Report': '帳戶報表報告',
    'reports.account_ID': '帳戶 ID',
    'reports.account.balance': '餘額',
    'reports.account.crossinstrument': '十字符號',
    'reports.account.currency': '貨幣',
    'reports.account.ismaster': '是主要',
    'reports.account.mirroraccounts': '鏡像帳戶',
    'reports.account.name': '帳戶名稱',
    'reports.account.status': '狀態',
    'reports.account.type': '類型',
    'reports.account.userid': '使用者 ID',
    'reports.account.visibility.editforsuper': '編輯超級群組',
    'reports.account.visibility.editforthis': '編輯該群組',
    'reports.account.visibility.tradeforsuper': '交換超級群組',
    'reports.account.visibility.tradeforthis': '交換該群組',
    'reports.account.visibility.viewforsuper': '檢視超級群組',
    'reports.account.visibility.viewforthis': '檢視該群組',
    'reports.accountcurrency': '貨幣',
    'reports.AccountId': '帳戶',
    'reports.accountname': '帳戶',
    'reports.AccountOperationAmount': '金額',
    'reports.accountoperationid': '帳戶操作 ID',
    'reports.accountPassword': '帳戶密碼',
    'reports.accounts': '帳戶',
    'reports.accountUsername': '帳戶使用者名稱',
    'reports.AccruedInterest': '應計利息',
    'reports.ACM Feed': 'ACM 餽送',
    'reports.action': '側',
    'reports.actionname': '操作名稱',
    'reports.Activated': '已啟用',
    'reports.Active Orders Report': '活躍訂單報告',
    'reports.actualprice': '實際價格',
    'reports.actualtime': '實際時間',
    'reports.addliquidityrebate': '加入流動性回扣',
    'reports.address': '地址',
    'reports.Adjusment': '調整',
    'reports.Adjustment': '調整',
    'reports.ADMINISTRATION_RULES': '管理規則',
    'reports.AK Equity Algo': 'AK Equity Algo',
    'reports.AK Genex Equity': 'AK Genex Equity',
    'reports.AK Genex Turdex': 'AK Genex Turdex',
    'reports.alertNotify': '提醒通知',
    'reports.All brokers': '全部經紀人',
    'reports.All data received.': '已收到全部資料。',
    'reports.ALLOW_AUTO_APPROVE_TRANSFER_REQUEST': '允許自動核准轉讓請求',
    'reports.ALLOW_AUTO_ORDER_TYPE': '自動設定訂單類型（限價/止損/市價）',
    'reports.ALLOW_AUTO_RESET_PWD': '允許自動重設密碼',
    'reports.ALLOW_CLOSE_POSITIONS_ON_STOP_OUT': '在止損離場時平倉',
    'reports.ALLOW_DEMO_AUTO_REGISTRATION': '允許示範使用者自動註冊',
    'reports.ALLOW_GENERATE_L3': '允許產生 L3',
    'reports.ALLOW_GENERATE_PRICE_FROM_SPOT': '允許從現貨產生價格',
    'reports.ALLOW_HEDGE_BY_SPOT': '按現貨對沖',
    'reports.ALLOW_HEDGE_MARGIN': '允許對沖保證金',
    'reports.ALLOW_JOIN_POSITIONS': '在對沖時聯合單邊部位',
    'reports.ALLOW_LAZY_LOAD_QUOTES': '允許載入延遲的報價',
    'reports.ALLOW_LIVE_AUTO_REGISTRATION': '允許即時自動註冊',
    'reports.ALLOW_ONE_BROKER_FOR_ALL_OPERATION': '允許一位經紀人進行全部操作',
    'reports.ALLOW_ORDER_AUTOEXECUTION_IS_MARKET_PRICE': '按市價執行訂單（如為假，則按訂單價格）',
    'reports.ALLOW_PRICE_EXECUTION_MAPPING': '價格執行對映',
    'reports.ALLOW_PWD_VERIFICATION': '透過簡訊驗證密碼',
    'reports.ALLOW_REFUSE_IF_BROKER_OFFLINE': '如果經紀人離線，則允許拒絕',
    'reports.ALLOW_REMOVE_ORDERS_ON_STOP_OUT': '在止損離場時移除訂單',
    'reports.ALLOW_SAVE_HISTORY': '儲存該股票代號的報價歷程記錄',
    'reports.ALLOW_SAVE_QUOTE_LOGS': '允許儲存報價日誌',
    'reports.ALLOW_SAVE_QUOTE_TICKS': '允許儲存報價跳動點',
    'reports.ALLOW_SAVE_QUOTES': '允許儲存報價',
    'reports.ALLOW_SAVE_TRADE_LOGS': '允許儲存交易日誌',
    'reports.ALLOW_SHORT_POSITIONS': '允許短部位',
    'reports.ALLOW_SKIP_REVERSED_PRICE': '略過全部「反轉」報價',
    'reports.ALLOW_SPIKE_FILTER': '允許價格大幅波動篩選器',
    'reports.ALLOW_STOP_AVARE_MARGIN': '使用止損警覺保證金',
    'reports.ALLOW_STORE_USERS_FILES': '允許儲存使用者檔案',
    'reports.ALLOW_USE_CERT': '允許在請求中使用記號',
    'reports.ALLOW_USER_LEVERIGE': '允許使用者槓桿',
    'reports.ALLOW_USERS_EMAIL_DUPLICATE': '允許複製示範帳戶的電子郵件',
    'reports.ALLOW_VISIBLITY': '允許可見度',
    'reports.allowLimitOrders': '允許限價單',
    'reports.allowStopOrders': '允許止損單',
    'reports.already in processing': '已在進行中',
    'reports.amount': '數量',
    'reports.Amount': '數量',
    'reports.Amount filled': '已滿足數量',
    'reports.amountperside': '每側的金額',
    'reports.An error occurred during sending mail using user settings. Will use default settings.': '在使用使用者設定傳送郵件時出錯。將使用預設設定。',
    'reports.Aphelion': 'Aphelion',
    'reports.APPLICATION.NAME': '應用程式名稱',
    'reports.Approved': '已核准',
    'reports.asset': '資產',
    'reports.AuditReport': '後台變更報告',
    'reports.Authorization Error：不允許進行操作': '授權錯誤：不允許進行操作',
    'reports.autotrade': '自動交易',
    'reports.Average filled price': '價格',
    'reports.Back Office Changes Report': '後台變動報告',
    'reports.balance': '餘額',
    'reports.Balance': '餘額',
    'reports.Balance by Instrument Report': '按股票代號報告劃分的餘額',
    'reports.Balance By Instrument Report': '按股票代號報告劃分的餘額',
    'reports.Balance Operations Report': '餘額操作報告',
    'reports.Balance Report': '餘額報告',
    'reports.Balance Report(copy)': '餘額報告（副本）',
    'reports.Balance Summary report': '餘額摘要報告',
    'reports.Balance Summary Report': '餘額摘要報告',
    'reports.balance_after': '以下時間後的餘額：',
    'reports.balance_before': '以下時間前的餘額：',
    'reports.balance.amount': '數量',
    'reports.balance.buy': '買入',
    'reports.balance.everbuy': '平均買入價',
    'reports.balance.eversell': '平均賣出價',
    'reports.balance.exposure': '暴險',
    'reports.balance.exposurenet': '暴險淨額',
    'reports.balance.grossexposure': '暴險總額',
    'reports.balance.grosspl': '總損益',
    'reports.balance.grossvalue': '總部位價值',
    'reports.balance.net': '淨額',
    'reports.balance.netexposure': '淨暴險',
    'reports.balance.netvalue': '淨部位價值',
    'reports.balance.profit': '利潤',
    'reports.balance.sell': '賣出',
    'reports.Barclays': '巴克萊',
    'reports.basis': '註解',
    'reports.Basis': '詳情',
    'reports.because Not enoght margin to create Order': '由於沒有建立訂單的足夠保證金',
    'reports.because Price for Stop limit order is incorrect.': '由於止損限價單的價格不正確。',
    'reports.BeginString': '開始字串',
    'reports.blocked_funds': '凍結的資金（保證金）',
    'reports.blockedbalance': '凍結餘額',
    'reports.Blocking': '正在凍結',
    'reports.bmr_brokermessageid': '訊息 ID',
    'reports.bmr_brokermessagename': '訊息名稱',
    'reports.bmr_confirmtime': '目標時間',
    'reports.bmr_targetuserid': '使用者 ID',
    'reports.bmr_targetuserlogin': '使用者',
    'reports.Bound To': '繫結',
    'reports.Bound to order': '繫結訂單',
    'reports.boundtoorderid': '繫結訂單 ID',
    'reports.BRANDING_ADMIN_HELP': '管理員說明 URL',
    'reports.BRANDING_ANONYM_GROUP': '將自動註冊的匿名使用者放入',
    'reports.BRANDING_ANONYM_GROUP_SUPPORT': '匿名使用者的支援群組',
    'reports.BRANDING_CLIENT_MINIMAL_VERSION': '最低客戶端版本（用於強制更新）',
    'reports.BRANDING_COMMON_RULES': '普通',
    'reports.BRANDING_COMPANY_DESC': '品牌塑造公司描述',
    'reports.BRANDING_COMPANY_INFO_RULES': '公司資訊',
    'reports.BRANDING_COMPANY_NAME': '公司名稱',
    'reports.BRANDING_COMPANY_URL': '公司 URL',
    'reports.BRANDING_DEMO_GROUP': '將自動註冊的示範使用者放入',
    'reports.BRANDING_DEMOREGISTRATION_URL': '示範註冊（空-本伺服器；0-停用；url-網頁）',
    'reports.BRANDING_DEPOSIT_URL': '儲放 URL（空為停用，url 代表網頁）',
    'reports.BRANDING_DISCLAMER': '免責聲明文字（已在登入皮膚 0 中顯示）',
    'reports.BRANDING_DOWJONES_ENCRYPTED_TOKEN': '道瓊加密訊標',
    'reports.BRANDING_EMAIL': '公司電子郵件',
    'reports.BRANDING_EXT_LINK_RULES': '外部連結',
    'reports.BRANDING_FORGOTPWD_URL': '忘記密碼 URL',
    'reports.BRANDING_HTML_FOOTER': '註腳影像',
    'reports.BRANDING_HTML_LOGINSCREEN_LOGO': '登入螢幕影像',
    'reports.BRANDING_HTML_LOGO': '標誌影像',
    'reports.BRANDING_HTML_NAME': '應用程式名稱',
    'reports.BRANDING_HTML_STATION_RULES': '後台',
    'reports.BRANDING_INFO_MESSAGE': '快顯訊息（登入後在站台中打開）',
    'reports.BRANDING_KEY': '品牌塑造金鑰（用於連結安裝檔）',
    'reports.BRANDING_KNOWLEDGE_BASE_URL': '知識庫 URL（-1 為停用）',
    'reports.BRANDING_LANG_SET': '語言資源',
    'reports.BRANDING_LIVE_GROUP': '將自動註冊的即時使用者放入',
    'reports.BRANDING_LOGIN_MESSAGE': '登入螢幕注意訊息',
    'reports.BRANDING_MAIL_CONFIGURATION': '郵件組態',
    'reports.BRANDING_MAIL_PATTERN': '模式',
    'reports.BRANDING_NET_BACKGROUND': '背景影像（可選/任何大小的 .bmp）',
    'reports.BRANDING_NET_BANNER': '橫幅影像 (.bmp 500x36)',
    'reports.BRANDING_NET_BROKERIMAGE': '報告影像（任何大小的 .bmp）',
    'reports.BRANDING_NET_CONFIGURAION': '預設組態（程式 .ini 檔案）',
    'reports.BRANDING_NET_DEFWORKSPACE': '預設工作區（*.wrk 檔案）',
    'reports.BRANDING_NET_FX_STYLE': '外匯欄框皮膚（輸入代碼）',
    'reports.BRANDING_NET_HELP': '說明 URL',
    'reports.BRANDING_NET_ICON': '圖示影像 (.ico 16x16+32x32)',
    'reports.BRANDING_NET_INSTALLER_BANNER_IMAGE': '安裝檔橫幅影像 (500*59)',
    'reports.BRANDING_NET_INSTALLER_DIALOG_IMAGE': '安裝檔對話影像 (500*316)',
    'reports.BRANDING_NET_LINK': '應用程式連結',
    'reports.BRANDING_NET_LOADING_IMAGE': '正在載入影像 *.gif',
    'reports.BRANDING_NET_LOGIN_STYLE': '登入螢幕皮膚（輸入代碼）',
    'reports.BRANDING_NET_LOGO': '標誌影像 (.bmp 500x35)',
    'reports.BRANDING_NET_NAME': '應用程式名稱（用於應用程式資料、快捷鍵）',
    'reports.BRANDING_NET_OE_STYLE': '訂單登錄皮膚（輸入代碼）',
    'reports.BRANDING_NET_RIBBONICON': '功能區圖示影像 (.ico 32x32)',
    'reports.BRANDING_NET_STATION_RULES': '.Net 站台',
    'reports.BRANDING_NET_USE_FINGERPRINT': '使用指紋',
    'reports.BRANDING_PHONE': '公司電話',
    'reports.BRANDING_POWERED_BY': '由（公司名稱或個人姓名）提供技術支援',
    'reports.BRANDING_PROPERTIES_RULES': '品牌塑造屬性',
    'reports.BRANDING_RULES': '品牌塑造可見度',
    'reports.BRANDING_SCRIPT_URL': '指令碼 URL',
    'reports.BRANDING_SERVER_URL': '品牌塑造伺服器 URL（在登入前用於連結第一個頁面的 URL 或 IP）',
    'reports.BRANDING_STATUS_MESSAGE': '狀態列訊息（在站台狀態列中顯示）',
    'reports.BRANDING_THEME': '觀看與感受',
    'reports.BRANDING_TOOLS_RULES': '「工具」選項單內的外部網站連結',
    'reports.BRANDING_TTP_FILE': '面板範本',
    'reports.BRANDING_USERS_RULES': '使用者規則',
    'reports.BRANDING_WITHDRAW_URL': '提款 URL（空為停用，url 代表網頁，1 -視窗表單）',
    'reports.branding.name': '名稱',
    'reports.Broker Message Report': '經紀人訊息報告',
    'reports.Brokerage': '經紀業務',
    'reports.brokerCancel': '經紀人取消',
    'reports.brokerlogin': '經紀人登入',
    'reports.Buy': '買入',
    'reports.buy trade amount': '買入交易金額',
    'reports.buy trades': '買入交易',
    'reports.buyselltype': '買賣類型',
    "reports.Can't allocate route for trade using hadget": '無法使用 hadget 將路由分配至交易',
    "reports.Can't close entry": '無法關閉登錄',
    'reports.Cancel order:': '取消訂單：',
    'reports.Cancel request accepted by broker': '經紀人已接受取消請求',
    'reports.Cancel request received by STP': 'STP 已收到取消請求',
    'reports.Canceled': '已取消',
    'reports.Canceling': '已取消',
    'reports.Cancelled': '已取消',
    'reports.Cancelling': '已取消',
    'reports.CantorFX': 'CantorFX',
    'reports.Cash Deposit And Withdrawal Report': '現金存款和提款報告',
    'reports.cash_posted': '已過帳現金',
    'reports.Champ stats report': 'Champ 統計資料報告',
    'reports.change': '變動',
    'reports.Change password error, new password must differ from old one': '變更密碼錯誤，新密碼必須與舊密碼不同',
    'reports.CHART_OE': '圖表（OE 內建）',
    'reports.CHART_VISUAL': '圖表（可視交易）',
    'reports.Chat History Report': '圖表歷程記錄報告',
    'reports.CheckCompID': '檢查電腦 ID',
    'reports.CheckLatency': '檢查延遲',
    'reports.ChoiceFX': 'ChoiceFX',
    'reports.CIBC': 'CIBC',
    'reports.Citi': '花旗',
    'reports.ClearingVmTransfer': '正在清除 VM 轉移',
    'reports.clfr_close_date': '平倉日期',
    'reports.clfr_fund_name': '基金名稱',
    'reports.clientid': '客戶端 ID',
    'reports.clienttype': '客戶端類型',
    'reports.Close Limit order created': '已建立收盤限價單',
    'reports.Close Limit order created is off market': '收盤限價單已離場',
    'reports.Close Limit order created is unplaced': '沒有在交易所下達收盤限價單',
    'reports.Close Limit order received by broker': '經紀人已收到收盤限價單',
    'reports.Close Market order created': '已建立收盤市價單',
    'reports.Close Market Order Created': '已建立收盤市價單',
    'reports.Close Market order created is off market': '收盤市價單已離場',
    'reports.Close Market order created is unplaced': '沒有在交易所下達收盤市價單',
    'reports.Close Market order received by broker': '經紀人已收到收盤市價單',
    'reports.Close OCO order created': '已建立收盤二擇一委託單',
    'reports.Close OCO order created is off market': '收盤二擇一委託單已離場',
    'reports.Close OCO order created is unplaced': '沒有在交易所下達收盤二擇一委託單',
    'reports.Close OCO order received by broker': '經紀人已收到收盤二擇一委託單',
    'reports.Close order type': '收盤訂單類型',
    'reports.Close position:': '平倉：',
    'reports.Close Sell': '收盤時賣出',
    'reports.Close SL order created': '已建立收盤止損委託單',
    'reports.Close SL order created is off market': '收盤止損委託單已離場',
    'reports.Close SL order created is unplaced': '沒有在交易所下達收盤止損委託單',
    'reports.Close SL order received by broker': '經紀人已收到收盤止損委託單',
    'reports.Close SL tr. stop order created': '已建立收盤止損跟蹤止損單',
    'reports.Close SL tr. stop order created is off market': '收盤止損跟蹤止損單已離場',
    'reports.Close SL tr. stop order created is unplaced': '沒有在交易所下達收盤止損跟蹤止損單',
    'reports.Close SL tr. stop order received by broker': '經紀人已收到收盤止損跟蹤止損單',
    'reports.Close Stop Limit order created': '已建立收盤止損限價單',
    'reports.Close Stop Limit order created is off market': '收盤止損限價單已離場',
    'reports.Close Stop Limit order created is unplaced': '沒有在交易所下達收盤止損限價單',
    'reports.Close Stop Limit order received by broker': '經紀人已收到收盤止損限價單',
    'reports.Close Stop order created': '已建立收盤止損單',
    'reports.Close Stop order created is off market': '收盤止損單已離場',
    'reports.Close Stop order created is unplaced': '沒有在交易所下達收盤止損單',
    'reports.Close Stop order received by broker': '經紀人已收到收盤止損單',
    'reports.Close TP order created': '已建立收盤獲利委託單',
    'reports.Close TP order created is off market': '收盤獲利委託單已離場',
    'reports.Close TP order created is unplaced': '沒有在交易所下達收盤獲利委託單',
    'reports.Close TP order received by broker': '經紀人已收到收盤獲利委託單',
    'reports.Close Tr. stop order created': '已建立收盤跟蹤止損單',
    'reports.Close Tr. stop order created is off market': '收盤跟蹤止損單已離場',
    'reports.Close Tr. stop order created is unplaced': '沒有在交易所下達收盤跟蹤止損單',
    'reports.Close Tr. stop order received by broker': '經紀人已收到收盤跟蹤止損單',
    'reports.CLOSE_ALL': '全部平倉',
    'reports.CLOSE_ALL_ORDERS': '取消全部訂單',
    'reports.CLOSE_ALL_POSITIONS': '全部平倉',
    'reports.CLOSE_FIFO': '在沒有足夠保證金時平倉（先進先出）',
    'reports.CLOSE_LAGES': '在沒有足夠保證金時平倉（最大者先出）',
    'reports.CLOSE_LIFO': '在沒有足夠保證金時平倉 (LIFO)',
    'reports.closebytradeid': '按交易 ID 平倉',
    'reports.Closed funds report': '封閉式基金報告',
    'reports.Closed Positions': '已平倉部位報告',
    'reports.closedat': '收盤',
    'reports.closedbyorderid': '按訂單 ID 平倉',
    'reports.ClosedResendInterval': '已平倉重新傳送間隔',
    'reports.CloseOptionPremium': '平倉權利金',
    'reports.Closeposition': '平倉',
    'reports.closeprice': '收盤價',
    'reports.closetime': '平倉時間',
    'reports.comission': '費用',
    'reports.Comment': '註解',
    'reports.comments': '註解',
    'reports.comments.CancelOperation': '取消操作 #',
    'reports.COMMISIONSPLAN_RULES': '收入計畫可見度',
    'reports.commission': '佣金',
    'reports.commission_out': 'Fee out',
    'reports.commissionpertrade': '每宗交易的佣金',
    'reports.commissions': '佣金',
    'reports.Commissions': '費用',
    'reports.Commissions report': '佣金報告',
    'reports.Commissions Report': '佣金報告',
    'reports.Commissions Report(copy)': '佣金報告（副本）',
    'reports.commissiontotal': '佣金總計',
    'reports.COMMON_RULES': '普通規則',
    'reports.Community': '社群',
    'reports.ConfigFilePath': '設定檔案路徑',
    'reports.Connected': '已連線',
    'reports.Connection established': '已建立連線',
    'reports.Connections Report': '連線報告',
    'reports.ConnectionType': '連線類型',
    'reports.connrp_conntime': '連線時間嘗試',
    'reports.connrp_fullname': '名字和姓氏',
    'reports.connrp_ip': 'IP',
    'reports.connrp_lastpwdchange': '最後的密碼變動',
    'reports.connrp_login': '登入',
    'reports.connrp_success': '連線成功',
    'reports.ContinueInitializationOnError': '發生錯誤時繼續初始化',
    'reports.Contract Specification Report': '契約規格報告',
    'reports.Counter Account Name': '櫃枱帳戶名稱',
    'reports.Counter Operation Id': '櫃枱操作 ID',
    'reports.COUNTERACCOUNTS_RULES': '櫃枱帳戶',
    'reports.Created': '已建立',
    'reports.Created by': '建立者',
    'reports.createdat': '建立時間為',
    'reports.Creating': '已建立',
    'reports.creator': '建立者',
    'reports.credit': '信用',
    'reports.Credit': '信用',
    'reports.crossprice': '交叉價格',
    'reports.crosstype': '交叉式',
    'reports.currency': '貨幣',
    'reports.Currency Exposure Report': '貨幣暴險報告',
    'reports.Currency summary exposure': '貨幣摘要暴險',
    'reports.currency.balance_interest': '餘額利息 (%)',
    'reports.currency.interest_buy_rate': '正餘額利息 (%)',
    'reports.currency.interest_sell_rate': '負餘額利息 (%)',
    'reports.currency.leverage_negative': '負暴險槓桿',
    'reports.currency.leverage_positive': '正暴險槓桿',
    'reports.currency.min_change': '最小變動',
    'reports.currency.name': '名稱',
    'reports.Currenex': 'Currenex',
    'reports.CurrenexFIX': 'CurrenexFIX',
    'reports.Current Balance': '當期餘額',
    'reports.currentprice': '當前價格',
    'reports.CUSTOM_COLUMN_BTN_SCRIPT': '自訂欄（按鈕指令碼）',
    'reports.customReport': '自訂報告',
    'reports.DataDictionary': '資料字典',
    'reports.DateTime': '日期/時間',
    'reports.days': '天',
    'reports.daystillexpiration': '到期前的天數',
    'reports.DBFX': 'DBFX',
    'reports.dealercommission': '經銷商佣金',
    'reports.debit': '借項',
    'reports.Default settings failed': '預設設定失敗',
    'reports.Delayed Limit Order Created': '已建立待決限價單',
    'reports.Delayed Limit Order Modified': '已修改待決限價單',
    'reports.Delayed Limit Order Removed': '已移除待決限價單',
    'reports.Delayed OCO Order Created': '已建立待決二擇一委託單',
    'reports.Delayed Stop Limit Order Created': '已建立止損限價單',
    'reports.Delayed Stop Limit Order Modified': '已修改止損限價單',
    'reports.Delayed Stop Limit Order Removed': '已移除止損限價單',
    'reports.Delayed Stop Order Created': '已建立待決止損單',
    'reports.Delayed Stop Order Modified': '已修改待決止損單',
    'reports.Delayed Stop Order Removed': '已移除待決止損單',
    'reports.Delayed Trailing Stop Order Created': '已建立跟蹤止損單',
    'reports.Delayed Trailing Stop Order Modified': '已修改跟蹤止損單',
    'reports.Delayed Trailing Stop Order Removed': '已移除跟蹤止損單',
    'reports.DelayedCharging': '延遲的收費',
    'reports.DelayedDataCharging': '延遲的資料收費',
    'reports.delivered': '已交割',
    'reports.DeliveredForward': '交割的遠期契約',
    'reports.Delivery': '交割',
    'reports.demoMode': '示範模式',
    'reports.deposit': '存款',
    'reports.Deposit': '存款',
    'reports.Detail': '詳情',
    'reports.Deutsche Bank': '德意志銀行',
    'reports.DeutscheBankFIXFeed': 'DeutscheBankFIXFeed',
    'reports.DGCX': 'DGCX',
    'reports.Dictionary': '字典',
    'reports.discount': '折扣',
    'reports.dividends': '股利',
    'reports.DO_NOT_CLOSE': '不要平倉',
    'reports.DukasCopy': 'DukasCopy',
    'reports.DWT': 'DWT',
    'reports.email': '電子郵件',
    'reports.EMAIL_NOTIFICATION': '電子郵件通知模式可見度',
    'reports.EMAIL_RULES': '電子郵件通知模式可見度',
    'reports.EndDay': '結束日',
    'reports.EndTime': '結束時間',
    'reports.entityId': '實體 ID',
    'reports.enum_StopOutType_AutoClose': '自動平倉',
    'reports.enum_StopOutType_CloseAllByInstruments': '透過工具全部平倉',
    'reports.enum_StopOutType_CloseAllPositions': '全部平倉',
    'reports.enum_StopOutType_CloseLagestLoss': '在沒有足夠保證金時平倉（最大損失者先出）',
    'reports.enum_StopOutType_ClosePositionsByMarginExcess': '透過保證金超額部分平倉',
    'reports.enum_StopOutType_DoNotClosePositions': '不要平倉',
    'reports.enum_StopOutType_FIFO': '在沒有足夠保證金時平倉（先進先出）',
    'reports.enum_StopOutType_LargestFirst': '在沒有足夠保證金時平倉（最大者先出）',
    'reports.enum_StopOutType_LIFO': '在沒有足夠保證金時平倉 (LIFO)',
    'reports.Error occured during downloading update:': '下載更新時出錯：',
    'reports.Error sending email': '傳送電子郵件時出錯',
    'reports.Error：沒有足夠的建倉保證金': '錯誤：沒有足夠的建倉保證金',
    'reports.error.certificate_corrupted': '使用者憑證已損毀。',
    'reports.error.certificate_not_found': '找不到使用者憑證。',
    'reports.error.error_during_saving_route': '儲存路由時出錯。請聯絡管理員。',
    'reports.error.fill_mandatory': '請填寫所有必填欄位。',
    'reports.error.name_is_in_use': '該名稱已存在！',
    'reports.error.not_valid_email': '電子郵件無效。',
    'reports.error.not_valid_firstname': '名字無效。',
    'reports.error.not_valid_instrumentname_capital': '名稱無效。僅需要大寫字母。',
    'reports.error.not_valid_instrumentname_exist': '名稱無效。該名稱已存在。',
    'reports.error.not_valid_instrumentname_length': '名稱無效。名稱不能超過 32 個字元。',
    'reports.error.not_valid_instrumenttypename': '工具類型無效。請指定正確的工具類型。',
    'reports.error.not_valid_lastname': '姓氏無效。',
    'reports.error.not_valid_login': '登入名無效。',
    'reports.error.not_valid_name': '名稱無效。',
    'reports.error.not_valid_name_exist': '名稱無效。該名稱已存在。',
    'reports.error.not_valid_phone_number': '電話號碼無效。僅輸入數位資料。',
    'reports.error.photo_not_found': '找不到電話',
    'reports.error.too_short_phone_password': '電話密碼太短',
    'reports.error.wrong_percenage': '錯誤的百分比',
    'reports.ESignal': '電子訊號',
    'reports.event': '事件',
    'reports.Event History Report': '事件歷程記錄報告',
    'reports.Event History Report with IP': '含 IP 的事件歷程記錄報告',
    'reports.Exception': '例外情況',
    'reports.exchange': '交易所',
    'reports.Exchange': '交易所',
    'reports.Executed': '已滿足',
    'reports.Executed Orders Report': '已執行訂單報告',
    'reports.executedat': '執行',
    'reports.Executing': '已滿足',
    'reports.executionfee': '執行費用',
    'reports.executionprice': '執行價格',
    'reports.expiredate': '到期日',
    'reports.expiresat': '到期',
    'reports.external_order_id': '外部訂單 ID',
    'reports.externalid': '外部 ID',
    'reports.externalorderid': '外部訂單 ID',
    'reports.extfield': 'Ext. 欄位',
    'reports.EXTFIELDS_RULES': '延伸的欄位可見度',
    'reports.extprice': '延伸的價格',
    'reports.False': '假',
    'reports.fee': '費用',
    'reports.Fee：[addliquidityrebate]': '費用：[addliquidityrebate]',
    'reports.Fee：[Brokerage]': '費用：[Brokerage]',
    'reports.Fee：[Exchange]': '費用：[Exchange]',
    'reports.Fee：[InvestorProtectionLevy]': '費用：[InvestorProtectionLevy]',
    'reports.Fee：[removeliquidityrebate]': '費用：[removeliquidityrebate]',
    'reports.Fee：[SecuritiesTransferTax]': '費用：[SecuritiesTransferTax]',
    'reports.Fee：[SettlementFee]': '費用：[SettlementFee]',
    'reports.Fee：[ValueAddedTax]': '費用：[ValueAddedTax]',
    'reports.Feed Data': '餽送資料',
    'reports.Fees Report': '費用報告',
    'reports.FFastFillFIXFeed': 'FFastFillFIXFeed',
    'reports.fiahr_action': '操作',
    'reports.fiahr_amount': '金額',
    'reports.fiahr_capital': '資本',
    'reports.fiahr_count': '股份計數',
    'reports.fiahr_date': '日期',
    'reports.fiahr_fee': '報酬',
    'reports.fiahr_grosspl': '總損益',
    'reports.fiahr_investor_id': '投資人 ID',
    'reports.fiahr_investor_name': '投資人',
    'reports.fiahr_netpl': '淨損益',
    'reports.fiahr_pamm_id': '基金 ID',
    'reports.fiahr_pamm_name': '基金',
    'reports.fiahr_price': '價格',
    'reports.fiahr_requestid': '請求 ID',
    'reports.Fidessa': 'Fidessa',
    'reports.FileIncludeMilliseconds': '檔案包括毫秒',
    'reports.FileIncludeTimeStampForMessages': '檔案包括訊息的時間戳記',
    'reports.FileLogHeartbeats': '檔案日誌活動訊號',
    'reports.Filled': '已滿足',
    'reports.Filled close Limit order': '已滿足收盤限價單',
    'reports.Filled close Limit order (Mutual)': '已滿足收盤限價單（相互）',
    'reports.Filled close Market order': '已滿足收盤市價單',
    'reports.Filled close Market order (Mutual)': '已滿足收盤市價單（相互）',
    'reports.Filled close OCO order': '已滿足收盤二選一委託單',
    'reports.Filled close OCO order (Mutual)': '已滿足收盤二選一委託單（相互）',
    'reports.Filled close SL order': '已滿足收盤止損委託單',
    'reports.Filled close SL order (Mutual)': '已滿足收盤止損委託單（相互）',
    'reports.Filled close SL tr. stop order': '已滿足收盤止損跟蹤止損單',
    'reports.Filled close SL tr. stop order (Mutual)': '已滿足收盤止損跟蹤止損單（相互）',
    'reports.Filled close Stop Limit order': '已滿足收盤止損限價單',
    'reports.Filled close Stop Limit order (Mutual)': '已滿足收盤止損限價單（相互）',
    'reports.Filled close Stop order': '已滿足收盤止損單',
    'reports.Filled close Stop order (Mutual)': '已滿足收盤止損單（相互）',
    'reports.Filled close TP order': '已滿足收盤獲利委託單',
    'reports.Filled close TP order (Mutual)': '已滿足收盤獲利委託單（相互）',
    'reports.Filled close Tr. stop loss order': '已滿足收盤跟蹤止損單',
    'reports.Filled close Tr. stop order': '已滿足收盤跟蹤止損單',
    'reports.Filled close Tr. stop order (Mutual)': '已滿足收盤跟蹤止損單（相互）',
    'reports.Filled Limit order': '已滿足限價單',
    'reports.Filled Limit order (Mutual)': '已滿足限價單（相互）',
    'reports.Filled Market order': '已滿足市價單',
    'reports.Filled Market order (Mutual)': '已滿足市價單（相互）',
    'reports.Filled OCO order': '已滿足二選一委託單',
    'reports.Filled OCO order (Mutual)': '已滿足二選一委託單（相互）',
    'reports.Filled SL order': '已滿足止損委託單',
    'reports.Filled SL order (Mutual)': '已滿足止損委託單（相互）',
    'reports.Filled SL tr. stop order': '已滿足止損跟蹤止損單',
    'reports.Filled SL tr. stop order (Mutual)': '已滿足止損跟蹤止損單（相互）',
    'reports.Filled Stop Limit order': '已滿足止損限價單',
    'reports.Filled Stop Limit order (Mutual)': '已滿足止損限價單（相互）',
    'reports.Filled Stop order': '已滿足止損單',
    'reports.Filled Stop order (Mutual)': '已滿足止損單（相互）',
    'reports.Filled TP order': '已滿足獲利委託單',
    'reports.Filled TP order (Mutual)': '已滿足獲利委託單（相互）',
    'reports.Filled Tr. stop order': '已滿足跟蹤止損單',
    'reports.Filled Tr. stop order (Mutual)': '已滿足跟蹤止損單（相互）',
    'reports.filledamount': '滿足的金額',
    'reports.fir_exit_capital': '當前資本',
    'reports.fir_exit_price': '當前股價',
    'reports.fir_investor_id': '投資人 ID',
    'reports.fir_investor_name': '投資人姓名/名稱',
    'reports.fir_join_capital': '起始資本',
    'reports.fir_join_date': '加入日期',
    'reports.fir_join_price': '加入價格',
    'reports.fir_pamm_id': '基金 ID',
    'reports.fir_pamm_name': '基金名稱',
    'reports.fir_reinvest_cycles': '重新投資週期',
    'reports.fir_share_count': '#股份',
    'reports.FirstDerivatives': 'FirstDerivatives',
    'reports.firstname': '名字',
    'reports.FixedFee': '固定的佣金',
    'reports.Foreks': 'Foreks',
    'reports.Forex balances report': '外匯餘額報告',
    'reports.Formal Dialog Report': '正式對話報告',
    'reports.FORTEX': 'FORTEX',
    'reports.fpghr_count': '股份',
    'reports.fpghr_exit_amount': '退出金額',
    'reports.fpghr_exit_price': '退出價格',
    'reports.fpghr_fee': '費用',
    'reports.fpghr_first_join_date': '加入日期',
    'reports.fpghr_grosspl': '總損益',
    'reports.fpghr_investor_id': '投資人 ID',
    'reports.fpghr_investor_name': '投資人姓名/名稱',
    'reports.fpghr_join_amount': '加入金額',
    'reports.fpghr_join_price': '加入價格',
    'reports.fpghr_last_exit_date': '退出日期',
    'reports.fpghr_netpl': '淨損益',
    'reports.fpghr_pamm_id': '基金 ID',
    'reports.fpghr_pamm_name': '基金名稱',
    'reports.fpghr_period': '期間',
    'reports.fpghr_period_end_date': '結束日期',
    'reports.fpghr_period_start_date': '開始日期',
    'reports.fprhr_date': '最後更新',
    'reports.fprhr_details': '詳情',
    'reports.fprhr_entryid': '登錄 ID',
    'reports.fprhr_event': '事件',
    'reports.fprhr_investor_id': '投資人 ID',
    'reports.fprhr_investor_name': '投資人',
    'reports.fprhr_pamm_id': '基金 ID',
    'reports.fprhr_pamm_name': '基金',
    'reports.fprhr_requestid': '請求 ID',
    'reports.from': '從',
    'reports.From.SERVER': '伺服器',
    'reports.fromDate': '從',
    'reports.fromDateConnAttempt': '從',
    'reports.fromDatePwdChange': '從（密碼）',
    'reports.FUNCTION_ACCOUNT_GROUP': '帳戶群組',
    'reports.FUNCTION_ACCOUNT_OPERATIONS': '資金運作可見度',
    'reports.FUNCTION_ACCOUNTS': '帳戶',
    'reports.FUNCTION_ALERTS': '提醒',
    'reports.FUNCTION_ARBITRAGER': '套利者',
    'reports.FUNCTION_AUTOBROKET_LIST': '自動經紀人列表',
    'reports.FUNCTION_BACK_TESTER': '回測者',
    'reports.FUNCTION_BARS_FROM': '從以下來源製作長條：',
    'reports.FUNCTION_BASE_CURRENCY': '系統基準貨幣（您可能需要變更 instrument cross）',
    'reports.FUNCTION_BASKET': '籃子',
    'reports.FUNCTION_BETTING': '投注',
    'reports.FUNCTION_BINDEDORDERS': '允許二擇一委託單',
    'reports.FUNCTION_BLOCKED_SUMM_FOR_ORDERS': '已凍結訂單的資金',
    'reports.FUNCTION_BRANDING': '品牌塑造',
    'reports.FUNCTION_BRANDINGADMIN': '品牌塑造管理員',
    'reports.FUNCTION_BUILDER': '構建者',
    'reports.FUNCTION_CACHEADMIN': '快取管理員',
    'reports.FUNCTION_CHART': '圖表',
    'reports.FUNCTION_CHAT': '聊天',
    'reports.FUNCTION_CLOSE_POSITIONS_DISABLE': '不允許平倉',
    'reports.FUNCTION_COMMUNITY': '社群',
    'reports.FUNCTION_CONFIGADMIN': '組態和品牌塑造',
    'reports.FUNCTION_CONNECTIONSSADMIN': '連線管理員',
    'reports.FUNCTION_CROSS_PAIR': '交叉價格',
    'reports.FUNCTION_CUR_EXP': '貨幣暴險',
    'reports.FUNCTION_DISABLE_BUY': '停用買入訂單',
    'reports.FUNCTION_DISABLE_OPTION_TRADING': '停用期權訂單',
    'reports.FUNCTION_DISABLE_SELL': '停用賣出訂單',
    'reports.FUNCTION_DO_NOT_MARGIN': '不使用保證金',
    'reports.FUNCTION_EVENT_LOG': '事件日誌',
    'reports.FUNCTION_FUNDING': '操作管理員',
    'reports.FUNCTION_GENERATE_OPTIONS': '從層級 1 產生期權',
    'reports.FUNCTION_HEDGE_RULE_TYPE': '對沖規則列表：',
    'reports.FUNCTION_INSTRUMENT_LOOKUP': '股票代號查詢',
    'reports.FUNCTION_INSTRUMENT_PORTFOLIO': '投資組合',
    'reports.FUNCTION_INSTRUMENTSADMIN': '股票代號管理員',
    'reports.FUNCTION_INTERSERVER': '伺服器間',
    'reports.FUNCTION_LEVEL2': '層級 2 資料和訂單',
    'reports.FUNCTION_LEVEL3': '時間與銷售',
    'reports.FUNCTION_LOCKED_BY_BROKER': '已被經紀人鎖定',
    'reports.FUNCTION_MANUAL': '允許手動訂單',
    'reports.FUNCTION_MARGIN_MODE': '保證金模式',
    'reports.FUNCTION_MASTER': '主帳戶管理',
    'reports.FUNCTION_MATRIX': '矩陣',
    'reports.FUNCTION_MULTI_CONNECTION': '允許多連線',
    'reports.FUNCTION_MUTUAL_CLOSE': '允許相互平倉',
    'reports.FUNCTION_NEWS': '新聞',
    'reports.FUNCTION_NEWSADMIN': '新聞管理員',
    'reports.FUNCTION_NOT_ALLOW_OPEN_POSITION': '不允許建倉',
    'reports.FUNCTION_NOT_ALLOW_SHORT_STOCK': '不允許做空股票',
    'reports.FUNCTION_ONE_COUNTERACCOUNT': '一個櫃枱帳戶',
    'reports.FUNCTION_ONE_POSITION': '每個股票代號一個部位（對沖）',
    'reports.FUNCTION_ONE_QUOTECACHECOUNT': '適合全部的快取大小',
    'reports.FUNCTION_OPTIONS': '期權',
    'reports.FUNCTION_ORDER_BOOK': '訂單歷程記錄',
    'reports.FUNCTION_PARTIAL_CLOSE': '允許部分平倉',
    'reports.FUNCTION_POSITION_BALANCE': '部位餘額',
    'reports.FUNCTION_POSITIONSADMIN': '部位管理員',
    'reports.FUNCTION_QUOTE_HISTORY': '報價歷程記錄',
    'reports.FUNCTION_QUOTES': '報價',
    'reports.FUNCTION_REPORT': '報告',
    'reports.FUNCTION_REPORTADMIN': '報告管理員',
    'reports.FUNCTION_RESERVER_WITHDRAWAL': '提款',
    'reports.FUNCTION_RFQ': '請求報價面板',
    'reports.FUNCTION_ROLESADMIN': '使用者群組管理員',
    'reports.FUNCTION_ROUTESADMIN': '路由管理員',
    'reports.FUNCTION_SCHEDULERADMIN': '排程器管理員',
    'reports.FUNCTION_SCRIPT_BUILDER': '指令碼構建者',
    'reports.FUNCTION_SERVICESADMIN': '服務管理員',
    'reports.FUNCTION_SETTINGSADMIN': '設定管理員',
    'reports.FUNCTION_SHOW_ORDERS': '訂單',
    'reports.FUNCTION_SHOW_POSITIONS': '部位',
    'reports.FUNCTION_SLAVE': '從屬',
    'reports.FUNCTION_SLTP': '允許止損/獲利單',
    'reports.FUNCTION_SMS_SERVICE_DEST': '簡訊服務目的地',
    'reports.FUNCTION_SN': '授權編號',
    'reports.FUNCTION_STRATEGIESADMIN': '策略管理員',
    'reports.FUNCTION_SUPPORT_EMAIL': '支援團隊的電子郵件',
    'reports.FUNCTION_TABLESADMIN': '表格管理員',
    'reports.FUNCTION_THREADEDSAVER': '已啟用執行緒保護裝置',
    'reports.FUNCTION_TRADES': '已滿足的訂單',
    'reports.FUNCTION_TRADING': '交易',
    'reports.FUNCTION_TRADING_MODE': '交易模式',
    'reports.FUNCTION_TRAILING_STOP': '允許跟蹤止損',
    'reports.FUNCTION_USER_CONNECTION_POLICY': '使用者多連線政策',
    'reports.FUNCTION_USERSADMIN': '使用者管理員',
    'reports.FUNCTION_VALIDATE_SSL_CERTIFICATE': '需要驗證 SSL 憑證',
    'reports.FUNCTION_VENDOR_NAME': '供應商名稱',
    'reports.FUNCTION_WATCHLIST': '關注清單',
    'reports.Fund Gain History Report': '基金收益歷程記錄報告',
    'reports.Fund Investments Report': '基金投資報告',
    'reports.Fund Investor Action History Report': '基金投資人操作歷程記錄報告',
    'reports.Fund Investor History Report': '基金投資人歷程記錄報告',
    'reports.Fund Request History Report': '基金請求歷程記錄報告',
    'reports.Fund Transfer': '基金轉換',
    'reports.fund_type.closed': '封閉式',
    'reports.fund_type.open': '開放式',
    'reports.fundID': '基金 ID',
    'reports.FUNDING_RULES': '資金運作可見度',
    'reports.fxintegrate.cert.host.verify': '外匯整合憑證主機驗證',
    'reports.fxintegrate.cert.root.ca.file': '外匯整合憑證根本原因檔',
    'reports.fxintegrate.host': '外匯整合主機',
    'reports.fxintegrate.logging.file': '外匯整合記錄檔',
    'reports.fxintegrate.logging.maxfilecount': '外匯整合記錄最大檔案計數',
    'reports.fxintegrate.logging.maxfilesize': '外匯整合記錄最大檔案大小',
    'reports.fxintegrate.logging.output': '外匯整合記錄輸出',
    'reports.fxintegrate.logging.priority': '外匯整合記錄優先順序',
    'reports.fxintegrate.port': '外匯整合連接埠',
    'reports.fxintegrate.protocol': '外匯整合協定',
    'reports.fxintegrate.usessl': '外匯整合使用 SSL',
    'reports.GENERAL_COMMON_RULES': '一般普通規則',
    'reports.GENERAL_USER_RULES': '一般規則',
    'reports.GFT': 'GFT',
    'reports.gi.available_margin': '可用保證金',
    'reports.gi.avaliable_funds': '可用資金',
    'reports.gi.balance': '餘額',
    'reports.gi.balance_blocked_by_orders': '已被訂單凍結',
    'reports.gi.cur_balance': '預期餘額',
    'reports.gi.cur_margin': '當前保證金，%',
    'reports.gi.fund_capital': '基金資本',
    'reports.gi.fund_used': '已用資金',
    'reports.gi.maintain_margin': '維持保證金',
    'reports.gi.margin_orders': '訂單保證金',
    'reports.gi.margin_positions': '部位保證金',
    'reports.gi.margin_stopout': '止損離場',
    'reports.gi.margin_stoptrade': '止損交易',
    'reports.gi.margin_used': '已用保證金',
    'reports.gi.margin_warning': '保證金警告',
    'reports.gi.min_margin': '最低保證金',
    'reports.gi.pos_maintenance_req': '部位維持要求',
    'reports.gi.risk_lvl': '風險水準',
    'reports.gi.stock_value': '股票',
    'reports.gi.today_released_pnl': '已實現的損益',
    'reports.gi.withdrawal_available': '可用提款',
    'reports.Goldman Sachs': '高盛',
    'reports.grosspl': '總損益',
    'reports.groupid': '群組 ID',
    'reports.groupname': '群組',
    'reports.GTL': 'GTL',
    'reports.HeartBtInt': '活動訊號間隔',
    'reports.hedged': 'STP（直通式處理）',
    'reports.helpIndex': 'http://protrader.org/en/knowledge-base/pt3/desktop/account/reports',
    'reports.host': '主機',
    'reports.HOT_BUTTON': '快速鍵',
    'reports.HotSpot': '熱點',
    'reports.hours': '小時',
    'reports.HTTP.SERVER.HOST': 'HTTP 伺服器主機',
    'reports.HTTP.SERVER.PORT': 'HTTP 伺服器連接埠',
    'reports.HTTP.SERVER.PROTOCOL': 'HTTP 伺服器協定',
    'reports.HTTP.URL.HEARTBEAT': 'HTTP URL 活動訊息',
    'reports.HTTP.URL.LOGIN': 'HTTP URL 登入',
    'reports.HTTP.URL.LOGOUT': 'HTTP URL 登出',
    'reports.HTTP.URL.PASSWORD.VALIDATION': 'HTTP URL 密碼驗證',
    'reports.HTTP.URL.PRELOGIN': 'HTTP URL 預登入',
    'reports.HTTP.URL.QUERY': 'HTTP URL 查詢',
    'reports.HTTP.URL.WORKFLOW': 'HTTP URL 工作流程',
    'reports.IB Trades Report': '介紹經紀人交易報告',
    'reports.IGIndex': 'IGIndex',
    'reports.IndexingQuoteFrequency(sec)': '索引報價頻率（秒）',
    'reports.Information': '資訊',
    'reports.Informational message': '資訊性訊息',
    'reports.Init margin req.': '初始保證金要求',
    'reports.Init. margin req.': '初始保證金要求',
    'reports.INSTALLER_SERVICE_URL': '服務安裝安裝檔 URL',
    'reports.InstrRouteMap': '路由地圖',
    'reports.instrtype': '股票代號群組',
    'reports.INSTRTYPES_RULES': '股票代號群組可見度',
    'reports.instrument': '股票代號',
    'reports.Instrument': '股票代號',
    'reports.instrument group': '股票代號群組',
    'reports.Instrument Specification': '股票代號規格',
    'reports.Instrument Statistic Report': '工具統計資料報告',
    'reports.Instrument Usage By Signal Report': '按訊號報告劃分股票代號用途',
    'reports.Instrument Visibility Report': '股票代號可見度報告',
    'reports.INSTRUMENT_DERIVATIVES': '衍生工具',
    'reports.INSTRUMENT_GENERAL': '一般',
    'reports.INSTRUMENT_MARGIN': '保證金',
    'reports.INSTRUMENT_TRADING': '交易',
    'reports.INSTRUMENT_TYPE_DERIVATIVES': '衍生工具',
    'reports.instrument.group': '股票代號群組',
    'reports.instrument.name': '股票代號名稱',
    'reports.instrumentgroup': '股票代號群組',
    'reports.InstrumentId': '股票代號',
    'reports.instrumentname': '股票代號',
    'reports.instruments': '工具',
    'reports.instrumenttype': '股票代號類型',
    'reports.instrumenttype.duplicatename': '含有該名稱的股票代號群組已存在',
    'reports.instrumenttype.inheritanceerror': '類型繼承時出錯',
    'reports.instrumentType.name': '股票代號群組名稱',
    'reports.instrumentType.superId': '股票代號超級類型名稱',
    'reports.instrumentVisibilityReport': '股票代號可見度報告',
    'reports.Integral': 'Integral',
    'reports.IntegralFIX': 'IntegralFIX',
    'reports.InteractiveData': 'InteractiveData',
    'reports.IntererstOnAvailableFundsAndUnsettledCash': '可用資金的利息+未結算的現金',
    'reports.interest': '利息',
    'reports.InterestOnAvailableFunds': '可用資金的利息',
    'reports.InterestOnCash': '現金利息',
    'reports.InterestOnMarginAvailable': '可用保證金的利息',
    'reports.InterestOnMarginAvailableAndUnsettledCash': '可用保證金的利息和未結算現金',
    'reports.Invalid installer hash.': '安裝檔雜湊無效。',
    'reports.Invalid installer size.': '安裝檔大小無效。',
    'reports.Invalid installer URL.': '安裝檔 URL 無效。',
    'reports.Invalid password：新密碼至少應有 1 個大寫字元、1 個小寫字元、1 個數字、1 個特殊字元（如 @#$%），且至少包含 8 個字元。請重試。': '新密碼至少應有 1 個大寫字元、1 個小寫字元、1 個數字、1 個特殊字元（如 @#$%），且至少包含 8 個字元。請重試。',
    'reports.Invalid price for the order.': '訂單價格無效。',
    'reports.Investor Action History Report': '投資人操作歷程記錄報告',
    'reports.investorID': '投資人 ID',
    'reports.InvestorProtectionLevy': '投資人保護稅',
    'reports.ip': 'IP',
    'reports.IP': 'IP',
    'reports.IQFeed': 'IQFeed',
    'reports.irdnumber': 'IR 編號',
    'reports.is out of range': '超出範圍',
    'reports.IsOnlyLastPeriod': '最後期限',
    'reports.isPriceStream': '為價格串流',
    'reports.isShowLots': '顯示口中的數量',
    'reports.IsShowLots': '顯示口中的數量',
    'reports.IsShowPamm': '顯示資金',
    'reports.issingle': '為單數',
    'reports.JdbcDriver': 'JDBC 驅動程式',
    'reports.JdbcPassword': 'JDBC 密碼',
    'reports.JdbcSessionIdDefaultPropertyValue': 'JDBC 對話 ID 預設屬性值',
    'reports.JdbcURL': 'JDBC URL',
    'reports.JdbcUser': 'JDBC 使用者',
    'reports.JMS.INIT.QUEUE': 'JMS 初始佇列',
    'reports.JMS.SERVER.HOST': 'JMS 伺服器主機',
    'reports.JMS.SERVER.PASSWORD': 'JMS 伺服器密碼',
    'reports.JMS.SERVER.PORT': 'JMS 伺服器連接埠',
    'reports.JMS.SERVER.PROTOCOL': 'JMS 伺服器協定',
    'reports.JMS.SERVER.USER': 'JMS 伺服器使用者',
    'reports.JPMorganTrade': 'JPMorganTrade',
    'reports.keystoreAlias': 'Keystore 別名',
    'reports.keystoreFile': 'Keystore 檔案',
    'reports.KyteFIX': 'KyteFIX',
    'reports.L1port': 'L1port',
    'reports.L2port': 'L2port',
    'reports.LADDER_VIEW': 'Super DOM',
    'reports.LADDER_VIEW_OE': 'Super DOM（OE 內建）',
    'reports.Last fill price': '最後滿足價格',
    'reports.lastname': '姓氏',
    'reports.Level1Charging': 'Level1Charging',
    'reports.Level1DataCharging': '層級 1 資料收費',
    'reports.Level2Charging': 'Level2Charging',
    'reports.Level2DataCharging': '層級 2 資料收費',
    'reports.Level3Charging': 'Level3Charging',
    'reports.Level3DataCharging': '層級 3 資料收費',
    'reports.Limit accepted by broker': '經紀人已接受限價單',
    'reports.Limit accepted by STP': 'STP 已接受限價單',
    'reports.Limit order accepted': '已接受限價單',
    'reports.Limit order activated': '已啟用限價單',
    'reports.Limit order cancel request': '取消限價單的請求',
    'reports.Limit order canceled': '已取消限價單',
    'reports.Limit order created': '已建立限價單',
    'reports.Limit Order Created': '已建立限價單',
    'reports.Limit order created is off market': '限價單已離場',
    'reports.Limit order created is unplaced': '沒有在交易所下達限價單',
    'reports.Limit order is awaiting market': '限價單正在等待市場',
    'reports.Limit Order Modified': '已修改限價單',
    'reports.Limit order partially reject': '已部分取消限價單',
    'reports.Limit order placing request': '下達限價單的請求',
    'reports.Limit order received by broker': '經紀人已收到限價單',
    'reports.Limit order rejected': '已拒絕限價單',
    'reports.Limit Order Removed': '已移除限價單',
    'reports.Limit order replace request': '替換限價單的請求',
    'reports.Limit order replaced': '已修改限價單',
    'reports.Limit order restated': '已重列限價單',
    'reports.Linked Users': '連結的使用者',
    'reports.LMAX': 'LMAX',
    'reports.LMAX FIX': 'LMAX FIX',
    'reports.Local': '本地',
    'reports.locked': '已鎖定',
    'reports.LogFileMaxSize': '日誌檔最大大小',
    'reports.login': '帳戶',
    'reports.Login': '登入',
    'reports.LOGIN.USER.NAMESPACE': '登入使用者命名空間',
    'reports.Login/password combination is not valid': '登入名/密碼組合無效',
    'reports.LogonTimeout': '登出逾時',
    'reports.Logout': '登出',
    'reports.Lots to open': '要建倉的口數',
    'reports.lotsize': '每口大小',
    'reports.Mail Settings': '郵件設定',
    'reports.mail.mailsettings': '郵件設定',
    'reports.mailPattern.ChatDuplicating': '正在複製聊天',
    'reports.mailPattern.DemoUserNotify': '示範使用者到期日通知',
    'reports.mailPattern.DemoUserRegistration': '示範使用者註冊確認',
    'reports.mailPattern.DemoUserRemove': '示範使用者移除確認',
    'reports.mailPattern.DepositWithdrawalNotification': '存款/提款電子郵件通知',
    'reports.mailPattern.MarginCall': '保證金催交通知',
    'reports.mailPattern.NewsCreated': '已建立新聞',
    'reports.mailPattern.OrderPlaced': '已下達止損/限價單',
    'reports.mailPattern.OrderRemoved': '已移除止損/限價單',
    'reports.mailPattern.PositionClosed': '平倉確認',
    'reports.mailPattern.PositionOpened': '建倉確認',
    'reports.mailPattern.SignalDuplicating': '正在複製訊號',
    'reports.mailPattern.TransactionStatementNotifyBroker': '經紀人的每日報告',
    'reports.mailPattern.TransactionStatementNotifyUser': '使用者的每日報告',
    'reports.mailPattern.UserEmailConfirmation': '確認電子郵件',
    'reports.mailPattern.UserPasswordRecovery': '正在恢復使用者密碼',
    'reports.mailPattern.UserPasswordReset': '重設使用者密碼',
    'reports.mailPattern.UserRegistration': '使用者註冊確認',
    'reports.mailPattern.UserUnlocking': '使用者在暴力密碼破解後解鎖',
    'reports.Main': '主',
    'reports.MAM trades report': 'MAM 交易報告',
    'reports.MAM_OE': '多帳戶管理（OE 內建）',
    'reports.MamManagementFee': '多帳戶管理費',
    'reports.MamPerfomanceFee': 'MamPerfomanceFee',
    'reports.MamPerformanceFee': '多帳戶管理表現費',
    'reports.Manual order cancel request': '手動取消訂單的請求',
    'reports.Manual order placing request': '手動下達訂單的請求',
    'reports.Manual order replace request': '手動替換訂單的請求',
    'reports.ManufacturedDividends': '已產生的股利',
    'reports.margin': '保證金',
    'reports.Margin Call': '保證金催交',
    'reports.Margin Call Reached': '已達到保證金催交',
    'reports.Margin Call Warning.': '保證金催交警告。',
    'reports.Margin used': '已用保證金',
    'reports.MARGIN_RULES_ACC': '保證金規則（帳戶）',
    'reports.MARGIN_RUSLES': '保證金規則',
    'reports.Margincall': '保證金催交',
    'reports.MarginCallMessage.header.MarginCallReached': '已達到保證金催交',
    'reports.MarginCallMessage.header.MarginWarning': '保證金警告',
    'reports.MarginCallMessage.Message': '請向您的帳戶存款',
    'reports.Market accepted by broker': '經紀人已接受市價單',
    'reports.Market accepted by STP': 'STP 已接受市價單',
    'reports.Market Close Order Removed': '已移除收盤市價單',
    'reports.Market data charging report': '市場資料收費報告',
    'reports.Market order accepted': '已接受市價單',
    'reports.Market order activated': '已啟用市價單',
    'reports.Market order cancel request': '取消市價單的請求',
    'reports.Market order canceled': '已取消市價單',
    'reports.Market order created': '已建立市價單',
    'reports.Market Order Created': '已建立市價單',
    'reports.Market order created is off market': '市價單已離場',
    'reports.Market order created is unplaced': '沒有在交易所下達市價單',
    'reports.Market Order Modified': '已修改市價單',
    'reports.Market order partially reject': '已部分取消市價單',
    'reports.Market order placing request': '下達市價單的請求',
    'reports.Market order received by broker': '經紀人已收到市價單',
    'reports.Market order rejected': '已拒絕市價單',
    'reports.Market Order Removed': '已移除市價單',
    'reports.Market order replace request': '替換市價單的請求',
    'reports.Market order replaced': '已修改市價單',
    'reports.Market order restated': '已重列市價單',
    'reports.Market price': '市價',
    'reports.MARKETDEPTH': '市場深度',
    'reports.MarketFactory Feed': 'MarketFactory 餽送',
    'reports.Markup': '加成',
    'reports.MATRIX': '矩陣',
    'reports.MATRIX_OE': '矩陣（OE 內建）',
    'reports.MaxLogsBackups': '最大日誌備份數',
    'reports.MBT': 'MBT',
    'reports.message': '訊息',
    'reports.Message': '訊息',
    'reports.messageid': '訊息 ID',
    'reports.MFGlobal': 'MFGlobal',
    'reports.MillisecondsinTimeStamp': '時間戳記中的毫秒',
    'reports.MillisecondsInTimeStamp': '時間戳記中的毫秒',
    'reports.minchange': '最小變動',
    'reports.MinFeeAdjEquity': '最低費用調整權益',
    'reports.Minimum Open Margin': '最低建倉保證金',
    'reports.minutes': '分鐘',
    'reports.MLFIX': 'MLFIX',
    'reports.MOB_CANCEL_ALL_ORDERS': '行動裝置（取消全部訂單）',
    'reports.MOB_CHART_OE': '行動裝置圖表（訂單登錄）',
    'reports.MOB_CLOSE_ALL_POSITIONS': '行動裝置（全部平倉）',
    'reports.MOB_MARKETDEPTH': '行動裝置市場深度',
    'reports.MOB_ORDERS_HOT_BTN': '行動裝置待執行訂單（熱按鈕）',
    'reports.MOB_OTHER': '行動裝置，其他',
    'reports.MOB_POSITIONS_HOT_BTN': '行動裝置部位（熱按鈕）',
    'reports.MOB_TAB_CHART_VISUAL_HOT_BTN': '行動裝置圖表（OE/平板電腦內建）',
    'reports.MOB_WL_OE': '行動裝置關注清單（訂單登錄）',
    'reports.Modified': '已修改',
    'reports.Modifing': '已修改',
    'reports.Modifyied by': '修改人：',
    'reports.Modifying': '已修改',
    'reports.months': '月',
    'reports.Morgan Stanley': '摩根士丹利',
    'reports.MorganStanleyCFD': 'MorganStanleyCFD',
    'reports.Mutual close request': '相互平倉請求',
    'reports.name': '名稱',
    'reports.NAMESPACE': 'NAMESPACE',
    'reports.NEED_EMAIL_CONFIRM_ON_REGISTRATION': '透過電子郵件確認帳戶註冊',
    'reports.NeoNet': 'NeoNet',
    'reports.Net Open Positions': '建倉淨額',
    'reports.netpl': '淨損益',
    'reports.New Back Office Changes Report': '新後台變更報告',
    'reports.New password must differ from several previous passwords.': '新密碼必須與以前的多個密碼不同',
    'reports.New Users Report': '新使用者報告',
    'reports.news.feed.type': '新聞餽送類型',
    'reports.news.reciving': '正在接收新聞',
    'reports.news.save': '儲存新聞',
    'reports.news.sendEmail': '透過電子郵件傳送新聞',
    'reports.NewsCharging': '新聞收費',
    'reports.NewsFeed': '新聞餽送',
    'reports.newvalue': '新值',
    'reports.no such position, no such order.': '無此部位，無此訂單。',
    'reports.Nomura': 'Nomura',
    'reports.Nomura-Lehman': 'Nomura-Lehman',
    'reports.None': '無',
    'reports.Not enoght margin to create Order': '沒有建立訂單的足夠保證金',
    'reports.Not enough margin to open order': '沒有建立訂單的足夠保證金',
    'reports.Not enough Margin to open position': '沒有足夠的建倉保證金',
    'reports.Not enough offer': '沒有足夠的要約',
    'reports.Not Filled Orders Report': '未滿足的訂單報告',
    'reports.Not Successful': '不成功',
    'reports.notShowMam': '不顯示多帳戶管理',
    'reports.NotSuccessful': '不成功',
    'reports.number': '編號',
    'reports.objectid': '物件 ID',
    'reports.ObjectId': '訂單 ID',
    'reports.ObjectID': '物件 ID',
    'reports.OCO accepted by broker': '經紀人已接受二擇一委託單',
    'reports.OCO accepted by STP': 'STP 已接受二擇一委託單',
    'reports.OCO order activated': '已啟用二擇一委託單',
    'reports.OCO order cancel request': '取消二擇一委託單的請求',
    'reports.OCO order canceled': '已取消二擇一委託單',
    'reports.OCO order created': '已建立二擇一委託單',
    'reports.OCO Order Created': '已建立二擇一委託單',
    'reports.OCO order created is off market': '二擇一委託單已離場',
    'reports.OCO order created is unplaced': '沒有在交易所下達二擇一委託單',
    'reports.OCO order partially reject': '已部分取消二擇一委託單',
    'reports.OCO order placing request': '下達二擇一委託單的請求',
    'reports.OCO order received by broker': '經紀人已收到二擇一委託單',
    'reports.OCO order rejected': '已拒絕二擇一委託單',
    'reports.OCO order replace request': '替換二擇一委託單的請求',
    'reports.OCO order replaced': '已修改二擇一委託單',
    'reports.OCO order restated': '已重列二擇一委託單',
    'reports.OE': 'OE',
    'reports.Off market': '離場',
    'reports.ONE_MARGIN_FOR_USER_ACC': '一份使用者帳戶的保證金',
    'reports.Open': '開盤',
    'reports.Open Buy': '開盤時買入',
    'reports.Open date': '開盤日期',
    'reports.Open lots': '開盤口數',
    'reports.Open order is forbidden by trade session': '交易時段已禁止開盤訂單',
    'reports.Open order type': '開盤訂單類型',
    'reports.Open Positions By Signal Report': '按訊號報告建倉',
    'reports.Open Positions Report': '建倉報告',
    'reports.Open price': '開盤價',
    'reports.Open Sell': '開盤時賣出',
    'reports.OPEN_SPOT_ON_OPTION_EXP': '在期權到期時建立現貨部位',
    'reports.openclosebuysell': '操作類型',
    'reports.opengrosspnl': '建倉總損益',
    'reports.OpenOptionPremium': '建倉權利金',
    'reports.openorderid': '開盤訂單 ID',
    'reports.openprice': '開盤價',
    'reports.opentime': '建倉時間',
    'reports.operation': '操作',
    'reports.Operation': '操作',
    'reports.Operation Id': '操作 ID',
    'reports.Operation type': '操作類型',
    'reports.Operation Type': '操作類型',
    'reports.operation.not_enough_margin': '沒有足夠的保證金',
    'reports.operation.wrong_length': '數量太大',
    'reports.operation.wrong_length;': '數量太大',
    'reports.operation.wrong_sum': '總和有誤',
    'reports.OperationID': '操作 ID',
    'reports.OperationInDisconnectedMode': '該操作在中斷連線模式中不可用！',
    'reports.operationtype': '操作類型',
    'reports.OPTION_MASTER': '主要期權',
    'reports.option_value': '期權價值',
    'reports.OrcFIX': 'OrcFIX',
    'reports.order': '訂單',
    'reports.Order': '訂單',
    'reports.Order Book History': '訂單簿歷程記錄',
    'reports.Order Book Report': '訂單簿報告',
    'reports.Order cancel is forbidden by trade session': '交易時段已禁止取消訂單',
    'reports.Order cancel request': '取消訂單的請求',
    'reports.Order canceled successfully': '訂單取消成功',
    'reports.Order cannot be created since order amount is forbidden.': '由於訂單金額被禁止，無法建立訂單。',
    'reports.Order cannot be created since route is not active.': '由於路由無效，無法建立訂單',
    'reports.Order cannot be created, because position is in executing': '由於正在執行部位，無法建立訂單',
    'reports.Order cannot be created, because route is not active': '由於路由無效，無法建立訂單',
    "reports.Order cannot be created, because short positions are forbidden. You don't have enough rights.": '由於短部位被禁止，無法建立訂單。您沒有足夠的權利。',
    'reports.Order cannot be created, because short positions are forbidden. You don?t have enough rights.': '由於短部位被禁止，無法建立訂單。您沒有足夠的權利。',
    'reports.Order cannot be created, because short positions are forbidden.Or yor already have Limit/Stop orders for cover all your positions.': '由於短部位被禁止，無法建立訂單。或者，您已有涵蓋您的所有部位的限價/止損訂單。',
    'reports.Order cannot be placed in non-trading day. Please, check the trading calendar.': '無法在非交易日下單。請檢查交易行事曆。',
    'reports.Order groupID': '訂單群組 ID',
    'reports.Order History Report': '訂立歷程記錄報告',
    'reports.Order modification is forbidden by trade session': '交易時間已禁止修改訂單',
    'reports.Order number': '訂單 ID',
    'reports.Order placing request': '下單請求',
    'reports.Order price': '訂單價格',
    'reports.Order Price': '訂單價格',
    'reports.Order received by STP': 'STP 已收到訂單',
    'reports.Order replace request': '替換訂單的請求',
    'reports.Order replacing confirmed': '已確認訂單替換',
    'reports.Order TIF is forbidden by trade session': '交易時段已禁止訂單委託時間有效期',
    'reports.Order type': '訂單類型',
    'reports.Order type is forbidden by trade session': '交易時段已禁止訂單類型',
    'reports.Order was rejected due to invalid price!': '因價格無效而拒絕了訂單！',
    'reports.order_book_account': '帳戶',
    'reports.order_book_datetime': '日期',
    'reports.order_book_event': '事件',
    'reports.order_book_login': '登入',
    'reports.order_book_modified_by': '已由使用者修改',
    'reports.order_book_orderid': '訂單 ID',
    'reports.order_book_price': '價格',
    'reports.order_book_quantity': '數量',
    'reports.order_book_route': '路由',
    'reports.order_book_side': '側',
    'reports.order_book_stop_price': '止損價格',
    'reports.order_book_symbol': '股票代號',
    'reports.order_book_symbol_type': '股票代號類型',
    'reports.order_book_tif': '委託時間有效期',
    'reports.order_book_type': '類型',
    'reports.orderCreate': '建立訂單',
    'reports.orderExecute': '執行訂單',
    'reports.OrderExternalConfirm': '從外部確認訂單',
    'reports.orderid': '訂單 ID',
    'reports.orderId': '訂單 ID',
    'reports.OrderId': '訂單 ID',
    'reports.orderModify': '修改訂單',
    'reports.orderMutural': 'order mutual',
    'reports.orderRemoved': '已移除訂單',
    'reports.ORDERS_PANEL_HOT_BTN': '待執行訂單（熱按鈕）',
    'reports.ordertype': '訂單類型',
    'reports.Oreder Number': '訂單 ID',
    'reports.originalvalue': '原始值',
    'reports.OTHER': '其他',
    'reports.other_account_operations': '其他帳戶操作',
    'reports.Out of session trading is forbidden': '禁止在時段外交易',
    'reports.Overnight trading is forbidden for account': '禁止帳戶進行隔夜交易',
    'reports.PAMM account can not be removed during investment period.': '無法在投資期間內移除資金',
    'reports.PAMM join': '加入資金',
    'reports.pamm.OrderByPnL.max_loss': '最大損失',
    'reports.pamm.OrderByPnL.max_profit': '最大利潤',
    'reports.pamm.OrderByPnL.min_loss': '最小損失',
    'reports.pamm.OrderByPnL.min_profit': '最小利潤',
    'reports.pamm.OrderByPnL.none': '無',
    'reports.pamm.OrderBySize.max': '最大部位',
    'reports.pamm.OrderBySize.min': '最小部位',
    'reports.pamm.OrderByTime.first': '第一次建倉',
    'reports.pamm.OrderByTime.last': '最後一次建倉',
    'reports.pammState.0': '新',
    'reports.pammState.1': '投資',
    'reports.pammState.2': '投資結束',
    'reports.pammState.3': '正在清除',
    'reports.pammState.4': '等待投資人',
    'reports.pammState.6': '投資開始',
    'reports.pammState.clearing': '正在清除',
    'reports.pammState.confirmed': '已確認',
    'reports.pammState.end_of_investment': '投資結束',
    'reports.pammState.investment': '投資',
    'reports.pammState.Investment': '投資',
    'reports.pammState.new': '新',
    'reports.pammState.pending': '待決',
    'reports.pammState.start_of_investment': '投資開始',
    'reports.pammState.wait_for_investors': '等待投資人',
    'reports.PAMMTransfer': '基金轉換',
    'reports.Partially filled': '已部分滿足',
    'reports.Partially filled close Limit order': '已部分滿足收盤限價單',
    'reports.Partially filled Close Limit Order': '已部分滿足收盤限價單',
    'reports.Partially filled close Limit order (Mutual)': '已部分滿足收盤限價單（相互）',
    'reports.Partially filled close Market order': '已部分滿足收盤市價單',
    'reports.Partially filled Close Market Order': '已部分滿足收盤市價單',
    'reports.Partially filled close Market order (Mutual)': '已部分滿足收盤市價單（相互）',
    'reports.Partially filled close OCO order': '已部分滿足收盤二選一委託單',
    'reports.Partially filled close OCO order (Mutual)': '已部分滿足收盤二選一委託單（相互）',
    'reports.Partially filled close SL order': '已部分滿足收盤止損委託單',
    'reports.Partially filled close SL order (Mutual)': '已部分滿足收盤止損委託單（相互）',
    'reports.Partially filled close SL tr. stop order': '已部分滿足收盤止損跟蹤止損單',
    'reports.Partially filled close SL tr. stop order (Mutual)': '已部分滿足收盤止損跟蹤止損單（相互）',
    'reports.Partially filled close Stop Limit order': '已部分滿足收盤止損限價單',
    'reports.Partially filled close Stop Limit order (Mutual)': '已部分滿足收盤止損限價單（相互）',
    'reports.Partially filled close Stop order': '已部分滿足收盤止損單',
    'reports.Partially filled Close Stop Order': '已部分滿足收盤止損單',
    'reports.Partially filled close Stop order (Mutual)': '已部分滿足收盤止損單（相互）',
    'reports.Partially filled close TP order': '已部分滿足收盤獲利委託單',
    'reports.Partially filled close TP order (Mutual)': '已部分滿足收盤獲利委託單（相互）',
    'reports.Partially filled close Tr. stop order': '已部分滿足收盤跟蹤止損單',
    'reports.Partially filled close Tr. stop order (Mutual)': '已部分滿足收盤跟蹤止損單（相互）',
    'reports.Partially filled Limit order': '已部分滿足限價單',
    'reports.Partially filled Limit order (Mutual)': '已部分滿足限價單（相互）',
    'reports.Partially filled Market order': '已部分滿足市價單',
    'reports.Partially filled Market Order': '已部分滿足市價單',
    'reports.Partially filled Market order (Mutual)': '已部分滿足市價單（相互）',
    'reports.Partially filled OCO order': '已部分滿足二選一委託單',
    'reports.Partially filled OCO order (Mutual)': '已部分滿足二選一委託單（相互）',
    'reports.Partially filled SL order': '已部分滿足止損委託單',
    'reports.Partially filled SL order (Mutual)': '已部分滿足止損委託單（相互）',
    'reports.Partially filled SL tr. stop order': '已部分滿足止損跟蹤止損單',
    'reports.Partially filled SL tr. stop order (Mutual)': '已部分滿足止損跟蹤止損單（相互）',
    'reports.Partially filled Stop Limit order': '已部分滿足止損限價單',
    'reports.Partially filled Stop Limit order (Mutual)': '已部分滿足止損限價單（相互）',
    'reports.Partially filled Stop order': '已部分滿足止損單',
    'reports.Partially filled Stop order (Mutual)': '已部分滿足止損單（相互）',
    'reports.Partially filled TP order': '已部分滿足獲利委託單',
    'reports.Partially filled TP order (Mutual)': '已部分滿足獲利委託單（相互）',
    'reports.Partially filled Tr. stop order': '已部分滿足跟蹤止損單',
    'reports.Partially filled Tr. stop order (Mutual)': '已部分滿足跟蹤止損單（相互）',
    'reports.Partially filled Traling Stop Order': '已部分滿足跟蹤止損單',
    'reports.Password required with level - 1': '無效的密碼：新密碼至少應有 1 個大寫字元、1 個小寫字元、1 個數字。請重試',
    'reports.Password required with level - 2': '無效的密碼：新密碼至少應有 1 個大寫字元、1 個小寫字元和 1 個數字，且至少長 8 個字元。請重試',
    'reports.Password required with level - 3': '無效的密碼：新密碼至少應有 1 個大寫字元、1 個小寫字元、1 個數字，且至少長 8 個字元和至少有一個特殊符號。請重試',
    'reports.PatsystemsFIXFeed': 'PatsystemsFIXFeed',
    'reports.Pending': '待決',
    'reports.pendingorderscount': '待決訂單計數',
    'reports.PeresysFIX': 'PeresysFIX',
    'reports.Perfomance By Signal Report': '按訊號報告劃分的表現',
    'reports.Perform': '表現',
    'reports.Performance history report': '表現歷程記錄報告',
    'reports.PFixFeed': 'PFixFeed',
    'reports.phone': '電話',
    'reports.phonepassword': '電話密碼',
    'reports.pin': 'PIN 碼',
    'reports.pl_unrealized': '未實現損益',
    'reports.Placed': '已下達',
    'reports.Please deposit on your account.': '請向您的帳戶存款',
    'reports.pnl': '損益',
    'reports.port': '連接埠',
    'reports.posId': '部位 ID',
    'reports.Position': '部位',
    'reports.Position close request': '平倉請求',
    'reports.Position Closed': '已平倉',
    'reports.Position Closed (Mutual)': '已平倉（相互）',
    'reports.Position Closed (SL)': '已平倉（止損）',
    'reports.Position Closed (TP)': '已平倉（獲利）',
    'reports.Position closed successfully': '平倉成功',
    'reports.POSITION MODIFIED': '已修改部位',
    'reports.Position modify request': '修改部位的請求',
    'reports.Position number': '部位 ID',
    'reports.positionOpened': '已建倉',
    'reports.Position Opened (Limit Order)': '已建倉（限價單）',
    'reports.Position Types Ratio By Instrument': '按股票代號劃分的部位類型比率',
    'reports.Position：{1} 平倉成功': '部位：{1} 平倉成功',
    'reports.positionamount': '部位金額',
    'reports.positionid': '部位 ID',
    'reports.positionId': '部位 ID',
    'reports.PositionId': '部位 ID',
    'reports.Positions History Report': '部位歷程記錄報告',
    'reports.Positions On Chosen Moment Report': '選定的時刻報告中的部位',
    'reports.POSITIONS_PANEL_HOT_BTN': '部位面板（熱按鈕）',
    'reports.positionstatus': '部位狀態',
    'reports.price': '價格',
    'reports.Price': '價格',
    'reports.Price is too close to current Ask price': '價格太接近當前要價',
    'reports.Price is too close to current Bid price': '價格太接近當前出價',
    'reports.priceExchange': '價格交換',
    'reports.priceExternal': '外部價格',
    'reports.product type': '產品類型',
    'reports.productType': '產品類型',
    'reports.PROFILE_LANGUAGE': '設定檔語言',
    'reports.profit': '利潤',
    'reports.Projected balance': '預期餘額',
    'reports.protocol': '協定',
    'reports.ProTrader': 'Protrader',
    'reports.ProTraderFIX': 'ProTraderFIX',
    'reports.Purchase And Sales Report': '買賣報告',
    'reports.purchaseprice': '暴險',
    'reports.quantity': '數量',
    'reports.QueueMaxSize': '佇列最大大小',
    'reports.quicktable.noData': '沒要要顯示的資料。請選取所需的報告標準，以獲取必要的資訊。',
    'reports.Quote is too old': '報價太舊',
    'reports.QuoteDelay(sec)': '報價延遲（秒）',
    'reports.quoteticker': '報價看板',
    'reports.raor_administrator': '經紀人',
    'reports.raor_approvetime': '核准時間',
    'reports.raor_balance': '從帳戶餘額',
    'reports.raor_basis': '註解',
    'reports.raor_commission_percent': '百分比佣金',
    'reports.raor_counterbalance': '至帳戶餘額',
    'reports.raor_countersum': '至帳戶總額',
    'reports.raor_crossprice': '交叉價格',
    'reports.raor_fromaccount': '從帳戶',
    'reports.raor_operationtime': '經營時間',
    'reports.raor_operationtype': '操作類型',
    'reports.raor_requestid': '操作 ID',
    'reports.raor_state': '核准狀態',
    'reports.raor_sum': '從帳戶總額',
    'reports.raor_sum_without_commission': '總額（不含佣金）',
    'reports.raor_toaccount': '至帳戶',
    'reports.rate': '費率',
    'reports.Rates Report': '費率報告',
    'reports.Realised profit/loss': '已實現的損益',
    'reports.Reason for rejection：交易已被風險規則停用。.': '拒絕原因：交易已被風險規則停用。.',
    'reports.receiveBestBidOfferOnly': '僅接收最佳出價要約',
    'reports.recipient': '收件者',
    'reports.ReconnectInterval': '重新連線間隔',
    'reports.refreshInterval': '重新整理間隔',
    'reports.RefreshonLogon': '在登入時重新整理',
    'reports.RefreshOnLogon': '在登入時重新整理',
    'reports.Refused': '被拒',
    'reports.Refused Amount': '已取消數量',
    'reports.Rejected': '已被拒',
    'reports.remoteid': '遠端 ID',
    'reports.Remove SL for': '移除以下對象的止損：',
    'reports.Removed Orders Report': '已移除的訂單報告',
    'reports.removedtime': '已移除的時間',
    'reports.removeliquidityrebate': '移除流動性回扣',
    'reports.Replace request received by STP': 'STP 已收到替換請求',
    'reports.REPORT_RULES': '報告可見度',
    'reports.report.currency': '貨幣',
    'reports.report.route': '路由',
    'reports.reportAccountSummaryReport': '帳戶報表報告',
    'reports.reportAllActiveOrdersReport': '活躍訂單報告',
    'reports.reportAllNotActiveOrdersReport': '已滿足的訂單報告',
    'reports.reportAllRemovedOrdersReport': '已移除的訂單報告',
    'reports.reportBalanceByInstrument': '按股票代號報告劃分的餘額',
    'reports.reportBalanceReport': '餘額報告',
    'reports.reportFormalDialogReport': '正式對話報告',
    'reports.reportInformalDialogReport': '圖表歷程記錄報告',
    'reports.reportNewUsersReport': '新使用者報告',
    'reports.reportOpenedPositionReport': '已建倉部位報告',
    'reports.reportPerfomanceReport': '表現報告',
    'reports.reportPurchaseAndSalesReport': '買賣報告',
    'reports.reportSummaryNewReport': '報告摘要',
    'reports.reportSummaryReport': '餘額摘要報告',
    'reports.reportTradeOperationReport': '交易操作報告',
    'reports.reportTradesReport': '交易報告',
    'reports.reportUsersReport': '使用者報告',
    'reports.Request Account Operation Report': '請求帳戶操作報告',
    'reports.Request is not allowed for this account': '不允許該帳戶的請求',
    'reports.Request not supported': '請求不受支援',
    'reports.RequestAccountOperationReport': '請求帳戶操作報告',
    'reports.Required Balance': '必要的餘額',
    'reports.REQUIRED_STRONG_PASSWORDS': '需要強密碼',
    'reports.ResetOnDisconnect': '中斷連線時重設',
    'reports.ResetOnLogon': '登入時重設',
    'reports.ResetOnLogout': '登出時重設',
    'reports.Restated': '已重列',
    'reports.Reuters': '路透社',
    'reports.Revenue plan': '收入計畫',
    'reports.REVENUE_PLAN_GENERAL': '一般',
    'reports.revenue.name': '收入計畫名稱',
    'reports.revenue.speccrossinstrument': '特殊十字符號',
    'reports.revenue.speccurrency': '特殊貨幣',
    'reports.Reverse': '沖銷',
    'reports.reward_type.capital_percent': '資本，%',
    'reports.reward_type.fixed': '固定',
    'reports.reward_type.fixed_and_percent': '固定 + 投資期間增長，%',
    'reports.reward_type.no': '否',
    'reports.reward_type.percent': '投資期間增長，%',
    'reports.role': '角色',
    'reports.Rollback': '復原',
    'reports.Rollback position': '復原部位',
    'reports.Rollback position request': '復原部位請求',
    'reports.route': '路由',
    'reports.Route': '路由',
    'reports.Route Trading Statistics Report': '路由交易統計資料報告',
    'reports.Route Visibility': '路由可見度報告',
    'reports.Route Visibility Report': '路由可見度報告',
    'reports.RouteInstrMap': '股票代號地圖',
    'reports.routename': '路由',
    'reports.routes': '路由',
    'reports.routes.account': '連結的費用帳戶',
    'reports.routes.allow_day': '天',
    'reports.routes.allow_foc': 'FOK',
    'reports.routes.allow_gtc': 'GTC',
    'reports.routes.allow_ioc': 'IOC',
    'reports.routes.allow_market': '市價',
    'reports.routes.allow_moc': '允許 MOC',
    'reports.routes.allow_moo': '允許 MOO',
    'reports.routes.allow_stop': '止損',
    'reports.routes.allow_stoplimit': '限價',
    'reports.routes.allow_stoptolimit': '止損限價',
    'reports.routes.route_comment': '註解',
    'reports.routes.route_external_feed': '流動性提供者',
    'reports.routes.route_isactive': '為有效',
    'reports.routes.route_isinfo': '為資訊',
    'reports.routes.route_istrade': '為交易',
    'reports.routes.route_login': '登入',
    'reports.routes.route_name': '名稱',
    'reports.routes.route_password': '密碼',
    'reports.routes.route_saveQuotes': '儲存分開的歷程記錄',
    'reports.routes.route_status': '狀態',
    'reports.ROUTESVIS_RULES': '路由可見度',
    'reports.RssNews': 'RSS 新聞',
    'reports.RTS': 'RTS',
    'reports.rules.error.restriction.max': '數字必須少於',
    'reports.rules.error.restriction.min': '數字必須大於',
    'reports.SAVED_ORDERS': '已儲存的訂單',
    'reports.saveLogs': '儲存日誌',
    'reports.saveNews': '儲存新聞',
    'reports.Saxo Bank': '盛寶銀行',
    'reports.SCALPER': '帽客',
    'reports.Scheduler Log Report': '排程器日誌報告',
    'reports.scheduler.accountoperation_archive': '封存帳戶操作',
    'reports.scheduler.dailyreport': '傳送報告',
    'reports.scheduler.dividentsservice': '執行部位上的股利',
    'reports.scheduler.lastexcecuted': '執行時間為',
    'reports.scheduler.modifyRuleTask': '正在修改任務',
    'reports.scheduler.movepositiontask': '移動部位任務',
    'reports.scheduler.openclosetradingfor': '打開/關閉交易',
    'reports.scheduler.opentradingfor': '打開交易',
    'reports.scheduler.parameter.backup': '備份水準',
    'reports.scheduler.parameter.calculateByClosePrice': '使用當前的收盤價',
    'reports.scheduler.parameter.calculateStrategy': '正在計算類型',
    'reports.scheduler.parameter.coeff1': '第 1 部分',
    'reports.scheduler.parameter.coeff2': '第 2 部分',
    'reports.scheduler.parameter.comment': '帳戶操作註解',
    'reports.scheduler.parameter.counteraccount': '櫃枱帳戶',
    'reports.scheduler.parameter.days_before_exp': '到期前的天數',
    'reports.scheduler.parameter.divident': '股利數量',
    'reports.scheduler.parameter.dividentmode': '股利類型',
    'reports.scheduler.parameter.email': '電子郵件（以逗號隔開）',
    'reports.scheduler.parameter.inheritUserGroups': '包括子群組',
    'reports.scheduler.parameter.instrtype': '股票代號群組',
    'reports.scheduler.parameter.instrument': '股票代號',
    'reports.scheduler.parameter.isopen': '打開/關閉交易',
    'reports.scheduler.parameter.isupdateall': '更新群組內的全部工具',
    'reports.scheduler.parameter.margincoeff': '保證金係數（基於時間）',
    'reports.scheduler.parameter.max': '最大 (%)',
    'reports.scheduler.parameter.message': '訊息',
    'reports.scheduler.parameter.min': '最小 (%)',
    'reports.scheduler.parameter.operations': '操作類型',
    'reports.scheduler.parameter.owner': '物件',
    'reports.scheduler.parameter.ownerType': '物件類型',
    'reports.scheduler.parameter.putinaleerts': '放入提醒內',
    'reports.scheduler.parameter.reconciliation.ClientId': '調節的客戶端 ID',
    'reports.scheduler.parameter.reconciliation.Direction': '指示',
    'reports.scheduler.parameter.reconciliation.FromDate': '開始調節起始日期',
    'reports.scheduler.parameter.reconciliation.Route': '路由',
    'reports.scheduler.parameter.reconciliation.SendItemName': '正在傳送檔案名稱',
    'reports.scheduler.parameter.reconciliation.Storage': '儲存類型',
    'reports.scheduler.parameter.reconciliation.Transport': '運輸類型',
    'reports.scheduler.parameter.reconciliation.transportDestination': '目的地',
    'reports.scheduler.parameter.reconciliation.transportLogin': '驗證登入',
    'reports.scheduler.parameter.reconciliation.transportPassword': '驗證密碼',
    'reports.scheduler.parameter.reconciliation.Type': '調節類型',
    'reports.scheduler.parameter.reports': '選取報告',
    'reports.scheduler.parameter.revenueplan': '選取收入計畫',
    'reports.scheduler.parameter.routes': '每條有效的路由必須',
    'reports.scheduler.parameter.routesName': '包括路由',
    'reports.scheduler.parameter.rule': '規則',
    'reports.scheduler.parameter.ruleType': '規則類型',
    'reports.scheduler.parameter.ruleView': '值',
    'reports.scheduler.parameter.sum': '系統貨幣中的數量',
    'reports.scheduler.parameter.swapCoefKey': '掉期係數',
    'reports.scheduler.parameter.userGroup': '使用者群組',
    'reports.scheduler.parameter.usergroups': '選取使用者群組',
    'reports.scheduler.removedo': '執行日訂單移除服務',
    'reports.scheduler.rolloverservice': '執行期貨轉倉',
    'reports.scheduler.runinterestservice': '執行餘額利息服務',
    'reports.scheduler.runsignalsubscribtion': '執行訊號的每日收費佣金',
    'reports.scheduler.runswaptask': '執行掉期',
    'reports.scheduler.splitinstrument': '拆分股票代碼',
    'reports.scheduler.type.accountoperation': '帳戶操作',
    'reports.scheduler.type.backup': '建立備份',
    'reports.scheduler.type.beginbalance': '期初餘額',
    'reports.scheduler.type.broadcastmessage': '線上廣播訊息',
    'reports.scheduler.type.changemargincoeff': '保證金係數（基於時間）',
    'reports.scheduler.type.changerevenue': '變更收入計畫',
    'reports.scheduler.type.closeoptions': '隱藏到期的股票代號',
    'reports.scheduler.type.deletedemousers': '刪除到期的示範使用者',
    'reports.scheduler.type.deletequotes': '刪除舊報價',
    'reports.scheduler.type.fixswapplan': '計算定息掉期計畫',
    'reports.scheduler.type.futuresrollover': '執行期貨轉倉',
    'reports.scheduler.type.insertlastquotestask': '插入最後的報價',
    'reports.scheduler.type.lockbybroker': '被經紀人鎖定',
    'reports.scheduler.type.message': '訊息',
    'reports.scheduler.type.modifyobject': '修改物件',
    'reports.scheduler.type.pamm': '基金任務',
    'reports.scheduler.type.pendingpayment': '等待付款',
    'reports.scheduler.type.reconciliation': '調節任務',
    'reports.scheduler.type.reconnect_routes': '有效路由開始/停止',
    'reports.scheduler.type.subscribe': '獲取訂閱付款',
    'reports.scheduler.type.unknown': '未知類型',
    'reports.scheduler.type.updatespikefilter': '更新價格大幅波動篩選器',
    'reports.scheduler.type.updateuserstradinginfo': '更新使用者交易統計資料資訊',
    'reports.ScreenLogEvents': '螢幕日誌事件',
    'reports.ScreenLogShowHeartBeats': '螢幕日誌顯示活動訊息',
    'reports.ScreenLogShowIncoming': '螢幕日誌顯示傳入',
    'reports.ScreenLogShowOutgoing': '螢幕日誌顯示傳出',
    'reports.Script': '指令碼',
    'reports.SDK': 'SDK',
    'reports.seconds': '秒',
    'reports.SecuritiesTransferTax': '證券過戶稅',
    'reports.Self trading is not allowed.': '不允許進行自我交易。',
    'reports.Sell': '賣出',
    'reports.sell trade amount': '賣出交易金額',
    'reports.sell trades': '賣出交易',
    'reports.Send from': '傳送自',
    'reports.Send_Rej': '拒絕',
    'reports.sender': '寄件者',
    'reports.SenderCompID': '寄件者電腦 ID',
    'reports.senderip': '寄件者 IP',
    'reports.SendResetSeqNumFlag': '傳送重設後續編號旗標',
    'reports.sendTag200': '傳送 Tag200',
    'reports.server': '伺服器',
    'reports.server_url': '伺服器 URL',
    'reports.SERVER.AUTO.RECONNECT': '伺服器自動重新連線',
    'reports.SERVER.POLLING.INTERVAL': '伺服器輪詢間隔',
    'reports.serviceName': '服務名稱',
    'reports.sessionid': '對話 ID',
    'reports.sessiontype': '對話類型',
    'reports.SETTINGS_FILTER': '篩選器',
    'reports.SETTINGS_GENERAL': '一般',
    'reports.SETTINGS_LOCKING': '正在鎖定',
    'reports.SETTINGS_QUOTE_CACHE_SIZE': '報價快取大小設定',
    'reports.SETTINGS_QUOTE_SAVE': '報價儲存設定',
    'reports.SETTINGS_QUOTES': '報價',
    'reports.SETTINGS_REGISTRATION': '註冊',
    'reports.SETTINGS_TRADING': '交易',
    'reports.SettleDate': '到期日',
    'reports.SettleDateForward': '價值日期',
    'reports.SettlementFee': '結算費',
    'reports.Sharia Operations Report': '伊斯蘭法操作報告',
    'reports.signedrequest': '已簽署的請求',
    'reports.sizeForQuoteSubscription': '報價規模訂閱',
    'reports.SL': '止損',
    'reports.SL accepted by broker': '經紀人已接受止損委託單',
    'reports.SL accepted by STP': 'STP 已接受止損委託單',
    'reports.SL order accepted': '已接受止損單',
    'reports.SL order cancel request': '取消止損委託單的請求',
    'reports.SL order canceled': '已取消止損委託單',
    'reports.SL order created': '已建立止損委託單',
    'reports.SL Order Created': '已建立止損委託單',
    'reports.SL order created is off market': '止損委託單已離場',
    'reports.SL order created is unplaced': '沒有在交易所下達止損委託單',
    'reports.SL Order Modified': '已修改止損委託單',
    'reports.SL order partially reject': '已部分取消止損委託單',
    'reports.SL order placing request': '下達止損委託單的請求',
    'reports.SL order received by broker': '經紀人已收到止損委託單',
    'reports.SL order rejected': '已拒絕止損委託單',
    'reports.SL Order Removed': '已移除止損單',
    'reports.SL order replace request': '替換止損委託單的請求',
    'reports.SL order replaced': '已修改止損委託單',
    'reports.SL price': '止損價格',
    'reports.SL tr. stop': '止損跟蹤止損',
    'reports.SL tr. stop accepted by broker': '經紀人已接受止損跟蹤止損單',
    'reports.SL tr. stop accepted by STP': 'STP 已接受止損跟蹤止損單',
    'reports.SL tr. stop order accepted': '已接受止損跟蹤止損單',
    'reports.SL tr. stop order canceled': '已取消止損跟蹤止損單',
    'reports.SL tr. stop order created': '已建立止損跟蹤止損單',
    'reports.SL tr. stop order created is off market': '止損跟蹤止損單已離場',
    'reports.SL tr. stop order created is unplaced': '沒有在交易所下達止損跟蹤止損單',
    'reports.SL tr. stop order partially reject': '已部分取消止損跟蹤止損單',
    'reports.SL tr. stop order received by broker': '經紀人已收到止損跟蹤止損單',
    'reports.SL tr. stop order rejected': '已拒絕止損跟蹤止損單',
    'reports.SL tr. stop order replaced': '已修改止損跟蹤止損單',
    'reports.SmartRoute(aggregator)': '拆分模擬器',
    'reports.Sms generation error': '簡訊產生錯誤',
    'reports.SMTP server required a secure connection, or identity of the client has not been established.': 'SMTP 伺服器需要安全的連線，或尚未建立客戶端的身份。',
    'reports.SOAP API methods are not available for execution for this user': 'SOAP 相對強弱指數法不可用於為該使用者執行操作',
    'reports.SocketConnectHost': '通訊端連接主機',
    'reports.SocketConnectPort': '通訊端連接連接埠',
    'reports.SocketKeyStore': '通訊端 Keystore',
    'reports.SocketKeyStorePassword': '通訊端 keystore 密碼',
    'reports.SocketUseSSL': '通訊端使用 SSL',
    'reports.SplitOrders': '拆分訂單',
    'reports.spread': '價差',
    'reports.Spread Amount Report': '價差金額報告',
    'reports.spreadpnl': '價差損益',
    'reports.Start receiving data from server...': '開始接收伺服器的資料...',
    'reports.StartDay': '開始日',
    'reports.StartTime': '開始時間',
    'reports.STATIONS_RULES': '站台',
    'reports.Statistic Report': '統計資料報告',
    'reports.STATUS_BLOCKED': '被凍結',
    'reports.stock_req': '股票訂單要求',
    'reports.stock_value': '股票價值',
    'reports.StockOperation': '股票操作',
    'reports.stocks_liquidity': '股票流動性',
    'reports.Stop accepted by broker': '經紀人已接受止損單',
    'reports.Stop accepted by STP': 'STP 已接受止損單',
    'reports.Stop Limit accepted by broker': '經紀人已接受止損限價單',
    'reports.Stop Limit accepted by STP': 'STP 已接受止損限價單',
    'reports.Stop Limit order accepted': '已接受止損限價單',
    'reports.Stop Limit order activated': '已啟用止損限價單',
    'reports.Stop limit order cancel request': '取消止損限價單的請求',
    'reports.Stop limit order canceled': '已取消止損限價單',
    'reports.Stop Limit order canceled': '已取消止損限價單',
    'reports.Stop Limit order created': '已建立止損限價單',
    'reports.Stop Limit Order Created': '已建立止損限價單',
    'reports.Stop Limit order created is off market': '止損限價單已離場',
    'reports.Stop Limit order created is unplaced': '沒有在交易所下達止損限價單',
    'reports.Stop limit order is awaiting market': '止損限價單正在等待市場',
    'reports.Stop Limit Order Modified': '已修改止損限價單',
    'reports.Stop Limit order partially reject': '已部分取消止損限價單',
    'reports.Stop limit order placing request': '下達止損限價單的請求',
    'reports.Stop Limit order received by broker': '經紀人已收到止損限價單',
    'reports.Stop Limit order rejected': '已拒絕止損限價單',
    'reports.Stop Limit Order Removed': '已移除止損限價單',
    'reports.Stop limit order replace request': '替換止損限價單的請求',
    'reports.Stop limit order replaced': '已修改止損限價單',
    'reports.Stop Limit order replaced': '已修改止損限價單',
    'reports.Stop Limit order restated': '已重列止損限價單',
    'reports.Stop loss order canceled': '已取消止損單',
    'reports.Stop Loss price is too big': '訂單的止損價格無效',
    'reports.Stop order accepted': '已接受止損單',
    'reports.Stop order activated': '已啟用止損單',
    'reports.Stop order cancel request': '取消止損單的請求',
    'reports.Stop order canceled': '已取消止損單',
    'reports.Stop order created': '已建立止損單',
    'reports.Stop Order Created': '已建立止損單',
    'reports.Stop order created is off market': '止損單已離場',
    'reports.Stop order created is unplaced': '沒有在交易所下達止損單',
    'reports.Stop order is awaiting market': '止損單正在等待市場',
    'reports.Stop Order Modified': '已修改止損單',
    'reports.Stop order partially reject': '已部分取消止損單',
    'reports.Stop order placing request': '下達止損單的請求',
    'reports.Stop order received by broker': '經紀人已收到止損單',
    'reports.Stop order rejected': '已拒絕止損單',
    'reports.Stop Order Removed': '已移除止損單',
    'reports.Stop order replace request': '替換止損單的請求',
    'reports.Stop order replaced': '已修改止損單',
    'reports.Stop order restated': '已重列止損單',
    'reports.Stop price': '止損價格',
    'reports.stoploss': '止損',
    'reports.StopOutType': '止損離場類型',
    'reports.stopprice': '止損價格',
    'reports.STP_Route_Change': '在訂單中設定原始路由名稱',
    'reports.Strike Price': '執行價格',
    'reports.submitted': '已送出',
    'reports.Subscribe': '訂閱',
    'reports.SUBSCRIBE_RULES': '訂閱計畫',
    'reports.success': '成功',
    'reports.Successful': '成功',
    'reports.sum': '總和',
    'reports.Sum': '總和',
    'reports.Summary Report': '報告摘要',
    'reports.SunGard': 'SunGard',
    'reports.SUSPEND_MARGINCALL_ON_CLOSE_MARKET': '暫停使用封閉式市場的保證金催交助理',
    'reports.swap': '掉期',
    'reports.swapbuy': '做多掉期',
    'reports.swapplan': '掉期計畫',
    'reports.swaps': '掉期',
    'reports.swaps_in': '換入',
    'reports.swaps_out': '換出',
    'reports.swapsell': '做空掉期',
    'reports.symbol': '股票代號',
    'reports.Symbol': '股票代號',
    'reports.symbol_type': '股票代號類型',
    'reports.System': '系統',
    'reports.system_currency': '系統貨幣',
    'reports.systemMonitor.instruments': '股票代號',
    'reports.systemMonitor.onlineConnections': '線上連線',
    'reports.systemMonitor.positions': '建倉',
    'reports.systemMonitor.regusers': '註冊使用者',
    'reports.systemMonitor.runningRoutes': '已從有效的路由開始路由',
    'reports.systemMonitor.totalQueueSize': '服務中的佇列',
    'reports.TABLE_ALERT': '表格提醒',
    'reports.tablename': '表格名稱',
    'reports.TableName': '表格名稱',
    'reports.Take profit order canceled': '已取消獲利單',
    'reports.Take Profit price is too big': '獲利價格太高',
    'reports.takeprofit': '獲利',
    'reports.TargetCompID': '目標電腦 ID',
    'reports.task.comment': '註解',
    'reports.task.day': '天',
    'reports.task.hour': '小時',
    'reports.task.minute': '分鐘',
    'reports.task.month': '月',
    'reports.task.timezone': '時區互抵',
    'reports.task.usedst': '使用夏令時',
    'reports.task.year': '年',
    'reports.TempFilePath': '臨時檔案路徑',
    'reports.Tenfore': 'Tenfore',
    'reports.terminal': '終端',
    'reports.Terminal': '終端',
    'reports.TERMINAL_API': 'API',
    'reports.TERMINAL_COMMUNITY': '社群',
    'reports.TERMINAL_FIX': 'FIX 連線',
    'reports.TERMINAL_HTML': 'HTML',
    'reports.TERMINAL_JAVA': 'Java',
    'reports.TERMINAL_MOBILE': '行動裝置',
    'reports.TERMINAL_NET': '.Net',
    'reports.TERMINAL_POCKETPC': 'Pocket PC',
    'reports.TERMINAL_WEB': 'WebStation',
    'reports.terminaltype': '終端類型',
    'reports.TFDS Quote Receiver': 'TFDS 報價接收器',
    'reports.tif': '委託時間有效期',
    'reports.TIF': '委託時間有效期',
    'reports.time': '時間',
    'reports.Time': '日期/時間',
    'reports.to': '至',
    'reports.toDate': '至',
    'reports.toDateConnAttempt': '至',
    'reports.toDatePwdChange': '至（密碼）',
    'reports.tooLargeData': '報告的資料太大！顯示第 {1} 列，共 {2} 列',
    'reports.total': '總計',
    'reports.total traded amount': '總交易金額',
    'reports.totalfee': '總費用',
    'reports.TP': '獲利',
    'reports.TP accepted by broker': '經紀人已接受獲利委託單',
    'reports.TP accepted by STP': 'STP 已接受獲利委託單',
    'reports.TP order accepted': '已接受獲利單',
    'reports.TP order cancel request': '取消獲利委託單的請求',
    'reports.TP order canceled': '已取消獲利委託單',
    'reports.TP order created': '已建立獲利委託單',
    'reports.TP Order Created': '已建立獲利委託單',
    'reports.TP order created is off market': '獲利委託單已離場',
    'reports.TP order created is unplaced': '沒有在交易所下達獲利委託單',
    'reports.TP Order Modified': '已修改獲利委託單',
    'reports.TP order partially reject': '已部分取消獲利委託單',
    'reports.TP order placing request': '下達獲利委託單的請求',
    'reports.TP order received by broker': '經紀人已收到獲利委託單',
    'reports.TP order rejected': '已拒絕獲利委託單',
    'reports.TP Order Removed': '已移除獲利單',
    'reports.TP order replace request': '替換獲利委託單的請求',
    'reports.TP order replaced': '已修改獲利委託單',
    'reports.Tr stop loss order cancel request': '取消跟蹤止損單的請求',
    'reports.Tr stop loss order placing request': '下達跟蹤止損單的請求',
    'reports.Tr stop loss order replace request': '替換跟蹤止損單的請求',
    'reports.tr. stop': '跟蹤止損',
    'reports.Tr. stop accepted by broker': '經紀人已接受跟蹤止損單',
    'reports.Tr. stop accepted by STP': 'STP 已接受跟蹤止損單',
    'reports.Tr. stop is too big': '訂單的跟蹤止損互抵無效',
    'reports.Tr. stop loss order accepted': '已接受跟蹤止損單',
    'reports.Tr. stop loss order canceled': '已取消跟蹤止損單',
    'reports.Tr. stop loss order created': '已建立跟蹤止損單',
    'reports.Tr. stop loss order created is off market': '跟蹤止損單已離場',
    'reports.Tr. stop loss order created is unplaced': '沒有在交易所下達跟蹤止損單',
    'reports.Tr. stop loss order replaced': '已修改跟蹤止損單',
    'reports.Tr. stop order accepted': '已接受跟蹤止損單',
    'reports.Tr. stop order activated': '已啟用跟蹤止損單',
    'reports.Tr. stop order canceled': '已取消跟蹤止損單',
    'reports.Tr. stop order created': '已建立跟蹤止損單',
    'reports.Tr. stop order created is off market': '跟蹤止損單已離場',
    'reports.Tr. stop order created is unplaced': '沒有在交易所下達跟蹤止損單',
    'reports.Tr. stop order is awaiting market': '跟蹤止損單正在等待市場',
    'reports.Tr. stop order partially reject': '已部分取消跟蹤止損單',
    'reports.Tr. stop order received by broker': '經紀人已收到跟蹤止損單',
    'reports.Tr. stop order placing request': '下達跟蹤止損單的請求',
    'reports.Tr. stop order cancel request': '取消跟蹤止損單的請求',
    'reports.Tr. stop order replace request': '替換跟蹤止損單的請求',
    'reports.Tr. stop order rejected': '已拒絕跟蹤止損單',
    'reports.Tr. stop order replaced': '已修改跟蹤止損單',
    'reports.Tr. stop order restated': '已重列跟蹤止損單',
    'reports.Tr.SL Order Removed': '已移除跟蹤止損單',
    'reports.Trade executed': '已執行交易',
    'reports.Trade executed (Mutual)': '已執行交易（相互）',
    'reports.Trade executed (SL)': '已執行交易（止損）',
    'reports.Trade Operations Report': '交易操作報告',
    'reports.Trade Periods By Signal Report': '按訊號報告劃分的交易期間',
    'reports.trade.BeginString': '交易開始字串',
    'reports.trade.CheckCompID': '交易檢查電腦 ID',
    'reports.trade.CheckLatency': '交易檢查延遲',
    'reports.trade.ClosedResendInterval': '已關閉的交易重新傳送間隔',
    'reports.trade.ConnectionType': '交易連線類型',
    'reports.trade.ContinueInitializationOnError': '交易在發生錯誤時繼續初始化',
    'reports.trade.DataDictionary': '交易資料字典',
    'reports.trade.EndDay': '交易結束日',
    'reports.trade.EndTime': '交易結束時間',
    'reports.trade.entityId': '交易實體 ID',
    'reports.trade.FileIncludeMilliseconds': '交易檔案包括毫秒',
    'reports.trade.FileIncludeTimeStampForMessages': '交易檔案包括訊息的時間戳記',
    'reports.trade.FileLogHeartbeats': '交易檔案日誌活動訊號',
    'reports.trade.fxintegrate.cert.host.verify': '交易外匯整合憑證主機驗證',
    'reports.trade.fxintegrate.cert.root.ca.file': '交易外匯整合憑證根本原因檔',
    'reports.trade.fxintegrate.host': '交易外匯整合主機',
    'reports.trade.fxintegrate.logging.file': '交易外匯整合記錄檔',
    'reports.trade.fxintegrate.logging.maxfilecount': '交易外匯整合記錄最大檔案計數',
    'reports.trade.fxintegrate.logging.output': '交易外匯整合記錄輸出',
    'reports.trade.fxintegrate.logging.priority': '交易外匯整合記錄優先順序',
    'reports.trade.fxintegrate.port': '交易外匯整合連接埠',
    'reports.trade.fxintegrate.protocol': '交易外匯整合協定',
    'reports.trade.fxintegrate.usessl': '交易外匯整合使用 SSL',
    'reports.trade.HeartBtInt': '交易活動訊號間隔',
    'reports.trade.JdbcDriver': '交易 JDBC 驅動程式',
    'reports.trade.JdbcPassword': '交易 JDBC 密碼',
    'reports.trade.JdbcURL': '交易 JDBC URL',
    'reports.trade.JdbcUser': '交易 JDBC 使用者',
    'reports.trade.keystoreAlias': '交易 Keystore 別名',
    'reports.trade.keystoreFile': '交易 Keystore 檔案',
    'reports.trade.LogFileMaxSize': '交易日誌檔最大大小',
    'reports.trade.login': '交易登入',
    'reports.trade.MaxLogsBackups': '交易最大日誌備份數',
    'reports.trade.MillisecondsinTimeStamp': '時間戳記中的毫秒',
    'reports.trade.MillisecondsInTimeStamp': '時間戳記中的交易毫秒',
    'reports.trade.password': '交易密碼',
    'reports.trade.ReconnectInterval': '交易重新連線間隔',
    'reports.trade.RefreshonLogon': '在登入時重新整理',
    'reports.trade.RefreshOnLogon': '在登入時重新整理交易',
    'reports.trade.ResetOnDisconnect': '在中斷連線時重設交易',
    'reports.trade.ResetOnLogon': '在登入時重設交易',
    'reports.trade.ResetOnLogout': '在登出時重設交易',
    'reports.trade.saveLogs': '交易儲存日誌',
    'reports.trade.ScreenLogEvents': '交易螢幕日誌事件',
    'reports.trade.ScreenLogShowHeartBeats': '交易螢幕日誌顯示活動訊息',
    'reports.trade.ScreenLogShowIncoming': '交易螢幕日誌顯示傳入',
    'reports.trade.ScreenLogShowOutgoing': '交易螢幕日誌顯示傳出',
    'reports.trade.SenderCompID': '交易寄件者電腦 ID',
    'reports.trade.SendResetSeqNumFlag': '交易傳送重設後續編號旗標',
    'reports.trade.SocketConnectHost': '交易通訊端連接主機',
    'reports.trade.SocketConnectPort': '交易通訊端連接連接埠',
    'reports.trade.SocketKeyStore': '交易通訊端 Keystore',
    'reports.trade.SocketKeyStorePassword': '交易通訊端 keystore 密碼',
    'reports.trade.SocketUseSSL': '交易通訊端使用 SSL',
    'reports.trade.StartDay': '交易開始日',
    'reports.trade.StartTime': '交易開始時間',
    'reports.trade.TargetCompID': '交易目標電腦 ID',
    'reports.trade.UseDataDictionary': '交易使用資料字典',
    'reports.trade.ValidateFieldsHaveValues': '交易驗證欄位有值',
    'reports.trade.ValidateFieldsOutOfOrder': '交易驗證欄位順序混亂',
    'reports.trade.ValidateUserDefinedFields': '交易驗證使用者定義的欄位',
    'reports.tradeamount': '交易金額',
    'reports.tradeid': '交易 ID',
    'reports.tradePassword': '交易密碼',
    'reports.trader': '交易員',
    'reports.Trades by Signal': '按訊號劃分的交易',
    'reports.Trades Report': '交易報告',
    'reports.Trades report by instrument': '按工具劃分的交易報告',
    'reports.trades_bought': '買入交易',
    'reports.trades_sold': '賣出交易',
    'reports.tradevolume': '成交量',
    'reports.trading': '交易',
    'reports.Trading': '交易',
    'reports.Trading disabled by risk rules': '交易已被風險規則停用',
    'reports.TRADING_IDEA_CARD': '交易構思卡',
    'reports.trading_in': '折價貼換',
    'reports.trading_out': '以物換物',
    'reports.TRADING_RULES_ACC': '交易規則（帳戶）',
    'reports.TRADING_RUSLES': '交易規則',
    'reports.TRADINGSYSTEMLIST': '策略經理',
    'reports.Traiding out of Main session is forbidden for this account': '禁止該帳戶在主要時段外交易',
    'reports.Trailing stop order cancel request': '取消跟蹤止損單的請求',
    'reports.Trailing Stop Order Created': '已建立跟蹤止損單',
    'reports.Trailing Stop Order Modified': '已修改跟蹤止損單',
    'reports.Trailing stop order placing request': '下達跟蹤止損單的請求',
    'reports.Trailing Stop Order Removed': '已移除跟蹤止損單',
    'reports.Trailing stop order replace request': '替換跟蹤止損單的請求',
    'reports.TrailingStop': '跟蹤止損',
    'reports.Transfer': '過戶',
    'reports.TransferFee': '過戶費',
    'reports.True': '真',
    'reports.type': '類型',
    'reports.Type': '類型',
    'reports.Types': '類型',
    'reports.UBSFIXFeed': 'UBSFIXFeed',
    'reports.Unable to cancel the order since route is not active.': '由於路由無效，無法取消訂單。',
    'reports.Unblocking': '正在解除凍結',
    'reports.Unplaced': '未下達',
    'reports.unrealized p/l': '未實現的損益',
    'reports.unrealizedpnl': '未實現的損益',
    'reports.unsettled_cash': '未結算的現金',
    'reports.updatedtime': '更新時間',
    'reports.Updater': '更新器',
    'reports.URL': 'URL',
    'reports.usage': '用途',
    'reports.USE_OPEN_PRICE_IN_MARGIN': '使用開盤價',
    'reports.UseDataDictionary': '使用資料字典',
    'reports.usePrevQuotedOrders': '使用上一批報價單',
    'reports.UsePriority': '使用優先順序',
    'reports.user': '使用者',
    'reports.User': '使用者',
    'reports.User Authorization Error': '使用者授權錯誤',
    'reports.user group': '使用者群組',
    'reports.User Previledges Report': '使用者特權報告',
    'reports.user.acc_4_signals': '訊號帳戶',
    'reports.user.address': '地址',
    'reports.user.amount': '金額',
    'reports.user.amountType': '金額類型',
    'reports.user.birthday': '出生日期',
    'reports.user.certificate': '使用者憑證',
    'reports.user.chatOnEmail': '透過電子郵件傳送聊天',
    'reports.user.city': '城市',
    'reports.user.comment': '註解',
    'reports.user.commission_type': '佣金依據',
    'reports.user.email': '電子郵件',
    'reports.user.extaccount': '外部帳戶',
    'reports.user.firstname': '名字',
    'reports.user.gender': '性別',
    'reports.user.group': '群組',
    'reports.user.how_handle': '如何處理訊號',
    'reports.user.introducebroker': '介紹經紀人帳戶',
    'reports.user.language': '語言',
    'reports.user.lastname': '姓氏',
    'reports.user.linkedUsers': '連結的使用者',
    'reports.user.login': '登入',
    'reports.user.middlename': '中間名',
    'reports.user.password': '新密碼',
    'reports.user.phonenumber': '電話號碼',
    'reports.user.phonepassword': '電話密碼',
    'reports.user.photo': '相片',
    'reports.user.pin': 'PIN 碼',
    'reports.user.showInSearch': '在搜尋中顯示',
    'reports.user.showMyContacts': '顯示我的聯絡人',
    'reports.user.signalsOnEmail': '電子郵件上的訊號',
    'reports.user.state': '州',
    'reports.user.status': '狀態',
    'reports.user.subscribeplan': '訂閱計畫',
    'reports.user.website': '網站',
    'reports.user.zipcode': '郵遞區號',
    'reports.usergroup.group': '超級群組',
    'reports.usergroup.name': '使用者群組',
    'reports.USERGROUPSVIS_RULES': '使用者群組可見度',
    'reports.userlogin': '使用者登入',
    'reports.UserLogin': '使用者登入',
    'reports.username': '使用者',
    'reports.Users Report': '使用者報告',
    'reports.Users Report(copy)': '使用者報告（副本）',
    'reports.users_num': '使用者數量',
    'reports.useSSL': '使用 SSL',
    'reports.ValidateFieldsHaveValues': '驗證欄位有值',
    'reports.ValidateFieldsOutOfOrder': '驗證欄位順序混亂',
    'reports.ValidateUserDefinedFields': '驗證使用者定義的欄位',
    'reports.value': '值',
    'reports.VALUE_ADDRESS': '地址',
    'reports.VALUE_BASE_CURRENCY': '基礎 symbol part',
    'reports.VALUE_BRUTEFORCE_LOCK_MINUTS': '值暴力破解鎖定分鐘數',
    'reports.VALUE_CITY': '城市',
    'reports.VALUE_COMMISSION_ACCOUNT': '佣金櫃枱帳戶',
    'reports.VALUE_COMMISSION_FOR_TRANSFER': '過戶所得佣金',
    'reports.VALUE_COMMISSION_ID': '收入計畫',
    'reports.VALUE_COUNTER_CURRENCY': '櫃枱 symbol part',
    'reports.VALUE_COUNTRY': '國家',
    'reports.VALUE_DAY_BEGIN': '開始日',
    'reports.VALUE_DAY_END': '結束日',
    'reports.VALUE_DAYS_TILL_EXP': '到期前的天數',
    'reports.VALUE_DEFAULT_BROKER': '預設經紀人',
    'reports.VALUE_DEFAULT_LANG': '預設語言',
    'reports.VALUE_DERIVATIVE_TYPE': '衍生工具類型',
    'reports.VALUE_HEDGE_COEFFICIENT': '對沖係數',
    'reports.VALUE_L2QUOTEGROUP_DEPTH': '層級 2 群組深度',
    'reports.VALUE_L2QUOTEGROUP_INTERVAL': '層級 2 群組間隔',
    'reports.VALUE_LEVERAGE': '槓桿',
    'reports.VALUE_LOT_SIZE': '每口大小',
    'reports.VALUE_LOTSTEP': '每口步長',
    'reports.VALUE_MARGIN_COEFFICIENT': '保證金係數（基於時間）',
    'reports.VALUE_MARGINCALL': '保證金催交，%',
    'reports.VALUE_MARGINTRADING': '停止交易水準，%',
    'reports.VALUE_MARKET_RANGE': '市場範圍，跳動點',
    'reports.VALUE_MAX_DELTA_QUOTES': '兩次後續報價之間（跳動點）可允許的最大價格差額',
    'reports.VALUE_MAX_DELTA_TRADES': '兩次後續交易之間的可允許的最高價格差額',
    'reports.VALUE_MAXIMUM_CACHE_SIZE': '最大快取大小',
    'reports.VALUE_MAXIMUM_FILTER': '價格大幅波動的最大值',
    'reports.VALUE_MAXIMUMLOT': '最大口數',
    'reports.VALUE_MIN_OPTION_INTERVAL': '兩次期權報價之間可允許的最小時間差',
    'reports.VALUE_MIN_PRICE_DIFF_FOR_MARGIN': '保證金的最小價格差額',
    'reports.VALUE_MINIMALLOT': '最小口數',
    'reports.VALUE_MINIMUM_FILTER': '價格大幅波動的最小值',
    'reports.value_nav': '值/負數',
    'reports.VALUE_NO_QUOTES_MAXTIME': '沒有報價最大次數',
    'reports.VALUE_NUMBER_OF_SHARES': '股數',
    'reports.VALUE_ONE_COUNTER_ACCOUNT': '櫃枱帳戶',
    'reports.VALUE_PIP_SIZE': '跳動點大小（最小變動）',
    'reports.VALUE_PnL_ACCOUNT': '損益櫃枱帳戶',
    'reports.VALUE_POST_SESSION_END': '時段結束後',
    'reports.VALUE_PRE_SESSION_BEGIN': '時段開始前',
    'reports.VALUE_PRICE_GENERATION_MODEL': '價格產生模型',
    'reports.VALUE_PWD_VERIFICATION_LEN': '驗證密碼的長度',
    'reports.VALUE_QUOTE_CACHE_15MIN_SIZE': '報價快取 15 分鐘大小',
    'reports.VALUE_QUOTE_CACHE_1MIN_SIZE': '報價快取 1 分鐘大小',
    'reports.VALUE_QUOTE_CACHE_30MIN_SIZE': '報價快取 30 分鐘大小',
    'reports.VALUE_QUOTE_CACHE_4HOUR_SIZE': '報價快取 4 小時大小',
    'reports.VALUE_QUOTE_CACHE_5MIN_SIZE': '報價快取 5 分鐘大小',
    'reports.VALUE_QUOTE_CACHE_DAY_SIZE': '報價快取日大小',
    'reports.VALUE_QUOTE_CACHE_HOUR_SIZE': '報價快取小時大小',
    'reports.VALUE_QUOTE_CACHE_MONTH_SIZE': '報價快取月大小',
    'reports.VALUE_QUOTE_CACHE_SIZE': '報價快取大小',
    'reports.VALUE_QUOTE_CACHE_TIC_SIZE': '報價快取跳動點大小',
    'reports.VALUE_QUOTE_CACHE_WEEK_SIZE': '報價快取週大小',
    'reports.VALUE_QUOTE_CACHE_YEAR_SIZE': '報價快取年大小',
    'reports.VALUE_QUOTE_PATH': '報價快取路徑',
    'reports.VALUE_QUOTES_FILE_SIZE': '報價檔大小',
    'reports.VALUE_SLIPPAGE': '進度落後',
    'reports.VALUE_SMS_PATTERN': '簡訊模式',
    'reports.VALUE_SPOT_INSTRUMENT': '現貨符號',
    'reports.VALUE_SPREAD_ACCOUNT': '價差櫃枱帳戶',
    'reports.VALUE_STATE': '州',
    'reports.VALUE_STOPOUT': '止損離場水準，%',
    'reports.VALUE_SWAP_ACCOUNT': '掉期櫃枱帳戶',
    'reports.VALUE_TEMPLATE_NUMERIC': '電子郵件範本',
    'reports.VALUE_TICK_COAST': '跳動點成本',
    'reports.VALUE_TIMEZONE_OFFSET': '時區互抵',
    'reports.VALUE_UNREPEAT_PASSWORD': '不重複密碼的數量',
    'reports.VALUE_WL_ACCOUNT': '存款/提款櫃枱帳戶',
    'reports.VALUE_WRONG_PASSWORDS_BEFORE_LOCK': '允許的錯誤密碼數量',
    'reports.VALUE_ZIP': '郵遞區號',
    'reports.ValueAddedTax': '加值稅',
    'reports.valuedate': '估值日期',
    'reports.Visibility': '可見度',
    'reports.VISIBILITY_BRANDING': '品牌塑造可見度',
    'reports.VISIBILITY_COMMISSIONSPLAN': '收入計畫可見度',
    'reports.VISIBILITY_EXTFIELDS': '延伸的欄位可見度',
    'reports.VISIBILITY_INSTRUMENT_TYPE': '股票代號類型可見度',
    'reports.VISIBILITY_ROUTE': '路由可見度',
    'reports.VISIBILITY_SUBSCRIBE_PLAN': '訂閱計畫',
    'reports.VISIBILITY_USERGROUP': '使用者群組可見度',
    'reports.Wait confirm for cancel': '等待對取消的確認',
    'reports.Wait confirm for execution': '等待對執行的確認',
    'reports.Wait confirm for modify': '等待對修改的確認',
    'reports.Waiting market': '等待市場',
    'reports.WEB_CHART_OE': 'Web 圖表（OE 內建）',
    'reports.WEB_CHART_VISUAL': 'Web 圖表（可視交易）',
    'reports.WEB_MARKETDEPTH': 'Web 市場深度',
    'reports.WEB_OE': 'Web OE',
    'reports.WEB_OPTION_MASTER': 'Web 期權鏈',
    'reports.WEB_ORDERS_PANEL_HOT_BTN': 'Web 待執行訂單（熱按鈕）',
    'reports.WEB_OTHER': 'Web，其他',
    'reports.WEB_POSITIONS_PANEL_HOT_BTN': 'Web 部位面板（熱按鈕）',
    'reports.weeks': '週',
    'reports.Widgets Instrument Statistics Report': '小工具的工具統計資料報告',
    'reports.widgets_isr_buytrades': '買入交易',
    'reports.widgets_isr_commission': '費用',
    'reports.widgets_isr_instrumentname': '股票代號',
    'reports.widgets_isr_netpl': '淨損益',
    'reports.widgets_isr_plticks': '損益跳動點',
    'reports.widgets_isr_selltrades': '賣出交易',
    'reports.widgets_isr_swap': '掉期',
    'reports.widgets_isr_totaltrades': '交易總數',
    'reports.widgets_isr_trades': '交易',
    'reports.widgets_isr_tradevolume': '成交量',
    'reports.widgets_isr_winningtrades': '獲利的交易',
    'reports.widthdraw': '提款',
    'reports.Withdraw': '提款',
    'reports.Withdrawal': '提款',
    'reports.withdrawalfee': '提款費',
    'reports.Wrong account operation summ.': '帳戶操作總和有誤',
    'reports.Wrong recovery key': '恢復金鑰有誤',
    'reports.years': '年',
    'reports.yes': '是',
    "reports.You can't trade with this account.": '您無法使用該帳戶進行交易。',
    Reserve: '保留',
    'ribbon.enviroment': '環境',
    'ribbon.enviroment.help': '說明',
    'ribbon.enviroment.logout': '登出',
    'ribbon.enviroment.New': '新工作區',
    'ribbon.enviroment.NotificationWindow.lockTrading': '已鎖定交易',
    'ribbon.enviroment.NotificationWindow.UnlockTrading': '已解鎖交易',
    'ribbon.enviroment.Save': '儲存工作區',
    'ribbon.enviroment.settings': '設定',
    'ribbon.help.about': '關於',
    'ribbon.help.onboarding': '入門',
    'ribbon.help.help': '更多',
    'ribbon.help.home': '首頁',
    'ribbon.linking.linkBlue': '藍色',
    'ribbon.linking.linkEmpty': '無',
    'ribbon.linking.linkGreen': '綠色',
    'ribbon.linking.linkOrange': '橙色',
    'ribbon.linking.linkPurple': '紫色',
    'ribbon.linking.linkRed': '紅色',
    'ribbon.linking.linkYellow': '黃色',
    'ribbon.more.tradingTools': '交易工具',
    'ribbon.tools': '工具',
    'ribbon.tools.full_logs': '完整的記錄伺服器訊息',
    'ribbon.tools.grid': '網格',
    'ribbon.tools.withdrawal': '提款',
    'ribbon.tools.reports': '報告',
    'ribbon.tradingTerminal': '終端',
    'ribbon.tradingTerminal.statement': '#hidden#',
    'RiskManagement.Messages.DailyLimit': '已達到每日損失限額',
    'RiskManagement.Messages.MaxDailyProfit': '已達到每日利潤',
    'RiskManagement.Messages.MaxTrailingLimit': '已達到最大跟蹤回撤',
    'RiskManagement.Messages.MaxUnrealizedLossLimit': '已達到未實現損失限額',
    'RiskManagement.Messages.WeeklyLimit': '已達到每週損失限額',
    'screen.about.copyright': '© 2017-2024 TraderEvolution Global LTD.',
    'screen.about.dateBuild': '建置日期：',
    'screen.about.title': '關於',
    'screen.about.titleWeb': '關於 TraderEvolution',
    'screen.about.versionWeb': '建置版本',
    'screen.additionalVerification.comment': '連線 ({1}) 需要額外的驗證：',
    'screen.additionalVerification.Need activation': '需要啟用',
    'screen.additionalVerification.Need activation.comment': '請輸入啟用代碼（已傳送至您的郵件）',
    'screen.additionalVerification.Need activation.watermark': '<輸入來自電子郵件的啟用代碼>',
    'screen.additionalVerification.title': '額外的驗證',
    'screen.additionalVerification.title.Email': '額外的驗證（電子郵件一次性密碼）',
    'screen.additionalVerification.title.Fixed': '額外的驗證（固定的密碼）',
    'screen.additionalVerification.title.Sms': '額外驗證（簡訊一次性密碼）',
    'screen.additionalVerification.watermark': '<輸入額外的密碼>',
    'screen.changepass.cancel': '取消',
    'screen.changepass.changed': '密碼變更成功',
    'screen.changepass.confirm': '確認新密碼',
    'screen.changepass.new': '新密碼',
    'screen.changepass.ok': '變動',
    'screen.changepass.old': '當前密碼',
    'screen.changepass.title': '變更密碼',
    'screen.changeTradingPass.title': '變更交易密碼',
    'screen.changepass.watermark_confirm': '<確認密碼>',
    'screen.changepass.watermark_new': '<新密碼>',
    'screen.changepass.watermark_old': '<當前密碼>',
    'screen.changepass.alert_not_match': '密碼和確認密碼不符',
    'screen.changepass.wrongVerification': '驗證有誤',
    'screen.closePosition.cancel': '取消',
    'screen.closePosition.ok': '收盤',
    'screen.closePosition.title': '收盤',
    'screen.error.title': '錯誤',
    'screen.export.header': '匯出',
    'screen.export.contextMenu': '匯出',
    'screen.export.Export': '匯出',
    'screen.export.Cancel': '取消',
    'screen.export.CSV': 'CSV',
    'screen.export.Excel': 'Excel',
    'screen.export.selectFormat.tt': '選取檔案類型',
    'screen.export.checkAllText': '檢查全部',
    'screen.licensing.emptyFieldErrors.password': '密碼欄位為空',
    'screen.licensing.statusMessages.connecting': '正在連線...',
    'screen.login.change_pass': '密碼已重設或到期，故需要變更',
    'screen.lookup.scripts.noitems': '沒有相符的項目',
    'screen.lookup.scripts.showing': '正在顯示 {1} 項目',
    'screen.lookup.scripts.watermark': '<搜尋>',
    'screen.modifyOrder.amount': '數量：',
    'screen.modifyOrder.bind_to': '繫結：',
    'screen.modifyOrder.Change order': '修改訂單',
    'screen.modifyOrder.created': '日期/時間：',
    'screen.modifyOrder.instrument': '股票代號：',
    'screen.modifyOrder.market_price': '當前價格：',
    'screen.modifyOrder.modify': '修改',
    'screen.modifyOrder.modifyPosition': '修改部位',
    'screen.modifyOrder.number': '部位 ID：',
    'screen.modifyOrder.operation': '側：',
    'screen.modifyOrder.orderNumber': '訂單 ID：',
    'screen.modifyOrder.ordertype': '訂單類型：',
    'screen.modifyOrder.price': '開盤價：',
    'screen.modifyOrder.route': '路由：',
    'screen.properties.cancel': '關閉',
    'screen.properties.cancel.ToolTip': '關閉',
    'screen.properties.common': '屬性',
    'screen.properties.ok': '確定',
    'screen.properties.ok.ToolTip': '確定',
    'screen.properties.title': '一般設定',
    'screen.properties.unsavedChanges': '您有未儲存的變動。結束會失去您的變動，是否結束？',
    'screen.LoginScreen.Connecting': '正在連線...',
    'screen.LoginScreen.Error.loginIdIsEmpty': '登入 ID 欄位為空',
    'screen.LoginScreen.Error.passwordIdIsEmpty': '密碼欄位為空',
    'screen.LoginScreen.ForgotLinkLabel': '忘記密碼',
    'screen.LoginScreen.ForgotLinkLabel.ToolTip': '密碼恢復',
    'screen.LoginScreen.LoadingUserData': '正在載入使用者資料...',
    'screen.LoginScreen.LoginButtonText': '登入',
    'screen.LoginScreen.LoginButtonTooltip': '登入平台',
    'screen.LoginScreen.LoginIDLabel': '登入',
    'screen.LoginScreen.LoginIDTextBox.ToolTip': '使用者登入',
    'screen.LoginScreen.PasswordLabel': '密碼',
    'screen.LoginScreen.PasswordTextBox.ToolTip': '使用者密碼',
    'screen.LoginScreen.RegistrationLinkLabel': '註冊',
    'screen.LoginScreen.RegistrationLinkLabel.ToolTip': '示範帳戶註冊',
    'screen.LoginScreen.SavePassCheckBox': '儲存密碼',
    'screen.LoginScreen.SavePassCheckBox.ToolTip': '記住認證',
    'screen.LoginScreen.Settings.LanguageLabel': '語言',
    'screen.recconection.attemptLabelLabel': '嘗試次數：',
    'screen.recconection.reconnecting': '正在重新連線...',
    'screen.recconection.seconds': '秒...',
    'screen.recconection.statusLabelLabel': '狀態：',
    'screen.recconection.stop': '止損',
    'screen.recconection.title': '重新連線管理員',
    'screen.recconection.tryNow': '立即嘗試',
    'screen.recconection.waiting': '正在等待',
    'screen.RecoveryPassForm.Cancel': '取消',
    'screen.RecoveryPassForm.Confirm': '確認密碼',
    'screen.RecoveryPassForm.Email': '電子郵件：',
    'screen.RecoveryPassForm.HaveCode': '我有金鑰',
    'screen.RecoveryPassForm.Key': '金鑰',
    'screen.RecoveryPassForm.Login': '登入名：',
    'screen.RecoveryPassForm.New password': '新密碼',
    'screen.RecoveryPassForm.New password contains prohibited symbols': '新密碼包含禁止的符號',
    'screen.RecoveryPassForm.Send': '恢復',
    'screen.RecoveryPassForm.Title': '密碼恢復',
    'screen.RecoveryPassForm.invalid_login': '登入名無效，無法恢復您的密碼',
    'screen.RecoveryPassForm.invalid_login_or_email': '登入名或電子郵件無效，無法恢復您的密碼',
    'screen.RecoveryPassForm.invalid_email': '該使用者的電子郵件無效',
    'screen.RecoveryPassForm.Your request for password recovering has sent successfully. Please check your email!': '您的密碼恢復請求已傳送成功。請檢查您的電子郵件！',
    'screen.registrationWizard.confirmPassword': '*確認密碼：',
    'screen.registrationWizard.confirmPassword.watermark': '<確認您的密碼>',
    'screen.registrationWizard.email': '*電子郵件：',
    'screen.registrationWizard.email.watermark': '<電子郵件>',
    'screen.registrationWizard.Country': '國家：',
    'screen.registrationWizard.Password': '*密碼：',
    'screen.registrationWizard.Balance': '餘額：',
    'screen.registrationWizard.firstName': '*名字：',
    'screen.registrationWizard.firstName.watermark': '<最小 2 個字元>',
    'screen.registrationWizard.lastName': '*姓氏：',
    'screen.registrationWizard.lastName.watermark': '<最小 2 個字元>',
    'screen.registrationWizard.login': '登入名：',
    'screen.registrationWizard.login.watermark': '<留空以自動產生>',
    'screen.registrationWizard.password.watermark': '<輸入您的密碼>',
    'screen.registrationWizard.phone': '電話號碼：',
    'screen.registrationWizard.phoneNumber.watermark': '<（國碼）電話號碼>',
    'screen.registrationWizard.registrationStatus': '註冊狀態',
    'screen.registrationWizard.successMessage': '註冊成功！\n登入名：{1}\n密碼：{2}',
    'screen.registrationWizard.Header': '建立示範帳戶',
    'screen.registrationWizard.Register': '註冊',
    'screen.registrationWizard.Cancel': '取消',
    'screen.renameScreen.cancel': '取消',
    'screen.renameScreen.illegalName': '非法名稱！',
    'screen.renameScreen.ok': '確定',
    'screen.renameScreen.newList.header': '列表名稱',
    'screen.renameScreen.newList.placeholder': '新列表',
    'screen.renameScreen.newTemplate.header': '範本名稱',
    'screen.renameScreen.newTemplate.placeholder': '新範本',
    'screen.renameScreen.rename.header': '重新命名',
    'screen.rename.alreadyExist.firstPart': '名稱',
    'screen.rename.alreadyExist.secondPart': '已存在。',
    'screen.remove.lastlist': '至少應有一個列表',
    'screen.remove.title': '移除',
    'screen.remove.confirmText.firstPart': '移除',
    'screen.remove.confirmText.secondPart': '列表？',
    'screen.remove.confirmText.secondPart.templates': '範本？',
    'screen.ReportMessageTooltip.CreatedAt': '建立時間為',
    'screen.ReportMessageTooltip.FilledAt': '滿足時間為',
    'screen.ReportMessageTooltip.MarginCall': '保證金催交',
    'screen.ReportMessageTooltip.Market': '市場',
    'screen.ReportMessageTooltip.ModifiedAt': '修改時間為',
    'screen.ReportMessageTooltip.RemovedAt': '移除時間為',
    'screen.reports.actions_print': '列印',
    'screen.reports.address': '地址',
    'screen.reports.beginDatePicker.ButtonDropDown.ToolTip': '點選以打開行事曆',
    'screen.reports.beginDatePicker.ToolTip': '選取日期/時間',
    'screen.reports.brokerzip': '郵遞區號',
    'screen.reports.button_printer_setup': '設定',
    'screen.reports.byGroup': '按群組',
    'screen.reports.byInstrumentGroup': '按群組',
    'screen.reports.byInstruments': '按工具',
    'screen.reports.byUser': '按帳戶',
    'screen.reports.byUserGroup': '按群組',
    'screen.reports.cell_data': '',
    'screen.reports.column.inner_text': '',
    'screen.reports.columns_maneger': '欄管理員...',
    'screen.reports.country': '國家',
    'screen.reports.csv': '匯出至 CSV...',
    'screen.reports.customerzip': '城市、郵遞區號',
    'screen.reports.dataCoverage': '資料覆蓋範圍',
    'screen.reports.errSaving': '儲存檔案時出錯。',
    'screen.reports.excel': '匯出至 Excel...',
    'screen.reports.exportToButton': '匯出至...',
    'screen.reports.filter': '篩選器管理員...',
    'screen.reports.historyFor': '以下項目的歷程記錄：',
    'screen.reports.html': '匯出至 HTML...',
    'screen.reports.info': '資訊',
    'screen.reports.error': '錯誤',
    'screen.reports.Reports': '報告',
    'screen.reports.InstrumentAndGroupLookup.byGroupCombobox.ToolTip': '選取股票代號的群組',
    'screen.reports.InstrumentAndGroupLookup.instrunentLookup.ToolTip': '選取股票代號',
    'screen.reports.license': '授權編號',
    'screen.reports.listViewEx.itemAll': '全部',
    'screen.reports.manager': '管理員',
    'screen.reports.minimizeButton.Hide reports parameters': '隱藏報告參數',
    'screen.reports.minimizeButton.Show reports parameters': '顯示報告參數',
    'screen.reports.name': '名稱',
    'screen.reports.news.daily': '每日',
    'screen.reports.news.from': '從',
    'screen.reports.news.hideACriterias': '隱藏進階標準',
    'screen.reports.news.invalidDateTime': '請選取有效的「從」和「至」日期/時間',
    'screen.reports.news.monthToDate': '本月至今',
    'screen.reports.news.range': '範圍',
    'screen.reports.news.showACriterias': '顯示進階標準',
    'screen.reports.news.sinceInception': '從開始日起',
    'screen.reports.news.till': '直至',
    'screen.reports.news.yearToDate': '本年至今',
    'screen.reports.noData': '沒有可用的資料',
    'screen.reports.order': '訂單',
    'screen.reports.param.Name': '名稱',
    'screen.reports.paramOfReport': '報告參數',
    'screen.reports.pdf': '匯出至 PDF...',
    'screen.reports.phone': '電話號碼',
    'screen.reports.pos': '部位',
    'screen.reports.president': '總經理姓名',
    'screen.reports.print': '列印...',
    'screen.reports.processingRequset': '取消',
    'screen.reports.rangeError': '"從"日期不能晚於"至"日期！',
    'screen.reports.report.button_printer_setup': '設定',
    'screen.reports.reportHeader.accnumber': '帳號',
    'screen.reports.reportHeader.from': '從',
    'screen.reports.reportHeader.Text': '客戶和經紀人資訊',
    'screen.reports.reportHeaders.account': '帳戶：',
    'screen.reports.reportHeaders.currancy': '貨幣：',
    'screen.reports.reportHeaders.date': '日期：',
    'screen.reports.reportHeaders.from': '從：',
    'screen.reports.reportHeaders.owner': '擁有者：',
    'screen.reports.reportHeaders.to': '至：',
    'screen.reports.reportHeaderText': '客戶和經紀人資訊',
    'screen.reports.reportParameters': '報告參數',
    'screen.reports.reports.Canceled': '已取消操作',
    'screen.reports.reports.ExpingToCSV': '正在將資料匯出至 CSV 文件...',
    'screen.reports.reports.ExpingToExcel': '正在將資料匯出至 Excel 文件...',
    'screen.reports.reports.ExpingToHTML': '正在將資料匯出至 HTML 文件...',
    'screen.reports.reports.ExpingToPDF': '正在將資料匯出至 PDF 文件...',
    'screen.reports.reports.ExportingPrint': '正在匯出要列印的資料...',
    'screen.reports.reports.ExpPrint': '資料匯出成功，可列印。',
    'screen.reports.reports.ExpPrintErr': '匯出以供列印時出錯！',
    'screen.reports.reports.ExpToCSV': '資料成功匯出至 CSV。',
    'screen.reports.reports.ExpToCSVErr': '匯出至 CSV 時出錯！',
    'screen.reports.reports.ExpToExcel': '資料成功匯出至 Excel。',
    'screen.reports.reports.ExpToExcelErr': '匯出至 Excel 時出錯！',
    'screen.reports.reports.ExpToHTML': '資料成功匯出至 HTML 文件。',
    'screen.reports.reports.ExpToHTMLErr': '匯出至 HTML 文件時出錯！',
    'screen.reports.reports.ExpToPDF': '資料成功匯出至 PDF 文件。',
    'screen.reports.reports.ExpToPDFErr': '匯出至 PDF 文件時出錯！',
    'screen.reports.reports.getReport': '獲取報告',
    'screen.reports.reports.in_separate_window': '報告已在獨立視窗中打開',
    'screen.reports.reports.inCurrentWindow': '在當前視窗中',
    'screen.reports.reports.info': '使用報告管理員檢視和列印帳戶活動報告',
    'screen.reports.reports.inSeparateWindow': '在獨立視窗中',
    'screen.reports.reports.loading': '正在載入報告的資料...',
    'screen.reports.reports.noData': '抱歉，該請求沒有可用的資料。請嘗試使用報告的其他參數。',
    'screen.reports.reports.noItemAvailable': '沒有可用的項目',
    'screen.reports.reportType': '報告類型',
    'screen.reports.reportTypeCombobox.ToolTip': '選取報告類型',
    'screen.reports.save': '儲存',
    'screen.reports.showSummary': '顯示摘要',
    'screen.reports.sinceInception': '從開始日起',
    'screen.reports.summary': '摘要',
    'screen.reports.table': '表格',
    'screen.reports.title': '報告管理員',
    'screen.reports.to': '至',
    'screen.reports.trades': '交易',
    'screen.reports.UserAndGroupLookup.byGroupCombobox.ToolTip': '選取帳戶的群組',
    'screen.reports.UserAndGroupLookup.usersLookup.ToolTip': '選取帳戶',
    'screen.TimeZoneSelector.title': '選取時區',
    'screen.tradingTools.header': '交易工具',
    'screen.UnlockTradingScreen.lockedLabel': '已鎖定交易',
    'screen.UnlockTradingScreen.unlockButton': '解鎖',
    'screen.withdrawal.account': '從帳戶',
    'screen.withdrawal.Asset': '資產',
    'screen.withdrawal.Availible funds': '可用：',
    'screen.withdrawal.avaliableNumeric.ToolTip': '設定剩餘資本',
    'screen.withdrawal.Cancel': '取消',
    'screen.withdrawal.error.not_allowed': '不允許您的使用者進行提款類帳戶操作，請聯絡經紀人',
    'screen.withdrawal.error.not_enough_balance': '沒有足夠的餘額',
    'screen.withdrawal.error.not_enough_blocked': '帳戶無效（解除凍結的數量不能超過已凍結的數量）',
    'screen.withdrawal.error.not_enough_margin': '沒有足夠的保證金',
    'screen.withdrawal.error.UnableToBlock': '錯誤！無法凍結。',
    'screen.withdrawal.error.WrongSum': '錯誤：總和有誤。',
    'screen.withdrawal.errorLabel': '沒有足夠的提款資金',
    'screen.withdrawal.Fee': '提款費：',
    'screen.withdrawal.from': '從',
    'screen.withdrawal.Remains': '剩餘',
    'screen.withdrawal.RequiredAmount': '要求的金額：',
    'screen.withdrawal.ReservedWithdrawal': '提款',
    'screen.withdrawal.usersLookup.ToolTip': '選取帳戶',
    'screen.withdrawal.Withdraw': '提款',
    'screen.withdrawal.Withdrawal': '提款',
    'screen.withdrawal.withdrawalNumeric.ToolTip': '設定提款資本',
    'screen.withdrawal.Amount': '金額',
    'Simple Moving Average': '簡單移動平均線',
    SixMonth: '6 個月',
    'Smoothed Moving Average': '平滑移動平均線',
    'Standard Deviation': '標準偏差',
    'statusBar.passwordpanel.DetalTicket.Text': '已允許存取交易活動',
    'statusBar.passwordPanel.passwordBox.invalidPass': '交易密碼無效。',
    Stochastic: '隨機',
    'Stochastic Slow': '隨機慢',
    Stop: '止損',
    'Stop Limit': '止損限價',
    'Stop-loss': '止損',
    Suomi: '#hidden#',
    'Swing Index': '升降指數',
    'Take-profit': '獲利',
    'TerceraAccountLookup.AccountNotExist': '帳戶不存在',
    'TerceraAccountLookup.IncorrectAccount': '帳戶不正確',
    'TerceraAccountLookup.Linked accounts': '連結的帳戶',
    'TerceraAccountLookup.Multy select': '多選',
    'TerceraAccountLookup.Select': '選取',
    'TerceraAccountLookup.Select All': '選取全部',
    'textBoxWithValidating.ErrorText.NameContainsInvalidChar': '名稱包含無效的字元',
    'textBoxWithValidating.ErrorText.NameExist': '該名稱已存在',
    ThreeMonth: '3 個月',
    'Tr. stop': '跟蹤止損',
    'TradeDefaultManager.Set defaults': '設定預設值',
    'TradeDefaultManager.Types manager': '類型管理員',
    'TradeTool.Amount of order': '訂單數量',
    'TradeTool.Cancel order': '取消訂單',
    'TradeTool.Cancel SL': '取消止損',
    'TradeTool.Cancel TP': '取消獲利',
    'TradeTool.Close position': '平倉',
    'TradeTool.Drag to set SL order': '拖曳以設定止損單',
    'TradeTool.Drag to set TP order': '拖曳以設定獲利單',
    'TradeTool.Order': '訂單',
    'TradeTool.Position': '部位',
    'TradeTool.Position P/L': '總損益',
    'TradeTool.Tick and P/L': '跳動點和損益',
    'TradeTool.Trailing Stop': '跟蹤止損',
    'TradeTool.View more actions': '檢視更多操作',
    TTO: 'TTO',
    'TypesManagerScreen.DefaultLotsSetup': '預設口數設定',
    'TypesManagerScreen.NumericLabel.Price increment (arrow), ticks': '價格增量（箭頭），跳動點',
    'TypesManagerScreen.NumericLabel.SL default offset, ticks': '止損預設互抵，跳動點',
    'TypesManagerScreen.NumericLabel.TP default offset, ticks': '獲利預設互抵，跳動點',
    'TypesManagerScreen.NumericLabel.User quantity coefficient': '使用者數量係數',
    'TypesManagerScreen.QuickTable.Column.Default lots': '預設口數',
    'TypesManagerScreen.QuickTable.Column.Enable': '啟用',
    'TypesManagerScreen.Types Manager': '類型管理員',
    'UserControl.TerceraNumeric.ValueGreaterMax': '值大於最大值',
    'UserControl.TerceraNumeric.ValueIsEmpty': '值為空',
    'UserControl.TerceraNumeric.ValueLessMin': '值小於最小值',
    'UserControl.TerceraNumeric.ValueNotMultiple': '值不是最小變動的倍數',
    'UserControl.TerceraNumeric.ValueNotNumber': '值不是數字',
    Volume: '成交量',
    'Volume Weighted Average Price': '成交量加權平均價格',
    VWAP: 'VWAP',
    allowCreatePanelsFromPanel: '',
    allowLinkSystem: '',
    allowWorkspaceContextMenu: '',
    allowWorkspaceEdit: '',
    'orders.confirm.all.question1': '取消全部（',
    'orders.confirm.all.question2': '）訂單？',
    'orders.confirm.all.title': '取消全部訂單',
    'orders.confirm.cancel.question1': '按選定的股票代號取消全部訂單？',
    'positions.confirm.mutual.question1': '是否要相互了結選定的（',
    'positions.confirm.mutual.question2': '）部位？',
    'Rows.firstPart': '已達到',
    'Rows.secondPart': '中的可用股票代號/列限制。請聯絡您的支援團隊，以瞭解詳細資訊',
    'general.messageBox.cancel.ToolTip': '捨棄變動並關閉',
    'property.Confirmations.ToolTip': '確認設定',
    'general.messageBox.ok.ToolTip': '應用變動並關閉',
    'property.Defaults.ToolTip': '交易預設設定',
    'property.View.ToolTip': '檢視設定',
    'property.Warnings.ToolTip': '警告設定',
    'Throtling.Count.firstPart': '已達到',
    'Throtling.Count.secondPart': '面板的可用限制。請聯絡您的支援團隊，以瞭解詳細資訊',
    'workspace.At first you should unlock workspace to rename it': '首先，您應解鎖工作區，以進行重新命名。',
    'workspace.Clear': '清除',
    'workspace.clear_ask': '清除工作區？',
    'workspace.clear_wsp': '清除工作區',
    'workspace.Close': '關閉',
    'workspace.close_wsp': '關閉工作區',
    'workspace.DefaultNameSpace': 'Wrk#',
    'workspace.errorSavingFile': '儲存檔案時出錯。',
    'workspace.information': '資訊',
    'workspace.saved.Message': '已儲存工作區',
    'workspace.Lock': '鎖定',
    'workspace.Lock.Message': '已鎖定工作區',
    'workspace.menu.Clone': '複製',
    'workspace.menu.Close': '關閉',
    'workspace.menu.CloseAllButThis': '關閉全部，但此工作區除外',
    'workspace.menu.Lock': '鎖定',
    'workspace.newWorkspaceName': '輸入新工作區名稱',
    'workspace.numberOfWorkspace': '打開的工作區數量超過允許的最大數量',
    'workspace.Rename': '重新命名',
    'workspace.Unlock': '解鎖',
    'workspace.Unlock.Message': '已解鎖工作區',
    'InstrumentDetailsPanel.ISIN': 'ISIN',
    'InstrumentDetailsPanel.AccruedInterest.descr': '按 1 口計算的當前應計利息',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot.descr': '意味著，對於已進行的交易內的每一口，將收取/返還固定的回扣值（以計畫貨幣列值）',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot.descr': '意味著，對於已進行的交易內的每一口，將收取/返還固定的回扣值（以計畫貨幣列值）',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume.descr': '意味著，收取/返還的總額將按占成交量的百分比計算',
    'InstrumentDetailsPanel.AllowedOperations.descr': '顯示指定股票代號的允許操作',
    'InstrumentDetailsPanel.AllowedOrderTypes.descr': '顯示指定股票代號的允許訂單類型',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday': '盤中允許短部位',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery': '交割允許短部位',
    'InstrumentDetailsPanel.AllowShortPositions.descr': '顯示是否允許透過指定股票代號建立短部位',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday.descr': '顯示是否允許透過指定股票代號建立短部位',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery.descr': '顯示是否允許透過指定股票代號建立短部位',
    'InstrumentDetailsPanel.AssetClass.descr': '在市場上買賣的股票代號的類型',
    'InstrumentDetailsPanel.CloseOutDeadline.descr': '將透過每份特定契約平倉的日期',
    'InstrumentDetailsPanel.ContactMonth.descr': '契約到期日。可用於期權和期貨。',
    'InstrumentDetailsPanel.ContractSize.descr': '一份契約的標的資產金額',
    'InstrumentDetailsPanel.CurrentSession.descr': '關於當前交易時段的資訊',
    'InstrumentDetailsPanel.DeliveryMethod.descr': '資產交割類型',
    'InstrumentDetailsPanel.DeliveryStatus.descr': '資產交割狀態',
    'InstrumentDetailsPanel.Description.descr': '關於該股票代號的詳細資訊',
    'InstrumentDetailsPanel.FirstTradeDate.descr': '可先買賣該契約的日期。可用於期權和期貨。',
    'InstrumentDetailsPanel.HiLimit.descr': '允許的價格範圍的上限（超過該上限則不接受延遲的訂單）',
    'InstrumentDetailsPanel.ISIN.descr': '國際證券識別號碼',
    'InstrumentDetailsPanel.KEYNextHoliday.descr': '顯示不允許交易的下一個假日的日期',
    'InstrumentDetailsPanel.LastTradeDate.descr': '可最後一次買賣該契約的日期。可用於期權、期貨和遠期契約',
    'InstrumentDetailsPanel.LotSize.descr': '一口的基礎資產金額',
    'InstrumentDetailsPanel.LotStep.descr': '口數變動的步長',
    'InstrumentDetailsPanel.LowLimit.descr': '允許的價格範圍的下限（超過該下限則不接受延遲的訂單）',
    'InstrumentDetailsPanel.MAIN.descr': '關於主要時段設定的資訊',
    'InstrumentDetailsPanel.Margin.descr': '顯示視保證金計算類型而定的保證金要求',
    'InstrumentDetailsPanel.Margin_ByAccount.descr': '顯示按帳戶係數計算的保證金要求',
    'InstrumentDetailsPanel.MarginBuy.descr': '顯示使用不同做多/做空保證金且並非視隔夜保證金而定的長部位的保證金要求',
    'InstrumentDetailsPanel.MarginDay.descr': '顯示並非視做多/做空保證金而定的日間保證金要求',
    'InstrumentDetailsPanel.MarginDayBuy.descr': '顯示長部位的初始和維持保證金的日間係數',
    'InstrumentDetailsPanel.MarginDaySell.descr': '顯示短部位的初始和維持保證金的日間係數',
    'InstrumentDetailsPanel.MarginOvernight.descr': '顯示並非視做多/做空保證金而定的隔夜保證金要求',
    'InstrumentDetailsPanel.MarginOvernightBuy.descr': '顯示長部位隔夜過戶的初始和維持保證金的係數',
    'InstrumentDetailsPanel.MarginOvernightSell.descr': '顯示短部位個月過戶的初始和維持保證金的係數',
    'InstrumentDetailsPanel.MarginSell.descr': '顯示使用不同做多/做空保證金且並非視隔夜保證金而定的短部位的保證金要求',
    'InstrumentDetailsPanel.Margin.Intraday.descr': '顯示視保證金計算類型而定的保證金要求',
    'InstrumentDetailsPanel.MarginBuy.Intraday.descr': '顯示使用不同做多/做空保證金且並非視隔夜保證金而定的長部位的保證金要求',
    'InstrumentDetailsPanel.MarginDay.Intraday.descr': '顯示並非視做多/做空保證金而定的日間保證金要求',
    'InstrumentDetailsPanel.MarginDayBuy.Intraday.descr': '顯示長部位的初始和維持保證金的日間係數',
    'InstrumentDetailsPanel.MarginDaySell.Intraday.descr': '顯示短部位的初始和維持保證金的日間係數',
    'InstrumentDetailsPanel.MarginOvernight.Intraday.descr': '顯示並非視做多/做空保證金而定的隔夜保證金要求',
    'InstrumentDetailsPanel.MarginOvernightBuy.Intraday.descr': '顯示長部位隔夜過戶的初始和維持保證金的係數',
    'InstrumentDetailsPanel.MarginOvernightSell.Intraday.descr': '顯示短部位個月過戶的初始和維持保證金的係數',
    'InstrumentDetailsPanel.MarginSell.Intraday.descr': '顯示使用不同做多/做空保證金且並非視隔夜保證金而定的短部位的保證金要求',
    'InstrumentDetailsPanel.Margin.Delivery.descr': '顯示視保證金計算類型而定的保證金要求',
    'InstrumentDetailsPanel.MarginBuy.Delivery.descr': '顯示使用不同做多/做空保證金且並非視隔夜保證金而定的長部位的保證金要求',
    'InstrumentDetailsPanel.MarginDay.Delivery.descr': '顯示並非視做多/做空保證金而定的日間保證金要求',
    'InstrumentDetailsPanel.MarginDayBuy.Delivery.descr': '顯示長部位的初始和維持保證金的日間係數',
    'InstrumentDetailsPanel.MarginDaySell.Delivery.descr': '顯示短部位的初始和維持保證金的日間係數',
    'InstrumentDetailsPanel.MarginOvernight.Delivery.descr': '顯示並非視做多/做空保證金而定的隔夜保證金要求',
    'InstrumentDetailsPanel.MarginOvernightBuy.Delivery.descr': '顯示長部位隔夜過戶的初始和維持保證金的係數',
    'InstrumentDetailsPanel.MarginOvernightSell.Delivery.descr': '顯示短部位個月過戶的初始和維持保證金的係數',
    'InstrumentDetailsPanel.MarginSell.Delivery.descr': '顯示使用不同做多/做空保證金且並非視隔夜保證金而定的短部位的保證金要求',
    'InstrumentDetailsPanel.MaturityDate.descr': '契約到期日',
    'InstrumentDetailsPanel.MaximumLot.descr': '多口中允許的最大宗交易',
    'InstrumentDetailsPanel.MaximumLot.Intraday': '盤中最大口數',
    'InstrumentDetailsPanel.MaximumLot.Intraday.descr': '多口中允許的最大宗交易',
    'InstrumentDetailsPanel.MaximumLot.Delivery': '交割最大口數',
    'InstrumentDetailsPanel.MaximumLot.Delivery.descr': '多口中允許的最大宗交易',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.descr': '多口中每個工具的所有部位的最大總數',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday.descr': '多口中每個工具的所有部位的最大總數',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery.descr': '多口中每個工具的所有部位的最大總數',
    'InstrumentDetailsPanel.MinimalLot.descr': '可供交易的每口的最小部分',
    'InstrumentDetailsPanel.MinimalLot.Intraday': '盤中最小口數',
    'InstrumentDetailsPanel.MinimalLot.Intraday.descr': '可供交易的每口的最小部分',
    'InstrumentDetailsPanel.MinimalLot.Delivery': '交割最小口數',
    'InstrumentDetailsPanel.MinimalLot.Delivery.descr': '可供交易的每口的最小部分',
    'InstrumentDetailsPanel.NextPaymentDate.descr': '下一次收益付款日期',
    'InstrumentDetailsPanel.NormalMarketSize.descr': '正常市場規模',
    'InstrumentDetailsPanel.NoticeDate.descr': '將發出最快到期日通知的日期。可用於期貨。',
    'InstrumentDetailsPanel.OrderPerLot.descr': '為已買賣的每口支付的費用',
    'InstrumentDetailsPanel.OrderVolume.descr': '為已滿足的訂單成交量每部分支付的費用',
    'InstrumentDetailsPanel.PerFill.descr': '為每次滿足訂單支付的費用',
    'InstrumentDetailsPanel.PerLot.descr': '為已買賣的每口支付的費用',
    'InstrumentDetailsPanel.PerOrderVolume.descr': '為已滿足的訂單成交量每部分支付的費用',
    'InstrumentDetailsPanel.PerPhoneTransaction.descr': '為電話經銷商進行的每件交易事項支付的費用',
    'InstrumentDetailsPanel.PerTransaction.descr': '每件交易事項的費用。交易事項：下單、取消訂單和修改訂單',
    'InstrumentDetailsPanel.PerVolume.descr': '按每宗交易的成交量計算的費用，%',
    'InstrumentDetailsPanel.POSTCLOSE.descr': '關於盤後時段設定的資訊',
    'InstrumentDetailsPanel.PREOPEN.descr': '關於盤前時段設定的資訊',
    'InstrumentDetailsPanel.PreviousPaymentDate.descr': '上一次收益付款日期',
    'InstrumentDetailsPanel.QuotiongCurrency.descr': '關於計算報價所用貨幣的資訊',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot.descr': '意味著，對於已進行的交易內的每一口，將收取/返還固定的回扣值（以計畫貨幣列值）',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot.descr': '意味著，對於已進行的交易內的每一口，將收取/返還固定的回扣值（以計畫貨幣列值）',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume.descr': '意味著，收取/返還的總額將按占成交量的百分比計算',
    'InstrumentDetailsPanel.SessionStatus.descr': '時段狀態，可用值：開盤、收盤',
    'InstrumentDetailsPanel.SettlementDate.descr': '必須交割的日期',
    'InstrumentDetailsPanel.StrikePrice.descr': '將執行期權契約所依據的標的資產的價格水準',
    'InstrumentDetailsPanel.SwapBuy.descr': '顯示對該股票代號的隔夜長部位應用的掉期價值',
    'InstrumentDetailsPanel.SwapSell.descr': '顯示對該股票代號的隔夜短部位應用的掉期價值',
    'InstrumentDetailsPanel.Symbol.descr': '在查詢中選擇的股票名稱',
    'InstrumentDetailsPanel.TickCoast.descr': '一個跳動點的基礎資產金額',
    'InstrumentDetailsPanel.TickSize.descr': '價格的最小變動',
    'InstrumentDetailsPanel.TradingBalance.descr': 'T+x 結算週期',
    'InstrumentDetailsPanel.TradindBlockedOnSession.descr': '顯示交易是否當前時段被凍結',
    'InstrumentDetailsPanel.TradingStatus.descr': '交易狀態，可用值：打開、關閉、交易暫停',
    'InstrumentDetailsPanel.Underlier.descr': '待交割資產的名稱。可用於期權和期貨。',
    'InstrumentDetailsPanel.VAT.descr': '為每次費用操作繳納的加值稅',
    'InstrumentDetailsPanel.Volume.descr': '按每宗交易的成交量計算的費用',
    'InstrumentDetailsPanel.Yield.descr': '當前收益價值',
    'panel.accounts.AccountValue.descr': '帳戶總價值',
    'panel.accounts.Asset.descr': '資產名稱',
    'panel.accounts.AvailableMargin.descr': '該帳戶的可用資金',
    'panel.accounts.Balance.descr': '帳戶餘額',
    'panel.accounts.BalancePlusAllRisks.descr': '帳戶和全部風險',
    'panel.accounts.BeginBalance.descr': '本日的淨損益',
    'panel.accounts.BlockedByOrders.descr': '為訂單而凍結的保證金',
    'panel.accounts.BlockedByPamm.descr': '為基金投入的起始資本',
    'panel.accounts.CashBalance.descr': '現金餘額',
    'panel.accounts.ClientType.descr': '帳戶擁有者最後連線自（ .Net、Java、行動裝置、Html）',
    'panel.accounts.CreditValue.descr': '交易的可用信貸資金',
    'panel.accounts.CurBalance.descr': '當前權益（餘額 + 未實現損益）',
    'panel.accounts.CurMargin.descr': '與餘額和全部風險有關的初始保證金要求',
    'panel.accounts.CurrentFundCapital.descr': '為基金投入的當前資本',
    'panel.accounts.Email.descr': '電子郵件地址',
    'panel.accounts.Estimate value.descr': '透過其他資產計算的價值',
    'panel.accounts.FundCapitalGain.descr': '當前和起始基金資本的差額',
    'panel.accounts.FundsUsedPercent.descr': '就餘額和全部風險使用的資金',
    'panel.accounts.InterestRate.descr': '存款的年利率',
    'panel.accounts.Leverage.descr': '帳戶的初始保證金槓桿',
    'panel.accounts.LockedBalance.descr': '用於進一步提款的鎖定餘額',
    'panel.accounts.LockedBy.descr': '已被經紀人鎖定（交易員報價請求）',
    'panel.accounts.Login.descr': '帳戶名稱',
    'panel.accounts.MaintMargin.descr': '與餘額和全部風險有關的維持保證金要求',
    'panel.accounts.MarginAvailableReal.descr': '帳戶的可用保證金',
    'panel.accounts.MarginRequirement.descr': '所有已建倉部位的維持要求',
    'panel.accounts.MarginStopOut.descr': '為部位和訂單凍結的維持保證金',
    'panel.accounts.MarginStopTrade.descr': '如果餘額低於該水準，則阻止帳戶進行交易',
    'panel.accounts.MarginUsedNew.descr': '為部位和訂單凍結的初始保證金',
    'panel.accounts.MarginWarning.descr': '如果餘額低於該水準，則出現警告保證金訊息',
    'panel.accounts.marginWarnPercent.descr': '保證金警告水準，%',
    'panel.accounts.MaxDailyLossLimit.descr': '帳戶日淨損失的最大值',
    'panel.accounts.MaxDailyProfit.descr': '帳戶日淨利潤的最大值',
    'panel.accounts.MaxDrawdownLevel.descr': '表示帳戶可擁有的最低允許預期餘額',
    'panel.accounts.MaximumLot.descr': '一份訂單的最大數量',
    'InstrumentDetailsPanel.Account.descr': '帳戶名稱',
    'panel.accounts.MaxOrderAmount.descr': '最高訂單金額',
    'panel.accounts.MaxOrderCapital.descr': '一份訂單的最大資本',
    'panel.accounts.MaxOrdersPerDay.descr': '每日的最大訂單計數',
    'panel.accounts.MaxPendingOrders.descr': '最大待決訂單計數',
    'panel.accounts.MaxPendingOrdersValue.descr': '使用者可用的最大累計待決訂單價值',
    'panel.accounts.MaxPositionQtyPerSymbol.descr': '每個股票代號內一個部位的最大數量',
    'panel.accounts.MaxPositions.descr': '最大部位計數',
    'panel.accounts.MaxWeekLostLimit.descr': '帳戶週淨損失的最大值',
    'panel.accounts.MinMargin.descr': '為部位而凍結的保證金',
    'panel.accounts.GrossPNL.descr': '本日的總損益',
    'panel.accounts.OptionPremiumReq.descr': '用於買入訂單的權利金',
    'panel.accounts.OptionValue.descr': '投資組合內的當前期權價值',
    'panel.accounts.Orders.descr': '訂單數量',
    'panel.accounts.Owner.descr': '帳戶擁有者',
    'panel.accounts.Positions.descr': '部位數量',
    'panel.accounts.Profit.descr': '所有已建倉部位的損益',
    'panel.accounts.ProfitNet.descr': '建倉時的淨利潤或損失',
    'panel.accounts.Role.descr': '基金類型',
    'panel.accounts.Status.descr': '帳戶擁有者使用者狀態（上線、離線）',
    'panel.accounts.Collateral.descr': '短部位的當前抵押品價值',
    'panel.accounts.StocksOrdersReq.descr': '股票訂單要求',
    'panel.accounts.StocksValue.descr': '投資組合內的當前股票價值',
    'panel.accounts.stopOutPercent.descr': '止損離場時的維持保證金要求 %',
    'panel.accounts.stopTradingPercent.descr': '停止交易水準，%',
    'panel.accounts.TodayFees.descr': '透過帳戶支付的費用金額',
    'panel.accounts.TodayRebates.descr': '經紀人就建立/移除當日的流動性而收到/支付的全部資金總額',
    'panel.accounts.TodayTrades.descr': '本日交易計數',
    'panel.accounts.TodayTurnover.descr': '本帳戶每日的資金周轉情況',
    'panel.accounts.TodayVolume.descr': '本日的成交量',
    'panel.accounts.TotalMaxPositionsQty.descr': '多口中所有部位的最大總數',
    'panel.accounts.TrailingDrawdownLevel.descr': '帳戶的最大回撤水準',
    'panel.accounts.UnsettledCash.descr': '將根據 T+n 價值週期記入帳戶貸方的損益',
    'panel.accounts.UserGroup.descr': '使用者群組',
    'panel.accounts.UserID.descr': '使用者 ID',
    'panel.accounts.UserLogin.descr': '使用者登入',
    'panel.accounts.WithdrawalAvailable.descr': '可用於提款的最大餘額',
    'panel.accounts.CurrentDailyLoss.descr': '帳戶的當前每日淨損失限值',
    'panel.accounts.CurrentWeeklyLoss.descr': '帳戶的當前每週淨損失限值',
    'panel.accounts.UnrealizedLoss.descr': '帳戶的當前未事先損失價值',
    'panel.accounts.UnrealizedLossLimit.descr': '帳戶的未實現損失的最大值',
    'panel.accounts.VolumeLimitForEquities.descr': '按帳戶的工具類型劃分的所有部位和訂單的最大匯總成交量。價值以口/契約、以金額（數量*口的規模或數量*跳動點成本/跳動點規模）或以現金暴險設定',
    'panel.accounts.VolumeLimitForFutures.descr': '按帳戶的工具類型劃分的所有部位和訂單的最大匯總成交量。價值以口/契約、以金額（數量*口的規模或數量*跳動點成本/跳動點規模）或以現金暴險設定',
    'panel.accounts.VolumeLimitForOptions.descr': '按帳戶的工具類型劃分的所有部位和訂單的最大匯總成交量。價值以口/契約、以金額（數量*口的規模或數量*跳動點成本/跳動點規模）或以現金暴險設定',
    'panel.accountDetails.Kyrrex risks.AvailableBalance.descr': '可用餘額',
    'panel.accountDetails.Kyrrex risks.BlockedBalance.descr': '凍結餘額',
    'panel.accountDetails.Groups.Cedro risks': '帳戶詳情',
    'panel.accountDetails.Cedro risks.SLDTL': '總餘額',
    'panel.accountDetails.Cedro risks.UNQL': '總限額',
    'Order.OrderStatus.STATUS_UNKNOWN': '未知',
    'Order.OrderStatus.STATUS_PENDING_NEW': '等待新',
    'Order.OrderStatus.STATUS_NEW': '已建立',
    'Order.OrderStatus.STATUS_ACCEPTED': '已接受',
    'Order.OrderStatus.STATUS_PENDING_EXECUTION': '等待執行',
    'Order.OrderStatus.STATUS_REPLACED': '已修改',
    'Order.OrderStatus.STATUS_PENDING_CANCEL': '等待取消',
    'Order.OrderStatus.STATUS_PART_FILLED': '已部分滿足',
    'Order.OrderStatus.STATUS_FILLED': '已滿足',
    'Order.OrderStatus.STATUS_PENDING_REPLACE': '等待修改',
    'Order.OrderStatus.STATUS_WAITING_MARKET': '等待市場',
    'Order.OrderStatus.STATUS_OFF_MARKET': '離場',
    'Order.OrderStatus.STATUS_UNPLACED': '未下達',
    'pageNavigation.nextButton': '下一步',
    'pageNavigation.backButton': '上一步',
    'pageNavigation.doneButton': '完成',
    'onBoarding.slide1.header1': '工作區區域',
    'onBoarding.slide1.text1': '工作區包含面板集、其組態設定和螢幕上的位置。工作區的變動會自動儲存',
    'onBoarding.slide2.header1': '檢視更多操作控制項',
    'onBoarding.slide2.text1': '複製——建立工作區的副本，重新命名——可為工作區重新命名',
    'onBoarding.slide2.text2': '無法透過兩次點選工作區板為工作區重新命名',
    'onBoarding.slide3.header1': '工作區鎖定控制項',
    'onBoarding.slide3.header2': '關閉工作區',
    'onBoarding.slide3.header3': '建立工作區',
    'onBoarding.slide3.text1': '如果工作區被鎖定，使用者無法關閉面板，加入新面板或進行其他操作',
    'onBoarding.slide3.text2': '您可透過點選"關閉"控制項關閉工作區',
    'onBoarding.slide3.text3': '點選"建立"控制項，即可建立新的工作區',
    'onBoarding.slide4.header1': '透過股票代號連結面板',
    'onBoarding.slide4.text1': '當您變更某個面板中的股票代碼時，該功能可讓您自動切換所有面板中的股票代號',
    'onBoarding.slide5.header1': '滑鼠交易',
    'onBoarding.slide5.text1': '使用滑鼠交易功能，即可下達止損單、限價單、止損限價單或二擇一委託單',
    'onBoarding.slide5.text2': '當使用滑鼠交易時，數量和委託時間有效期從小工具中設定',
    'onBoarding.slide5.text3': '使用拖放功能，即可修改圖表上的訂單和部位，以及加入止損/獲利單，或變更數量',
    'onBoarding.slide6.header1': '下單風控預覽',
    'onBoarding.slide6.text1': '執行下單前,可開啟風控預覽查看相關風控數據',
    'onBoarding.slide6.text2': '開啟/隱藏風控預覽',
    'onBoarding.slide7.header1': '銜接系統',
    'onBoarding.slide7.text1': '您可使用拖放功能，將面板移至工作區',
    'onBoarding.slide8.header1': '股票代號資訊',
    'onBoarding.slide8.text1': '點選關注清單的"資訊"控制項，即可檢視"股票代號資訊"',
    'onBoarding.slide9.header1': '帳戶詳情小工具',
    'onBoarding.slide9.text1': '您可透過拖放自訂小工具欄位。',
    'onBoarding.slide10.header1': '面板連結',
    'onBoarding.slide10.text1': '您可透過帳戶啟用面板的連結功能',
    'onBoarding.slide10.text2': '您可透過帳戶啟用訂單和部位篩選功能',
    'onBoarding.slide11.header1': '說明',
    'onBoarding.slide11.header2': '一般設定',
    'onBoarding.slide11.text1': '如需使用該手冊。請點選"說明"圖示',
    'onBoarding.slide11.text2': '您可透過點選"設定"控制項打開主設定選項單',
    'onBoarding.slide12.header1': '完成！',
    'IndicatorGroup.Extra': '額外',
    Didi: 'Didi',
    HiloEscandina: 'HiloEscandina',
    StopATR: 'StopATR',
    Fractals3: 'Fractals3',
    Fractals5: 'Fractals5',
    'Kaufman adaptive moving average': 'Kaufman adaptive moving average',
    'Multi EMA': 'Multi EMA',
    'Self-Adjusting Alpha with Fractals Energy': 'RSI Self-Adjusting Alpha with Fractals Energy',
    'panel.positions.confirmation.ModifyProductType': '將{2}，{3}，{4}的產品類型從{0}更改為{1}',

    'Web.mobile.active.WorkingOrders': '活動訂單',
    'Web.mobile.active.WorkingOrders.EmptyPlaceholder': '沒有訂單',
    'Web.mobile.active.FilledOrders': '已成交訂單',
    'Web.mobile.active.HistoryOrders': '歷史訂單',
    'Web.mobile.active.Positions': '倉位',
    'Web.mobile.active.Positions.EmptyPlaceholder': '沒有持倉',
    'Web.mobile.active.Assets': '資產',
    'Web.mobile.active.Orders': '訂單',
    'Web.mobile.active.More': '更多',
    'Web.mobile.active.MamSummary': 'MAM 摘要',
    'Web.mobile.active.OptionChain': '期權鏈',
    'Web.mobile.active.Alerts.NoAlerts': '無警報',
    'Web.mobile.active.EventLog.NoEvents': '無事件',
    'Web.mobile.active.EventLog.DateTime': '日期和時間',
    'Web.mobile.active.MamSummary.Column.OperationType': '操作類型',
    'Web.mobile.active.MamSummary.Column.Amount': '金額',
    'Web.mobile.active.More.Header': '更多',
    'Web.mobile.active.Settings.Defaults': '默認設置',
    'Web.mobile.active.Settings.QuantityInLots': '手數',
    'Web.mobile.active.Settings.SetSlTpInOffset': '設置 SL/TP 偏移',
    'Web.mobile.active.Settings.OrderSending': '發送訂單',
    'Web.mobile.active.Settings.OrderModifying': '修改訂單',
    'Web.mobile.active.Settings.OrderExecuting': '執行訂單',
    'Web.mobile.active.Settings.OrderCancelling': '取消訂單',
    'Web.mobile.active.Settings.PositionModifying': '修改持倉',
    'Web.mobile.active.Table.Total.Profit': '總利潤',
    'Web.mobile.active.NewTrades.PnL': '盈虧',
    'Web.mobile.active.OrderBook.ExecutionType': '執行類型',
    'Web.mobile.active.Assets.EmptyPlaceholder': '沒有資產',
    'Web.mobile.active.Assets.DefSymbol': '默認符號',
    'Web.mobile.active.Assets.SODQuantity': 'SOD 數量',
    'Web.mobile.active.Assets.CurrentValueForSale': '可售價值',
    'Web.mobile.active.Positions.Profit': 'P/L',
    'Web.mobile.active.AccountDetails.LinkingTooltip': '此功能按帳戶篩選您的倉位和訂單。帳戶切換在所有面板中同步。',
    'Web.mobile.active.Watchlist.EmptyPlaceholder': '沒有符號',
    'Web.mobile.active.Watchlist.ListNamePlaceholder': '在此處輸入名稱',
    'Web.mobile.active.Watchlist.Added': '已添加到監視列表',
    'Web.mobile.active.Watchlist.Removed': '已從監視列表中移除',
    'Web.mobile.active.Watchlist.MaxReached': '已達到最大數量',
    'Web.mobile.active.ExchangesFilter.Filter': '篩選器',
    'Web.mobile.active.OrderEntry.Send': '發送訂單',
    'Web.mobile.active.OrderEntry.Price': '價格',
    'Web.mobile.active.OrderEntry.TrStop': '跟蹤止損',
    'Web.mobile.active.OrderEntry.SlPrice': '止損價格',
    'Web.mobile.active.OrderEntry.SlOffset': '止損偏移',
    'Web.mobile.active.OrderEntry.TrSlOffset': '跟蹤止損偏移',
    'Web.mobile.active.OrderEntry.SllPrice': '止損限價價格',
    'Web.mobile.active.OrderEntry.SllOffset': '止損限價偏移',
    'Web.mobile.active.OrderEntry.TpPrice': '止盈價格',
    'Web.mobile.active.OrderEntry.TpOffset': '止盈偏移',
    'Web.mobile.active.Login.ExploreTheApp': '探索應用程序',
    'Web.mobile.active.InstrumentInfo.Price': '價格',
    'Web.mobile.active.InstrumentInfo.BreakEven': '保本價',
    'Web.mobile.active.Asset.SellExchange': '交易所',
    'Web.mobile.active.Asset.StartDayQtyMargin': 'MA 的 SOD 數量',
    'Web.mobile.active.Asset.LiquidityRate': '流動性費率 %',
    'Web.mobile.active.Asset.TodayTradedQty': '當日交易數量',
    'Web.mobile.active.Asset.AvailableQtySell': '可售數量',
    'Web.mobile.active.FilledOrder.TradeVolume': '交易量',
    'Web.mobile.active.FilledOrder.Date': '日期',
    'Web.mobile.active.FilledOrder.GrossPnL': '總損益',
    'Web.mobile.active.HistoryOrder.Date': '日期',
    'Web.mobile.active.MamSummary.EmptyPlaceholder': '在這段時期您沒有帳戶歷史紀錄',
    'Web.mobile.active.MamSummary.Account': '帳戶',
    'Web.mobile.active.MamSummary.OperationId': '操作 ID',
    'Web.mobile.active.MamSummary.InstrumentName': '工具名稱',
    'Web.mobile.active.MamSummary.OpenPrice': '開盤價格',
    'Web.mobile.active.MamSummary.ClosePrice': '收盤價格',
    'Web.mobile.active.MamSummary.Side': '方向',
    'Web.mobile.active.MamSummary.Quantity': '數量',
    'Web.mobile.active.MamSummary.Buy': '買入',
    'Web.mobile.active.MamSummary.Sell': '賣出',
    'Web.mobile.active.Position.StopLoss': '止損',
    'Web.mobile.active.Position.TakeProfit': '止盈',
    'Web.mobile.active.Position.Date': '日期',
    'Web.mobile.active.Order.StopLoss': '止損',
    'Web.mobile.active.Order.TrStopOffset': 'Trailing SL offset',
    'Web.mobile.active.Order.TrStopLoss': 'SL Trailing stop',
    'Web.mobile.active.Order.TakeProfit': '止盈',
    'Web.mobile.active.Order.QuantityFilled': 'Qty. filled',
    'Web.mobile.active.Search': '搜索',
    'Web.mobile.active.CalendarPeriod.Today': '今天',
    'Web.mobile.active.CalendarPeriod.LastWeek': '上周',
    'Web.mobile.active.CalendarPeriod.Last1M': '上個月',
    'Web.mobile.active.CalendarPeriod.Last3M': '過去 3 個月',
    'Web.mobile.active.CalendarPeriod.Last6M': '過去 6 個月',
    'Web.mobile.active.InstrumentDetails.6.SessionInfo': '日程安排',
    'Web.mobile.active.OrderModify.Confirmation.Title.Modify': '修改訂單',
    'Web.mobile.active.OrderModify.Confirmation.Title.Cancel': '取消訂單',
    'Web.mobile.active.OrderModify.Confirmation.Title.ExecuteAtMarket': '在市場上執行訂單？',
    'Web.mobile.active.OrderModify.Error.SLSmallerMin': '止損小於最小可能值',
    'Web.mobile.active.OrderModify.Error.SLGreaterMax': '止損大於最大可能值',
    'Web.mobile.active.OrderModify.Error.TPSmallerMin': '止盈小於最小可能值',
    'Web.mobile.active.OrderModify.Error.TPGreaterMax': '止盈大於最大可能值',
    'Web.mobile.active.PositionModify.Confirmation.Title': '修改倉位',
    'Web.mobile.active.PositionClose.Confirmation.Lots': '手',
    'Web.mobile.active.Lists': '清單',
    'Web.mobile.active.AccountDetails.Groups.1.AccountSummary': '帳戶摘要',
    'Web.mobile.active.AccountDetails.Groups.4.TodayResult': '今日結果',
    'Web.mobile.active.AccountDetails.MarginUsedPercent': '初始保證金要求 %',
    'Web.mobile.active.AccountDetails.OptionPremiumReq': '期權保證金要求',
    'Web.mobile.active.AccountDetails.OpenGrossPL': '開放總毛利/虧損',
    'Web.mobile.active.AccountDetails.OpenPostionsNumber': '開放職位',
    'Web.mobile.active.AccountDetails.OpenOrdersNumber': '工作訂單',
    'Web.mobile.active.AccountDetails.TodaysNetProfit': '今日淨利潤',
    'Web.mobile.active.Chart.IndicatorsMenu.AddedIndicators': '已添加指標',
    'Web.mobile.active.Chart.Menu.ChartSyle': '圖表風格',
    'Web.mobile.active.Chart.Menu.Settings': '設置',
    'Web.mobile.active.Chart.NoDataAvailable': 'No data available for {0}',
    'Web.mobile.active.TrailingStopLoss': 'Tr.',
    'Web.mobile.active.Position.GrossPnL': '總損益',
    'Web.mobile.active.Table.Total.GrossPnL': 'Total Gross P/L'
};
