// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ContainerControl } from '../ContainerControl';
import { SideBarTemplate } from '../../../templates.js';
import { Resources } from '../../../Commons/properties/Resources';
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { DropDownSideBar } from './DropDownSideBar';
import { SideBarButtonsHelper } from '../../../Commons/SideBar/SideBarButtonsHelper';
import { DataCache } from '../../../Commons/DataCache';
import { CustomerAccess } from '../../../Commons/CustomerAccess/CustomerAccess';
import { PanelDisplayMode } from '../../../Commons/CustomerAccess/PanelDisplayMode';

export class SideBar extends ContainerControl {
    private activeMainMenuBtn = null;

    public getType (): string {
        return 'SideBar';
    }

    oncomplete (): void {
        super.oncomplete();
        DataCache.OnReintialize.Subscribe(this.ReInitialVisible);
        this.subscribeToBtnsEvents();
        DropDownSideBar.ClosePanel.Subscribe(this.closeSidePanel, this);
        SideBarButtonsHelper.subscribeOnConnectedStatusChanged(this.OnConnectedStatusChanged);
        void super.set({ isHelpVisible: !Resources.isHidden('ribbon.enviroment.help') });
        window.addEventListener('resize', this.resizingProcess);
        this.localize();
    }

    private readonly OnConnectedStatusChanged = (connectedStatus: boolean): void => {
        void super.set({
            connectedStatus,
            localizedSettingsDisabledTT: Resources.getResource('ribbon.enviroment.settings.disabled.tt')
        });
    };

    private closeSidePanel (): void {
        void super.set({
            visibleSidePanel: false,
            activeSidePanel: null
        });
        this.activeMainMenuBtn?.set('focused', false);
        this.activeMainMenuBtn = null;
    }

    private subscribeToBtnsEvents (): void {
        super.on('alertClick', (btnContext) => {
            const panelType = PanelNames.AlertPanel;
            this.changeFocusedBtn(btnContext);
            void this.changeVisibleStatePanel(panelType);
        });
        super.on('ideasClick', (btnContext) => {
            const panelType = PanelNames.IdeasPanel;
            this.changeFocusedBtn(btnContext);
            void this.changeVisibleStatePanel(panelType);
        });
        super.on('notificationsClick', (btnContext) => {
            const panelType = PanelNames.NotificationsPanel;
            this.changeFocusedBtn(btnContext);
            void this.changeVisibleStatePanel(panelType);
        });
        super.on('videoWidgetBtnClick', (btnContext) => {
            const panelType = PanelNames.VideoWidget;
            this.changeFocusedBtn(btnContext);
            void this.changeVisibleStatePanel(panelType);
        });
        super.on('helpClick', () => {
            window.open(Resources.getResource('helpPage.link'));
        });
        super.on('settingsClick', () => {
            if (SideBarButtonsHelper.connectedStatus) { MainWindowManager.MainWindow.showGeneralSetting(); } // #111602
        });
        super.on('disconnectClick', () => {
            MainWindowManager.MainWindow.disconnectClick();
        });
    }

    private changeFocusedBtn (btnContext): void {
        if (!isNullOrUndefined(this.activeMainMenuBtn)) {
            this.activeMainMenuBtn.set('focused', false);
        }

        this.activeMainMenuBtn = btnContext;
        this.activeMainMenuBtn.set('focused', true);
    }

    private async changeVisibleStatePanel (type: PanelNames): Promise<void> {
        const activeSidePanel = super.get('activeSidePanel');
        const height = super.find('.side_bar')?.offsetHeight - 6;

        if (activeSidePanel === type) {
            this.closeSidePanel();
            return;
        } else {
            await super.set('visibleSidePanel', true);
            this.registerAllControl();
        }

        void super.set({
            activeSidePanel: type,
            heightSidePanelContainer: height
        });
    }

    private readonly ReInitialVisible = (): void => {
        this.initialVisibleAlertsPanel();
        this.initialVisibleIdeasPanel();
        this.initialVisibleNotificationsPanel();
        this.initialVisibleVideoWidget();
    };

    private initialVisibleAlertsPanel (): void {
        const alertsVisible = DataCache.AlertManager.AlertsVisible;
        void super.set('alertsBtnVisible', alertsVisible);
    }

    private initialVisibleIdeasPanel (): void {
        const ideasVisible = CustomerAccess.GetDisplayMode(PanelNames.IdeasPanel) === PanelDisplayMode.Allowed && !Resources.isHidden('panel.Ideas');
        void super.set('ideasBtnVisible', ideasVisible);
        if (ideasVisible) { DataCache.SendTradingSignalRequest(); }
    }

    private initialVisibleNotificationsPanel (): void {
        const notificationsVisible = CustomerAccess.GetDisplayMode(PanelNames.NotificationsPanel) === PanelDisplayMode.Allowed && !Resources.isHidden('panel.Notifications');
        void super.set('notificationsBtnVisible', notificationsVisible);
    }

    private initialVisibleVideoWidget (): void {
        const videoWidgetVisible = CustomerAccess.GetDisplayMode(PanelNames.VideoWidget) === PanelDisplayMode.Allowed && !Resources.isHidden('panel.VideoWidget');
        void super.set('videoWidgetBtnVisible', videoWidgetVisible);

        if (!videoWidgetVisible) {
            return;
        }

        const videoWidgetBtn = this.Controls.videoWidgetBtn;
        if (videoWidgetBtn) {
            DataCache.ExternalLinksCache.UpdateYouTubeLIVECounter.Subscribe(videoWidgetBtn.ChangeBlinkStatus, videoWidgetBtn);
        }
    }

    public onMouseDown (event): void {
        super.onMouseDown(event, false);
    }

    public lostFocus (): void {
        if (this.isLostFocusForbidden() || this.isSettingsOpen()) {
            if (this.customLostFocus()) { this.AfterLostFocusWaiting = this; } // return focus back to sideBar. SideBarPanel is opened;
            return;
        }
        this.get('SideBarPanelRef')?.lostFocus();
        super.lostFocus();
        this.closeSidePanel();
    }

    public isLostFocusForbidden (): boolean {
        return this.get('SideBarPanelRef')?.isLostFocusForbidden();
    }

    public customLostFocus (): boolean {
        return this.get('SideBarPanelRef')?.customLostFocus(this);
    }

    public isSettingsOpen (): boolean {
        return this.get('SideBarPanelRef')?.isSettingsOpen();
    }

    private readonly resizingProcess = (): void => {
        const height = this.find('.side_bar')?.offsetHeight - 6;
        void this.set('heightSidePanelContainer', height);
    };

    public localize (): void {
        void this.set({
            helpTooltip: Resources.getResource('ribbon.enviroment.help'),
            settingsTooltip: Resources.getResource('ribbon.enviroment.settings'),
            logoutTooltip: Resources.getResource('ribbon.enviroment.logout')
        });
    }
};

ContainerControl.extendWith(SideBar,
    {
        template: SideBarTemplate,
        data: function () {
            return {
                alertsBtnVisible: true,
                ideasBtnVisible: true,
                videoWidgetBtnVisible: true,
                notificationsBtnVisible: true,
                localkeyVideoWidget: '',
                visibleSidePanel: false,
                activeSidePanel: null,
                isHelpVisible: true,
                heightSidePanelContainer: 0,
                connectedStatus: false,
                localizedSettingsDisabledTT: '',
                topButtonAdditionalClass: 'buttonAdditionalClass',
                helpTooltip: '',
                settingsTooltip: '',
                logoutTooltip: ''
            };
        }
    });
