// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MDButton } from './MDButton';
import { OperationType } from '../../Utils/Trading/OperationType';
import { TerceraButtonStyle } from '../../Utils/Enums/ButtonEnums';

export class BuySellMDButton extends MDButton {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.observe('side', this.onSideChanged);
    }

    public onSideChanged (side): void {
        const buy = side === OperationType.Buy;
        void this.set(
            {
                terceraButtonStyle: TerceraButtonStyle[buy ? 'Buy' : 'Sell'],
                locKey: buy ? 'panel.Level2.oe.BuyButton' : 'panel.Level2.oe.SellButton'
            });
    }
}

MDButton.extendWith(BuySellMDButton, {
    data: function () {
        return {
            side: null
        };
    }
});
