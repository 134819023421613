// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { Resources } from '../../Commons/properties/Resources';
import { OrderTypeBaseParameters } from '../../Commons/cache/OrderParams/order-type/OrderTypeBaseParameters';
import { TerceraVisualTradingTemplate } from '../../templates.js';
import { OETIFSelector } from '../trading/OE/OETIFSelector';
import { ContainerControl } from './ContainerControl';
import { OrderType } from '../../Utils/Trading/OrderType';
import { OCOOrderType } from '../../Commons/cache/OrderParams/order-type/OCOOrderType';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Account } from '../../Commons/cache/Account';

export class TerceraVisualTradingWidget extends ContainerControl {
    public showChartSetting: any = null;
    public OnVisualTradingOrderTypeChange: any = null;
    public Placed: boolean;

    constructor () {
        super();

        this.OnVisualTradingOrderTypeChange = new CustomEvent();
    }

    public override getType (): string { return 'TerceraVisualTradingWidget'; }

    public override oncomplete (): void {
        super.oncomplete();
        this.Controls.OCO.btnInGroup = true;
        this.Controls.single.btnInGroup = true;
        this.Controls.qtyNumeric.settingsUpdate();
        this.localize();
        void this.setWidth();
    }

    public async setWidth (isVisible: boolean = false): Promise<void> {
        if (!isVisible) { return; }

        await this.set({ width: 0 }).then(async () => {
            const el = this.find('*');
            const w = el.offsetWidth;

            await this.set({ width: w });
        });
    }

    public override oninit (): void {
        this.on('single_click', () => {
            void this.set({ OCO_checked: false });
            this.OnVisualTradingOrderTypeChange.Raise(true);
        });
        this.on('OCO_click', () => {
            void this.set({ single_checked: false });
            this.OnVisualTradingOrderTypeChange.Raise(false);
        });
        this.on('settings_click', () => { this.showSetting(); });

        this.observe('instrumentItem defaultTif', this.repopulateTIFComboBox);
        this.observe('selectedItem gtdDate', this.updateTIF);

        this.observe('accountItem instrumentItem', () => {
            const ins: Instrument = this.get('instrumentItem');
            const acc: Account = this.get('accountItem');

            if (isNullOrUndefined(ins) || isNullOrUndefined(acc)) {
                return;
            }

            if (!isNullOrUndefined(this.Controls)) {
                this.Controls.qtyNumeric.settingsUpdate();
            }

            const oco = new OCOOrderType();

            const supportedParamObj = new OrderTypeBaseParameters(ins, acc);

            const supportOCO = oco.IsSupported(supportedParamObj);

            void this.set({ showOCObtns: supportOCO }).then(() => { void this.setWidth(this.get('visible')); });
        });
    }

    public repopulateTIFComboBox (): void {
        OETIFSelector.GetAdvancedComboBoxItems([OrderType.Limit, OrderType.Stop], this);
    }

    public updateTIF (): void {
        OETIFSelector.GetAdvancedUpdateTIF(this);
    }

    public localize (): void {
        void this.set({
            singleText: Resources.getResource('panel.newOrderEntry.singleOrderRadio'),
            ocoText: Resources.getResource('OCO')
        });
    }

    public showSetting (): void {
        if (!isNullOrUndefined(this.showChartSetting)) {
            this.showChartSetting();
        }
    }

    public async show (isVisible): Promise<void> {
        await this.set({ visible: isVisible }).then(async () => {
            await this.setWidth(isVisible);
        });
    }
}

ContainerControl.extendWith(TerceraVisualTradingWidget, {
    template: TerceraVisualTradingTemplate,
    data: function () {
        return {
            top: 0,
            left: 0,
            height: 29,
            movable: true,
            visible: false,
            ocoText: '',
            singleText: '',
            single_checked: true,
            OCO_checked: false,
            instrumentItem: null,
            accountItem: null,
            showOCObtns: true,
            showLeverage: false,
            tif: null,
            items: null,
            gtdDate: null,
            orderTypeId: null,
            selectedItem: null,

            // TODO. Remove. Ugly.
            defaultTif: null
        };
    }
});
