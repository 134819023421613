// Copyright TraderEvolution Global LTD. © 2017-2023. All rights reserved.

import { type ParamType } from '../../Commons/cache/AllowedReportConstants';
import { TerceraGroupPanelTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';

export class TerceraGroupPanel extends ContainerControl {
    public paramType: ParamType;
    public ReportParamName: string;

    constructor () { super(); }

    public override getType (): string { return 'TerceraGroupPanel'; }

    public override getClientPanel (customPlace: boolean): HTMLElement {
        void this.set('showRightContainer', customPlace);

        if (customPlace) {
            return this.find('.js-group-panel-right-container');
        } else {
            return this.find('.js-group-panel-left-container');
        }
    }
}

ContainerControl.extendWith(TerceraGroupPanel, {
    template: TerceraGroupPanelTemplate
});
