// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { TerceraChartTradingOperation } from '../../Chart/Utils/ChartConstants';
import { DataCache } from '../../Commons/DataCache';
import { CustomErrorClass, ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { GenerateReportMessages } from '../../Commons/GenerateReportMessages';
import { PositionEdit } from '../../Commons/cache/OrderParams/PositionEdit';
import { SLTPTriggerUtils } from '../../Commons/cache/OrderParams/SLTPTriggerUtils';
import { Resources } from '../../Commons/properties/Resources';
import { PanelNames } from '../../Controls/UtilsClasses/FactoryConstants';
import { SlTpPriceType } from '../Enums/Constants';
import { GeneralSettings } from '../GeneralSettings/GeneralSettings';
import { InstrumentUtils } from '../Instruments/InstrumentUtils';
import { OperationType } from './OperationType';
import { OrderEditUpdateData } from './OrderEditUpdateData';
import { type IOrderParams } from './OrderParams';
import { TIF } from './OrderTif';
import { OrderType } from './OrderType';
import { OrderUtils } from './OrderUtils';
import { PlacedFrom } from './PlacedFrom';
import { Quantity } from './Quantity';
import { SlTpHolder } from './SlTpHolder';

export class ChartTradingCore {
    isMobileChart = false;
    myTradeNumeric = null;
    myVisualWidget = null;
    chartOE = null;
    terceraChartRactive = null;
    TradingNumericErrorChecker = null;
    EditPositionScreen = null;
    ModifyOrderScreen = null;
    TerceraMessageBox = null;
    contextMenuHandler = null;
    MainWindowManager = null;

    constructor (isMobileChart = false) {
        this.isMobileChart = isMobileChart;
    }

    public ChartVisualTrading (order, newData, instrumentItem): any {
        const confirm = !this.isMobileChart;
        switch (newData.action) {
        case TerceraChartTradingOperation.ActionButton:{
            const tc = this.terceraChartRactive.terceraChart;
            const lastMouseMoveArgs = tc.lastMouseMoveArgs;
            const menu = tc.GetContextMenu(lastMouseMoveArgs, tc);
            // TODO?
            if (menu && !this.isMobileChart) {
                this.contextMenuHandler.Show(menu, lastMouseMoveArgs.ClientLocation.X, lastMouseMoveArgs.ClientLocation.Y);
            }
            return;
        }
        case TerceraChartTradingOperation.PlaceNewOrder:
            return this.placeNewOrderFromVisualTrading(newData.placeOrderParams, instrumentItem);

        case TerceraChartTradingOperation.CancelOrder:
            if (this.isMobileChart) { return null; }
            return DataCache.FOrderExecutor.cancelOrdersByIdPromise([order.OrderNumber], confirm, PlacedFrom.WEB_CHART_VISUAL);

        case TerceraChartTradingOperation.CancelOrdersAccount:
            void DataCache.FOrderExecutor.cancelOrdersByAccountPromise(order.Account, confirm, PlacedFrom.WEB_CHART_VISUAL);
            return;

        case TerceraChartTradingOperation.CancelOrdersSymbol:
            void DataCache.FOrderExecutor.cancelOrdersByInstrumentAndAccountPromise(order.Instrument, order.Accounttrue, confirm, PlacedFrom.WEB_CHART_VISUAL);
            return;

        case TerceraChartTradingOperation.ClosePosition:
            if (this.isMobileChart) { return null; }
            void DataCache.FOrderExecutor.closePositionsByIdPromise(
                [order.PositionId],
                confirm,
                PlacedFrom.WEB_CHART_VISUAL_DB_CLICK);
            return;

        case TerceraChartTradingOperation.ModifyOrderPosition:
            // TODO?
            if (this.isMobileChart) { return; }
            if (order.isClosingOrder() || order.isPosition) {
                this.EditPositionScreen?.show(order.PositionId,
                    PlacedFrom.WEB_CHART_VISUAL, null, true);
            } else {
                this.ModifyOrderScreen?.show(
                    order.OrderNumber,
                    PlacedFrom.WEB_CHART_VISUAL);
            }
            return;

        case TerceraChartTradingOperation.ModifyOrderQuantity:{
            // TODO?
            if (this.isMobileChart) {
                return;
            }

            const rect = newData.rect;
            const quantity = newData.quantity;

            this.myTradeNumeric.set({
                left: rect.X + 5,
                top: rect.Y + 25,
                width: rect.Width + 1,
                height: rect.Height,
                instrument: order.Instrument,
                noArrowCB: true,
                showArrows: false
            }).then(function () {
                this.myTradeNumeric.set({ value: quantity });
            }.bind(this));

            this.myTradeNumeric.set('visible', true);
            this.myTradeNumeric.Controls.QTYNumeric.setFocus();
            void new Promise(function (resolve, reject) {
                this.chartOE.parent.myTradeNumericPromise = resolve;
            }.bind(this)).then(function (quantity) {
                this.chartOE.parent.changeQuantity(order, { quantity: new Quantity(quantity, GeneralSettings.View.displayAmountInLots()) });
            }.bind(this));

            return;
        }
        case TerceraChartTradingOperation.PlaceSL:
        case TerceraChartTradingOperation.ModifySL:
        case TerceraChartTradingOperation.PlaceTP:
        case TerceraChartTradingOperation.ModifyTP:
        case TerceraChartTradingOperation.PlaceSLL:
        case TerceraChartTradingOperation.ModifySLL:
        case TerceraChartTradingOperation.RemoveSLL:
            newData.placedFrom = PlacedFrom.WEB_CHART_VISUAL;
            if (order.isPosition) {
                if (order.IsPendingExerciseOptionStatus()) {
                    GenerateReportMessages.GenerateRefusalOnOptionExercise(order.GetInstrumentDisplayName()); // #122442
                }
                return this.changePositionSLTP(order, newData);
            } else {
                return this.changeOrderSLTP(order, newData);
            }

        case TerceraChartTradingOperation.RemoveSL:
        case TerceraChartTradingOperation.RemoveTP:
            newData.placedFrom = PlacedFrom.WEB_CHART_VISUAL;
            if (order.isPosition) {
                const sltp = order[newData.action == TerceraChartTradingOperation.RemoveSL ? 'SLOrder' : 'TPOrder'];
                void DataCache.FOrderExecutor.cancelOrdersByIdPromise([sltp.OrderNumber], confirm, PlacedFrom.WEB_CHART_VISUAL);
            } else {
                return this.changeOrderSLTP(order, newData);
            }
            return;

        case TerceraChartTradingOperation.MoveOrder:
            newData.placedFrom = PlacedFrom.WEB_CHART_VISUAL;
            return this.moveOrder(order, newData);

        case TerceraChartTradingOperation.ChangeToMarket:
            void DataCache.FOrderExecutor.changeOrdersToMarketPromise([order.OrderNumber], confirm, PlacedFrom.WEB_CHART_VISUAL);
            return;

        case TerceraChartTradingOperation.AlertReplacement:
            DataCache.AlertManager.ReplaceAlertFromChart(order, newData);
            return;

        case TerceraChartTradingOperation.EditAlert:{
            // TODO?
            if (this.isMobileChart) {
                return;
            }

            const panel = this.MainWindowManager?.Factory.addPanel(PanelNames.CreateAlertPanel);
            panel.set('movable', true);
            panel.center();
            panel.setUpdateParams(order);
            panel.setFocus();
            return;
        }
        case TerceraChartTradingOperation.RemoveAlert:
            DataCache.AlertManager.RemoveAlertProcess(order, null, confirm);
            return;

        case TerceraChartTradingOperation.ChartNewAlert:
            DataCache.AlertManager.ChartNewAlert(newData);
        }
    }

    public placeNewOrderFromVisualTrading (placeOrderParams, instrumentItem): any {
        if (!this.myVisualWidget || this.TradingNumericErrorChecker?.HasErrors(this.myVisualWidget)) {
            return this.WM_placeNewOrderFromVisualTrading(placeOrderParams, instrumentItem);
        }

        placeOrderParams.fromChart = true;
        placeOrderParams.place = true;
        placeOrderParams.lTif = this.myVisualWidget.get('selectedItem').value;

        placeOrderParams.expireTime = this.myVisualWidget.get('gtdDate');

        placeOrderParams.quantity = this.myVisualWidget.get('quantity');
        placeOrderParams.placedFrom = PlacedFrom.WEB_CHART_VISUAL;

        const chartOE = this.chartOE;
        placeOrderParams.productType = chartOE.get('productType');
        placeOrderParams.leverageValue = this.myVisualWidget.get('leverageValue');

        try {
            const ins = instrumentItem;
            const route = DataCache.getRouteByName(ins.Route);
            const isAllowedByTIFandOrderType = route.IsAllowableTif(placeOrderParams.lTif, placeOrderParams.lOrdType);

            if (!isAllowedByTIFandOrderType) {
                this.TerceraMessageBox?.Show(Resources.getResource('screen.error.title'), Resources.getResource('IsAllowedResponceReason.NotAllowedRouteOrderType'), this.TerceraMessageBox?.msgType.Error, null, null, false, true);
                return;
            }
        } catch (err) {
            console.error(err);
        }

        chartOE.OrderParamsSet(placeOrderParams);
    }

    public WM_placeNewOrderFromVisualTrading (orderParams: IOrderParams, instrumentItem): any {
        orderParams.fromChart = true;
        orderParams.place = true;
        // placeOrderParams["lTif"] = this.myVisualWidget.get("selectedItem").value;

        // placeOrderParams["expireTime"] = this.myVisualWidget.get("gtdDate");

        // placeOrderParams["quantity"] = this.myVisualWidget.get("quantity");
        orderParams.placedFrom = PlacedFrom.WEB_CHART_VISUAL;

        // placeOrderParams["productType"] = chartOE.get('productType')
        // placeOrderParams["leverageValue"] = chartOE.get('leverageValue')

        if (isNullOrUndefined(orderParams)) {
            return null;
        }

        const orderType = orderParams.lOrdType;
        const orderTypeObj = DataCache.OrderParameterContainer.OrderTypes[orderType];

        const orderEditCtorData = {
            dataCache: DataCache,
            // TODO. UGLY. Details are in OrderEditBase.ts
            forceSLTPOffset: true
        };

        // TODO. Ugly.
        const orderEdit = orderTypeObj.createOrderEditObject(orderEditCtorData);

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            {
                account: orderParams.account,
                instrument: orderParams.instrument,
                side: orderParams.lBuySell,

                quantity: new Quantity(
                    orderParams.quantity,
                    GeneralSettings.View.displayAmountInLots()),

                tif: new TIF(orderParams.lTif, orderParams.expireTime),
                productType: orderParams.productType,
                leverageValue: orderParams.leverageValue,
                placedFrom: orderParams.placedFrom
            }));

        const chartSLTPHolder = this.getChartSLTPHolder(orderParams);

        // TODO. Ugly.
        switch (orderType) {
        case OrderType.Limit:
            orderEdit.setLimitPrice(orderParams.dPrice);
            break;
        case OrderType.Stop:
            orderEdit.setStopPrice(orderParams.dStopPrice);
            break;
        case OrderType.StopLimit:
            orderEdit.setBasePrice(orderParams.dStopPrice);
            break;
            // case OrderType.OCO:
            //     this.setOCOOrderEdit(orderEdit, orderParams)
            //     break
        }

        // TODO. Ugly.
        orderEdit.setSLTP(chartSLTPHolder);

        return orderEdit;
    }

    public getChartSLTPHolder = function (orderParams: IOrderParams): SlTpHolder {
        // Chart SL/TP are always offset.
        // const offsetType = GeneralSettings.TradingDefaults.ShowOffsetIn;
        const instrument = orderParams.instrument;

        const sltpHolder = new SlTpHolder();

        const slEnabled = !isNaN(orderParams.slPriceValue);
        // let trStopEnabled = this.get('enabledSL') && this.get('trStopChecked')

        if (slEnabled) {
            sltpHolder.StopLossPriceValue = orderParams.slPriceValue;
            // OrderUtils.toRawTicks(orderParams.slPriceValue, offsetType, instrument)

            // sltpHolder.StopLossPriceType = trStopEnabled
            //     ? SlTpPriceType.TrOffset
            //     : SlTpPriceType.Offset

            sltpHolder.StopLossPriceType = SlTpPriceType.Absolute;

            // if (this.get('isSLLVisible'))
            //     sltpHolder.StopLossLimitPriceValue =
            //         OrderUtils.toRawTicks(this.get('priceSLL'), offsetType, instrument)
        }

        const tpEnabled = !isNaN(orderParams.tpPriceValue);
        if (tpEnabled) {
            sltpHolder.TakeProfitPriceValue = orderParams.tpPriceValue;
            // OrderUtils.toRawTicks(orderParams.tpPriceValue, offsetType, instrument)

            sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
        }

        const anyEnabled = slEnabled /* || trStopEnabled */ || tpEnabled;
        if (anyEnabled) // #110841
        {
            sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetTriggersShortForInstrument(instrument);
        }

        return sltpHolder;
    };

    public changePositionSLTP (position, newData): PositionEdit {
        const action = newData.action;
        const modifyPosObj = new PositionEdit({
            dataCache: DataCache,
            position,
            placedFrom: newData.placedFrom
        });
        // TODO. Ugly. Method is not base,
        // it works just cuz JS doesn't give a dayum.
        const sltpHolder = modifyPosObj.getRawSLTP();

        const newPrice = newData.price;
        const newIsTrStop = newData.ts;
        const ins = position.Instrument;

        switch (action) {
        case TerceraChartTradingOperation.PlaceTP:
        case TerceraChartTradingOperation.ModifyTP:
            sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            sltpHolder.TakeProfitPriceValue = newPrice;

            if (position.SLOrder) {
                sltpHolder.OpenLossPriceValue =
                        InstrumentUtils.getPriceDifferenceInTicks(position.OpenPrice, position.SLOrder.Price, ins);
            }
            break;

        case TerceraChartTradingOperation.PlaceSL:
        case TerceraChartTradingOperation.ModifySL:{
            const roundedOpenPrice = ins.RoundPriceToNearestPointSize(position.OpenPrice);
            const roundedPrice = ins.RoundPriceToNearestPointSize(position.Price);
            const roundedNewPrice = ins.RoundPriceToNearestPointSize(newPrice);

            const sign = (position.BuySell ? -1 : 1) * Math.sign(roundedPrice - roundedNewPrice);
            sltpHolder.StopLossPriceType =
                    newIsTrStop ? SlTpPriceType.TrOffset : SlTpPriceType.Offset;
            sltpHolder.StopLossPriceValue =
                    InstrumentUtils.getPriceDifferenceInTicks(roundedOpenPrice, roundedNewPrice, ins) * sign;
            sltpHolder.OpenLossPriceValue =
                    InstrumentUtils.getPriceDifferenceInTicks(roundedOpenPrice, roundedNewPrice, ins) * sign;

            if (sltpHolder.StopLossPriceType != SlTpPriceType.TrOffset) {
                sltpHolder.StopLossLimitPriceValue = newData.sllPrice
                    ? InstrumentUtils.getPriceDifferenceInTicks(roundedPrice, newData.sllPrice, ins) * (position.BuySell ? -1 : 1) * Math.sign(roundedPrice - newData.sllPrice)
                    : sltpHolder.OpenLossPriceValue + GeneralSettings.TradingDefaults.LimitOffsetTicks; // default offset if place SL
            }

            break;
        }
        case TerceraChartTradingOperation.RemoveTP:
            sltpHolder.TakeProfitPriceValue = NaN;
            break;
        case TerceraChartTradingOperation.RemoveSL:
        case TerceraChartTradingOperation.RemoveSLL:
            sltpHolder.StopLossPriceValue = NaN;
            sltpHolder.StopLossLimitPriceValue = null;
            break;

        case TerceraChartTradingOperation.PlaceSLL:
        case TerceraChartTradingOperation.ModifySLL:
            if (newIsTrStop) {
                sltpHolder.StopLossPriceType = SlTpPriceType.TrOffset;
                sltpHolder.StopLossPriceValue = InstrumentUtils.getPriceDifferenceInTicks(position.CurPriceClose, newPrice, ins);
            } else {
                sltpHolder.StopLossLimitPriceValue =
                        sltpHolder.StopLossPriceType === SlTpPriceType.Absolute
                            ? newPrice
                            : InstrumentUtils.getPriceDifferenceInTicks(position.StopLimit, newPrice, ins);
            }

            break;
        }

        if (sltpHolder.TakeProfitPriceValue || sltpHolder.StopLossPriceValue) {
            sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetShortByOrder(position);
        }

        // TODO. Ugly. Method is not base,
        // it works just cuz JS doesn't give a dayum.
        modifyPosObj.setSLTP(sltpHolder);
        // Trailing stop implicit modification fix.
        // For instance: dragging of tp order causes tr stop order change as well
        // due to setting up trailing stop value in offset ticks.
        // Server treats it as an offset relative to current price...
        // Closing trailing stop order receives price updates from a server,
        // but Order.TrStopOffset field isn't recalculated accordingly.
        // Whatever. Refactor this pile of shit.
        modifyPosObj.setCanEditSLOrTrailingStop(
            action === TerceraChartTradingOperation.PlaceSL ||
            action === TerceraChartTradingOperation.ModifySL ||
            action === TerceraChartTradingOperation.RemoveSL ||
            action === TerceraChartTradingOperation.PlaceSLL ||
            action === TerceraChartTradingOperation.ModifySLL ||
            action === TerceraChartTradingOperation.RemoveSLL);
        modifyPosObj.setCanEditTP(
            action === TerceraChartTradingOperation.PlaceTP ||
            action === TerceraChartTradingOperation.ModifyTP ||
            action === TerceraChartTradingOperation.RemoveTP);

        if (this.isMobileChart) {
            return modifyPosObj;
        }

        DataCache.FOrderExecutor.modifyPositionPromise(modifyPosObj, this.isMobileChart)
            .then(function (confirmed) {
                if (newData.CancelCallback && !confirmed) {
                    newData.CancelCallback();
                }
            })
            .catch(function () {
                const ex = new CustomErrorClass('ChangePositionSLTP error', 'ChartPanel.changePositionSLTP', 'changePositionSLTP -> modifyPositionPromise');
                ErrorInformationStorage.GetException(ex);

                if (newData.CancelCallback) {
                    newData.CancelCallback();
                }
            })
            .finally(function () {
                // TODO. Refactor.
                modifyPosObj.dispose();
            });
    }

    public changeOrderSLTP = function (order, newData): any {
        const action = newData.action;
        const modifyOrdObj = this.createModifyOrderObject(order);
        modifyOrdObj.placedFrom = newData.placedFrom;
        // TODO. Ugly. Method is not base,
        // it works just cuz JS doesn't give a dayum.
        const sltpHolder = modifyOrdObj.getRawSLTP();

        const trSett = GeneralSettings.TradingDefaults;
        const ins = order.Instrument;
        const orderPrice = order.Price;

        const newPrice = newData.price;
        const newIsTrStop = newData.ts;
        const isBuy = order.BuySell === OperationType.Buy;

        switch (action) {
        case TerceraChartTradingOperation.PlaceTP:
        case TerceraChartTradingOperation.ModifyTP:
            sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            sltpHolder.TakeProfitPriceValue = newPrice;
            sltpHolder.OpenLossPriceValue = order.StopLossPriceValue;
            break;

        case TerceraChartTradingOperation.PlaceSL:
        case TerceraChartTradingOperation.ModifySL:{
            const wasTrStop = sltpHolder.StopLossPriceType == SlTpPriceType.TrOffset;

            sltpHolder.StopLossPriceType =
                    newIsTrStop ? SlTpPriceType.TrOffset : SlTpPriceType.Offset;

            const offsetValue = InstrumentUtils.getPriceDifferenceInTicks(orderPrice, newPrice, ins);
            sltpHolder.StopLossPriceValue = offsetValue;
            sltpHolder.OpenLossPriceValue = offsetValue;

            if (newIsTrStop) {
                sltpHolder.StopLossLimitPriceValue = null;
            } else {
                const priceToCalcFrom = trSett.SetSlTpValuesInOffset ? newPrice : orderPrice;

                sltpHolder.StopLossLimitPriceValue = sltpHolder.StopLossLimitPriceValue != null
                    ? InstrumentUtils.getPriceDifferenceInTicks(priceToCalcFrom, newData.sllPrice, ins) * (isBuy ? 1 : -1) * Math.sign(priceToCalcFrom - newData.sllPrice)
                    : (action == TerceraChartTradingOperation.PlaceSL || wasTrStop ? trSett.LimitOffsetTicks : null);
            }
            break;
        }
        case TerceraChartTradingOperation.PlaceSLL:
        case TerceraChartTradingOperation.ModifySLL:{
            const slPrice = order.GetStopLossInPriceValue();
            const sign = (isBuy ? 1 : -1) * Math.sign(slPrice - newPrice);

            sltpHolder.StopLossLimitPriceValue = sltpHolder.StopLossPriceType === SlTpPriceType.Absolute
                ? newPrice
                : InstrumentUtils.getPriceDifferenceInTicks(slPrice, newPrice, ins) * sign;
            break;
        }
        case TerceraChartTradingOperation.RemoveTP:
            sltpHolder.TakeProfitPriceValue = NaN;
            break;
        case TerceraChartTradingOperation.RemoveSL:
        case TerceraChartTradingOperation.RemoveSLL:
            sltpHolder.StopLossPriceValue = NaN;
            sltpHolder.StopLossLimitPriceValue = null;
            break;
        }

        if (sltpHolder.TakeProfitPriceValue || sltpHolder.StopLossPriceValue) {
            sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetShortByOrder(order);
        }

        // TODO. Ugly. Method is not base,
        // it works just cuz JS doesn't give a dayum.
        modifyOrdObj.setSLTP(sltpHolder);

        if (this.isMobileChart) {
            return modifyOrdObj;
        }

        DataCache.FOrderExecutor.modifyOrderPromiseWithCallbacks(modifyOrdObj, null, null, this.isMobileChart)
            .then(function (confirmed) {
                if (newData.CancelCallback && !confirmed) {
                    newData.CancelCallback();
                }
            })
            .catch(function () {
                const ex = new CustomErrorClass('ChangeOrderSLTP error', 'ChartPanel.changeOrderSLTP', 'changeOrderSLTP -> modifyOrderPromiseWithCallbacks');
                ErrorInformationStorage.GetException(ex);

                if (newData.CancelCallback) {
                    newData.CancelCallback();
                }
            })
            .finally(function () {
                // TODO. Refactor.
                modifyOrdObj.dispose();
            });
    };

    public moveOrder (order, newData): any {
        const newPrice = newData.price;
        const ins = order.Instrument;
        const isLimitModify = newData.isLimitModify;

        const modifyOrdObj = this.createModifyOrderObject(order);

        if (modifyOrdObj === null) {
            if (newData.CancelCallback) {
                newData.CancelCallback();
                return;
            }
        }
        // Moving sl/tp along with order's price change.
        // TODO. UGLY as fook boi.
        // Trailing stop's sl/tp values are always in offset,
        // thus there's no need to change sl/tp.
        const ordTypes = OrderType;
        switch (order.OrderType) {
        case ordTypes.Stop:
        case ordTypes.StopLimit:
        case ordTypes.Limit:
            // TODO. UGLY. It works just cuz JS doesn't give a dayum.
            const oldBasePrice = modifyOrdObj.getBasePrice();
            let tmp_nP = newPrice;
            if (order.OrderType === ordTypes.StopLimit) {
                modifyOrdObj.setBasePrice(newPrice, isLimitModify, true);
                tmp_nP = modifyOrdObj.getBasePrice();
            }
            let priceDifferenceInTicks = InstrumentUtils.getPriceDifferenceInTicks(oldBasePrice, tmp_nP, ins);

            if (priceDifferenceInTicks === 0) {
                break;
            }
            // Ewww.
            priceDifferenceInTicks *= tmp_nP > oldBasePrice ? 1 : -1;
            const sltpHolder = modifyOrdObj.getRawSLTP();

            if (!isNaN(sltpHolder.StopLossPriceValue) &&
                    (sltpHolder.StopLossPriceType === SlTpPriceType.Offset || sltpHolder.StopLossPriceType === SlTpPriceType.TrOffset)) {
                sltpHolder.OpenLossPriceValue = order.StopLossPriceValue;
            }

            // Ignore if value is already in offset.
            if (!isNaN(sltpHolder.StopLossPriceValue) &&
                    sltpHolder.StopLossPriceType === SlTpPriceType.Absolute) {
                sltpHolder.StopLossPriceValue = OrderUtils.ConvertTickOffset(
                    ins,
                    null,
                    sltpHolder.StopLossPriceValue,
                    priceDifferenceInTicks);
            }

            // Ignore if value is already in offset.
            if (sltpHolder.StopLossLimitPriceValue !== null &&
                    sltpHolder.StopLossPriceType === SlTpPriceType.Absolute) {
                sltpHolder.StopLossLimitPriceValue = OrderUtils.ConvertTickOffset(
                    ins,
                    null,
                    sltpHolder.StopLossLimitPriceValue,
                    priceDifferenceInTicks);
            }

            // Ignore if value is already in offset.
            if (!isNaN(sltpHolder.TakeProfitPriceValue) &&
                    sltpHolder.TakeProfitPriceType === SlTpPriceType.Absolute) {
                sltpHolder.TakeProfitPriceValue = OrderUtils.ConvertTickOffset(
                    ins,
                    null,
                    sltpHolder.TakeProfitPriceValue,
                    priceDifferenceInTicks);
            }

            // TODO. Ugly. Method is not base,
            // it works just cuz JS doesn't give a dayum.
            modifyOrdObj.setSLTP(sltpHolder);
            break;
        }

        modifyOrdObj.setBasePrice(newPrice, isLimitModify, true);
        modifyOrdObj.placedFrom = newData.placedFrom;

        if (this.isMobileChart) {
            return modifyOrdObj;
        }

        DataCache.FOrderExecutor.modifyOrderPromiseWithCallbacks(modifyOrdObj, null, null, this.isMobileChart)
            .then(function (confirmed) {
                if (newData.CancelCallback && !confirmed) {
                    newData.CancelCallback();
                }
            })
            .catch(function () {
                const ex = new CustomErrorClass('MoveOrder error', 'ChartPanel.moveOrder', 'moveOrder -> modifyOrderPromiseWithCallbacks');
                ErrorInformationStorage.GetException(ex);

                if (newData.CancelCallback) {
                    newData.CancelCallback();
                }
            })
            .finally(function () {
                // TODO. Refactor.
                modifyOrdObj.dispose();
            });
    }

    public createModifyOrderObject (order): any {
        const orderTypeObj = DataCache.OrderParameterContainer.GetOrderType(order.OrderType);
        return orderTypeObj.createModifyOrderObject({
            dataCache: DataCache,
            order
        });
    }
}
