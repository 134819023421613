// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ControlsUtils } from '../UtilsClasses/ControlsUtils';
import { TerceraMultiComboBoxTemplate } from '../../templates.js';
import { Control } from './Control';
import { multiComboBoxPopupMenuHandler } from '../../Utils/AppHandlers';

export class TerceraMultiComboBox extends Control {
    public scrollIsShown: boolean = false;
    public c: boolean = false;
    public isPopupShown: boolean;

    public override getType (): string { return 'TerceraMultiComboBox'; }

    public override oninit (): void {
        super.oninit();
        this.on('showPopup', this.showPopup);
        this.popupCallBack = this.popupCallBack.bind(this);
        this.observe('items', this.onItemsChanged);
    }

    public onItemsChanged (): void {
        this.fire(MultiComboBoxEvents.ValueChange);
    }

    public override lostFocus (): void {
        super.lostFocus();
        this.isPopupShown = false;
        this.getPopup().Hide();
    }

    public setBooleans (boolArr): void {
        let i = -1;
        void this.set('items', this.get('items').map(element => {
            element.checked = boolArr[i++];
            return element;
        }));
    }

    public showPopup (): void {
        const items: any[] = this.get('items');
        if (!this.get<boolean>('enabled') || ((items?.length) === 0)) {
            return;
        }

        if (this.isPopupShown) {
            this.isPopupShown = false;
            this.getPopup().Hide();
            return;
        }

        const loc = ControlsUtils.getAbsoluteLocation(this);

        this.isPopupShown = true;
        this.getPopup().Show(
            items,
            loc.X,
            loc.Y + 26,
            this.find('*').offsetWidth,
            this.popupCallBack,
            this.get('hideAll'),
            this.get('useLastItem'));
    }

    public popupCallBack (popupItems: any[]): void {
        this.isPopupShown = false;

        const popupItemsByText = {};
        if (isValidArray(popupItems)) { // converting array to object for instant item search
            popupItems.forEach(pI => {
                if (isValidString(pI.text)) {
                    popupItemsByText[pI.text] = pI;
                }
            });
        }

        const items = this.get('items');
        for (let i = 0; i < items.length; i++) {
            const popupItem = popupItemsByText[items[i].text];
            if (popupItem != null) { // popupItem is user's selection, so we should use its value
                items[i].checked = popupItem.checked;
            }
        }

        void this.set('items', items); // this.update('items');
    }

    public getPopup (): any {
        return multiComboBoxPopupMenuHandler;
    }

    public isEmpty (): boolean {
        const items = this.get('items');
        return !isValidArray(items);
    }

    public isAllItemsChecked (): boolean {
        if (this.isEmpty()) {
            return true;
        }
        const items = this.get('items');
        for (let i = 0; i < items.length; i++) {
            if (!items[i].checked) {
                return false;
            }
        }
        return true;
    }

    public getItemsMap (): Map<any, boolean> {
        const map = new Map<any, boolean>();
        if (this.isEmpty()) {
            return map;
        }
        const items = this.get('items');
        for (let i = 0; i < items.length; i++) {
            map.set(items[i].value, items[i].checked);
        }
        return map;
    }

    public getSelectedItems (): any[] {
        const selectedValues: any[] = [];
        if (this.isEmpty()) {
            return selectedValues;
        }
        const items = this.get('items');
        for (let i = 0; i < items.length; i++) {
            if (items[i].checked) {
                selectedValues.push(items[i].value);
            }
        }
        return selectedValues;
    }
}

// Events
export enum MultiComboBoxEvents {
    ValueChange = 'ValueChange'
};

Control.extendWith(TerceraMultiComboBox, {
    data: function () {
        return {
            items: null,
            allItemsSelectedText: '',
            noItemsSelectedText: '',
            noItemsText: '',
            fewItemsSelectedPostfixText: '',
            hideAll: false,
            useLastItem: false
        };
    },
    computed: {
        selectionText: {
            get: function () {
                const items = this.get('items');
                if (!isValidArray(items)) {
                    return this.get('noItemsText');
                };

                let lastCheckedIdx = -1;
                let checkedCount = 0;
                const len = items.length;

                for (let i = 0; i < len; i++) {
                    if (!items[i].checked) continue;
                    lastCheckedIdx = i;
                    checkedCount++;
                }

                if (checkedCount === len) {
                    return this.get('allItemsSelectedText');
                } else if (checkedCount === 0) {
                    return this.get('noItemsSelectedText');
                } else if (checkedCount === 1) {
                    return items[lastCheckedIdx].text;
                } else {
                    return checkedCount + '/' + len + ' ' +
                        this.get('fewItemsSelectedPostfixText');
                }
            },
            set: function () { }
        }
    },
    template: TerceraMultiComboBoxTemplate
});
