// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DocumentUtils } from '../../../../Utils/DocumentUtils';
import { VerticalPanelColoringTypes } from '../../../VerticalGroupedPanels/VerticalPanelColoringTypes';

export class RowItemDataStringPair {
    public firstValue: string = null;
    public secondValue: string = null;

    constructor (first: string, second: string = null) {
        this.firstValue = first;
        this.secondValue = second;
    }

    public isValid (): boolean {
        return isValidString(this.firstValue);
    }

    public isNAString (): boolean {
        return this.firstValue === NAString;
    }

    public isSingle (): boolean {
        return this.secondValue === null;
    }

    public toString (): string {
        let result = this.firstValue;
        if (!this.isSingle()) { result += `/${this.secondValue}`; }
        return result;
    }
}

export class RowItemDataNumberPair {
    public firstValue: number = null;
    public secondValue: number = null;

    constructor (first: number, second: number = null) {
        this.firstValue = first;
        this.secondValue = second;
    }

    public isValid (): boolean {
        if (this.isSingle()) { return !isNullOrUndefined(this.firstValue); }

        return !isNullOrUndefined(this.firstValue) && !isNullOrUndefined(this.firstValue);
    }

    public isSingle (): boolean {
        return this.secondValue === null;
    }
}

export class RowItemDataColorPair {
    public firstValue: string = DocumentUtils.TABLE_WHITE;
    public secondValue: string = DocumentUtils.TABLE_WHITE;

    constructor (dataForColoring: RowItemDataNumberPair, coloringType: VerticalPanelColoringTypes) {
        const coloringHandler = this.getColoringMethod(coloringType);
        if (dataForColoring === null) { return; }
        this.firstValue = coloringHandler(dataForColoring.firstValue);
        if (!dataForColoring.isSingle()) { this.secondValue = coloringHandler(dataForColoring.secondValue); }
    }

    private getColoringMethod (coloringType: VerticalPanelColoringTypes): (value: number) => string {
        if (coloringType === VerticalPanelColoringTypes.LiveLeverage) { return DocumentUtils.getColorTableForLiveLeverage; }
        return DocumentUtils.getColorTableStyle;
    }
}

export const NAString = 'N/A';
