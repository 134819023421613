// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { PFixFieldSet } from '../Fields/FieldsImport';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type InstrumentDeliveryType } from '../../../../Utils/Instruments/InstrumentDelivertyType';
import { type InstrumentTypes } from '../../../../Utils/Instruments/InstrumentTypes';

export class NonFixedInstumentListRequest extends PFixMessage {
    constructor (fieldSet: PFixFieldSet, serverMaxFieldNumber: number, aliasLanguage?: string | null) {
        super(Message.CODE_INSTRUMENT_LIST_REQUEST, fieldSet);

        if (aliasLanguage && serverMaxFieldNumber !== Number.MAX_VALUE && serverMaxFieldNumber >= FieldsFactory.FIELD_LANG) {
            this.setFieldValue(FieldsFactory.FIELD_LANG, aliasLanguage);
        }
    }

    public setFilterName (filterName: string): void {
        this.setFieldValue(FieldsFactory.FIELD_NAME, filterName);
    }

    public setExchangeIDs (exchangeIDs: string []): void {
        if (exchangeIDs !== null) {
            for (let i = 0; i < exchangeIDs.length; i++) {
                const value = exchangeIDs[i];
                const group = new PFixFieldSet(FieldsFactory.EXCHANGE_GROUP);
                group.SetField(FieldsFactory.FIELD_TRADING_EXCHANGE, value);
                this.FieldSet.AddGroup(group);
            }
        }
    }

    public setDelivery (delivery: InstrumentDeliveryType): void {
        const group = new PFixFieldSet(FieldsFactory.DELIVERY_METHOD_ID_GROUP);
        group.SetField(FieldsFactory.FIELD_DELIVERY_METHOD_ID, delivery);
        this.FieldSet.AddGroup(group);
    }

    public setInstrumentTypes (instrumentTypes: InstrumentTypes[]): void {
        if (instrumentTypes !== null) {
            for (let i = 0; i < instrumentTypes.length; i++) {
                const value = instrumentTypes[i];
                const group = new PFixFieldSet(FieldsFactory.INSTRUMENT_TYPE_GROUP);
                group.SetField(FieldsFactory.FIELD_TYPE, value);
                this.FieldSet.AddGroup(group);
            }
        }
    }

    public setRequestId (p: number): void {
        this.setFieldValue(FieldsFactory.FIELD_REQUEST_ID, p);
    }

    public setAssetName (AssetName: string): void {
        this.setFieldValue(FieldsFactory.FIELD_ASSET_SHARES_NAMES, AssetName);
    }

    public setSymbolLookupType (isOldType: boolean): void {
        this.setFieldValue(FieldsFactory.FIELD_SYMBOL_LOOKUP_TYPE, isOldType ? 0 : 1);
    }
}

export class NonFixedInstumentListUnderlierRequest extends NonFixedInstumentListRequest {
    constructor (fieldSet: PFixFieldSet, serverMaxFieldNumber: number, aliasLanguage?: string | null) {
        super(fieldSet, serverMaxFieldNumber, aliasLanguage);
        this.Code = Message.CODE_OPTIONS_CHAIN_REQUEST;
        this.FieldSet.SetField(FieldsFactory.FIELD_MSG_TYPE, this.Code);
    }
}
