import Decimal from 'decimal.js';
import { OptionPutCall } from '../../../../../Utils/Instruments/OptionPutCall';
import { OperationType } from '../../../../../Utils/Trading/OperationType';
import { Pen } from '../../../../Graphics';
import { type PaperPosition } from '../OptionPaperPosition/PaperPosition';
import { OptionTraderUtils } from '../OptionTraderUtils';
import { AnalyzerChartData } from './AnalyzerChartData';
import { type AnalyzerChartDataInputParameters } from './AnalyzerChartDataInputParameters';
import { AnalyzerChartType } from './AnalyzerChartType';
import { AnalyzerLinesType } from './AnalyzerLinesType';
import { AnalyzerProbabilityCalculationMode } from './AnalyzerProbabilityCalculationMode';
import { AnalyzerVolatilityLine } from './AnalyzerVolatilityLine';
import { ProbabilityScenario } from './ProbabilityScenario';
import { SimulationModel } from './SimulationModel';
import { AnalyzerInfoData } from './AnalyzerInfoData';
import { type AnalyzerInfoDataInputParameters } from './AnalyzerInfoDataInputParameters';
import { Greeks } from '../Greeks';
import { BaseInterval } from '../../../../../Utils/History/BaseInterval';
import { MathUtils } from '../../../../../Utils/MathUtils';
import { XYSeriesData } from '../../../../../Chart/Series/TerceraChartXYSeries';

export class OptionAnalyzer {
    public intrinsicColor: string = '';
    public intrinsicStyle: number = Pen.csSimpleChart;
    public intrinsicWidth: number = 2;
    public timeLineColor: string = '';
    public timeLineStyle: number = Pen.csSimpleChart;
    public timeLineWidth: number = 1;
    public basePriceLineColor: string = '';
    public basePriceLineStyle: number = Pen.csShapedChart;
    public basePriceLineWidth: number = 1;
    public zeroLineColor: string = '';
    public zeroLineStyle: number = Pen.csShapedChart;
    public zeroLineWidth: number = 1;
    public underlierPriceColor: string = '';
    public underlierPriceStyle: number = Pen.csShapedChart;
    public underlierPriceWidth: number = 1;

    public simulationModel: SimulationModel = SimulationModel.ByAbsolutePrices;
    public simulationExamples: number = 1000;
    public probabilityScenario: ProbabilityScenario = ProbabilityScenario.OneTouch;
    public historyPeriodYear: number = 3;

    public chartType: AnalyzerChartType = AnalyzerChartType.PL;
    public linesType: AnalyzerLinesType = AnalyzerLinesType.VolatilityChange;
    public volatilityChange: number = 0;

    public readonly volatilityLines: AnalyzerVolatilityLine[] = [
        new AnalyzerVolatilityLine(),
        new AnalyzerVolatilityLine(),
        new AnalyzerVolatilityLine(),
        new AnalyzerVolatilityLine(),
        new AnalyzerVolatilityLine()
    ];

    public probabilityCalculationDays: number = 10;
    public probabilityCalculationMode: AnalyzerProbabilityCalculationMode = AnalyzerProbabilityCalculationMode.None;

    constructor () {
        this.resetVolatityLinesToDefault();
    }

    public resetVolatityLinesToDefault (): void {
        for (let i = 0; i < this.volatilityLines.length; i++) {
            const line = this.volatilityLines[i];
            line.isActive = false;
            line.value = i * 10;
            line.width = 1;
            line.color = '';
        }
    }

    public getOptionAnalyzerChartData (analyzerChartDataInputParameters: AnalyzerChartDataInputParameters): AnalyzerChartData {
        const zeroLineSeries: XYSeriesData[] = [];
        const timeLineSeries: XYSeriesData[] = [];
        const intrinsicSeries: XYSeriesData[] = [];
        const volatilitySeries: XYSeriesData[][] = [[], [], [], [], []];
        const analyzerChartData = new AnalyzerChartData(analyzerChartDataInputParameters.underlierPrice, zeroLineSeries, timeLineSeries, intrinsicSeries, volatilitySeries);
        if (!isValidArray(analyzerChartDataInputParameters.paperPositionsInfo)) {
            return analyzerChartData;
        }

        if (!isValidNumber(analyzerChartDataInputParameters.underlierPrice)) {
            return analyzerChartData;
        }
        const seriesXRange = this.calculateSeriesXRange(analyzerChartDataInputParameters);
        if (this.chartType === AnalyzerChartType.PL) {
            this.fillAnalyzerChartDataByPL(analyzerChartData, analyzerChartDataInputParameters, seriesXRange);
        } else {
            this.fillAnalyzerChartDataByGreeks(analyzerChartData, analyzerChartDataInputParameters, seriesXRange);
        }
        return analyzerChartData;
    }

    public getOptionAnalyzerInfoData (analyzerInfoDataInputParameters: AnalyzerInfoDataInputParameters): AnalyzerInfoData {
        let intrinsicPnL: number = 0;
        let PnL: number = 0;
        const greeks: Greeks = new Greeks();
        greeks.iv = greeks.delta = greeks.gamma = greeks.vega = greeks.theta = greeks.rho = 0;
        const whatIf: number[] = [0, 0, 0, 0, 0];
        const price = analyzerInfoDataInputParameters.dataX;
        const optionCalculator = analyzerInfoDataInputParameters.optionCalculator;
        const interestRate = analyzerInfoDataInputParameters.interestRate;
        const paperPositionsInfo = analyzerInfoDataInputParameters.paperPositionsInfo;
        for (let i = 0; i < paperPositionsInfo.length; i++) {
            const paperPositionInfo = paperPositionsInfo[i];
            const paperPosition = paperPositionInfo.paperPosition;
            const amount = Math.abs(paperPosition.QuantityAmount);
            const sign = paperPosition.Operation === OperationType.Buy ? 1 : -1;
            const iv = paperPositionInfo.greeks.iv;
            const daysToExpire = OptionTraderUtils.getDaysToExpirationForInstrument(paperPosition.Instrument);
            const expiration = daysToExpire / 365;

            const theoreticalPrice = optionCalculator.theoreticalPrice(paperPosition.Instrument.PutCall, price, paperPosition.StrikePrice, expiration, interestRate, iv);
            const pnl = this.getPnL(paperPosition, price, theoreticalPrice);
            intrinsicPnL += pnl.a;
            if (!isNullOrUndefined(pnl.nl)) {
                PnL += pnl.nl;
            }

            if (paperPosition.Instrument.isOptionSymbol) {
                const putCall = paperPosition.Instrument.PutCall;
                const strikePrice = paperPosition.StrikePrice;
                const delta = optionCalculator.delta(putCall, price, strikePrice, expiration, interestRate, iv) * amount * sign;
                if (!isNaN(delta)) {
                    greeks.delta += delta;
                }
                const gamma = optionCalculator.gamma(price, strikePrice, expiration, interestRate, iv) * amount * sign;
                if (!isNaN(gamma)) {
                    greeks.gamma += gamma;
                }
                const vega = optionCalculator.vega(price, strikePrice, expiration, interestRate, iv) * amount * sign;
                if (!isNaN(vega)) {
                    greeks.vega += vega;
                }
                const theta = optionCalculator.theta(putCall, price, strikePrice, expiration, interestRate, iv) * amount * sign;
                if (!isNaN(theta)) {
                    greeks.theta += theta;
                }
                const rho = optionCalculator.rho(putCall, price, strikePrice, expiration, interestRate, iv) * amount * sign;
                if (!isNaN(rho)) {
                    greeks.rho += rho;
                }
            } else {
                const delta = amount * sign;
                if (!isNaN(delta)) {
                    greeks.delta += delta;
                }
            }

            for (let i = 0; i < whatIf.length; i++) {
                const putCall = paperPosition.Instrument.PutCall;
                const result = this.getDataForWhatIfLine(iv, daysToExpire, i);
                const volatilityLineExpiration = (daysToExpire - result.srok) / 365;
                let nl = 0;
                if (paperPosition.Instrument.isOptionSymbol) {
                    switch (this.chartType) {
                    case AnalyzerChartType.PL:
                        {
                            const theoreticalPrice = optionCalculator.theoreticalPrice(paperPosition.Instrument.PutCall, price, paperPosition.StrikePrice, volatilityLineExpiration, interestRate, result.iv);
                            const pnl = this.getPnL(paperPosition, price, theoreticalPrice);
                            nl = pnl.nl * sign;
                        }
                        break;
                    case AnalyzerChartType.Delta:
                        nl = optionCalculator.delta(putCall, price, paperPosition.StrikePrice, volatilityLineExpiration, interestRate, result.iv);
                        break;
                    case AnalyzerChartType.Gamma:
                        nl = optionCalculator.gamma(price, paperPosition.StrikePrice, volatilityLineExpiration, interestRate, result.iv);
                        break;
                    case AnalyzerChartType.Vega:
                        nl = optionCalculator.vega(price, paperPosition.StrikePrice, volatilityLineExpiration, interestRate, result.iv);
                        break;
                    case AnalyzerChartType.Theta:
                        nl = optionCalculator.theta(putCall, price, paperPosition.StrikePrice, volatilityLineExpiration, interestRate, result.iv);
                        break;
                    case AnalyzerChartType.Rho:
                        nl = optionCalculator.rho(putCall, price, paperPosition.StrikePrice, volatilityLineExpiration, interestRate, result.iv);
                        break;
                    }
                    nl *= sign;
                } else if (this.chartType === AnalyzerChartType.Delta) {
                    nl = amount * sign;
                }
                whatIf[i] += nl;
            }
        }

        return new AnalyzerInfoData(intrinsicPnL, PnL, greeks, whatIf);
    }

    public getOptionAnalyzerProbabilityData (history: BaseInterval[], lastPrice: number, leftBorder: number, rightBorder: number): { p1: number, p2: number } {
        const p1 = NaN;
        const p2 = NaN;

        if (!isValidArray(history) || history.length - this.probabilityCalculationDays <= 2) {
            return { p1, p2 };
        }

        switch (this.probabilityCalculationMode) {
        case AnalyzerProbabilityCalculationMode.Single:
            return this.calculateProbabilitySingleMode(history, lastPrice, leftBorder);
        case AnalyzerProbabilityCalculationMode.OR:
            return this.calculateProbabilityORMode(history, lastPrice, leftBorder, rightBorder);
        case AnalyzerProbabilityCalculationMode.AND:
            return this.calculateProbabilityANDMode(history, lastPrice, leftBorder, rightBorder);
        default:
            return { p1, p2 };
        }
    }

    private calculateSeriesXRange (analyzerChartDataInputParameters: AnalyzerChartDataInputParameters): { start: number, end: number, increment: number } {
        const result = {
            start: -1,
            end: -1,
            increment: 0
        };
        const underlierLastPrice = analyzerChartDataInputParameters.underlierPrice;
        const paperPositionsInfo = analyzerChartDataInputParameters.paperPositionsInfo;
        for (let i = 0; i < paperPositionsInfo.length; i++) {
            const paperPosition = paperPositionsInfo[i].paperPosition;
            const price = isValidNumber(paperPosition.StrikePrice) && paperPosition.StrikePrice !== 0 ? paperPosition.StrikePrice : underlierLastPrice;
            if (price < result.start || result.start === -1) {
                result.start = price;
            }
            if (price > result.end) {
                result.end = price;
            }
        }
        if (result.start > underlierLastPrice) {
            result.start = underlierLastPrice;
        } else if (result.end < underlierLastPrice) {
            result.end = underlierLastPrice;
        }
        if (result.start === result.end) {
            result.start = new Decimal(result.start).div(2).toNumber();
            result.end = new Decimal(result.end).mul(1.5).toNumber();
        } else {
            const mid = new Decimal(result.start).add(result.end).div(2).toNumber();
            result.start = new Decimal(mid).div(2).toNumber();
            result.end = new Decimal(mid).mul(1.5).toNumber();
        }
        result.increment = new Decimal(result.end).minus(result.start).div(500).toNumber();
        return result;
    }

    private fillAnalyzerChartDataByPL (analyzerChartData: AnalyzerChartData, analyzerChartDataInputParameters: AnalyzerChartDataInputParameters, seriesXRange: { start: number, end: number, increment: number }): void {
        let start = seriesXRange.start;
        const end = seriesXRange.end;
        const increment = seriesXRange.increment;
        const paperPositionsInfo = analyzerChartDataInputParameters.paperPositionsInfo;
        const interestRate = analyzerChartDataInputParameters.interestRate;
        while (start <= end) {
            let sum = 0; // Red line
            const nlSum: number[] = [0, 0, 0, 0, 0, 0]; // Blue line and all green lines
            for (let i = 0; i < paperPositionsInfo.length; i++) {
                const paperPositionInfo = paperPositionsInfo[i];
                const paperPosition = paperPositionInfo.paperPosition;
                const daysToExpire = OptionTraderUtils.getDaysToExpirationForInstrument(paperPosition.Instrument);
                let a = 0; // Red line
                const nl: number[] = [0, 0, 0, 0, 0]; // Blue line and all green lines
                for (let linesCount = 0; linesCount < 6; linesCount++) {
                    let srok = 0;
                    let iv = paperPositionInfo.greeks.iv;

                    // Green lines
                    if (linesCount > 0) {
                        const result = this.getDataForWhatIfLine(iv, daysToExpire, linesCount - 1);
                        srok = result.srok;
                        iv = result.iv;
                    }
                    const expiration = (daysToExpire - srok) / 365;
                    const theoreticalPrice = analyzerChartDataInputParameters.optionCalculator.theoreticalPrice(paperPosition.Instrument.PutCall,
                        start,
                        paperPosition.StrikePrice,
                        expiration,
                        interestRate,
                        iv);
                    const result = this.getPnL(paperPosition, start, theoreticalPrice);
                    a = result.a;
                    nl[linesCount] = result.nl;

                    if (linesCount === 0 && !isNaN(a)) {
                        sum = sum + a;
                    }

                    if (!isNaN(nl[linesCount])) {
                        nlSum[linesCount] = nlSum[linesCount] + nl[linesCount];
                    }
                }
            }
            analyzerChartData.zeroLineSeries.push(new XYSeriesData(start, 0));
            analyzerChartData.intrinsicSeries.push(new XYSeriesData(start, sum));
            analyzerChartData.timeLineSeries.push(new XYSeriesData(start, nlSum[0])); // blue
            analyzerChartData.volatilitySeries[0].push(new XYSeriesData(start, nlSum[1])); // green
            analyzerChartData.volatilitySeries[1].push(new XYSeriesData(start, nlSum[2])); // green
            analyzerChartData.volatilitySeries[2].push(new XYSeriesData(start, nlSum[3])); // green
            analyzerChartData.volatilitySeries[3].push(new XYSeriesData(start, nlSum[4])); // green
            analyzerChartData.volatilitySeries[4].push(new XYSeriesData(start, nlSum[5])); // green
            start = start + increment;
        }
    }

    private fillAnalyzerChartDataByGreeks (analyzerChartData: AnalyzerChartData, analyzerChartDataInputParameters: AnalyzerChartDataInputParameters, seriesXRange: { start: number, end: number, increment: number }): void {
        let start = seriesXRange.start;
        const end = seriesXRange.end;
        const increment = seriesXRange.increment;
        const paperPositionsInfo = analyzerChartDataInputParameters.paperPositionsInfo;
        const interestRate = analyzerChartDataInputParameters.interestRate;
        while (start <= end) {
            const nlSum: number[] = [0, 0, 0, 0, 0, 0]; // Blue line and all green lines
            for (let i = 0; i < paperPositionsInfo.length; i++) {
                const paperPositionInfo = paperPositionsInfo[i];
                const paperPosition = paperPositionInfo.paperPosition;
                const daysToExpire = OptionTraderUtils.getDaysToExpirationForInstrument(paperPosition.Instrument);
                const amount = Math.abs(paperPosition.QuantityAmount);
                const nl: number[] = [0, 0, 0, 0, 0]; // Blue line and all green lines
                for (let linesCount = 0; linesCount < 6; linesCount++) {
                    let srok = 0;
                    let iv = paperPositionInfo.greeks.iv;

                    // Green lines
                    if (linesCount > 0) {
                        const result = this.getDataForWhatIfLine(iv, daysToExpire, linesCount - 1);
                        srok = result.srok;
                        iv = result.iv;
                    }
                    const expiration = (daysToExpire - srok) / 365;

                    if (paperPosition.Instrument.isOptionSymbol) {
                        const putCall = paperPosition.Instrument.PutCall;
                        const underlierPrice = start;
                        const strikePrice = paperPosition.StrikePrice;
                        switch (this.chartType) {
                        case AnalyzerChartType.Delta:
                            nl[linesCount] = analyzerChartDataInputParameters.optionCalculator.delta(putCall, underlierPrice, strikePrice, expiration, interestRate, iv) * amount;
                            break;
                        case AnalyzerChartType.Gamma:
                            nl[linesCount] = analyzerChartDataInputParameters.optionCalculator.gamma(underlierPrice, strikePrice, expiration, interestRate, iv) * amount;
                            break;
                        case AnalyzerChartType.Vega:
                            nl[linesCount] = analyzerChartDataInputParameters.optionCalculator.vega(underlierPrice, strikePrice, expiration, interestRate, iv) * amount;
                            break;
                        case AnalyzerChartType.Theta:
                            nl[linesCount] = analyzerChartDataInputParameters.optionCalculator.theta(putCall, underlierPrice, strikePrice, expiration, interestRate, iv) * amount;
                            break;
                        case AnalyzerChartType.Rho:
                            nl[linesCount] = analyzerChartDataInputParameters.optionCalculator.rho(putCall, underlierPrice, strikePrice, expiration, interestRate, iv) * amount;
                            break;
                        }

                        if (paperPosition.Operation === OperationType.Sell) {
                            nl[linesCount] *= -1;
                        }
                    } else if (paperPosition.Instrument.isFuturesSymbol && this.chartType === AnalyzerChartType.Delta) {
                        nl[linesCount] = 1 * amount * (paperPosition.Operation === OperationType.Sell ? -1 : 1);
                    }

                    if (!isNaN(nl[linesCount])) {
                        nlSum[linesCount] = nlSum[linesCount] + nl[linesCount];
                    }
                }
            }
            analyzerChartData.zeroLineSeries.push(new XYSeriesData(start, 0));
            analyzerChartData.timeLineSeries.push(new XYSeriesData(start, nlSum[0]));
            analyzerChartData.volatilitySeries[0].push(new XYSeriesData(start, nlSum[1]));
            analyzerChartData.volatilitySeries[1].push(new XYSeriesData(start, nlSum[2]));
            analyzerChartData.volatilitySeries[2].push(new XYSeriesData(start, nlSum[3]));
            analyzerChartData.volatilitySeries[3].push(new XYSeriesData(start, nlSum[4]));
            analyzerChartData.volatilitySeries[4].push(new XYSeriesData(start, nlSum[5]));
            start = start + increment;
        }
    }

    private getDataForWhatIfLine (iv: number, daysToExpire: number, lineIndex: number): { srok: number, iv: number } {
        let srok = this.linesType === AnalyzerLinesType.VolatilityChange ? this.volatilityChange : this.volatilityLines[lineIndex].value;
        const plusIV = this.linesType === AnalyzerLinesType.TimeChange ? this.volatilityChange : this.volatilityLines[lineIndex].value;

        if (this.chartType === AnalyzerChartType.PL) {
            if (srok > daysToExpire) {
                srok = daysToExpire;
            }
            iv = iv + plusIV / 100 > 0 ? iv + plusIV / 100 : 0;
        } else {
            if (srok >= daysToExpire) {
                srok = 0;
            }
            if (iv + plusIV / 100 > 0) {
                iv += plusIV / 100;
            }
        }
        return { srok, iv };
    }

    private getPnL (position: PaperPosition, start: number, theoreticalPrice: number): { a: number, nl: number } {
        const price = position.PriceForPnlCalculation;
        const amount = Math.abs(position.QuantityAmount);
        const isOption = position.Instrument.isOptionSymbol;
        const result = {
            a: 0,
            nl: 0
        };
        if (isOption) {
            const isCall = position.Instrument.PutCall === OptionPutCall.OPTION_CALL_VANILLA;
            const isBuy = position.Operation === OperationType.Buy;
            if (isCall) {
                if (isBuy) {
                    if (start - position.StrikePrice > 0) {
                        result.a = (start - position.StrikePrice) * amount - amount * price;
                    } else {
                        result.a = 0 - amount * price;
                    }
                    result.nl = theoreticalPrice * amount - amount * price;
                } else {
                    if (start - position.StrikePrice > 0) {
                        result.a = (-1) * (start - position.StrikePrice) * amount + amount * price;
                    }
                    if (start - position.StrikePrice <= 0) {
                        result.a = 0 + amount * price;
                    }
                    result.nl = (-1) * theoreticalPrice * amount + amount * price;
                }
            } else {
                if (isBuy) {
                    if (start - position.StrikePrice < 0) {
                        result.a = (-1) * (start - position.StrikePrice) * amount - amount * price;
                    }
                    if (start - position.StrikePrice >= 0) {
                        result.a = 0 - amount * price;
                    }

                    result.nl = theoreticalPrice * amount - amount * price;
                } else {
                    if (start - position.StrikePrice < 0) {
                        result.a = (start - position.StrikePrice) * amount + amount * price;
                    }
                    if (start - position.StrikePrice >= 0) {
                        result.a = 0 + amount * price;
                    }

                    result.nl = (-1) * theoreticalPrice * amount + amount * price;
                }
            }
        } else {
            const isBuy = position.Operation === OperationType.Buy;
            if (isBuy) {
                result.a = (start - price) * amount;
            } else {
                result.a = (-1) * (start - price) * amount;
            }
            result.nl = result.a;
        }

        const tickCost = position.Instrument.GetTickCost();
        result.a *= tickCost;
        result.nl *= tickCost;
        return result;
    }

    private calculateProbabilitySingleMode (history: BaseInterval[], lastPrice: number, leftBorder: number): { p1: number, p2: number } {
        const up: boolean = leftBorder > lastPrice;

        let R = 0;
        switch (this.simulationModel) {
        case SimulationModel.ByAbsolutePrices:
            R = leftBorder - lastPrice;
            break;
        case SimulationModel.ByRelativePrices:
            R = leftBorder / lastPrice;
            break;
        case SimulationModel.ByLogarithmicPrices:
            R = Math.log(leftBorder / lastPrice);
            break;
        }

        let positiveResults = 0;
        for (let index = 0; index < this.simulationExamples; index++) {
            const barIndex = MathUtils.random(0, history.length - this.probabilityCalculationDays);
            const interval = history[barIndex];
            const price = interval.Data[BaseInterval.CLOSE_INDEX];
            let price1 = 0;
            switch (this.simulationModel) {
            case SimulationModel.ByAbsolutePrices:
                price1 = price + R;
                break;
            case SimulationModel.ByRelativePrices:
                price1 = price * R;
                break;
            case SimulationModel.ByLogarithmicPrices:
                price1 = price * Math.pow(Math.E, R);
                break;
            }

            switch (this.probabilityScenario) {
            case ProbabilityScenario.OneTouch:
                for (let i = barIndex; i < barIndex + this.probabilityCalculationDays; i++) {
                    if ((up && history[i].Data[BaseInterval.CLOSE_INDEX] >= price1) ||
                            (!up && history[i].Data[BaseInterval.CLOSE_INDEX] <= price1)) {
                        positiveResults++;
                        break;
                    }
                }
                break;
            case ProbabilityScenario.OutOfRange:
                if ((up && history[barIndex + this.probabilityCalculationDays].Data[BaseInterval.CLOSE_INDEX] >= price1) ||
                        (!up && history[barIndex + this.probabilityCalculationDays].Data[BaseInterval.CLOSE_INDEX] <= price1)) { positiveResults++; }
                break;
            }
        }
        const p1 = positiveResults / this.simulationExamples;
        return {
            p1,
            p2: NaN
        };
    }

    private calculateProbabilityORMode (history: BaseInterval[], lastPrice: number, leftBorder: number, rightBorder: number): { p1: number, p2: number } {
        let R1 = 0;
        let R2 = 0;
        switch (this.simulationModel) {
        case SimulationModel.ByAbsolutePrices:
            R1 = leftBorder - lastPrice;
            R2 = rightBorder - lastPrice;
            break;
        case SimulationModel.ByRelativePrices:
            R1 = leftBorder / lastPrice;
            R2 = rightBorder / lastPrice;
            break;
        case SimulationModel.ByLogarithmicPrices:
            R1 = Math.log(leftBorder / lastPrice);

            R2 = Math.log(rightBorder / lastPrice);
            break;
        }

        let positiveResults = 0;
        for (let index = 0; index < this.simulationExamples; index++) {
            const barIndex = MathUtils.random(0, history.length - this.probabilityCalculationDays);
            const interval = history[barIndex];
            const price = interval.Data[BaseInterval.CLOSE_INDEX];
            let price1 = 0;
            let price2 = 0;
            switch (this.simulationModel) {
            case SimulationModel.ByAbsolutePrices:
                price1 = price + R1;
                price2 = price + R2;
                break;
            case SimulationModel.ByRelativePrices:
                price1 = price * R1;
                price2 = price * R2;
                break;
            case SimulationModel.ByLogarithmicPrices:
                price1 = price * Math.pow(Math.E, R1);
                price2 = price * Math.pow(Math.E, R2);
                break;
            }

            switch (this.probabilityScenario) {
            case ProbabilityScenario.OneTouch:
                for (let i = barIndex; i < barIndex + this.probabilityCalculationDays; i++) {
                    if ((history[i].Data[BaseInterval.CLOSE_INDEX] <= price1) ||
                    (history[i].Data[BaseInterval.CLOSE_INDEX] >= price2)) {
                        positiveResults++;
                        break;
                    }
                }
                break;
            case ProbabilityScenario.OutOfRange:
                if ((history[barIndex + this.probabilityCalculationDays].Data[BaseInterval.CLOSE_INDEX] <= price1) ||
                (history[barIndex + this.probabilityCalculationDays].Data[BaseInterval.CLOSE_INDEX] >= price2)) {
                    positiveResults++;
                }
                break;
            }
        }
        const p1 = positiveResults / this.simulationExamples;
        return {
            p1,
            p2: NaN
        };
    }

    private calculateProbabilityANDMode (history: BaseInterval[], lastPrice: number, leftBorder: number, rightBorder: number): { p1: number, p2: number } {
        const up1 = leftBorder > lastPrice;
        const up2 = rightBorder > leftBorder;

        let R1 = 0;
        let R2 = 0;
        switch (this.simulationModel) {
        case SimulationModel.ByAbsolutePrices:
            R1 = leftBorder - lastPrice;
            R2 = rightBorder - leftBorder;
            break;
        case SimulationModel.ByRelativePrices:
            R1 = leftBorder / lastPrice;
            R2 = rightBorder / leftBorder;
            break;
        case SimulationModel.ByLogarithmicPrices:
            R1 = Math.log(leftBorder / lastPrice);
            R2 = Math.log(rightBorder / leftBorder);
            break;
        }

        let positiveResults1 = 0;
        let positiveResults2 = 0;
        for (let index = 0; index < this.simulationExamples; index++) {
            let barIndex = MathUtils.random(0, history.length - this.probabilityCalculationDays);
            const interval = history[barIndex];
            const price = interval.Data[BaseInterval.CLOSE_INDEX];
            let price1 = 0;
            let price2 = 0;
            switch (this.simulationModel) {
            case SimulationModel.ByAbsolutePrices:
                price1 = price + R1;
                price2 = price1 + R2;
                break;
            case SimulationModel.ByRelativePrices:
                price1 = price * R1;
                price2 = price1 * R2;
                break;
            case SimulationModel.ByLogarithmicPrices:
                price1 = price * Math.pow(Math.E, R1);
                price2 = price1 * Math.pow(Math.E, R2);
                break;
            }

            let event1Raise = false;
            const lastBar = barIndex + this.probabilityCalculationDays;
            for (let i = barIndex; i < lastBar; i++) {
                if ((up1 && history[i].Data[BaseInterval.CLOSE_INDEX] >= price1) ||
            (!up1 && history[i].Data[BaseInterval.CLOSE_INDEX] <= price1)) {
                    positiveResults1++;
                    barIndex = i;
                    event1Raise = true;
                    break;
                }
            }

            if (event1Raise) {
                switch (this.probabilityScenario) {
                case ProbabilityScenario.OneTouch:
                    for (let i = barIndex; i < lastBar; i++) {
                        if ((up2 && history[i].Data[BaseInterval.CLOSE_INDEX] >= price2) ||
                        (!up2 && history[i].Data[BaseInterval.CLOSE_INDEX] <= price2)) {
                            positiveResults2++;
                            break;
                        }
                    }
                    break;
                case ProbabilityScenario.OutOfRange:
                    if ((up2 && history[lastBar].Data[BaseInterval.CLOSE_INDEX] >= price2) ||
                    (!up2 && history[lastBar].Data[BaseInterval.CLOSE_INDEX] <= price2)) {
                        positiveResults2++;
                    }
                    break;
                }
            }
        }
        const p1 = positiveResults1 / this.simulationExamples;
        const p2 = positiveResults2 / this.simulationExamples;
        return {
            p1,
            p2
        };
    }
}
