// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { EventEmitter } from 'events';
import { type IGroupController } from '../../VerticalGroupedPanels/Interfaces/IGroupController';
import { Resources } from '../../properties/Resources';

export class BaseGroupItem<GroupItemsType, GroupsType> {
    protected readonly _eventEmitter = new EventEmitter();
    protected readonly type: GroupsType;
    protected readonly controller: IGroupController<GroupItemsType>;
    protected readonly isOpened: boolean;

    constructor (type: GroupsType, controller: IGroupController<GroupItemsType>, isOpened: boolean) {
        this.type = type;
        this.controller = controller;
        this.isOpened = isOpened;
    }

    public subscribeOnUpDateEmit (callback: () => void): void {
        this._eventEmitter.on('update', callback);
    }

    public unsubscribeOnUpDateEmit (callback: () => void): void {
        this._eventEmitter.off('update', callback);
    }

    public onUpDateEmit (): void {
        this._eventEmitter.emit('update');
    }

    public subscribeChangeOpenedStateEmit (callback: (itemId: GroupsType, isOpened: boolean) => void): void {
        this._eventEmitter.on('changeOpenedState', callback);
    }

    public unsubscribeChangeOpenedStateEmit (callback: (itemId: GroupsType, isOpened: boolean) => void): void {
        this._eventEmitter.off('changeOpenedState', callback);
    }

    public onChangeOpenedStateEmit (itemId: GroupsType, isOpened: boolean): void {
        this._eventEmitter.emit('changeOpenedState', itemId, isOpened);
    }

    public getController (): IGroupController<GroupItemsType> {
        return this.controller;
    }

    public getType (): GroupsType {
        return this.type;
    }

    public getOpenedState (): boolean {
        return this.isOpened;
    }

    public getGroupName (): string {
        const groupLabelKey = this.getGroupNameKey();
        if (isNullOrUndefined(groupLabelKey)) {
            return 'add new group name';
        }

        return Resources.getResource(groupLabelKey);
    }

    protected getGroupNameKey (): string | null {
        return null;
    }
}
