// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources, LOCALE_EN } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { AccountOperationInfo } from '../../Commons/cache/AccountOperationInfo';
import { MathUtils } from '../../Utils/MathUtils';
import { WithdrawalScreenTemplate } from '../../templates.js';
import { Control } from '../elements/Control';
import { TerceraWindowBase } from './TerceraWindowBase';
import { RevenueCommissionType } from '../../Utils/Enums/Constants';
import { AccountType } from '../../Utils/Account/AccountType';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { DataCache } from '../../Commons/DataCache';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';
import { WithdrawalCalculator } from '../../Commons/cache/WithdrawalCalculator';
import { TerceraButtonStyle } from '../../Utils/Enums/ButtonEnums';

let instance: WithdrawalScreen = null;

export class WithdrawalScreen extends TerceraWindowBase {
    public CommissionPlan: any = null;
    public Flock: boolean;

    public override getType (): ScreensNames { return ScreensNames.WithdrawalScreen; }

    public override oninit (): void {
        super.oninit();

        this.observe('accountItem', this.OnAccountItemChanged);
        this.observe('selectedAsset', this.OnAssetChangedChanged);
        this.observe('amountValue', this.OnAmountValueChanged);
        this.observe('remainsValue', this.OnRemainsValueChanged);
        this.observe('avaliableValue', this.OnAvaliableValueChanged);
        this.on('cancelClick', this.close.bind(this));
        this.on('okClick', this.okClick.bind(this));

        this.localize();
    }

    public override localize (): void {
        void this.set({
            header: Resources.getResource('screen.withdrawal.ReservedWithdrawal'),

            fromAccount: Resources.getResource('screen.withdrawal.account'),
            asset: Resources.getResource('screen.withdrawal.Asset'),
            avaliable: Resources.getResource('screen.withdrawal.Availible funds'),
            amount: Resources.getResource('screen.withdrawal.Amount'),
            remains: Resources.getResource('screen.withdrawal.Remains'),
            okText: Resources.getResource('screen.withdrawal.Withdrawal'),
            cancelText: Resources.getResource('screen.withdrawal.Cancel'),
            feeTitleLabel: Resources.getResource('screen.withdrawal.Fee'),
            requiredAmountTitleLabel: Resources.getResource('screen.withdrawal.RequiredAmount')
        });
    }

    public override oncomplete (): void {
        super.oncomplete();

        if (DataCache) {
            const accs = DataCache.getOwnedAccounts();
            const primaryAcc = DataCache.getPrimaryAccount();
            void this.set({ userLogin: primaryAcc.FullAccString });

            const acc = this.get('accountItem');
            if (acc != null && accs[acc.BstrAccount] == null) {
                void this.set('accountItem', primaryAcc); // #119826
            }
        }

        Control.Ticker.Subscribe(this.TickAsync, this);

        this.center();
        this.updateValues(true);
    }

    public okClick (): void {
        const data: any = {};
        const sum = this.get('amountValue');
        let SelectedAsset = this.get('selectedAsset');
        if (SelectedAsset) {
            SelectedAsset = SelectedAsset.value;
        }
        const SelectedAccount = this.get('accountItem');

        data.operationType = AccountOperationInfo.TYPE_WITHDRAW;
        data.accountId = SelectedAccount.AcctNumber;
        data.amount = 0 - sum;
        data.comment = Resources.getResourceLang('screen.withdrawal.Withdrawal', LOCALE_EN) + ' ' +
            (SelectedAsset != null ? SelectedAsset.formatPrice((sum)) : SelectedAccount.formatPrice((sum))) +
            ' ' + Resources.getResourceLang('screen.withdrawal.from', LOCALE_EN) + ' ' + SelectedAccount.toString();

        if (SelectedAsset != null && SelectedAccount.AccountType == AccountType.MultiAsset) {
            data.assetId = SelectedAsset.Id;
        }

        DataCache.AccountOperationRequest(data).then(function () {
            this.set('amountValue', 0);
        }.bind(this));
    }

    public OnAmountValueChanged (newValue, oldValue): void {
        if (Math.abs(newValue - oldValue) < MathUtils.MATH_ROUND_EPSILON) {
            return;
        }

        this.updateValues(true);
    }

    public OnRemainsValueChanged (newValue, oldValue): void {
        if (Math.abs(newValue - oldValue) < MathUtils.MATH_ROUND_EPSILON) {
            return;
        }

        // let avaliableValue = this.get("avaliableValue");
        // let newAmountValue = avaliableValue - newValue;
        // this.set({ amountValue: newAmountValue });
        this.updateValues(false);
    }

    public updateValues (isAmount): void {
        if (this.Flock) {
            return;
        }

        this.Flock = true;

        const account = this.get('accountItem');
        const avaliableValue = this.get('avaliableValue');
        const amountValue = this.get('amountValue');
        const remainsValue = this.get('remainsValue');
        let newAmountValue = amountValue;
        let newRemainsValue = remainsValue;

        let feeText = '';
        let requiredAmountLabel = '';
        let fee = WithdrawalCalculator.CalculateFeeByWithdraw(account, this.CommissionPlan, amountValue);

        if (isAmount) {
            newRemainsValue = avaliableValue - newAmountValue - fee;
        } else {
            newAmountValue = WithdrawalCalculator.GetAmountByRemain(account, this.CommissionPlan, avaliableValue, newRemainsValue);
            fee = WithdrawalCalculator.CalculateFeeByWithdraw(account, this.CommissionPlan, newAmountValue);
        }

        if (isAmount) {
            if (newRemainsValue < 0) {
                newRemainsValue = 0;
            }

            void this.set('remainsValue', newRemainsValue);
        } else {
            void this.set('amountValue', newAmountValue);
        }

        void this.set('enoughAmount', newAmountValue + fee <= avaliableValue);

        const selAsset = this.get('selectedAsset');
        if (selAsset?.value) {
            const asset = selAsset.value;
            feeText = asset.formatPrice(fee);
            requiredAmountLabel = asset.formatPrice(newAmountValue + fee);
        }

        void this.set({
            feeText,
            requiredAmountText: requiredAmountLabel
        }).then(function () {
            this.Flock = false;
        }.bind(this));
    }

    public OnAccountItemChanged (newValue, oldValue, key): void {
        if (!newValue) {
            return;
        }

        let items = [];
        const assets = Object.keys(newValue.assetBalances);

        const hasAssets = Object.keys(assets).length > 1;

        // if (hasAssets)
        items = assets.map(function (x) {
            return newValue.assetBalances[x].Asset;
        });

        const avaliableValue = newValue.WithdrawalAvailable;

        let hasFee = false;
        if (newValue.CommissionPlan !== null) {
            hasFee = newValue.CommissionPlan.WithdrawalCommisionType != RevenueCommissionType.None;
        }

        this.CommissionPlan = newValue.CommissionPlan;

        void this.set(
            {
                hasAssets,
                avaliableValueText: newValue.formatPrice(avaliableValue),
                amountValue: 0,
                maxValue: avaliableValue,
                remainsValue: avaliableValue,
                avaliableValue,
                hasFee
            });

        if (hasAssets) {
            void this.set({
                assetsItems: this.toComboBoxItems(items)
            });
        } else if (assets.length > 0) {
            void this.set('selectedAsset', { text: assets[0], value: items[0] });
        }
    }

    public OnAssetChangedChanged (newValue, oldValue, key): void {
        if (!newValue) {
            return;
        }

        const accountItem = this.get('accountItem');

        if (!accountItem) {
            return;
        }

        const assetBalance = accountItem.assetBalances[newValue.text];
        const asset = assetBalance.Asset;
        const step = asset ? asset.MinChange : this.get('step');
        const decimalPrecision = asset ? asset.Point : this.get('decimalPrecision');
        const avaliableValue = accountItem.AccountType === AccountType.MultiAsset ? assetBalance.WithdrawalAvaliable(accountItem) : accountItem.WithdrawalAvailable;

        void this.set(
            {
                avaliableValueText: asset.formatPrice(avaliableValue),
                amountValue: 0,
                step,
                decimalPrecision,
                maxValue: avaliableValue,
                remainsValue: avaliableValue,
                avaliableValue
            });
    }

    public OnAvaliableValueChanged (newVal, oldVal): void {
        if (!MathUtils.IsNullOrUndefined(newVal) && newVal !== oldVal) {
            this.updateValues(true);
        }
    }

    public TickAsync (): void {
        this.getWithdrawalAvailable();
    }

    public getWithdrawalAvailable (): void {
        const account = this.get('accountItem');

        if (!account) {
            return;
        }

        let avaliableValue = account.WithdrawalAvailable;
        let asset = null;
        if (account.AccountType === AccountType.MultiAsset) {
            asset = this.get('selectedAsset');
            if (asset) {
                asset = account.assetBalances[asset.text];
                avaliableValue = asset.WithdrawalAvaliable(account);
            }
        }

        const currentAmount = this.get('amountValue');
        const remainsAmount = avaliableValue - currentAmount;

        void this.set(
            {
                avaliableValueText: asset ? asset.formatPrice(avaliableValue) : account.formatPrice(avaliableValue),
                maxValue: avaliableValue,
                avaliableValue,
                // remainsValue: remainsAmount,
                canWithdrawal: DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_RESERVER_WITHDRAWAL)
            });
    }

    public toComboBoxItems (assetIds): any {
        const arr = [];
        for (let i = 0; i < assetIds.length; i++) {
            const asset = assetIds[i];
            arr.push({ text: asset.Name, value: asset });
        }
        const funcSort = function (a, b) {
            if (a.text.toLowerCase() > b.text.toLowerCase()) {
                return 1;
            } else {
                return -1;
            }
        };
        arr.sort(funcSort);

        return arr;
    }

    public override dispose (): void {
        Control.Ticker.UnSubscribe(this.TickAsync, this);
        super.dispose();
    }

    public static show (): void {
        if (!instance) {
            instance = new WithdrawalScreen();
            instance.on('teardown', function () {
                instance = null;
            });
            MainWindowManager.MainWindow.addControl(instance);
        }

        instance.setFocus();
    }
}

// localization-key
// screen.withdrawal.avaliableNumeric.ToolTip=Set remaining capital
// screen.withdrawal.error.not_allowed=Account Operation with type Withdrawal is not allowed for your user, please contact your broker
// screen.withdrawal.error.not_enough_balance=Not enough balance
// screen.withdrawal.error.not_enough_blocked=Invalid amount(cannot unblock more than blocked)
// screen.withdrawal.error.not_enough_margin=Not enough margin
// screen.withdrawal.error.UnableToBlock=Error! Unable to block.
// screen.withdrawal.error.WrongSum=Error: wrong sum.
// screen.withdrawal.errorLabel=Not enough money for withdrawal
// screen.withdrawal.Fee=Withdrawal fee:
// screen.withdrawal.from=from
// screen.withdrawal.RequiredAmount=Required amount:
// screen.withdrawal.ReservedWithdrawal=Withdrawal
// screen.withdrawal.usersLookup.ToolTip=Select account
// screen.withdrawal.Withdraw=Withdraw
// screen.withdrawal.withdrawalNumeric.ToolTip=Set withdrawing capital

TerceraWindowBase.extendWith(WithdrawalScreen, {
    data: function () {
        return {
            movable: true,
            resizable: false,
            showFooter: false,
            focused: true,

            width: 324,
            zIndex: 290, // Ниже TerceraLookupDropDownForm === 300
            accountItem: null,
            userLogin: '', // текстовый представление юзера для отображения когда лукап скрыт (1 аккаунт)
            selectedAsset: null,
            hasAssets: false,
            canWithdrawal: true,
            enoughAmount: true, // для блокировки кнопки если выбрано слишком много
            hasFee: true,

            avaliableValue: 123456789,
            amountValue: 0,
            remainsValue: 0,
            minValue: 0,
            maxValue: 0,
            step: 0.01,
            decimalPrecision: 2,

            btnOKStyle: TerceraButtonStyle.Blue,
            btnCancelStyle: TerceraButtonStyle.Standard,
            style_addition_header: 'js-Withdrawal-AdditionalHeader',

            feeTitleLabel: 'Withdrawal fee:',
            requiredAmountTitleLabel: 'Required amount:',

            feeText: 0,
            requiredAmountText: 0,
            singleAccount: true
        };
    },
    partials: { bodyPartial: WithdrawalScreenTemplate }
});
