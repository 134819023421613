// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from '../../Commons/Connection';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { FullLogsItem } from '../cache/FullLogsItem';
import { TerceraQuickTreeEvents } from '../elements/QuickTree/TerceraQuickTree';
import { type TerceraButton } from '../elements/TerceraButton';
import { type TerceraComboBox } from '../elements/TerceraComboBox';
import { type TerceraTextBox } from '../elements/TerceraTextBox';
import { QuickTreeViewScreen } from '../screen/QuickTreeViewScreen';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { FullLogPanelTemplate } from '../../templates.js';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';

export class FullLogsPanel extends ApplicationPanelWithTable<FullLogsItem> {
    public headerLocaleKey: string = 'ribbon.tools.full_logs';
    public loggingStarted: boolean = false;
    public codesTB: TerceraTextBox | null = null;
    public codesCB: TerceraComboBox | null = null;
    public startLogginBtn: TerceraButton | null = null;
    public addBtn: TerceraButton | null = null;
    public Filter: any = null;
    public forceCloseOnLogout: boolean = false;

    public override getType (): PanelNames { return PanelNames.FullLogsPanel; }

    public override jbInit (): void {
        super.jbInit();

        this.startLogginBtn = this.Controls.startLoggin;

        this.codesCB = this.Controls.codesCBFullLog;

        this.addBtn = this.Controls.add;

        this.codesTB = this.Controls.codesTBFullLog;
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.center();

        this.FillCB();

        this.loggingStarted = false;

        this.Filter = {};

        this.localize();

        this.getQuickTable().AddToEnd = false;
        this.quickTableRactive.on(TerceraQuickTreeEvents.DoubleClick, this.ShowMoreInformation.bind(this));
    }

    public setEnable (enable: boolean): void {
    // this.startLogginBtn.set({ enabled: enable });
        void this.codesCB.set({ enabled: enable });
        void this.addBtn.set({ enabled: enable });
        void this.codesTB.set({ enabled: enable });
    }

    public OnNewMessageHandler (info): void {
        if (this.Filter.FiltersCount !== 0) {
            if (!this.Filter[info.MessageCode]) {
                return;
            }
        }
        info.dateTime = new Date();
        this.getQuickTable().AddItem(new FullLogsItem(info));
    }

    public StartStopLogging (): void {
        if (!this.loggingStarted) {
            const text = this.codesTB.get('text');
            const fArr = text.split(',');
            for (let i = 0; i < fArr.length; i++) {
                if (fArr[i].trim() !== '') {
                    this.Filter[fArr[i].trim()] = true;
                }
            }

            this.Filter.FiltersCount = Object.keys(this.Filter).length;

            Connection.SubscribeOnFullLoging(this.OnNewMessageHandler.bind(this));
            void this.startLogginBtn.set({ text: 'Stop logging' });
            this.setEnable(false);
        } else {
            this.Filter = {};
            Connection.UnSubscribeOnFullLoging();
            void this.startLogginBtn.set({ text: 'Start logging' });
            this.setEnable(true);
        }
        this.loggingStarted = !this.loggingStarted;
    }

    public ShowMoreInformation (): void {
        const qt = this.getQuickTable();
        const selectedRows = qt.selectedRowIds;
        if (selectedRows.length === 0) {
            return;
        }

        const selectedRow = qt.rows[selectedRows[0]];
        if (isNullOrUndefined(selectedRow)) {
            return;
        }

        QuickTreeViewScreen.Show(selectedRow.item.info.DataForTreeView);
    }

    public AddMsgCodeForLogging (): void {
        const CBItem = this.codesCB.get('selectedItem');

        const value = CBItem.value;

        let text = this.codesTB.get('text');

        text += value + ', ';

        void this.codesTB.set('text', text);
    }

    public ClearTable (): void {
        this.getQuickTable().ClearRows();
    }

    public FillCB (): void {
        const msgDict = Connection.GetMessagesList();

        const items = [];

        const objectKeys = Object.keys(msgDict);

        for (let i = 0; i < objectKeys.length; i++) {
            items.push({ text: msgDict[objectKeys[i]] + '(' + objectKeys[i] + ')', value: objectKeys[i] });
        }

        void this.codesCB.set({ items });
    }

    public override dispose (): void {
        Connection.UnSubscribeOnFullLoging();

        super.dispose();

        MainWindowManager.FullLogPanel = null;
    }
}

function ShowFullLogPanel (): void {
    if (!isNullOrUndefined(MainWindowManager.FullLogPanel)) {
        return;
    }

    const newPanel = new FullLogsPanel();
    MainWindowManager.MainWindow.addControl(newPanel);
    MainWindowManager.FullLogPanel = newPanel;
}

export function logs (): void {
    if (isNullOrUndefined(MainWindowManager.FullLogPanel)) {
        ShowFullLogPanel();
    }
}

ApplicationPanelWithTable.extendWith(FullLogsPanel,
    {
        Name: 'FullLogsPanel',
        data: function () {
            return {
                zIndex: 300,
                showHeader: true,
                showFooter: false,
                dockablePanel: false,
                resizable: true,
                width: 850,
                height: 300,
                canLinkByAccount: false
            };
        },
        partials: {
            bodyPartial: FullLogPanelTemplate
        }
    });
