import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { OrdersPanel } from '../OrdersPanel';

export class OptionOrdersPanel extends OrdersPanel {
    private _optionTrader: OptionTrader;

    constructor () {
        super();
        this.isFiltered = true;
        this.Name = 'OptionOrdersPanel';
        this.headerLocaleKey = 'panel.optionOrders';
        this.AddOrderEvent = this.AddOrderEvent.bind(this);
        this.RemoveOrderEvent = this.RemoveOrderEvent.bind(this);
        this.populateItemsDirect = this.populateItemsDirect.bind(this);
    }

    public override oninit (): void {
        void super.set('isRelativeStyle', true);
        void super.set('isAllowPadding', false);
        super.observe('visible', this.onVisibleChanged);
    }

    public override getType (): PanelNames { return PanelNames.OptionOrdersPanel; }

    public override TickAsync (): void {
        super.TickAsync();
        const isVisible: boolean = this.get<boolean>('visible');
        if (!isVisible) {
            return;
        }
        this.getQuickTable().needRedrawBackground = true;
    }

    public override populateItemsDirect (): void {
        const quickTable = this.getQuickTable();
        quickTable.ClearAll();
    }

    public setOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
        this.DCEventsSubscribe();
    }

    public override DCEventsSubscribe (): void {
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        this._optionTrader.subscribeOnRepopulate(this.populateItemsDirect);
        this._optionTrader.subscribeOnAddOrder(this.AddOrderEvent);
        this._optionTrader.subscribeOnRemoveOrder(this.RemoveOrderEvent);
    }

    public override DCEventsUnSubscribe (): void {
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        this._optionTrader.unsubscribeOnRepopulate(this.populateItemsDirect);
        this._optionTrader.unsubscribeOnAddOrder(this.AddOrderEvent);
        this._optionTrader.unsubscribeOnAddOrder(this.RemoveOrderEvent);
    }

    private onVisibleChanged (): void {
        this.layoutTable();
    }
}
