// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { AgressorFlagType } from '../../Utils/Enums/Constants';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';

export class TimeSalesItem extends BaseItem {
    public instrument: any = null;
    public isSeparatedSourceMode: any = null;
    public price: any = null;
    public time: any = null;
    public size: any = null;
    public exchange: any = null;
    public buyerSource: any = null;
    public sellerSource: any = null;
    public aggressorFlag: any = null;
    public colorValue: any = null;
    public itemType: any = null;
    public lastVenue: any = null;

    public override ColumnCount (): number {
        return TimeSalesItem.columnsParams.length;
    }

    public override getColumnData (column: number): ColumnData {
        const val = this.getColumnValue(column);
        let fVal = null;
        const ins = this.instrument;

        switch (column) {
        case 1:
            fVal = DateTimeUtils.formatDate(val, 'HH:mm:ss');
            break;
        case 2:
            fVal = ins.formatPrice(val);
            break;
        case 3:
            fVal = ins.DataCache.formatVolume(ins, val, GeneralSettings.View.displayAmountInLots());
            break;
        case 11:{
            const agrFlagTypes = AgressorFlagType;
            let locKey = null;
            if (val == -2) {
                locKey = '';
            } else if (val === agrFlagTypes.None || val === agrFlagTypes.NotSet) {
                locKey = 'panel.timeSales.AgressorFlag.None';
            } else if (val === agrFlagTypes.Ask) {
                locKey = 'panel.timeSales.AgressorFlag.Buy';
            } else if (val === agrFlagTypes.Bid) {
                locKey = 'panel.timeSales.AgressorFlag.Sell';
            } else if (val === agrFlagTypes.CrossTrade) {
                locKey = 'panel.timeSales.AgressorFlag.CrossTrade';
            } else if (val === agrFlagTypes.RLP) {
                locKey = 'panel.timeSales.AgressorFlag.RLP';
            } else {
                locKey = 'panel.timeSales.AgressorFlag.Auction';
            }
            fVal = Resources.getResource(locKey);
            break;
        }

        case 12:
        case 13:
            fVal = val === null || val === undefined ? Resources.getResource('general.N_A') : val.toString();
            break;
        default:
            fVal = val === null || val === undefined ? '' : val.toString();
        }

        return new ColumnData(val, fVal);
    }

    public override getColumnValue (column: number): any {
        const ins = this.instrument;
        const dc = ins.DataCache;

        switch (column) {
        case 0:
            return ins.DisplayName();
        case 1:
            return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(this.time);
        case 2:
            return this.price;
        case 3:
            return this.size;
        case 4:
            return this.lastVenue != null ? dc.GetSourceName(this.lastVenue) : this.exchange;
        case 9:
            return ''; // брать BID_VENUE из Quote (1) когда TimeSalesPanel начнет поддерживать Q1
        case 10:
            return ''; // брать ASK_VENUE из Quote (1) когда TimeSalesPanel начнет поддерживать Q1
        case 11:
            return this.aggressorFlag;
        case 12:
            return this.buyerSource;
        case 13:
            return this.sellerSource;
        default:
            return '';
        }
    }

    // TODO. Ugly. Static modification in each item.
    public override GetColumnParams (column: number): ColumnParams {
        const result = TimeSalesItem.columnsParams[column];
        let isHidden = Resources.isHidden(result.HeaderKey);

        if (column === 4) {
            isHidden = isHidden || this.isSeparatedSourceMode;
        }

        if (column === 12 || column === 13) {
            isHidden = isHidden || !this.isSeparatedSourceMode;
        }

        result.Hidden = isHidden || result.Hidden;
        return result;
    }

    public static readonly columnsParams = [
    /* 0 */new ColumnParams('panel.timeSales.Instrument', 70, 0, false, true, false),
        /* 1 */new ColumnParams('panel.timeSales.Time', 70, QuickTableColumnType.COL_DATE_SORT, false, true, false),
        /* 2 */new ColumnParams('panel.timeSales.Price', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        /* 3 */new ColumnParams('panel.timeSales.Size', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        /* 4 */new ColumnParams('panel.timeSales.Exchange', 70, 0, false, true, false),
        new ColumnParams('panel.timeSales.Type', 70, 0, false, true, false, true),
        new ColumnParams('panel.timeSales.bid', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false, true),
        new ColumnParams('panel.timeSales.ask', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false, true),
        new ColumnParams('panel.timeSales.AskBidSize', 70, 0, false, true, false, true),
        new ColumnParams('panel.timeSales.BidSource', 70, 0, false, false, false, true),
        new ColumnParams('panel.timeSales.AskSource', 70, 0, false, false, false, true),
        /* 11 */new ColumnParams('panel.timeSales.Agressor flag', 70, 0, false, false, false),
        /* 12 */new ColumnParams('panel.timeSales.BuyerSource', 70, 0, false, true, false),
        /* 13 */new ColumnParams('panel.timeSales.SellerSource', 70, 0, false, true, false)
    ];
}

export enum TSItemType {
    AboveAsk = 0, // cASKHI
    AtAsk = 1, // cASK
    AtBid = 2, // cBID
    BelowBid = 3, // cBIDLO
    BetweenSpread = 4, // cMIDLO
    Quotes = 5 // cQUOTE
}
