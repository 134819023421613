export class RiskPlanMargin {
    public Warning: number;
    public Initial: number;
    public Maintenance: number;
    public Leverage: number;

    constructor (Warning?: number, Initial?: number, Maintenance?: number, Leverage?: number) {
        this.Warning = Warning;
        this.Initial = Initial;
        this.Maintenance = Maintenance;
        this.Leverage = Leverage;
    }

    public isEmpty (): boolean {
        return this.isNumberEmpty(this.Warning) &&
                this.isNumberEmpty(this.Initial) &&
                this.isNumberEmpty(this.Maintenance);
    }

    public isNumberEmpty (value: number): boolean {
        return !isValidNumber(value) && value === 0;
    }
}
