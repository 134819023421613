// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources } from '../../Commons/properties/Resources';
import { CommissionItem } from '../../Commons/cache/Commissions/CommissionItem';
import { CommissionTypes, CommissionOperationType } from '../../Utils/Commission/CommissionEnums';
import { MarginOEControlTemplate } from '../../templates.js';
import { VerticalApplicationPanelNew } from '../cache/VerticalPanel/VerticalApplicationPanelNew';
import { VerticalPanelDataProvider } from '../cache/VerticalPanel/VerticalPanelDataProvider';
import { VerticalPanelDataProviderItem } from '../cache/VerticalPanel/VerticalPanelDataProviderItem';
import { Control } from '../elements/Control';
import { QuickTableRow } from '../elements/QuickTable/QuickTableRow';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { ThemeManager } from '../misc/ThemeManager';
import { OperationType } from '../../Utils/Trading/OperationType';
import { OrderType } from '../../Utils/Trading/OrderType';

import { QuotingType } from '../../Utils/Instruments/QuotingType';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { CommissionPlan } from '../../Commons/cache/Commissions/CommissionPlan';
import { PriceFormatter } from '../../Utils/Instruments/PriceFormatter';
import { DynProperty } from '../../Commons/DynProperty';
import { QuoteValid } from '../../Utils/Quotes/QuoteValid';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { Quantity } from '../../Utils/Trading/Quantity';
import { OrderEditBaseUtils } from '../../Utils/Trading/OrderEditBaseUtils';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { ControlsUtils } from '../UtilsClasses/ControlsUtils';
import { SymbolInfoPanel } from './SymbolInfoPanel';
import { MarginInfoParameters } from '../../Commons/UtilsClasses/MarginInfo/MarginInfoParameters';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Account } from '../../Commons/cache/Account';
import { type ProductType } from '../../Utils/Instruments/ProductType';
import { type OrderTypeBase } from '../../Commons/cache/OrderParams/order-type/OrderTypeBase';
import { type QuickTable } from '../elements/QuickTable/QuickTable';
import { type CommissionGroup, type DirectMarginMessage } from '../../Utils/DirectMessages/DirectMarginMessage';
import { type SpreadPlan } from '../../Commons/cache/SpreadPlan';
import { type AssetBalanceItem } from '../cache/AssetBalanceItem';
import { type NewVerticalPanelTableItem } from '../cache/VerticalPanel/NewVerticalPanelTableItem';

export class MarginOEControl extends VerticalApplicationPanelNew {
    public static readonly TICKCOST_INS_TYPES = [InstrumentTypes.FUTURES, InstrumentTypes.CFD_FUTURES, InstrumentTypes.OPTIONS, InstrumentTypes.SPREADBET];

    public settings: any = null;
    public lastReceivedResponseMessage: DirectMarginMessage | null = null;

    public commissionPlan: CommissionPlan | null = null;
    public allowedCommissionTypes: Record<CommissionTypes, boolean> | null = null;
    public instrumentWasChanged: boolean = false;
    public emptyMode: boolean = false;

    public refreshTick: number = 0; // current tick
    public refreshCycleFullTime: number = 30000; // full time in ms
    public refreshTickTime: number = 1000; // timer timeout duration in ms
    public refreshIntervalHandler: any = null; // setInterval handler

    public lastLimitPrice: number | null = null; // кэшируем во избежание избыточного запроса при изменении orderEditParameterArray
    public lastStopPrice: number | null = null; // ^_^ -!-

    public msgTimeoutHandler: any = null; // задержка перед отправкой запроса на сервер
    public msgTimeoutDuration: number = 200; // длительность задержки в мс

    constructor () {
        super();

        this.settings = SessionSettings;
    }

    public override getType (): PanelNames { return PanelNames.MarginOEControl; }

    public override oninit (): void {
        super.oninit();

        Control.Ticker.Subscribe(this.UpdateData, this);

        this.on('refreshBtnClicked', this.RefreshByMarginRequest);
        this.observe('account', this.onAccountChange);
        this.observe('instrument', this.onInstrumentChange);
        this.observe('operation orderType amount leverageValue', this.RefreshByMarginRequest, { init: false });
        this.observe('orderEditParameterArray', this.onOrderEditParameterArrayChange);
        this.observe('visible', this.onVisibleChange, { init: false });
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.InitTable();
    }

    public override localize (): void {
        super.localize();

        if (!isNullOrUndefined(this.VerticalPanelDataProvider)) {
            this.VerticalPanelDataProvider.Localize();
        }

        void this.set('refreshBtnTooltip', Resources.getResource('OrderEntry.InfoBlock.refreshBtnTooltip'));
    }

    public override InitializeDataProvider (force: boolean): void {
        this.VerticalPanelDataProvider = new VerticalPanelDataProvider(this.settings);
        // Data items
        this.VerticalPanelDataProvider.items = this.getDataItems(force || this.IsFeesGroupEnabled());

        this.VerticalPanelDataProvider.GetVerticalPanelValueHandler = this.GetDataValue.bind(this);
        this.VerticalPanelDataProvider.GetItemsIDHandler = this.GetItemsID.bind(this);

        this.VerticalPanelDataProvider.GetDynamicItemsHandler = this.GetDynamicItems.bind(this);
        this.VerticalPanelDataProvider.DisposeHandler = this.DisposeSource.bind(this);

        const account: Account = this.get('account');
        if (!isNullOrUndefined(account)) {
            this.SetSource(DataCache, account.BstrAccount);
        }
    }

    public RefreshByMarginRequest (): void {
        if (!this.get<boolean>('visible')) {
            return;
        }

        if (!isNullOrUndefined(this.msgTimeoutHandler)) {
            clearTimeout(this.msgTimeoutHandler);
        }

        this.msgTimeoutHandler = setTimeout(() => {
            this.refreshTick = 0;
            clearTimeout(this.msgTimeoutHandler);
            const ins: Instrument = this.get('instrument');
            const acc: Account = this.get('account');
            const productType: ProductType = this.get('productType');
            const leverageValue: number = this.get('leverageValue');
            const quantity: Quantity = this.get('amount');
            const orderType: OrderTypeBase = this.get('orderType');
            const amount = !isNullOrUndefined(quantity) ? Quantity.toLots(quantity, ins) : null;

            if (isNullOrUndefined(ins) || isNullOrUndefined(acc)) return;

            const price = this.GetLimitPrice();
            const stopPrice = this.GetStopPrice();
            const orderTypeID = !isNullOrUndefined(orderType) ? orderType.id() : null;

            if (!isNullOrUndefined(orderTypeID) && orderTypeID !== OrderType.Market && (!isValidNumber(price) || price === 0) && (!isValidNumber(stopPrice) || stopPrice === 0)) {
                this.emptyMode = true;
                this.repopulate(true);
                this.UpdateTable();
                return;
            }
            const parameters = new MarginInfoParameters();
            parameters.account = acc;
            parameters.instrument = ins;
            parameters.orderType = orderTypeID;
            parameters.amountLots = amount;
            parameters.limitPrice = price;
            parameters.stopPrice = stopPrice;
            parameters.productType = productType;
            parameters.leverageValue = leverageValue;
            void DataCache.SendMarginRequest(parameters)
                .then((msgArr: DirectMarginMessage[]) => {
                    if (isValidArray(msgArr)) {
                        this.lastReceivedResponseMessage = msgArr[msgArr.length - 1];

                        if (this.instrumentWasChanged) {
                            this.emptyMode = false;
                            this.instrumentWasChanged = false;
                            this.repopulate();
                        }

                        this.UpdateTable();
                    }
                });
        }, this.msgTimeoutDuration);
    }

    public onOrderEditParameterArrayChange (): void {
        const newLimitPrice = this.GetLimitPrice();
        const newStopPrice = this.GetStopPrice();

        if (this.lastLimitPrice !== newLimitPrice || this.lastStopPrice !== newStopPrice) {
            this.RefreshByMarginRequest();
        }

        this.lastLimitPrice = newLimitPrice;
        this.lastStopPrice = newStopPrice;
    }

    public InitTable (): void {
        const qtR = this.quickTableRactive;
        if (isNullOrUndefined(qtR)) return;

        qtR.setShowColumnHeaders(false);

        const qt = qtR.quickTable;
        if (isNullOrUndefined(qt)) return;

        qt.allowGroupBy = false;
        qt.useVerticalSeparatorForResizing = true;
        qt.FormatGroup = this.quickTable_FormatGroup.bind(this);
        // qt.OnPaintedPictureButtonClick.Subscribe(this.onPaintedPictureButtonClick, this);
    }

    public UpdateTable (): void {
        this.PopulateTable();
        this.layoutTable();
        this.UpdateData();
    }

    public UpdateData (): void {
        const qtRactive = this.quickTableRactive;
        const qt = !isNullOrUndefined(qtRactive) ? qtRactive.quickTable : null;
        let needRedraw = !isNullOrUndefined(qt) ? qt.needRedraw : null;

        if (!isNullOrUndefined(this.VerticalPanelDataProvider)) {
            this.VerticalPanelDataProvider.UpdateItems();
            // this.hideGroupsIfEmpty()

            needRedraw = true;
        }

        if (needRedraw != null) {
            qt.needRedraw = false;
            qt.Draw();
        }
    }

    public override layoutTable (): void {
        super.layoutTable();

        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        if (!isNullOrUndefined(qt.columns[0]) && !isNullOrUndefined(qt.columns[1])) { // установка ширин колонок - todo подумать как сделать это лучше
            qt.columns[0].width = 150;
            qt.columns[1].width = 150;
        }
    }

    public quickTable_FormatGroup (groupValue: string): string {
        const acc: Account = this.get('account');
        const currency = !isNullOrUndefined(acc) ? ' ' + acc.BaseCurrency : '';

        return Resources.getResource(groupValue) + currency;
    }

    public override dispose (): void {
        this.lastReceivedResponseMessage = null;
        this.commissionPlan = null;

        if (!isNullOrUndefined(this.refreshIntervalHandler)) {
            clearInterval(this.refreshIntervalHandler);
        }

        // if (this.getQuickTable())
        //     this.getQuickTable().OnPaintedPictureButtonClick.UnSubscribe(this.onPaintedPictureButtonClick, this);

        Control.Ticker.UnSubscribe(this.UpdateData, this);

        super.dispose();
    }

    public onAccountChange (account: Account): void {
        if (isNullOrUndefined(account)) return;

        if (!isNullOrUndefined(account.CommissionPlan)) {
            this.commissionPlan = account.CommissionPlan;
            this.allowedCommissionTypes = this.GetAllowedCommissionTypes();
        }

        this.SetSource(DataCache, account.BstrAccount);

        this.RefreshByMarginRequest();
    }

    public onInstrumentChange (ins: Instrument): void {
        if (isNullOrUndefined(ins)) return;

        this.instrumentWasChanged = true;

        this.allowedCommissionTypes = this.GetAllowedCommissionTypes(ins);

        this.RefreshByMarginRequest();
    }

    public onVisibleChange (visible: boolean): void {
        if (visible) {
            this.refreshTick = this.refreshCycleFullTime;
            this.RefreshBtnIntervalTick();
            this.InitRefreshBtnTimer();
        } else
            if (!isNullOrUndefined(this.refreshIntervalHandler)) {
                clearInterval(this.refreshIntervalHandler);
            }
    }

    public getDataItems (feesGroupEnabled: boolean): VerticalPanelDataProviderItem[] {
        const items: VerticalPanelDataProviderItem[] = [];
        const keys = this.GetItemsID();

        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            const group = MarginOEControl.GetGroupForKey(k);

            if (group === MarginOEControl.KEY_GROUP_FEES) // вот так пока не добавляю поле из fees если их нет
            {
                if (!isNullOrUndefined(this.allowedCommissionTypes)) {
                    const cType = MarginOEControl.KeyToCommissionType(k);
                    if (!this.allowedCommissionTypes[cType] && !isNullOrUndefined(cType)) {
                        continue;
                    }
                }
                if (!feesGroupEnabled) {
                    continue;
                }
            }

            if (isValidString(group)) {
                const locKey = k;
                const newItem = new VerticalPanelDataProviderItem();
                newItem.Id = locKey;
                newItem.DynPropertyControltype = this.GetDynPropertyControlTypeForKey(k);
                newItem.SortIndex = MarginOEControl.GetSortIndex(k);
                newItem.LocalizationKey = locKey;
                // newItem.tooltipKey = Resources.GetToolTipKey(columnParams.HeaderKey);
                newItem.Group = group;

                items.push(newItem);
            }
        }

        return items;
    }

    public GetDataValue (key: string, VerticalPanelDataProvider: VerticalPanelDataProvider, sett, rowId): string {
        try {
            const ins: Instrument = this.get('instrument');
            const productType: ProductType = this.get('productType');
            const qt = this.getQuickTable();
            const row = qt.rows[rowId];
            const accItems = VerticalPanelDataProvider.Source;
            let useAssetBalanceItem = true;
            let isColoredField = MarginOEControl.isColoredField(key);
            const id = MarginOEControl.KEYS_MAP[key];
            if (!isValidNumber(id)) {
                useAssetBalanceItem = false;
            }

            if (isValidArray(accItems)) {
                const assetBalanceItem = accItems[0];
                let formattedValue = '';
                if (!this.emptyMode) {
                    if (useAssetBalanceItem) {
                        formattedValue = assetBalanceItem.getColumnData(id).FormattedValue;
                    } else {
                        const side = this.get<OperationType>('operation');
                        const operationMode = side + 1;

                        switch (key) {
                        case MarginOEControl.KEY_INIT_MARGIN:
                            formattedValue = this.GetMarginParamFormattedByPropName('InitMargin', side);
                            break;
                        case MarginOEControl.KEY_MAINT_MARGIN:
                            formattedValue = this.GetMarginParamFormattedByPropName('MaintMargin', side);
                            break;
                        case MarginOEControl.KEY_WARN_MARGIN:
                            formattedValue = this.GetMarginParamFormattedByPropName('WarnMargin', side);
                            break;
                        case MarginOEControl.KEY_IMPACT_ON_PORTFOLIO:
                            formattedValue = this.GetImpactOnPortfolioFormatted(assetBalanceItem, side);
                            break;
                        case MarginOEControl.KEY_AFTER_TRADE_FUNDS:
                            formattedValue = this.GetAfterTradeFundsFormatted(side, operationMode);
                            break;
                        case MarginOEControl.KEY_SPREAD_INIT_LOSS:
                            formattedValue = this.GetSpreadInitLossFormatted();
                            break;
                        case MarginOEControl.KEY_PL_PER_TICK:
                            formattedValue = this.GetPLPerTickFormatted();
                            break;
                        case MarginOEControl.KEY_ALLOW_SHORT_POSITIONS:
                            formattedValue = SymbolInfoPanel.getLocalizedAllowShortPosition(ins.IsAllowShortPositions(productType));
                            break;
                        case MarginOEControl.KEY_AFTER_TRADE_CASH:
                            formattedValue = this.GetAfterTradeCashFormatted();
                            break;
                        case MarginOEControl.KEY_FILL_PER_LOT:
                            formattedValue = this.GetFillPerLotFeeFormatted(operationMode);
                            break;
                        case MarginOEControl.KEY_ORDER_PER_LOT:
                            formattedValue = this.GetOrderPerLotFeeFormatted(operationMode);
                            break;
                        case MarginOEControl.KEY_PER_FILL:
                            formattedValue = this.GetPerFillFeeFormatted(operationMode);
                            break;
                        case MarginOEControl.KEY_PER_TRANSACTION:
                            formattedValue = this.GetPerTransactionFeeFormatted(operationMode);
                            break;
                        case MarginOEControl.KEY_PER_PHONE_TRANSACTION:
                            formattedValue = this.GetPerPhoneTransactionFeeFormatted(operationMode);
                            break;
                        case MarginOEControl.KEY_VAT:
                            formattedValue = this.GetVatFeeFormatted(operationMode);
                            break;
                        case MarginOEControl.KEY_FILL_VOLUME:
                            formattedValue = this.GetFillVolumeFeeFormatted(operationMode);
                            break;
                        case MarginOEControl.KEY_ORDER_VOLUME:
                            formattedValue = this.GetOrderVolumeFeeFormatted(operationMode);
                            break;
                        case MarginOEControl.KEY_FILL_VOLUME_WITH_MIN_PD:
                            formattedValue = this.GetFillVolumeWithMinPDFeeFormatted(operationMode);
                            break;
                        case MarginOEControl.KEY_SHORT_SWAP_INFO:
                            formattedValue = this.GetShortSwapInfo();
                            break;
                        case MarginOEControl.KEY_LONG_SWAP:
                            formattedValue = this.GetLongSwap();
                            break;
                        case MarginOEControl.KEY_SHORT_SWAP:
                            formattedValue = this.GetShortSwap();
                            break;
                        case MarginOEControl.KEY_TOTAL_FEES:
                            formattedValue = this.IsTotalFeeRowVisible() ? this.GetTotalFeeFormatted(operationMode) : '';
                            break;
                        }

                        const addInfo = assetBalanceItem.AssetBalance.AssetAdditionalInfo;
                        if (!isNullOrUndefined(addInfo?.[key])) {
                            isColoredField = true;
                            formattedValue = assetBalanceItem.AssetBalance.formatPriceExactly(addInfo[key].Value); // custom available funds #106952
                        }
                    }
                }

                if (!isNullOrUndefined(row)) {
                    if (this.emptyMode) {
                        row.visible = true;
                    } else if (!this.instrumentWasChanged && formattedValue === '') {
                        row.visible = false;
                    } // скрываем пустые строки

                    const val = parseFloat(formattedValue.replace(',', '.'));
                    if (isColoredField && isValidNumber(val)) // красим пока только это поле
                    {
                        row.cells[1].ForeColor = QuickTableRow.ColorBySign(row.cells[1], row.table.columns[1], val).newForeColor;
                    }
                }

                if (this.emptyMode) {
                    return '';
                }

                return formattedValue;
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }

        return '';
    }

    public GetItemsID (): string[] {
        let result = MarginOEControl.ALL_FIELDS;

        const account: Account = this.get('account');
        if (!isNullOrUndefined(account)) {
            result = result.concat(Object.keys(account.GetAssetBalanceCorrect().AssetAdditionalInfo));
        }

        return result;
    }

    public GetMarginParamValueByPropName (paramName: string, operationMode: OperationType, withoutBuySellStr?: boolean): number | null // for Init, Maint, Warn Margin fields
    {
        const msg = this.lastReceivedResponseMessage;
        let value: number | null = null;
        const propName = paramName + (withoutBuySellStr ? '' : OrderUtils.getBuySellStr(operationMode));

        if (msg?.hasOwnProperty(propName)) {
            value = parseFloat(msg[propName]);
        }

        return value;
    }

    public GetAfterTradeCashFormatted (): string {
        const acc: Account = this.get('account');
        const msg = this.lastReceivedResponseMessage;

        if (isNullOrUndefined(acc) || isNullOrUndefined(msg) || isNullOrUndefined(msg.AfterTradeCash)) {
            return '';
        }

        return acc.formatPrice(msg.AfterTradeCash);
    }

    public GetMarginParamFormattedByPropName (paramName: string, operationMode: OperationType, withoutBuySellStr?: boolean, withoutCurrency?: boolean): string // for Init, Maint, Warn Margin fields
    {
        const value = this.GetMarginParamValueByPropName(paramName, operationMode, withoutBuySellStr);
        let formattedValue = '';

        withoutCurrency = withoutCurrency ?? false;

        if (!isNullOrUndefined(value)) {
            formattedValue = this.formatPrice(value, withoutCurrency);
        }

        return formattedValue;
    }

    public GetSpreadInitLossFormatted (): string {
        const ins: Instrument = this.get('instrument');
        const acc: Account = this.get('account');
        const qty: Quantity = this.get('amount');
        const side: OperationType = this.get<OperationType>('operation');
        const sp: SpreadPlan = DataCache.GetSpreadPlan(acc);

        if (isNullOrUndefined(acc) || isNullOrUndefined(ins) || isNullOrUndefined(qty) || isNullOrUndefined(ins.LastQuote)) {
            return MarginOEControl.EMPTY_STRING;
        }

        const crossPrice = DataCache.CrossRateCache.GetCrossPriceInsSideExp2(ins, side, acc.BaseCurrency); // постоянно меняющаяся формула из доки https://docs.google.com/document/d/1-Dq-3OjRZUbWq-ppOJI0cpmqOxCP-qUCQYv20ZjTYF0
        const ask = ins.LastQuote.AskSpread_SP_Ins(sp, ins);
        const bid = ins.LastQuote.BidSpread_SP_Ins(sp, ins);
        const amount = Quantity.toLots(qty, ins);
        let result = -(ask - bid) * amount * crossPrice;
        result *= ins.getLotSize();
        result *= ins.GetTickCost();

        return result !== 0 ? acc.formatPrice(result) : MarginOEControl.EMPTY_STRING;
    }

    public GetPLPerTickFormatted (): string | null {
        const acc: Account = this.get('account');
        const ins: Instrument = this.get('instrument');
        const qty: Quantity = this.get('amount');
        const side: OperationType = this.get<OperationType>('operation');
        let result = null;

        if (isNullOrUndefined(acc) || isNullOrUndefined(ins) || isNullOrUndefined(qty)) {
            return null;
        }

        const crossPrice = DataCache.CrossRateCache.GetCrossPriceInsSideExp2(ins, side, acc.BaseCurrency);
        const amount = Quantity.toLots(qty, ins);

        if (MarginOEControl.TICKCOST_INS_TYPES.includes(ins.InstrType) &&
        ins.QuotingType === QuotingType.TickCost_TickSize) {
            result = ins.FuturesTickCoast * amount * crossPrice;
        } else {
            let price = this.GetLimitPrice() ?? this.GetStopPrice();
            if (!isValidNumber(price) || price === 0) {
                const quote = ins.GetLastQuote(QuoteValid.Valid);
                const isBuy = this.get<OperationType>('operation') === OperationType.Buy;

                if (!isNullOrUndefined(quote)) {
                    price = isBuy ? quote.Ask : quote.Bid;
                }
            }

            const tickSize = ins.getLotSize() * ins.GetPointSize(price);

            result = tickSize * amount * crossPrice;
        }

        return acc.formatPrice(result);
    }

    public GetImpactOnPortfolioFormatted (assetBalanceItem: AssetBalanceItem, side: OperationType): string {
        const afterTradeFunds = this.GetMarginParamValueByPropName('AfterTradeFunds', side);
        const availableFunds = this.GetAvailableFundsDependsOnBuyingPowerName(assetBalanceItem);

        if (isNullOrUndefined(afterTradeFunds)) // #99902
        {
            return '';
        }

        return this.formatPrice(afterTradeFunds - availableFunds);
    }

    public GetAvailableFundsDependsOnBuyingPowerName (assetBalanceItem: AssetBalanceItem): number | null // #106952+
    {
        if (isNullOrUndefined(assetBalanceItem)) {
            return null;
        }

        const assetAdditionalInfo = !isNullOrUndefined(assetBalanceItem.AssetBalance) ? assetBalanceItem.AssetBalance.AssetAdditionalInfo : null;
        const buyingPowerName = this.GetBuyingPowerName();

        if (!isNullOrUndefined(assetAdditionalInfo?.[buyingPowerName])) {
            return assetAdditionalInfo[buyingPowerName].Value;
        }

        return assetBalanceItem.getColumnData(MarginOEControl.KEYS_MAP[MarginOEControl.KEY_AVAILABLE_FUNDS]).Value;
    }

    public GetAfterTradeFundsFormatted (side: OperationType, operationMode: CommissionOperationType): string // #99556
    {
        const afterTradeFunds = this.GetMarginParamValueByPropName('AfterTradeFunds', side);
        const totalFee = Math.abs(this.GetTotalFee(operationMode));

        if (isNullOrUndefined(afterTradeFunds)) // #99902
        {
            return '';
        }

        return this.formatPrice(afterTradeFunds - totalFee);
    }

    public formatPrice (price: number, withoutAsset: boolean = false): string {
        if (withoutAsset) {
            return PriceFormatter.formatPrice(price, 2);
        }

        const acc = this.get('account');

        if (!isNullOrUndefined(acc)) {
            return acc.formatPrice(price);
        }

        return price.toString();
    }

    public SearchForFeeValueByType (commissionType: CommissionTypes): string | null {
        const ins = this.get('instrument');
        const acc = this.get('account');
        const commissionPlan = this.commissionPlan;
        if (isNullOrUndefined(commissionPlan) || isNullOrUndefined(ins) || isNullOrUndefined(acc)) {
            return null;
        }

        const key = MarginOEControl.CommissionTypeToKey(commissionType);
        const feeCurrency = CommissionItem.GetCommissionCurrency(acc, ins);

        if (!isValidString(key) || !isValidString(feeCurrency)) {
            return null;
        }

        const comResult = CommissionPlan.TryGetCommissionDataValue(feeCurrency, key, ins, acc);
        if (isValidString(comResult)) {
            return comResult;
        }

        return null;
    }

    public GetCommissionGroups (commissionType: CommissionTypes, operationMode: CommissionOperationType): CommissionGroup[] | null {
        const msg = this.lastReceivedResponseMessage;
        if (isNullOrUndefined(msg?.CommissionsByOperationMode)) {
            return null;
        }

        const commissionGroups = msg.CommissionsByOperationMode[operationMode];
        const resultArr: CommissionGroup[] = [];
        if (isValidArray(commissionGroups)) {
            for (let i = 0; i < commissionGroups.length; i++) {
                const commissionGroup = commissionGroups[i];
                if (commissionGroup.CommissionType === commissionType) {
                    resultArr.push(commissionGroup);
                }
            }
        }

        return resultArr;
    }

    public GetFeeAmountFormatted (commissionType: CommissionTypes, operationMode: CommissionOperationType): string {
        const value = this.GetFeeAmount(commissionType, operationMode);
        if (!isValidNumber(value) || value === 0) {
            return '';
        }

        return this.formatPrice(Math.abs(value), true);
    }

    public GetFeeAmount (commissionType: CommissionTypes, operationMode: CommissionOperationType): number | null {
        const commissionGroups = this.GetCommissionGroups(commissionType, operationMode);
        if (!isValidArray(commissionGroups)) {
            return null;
        }

        let sumByOperationMode = 0;
        for (let i = 0; i < commissionGroups.length; i++) {
            sumByOperationMode += commissionGroups[i].Amount;
        }

        return sumByOperationMode;
    }

    public GetFeeCurrencyID (commissionType: CommissionTypes, operationMode: CommissionOperationType): number | null {
        const commissionGroups = this.GetCommissionGroups(commissionType, operationMode);
        if (isValidArray(commissionGroups)) {
            return commissionGroups[0].CurrencyId;
        } // судя по настройкам сервера комиссия в разных группах не модет отличаться -> берем из первой группы

        return null;
    }

    public GetFillPerLotFeeFormatted (operationMode: CommissionOperationType): string {
        return this.GetFeeAmountFormatted(CommissionTypes.PerLot, operationMode);
    }

    public GetOrderPerLotFeeFormatted (operationMode: CommissionOperationType): string {
        return this.GetFeeAmountFormatted(CommissionTypes.OrderPerLot, operationMode);
    }

    public GetPerFillFeeFormatted (operationMode: CommissionOperationType): string {
        return this.GetFeeAmountFormatted(CommissionTypes.PerFill, operationMode);
    }

    public GetPerTransactionFeeFormatted (operationMode: CommissionOperationType): string {
        return this.GetFeeAmountFormatted(CommissionTypes.PerTransaction, operationMode);
    }

    public GetPerPhoneTransactionFeeFormatted (operationMode): string {
        return ''; // TODO выяснить, вероятно устарело, десктоп не показывает в margin OE
    }

    public GetVatFeeFormatted (operationMode: CommissionOperationType): string {
        return this.GetFeeAmountFormatted(CommissionTypes.VAT, operationMode);
    }

    public GetFillVolumeFeeFormatted (operationMode: CommissionOperationType): string {
        return this.GetFeeAmountFormatted(CommissionTypes.PerVolume, operationMode);
    }

    public GetOrderVolumeFeeFormatted (operationMode: CommissionOperationType): string {
        return this.GetFeeAmountFormatted(CommissionTypes.PerOrderVolume, operationMode);
    }

    public GetFillVolumeWithMinPDFeeFormatted (operationMode: CommissionOperationType): string {
        return this.GetFeeAmountFormatted(CommissionTypes.VolumeWithMinPD, operationMode);
    }

    public GetShortSwapInfo (): string {
        const msg = this.lastReceivedResponseMessage;
        const propName = 'ShortSwapInfo';
        let res: string | null = null;

        if (msg?.hasOwnProperty(propName)) {
            res = msg[propName];
        }

        if (!isValidString(res)) {
            res = Resources.getResource('InstrumentDetailsPanel.None');
        }

        return res;
    }

    public GetLongSwap (): string {
        return this.GetSwap(true);
    }

    public GetShortSwap (): string {
        return this.GetSwap(false);
    }

    public GetSwap (forBuy: boolean): string {
        let res = this.GetMarginParamFormattedByPropName('Swap' + (forBuy ? 'Buy' : 'Sell'), null, true, true);
        if (!isValidString(res)) {
            res = MarginOEControl.EMPTY_STRING;
        }

        return res;
    }

    public GetTotalFee (operationMode): number {
        let totalFee = 0;

        for (const commissionType in CommissionTypes) {
            const feeType: CommissionTypes = CommissionTypes[commissionType as keyof typeof CommissionTypes];
            const value = this.GetFeeAmount(feeType, operationMode);

            if (isValidNumber(value)) {
                totalFee += value;
            }
        }

        return totalFee;
    }

    public GetTotalFeeFormatted (operationMode): string {
        const totalFeeValue = this.GetTotalFee(operationMode);

        if (!isValidNumber(totalFeeValue) || totalFeeValue === 0) {
            return '';
        } // MarginOEControl.EMPTY_STRING

        return this.formatPrice(Math.abs(totalFeeValue), true);
    }

    public IsFeesGroupEnabled (): boolean {
        const msg = this.lastReceivedResponseMessage;
        if (!isNullOrUndefined(msg)) {
            if (!Resources.isHidden(MarginOEControl.KEY_LONG_SWAP) || !Resources.isHidden(MarginOEControl.KEY_SHORT_SWAP)) // Свопы всегда отображаем в рисках ОЕ (за исключением случая, когда они скрыты ключами).
            {
                return true;
            }
        }

        return this.GetTotalFee(CommissionOperationType.BUY) !== 0;
    }

    public IsTotalFeeRowVisible (): boolean {
        const operationMode = CommissionOperationType.BUY;
        let sum = 0;

        for (const commissionType in CommissionTypes) {
            const feeType = CommissionTypes[commissionType as keyof typeof CommissionTypes];
            const value = this.GetFeeAmount(feeType, operationMode);

            if (isValidNumber(value) && value !== 0) {
                if (isValidNumber(sum) && sum !== 0) {
                    return true;
                }

                sum += value;
            }
        }

        return false;
    }

    // groups
    public static readonly KEY_GROUP_RISKS = 'OrderEntry.InfoBlock.1.Risks';
    public static readonly KEY_GROUP_FEES = 'OrderEntry.InfoBlock.2.Fees';

    // fields' localization keys
    // 1.Risks
    public static readonly KEY_BALANCE = 'OrderEntry.InfoBlock.Balance';
    public static readonly KEY_AVAILABLE_FUNDS = 'OrderEntry.InfoBlock.Availible funds';
    public static readonly KEY_INCOMING_FUNDS = 'OrderEntry.InfoBlock.Incoming funds';
    public static readonly KEY_MARGIN_AVAILABLE = 'OrderEntry.InfoBlock.Margin availible';
    public static readonly KEY_INIT_MARGIN = 'OrderEntry.InfoBlock.Init. margin';
    public static readonly KEY_MAINT_MARGIN = 'OrderEntry.InfoBlock.Maint. margin';
    public static readonly KEY_WARN_MARGIN = 'OrderEntry.InfoBlock.Warn. margin';
    public static readonly KEY_IMPACT_ON_PORTFOLIO = 'OrderEntry.InfoBlock.Impact on portfolio';
    public static readonly KEY_AFTER_TRADE_FUNDS = 'OrderEntry.InfoBlock.After trade funds';
    public static readonly KEY_BLOCKED_FOR_STOCKS = 'OrderEntry.InfoBlock.Blocked for Stocks';
    public static readonly KEY_SPREAD_INIT_LOSS = 'OrderEntry.InfoBlock.Spread in loss';
    public static readonly KEY_PL_PER_TICK = 'OrderEntry.InfoBlock.P/L per Tick';
    public static readonly KEY_ALLOW_SHORT_POSITIONS = 'OrderEntry.InfoBlock.Allow short positions';
    public static readonly KEY_AFTER_TRADE_CASH = 'OrderEntry.InfoBlock.After trade cash';
    // 2.Fees
    public static readonly KEY_FILL_PER_LOT = 'OrderEntry.InfoBlock.FillPerLot';
    public static readonly KEY_ORDER_PER_LOT = 'OrderEntry.InfoBlock.OrderPerLot';
    public static readonly KEY_FILL_VOLUME = 'OrderEntry.InfoBlock.FillVolume';
    public static readonly KEY_FILL_VOLUME_WITH_MIN_PD = 'OrderEntry.InfoBlock.FillVolumeWithMinPD';
    public static readonly KEY_PER_FILL = 'OrderEntry.InfoBlock.PerFill';
    public static readonly KEY_PER_TRANSACTION = 'OrderEntry.InfoBlock.PerTransaction';
    public static readonly KEY_PER_PHONE_TRANSACTION = 'OrderEntry.InfoBlock.PerPhoneTransaction';
    public static readonly KEY_VAT = 'OrderEntry.InfoBlock.VAT';
    public static readonly KEY_ORDER_VOLUME = 'OrderEntry.InfoBlock.OrderVolume';
    public static readonly KEY_SHORT_SWAP_INFO = 'OrderEntry.InfoBlock.SwapDetails';
    public static readonly KEY_LONG_SWAP = 'OrderEntry.InfoBlock.LongSwap';
    public static readonly KEY_SHORT_SWAP = 'OrderEntry.InfoBlock.ShortSwap';
    public static readonly KEY_TOTAL_FEES = 'OrderEntry.InfoBlock.TotalFee';

    // fields and their column_id from AssetBalanceItem where it possible
    public static readonly KEYS_MAP = (function () {
        const keys = {}; // тут также задается в каком порядке идут строки
        // 1.Risks
        keys[MarginOEControl.KEY_BALANCE] = 2;
        keys[MarginOEControl.KEY_AVAILABLE_FUNDS] = 20;
        keys[MarginOEControl.KEY_INCOMING_FUNDS] = 117;
        keys[MarginOEControl.KEY_MARGIN_AVAILABLE] = 58;
        keys[MarginOEControl.KEY_INIT_MARGIN] = null;
        keys[MarginOEControl.KEY_MAINT_MARGIN] = null;
        keys[MarginOEControl.KEY_WARN_MARGIN] = null;
        keys[MarginOEControl.KEY_IMPACT_ON_PORTFOLIO] = null;
        keys[MarginOEControl.KEY_AFTER_TRADE_FUNDS] = null;
        keys[MarginOEControl.KEY_BLOCKED_FOR_STOCKS] = 74;
        keys[MarginOEControl.KEY_SPREAD_INIT_LOSS] = null;
        keys[MarginOEControl.KEY_PL_PER_TICK] = null;
        keys[MarginOEControl.KEY_ALLOW_SHORT_POSITIONS] = null;
        keys[MarginOEControl.KEY_AFTER_TRADE_CASH] = null;
        // 2.Fees
        keys[MarginOEControl.KEY_FILL_PER_LOT] = null;
        keys[MarginOEControl.KEY_ORDER_PER_LOT] = null;
        keys[MarginOEControl.KEY_FILL_VOLUME] = null;
        keys[MarginOEControl.KEY_ORDER_VOLUME] = null;
        keys[MarginOEControl.KEY_PER_FILL] = null;
        keys[MarginOEControl.KEY_PER_TRANSACTION] = null;
        keys[MarginOEControl.KEY_PER_PHONE_TRANSACTION] = null;
        keys[MarginOEControl.KEY_FILL_VOLUME_WITH_MIN_PD] = null;
        keys[MarginOEControl.KEY_VAT] = null;
        keys[MarginOEControl.KEY_SHORT_SWAP_INFO] = null;
        keys[MarginOEControl.KEY_LONG_SWAP] = null;
        keys[MarginOEControl.KEY_SHORT_SWAP] = null;
        keys[MarginOEControl.KEY_TOTAL_FEES] = null;

        return keys;
    }());

    // array of all available keys = fields
    public static readonly ALL_FIELDS = (function () {
        return Object.keys(MarginOEControl.KEYS_MAP);
    }());

    public static readonly EMPTY_STRING = '---';

    public static GetSortIndex (key: string): number {
        switch (key) {
        case MarginOEControl.KEY_BALANCE:
            return 0;
        case MarginOEControl.KEY_AVAILABLE_FUNDS:
            return 1;
        }

        if (!MarginOEControl.ALL_FIELDS.includes(key)) {
            return 2;
        }

        return 100;
    }

    public static isColoredField (key: string): boolean // тут перечислены подкрашиваемые поля
    {
        switch (key) {
        case MarginOEControl.KEY_BALANCE:
        case MarginOEControl.KEY_AVAILABLE_FUNDS:
        case MarginOEControl.KEY_INCOMING_FUNDS:
        case MarginOEControl.KEY_MARGIN_AVAILABLE:
        case MarginOEControl.KEY_INIT_MARGIN:
        case MarginOEControl.KEY_MAINT_MARGIN:
        case MarginOEControl.KEY_WARN_MARGIN:
        case MarginOEControl.KEY_AFTER_TRADE_FUNDS:
        case MarginOEControl.KEY_IMPACT_ON_PORTFOLIO:
        case MarginOEControl.KEY_BLOCKED_FOR_STOCKS:
        case MarginOEControl.KEY_SPREAD_INIT_LOSS:
        case MarginOEControl.KEY_PL_PER_TICK:
        case MarginOEControl.KEY_AFTER_TRADE_CASH:
            return true;
        }

        return false;
    };

    public static CommissionTypeToKey (type: CommissionTypes): string {
        switch (type) {
        case CommissionTypes.PerLot:
            return SymbolInfoPanel.KEY_FILLPERLOT;
        case CommissionTypes.OrderPerLot:
            return SymbolInfoPanel.KEY_ORDERPERLOT;
        case CommissionTypes.PerVolume:
            return SymbolInfoPanel.KEY_PERVOLUME;
        case CommissionTypes.VolumeWithMinPD:
            return SymbolInfoPanel.KEY_VOLUME_WITH_MIN_PD;
        case CommissionTypes.PerFill:
            return SymbolInfoPanel.KEY_PERFILL;
        case CommissionTypes.PerTransaction:
            return SymbolInfoPanel.KEY_PERTRANSACTION;
        case CommissionTypes.PerPhoneTransaction:
            return SymbolInfoPanel.KEY_PERPHONETRANSACTION;
        case CommissionTypes.VAT:
            return SymbolInfoPanel.KEY_VAT;
        case CommissionTypes.PerOrderVolume:
            return SymbolInfoPanel.KEY_ORDER_VOLUME;
        }

        return null;
    }

    // метод наоборот
    public static KeyToCommissionType (key: string): CommissionTypes {
        switch (key) {
        case MarginOEControl.KEY_FILL_PER_LOT:
            return CommissionTypes.PerLot;
        case MarginOEControl.KEY_ORDER_PER_LOT:
            return CommissionTypes.OrderPerLot;
        case MarginOEControl.KEY_FILL_VOLUME:
            return CommissionTypes.PerVolume;
        case MarginOEControl.KEY_FILL_VOLUME_WITH_MIN_PD:
            return CommissionTypes.VolumeWithMinPD;
        case MarginOEControl.KEY_PER_FILL:
            return CommissionTypes.PerFill;
        case MarginOEControl.KEY_PER_TRANSACTION:
            return CommissionTypes.PerTransaction;
        case MarginOEControl.KEY_PER_PHONE_TRANSACTION:
            return CommissionTypes.PerPhoneTransaction;
        case MarginOEControl.KEY_VAT:
            return CommissionTypes.VAT;
        case MarginOEControl.KEY_ORDER_VOLUME:
            return CommissionTypes.PerOrderVolume;
        case MarginOEControl.KEY_LONG_SWAP:
            return CommissionTypes.SwapBuy;
        case MarginOEControl.KEY_SHORT_SWAP:
            return CommissionTypes.SwapSell;
        }
        return null;
    }

    public static GetGroupForKey (headerKey: string): string {
        switch (headerKey) {
        case MarginOEControl.KEY_BALANCE:
        case MarginOEControl.KEY_AVAILABLE_FUNDS:
        case MarginOEControl.KEY_INCOMING_FUNDS:
        case MarginOEControl.KEY_MARGIN_AVAILABLE:
        case MarginOEControl.KEY_INIT_MARGIN:
        case MarginOEControl.KEY_MAINT_MARGIN:
        case MarginOEControl.KEY_WARN_MARGIN:
        case MarginOEControl.KEY_IMPACT_ON_PORTFOLIO:
        case MarginOEControl.KEY_AFTER_TRADE_FUNDS:
        case MarginOEControl.KEY_BLOCKED_FOR_STOCKS:
        case MarginOEControl.KEY_SPREAD_INIT_LOSS:
        case MarginOEControl.KEY_PL_PER_TICK:
        case MarginOEControl.KEY_ALLOW_SHORT_POSITIONS:
        case MarginOEControl.KEY_AFTER_TRADE_CASH:
            return MarginOEControl.KEY_GROUP_RISKS;

        case MarginOEControl.KEY_FILL_PER_LOT:
        case MarginOEControl.KEY_ORDER_PER_LOT:
        case MarginOEControl.KEY_FILL_VOLUME:
        case MarginOEControl.KEY_FILL_VOLUME_WITH_MIN_PD:
        case MarginOEControl.KEY_PER_FILL:
        case MarginOEControl.KEY_PER_TRANSACTION:
        case MarginOEControl.KEY_PER_PHONE_TRANSACTION:
        case MarginOEControl.KEY_VAT:
        case MarginOEControl.KEY_ORDER_VOLUME:
        case MarginOEControl.KEY_SHORT_SWAP_INFO:
        case MarginOEControl.KEY_LONG_SWAP:
        case MarginOEControl.KEY_SHORT_SWAP:
        case MarginOEControl.KEY_TOTAL_FEES:
            return MarginOEControl.KEY_GROUP_FEES;

        default: // for custom available funds #106952
            return MarginOEControl.KEY_GROUP_RISKS;
        }
    }

    public GetAllowedCommissionTypes (instrument?: Instrument): Record<CommissionTypes, boolean> {
        const ins = instrument ?? this.get<Instrument>('instrument');
        const result: Record<number, boolean> = {}; // Record<CommissionTypes, boolean>

        result[CommissionTypes.SwapBuy] = true;
        result[CommissionTypes.SwapSell] = true;

        if (isNullOrUndefined(this.commissionPlan)) {
            return result;
        }

        return Object.assign(result, this.commissionPlan.GetAllowedCommissionTypes(ins));
    }

    public override SetColumnsColouringMode (table: QuickTable): void {
        const up = ThemeManager.CurrentTheme.TableValueUpForeColor;
        const down = ThemeManager.CurrentTheme.TableValueDownForeColor;
        const column = table.columns[1];
        column.ValueUpForeColor = up;
        column.ValueDownForeColor = down;
    }

    public GetPriceByName (paramName: string): number | null {
        const params: DynProperty[] = this.get('orderEditParameterArray');

        if (!isValidArray(params)) return;

        for (let i = 0; i < params.length; i++) {
            const param = params[i];
            if (!isNullOrUndefined(param) && param.name === paramName) {
                return param.value;
            }
        }

        return null;
    }

    public GetLimitPrice (): number {
        const orderType: OrderTypeBase = this.get('orderType');
        const isStop: boolean = !isNullOrUndefined(orderType) && orderType.id() === OrderType.Stop;
        const isTrStop: boolean = !isNullOrUndefined(orderType) && orderType.id() === OrderType.TrailingStop;

        if (isStop) {
            return this.GetStopPrice();
        }

        if (isTrStop) {
            return this.GetTrailingStopPrice();
        }

        return this.GetPriceByName(OrderEditBaseUtils.LIMIT_PRICE_PARAM);
    }

    public GetStopPrice (): number {
        return this.GetPriceByName(OrderEditBaseUtils.STOP_PRICE_PARAM);
    }

    public GetTrailingStopPrice (): number {
        const offsetTrStop = this.GetPriceByName(OrderEditBaseUtils.TRAILING_STOP_PARAM);
        const ins: Instrument = this.get('instrument');
        const isBuy: boolean = this.get<OperationType>('operation') === OperationType.Buy;

        const lastQuote = !isNullOrUndefined(ins) ? ins.GetLastQuote(QuoteValid.Last) : null;

        if (isNullOrUndefined(lastQuote)) {
            return offsetTrStop;
        }

        const price = isBuy ? lastQuote.Ask : lastQuote.Bid;
        const sign = isBuy ? 1 : -1;

        const rawTicks = OrderUtils.toRawTicks(offsetTrStop, GeneralSettings.TradingDefaults.ShowOffsetIn, ins);

        return OrderUtils.ConvertTickOffset(ins, null, price, rawTicks * sign);
    }

    public GetDynamicItems (): any[] {
        return [];
    }

    public override getClientPanel (): HTMLElement {
        return this.find('div');
    }

    public override repopulate (force: boolean = false): void {
        super.repopulate();

        this.InitializeDataProvider(force);
    }

    public GetDynPropertyControlTypeForKey (key: string): number {
        if (!isValidString(this.GetBuyingPowerName())) {
            return DynProperty.STRING;
        }

        switch (key) {
        case MarginOEControl.KEY_AFTER_TRADE_FUNDS:
            return DynProperty.INFO_PICTURE_RIGHT_AND_TEXT;
        default:
            return DynProperty.STRING;
        }
    }

    public override SetEditingInfo (row: QuickTableRow<NewVerticalPanelTableItem>, controlType: number): void {
        super.SetEditingInfo(row, controlType);

        if (controlType !== DynProperty.STRING) {
            const cells = row.cells;
            const cellLabel = cells[0];
            const cellValue = cells[1];

            cellLabel.ReadOnly = false;
            cellLabel.QuickTableEditingInfo = new QuickTableEditingInfo(controlType);
            cellLabel.QuickTableEditingInfo.GetDataHandler = this.GetBuyingPowerName.bind(this);

            cellValue.ReadOnly = true;
            cellValue.QuickTableEditingInfo = new QuickTableEditingInfo(DynProperty.STRING);
        }
    }

    public GetBuyingPowerName (): string // #106952
    {
        const msg = this.lastReceivedResponseMessage;
        if (!isValidString(msg?.BuyingPowerName)) {
            return null;
        }

        return msg.BuyingPowerName;
    }

    public onPaintedPictureButtonClick (data, event): void {
    // if (data.controlType === DynProperty.INFO_PICTURE_RIGHT_AND_TEXT)
    // {
    //     let text = this.GetBuyingPowerName()
    //     RactiveTooltip.showTooltip();
    //     return
    // }
    }

    public DisposeSource (): void {
    /// на смену Source отписка и пр.
    }

    // Refresh Button Region

    public InitRefreshBtnTimer (): void {
        if (!isNullOrUndefined(this.refreshIntervalHandler)) {
            clearInterval(this.refreshIntervalHandler);

            this.refreshIntervalHandler = null;
        }

        this.refreshIntervalHandler = setInterval(this.RefreshBtnIntervalTick.bind(this), this.refreshTickTime);
    }

    public RefreshBtnIntervalTick (): void {
        if (this.refreshTick * this.refreshTickTime > this.refreshCycleFullTime) {
            this.RefreshByMarginRequest();
        }

        const tick = (this.refreshTick++) * this.refreshTickTime;

        const deg = (tick / this.refreshCycleFullTime * 360); // % 360

        this.drawTimerSector(deg);
    }

    public drawTimerSector (deg: number): void {
        const opts = { // sector
            cx: 8, // <-- center x
            cy: 8, // <-- center y
            radius: 8, // <-- circle radius
            start_angle: 0, // <-- start angle in degrees
            end_angle: deg // <-- end angle in degrees
        };

        const path = ControlsUtils.createSVGPathSector(opts);

        void this.set('refreshCountdownSvgPath', path);
    }
}

VerticalApplicationPanelNew.extendWith(MarginOEControl, {
    data: function () {
        return {
            account: null,
            instrument: null,
            operation: null,
            orderType: null,
            tif: null,
            price: null,
            stopPrice: null,
            amount: null,
            productType: null,
            leverageValue: null,
            dockablePanel: false,
            orderEditParameterArray: null,
            refreshBtnTooltip: '',
            refreshCountdownSvgPath: ''
        };
    },
    template: MarginOEControlTemplate
});
