// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraBaseWindowTemplate, TerceraRenameScreenTemplate, TerceraRenameScreenFooterTemplate } from '../../templates.js';
import { TerceraMessageBox, MessageBoxType } from './TerceraMessageBox';
import { TerceraWindowBase } from './TerceraWindowBase';

export class TerceraRenameScreen extends TerceraWindowBase {
    public callBack: any;

    constructor () { super(); }

    public override oncomplete (): void {
        super.oncomplete();

        void this.set({
            okBtnText: Resources.getResource('screen.renameScreen.ok'),
            cancelBtnText: Resources.getResource('screen.renameScreen.cancel')
        });

        this.Controls.textBox.setFocus();
    }

    public onOKBtnClicked (): void {
        const newName = this.get('currentName');
        if (!newName) {
            TerceraMessageBox.Show(
                Resources.getResource('screen.error.title'),
                Resources.getResource('screen.renameScreen.illegalName'),
                MessageBoxType.Info,
                null, null, false, true, '');
        } else if (this.callBack) {
            this.callBack(newName);
            this.close();
        }
    }

    public onCanceBtnlClick (): void {
        this.close();
    }

    public static show (currentName, callBack, header): void {
        const scr = new TerceraRenameScreen();
        void scr.set('header', header);
        void scr.set('currentName', currentName);
        scr.callBack = callBack;
        MainWindowManager.MainWindow.addControl(scr);
        scr.center();
    }
}

TerceraWindowBase.extendWith(TerceraRenameScreen, {
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            resizable: false,
            zIndex: 2000,
            width: 240,
            height: 112,
            currentName: '',
            style_addition_body: 'tercera-rename-innerContainer'
        };
    },
    partials: {
        bodyPartial: TerceraRenameScreenTemplate,
        footerPartial: TerceraRenameScreenFooterTemplate
    }
});
