// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { EventSource } from '../../Commons/cache/EventConstants';
import { type TooltipType } from '../../Utils/ReportMessageTooltipTypeEnum';

export class TicketEntry {
    caption: string = '';
    text: string = '';

    constructor (caption: string, text: string) {
        this.caption = caption;
        this.text = text;
    }
}

export class TicketData {
    screenHeader: string = '';
    text: string = '';
    header: string = '';
    sortedEntries: TicketEntry[] = new Array<TicketEntry>();
    isTrade: boolean = false;
    eventSource: EventSource = EventSource.None;
    soundKey: string = '';
    tooltipDrawType: TooltipType;
    dealTicketTooltip: string;
    showNextTime: boolean;
}
