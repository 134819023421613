import { type ColorStyleWidth } from '../../Commons/DynProperty';
import { Point, Rectangle } from '../../Commons/Geometry';
import { Pen, SolidBrush } from '../../Commons/Graphics';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { XYSeriesData, type TerceraChartXYSeries } from '../Series/TerceraChartXYSeries';
import { type TerceraChartBase } from '../TerceraChartBase';
import { DrawPointer, DrawPointerTypeEnum } from '../Utils/DrawPointer';
import { type TerceraChartAdvancedParams } from '../Utils/TerceraChartAdvancedParams';
import { type TerceraChartMouseEventArgs } from '../Utils/TerceraChartMouseEventArgs';
import { type TerceraChartWindow } from '../Windows/TerceraChartWindow';
import { TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { type ISeriesRenderer } from './Utils/ISeriesRenderer';

export class TerceraChartStyledLineRenderer extends TerceraChartBaseRenderer implements ISeriesRenderer<TerceraChartXYSeries> {
    private _hoveredX: number;
    private _selectedColor: string;

    public ColorStyleWidth: ColorStyleWidth;

    public Series: TerceraChartXYSeries;
    public LineStyle: LineStyle = LineStyle.Curve;
    public isDrawPoints: boolean = false;
    public isSelected: boolean = false;

    constructor (chart: TerceraChartBase) {
        super(chart);
        this.UseInAutoscale = true;
        this.ThemeChanged();
    }

    public override ThemeChanged (): void {
        super.ThemeChanged();
        this._selectedColor = ThemeManager.CurrentTheme.Chart_StyledLineRenderer_SelectedColor;
    }

    public override Draw (gr: CanvasRenderingContext2D, window: TerceraChartWindow, windowsContainer: any, advParams: TerceraChartAdvancedParams): void {
        if (!this.Visible) {
            return;
        }

        if (isNullOrUndefined(this.ColorStyleWidth)) {
            return;
        }

        if (isNullOrUndefined(this.Series) || this.Series.Count() === 0) {
            return;
        }

        const points = this.getPoints(window);
        if (points.length === 0) {
            return;
        }
        gr.save();
        const clientRect = window.ClientRectangle;
        const rect = new Path2D();
        rect.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
        gr.clip(rect);
        const color = this.isSelected ? this._selectedColor : this.ColorStyleWidth.Color;
        const pen = new Pen(color, this.ColorStyleWidth.Width);
        Pen.ProcessPen(pen, this.ColorStyleWidth.Style);
        switch (this.LineStyle) {
        case LineStyle.Curve:
            gr.DrawCurve(pen, points);
            break;
        case LineStyle.Line:
            gr.DrawLines(pen, points);
            break;
        case LineStyle.LineDots:
            gr.DrawLines(pen, points);
            this.DrawDots(gr, points, advParams);
            break;
        }
        gr.restore();
    }

    private DrawDots (gr: CanvasRenderingContext2D, points: Point[], advParams: TerceraChartAdvancedParams): void {
        const color = this.isSelected ? this._selectedColor : this.ColorStyleWidth.Color;
        const brush = new SolidBrush(color);
        let closestPoint: Point;
        let closestDistance: number = Number.MAX_VALUE;
        for (let i = 0; i < points.length; i++) {
            const point = points[i];
            gr.FillEllipse(brush, point.X - 2, point.Y - 2, 4, 4);
            if (isValidNumber(this._hoveredX)) {
                const distance = Math.abs(this._hoveredX - point.X);
                if (distance < closestDistance) {
                    closestDistance = distance;
                    closestPoint = point;
                }
            }
        }
        if (!isNullOrUndefined(closestPoint)) {
            gr.FillEllipse(brush, closestPoint.X - 4, closestPoint.Y - 4, 8, 8);
            const mainWindow = advParams?.TerceraChart?.mainWindow;
            if (!isNullOrUndefined(mainWindow) && !isNullOrUndefined(mainWindow.rightYScaleRenderer)) {
                const dataY = mainWindow.PointsConverter.GetDataY(closestPoint.Y);
                advParams.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.LowPriority, dataY, brush, mainWindow.rightYScaleRenderer.FormatValue(dataY)));
            }
        }
    }

    public override ProcessMouseMove (e: TerceraChartMouseEventArgs): boolean {
        this._hoveredX = e.Location.X;
        e.NeedRedraw = true;
        return super.ProcessMouseMove(e);
    }

    public override FindMinMax (minMax: { tMin: number, tMax: number }, window: TerceraChartWindow): boolean {
        if (isNullOrUndefined(this.Series) || this.Series.Count() === 0) {
            return super.FindMinMax(minMax, window);
        }
        let min = Number.MAX_VALUE;
        let max = -Number.MAX_VALUE;
        for (let i = 0; i < this.Series.Count(); i++) {
            const y = this.Series.GetValue(i, XYSeriesData.Y_INDEX);
            min = Math.min(min, y);
            max = Math.max(max, y);
        }
        minMax.tMin = min;
        minMax.tMax = max;
        return true;
    }

    protected getPoints (window: TerceraChartWindow): Point[] {
        const clientRectangle = window.ClientRectangle;
        const maxDelta = 10000;
        const maxRect = new Rectangle(clientRectangle.X - maxDelta, clientRectangle.Y - maxDelta, clientRectangle.Width + 2 * maxDelta, clientRectangle.Height + 2 * maxDelta);

        const points: Point[] = [];
        for (let i = 0; i < this.Series.Count(); i++) {
            const x = this.Series.GetValue(i, XYSeriesData.X_INDEX);
            const y = this.Series.GetValue(i, XYSeriesData.Y_INDEX);
            const point = new Point(window.PointsConverter.GetScreenX(x), window.PointsConverter.GetScreenY(y));
            if (maxRect.Contains(point.X, point.Y)) {
                points.push(point);
            }
        }

        return points;
    }
}

export enum LineStyle {
    Curve,
    Line,
    LineDots
}
