// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectInstrumentDayBarMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { PfixQuoteBarGroup } from '../Groups/GroupsImport';
import { HistoryTypeConverter } from '../../../../Utils/History/HistoryTypeConverter';

export class InstrumentDayBarMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectInstrumentDayBarMessage[] {
        const msg = new DirectInstrumentDayBarMessage();
        let hasGroup = false;
        // alexb: оптимизация - заполняем за один проход. Распологать по частоте.
        const fArr = fieldSet.fieldSet;
        const len = fieldSet.Count();
        for (let i = 0; i < len; i++) {
            const f = fArr[i];
            hasGroup = this.processField(msg, f) || hasGroup;
        }

        if (hasGroup) {
        // #46585 - эта группа оставлена только для совместимости со старыми версиями,
        // ее не должны будут присылать, будут присылать отдельные мессаджи
            const groups = fieldSet.GetGroups(FieldsFactory.QUOTE_BAR_GROUP, PfixQuoteBarGroup);
            if (groups.length > 0) {
                const group = groups[0];
                msg.AskOpen = group.GetValue(FieldsFactory.FIELD_OPEN);
                msg.AskHigh = group.GetValue(FieldsFactory.FIELD_HIGH);
                msg.AskLow = group.GetValue(FieldsFactory.FIELD_LOW);
                msg.AskPreviousClosePrice = group.GetValue(FieldsFactory.FIELD_CLOSE_PRICE);
                msg.AskTodayClosePrice = group.GetValue(FieldsFactory.FIELD_MAIN_CLOSE_PRICE);
            }
        }

        return [msg];
    }

    private processField (msg: DirectInstrumentDayBarMessage, fixField): boolean {
        const fieldId = fixField.FieldId;
        const fieldVal = fixField.Value;

        if (fieldId === 0) {
            return false;
        }
        if (fieldId === FieldsFactory.FIELD_ROUTE_ID) {
            msg.Route = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_OPEN) {
            msg.Open = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_HIGH) {
            msg.High = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_LOW) {
            msg.Low = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_MAIN_CLOSE_PRICE) {
        // ахтунг! на сервере сказали, что этот филд используется только в старой схеме,
        // когда приходит только один InstrumentDayBarMessage
            msg.TodayClosePrice = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_CLOSE_PRICE) {
            msg.PreviousClosePrice = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_TICKS) {
            msg.Ticks = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_VOLUME) {
            msg.Volume = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_OPEN_INTEREST) {
            msg.OpenInterest = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_SETTLEMENT_PRICE) {
            msg.SettlementPrice = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_PREV_SETTLEMENT_PRICE) {
            msg.PrevSettlementPrice = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_LAST_PRICE) {
            msg.LastPrice = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_LAST_SIZE) {
            msg.LastSize = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_LAST_TRADE_DATE) {
            msg.LastTime = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_FIFTY_TWO_WEEK_HIGH_PRICE) {
            msg.FiftyTwoWeekHighPrice = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_FIFTY_TWO_WEEK_LOW_PRICE) {
            msg.FiftyTwoWeekLowPrice = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_INSTRUMENT_BAR_TYPE) {
        // значения оригинального филда: 0 - bid, 1 - trade, 2 - ask
            msg.InstrumentBarType = HistoryTypeConverter.GetLocalHistoryType(fieldVal);
        } else if (fieldId === FieldsFactory.FIELD_REFERENCE_PRICE) {
            msg.ReferencePrice = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID) {
            msg.InstrumentTradableID = fieldVal;
        } else if (fieldId === FieldsFactory.FIELD_VENUE) {
            msg.LastVenue = fieldVal;
        }

        return fieldId === FieldsFactory.FIELD_GROUP;
    }
}
