import { type QuickTableColumn } from '../QuickTableColumn';

export class QuickTableColumnResizer {
    private readonly column = null;
    constructor (column: QuickTableColumn) {
        this.column = column;
    }

    public increaseWidth (delta: number, minwidth: number, lastWKoef: number): void {
        const newWidth = this.column.width - delta;// minwidth
        this.setWidth(newWidth, minwidth / lastWKoef);
    }

    public setWidth (newWidth: number, minwidth: number): void {
        if (minwidth !== 0) { newWidth = Math.max(newWidth, minwidth); }
        this.column.width = newWidth;

        if (this.column.width < 7) {
            this.column.width = 7;
        }
    }
}
