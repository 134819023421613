// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from '../../Commons/Connection';
import { CustomEvent } from '../../Utils/CustomEvents';
import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraBaseWindowTemplate, PropertySetupScreenTemplate, PropertySetupScreenFooterTemplate } from '../../templates.js';
import { ChartWindow } from '../elements/ChartWindow';
import { PairColorPicker } from '../elements/PairColorPicker';
import { TrioColorPicker } from '../elements/TrioColorPicker';
import { TrioColorGradient } from '../elements/TrioColorGradient';
import { TerceraBoolNumeric } from '../elements/TerceraBoolNumeric';
import { TerceraButton } from '../elements/TerceraButton';
import { TerceraButtonColorPicker } from '../elements/TerceraButtonColorPicker';
import { TerceraCheckBox, CheckBoxEvents } from '../elements/TerceraCheckBox';
import { TerceraCheckBoxEvent } from '../elements/TerceraCheckBoxEvent';
import { TerceraColorStyleWidthPicker } from '../elements/TerceraColorStyleWidthPicker';
import { TerceraComboBox, TerceraComboBoxEvents } from '../elements/TerceraComboBox';
import { TerceraFontPicker } from '../elements/TerceraFontPicker';
import { TerceraImageUploader } from '../elements/TerceraImageUploader';
import { TerceraLabel } from '../elements/TerceraLabel';
import { TerceraLineStyleComboBox } from '../elements/TerceraLineStyleComboBox';
import { TerceraNumeric, TerceraNumericEvents } from '../elements/TerceraNumeric';
import { TerceraPropertyGroupSeparator } from '../elements/TerceraPropertyGroupSeparator';
import { TerceraRadioComboItemGroup } from '../elements/TerceraRadioComboItemGroup';
import { TerceraRangeControl } from '../elements/TerceraRangeControl';
import { TerceraSeparator } from '../elements/TerceraSeparator';
import { TerceraTextBox, TextBoxEvents } from '../elements/TerceraTextBox';
import { TerceraTIFComboBox } from '../elements/TerceraTIFComboBox';
import { TerceraWidthComboBox } from '../elements/TerceraWidthComboBox';
import { CreateAlertNotificationSelector } from '../trading/alerts/CreateAlertNotificationSelector';
import { TerceraMessageBox, MessageBoxType } from './TerceraMessageBox';
import { TerceraWindowBase } from './TerceraWindowBase';
import { TypesManagerScreen } from './TypesManagerScreen';
import { HtmlScroll } from '../../Commons/HtmlScroll';
import { TerceraGroupPanel } from '../elements/TerceraGroupPanel';
import { DynProperty } from '../../Commons/DynProperty';
import { SoundManager } from '../../Utils/SoundManager';
import { DataCache } from '../../Commons/DataCache';
import { InsDefSettings } from '../../Commons/cache/InstrumentDefaults';
import { TerceraLookupEvents } from '../elements/Lookup/TerceraLookup';
import { AccountSelector } from '../elements/AccountSelector';
import { TerceraInstrumentLookup } from '../elements/Lookup/TerceraInstrumentLookup';
import { TerceraNumericWithRefreshButton } from '../elements/TerceraNumericWithRefreshButton';
import { TerceraButtonEvents } from '../../Utils/Enums/ButtonEnums';
import { DynPropertyGroupComparer } from './DynPropertyGroupComparer';

export class PropertySetupScreen extends TerceraWindowBase {
    public lastSelectedGroup: any = null;
    public visibleControls: any[] = [];
    public sortedProperties: any = null;
    public loggingAllowed: any = null;
    public propertiesChanged: boolean = false;
    public arrayProperties: any[];
    public Tag: any;
    public onClosedCallback: any;

    constructor () { super(); }

    public override getType (): string { return 'PropertySetupScreen'; }

    public override oncomplete (): void {
        super.oncomplete();
        HtmlScroll.addScroll(this.find('.js-right-panel'));

        Connection.onConnectStateChange.Subscribe(this.onConnectionStateChange, this);

        this.localize();
        this.center();
        this.loggingAllowed = DataCache.AllowLoggingChanges();
    }

    public override localize (): void {
        void this.set({
            okBtnText: Resources.getResource('screen.properties.ok'),
            okBtnTooltip: Resources.getResource('screen.properties.ok.ToolTip'),
            cancelBtnText: Resources.getResource('screen.properties.cancel'),
            cancelBtnTooltip: Resources.getResource('screen.properties.cancel.ToolTip')
        });
    }

    public PopulateProperties (properties): void {
        try {
            if (properties === null) {
                return;
            }

            // Клонируем свойтсва
            this.arrayProperties = DynProperty.CloneDynProperties(properties);
            this.arrayProperties.sort(DynPropertyGroupComparer.Compare);
            // Заполняем дерево
            this.FillTree();
            // Обновляем связи для всех проперти
            for (let i = 0, max = this.arrayProperties.length; i < max; i++) {
                const p = this.arrayProperties[i];

                if (p.assignedProperty) {
                    DynProperty.UpdatePropertyRelation(this.arrayProperties, p);
                }
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public FillTree (): void {
        const properties = this.arrayProperties;
        let len = properties.length;

        const groups = {};

        for (let i = 0; i < len; i++) {
        // sorting
            const curProp = properties[i];
            let curPropGroup = curProp.group;
            if (curPropGroup === '') {
                curPropGroup = DynProperty.PARAM_GROUP;
            }

            if (groups[curPropGroup] !== undefined) {
                groups[curPropGroup].push(curProp);
            } else {
                groups[curPropGroup] = [];
                groups[curPropGroup].push(curProp);
            }
        }

        const groupNames = Object.keys(groups);
        // Removes 'hidden' group from visible groups.
        const hiddenGr = groupNames.indexOf(DynProperty.HIDDEN_GROUP);
        if (hiddenGr !== -1) {
            groupNames.splice(hiddenGr, 1);
        }

        len = groupNames.length;

        const evt = new CustomEvent();
        evt.Subscribe(this.switchBtnClick, this);

        for (let i = 0; i < len; i++) {
            const groupName = groupNames[i];
            if (groupName === DynProperty.HIDDEN_GROUP) {
                continue;
            }

            const groupLocStrings = DynProperty.getLocalizedGroupStrings(groupName);

            if (Resources.isHidden(groupLocStrings.name)) {
                continue;
            }

            if (groupName === DynProperty.SEPARATOR_GROUP1 || groupName === DynProperty.SEPARATOR_GROUP2) {
                const separator = new TerceraSeparator();
                void separator.set({ additionalClass: 'separator-leftpanel' });
                this.Controls.leftPanel.addControl(separator);
                continue;
            }

            const btn = new TerceraButton();
            void btn.set({
                text: groupLocStrings.name,
                tooltip: groupLocStrings.tooltip,
                terceraButtonStyle: 'js-settings-switch',
                canCheck: true,
                name: groupName
            });

            btn.onBtnClick = evt;
            btn.btnInGroup = true;

            this.Controls.leftPanel.addControl(btn);
        };

        this.sortedProperties = groups;
        this.selectGroup(groupNames[0]);
        this.initLastBtn(groupNames[0]);

    // Hides left panel.
    // this.set('oneGroup', groupNames.length === 1);
    }

    // Основной метод: заполнение правой панели отфильтрованными свойствами
    public FillGrid (properties): void {
    // clear
        for (let i = 0; i < this.visibleControls.length; i++) {
            this.visibleControls[i].dispose();
        }
        this.visibleControls = [];

        const usedSeparatorsDict = {};

        const isConnectionCorrect = Connection.isConnectionCorrect();
        const notCorrectConnection_TT = Resources.getResource('dynProperty.inactive.tooltip');

        let gr;
        let label;
        let ractiveControl;
        for (let max = properties.length, i = 0; i < max; i += 1) {
            const prop = properties[i];

            if (!prop.visible || Resources.isHidden(prop.localizationKey)) continue;

            gr = null;
            label = null;
            ractiveControl = null;

            // Adding separator groups.
            const separatorGroup = prop.separatorGroup.replace(/#.*#/, '');
            if (separatorGroup && !usedSeparatorsDict[separatorGroup]) {
                usedSeparatorsDict[separatorGroup] = true;
                const separator = new TerceraPropertyGroupSeparator();
                void separator.set({ text: separatorGroup });
                this.Controls.rightPanel.addControl(separator);
                this.visibleControls.push(separator);
            }

            switch (prop.type) {
            case DynProperty.SEPARATOR:{
                const separator = new TerceraSeparator();
                this.Controls.rightPanel.addControl(separator);
                this.visibleControls.push(separator);
                continue;
            }

            case DynProperty.GROUP_SEPARATOR:{
                const separator = new TerceraPropertyGroupSeparator();
                void separator.set({ text: prop.GetLocalizedName() });
                this.Controls.rightPanel.addControl(separator);
                this.visibleControls.push(separator);
                continue;
            }

            case DynProperty.BUTTON:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraButton();
                ractiveControl.set({
                    terceraButtonStyle: 'js-toolbarbutton-gray21',
                    text: Resources.getResource(prop.value.locKey)
                });
                ractiveControl.on(TerceraButtonEvents.onClick, prop.value.callback);
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.INSTRUMENTS_DEFAULTS:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: Resources.getResource('TradeDefaultManager.Types manager')
                });
                gr.addControl(label);

                ractiveControl = new TerceraButton();
                ractiveControl.set({
                    terceraButtonStyle: 'js-button-gray25',
                    text: Resources.getResource('TradeDefaultManager.Set defaults')
                });
                ractiveControl.on(TerceraButtonEvents.onClick, PropertySetupScreen.onInstrumentDefaultsButtonClick);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.STRING:
            case DynProperty.PASSWORD:{
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraTextBox();
                ractiveControl.set({
                    text: prop.value,
                    inputType: 'password'
                });
                ractiveControl.on(TextBoxEvents.TextChanged, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;
            }

            case DynProperty.BOOLEAN:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                ractiveControl = new TerceraCheckBox();
                ractiveControl.set({
                    text: prop.GetLocalizedName(),
                    checked: prop.value,
                    enabled: prop.enabled
                });
                ractiveControl.on(CheckBoxEvents.CheckedStateChange, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.BOOLEAN_EVT:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                ractiveControl = new TerceraCheckBoxEvent();
                ractiveControl.set({
                    text: prop.GetLocalizedName(),
                    checked: prop.value,
                    enabled: prop.enabled
                });
                ractiveControl.on(CheckBoxEvents.CheckedStateChange, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                ractiveControl.BeforeChangeEvent = prop.BeforeChangeEvent;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.RADIOBUTTON_COMBOITEM:
                label = null;
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                ractiveControl = new TerceraRadioComboItemGroup();
                ractiveControl.set({
                    isHorisontal: true,
                    spaceBetweenItems: true
                });
                ractiveControl.setItems(prop.value, prop.objectVariants);
                ractiveControl.SelectedValueChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.RADIOBUTTON_COMBOITEM_WITH_LABEL:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: Resources.getResource(prop.localizationKey)
                });
                gr.addControl(label);

                ractiveControl = new TerceraRadioComboItemGroup();
                ractiveControl.set({
                    isHorisontal: true,
                    spaceBetweenItems: true
                });
                ractiveControl.setItems(prop.value, prop.objectVariants);
                ractiveControl.SelectedValueChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.BOOL_NUMERIC:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = null;
                ractiveControl = new TerceraBoolNumeric();
                ractiveControl.set({
                    boolNumericData: prop.value,
                    labelText: prop.GetLocalizedName()
                });
                ractiveControl.BoolNumericDataChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.INTEGER:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraNumeric();
                ractiveControl.set({
                    minValue: prop.minimalValue,
                    maxValue: prop.maximalValue,
                    step: 1,
                    decimalPrecision: 0
                });
                ractiveControl.set({ value: prop.value });
                ractiveControl.on(TerceraNumericEvents.ValueChanged, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.DOUBLE:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraNumeric();
                ractiveControl.set({
                    minValue: prop.minimalValue,
                    maxValue: prop.maximalValue,
                    step: prop.increment,
                    decimalPrecision: prop.decimalPlaces
                });
                ractiveControl.set({ value: prop.value });
                ractiveControl.on(TerceraNumericEvents.ValueChanged, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.COMBOBOX_COMBOITEM:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraComboBox();
                ractiveControl.set({
                    text: prop.GetLocalizedName(),
                    items: prop.objectVariants
                });
                ractiveControl.Tag = prop;
                ractiveControl.setItembyValue(prop.value);
                ractiveControl.on(TerceraComboBoxEvents.ComboItemClicked, this.private_ControlItemChanged.bind(this));
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.COMBOBOX_COMBOITEM_TIF:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraTIFComboBox();
                ractiveControl.set({
                    text: prop.GetLocalizedName(),
                    items: prop.objectVariants
                });
                ractiveControl.Tag = prop;
                ractiveControl.setItembyValue(prop.value.tif.value);
                ractiveControl.set({ gtdDate: prop.value.date });
                ractiveControl.on(TerceraComboBoxEvents.ValueChange, this.private_ControlValueChanged.bind(this));
                // ractiveControl.dateChanged.Subscribe(this.private_ControlValueChanged, this);
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.COMBOBOX:
            case DynProperty.CSSTYLE:
            case DynProperty.LINEWIDTH:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                if (DynProperty.LINEWIDTH === prop.type) { ractiveControl = new TerceraWidthComboBox(); } else if (DynProperty.CSSTYLE === prop.type) { ractiveControl = new TerceraLineStyleComboBox(); } else {
                    ractiveControl = new TerceraComboBox();
                    ractiveControl.set('items', prop.objectVariants);
                }
                ractiveControl.set({
                    text: prop.GetLocalizedName()
                });
                ractiveControl.Tag = prop;
                ractiveControl.setItembyValue(prop.value);
                ractiveControl.on(TerceraComboBoxEvents.ComboItemClicked, this.private_ControlItemChanged.bind(this));
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.ALERT_NOTIFICATION_SELECTOR:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                ractiveControl = new CreateAlertNotificationSelector();
                ractiveControl.set({ selectedValue: prop.value });
                ractiveControl.Tag = prop;
                ractiveControl.ValueChanged.Subscribe(this.private_ControlValueChanged);
                gr.addControl(ractiveControl);
                break;

            case DynProperty.BOOLEAN_SOUND:{
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                ractiveControl = new TerceraCheckBox();
                ractiveControl.set({
                    checked: prop.value,
                    enabled: prop.enabled
                });
                ractiveControl.on(CheckBoxEvents.CheckedStateChange, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);

                const sound = new TerceraButton();
                void sound.set({
                    terceraButtonStyle: 'js-sound-test',
                    enabled: prop.enabled
                });
                sound.on(TerceraButtonEvents.onClick, this.private_OnSoundPlay);
                sound.Tag = prop;
                gr.addControl(sound);
                this.visibleControls.push(sound);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);
                // #51504
                ractiveControl.observe(
                    'enabled',
                    this.onBooleanSoundControlEnabledChanged,
                    { context: sound });
                break;
            }

            case DynProperty.INSTRUMENT:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraInstrumentLookup();
                ractiveControl.set({
                    mayContainTrees: true
                });
                ractiveControl.Tag = prop;
                ractiveControl.selectItem(prop.value);
                ractiveControl.on(TerceraLookupEvents.SelectedItemChanged, this.private_InstrumentValueChanged);
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.ACCOUNT:
                if (DataCache.EnableForceLinkingByAccount()) {
                    continue;
                }

                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new AccountSelector();
                ractiveControl.Tag = prop;
                ractiveControl.on(TerceraLookupEvents.SelectedItemChanged, this.private_AccountValueChanged);
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.COLOR:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraButtonColorPicker();
                ractiveControl.set({
                    coloringButtonColor: prop.value
                });
                ractiveControl.ColorChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.PAIR_COLOR:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new PairColorPicker();
                ractiveControl.set({
                    pairColor: prop.value
                });
                ractiveControl.PairColorChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.TRIO_COLOR:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TrioColorPicker();
                ractiveControl.set({
                    trioColor: prop.value
                });
                ractiveControl.TrioColorChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.TRIO_COLOR_GRADIENT:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TrioColorGradient();
                ractiveControl.set({
                    value: prop.value
                });
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.COLOR_STYLE_WIDTH:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = null;
                ractiveControl = new TerceraColorStyleWidthPicker();
                ractiveControl.set({
                    styleItems: prop.objectVariants || [],
                    useStyleProperties: prop.objectVariants ? !!prop.objectVariants.useStyleProperties : false,
                    colorStyleWidth: prop.value,
                    labelText: prop.GetLocalizedName()
                });
                ractiveControl.ColorStyleWidthChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.FONT:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraFontPicker();
                ractiveControl.set({
                    font: prop.value
                });
                ractiveControl.FontChanged.Subscribe(this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl);
                break;

            case DynProperty.CHART_WINDOW:{
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new ChartWindow();
                ractiveControl.SetMinimum(prop.minimalValue);
                ractiveControl.SetMaximum(prop.maximalValue);
                ractiveControl.SetValue(prop.value);
                ractiveControl.ValueChanged.Subscribe(this.private_ControlValueChanged, this);
                const setValue = ractiveControl.GetValue();
                if (prop.value !== setValue) {
                    ractiveControl.SetValue(setValue);
                }

                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;
            }

            case DynProperty.IMAGE_WEB:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraImageUploader();
                ractiveControl.set({
                    defaultImageBase64: prop.value
                });
                ractiveControl.CurrentImageBase64Changed.Subscribe(this.private_ControlValueChanged, this);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            case DynProperty.RANGECONTROL:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraRangeControl();
                ractiveControl.Tag = prop;
                ractiveControl.setInitValue(prop.value);
                ractiveControl.RangeValueChanged.Subscribe(this.private_ControlValueChanged, this);
                gr.addControl(ractiveControl, true);
                break;
            case DynProperty.DOUBLE_WITH_REFRESH_BUTTON:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);

                ractiveControl = new TerceraNumericWithRefreshButton();
                ractiveControl.set({
                    minValue: prop.minimalValue,
                    maxValue: prop.maximalValue,
                    step: prop.increment,
                    decimalPrecision: prop.decimalPlaces,
                    refreshCallback: prop.callback
                });
                ractiveControl.set({ value: prop.value });
                ractiveControl.on(TerceraNumericWithRefreshButton.Events.ValueChanged, this.private_ControlValueChanged);
                ractiveControl.Tag = prop;
                gr.addControl(ractiveControl, true);
                break;

            default:
                gr = new TerceraGroupPanel();
                this.Controls.rightPanel.addControl(gr);

                label = new TerceraLabel();
                label.set({
                    text: prop.GetLocalizedName()
                });
                gr.addControl(label);
                this.visibleControls.push(label);

                continue;
            }

            // update property relation
            if (ractiveControl) {
                ractiveControl.ownerPanel = this;
            }

            if (label) {
                this.visibleControls.push(label);
            }
            if (gr) {
                this.visibleControls.push(gr);
            }
            if (ractiveControl) {
            // TODO. Remove previous enabled assignments.
                ractiveControl.set('enabled', prop.enabled);
                this.visibleControls.push(ractiveControl);
            }
            if (prop.tooltip) {
                ractiveControl.set({ tooltip: prop.tooltip });
            }

            if (!isConnectionCorrect) {
                ractiveControl.set({ tooltip: notCorrectConnection_TT, enabled: false });
            }

            prop.AssignedControl = ractiveControl;
        }
    }

    // #region DynProperty value changed region

    public private_UpdatePropertyRelation (sender, tag): void {
        const scr = sender.ownerPanel;
        scr.propertiesChanged = true;

        if (tag.assignedProperty?.length) {
            DynProperty.UpdatePropertyRelation(scr.arrayProperties, tag);
            scr.selectGroup(scr.lastSelectedGroup.get('name'));
        }
    }

    public private_ControlValueChanged (context, value): void {
        let sender = context.ractive;
        if (isNullOrUndefined(sender)) {
            sender = context;
        }

        const tag = sender.Tag;
        tag.value = value;

        sender.ownerPanel.private_UpdatePropertyRelation(sender, tag);
    }

    public private_ControlItemChanged (context, item): void {
        this.private_ControlValueChanged(context, item.tag);
    }

    public private_AccountValueChanged (context, value): void {
        let sender = context.ractive;
        if (isNullOrUndefined(sender)) {
            sender = context;
        }

        const tag = sender.Tag;
        tag.value = value.AcctNumber;

        sender.ownerPanel.private_UpdatePropertyRelation(sender, tag);
    }

    public private_InstrumentValueChanged (context, value): void {
        let sender = context.ractive;
        if (isNullOrUndefined(sender)) {
            sender = context;
        }

        const tag = sender.Tag;
        tag.value = value.GetInteriorID ? value.GetInteriorID() : value;

        sender.ownerPanel.private_UpdatePropertyRelation(sender, tag);
    }

    public private_OnSoundPlay (): void {
        SoundManager.tryPlaySound(this.Tag.localizationKey, true);
    }

    public onBooleanSoundControlEnabledChanged (newVal): void {
        void this.set('enabled', newVal);
    }

    // #region Instruments Defaults

    public static onInstrumentDefaultsButtonClick (context): void {
        const sender = context.ractive;
        const prop = sender.Tag;
        // For TypesManagerScreen.
        sender.getAllInstrumentsDefaultSettings =
        sender.getAllInstrumentsDefaultSettings ||
        function () {
            const insDefSettings = new InsDefSettings();
            insDefSettings.LoadFromXML(this.Tag.value);
            return insDefSettings;
        };

        sender.typesManagerScreenCallBack =
        sender.typesManagerScreenCallBack ||
        function (settings) {
            if (!settings) {
                return;
            }

            const sender = this;
            const prop = sender.Tag;
            prop.value = settings.ToXML();
            const scr = sender.ownerPanel;
            scr.propertiesChanged = true;
        };

        TypesManagerScreen.show(
            sender,
            null,
            sender.typesManagerScreenCallBack.bind(sender));
    };

    // #endregion

    // #endregion

    // For public use.
    public selectPage (name): void {
        if (this.get('oneGroup')) return;

        if (!name) {
            const lastSelectedBtn = this.lastSelectedGroup;
            if (lastSelectedBtn) {
                this.selectGroup(lastSelectedBtn.get('name'));
            }
            return;
        }

        const leftPanel = this.Controls.leftPanel;
        if (!leftPanel) return;

        const lastSelectedBtn = this.lastSelectedGroup;
        if (lastSelectedBtn) {
            void lastSelectedBtn.set('checked', false);
        }

        const newSelectedBtn = leftPanel.Controls[name];
        if (newSelectedBtn) {
            newSelectedBtn.set('checked', true);
            this.lastSelectedGroup = newSelectedBtn;
            this.selectGroup(name);
        }
    }

    public switchBtnClick (sender, btn): void {
        if (this.lastSelectedGroup && this.lastSelectedGroup !== btn) {
            this.lastSelectedGroup.set({ checked: false });
        }
        this.lastSelectedGroup = btn;

        this.selectGroup(btn.get('name'));
    }

    public selectGroup (name): void {
        this.FillGrid(this.sortedProperties[name]);
    }

    public initLastBtn (name): void {
        const btn = this.Controls.leftPanel.Controls[name];
        if (btn) {
            btn.set({ checked: true });
            this.lastSelectedGroup = btn;
        }
    }

    public setOnClosedCallback (onClosedCallback): void {
        this.onClosedCallback = onClosedCallback;
        this.on('teardown', () => {
            if (this.onClosedCallback) {
                this.onClosedCallback();
            }

            delete this.onClosedCallback;
        });
    }

    public okClick (): void {
        const callback = this.get('callback');
        if (callback) {
            callback(this.arrayProperties, this.propertiesChanged);
        }
        this.close();
    }

    public closeClick (): void {
        if (this.propertiesChanged) {
            const self = this;
            TerceraMessageBox.Show(
                Resources.getResource('screen.properties.title'),
                Resources.getResource('screen.properties.unsavedChanges'),
                MessageBoxType.Question,
                function () { self.close(); });
        } else {
            this.close();
        }
    }

    // Close button (cross icon).
    public override canCloseFromButton (): boolean {
        if (this.propertiesChanged) {
            this.closeClick();
            return false;
        }
        return true;
    }

    public onConnectionStateChange (): void {
        const normal = Connection.isConnectionCorrect();
        const len = this.visibleControls.length;
        const tt = Resources.getResource('dynProperty.inactive.tooltip');
        for (let i = 0; i < len; i++) {
            const control = this.visibleControls[i];
            let mytt = '';
            if (control.tag?.tooltip) {
                mytt = control.tag.tooltip;
            }
            if (control?.set) {
                control.set({
                    enabled: normal,
                    tooltip: normal ? mytt : tt
                });
            }
        }

        if (this.loggingAllowed) {
            void this.set({ enabledBtn: normal }); // #111602
        }
    }

    public override dispose (): void {
        Connection.onConnectStateChange.UnSubscribe(this.onConnectionStateChange, this);
        super.dispose();
    }

    public static editProperty (caller, headerKey, parentPanel?): void {
        if (!caller?.Properties) { return; }

        const propScreen = caller.propertiesScreen;
        if (propScreen) {
            propScreen.set('focused', true);
            return;
        }

        const scr = new PropertySetupScreen();

        MainWindowManager.MainWindow.addControl(scr);
        scr.PopulateProperties(caller.Properties(caller.skipSomeProperties)); // #86776 аргумент true передается для TradingCentral, указывая что нужно получить Properties только активной таблицы
        void scr.set({
            callback: caller.callBack.bind(caller),
            name: scr._guid,
            header: Resources.getResource(headerKey),
            focused: true
        });

        scr.setOnClosedCallback(function () {
            delete caller.propertiesScreen;
        });

        caller.propertiesScreen = scr;
        scr.setParentPanel(parentPanel || caller);
    }
}

TerceraWindowBase.extendWith(PropertySetupScreen, {
    data: function () {
        return {
            properties: [],
            callback: null,
            width: 664,
            height: 487,
            resizable: false,
            style_addition_header: 'js-PropertySetupScreen-AdditionalHeader',
            style_addition_body: 'js-PropertySetupScreen-AdditionalBody',
            style_addition_footer: 'js-PropertySetupScreen-AdditionalFooter'
        };
    },
    template: TerceraBaseWindowTemplate,
    partials: {
        bodyPartial: PropertySetupScreenTemplate,
        footerPartial: PropertySetupScreenFooterTemplate
    }
});
