import { OptionVolatilityLabPanelTemplate } from '../../../templates';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { DynProperty } from '../../../Commons/DynProperty';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { ApplicationPanelWithTable } from '../ApplicationPanelWithTable';
import { type QuickTable } from '../../elements/QuickTable/QuickTable';
import { type QuickTableRactive } from '../../elements/QuickTable/QuickTableRactive';
import { type OptionVolatilityLabChartRactive } from '../../elements/TerceraChartRactive/OptionVolatilityLabChartRactive';
import { Resources } from '../../../Commons/properties/Resources';
import { VolatilityLabSide } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionVolatilityLab/VolatilityLabSide';
import { VolatilityLabBasis } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionVolatilityLab/VolatilityLabBasis';
import { OptionVolatilityLabSeriesTableItem } from '../../cache/OptionMaster/OptionVolatilityLabSeriesTableItem';
import { AnalyzerVolatilityLine } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerVolatilityLine';
import { SessionSettings } from '../../../Commons/SessionSettings';
import { ThemeManager } from '../../misc/ThemeManager';
import { type Option } from '../../../Commons/cache/OptionMaster/OptionTrader/Option';
import { DataCache } from '../../../Commons/DataCache';
import { HistoryType } from '../../../Utils/History/HistoryType';
import { DirectQuoteMessage } from '../../../Utils/DirectMessages/DirectQuoteMessage';

export class OptionVolatilityLabPanel extends ApplicationPanelWithTable<any> {
    private readonly _quotesMap: Map<number, boolean> = new Map<number, boolean>();
    private _isRepopulateChart: boolean = false;
    private _isUpdateChart: boolean = false;
    private _isResetChartScales: boolean = false;
    private _optionTrader: OptionTrader;
    private _seriesTable: QuickTable;
    private _chartRactive: OptionVolatilityLabChartRactive;
    private _seriesLineColors: string[] = [];
    private _subscribedSeriesDates: Date[] = [];

    private get selectedSide (): VolatilityLabSide {
        const selectedSideItem = this.get('selectedSideItem');
        if (isNullOrUndefined(selectedSideItem)) {
            return undefined;
        } else {
            return selectedSideItem.value;
        }
    }

    private set selectedSide (value: VolatilityLabSide) {
        const sideItems = this.get('sideItems');
        for (let i = 0; i < sideItems.length; i++) {
            if (sideItems[i].value === value) {
                void this.set('selectedSideItem', sideItems[i]);
            }
        }
    }

    constructor () {
        super();
        this.Name = 'OptionVolatilityLablPanel';
        this.headerLocaleKey = 'panel.optionVolatilityLab';
        this.NeedCalculateRowCount = false;
        this.repopulate = this.repopulate.bind(this);
        this.onQuoteChanged = this.onQuoteChanged.bind(this);
    }

    // #region Overrides

    public override oncomplete (): void {
        super.oncomplete();
        this.on('expandCollapsSettings_btnClick', this.onExpandCollapseButtonClicked);
        this.observe('visible', this.onVisibleChanged);
        this.observe('selectedSideItem', this.onSelectedSideItemChanged);
    }

    public override onteardown (): void {
        this.off('expandCollapsSettings_btnClick', this.onExpandCollapseButtonClicked);
        this.unsubscribeAllOptions();
        if (!isNullOrUndefined(this._seriesTable)) {
            this._seriesTable.AfterEditItem.UnSubscribe(this.seriesTable_onAfterEditItem, this);
            this._seriesTable.OnSelectionChanged.UnSubscribe(this.seriesTable_onSelectionChanged, this);
        }
        this._optionTrader.unsubscribeOnRepopulate(this.repopulate);
        this._optionTrader.unsubscribeOnQuoteChanged(this.onQuoteChanged);
        super.onteardown();
    }

    public override getType (): PanelNames { return PanelNames.OptionVolatilityLabPanel; }
    public override jbInit (): void {
        this._seriesTable = (super.findAllComponents('quickTableRactive')[0] as QuickTableRactive).quickTable;

        this._chartRactive = super.findAllComponents('optionVolatilityLabChartRactive')[0] as OptionVolatilityLabChartRactive;
        super.jbInit();
        this._seriesTable.showHeader = false;
        this._seriesTable.InitializeDirect(new OptionVolatilityLabSeriesTableItem(null, null, null));
        this._seriesTable.UpdateSortedColumns();
        this._seriesTable.AfterEditItem.Subscribe(this.seriesTable_onAfterEditItem, this);
        this._seriesTable.OnSelectionChanged.Subscribe(this.seriesTable_onSelectionChanged, this);
    }

    public override localize (): void {
        super.localize();
        void this.set({
            settingsLabel: Resources.getResource('general.Settings'),
            sideLabel: Resources.getResource('property.Side'),
            visibleSeriesTableLabel: Resources.getResource('property.VisibleSeries')
        });
        const sideItems = this.get('sideItems');
        for (let i = 0; i < sideItems.length; i++) {
            switch (sideItems[i].value) {
            case VolatilityLabSide.Calls:
                sideItems[i].text = Resources.getResource('property.Calls');
                break;
            case VolatilityLabSide.Puts:
                sideItems[i].text = Resources.getResource('property.Puts');
                break;
            case VolatilityLabSide.CallsAndPuts:
                sideItems[i].text = Resources.getResource('property.CallsAndPuts');
                break;
            }
        }
        this._seriesTable.localize();
        this._chartRactive.terceraChart.Localize();
    }

    public override themeChange (): void {
        super.themeChange();
        this.themeChangeLineColors();
        this._seriesTable.themeChange();
        this._chartRactive.terceraChart.ThemeChanged();
    }

    private themeChangeLineColors (): void {
        this._seriesLineColors = [
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor1,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor2,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor3,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor4,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor5,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor6,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor7,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor8,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor9,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor10,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor11,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor12,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor13,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor14,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor15,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor16,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor17,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor18,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor19,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor20,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor21,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor22,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor23,
            ThemeManager.CurrentTheme.OptionMasterVolatilityLabLineColor24
        ];
        for (let i = 0; i < this._seriesTable.rowsArray.length; i++) {
            const row = this._seriesTable.rowsArray[i];
            const item = row.item as OptionVolatilityLabSeriesTableItem;
            if (!isNullOrUndefined(item) && !isNullOrUndefined(item.seriesLine)) {
                item.seriesLine.color = this._seriesLineColors[i % this._seriesLineColors.length];
            }
        }
    }

    public override Properties (): DynProperty[] {
        const optionVolatilityLab = this._optionTrader.optionVolatilityLab;

        const properties = super.Properties();
        let sortIndex = 0;

        const dp = new DynProperty('property.Basis', optionVolatilityLab.basis, DynProperty.COMBOBOX, DynProperty.VOLATILITY_LAB_GROUP);
        dp.sortIndex = sortIndex++;
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.objectVariants = [
            { text: Resources.getResource('property.StrikePrices'), value: VolatilityLabBasis.StrikePrices },
            { text: Resources.getResource('property.Delta'), value: VolatilityLabBasis.Delta }
        ];
        properties.push(dp);

        return properties;
    }

    public override callBack (newProperties: any): void {
        super.callBack(newProperties);

        const optionVolatilityLab = this._optionTrader.optionVolatilityLab;

        const dp = DynProperty.getPropertyByGroupAndName(newProperties, DynProperty.VOLATILITY_LAB_GROUP, 'property.Basis');
        if (!isNullOrUndefined(dp?.value)) {
            optionVolatilityLab.basis = dp.value as VolatilityLabBasis;
        }
        this._isRepopulateChart = true;
        this._isResetChartScales = true;
    }

    public override TickAsync (): void {
        super.TickAsync();
        const isVisible: boolean = this.get<boolean>('visible');
        if (!isVisible) {
            return;
        }
        if (this._isRepopulateChart) {
            this._isRepopulateChart = false;
            this.repopulateChart();
        }
        if (this._isResetChartScales) {
            this._isResetChartScales = false;
            this._chartRactive.terceraChart.resetScales();
        }
        const chart = this._chartRactive.terceraChart;
        if (chart.needRedraw) {
            chart.needRedraw = false;
            chart.Draw();
            chart.AddForceUpdate();
        }
        this.updateTable(this._seriesTable);
    }

    public override layoutTable (): void {
        super.layoutTable();
        this.layoutTableResize(this._chartRactive);
        for (const table of this.findAllComponents('quickTableRactive')) {
            this.layoutTableResize(table);
        }
    }

    public override repopulate (): void {
        super.repopulate();
        const optionTrader = this._optionTrader;
        if (isNullOrUndefined(optionTrader.fakeOption) || isNullOrUndefined(optionTrader.underlier)) {
            return;
        }
        this.unsubscribeAllOptions();
        this._seriesTable.ClearAll();
        const seriesDates = this._optionTrader.seriesDates;
        const seriesRowHeight = this._seriesTable.rowHeight + 2;
        const minSeriesTableHeight = seriesRowHeight;
        const maxSeriesTableHeight = seriesRowHeight * 7;
        const seriesTableHeight = Math.min(maxSeriesTableHeight, Math.max(minSeriesTableHeight, seriesRowHeight * seriesDates.length));
        for (let i = 0; i < seriesDates.length; i++) {
            const seriesDate = seriesDates[i];
            const seriesLine = new AnalyzerVolatilityLine();
            seriesLine.isActive = false;
            seriesLine.color = this._seriesLineColors[i % this._seriesLineColors.length];
            const seriesTableItem = new OptionVolatilityLabSeriesTableItem(seriesDate, seriesLine, SessionSettings);
            this._seriesTable.AddItem(seriesTableItem);
        }
        void this.set('seriesTableHeight', `${seriesTableHeight}px`);

        const side = this._optionTrader.optionVolatilityLab.side;
        const selectedSide = this.selectedSide;
        if (selectedSide !== side) {
            this.selectedSide = side;
        } else {
            this._isRepopulateChart = true;
            this._isResetChartScales = true;
        }
    }

    public updateChart (): void {
        if (this._isUpdateChart) {
            const chart = this._chartRactive.terceraChart;
            chart.update(this._optionTrader.getVolatilityLabChartData(this._subscribedSeriesDates));
            this._isUpdateChart = false;
        }
    }

    private repopulateChart (): void {
        const chart = this._chartRactive.terceraChart;
        chart.set(this._optionTrader.getVolatilityLabChartData(this._subscribedSeriesDates));
        for (let i = 0; i < this._seriesTable.rowsArray.length; i++) {
            const item = this._seriesTable.rowsArray[i].item as OptionVolatilityLabSeriesTableItem;
            if (!isNullOrUndefined(item)) {
                chart.setLineColor(item.expirationDate, item.seriesLine.color);
            }
        }
        this.updateChartLinesSelection();
    }

    private updateChartLinesSelection (): void {
        const selectedRowsId = this._seriesTable.selectedRowIds;
        if (!isValidArray(selectedRowsId)) {
            return;
        }
        const selectedDates: Date[] = [];
        for (let i = 0; i < selectedRowsId.length; i++) {
            const item = this._seriesTable.rows[selectedRowsId[i]].item as OptionVolatilityLabSeriesTableItem;
            selectedDates.push(item.expirationDate);
        }
        this._chartRactive.terceraChart.setSelectedLines(selectedDates);
    }
    // #endregion

    // #region Eventhandlers
    private async onExpandCollapseButtonClicked (): Promise<void> {
        const isExpanded: boolean = this.get('isSettingsExpanded');
        await this.set('isSettingsExpanded', !isExpanded);
        this.layoutTable();
    }

    private onVisibleChanged (): void {
        this.layoutTable();
    }

    private onSelectedSideItemChanged (newValue: any, prevValue: any): void {
        if (newValue === prevValue) {
            return;
        }
        const selectedSide = this.selectedSide;
        if (isNullOrUndefined(selectedSide)) {
            return;
        }
        this._optionTrader.optionVolatilityLab.side = selectedSide;
        this._isRepopulateChart = true;
        this._isResetChartScales = true;
    }

    private seriesTable_onAfterEditItem (data): void {
        const tableItem: OptionVolatilityLabSeriesTableItem = data.row.item;
        const chart = this._chartRactive.terceraChart;
        switch (data.realColumnIndex) {
        case OptionVolatilityLabSeriesTableItem.ACTIVE_COL_INDEX:
            tableItem.seriesLine.isActive = !tableItem.seriesLine.isActive;
            this.subscribeUnsubscribeSeries(tableItem.expirationDate, tableItem.seriesLine.isActive);
            this._isRepopulateChart = true;
            this._isResetChartScales = true;
            break;
        case OptionVolatilityLabSeriesTableItem.COLOR_COL_INDEX:
            tableItem.seriesLine.color = data.newValue;
            chart.setLineColor(tableItem.expirationDate, tableItem.seriesLine.color);
            chart.IsDirty(true);
            break;
        }
        data.row.FillRowByItem(tableItem, true);
    }

    private seriesTable_onSelectionChanged (): void {
        this.updateChartLinesSelection();
    }

    private onQuoteChanged (): void {
        this._isUpdateChart = true;
    }
    // #endregion

    public setOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
        this._optionTrader.subscribeOnRepopulate(this.repopulate);
        this._optionTrader.subscribeOnQuoteChanged(this.onQuoteChanged);
    }

    public applyCursor (cursor: string): void {
        if (!isNullOrUndefined(this._chartRactive)) {
            void this._chartRactive.setCursor(cursor);
        }
    }

    private updateTable (table: QuickTable): void {
        table.updateRowsCellsValues();
        table.Draw();
        table.needRedraw = false;
        table.needRedrawBackground = true;
    }

    private subscribeUnsubscribeSeries (expirationDate: Date, isSubscribe: boolean): void {
        const strikePriceSettings = this._optionTrader.getStrikePriceSettings(expirationDate);
        if (isSubscribe) {
            this._subscribedSeriesDates.push(expirationDate);
            const options = this._optionTrader.getOptions(strikePriceSettings);
            for (let i = 0; i < options.length; i++) {
                this.subscribeOption(options[i]);
            }
        } else {
            const options = this._optionTrader.getOptions(strikePriceSettings);
            for (let i = 0; i < options.length; i++) {
                this.unsubscribeOption(options[i]);
            }
            const index = this._subscribedSeriesDates.indexOf(expirationDate);
            if (index !== -1) {
                this._subscribedSeriesDates.splice(index, 1);
            }
        }
    }

    private subscribeOption (option: Option): void {
        if (option.callEnabled) {
            DataCache.FQuoteCache.addListener(option.call, this, HistoryType.QUOTE_LEVEL1);
            DataCache.FQuoteCache.addListener(option.call, this, HistoryType.QUOTE_TRADES);
            DataCache.FQuoteCache.addListener(option.call, this, HistoryType.QUOTE_INSTRUMENT_DAY_BAR);
        }
        if (option.putEnabled) {
            DataCache.FQuoteCache.addListener(option.put, this, HistoryType.QUOTE_LEVEL1);
            DataCache.FQuoteCache.addListener(option.put, this, HistoryType.QUOTE_TRADES);
            DataCache.FQuoteCache.addListener(option.put, this, HistoryType.QUOTE_INSTRUMENT_DAY_BAR);
        }
    }

    private unsubscribeOption (option: Option): void {
        if (option.callEnabled && !isNullOrUndefined(option.call)) {
            this._quotesMap.delete(option.call.InstrumentTradableID);
            DataCache.FQuoteCache.removeListener(option.call, this, HistoryType.QUOTE_LEVEL1);
            DataCache.FQuoteCache.removeListener(option.call, this, HistoryType.QUOTE_TRADES);
            DataCache.FQuoteCache.removeListener(option.call, this, HistoryType.QUOTE_INSTRUMENT_DAY_BAR);
        }
        if (option.putEnabled && !isNullOrUndefined(option.put)) {
            this._quotesMap.delete(option.put.InstrumentTradableID);
            DataCache.FQuoteCache.removeListener(option.put, this, HistoryType.QUOTE_LEVEL1);
            DataCache.FQuoteCache.removeListener(option.put, this, HistoryType.QUOTE_TRADES);
            DataCache.FQuoteCache.removeListener(option.put, this, HistoryType.QUOTE_INSTRUMENT_DAY_BAR);
        }
    }

    private unsubscribeAllOptions (): void {
        for (let i = 0; i < this._subscribedSeriesDates.length; i++) {
            const expirationDate = this._subscribedSeriesDates[i];
            const strikePriceSettings = this._optionTrader.getStrikePriceSettings(expirationDate);
            const options = this._optionTrader.getOptions(strikePriceSettings);
            for (let j = 0; j < options.length; j++) {
                this.unsubscribeOption(options[j]);
            }
        }
        this._subscribedSeriesDates = [];
        this._quotesMap.clear();
    }

    private newQuote (quote): void {
        if (quote.Type === DirectQuoteMessage.QUOTE_INSTRUMENT_DAY_BAR) {
            this._isRepopulateChart = true;
            this._isResetChartScales = true;
            return;
        }
        const tradableId = quote.InstrumentTradableID;
        if (this._quotesMap.get(tradableId)) {
            this._isUpdateChart = true;
        } else {
            this._quotesMap.set(tradableId, true);
            this._isRepopulateChart = true;
            this._isResetChartScales = true;
        }
    }
}

ApplicationPanelWithTable.extendWith(OptionVolatilityLabPanel, {
    template: OptionVolatilityLabPanelTemplate,
    data: function () {
        return {
            settingsLabel: '',
            sideLabel: '',
            visibleSeriesTableLabel: '',

            isSettingsExpanded: true,

            seriesTableHeight: '25px',

            sideItems: [
                { text: '', value: VolatilityLabSide.Calls },
                { text: '', value: VolatilityLabSide.Puts },
                { text: '', value: VolatilityLabSide.CallsAndPuts }
            ],
            selectedSideItem: undefined
        };
    },
    computed: {
        terceraChartPanelContext: {
            get: function () { return this; },
            set: function (value) { }
        }
    }
});
