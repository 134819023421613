// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MODE_UPPER, MODE_LOWER, type MAMode } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iGator extends iBuildInIndicator {
    public FJawPeriod: any;
    public FTeethPeriod: any;
    public FLipsPeriod: any;

    public FJawShiftPlus: number;
    public FTeethShiftPlus: number;
    public FLipsShiftPlus: number;
    public FJawShiftMinus: number;
    public FTeethShiftMinus: number;
    public FLipsShiftMinus: number;

    public FMaMode: MAMode;
    public FJawMA: ExpandDoubleVector;
    public FTeethMA: ExpandDoubleVector;
    public FLipsMA: ExpandDoubleVector;

    public Line1Offset: number;
    public Line2Offset: number;

    public FJawShift: any;
    public FTeethShift: any;
    public FLipsShift: any;

    constructor (jawPeriod, jawShift,

        teethPeriod, teethShift,
        lipsPeriod, lipsShift,
        maMode: MAMode, priceType) {
        super(3);

        this.FJawPeriod = jawPeriod;
        this.FTeethPeriod = teethPeriod;
        this.FLipsPeriod = lipsPeriod;

        this.FJawShiftPlus = jawShift > 0 ? jawShift : 0;
        this.FTeethShiftPlus = teethShift > 0 ? teethShift : 0;
        this.FLipsShiftPlus = lipsShift > 0 ? lipsShift : 0;
        this.FJawShiftMinus = jawShift < 0 ? -jawShift : 0;
        this.FTeethShiftMinus = teethShift < 0 ? -teethShift : 0;
        this.FLipsShiftMinus = lipsShift < 0 ? -lipsShift : 0;

        this.FMaMode = maMode;
        this.FPriceType = priceType;
        this.FJawMA = new ExpandDoubleVector(0, this.FJawShiftPlus - this.FJawShiftMinus);
        this.FTeethMA = new ExpandDoubleVector(0, this.FTeethShiftPlus - this.FTeethShiftMinus);
        this.FLipsMA = new ExpandDoubleVector(0, this.FLipsShiftPlus - this.FLipsShiftMinus);

        this.Line1Offset = Math.max(this.FJawShiftMinus, this.FTeethShiftMinus);
        this.Line2Offset = Math.max(this.FLipsShiftMinus, this.FTeethShiftMinus);
    }

    override get Name (): string { return 'iGator'; }

    public override Refresh (count, newThread): void {
        this.FJawMA.Dispose();
        this.FJawMA = new ExpandDoubleVector();
        this.FTeethMA.Dispose();
        this.FTeethMA = new ExpandDoubleVector();
        this.FLipsMA.Dispose();
        this.FLipsMA = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }

    override get Key (): string {
        return this.DefaultKey + this.FJawPeriod + this.FJawShift +
                this.FTeethPeriod + this.FTeethShift +
                this.FLipsPeriod + this.FLipsShift +
                this.FMaMode + this.FPriceType;
    }

    public override NextBar (callBound): void {
        super.NextBar(callBound);
        this.FJawMA.Add(0.0);
        this.FTeethMA.Add(0.0);
        this.FLipsMA.Add(0.0);
    }

    public override OnQuote (): void {
        const price = this.GetPrice(this.FPriceType, 0);
        this.FJawMA[this.FJawMA.Length - 1] = price;
        this.FTeethMA[this.FTeethMA.Length - 1] = price;
        this.FLipsMA[this.FLipsMA.Length - 1] = price;

        const Parent = this.Parent;
        if (!Parent) {
            return;
        }

        const start = Math.max(this.FJawPeriod, Math.max(this.FLipsPeriod, this.FTeethPeriod));
        if (start > this.Count - 1) {
            return;
        }

        const jaw1 = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FJawMA, this.FJawPeriod, this.Line1Offset - this.FJawShiftMinus + this.FJawShiftPlus);
        const teeth1 = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FTeethMA, this.FTeethPeriod, this.Line1Offset - this.FTeethShiftMinus + this.FTeethShiftPlus);

        if (jaw1 > 0 && teeth1 > 0) {
            const upper = Math.abs(jaw1 - teeth1);
            this.SetValue(MODE_UPPER, this.Line1Offset, upper);
        }

        const teeth2 = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FTeethMA, this.FTeethPeriod, this.Line2Offset - this.FTeethShiftMinus + this.FTeethShiftPlus);
        const lips2 = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FLipsMA, this.FLipsPeriod, this.Line2Offset - this.FLipsShiftMinus + this.FLipsShiftPlus);

        if (teeth2 > 0 && lips2 > 0) {
            const lower = -Math.abs(teeth2 - lips2);
            this.SetValue(MODE_LOWER, this.Line2Offset, lower);
        }
    }
}
