// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { AccountType } from '../../Utils/Account/AccountType';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { QuoteValid } from '../../Utils/Quotes/QuoteValid';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { OperationType } from '../../Utils/Trading/OperationType';
import { DataCache } from '../DataCache';
import { type Account } from './Account';
import { type Asset } from './Asset';
import { type Instrument } from './Instrument';
import { type Position } from './Position';

export class PositionBalanceID {
    private readonly tradableID: number;
    private readonly productType: ProductType;

    constructor (tradableID: number, productType: ProductType) {
        this.tradableID = tradableID;
        this.productType = productType;
    }

    get ProductType (): ProductType {
        return this.productType;
    }

    equals (other: PositionBalanceID): boolean {
        return this.productType === other.productType && this.tradableID === other.tradableID;
    }

    toString (): string {
        return `${this.tradableID}-${this.productType}`;
    }
}

export class PositionsBalance {
    public Positions: Record<number, Position> = {};
    public Symbol: any;
    public ExpDate: any;
    public CreatedDate: any;
    public StrikePrice = null;
    public LongQty = null; // always in lots
    public ShortQty = null; // always in lots
    public GrossPnL = null;
    public NetPositionQty: number = null; // always in lots
    public PositionValue = null;
    public GrossExposure = null;
    public BreakEvenPoint: number = null;
    public AverageLong = null;
    public AverageShort = null;
    public NetExposure = null;
    public CurrentPrice = null;
    public NetPnL = null;
    public ShortFullCost = null;
    public LongFullCost = null;
    public Asset?: Asset;
    public isMultiAssets: boolean = false;
    private maximumAmountPrecisionAccount: Account = null;
    private _positionBalanceID: PositionBalanceID | null = null;
    get positionBalanceID (): PositionBalanceID {
        if (!isNullOrUndefined(this._positionBalanceID)) {
            return this._positionBalanceID;
        }
        return new PositionBalanceID(-1, ProductType.General);
    }

    get Instrument (): Instrument | null {
        return Object.values(this.Positions)[0]?.Instrument;
    }

    get Position (): Position | null {
        return Object.values(this.Positions)[0];
    }

    get Account (): Account | null {
        return Object.values(this.Positions)[0]?.Account;
    }

    public getAccountWithMaxAmountPrecision (): Account | null {
        return this.maximumAmountPrecisionAccount;
    }

    get ProductType (): ProductType {
        return this.positionBalanceID.ProductType;
    }

    get hasSameRoute (): boolean {
        const positionValues = Object.values(this.Positions);
        if (positionValues.length > 0) {
            const currentRouteID: number | undefined = positionValues[0].Instrument.Route;
            for (const position of positionValues) {
                if (currentRouteID !== position.Instrument.Route) {
                    return false;
                }
            }
        }
        return true;
    }

    public removePosition (position: Position): void {
        delete this.Positions[position.PositionId];
        if (Object.keys(this.Positions).length !== 0) {
            this.RecalculatePositionBalance();
            this.updateAccountWithMaximumAmountPrecision();
        }
    }

    public addPosition (position: Position): void {
        if (isNullOrUndefined(this._positionBalanceID)) {
            this._positionBalanceID = new PositionBalanceID(position.Instrument.InstrumentTradableID, position.ProductType);
        }

        this.Positions[position.PositionId] = position;
        this.CreatedDate = Math.min(position.UTCDateTime, this.CreatedDate);
        this.RecalculatePositionBalance();
        this.updateAccountWithMaximumAmountPrecision();
    }

    public Dispose (): void {
    }

    // TODO remove??? not in use
    public populatePositions (accountID: number, interiorID: string): void {
        const tempPositions: Record<number, Position> = {};

        const positions = DataCache.getAllPositions();
        for (const positionID in positions) {
            const position = positions[positionID];
            if (
                position.Account.AcctNumber === accountID.toString() &&
                position.Instrument.GetInteriorID() === interiorID
            ) {
                tempPositions[positionID] = position;
            }
        }
        this.Positions = tempPositions;
        this.RecalculatePositionBalance();
        this.updateAccountWithMaximumAmountPrecision();
    }

    public RecalculatePositionBalance (): void {
        let GrossPnLTemp: number = 0;
        let NetPnLTemp: number = 0;
        let ShortExposureSum: number = 0;
        let LongExposureSum: number = 0;
        let ShortFullCostTemp: number = 0;
        let LongFullCostTemp: number = 0;
        let PositionValue: number = 0;
        let LongQtyTemp: number = 0;
        let ShortQtyTemp: number = 0;
        let AssetName: string;

        for (const position of Object.values(this.Positions)) {
            const account = position.Account;

            const positionAssetName =
                account.AccountType === AccountType.MultiAsset
                    ? position.Instrument.Exp2
                    : account.assetBalanceDefault.Asset.Name;

            if (AssetName === undefined) {
                AssetName = positionAssetName;
            }
            if (AssetName !== positionAssetName) {
                this.isMultiAssets = true;
                break;
            }
        }

        const calculateInServerCurrency: boolean = this.isMultiAssets;
        if (calculateInServerCurrency) {
            this.Asset = DataCache.GetAssetByName(DataCache.baseCurrency);
        } else {
            this.Asset = DataCache.GetAssetByName(AssetName);
        }

        for (const position of Object.values(this.Positions)) {
            let recordGrossPnL: number;
            let recordNetPnL: number;
            let recordExposureValue: number;
            let recordPositionValue: number;
            if (calculateInServerCurrency) {
                recordGrossPnL = position.getGrossPnL(false);
                recordNetPnL = position.getNetPnL(false);
                recordExposureValue = position.getExpositionValue(false);
                recordPositionValue = position.getPositionValue(false);
            } else {
                recordGrossPnL = position.getGrossPnL(true);
                recordNetPnL = position.getNetPnL(true);
                recordExposureValue = position.getExpositionValue(true);
                recordPositionValue = position.getPositionValue(true);
            }
            if (position.getGrossPnL(true) !== 0) {
                GrossPnLTemp += recordGrossPnL;
            }
            if (position.getNetPnL(true) !== 0) {
                NetPnLTemp += recordNetPnL;
            }

            if (position.BuySell === OperationType.Buy) {
                LongQtyTemp += position.Amount;
                LongExposureSum += recordExposureValue;
                LongFullCostTemp += position.Amount * position.Price;
            } else {
                ShortQtyTemp += position.Amount;
                ShortExposureSum += recordExposureValue;
                ShortFullCostTemp += position.Amount * position.Price;
            }
            PositionValue += recordPositionValue;
        }

        this.Symbol = this.Position.GetInstrumentDisplayName();
        this.ExpDate = (this.Instrument?.ExpDate) ? DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(new Date(this.Instrument.ExpDateReal.getTime())) : null;
        this.StrikePrice = (this.Instrument != null && this.Instrument.StrikePrice > 0) ? this.Instrument.StrikePrice : 0;
        this.LongQty = LongQtyTemp;
        this.ShortQty = ShortQtyTemp;
        this.GrossPnL = GrossPnLTemp;
        this.NetPnL = NetPnLTemp;
        this.ShortFullCost = ShortFullCostTemp;
        this.LongFullCost = LongFullCostTemp;
        this.NetPositionQty = LongQtyTemp - ShortQtyTemp;
        this.PositionValue = PositionValue;
        const LongAndShortExposure: number = LongExposureSum - ShortExposureSum;
        const LongAndShortFullCost: number = LongFullCostTemp - ShortFullCostTemp;
        this.GrossExposure = LongExposureSum + ShortExposureSum;
        if (this.NetPositionQty !== undefined && this.NetPositionQty !== 0) {
            if (LongAndShortFullCost === 0) {
                this.BreakEvenPoint = 0;
            } else {
                const breakeven: number = LongAndShortFullCost / this.NetPositionQty;
                this.BreakEvenPoint = breakeven >= 0 ? breakeven : NaN;
            }
        } else {
            this.BreakEvenPoint = NaN;
        }

        if (this.LongQty !== undefined) {
            this.AverageLong = LongFullCostTemp / this.LongQty;
        } else {
            this.AverageLong = 0;
        }

        if (this.ShortQty !== undefined) {
            this.AverageShort = ShortFullCostTemp / this.ShortQty;
        } else {
            this.AverageShort = 0;
        }
        this.NetExposure = LongAndShortExposure;

        this.CurrentPrice = this.GetCurrentPrice(this.Instrument, LongQtyTemp, ShortQtyTemp);
    }

    private GetCurrentPrice (instrument: Instrument | null, buyAmount, sellAmount): number {
        if (instrument?.DataCache == null) {
            return -1;
        }

        if (instrument.CalcPriceOnTrade()) {
            const lastPrice = instrument.Level1.GetLastPrice();
            if (isNaN(lastPrice) || lastPrice >= 0) {
                return lastPrice;
            }

            return -1;
        } else {
            const lastQuote = instrument.GetLastQuote(QuoteValid.Last);
            if (lastQuote == null) {
                return -1;
            }

            let lastPrice = 0;

            if (!lastQuote.IsValid) {
                lastPrice = instrument.Level1.GetLastPrice();
            }

            const sp = instrument.DataCache.GetSpreadPlan(null);
            const ask = !isNaN(lastQuote.Ask) ? lastQuote.AskSpread_SP_Ins(sp, instrument) : lastPrice;
            const bid = !isNaN(lastQuote.Bid) ? lastQuote.BidSpread_SP_Ins(sp, instrument) : lastPrice;

            // чтобы было как в позиции (#39817)
            let curPrice = (buyAmount - sellAmount > 0) ? bid : ask;
            if (curPrice == 0) {
                const prevClose = instrument.Level1.GetPrevClose();
                if (prevClose >= 0) {
                    curPrice = prevClose;
                }
            }

            return curPrice;
        }
    }

    private updateAccountWithMaximumAmountPrecision (): void {
        let maxPrecision = -1;
        for (const posID in this.Positions) {
            const pos = this.Positions[posID];
            const currentPrecision = pos.Instrument.getAmountPrecision(null, pos.Account, this.ProductType);
            if (currentPrecision > maxPrecision) {
                maxPrecision = currentPrecision;
                this.maximumAmountPrecisionAccount = pos.Account;
            }
        }
    }
}
