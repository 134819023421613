// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { App } from './Commons/Application';
import { logs } from './Controls/panels/FullLogsPanel';
import { Resources } from './Commons/properties/Resources';
export * from './ControlsImport';
export * from './ScreensImport';
export * from './Extensions';
export * from './GlobalDeclaration';

declare global{
    interface Window {
        ShowAllLocalizationKeys: any
        logs: any
    }
}

window.addEventListener('load', function () {
    const me = document.getElementById('build_time');
    App.Initialize();

    window.ShowAllLocalizationKeys = (isShow) => { Resources.ShowAllLocalizationKeys(isShow); };
    window.logs = logs;
});
