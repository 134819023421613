import { Control } from './Control';
import { AccountsComboBoxTemplate } from '../../templates';
import { AccountMenuItem } from '../../Commons/AccountWidget/AccountMenuItem';
import { AccountMenuItemsHelper } from '../../Commons/AccountWidget/AccountMenuItemsHelper';
import { type Account } from '../../Commons/cache/Account';

export class AccountsComboBox extends Control {
    constructor () {
        super();
    }

    public override getType (): string { return 'AccountsComboBox'; }

    public oncomplete (): void {
        super.oncomplete();
        this.InitData();
        this.observe('selectedItem', (newAcc: Account, oldAcc: Account) => {
            if (isNullOrUndefined(newAcc) || newAcc === oldAcc) { return; }
            const item = new AccountMenuItem(newAcc);
            this.changeSelection(item);
            void this.set({ selectedItemView: item });
        });
    }

    AccountItemClick (item: AccountMenuItem): boolean {
        if (isNullOrUndefined(item)) return;

        void this.set({ selectedItem: item.GetAccount(), showItems: false });
        return false; // cancelBubble
    }

    onMouseDown (event): void {
        super.onMouseDown(event);
        if (this.get('showItems') as boolean) {
            void this.set({ showItems: false });
            return;
        }
        const myEl = this.find('.js-accountsComboBox');
        if (isNullOrUndefined(myEl)) return;
        void this.set({ left: myEl.offsetLeft, top: myEl.offsetTop + myEl.offsetHeight + 1, showItems: true });
    }

    public override lostFocus (): void {
        super.lostFocus();
        void this.set({ showItems: false });
    }

    private changeSelection (newSelected: AccountMenuItem): void {
        const accMenuItems: AccountMenuItem[] = this.get('accMenuItems');
        for (const item of accMenuItems) { item.Selected = item.equal(newSelected); }
        void this.set({ accMenuItems });
    }

    InitData (): void {
        const Data = AccountMenuItemsHelper.GetDataToFillOut(this.get('selectedItem'));
        void this.set({ accMenuItems: Data.Items, onlyOwns: Data.IsOnlyOwnAccounts, addtype: Data.IsAddType });
    }
}

Control.extendWith(AccountsComboBox, {
    template: AccountsComboBoxTemplate,
    data: function () {
        return {
            addtype: true,
            showItems: false,
            selectedItem: null,
            selectedItemView: null,
            accMenuItems: []
        };
    }
});
