// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { Color } from '../../../Graphics';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class DPO extends IndicatorScriptBase {
    constructor () {
        super();
        this.ProjectName = 'Detrended Price Oscillator';
        this.Comments = 'Detrended Price Oscillator';
        super.SetIndicatorLine('dpo', Color.Blue, 2, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.PeriodMA = 20;
        super.InputParameter(new InputParameterInteger('PeriodMA', 'Period of MA', 0, 1, 9999));

        this.SourcePrices = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('PeriodMA', 'Sources prices for MA', 2,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
    }

    public PeriodMA: number;

    public SourcePrices: number;

    private sma: iBuildInIndicator;

    public Init (): void {
        super.Init();
        super.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'DPO(' + this.PeriodMA + ')';
    }

    public override getCustomName (): string {
        return 'DPO (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.sma = this.Indicators.iMA(this.CurrentData, this.PeriodMA, MAMode.SMA, 0, this.SourcePrices) as iBuildInIndicator;

        super.IndicatorShortName(this.GetIndicatorShortName());

        return [this.sma];
    }

    public OnQuote (): void {
        super.OnQuote();
        super.SetValue(0, 0, this.getDpo());
    }

    private getDpo (): number {
        if (this.CurrentData.Count - 1 > Math.round(this.PeriodMA / 2) + 1) {
            return this.sma.getValue() - this.CurrentData.GetPrice(PriceType.Close, Math.round(this.PeriodMA / 2) + 1);
        } else {
            return 0;
        }
    }
}
