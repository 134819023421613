// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
export class Level1CalculatorUtils {
    public static getLastColoring (prevLastPrice: number, curLastPrice: number): ColoringEnum {
        if (isNaN(prevLastPrice) || isNaN(curLastPrice)) {
            return ColoringEnum.Neitral;
        }

        if (curLastPrice > prevLastPrice) {
            return ColoringEnum.Positive;
        } else if (curLastPrice < prevLastPrice) {
            return ColoringEnum.Negative;
        }

        return ColoringEnum.Previous;
    }

    public static getChangeColoring (curChange: number): ColoringEnum {
        if (isNaN(curChange)) {
            return ColoringEnum.Neitral;
        }

        if (curChange > 0) {
            return ColoringEnum.Positive;
        } else if (curChange < 0) {
            return ColoringEnum.Negative;
        }

        return ColoringEnum.Neitral;
    }

    public static getChangeDirectionArrow (oldChange: number, newChange: number): DirectionArrowEnum {
        if (isNullOrUndefined(newChange) || isNaN(newChange)) {
            return DirectionArrowEnum.Unknown;
        }

        if (isNullOrUndefined(oldChange) || isNaN(oldChange)) {
            const coloring = this.getChangeColoring(newChange);
            if (coloring === ColoringEnum.Positive) {
                return DirectionArrowEnum.Up;
            } else if (coloring === ColoringEnum.Negative) {
                return DirectionArrowEnum.Down;
            }
        }

        if (newChange > oldChange) {
            return DirectionArrowEnum.Up;
        } else if (newChange < oldChange) {
            return DirectionArrowEnum.Down;
        }
    }
}

export enum ColoringEnum {
    Neitral,
    Positive,
    Negative,
    Previous,
}

export enum DirectionArrowEnum {
    Up,
    Down,
    Unknown
}
