// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MathUtils } from './MathUtils';

export class DocumentUtils {
    static TABLE_GREEN = 'table-green';
    static TABLE_RED = 'table-red';
    static TABLE_WHITE = 'table-white';

    static isInputActive (): boolean { return document.activeElement instanceof HTMLInputElement; }
    static blurInput (): void {
        const inputElement = document.activeElement as HTMLInputElement;
        inputElement?.blur();
    }

    static getSignedStyle (value: number): string {
        if (value > 0) {
            return 'green';
        } else if (value < 0) {
            return 'red';
        } else {
            return '';
        }
    }

    static getColorTableStyle (value: number): string {
        if (!isValidNumber(value)) {
            return DocumentUtils.TABLE_WHITE;
        }

        if (value > 0) {
            return DocumentUtils.TABLE_GREEN;
        } else if (value < 0) {
            return DocumentUtils.TABLE_RED;
        } else {
            return DocumentUtils.TABLE_WHITE;
        }
    }

    static getColorTableForLiveLeverage (value: number): string {
        if (!isValidNumber(value)) {
            return DocumentUtils.TABLE_WHITE;
        }
        const _value = MathUtils.TruncateDouble(value, 2);
        if (_value > 1) {
            return DocumentUtils.TABLE_RED;
        } else if (_value >= 0 && _value <= 1) {
            return DocumentUtils.TABLE_GREEN;
        } else {
            return DocumentUtils.TABLE_WHITE;
        }
    }

    static getTrendStyle (isUpTrend: boolean): string {
        if (isUpTrend === undefined) { return ''; }
        return isUpTrend ? 'green' : 'red';
    }
}
