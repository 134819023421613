// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Graphics } from '../../Commons/Graphics';
import { ContainerControl } from '../../Controls/elements/ContainerControl';
import { TradingViewBaseToolTemplate } from '../../templates.js';

export class TradingViewBaseTool extends ContainerControl {
    public toolsContainerRef: any = null; // The TradingViewToolsContainer reference that created this tool
    public Name: string;

    constructor () {
        super();
        this.Name = 'TradingViewChartTool';
    }

    public override getType (): string { return 'TradingViewBaseTool'; }

    public getPrice (): number | null { return null; }

    public id (): any { return null; } // AlertId, PositionID or OrderId

    public getInstrument (): any {
        const dataObject = this.get('dataObject');
        return dataObject ? dataObject.Instrument : null;
    }

    public getSelfHeightOffset (): number {
        const h = this.get('height');
        return h ? h * 1.5 : 0;
    }

    public override oninit (): void {
        super.oninit();

        this.observe('top', (tV) => {
            void this.set('visible', tV >= 35 && (!this.getClientPanel() || tV <= this.getClientPanel().clientHeight - 50));
        });

        this.observe('mainImageHovered actionImageHovered cancelImageHovered', () => {
            if (this.get('collapseByClick')) { return; } // for Position and Order tools

            void this.set('uncollapsed', this.get('mainImageHovered') || this.get('actionImageHovered') || this.get('cancelImageHovered'));
        });

        this.observe('pricePlateColor', (nC) => {
            if (nC) {
                void this.set('priceTextColor', Graphics.GetContrastColor(nC));
            }
        });

        this.observe('dataObject', () => {
            const p = this.getPrice();
            void this.set({ price: p, priceValue: p });
        });

        this.observe('uncollapsed mainSrc actionSrc cancelSrc', (uncVal) => {
            const mImg = this.get('mainImg');
            let w = mImg ? mImg.width : 0;

            const uncollapsed = this.get('uncollapsed');
            if (uncollapsed) {
                const actionImg = this.get('actionImg');
                if (actionImg) {
                    w += this.get('actionImg')?.width + this.get('cancelImg')?.width;
                } else if (this.append) {
                    w = this.find(`.${this.get('className')}`)?.clientWidth;
                } // for tools with text action part which has dynamic width
            }

            void this.set('width', w);
        });

        this.on('componentOver', (event) => {
            const draggingID = this.toolsContainerRef?.currentDraggingToolID;
            if (draggingID && draggingID != this.id()) {
                return;
            }

            (document.getElementsByClassName('trading-view-tools-container')[0] as HTMLElement).style.pointerEvents = 'all';
            void this.set('hovered', true);
        });

        this.on('componentOut', (event) => {
            void this.set('hovered', false);

            const draggingID = this.toolsContainerRef?.currentDraggingToolID;
            if (draggingID && draggingID == this.id() && this.get<boolean>('dragging')) {
                return;
            }

            if (!this.toolsContainerRef?.currentDraggingToolID) {
                (document.getElementsByClassName('trading-view-tools-container')[0] as HTMLElement).style.pointerEvents = 'none';
            }
        });

        this.on('dragstart', (event) => {
            if (!this.get('movable')) {
                return;
            }

            if (!this.toolsContainerRef || this.toolsContainerRef.currentDraggingToolID) {
                return;
            } // id of moving tool is not empty, can't start move another one

            void this.set('dragging', true);

            this.toolsContainerRef.currentDraggingToolID = this.id();
        });

        this.on('dragend', (event) => {
            const draggingID = this.toolsContainerRef?.currentDraggingToolID;
            if (draggingID && draggingID != this.id()) {
                return;
            }

            void this.set('dragging', false);

            if (this.toolsContainerRef) {
                this.toolsContainerRef.currentDraggingToolID = null;
                if (!this.get('hovered')) {
                    (document.getElementsByClassName('trading-view-tools-container')[0] as HTMLElement).style.pointerEvents = 'none';
                }
            }
        });

        this.on('onMouseOverMain', (event) => { void this.set('mainImageHovered', true); });
        this.on('onMouseOutMain', (event) => { void this.set('mainImageHovered', false); });

        this.on('onMouseOverAction', (event) => { void this.set('actionImageHovered', true); });
        this.on('onMouseOutAction', (event) => { void this.set('actionImageHovered', false); });

        this.on('onMouseOverCancel', (event) => { void this.set('cancelImageHovered', true); });
        this.on('onMouseOutCancel', (event) => { void this.set('cancelImageHovered', false); });

        this.on('handleMainImageClick', (event) => { });
        this.on('handleActionImageClick', (event) => { });
        this.on('handleCancelImageClick', (event) => { });
    }
}

ContainerControl.extendWith(TradingViewBaseTool, {
    data: function () {
        return {
            className: '',

            chartTradingCoreRef: null,
            dataObject: null, // type of <Position,Order,Alert>

            collapseByClick: false, // for Position and Order tools

            uncollapsed: false,
            hovered: false,
            movable: true, // available to move
            dragging: false, // is moving now

            useTextForAction: true, // text description with quantity for Order, PnL for Position but image (useTextForAction=false) for action section in Alert tools
            actionSectionText: '', // ^^^^

            mainImageHovered: false,
            actionImageHovered: false,
            cancelImageHovered: false,

            priceTextColor: '#fff',
            priceLineColor: '#fff',
            pricePlateColor: '#fff',

            mainImg: null,
            actionImg: null,
            cancelImg: null,

            mainSrc: null,
            actionSrc: null,
            cancelSrc: null,

            mainCursor: 'ns-resize',
            actionCursor: 'pointer',
            closeCursor: 'pointer'
        };
    },
    template: TradingViewBaseToolTemplate
});
