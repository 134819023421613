// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources } from '../../Commons/properties/Resources';
import { HistoryType } from '../../Utils/History/HistoryType';
import { LayersEnum } from '../../Chart/Renderers/TerceraChartBaseRenderer';
import { DataCacheToolType } from '../../Commons/cache/DataCacheToolEnums';
import { MessageBoxType, TerceraMessageBox } from '../screen/TerceraMessageBox';
import { TerceraChartAction, TerceraChartActionEnum, TerceraChartToolbarsEnum } from '../../Chart/TerceraChartAction';
import { LinkedSystem } from '../misc/LinkedSystem';
import { ModelDataType, TerceraChartMVCCommand } from '../../Chart/TerceraChartMVC';
import { ChartHistoryType, TerceraChartDrawingType, TerceraChartTradingOperation } from '../../Chart/Utils/ChartConstants';
import { TerceraChartUtils, TerceraChartHistoryType } from '../../Chart/TerceraChartUtils';
import { KeyCode, KeyEventProcessor } from '../../Commons/KeyEventProcessor';
import { contextMenuHandler } from '../../Utils/AppHandlers';
import { TerceraIndicatorLookupDropDownForm } from '../elements/Lookup/TerceraIndicatorLookupDropDownForm';
import { ChartPanelTemplate } from '../../templates.js';
import { ControlEvents } from '../elements/Control';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { type TerceraQuantityNumeric } from '../elements/TerceraQuantityNumeric';
import { type TerceraVisualTradingWidget } from '../elements/TerceraVisualTradingWidget';
import { EditPositionScreen } from '../screen/EditPositionScreen';
import { ModifyOrderScreen } from '../screen/ModifyOrderScreen';
import { TerceraAddOverlayScreen } from '../screen/TerceraAddOverlayScreen';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { LookupDropDownShowParams } from '.././UtilsClasses/LookupDropDownShowParams';
import { WINDOW_SIDE_BORDERS_WIDTH } from '../UtilsClasses/SizeConstants';
import { CustomEvent } from '../../Utils/CustomEvents';
import { Periods } from '../../Utils/History/TFInfo';
import { OperationType } from '../../Utils/Trading/OperationType';
import { OrderType } from '../../Utils/Trading/OrderType';
import { SlTpPriceType } from '../../Utils/Enums/Constants';
import { PlacedFrom } from '../../Utils/Trading/PlacedFrom';
import { DynProperty } from '../../Commons/DynProperty';
import { IndicatorManager } from '../../Commons/cache/indicators/IndicatorManager';
import { SLTPTriggerUtils } from '../../Commons/cache/OrderParams/SLTPTriggerUtils';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { TradingLockUtils } from '../../Utils/TradingLockUtils';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { PositionEdit } from '../../Commons/cache/OrderParams/PositionEdit';
import { TradingNumericErrorChecker } from '../../Commons/Trading/TradingNumericErrorChecker';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { ITerceraChartShowScreenType } from '../../Chart/ITerceraChartShowScreenType';
import { TerceraChartActionProcessor } from '../../Chart/TerceraChartActionProcessor/TerceraChartActionProcessor';
import { PanelSettingsScreen } from '../screen/PanelSettingsScreen';
import { ChartTradingCore } from '../../Utils/Trading/ChartTradingCore';
import { type TerceraChartToolsPanel } from '../elements/TerceraChartToolsPanel';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type TerceraChartRactive } from '../elements/TerceraChartRactive/TerceraChartRactive';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Account } from '../../Commons/cache/Account';
import { type TerceraChart } from '../../Chart/TerceraChart';
import { type Order } from '../../Commons/cache/Order';
import { type Position } from '../../Commons/cache/Position';
import { FullScreenManager } from '../UtilsClasses/FullScreenManager';
import { type IChartContextMenuItem } from './ChartContextMenuItem';
import { type TerceraEditableListComboBox } from '../elements/TerceraEditableListComboBox';
import { type OrderEditBase } from '../../Commons/cache/OrderParams/order-edit/OrderEditBase';
import { type DirectReloadHistoryMessage } from '../../Utils/DirectMessages/DirectReloadHistoryMessage';

export class ChartPanel extends ApplicationPanelWithTable<any> {
    public static ContextMenuItemsInstance: IChartContextMenuItem[] = null;

    public terceraChartRactive: TerceraChartRactive = null;
    public chartOE: any = null;
    public needUpdateLimitStopNumerics: boolean = false;
    public ModelChanged: CustomEvent = new CustomEvent();
    public myVisualWidget: TerceraVisualTradingWidget | null = null;
    public myTradeNumeric: TerceraQuantityNumeric | null = null; // нумерик для редактирования quantity размещенных на Chart-e ордеров
    public myTradeNumericPromise: any = null;
    public initialTemplateSelected: boolean = false;
    public _terceraChart: any = null;
    public myToolsPanel: TerceraChartToolsPanel;
    public deferredCallbackXml: any;
    public ChartTradingCore: ChartTradingCore;
    public lastMouseMoveEvent: any;
    public mouseMoveAnimationFrameId: number;
    public lastMouseMoveX: number;
    public lastMouseMoveY: number;
    private isOpenedTradingWidget: boolean = false;

    constructor () { super(); }

    public override getType (): PanelNames { return PanelNames.ChartPanel; }

    public override oninit (): void {
        super.oninit();

        this.on('indicatorButton_btnClick', this.onIndicatorBtnClick);
        this.on('mouseTradeButton_btnClick', this.mouseTradeButton_btnClick);

        this.observe('instrumentItem', this.onInstrumentItemChanged);
        this.observe('accountItem', this.onAccountItemChanged);

        this.on('timeFrameComboBox_ComboItemClicked', this.onTimeFrameComboBox_ComboItemClicked);
        this.on('drawingStyleComboBox_ComboItemClicked', this.onDrawingStyleComboBox_ComboItemClicked);
        this.on('loadRangeComboBox_ComboItemClicked', this.onLoadRangeComboBox_ComboItemClicked);

        this.observe('showToolsPanel isShowOE oeSmall',
            this.layoutTable,
            { init: false });
        this.observe('showMainToolBarPanel',
            (newValue) => {
                const lastVisible = this.get<boolean>('isVisibleMainToolBar');
                void this.set({ isVisibleMainToolBar: lastVisible && newValue });
                this.layoutTable();
            },
            { init: false });

        this.on('overlay_btnClick', this.overlay_btnClick);

        // TODO
        // Utils.TradingLock.TradingLockChanged.Subscribe(this.updateTradingLockState, this);
        // DataCache.isAllowCache.GlobalWebRulesChanged.Subscribe(this.updateTimeFrameComboBox, this);

        const hideOE = Resources.isHidden('chart.ChartSymbolSelector.oeButton');
        void this.set('showOverlayButton', !Resources.isHidden('chart.ChartSymbolSelector.OverlayTooltip'));
        void this.set('isHiddenMouseTradeButton', !Resources.isHidden('chart.ChartSymbolSelector.mouseTradeButton'));
        void this.set('isHiddenOEButton', !hideOE);

        // У когото может быть открыто ОЕ до его скрытия, нужно его принудительно закрыть
        if (hideOE) {
            void this.set('isShowOE', false);
        }
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.myTradeNumeric = this.Controls.myTradeNumeric;
        this.myVisualWidget = this.Controls.myVisualWidget;

        this.initChart();

        this.chartOE = this.Controls.oe;

        this.OnResize.Subscribe(this.layoutTable, this);
        TradingLockUtils.TradingLock.TradingLockChanged.Subscribe(this.updateTradingLockState, this);

        this.MouseTradingEnabledSet(false);
        if (!isNullOrUndefined(this.myVisualWidget)) {
            this.myVisualWidget.OnVisualTradingOrderTypeChange.Subscribe((isSingle: boolean) => {
                this.terceraChartRactive.terceraChart.setIsOCOMode(!isSingle);
            }, this);
            this.myVisualWidget.Placed = false;
            this.myVisualWidget.showChartSetting = this.ShowScreen.bind(this, ITerceraChartShowScreenType.ChartSettings, { PanelSettingsDefaultPage: DynProperty.VISUAL_TRADING_GROUP });
        }

        // Ugly. http://tp.pfsoft.lan/entity/61292
        // TODO. Implement "Reset layout" feature or whatever it's called.
        this.themeChange();

        if (!isNullOrUndefined(this.deferredCallbackProps)) {
            this.callBack(this.deferredCallbackProps);
            delete this.deferredCallbackProps;
        }
        if (!isNullOrUndefined(this.deferredCallbackXml)) {
            this.setXmlSettingsTemplate(this.deferredCallbackXml);
            delete this.deferredCallbackXml;
        }
        this.terceraChartRactive.terceraChart.chartController.SuspendRefreshChart = false;
        this.terceraChartRactive.terceraChart.RefreshChart();
        if (DataCache.getNumberOfAccounts() === 1) {
            void this.set({ showAccountLookUp: false });
        }

        this.terceraChartRactive.terceraChart.model.ModelItemChanged.Subscribe(() => {
            this.ModelChanged.Raise();
        }, this);

        this.terceraChartRactive.terceraChart.onHasCorrectDataChanged.Subscribe(this.onHasCorrectDataChanged, this);

        // SessionSettings.timeZoneChanged.Subscribe(this.terceraChartRactive.terceraChart.RefreshChart, this.terceraChartRactive.terceraChart);

        KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
        KeyEventProcessor.OnKeyUp.Subscribe(this.onGlobalKeyUp, this);

        this.myToolsPanel = this.Controls.toolsPanel;
        this.observe('isShowOE', (chartOEVisible) => {
            void this.myToolsPanel.set('chartOEVisible', chartOEVisible);
            this.myToolsPanel.recalculateLocation();
        });
        this.myToolsPanel.observe('isVertical', (val) => { void this.set('verticalToolsPanel', val); });
        this.myToolsPanel.observe('isLeftOrTop', (val) => { void this.set('leftOrTopToolsPanel', val); });
        this.myToolsPanel.observe('onProcessMove', (moving: boolean) => {
            if (!moving) {
                this.layoutTable();
            }
        });
        void this.myToolsPanel.set(
            'actionProcessor',
            this.terceraChartRactive.terceraChart.TerceraChartActionProcessor);

        this.layoutTable();
        this.localize();
        this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
        if (!isNullOrUndefined(this.myTradeNumeric)) {
            this.myTradeNumeric.on(ControlEvents.LostFocus, this.onEnterPressed.bind(this));
        }

        const editableList = this.Controls.editableList;
        if (!isNullOrUndefined(editableList)) {
            editableList.set('visible', !Resources.isHidden('chart.templates.visibility'));
            editableList.OnItemSave.Subscribe(this.onSaveTemplate, this);
            editableList.OnSelectList.Subscribe(this.onSelectTemplate, this);
            this.selectInitialTemplate();
        }

        const newIns = this.get('instrumentItem');
        this.subscribe(newIns);
        this.initChartTradingCore();
        DataCache.OnHistoryChangedAndNeedReload.Subscribe(this.checkNeedReload, this);
    }

    private checkNeedReload (message: DirectReloadHistoryMessage): void {
        const instrument: Instrument = this.get('instrumentItem');

        if (isNullOrUndefined(instrument)) { return; }

        const chart = this.terceraChartRactive?.terceraChart;

        if (isNullOrUndefined(chart)) { return; }

        const model = chart.model;
        const tfi = model.GetTimeFrameInfo();

        if (isNullOrUndefined(tfi)) { return; }

        if (message.instrumentTradableID === instrument.InstrumentTradableID && message.routeID === instrument.Route &&
            tfi.HistoryType === message.barsType) {
            chart.RefreshChart();
        }
    }

    public initChartTradingCore (): void {
        const ctc = new ChartTradingCore();
        this.ChartTradingCore = ctc;

        ctc.myTradeNumeric = this.myTradeNumeric;
        ctc.myVisualWidget = this.myVisualWidget;
        ctc.chartOE = this.chartOE;
        ctc.terceraChartRactive = this.terceraChartRactive;
        ctc.TradingNumericErrorChecker = TradingNumericErrorChecker;
        ctc.EditPositionScreen = EditPositionScreen;
        ctc.ModifyOrderScreen = ModifyOrderScreen;
        ctc.TerceraMessageBox = TerceraMessageBox;
        ctc.contextMenuHandler = contextMenuHandler;
        ctc.MainWindowManager = MainWindowManager;
    }

    public updateTradingLockState (): void {
        const tradingLocked = TradingLockUtils.TradingLock.tradingLocked;
        void this.set('tradingLocked', tradingLocked);

        if (tradingLocked) {
            const chart = this.terceraChartRactive.terceraChart;
            const ttRenderer = chart.TerceraChartTradingToolsRenderer;
            this.isOpenedTradingWidget = ttRenderer.MouseTradeEnabled;
            this.MouseTradingEnabledSet(false);
        } else {
            this.MouseTradingEnabledSet(this.isOpenedTradingWidget);
        }
    }

    public onInstrumentItemChanged (newIns: Instrument, oldIns: Instrument): void {
        if (isNullOrUndefined(newIns) || newIns === oldIns) {
            return;
        }

        if (!isNullOrUndefined(this.myVisualWidget)) {
            void this.myVisualWidget.set('instrumentItem', newIns);
        }

        this.symbolLink_Out(false, newIns);

        if (!this.completed) {
            return;
        }

        const chart = !isNullOrUndefined(this.terceraChartRactive) ? this.terceraChartRactive.terceraChart : null;
        if (!isNullOrUndefined(chart)) {
            chart.unsubscribeTrades();
            chart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Instrument), newIns);
            this.unsubscribe(oldIns);
            this.subscribe(newIns);
        }
    }

    public onAccountItemChanged (newAcc: Account, oldAcc: Account): void {
        if (isNullOrUndefined(newAcc) || newAcc === oldAcc) {
            return;
        }

        if (!isNullOrUndefined(this.myVisualWidget)) {
            void this.myVisualWidget.set('accountItem', newAcc);
        }

        if (!isNullOrUndefined(this.myTradeNumeric)) {
            this.myTradeNumeric.settingsUpdate();
        }

        if (!isNullOrUndefined(this.terceraChartRactive?.terceraChart)) {
            this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(
                new TerceraChartMVCCommand(ModelDataType.Account),
                newAcc);
        }

        this.accountLink_Out(false, newAcc);
    }

    // TODO. Refactor.
    public subscribe (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const qc = DataCache.FQuoteCache;
        const chart = this.terceraChartRactive.terceraChart;

        qc.addListener(instrument, chart, HistoryType.QUOTE_LEVEL1);
    }

    // TODO. Refactor.
    public unsubscribe (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const qc = DataCache.FQuoteCache;
        const chart = this.terceraChartRactive.terceraChart;

        qc.removeListener(instrument, chart, HistoryType.QUOTE_LEVEL1);
    // qc.removeListener(instrument, chart, HistoryType.QUOTE_TRADES)
    }

    public onEnterPressed (): void {
        if (isNullOrUndefined(this.myTradeNumeric)) {
            return;
        }

        void this.myTradeNumeric.set('visible', false);
        const value = this.myTradeNumeric.get('value');
        if (!isNullOrUndefined(this.myTradeNumericPromise)) {
            this.myTradeNumericPromise(value);
        }
    }

    public repopulate (): void {
        super.repopulate();
        this.terceraChartRactive.terceraChart.repopulate();
        void this.set({ instrumentItem: this.get('instrumentItem') });
        void this.set({ accountItem: this.get('accountItem') });
    }

    public override layoutTable (): void {
        const chartRactive = this.terceraChartRactive;
        if (isNullOrUndefined(chartRactive)) {
            return;
        }

        this.layoutTableResize(chartRactive);

        const minSizeForToolBars = this.get('minSizeForToolBars');
        const panelSize = this.getCorrectChartPanelSize();
        if ((panelSize.width <= minSizeForToolBars || panelSize.height <= minSizeForToolBars) && !FullScreenManager.IsFullScreenMode) {
            void this.set({ isVisibleMainToolBar: false });
        } else if (!this.get<boolean>('isVisibleMainToolBar')) {
            void this.set({ isVisibleMainToolBar: true });
        }

        if (this.get<boolean>('showToolsPanel')) {
            this.myToolsPanel.onResize();
        }
    }

    public override updatePanelHeader (): void {
        let header = Resources.getResource('panel.terceraChart') + ' ';

        const chart = !isNullOrUndefined(this.terceraChartRactive)
            ? this.terceraChartRactive.terceraChart
            : null;

        if (!isNullOrUndefined(chart)) {
            const ins = chart.Instrument();
            if (!isNullOrUndefined(ins)) header += ins.DisplayName() + ' ';

            const tfi = chart.TimeFrameInfo();
            if (!isNullOrUndefined(tfi)) {
                header +=
                Resources.getResource('chart.timeframeselector.time') +
                ' - ' +
                Periods.ToLocalizedShortPeriod(tfi.Periods);
            }
        }

        void this.set('header', header);
    }

    public override localize (): void {
        super.localize();

        const addOverlayBtn = this.Controls.overlayButton;
        if (!isNullOrUndefined(addOverlayBtn)) {
            const addOverlayText = Resources.getResource('chart.ChartSymbolSelector.OverlayTooltip');
            addOverlayBtn.set('contextMenuItemText', addOverlayText);
            addOverlayBtn.set('tooltip', addOverlayText);
        }

        const timeFrameCB = this.Controls.timeFrameComboBox;
        if (!isNullOrUndefined(timeFrameCB)) {
            timeFrameCB.set('contextMenuItemText', Resources.getResource('chart.agregationType.Button'));
            timeFrameCB.set('tooltip', Resources.getResource('chart.agregationType.Button.ToolTip'));
        }

        const drawingStyleCB = this.Controls.drawingStyleComboBox;
        if (!isNullOrUndefined(drawingStyleCB)) {
            const styleText = Resources.getResource('chart.StyleButton.ToolTip');
            drawingStyleCB.set('contextMenuItemText', styleText);
            drawingStyleCB.set('tooltip', styleText);
        }

        const loadRangeCB = this.Controls.loadRangeComboBox;
        if (!isNullOrUndefined(loadRangeCB)) {
            loadRangeCB.set('contextMenuItemText', Resources.getResource('chart.timeframeselector.rangeButton'));
            loadRangeCB.set('tooltip', Resources.getResource('chart.timeframeselector.rangeButton.ToolTip'));
        }

        const toolsButton = this.Controls.toolsButton;
        if (!isNullOrUndefined(toolsButton)) {
            toolsButton.set('contextMenuItemText', Resources.getResource('chart.ChartSymbolSelector.drawingButton'));
            toolsButton.set('tooltip', Resources.getResource('chart.ChartSymbolSelector.drawingButton.ToolTip'));
        }

        const indicatorButton = this.Controls.indicatorButton;
        if (!isNullOrUndefined(indicatorButton)) {
            const indicatorText = Resources.getResource('chart.ChartSymbolSelector.addIndicator.ButtonToolTip');
            indicatorButton.set('contextMenuItemText', indicatorText);
            indicatorButton.set('tooltip', indicatorText);
        }

        const templatesEditable = this.Controls.editableList;
        if (!isNullOrUndefined(templatesEditable)) {
            const templatesText = Resources.getResource('chart.templates.tooltip');
            templatesEditable.set('contextMenuItemText', templatesText);
            templatesEditable.set('tooltip', templatesText);
        }

        const mouseTradeButton = this.Controls.mouseTradeButton;
        if (!isNullOrUndefined(mouseTradeButton)) {
            const mouseTradingButtonText = Resources.getResource('chart.ChartSymbolSelector.mouseTradeButton.ToolTip');
            mouseTradeButton.set('contextMenuItemText', mouseTradingButtonText);
            mouseTradeButton.set('tooltip', mouseTradingButtonText);
        }

        const oeButton = this.Controls.oeButton;
        if (!isNullOrUndefined(oeButton)) {
            const oeButtonText = Resources.getResource('chart.ChartSymbolSelector.oeButton.ToolTip');
            oeButton.set('contextMenuItemText', oeButtonText);
            oeButton.set('tooltip', oeButtonText);
        }

        this.updateTimeFrameComboBox();
        this.updateLoadRangeComboBox();
        this.updateDrawingStyleComboBox();

        if (!isNullOrUndefined(this.terceraChartRactive)) {
            this.terceraChartRactive.localize();
        }

        if (!isNullOrUndefined(this.chartOE)) {
            this.chartOE.localize();
        }

        const toolsPanel = this.Controls.toolsPanel;
        if (!isNullOrUndefined(toolsPanel)) {
            toolsPanel.localize();
        }

        const editableCB = this.Controls.editableList;
        if (!isNullOrUndefined(editableCB)) {
            editableCB.localize();
        }
    }

    public static GetContextMenuItems (): IChartContextMenuItem[] {
        let menuItems: IChartContextMenuItem[] = ChartPanel.ContextMenuItemsInstance;
        if (isValidArray(menuItems)) return menuItems;

        menuItems = [];

        // 2. Style
        menuItems.push({
            tag: TerceraChartAction.Create(TerceraChartActionEnum.Style),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Renko) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.LinesBreak) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Line) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Bar) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Candle) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Dot) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.DotLine) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Forest) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Solid) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Kagi) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Profile) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.TicTac) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Cluster) }
            ]
        });

        /*
    var editBars = new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars));
    editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.EditBar)));
    editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.RemoveBar)));
    editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.ResetAllChanges)));
    //editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.FinishEditing)));
    menuItems.Add(editBars);
    */

        /*
    // 3. Interval
    menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.Agregation) });
    */

        // 4. DataType
        menuItems.push({
            tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.Default)) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByBid)) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByBidAsk)) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByTrades)) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByASk)) }
            ]
        });

        menuItems.push({ separator: true });

        // Overlays
        menuItems.push({
            tag: TerceraChartAction.Create(TerceraChartActionEnum.Overlay),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Overlay, ITerceraChartShowScreenType.OverlayScreen) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Overlay, true) }
            ]
        });

        // Indicators
        menuItems.push({
            tag: TerceraChartAction.Create(TerceraChartActionEnum.Indicator),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Indicator, ITerceraChartShowScreenType.ScriptLookup) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Indicator, true) }
            ]
        });

        // Tools.
        const toolsMI: any = {
            tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.ToolSelector) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Eraser) },

                { separator: true },

                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.TrendTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.HorizontalLine) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.VerticalLine) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Line) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.PriceChannel) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.AndrewsPitchFork) }
                    ]
                },
                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GeometryTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Triangle) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Rectangle) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Circle) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Ellipse) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Polygon) }
                    ]
                },
                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciRetracement) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciExpansion) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciArc) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciEllipse) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciSpiral) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciFans) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciPhiChannel) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciTimeGoal) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciTimeZone) }
                    ]
                },
                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannLine) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannFan) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannGrid) }
                    ]
                },
                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottImpulse) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottCorrection) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottTriangle) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottTripleCombo) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottDoubleCombo) }
                    ]
                },
                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.SecondaryTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.InfoLine) }
                    ]
                }
            // { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Symbol) }
            ]
        };

        // Delete tools.
        toolsMI.subitems.push({
            tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.HorizontalLine) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.VerticalLine) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Line) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.PriceChannel) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.AndrewsPitchFork) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Triangle) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Rectangle) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Circle) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Ellipse) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Polygon) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciRetracement) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciExpansion) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciArc) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciEllipse) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciSpiral) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciFans) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciPhiChannel) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciTimeGoal) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciTimeZone) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.GannLine) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.GannFan) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.GannGrid) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottImpulse) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottCorrection) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottTriangle) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottDoubleCombo) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottTripleCombo) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.InfoLine) },
                // { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Symbol) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteAllTools) }
            ]
        });

        menuItems.push(toolsMI);

        if (!Resources.isHidden('allowCreatePanelsFromPanel')) {
        // New
            const subitems = [];

            if (!Resources.isHidden('chart.new.oe')) { subitems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.NewPanel, PanelNames.AdvancedOrderEntry) }); }
            if (!Resources.isHidden('chart.new.level2')) { subitems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.NewPanel, PanelNames.MarketDepthPanel) }); }

            if (subitems.length > 0) {
                menuItems.push({
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.NewPanel),
                    subitems
                });
            }
        }

        /// / View
        const viewMI = {
            tag: TerceraChartAction.Create(TerceraChartActionEnum.View, null, false),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.PreviousCloseLine, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.TimeToNextBar, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.DayHighLow, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.InfoVindow, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.Volume, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.GridTime, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.GridPrice, false) },
                { separator: true },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.AnalyseToolBar, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.DrawingToolBar, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.MainToolBar, false) }
            ]
        };

        viewMI.subitems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.OrderEntry, false) });
        viewMI.subitems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.ScrollBar, false) });
        menuItems.push(viewMI);

        menuItems.push({ separator: true });

        menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.Refresh) });
        // Settings
        menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.Settings) });

        ChartPanel.ContextMenuItemsInstance = menuItems;
        return menuItems;
    }

    // #region ICaller

    public override Properties (skipBaseProperties: boolean = false): DynProperty[] {
        let properties = [];
        if (!skipBaseProperties) {
            properties = super.Properties();
        }

        const propsTCh = !isNullOrUndefined(this.terceraChartRactive) ? this.terceraChartRactive.Properties() : [];
        properties = properties.concat(propsTCh);

        let prop = null;

        // Additional
        let SeparatorGroup = '#3#' + Resources.getResource('property.SeparatorGroup.Additional');

        const terceraChart = !isNullOrUndefined(this.terceraChartRactive) ? this.terceraChartRactive.terceraChart : null;

        const mainToolBarAction = TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.MainToolBar);
        if (terceraChart?.TerceraChartActionProcessor.GetTerceraChartActionState(mainToolBarAction).Visible) {
            prop = new DynProperty('panel.caption.showHideToolbar', this.get('showMainToolBarPanel'), DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
            prop.separatorGroup = SeparatorGroup;
            prop.sortIndex = 3;
            properties.push(prop);
        }

        prop = new DynProperty('ChartDrawingToolsToolBar', this.get('showToolsPanel'), DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 0;
        properties.push(prop);

        const analyzeToolBarAction = TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.AnalyseToolBar);
        if (terceraChart?.TerceraChartActionProcessor.GetTerceraChartActionState(analyzeToolBarAction).Visible) {
            prop = new DynProperty('ChartAnalyseToolsToolBar', this.get('showAnalyzeToolsPanel'), DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
            prop.separatorGroup = SeparatorGroup;
            prop.sortIndex = 1;
            properties.push(prop);
        }

        const dataSourceOpen = this.get('dataSourceOpen');
        properties.push(new DynProperty('dataSourceOpen', dataSourceOpen, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        // OE
        const oePanel = this.chartOE;
        if (!isNullOrUndefined(oePanel)) {
            const isShowOE = this.get('isShowOE');

            SeparatorGroup = '#0#' + Resources.getResource('property.VisibilitySeparatorGroup');

            prop = new DynProperty('property.chart.showingOE', isShowOE, DynProperty.BOOLEAN, DynProperty.CHART_OE_GROUP);
            prop.sortIndex = 0;
            prop.separatorGroup = SeparatorGroup;
            prop.assignedProperty = [
                'OE_ShowBuyMarketSellMarket',
                'OE_ShowSellBidBuyASk',
                'OE_AddBidAddAsk'
            // "OE_Small",
            // "OE_ShowSLTP"
            ];
            properties.push(prop);

            SeparatorGroup = '#2#' + Resources.getResource('property.SeparatorViewButtons');

            prop = new DynProperty('OE_ShowBuyMarketSellMarket', oePanel.get('_userPreferredShowBuySellMarket'), DynProperty.BOOLEAN, DynProperty.CHART_OE_GROUP);
            prop.sortIndex = 1;
            prop.separatorGroup = SeparatorGroup;
            prop.enabled = isShowOE;
            properties.push(prop);

            const btnGr = Resources.isHidden('panel.newOrderEntry.LimitOrderButtons.Visibility') ? DynProperty.HIDDEN_GROUP : DynProperty.CHART_OE_GROUP;

            prop = new DynProperty('OE_ShowSellBidBuyASk', oePanel.get('_userPreferredShowSellBidBuyAsk'), DynProperty.BOOLEAN, btnGr);
            prop.sortIndex = 2;
            prop.separatorGroup = SeparatorGroup;
            prop.enabled = isShowOE;
            properties.push(prop);

            prop = new DynProperty('OE_AddBidAddAsk', oePanel.get('_userPreferredShowAddBidAsk'), DynProperty.BOOLEAN, btnGr);
            prop.sortIndex = 3;
            prop.separatorGroup = SeparatorGroup;
            prop.enabled = isShowOE;
            properties.push(prop);

            /* SeparatorGroup = "#1#" + Resources.getResource("property.SeparatorGroup.SeparatorOEWidth");

        prop = new DynProperty("OE_Small", this.get('oeSmall'), DynProperty.BOOLEAN, DynProperty.CHART_OE_GROUP);
        prop.tooltip = Resources.getResource("property.OE_Small.ToolTip");
        prop.sortIndex = 4;
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = isShowOE;
        properties.push(prop); */

        // #45682 - здесь для сохранения нужно использовать пременную oE_ShowSLTP, а не свойство OE_ShowSLTP
        // var vis = DataCache.isAllowCache.GlobalWebRules[IsAllowed.RequestsTypes.isAllowedForMyUser_FUNCTION_SLTP];
        // prop = new DynProperty("OE_ShowSLTP", oePanel.OE_ShowSLTP, DynProperty.BOOLEAN, vis ? DynProperty.CHART_OE_GROUP : DynProperty.HIDDEN_GROUP);
        // prop.sortIndex = 5;
        // prop.separatorGroup = SeparatorGroup;
        // prop.enabled = this.ShowingOE;
        // prop.visible = DataCache.isAllowCache.GlobalWebRules[IsAllowed.RequestsTypes.isAllowedForMyUser_FUNCTION_SLTP];
        // properties.push(prop);
        }

        // Hidden
        if (!skipBaseProperties) {
            const ins = this.get('instrumentItem');
            if (!isNullOrUndefined(ins)) {
                properties.push(new DynProperty('symbol', ins.GetInteriorID(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
            }

            const acc = this.get('accountItem');
            if (!isNullOrUndefined(acc)) {
                properties.push(new DynProperty('account', acc.AcctNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
            }
        }

        if (!isNullOrUndefined(this.Controls.editableList)) {
            const tmpItem = this.Controls.editableList.get('selectedItem');
            const tmp = !isNullOrUndefined(tmpItem) ? tmpItem.text : null;
            if (!isNullOrUndefined(tmp)) {
                properties.push(new DynProperty('selectedTemplate', tmp, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
            }
        }

        return properties;
    }

    public override callBack (properties: DynProperty[], skipBaseProperties?): void {
        super.callBack(properties);

        if (isNullOrUndefined(this.chartOE) || isNullOrUndefined(this.terceraChartRactive)) {
            this.deferredCallbackProps = properties;
            return;
        }

        let dp = DynProperty.getPropertyByName(properties, 'panel.caption.showHideToolbar');
        if (!isNullOrUndefined(dp)) {
            void this.set('showMainToolBarPanel', dp.value);
        }

        dp = DynProperty.getPropertyByName(properties, 'ChartDrawingToolsToolBar');
        if (!isNullOrUndefined(dp)) {
            void this.set('showToolsPanel', dp.value);
        }

        dp = DynProperty.getPropertyByName(properties, 'ChartAnalyseToolsToolBar');
        if (!isNullOrUndefined(dp)) {
            void this.set('showAnalyzeToolsPanel', dp.value);
        }

        const oePanel = this.chartOE;
        if (!isNullOrUndefined(oePanel)) {
        // У когото может быть открыто ОЕ до его скрытия, нужно его принудительно закрыть
            const hideOE = Resources.isHidden('chart.ChartSymbolSelector.oeButton');

            if (hideOE) {
                void this.set('isShowOE', false);
            } else {
                dp = DynProperty.getPropertyByName(properties, 'property.chart.showingOE');
                if (!isNullOrUndefined(dp)) {
                    void this.set('isShowOE', dp.value);
                }
            }

            // dp = DynProperty.getPropertyByName(properties, "OE_Small");
            // if (dp) this.set('oeSmall', dp.value);

            // dp = DynProperty.getPropertyByName(properties, "OE_ShowSLTP");
            // if (dp) oePanel.OE_ShowSLTP = dp.value;

            dp = DynProperty.getPropertyByName(properties, 'OE_ShowBuyMarketSellMarket');
            if (!isNullOrUndefined(dp)) {
                oePanel.set('_userPreferredShowBuySellMarket', dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'OE_ShowSellBidBuyASk');
            if (!isNullOrUndefined(dp)) {
                oePanel.set('_userPreferredShowSellBidBuyAsk', dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'OE_AddBidAddAsk');
            if (!isNullOrUndefined(dp)) {
                oePanel.set('_userPreferredShowAddBidAsk', dp.value);
            }
        }

        if (!isNullOrUndefined(this.terceraChartRactive)) {
            this.terceraChartRactive.callBack(properties);
        }

        if (skipBaseProperties === undefined) {
        // Hidden
            void this.set('instrumentItem', this.getCallBackInstrument(properties, 'symbol'));

            dp = DynProperty.getPropertyByName(properties, 'account');
            if (!isNullOrUndefined(dp?.value) && !LinkedSystem.accLinkingActive) { // #119833
                void this.set('accountItem', SessionSettings.getDefValueFromObj(DataCache.Accounts[dp.value], DataCache.Accounts));
            }

            dp = DynProperty.getPropertyByName(properties, 'selectedTemplate');
            const cb: TerceraEditableListComboBox = this.Controls.editableList;
            if (isValidString(dp?.value) && !isNullOrUndefined(cb)) {
                cb.restoreCorrectSelectItem(dp.value);
                this.initialTemplateSelected = true;
            }
        }

        dp = DynProperty.getPropertyByName(properties, 'dataSourceOpen');
        if (!isNullOrUndefined(dp)) {
            void this.set('dataSourceOpen', dp.value);
        }
    }

    // #endregion

    public override themeChange (): void {
        const Controls = this.Controls;

        if (!isNullOrUndefined(this.terceraChartRactive)) {
            this.terceraChartRactive.themeChange();
        }

        const toolsPanel = Controls.toolsPanel;
        if (!isNullOrUndefined(toolsPanel)) {
            toolsPanel.themeChange();
        }
    }

    public onTimeFrameComboBox_ComboItemClicked (context, newVal): void {
        const model = this.terceraChartRactive.terceraChart.model;
        const oldTFInfo = model.GetTimeFrameInfo();
        const controller = this.terceraChartRactive.terceraChart.chartController;
        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.TFI), oldTFInfo.Copy({ period: newVal.tag }));
    }

    public onDrawingStyleComboBox_ComboItemClicked (context, newVal): void {
        this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Style), newVal.tag);
    }

    public onLoadRangeComboBox_ComboItemClicked (context, newVal): void {
        this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Range), newVal.tag);
    }

    public updateTimeFrameComboBox (): void {
        if (isNullOrUndefined(this.terceraChartRactive?.terceraChart)) {
            return;
        }

        const cBox = this.Controls.timeFrameComboBox;

        const newItems = [
            { text: Periods.ToLocalizedShortPeriod(Periods.TIC), value: Periods.TIC },
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN), value: Periods.MIN },
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN5), value: Periods.MIN5 },
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN15), value: Periods.MIN15 },
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN30), value: Periods.MIN30 },
            { text: Periods.ToLocalizedShortPeriod(Periods.HOUR), value: Periods.HOUR },
            { text: Periods.ToLocalizedShortPeriod(Periods.HOUR4), value: Periods.HOUR4 },
            { text: Periods.ToLocalizedShortPeriod(Periods.DAY), value: Periods.DAY },
            { text: Periods.ToLocalizedShortPeriod(Periods.WEEK), value: Periods.WEEK },
            { text: Periods.ToLocalizedShortPeriod(Periods.MONTH), value: Periods.MONTH },
            { text: Periods.ToLocalizedShortPeriod(Periods.YEAR), value: Periods.YEAR }
        ];

        if (Resources.isHidden('chart.AllowedPeriods.1T'))
        // || DataCache.isAllowed(IsAllowed.RequestsTypes.isAllowed_PERIOD_TICK) )
        {
            newItems.splice(0, 1);
        }

        if (!isNullOrUndefined(cBox)) {
            cBox.set('items', newItems);
        }
        const tf = this.terceraChartRactive.terceraChart.model.GetTimeFrameInfo();
        if (!isNullOrUndefined(tf) && !isNullOrUndefined(cBox)) {
            cBox.setItembyValue(tf.Periods);
        }
    }

    public updateDrawingStyleComboBox (): void {
        if (isNullOrUndefined(this.terceraChartRactive?.terceraChart)) {
            return;
        }

        const chart = this.terceraChartRactive.terceraChart;
        const allowedStyles = chart.model.CurrentAllowedStyles;
        const len = allowedStyles.length;
        const items = [];
        for (let i = 0; i < len; i++) {
            const style = allowedStyles[i];
            items.push({ style: 'js-drawing-style' + style, text: TerceraChartUtils.ToLocalizedTerceraChartDrawingType(style), value: style });
        }
        const cBox = this.Controls.drawingStyleComboBox;
        if (!isNullOrUndefined(cBox)) {
            cBox.set({ listWidth: 120, usePNGView: true, showArrow: false, items, enabled: len > 1 });
            cBox.setItembyValue(chart.model.GetChartDrawingType());
        }
    }

    public updateLoadRangeComboBox (): void {
        if (isNullOrUndefined(this.terceraChartRactive?.terceraChart)) {
            return;
        }

        const model = this.terceraChartRactive.terceraChart.model;

        const periods = model.CurrentAllowedPeriods;
        const len = periods.length;
        const cBoxItems = [];

        let selItem = null;
        const curPeriodDesc = model.GetPeriodDesc();

        for (let i = 0; i < len; i++) {
            const p = periods[i];
            cBoxItems.push({ text: p.toString(), value: p });

            if (curPeriodDesc.equals(p)) {
                selItem = p;
            }
        }
        const cBox = this.Controls.loadRangeComboBox;
        if (!isNullOrUndefined(cBox)) {
            cBox.set('items', cBoxItems);
            cBox.setItembyValue(selItem);
        }
    }

    public initChart (): void {
        this.terceraChartRactive = this.Controls.chart;
        if (isNullOrUndefined(this.terceraChartRactive)) {
            return;
        }

        this.terceraChartRactive.terceraChart.ContextMenuItems = ChartPanel.GetContextMenuItems();

        const model = this.terceraChartRactive.terceraChart.model;
        model.ModelItemChanged.Subscribe(this.MVCModelItemChanged, this);

        const controller = this.terceraChartRactive.terceraChart.chartController;

        controller.ExecuteCommand(
            new TerceraChartMVCCommand(ModelDataType.Instrument),
            this.get('instrumentItem'));

        controller.ExecuteCommand(
            new TerceraChartMVCCommand(ModelDataType.Account),
            this.get('accountItem'));
    }

    public MVCModelItemChanged (item): void {
        switch (item.ItemType) {
        case ModelDataType.Style:
            this.updateDrawingStyleComboBox();
            break;
        case ModelDataType.Instrument:
            this.updatePanelHeader();
            break;
        case ModelDataType.TFI:
            this.updateTimeFrameComboBox();
            this.updatePanelHeader();
            break;
        case ModelDataType.Range:
            this.updateLoadRangeComboBox();
            break;
        }
    }

    public GetTerceraChart (): TerceraChart {
        if (!isNullOrUndefined(this._terceraChart)) {
            return this._terceraChart;
        }

        if (!isNullOrUndefined(this.terceraChartRactive?.terceraChart)) {
            this._terceraChart = this.terceraChartRactive.terceraChart;
        }

        return this._terceraChart;
    }

    public override TickAsync (): void {
        const chart = this.GetTerceraChart();
        if (chart?.needRedraw) {
            chart.needRedraw = false;
            chart.Draw();
            chart.AddForceUpdate();
        }
    }

    public addind (): any {

    }

    public indPropSuccess (): any {

    }

    public overlay_btnClick (): void {
        TerceraAddOverlayScreen.addNewOverlay(this.terceraChartRactive.terceraChart);
    }

    public onIndicatorBtnClick (): void {
        const params = new LookupDropDownShowParams();
        params.callBack = this.indicatorLookupCB.bind(this);
        TerceraIndicatorLookupDropDownForm.ShowForm(params);
    }

    public indicatorLookupCB (selectedItem): void {
        if (typeof selectedItem === 'object' && isValidString(selectedItem.FullName)) {
            this.terceraChartRactive.terceraChart.AddIndicator(IndicatorManager.GetIndicator(selectedItem.FullName));
        }
    }
    // #region ITerceraChartPanelContext

    public ShowScreen (type, parameters): any {
        switch (type) {
        case ITerceraChartShowScreenType.ChartSettings:{
            if (this.AllowSettings) {
                const headerString = Resources.getResource('panel.terceraChart') +
                    Resources.getResource('PanelSettingsScreen.TextSmall');

                if (parameters?.PanelSettingsDefaultPage) {
                    PanelSettingsScreen.EditProperties(this, parameters.PanelSettingsDefaultPage, headerString);
                } else {
                    PanelSettingsScreen.EditProperties(this, DynProperty.DATA_STYLE_GROUP, headerString);
                }
            }
            break;
        }
        case ITerceraChartShowScreenType.Properties:{
            const caller = parameters.caller;
            const toolStateInfoDict = TerceraChartActionProcessor.ToolStateInfoDict;
            const toolType = caller.dataCacheTool.ToolType;
            const headerString =
                Resources.getResource(toolStateInfoDict[toolType].locKey) + ' ' +
                Resources.getResource('screen.properties.common');
            PanelSettingsScreen.EditProperties(caller, null, headerString, this);
            return null;
        }

        case ITerceraChartShowScreenType.UIManipulation:{
            const isSet: boolean = parameters.isSet;
            const toolBarType = parameters.toolBarType;
            switch (toolBarType) {
            case TerceraChartToolbarsEnum.MainToolBar:
                if (isSet) {
                    void this.toggle('showMainToolBarPanel');
                }
                return this.get('showMainToolBarPanel');
            case TerceraChartToolbarsEnum.DrawingToolBar:
                if (isSet) {
                    void this.toggle('showToolsPanel');
                }
                return this.get('showToolsPanel');
            case TerceraChartToolbarsEnum.AnalyseToolBar:
                if (isSet) {
                    void this.toggle('showAnalyzeToolsPanel');
                }
                return this.get('showAnalyzeToolsPanel');
            case TerceraChartToolbarsEnum.OrderEntry:
                if (isSet) {
                    void this.toggle('isShowOE');
                }
                return this.get('isShowOE');
            case TerceraChartToolbarsEnum.AllPricePanel:
                return {};
            /* TODO.
                if (isSet)
                {
                    allpricePanelSettings.Visible = !allpricePanelSettings.Visible;
                    UpdateChildPanelsState();
                }
                return new object[] { allpricePanelSettings.Visible, allpricePanelSettings.Visible && allPricePanel != null ? allPricePanel.quickTable.RealRowsCount > 0 : false };
                */
            case TerceraChartToolbarsEnum.TimeAndSalesPanel:
                return {};
                /* TODO.
                if (isSet)
                {
                    tsPanelSettings.Visible = !tsPanelSettings.Visible;
                    UpdateChildPanelsState();
                }
                return new object[] { tsPanelSettings.Visible, tsPanelSettings.Visible && tsPanel != null ? tsPanel.quickTable.RealRowsCount > 0 : false };
                */
            }
            break;
        }

        case ITerceraChartShowScreenType.OverlayScreen:
            TerceraAddOverlayScreen.addNewOverlay(this.terceraChartRactive.terceraChart);
            return null;
        case ITerceraChartShowScreenType.ScriptLookup:
            this.onIndicatorBtnClick();
            return null;
        case ITerceraChartShowScreenType.NewPanel:{
            const accItem = this.get('accountItem');
            const insItem = this.get('instrumentItem');
            MainWindowManager.Factory.addPanel(
                parameters.panelName,
                null,
                function (panel) {
                    if (!isNullOrUndefined(accItem)) {
                        panel.accountLink_In(accItem.AcctNumber);
                    }
                    if (!isNullOrUndefined(insItem)) {
                        panel.symbolLink_In(insItem.GetInteriorID());
                    }
                });
            return null;
        }

        case ITerceraChartShowScreenType.ChartTrading:
            if (parameters?.hasOwnProperty('tradingFlag')) {
                this.MouseTradingEnabledSet(parameters.tradingFlag);
            } else {
                this.MouseTradingEnabledSet(!this.MouseTradingEnabledGet());
            }
            break;
        }
        return null;
    }

    // #endregion

    public MouseTradingEnabledGet (): boolean {
        const chart = this.terceraChartRactive.terceraChart;
        return chart.TerceraChartTradingToolsRenderer.MouseTradeEnabled &&
        !TradingLockUtils.TradingLock.tradingLocked;
    }

    public MouseTradingEnabledSet (value): void {
        const chart = this.terceraChartRactive.terceraChart;
        chart.TerceraChartTradingToolsRenderer.MouseTradeEnabled = value;
        this.activateCtrlMouseTrading(value);
        this.UpdateMouseTradingButton();
    }

    public CtrlMouseTradingEnabledGet (): boolean {
        const chart = this.terceraChartRactive.terceraChart;
        return chart.TerceraChartTradingToolsRenderer.CtrlMouseTradeEnabled &&
        !TradingLockUtils.TradingLock.tradingLocked;
    }

    public CtrlMouseTradingEnabledSet (value): void {
        const chart = this.terceraChartRactive.terceraChart;
        chart.TerceraChartTradingToolsRenderer.CtrlMouseTradeEnabled = value;
        this.UpdateMouseTradingButton();
    }

    public UpdateMouseTradingButton (): void {
        const mouseTradeButton = this.Controls.mouseTradeButton;
        if (isNullOrUndefined(mouseTradeButton)) return;

        const checked = this.MouseTradingEnabledGet() || this.CtrlMouseTradingEnabledGet();

        mouseTradeButton.set('checked', checked);
        this.terceraChartRactive.terceraChart.ForceUpdateCursor();

        if (isNullOrUndefined(this.myVisualWidget)) {
            return;
        }

        void this.myVisualWidget.show(checked).then(() => {
            if (!checked) { return; }

            const mL = this.myVisualWidget.get('left');
            const mVWidthC = this.myVisualWidget.get('width');
            const mVWidth = this.terceraChartRactive.get('width');
            // TODO сделать по нормальному наверное
            if (!this.myVisualWidget.Placed || mL < 0 || mL + mVWidthC > mVWidth + 10/* небольшое окно на полгрешность справа */) {
                void this.myVisualWidget.set({ left: mVWidth / 2 - mVWidthC / 2, top: 31 });
                this.myVisualWidget.Placed = true;
            }
        });
    }

    public mouseTradeButton_btnClick (): void {
        this.terceraChartRactive.terceraChart.TerceraChartActionProcessor.ProcessTerceraChartAction(
            TerceraChartAction.Create(TerceraChartActionEnum.ChartTrading));
    }

    public ChartVisualTrading (order: Order, newData): any {
        return this.ChartTradingCore.ChartVisualTrading(order, newData, this.get('instrumentItem'));
    }

    // TODO. Ugly.
    public changeOrderSLTP (order: Order, newData): void {
        const action = newData.action;
        const modifyOrdObj = this.createModifyOrderObject(order);
        modifyOrdObj.placedFrom = newData.placedFrom;
        // TODO. Ugly. Method is not base,
        // it works just cuz JS doesn't give a dayum.
        const sltpHolder = modifyOrdObj.getRawSLTP();

        const trSett = GeneralSettings.TradingDefaults;
        const ins = order.Instrument;
        const orderPrice = order.Price;

        const newPrice = newData.price;
        const newIsTrStop = newData.ts;
        const isBuy = order.BuySell === OperationType.Buy;

        switch (action) {
        case TerceraChartTradingOperation.PlaceTP:
        case TerceraChartTradingOperation.ModifyTP:
            sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            sltpHolder.TakeProfitPriceValue = newPrice;
            sltpHolder.OpenLossPriceValue = order.StopLossPriceValue;
            break;

        case TerceraChartTradingOperation.PlaceSL:
        case TerceraChartTradingOperation.ModifySL:{
            const wasTrStop = sltpHolder.StopLossPriceType === SlTpPriceType.TrOffset;

            sltpHolder.StopLossPriceType =
            !isNullOrUndefined(newIsTrStop) ? SlTpPriceType.TrOffset : SlTpPriceType.Offset;

            const offsetValue = InstrumentUtils.getPriceDifferenceInTicks(orderPrice, newPrice, ins);
            sltpHolder.StopLossPriceValue = offsetValue;
            sltpHolder.OpenLossPriceValue = offsetValue;

            if (!isNullOrUndefined(newIsTrStop)) {
                sltpHolder.StopLossLimitPriceValue = null;
            } else {
                const priceToCalcFrom = trSett.SetSlTpValuesInOffset ? newPrice : orderPrice;

                sltpHolder.StopLossLimitPriceValue = sltpHolder.StopLossLimitPriceValue != null
                    ? InstrumentUtils.getPriceDifferenceInTicks(priceToCalcFrom, newData.sllPrice, ins) * (isBuy ? 1 : -1) * Math.sign(priceToCalcFrom - newData.sllPrice)
                    : (action === TerceraChartTradingOperation.PlaceSL || wasTrStop ? trSett.LimitOffsetTicks : null);
            }
            break;
        }

        case TerceraChartTradingOperation.PlaceSLL:
        case TerceraChartTradingOperation.ModifySLL:{
            const slPrice = order.GetStopLossInPriceValue();
            const sign = (isBuy ? 1 : -1) * Math.sign(slPrice - newPrice);

            sltpHolder.StopLossLimitPriceValue = sltpHolder.StopLossPriceType === SlTpPriceType.Absolute
                ? newPrice
                : InstrumentUtils.getPriceDifferenceInTicks(slPrice, newPrice, ins) * sign;
            break;
        }

        case TerceraChartTradingOperation.RemoveTP:
            sltpHolder.TakeProfitPriceValue = NaN;
            break;
        case TerceraChartTradingOperation.RemoveSL:
        case TerceraChartTradingOperation.RemoveSLL:
            sltpHolder.StopLossPriceValue = NaN;
            sltpHolder.StopLossLimitPriceValue = null;
            break;
        }

        if ((isValidNumber(sltpHolder.TakeProfitPriceValue) && sltpHolder.TakeProfitPriceValue !== 0) || (isValidNumber(sltpHolder.StopLossPriceValue) && sltpHolder.StopLossPriceValue !== 0)) {
            sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetShortByOrder(order);
        }

        // TODO. Ugly. Method is not base,
        // it works just cuz JS doesn't give a dayum.
        modifyOrdObj.setSLTP(sltpHolder);

        DataCache.FOrderExecutor.modifyOrderPromiseWithCallbacks(modifyOrdObj)
            .then(function (confirmed) {
                if (!isNullOrUndefined(newData.CancelCallback) && !confirmed) {
                    newData.CancelCallback();
                }
            })
            .catch(function () {
                const ex = new CustomErrorClass('ChangeOrderSLTP error', 'ChartPanel.changeOrderSLTP', 'changeOrderSLTP -> modifyOrderPromiseWithCallbacks');
                ErrorInformationStorage.GetException(ex);

                if (!isNullOrUndefined(newData.CancelCallback)) {
                    newData.CancelCallback();
                }
            })
            .finally(function () {
            // TODO. Refactor.
                modifyOrdObj.dispose();
            });
    }

    // TODO. Ugly.
    // IMPORTANT SL trailing stop mustn't change when position's tp is modified
    public changePositionSLTP (position: Position, newData): void {
        const action = newData.action;
        const modifyPosObj = new PositionEdit({
            dataCache: DataCache,
            position,
            placedFrom: newData.placedFrom
        });
        // TODO. Ugly. Method is not base,
        // it works just cuz JS doesn't give a dayum.
        const sltpHolder = modifyPosObj.getRawSLTP();

        const newPrice = newData.price;
        const newIsTrStop = newData.ts;
        const ins = position.Instrument;

        switch (action) {
        case TerceraChartTradingOperation.PlaceTP:
        case TerceraChartTradingOperation.ModifyTP:
            sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            sltpHolder.TakeProfitPriceValue = newPrice;

            if (!isNullOrUndefined(position.SLOrder)) {
                sltpHolder.OpenLossPriceValue =
                    InstrumentUtils.getPriceDifferenceInTicks(position.OpenPrice, position.SLOrder.Price, ins);
            }
            break;

        case TerceraChartTradingOperation.PlaceSL:
        case TerceraChartTradingOperation.ModifySL:{
            const roundedOpenPrice = ins.RoundPriceToNearestPointSize(position.OpenPrice);
            const roundedPrice = ins.RoundPriceToNearestPointSize(position.Price);
            const roundedNewPrice = ins.RoundPriceToNearestPointSize(newPrice);

            const sign = (position.BuySell !== OperationType.Buy ? -1 : 1) * Math.sign(roundedPrice - roundedNewPrice);
            sltpHolder.StopLossPriceType =
                newIsTrStop ? SlTpPriceType.TrOffset : SlTpPriceType.Offset;
            sltpHolder.StopLossPriceValue =
                InstrumentUtils.getPriceDifferenceInTicks(roundedOpenPrice, roundedNewPrice, ins) * sign;
            sltpHolder.OpenLossPriceValue =
                InstrumentUtils.getPriceDifferenceInTicks(roundedOpenPrice, roundedNewPrice, ins) * sign;

            if (sltpHolder.StopLossPriceType !== SlTpPriceType.TrOffset) {
                sltpHolder.StopLossLimitPriceValue = isValidNumber(newData.sllPrice) && newData.sllPrice !== 0
                    ? InstrumentUtils.getPriceDifferenceInTicks(roundedPrice, newData.sllPrice, ins) * (position.BuySell !== OperationType.Buy ? -1 : 1) * Math.sign(roundedPrice - newData.sllPrice)
                    : sltpHolder.OpenLossPriceValue + GeneralSettings.TradingDefaults.LimitOffsetTicks; // default offset if place SL
            }

            break;
        }

        case TerceraChartTradingOperation.RemoveTP:
            sltpHolder.TakeProfitPriceValue = NaN;
            break;
        case TerceraChartTradingOperation.RemoveSL:
        case TerceraChartTradingOperation.RemoveSLL:
            sltpHolder.StopLossPriceValue = NaN;
            sltpHolder.StopLossLimitPriceValue = null;
            break;

        case TerceraChartTradingOperation.PlaceSLL:
        case TerceraChartTradingOperation.ModifySLL:
            if (newIsTrStop) {
                sltpHolder.StopLossPriceType = SlTpPriceType.TrOffset;
                sltpHolder.StopLossPriceValue = InstrumentUtils.getPriceDifferenceInTicks(position.CurPriceClose, newPrice, ins);
            } else {
                sltpHolder.StopLossLimitPriceValue =
                    sltpHolder.StopLossPriceType === SlTpPriceType.Absolute
                        ? newPrice
                        : InstrumentUtils.getPriceDifferenceInTicks(position.StopLimit, newPrice, ins);
            }

            break;
        }

        if ((isValidNumber(sltpHolder.TakeProfitPriceValue) && sltpHolder.TakeProfitPriceValue !== 0) || (isValidNumber(sltpHolder.StopLossPriceValue) && sltpHolder.StopLossPriceValue !== 0)) {
            sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetShortByOrder(position);
        }

        // TODO. Ugly. Method is not base,
        // it works just cuz JS doesn't give a dayum.
        modifyPosObj.setSLTP(sltpHolder);
        // Trailing stop implicit modification fix.
        // For instance: dragging of tp order causes tr stop order change as well
        // due to setting up trailing stop value in offset ticks.
        // Server treats it as an offset relative to current price...
        // Closing trailing stop order receives price updates from a server,
        // but Order.TrStopOffset field isn't recalculated accordingly.
        // Whatever. Refactor this pile of shit.
        modifyPosObj.setCanEditSLOrTrailingStop(
            action === TerceraChartTradingOperation.PlaceSL ||
        action === TerceraChartTradingOperation.ModifySL ||
        action === TerceraChartTradingOperation.RemoveSL ||
        action === TerceraChartTradingOperation.PlaceSLL ||
        action === TerceraChartTradingOperation.ModifySLL ||
        action === TerceraChartTradingOperation.RemoveSLL);
        modifyPosObj.setCanEditTP(
            action === TerceraChartTradingOperation.PlaceTP ||
        action === TerceraChartTradingOperation.ModifyTP ||
        action === TerceraChartTradingOperation.RemoveTP);

        DataCache.FOrderExecutor.modifyPositionPromise(modifyPosObj)
            .then(function (confirmed) {
                if (!isNullOrUndefined(newData.CancelCallback) && !confirmed) {
                    newData.CancelCallback();
                }
            })
            .catch(function () {
                const ex = new CustomErrorClass('ChangePositionSLTP error', 'ChartPanel.changePositionSLTP', 'changePositionSLTP -> modifyPositionPromise');
                ErrorInformationStorage.GetException(ex);

                if (!isNullOrUndefined(newData.CancelCallback)) {
                    newData.CancelCallback();
                }
            })
            .finally(function () {
            // TODO. Refactor.
                modifyPosObj.dispose();
            });
    }

    // TODO. Very Ugly.
    public changeQuantity (order: Order, newData): void {
        const quant = newData.quantity;

        const modifyOrdObj = this.createModifyOrderObject(order);

        modifyOrdObj.setTradingData({ quantity: quant });

        DataCache.FOrderExecutor.modifyOrderPromiseWithCallbacks(modifyOrdObj)
            .then(function (confirmed) {
                if (!isNullOrUndefined(newData.CancelCallback) && !confirmed) {
                    newData.CancelCallback();
                }
            })
            .catch(function () {
                const ex = new CustomErrorClass('ChangeQuantity error', 'ChartPanel.changeQuantity', 'changeQuantity -> modifyOrderPromiseWithCallbacks');
                ErrorInformationStorage.GetException(ex);

                if (!isNullOrUndefined(newData.CancelCallback)) {
                    newData.CancelCallback();
                }
            })
            .finally(function () {
            // TODO. Refactor.
                modifyOrdObj.dispose();
            });
    }

    // TODO. Ugly.
    public moveOrder (order: Order, newData): void {
        const newPrice = newData.price;
        const ins = order.Instrument;
        const isLimitModify = newData.isLimitModify;

        const modifyOrdObj = this.createModifyOrderObject(order);

        if (modifyOrdObj === null) {
            if (!isNullOrUndefined(newData.CancelCallback)) {
                newData.CancelCallback();
                return;
            }
        }
        // Moving sl/tp along with order's price change.
        // TODO. UGLY as fook boi.
        // Trailing stop's sl/tp values are always in offset,
        // thus there's no need to change sl/tp.
        const ordTypes = OrderType;
        switch (order.OrderType) {
        case ordTypes.Stop:
        case ordTypes.StopLimit:
        case ordTypes.Limit:{
            // TODO. UGLY. It works just cuz JS doesn't give a dayum.
            const oldBasePrice = modifyOrdObj.getBasePrice();
            let tmpNP = newPrice;
            if (order.OrderType === ordTypes.StopLimit) {
                modifyOrdObj.setBasePrice(newPrice, isLimitModify, true);
                tmpNP = modifyOrdObj.getBasePrice();
            }
            let priceDifferenceInTicks = InstrumentUtils.getPriceDifferenceInTicks(oldBasePrice, tmpNP, ins);

            if (priceDifferenceInTicks === 0) {
                break;
            }
            // Ewww.
            priceDifferenceInTicks *= tmpNP > oldBasePrice ? 1 : -1;
            const sltpHolder = modifyOrdObj.getRawSLTP();

            if (!isNaN(sltpHolder.StopLossPriceValue) &&
    (sltpHolder.StopLossPriceType === SlTpPriceType.Offset || sltpHolder.StopLossPriceType === SlTpPriceType.TrOffset)) {
                sltpHolder.OpenLossPriceValue = order.StopLossPriceValue;
            }

            // Ignore if value is already in offset.
            if (!isNaN(sltpHolder.StopLossPriceValue) &&
    sltpHolder.StopLossPriceType === SlTpPriceType.Absolute) {
                sltpHolder.StopLossPriceValue = OrderUtils.ConvertTickOffset(
                    ins,
                    null,
                    sltpHolder.StopLossPriceValue,
                    priceDifferenceInTicks);
            }

            // Ignore if value is already in offset.
            if (sltpHolder.StopLossLimitPriceValue !== null &&
    sltpHolder.StopLossPriceType === SlTpPriceType.Absolute) {
                sltpHolder.StopLossLimitPriceValue = OrderUtils.ConvertTickOffset(
                    ins,
                    null,
                    sltpHolder.StopLossLimitPriceValue,
                    priceDifferenceInTicks);
            }

            // Ignore if value is already in offset.
            if (!isNaN(sltpHolder.TakeProfitPriceValue) &&
    sltpHolder.TakeProfitPriceType === SlTpPriceType.Absolute) {
                sltpHolder.TakeProfitPriceValue = OrderUtils.ConvertTickOffset(
                    ins,
                    null,
                    sltpHolder.TakeProfitPriceValue,
                    priceDifferenceInTicks);
            }

            // TODO. Ugly. Method is not base,
            // it works just cuz JS doesn't give a dayum.
            modifyOrdObj.setSLTP(sltpHolder);
            break;
        }
        }

        modifyOrdObj.setBasePrice(newPrice, isLimitModify, true);
        modifyOrdObj.placedFrom = newData.placedFrom;

        DataCache.FOrderExecutor.modifyOrderPromiseWithCallbacks(modifyOrdObj)
            .then(function (confirmed) {
                if (!isNullOrUndefined(newData.CancelCallback) && !confirmed) {
                    newData.CancelCallback();
                }
            })
            .catch(function () {
                const ex = new CustomErrorClass('MoveOrder error', 'ChartPanel.moveOrder', 'moveOrder -> modifyOrderPromiseWithCallbacks');
                ErrorInformationStorage.GetException(ex);

                if (!isNullOrUndefined(newData.CancelCallback)) {
                    newData.CancelCallback();
                }
            })
            .finally(function () {
            // TODO. Refactor.
                modifyOrdObj.dispose();
            });
    }

    public placeNewOrderFromVisualTrading (placeOrderParams): void {
        if (isNullOrUndefined(this.myVisualWidget) || TradingNumericErrorChecker.HasErrors(this.myVisualWidget)) {
            return;
        }

        placeOrderParams.fromChart = true;
        placeOrderParams.place = true;
        placeOrderParams.lTif = this.myVisualWidget.get('selectedItem').value;

        placeOrderParams.expireTime = this.myVisualWidget.get('gtdDate');

        placeOrderParams.quantity = this.myVisualWidget.get('quantity');
        placeOrderParams.placedFrom = PlacedFrom.WEB_CHART_VISUAL;

        const chartOE = this.chartOE;
        placeOrderParams.productType = chartOE.get('productType');
        placeOrderParams.leverageValue = chartOE.get('leverageValue');

        try {
            const ins = this.get('instrumentItem');
            const route = DataCache.getRouteByName(ins.Route);
            const isAllowedByTIFandOrderType = route.IsAllowableTif(placeOrderParams.lTif, placeOrderParams.lOrdType);

            if (!isAllowedByTIFandOrderType) {
                TerceraMessageBox.Show(Resources.getResource('screen.error.title'), Resources.getResource('IsAllowedResponceReason.NotAllowedRouteOrderType'), MessageBoxType.Error, null, null, false, true);
                return;
            }
        } catch (err) {
            console.error(err);
        }

        chartOE.OrderParamsSet(placeOrderParams);
    }

    // TODO. Ugly.
    public createModifyOrderObject (order: Order): OrderEditBase {
        const orderTypeObj = DataCache.OrderParameterContainer.GetOrderType(order.OrderType);
        return orderTypeObj.createModifyOrderObject({
            dataCache: DataCache,
            order
        });
    }

    public override updateSettings (): void {
        super.updateSettings();
        this.chartOE.updateTradingAllowedStuff();
    }

    public onGlobalKeyDown (btn, modificators): void {
        if (!this.isPanelOrChartFocused()) {
            return;
        }

        if (this.myTradeNumeric?.get<boolean>('visible') && btn === KeyCode.ENTER) {
            this.onEnterPressed();
        }

        this.activateCtrlMouseTrading(
            btn === KeyCode.CTRL &&
            !modificators.ALT &&
            !modificators.SHIFT);
    }

    public onGlobalKeyUp (btn, modificators): void {
        if (!this.isPanelOrChartFocused()) {
            const chart = this.terceraChartRactive.terceraChart;
            const ttRenderer = chart.TerceraChartTradingToolsRenderer;
            if (!ttRenderer.CtrlMouseTradeEnabled) {
                return;
            }
        }

        this.activateCtrlMouseTrading(false);
    }

    public isPanelOrChartFocused (): boolean {
        return this.get('focused') ||
        this.terceraChartRactive.get('focused');
    }

    // TODO.
    public override lostFocus (): void {
        super.lostFocus();
        this.activateCtrlMouseTrading(false);
    }

    public activateCtrlMouseTrading (activate): void {
        if (isNullOrUndefined(this.terceraChartRactive)) {
            return;
        }

        // http://tp.pfsoft.net/entity/56578
        if (this.MouseTradingEnabledGet()) {
            return;
        }

        const hideOE = Resources.isHidden('chart.ChartSymbolSelector.oeButton');
        if (hideOE) {
            return;
        }

        const chart = this.terceraChartRactive.terceraChart;
        const ttRenderer = chart.TerceraChartTradingToolsRenderer;

        // http://tp.pfsoft.net/entity/56570
        if (ttRenderer.CtrlMouseTradeEnabled === activate) {
            return;
        }

        ttRenderer.CtrlMouseTradeEnabled = activate;
        this.UpdateMouseTradingButton();
        chart.IsDirty(LayersEnum.Tools);
    }

    public override dispose (): void {
        KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
        KeyEventProcessor.OnKeyUp.UnSubscribe(this.onGlobalKeyUp, this);
        DataCache.OnHistoryChangedAndNeedReload.UnSubscribe(this.checkNeedReload, this);

        if (!isNullOrUndefined(this.Controls.editableList)) {
            this.Controls.editableList.OnItemSave.UnSubscribe(this.onSaveTemplate, this);
            this.Controls.editableList.OnSelectList.UnSubscribe(this.onSelectTemplate, this);
        }

        // SessionSettings.timeZoneChanged.UnSubscribe(this.terceraChartRactive.terceraChart.RefreshChart, this.terceraChartRactive.terceraChart);
        this.unsubscribe(this.get('instrumentItem'));
        this.terceraChartRactive.terceraChart.Dispose();
        if (!isNullOrUndefined(this.myVisualWidget)) {
            this.myVisualWidget.dispose();
        }
        if (!isNullOrUndefined(this.myTradeNumeric)) {
            this.myTradeNumeric.dispose();
        }

        this.OnResize.UnSubscribe(this.layoutTable, this);
        TradingLockUtils.TradingLock.TradingLockChanged.UnSubscribe(this.updateTradingLockState, this);

        super.dispose();
    }

    public override getXmlSettingsTemplate (): any {
        const result: any = {};
        if (!isNullOrUndefined(this.terceraChartRactive?.terceraChart)) {
            result.indicatorsObj = this.terceraChartRactive.terceraChart.SaveIndicators();
            result.overlaysObj = this.terceraChartRactive.terceraChart.SaveOverlays();
        }
        return result;
    }

    public override setXmlSettingsTemplate (value): void {
        if (isNullOrUndefined(this.chartOE) || isNullOrUndefined(this.terceraChartRactive)) {
            this.deferredCallbackXml = value;
            return;
        }
        this.terceraChartRactive.terceraChart.LoadIndicators(!isNullOrUndefined(value) ? value.indicatorsObj : {});
        this.terceraChartRactive.terceraChart.LoadOverlays(!isNullOrUndefined(value) ? value.overlaysObj : {});
    }

    public override accountLink_In (accId, fromLinkedSystem: boolean = false): boolean {
        if (fromLinkedSystem && !this.OnAccountLinking()) {
            return;
        }

        if (!isNullOrUndefined(DataCache.Accounts[accId])) {
            void this.set('accountItem', DataCache.Accounts[accId]);
        }

        this.localizeAccountLinkTooltip();

        return true;
    }

    public override symbolLink_In (symbolName: string): void {
        const currentInstrument = this.get('instrumentItem');

        const newInstr = DataCache.getInstrumentByName(symbolName);
        if (!isNullOrUndefined(newInstr)) {
        // убираем не нужный апдейт
            if (!isNullOrUndefined(currentInstrument) && currentInstrument.GetInteriorID() === symbolName) {
                return;
            }
            void this.set('instrumentItem', newInstr);
        }
    }

    public override symbolLink_Out (newSubscriber, instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            const ins = this.get('instrumentItem');
            if (isNullOrUndefined(ins)) return;
            instrument = ins;
        }
        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber);
        }
    }

    public override workWithToolBar (showHide): void {
        void this.set({ showMainToolBarPanel: showHide });
    }

    public override getInstrument (): Instrument {
        return this.get('instrumentItem');
    }

    public onSaveTemplate (element): void {
    // console.log(this._guid + " onSaveTemplate")
        const editableCB = this.Controls.editableList;
        if (isNullOrUndefined(editableCB?.get('selectedItem'))) {
            return;
        }

        const allLists = editableCB.get('valueOfLists');
        const list = allLists[element.tag];

        const props = DynProperty.serialize(this.Properties(true));
        const xmlSet = this.getXmlSettingsTemplate();

        list.itemsStr = JSON.stringify({ properties: props, xmlSettings: xmlSet });

        editableCB.updateListsValue(null, false, null, true);
    }

    public onSelectTemplate (propJSON): void {
    // console.log(this._guid + " onSelectTemplate")
        if (isNullOrUndefined(propJSON)) {
            return;
        }

        const chartTemplate = JSON.parse(propJSON);
        const properties = DynProperty.deserialize(chartTemplate.properties);
        const xmlSet = chartTemplate.xmlSettings;

        this.terceraChartRactive.terceraChart.RemoveAllIndicators();

        this.callBack(properties, true);
        this.setXmlSettingsTemplate(xmlSet);
    }

    public selectInitialTemplate (): void {
        const editableList = this.Controls.editableList;

        if (this.initialTemplateSelected) {
            return;
        }

        const items = editableList.menuItems; // #87095
        const selItem = editableList.get('selectedItem');

        if (isNullOrUndefined(selItem) || selItem.value === undefined) {
            return;
        }

        if (!isNullOrUndefined(items[selItem.value])) {
            items[selItem.value].checked = false;
        }

        editableList.set('selectedItem', { separator: true });
        // editableList.set('showLastValue', true )

        // применение стартового темплейта для здоровых людей
        // const templates = editableList.get('valueOfLists');
        // const defaultTemplate = templates.length ? templates[0].itemsStr : null;

        // if (defaultTemplate) { this.onSelectTemplate(defaultTemplate); }
    }

    // setcursor settings to div
    public applyCursor (cursor): void {
        if (!isNullOrUndefined(this.terceraChartRactive)) {
            void this.terceraChartRactive.setCursor(cursor);
        }
    }

    public override RefreshInstrumentDescriptions (insArray: any[]): void {
        super.RefreshInstrumentDescriptions();
        if (!insArray.includes(this.get('instrumentItem'))) { return; }

        const tCh = this.terceraChartRactive?.terceraChart;
        if (isNullOrUndefined(tCh)) {
            return;
        }
        tCh.RefreshChart();
    }

    public onHasCorrectDataChanged (hasCorrectData): void {
        void this.set('dataSourceBottom', hasCorrectData ? 42 : 5);
    }

    // TODO перенести в один обработчик
    public override onMouseMove (event): void {
        super.onMouseMove(event);
        this.lastMouseMoveEvent = event;
        // In order to increase smoothness of panel's movement.
        cancelAnimationFrame(this.mouseMoveAnimationFrameId);
        this.mouseMoveAnimationFrameId = requestAnimationFrame(this.mouseMoveHandler.bind(this));
    }

    public mouseMoveHandler (): void {
        if (!this.get) { return; }
        const activeControl = this.get('activeControl');
        if (isNullOrUndefined(activeControl)) return;

        const event = this.lastMouseMoveEvent;
        if (isNullOrUndefined(event)) return;

        if (activeControl.get('onProcessMove') === true) {
            const myParams = activeControl.movingParameters;
            const ev = event.original;
            const xShift = myParams.startMoveCoordX - ev.pageX;
            const yShift = myParams.startMoveCoordY - ev.pageY;

            let lastMouseMoveX = myParams.startMyX - xShift;
            let lastMouseMoveY = myParams.startMyY - yShift;

            const windowSideBordersW = WINDOW_SIDE_BORDERS_WIDTH;
            const panelSize = this.getCorrectChartPanelSize();
            // New window left.
            const activeControlW = activeControl.get('width') + windowSideBordersW;
            const mainWindowW = panelSize.width;

            if (lastMouseMoveX + activeControlW > mainWindowW) {
                lastMouseMoveX = mainWindowW - activeControlW;
            }

            if (lastMouseMoveX < 0) {
                lastMouseMoveX = 0;
            }

            // New window top.
            const activeControlH = activeControl.get('height') + windowSideBordersW;
            const topWindowsMargin = 31;
            const bottomWindowsMargin = panelSize.height;

            if (lastMouseMoveY + activeControlH > bottomWindowsMargin) {
                lastMouseMoveY = bottomWindowsMargin - activeControlH;
            }

            if (lastMouseMoveY < topWindowsMargin) {
                lastMouseMoveY = topWindowsMargin;
            }

            this.lastMouseMoveX = lastMouseMoveX;
            this.lastMouseMoveY = lastMouseMoveY;

            // Workaround to avoid "heavy" ractive code.
            // var rootPanelDiv = this.activePanelRootDiv;
            activeControl.set({ left: lastMouseMoveX, top: lastMouseMoveY });
        // rootPanelDiv.style.left = lastMouseMoveX + 'px';
        // rootPanelDiv.style.top = lastMouseMoveY + 'px';
        }

        if (activeControl.onProcessResize) {
            activeControl.onResizing(event);
        }
    }

    private getCorrectChartPanelSize (): { width: number, height: number } {
        let mainWindowW: number = this.get('width');
        if (!isValidNumber(mainWindowW) || mainWindowW === 0) {
            mainWindowW = this.el.offsetWidth;
        }
        let mainWindowH: number = this.get('height');
        if (!isValidNumber(mainWindowH) || mainWindowH === 0) {
            mainWindowH = this.el.offsetHeight;
        }

        return { width: mainWindowW, height: mainWindowH };
    };
}

ApplicationPanelWithTable.extendWith(ChartPanel, {
    Name: 'ChartPanel',
    data: function () {
        return {
            showFullscreenModeButton: true,
            isAccountLinkShow: true,
            isSymbolLinkShow: true,
            instrumentItem: null,
            accountItem: null,

            isShowOE: false,
            oeSmall: true,

            showToolsPanel: false,
            showMainToolBarPanel: true,
            showAnalyzeToolsPanel: false,
            showAccountLookUp: true,
            isVisibleMainToolBar: true,
            tradingLocked: false,

            canFilterByAccount: false,

            showOverlayButton: true,

            dataSourceOpen: false,
            dataSourceVisible: true, // true to enable data source
            dataSourceBottom: 5,

            hasCorrectData: false,

            verticalToolsPanel: true,
            leftOrTopToolsPanel: true,

            addWidgets: true,
            minSizeForToolBars: 200
        };
    },
    computed: {
        terceraChartPanelContext: {
            get: function () { return this; },
            set: function (value) { }
        }
    },
    partials: {
        bodyPartial: ChartPanelTemplate
    }
});
