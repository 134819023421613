// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from '../../Commons/Connection';
import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraBaseWindowTemplate, TerceraRecoverScreenTemplate } from '../../templates.js';
import { TerceraMessageBox, MessageBoxType } from './TerceraMessageBox';
import { TerceraWindowBase } from './TerceraWindowBase';
import { BrowserUtils } from '../../Commons/UtilsClasses/BrowserUtils';
import { ChangePasswordStatusEnum } from './TerceraChangePasswordScreen';
import { TerceraButtonStyle } from '../../Utils/Enums/ButtonEnums';
import { KeyCode } from '../../Commons/KeyEventProcessor';

export class TerceraRecoverScreen extends TerceraWindowBase {
    public wasPaste: boolean = false;

    public override oninit (): void {
        super.oninit();

        this.on('cancelClick', this.close);
        this.on('recoverClick', this.recover);
        this.observe('valueEmail', (newValue) => {
            void this.set({ valueEmailHaveError: !BrowserUtils.isEmail(newValue) });
        });
        this.observe('iHaveKey', (newValue) => {
            void this.set({ recoverText: newValue ? 'Change' : 'Recover' });
        });

        this.observe('visible', (visible: boolean) => {
            if (visible) {
                this.localize();
            }
        });

        this.observe('iHaveKey valueLogin valueKey valueNewPassword valueConfirm valueEmailHaveError', () => {
            const { iHaveKey, valueLogin, valueKey, valueNewPassword, valueConfirm, valueEmailHaveError } = this.get();
            let btnEnabled = false;
            if (iHaveKey) {
                btnEnabled = valueLogin && valueKey && valueNewPassword && valueConfirm && valueNewPassword === valueConfirm;
            } else {
                btnEnabled = valueLogin && !valueEmailHaveError;
            }
            void this.set('btnRecoverEnable', btnEnabled);
        });

        this.localize();
    }

    public override oncomplete (): void {
        super.oncomplete();
        const loginTextBox = this.Controls.loginTextBox;
        loginTextBox.on('keyDown', this.onKeyDown.bind(this));
        this.trimOnPasteAddEventListener(loginTextBox.getInputElement());

        const keyTextBox = this.Controls.keyTextBox;
        keyTextBox.on('keyDown', this.onKeyDown.bind(this));
        this.trimOnPasteAddEventListener(keyTextBox.getInputElement());
    }

    public override onKeyDown (context): void {
        const ev = context.event;
        if (ev.ctrlKey === true && ev.keyCode === KeyCode.V) {
            this.wasPaste = true;
        }
    }

    public trimOnPasteAddEventListener (input /* : HTMLInputElement */): void {
        if (input == null) {
            return;
        }
        input.addEventListener('input', () => {
            if (this.wasPaste) {
                input.value = input.value.trim();
            }
            this.wasPaste = false;
        });
    }

    public recover (): void {
    // var pwd = this.get('valueNewPassword');
    // if (pwd && pwd.indexOf('&') > -1)
    // {
    //     TerceraMessageBox.Show(
    //         this.get('header'),
    //         Resources.getResource("screen.RecoveryPassForm.New password contains prohibited symbols"),
    //         MessageBoxType.Warning,
    //         null, null, false, true);

        //     this.set({ valueNewPassword: '', valueConfirm: '' });
        //     return;
        // }

        // var iHaveKey = this.get('iHaveKey');
        // if (!iHaveKey)
        //     this.RecoverPass();
        // else
        //     this.SendCodePass();

        this.RecoverPassProcess();
    }

    public RecoverPassProcess (): void {
        const currentConnection = MainWindowManager.MainWindow.Controls.loginScreen.get('connectionParams');
        const iHaveKey = this.get('iHaveKey');
        let request = null;
        if (!iHaveKey) {
            const login = this.get('valueLogin');
            const email = this.get('valueEmail');

            request = {};
            request.msgType = 'SendRequestRecoveryPassword';
            request.dataForMsg = { login, email };
        } else {
            const login = this.get('valueLogin');
            const newPass = this.get('valueNewPassword');
            const key = this.get('valueKey');

            request = {};
            request.msgType = 'SendPasswordRecoveryChangeRequest';
            request.dataForMsg = { login, newPassword: newPass, key, accessToken: currentConnection.accessToken };
        }
        const connectServers = [];
        const splitedConnections = currentConnection.ServerAdress.split(',');
        for (let i = 0, len = splitedConnections.length; i < len; i++) {
            if (splitedConnections[i]) {
                connectServers.push(splitedConnections[i].trim());
            }
        }

        this.SendMessagePromise(request, connectServers, currentConnection, 0).then(this.SendMessageFinish.bind(this));
    }

    public SendMessageFinish (result): void {
        if (result === null || result === 'Bad Arguments') {
            TerceraMessageBox.Show(this.get('header'), Resources.getResource('Bad request arguments'), MessageBoxType.Warning, null, null, false, true);
            return;
        }

        // new Logic
        const status = result.status;
        switch (status) {
        case ChangePasswordStatusEnum.STATUS_ACCEPTED:
            if (this.get('iHaveKey')) {
                TerceraMessageBox.Show(this.get('header'), Resources.getResource('screen.changepass.changed.login'), MessageBoxType.Warning, null, null, false, true);

                this.clearAllAndHide(); // #https://tp.traderevolution.com/entity/123245
                if (MainWindowManager.MainWindow.IsNewNetTrader === true) {
                    this.close();
                }
            } else {
                TerceraMessageBox.Show(this.get('header'), Resources.getResource('screen.RecoveryPassForm.Your request for password recovering has sent successfully. Please check your email!'), MessageBoxType.Warning, null, null, false, true);
                void this.set('iHaveKey', true);
            }
            return;

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_LOW:
            this.showMSG(Resources.getResource('system.ChangePassLow').replace('{0}', result.minLength), async () => { await this.set({ valueNewPassword: '', valueConfirm: '' }); });
            return;

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_BASE:
            this.showMSG(Resources.getResource('system.ChangePassBase').replace('{0}', result.minLength), async () => { await this.set({ valueNewPassword: '', valueConfirm: '' }); });
            return;

        case ChangePasswordStatusEnum.STATUS_STRONG_PAS_HIGH:
            this.showMSG(Resources.getResource('system.ChangePassHigh').replace('{0}', result.minLength), async () => { await this.set({ valueNewPassword: '', valueConfirm: '' }); });
            return;

        case ChangePasswordStatusEnum.STATUS_LOCKED_AFTER_BRUT_FORCE:
            this.showMSG(Resources.getResource('screen.changepass.lockedAfterBrut'), async () => { await this.set({ valueEmail: '', valueLogin: '', valueKey: '', valueNewPassword: '', valueConfirm: '' }); });
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_OLD_OR_NEW_PASSWORD:
            this.showMSG(Resources.getResource('screen.changepass.wrongOldOrNewPassword'), async () => { await this.set({ valueNewPassword: '', valueConfirm: '' }); });
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_KEY:
            this.showMSG(Resources.getResource('screen.changepass.wrongKey'), async () => { await this.set({ valueKey: '', valueNewPassword: '', valueConfirm: '' }); });
            return;

        case ChangePasswordStatusEnum.STATUS_PWD_MUST_DE_DIFFERENT:
            this.showMSG(Resources.getResource('screen.changepass.pwdMustBeDifferent'), async () => { await this.set({ valueNewPassword: '', valueConfirm: '' }); });
            return;

        case ChangePasswordStatusEnum.STATUS_ERROR_EMAIL:
            this.showMSG(Resources.getResource('screen.changepass.errorEmail'), async () => { await this.set({ valueEmail: '' }); });
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_LOGIN:
            this.showMSG(Resources.getResource('screen.changepass.wrongLogin'), async () => { await this.set({ valueEmail: '', valueLogin: '' }); });
            return;

        case ChangePasswordStatusEnum.STATUS_WRONG_MAX_LENGTH:
            this.showMSG(Resources.getResource('system.TooLongPass').replace('{0}', result.MaxLength), async () => { await this.set({ valueNewPassword: '', valueConfirm: '' }); });
            return;

        case ChangePasswordStatusEnum.STATUS_PWD_IS_TOO_EASY:
            this.showMSG(Resources.getResource('screen.changepass.tooeasy'), async () => { await this.set({ valueNewPassword: '', valueConfirm: '' }); });
            return;
        }

        // oldLogic
        this.OldLogicNotification(result.BusinessRejectCode, result.errText);
    }

    public showMSG (body, closeHandler): void {
        if (!closeHandler) {
            closeHandler = null;
        }

        const additionalData = {
            okText: Resources.getResource('general.messageBox.ok') // #123244
        };

        TerceraMessageBox.Show(this.get('header'), body, MessageBoxType.Warning, closeHandler, closeHandler, false, true, null, additionalData);
    }

    public OldLogicNotification (errCode, errText): void {
        switch (errCode) {
        case 381:
            TerceraMessageBox.Show(this.get('header'), Resources.getResource('screen.RecoveryPassForm.invalid_login_or_email'), MessageBoxType.Warning, null, null, false, true);
            return;
        case 384:
            TerceraMessageBox.Show(this.get('header'), Resources.getResource('screen.RecoveryPassForm.invalid_login'), MessageBoxType.Warning, null, null, false, true);
            return;
        case 31:
            TerceraMessageBox.Show(this.get('header'), Resources.getResource('reports.New password must differ from several previous passwords.'), MessageBoxType.Warning, null, null, false, true);
            return;

        default:
            if (Resources.IsResourcePresentEN('BusinessRejectMessage.' + errCode)) {
                TerceraMessageBox.Show(this.get('header'), Resources.getResource('BusinessRejectMessage.' + errCode), MessageBoxType.Warning, null, null, false, true);
            } else {
                TerceraMessageBox.Show(this.get('header'), errText /* + "\n" + errCode */, MessageBoxType.Warning, null, null, false, true);
            }
        }
    }

    public SendMessagePromise (request, connectServers, currentConnection, index): any {
        if (connectServers.length < index + 1) {
            return Promise.resolve('Bad Arguments');
        }

        return Connection.SendMessageToVendor(connectServers[index], currentConnection.isSSL, request.msgType, request.dataForMsg)
            .then((responce) => {
                if (responce === null) {
                    return this.SendMessagePromise(request, connectServers, currentConnection, ++index);
                }

                return responce;
            });
    }

    public override localize (): void {
        void this.set({
            textLoginLabel: Resources.getResource('screen.RecoveryPassForm.Login'),
            textEmailLabel: Resources.getResource('screen.RecoveryPassForm.Email'),
            textKeyLabel: Resources.getResource('screen.RecoveryPassForm.Key'),
            textNewPasswordLabel: Resources.getResource('screen.RecoveryPassForm.New password'),
            textConfirmLabel: Resources.getResource('screen.RecoveryPassForm.Confirm'),
            textIHaveKey: Resources.getResource('screen.RecoveryPassForm.HaveCode'),
            recoverText: Resources.getResource('screen.RecoveryPassForm.Send'),
            cancelText: Resources.getResource('screen.RecoveryPassForm.Cancel'),
            header: Resources.getResource('screen.RecoveryPassForm.Title')

        });
    }

    public clearAllAndHide (): void {
        void super.set({
            visible: false,
            iHaveKey: false,
            valueEmail: '',
            valueKey: '',
            valueNewPassword: '',
            valueConfirm: '',
            newPassHided: true,
            confirmPassHided: true
        });
    }
}

TerceraWindowBase.extendWith(TerceraRecoverScreen, {
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            movable: false,
            showFooter: false,
            resizable: false,
            alwaysMiddle: true,
            focused: true,

            btnRecoverEnable: false,
            btnRecoverStyle: TerceraButtonStyle.Blue,
            btnCancelStyle: TerceraButtonStyle.Standard,

            textLoginLabel: 'Login',
            textEmailLabel: 'Email',
            textKeyLabel: 'Key',
            textNewPasswordLabel: 'New Password',
            textConfirmLabel: 'Confirm',
            textIHaveKey: 'I have a key',
            recoverText: 'Recover',
            cancelText: 'Cancel',
            header: 'Password recovery',

            allowPasswordPreview: false,
            newPassHided: true,
            confirmPassHided: true,

            valueLogin: '',
            valueEmail: '',
            valueKey: '',
            valueNewPassword: '',
            valueConfirm: '',

            iHaveKey: false,

            valueEmailHaveError: true,

            connectionName: ''
        };
    },
    partials: { bodyPartial: TerceraRecoverScreenTemplate }
});
