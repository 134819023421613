// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// #region Base Controls
import Ractive from 'ractive';

import { TerceraPanel } from './Controls/elements/TerceraPanel';
import { TerceraAutoHidePanel } from './Controls/elements/TerceraAutoHidePanel';

import { TerceraLabel } from './Controls/elements/TerceraLabel';
import { TerceraMultiLineLabel } from './Controls/elements/TerceraMultiLineLabel';
import { TerceraLinkLabel } from './Controls/elements/TerceraLinkLabel';
import { DateTimeLabel } from './Controls/elements/DateTimeLabel';

import { TerceraCheckBox } from './Controls/elements/TerceraCheckBox';
import { TerceraCheckBoxGroup } from './Controls/elements/TerceraCheckBoxGroup';
import { TerceraCheckBoxEvent } from './Controls/elements/TerceraCheckBoxEvent';
import { TerceraCheckBoxButton } from './Controls/elements/TerceraCheckBoxButton';

import { Combobox } from './Controls/elements/Combobox';
import { TerceraComboBox } from './Controls/elements/TerceraComboBox';
import { TerceraWidthComboBox } from './Controls/elements/TerceraWidthComboBox';
import { TerceraLineStyleComboBox } from './Controls/elements/TerceraLineStyleComboBox';
import { TerceraMultiComboBox } from './Controls/elements/TerceraMultiComboBox';

import { TerceraRadioButton } from './Controls/elements/TerceraRadioButton';
import { TerceraRadioButtonComponent } from './Controls/elements/TerceraRadioButtonComponent';
import { TerceraRadioComboItemGroup } from './Controls/elements/TerceraRadioComboItemGroup';

import { TerceraPictureBox } from './Controls/elements/TerceraPictureBox';

import { TerceraTextBox } from './Controls/elements/TerceraTextBox';

import { PasswordTextBox } from './Controls/elements/PasswordTextBox';

import { TerceraButton } from './Controls/elements/TerceraButton';
import { TerceraGroupButtons } from './Controls/elements/TerceraGroupButtons';
import { TerceraMainHeaderButton } from './Controls/elements/TerceraMainHeaderButton';
import { AccountWidgetMainHeaderButton } from './Controls/elements/AccountWidget/AccountWidgetMainHeaderButton';
import { TradingButtonStripe } from './Controls/elements/TradingButtonStripe';

import { TerceraBoolNumeric } from './Controls/elements/TerceraBoolNumeric';

import { TerceraSeparator } from './Controls/elements/TerceraSeparator';
import { TerceraPropertyGroupSeparator } from './Controls/elements/TerceraPropertyGroupSeparator';

import { TerceraColorStyleWidthPicker } from './Controls/elements/TerceraColorStyleWidthPicker';
import { TerceraDateTimePicker } from './Controls/elements/DateTimePicker/TerceraDateTimePicker';
import { TerceraDatePickerCalendarComponent } from './Controls/elements/DateTimePicker/TerceraDatePickerCalendarComponent';
import { TerceraFontPicker } from './Controls/elements/TerceraFontPicker';

import { TerceraMenu } from './Controls/elements/TerceraMenu';

import { TerceraHeaderMenuControl } from './Controls/elements/TerceraHeaderMenuControl';

import { TerceraNumeric } from './Controls/elements/TerceraNumeric';
import { TerceraSLTPNumeric } from './Controls/elements/TerceraSLTPNumeric';

import { TerceraTabContainer } from './Controls/elements/TerceraTabContainer';
import { TerceraTabStrip } from './Controls/elements/TerceraTabStrip';

import { TerceraWarningMessage } from './Controls/elements/TerceraWarningMessage';

import { ColorPicker } from './Controls/elements/ColorPicker';

import { TerceraPopupBaloon } from './Controls/elements/TerceraPopupBaloon';

import { TerceraButtonsPopup } from './Controls/elements/TerceraButtonsPopup';

import { GridPicker } from './Controls/elements/GridPicker';

import { TerceraFileUploader } from './Controls/elements/TerceraFileUploader';
import { TerceraImageUploader } from './Controls/elements/TerceraImageUploader';

import { PanelsContainerControl } from './Controls/elements/PanelsContainerControl';

import { ResizeSplitter } from './Controls/elements/ResizeSplitter';

import { TerceraGroupPanel } from './Controls/elements/TerceraGroupPanel';

// #endregion Base Controls

// #region Specific Controls
import { TerceraLinkControl } from './Controls/elements/TerceraLinkControl';
import { PairColorPicker } from './Controls/elements/PairColorPicker';
import { TrioColorPicker } from './Controls/elements/TrioColorPicker';
import { TrioColorGradient } from './Controls/elements/TrioColorGradient';
import { TerceraButtonColorPicker } from './Controls/elements/TerceraButtonColorPicker';
import { AccountWidget } from './Controls/elements/AccountWidget';
import { AccountWidgetItem } from './Controls/elements/AccountWidget/AccountWidgetItem';
import { GridPanelEdit } from './Controls/elements/GridPanelEdit';
import { IdeasButton } from './Controls/elements/IdeasButton';
import { InstrumentTotalInfo } from './Controls/elements/InstrumentTotalInfo';
import { NotificationsButton } from './Controls/elements/NotificationsButton';
import { VideoWidgetButton } from './Controls/panels/VideoWidget/VideoWidgetButton';
import { OrderInfoView } from './Controls/elements/OrderInfoView';
import { PositionInfoView } from './Controls/elements/PositionInfoView';
import { ProductsComponent } from './Controls/elements/ProductsComponent';
import { QuickTableComboBox } from './Controls/elements/QuickTableComboBox';
import { QuickTableTifComboBox } from './Controls/elements/QuickTableTifComboBox';
import { QuickTableNumeric } from './Controls/elements/QuickTableNumeric';
import { QuickTableAccountLookup } from './Controls/elements/QuickTableAccountLookup';
import { QuickTableAccountsComboBox } from './Controls/elements/QuickTableAccountsComboBox';
import { QuickTableInstrumentLookup } from './Controls/elements/QuickTableInstrumentLookup';
import { SLTPTriggerControl } from './Controls/elements/SLTPTriggerControl';
import { TerceraChartToolsPanel } from './Controls/elements/TerceraChartToolsPanel';
import { TerceraCollapseLink } from './Controls/elements/TerceraCollapseLink';
import { TerceraEditableListComboBox } from './Controls/elements/TerceraEditableListComboBox';
import { TerceraEventLog } from './Controls/elements/TerceraEventLog';
import { TerceraLevel1Panel } from './Controls/elements/TerceraLevel1Panel';
import { TerceraQuantityNumeric } from './Controls/elements/TerceraQuantityNumeric';
import { TerceraRangeControl } from './Controls/elements/TerceraRangeControl';
import { TerceraTicketViewer } from './Controls/elements/TerceraTicketViewer';
import { TerceraTIFComboBox } from './Controls/elements/TerceraTIFComboBox';
import { TerceraTimeZoneSelector } from './Controls/elements/TerceraTimeZoneSelector';
import { TerceraVisualTradingWidget } from './Controls/elements/TerceraVisualTradingWidget';
// #endregion Specific Controls

// #region Lookup
import { InstrumentsDefaultsLookup } from './Controls/elements/Lookup/InstrumentsDefaultsLookup';
import { TerceraAccountLookup } from './Controls/elements/Lookup/TerceraAccountLookup';
import { TerceraDateRangeLookup } from './Controls/elements/Lookup/TerceraDateRangeLookup';
import { TerceraDateSelectorLookup } from './Controls/elements/Lookup/TerceraDateSelectorLookup';
import { TerceraFontLookup } from './Controls/elements/Lookup/TerceraFontLookup';
import { TerceraInstrumentLookup } from './Controls/elements/Lookup/TerceraInstrumentLookup';
import { TerceraInstrumentLookupTextBox } from './Controls/elements/Lookup/TerceraInstrumentLookupTextBox';
// #endregion Lookup

import { AccountsComboBox } from './Controls/elements/AccountsComboBox';
import { AccountSelector } from './Controls/elements/AccountSelector';

// #region QuickTable
import { QuickTableRactive } from './Controls/elements/QuickTable/QuickTableRactive';
// #endregion QuickTable

// #region QuickTree
import { TerceraQuickTree } from './Controls/elements/QuickTree/TerceraQuickTree';
// #endregion QuickTree

// #region Chart
import { TerceraChartRactive } from './Controls/elements/TerceraChartRactive/TerceraChartRactive';
import { OptionAnalyzerChartRactive } from './Controls/elements/TerceraChartRactive/OptionAnalyzerChartRactive';
import { OptionVolatilityLabChartRactive } from './Controls/elements/TerceraChartRactive/OptionVolatilityLabChartRactive';
import { ChartPanel } from './Controls/panels/ChartPanel';
// #endregion Chart

// #region Panels
import { AdvancedChartOrderEntry } from './Controls/panels/AdvancedChartOrderEntry';
import { MarginOEControl } from './Controls/panels/MarginOEControl';
import { RangeSelectPanel } from './Controls/panels/RangeSelectPanel';
// #endregion Panels

// #region Screens
import { ExternalScreen } from './Controls/screen/ExternalScreen';
import { TerceraLoginScreen } from './Controls/screen/TerceraLoginScreen';
import { TerceraBrokerMessageScreen } from './Controls/screen/TerceraBrokerMessageScreen';
import { NewNetTrader } from './Controls/screen/NewNetTrader';
import { TerceraMessageBox } from './Controls/screen/TerceraMessageBox';
// #endregion Screens

// #region Trading controls
import { BuySellMDButton } from './Controls/trading/BuySellMDButton';
import { CancelOrdersMDButton } from './Controls/trading/CancelOrdersMDButton';
import { DataSourceWidget } from './Controls/trading/DataSourceWidget';
import { DPAttentionMsg } from './Controls/trading/DPAttentionMsg';
import { DPBoundToControl } from './Controls/trading/DPBoundToControl';
import { DPPositionSizingControls } from './Controls/trading/DPPositionSizingControls';
import { DPProductTypeAndModifyQuantity } from './Controls/trading/DPProductTypeAndModifyQuantity';
import { DPSLTPControl } from './Controls/trading/DPSLTPControl';
import { DPTradingNumeric } from './Controls/trading/DPTradingNumeric';
import { QuotePricePicker } from './Controls/trading/QuotePricePicker';
// #endregion Trading controls

// #region Alerts
import { CreateAlertActionSelector } from './Controls/trading/alerts/CreateAlertActionSelector';
import { CreateAlertConditionSelector } from './Controls/trading/alerts/CreateAlertConditionSelector';
import { CreateAlertExecuteSelector } from './Controls/trading/alerts/CreateAlertExecuteSelector';
import { CreateAlertExpirationSelector } from './Controls/trading/alerts/CreateAlertExpirationSelector';
import { CreateAlertImportanceSelector } from './Controls/trading/alerts/CreateAlertImportanceSelector';
import { CreateAlertInstrumentLookup } from './Controls/trading/alerts/CreateAlertInstrumentLookup';
import { CreateAlertAccountLookup } from './Controls/trading/alerts/CreateAlertAccountLookup';
import { CreateAlertMessageSelector } from './Controls/trading/alerts/CreateAlertMessageSelector';
import { CreateAlertNotificationSelector } from './Controls/trading/alerts/CreateAlertNotificationSelector';
import { CreateAlertOrderParametrsSelector } from './Controls/trading/alerts/CreateAlertOrderParametrsSelector';
import { CreateAlertTypeSelector } from './Controls/trading/alerts/CreateAlertTypeSelector';
import { CreateAlertValueSelector } from './Controls/trading/alerts/CreateAlertValueSelector';
import { CreateAlertWidget } from './Controls/trading/alerts/CreateAlertWidget';
// #endregion Alerts

// #region Order entry
import { OEAccountLookup } from './Controls/trading/OE/OEAccountLookup';
import { OEInstrumentLookup } from './Controls/trading/OE/OEInstrumentLookup';
import { OELeverageSelector } from './Controls/trading/OE/OELeverageSelector';
import { OEOrderTypeSelector } from './Controls/trading/OE/OEOrderTypeSelector';
import { OEPlaceOrderButton } from './Controls/trading/OE/OEPlaceOrderButton';
import { OEProductTypeSelector } from './Controls/trading/OE/OEProductTypeSelector';
import { OEQuantity } from './Controls/trading/OE/OEQuantity';
import { OEQuote1Viewer } from './Controls/trading/OE/OEQuote1Viewer';
import { OESideSelector } from './Controls/trading/OE/OESideSelector';
import { OETIFSelector } from './Controls/trading/OE/OETIFSelector';
import { PositionSizeButton } from './Controls/trading/OE/PositionSizeButton';
// #endregion Order entry

// #region  TradingView
import { TradingViewBaseTool } from './Utils/TradingView/TradingViewBaseTool';
import { TradingViewAlertTool } from './Utils/TradingView/TradingViewAlertTool';
import { TradingViewPositionTool } from './Utils/TradingView/TradingViewPositionTool';
import { TradingViewOrderTool } from './Utils/TradingView/TradingViewOrderTool';
import { TradingViewToolsContainer } from './Utils/TradingView/TradingViewToolsContainer';
// #endregion Potfolio

// #region CanvasControl
import { HeatmapRactive } from './Controls/elements/Canvas/HeatmapRactive';
import { HeatmapSymbolInfo } from './Controls/panels/Heatmap/HeatmapSymbolInfo';
import { HeatmapFilter } from './Controls/panels/Heatmap/HeatmapFilter';
// #endregion

import { SideBar } from './Controls/elements/SideBar/SideBar';
// #endregion

// #region OptionMaster
import { OptionChainPanel } from './Controls/panels/OptionMaster/OptionChainPanel';
import { OptionAnalyzerPanel } from './Controls/panels/OptionMaster/OptionAnalyzerPanel';
import { OptionVolatilityLabPanel } from './Controls/panels/OptionMaster/OptionVolatilityLabPanel';
import { OptionPositionsPanel } from './Controls/panels/OptionMaster/OptionPositionsPanel';
import { OptionOrdersPanel } from './Controls/panels/OptionMaster/OptionOrdersPanel';
import { OptionPaperPositionsPanel } from './Controls/panels/OptionMaster/OptionPaperPositionsPanel';
import { OptionPortfolioControl } from './Controls/panels/OptionMaster/OptionPortfolioControl';
// #endregion

// #region NewGui
import { TerceraSideBarButton } from './Controls/elements/SideBar/TerceraSideBarButton';
import { DropDownSideBar } from './Controls/elements/SideBar/DropDownSideBar';
import { AlertPanelSideBar } from './Controls/elements/SideBar/SideBarPanels/AlertPanelSideBar';
import { AlertItemSideBar } from './Controls/elements/SideBar/SideBarItems/AlertItemSideBar';
import { AlertsButton } from './Controls/elements/AlertsButton';
import { IdeasPanelSideBar } from './Controls/elements/SideBar/SideBarPanels/IdeasPanelSideBar';
import { IdeasItemSideBar } from './Controls/elements/SideBar/SideBarItems/IdeasItemSideBar';
import { NotificationsPanelSideBar } from './Controls/elements/SideBar/SideBarPanels/NotificationsPanelSideBar';
import { NotificationsItemSideBar } from './Controls/elements/SideBar/SideBarItems/NotificationsItemSideBar';
import { VideoWidgetPanelSideBar } from './Controls/elements/SideBar/SideBarPanels/VideoWidgetPanelSideBar';
import { VideoWidgetItemSideBar } from './Controls/elements/SideBar/SideBarItems/VideoWidgetItemSideBar';
// #endregion
import { NewAccountDetailsPanel } from './Controls/panels/VerticalPanels/NewAccountDetailsPanel';
import { VerticalDetailsGroup } from './Controls/elements/VerticalPanel/VerticalDetailsGroup';
import { VerticalDetailsItem } from './Controls/elements/VerticalPanel/VerticalDetailsItem';
// #region NewAccountDetails

Ractive.components.terceraPanel = TerceraPanel;
Ractive.components.terceraAutoHidePanel = TerceraAutoHidePanel;
Ractive.components.terceraLabel = TerceraLabel;
Ractive.components.terceraMultiLineLabel = TerceraMultiLineLabel;
Ractive.components.terceraLinkLabel = TerceraLinkLabel;
Ractive.components.dateTimeLabel = DateTimeLabel;
Ractive.components.terceraCheckBox = TerceraCheckBox;
Ractive.components.terceraCheckBoxGroup = TerceraCheckBoxGroup;
Ractive.components.terceraCheckBoxEvent = TerceraCheckBoxEvent;
Ractive.components.terceraCheckBoxButton = TerceraCheckBoxButton;
Ractive.components.combobox = Combobox;
Ractive.components.terceraComboBox = TerceraComboBox;
Ractive.components.terceraWidthComboBox = TerceraWidthComboBox;
Ractive.components.terceraLineStyleComboBox = TerceraLineStyleComboBox;
Ractive.components.terceraMultiComboBox = TerceraMultiComboBox;
Ractive.components.terceraRadioButton = TerceraRadioButton;
Ractive.components.terceraRadioButtonComponent = TerceraRadioButtonComponent;
Ractive.components.terceraRadioComboItemGroup = TerceraRadioComboItemGroup;
Ractive.components.terceraPictureBox = TerceraPictureBox;
Ractive.components.terceraTextBox = TerceraTextBox;
Ractive.components.passwordTextBox = PasswordTextBox;
Ractive.components.terceraButton = TerceraButton;
Ractive.components.terceraGroupButtons = TerceraGroupButtons;
Ractive.components.terceraMainHeaderButton = TerceraMainHeaderButton;
Ractive.components.accountWidgetMainHeaderButton = AccountWidgetMainHeaderButton;
Ractive.components.tradingButtonStripe = TradingButtonStripe;
Ractive.components.terceraBoolNumeric = TerceraBoolNumeric;
Ractive.components.terceraSeparator = TerceraSeparator;
Ractive.components.terceraPropertyGroupSeparator = TerceraPropertyGroupSeparator;
Ractive.components.terceraColorStyleWidthPicker = TerceraColorStyleWidthPicker;
Ractive.components.terceraDateTimePicker = TerceraDateTimePicker;
Ractive.components.terceraDatePickerCalendarComponent = TerceraDatePickerCalendarComponent;
Ractive.components.terceraFontPicker = TerceraFontPicker;
Ractive.components.terceraMenu = TerceraMenu;
Ractive.components.headerMenuControl = TerceraHeaderMenuControl;
Ractive.components.terceraNumeric = TerceraNumeric;
Ractive.components.terceraSLTPNumeric = TerceraSLTPNumeric;
Ractive.components.terceraTabContainer = TerceraTabContainer;
Ractive.components.terceraTabStrip = TerceraTabStrip;
Ractive.components.terceraWarningMessage = TerceraWarningMessage;
Ractive.components.colorPicker = ColorPicker;
Ractive.components.terceraPopupBaloon = TerceraPopupBaloon;
Ractive.components.terceraButtonsPopup = TerceraButtonsPopup;
Ractive.components.gridPicker = GridPicker;
Ractive.components.terceraFileUploader = TerceraFileUploader;
Ractive.components.terceraImageUploader = TerceraImageUploader;
Ractive.components.panelsContainerControl = PanelsContainerControl;
Ractive.components.resizeSplitter = ResizeSplitter;
Ractive.components.terceraGroupPanel = TerceraGroupPanel;
Ractive.components.terceraLinkControl = TerceraLinkControl;
Ractive.components.pairColorPicker = PairColorPicker;
Ractive.components.trioColorPicker = TrioColorPicker;
Ractive.components.trioColorGradient = TrioColorGradient;
Ractive.components.terceraButtonColorPicker = TerceraButtonColorPicker;
Ractive.components.accountWidget = AccountWidget;
Ractive.components.accountWidgetItem = AccountWidgetItem;
Ractive.components.gridPanelEdit = GridPanelEdit;
Ractive.components.ideasButton = IdeasButton;
Ractive.components.instrumentTotalInfo = InstrumentTotalInfo;
Ractive.components.notificationsButton = NotificationsButton;
Ractive.components.videoWidgetButton = VideoWidgetButton;
Ractive.components.orderInfoView = OrderInfoView;
Ractive.components.positionInfoView = PositionInfoView;
Ractive.components.productsComponent = ProductsComponent;
Ractive.components.quickTableComboBox = QuickTableComboBox;
Ractive.components.quickTableTifComboBox = QuickTableTifComboBox;
Ractive.components.quickTableNumeric = QuickTableNumeric;
Ractive.components.quickTableAccountLookup = QuickTableAccountLookup;
Ractive.components.quickTableAccountsComboBox = QuickTableAccountsComboBox;
Ractive.components.quickTableInstrumentLookup = QuickTableInstrumentLookup;
Ractive.components.sltpTriggerControl = SLTPTriggerControl;
Ractive.components.terceraChartToolsPanel = TerceraChartToolsPanel;
Ractive.components.terceraCollapseLink = TerceraCollapseLink;
Ractive.components.terceraEditableListComboBox = TerceraEditableListComboBox;
Ractive.components.terceraEventLog = TerceraEventLog;
Ractive.components.terceraLevel1Panel = TerceraLevel1Panel;
Ractive.components.terceraQuantityNumeric = TerceraQuantityNumeric;
Ractive.components.terceraRangeControl = TerceraRangeControl;
Ractive.components.terceraTicketViewer = TerceraTicketViewer;
Ractive.components.terceraTIFComboBox = TerceraTIFComboBox;
Ractive.components.terceraTimeZoneSelector = TerceraTimeZoneSelector;
Ractive.components.terceraVisualTradingWidget = TerceraVisualTradingWidget;
Ractive.components.instrumentsDefaultsLookup = InstrumentsDefaultsLookup;
Ractive.components.terceraAccountLookup = TerceraAccountLookup;
Ractive.components.terceraDateRangeLookup = TerceraDateRangeLookup;
Ractive.components.terceraDateSelectorLookup = TerceraDateSelectorLookup;
Ractive.components.terceraFontLookup = TerceraFontLookup;
Ractive.components.terceraInstrumentLookup = TerceraInstrumentLookup;
Ractive.components.terceraInstrumentLookupTextBox = TerceraInstrumentLookupTextBox;
Ractive.components.accountsComboBox = AccountsComboBox;
Ractive.components.accountSelector = AccountSelector;
Ractive.components.quickTableRactive = QuickTableRactive;
Ractive.components.terceraQuickTree = TerceraQuickTree;
Ractive.components.terceraChartRactive = TerceraChartRactive;
Ractive.components.optionAnalyzerChartRactive = OptionAnalyzerChartRactive;
Ractive.components.optionVolatilityLabChartRactive = OptionVolatilityLabChartRactive;
Ractive.components.chartPanel = ChartPanel;
Ractive.components.advancedChartOrderEntry = AdvancedChartOrderEntry;
Ractive.components.marginOEControl = MarginOEControl;
Ractive.components.rangeSelectPanel = RangeSelectPanel;
Ractive.components.externalScreen = ExternalScreen;
Ractive.components.terceraLoginScreen = TerceraLoginScreen;
Ractive.components.terceraBrokerMessageScreen = TerceraBrokerMessageScreen;
Ractive.components.newNetTrader = NewNetTrader;
Ractive.components.BuySellMDButton = BuySellMDButton;
Ractive.components.CancelOrdersMDButton = CancelOrdersMDButton;
Ractive.components.dataSourceWidget = DataSourceWidget;
Ractive.components.DPAttentionMsg = DPAttentionMsg;
Ractive.components.DPBoundToControl = DPBoundToControl;
Ractive.components.DPPositionSizingControls = DPPositionSizingControls;
Ractive.components.DPProductTypeAndModifyQuantity = DPProductTypeAndModifyQuantity;
Ractive.components.DPSLTPControl = DPSLTPControl;
Ractive.components.DPTradingNumeric = DPTradingNumeric;
Ractive.components.QuotePricePicker = QuotePricePicker;
Ractive.components.CreateAlertActionSelector = CreateAlertActionSelector;
Ractive.components.CreateAlertConditionSelector = CreateAlertConditionSelector;
Ractive.components.CreateAlertExecuteSelector = CreateAlertExecuteSelector;
Ractive.components.CreateAlertExpirationSelector = CreateAlertExpirationSelector;
Ractive.components.CreateAlertImportanceSelector = CreateAlertImportanceSelector;
Ractive.components.CreateAlertInstrumentLookup = CreateAlertInstrumentLookup;
Ractive.components.CreateAlertAccountLookup = CreateAlertAccountLookup;
Ractive.components.CreateAlertMessageSelector = CreateAlertMessageSelector;
Ractive.components.CreateAlertNotificationSelector = CreateAlertNotificationSelector;
Ractive.components.CreateAlertOrderParametrsSelector = CreateAlertOrderParametrsSelector;
Ractive.components.CreateAlertTypeSelector = CreateAlertTypeSelector;
Ractive.components.CreateAlertValueSelector = CreateAlertValueSelector;
Ractive.components.CreateAlertWidget = CreateAlertWidget;
Ractive.components.OEAccountLookup = OEAccountLookup;
Ractive.components.OEInstrumentLookup = OEInstrumentLookup;
Ractive.components.OELeverageSelector = OELeverageSelector;
Ractive.components.OEOrderTypeSelector = OEOrderTypeSelector;
Ractive.components.OEPlaceOrderButton = OEPlaceOrderButton;
Ractive.components.OEProductTypeSelector = OEProductTypeSelector;
Ractive.components.OEQuantity = OEQuantity;
Ractive.components.OEQuote1Viewer = OEQuote1Viewer;
Ractive.components.OESideSelector = OESideSelector;
Ractive.components.OETIFSelector = OETIFSelector;
Ractive.components.PositionSizeButton = PositionSizeButton;
Ractive.components.tradingViewBaseTool = TradingViewBaseTool;
Ractive.components.tradingViewAlertTool = TradingViewAlertTool;
Ractive.components.tradingViewPositionTool = TradingViewPositionTool;
Ractive.components.tradingViewOrderTool = TradingViewOrderTool;
Ractive.components.tradingViewToolsContainer = TradingViewToolsContainer;
Ractive.components.heatmapRactive = HeatmapRactive;
Ractive.components.heatmapSymbolInfo = HeatmapSymbolInfo;
Ractive.components.heatmapFilter = HeatmapFilter;
Ractive.components.sideBar = SideBar;
Ractive.components.optionChainPanel = OptionChainPanel;
Ractive.components.optionAnalyzerPanel = OptionAnalyzerPanel;
Ractive.components.optionVolatilityLabPanel = OptionVolatilityLabPanel;
Ractive.components.optionPositionsPanel = OptionPositionsPanel;
Ractive.components.optionOrdersPanel = OptionOrdersPanel;
Ractive.components.optionPaperPositionsPanel = OptionPaperPositionsPanel;
Ractive.components.optionPortfolioControl = OptionPortfolioControl;
Ractive.components.terceraSideBarButton = TerceraSideBarButton;
Ractive.components.dropDownSideBar = DropDownSideBar;
Ractive.components.alertPanelSideBar = AlertPanelSideBar;
Ractive.components.alertItemSideBar = AlertItemSideBar;
Ractive.components.alertsButton = AlertsButton;
Ractive.components.ideasPanelSideBar = IdeasPanelSideBar;
Ractive.components.ideasItemSideBar = IdeasItemSideBar;
Ractive.components.notificationsPanelSideBar = NotificationsPanelSideBar;
Ractive.components.notificationsItemSideBar = NotificationsItemSideBar;
Ractive.components.videoWidgetPanelSideBar = VideoWidgetPanelSideBar;
Ractive.components.videoWidgetItemSideBar = VideoWidgetItemSideBar;
Ractive.components.newAccountDetailsPanel = NewAccountDetailsPanel;
Ractive.components.terceraMessageBox = TerceraMessageBox;
Ractive.components.verticalDetailsGroup = VerticalDetailsGroup;
Ractive.components.verticalDetailsItem = VerticalDetailsItem;
// #endregion
