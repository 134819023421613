// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { contextMenuHandler } from '../../Utils/AppHandlers';
import { TerceraHeaderMenuControlTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';

interface IMenuItem {
    text: string
    event: any
    type: number
    visible: boolean
}

export class TerceraHeaderMenuControl extends ContainerControl {
    public static readonly ATTACH = 1;
    public static readonly DETTACH = 2;
    public static readonly HeaderMenuDataArray = [
        { textKey: 'panel.external.Attach', type: TerceraHeaderMenuControl.ATTACH },
        { textKey: 'panel.external.Dettach', type: TerceraHeaderMenuControl.DETTACH }
    ];

    public menuitems: IMenuItem[] = null;
    public completed: boolean;

    constructor () { super(); }

    public override getType (): string { return 'TerceraHeaderMenuControl'; }

    public override oninit (): void {
        super.oninit();

        this.on('btnClick', () => {
            contextMenuHandler.Show(this.menuitems, this.getX(), this.getY() + 24);
        });
        this.observe('attach', (newVal, oldVal, key) => { this.initContextMenu(); }, { init: false });
        this.observe('tooltip', function (newVal, oldVal, key) {
            const x = 0;
        });
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.initContextMenu();

        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.completed = true;
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public localize (): void {
        const menuitems = this.menuitems;
        if (!isValidArray(menuitems)) return;

        const HeaderMenuDataArray = TerceraHeaderMenuControl.HeaderMenuDataArray;
        const len = HeaderMenuDataArray.length;
        for (let i = 0; i < len; i++) {
            const mi = menuitems[i];
            const textKey = HeaderMenuDataArray[i].textKey;
            mi.text = Resources.getResource(textKey);
        }
    }

    public initContextMenu (): void {
        const clickItemEvent = function (curItem) {
        // второй параметр итем по которому кликнули
            this.fire('MenuClicked', curItem);
        }.bind(this);

        const length = TerceraHeaderMenuControl.HeaderMenuDataArray.length;
        const items = [];
        const attach = this.get<boolean>('attach');
        for (let i = 0; i < length; i++) {
            const curData = TerceraHeaderMenuControl.HeaderMenuDataArray[i];

            // if (attach && curData.type === TerceraHeaderMenuControl.DETTACH || !attach && curData.type === TerceraHeaderMenuControl.ATTACH)
            //     continue;

            const newItem: IMenuItem = {
                text: Resources.getResource(curData.textKey),
                event: clickItemEvent,
                type: curData.type,
                visible: !(attach && curData.type === TerceraHeaderMenuControl.DETTACH || !attach && curData.type === TerceraHeaderMenuControl.ATTACH)
            };
            items.push(newItem);
        }
        this.menuitems = items;
    }
}

ContainerControl.extendWith(TerceraHeaderMenuControl, {

    template: TerceraHeaderMenuControlTemplate,
    data: function () {
        return {
            btnClass: '',
            attach: true
        };
    }
});
