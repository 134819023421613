// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { alertMessageScreenHandler } from '../../Utils/AppHandlers';
import { KeyEventProcessor } from '../../Commons/KeyEventProcessor';
import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { type MessageBoxType, TerceraMessageBox } from './TerceraMessageBox';
import { ThemeManager } from '../misc/ThemeManager';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';

export class AlertMessageBox extends TerceraMessageBox {
    constructor () { super(); }

    public override getType (): ScreensNames { return ScreensNames.AlertMessageBox; }

    public override oninit (): void {
        super.oninit();

        this.observe('messageBoxType', (newValue) => {
            let imgUrl = null;
            switch (newValue) {
            case AlertMessageBoxType.HIGH:
                imgUrl = 'i_messagebox/alert_high_message.png';
                break;
            case AlertMessageBoxType.MEDIUM:
                imgUrl = 'i_messagebox/alert_medium_message.png';
                break;
            case AlertMessageBoxType.LOW:
                imgUrl = 'i_messagebox/alert_low_message.png';
                break;
            }

            void this.set({ pictureUrl: ThemeManager.getImageFullUrlNew(imgUrl) });
        });
    }

    public static override Show (headerText: string, bodyText: string, type: AlertMessageBoxType | MessageBoxType): AlertMessageBox {
        const alertMessageBox = new AlertMessageBox();
        void alertMessageBox.set({
            header: headerText,
            messageBoxText: bodyText,
            messageBoxType: type
        });

        MainWindowManager.MainWindow.addControl(alertMessageBox);
        alertMessageBox.setFocus();

        const keyProc = KeyEventProcessor;
        keyProc.OnKeyDown.Subscribe(alertMessageBox.onGlobalKeyDown, alertMessageBox);

        return alertMessageBox;
    }

    public static alertMessageScreenHandlerInitialize (): void {
        alertMessageScreenHandler.Show = AlertMessageBox.Show;
    }
}

export enum AlertMessageBoxType {
    HIGH = 1,
    MEDIUM = 5,
    LOW = 10
};

TerceraMessageBox.extendWith(AlertMessageBox, {
    data: function () {
        return {
            hideCancel: true,
            okText: Resources.getResource('general.messageBox.ok')
        };
    }
});
