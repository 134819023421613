// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { ProductType, getProductTypeKey } from '../../../Utils/Instruments/ProductType';
import { DataCache } from '../../DataCache';
import { Resources } from '../../properties/Resources';
import { type PositionsBalance } from '../PositionsBalance';

const NOT_AVAILABLE = '-';

export class PositionsBalanceFormatter {
    static SymbolStr (positionsBalance: PositionsBalance): any {
        if (positionsBalance.Position.ProductType === ProductType.CorporateAction) {
            return positionsBalance.Position.GetInstrumentDisplayName(true);
        } else {
            return positionsBalance.Instrument.DisplayShortName();
        }
    }

    static NetPositionQty (positionsBalance: PositionsBalance): any {
        const netPositionQty = positionsBalance.NetPositionQty;
        if (GeneralSettings.View.DisplayQuantityInLots) { return netPositionQty; } else { return netPositionQty * positionsBalance.Instrument.LotSize; }
    }

    static NetPositionQtyStr (positionsBalance: PositionsBalance): any {
        const netPositionQty = this.NetPositionQty(positionsBalance);
        return DataCache.formatVolume(positionsBalance.Instrument, netPositionQty, GeneralSettings.View.DisplayQuantityInLots, positionsBalance.ProductType, positionsBalance.getAccountWithMaxAmountPrecision());
    }

    static GrossPnL (positionsBalance: PositionsBalance): any {
        return positionsBalance.GrossPnL;
    }

    static GrossPnLStr (positionsBalance: PositionsBalance): any {
        const grossPnL = this.GrossPnL(positionsBalance);
        return positionsBalance.Asset?.formatPrice(grossPnL);
    }

    static CurrentPrice (positionsBalance: PositionsBalance): any {
        return positionsBalance.CurrentPrice;
    }

    static CurrentPriceStr (positionsBalance: PositionsBalance): string {
        const currentPrice = this.CurrentPrice(positionsBalance);
        return isNaN(currentPrice) ? NOT_AVAILABLE : positionsBalance.Instrument.formatPrice(currentPrice);
    }

    static BreakEven (positionsBalance: PositionsBalance): any {
        return positionsBalance.BreakEvenPoint;
    }

    static BreakEvenStr (positionsBalance: PositionsBalance): string {
        const breakevenPoint = this.BreakEven(positionsBalance);
        return isNaN(breakevenPoint) ? NOT_AVAILABLE : positionsBalance.Instrument.formatPrice(breakevenPoint, true, true);
    }

    static GrossExposure (positionsBalance: PositionsBalance): any {
        return positionsBalance.GrossExposure;
    }

    static GrossExposureStr (positionsBalance: PositionsBalance): string {
        const grossExposure = this.GrossExposure(positionsBalance);
        return positionsBalance.Asset?.formatPrice(grossExposure);
    }

    static NetExposure (positionsBalance: PositionsBalance): any {
        return positionsBalance.NetExposure;
    }

    static NetExposureStr (positionsBalance: PositionsBalance): string {
        const netExposure = this.NetExposure(positionsBalance);
        return positionsBalance.Asset?.formatPrice(netExposure);
    }

    static LongQty (positionsBalance: PositionsBalance): any {
        const longQty = positionsBalance.LongQty;
        if (GeneralSettings.View.DisplayQuantityInLots) { return longQty; } else { return longQty * positionsBalance.Instrument.LotSize; }
    }

    static LongQtyStr (positionsBalance: PositionsBalance): string {
        const longQty = this.LongQty(positionsBalance);
        return DataCache.formatVolume(positionsBalance.Instrument, Math.abs(longQty), GeneralSettings.View.DisplayQuantityInLots, positionsBalance.ProductType, positionsBalance.getAccountWithMaxAmountPrecision());
    }

    static ShortQty (positionsBalance: PositionsBalance): any {
        const shortQty = positionsBalance.ShortQty;
        if (GeneralSettings.View.DisplayQuantityInLots) { return shortQty; } else { return shortQty * positionsBalance.Instrument.LotSize; }
    }

    static ShortQtyStr (positionsBalance: PositionsBalance): string {
        const shortQty = this.ShortQty(positionsBalance);
        return DataCache.formatVolume(positionsBalance.Instrument, Math.abs(shortQty), GeneralSettings.View.DisplayQuantityInLots, positionsBalance.ProductType, positionsBalance.getAccountWithMaxAmountPrecision());
    }

    static AverageLong (positionsBalance: PositionsBalance): any {
        return positionsBalance.AverageLong;
    }

    static AverageLongStr (positionsBalance: PositionsBalance): string {
        const averageLong = this.AverageLong(positionsBalance);
        return isNaN(averageLong) ? NOT_AVAILABLE : positionsBalance.Instrument.formatPrice(averageLong);
    }

    static AverageShort (positionsBalance: PositionsBalance): any {
        return positionsBalance.AverageShort;
    }

    static AverageShortStr (positionsBalance: PositionsBalance): string {
        const averageShort = this.AverageShort(positionsBalance);
        return isNaN(averageShort) ? NOT_AVAILABLE : positionsBalance.Instrument.formatPrice(averageShort);
    }

    static PositionValue (positionsBalance: PositionsBalance): any {
        return positionsBalance.PositionValue;
    }

    static PositionValueStr (positionsBalance: PositionsBalance): string {
        const positionValue = this.PositionValue(positionsBalance);
        return positionsBalance.Asset?.formatPrice(positionValue);
    }

    static ProductTypeStr (positionsBalance: PositionsBalance): string {
        const productType = positionsBalance.Position.ProductType;
        return Resources.getResource('ProductType.' + getProductTypeKey(productType));
    }
}
