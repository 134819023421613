// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { Resources } from '../../Commons/properties/Resources';
import { TerceraImageUploaderTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';
import { ThemeManager } from '../misc/ThemeManager';
import { TerceraImagePreviewScreen } from '../screen/TerceraImagePreviewScreen';

// Ніде не створюється
export class TerceraImageUploader extends ContainerControl {
    public fileReader: FileReader = null;
    public CurrentImageBase64Changed: CustomEvent;

    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        void this.set('btnPreviewImg', ThemeManager.getImageFullUrl('j_algostudio/16x16_icon_search.png'));
        this.initFileReader();
        this.on('showPreviewClick', this.onShowPreviewClicked);
        this.observe('imgFile', this.onImgFileChanged);
        this.observe('defaultImageBase64 selectedImageBase64', this.onCurrentImageBase64Changed);
        this.CurrentImageBase64Changed = new CustomEvent();
    }

    public initFileReader (): void {
        const fr = new FileReader();
        fr.onloadend = this.onFileLoadEnd.bind(this);
        this.fileReader = fr;
    }

    public onFileLoadEnd (): void {
        const fr = this.fileReader;
        if (isNullOrUndefined(fr)) return;

        void this.set('selectedImageBase64',
            fr.readyState === FileReader.DONE
                ? fr.result
                : null);
    }

    public override dispose (): void {
        super.dispose();
        const fr = this.fileReader;

        if (fr.readyState === FileReader.LOADING) {
            fr.abort();
        }

        this.fileReader = null;
    }

    public onImgFileChanged (imgFile): void {
        void this.set('selectedImageBase64', null);
        if (!imgFile || !this.fileReader) {
            return;
        }

        this.fileReader.readAsDataURL(imgFile);
    }

    public onCurrentImageBase64Changed (): void {
        if (!isNullOrUndefined(this.CurrentImageBase64Changed)) {
            this.CurrentImageBase64Changed.Raise(this, this.getCurrentImageBase64());
        }
    }

    public onShowPreviewClicked (): void {
        TerceraImagePreviewScreen.show(
            this.getCurrentImageBase64(),
            this.get('imgFile.name') ?? Resources.getResource('Default image'));
    }

    public getCurrentImageBase64 (): string {
        return this.get('selectedImageBase64') ?? this.get('defaultImageBase64');
    }
}

ContainerControl.extendWith(TerceraImageUploader, {
    data: function () {
        return {
            width: 200,
            height: 20,
            btnPreviewImg: '',
            filter: 'image/png',
            imgFile: null,
            selectedImageBase64: null,
            defaultImageBase64: null
        };
    },
    computed: {
        previewEnabled: {
            get: function () {
                if (this.get('imgFile')) {
                    return !!this.get('selectedImageBase64');
                } else {
                    return !!this.get('defaultImageBase64');
                }
            },
            set: function (value) { }
        }
    },
    template: TerceraImageUploaderTemplate
});
