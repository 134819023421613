// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class ROC extends IndicatorScriptBase {
    public MAPeriod: number;

    constructor () {
        super();
        this.ProjectName = 'Rate of Change';
        this.Comments = 'Shows the speed at which price is changing';
        this.SetIndicatorLine('ROC', Color.Brown, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.SetLevelLine('Zero', 0, Color.Gray, 1, LineStyle.ShapedChart);

        this.MAPeriod = 20;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of MA for envelopese', 1, 1, 99999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return 'ROC(' + this.MAPeriod + ')';
    };

    public override getCustomName (): string {
        return 'ROC (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count <= this.MAPeriod) { return; }

        const price = this.CurrentData.GetPrice(PriceType.Close, 0);
        const pricen = this.CurrentData.GetPrice(PriceType.Close, this.MAPeriod);
        this.SetValue(0, 0, 100 * (price - pricen) / pricen);
    };
}
