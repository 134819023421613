// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Rectangle } from '../../../Commons/Geometry';
import { Font, Pen } from '../../../Commons/Graphics';
import { ThemeManager } from '../../../Controls/misc/ThemeManager';
import { PriceFormatter } from '../../../Utils/Instruments/PriceFormatter';
import { MathUtils } from '../../../Utils/MathUtils';
import { type TerceraChartBase } from '../../TerceraChartBase';
import { TerceraChartBaseScaleRenderer, TerceraChartBaseScaleRendererSettings } from './TerceraChartBaseScaleRenderer';

export class TerceraChartNumberScaleRenderer<Chart extends TerceraChartBase = TerceraChartBase> extends TerceraChartBaseScaleRenderer<Chart> {
    public Step: number = 0.000000001;
    public Precision: number = 9;
    public Legend: Map<number, string> = new Map<number, string>();
    public Format: string = '';
    static hpiTrue: number[] = [10000000000, 5000000000, 1000000000, 500000000, 100000000, 50000000, 10000000, 5000000, 1000000, 500000, 100000, 50000, 20000, 10000, 5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 7.5, 5, 2.5, 1, 0.5, 0.2, 0.1, 0.05, 0.02, 0.01, 0.005, 0.002, 0.001, 0.0005, 0.0002, 0.0001, 0.00005, 0.00002, 0.00001, 0.000005, 0.000002, 0.000001, 0.0000005, 0.0000002, 0.0000001, 0.00000005, 0.00000002, 0.00000001, 0.000000005, 0.000000002, 0.000000001];

    public window: any = null;
    public AutoScaleRect: Rectangle = new Rectangle();

    constructor (settings: TerceraChartBaseScaleRendererSettings, terceraChart: Chart) {
        super(settings, terceraChart);
        this.SetClassName('TerceraChartNumberScaleRenderer');
    }

    public calcStep (maxV: number, minV: number, maxC: number, minC: number, itemheight?: number): number {
        if (itemheight === undefined) {
            itemheight = 38;
        }

        let step: number = 10;
        const inter: number[] = TerceraChartNumberScaleRenderer.hpiTrue;

        const nstep: number = (itemheight * (maxV - minV)) / (maxC - minC);

        if (nstep > inter[0]) {
            step = inter[0];
        } else {
            for (let i: number = 1; i < inter.length; i++) {
                if ((nstep >= inter[i]) && (nstep < inter[i - 1])) {
                    if ((nstep - inter[i - 1]) > (inter[i - 1] - nstep)) {
                        step = inter[i - 1];
                    } else {
                        step = inter[i];
                    }
                }
            }
        }

        return MathUtils.RoundTo(step, this.Step);
    }

    public calcStart (step: number, minV: number): number {
        const astep: number = (1 / step);
        const st: number = Math.round((minV * astep));
        return st / astep;
    }

    /// <summary>
    /// Calculate preferred scale width
    /// Should know it before drawing to layout renderers
    /// </summary>
    public GetPreferredWidth (gr: CanvasRenderingContext2D, formatFunction: (value: any) => string): number {
        let maxW = 0;
        const values = [this.window.FminFloatY, this.window.FmaxFloatY];
        for (let i = 0; i < values.length; i++) {
            let formattedValue = '';
            if (!isNullOrUndefined(formatFunction)) {
                formattedValue = formatFunction(values[i]);
            }
            gr.font = this.GetScaleFont();
            const curW = gr.measureText(formattedValue).width;

            if (maxW < curW) {
                maxW = curW;
            }
        }
        if (this.settings.AutoScaleSwitcherVisible) {
            const manualW = gr.measureText('Manual').width;
            if (maxW < manualW) {
                maxW = manualW;
            }
        }
        return maxW + 5;
    }

    public GetScaleFontHeight (): number {
        return this.settings.ScaleFont.Height;
    }

    public GetScaleFont (): any {
        return this.settings.ScaleFont;
    }

    public override FormatValue (value: number): string {
        const roundedValue = MathUtils.RoundTo(value, this.Step);
        const formatterValue = this.Legend.has(roundedValue) ? this.Legend.get(roundedValue) : PriceFormatter.formatPrice(roundedValue, this.Precision);
        return `${formatterValue}${this.Format}`;
    }
}

export class TerceraChartNumberScaleRendererSettings extends TerceraChartBaseScaleRendererSettings {
    public gridPriceHLColor: string;
    public gridPriceHLStyle: number;
    public gridPriceHLWidth: number;

    public gridPriceHLPen: Pen;

    public priceScaleFont: Font;
    HighlightMarkingsStep: any;
    HighlightMarkings: any;
    PercentView: any;
    Asset: any;

    constructor () {
        super();

        this.gridPriceHLColor = ThemeManager.CurrentTheme.chart_gridPriceColor;
        this.gridPriceHLStyle = Pen.csIsoDotChart;
        this.gridPriceHLWidth = 1;

        this.gridPriceHLPen = new Pen(this.gridPriceHLColor, this.gridPriceHLStyle, this.gridPriceHLWidth);

        this.priceScaleFont = new Font(10, ThemeManager.customFontFamily);
    }

    public themeChanged (): void {
        super.ThemeChanged();

        this.scaleAxisColor = ThemeManager.CurrentTheme.chart_priceScaleAxisColor;
        this.scaleBackColor = ThemeManager.CurrentTheme.chart_priceScaleBackColor;
        this.scaleTextColor = ThemeManager.CurrentTheme.tableForeColor;
        this.scaleGridColor = ThemeManager.CurrentTheme.chart_gridPriceColor;

        this.gridPriceHLColor = ThemeManager.CurrentTheme.chart_gridPriceColor;

        this.priceScaleFont = ThemeManager.Fonts.Font_10F_regular.copy();
    }
}
