import { ContainerControl } from './ContainerControl';
import { TerceraNumericWithRefreshButtonTemplate } from '../../templates';

export class TerceraNumericWithRefreshButton extends ContainerControl {
    constructor () { super(); }

    oninit (): void {
        super.oninit();
        super.observe('value', this.onValueChanged);
        super.on('onRefreshButtonClick', this.onRefreshButtonClicked);
    }

    onteardown (): void {
        super.off('onRefreshButtonClick', this.onRefreshButtonClicked);
        super.onteardown();
    }

    private onRefreshButtonClicked (): void {
        void super.set('enabled', false);
        const refreshCallback = super.get('refreshCallback');
        refreshCallback().then((value: number) => {
            void super.set('value', value);
        }).finally(() => {
            void super.set('enabled', true);
        });
    }

    private onValueChanged (): void {
        const value = super.get('value');
        const prop = super.get('DynPropertyData');
        if (!isNullOrUndefined(prop) && !isNullOrUndefined(prop.value)) {
            prop.value = value;
            void super.set({ DynPropertyData: prop });
        }
        super.fire(TerceraNumericWithRefreshButton.Events.ValueChanged, super.get('value'));
    }

    public static Events = {
        ValueChanged: 'ValueChanged'
    };
}

ContainerControl.extendWith(TerceraNumericWithRefreshButton, {
    template: TerceraNumericWithRefreshButtonTemplate,
    data: function () {
        return {
            enabled: true,
            minValue: 1,
            maxValue: 100000,
            step: 1,
            decimalPrecision: 0,
            value: 0,
            refreshCallback: undefined
        };
    }
});
