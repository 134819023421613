import { Greeks } from '../../../Commons/cache/OptionMaster/OptionTrader/Greeks';
import { GreeksFormatter } from '../../../Commons/cache/OptionMaster/OptionTrader/GreeksFormatter';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { Resources } from '../../../Commons/properties/Resources';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { InfoControl, InfoData, InfoDataItem } from '../../elements/InfoControl';

enum GroupType {
    Greeks
}
enum ItemType {
    Delta,
    Gamma,
    Vega,
    Theta
}

export class OptionPortfolioControl extends InfoControl {
    private readonly DEFATULT_STR: string = '---';

    private _optionTrader: OptionTrader;
    private _timer500ms: NodeJS.Timeout;

    constructor () {
        super();
        this.updateDataAsync = this.updateDataAsync.bind(this);
        this.onTimer500msTick = this.onTimer500msTick.bind(this);
        this.updateDataWithoutRequest = this.updateDataWithoutRequest.bind(this);
    }

    public override getType (): string { return 'OptionPortfolioControl'; }
    public override oninit (): void {
        super.oninit();
        const map = new Map<GroupType, ItemType[]>([
            [GroupType.Greeks, [ItemType.Delta, ItemType.Gamma, ItemType.Vega, ItemType.Theta]]
        ]);
        const data: InfoData[] = [];
        map.forEach((items: ItemType[], group: GroupType) => {
            data.push(new InfoData(group.toString(), this.getGroupTitle(group), this.initializeItems(items)));
        });
        void super.set('data', data);
    }

    public override oncomplete (): void {
        super.observe('visible', this.onVisibleChanged);
    }

    public override onteardown (): void {
        this._optionTrader.unsubscribeOnChangeAccountForPaperPositions(this.updateDataWithoutRequest);
        this._optionTrader.unsubscribeOnChangeInstrumentForPaperPosition(this.updateDataWithoutRequest);
        clearInterval(this._timer500ms);
        super.onteardown();
    }

    public setOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
        this._optionTrader.subscribeOnChangeAccountForPaperPositions(this.updateDataWithoutRequest);
        this._optionTrader.subscribeOnChangeInstrumentForPaperPosition(this.updateDataWithoutRequest);
        clearInterval(this._timer500ms);
        this._timer500ms = setInterval(this.onTimer500msTick, 500);
    }

    private updateDataWithoutRequest (): void {
        void this.updateDataAsync();
    }

    private async updateDataAsync (): Promise<void> {
        const isVisible: boolean = await super.get('visible');
        if (!isVisible) {
            return;
        }
        await this.updateGreeksDataAsync();
        await super.update();
    }

    private async updateGreeksDataAsync (): Promise<void> {
        const aggregatedGreeks = new Greeks();
        const paperPositions = this._optionTrader.getPaperPositionsForAnalyzer();
        for (let i = 0; i < paperPositions.length; i++) {
            const paperPosition = paperPositions[i];
            if (aggregatedGreeks.isEmpty()) {
                aggregatedGreeks.delta = 0;
                aggregatedGreeks.gamma = 0;
                aggregatedGreeks.vega = 0;
                aggregatedGreeks.theta = 0;
            }
            const sign = paperPosition.Operation === OperationType.Buy ? 1 : -1;
            const greeks = this._optionTrader.getGreeks(paperPosition.Instrument.PutCall, paperPosition.Instrument, paperPosition.PriceForGreeksCalculation);
            aggregatedGreeks.delta += GreeksFormatter.roundGreek(greeks.delta * paperPosition.QuantityAmount * sign);
            aggregatedGreeks.gamma += GreeksFormatter.roundGreek(greeks.gamma * paperPosition.QuantityAmount * sign);
            aggregatedGreeks.vega += GreeksFormatter.roundGreek(greeks.vega * paperPosition.QuantityAmount * sign);
            aggregatedGreeks.theta += GreeksFormatter.roundGreek(greeks.theta * paperPosition.QuantityAmount * sign);
        }
        const data: InfoData[] = await super.get('data');
        for (let i = 0; i < data.length; i++) {
            const infoData = data[i];
            if (infoData.id !== GroupType.Greeks.toString()) {
                continue;
            }
            for (let j = 0; j < infoData.items.length; j++) {
                const infoDataItem = infoData.items[j];
                switch (infoDataItem.id) {
                case ItemType.Delta.toString():
                    infoDataItem.value = GreeksFormatter.formatGreek(aggregatedGreeks.delta);
                    break;
                case ItemType.Gamma.toString():
                    infoDataItem.value = GreeksFormatter.formatGreek(aggregatedGreeks.gamma);
                    break;
                case ItemType.Vega.toString():
                    infoDataItem.value = GreeksFormatter.formatGreek(aggregatedGreeks.vega);
                    break;
                case ItemType.Theta.toString():
                    infoDataItem.value = GreeksFormatter.formatGreek(aggregatedGreeks.theta);
                    break;
                }
            }
        }
    }

    private initializeItems (items: ItemType[]): InfoDataItem[] {
        return items.map((item: ItemType) => { return new InfoDataItem(item.toString(), this.getItemTitle(item), this.DEFATULT_STR); });
    }

    private getGroupTitle (data: GroupType): string {
        switch (data) {
        case GroupType.Greeks:
            return Resources.getResource('panel.optionMaster.optionTraderInfoControl.greeks');
        default:
            return '';
        }
    }

    private getItemTitle (item: ItemType): string {
        switch (item) {
        case ItemType.Delta:
            return Resources.getResource('panel.optionPaperPositions.Delta');
        case ItemType.Gamma:
            return Resources.getResource('panel.optionPaperPositions.Gamma');
        case ItemType.Vega:
            return Resources.getResource('panel.optionPaperPositions.Vega');
        case ItemType.Theta:
            return Resources.getResource('panel.optionPaperPositions.Theta');
        default:
            return '';
        }
    }

    private onVisibleChanged (): void {
        const isVisible: boolean = super.get('visible');
        if (isVisible) { this.updateDataWithoutRequest(); }
    }

    private onTimer500msTick (): void { this.updateDataWithoutRequest(); }
}

InfoControl.extendWith(OptionPortfolioControl, {
    data: function () {
        return {
            columnsCount: 4,
            rowsCount: 1
        };
    }
});
