// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { Resources } from '../../Commons/properties/Resources';
import { TimeZoneInfo } from '../../Utils/Time/TimeZoneInfo';
import { TimeZoneInfoSet } from '../../Utils/Time/TimeZoneInfoSet';
import { contextMenuHandler } from '../../Utils/AppHandlers';
import { TerceraTimeZoneSelectorTemplate } from '../../templates.js';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { PropertySetupScreen } from '../screen/PropertySetupScreen';
import { Control } from './Control';
import { DynProperty } from '../../Commons/DynProperty';
import { SessionSettings } from '../../Commons/SessionSettings';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import $ from 'jquery';

interface IMenuItem {
    text: string
    tag?: string
    event: (menuItem: any) => void
}

export class TerceraTimeZoneSelector extends Control {
    public menuItems: IMenuItem[];

    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.TerceraTimeZoneSelector; }

    public override oninit (): void {
        super.oninit();
        // #75789.
        // this.on('click', this.showMenu);
        this.menuItems = [];
        Control.Ticker.Subscribe(this.updateTime, this);
    }

    public showMenu (): void {
        const offset = $(this.find('*')).offset();
        const posY = offset.top - $(window).scrollTop();
        const posX = offset.left - $(window).scrollLeft();

        this.menuItems = this.getMenuItems();

        const menuMaxHeight = 300;
        const itemHeight = 20;
        let menuHeight = this.menuItems.length * itemHeight;
        if (menuHeight > menuMaxHeight) {
            menuHeight = menuMaxHeight;
        }

        contextMenuHandler.Show(this.menuItems, posX - 237, posY - menuHeight - 2, { width: 342 });
    }

    public getMenuItems (): IMenuItem[] {
        const resourceKeyPrefix = 'screen.TimeZoneSelector.';
        const callback = TerceraTimeZoneSelector.updateCurrentTimeZone;

        const items: IMenuItem[] = [
            {
                text: Resources.getResource(resourceKeyPrefix + 'TIMEZONE_LOCAL'),
                tag: TimeZoneInfo.LOCAL,
                event: callback
            },
            {
                text: Resources.getResource(resourceKeyPrefix + 'TIMEZONE_SERVER'),
                tag: TimeZoneInfo.SERVER,
                event: callback
            }
        ];

        items.push.apply(items, this.getFavoriteTimeZoneMenuItems());

        items.push({
            text: Resources.getResource(resourceKeyPrefix + 'Customize'),
            event: this.showCustomizeScreen.bind(this)
        });

        return items;
    }

    public getFavoriteTimeZoneMenuItems (): IMenuItem[] {
        const favoriteTZIArray = [];
        for (const key in SessionSettings.favoriteTimeZoneInfoIdsSet) {
            favoriteTZIArray.push(TimeZoneInfoSet[key]);
        }

        favoriteTZIArray.sort(TimeZoneInfo.compare);

        const callback = TerceraTimeZoneSelector.updateCurrentTimeZone;
        const menuItems: IMenuItem[] = [];

        for (let i = 0, len = favoriteTZIArray.length; i < len; i++) {
            const tzi = favoriteTZIArray[i];

            menuItems.push({
                text: tzi.toLocalizedString(),
                tag: tzi.id,
                event: callback
            });
        }

        return menuItems;
    }

    public showCustomizeScreen (): void {
        PropertySetupScreen.editProperty(this, 'screen.TimeZoneSelector.title');
    }

    public updateTime (): void {
        const currentTZInfoId = DateTimeConvertor.currentTimeZoneInfoId.value;

        const tzInfo = TimeZoneInfoSet[currentTZInfoId];
        if (isNullOrUndefined(tzInfo)) return;

        const now = DateTimeUtils.DateTimeUtcNow();

        const userUtcOffset = tzInfo.baseUtcOffset;
        const utcOffsetDeltaMillis = (userUtcOffset.totalMinutes() + now.getTimezoneOffset()) * 60 * 1000;

        now.setTime(now.getTime() + utcOffsetDeltaMillis);

        const utcOffsetString = userUtcOffset.toString();
        const utcString = isValidString(utcOffsetString) ? ' (UTC' + utcOffsetString + ')' : ' (UTC)';

        void this.set('currentTime', DateTimeUtils.formatDate(now, 'HH:mm:ss') + utcString);
    }

    // #region ICaller

    public Properties (): DynProperty[] {
        const sess = SessionSettings;

        const curTZInfoId = DateTimeConvertor.currentTimeZoneInfoId.value;
        const favTZInfoIdsSet = sess.favoriteTimeZoneInfoIdsSet;

        const props: DynProperty[] = [];
        const tzInfoSet = TimeZoneInfoSet;
        for (const key in tzInfoSet) {
            const tzInfo = tzInfoSet[key];
            if (tzInfo.id === TimeZoneInfo.LOCAL || tzInfo.id === TimeZoneInfo.SERVER) {
                continue;
            }

            const isFavTZ = favTZInfoIdsSet.hasOwnProperty(tzInfo.id);

            const dp = new DynProperty(tzInfo.id, isFavTZ, DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
            dp.localizationKey = tzInfo.toLocalizedString();
            dp.enabled = curTZInfoId !== tzInfo.id;
            dp.sortIndex = tzInfo.sortIndex;

            props.push(dp);
        }

        return props;
    }

    public callBack (props: DynProperty[]): void {
        const favIdsSet = {};
        const len = props.length;
        for (let i = 0; i < len; i++) {
            const dp = props[i];
            if (dp.value) {
                favIdsSet[dp.name] = true;
            }
        }
        SessionSettings.updateFavoriteTimeZoneInfoIdsSet(favIdsSet);
    }

    // #endregion

    public static updateCurrentTimeZone (menuItem): void {
        SessionSettings.updateCurrentTimeZoneInfoId(menuItem.tag);
    }
}

Control.extendWith(TerceraTimeZoneSelector, {
    template: TerceraTimeZoneSelectorTemplate,
    data: function () { return { currentTime: '' }; }
});
