// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TimeSpanPeriods } from '../../Utils/Time/TimeSpan';
import { Resources, CurrentLang, LOCALE_ES, LOCALE_EN, LOCALE_RU, LOCALE_PT, LOCALE_AR, LOCALE_CH_SM, LOCALE_CH_TR, LOCALE_DE } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TradingToolsScreenTemplate } from '../../templates.js';
import { TerceraWindowBase } from './TerceraWindowBase';
import { DataCache } from '../../Commons/DataCache';
import { Md5 } from 'ts-md5';
import { blowfish } from 'sladex-blowfish';

let instance: TradingToolsScreen = null;
export class TradingToolsScreen extends TerceraWindowBase {
    constructor () { super(); }

    public override getType (): string { return 'TradingToolsScreen'; }

    public override oncomplete (): void {
        super.oncomplete();

        this.getLinks();

        this.on('tradingCentralLinkClick', this.tradingCentralLinkClick);
        this.on('autochartistLinkClick', this.autochartistLinkClick);

        this.center();
        this.localize();
    }

    public getLinks (): void {
        void this.set('tradingCentralTool', DataCache.ExternalLinksCache.TradingCentralExternalTool);
        void this.set('autochartistTool', DataCache.ExternalLinksCache.AutoChartistExternalTool);
    }

    public static getTradingCentralLink (): string | null {
        const tool = DataCache.ExternalLinksCache.TradingCentralExternalTool;

        if (!tool) {
            return null;
        }

        const culture = CurrentLang === LOCALE_ES ? 'es-ES' : 'en-US';
        const userID = DataCache.getUserID();
        const POSIXtimestamp = Math.round(Date.now() / TimeSpanPeriods.TicksPerSecond);

        const url = tool.tcrpUrl;
        const key = window.atob(tool.tcrpEncryptionKey);
        const initialToken = tool.tcrpPartnerID + ',' + userID + ',' + culture + ',' + POSIXtimestamp;
        const token = blowfish.encrypt(initialToken, key, { cipherMode: 0, outputType: 0 });
        // #89126
        return url + encodeURIComponent(token);
    }

    public getAutochartistLink (): string | null {
        const tool = this.get('autochartistTool');

        if (!tool) {
            return null;
        }

        let url = tool.url + (tool.url.indexOf('?') == -1 ? '?' : ''); // #92836

        const indexToInsert = url.indexOf('://') + 3;

        url = url.substring(0, indexToInsert) + tool.ssoDNS + '.' + url.substring(indexToInsert); // insert ssoDNS

        url = url.substr(0, url.indexOf('?')) + tool.ssoEndPoint + '?'; // insert ssoEndPoint

        const culture = TradingToolsScreen.getCultureForACRP();
        const userID = DataCache.getUserID();
        const POSIXtimestamp = Math.round(Date.now() / TimeSpanPeriods.TicksPerSecond);
        const accTypeForToken = tool.accountType == 'LIVE' ? '0|' : '1';
        const expiry = tool.accountType == 'LIVE' ? POSIXtimestamp : '';
        const token = Md5.hashStr(userID + '|' + accTypeForToken + expiry + tool.secretKey);
        const expireStr = tool.accountType == 'LIVE' ? '&expire=' + (POSIXtimestamp) : '';

        url += 'username=' + userID + '&account_type=' + tool.accountType + expireStr + '&logintoken=' + token + '&locale=' + culture;

        return url;
    }

    public static getAutochartist_iFrameLink (tool): string | null {
        if (!tool) {
            return null;
        }

        let url = tool.url + (tool.url.indexOf('?') == -1 ? '?' : ''); // #92836

        const culture = TradingToolsScreen.getCultureForACRP();
        const userID = DataCache.getUserID();
        const POSIXtimestamp = Math.round(Date.now() / TimeSpanPeriods.TicksPerSecond);
        const accTypeForToken = tool.accountType == 'LIVE' ? '0|' : '1';
        const expiry = tool.accountType == 'LIVE' ? (POSIXtimestamp + 86400) : '';
        const token = Md5.hashStr(userID + '|' + accTypeForToken + expiry + tool.secretKey);

        url += 'broker_id=' + tool.brokerId + '&account_type=' + tool.accountType + '&user=' + userID + '&token=' + token +
        '&locale=' + culture + '&trade_now=n&layout=horizontal&css=https://www.autochartist.com/components-css/traderevolution-to.css' + '&expire=' + expiry;

        return url;
    }

    public override localize (): void {
        void this.set({
            header: Resources.getResource('screen.tradingTools.header')
        });
    }

    public autochartistLinkClick (): void {
        window.open(this.getAutochartistLink());
    }

    public tradingCentralLinkClick (): void {
        window.open(TradingToolsScreen.getTradingCentralLink());
    }

    public static show (): void {
        if (!instance) {
            instance = new TradingToolsScreen();
            instance.on('teardown', function () {
                instance = null;
            });
            MainWindowManager.MainWindow.addControl(instance);
        }

        instance.setFocus();
    }

    public static getCultureForACRP (): string {
        const appLang = CurrentLang;

        switch (appLang) {
        case LOCALE_EN:
            return 'en_GB';
        case LOCALE_RU:
            return 'ru_RU';
        case LOCALE_PT:
            return 'pt_PT';
        case LOCALE_AR:
            return 'ar_AE';
        case LOCALE_CH_SM:
        case LOCALE_CH_TR:
            return 'zh_CN';
        case LOCALE_ES:
            return 'es_ES';
        case LOCALE_DE:
            return 'de_DE';
        default:
            return 'en_GB';
        }
    }
}

TerceraWindowBase.extendWith(TradingToolsScreen, {
    partials: { bodyPartial: TradingToolsScreenTemplate },
    data: function () {
        return {
            showHeader: true,
            showFooter: false,
            resizable: false,
            alwaysMiddle: false,
            showBorder: true,
            width: 309,
            height: 207,
            tradingCentralTool: null,
            autochartistTool: null,
            style_addition_body: 'tradingTools-AdditionBody',
            style_addition_header: 'tradingTools-AdditionHeader'
        };
    }
});
