export class QuickTableResizer {
    private cellsMinSizes: Record<number, number> = {};
    private readonly onResizeArr: QuickTableResizerItem[] = [];
    public setColumnMinSize (col: number, val: number): void {
        if (isNullOrUndefined(this.cellsMinSizes[col])) { this.cellsMinSizes[col] = val; }

        this.cellsMinSizes[col] = Math.max(this.cellsMinSizes[col], val);
    }

    public getMinWidth (col: number): number {
        return this.cellsMinSizes[col] ?? 0;
    }

    public AddOnResizeData (data: QuickTableResizerItem): void {
        this.onResizeArr.push(data);
    }

    public GetOnResizeData (): QuickTableResizerItem [] {
        return this.onResizeArr;
    }

    public ClearOnResizeData (): void {
        this.onResizeArr.length = 0;
    }
}

export class QuickTableResizerItem {
    public width: number;
    public minWidth: number;
    public index: number;
}
