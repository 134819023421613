// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { externalScreenHandler } from '../../Utils/AppHandlers';
import { CustomEvent } from '../../Utils/CustomEvents';
import { type DirectExternalLinkMessage, Message } from '../../Utils/DirectMessages/DirectMessagesImport';
import { CurrentLang } from '../properties/Resources';

export class ExternalLink {
    public id: any = null;
    public name: any = null;
    public externalResource: ExternalLinkType = null; // тип линки из ExternalLink.ExternalLinkType

    public url: any = null;
    public useInternalBrowser: boolean | null = null;

    public onAvailableUntilExpired = new CustomEvent();
    public availableUntilExpiredTimeoutID: any = null; // тут сохраняем id setTimeout-а, очищаем по нему в случае апдейта availableUntil (на дан.момент поле availableUntil есть только у линков с externalResource == ExternalLink.ExternalLinkType.YOUTUBE_LINK #114288)

    public onStreamNeedUpdate = new CustomEvent();
    public onStreamNeedUpdateTimeoutID: any = null; // тут сохраняем id setTimeout-а, очищаем по нему в случае апдейта линки (на дан.момент поле availableUntil есть только у линков с externalResource == ExternalLink.ExternalLinkType.YOUTUBE_LINK #114288)
    public availableUntil: any;
    public accountType: any;
    public StreamNeedUpdateDate: number;
    public ExternalLinkWebContentDark: string | null;
    public ExternalLinkWebContentLight: string | null;
    private readonly attachingArea: AttachingArea | null = AttachingArea.PopUp;
    public clientTab: null;
    private readonly aliases: any = null;

    // + поля в зависимости от типа линки приходящие в msg.ExternalLinkParameters

    constructor (message?) {
        this.UpdateByMessage(message);
    }

    public getAliases (): any {
        return this.aliases;
    }

    public UpdateByMessage (msg: DirectExternalLinkMessage): void {
        if (!msg || msg.Code != Message.CODE_PFIX_EXTERNAL_LINK_MESSAGE) {
            return;
        }

        const oldAvailableUntil = this.availableUntil;

        this.id = msg.Id;
        this.name = msg.Name;

        this.url = msg.Url;
        this.useInternalBrowser = msg.UseInternalBrowser;

        this.externalResource = msg.ExternalResource;

        this.ExternalLinkWebContentDark = msg.ExternalLinkWebContentDark || null;
        this.ExternalLinkWebContentLight = msg.ExternalLinkWebContentLight || null;
        const externalLinkParameters = JSON.parse(msg.ExternalLinkParameters);

        for (const key in externalLinkParameters) {
            this[key] = externalLinkParameters[key];
        }

        if (this.availableUntil && this.availableUntil != oldAvailableUntil) {
            this.setTimeoutOnExpirationOfAvailableUntil();
        }

        if (this.accountType) {
            this.accountType = ExternalLink.AccountType[this.accountType];
        }
    }

    public HasTabSetting (): boolean // для этих типов ссылок можно указать в какую вкладку она будет положена
    {
        const type = this.externalResource;
        const allTypes = ExternalLinkType;
        const typesAllowTabChoise = [allTypes.NONE, allTypes.LINK_TOKEN_LANG, allTypes.AUTOCHARTIST_IFRAME, allTypes.WEB_CONTAINER];
        const allowTabChoise = typesAllowTabChoise.includes(type);

        return allowTabChoise;
    }

    public GetEventOnClick (mainWindow): any {
        const tool = this;

        const eventOnLinkClick = tool.IsAutochartistIFrameLink()
            ? mainWindow.openAutochartistIFrame.bind(mainWindow, tool)
            : function () {
                const scr = externalScreenHandler.Show(mainWindow.ExternalScreenStorage.GetToolData(tool.id), tool.IsNoneLink());
            };

        return eventOnLinkClick;
    }

    public Copy (): ExternalLink {
        const link = new ExternalLink();

        Object.assign(link, this);
        // for (const key in this) {
        //     link[key as keyof ExternalLink] = this[key];
        // }

        return link;
    }

    public IsNoneLink (): boolean {
        return this.externalResource === ExternalLinkType.NONE;
    }

    public IsWebContainerLink (): boolean {
        return this.externalResource === ExternalLinkType.WEB_CONTAINER;
    }

    public static IsYouTubeLink (link): boolean {
        return link && link.externalResource == ExternalLinkType.YOUTUBE_LINK;
    }

    public getExternalLinkWebContent (isDark: boolean): string {
        if (isDark) {
            if (!isNullOrUndefined(this.ExternalLinkWebContentDark)) { return this.ExternalLinkWebContentDark; } else { return this.ExternalLinkWebContentLight; }
        }
        if (!isNullOrUndefined(this.ExternalLinkWebContentLight)) { return this.ExternalLinkWebContentLight; } else { return this.ExternalLinkWebContentDark; }
    }

    public IsAutochartistIFrameLink (): boolean {
        return this.externalResource === ExternalLinkType.AUTOCHARTIST_IFRAME;
    }

    public IsStillAvailable (): boolean | null {
        const linkInstance = this;
        const availableUntil = linkInstance.availableUntil;
        if (!availableUntil) {
            return null;
        }

        const willBecomeExpiredAfterMS = availableUntil - Date.now();
        return willBecomeExpiredAfterMS > 0;
    }

    public getLocalizedToolNameOrNull (): string | null {
        const alias = this.aliases?.find(alias => alias.lang === CurrentLang);
        if (!isNullOrUndefined(alias?.toolName)) {
            return alias.toolName;
        }
        return null;
    }

    public getLocalizedTabNameOrNull (): string | null {
        const alias = this.aliases?.find(alias => alias.lang === CurrentLang);
        if (!isNullOrUndefined(alias?.tabName)) {
            return alias.tabName;
        }
        return null;
    }

    public setTimeoutOnExpirationOfAvailableUntil (): void {
        const linkInstance = this;
        const availableUntil = linkInstance.availableUntil;
        if (!availableUntil) {
            return;
        }

        if (linkInstance.availableUntilExpiredTimeoutID) {
            clearTimeout(linkInstance.availableUntilExpiredTimeoutID);
        }

        const willBecomeExpiredAfterMS = availableUntil - Date.now();

        if (willBecomeExpiredAfterMS > 0) {
            linkInstance.availableUntilExpiredTimeoutID = setTimeout(() => {
                linkInstance.availableUntilExpiredTimeoutID = null;

                linkInstance.onAvailableUntilExpired.Raise(linkInstance);
            }, willBecomeExpiredAfterMS);
        }
    }

    public setLIVEUpdateTimeout (startStreamUpdate = false, cacheItem, eventOnUpdate): void {
        const linkInstance = this;
        if (!linkInstance) {
            return;
        }

        if (linkInstance.onStreamNeedUpdateTimeoutID) {
            clearTimeout(linkInstance.onStreamNeedUpdateTimeoutID);
        }

        linkInstance.StreamNeedUpdateDate = Date.now() + UPDATE_LIVE_TIMEOUT_MS;
        const updateDate = startStreamUpdate && cacheItem.streamStartDateTimeString ? new Date(cacheItem.streamStartDateTimeString).getTime() : linkInstance.StreamNeedUpdateDate;
        if (!updateDate) {
            return;
        }
        const streamNeedUpdateAfterMS = Math.abs(updateDate - Date.now());

        linkInstance.onStreamNeedUpdateTimeoutID = setTimeout(() => {
            linkInstance.onStreamNeedUpdateTimeoutID = null;
            linkInstance.onStreamNeedUpdate.Raise(linkInstance);

            if (eventOnUpdate) {
                eventOnUpdate(linkInstance);
            }
        }, streamNeedUpdateAfterMS);
    }

    public static readonly AccountType = {
        0: 'LIVE',
        1: 'DEMO',
        LIVE: 'LIVE',
        DEMO: 'DEMO'
    };

    public isAttached (): boolean {
        return this.attachingArea === AttachingArea.AttachPanel;
    }
}

const UPDATE_LIVE_TIMEOUT_MS = 300000; // need update live streams every 10 minutes -> update every 5 min to get fresh data every 10 min

export enum ExternalLinkType {
    UNKNOWN = -1,
    NONE = 0,
    LINK_TOKEN_LANG = 1,
    TRADING_CENTRAL = 2,
    AUTOCHARTIST_RP = 3,
    AUTOCHARTIST_IFRAME = 4,
    ITCHART_ADVANCED = 5,
    YOUTUBE_LINK = 6, // #114288
    WEB_CONTAINER = 7// #118885
};

export enum ExternalLinkClientTab {
    MORE = 'More',
    ACCOUNT = 'Account',
    TOOLS = 'Tools',
    CUSTOM_TAB = 'Custom tab'
};

export enum AttachingArea {
    PopUp = '0',
    AttachPanel = '1'
}
