import { type Rectangle } from '../../Commons/Geometry';
import { type Account } from '../../Commons/cache/Account';
import { AccountsComboBox } from './AccountsComboBox';

export class QuickTableAccountsComboBox extends AccountsComboBox {
    private readonly _isProcessedCallback: boolean = false;
    tag: any;
    callback: (value: Account | undefined, tag: any) => void;
    public override getType (): string { return 'QuickTableAccountsComboBox'; }

    constructor (initProps, data?, callback?: (value: Account | undefined, tag: any) => void, rect?: Rectangle) {
        super();

        this.tag = data;
        this.callback = callback;
        this.setBounds(rect.X, rect.Y, rect.Width, rect.Height);
        void this.set('isPosAbsolute', true);
    }
}

AccountsComboBox.extendWith(QuickTableAccountsComboBox, { });
