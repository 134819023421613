// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { ContainerControl } from './ContainerControl';

export class DynPropertyControl extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'DynPropertyControl'; }

    public override oninit (): void {
        super.oninit();

        this.observe('dp.localizationKey', this.localize);
        this.observe('dp.customCSS', this.onCustomCSSChanged, { init: false });

        Resources.onLocaleChange.Subscribe(this.localize, this);
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public localize (): void {
        const locKey = this.get('dp.localizationKey');
        void this.set('label', Resources.getResource(locKey));
    }

    public onCustomCSSChanged (): void {
        const style = this.get('dp.customCSS');

        if (style !== undefined) {
            void this.set('additionalClassName', style);
        }
    }
}

ContainerControl.extendWith(DynPropertyControl, {
    data: function () {
        return {
            label: '',
            dp: null,
            SLNumericLink: null,
            TPNumericLink: null,
            PriceNumericLink: null,
            StopPriceNumericLink: null
        };
    }
});
