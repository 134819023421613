// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ColoringEnum, DirectionArrowEnum } from './Level1CalculatorUtils';

export class Level1CalculatorViewModelHelper {
    public static getWMColor (coloring: ColoringEnum, forceNeutral: string = null): string {
        if (coloring === ColoringEnum.Positive) {
            return 'green';
        } else if (coloring === ColoringEnum.Negative) {
            return 'red';
        } else {
            return forceNeutral || 'white';
        }
    }

    public static getDesktopColor (coloring: ColoringEnum): string {
        if (coloring === ColoringEnum.Positive) {
            return 'green-light';
        } else if (coloring === ColoringEnum.Negative) {
            return 'red-darken';
        } else {
            return 'gray';
        }
    }

    public static getArrow (direction: DirectionArrowEnum): string {
        if (direction === DirectionArrowEnum.Up) {
            return '↑';
        } else if (direction === DirectionArrowEnum.Down) {
            return '↓';
        } else {
            return '';
        }
    }
}
