// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { type MAMode, MODE_MAIN, MODE_SIGNAL } from '../../IndicatorConstants';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iRVI extends iBuildInIndicator {
    public FMaPeriod: any;
    public MaMode: MAMode;
    public MaPeriod: any;

    constructor (maPeriod, maMode: MAMode) {
        super(2);

        this.FMaPeriod = maPeriod;
        this.MaMode = maMode;
    }

    override get Name (): string { return 'iRVI'; }

    override get Key (): string { return this.DefaultKey + this.MaPeriod + this.MaMode; }

    public override OnQuote (): void {
        if (this.Parent == null || this.FCount < this.FMaPeriod + 3) {
            return;
        }

        let up = 0;
        let down = 0;
        for (let i = 0; i < this.FMaPeriod; i++) {
            up += this.GetPrice(PriceType.Close, i) - this.GetPrice(PriceType.Open, i) +
                2 * (this.GetPrice(PriceType.Close, i + 1) - this.GetPrice(PriceType.Open, i + 1)) +
                2 * (this.GetPrice(PriceType.Close, i + 2) - this.GetPrice(PriceType.Open, i + 2)) +
                this.GetPrice(PriceType.Close, i + 3) - this.GetPrice(PriceType.Open, i + 3);

            down += this.GetPrice(PriceType.High, i) - this.GetPrice(PriceType.Low, i) +
                2 * (this.GetPrice(PriceType.High, i + 1) - this.GetPrice(PriceType.Low, i + 1)) +
                2 * (this.GetPrice(PriceType.High, i + 2) - this.GetPrice(PriceType.Low, i + 2)) +
                this.GetPrice(PriceType.High, i + 3) - this.GetPrice(PriceType.Low, i + 3);
        }

        const value = (down != 0) ? up / down : up;
        this.SetValue(MODE_MAIN, 0, value);

        if (this.FCount >= this.FMaPeriod + 6) {
            this.SetValue(MODE_SIGNAL, 0, (value + 2 * this.GetValue(0, 1) + 2 * this.GetValue(0, 2) + this.GetValue(0, 3)) / 6);
        }
    }
}
