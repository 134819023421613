// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type InsDefSettingsStorage } from '../../Commons/cache/InstrumentDefaults';
import { CustomEvent } from '../CustomEvents';
import { AlertSett } from './AlertSettings';
import { ConfirmationSett } from './SubSettings/ConfirmationSett';
import { GeneralSett } from './SubSettings/GeneralSett';
import { SoundSett } from './SubSettings/SoundSett';
import { TradingDefaultsSett } from './SubSettings/TradingDefaultsSett';
import { ViewSett } from './SubSettings/ViewSett';
import { WarningSett } from './SubSettings/WarningSett';

class _GeneralSettings {
    public SettingsChanged: CustomEvent;
    public SettingsNeedSave: CustomEvent;
    public InsDefSettingsStorage: InsDefSettingsStorage | null = null;
    public General: GeneralSett;
    public View: ViewSett;
    public Sound: SoundSett;
    public TradingDefaults: TradingDefaultsSett;
    public Confirmations: ConfirmationSett;
    public Warnings: WarningSett;
    public Alert: AlertSett;

    constructor () {
        this.ApplyDefaults();
        this.SettingsChanged = new CustomEvent();
        this.SettingsNeedSave = new CustomEvent();
    }

    public ApplyDefaults (): void {
        this.General = new GeneralSett();
        this.View = new ViewSett();
        this.Sound = new SoundSett();
        this.TradingDefaults = new TradingDefaultsSett();
        this.Confirmations = new ConfirmationSett(this);
        this.Warnings = new WarningSett();
        this.Alert = new AlertSett();
    }

    public SettingsChangedRaise (): void {
        this.SettingsChanged.Raise();
    }

    public SettingNeedSaveRaise (): void {
        this.SettingsNeedSave.Raise();
    }
}

export const GeneralSettings = new _GeneralSettings();
