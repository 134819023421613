// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Connection } from '../../Commons/Connection';
import { ConnectionStates, ConectionResultEnum } from '../../Commons/ConnectionEnums';
import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraBaseWindowTemplate, TerceraReconnectScreenTemplate } from '../../templates.js';
import { TerceraButton } from '../elements/TerceraButton';
import { AdditionalVerificationScreen } from './AdditionalVerificationScreen';
import { TerceraMessageBox, MessageBoxType } from './TerceraMessageBox';
import { TerceraWindowBase } from './TerceraWindowBase';

export class TerceraReconnectScreen extends TerceraWindowBase {
// more than 1 instance is prohibited
    public static instance = null;
    public static readonly RECONNECTTIME = 5000;

    public forceCloseOnLogout: boolean = false;
    public timeout: any = null;
    public lastWorkedVendorState: any = null;
    public tryNowBtn: TerceraButton;
    public cancelBtn: TerceraButton;

    public override getType (): string { return 'TerceraReconnectScreen'; }

    public override oncomplete (): void {
        super.oncomplete();
        Connection.onConnectStateChange.Subscribe(this.connectionStateChange, this);

        const myWidth = this.get('width');

        this.tryNowBtn = new TerceraButton();
        this.tryNowBtn.setLocation(myWidth - 2 * 10 - 2 * 100, 10);
        void this.tryNowBtn.set({
            name: 'tryNowBtn',
            text: Resources.getResource('screen.recconection.tryNow')
        });
        this.tryNowBtn.onClick = this.tryNowBtnClick.bind(this);

        this.cancelBtn = new TerceraButton();
        this.cancelBtn.setLocation(myWidth - 10 - 100, 10);
        void this.cancelBtn.set({
            name: 'cancelBtn',
            text: Resources.getResource('screen.recconection.stop')
        });
        this.cancelBtn.onClick = this.cancelBtnClick.bind(this);

        this.Controls.windowPanelFooter.addControl(this.tryNowBtn);
        this.Controls.windowPanelFooter.addControl(this.cancelBtn);

        this.tryNowBtnClick();
        this.center();
        this.localiize();
    }

    public localiize (): void {
        void this.set({
            textAttemptLabel: Resources.getResource('screen.recconection.attemptLabelLabel'),
            textStatusLabel: Resources.getResource('screen.recconection.statusLabelLabel'),
            textTryNowButton: Resources.getResource('screen.recconection.tryNow'),
            textCancelButton: Resources.getResource('screen.recconection.stop'),
            header: Resources.getResource('screen.recconection.title')
        });
    }

    public tryNowBtnClick (): void {
    // логинюсь, если я в разрешенном для логина состоянии
        const connection = Connection;
        const state = connection.getState();
        if (state != ConnectionStates.CONNECTION_LOST && state != ConnectionStates.DISCONNECTED) {
            return;
        }

        const connectData = MainWindowManager.MainWindow.Controls.loginScreen.get('connectionParams');
        if (!connectData || connectData === null) {
            return;
        }
        connectData.ServerAdressesArray = connectData.ServerAdress.split(',');
        for (let i = 0, len = connectData.ServerAdressesArray.length; i < len; i++) {
            if (connectData.ServerAdressesArray[i]) {
                connectData.ServerAdressesArray[i] = connectData.ServerAdressesArray[i].trim();
            }
        }
        void this.set('textStatus', Resources.getResource('screen.recconection.reconnecting'));
        void this.tryNowBtn.set('enabled', false);
        connectData.reconnect = true;
        connection.connect(connectData, true).then(function (answers) {
            const connected = !!answers[0];
            const tryAgain = !!answers[1];
            if (!connected && tryAgain) {
                setTimeout(this.tryNowBtnClick.bind(this), TerceraReconnectScreen.RECONNECTTIME);
            }
        }.bind(this));
    }

    public cancelBtnClick (): void {
        this.close();
    }

    public override close (): void {
    // сбрасываю состояние на дисконнект
        const connection = Connection;
        const state = connection.getState();
        if (state == ConnectionStates.CONNECTION_LOST || state == ConnectionStates.CONNECTING) {
            Connection.disconnect();
        }
    }

    public override dispose (): void {
        const connection = Connection;
        connection.onConnectStateChange.UnSubscribe(this.connectionStateChange, this);
        TerceraReconnectScreen.instance = null;
        super.dispose();
    }

    public connectionStateChange (): void {
        const state = Connection.getState();
        // досрочно прерываю скрин
        if (state == ConnectionStates.CONNECTED || state == ConnectionStates.DISCONNECTED) {
            this.close();
        }

        const lastResp = Connection.lastConnectionAttemptResponse;
        if (state !== ConnectionStates.CONNECTING && lastResp.connectReason === ConectionResultEnum.AdditionalVerification) {
            clearTimeout(this.timeout);
            const connectData = MainWindowManager.MainWindow.Controls.loginScreen.get('reconnectConnectionParams');
            if (!connectData || connectData === null) {
                return;
            }
            const okCallBack = function (verificPass) {
                const connection = Connection;
                connectData.verificationPassword = verificPass;
                connectData.reconnect = true;
                connection.connect(connectData, true);
                connectData.verificationPassword = undefined;
            };
            const cancelCallBack = function () {
                this.close();
            }.bind(this);
            AdditionalVerificationScreen.Show(lastResp, okCallBack, cancelCallBack, connectData);
        } else if (state === ConnectionStates.CONNECTION_LOST && lastResp.connectReason === ConectionResultEnum.Error && lastResp.text !== 'connect.connection.connect.error') {
            TerceraMessageBox.Show(
                Resources.getResource('screen.additionalVerification.title'),
                Connection.getLocalizedConnectionErrorString(),
                MessageBoxType.Info,
                this.close.bind(this),
                null, false, true);
        }
    // else if (state === Connection.ConnectionStates.DISCONNECTED)
    // {
    //     this.tryNowBtnClick();
    // }
    }

    public static startReconnection (): void {
        if (TerceraReconnectScreen.instance != null) {
            TerceraReconnectScreen.instance.tryNowBtnClick();
            return;
        }

        const scr = new TerceraReconnectScreen();
        scr.setBounds(0, 0, 400, 160);
        MainWindowManager.MainWindow.addControl(scr);
        TerceraReconnectScreen.instance = scr;
    }
}

TerceraWindowBase.extendWith(TerceraReconnectScreen, { // Был screen-ом раньше, теперь это Manager.
    template: TerceraBaseWindowTemplate, // Процесс реконнекта происходит в методе tryNowBtnClick
    data: function () {
        return {
            textAttemptLabel: 'Attempts:',
            textStatusLabel: 'Status:',
            textTryNowButton: 'Try Now',
            textCancelButton: 'Cancel',
            header: 'Reconnect...',
            visible: false
        };
    },
    partials: { bodyPartial: TerceraReconnectScreenTemplate }
});
