// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';

export class DefaultLotTableItem extends BaseItem {
    public selectiveLot: any;

    constructor (selectiveLot, id?) {
        super(null);

        this.selectiveLot = selectiveLot;
        this.itemId = id;
    }

    public override ColumnCount (): number {
        return DefaultLotTableItem.columnsParams.length;
    }

    public override GetColumnParams (column: number): ColumnParams {
        return DefaultLotTableItem.columnsParams[column];
    }

    public override getColumnData (column: number): ColumnData {
        const val = this.getColumnValue(column);
        return new ColumnData(val, val ? val.toString() : '');
    }

    public override getColumnValue (column: number): any {
        const selectiveLot = this.selectiveLot;
        switch (column) {
        case 0:
            return selectiveLot.Selected;
        case 1:
            return selectiveLot.Lots;
        default:
            return null;
        }
    }

    public static readonly columnsParams =
        [
            new ColumnParams('TypesManagerScreen.QuickTable.Column.Enable', 80, QuickTableColumnType.COL_BOOLEAN, false, true, false),
            new ColumnParams('TypesManagerScreen.QuickTable.Column.Default lots', 110, QuickTableColumnType.COL_DEFAULT, false, true, false)
        ];
}
