import { Resources } from '../../Commons/properties/Resources';
import { MathUtils } from '../MathUtils';
import { OrderExecutionType } from './OrderExecutionType';

export class _OrderHistoryUtils {
    // TODO. Refactor.
    public localizeOrderExecutionType (executionType: OrderExecutionType): string {
        if (MathUtils.IsNullOrUndefined(executionType)) {
            return '';
        }

        const et = OrderExecutionType;
        let key = 'UNKNOWN';
        switch (executionType) {
        case et.UNKNOWN:
            key = 'UNKNOWN';
            break;
        case et.PENDING_NEW:
            key = 'PENDING_NEW';
            break;
        case et.PENDING_EXECUTION:
            key = 'PENDING_EXECUTION';
            break;
        case et.PENDING_CANCEL:
            key = 'PENDING_CANCEL';
            break;
        case et.PENDING_REPLACE:
            key = 'PENDING_REPLACE';
            break;
        case et.PENDING_REPLACE_NOT_ACTIVE:
            key = 'PENDING_REPLACE_NOT_ACTIVE';
            break;
        case et.NEW:
            key = 'NEW';
            break;
        case et.ACCEPTED:
            key = 'ACCEPTED';
            break;
        case et.REPLACED:
            key = 'REPLACED';
            break;
        case et.PART_FILLED:
            key = 'PART_FILLED';
            break;
        case et.FILLED:
            key = 'FILLED';
            break;
        case et.CANCELED:
            key = 'CANCELED';
            break;
        case et.REFUSED:
            key = 'REFUSED';
            break;
        case et.RESTATED:
            key = 'RESTATED';
            break;
        case et.ACTIVATED:
            key = 'ACTIVATED';
            break;
        }

        return Resources.getResource('Order.ExecutionType.' + key);
    }
}
export const OrderHistoryUtils = new _OrderHistoryUtils();
