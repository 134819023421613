// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OEQuantityTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Commons/properties/Resources';
import { Quantity } from '../../../Utils/Trading/Quantity';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';

export class OEQuantity extends ContainerControl {
    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.OEQuantity; }

    public override oninit (): void {
        super.oninit();

        this.observe('inLots value', this.updateQuantity);

        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public override setFocus (): void {
        super.setFocus();
        this.Controls.QN.setFocus();
    }

    public updateQuantity (): void {
        const value = this.get('value');
        const inLots = this.get('inLots');
        void this.set('quantity', new Quantity(value, inLots));
    }

    public localize (): void {
        void this.set('label', Resources.getResource('panel.newOrderEntry.amountLabel'));
    }
}

ContainerControl.extendWith(OEQuantity, {
    template: OEQuantityTemplate,
    data: function () {
        return {
            label: '',
            instrument: null,
            account: null,
            value: null,
            inLots: false,
            quantity: null,
            // TODO. Ugly. Remove.
            defaultQuantity: null,

            showLabel: true,
            noArrowCB: false,
            positionSizingVisible: false,
            positionSizingChecked: false
        };
    }
});
