// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { Resources } from '../properties/Resources';
import { AccountType } from '../../Utils/Account/AccountType';
import { MathUtils } from '../../Utils/MathUtils';
import { AssetInfoData } from './AssetInfoData';
import { AccountAdditionalInfoItem } from '../../Utils/Account/AdditionalInfoItem';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { PriceFormatter } from '../../Utils/Instruments/PriceFormatter';
import { InstrumentTradingBalance } from '../../Utils/Instruments/InstrumentTradingBalance';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { AssetType } from './Asset';
import { AssetBalance } from './AssetBalance';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { type RiskPlan } from './RiskPlan';
import { AccountSeal, AccountVisibilityType } from './AccountEnums';
import { AccountFeature } from '../../Utils/Account/AccountFeature';
import { Statistics } from './Statistics';
import { type User } from './User';
import { CurrencyUtils } from '../../Utils/Asset/CurrencyUtils';
import { type AccountTradeStatus } from '../../Utils/Account/AccountTradeStatus';
import { type CrossratesPlan } from './CrossratesPlan';
import { type Instrument } from './Instrument';
import { type DataCache } from '../DataCache';
import { type DirectAccountStatusMessage } from '../../Utils/DirectMessages/DirectAccountStatusMessage';
import { AccountOperations } from '../AccountOperations';
import { type CommissionPlan } from './Commissions/CommissionPlan';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';

// #region AccountDirect

export class Account {
    public DataCache: DataCache;
    public User: User | null = null;
    public userID: string;
    public userPin: any;
    public FullAccString: string;
    public MarginLevel: number;
    public AllowOvernightTrading: any;
    public WarningLevel: number;
    public AccountTradeStatus: AccountTradeStatus;
    public assetBalanceDefault: AssetBalance;
    public AccountType: AccountType = AccountType.SingleCCY;
    public BstrAccount: any;
    public AccountMode: AccountSeal = AccountSeal.Unspecified;

    public FirstName: string = '';
    public LastName: string = '';

    public AccountAdditionalInfo: any = {};
    public assetBalances: Record<string, AssetBalance> = {};
    public assetInfoData: any = {};

    public StockValue: number = 0;
    public StockValueByBasis: number = 0;
    public StockValueWithoutPnl: number = 0;
    public StockValueDiscount: number = 0;
    public StartPammCapital: number = 0;
    public CurrPammCapital: number = 0;

    public MaxDailyLossLimit: number = 0;
    public MaxPositions: number = 0;
    public MaxPendingOrders: number = 0;
    public MaxOrderCapital: number = 0;
    public MaxDrawdownLevel: number = 0;
    public AccountDescription: string = '';
    public TotalMaxPositionsQty: number = 0;
    public MaxWeeklyLossLimit: number = 0;
    public MaxUrealizedLossLimit: number = 0;
    public MaxOrderAmount: number = 0;

    public Profit: number = 0;
    public Loss: number = 0;
    public LossForUnsettled: number = 0;
    public ProfitBase: number = 0;
    public ProfitNet: number = 0;
    public ProfitForMargin: number = 0;
    public ProfitForUnsettled: number = 0;
    public OnlyProfitForWithdrawalAvailable: number = 0;
    public ProfitNetBase: number = 0;
    public OpenOrdAmount: number = 0;
    public OpenPosAmount: number = 0;
    public OpenPosNumber: number = 0;
    public OpenOrdNumber: number = 0;
    public BlockedForStocksAccountCurrency: number = 0;
    public BlockedForStocks: number = 0;
    public OptionValue: number = 0;
    public OptionPremium: number = 0;
    public PositionsValue: number = 0;
    public StopTradingReason: number = 0;
    public WaivedMargin: any = null;
    public ClusterNode: any = null;

    public CommissionPlan: CommissionPlan | null = null;
    public CustodialPlanId: any = null;
    public FundingRatesPlanId: any = null;

    public MaxRelativeDrawDownLevel: any = null;
    public LossLimitPerTrade: any = null;

    public DayTraderPattern: any = null;
    public DayTraderPatternProtection: any = null;
    public AvailableDayTrades: any = null;

    public OnDayTraderPatternChanged: CustomEvent = new CustomEvent();
    public OnUpdateInfoData: CustomEvent = new CustomEvent();
    public OnUpdateAssetBalanceData: CustomEvent = new CustomEvent();

    public Statistics: Statistics = new Statistics();

    public CommisionId: number;
    public SpreadPlanId: number;
    public SpreadPlan: any;
    public CrossratesPlanId: number;
    public CrossratesPlan: CrossratesPlan;
    public RiskPlanId: number;
    public RiskPlan: RiskPlan;
    public SwapPlanId: number;
    public SwapPlan: any;
    public ChargingPlanId: number;
    public ChargingPlan: any;
    public TradingLevel: any;
    public AcctNumber: string;
    public isMasterAccount: boolean;
    public userLogin: string;
    public FundType: any;
    public TodayTrades: any;
    public Email: string;
    public TelephoneNumber: string;
    public MiddleName: string;
    public IntradayRiskManagement: any;
    public AllowTradingOnPrepostMarket: any;
    public MaxPendingOrdersValue: any;
    public InterestPlanID: number;
    public IsSigned: boolean;
    public allowedRoutes: string[];
    public AccountVisibilityFlag: AccountVisibilityType;
    public isLinked: boolean;
    public AssetSharesNames: string[];
    public ExtendedFields: any;
    public PreferredInstrumentType: number = -1;
    private instrumentTypeVolumeLimit: any;
    public interestPlan: any;
    public isMAM: boolean;

    public static TradeStatusChanged = new CustomEvent();
    public static point = 2;

    public static readonly STOP_TRADING_NONE = 0;
    public static readonly STOP_TRADING_UNKNOWN = 1;
    public static readonly STOP_TRADING_MAX_LOSS = 2;
    public static readonly STOP_TRADING_MAX_DAY_VOL = 3;
    public static readonly STOP_TRADING_WEEKLY_LOST_LIMIT = 4;
    public static readonly STOP_TRADING_TRAILING_DRAWDOWN_LIMIT = 5;
    public static readonly STOP_TRADING_MAX_UNREALIZED_LOSS = 6;
    public static readonly STOP_TRADING_MAX_DAILY_PROFIT = 7;
    public static readonly STOP_TRADING_MAX_ORDERS_COUNT_PER_DAY = 9;
    public static readonly STOP_TRADING_RELATIVE_DRAWDOWN_LEVEL = 11;
    public static readonly STOP_TRADING_EOD_TRAILING_DRAWDOWN = 14;
    public static readonly STOP_TRADING_RELATIVE_DAILY_LOSS_LIMIT = 15;

    constructor (dataCache, accountStatusMessage) {
        this.DataCache = dataCache;

        this.FillAccount(accountStatusMessage);
    }

    get Owner (): string {
        return this.FirstName + ' ' + this.LastName;
    }

    get IsOwnedByUser (): boolean { return !this.isLinked; }

    get BaseCurrency (): string {
        if (this.assetBalanceDefault?.Asset == null) { return this.DataCache.baseCurrency; }

        return this.assetBalanceDefault.Asset.Name;
    }

    public FillAccount (accountStatusMessage: DirectAccountStatusMessage): void {
        this.DataCache.CreateOrUpdateUser(accountStatusMessage, this);

        if (accountStatusMessage.UserAdditionalInfo !== null && this.User) { this.User.UserAdditionalInfo = accountStatusMessage.UserAdditionalInfo; }

        if (accountStatusMessage.AllowOvernightTrading !== null) { this.AllowOvernightTrading = accountStatusMessage.AllowOvernightTrading; }

        if (accountStatusMessage.CommisionId !== null) {
            this.CommisionId = accountStatusMessage.CommisionId;
            if (this.DataCache !== null) {
                this.CommissionPlan = this.DataCache.TryGetCommissionPlan(accountStatusMessage.CommisionId);
            }
        }
        if (accountStatusMessage.SpreadPlanId !== null) {
            this.SpreadPlanId = accountStatusMessage.SpreadPlanId;
            if (this.DataCache !== null) {
                this.SpreadPlan = this.DataCache.TryGetSpreadPlan(accountStatusMessage.SpreadPlanId);
            }
        }
        if (accountStatusMessage.CrossratesPlanId !== null) {
            this.CrossratesPlanId = accountStatusMessage.CrossratesPlanId;
            if (this.DataCache !== null) {
                this.CrossratesPlan = this.DataCache.TryGetCrossratesPlan(accountStatusMessage.CrossratesPlanId);
            }
        }
        if (accountStatusMessage.RiskPlanId !== null) {
            this.RiskPlanId = accountStatusMessage.RiskPlanId;
            if (this.DataCache !== null) {
                this.RiskPlan = this.DataCache.TryGetRiskPlan(accountStatusMessage.RiskPlanId);
            }
        }
        if (accountStatusMessage.SwapPlanId !== null) {
            this.SwapPlanId = accountStatusMessage.SwapPlanId;
            if (this.DataCache !== null) {
                this.SwapPlan = this.DataCache.TryGetSwapPlan(accountStatusMessage.SwapPlanId);
            }
        }
        if (accountStatusMessage.ChargingPlanId !== null) {
            this.ChargingPlanId = accountStatusMessage.ChargingPlanId;
            if (this.DataCache !== null) {
                this.ChargingPlan = this.DataCache.TryGetChargingPlan(accountStatusMessage.ChargingPlanId);
            }
        }
        if (accountStatusMessage.MarginLevel !== null) { this.MarginLevel = accountStatusMessage.MarginLevel; }

        if (accountStatusMessage.WarningLevel !== null) { this.WarningLevel = accountStatusMessage.WarningLevel; }
        if (accountStatusMessage.TradingLevel !== null) { this.TradingLevel = accountStatusMessage.TradingLevel; }
        if (accountStatusMessage.BstrAccount !== null) {
            this.BstrAccount = accountStatusMessage.BstrAccount;
            this.AcctNumber = accountStatusMessage.BstrAccount;
        }

        if (accountStatusMessage.IsMasterAccount !== null) { this.isMasterAccount = accountStatusMessage.IsMasterAccount; }

        if (accountStatusMessage.UserID !== null) { this.userID = accountStatusMessage.UserID; }

        if (accountStatusMessage.UserLogin !== null) { this.userLogin = accountStatusMessage.UserLogin; }
        if (accountStatusMessage.FundType !== null) { this.FundType = accountStatusMessage.FundType; }
        if (accountStatusMessage.TodayTrades !== null) { this.TodayTrades = accountStatusMessage.TodayTrades; }
        if (accountStatusMessage.Email !== null) { this.Email = accountStatusMessage.Email; }
        if (accountStatusMessage.TelephoneNumber != null) { this.TelephoneNumber = accountStatusMessage.TelephoneNumber; }
        if (accountStatusMessage.FirstName !== null) { this.FirstName = accountStatusMessage.FirstName; }
        if (accountStatusMessage.MiddleName !== null) { this.MiddleName = accountStatusMessage.MiddleName; }
        if (accountStatusMessage.LastName !== null) { this.LastName = accountStatusMessage.LastName; }
        if (accountStatusMessage.AccountDescription !== null) { this.AccountDescription = accountStatusMessage.AccountDescription; }

        if (accountStatusMessage.AccountType !== null) { this.AccountType = accountStatusMessage.AccountType; }

        if (accountStatusMessage.IntradayRiskManagement !== null) {
            this.IntradayRiskManagement = accountStatusMessage.IntradayRiskManagement;
        }

        if (accountStatusMessage.AccountTradeStatus !== null) {
            this.AccountTradeStatus = accountStatusMessage.AccountTradeStatus;

            Account.TradeStatusChanged.Raise();
        }

        if (accountStatusMessage.AllowTradingOnPrepostMarket !== null) {
            this.AllowTradingOnPrepostMarket = accountStatusMessage.AllowTradingOnPrepostMarket;
        }

        if (accountStatusMessage.MaxDailyLossLimit !== null) {
            this.MaxDailyLossLimit = accountStatusMessage.MaxDailyLossLimit;
        }

        if (accountStatusMessage.MaxWeeklyLossLimit !== null) {
            this.MaxWeeklyLossLimit = accountStatusMessage.MaxWeeklyLossLimit;
        }

        if (accountStatusMessage.TotalMaxPositionsQty !== null) {
            this.TotalMaxPositionsQty = accountStatusMessage.TotalMaxPositionsQty;
        }

        // if (accountStatusMessage.MaxOrdersPerDay !== null)
        //     this.MaxOrdersPerDay = accountStatusMessage.MaxOrdersPerDay;

        if (accountStatusMessage.Pin !== null) { this.userPin = accountStatusMessage.Pin; }

        if (accountStatusMessage.Login !== null) {
            this.FullAccString = accountStatusMessage.Login;
        }

        if (accountStatusMessage.AccountMode !== null) { this.AccountMode = accountStatusMessage.AccountMode; }

        if (accountStatusMessage.PreferredInstrumentType !== null) { this.PreferredInstrumentType = accountStatusMessage.PreferredInstrumentType; }

        if (accountStatusMessage.MaxPositions !== null) { this.MaxPositions = accountStatusMessage.MaxPositions; }

        if (accountStatusMessage.MaxPendingOrders !== null) { this.MaxPendingOrders = accountStatusMessage.MaxPendingOrders; }

        if (accountStatusMessage.MaxPendingOrdersValue !== null) {
            this.MaxPendingOrdersValue = accountStatusMessage.MaxPendingOrdersValue;
        }

        if (accountStatusMessage.MaxOrderCapital !== null) { this.MaxOrderCapital = accountStatusMessage.MaxOrderCapital; }

        if (accountStatusMessage.MaxDrawdownLevel !== null) { this.MaxDrawdownLevel = accountStatusMessage.MaxDrawdownLevel; }

        if (accountStatusMessage.StopTradingReason !== null) { this.StopTradingReason = accountStatusMessage.StopTradingReason; }

        if (accountStatusMessage.InterestsOnBalancePlanID !== null) { this.InterestPlanID = accountStatusMessage.InterestsOnBalancePlanID; }

        if (accountStatusMessage.IsSigned !== null) { this/* .user */.IsSigned = accountStatusMessage.IsSigned; }

        // allowedRoutes.Clear();
        if (accountStatusMessage.AllowedRoutes) {
            this.allowedRoutes = accountStatusMessage.AllowedRoutes.split(';');
        }

        if (accountStatusMessage.AccountVisibilityFlag != null) { // не перезатирать! мессадж с апдейтами не содержат это поле
            this.AccountVisibilityFlag = accountStatusMessage.AccountVisibilityFlag;

            this.isLinked = this.AccountVisibilityFlag === AccountVisibilityType.VISIBILITY_FLAG_LINK;
        }

        if (accountStatusMessage.AssetSharesNames !== null) { this.AssetSharesNames = accountStatusMessage.AssetSharesNames; }

        if (accountStatusMessage.MaxUrealizedLossLimit !== null) { this.MaxUrealizedLossLimit = accountStatusMessage.MaxUrealizedLossLimit; }

        if (accountStatusMessage.MaxRelativeDrawDownLevel !== null) { this.MaxRelativeDrawDownLevel = accountStatusMessage.MaxRelativeDrawDownLevel; }

        if (accountStatusMessage.LossLimitPerTrade !== null) { this.LossLimitPerTrade = accountStatusMessage.LossLimitPerTrade; }

        if (accountStatusMessage.MaxOrderAmount !== null) { this.MaxOrderAmount = accountStatusMessage.MaxOrderAmount; }

        if (accountStatusMessage.WaivedMargin !== null) { this.WaivedMargin = accountStatusMessage.WaivedMargin; }

        // if (accountStatusMessage.InitialMarginWithoutWaived !== null)
        //     this.InitialMarginWithoutWaived = accountStatusMessage.InitialMarginWithoutWaived;

        this.ExtendedFields = accountStatusMessage.ExtendedFields;

        /// //

        if (accountStatusMessage.ClusterNode !== null) { this.ClusterNode = accountStatusMessage.ClusterNode; }

        if (accountStatusMessage.CustodialPlanId !== null) { this.CustodialPlanId = accountStatusMessage.CustodialPlanId; }

        if (accountStatusMessage.FundingRateMarkupPlanId !== null) { this.FundingRatesPlanId = accountStatusMessage.FundingRateMarkupPlanId; }

        if (accountStatusMessage.DayTraderPattern !== null) {
            this.DayTraderPattern = accountStatusMessage.DayTraderPattern;
            this.OnDayTraderPatternChanged.Raise();
        }

        if (accountStatusMessage.DayTraderPatternProtection !== null) { this.DayTraderPatternProtection = accountStatusMessage.DayTraderPatternProtection; }

        if (accountStatusMessage.AvailableDayTrades !== null) { this.AvailableDayTrades = accountStatusMessage.AvailableDayTrades; }

        if (accountStatusMessage.StatisticsGroup !== null) {
            this.Statistics.FillByMessage(accountStatusMessage.StatisticsGroup);
        }

        this.tryUpdateAssetBalances(accountStatusMessage.AssetBalances);

        this.tryUpdateAssetInfoData(accountStatusMessage.AssetInfoData);

        this.tryUpdateAccountAdditionalInfo(accountStatusMessage.AccountAdditionalInfo);
    };

    public tryUpdateAccountAdditionalInfo (accountAdditionalInfo): void {
        if (!accountAdditionalInfo) { return; };

        for (const key in accountAdditionalInfo) {
            const item = accountAdditionalInfo[key];
            if (this.AccountAdditionalInfo[item.APIKey]) {
                this.AccountAdditionalInfo[item.APIKey].Update(item);
            } else {
                const newItem = new AccountAdditionalInfoItem();
                newItem.Update(item);
                this.AccountAdditionalInfo[item.APIKey] = newItem;
            }
        }
    };

    public tryUpdateAssetBalances (assetBalanceArray): void {
        if (!assetBalanceArray) { return; };

        // TODO.
        const dc = this.DataCache;

        const assetBalances = this.assetBalances;

        let AssetAdditionalInfoUpdated = false;

        for (let i = 0, len = assetBalanceArray.length; i < len; i++) {
            const balanceMessage = assetBalanceArray[i];

            const asset = dc.GetAssetById(balanceMessage.AssetId);
            if (!asset) continue;

            let balance = assetBalances[asset.Name];
            if (!balance) {
                balance = assetBalances[asset.Name] = new AssetBalance(this, balanceMessage.IsEmpty);
            } else if (balance.IsEmpty && !balanceMessage.IsEmpty) {
                balance.RecreateData();
            }

            // Fill AssetBalance
            if (!balance.IsEmpty) {
                AssetAdditionalInfoUpdated = balance.FillByMessage(balanceMessage) || AssetAdditionalInfoUpdated;
            }

            if (!balance.Asset && dc) {
                balance.Asset = asset;
            };

            if (!this.assetBalanceDefault ||
            (balance.Asset && dc && balance.Asset.Name === dc.baseCurrency)) {
                this.assetBalanceDefault = balance;
            };
        }
        if (AssetAdditionalInfoUpdated) {
            this.OnUpdateAssetBalanceData.Raise();
        }
    };

    public tryUpdateAssetInfoData (assetInfoDataArray): void {
        if (!assetInfoDataArray) { return; };

        const assetInfoData = this.assetInfoData;

        // TODO.
        const dc = this.DataCache;
        for (let i = 0, len = assetInfoDataArray.length; i < len; i++) {
            const infoDataMessage = assetInfoDataArray[i];

            const asset = dc.GetAssetByName(infoDataMessage.Name);
            if (!asset) continue;

            let infoData = assetInfoData[asset.Name];
            if (!infoData) { infoData = assetInfoData[asset.Name] = new AssetInfoData(); };

            infoData.CalculationMethod = infoDataMessage.CalculationMethod;
            infoData.Name = infoDataMessage.Name;
            infoData.TodayTradedQty = infoDataMessage.TodayTradedQty;
            infoData.TotalQuantity = infoDataMessage.TotalQuantity;
            infoData.TotalQuantityForMarginAvailable = infoDataMessage.TotalQuantityForMarginAvailable;
            infoData.AssetTradingMode = infoDataMessage.AssetTradingMode;

            this.OnUpdateInfoData.Raise(asset);
        }
    };

    public toString (withAsset = false): string {
        let result = this.FullAccString;
        const accNum = this.DataCache ? this.DataCache.getNumberOfAccounts() : null;
        const singleCurrency = this.DataCache.userHasSameCurrencyOnAllAccounts(); // #116404
        const isMultAsset = this.AccountType === AccountType.MultiAsset; // #116419

        if (withAsset && accNum > 1 && !singleCurrency && !isMultAsset) // #103422
        {
            const asset = this.assetBalanceDefault ? this.assetBalanceDefault.Asset : null;
            result += ' [' + (asset ? asset.Name : '') + ']';
        }

        return result;
    };

    get TrailingDrawdownLevel (): number {
        return this.assetBalanceDefault !== null ? this.assetBalanceDefault.TrailingDrawdownLevel : -1;
    }

    public getCrossPrice (): number {
    // Раньше сервер присылал обратный кросс - для перевода из валюты сервера в валюту аккаунта
        return this.DataCache.CrossRateCache.GetCrossPriceExp1(this.DataCache.baseCurrency);
    };

    // MB CORRCECT
    public getCrossPriceCorrect (): number {
    // Раньше сервер присылал обратный кросс - для перевода из валюты сервера в валюту аккаунта
        return 1 / this.DataCache.CrossRateCache.GetCrossPriceExp1(this.BaseCurrency);
    };

    public formatPrice (value, noCurrency = false): string {
        if (this.assetBalanceDefault) {
            return this.assetBalanceDefault.formatPrice(value, noCurrency);
        }
        // TODO
        if (noCurrency) {
            return PriceFormatter.formatPrice(value, Account.point);
        } else {
            return PriceFormatter.formatPrice(value, Account.point) + ' ' + this.DataCache.baseCurrency;
        }
    };

    public roundPrice (value: number): number {
        if (this.assetBalanceDefault != null) {
            return this.assetBalanceDefault.roundPrice(value);
        }

        return MathUtils.TruncateDouble(value, Account.point);
    }

    get StockValueCrossPrice (): number {
        return this.StockValue / this.getCrossPrice();
    }

    get StockValueWithoutPnlCrossPrice (): number {
        return this.StockValueWithoutPnl / this.getCrossPrice();
    }

    get StockValueDiscountCrossPrice (): number {
        return this.StockValueDiscount / this.getCrossPrice();
    }

    get StartPammCapitalCrossPrice (): number {
        return this.StartPammCapital / this.getCrossPrice();
    }

    get CurrPammCapitalCrossPrice (): number {
        return this.CurrPammCapital / this.getCrossPrice();
    }

    get CurrentDailyLoss (): number {
        return this.assetBalanceDefault !== null ? this.assetBalanceDefault.CurrentDailyLoss : -1;
    }

    get CurrentWeeklyLoss (): number {
        return this.assetBalanceDefault !== null ? this.assetBalanceDefault.CurrentWeeklyLoss : -1;
    }

    get BalancePlusAllRisks (): number {
        return this.assetBalanceDefault.assetBalanceData.balance +
                this.StockValueDiscount + this.ProfitForUnsettled +
                this.LossForUnsettled + this.assetBalanceDefault.UnsettledLoss +
                this.assetBalanceDefault.UnsettledProfit +
                this.UnsettledCashForStocks +
                this.UnsettledOptionPremium -
                this.assetBalanceDefault.UnusedPremiumFromOpenSell +
                this.assetBalanceDefault.UnsettledCollateral -/* 82717 */
                this.assetBalanceDefault.UnusedSettledCashForStocks - /* 98161 */
                this.assetBalanceDefault.UnusedSettledCollateral + /* 98161 */
                this.assetBalanceDefault.UnsettledDeposit;
    }

    get BalancePlusAllRisksCrossPrice (): number {
        return this.BalancePlusAllRisks / this.getCrossPrice();
    }

    get InstrumentTypeVolumeLimit (): string {
        if (!this.instrumentTypeVolumeLimit) {
            this.instrumentTypeVolumeLimit = this.DataCache.getRuleStringValueForAccount(RulesSet.VALUE_INSTRUMENT_TYPE_LIMIT, this, '');
        };

        return this.instrumentTypeVolumeLimit;
    }

    get UnsettledCashForStocks (): number {
        if (this.assetBalanceDefault != null) {
            return this.assetBalanceDefault.UsedUnsettledNegativeCashForStocks + this.assetBalanceDefault.UnsettledPositiveCashForStocks;
        } else { return 0; }
    }

    get UnsettledOptionPremium (): number {
        return this.assetBalanceDefault.UsedUnsettledNegativePremium + this.assetBalanceDefault.UnsettledPositivePremium - this.assetBalanceDefault.UnsettledPremiumFromOpenSell;
    }

    get StockOrdersReq (): number {
        return this.assetBalanceDefault != null ? this.assetBalanceDefault.StockOrdersReq : 0;
    }

    get StockOrdersReqCrossPrice (): number {
        return this.StockOrdersReq / this.getCrossPrice();
    }

    get WithdrawalAvailable (): any {
        return this.assetBalanceDefault != null ? this.assetBalanceDefault.WithdrawalAvaliable(this) : 1;
    }

    get assetName (): string {
        return this.assetBalanceDefault.Asset.Name;
    }

    /// <summary>
    /// Поиск ассетбаланса, правильный его пользовать везде
    /// </summary>
    public GetAssetBalanceCorrect (instrument?: Instrument, isBuy?: boolean): AssetBalance | null {
        if (this.AccountType !== AccountType.MultiAsset) {
            return this.assetBalanceDefault;
        }

        const assetBalances = this.assetBalances;
        if (!assetBalances) {
            return null;
        } else if (instrument !== null) {
            let res: any = null;
            if (instrument.TradingBalance === InstrumentTradingBalance.SETTLEMENT_IMMEDIATE) {
                if (isBuy) {
                    res = assetBalances[instrument.Exp2];
                } else { res = assetBalances[instrument.Exp1]; }
            } else {
                res = assetBalances[instrument.Exp2];
            }

            return res || null;
        } else if (instrument === null) {
            return this.assetBalanceDefault;
        }

        return null;
    }

    /// <summary>
    /// Поиск ассетбаланса
    /// </summary>
    public GetAssetBalanceByName (assetName: string): any {
        return this.assetBalances[assetName] || null;
    };

    public GetAssetDataInfoByName (assetName: string): AssetInfoData | null {
        return this.assetInfoData[assetName] || null;
    };

    public GetVolumeLimitStringByInstrumentType (instrType): string // #112150
    {
        const value = this.GetVolumeLimitByInstrumentType(instrType);
        const prec = MathUtils.getPrecision(value);
        const result = value ? PriceFormatter.formatPrice(value, prec) : '';

        return result;
    }

    public GetVolumeLimitByInstrumentType (instrType, returnFullData = false): any // #112150 if returnFullData = true -> result example: {instrumentTypeId: 2, amountType: 'EXPOSURE', limit: 14420.88}
    {
        const limitsJSON = this.InstrumentTypeVolumeLimit;
        const limitsObj = limitsJSON ? JSON.parse(limitsJSON) : null;
        const limits = limitsObj ? limitsObj.limits : [];

        for (let i = 0; i < limits.length; i++) {
            if (limits[i].instrumentTypeId == instrType) { return returnFullData ? limits[i] : limits[i].limit; }
        };

        return null;
    };

    public GetCommissionInfo (instrument): any {
        const commissionItem = this.CommissionPlan ? this.CommissionPlan.GetCommissionItem(instrument) : null;
        const additionalInfo = commissionItem ? commissionItem.GetCommissionInfo() : '';
        const key = 'screen.closePosition.additionalInfoLabel'; const label = Resources.getResource(key) + ':';

        return additionalInfo && !Resources.isHidden(key)
            ? '<br>' + label + '<br>' + additionalInfo
            : '';
    };

    public formatPriceShortCurrency (price: number): string {
        const asset = this.assetBalanceDefault?.Asset;
        if (asset) {
            const formattedPrice = asset.formatPrice(price, true);
            const [currencyStr, hasSymbol] = CurrencyUtils.GetCurrecySymbol(asset.Name);
            if (hasSymbol) { return `${currencyStr} ${formattedPrice}`; } else { return `${formattedPrice} ${currencyStr}`; }
        } else { return this.formatPrice(price, false); }
    }

    public equals (account: Account): boolean {
        return this === account;
    }

    public static IsEqualAccount (a1: Account, a2: Account): boolean {
        if (!a1 || !a2) { return false; };
        return a1.AcctNumber === a2.AcctNumber;
    };

    // #endregion

    public static GetInitialMarginSize (account: Account): any {
        const rp = account.RiskPlan.riskPlanCache.getDefaultRiskPlanSettings();
        if (!rp) { return null; };
        const productTypes = rp.availableProductTypes;
        const prodType = productTypes.length > 1 ? ProductType.Intraday : productTypes[0];
        const rpCashItem = rp.cache[prodType];
        if (!rpCashItem) { return null; };

        return rpCashItem.GetInitMargin();
    };

    public static GetAccountFeature (key: AccountFeature, account: Account, assetBalance, estimatedCurrency: any = undefined): number {
        if (estimatedCurrency === undefined) estimatedCurrency = null;
        if (account == null || assetBalance == null) { return 0; }

        const UseAccCurrency = true;// BaseApplication.App.MultiDataCache.UseAccCurrency;
        let initialMarginSize = null;
        switch (key) {
        // General
        case AccountFeature.Balance:
            return assetBalance.assetBalanceData.balance;

        case AccountFeature.ProjectedBalance:
            return assetBalance.assetBalanceData.balance + account.Profit + account.Loss + assetBalance.assetBalanceData.reservedBalance/* Unsettled сash */ + account.BlockedForStocks + account.OptionPremium + assetBalance.Collateral; // #41306 - BlockedForStocks, //#49418 - reserved balance //#82717 - Collateral

        case AccountFeature.BalancePlusAllRisks:
            return account.AccountType === AccountType.MultiAsset
                ? assetBalance.assetBalanceData.balance
                : account.BalancePlusAllRisks;

        case AccountFeature.CreditValue:
            return assetBalance.assetBalanceData.creditValue;

        case AccountFeature.AvailableFunds:
            return assetBalance.assetBalanceData.availableMargin;

        case AccountFeature.BlockedBalance:
            return assetBalance.assetBalanceData.blockedSum;

        case AccountFeature.CashBalance:
            return assetBalance.assetBalanceData.cashBalance;

        case AccountFeature.WithdrawalAvailable:
            return account.AccountType === AccountType.MultiAsset ? assetBalance.WithdrawalAvaliable(account) : account.WithdrawalAvailable;

        case AccountFeature.InterestRatePercent:
            return 0; // #40740

        case AccountFeature.EstimateValue:{
            let ec = 0;
            if (estimatedCurrency != null) {
                if (Object.keys(account.assetBalances).length > 1) {
                    for (const abKey in account.assetBalances) {
                        const ab = account.assetBalances[abKey];
                        if (ab.Asset.Type === AssetType.CURRENCY || ab.Asset.Type === AssetType.CRYPTO_CCY) { ec += ab.assetBalanceData.balance * account.DataCache.CrossRateCache.GetCrossPriceExp1Exp2(ab.Asset.Name, estimatedCurrency.Name); }
                    }
                } else { ec = assetBalance.assetBalanceData.balance * account.DataCache.CrossRateCache.GetCrossPriceExp1Exp2(assetBalance.Asset.Name, estimatedCurrency.Name); }
            } else { ec = assetBalance.assetBalanceData.balance; }

            return ec + account.BlockedForStocks;
        }

        // Margin
        case AccountFeature.MarginUsed:
            return assetBalance.assetBalanceData.minMargin + assetBalance.assetBalanceData.blockedForOrders + assetBalance.assetBalanceData.marginDeficiency;

        case AccountFeature.MarginUsedPercent:
            return assetBalance.InitialMarginReqPercent;

        case AccountFeature.RiskLevelPercent:
            return assetBalance.MaintenanceMarginReqPercent;

        case AccountFeature.WarnMarginReq:
            return assetBalance.WarningMarginRequirement;

        case AccountFeature.WarnMarginReqPercent:
            return assetBalance.WarningMarginReqPercent;

        case AccountFeature.MarginBeforeWarning:
            return assetBalance.MarginBeforeWarning;

        case AccountFeature.InitialMarginWithoutWaived:
            return assetBalance.assetBalanceData.InitialMarginWithoutWaived;

        case AccountFeature.MarginAvailable:{
            const margAvailableInit = account.AccountType === AccountType.MultiAsset
                ? (assetBalance.assetBalanceData.balance - (assetBalance.assetBalanceData.maintanceMargin + assetBalance.assetBalanceData.blockedForOrders + assetBalance.StockOrdersReq))
                : account.BalancePlusAllRisks - (assetBalance.assetBalanceData.maintanceMargin + assetBalance.assetBalanceData.blockedForOrders + assetBalance.StockOrdersReq);
            return margAvailableInit + assetBalance.assetBalanceData.StocksLiquidity - assetBalance.assetBalanceData.OptionPremiumReq;
        }

        case AccountFeature.MarginWarningLevel:
            return account.WarningLevel;

        case AccountFeature.WarningMargin:{
            let risks = (account.AccountType === AccountType.MultiAsset
                ? assetBalance.assetBalanceData.balance - assetBalance.StockOrdersReq
                : account.BalancePlusAllRisks - account.StockOrdersReq + assetBalance.assetBalanceData.StocksLiquidity);

            risks -= assetBalance.assetBalanceData.OptionPremiumReq;
            return risks * (account.WarningLevel / 100);
        }

        case AccountFeature.TotalMaintReq:
            return assetBalance.assetBalanceData.maintanceMargin;

        case AccountFeature.BlockedForStocks:
            return account.BlockedForStocks;

        case AccountFeature.UnsettledCash:
            return assetBalance.assetBalanceData.reservedBalance;

        case AccountFeature.StockValue:
            return UseAccCurrency ? account.StockValue : account.StockValueCrossPrice;

        case AccountFeature.StocksOrdersReq:
            return assetBalance.StockOrdersReq;

        case AccountFeature.OptionValue:
            return account.OptionValue;

        case AccountFeature.StocksLiquidity:
            return assetBalance.assetBalanceData.StocksLiquidity;

        case AccountFeature.VolumeLimitForALL:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.ALL);

        case AccountFeature.VolumeLimitForFOREX:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.FOREX);

        case AccountFeature.VolumeLimitForEQUITIES:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.EQUITIES);

        case AccountFeature.VolumeLimitForFUTURES:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.FUTURES);

        case AccountFeature.VolumeLimitForOPTIONS:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.OPTIONS);

        case AccountFeature.VolumeLimitForEQUITIES_CFD:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.EQUITIES_CFD);

        case AccountFeature.VolumeLimitForFORWARD:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.FORWARD);

        case AccountFeature.VolumeLimitForCFD_FUTURES:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.CFD_FUTURES);

        case AccountFeature.VolumeLimitForINDICIES:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.INDICIES);

        case AccountFeature.VolumeLimitForCRYPTO:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.CRYPTO);

        case AccountFeature.VolumeLimitForSPREADBET:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.SPREADBET);

        case AccountFeature.VolumeLimitForBOND:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.BOND);

        case AccountFeature.VolumeLimitForETF:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.ETF);

        case AccountFeature.VolumeLimitForTBILL:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.TBILL);

        case AccountFeature.VolumeLimitForSPOT:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.SPOT);

        case AccountFeature.VolumeLimitForCORPORATE:
            return account.GetVolumeLimitByInstrumentType(InstrumentTypes.CORPORATE);

        case AccountFeature.PowerOfAttorney:
            return 0;

        case AccountFeature.BuyingPower:
            initialMarginSize = this.GetInitialMarginSize(account);

            if (initialMarginSize === 0 || initialMarginSize === null) { return -1; }

            return assetBalance.AvailableMargin / initialMarginSize;

        case AccountFeature.GivenLeverage:
            initialMarginSize = this.GetInitialMarginSize(account);

            if (initialMarginSize === 0 || initialMarginSize === null) { return -1; }

            //                100/Init %
            return Math.round(1 / initialMarginSize);

        case AccountFeature.OpenBalance:
            return account.Statistics.BeginProjectedBalance || NaN;

        case AccountFeature.StopOut:
            return assetBalance.StopOutValue;

        case AccountFeature.DayTraderPatternProtection:
            return account.DayTraderPatternProtection;

        case AccountFeature.AvailableDayTrades:
            return account.AvailableDayTrades;

        case AccountFeature.MaxRelativeDrawDownLevel:
            return account.MaxRelativeDrawDownLevel !== null && account.MaxRelativeDrawDownLevel !== -1 ? account.MaxRelativeDrawDownLevel : 0;

        case AccountFeature.LossLimitPerTrade:
            return account.LossLimitPerTrade !== null ? account.MaxDailyLossLimit * account.LossLimitPerTrade / 100 : 0;

        case AccountFeature.IncomingFunds:
            return assetBalance.UnsettledDeposit;

        case AccountFeature.OpenNetPL:
            return account.ProfitNet;

        case AccountFeature.WaivedMargin:
            return account.WaivedMargin;

        case AccountFeature.OptionPremiumReq:
            return assetBalance.OptionPremiumReq;

        case AccountFeature.OpenGrossPL:
            return account.Profit;

        case AccountFeature.OpenPostionsNumber:
            return account.AccountType === AccountType.MultiAsset
                ? assetBalance.OpenPosNumber
                : account.OpenPosNumber;

        case AccountFeature.OpenOrdersNumber:
            return account.AccountType === AccountType.MultiAsset
                ? assetBalance.OpenOrdNumber
                : account.OpenOrdNumber;

        case AccountFeature.TodaysNetProfit:
            return assetBalance.TodayRealizedPNL;

        case AccountFeature.TodaysFee:
            return assetBalance.TodayFees;

        case AccountFeature.TodayGrossPNL:
            return assetBalance.TodayGrossPNL;

        case AccountFeature.TodayVolume:
            return assetBalance.TodayVolume ? assetBalance.TodayVolume : 0.0;

        case AccountFeature.TodayTrades:
            return assetBalance.TodayTrades ? assetBalance.TodayTrades : 0.0;

        case AccountFeature.EODTrailingDrawdownLevel:
            return assetBalance.EODTrailingDrawdownLevel;

        case AccountFeature.RelativeDailyLossLimit:
            return assetBalance.CalculatedRelativeDailyLossLimit;

        case AccountFeature.LiveLeverage:{
            const projBalance = Account.GetAccountFeature(AccountFeature.ProjectedBalance, account, assetBalance);
            return projBalance === 0 ? NaN : account.PositionsValue / projBalance;
        }

        case AccountFeature.TodaysPnLPercent:{
            const deposits = account.Statistics.AccountOperationStatistic.get(AccountOperations.Type.Deposit) ?? 0;
            const withdrawals = account.Statistics.AccountOperationStatistic.get(AccountOperations.Type.Withdraw) ?? 0;
            const transfers = account.Statistics.AccountOperationStatistic.get(AccountOperations.Type.Transfer) ?? 0;
            const transferFees = account.Statistics.AccountOperationStatistic.get(AccountOperations.Type.TransferFee) ?? 0;
            const withdrawalFees = account.Statistics.AccountOperationStatistic.get(AccountOperations.Type.withdrawalfee) ?? 0;
            const balanceDebit = account.Statistics.AccountOperationStatistic.get(AccountOperations.Type.BalanceDebit) ?? 0;
            const balanceCredit = account.Statistics.AccountOperationStatistic.get(AccountOperations.Type.BalanceCredit) ?? 0;
            const adjustments = account.Statistics.AccountOperationStatistic.get(AccountOperations.Type.Adjustment) ?? 0;
            const lastProjectedBalance = account.Statistics.BeginProjectedBalance;
            const projectedBalance = Account.GetAccountFeature(AccountFeature.ProjectedBalance, account, assetBalance, estimatedCurrency);
            const ID = projectedBalance - deposits - balanceCredit + Math.abs(withdrawals) + Math.abs(balanceDebit) - transfers - adjustments + transferFees + Math.abs(withdrawalFees);
            if (lastProjectedBalance === 0 || isNullOrUndefined(lastProjectedBalance)) {
                const divider = projectedBalance - ID;

                if (divider === 0) {
                    return 0;
                } else {
                    return ID * 100 / divider;
                }
            } else {
                return (ID - lastProjectedBalance) * 100 / lastProjectedBalance;
            }
        }

        case AccountFeature.AvailableCash:
            return assetBalance.AvailableCash;

        case AccountFeature.TotalPositionsValue:
            return assetBalance.TotalPositionsValue;

        default:
            return 0;
        }
    }

    public static GetAccountFeatureString (value: number | null | undefined, key: AccountFeature, account: Account, assetBalance, estimatedCurrency: any = undefined, noCurrency = false): string {
        if (estimatedCurrency === undefined) { estimatedCurrency = null; };

        switch (key) {
        case AccountFeature.Balance:
        case AccountFeature.ProjectedBalance:
        case AccountFeature.AvailableFunds:
        case AccountFeature.BlockedBalance:
        case AccountFeature.CashBalance:
        case AccountFeature.UnsettledCash:
        case AccountFeature.TodaysNetProfit:
        case AccountFeature.TodaysFee:
        case AccountFeature.TodayGrossPNL:
            return assetBalance.formatPriceExactly(value, noCurrency);

        case AccountFeature.WithdrawalAvailable:
            return assetBalance.formatPriceExactly(value === 0 ? 0 : value, noCurrency); // avoid -0   #95084

        case AccountFeature.MarginUsedPercent:
        case AccountFeature.RiskLevelPercent:
        case AccountFeature.WarnMarginReqPercent:
        case AccountFeature.MarginWarningLevel:
        case AccountFeature.TodaysPnLPercent:
            return PriceFormatter.formatPrice(MathUtils.TruncateDouble(value, 2), 2, false) + ' %';

        case AccountFeature.MarginUsed:
        case AccountFeature.MarginAvailable:
        case AccountFeature.WarningMargin:
        case AccountFeature.TotalMaintReq:
        case AccountFeature.BalancePlusAllRisks:
        case AccountFeature.CreditValue:
        case AccountFeature.BlockedForStocks:
        case AccountFeature.StocksOrdersReq:
        case AccountFeature.WarnMarginReq:
        case AccountFeature.MarginBeforeWarning:
        case AccountFeature.InitialMarginWithoutWaived:
        case AccountFeature.OpenBalance:
        case AccountFeature.StopOut:
        case AccountFeature.IncomingFunds:
        case AccountFeature.OptionPremiumReq:
        case AccountFeature.OpenGrossPL:
        case AccountFeature.EODTrailingDrawdownLevel:
        case AccountFeature.RelativeDailyLossLimit:
        case AccountFeature.AvailableCash:
        case AccountFeature.TotalPositionsValue:
            return assetBalance.formatPrice(value, noCurrency);

        case AccountFeature.BuyingPower:
            return assetBalance.formatPrice(value, noCurrency); // #108607 <- #108921

        case AccountFeature.GivenLeverage:
            return value && value >= 0 ? PriceFormatter.formatPrice(value, 0) : Resources.getResource('general.N_A');

        case AccountFeature.InterestRatePercent:
            return value?.toString() + ' %';

        case AccountFeature.EstimateValue:
            return estimatedCurrency != null
                ? PriceFormatter.formatPrice(value, estimatedCurrency.Point) + ' ' + estimatedCurrency.Name
                : assetBalance.formatPrice(value, noCurrency);

        case AccountFeature.StockValue:
            return assetBalance.formatPriceExactly(account.StockValue, noCurrency);

        case AccountFeature.OptionValue:
            return assetBalance.formatPriceExactly(account.OptionValue, noCurrency);

        case AccountFeature.StocksLiquidity:
            return assetBalance.formatPrice(assetBalance.StocksLiquidity, noCurrency);

        case AccountFeature.VolumeLimitForALL:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.ALL);

        case AccountFeature.VolumeLimitForFOREX:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.FOREX);

        case AccountFeature.VolumeLimitForEQUITIES:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.EQUITIES);

        case AccountFeature.VolumeLimitForFUTURES:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.FUTURES);

        case AccountFeature.VolumeLimitForOPTIONS:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.OPTIONS);

        case AccountFeature.VolumeLimitForEQUITIES_CFD:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.EQUITIES_CFD);

        case AccountFeature.VolumeLimitForFORWARD:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.FORWARD);

        case AccountFeature.VolumeLimitForCFD_FUTURES:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.CFD_FUTURES);

        case AccountFeature.VolumeLimitForINDICIES:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.INDICIES);

        case AccountFeature.VolumeLimitForCRYPTO:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.CRYPTO);

        case AccountFeature.VolumeLimitForSPREADBET:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.SPREADBET);

        case AccountFeature.VolumeLimitForBOND:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.BOND);

        case AccountFeature.VolumeLimitForETF:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.ETF);

        case AccountFeature.VolumeLimitForTBILL:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.TBILL);

        case AccountFeature.VolumeLimitForSPOT:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.SPOT);

        case AccountFeature.VolumeLimitForCORPORATE:
            return account.GetVolumeLimitStringByInstrumentType(InstrumentTypes.CORPORATE);

        case AccountFeature.PowerOfAttorney:
        // List<PowerOfAttorneyUser> powerOfAttorney = PowerOfAttorney.GetPoAUsersList(account);
            return '';// powerOfAttorney.Count > 0  ? string.Join(", ", powerOfAttorney.Select(x => x.login)) : "";

        case AccountFeature.DayTraderPatternProtection:
            return Resources.getResource('panel.accounts.accountDetails.RiskManagement.Trading.State.' + (value ? 'Enabled' : 'Disabled'));

        case AccountFeature.AvailableDayTrades:
            return value !== null && value !== undefined ? value.toString() : '';

        case AccountFeature.MaxRelativeDrawDownLevel:
            return value !== -1 ? assetBalance.formatPrice(value, noCurrency) : '';

        case AccountFeature.LossLimitPerTrade:
            return value !== null && value !== undefined && !isNaN(value) ? assetBalance.formatPrice(value, noCurrency) : '';

        case AccountFeature.OpenNetPL:
            return value !== null && value !== undefined && !isNaN(value) ? assetBalance.formatPrice(value, noCurrency) : '';

        case AccountFeature.WaivedMargin:
            return value != null && value !== -1 ? assetBalance.formatPrice(value, noCurrency) : Resources.getResource('general.N_A');

        case AccountFeature.OpenPostionsNumber:
        case AccountFeature.OpenOrdersNumber:
            return value === null || value === undefined
                ? ''
                : value.toString();

        case AccountFeature.TodayVolume:
            if (!isNaN(value)) {
                return assetBalance.FDataCache.formatVolume(null, value, false, GeneralSettings.TradingDefaults.ProductType, account);
            } else {
                return Resources.getResource('general.Calculating');
            }

        case AccountFeature.LiveLeverage:
            return !isNaN(value) ? PriceFormatter.formatPrice(MathUtils.TruncateDouble(value, 2), 2, false) : Resources.getResource('general.N_A');

        case AccountFeature.TodayTrades:
            return !isNaN(value) ? value.toString() : Resources.getResource('general.Calculating');

        default:
            return '';
        }
    }
}
