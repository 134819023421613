import { EventEmitter } from 'events';
import { type Account } from '../../Account';
import { type Instrument } from '../../Instrument';
import { OptionCalculatorModel } from '../OptionCalculator/OptionCalculatorModel';
import { IVCalculationPrice } from './IVCalculationPrice';
import { OptionChain } from './OptionChain/OptionChain';
import { DataCache } from '../../../DataCache';
import { HistoryType } from '../../../../Utils/History/HistoryType';
import { Greeks } from './Greeks';
import { OptionTraderUtils } from './OptionTraderUtils';
import { OptionCalculator } from '../OptionCalculator/OptionCalculator';
import { PaperPositionsCache } from './OptionPaperPosition/PaperPositionsCache';
import { OptionPutCall } from '../../../../Utils/Instruments/OptionPutCall';
import { type PaperPosition } from './OptionPaperPosition/PaperPosition';
import { type Order } from '../../Order';
import { type Position } from '../../Position';
import { type StrikePriceSettings } from '../../../../Utils/Instruments/StrikePriceSettings';
import { SavedOrdersController } from '../../SavedOrders/SavedOrdersController';
import { type OrderEditBase } from '../../OrderParams/order-edit/OrderEditBase';
import { Option } from './Option';
import { OptionAnalyzer } from './OptionAnalyzer/OptionAnalyzer';
import { type AnalyzerChartData } from './OptionAnalyzer/AnalyzerChartData';
import { AnalyzerChartDataInputParameters, AnalyzerChartDataPaperPositionInfo } from './OptionAnalyzer/AnalyzerChartDataInputParameters';
import { type AnalyzerInfoData } from './OptionAnalyzer/AnalyzerInfoData';
import { AnalyzerInfoDataInputParameters } from './OptionAnalyzer/AnalyzerInfoDataInputParameters';
import { type BaseInterval } from '../../../../Utils/History/BaseInterval';
import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';
import { Periods, TFInfo } from '../../../../Utils/History/TFInfo';
import { SessionInfo } from '../../../../Utils/History/SessionInfo';
import { ReloadHistoryParams } from '../../../../Utils/History/ReloadHistoryParams';
import { CashItem } from '../../History/CashItem';
import { type IOptionCalculator } from '../OptionCalculator/IOptionCalculator';
import { OptionVolatilityLab } from './OptionVolatilityLab/OptionVolatilityLab';
import { StrikeInfo, StrikeSeriesInfo, VolatilityLabChartDataInputParameters } from './OptionVolatilityLab/VolatilityLabChartDataInputParameters';
import { type VolatilityLabChartData } from './OptionVolatilityLab/VolatilityLabChartData';
import { OrderType } from '../../../../Utils/Trading/OrderType';

export class OptionTrader {
    private readonly _eventEmitter = new EventEmitter();
    private readonly _paperPositionsCache: PaperPositionsCache = new PaperPositionsCache();

    private _account: Account;
    private _fakeOption: Instrument;
    private _seriesDate: Date;

    private _lastProbabilityHistory: BaseInterval[];
    private _lastProbabilityHistoryPeriodYear: number = -1;

    public pricingModel: OptionCalculatorModel = OptionCalculatorModel.BlackSholes;
    public interestRate: number = 0;
    public ivCalculationPrice: IVCalculationPrice = IVCalculationPrice.Ask;

    public readonly optionChain: OptionChain = new OptionChain();
    public readonly optionAnalyzer: OptionAnalyzer = new OptionAnalyzer();
    public readonly optionVolatilityLab: OptionVolatilityLab = new OptionVolatilityLab();

    public get account (): Account {
        return this._account;
    }

    public set account (value: Account) {
        this._account = value;
        this.clear(true);
        this.populate();
        this.changeAccountForPaperPositions();
    }

    public get fakeOption (): Instrument {
        return this._fakeOption;
    }

    public set fakeOption (value: Instrument) {
        this.unsubscribe();
        this.clear(false);
        this._fakeOption = value;
        this.subscribe();
        this._eventEmitter.emit('onInstrumentChanged');
        this.populate();
    }

    public get underlier (): Instrument {
        if (isNullOrUndefined(this._fakeOption)) {
            return undefined;
        } else {
            return this._fakeOption.ForwardBaseInstrument;
        }
    }

    public get seriesDates (): Date[] {
        const seriesDates: Date[] = [];
        if (isNullOrUndefined(this.fakeOption)) {
            return seriesDates;
        }
        const instrDateSettingsList = this.fakeOption.InstrDateSettingsList;
        for (let i = 0; i < instrDateSettingsList.length; i++) {
            seriesDates.push(instrDateSettingsList[i].ContractMonthDate);
        }
        return seriesDates;
    }

    public get seriesDate (): Date {
        return this._seriesDate;
    }

    public set seriesDate (value: Date) {
        this._seriesDate = value;
        this._eventEmitter.emit('onSeriesDateChanged');
    }

    public get strikePriceSettings (): StrikePriceSettings[] {
        return this.getStrikePriceSettings(this.seriesDate);
    }

    public get options (): Option[] {
        return this.getOptions(this.strikePriceSettings);
    }

    constructor () {
        DataCache.OnAddOrder.Subscribe(this.onAddOrder, this);
        DataCache.OnRemoveOrder.Subscribe(this.onRemoveOrder, this);
        DataCache.OnUpdateOrder.Subscribe(this.onUpdateOrder, this);
        DataCache.OnAddPosition.Subscribe(this.onAddPosition, this);
        DataCache.OnRemovePosition.Subscribe(this.onRemovePosition, this);
        DataCache.OnUpdatePosition.Subscribe(this.onUpdatePosition, this);
        DataCache.OnAddSLOrderToPosition.Subscribe(this.onAddSLOrderToPosition, this);
        DataCache.OnAddTPOrderToPosition.Subscribe(this.onAddTPOrderToPosition, this);
    }

    dispose (): void {
        DataCache.OnAddOrder.UnSubscribe(this.onAddOrder, this);
        DataCache.OnRemoveOrder.UnSubscribe(this.onRemoveOrder, this);
        DataCache.OnUpdateOrder.UnSubscribe(this.onUpdateOrder, this);
        DataCache.OnAddPosition.UnSubscribe(this.onAddPosition, this);
        DataCache.OnRemovePosition.UnSubscribe(this.onRemovePosition, this);
        DataCache.OnUpdatePosition.UnSubscribe(this.onUpdatePosition, this);
        DataCache.OnAddSLOrderToPosition.UnSubscribe(this.onAddSLOrderToPosition, this);
        DataCache.OnAddTPOrderToPosition.UnSubscribe(this.onAddTPOrderToPosition, this);
        this.unsubscribe();
        this.clear(false);
    }

    public getUnderlierPrice (): number {
        const account: Account = this.account;
        const underlier: Instrument = this.underlier;
        let underlierPrice: number = NaN;
        if (isNullOrUndefined(account) || isNullOrUndefined(underlier)) {
            return NaN;
        }
        switch (underlier.HistoryType) {
        case HistoryType.QUOTE_LEVEL1:
            underlierPrice = underlier.Level1.GetBid(account);
            break;
        case HistoryType.QUOTE_ASK:
            underlierPrice = underlier.Level1.GetAsk(account);
            break;
        default:
            underlierPrice = underlier.Level1.GetLastPrice(account);
            break;
        }

        if (!isValidNumber(underlierPrice) || underlierPrice <= 0) {
            return NaN;
        } else {
            return underlierPrice;
        }
    }

    public getStrikePriceSettings (date: Date): StrikePriceSettings[] {
        if (isNullOrUndefined(this.fakeOption) || isNullOrUndefined(date)) {
            return [];
        }
        const instrDateSettings = this.fakeOption.InstrDateSettingsList.filter((x) => x.ContractMonthDate.getTime() === date.getTime())[0];
        if (!isNullOrUndefined(instrDateSettings)) {
            return [...instrDateSettings.StrikePricesList].sort((a, b) => a.StrikePrice - b.StrikePrice);
        } else {
            return [];
        }
    }

    public getOptions (strikePriceSettingsList: StrikePriceSettings[]): Option[] {
        const options = [];

        const fakeOption = this.fakeOption;

        for (let i = 0; i < strikePriceSettingsList.length; i++) {
            const strikePriceSettings: StrikePriceSettings = strikePriceSettingsList[i];
            const putInstrument = DataCache.getInstrumentByTradable_ID(strikePriceSettings.InstrumentTradableIDPut, fakeOption.Route);
            const callInstrument = DataCache.getInstrumentByTradable_ID(strikePriceSettings.InstrumentTradableIDCall, fakeOption.Route);
            options.push(new Option(strikePriceSettings.StrikePrice, putInstrument, callInstrument, strikePriceSettings.PutEnabled, strikePriceSettings.CallEnabled));
        }
        return options;
    }

    public getCalculator (): IOptionCalculator {
        return OptionCalculator.getCalculator(this.pricingModel);
    }

    public getGreeks (putCall: OptionPutCall, optionInstrument: Instrument, price: number = NaN): Greeks {
        let greeks = new Greeks();

        if (optionInstrument == null || !optionInstrument.isOptionSymbol) {
            greeks.delta = 1;
            greeks.gamma = 0;
            greeks.vega = 0;
            greeks.theta = 0;
            greeks.rho = 0;
            greeks.iv = 0;
            return greeks;
        }

        const underlierPrice = this.getUnderlierPrice();
        const optionPrice = isNaN(price) ? this.getOptionPrice(optionInstrument) : price;
        const strike = optionInstrument.StrikePrice;
        const daysToExpiration = OptionTraderUtils.getDaysToExpirationForInstrument(optionInstrument) / 365;
        const intRate = this.interestRate;

        if (isNaN(underlierPrice) || isNaN(optionPrice)) {
            return greeks;
        }

        const model = this.getCalculator();

        if (isNullOrUndefined(model)) {
            return greeks;
        }

        greeks = new Greeks();
        greeks.iv = model.iv(putCall, optionPrice, underlierPrice, strike, daysToExpiration, intRate);
        greeks.delta = model.delta(putCall, underlierPrice, strike, daysToExpiration, intRate, greeks.iv);
        greeks.theta = model.theta(putCall, underlierPrice, strike, daysToExpiration, intRate, greeks.iv);
        greeks.rho = model.rho(putCall, underlierPrice, strike, daysToExpiration, intRate, greeks.iv);
        greeks.vega = model.vega(underlierPrice, strike, daysToExpiration, intRate, greeks.iv);
        greeks.gamma = model.gamma(underlierPrice, strike, daysToExpiration, intRate, greeks.iv);

        return greeks;
    }

    public getPaperPositionsForAnalyzer (): PaperPosition[] {
        return this._paperPositionsCache.getPaperPositionsForAnalyzer();
    }

    public getPaperPosition (option: Instrument): PaperPosition {
        return this._paperPositionsCache.getPaperPosition(option);
    }

    public getPaperPositionForOrder (order: Order): PaperPosition {
        return this._paperPositionsCache.getPaperPositionForOrder(order);
    }

    public getPaperPositionForPosition (position: Position): PaperPosition {
        return this._paperPositionsCache.getPaperPositionForPosition(position);
    }

    public createPaperPosition (option: Instrument): void {
        const paperPosition = this._paperPositionsCache.createPaperPosition(this.account, option);
        paperPosition.SubscribeOnUpdate(this.raiseOnUpdateMarketPaperPosition);
        this._eventEmitter.emit('onCreatePaperPosition', paperPosition);
    }

    public createPaperPositionFromOrderEdit (orderEdit: OrderEditBase): void {
        const paperPosition = this._paperPositionsCache.createPaperPosition(orderEdit.account, orderEdit.instrument);
        SavedOrdersController.updateByOrderEdit(paperPosition, orderEdit);
        this._eventEmitter.emit('onCreatePaperPosition', paperPosition);
    }

    public removePaperPosition (option: Instrument): void {
        const paperPosition = this._paperPositionsCache.removePaperPosition(option);
        if (!isNullOrUndefined(paperPosition)) {
            paperPosition.UnsubscribeOnUpdate(this.raiseOnUpdateMarketPaperPosition);
            this._eventEmitter.emit('onRemovePaperPosition', paperPosition);
        }
    }

    public removePaperPositionForOrder (order: Order): void {
        const paperPosition = this._paperPositionsCache.removePaperPositionForOrder(order);
        if (!isNullOrUndefined(paperPosition)) {
            this._eventEmitter.emit('onRemovePaperPosition', paperPosition);
        }
    }

    public removePaperPositionForPosition (position: Position): void {
        const paperPosition = this._paperPositionsCache.removePaperPositionForPosition(position);
        if (!isNullOrUndefined(paperPosition)) {
            this._eventEmitter.emit('onRemovePaperPosition', paperPosition);
        }
    }

    public updatePaperPosition (option: Instrument): void {
        const paperPosition = this._paperPositionsCache.getPaperPosition(option);
        if (!isNullOrUndefined(paperPosition)) { this._eventEmitter.emit('onUpdatePaperPosition', paperPosition); }
    }

    public changeAccountForPaperPositions (): void {
        const paperPositions = this._paperPositionsCache.getPaperPositions();
        for (let i = 0; i < paperPositions.length; i++) {
            const paperPosition = paperPositions[i];
            if (paperPosition.isPosition()) {
                continue;
            }
            paperPosition.Account = this.account;
            this._eventEmitter.emit('onCreatePaperPosition', paperPosition);
        }
        this._eventEmitter.emit('onChangeAccountForPaperPositions');
    }

    public changeInstrumentForPaperPosition (oldValue: Instrument, newValue: Instrument): void {
        const paperPosition = this._paperPositionsCache.changeInstrumentForPaperPosition(oldValue, newValue);
        this._eventEmitter.emit('onChangeInstrumentForPaperPosition', paperPosition);
    }

    public clearPaperPositions (): void {
        const paperPositions = this._paperPositionsCache.getPaperPositions();
        for (let i = 0; i < paperPositions.length; i++) {
            const paperPosition = paperPositions[i];
            if (paperPosition.isPosition()) {
                continue;
            }
            this.removePaperPosition(paperPosition.Instrument);
        }
    }

    public getOptionAnalyzerChartData (): AnalyzerChartData {
        const underlierPrice = this.getUnderlierPrice();
        const interestRate = this.interestRate;
        const paperPositionsInfo: AnalyzerChartDataPaperPositionInfo[] = [];
        const analyzePaperPositions = this.getPaperPositionsForAnalyzer();
        for (let i = 0; i < analyzePaperPositions.length; i++) {
            const analyzePaperPosition = analyzePaperPositions[i];
            const greeks = this.getGreeks(analyzePaperPosition.Instrument.PutCall, analyzePaperPosition.Instrument, analyzePaperPosition.PriceForGreeksCalculation);
            paperPositionsInfo.push(new AnalyzerChartDataPaperPositionInfo(analyzePaperPosition, greeks));
        }
        const model = this.getCalculator();
        const inputParams = new AnalyzerChartDataInputParameters(underlierPrice, interestRate, paperPositionsInfo, model);
        return this.optionAnalyzer.getOptionAnalyzerChartData(inputParams);
    }

    public getOptionAnalyzerInfoData (dataX: number): AnalyzerInfoData {
        const paperPositionsInfo: AnalyzerChartDataPaperPositionInfo[] = [];
        const analyzePaperPositions = this.getPaperPositionsForAnalyzer();
        for (let i = 0; i < analyzePaperPositions.length; i++) {
            const analyzePaperPosition = analyzePaperPositions[i];
            const greeks = this.getGreeks(analyzePaperPosition.Instrument.PutCall, analyzePaperPosition.Instrument, analyzePaperPosition.PriceForGreeksCalculation);
            paperPositionsInfo.push(new AnalyzerChartDataPaperPositionInfo(analyzePaperPosition, greeks));
        }
        const model = this.getCalculator();
        return this.optionAnalyzer.getOptionAnalyzerInfoData(new AnalyzerInfoDataInputParameters(dataX, this.interestRate, paperPositionsInfo, model));
    }

    public async getOptionAnalyzerProbabilityDataAsync (leftBorder: number, rightBorder: number): Promise<{ p1: number, p2: number }> {
        const account = this.account;
        const instrument = this.underlier;
        const optionAnalyzer = this.optionAnalyzer;
        const lastPrice = instrument.Level1.GetLastPrice(account);

        if (!isValidArray(this._lastProbabilityHistory) || this._lastProbabilityHistoryPeriodYear !== optionAnalyzer.historyPeriodYear) {
            const to = new Date(DateTimeUtils.DateTimeUtcNow().getTime());
            const from = new Date(DateTimeUtils.DateTimeUtcNow().getTime());
            from.setFullYear(from.getFullYear() - optionAnalyzer.historyPeriodYear);
            const tfInfo = new TFInfo();
            tfInfo.Periods = Periods.DAY;
            tfInfo.HistoryType = instrument.HistoryType;
            tfInfo.SessionInfo = new SessionInfo(false);
            tfInfo.Plan = DataCache.GetSpreadPlan(account);
            const historyParams = new ReloadHistoryParams();
            historyParams.updateWithProps({
                account,
                instrument,
                TimeFrameInfo: tfInfo,
                FromTime: from,
                ToTime: to,
                UseDefaultInstrumentHistoryType: false
            });
            const cashItem: CashItem = await CashItem.CreateWithHistory(instrument, historyParams);
            if (!isNullOrUndefined(cashItem)) {
                this._lastProbabilityHistory = cashItem.FNonEmptyCashArray;
                this._lastProbabilityHistoryPeriodYear = optionAnalyzer.historyPeriodYear;
            }
        }
        return this.optionAnalyzer.getOptionAnalyzerProbabilityData(this._lastProbabilityHistory, lastPrice, leftBorder, rightBorder);
    }

    public getVolatilityLabChartData (seriesDates: Date[]): VolatilityLabChartData {
        const underlierPriceStep = this.underlier.PointSize;
        const underlierPricePrecision = this.underlier.Precision;
        const underlierPrice = this.getUnderlierPrice();
        const calculator = this.getCalculator();
        const seriesMapInputParameters = new Map<Date, StrikeSeriesInfo>();
        const dates = seriesDates;
        for (let i = 0; i < dates.length; i++) {
            const strikes: StrikeInfo[] = [];
            const strikePriceSettings = this.getStrikePriceSettings(dates[i]);
            const options = this.getOptions(strikePriceSettings);
            for (let j = 0; j < options.length; j++) {
                const putEnabled = options[j].putEnabled;
                const callEnabled = options[j].callEnabled;
                const strikePrice = options[j].strikePrice;
                const call = options[j].call;
                const put = options[j].put;
                const callGreeks = this.getGreeks(OptionPutCall.OPTION_CALL_VANILLA, call);
                const putGreeks = this.getGreeks(OptionPutCall.OPTION_PUT_VANILLA, put);
                const strikeInfo = new StrikeInfo(strikePrice, callEnabled, putEnabled, callGreeks, putGreeks);
                strikes.push(strikeInfo);
            }
            let minATMStrike: number;
            let maxATMStrike: number;
            for (let i = 0; i < strikes.length; i++) {
                if (strikes[i].strikePrice <= underlierPrice) {
                    continue;
                }

                if (i === 0) {
                    break;
                }

                minATMStrike = maxATMStrike = strikes[i - 1].strikePrice;
                if (minATMStrike !== underlierPrice) {
                    maxATMStrike = strikes[i].strikePrice;
                }
                break;
            }
            seriesMapInputParameters.set(dates[i], new StrikeSeriesInfo(minATMStrike, maxATMStrike, strikes));
        }
        const inputParameters = new VolatilityLabChartDataInputParameters(underlierPriceStep, underlierPricePrecision, underlierPrice, calculator, seriesMapInputParameters);
        return this.optionVolatilityLab.getVolatilityChartData(inputParameters);
    }

    // #region Subscriptions

    public subscribeOnRepopulate (callback: () => void): void {
        this._eventEmitter.on('onRepopulate', callback);
    }

    public unsubscribeOnRepopulate (callback: () => void): void {
        this._eventEmitter.off('onRepopulate', callback);
    }

    public subscribeOnInstrumentChanged (callback: () => void): void {
        this._eventEmitter.on('onInstrumentChanged', callback);
    }

    public unsubscribeOnInstrumentChanged (callback: () => void): void {
        this._eventEmitter.off('onInstrumentChanged', callback);
    }

    public subscribeOnSeriesDateChanged (callback: () => void): void {
        this._eventEmitter.on('onSeriesDateChanged', callback);
    }

    public unsubscribeOnSeriesDateChanged (callback: () => void): void {
        this._eventEmitter.off('onSeriesDateChanged', callback);
    }

    public subscribeOnCreatePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.on('onCreatePaperPosition', callback);
    }

    public unsubscribeOnCreatePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.off('onCreatePaperPosition', callback);
    }

    public subscribeOnUpdatePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.on('onUpdatePaperPosition', callback);
    }

    public unsubscribeOnUpdatePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.off('onUpdatePaperPosition', callback);
    }

    public subscribeOnChangeAccountForPaperPositions (callback: () => void): void {
        this._eventEmitter.on('onChangeAccountForPaperPositions', callback);
    }

    public unsubscribeOnChangeAccountForPaperPositions (callback: () => void): void {
        this._eventEmitter.off('onChangeAccountForPaperPositions', callback);
    }

    public subscribeOnChangeInstrumentForPaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.on('onChangeInstrumentForPaperPosition', callback);
    }

    public unsubscribeOnChangeInstrumentForPaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.off('onChangeInstrumentForPaperPosition', callback);
    }

    public subscribeOnRemovePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.on('onRemovePaperPosition', callback);
    }

    public unsubscribeOnRemovePaperPosition (callback: (paperPosition: PaperPosition) => void): void {
        this._eventEmitter.off('onRemovePaperPosition', callback);
    }

    public subscribeOnQuoteChanged (callback: () => void): void {
        this._eventEmitter.on('onQuoteChanged', callback);
    }

    public unsubscribeOnQuoteChanged (callback: () => void): void {
        this._eventEmitter.off('onQuoteChanged', callback);
    }

    public subscribeOnAddOrder (callback: (order: Order) => void): void {
        this._eventEmitter.on('onAddOrder', callback);
    }

    public unsubscribeOnAddOrder (callback: (order: Order) => void): void {
        this._eventEmitter.off('onAddOrder', callback);
    }

    public subscribeOnRemoveOrder (callback: (order: Order) => void): void {
        this._eventEmitter.on('onRemoveOrder', callback);
    }

    public unsubscribeOnRemoveOrder (callback: (order: Order) => void): void {
        this._eventEmitter.off('onRemoveOrder', callback);
    }

    public subscribeOnUpdateOrder (callback: (order: Order) => void): void {
        this._eventEmitter.on('onUpdateOrder', callback);
    }

    public unsubscribeOnUpdateOrder (callback: (order: Order) => void): void {
        this._eventEmitter.off('onUpdateOrder', callback);
    }

    public subscribeOnAddPosition (callback: (position: Position) => void): void {
        this._eventEmitter.on('onAddPosition', callback);
    }

    public unsubscribeOnAddPosition (callback: (position: Position) => void): void {
        this._eventEmitter.off('onAddPosition', callback);
    }

    public subscribeOnRemovePosition (callback: (position: Position) => void): void {
        this._eventEmitter.on('onRemovePosition', callback);
    }

    public unsubscribeOnRemovePosition (callback: (position: Position) => void): void {
        this._eventEmitter.off('onRemovePosition', callback);
    }
    // #endregion

    public updateByInstrumentID (insID: string): Instrument {
        const instrument = DataCache.OptionsCache.getFirstOptionById(insID);
        if (instrument != null) {
            this.fakeOption = instrument;
        }

        return instrument;
    }

    // #region  Eventhandlers
    private readonly newQuote = (quote): void => { this._eventEmitter.emit('onQuoteChanged'); };

    private onAddOrder (order: Order): void {
        if (!this.IsAllowAccountAndInstrument(order.Account, order.Instrument)) {
            return;
        }
        if (order.isClosingOrder()) {
            const position = DataCache.getPositionById(order.PositionId);
            if (!isNullOrUndefined(position)) {
                this.onUpdatePosition(position);
            }
        } else {
            this.removePaperPositionForOrder(order);
        }
        this._eventEmitter.emit('onAddOrder', order);
    }

    private onRemoveOrder (order: Order): void {
        if (!this.IsAllowAccountAndInstrument(order.Account, order.Instrument)) {
            return;
        }
        if (order.isClosingOrder()) {
            const position = DataCache.getPositionById(order.PositionId);
            if (!isNullOrUndefined(position)) {
                this.onUpdatePosition(position);
            }
        }
        this._eventEmitter.emit('onRemoveOrder', order);
    }

    private onUpdateOrder (order: Order): void {
        // TODO
    }

    private onAddPosition (position: Position): void {
        if (!this.IsAllowAccountAndInstrument(position.Account, position.Instrument)) {
            return;
        }
        const paperPosition = this.getPaperPositionForPosition(position);
        if (!isNullOrUndefined(paperPosition)) {
            paperPosition.attachPosition(position);
            this._eventEmitter.emit('onUpdatePaperPosition', paperPosition);
        } else {
            const paperPosition = this.createPaperPositionForPosition(position);
            this._eventEmitter.emit('onCreatePaperPosition', paperPosition);
        }
        this._eventEmitter.emit('onAddPosition', position);
    }

    private onRemovePosition (position: Position): void {
        if (!this.IsAllowAccountAndInstrument(position.Account, position.Instrument)) {
            return;
        }
        this.removePaperPositionForPosition(position);
        this._eventEmitter.emit('onRemovePosition', position);
    }

    private onUpdatePosition (position: Position): void {
        if (!this.IsAllowAccountAndInstrument(position.Account, position.Instrument)) {
            return;
        }
        const paperPosition = this.getPaperPositionForPosition(position);
        if (!isNullOrUndefined(paperPosition)) {
            if (paperPosition.isAttachedPosition(position)) {
                paperPosition.updatePosition();
            } else {
                paperPosition.attachPosition(position);
            }
            this._eventEmitter.emit('onUpdatePaperPosition', paperPosition);
        } else {
            const paperPosition = this.createPaperPositionForPosition(position);
            this._eventEmitter.emit('onCreatePaperPosition', paperPosition);
        }
        this._eventEmitter.emit('onUpdatePosition', position);
    }

    private onAddSLOrderToPosition (position: Position): void {
        this.onAddOrder(position.SLOrder);
    }

    private onAddTPOrderToPosition (position: Position): void {
        this.onAddOrder(position.TPOrder);
    }
    // #endregion

    private getOptionPrice (spotInstrument: Instrument): number {
        const account = this.account;
        const ivCalculationPrice = this.ivCalculationPrice;

        let spotPrice = NaN;
        switch (ivCalculationPrice) {
        case IVCalculationPrice.Ask:
            spotPrice = spotInstrument.Level1.GetAsk(account);
            break;
        case IVCalculationPrice.Bid:
            spotPrice = spotInstrument.Level1.GetBid(account);
            break;
        case IVCalculationPrice.Last:
            spotPrice = spotInstrument.Level1.GetLastPrice(account);
            break;
        }

        if (!isValidNumber(spotPrice) || spotPrice <= 0) {
            return NaN;
        } else {
            return spotPrice;
        }
    }

    private getOrders (): Order[] {
        const result = [];
        const orders = DataCache.getAllOrders();
        for (const orderId in orders) {
            const order = orders[orderId];
            if (this.IsAllowAccountAndInstrument(order.Account, order.Instrument)) {
                result.push(order);
            }
        }
        return result;
    }

    private getPositions (): Position[] {
        const result = [];
        const positions = DataCache.getAllPositions();
        for (const posId in positions) {
            const pos = positions[posId];
            if (this.IsAllowAccountAndInstrument(pos.Account, pos.Instrument)) {
                result.push(pos);
            }
        }
        return result;
    }

    private createPaperPositionForPosition (position: Position): PaperPosition {
        const paperPosition = this._paperPositionsCache.createPaperPosition(this.account, position.Instrument);
        paperPosition.attachPosition(position);
        return paperPosition;
    }

    private readonly raiseOnUpdateMarketPaperPosition = (paperPosition: PaperPosition): void => {
        if (paperPosition.OrderType !== OrderType.Market) {
            return;
        }
        this._eventEmitter.emit('onUpdatePaperPosition', paperPosition);
    };

    private IsAllowAccountAndInstrument (account: Account, instrument: Instrument): boolean {
        if (this.account !== account) {
            return false;
        }

        if (this.underlier.InstrumentTradableID === instrument.InstrumentTradableID &&
            this.underlier.Route === instrument.Route
        ) {
            return true;
        }
        if (this.seriesDates.length === 0) {
            return false;
        }
        return this.underlier === instrument.ForwardBaseInstrument;
    }

    private populate (): void {
        if (isNullOrUndefined(this.account) || isNullOrUndefined(this.underlier)) {
            return;
        }
        this._eventEmitter.emit('onRepopulate');

        const positions = this.getPositions();
        for (const position of positions) {
            this.onAddPosition(position);
        }

        const orders = this.getOrders();
        for (const order of orders) {
            this.onAddOrder(order);
        }
    }

    private clear (onlyPositions: boolean): void {
        const paperPositions = this._paperPositionsCache.getPaperPositions();
        for (let i = 0; i < paperPositions.length; i++) {
            if (onlyPositions && !paperPositions[i].isPosition()) {
                continue;
            }
            const paperPosition = paperPositions[i];
            paperPosition.UnsubscribeOnUpdate(this.raiseOnUpdateMarketPaperPosition);
        }
        this._paperPositionsCache.clear(onlyPositions);
    }

    private subscribe (): void {
        const underlier = this.underlier;
        if (isNullOrUndefined(underlier)) {
            return;
        }
        DataCache.FQuoteCache.addListener(underlier, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.addListener(underlier, this, HistoryType.QUOTE_TRADES);
        DataCache.FQuoteCache.addListener(underlier, this, HistoryType.QUOTE_INSTRUMENT_DAY_BAR);
    }

    private unsubscribe (): void {
        const underlier = this.underlier;
        if (isNullOrUndefined(underlier)) {
            return;
        }
        DataCache.FQuoteCache.removeListener(underlier, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.removeListener(underlier, this, HistoryType.QUOTE_TRADES);
        DataCache.FQuoteCache.removeListener(underlier, this, HistoryType.QUOTE_INSTRUMENT_DAY_BAR);
    }
}
