// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { LookupDropDownShowParams } from '../../UtilsClasses/LookupDropDownShowParams';
import { TerceraAccountLookupDropDownForm } from './TerceraAccountLookupDropDownForm';
import { DataCache } from '../../../Commons/DataCache';
import { SessionSettings } from '../../../Commons/SessionSettings';
import { TerceraLookup } from './TerceraLookup';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';
import { type Account } from '../../../Commons/cache/Account';
import { AccountType } from '../../../Utils/Account/AccountType';
import { type ParamType } from '../../../Commons/cache/AllowedReportConstants';

export class TerceraAccountLookup extends TerceraLookup {
    public ItemAll: _AccountItemAll;
    public isFocusoutListenerAdded: boolean;
    public showMAM: boolean;
    public multiSelectDropDownItems: any[] = null;
    public isMultiSelectMode: boolean;
    public paramType: ParamType;

    public override getType (): ControlsTypes { return ControlsTypes.TerceraAccountLookup; }

    public override oninit (): void {
        super.oninit();
        this.ItemAll = new _AccountItemAll();
    }

    public override oncomplete (): void {
        this.repopulate();
        super.oncomplete();

        this.updateEnabledState();
    }

    public updateEnabledState (): void {
        const ignore = this.get('ignoreForceLinkingByAccount');
        const forceLinking = DataCache.EnableForceLinkingByAccount();

        const enabled = ignore || !forceLinking;
        void this.set({ enabled });
    }

    public getSelectedAccountID (): string {
        const acc = this.get('selectedItem');
        return acc ? acc.AcctNumber : '';
    }

    public override setSelectValue (newSelectedItem: any/* _AccountItemSelected */, suppressEvents?): void {
        super.setSelectValue(newSelectedItem, suppressEvents);

        if (newSelectedItem == null) {
            return;
        }

        newSelectedItem.multiSelected ? this.alignToBegin() : this.alignToEnd();
    }

    public alignToEnd (): void {
        const input = this.textBox?.getInputElement();
        if (isNullOrUndefined(input)) return;

        const caretPosition = input.selectionStart;
        const textWidth = input.scrollWidth;
        const visibleWidth = input.clientWidth;

        if (textWidth > visibleWidth) { input.scrollTo(caretPosition + textWidth - visibleWidth, 0); }

        // #117822: correcting the issue where HTML input resets the scroll to zero position when the input loses focus
        if (!this.isFocusoutListenerAdded) // preventing the event from being added again
        {
            input.addEventListener('focusout', () => { this.alignToEnd(); }); // blur event is not suitable here
            this.isFocusoutListenerAdded = true;
        }
    }

    public alignToBegin (): void {
        const input = this.textBox?.getInputElement();
        if (input != null) {
            input.scrollTo(0, 0);
        }
    }

    public selectItem (accountId: string): void {
        const accs = DataCache.Accounts;
        const acc = accs[accountId];
        if (!isNullOrUndefined(acc)) {
            void this.set('selectedItem', acc);
        }
    }

    public override moveToItem (next: boolean): void {
        const newIndex = this.selectedItemIndex + (next ? 1 : -1);

        if (newIndex >= 0 && newIndex < this.items.length) {
            if (!this.showMAM && this.items[newIndex].isMAM) {
                return;
            }
            this.selectedItemIndex = newIndex;
            this.setSelectValue(this.items[newIndex]);
            this.textBox.selectAll();
        }
    }

    public repopulate (): void {
        const accounts = (this.get('onlyOwnAccounts') as boolean) && DataCache.EnableForceLinkingByAccount() ? DataCache.getOwnedAccounts() : DataCache.getAccounts();
        if (DataCache.getNumberOfAccounts() === 1) {
            void this.set('visible', false);
        }

        this.multiSelectDropDownItems = null;

        const excludeMultiAsset: boolean = this.get('excludeMultiAsset');
        if (this.isMultiSelect) {
            const multiSelectItems = {};
            multiSelectItems[this.ItemAll.AcctNumber] = this.ItemAll;
            for (const key in accounts) {
                const acc = accounts[key];
                if (excludeMultiAsset && acc.AccountType !== AccountType.MultiAsset) continue;
                multiSelectItems[key] = acc;
            }

            this.setItems(multiSelectItems);

            const multiSelectDropDownItems = this.items.slice();
            // Removing ItemAll from multiSelectDropDownItems;
            const itemAllIdx = multiSelectDropDownItems.indexOf(this.ItemAll);
            if (itemAllIdx !== -1) {
                multiSelectDropDownItems.splice(itemAllIdx, 1);
            }
            this.multiSelectDropDownItems = multiSelectDropDownItems;
        } else {
            this.setItems(accounts);
            // Default account.
            const defAcc = SessionSettings.getDefaultAccount();
            let lastItem = this.get('selectedItem');
            if (!isNullOrUndefined(lastItem) && accounts[lastItem.BstrAccount] === undefined) // #84959
            {
                lastItem = null;
            }

            if (isNullOrUndefined(lastItem) && !isNullOrUndefined(defAcc)) {
                void this.set('selectedItem', defAcc);
            }
        }
    }

    public override getButtonTooltip (): string {
        return Resources.getResource('chart.ChartPanel.accountLookup.ToolTip');
    }

    public override showForm (): void {
        super.showForm();
        const params = new LookupDropDownShowParams();
        params.x = this.getX();
        params.y = this.getY();
        params.items = this.isMultiSelect ? this.multiSelectDropDownItems : this.items;
        params.callBack = this.dropDownFormCallBack.bind(this);
        params.closeCallback = this.closeForm.bind(this);
        params.isMultiSelect = this.isMultiSelect;
        params.isMultiSelectMode = this.isMultiSelectMode;
        params.isMultiSelectCheckboxUsing = this.isMultiSelect;
        params.caller = this.get('parentContainerControl');
        if (this.selectedItem.multiSelected) {
            params.selectedItem = this.selectedItem.arraySelected;
        } else if (this.selectedItem === this.ItemAll) {
            params.selectedItem = params.items;
        } else {
            params.selectedItem = this.selectedItem;
        }
        params.showMAM = this.showMAM;
        TerceraAccountLookupDropDownForm.ShowForm(params);

        this.fire('dropDownFormOpened');
    }

    public override getTypingLookupValueForSet (textValueId: string): _AccountItemAll | Account {
        if (textValueId === 'All') {
            return this.ItemAll;
        }

        return DataCache.GetAccountById(textValueId);
    }

    public override dropDownFormCallBack (result): void {
        const len = Array.isArray(result) ? result.length : 0;
        if (len !== 0) {
            if (len === 1) {
                this.setSelectValue(result[0]);
            } else if (len === DataCache.getNumberOfAccounts()) {
                this.setSelectValue(this.ItemAll);
            } else if (len < DataCache.getNumberOfAccounts()) {
                this.setSelectValue(new _AccountItemSelected(result));
            }
        } else {
            this.setSelectValue(result);
        }

        void this.set('labelShow', len > 1 && len < DataCache.getNumberOfAccounts());
    }

    get IsMultiSelect (): boolean { return this.isMultiSelect; }
    set IsMultiSelect (value) {
        this.isMultiSelect = value;
        this.repopulate();
    }

    public override lookupErrorHideShow (isShow: boolean): void {
        super.lookupErrorHideShow(isShow);
        if (isShow) {
            this.showPopupError(Resources.getResource('TerceraAccountLookup.AccountNotExist'), Resources.getResource('TerceraAccountLookup.IncorrectAccount'));
        }
    }
}

export class _AccountItemAll {
    public AcctNumber = 'All';
    // Hide in account lookup dropdown.
    public isHide = true;
    public UserLogin = 'All';
    public isLinked = false;
    public _AccountItemAll = true;
    public AcctNumberAll: string | null = null;

    public toString (): string {
        return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.All');
    }

    public GetDataForSave (): string {
        if (isValidString(this.AcctNumberAll)) {
            return this.AcctNumberAll;
        }

        const accounts = DataCache.getAccounts();
        const keys = Object.keys(accounts);
        let FullAccString = '';
        for (let i = 0; i < keys.length; i++) {
            FullAccString += accounts[keys[i]].AcctNumber + ',';
        }

        // Removing comma.
        FullAccString = FullAccString.slice(0, -1);
        this.AcctNumberAll = FullAccString;
        return this.AcctNumberAll;
    }
}

export class _AccountItemSelected {
    public selectedText: string;
    public AcctNumber: string;
    // Hide in account lookup dropdown.
    public isHide = true;
    public isLinked = false;
    public multiSelected = true;
    public arraySelected: Account[];
    public readonly _AccountItemSelected = true;

    constructor (accounts: Account[]) {
        let id = '';
        const len = accounts ? accounts.length : 0;
        for (let i = 0; i < len; i++) {
            const acc = accounts[i];
            if (acc.AcctNumber === 'All') continue;

            id += accounts[i].FullAccString + ',';
        }
        // Removing comma.
        id = id.slice(0, -1);
        this.selectedText = len + ' items (' + id + ')';
        this.AcctNumber = id;

        this.arraySelected = accounts;
    }

    public GetDataForSave (): string {
        let FullAccString = '';
        for (let i = 0; i < this.arraySelected.length; i++) {
            FullAccString += this.arraySelected[i].AcctNumber + ',';
        }
        // Removing comma.
        FullAccString = FullAccString.slice(0, -1);
        return FullAccString;
    }

    public toString (): string {
        return this.selectedText;
    }
}

TerceraLookup.extendWith(TerceraAccountLookup,
    {
        data: function () {
            return {
                btnClass: 'js-lookup-showAcc',
                btnName: 'accountLookup',
                name: ControlsTypes.TerceraAccountLookup,
                onlyOwnAccounts: false, // if true, the lookup does not include linked accounts
                ignoreForceLinkingByAccount: false, // true for not disabled accounts when enable force account linking
                excludeMultiAsset: false
            };
        },
        isMultiSelect: false,
        showMAM: false,
        ItemAll: null,
        isFocusoutListenerAdded: false,
        multiSelectDropDownItems: null
    });
