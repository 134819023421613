// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { CommissionGroup, DirectMarginMessage } from '../../../../Utils/DirectMessages/DirectMarginMessage';
import { CommissionOperationType } from '../../../../Utils/Commission/CommissionEnums';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class MarginResponseMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectMarginMessage[] {
        const message = new DirectMarginMessage();
        const groups = fieldSet.GetGroups(FieldsFactory.MARGIN_GROUP);
        const isPaperPositionsResponse = groups.length > 0 && groups[0].GetValue(FieldsFactory.FIELD_MARGIN_MODE) === 3;
        if (isPaperPositionsResponse) {
            this.processPaperPositionsResponse(message, groups);
        } else {
            this.processResponse(message, groups);
        }

        return [message];
    }

    private parseCommissions (commissionGroups): CommissionGroup[] {
        if (!commissionGroups) {
            return;
        }

        const result: CommissionGroup[] = [];

        for (let i = 0; i < commissionGroups.length; i++) {
            const group = commissionGroups[i];
            const resGroup = new CommissionGroup();

            resGroup.CommissionType = group.GetValue(FieldsFactory.FIELD_COMMISSION_TYPE);
            resGroup.OperationType = group.GetValue(FieldsFactory.FIELD_TYPE);
            resGroup.Amount = group.GetValue(FieldsFactory.FIELD_AMOUNT);
            resGroup.CurrencyId = group.GetValue(FieldsFactory.FIELD_ASSET_ID);

            result.push(resGroup);
        }

        return result;
    }

    private processResponse (message: DirectMarginMessage, groups: any[]): void {
        for (let g = 0; g < groups.length; g++) {
            const group = groups[g];
            const len = group.fieldSet.length;
            const operationMode = group.GetValue(FieldsFactory.FIELD_OPERATION_MODE);
            const buySellSuffix = operationMode === CommissionOperationType.BUY ? 'Buy' : 'Sell';

            const commissionGroups = group.GetGroups(FieldsFactory.COMMISSION_RESPONSE_GROUP);
            if (commissionGroups) {
                message.CommissionsByOperationMode[operationMode] = this.parseCommissions(commissionGroups);
            }

            // buying power name #106952
            const name = group.GetValue(FieldsFactory.FIELD_NAME);
            if (name) {
                message.BuyingPowerName = name;
            }

            for (let i = 0; i < len; i++) {
                const fixField = group.fieldSet[i];
                const fieldID = fixField.FieldId;
                const fieldValue = fixField.Value;

                if (fieldID === 0) {
                    continue;
                }

                switch (fieldID) {
                case FieldsFactory.FIELD_BUY_INIT:
                    message.InitMarginBuy = fieldValue;
                    break;
                case FieldsFactory.FIELD_SELL_INIT:
                    message.InitMarginSell = fieldValue;
                    break;
                case FieldsFactory.FIELD_BUY_SUPP:
                    message.MaintMarginBuy = fieldValue;
                    break;
                case FieldsFactory.FIELD_SELL_SUPP:
                    message.MaintMarginSell = fieldValue;
                    break;
                case FieldsFactory.FIELD_WARN_MARGINGSIZE:
                    message.WarnMarginBuy = fieldValue;
                    break;
                case FieldsFactory.FIELD_WARN_MARGINGSIZE_SHORT:
                    message.WarnMarginSell = fieldValue;
                    break;
                case FieldsFactory.FIELD_SHORT_SWAP_INFO:
                    message.ShortSwapInfo = fieldValue;
                    break;
                case FieldsFactory.FIELD_SWAP_BUY:
                    message.SwapBuy = fieldValue;
                    break;
                case FieldsFactory.FIELD_SWAP_SELL:
                    message.SwapSell = fieldValue;
                    break;
                case FieldsFactory.FIELD_AVAILABLE_MARGIN:
                    message['AfterTradeFunds' + buySellSuffix] = fieldValue;
                    break;
                case FieldsFactory.FIELD_AFTER_TRADE_CASH:
                    message.AfterTradeCash = fieldValue;
                    break;
                }
            }
        }
    }

    private processPaperPositionsResponse (message: DirectMarginMessage, groups: any[]): void {
        const group = groups[0];
        const len = group.fieldSet.length;
        for (let i = 0; i < len; i++) {
            const fixField = group.fieldSet[i];
            const fieldID = fixField.FieldId;
            const fieldValue = fixField.Value;

            if (fieldID === 0) {
                continue;
            }
            switch (fieldID) {
            case FieldsFactory.FIELD_USED_MARGIN:
                message.InitMarginBuy = fieldValue;
                break;
            case FieldsFactory.FIELD_MAINTANCE_MARGIN:
                message.MaintMarginBuy = fieldValue;
                break;
            case FieldsFactory.FIELD_PAPER_STOCK_REQ:
                message.StocksOrdersReqBuy = fieldValue;
                break;
            case FieldsFactory.FIELD_INIT_MARGINGSIZE:
                message.InitMarginSell = fieldValue;
                break;
            case FieldsFactory.FIELD_HOLD_MARGINGSIZE:
                message.MaintMarginSell = fieldValue;
                break;
            case FieldsFactory.FIELD_STOCK_REQ:
                message.StocksOrdersReqSell = fieldValue;
                break;
            }
        }
    }
}
