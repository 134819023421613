// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { ClosePositionsMutuallyTemplate } from '../../templates.js';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { positionsMutualCloseScreenHandler } from '../../Utils/AppHandlers';
import { DataCache } from '../../Commons/DataCache';
import { ClosePositionsMutuallyItem } from '../cache/ClosePositionsMutuallyItem';
import { PositionItem } from '../cache/PositionItem';
import { KeyCode, KeyEventProcessor } from '../../Commons/KeyEventProcessor';
import { Quantity } from '../../Utils/Trading/Quantity';
import { PositionFormatter } from '../../Commons/cache/Formatters/PositionFormatter';
import { ThemeManager } from '../misc/ThemeManager';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type PlacedFrom } from '../../Utils/Trading/PlacedFrom';
import { type ProductType } from '../../Utils/Instruments/ProductType';
import { type Account } from '../../Commons/cache/Account';

export class ClosePositionsMutually extends ApplicationPanelWithTable<ClosePositionsMutuallyItem> {
    public positionsIdArray: string[] = [];
    public account: Account | null = null;
    public productType: ProductType | null = null;
    public instrument: Instrument | null = null;
    public totalValue: any = null;
    public totalLongColor: string = ThemeManager.CurrentTheme.TableLongBuyColor;
    public totalShortColor: string = ThemeManager.CurrentTheme.TableShortSellColor;
    public placedFrom: PlacedFrom | null = null;
    public displayAmountInLots: boolean | null = null;
    public closingFunctionReference: any = null;
    public skipOnSave: boolean = true;
    public NeedCalculateRowCount: boolean = false;
    public headerLocaleKey: string = 'panel.ClosePositionsMutually';
    public Name: PanelNames = PanelNames.ClosePositionsMutually;

    public override getType (): PanelNames { return PanelNames.ClosePositionsMutually; }

    public override oninit (): void {
        super.oninit();

        this.on('modifyBtnClick', this.mutualPositionsClosing);
        this.on('cancelBtnClick', this.close);

        KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
        GeneralSettings.SettingsChanged.Subscribe(this.settingsChanged, this);
        Resources.onLocaleChange.Subscribe(this.localize, this);
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.displayAmountInLots = GeneralSettings.View.displayAmountInLots();

        this.localize();
        this.initByPositions();

        this.center();
    }

    public override localize (): void {
        super.localize();

        const totalValueSign = !isNullOrUndefined(this.totalValue) ? Math.sign(this.totalValue.value) : 0;
        const totalValuePrefix = totalValueSign === 0
            ? ''
            : (totalValueSign > 0 ? Resources.getResource('general.trading.position.Buy') : Resources.getResource('general.trading.position.Sell'));

        void this.set({
            symbolLabel: Resources.getResource('panel.ClosePositionsMutually.Symbol'),
            totalValuePrefix,
            totalLabel: Resources.getResource('panel.ClosePositionsMutually.Total'),
            closePosBtnText: Resources.getResource('panel.ClosePositionsMutually.CloseBtn'),
            cancelBtnText: Resources.getResource('panel.ClosePositionsMutually.CancelBtn')
        });
    }

    public mutualPositionsClosing (): void {
        if ((this.positionsIdArray?.length) === 0) {
            return;
        }

        const posDict = DataCache.getPositionsById(this.positionsIdArray);

        const p0ID = this.positionsIdArray[0];
        const p1ID = this.positionsIdArray[1];

        const p0 = posDict[p0ID];
        const p1 = posDict[p1ID];

        const pos0DateTime = PositionFormatter.DateTime(p0);
        const pos1DateTime = PositionFormatter.DateTime(p1);

        let mutualPositionsIds = [];
        if (pos0DateTime.getTime() < pos1DateTime.getTime()) {
            mutualPositionsIds = [p0ID, p1ID];
        } else {
            mutualPositionsIds = [p1ID, p0ID];
        }

        if (!isNullOrUndefined(this.closingFunctionReference)) {
            this.closingFunctionReference(posDict, this.placedFrom, mutualPositionsIds);
        }

        this.close();
    }

    public initByPositions (): void {
        const qt = this.getQuickTable();

        if (isNullOrUndefined(qt)) {
            return;
        }

        qt.ClearRows();

        let totalValue = 0;
        let symbol = null;
        let pT: ProductType = null;
        let acc: Account = null;
        for (let i = 0; i < this.positionsIdArray.length; i++) {
            const pos = DataCache.getPositionById(this.positionsIdArray[i]);

            symbol = pos.Instrument;
            acc = pos.Account;
            pT = pos.ProductType;
            totalValue += PositionItem.GetPositionAmount(pos) * PositionItem.GetPositionSign(pos);

            qt.AddItem(new ClosePositionsMutuallyItem(pos));
        }
        // ! Note that the account, product type, and instrument have been taken from the last position of the positionsIdArray !
        this.account = acc;
        this.productType = pT;
        this.instrument = symbol;
        this.totalValue = new Quantity(totalValue, this.displayAmountInLots);

        const totalValuePrefix = totalValue === 0
            ? ''
            : (totalValue > 0 ? Resources.getResource('general.trading.position.Buy') : Resources.getResource('general.trading.position.Sell'));

        void this.set({
            symbolName: isValidString(symbol) ? symbol.DisplayName() : '',
            totalValueStyle: 'color: ' + (totalValue === 0 ? 'none' : (totalValue > 0 ? this.totalLongColor : this.totalShortColor)),
            totalValuePrefix,
            totalValueFormatted: DataCache.formatVolume(symbol, Math.abs(totalValue), this.displayAmountInLots, pT, acc)
        });
    }

    public onGlobalKeyDown (): void {
        if (KeyEventProcessor.currentButton === KeyCode.ESC) { // This window can be closed using ESC button as well.
            void this.set('visible', false);
        } // this.close();
    }

    public override dispose (): void {
        GeneralSettings.SettingsChanged.UnSubscribe(this.settingsChanged, this);
        Resources.onLocaleChange.UnSubscribe(this.localize, this);
        KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);

        super.dispose();
    }

    public override layoutTable (): void {
        const qtR = this.quickTableRactive;
        if (isNullOrUndefined(qtR)) return;

        qtR.setSizes();
    }

    public settingsChanged (): void {
        const newSettingsInLots = GeneralSettings.View.displayAmountInLots();
        if (this.displayAmountInLots === newSettingsInLots) {
            return;
        }

        this.displayAmountInLots = newSettingsInLots;

        const ins = this.instrument;
        if (!isNullOrUndefined(ins)) {
            void this.set('totalValueFormatted', DataCache.formatVolume(ins,
                Quantity.convertQuantityValue(this.totalValue, ins, newSettingsInLots, this.account, this.productType),
                newSettingsInLots));
        }
    }

    public static show (positionsIdArray, placedFrom, closeFunction): ClosePositionsMutually {
        const panel: ClosePositionsMutually = MainWindowManager.Factory.addPanel(PanelNames.ClosePositionsMutually) as ClosePositionsMutually;

        panel.placedFrom = placedFrom;
        panel.positionsIdArray = positionsIdArray;
        panel.closingFunctionReference = closeFunction;

        return panel;
    }

    public static positionsMutualCloseScreenHandlerInitialize (): void {
        positionsMutualCloseScreenHandler.show = ClosePositionsMutually.show;
    }
}

ApplicationPanelWithTable.extendWith(ClosePositionsMutually, {
    partials: { bodyPartial: ClosePositionsMutuallyTemplate },
    data: function () {
        return {
            dockablePanel: false,
            isAccountLinkShow: false,
            isSymbolLinkShow: false,

            showFooter: false,
            showHeader: true,
            style_addition_header: 'js-MutualClose-AdditionalHeader',

            symbolValueStyle: 'color: ' + ThemeManager.CurrentTheme.TableGoldColor + ';',
            totalValueStyle: '',
            symbolLabel: 'Symbol:', // example values
            symbolName: 'AUD/CAD', // <
            totalLabel: 'Total: 0', // <
            totalValueFormatted: '0.00', // <
            closePosBtnText: 'Close', // <
            cancelBtnText: 'Cancel' // example values
        };
    }
});
