import { ControlsTypes } from '../../../UtilsClasses/FactoryConstants';
import { ContainerControl } from '../../ContainerControl';
import { PanelSideBarBaseNoDataPartial } from '../../../../templates.js';

export class PanelSideBarBase extends ContainerControl {
    public propertiesScreen = null;
    constructor () { super(); }

    oncomplete (): void {
        super.oncomplete();
        this.localize();
        void this.set('SideBarPanelRef', this);
    }

    public getType (): ControlsTypes {
        return ControlsTypes.PanelSideBarBase;
    }

    public isLostFocusForbidden (): boolean {
        return false;
    }

    public isSettingsOpen (): boolean {
        return !isNullOrUndefined(this.propertiesScreen);
    }

    public customLostFocus (parentPanel): boolean {
        if (this.propertiesScreen !== null) {
            this.propertiesScreen.on('teardown', () => {
                parentPanel.setFocus();
            });
            return true;
        }

        return false;
    }

    public onMouseDown (event): void {
        super.onMouseDown(event, false);
    }

    public localize (): void {

    }
}

ContainerControl.extendWith(PanelSideBarBase,
    {
        data: function () {
            return {
                SideBarPanelRef: null,
                noDataText: '',
                noDataImg: ''
            };
        },
        partials: {
            noData: PanelSideBarBaseNoDataPartial
        }
    });
