import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Rectangle } from '../../Commons/Geometry';
import { type TerceraChartNumberScaleRenderer } from '../Renderers/Scales/TerceraChartNumberScaleRenderer';
import { type TerceraChartBaseRenderer } from '../Renderers/TerceraChartBaseRenderer';
import { TerceraChartUtils } from '../TerceraChartUtils';
import { TerceraChartRendererDrawingAdvancedParamsEnum, TerceraChartToolRendererDrawingType } from '../Utils/ChartConstants';
import { type IPointsConverter } from '../Utils/PointsConverter/IPointsConverter';

export class TerceraChartWindowBase {
    public PaddingRenderers = [];
    public RendPaddingTop: number = 0;
    public RendPaddingBottom: number = 0;
    public RendPaddingLeft: number = 0;
    public RendPaddingRight: number = 0;

    public AutoScalePaddPixel = 20;
    public HeaderVisible = false;
    public IsMainWindow: boolean;
    public HeaderHeight = 21;
    public PaddingLeft = 0;
    public PaddingTop = 0;
    public PaddingRight = 40;
    public PaddingBottom = 0;

    /// <summary>
    /// Индекс крайнего правого отображаемого бара
    /// </summary>
    public i1 = 0;
    /// <summary>
    /// Масштаб по оси X (px на 1 бар)
    /// </summary>
    public XScale = 6;
    /// <summary>
    /// Масшатб по оси Y
    /// </summary>
    public YScale;
    /// <summary>
    /// Мин/Макс по шкале Y
    /// </summary>

    public FminFloatX = 0;
    public FmaxFloatX = 0;
    public FminFloatY = 0;
    public FmaxFloatY = 0;

    public autoscaleMinDelta = 1;
    public autoscaleMaxDelta = 1;

    public rightYScaleRenderer: TerceraChartNumberScaleRenderer;
    public leftYScaleRenderer: TerceraChartNumberScaleRenderer;
    public PointsConverter: IPointsConverter;

    public Rectangle = new Rectangle();

    public MainThreadRenderers: any[] = [];
    public Renderers: any[] = [];

    public HeightKoef = 100;
    public AutoScale = true;
    public needRecalcAutoscale = false;
    /// <summary>
    /// Всегда отображать последние бары
    /// </summary>
    public StickToEnd = true;

    public SCALE_LIMIT = 100000000;

    public ClientRectangle = new Rectangle();
    public Collapsed = false;
    public previousYScale: any;

    constructor (rightYScaleRenderer: TerceraChartNumberScaleRenderer, leftYScaleRenderer: TerceraChartNumberScaleRenderer) {
        this.rightYScaleRenderer = rightYScaleRenderer;
        if (rightYScaleRenderer !== null && rightYScaleRenderer !== undefined) {
            this.Renderers.push(rightYScaleRenderer);
            rightYScaleRenderer.window = this;
        }

        this.leftYScaleRenderer = leftYScaleRenderer;
        if (leftYScaleRenderer !== null && leftYScaleRenderer !== undefined) {
            this.Renderers.push(this.leftYScaleRenderer);
            leftYScaleRenderer.window = this;
        }
    }

    public ThemeChanged (): void {
        const renderers = this.Renderers;
        const len = renderers.length;
        for (let i = 0; i < len; i++) {
            renderers[i].ThemeChanged();
        }
    }

    public Localize (): void {
        const renderers = this.Renderers;
        const len = renderers.length;
        for (let i = 0; i < len; i++) {
            renderers[i].Localize();
        }
    }

    public GetRenderer (T): any {
        const res = null;
        const arr = this.Renderers;

        let i;
        const len = arr.length;
        for (i = 0; i < len; i++) {
            if ((arr[i] instanceof T)) {
                return arr[i];
            }
        }

        return res;
    }

    public GetRenderers (rendererType: any): TerceraChartBaseRenderer[] {
        const renderers = [];
        const renderersCount = this.Renderers.length;
        for (let i = 0; i < renderersCount; i++) {
            if (this.Renderers[i] instanceof rendererType) {
                renderers.push(this.Renderers[i]);
            }
        }
        return renderers;
    }

    public GetAllRenderers<T extends TerceraChartBaseRenderer>(): T[] {
        return this.MainThreadRenderers.concat(this.Renderers);
    }

    public im (): number {
        return this.ClientRectangle.Width / this.XScale;
    }

    public UpdateClientRectangle (): void {
        this.ClientRectangle.X = this.Rectangle.X + this.PaddingLeft + this.RendPaddingLeft;
        this.ClientRectangle.Y = this.Rectangle.Y + this.PaddingTop + this.RendPaddingTop;
        this.ClientRectangle.Width = this.Rectangle.Width - this.PaddingLeft - this.PaddingRight - this.RendPaddingLeft - this.RendPaddingRight;
        this.ClientRectangle.Height = this.Rectangle.Height - this.PaddingTop - this.PaddingBottom - this.RendPaddingTop - this.RendPaddingBottom;
    }

    public GetWindowHeaderRectangle (WindowHeaderRectangle, collapserRectangle) {
        if (this.HeaderVisible) {
            WindowHeaderRectangle = new Rectangle(
                this.Rectangle.X + (this.PaddingLeft > 0 ? this.PaddingLeft - 1 : 0),
                this.Rectangle.Y,
                this.ClientRectangle.Width + 1,
                this.HeaderHeight);

            // ГЛАВНОЕ НЕЛЬЗЯ КОЛЛАПСИТЬ
            if (!this.IsMainWindow) {
                collapserRectangle = new Rectangle(WindowHeaderRectangle.X + WindowHeaderRectangle.Width - 16, WindowHeaderRectangle.Y, 16, this.HeaderHeight);
            } else {
                collapserRectangle = Rectangle.Empty();
            }
        } else {
            WindowHeaderRectangle = collapserRectangle = Rectangle.Empty();
        }

        return { WindowHeaderRectangle, collapserRectangle };
    }

    /// <summary>
    /// Расчёты выполняемы перед прорисовкой окнв
    /// </summary>
    public BeforeDrawing (advParams = null): void {
        this.CalcScales();

        // Сбрасываем параметр на дефолт
        const par = advParams;
        if (par != null) {
            // to think
            this.OnLayout(par.LayoutInfo);

            par.Tag[TerceraChartRendererDrawingAdvancedParamsEnum.CurrentDrawingType] = TerceraChartToolRendererDrawingType.Background;
            // каждое окно рисует само
            // par.DrawPointers.Clear();
        }
    }

    public OnLayout (info = null): void {
        this.PaddingTop = 0;
        this.HeaderVisible = false;
        if (!isNullOrUndefined(info)) {
            this.PaddingRight = info.PreferredWidthScales;
            this.PaddingLeft = info.PreferredWidthScalesLeft;
        }

        this.UpdateClientRectangle();

        const clientR = this.ClientRectangle;
        if (!isNullOrUndefined(this.rightYScaleRenderer)) {
            this.rightYScaleRenderer.Rectangle = new Rectangle(
                clientR.X + clientR.Width,
                clientR.Y - this.PaddingTop,
                this.PaddingRight,
                clientR.Height + this.PaddingTop);
        }

        if (!isNullOrUndefined(this.leftYScaleRenderer)) {
            this.leftYScaleRenderer.Rectangle = new Rectangle(
                clientR.X - this.PaddingLeft,
                clientR.Y - this.PaddingTop,
                this.PaddingLeft,
                clientR.Height + this.PaddingTop);
        }

        this.LayoutPaddingRenderers();
    }

    public CalcScales (): boolean {
        if (this.FmaxFloatY === this.FminFloatY) {
            this.YScale = 0;
        } else {
            this.YScale = this.ClientRectangle.Height / (this.FmaxFloatY - this.FminFloatY);
            if (this.previousYScale !== this.YScale) {
                this.previousYScale = this.YScale;
                return true;
            }
        }
    }

    public CalculateMinMax (): void {
        try {
            if (!this.AutoScale && !this.needRecalcAutoscale) {
                return;
            }

            if (this.needRecalcAutoscale) {
                this.needRecalcAutoscale = false;
            }

            let newFminFloatY = Number.MAX_VALUE;
            let newFmaxFloatY = -Number.MAX_VALUE;

            const tMin = Number.MAX_VALUE;
            const tMax = -Number.MAX_VALUE;

            const rendLen = this.Renderers.length;
            for (let i = 0; i < rendLen; i++) {
                const refObj: any = {};
                refObj.tMin = tMin;
                refObj.tMax = tMax;

                if (this.Renderers[i].UseInAutoscale && this.Renderers[i].FindMinMax(refObj, this)) {
                    if (refObj.tMin < newFminFloatY) {
                        newFminFloatY = refObj.tMin;
                    }

                    if (refObj.tMax > newFmaxFloatY) {
                        newFmaxFloatY = refObj.tMax;
                    }
                }
            }

            if (newFminFloatY === Number.MAX_VALUE && newFmaxFloatY === -Number.MAX_VALUE) {
                // Никто не дал мин/макс - оставляем предыдущее значения
                // (пример: скрытие всех линий индикатора на дополнительно окне)
            } else {
                // Женя: если так совпало что мин и макс одинаковы... чтоб не было на шкале вечного нуля
                if (newFminFloatY === newFmaxFloatY) {
                    newFminFloatY -= this.autoscaleMinDelta;
                    newFmaxFloatY += this.autoscaleMaxDelta;
                }

                //
                // Add small padding for autoscale
                // pixels convert to price and change Min/Max
                if (newFminFloatY !== newFmaxFloatY) {
                    const minMaxDelta = Math.abs(newFmaxFloatY - newFminFloatY);
                    const clientH = this.ClientRectangle.Height;
                    const scalePadding = clientH && minMaxDelta
                        ? this.AutoScalePaddPixel / (clientH / minMaxDelta)
                        : 1;

                    newFminFloatY -= scalePadding;
                    newFmaxFloatY += scalePadding;
                }

                // replace values
                this.FminFloatY = newFminFloatY;
                this.FmaxFloatY = newFmaxFloatY;
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            // Utils.log(ex);
        }
    }

    public AutoFit (): void {
        this.needRecalcAutoscale = true;
    }

    /// <summary>
    ///
    /// </summary>
    public Draw (gr, windowsContainer, advParams = null): void {
        this.BeforeDrawing(advParams);

        // First draw container renderes
        let i;
        const curLayer = advParams.layerId;
        const len = this.Renderers.length;
        for (i = 0; i < len; i++) {
            const curRend = this.Renderers[i];
            // TODO. Move "!this.Collapsed" out from the loop.
            if (!this.Collapsed || curRend.plateRenderer || curRend.priceScaleRenderer) {
                if (curRend.IsNeedDraw(curLayer)) {
                    curRend.Draw(gr, this, windowsContainer, advParams);
                }
            }
        }

        const par = advParams;
        if (par) {
            const drawPointers = par.DrawPointers;
            // drawPointers.Sort(new DrawPointerComparer());

            const len = drawPointers.length;
            for (let i = 0; i < len; i++) {
                const pointer = drawPointers[i];
                TerceraChartUtils.DrawPointer(gr, this, pointer.priceValue, pointer.backgroundBrush, pointer.formatPriceValue, pointer.Font, pointer.borderPen, pointer.StringFormat, pointer.yLocation);
            }

            drawPointers.splice(0, len);
        }
    }

    // TODO. Replace this.Renderers with main thread renderers.
    // parameter is name of Class
    public GetMainThreadRenderer (T): any {
        const res = null;
        const arr = this.Renderers;

        let i;
        const len = arr.length;
        for (i = 0; i < len; i++) {
            if ((arr[i].ClassNameStorage[T])) {
                return arr[i];
            }
        }
        return res;
    }

    public LayoutPaddingRenderers (): void {
        if (this.PaddingRenderers.length == 0) {
            return;
        }

        this.RendPaddingTop = 0;
        this.RendPaddingBottom = 0;
        this.RendPaddingLeft = 0;
        this.RendPaddingRight = 0;

        const clientRect = this.ClientRectangle;

        this.RendPaddingTop += clientRect.Top();// - leftRenderer.Top;
        this.RendPaddingBottom += clientRect.Bottom(); // - leftRenderer.Bottom;
        this.RendPaddingLeft += clientRect.Left(); // - leftRenderer.Left;
        this.RendPaddingRight += clientRect.Right(); // - leftRenderer.Right;
    };

    public Dispose (): void {
        this.rightYScaleRenderer = null;
        this.leftYScaleRenderer = null;

        for (let i = 0; i < this.Renderers.length; i++) {
            this.Renderers[i].Dispose();
        }

        this.Renderers = [];
        this.MainThreadRenderers = [];
        this.PaddingRenderers = [];
    }

    public ActiveZoomLevels (): number[] {
        return TerceraChartWindowBase.ZOOM_VALUES;
    }

    public ZoomIn (): void {
        let scaleIndex = this.ActiveZoomLevels().indexOf(this.XScale);
        scaleIndex++;
        if (scaleIndex < this.ActiveZoomLevels().length) {
            this.CheckAndSetXScale(this.ActiveZoomLevels()[scaleIndex]);
        }
    }

    public ZoomOut (): void {
        let scaleIndex = this.ActiveZoomLevels().indexOf(this.XScale);
        scaleIndex--;
        if (scaleIndex >= 0) {
            this.CheckAndSetXScale(this.ActiveZoomLevels()[scaleIndex]);
        }
    }

    public ZoomX (delta: number): boolean {
        return false;
    }

    public ZoomY (delta: number): boolean {
        return false;
    }

    public MoveX (minXDelta: number, maxXDelta: number): boolean {
        return false;
    }

    public MoveY (newMinY: number, newMaxY: number): boolean {
        if (Math.abs(newMaxY) < this.SCALE_LIMIT && Math.abs(newMinY) < this.SCALE_LIMIT) {
            this.FmaxFloatY = newMaxY;
            this.FminFloatY = newMinY;
            this.CalcScales();
        }
        return true;
    }

    public static readonly ZOOM_VALUES = [1, 2, 3, 4, 6, 8, 12, 16, 25, 50, 100, 200];

    public CheckAndSetXScale (newXScale: number): void {
        // max
        if (newXScale > this.ActiveZoomLevels()[this.ActiveZoomLevels().length - 1]) {
            newXScale = this.ActiveZoomLevels()[this.ActiveZoomLevels().length - 1];
        }

        // min
        if (newXScale < this.ActiveZoomLevels()[0]) {
            newXScale = this.ActiveZoomLevels()[0];
        }

        // Allow values only from ZOOM_VALUES
        if (!this.ActiveZoomLevels().includes(newXScale)) {
            let minIndex = 0;
            let minDelta = Math.abs(this.ActiveZoomLevels()[0] - newXScale);
            for (let i = 1; i < this.ActiveZoomLevels().length; i++) {
                if (Math.abs(this.ActiveZoomLevels()[i] - newXScale) < minDelta) {
                    minDelta = Math.abs(this.ActiveZoomLevels()[i] - newXScale);
                    minIndex = i;
                }
            }
            newXScale = this.ActiveZoomLevels()[minIndex];
        }

        this.XScale = newXScale;
    }

    public CanZoomIn (): boolean {
        return this.XScale < this.ActiveZoomLevels()[this.ActiveZoomLevels().length - 1];
    }

    public CanZoomOut (): boolean {
        return this.XScale > this.ActiveZoomLevels()[0];
    }

    public ZoomAll (): void {
        this.CheckAndSetXScale(this.ActiveZoomLevels()[0]);
    }
}
