// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iMomEx extends iBuildInIndicator {
    public FMomPeriod: any;

    constructor (momPeriod, priceType?) {
        super(1);

        this.FPriceType = priceType ?? PriceType.Close;
        this.FMomPeriod = momPeriod;
    }

    override get Name (): string { return 'iMomEx'; }

    override get Key (): string { return this.DefaultKey + this.FMomPeriod + this.FPriceType; }

    public override OnQuote (): void {
        const Parent = this.Parent;
        if (Parent != null || this.FCount > this.FMomPeriod) {
            this.SetValue(this.GetPrice(this.FPriceType, 0) * 100 / this.GetPrice(this.FPriceType, this.FMomPeriod));
        }
    }
}

export class iMom extends iMomEx {
    override get Name (): string { return 'iMom'; }
}
