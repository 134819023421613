// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectRiskWarningMessage extends DirectMessageBaseClass {
    public AccountID: number | null = null;
    public Text: string | null = null;
    public RiskRuleType: any | null = null;
    public RiskWarnLevel: any | null = null;

    public static RiskRuleWarningHeader = 'dealticket.RiskRuleWarning.Header';
    public static PositionLossLimitRiskRuleType = 10;
    public static LossLimitPerTrade = 12;
    public static NegativeProjectedBalanceRiskRuleType = 13;
    public static AvailableCashRiskRuleType = 16;

    constructor () {
        super(Message.CODE_RISK_WARN_MESSAGE);
    }
}
