// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { InsDefSettingsStorage, InsDefSettings } from './cache/InstrumentDefaults';
import { AlertUtils } from '../Utils/Alert/AlertUtils';
import { TerceraSymbolLookupBaseDataProvider } from './NoNFixedListCore';
import { Resources, LocaleNames } from './properties/Resources';
import { TimeZoneInfo } from '../Utils/Time/TimeZoneInfo';
import { CustomErrorClass, ErrorInformationStorage } from './ErrorInformationStorage';
import { CustomEvent } from '../Utils/CustomEvents';
import { Connection } from './Connection';
import { OrderType, OrderTypeMap } from '../Utils/Trading/OrderType';
import { TIF } from '../Utils/Trading/OrderTif';
import { OrderTif, OrderTifMap } from '../Utils/Trading/OrderTifEnum';
import { RulesSet } from '../Utils/Rules/RulesSet';
import { TerceraLineStyleComboBoxUtils } from './UtilsClasses/TerceraTIFComboBoxUtils';
import { DynProperty, BoolNumeric } from './DynProperty';
import { GeneralSettings } from '../Utils/GeneralSettings/GeneralSettings';
import { SLTPTriggerUtils } from './cache/OrderParams/SLTPTriggerUtils';
import { type SLTPTrigger } from '../Utils/SlTpTrigger';
import { OffsetModeViewEnum } from '../Utils/Trading/OffsetModeViewEnum';
import { NumericUtils } from '../Utils/NumericUtils';
import { TradingLockUtils } from '../Utils/TradingLockUtils';
import { IsAllowed } from './IsAllowed';
import { OrderUtils } from '../Utils/Trading/OrderUtils';
import { UserWebStorageInstance } from '../user-web-storage';
import { DataCache } from './DataCache';
import { SessionSettingsConst } from './SessionSettingsConst';
import { SettingsLoggingManager } from './SettingsLoggingManager';
import { messageBoxHandler } from '../Utils/AppHandlers';
import { DateTimeConvertor } from '../Utils/Time/DateTimeConvertor';
import { ApplicationInfo } from './ApplicationInfo';
import { ProductType } from '../Utils/Instruments/ProductType';
import { type Instrument } from './cache/Instrument';
import { type Account } from './cache/Account';
import { SettingsDBManager } from './Settings/SettingsDBManager';
import { type SettingsDataResult } from './Settings/SettingsDataResult';

class _SessionSettings {
    public watchlistSheets: any = {};
    public chartSheets: any = {};
    public savedOrdersSheets: any = {};

    public timeZoneChanged = new CustomEvent();
    public sheetsChangedEvents = {
        watchlistSheets: new CustomEvent(),
        chartSheets: new CustomEvent(),
        savedOrdersSheets: new CustomEvent()
    };

    public AdditionalPropertiesHandlers = [];
    public AdditionalCallBacksHandlers = [];

    public savedPromiseHandler = null;
    public HandlersStack = [];
    public Loading = false;
    public favoriteTimeZoneInfoIdsSet: Record<string, boolean>;
    public SettingsChanged: any;
    private readonly _settingsSynchronizer = new SettingsSynchronizer();

    constructor () {
        GeneralSettings.InsDefSettingsStorage = new InsDefSettingsStorage();

        GeneralSettings.ApplyDefaults();

        this.AdditionalPropertiesHandlers.push(this.GetAlertPanelProperties.bind(this, true));

        TradingLockUtils.TradingLock.TradingLockChanged.Subscribe(this.save, this);
        GeneralSettings.SettingsNeedSave.Subscribe(this.save, this);
    }

    public GetAllowedTifsForOrderTypes (orderTypeArray): any[] {
        const dc = DataCache;
        const allowedTiffs = [];
        const allowedTiffsObj = {};

        const routeDict = dc.routes;
        for (const routeName in routeDict) {
            if (!dc.routes.hasOwnProperty(routeName)) {
                continue;
            }

            const route = dc.routes[routeName];
            for (let i = 0; i < orderTypeArray.length; i++) {
                const orderType = orderTypeArray[i];
                const allowedOrderType = route.IsAllowableOrderType(orderType);

                if (!allowedOrderType) {
                    continue;
                }

                for (const tifName in OrderTifMap) {
                    const tifId = OrderTif[tifName];
                    const tifIsAllowed = route.IsAllowableTif(tifId, orderType);

                    if (tifIsAllowed) {
                        allowedTiffsObj[tifId] = tifId;
                    }
                }
            }
        }

        Object.keys(allowedTiffsObj).map(function (it): void {
            allowedTiffs.push(allowedTiffsObj[it]);
        });

        return allowedTiffs;
    }

    public Properties (forSaving = false): DynProperty[] {
        let properties: DynProperty[] = [];

        for (let i = 0; i < this.AdditionalPropertiesHandlers.length; i++) {
            properties = properties.concat(this.AdditionalPropertiesHandlers[i]());
        }

        let dp = new DynProperty('property.Language', GeneralSettings.General.Language, DynProperty.COMBOBOX_COMBOITEM, DynProperty.PARAM_GROUP);
        dp.objectVariants = [];

        const locs = LocaleNames;
        const len = locs.length;
        for (let i = 0; i < len; i++) {
            const l = locs[i];
            if (!Resources.isHidden('locale.' + l)) {
                dp.objectVariants.push({
                    value: l,
                    text: Resources.getLangNameByLocale(l)
                });
            }
        }

        dp.COMBOBOX_TYPE = DynProperty.STRING;
        dp.sortIndex = 1;
        properties.push(dp);

        dp = new DynProperty('property.onboardinFirstTimeShowed', GeneralSettings.General.OnboardInFirstTimeShowed, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty('property.DisableInactivityPeriod', GeneralSettings.General.DisableInactivityPeriod, DynProperty.BOOLEAN_EVT,
            Resources.isHidden('property.DisableInactivityPeriod') ? DynProperty.HIDDEN_GROUP : DynProperty.PARAM_GROUP);
        dp.sortIndex = 3;
        // dp.tooltip = Resources.getResource("property.DisableInactivityPeriod.tt")
        dp.BeforeChangeEvent = function (newCheck, resolver) {
            if (!newCheck) {
                return resolver(false);
            }

            const scr = messageBoxHandler.Show(Resources.getResource('property.general.DisableInactivityPeriod.confirmation.caption'), Resources.getResource('property.general.DisableInactivityPeriod.confirmation.message'), messageBoxHandler.msgType.Info);
            scr.OK_Handler.then(function () {
                resolver(true);
            });

            scr.NO_Handler.then(function () {
                resolver(false);
            });
        };
        properties.push(dp);

        let prop = new DynProperty('property.Enable Sounds', GeneralSettings.Sound.EnableSounds, DynProperty.BOOLEAN, DynProperty.PARAM_GROUP);
        prop.sortIndex = 4;
        prop.assignedProperty = ['property.DisableStartEndSounds'];
        properties.push(prop);

        prop = new DynProperty('property.DisableStartEndSounds', GeneralSettings.Sound.DisableStartEndSound, DynProperty.BOOLEAN,
            Resources.isHidden('property.DisableStartEndSounds') ? DynProperty.HIDDEN_GROUP : DynProperty.PARAM_GROUP);
        prop.sortIndex = 5;
        properties.push(prop);

        dp = new DynProperty(SessionSettingsConst.IS_ROUNDED_AVERAGE_OPEN_PRICE, GeneralSettings.View.RoundedAverageOpenPrice, DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
        dp.sortIndex = 1;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.DISPLAY_QUANTITY_IN_LOTS, GeneralSettings.View.DisplayQuantityInLots, DynProperty.BOOLEAN,
            Resources.isHidden(SessionSettingsConst.DISPLAY_QUANTITY_IN_LOTS) ? DynProperty.HIDDEN_GROUP : DynProperty.VIEW_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.REVERSE_BUTTONS_ORDER, GeneralSettings.View.ReverseButtonsOrder, DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
        dp.sortIndex = 3;
        properties.push(dp);

        if (!forSaving) {
            dp = new DynProperty('property.isShowToolTipsGrop', '', DynProperty.SEPARATOR, DynProperty.VIEW_GROUP);
            dp.sortIndex = 6;
            properties.push(dp);
        }

        dp = new DynProperty(SessionSettingsConst.TOOLTIPS, GeneralSettings.View.Tooltips, DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
        dp.sortIndex = 7;
        properties.push(dp);

        if (!forSaving) {
            dp = new DynProperty('', '', DynProperty.STRING, DynProperty.SEPARATOR_GROUP1);
            properties.push(dp);
        }

        dp = new DynProperty('property.logSnapshotHasBeenSent', GeneralSettings.General.LogSnapshotHasBeenSent, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 8;
        properties.push(dp);

        // #region Sounds

        // #endregion

        const trading = GeneralSettings.TradingDefaults;

        const _acc_ = this.getDefValueFromObj(trading.Account, DataCache.Accounts);
        if (!trading.Account) {
            trading.Account = _acc_;
        }

        dp = new DynProperty('property.routing.account', _acc_.AcctNumber, DynProperty.ACCOUNT,
            DataCache.getNumberOfAccounts() === 1 ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 0;
        properties.push(dp);

        const _ins_ = this.getFirstTradableInstrument(trading.Symbol);
        if (!trading.Symbol) {
            trading.Symbol = _ins_;
        }

        const interiorId = trading.Symbol ? trading.Symbol.GetInteriorID() : null;

        dp = new DynProperty('property.general.defaultSymbol', interiorId, DynProperty.INSTRUMENT, DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 10;
        properties.push(dp);

        if (!forSaving) {
            this.AddSeparator(properties, DynProperty.TRADING_DEFAULTS_GROUP, 20);
        }

        const dc = DataCache;

        const sltpSettingsHideByRules = IsAllowed.IsProtectiveOrders(); // || !DataCache.isAllowed(RulesSet.FUNCTION_SLTP)

        dp = new DynProperty(SessionSettingsConst.SET_SLTP_VALUES_IN_OFFSET, trading.SetSlTpValuesInOffset, DynProperty.BOOLEAN, // isHidden ключ ('property.sltpOffsetMode') отличается от DynProp.name('sltpOffsetMode'), стоило б исправить, но вдруг уже настроено так у кого-то из клиентов потому оставил
            Resources.isHidden('property.' + SessionSettingsConst.SET_SLTP_VALUES_IN_OFFSET) || sltpSettingsHideByRules ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 30;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.SHOW_OFFSET_IN, trading.ShowOffsetIn, DynProperty.COMBOBOX_COMBOITEM, // isHidden ключ ('property.property.OffsetMode') отличается от DynProp.name('property.OffsetMode'), стоило б исправить, но вдруг уже настроено так у кого-то из клиентов потому оставил
            Resources.isHidden('property.' + SessionSettingsConst.SHOW_OFFSET_IN) || sltpSettingsHideByRules ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 40;
        properties.push(dp);

        const offsetModeTypes = [];
        for (const key in OffsetModeViewEnum) {
            const str = SessionSettingsConst.SHOW_OFFSET_IN + '.' + key;
            if (!isNaN(Number(key)) || Resources.isHidden(str)) continue;

            offsetModeTypes.push({
                key: str,
                value: OffsetModeViewEnum[key],
                text: Resources.getResource(str)
            });
        }
        dp.objectVariants = offsetModeTypes;

        dp = new DynProperty(SessionSettingsConst.USE_STOP_LIMIT_INSTEAD_OF_STOP, trading.UseStopLimitInsteadStop, DynProperty.BOOLEAN,
            Resources.isHidden(SessionSettingsConst.USE_STOP_LIMIT_INSTEAD_OF_STOP) ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 45;
        dp.assignedProperty = ['property.limitOffsetTicks'];
        properties.push(dp);

        dp = new DynProperty('property.limitOffsetTicks', trading.LimitOffsetTicks, DynProperty.INTEGER,
            Resources.isHidden('property.limitOffsetTicks') ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 46;
        dp.minimalValue = -NumericUtils.MAXVALUE;
        dp.maximalValue = NumericUtils.MAXVALUE;
        properties.push(dp);

        if (dc.AllowCustomSLTPTriggerPriceForUser()) //  AddSLTPTriggerProperties
        {
            properties = properties.concat(SLTPTriggerUtils.GetProperties(trading.SlTpTriggers, 50, forSaving));
        }

        if (!forSaving) {
            this.AddSeparator(properties, DynProperty.TRADING_DEFAULTS_GROUP, 60);
        }

        dp = new DynProperty('property.routing.orderType', trading.OrderType, DynProperty.COMBOBOX_COMBOITEM, DynProperty.TRADING_DEFAULTS_GROUP);
        const OrderTypes = dc.OrderParameterContainer.OrderTypes;
        const forOrderTypesPopulate = [];
        for (const key in OrderTypes) {
            forOrderTypesPopulate.push(OrderTypes[key].id());
        }

        dp.objectVariants = _SessionSettings.createVariantsForOrderTypes(forOrderTypesPopulate);
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.sortIndex = 70;
        properties.push(dp);

        dc.TIF = this.GetAllowedTifsForOrderTypes([
            OrderType.Market
        ]);

        dp = new DynProperty('TIF',
            TerceraLineStyleComboBoxUtils.cretaComboItem(this.checkTifInArray(dc.TIF, trading.MarketValidity), TIF.expireTimeTIF_Market),
            DynProperty.COMBOBOX_COMBOITEM_TIF,
            DynProperty.TRADING_DEFAULTS_GROUP);
        dp.objectVariants = TIF.createVariantsForTifs(dc.TIF);
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.sortIndex = 80;
        properties.push(dp);

        dc.TIF_Limit_And_Stop_Limit = this.GetAllowedTifsForOrderTypes([
            OrderType.Limit,
            OrderType.StopLimit
        ]);

        dp = new DynProperty('TIF_Limit_And_Stop_Limit',
            TerceraLineStyleComboBoxUtils.cretaComboItem(this.checkTifInArray(dc.TIF_Limit_And_Stop_Limit, trading.LimitAndStopLimitValidity), TIF.expireTimeTIF_Limit),
            DynProperty.COMBOBOX_COMBOITEM_TIF,
            DynProperty.TRADING_DEFAULTS_GROUP);
        dp.objectVariants = TIF.createVariantsForTifs(dc.TIF_Limit_And_Stop_Limit);
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.sortIndex = 90;
        properties.push(dp);

        dc.TIF_Stop = this.GetAllowedTifsForOrderTypes([
            OrderType.Stop
        ]);

        dp = new DynProperty('TIF_Stop',
            TerceraLineStyleComboBoxUtils.cretaComboItem(this.checkTifInArray(dc.TIF_Stop, trading.StopValidity), TIF.expireTimeTIF_Stop),
            DynProperty.COMBOBOX_COMBOITEM_TIF,
            DynProperty.TRADING_DEFAULTS_GROUP);
        dp.objectVariants = TIF.createVariantsForTifs(dc.TIF_Stop);
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.sortIndex = 100;
        properties.push(dp);

        // if (!trading.instrument.IsEmpty && trading.instrument.RiskSettings)
        // {
        const dprtC = DataCache.productTypeEnabilityCache;
        const arrP = [];
        for (const t in dprtC) {
            arrP.push(t);
        }

        const prodTypes = _SessionSettings.createVariantsForProductTypes(arrP);
        dp = new DynProperty('property.DefaultProductType', trading.ProductType, DynProperty.COMBOBOX_COMBOITEM,
            prodTypes.length === 1 && prodTypes[0].value == ProductType.General ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.objectVariants = prodTypes;
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        if (prodTypes.length === 1) {
            dp.enabled = false;
        }

        dp.sortIndex = 110;
        properties.push(dp);

        if (!forSaving) {
            this.AddSeparator(properties, DynProperty.TRADING_DEFAULTS_GROUP, 120);
        }

        dp = new DynProperty(SessionSettingsConst.TYPES_MANAGER,
            GeneralSettings.InsDefSettingsStorage.GetSettingsCopy().ToXML(),
            DynProperty.INSTRUMENTS_DEFAULTS,
            DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 130;
        properties.push(dp);

        const confirm = GeneralSettings.Confirmations;
        dp = new DynProperty(SessionSettingsConst.CONFIRM_ORDER_PLACEMENT, confirm.ConfirmOrderPlacement, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 1;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.CONFIRM_ORDER_CANCELLATION, confirm.ConfirmOrderCancellation, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.CONFIRM_ORDER_OR_POSITION_MODIFICATION, confirm.ConfirmOrderPositionsModification, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 3;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.CONFIRM_POSITION_CLOSING, confirm.ConfirmPositionClosing, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 4;
        properties.push(dp);

        dp = new DynProperty('property.oe.confirmReversePosition', confirm.ConfirmReversePosition, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        // https://tp.traderevolution.com/entity/98279
        // Resources.isHidden('property.oe.confirmReversePosition') ? DynProperty.HIDDEN_GROUP : DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 5;
        properties.push(dp);

        if (!forSaving) {
            dp = new DynProperty('property.OrderCreateAlertGroup', '', DynProperty.GROUP_SEPARATOR, DynProperty.CONFIRMATIONS_GROUP);
            dp.sortIndex = 48;
            properties.push(dp);
        }

        dp = new DynProperty('property.oe.confirmOrderCreateAlert', confirm.ConfirmPlaceAlert, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 49;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.CONFIRM_ALERT_REMOVE, confirm.ConfirmCancelAlert, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 49;
        properties.push(dp);

        if (!forSaving) {
            dp = new DynProperty('property.ConfirmationSubgroup.Other', '', DynProperty.GROUP_SEPARATOR, DynProperty.CONFIRMATIONS_GROUP);
            dp.sortIndex = 56;
            properties.push(dp);
        }

        dp = new DynProperty('property.dealTickets.openOnWorkspace', confirm.OpenDialTicketsOnWorkspace, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 57;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.SHOW_MARGIN_DEAL_TICKETS_ON_WORKSPACE, confirm.ShowMarginDealTicketOnWorkspace, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.tooltip = Resources.getResource('property.dealTickets.showMarginDealTicketsOnWorkspace.ToolTip');
        dp.sortIndex = 58;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.SHOW_DEAL_TICKETS, confirm.ShowDialTicketsInApplicationTray, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.tooltip = Resources.getResource('property.dealTickets.showDealTickets.ToolTip');
        dp.sortIndex = 59;
        properties.push(dp);

        dp = new DynProperty('property.positionSizing.infoWindowShow', confirm.PositionSizeCalculatorInfoWindow, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        properties.push(dp);

        let groupToAdd = DataCache.isAllowedForMyUser(RulesSet.FUNCTION_PRODUCTS) && !Resources.isHidden('ribbon.tools.products') ? DynProperty.CONFIRMATIONS_GROUP : DynProperty.HIDDEN_GROUP;
        dp = new DynProperty('property.subscriptions.confirmSubscribeAndUnsubscribe', confirm.ConfirmSubscriptions, DynProperty.BOOLEAN, groupToAdd);
        dp.sortIndex = 60;
        properties.push(dp);

        const warning = GeneralSettings.Warnings;

        dp = new DynProperty('property.general.OvernightMarginNotificationMessage', warning.OvernightMarginNotificationMessage, DynProperty.BOOLEAN, DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty('property.general.WarnIfWrongOrderParam', warning.WarnIfWrongOrder, DynProperty.BOOLEAN, DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 3;
        properties.push(dp);

        dp = new DynProperty('property.general.WarnIfIdenticalOrder', new BoolNumeric(warning.WarnIfIdenticalSubsequentOrderIsPlaced, warning.WarnIfIdenticalOrderTime),
            DynProperty.BOOL_NUMERIC, Resources.isHidden('general.WarnIfOrderRepeat') ? DynProperty.HIDDEN_GROUP : DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 4;
        dp.decimalPlaces = 0;
        dp.increment = 1;
        dp.minimal = 0;
        properties.push(dp);

        groupToAdd = DataCache.getValidateMaxLotCloseOrderBoolRule() ? DynProperty.WARNINGS_GROUP : DynProperty.HIDDEN_GROUP;
        dp = new DynProperty('property.general.WarnIfQtyToCloseMoreThanMaxLot', warning.WarnIfQtyToCloseMoreThanMaxLot, DynProperty.BOOLEAN, groupToAdd);
        dp.sortIndex = 5;
        properties.push(dp);

        groupToAdd = DataCache.isAllowedForMyUser(RulesSet.LEVERAGE_USAGE_WARN) ? DynProperty.WARNINGS_GROUP : DynProperty.HIDDEN_GROUP;
        dp = new DynProperty('property.general.WarnWhenEnteringIntoLeverage', warning.WarnWhenEnteringIntoLeverage, DynProperty.BOOLEAN, groupToAdd);
        dp.sortIndex = 6;
        properties.push(dp);

        if (!forSaving) {
            DataCache.EntitlementManager.AddNotificationProperties(properties);
        } // for Products with ProductDataType == EntitlementProductDataType.Notifications && ShowInSettings == true

        dp = new DynProperty('watchlistSheets.itemsValue', JSON.stringify(this.watchlistSheets.itemsValue), DynProperty.STRING, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty('chartSheets.itemsValue', JSON.stringify(this.chartSheets.itemsValue), DynProperty.STRING, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty('savedOrdersSheets.itemsValue', JSON.stringify(this.savedOrdersSheets.itemsValue), DynProperty.STRING, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        const curTZ = DateTimeConvertor.currentTimeZoneInfoId.value;
        properties.push(new DynProperty('property.general.timezone.current', curTZ, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        const favTZones = TimeZoneInfo.serializeToIdsString(this.favoriteTimeZoneInfoIdsSet);
        properties.push(new DynProperty('property.general.timezone.favorites', favTZones, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        properties.push(new DynProperty('property.general.Settings.TradingLock.TradingLocked', TradingLockUtils.TradingLock.tradingLocked, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    public callBack (properties: DynProperty[], changed): void {
        if (!changed) {
            return;
        }

        SettingsLoggingManager.LogChange(this.Properties(), properties); // #111072

        const needInform = !Resources.isHidden('property.DisableInactivityPeriod');
        let needSend = false;

        if (needInform) {
            let newDIPV = null;
            const dp = DynProperty.getPropertyByName(properties, 'property.DisableInactivityPeriod');
            if (DynProperty.isCorrect(dp)) {
                newDIPV = dp.value;
            }

            needSend = newDIPV !== null && newDIPV !== GeneralSettings.General.DisableInactivityPeriod;
        }

        this.applyNewSettings(properties);
        this.save();

        if (needSend) {
            const msgText = GeneralSettings.General.DisableInactivityPeriod ? 'Inactivity period setting was activated' : 'Inactivity period setting was disabled';
            Connection.vendor.SendInactivityPeriodState(msgText);
        }
    }

    public AddSeparator (properties: DynProperty[], group: string, sortIndex): void // add DynProperty of separator to properties array
    {
        const dp = new DynProperty('', '', DynProperty.SEPARATOR, group);
        if (sortIndex !== null) {
            dp.sortIndex = sortIndex;
        }

        properties.push(dp);
    }

    public GetAlertPanelProperties (needHide?: boolean): DynProperty[] {
        const properties: DynProperty[] = [];
        const sett = GeneralSettings.Alert;
        const Group = needHide ? DynProperty.HIDDEN_GROUP : DynProperty.ALERTS_GROUP;

        const prop1 = new DynProperty('Notification', sett.NotificationVariable, DynProperty.ALERT_NOTIFICATION_SELECTOR, Group);
        prop1.sortIndex = 2;
        prop1.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop1);

        const prop2 = new DynProperty('AlertType', sett.AlertTypeVariable, DynProperty.COMBOBOX, Group);
        prop2.sortIndex = 3;
        prop2.objectVariants = AlertUtils.GetAlertTypeItems();
        prop2.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop2);

        const prop3 = new DynProperty('Condition', sett.ConditionVariable, DynProperty.COMBOBOX, Group);
        prop3.sortIndex = 4;
        prop3.objectVariants = AlertUtils.GetAlertConditionItems();
        prop3.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop3);

        const prop4 = new DynProperty('Importance', sett.ImportanceVariable, DynProperty.COMBOBOX, Group);
        prop4.sortIndex = 5;
        prop4.objectVariants = AlertUtils.GetAlertImportanceItems();
        prop4.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop4);

        const prop5 = new DynProperty('AfterExecute', sett.AfterExecuteVariable, DynProperty.COMBOBOX, Group);
        prop5.sortIndex = 6;
        prop5.objectVariants = AlertUtils.GetAlertAfterExecuteItems();
        prop5.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop5);

        return properties;
    }

    public AlertPanelCallBack (newProperties: DynProperty[]): void {
        const sett = GeneralSettings.Alert;
        const dp1 = DynProperty.getPropertyByName(newProperties, 'Notification');
        if (dp1) {
            sett.NotificationVariable = dp1.value;
        }

        const dp2 = DynProperty.getPropertyByName(newProperties, 'AlertType');
        if (dp2) {
            sett.AlertTypeVariable = dp2.value;
        }

        const dp3 = DynProperty.getPropertyByName(newProperties, 'Condition');
        if (dp3) {
            sett.ConditionVariable = dp3.value;
        }

        const dp4 = DynProperty.getPropertyByName(newProperties, 'AfterExecute');
        if (dp4) {
            sett.AfterExecuteVariable = dp4.value;
        }

        const dp5 = DynProperty.getPropertyByName(newProperties, 'Importance');
        if (dp5) {
            sett.ImportanceVariable = dp5.value;
        }
    }

    public applyNewSettings (properties: DynProperty[], fromJson = false, loginScreenLang = null): void {
        this.Loading = true;
        try {
            let dp = DynProperty.getPropertyByName(properties, 'property.Language');
            if (DynProperty.isCorrect(dp)) {
                let value = dp.value;
                if (isValidString(loginScreenLang) && value !== loginScreenLang) // #89339
                {
                    value = loginScreenLang;
                }

                GeneralSettings.General.Language = value;
                Resources.setLocale(value);
            }

            for (let i = 0; i < this.AdditionalCallBacksHandlers.length; i++) {
                properties = properties.concat(this.AdditionalCallBacksHandlers[i](properties));
            }

            dp = DynProperty.getPropertyByName(properties, 'property.onboardinFirstTimeShowed');
            if (DynProperty.isCorrect(dp)) {
                GeneralSettings.General.OnboardInFirstTimeShowed = dp.value && !ApplicationInfo.isExploreMode;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.logSnapshotHasBeenSent');
            if (DynProperty.isCorrect(dp)) {
                GeneralSettings.General.LogSnapshotHasBeenSent = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.DisableInactivityPeriod');
            if (DynProperty.isCorrect(dp)) {
                GeneralSettings.General.DisableInactivityPeriod = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.DISPLAY_QUANTITY_IN_LOTS);
            if (DynProperty.isCorrect(dp)) {
                GeneralSettings.View.DisplayQuantityInLots = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.TOOLTIPS);
            if (DynProperty.isCorrect(dp)) {
                GeneralSettings.View.Tooltips = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.IS_ROUNDED_AVERAGE_OPEN_PRICE);
            if (DynProperty.isCorrect(dp)) {
                GeneralSettings.View.RoundedAverageOpenPrice = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.REVERSE_BUTTONS_ORDER);
            if (DynProperty.isCorrect(dp)) {
                GeneralSettings.View.ReverseButtonsOrder = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.Enable Sounds');
            if (DynProperty.isCorrect(dp)) {
                GeneralSettings.Sound.EnableSounds = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.DisableStartEndSounds');
            if (DynProperty.isCorrect(dp)) {
                GeneralSettings.Sound.DisableStartEndSound = dp.value;
                GeneralSettings.Sound.EnableSound_welcome = !GeneralSettings.Sound.DisableStartEndSound;
                GeneralSettings.Sound.EnableSound_finish = !GeneralSettings.Sound.DisableStartEndSound;
            }
            // #region trading

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.TYPES_MANAGER);
            if (dp) {
                const insDefSettings = new InsDefSettings();
                insDefSettings.LoadFromXML(dp.value);
                GeneralSettings.InsDefSettingsStorage.SetSettings(insDefSettings);
            }

            const trading = GeneralSettings.TradingDefaults;

            dp = DynProperty.getPropertyByName(properties, 'property.routing.orderType');
            if (DynProperty.isCorrect(dp)) {
                trading.OrderType = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.DefaultProductType');
            if (DynProperty.isCorrect(dp)) {
                trading.ProductType = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.SET_SLTP_VALUES_IN_OFFSET);
            if (DynProperty.isCorrect(dp)) {
                trading.SetSlTpValuesInOffset = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.SHOW_OFFSET_IN);
            if (DynProperty.isCorrect(dp)) {
                trading.ShowOffsetIn = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.USE_STOP_LIMIT_INSTEAD_OF_STOP);
            if (DynProperty.isCorrect(dp)) {
                trading.UseStopLimitInsteadStop = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.limitOffsetTicks');
            if (DynProperty.isCorrect(dp)) {
                trading.LimitOffsetTicks = dp.value;
            }

            SLTPTriggerUtils.ApplyProperties(trading.SlTpTriggers, properties, fromJson); // #109798 callBack SLTPTrigger Properties

            const dc = DataCache;

            dp = DynProperty.getPropertyByName(properties, 'TIF');
            if (DynProperty.isCorrect(dp)) {
                trading.MarketValidity = dp.value.tif.value;
                TIF.expireTimeTIF_Market = TIF.handleNewExpireTime(dp.value.date);
            }

            dp = DynProperty.getPropertyByName(properties, 'TIF_Limit_And_Stop_Limit');
            if (DynProperty.isCorrect(dp)) {
                trading.LimitAndStopLimitValidity = dp.value.tif.value;
                TIF.expireTimeTIF_Limit = TIF.handleNewExpireTime(dp.value.date);
            }

            dp = DynProperty.getPropertyByName(properties, 'TIF_Stop');
            if (DynProperty.isCorrect(dp)) {
                trading.StopValidity = dp.value.tif.value;
                TIF.expireTimeTIF_Stop = TIF.handleNewExpireTime(dp.value.date);
            }

            dp = DynProperty.getPropertyByName(properties, 'property.routing.account');
            if (DynProperty.isCorrect(dp)) {
                trading.Account = DataCache.Accounts[dp.value];
            }

            if (!trading.Account) {
                trading.Account = this.getDefValueFromObj(trading.Account, DataCache.Accounts);
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.defaultSymbol');
            if (DynProperty.isCorrect(dp)) {
                const _symbol = DataCache.getInstrumentByName(dp.value);
                if (_symbol) {
                    trading.Symbol = _symbol;
                }
            }

            if (!trading.Symbol) {
                trading.Symbol = this.getFirstTradableInstrument(trading.Symbol);
            }

            // #endregion

            const confirm = GeneralSettings.Confirmations;
            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.CONFIRM_ORDER_CANCELLATION);
            if (DynProperty.isCorrect(dp)) {
                confirm.ConfirmOrderCancellation = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.CONFIRM_ORDER_PLACEMENT);
            if (DynProperty.isCorrect(dp)) {
                confirm.ConfirmOrderPlacement = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.oe.confirmOrderCreateAlert');
            if (DynProperty.isCorrect(dp)) {
                confirm.ConfirmPlaceAlert = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.CONFIRM_ALERT_REMOVE);
            if (DynProperty.isCorrect(dp)) {
                confirm.ConfirmCancelAlert = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.CONFIRM_POSITION_CLOSING);
            if (DynProperty.isCorrect(dp)) {
                confirm.ConfirmPositionClosing = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.oe.confirmReversePosition');
            if (DynProperty.isCorrect(dp)) {
                confirm.ConfirmReversePosition = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.CONFIRM_ORDER_OR_POSITION_MODIFICATION);
            if (DynProperty.isCorrect(dp)) {
                confirm.ConfirmOrderPositionsModification = dp.value;
            }

            let openDealTicketsOnWorkspaceDynPropertyAvailable = false;
            dp = DynProperty.getPropertyByName(properties, 'property.dealTickets.openOnWorkspace');
            if (DynProperty.isCorrect(dp)) {
                confirm.OpenDialTicketsOnWorkspace = dp.value;
                openDealTicketsOnWorkspaceDynPropertyAvailable = true;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.SHOW_MARGIN_DEAL_TICKETS_ON_WORKSPACE);
            if (DynProperty.isCorrect(dp)) {
                confirm.ShowMarginDealTicketOnWorkspace = dp.value;
            } else if (openDealTicketsOnWorkspaceDynPropertyAvailable) {
                // migration process #123386
                confirm.ShowMarginDealTicketOnWorkspace = Resources.isHidden('property.dealTickets.openMarginOnWorkspace.Default') ? confirm.OpenDialTicketsOnWorkspace : true;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.SHOW_DEAL_TICKETS);
            if (DynProperty.isCorrect(dp)) {
                confirm.ShowDialTicketsInApplicationTray = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.positionSizing.infoWindowShow');
            if (DynProperty.isCorrect(dp)) {
                confirm.PositionSizeCalculatorInfoWindow = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.subscriptions.confirmSubscribeAndUnsubscribe');
            if (DynProperty.isCorrect(dp)) {
                confirm.ConfirmSubscriptions = dp.value;
            }

            const warning = GeneralSettings.Warnings;
            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnIfWrongOrderParam');
            if (DynProperty.isCorrect(dp)) {
                warning.WarnIfWrongOrder = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnIfIdenticalOrder');
            if (DynProperty.isCorrect(dp)) {
                const boolNumeric = dp.value;

                if (boolNumeric) {
                    warning.WarnIfIdenticalSubsequentOrderIsPlaced = boolNumeric.Checked;
                    warning.WarnIfIdenticalOrderTime = boolNumeric.Value;
                }
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnIfQtyToCloseMoreThanMaxLot');
            if (DynProperty.isCorrect(dp)) {
                warning.WarnIfQtyToCloseMoreThanMaxLot = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.OvernightMarginNotificationMessage');
            if (DynProperty.isCorrect(dp)) {
                warning.OvernightMarginNotificationMessage = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnWhenEnteringIntoLeverage');
            if (DynProperty.isCorrect(dp)) {
                warning.WarnWhenEnteringIntoLeverage = dp.value;
            }

            dc.EntitlementManager.callBackNotificationProperties(properties, fromJson); // #109924

            // TODO. Implement with TimeZone
            // dp = DynProperty.getPropertyByName(properties, "property.general.timezone.current");
            // if (DynProperty.isCorrect(dp))
            //    this.currentTimeZoneInfoId = dp.value;
            DateTimeConvertor.currentTimeZoneInfoId.value = TimeZoneInfo.LOCAL;

            dp = DynProperty.getPropertyByName(properties, 'property.general.timezone.favorites');
            if (DynProperty.isCorrect(dp)) {
                this.favoriteTimeZoneInfoIdsSet = TimeZoneInfo.deserializeToIdsSet(dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.Settings.TradingLock.TradingLocked');
            if (DynProperty.isCorrect(dp)) {
                TradingLockUtils.TradingLock.updateTradingLock(dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'watchlistSheets.itemsValue');
            if (DynProperty.isCorrect(dp)) {
                this.watchlistSheets.itemsValue = JSON.parse(dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'chartSheets.itemsValue');
            if (DynProperty.isCorrect(dp)) {
                this.chartSheets.itemsValue = JSON.parse(dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'savedOrdersSheets.itemsValue');
            if (DynProperty.isCorrect(dp)) {
                this.savedOrdersSheets.itemsValue = JSON.parse(dp.value);
            }

            this.AlertPanelCallBack(properties);
        } catch (e) {
            console.log('Crashed while applying new settings. ' + e);
        }

        this.Loading = false;
        GeneralSettings.SettingsChangedRaise();
    }

    public ClearSaveStack (): void {
        this.HandlersStack.length = 0;
        this.savedPromiseHandler = null;
    }

    public executeAfterSaveEnd (func): boolean //  задерживает выполнение передаваемого аргумента-функции до окончания сохранения настроек
    {
        if (ApplicationInfo.isExploreMode) {
            return false;
        }

        if (typeof func !== 'function') {
            return false;
        }

        const wasWaiting = !(this.HandlersStack.length === 0) && !!this.savedPromiseHandler;
        this.HandlersStack.push(func);
        this.nextHandler();
        return wasWaiting;
    }

    public nextHandler (): any {
        if ((this.HandlersStack.length === 0) || this.savedPromiseHandler) {
            return;
        }

        const func = this.HandlersStack.shift();
        // let x = this.counter++
        if (!this.savedPromiseHandler) {
            this.savedPromiseHandler = func.apply(this);
        }

        this.savedPromiseHandler
            .then(function () {
                return this.savedPromiseHandler = null;
            }.bind(this))
            .then(function () {
                return this.nextHandler();
            }.bind(this))
            .finally(function () {
                return this.savedPromiseHandler = null;
            }.bind(this));

        return this.savedPromiseHandler;
    }

    public save (): void {
        this.executeAfterSaveEnd(this.saveRun); // задерживаем новое сохранение до окончания предыдущего #94064
    }

    public saveRun (): any {
        if (!DataCache.Loaded || this.Loading) {
            return Promise.resolve();
        }
        return this._settingsSynchronizer.settingSaveProcess(DynProperty.serialize(this.Properties(true)));
    }

    public async load (): Promise<any> {
        this.clearSessionSettings();
        try {
            const settingsData = await UserWebStorageInstance.settings.get();
            return await this._settingsSynchronizer.handleLoadedSettings(settingsData);
        } catch (err) {
            const settingsData: SettingsDataResult = await SettingsDBManager.GetSettings();
            return settingsData?.settings ?? [];
        };
    }

    public async afterLoad (jsonSettings, loginScreenLang: string): Promise<boolean> {
        try {
            const dp = DynProperty.getPropertyByName(jsonSettings, 'property.general.defaultSymbol');
            if (isValidString(dp?.value)) {
                const DataProvider = new TerceraSymbolLookupBaseDataProvider();
                await DataProvider.getInstrumentByName(dp.value);
            }
            await this.loadFromJson(jsonSettings, loginScreenLang);
            return true;
        } catch (err) {
            const ex = new CustomErrorClass('SessionSettings load error', 'SessionSettings.load', 'load -> UserWebStorage.settings');
            ErrorInformationStorage.GetException(ex);
            return false;
        };
    }

    public async RestoreSettings (settingsJson, loginScreenLang: string): Promise<void> {
        await this.loadFromJson(settingsJson, loginScreenLang);
    }

    // TODO. Rename. Refactor.
    // TODO. Ugly as fck.
    public async loadFromJson (jsonSettings, loginScreenLang: string = null): Promise<void> {
        jsonSettings = await this.handleFirstLoginAndFetchWatchlist(jsonSettings);
        const properties = DynProperty.deserialize(jsonSettings);
        if (properties?.length > 0) {
            this.applyNewSettings(properties, true, loginScreenLang);
        } else { // For now, I have left it as is, but it seems that we never reach this else because if the user's settings are missing, default settings are returned from settings-router.ts. However, if the default user settings are missing in / or there is no connection to MongoDB, it gets stuck on "Loading user data..." (which probably needs to be fixed).
            GeneralSettings.ApplyDefaults();
            GeneralSettings.SettingsChangedRaise();
        }
    }

    public updateFavoriteTimeZoneInfoIdsSet (idsSet): void {
        this.favoriteTimeZoneInfoIdsSet = idsSet;
        GeneralSettings.SettingsChangedRaise();
        this.save();
    }

    public updateCurrentTimeZoneInfoId (currentTimeZoneInfoId): any {
        DateTimeConvertor.currentTimeZoneInfoId.value = currentTimeZoneInfoId;
        this.timeZoneChanged.Raise();
        GeneralSettings.SettingsChangedRaise();
        this.save();
    }

    public updateCurrentTheme (currentTheme): any {
        GeneralSettings.General.CurrentTheme = currentTheme;
        GeneralSettings.SettingsChangedRaise();
        this.save();
    }

    // SessionSettings.prototype.updateWarningOnApplicationClose  (showWarning)
    // {
    //     if (showWarning === undefined) return;

    //     if (this.warning.warnOnCloseApplication !== showWarning)
    //     {
    //         this.warning.warnOnCloseApplication = showWarning;
    //         this.SettingsChanged.Raise();
    //         this.save();
    //     }
    // };

    public setSheetsData (storageName, valueOfLists, owner?): any {
        this[storageName].itemsValue = valueOfLists;

        this.save();

        this.sheetsChangedEvents[storageName].Raise(owner);
    }

    public updateInstrumentsDefaultSettings (settings): any {
        if (!settings) return;
        GeneralSettings.InsDefSettingsStorage.SetSettings(settings);
        GeneralSettings.SettingsChangedRaise();
        this.save();
    }

    public overnightMarginNotificationMessage (showWarning): any {
        if (showWarning === undefined) return;

        if (GeneralSettings.Warnings.OvernightMarginNotificationMessage !== showWarning) {
            GeneralSettings.Warnings.OvernightMarginNotificationMessage = showWarning;
            this.SettingsChanged.Raise();
            GeneralSettings.SettingsChangedRaise();
        }
    }

    public getDefValueFromObj (defVal, ifNotObj): any {
        if (defVal) return defVal;

        const objKeys = Object.keys(ifNotObj);

        if (objKeys.length > 0) {
            defVal = ifNotObj[objKeys[0]];
            return ifNotObj[objKeys[0]];
        }

        return null;
    }

    public getFirstTradableInstrument (defValue?): Instrument {
        if (defValue?.RouteIsTradable()) {
            return defValue;
        }

        const dCache = DataCache;
        const instruments = dCache.Instruments;

        for (const ins_id in instruments) {
            const ins = instruments[ins_id];
            if (ins.RouteIsTradable()) {
                return ins;
            }
        }

        return defValue;
    }

    public getDefaultInstrument (): Instrument {
        const dCache = DataCache;
        let id = '';
        if (GeneralSettings.TradingDefaults.Symbol) {
            id = GeneralSettings.TradingDefaults.Symbol.GetInteriorID();
        }

        let defIns = dCache.getInstrumentByName(id);
        if (!defIns) {
            const k = Object.keys(dCache.Instruments);
            defIns = dCache.Instruments[k[0]];
            if (!defIns) {
                GeneralSettings.TradingDefaults.Symbol = null;
            } else {
                GeneralSettings.TradingDefaults.Symbol = defIns;
            }
        }
        return defIns;
    }

    public getDefaultAccount (): Account {
        const dCache = DataCache;
        let defAcc = dCache.Accounts[GeneralSettings.TradingDefaults.Account?.AcctNumber];
        if (isNullOrUndefined(defAcc)) {
            const k = Object.keys(dCache.Accounts);
            defAcc = dCache.Accounts[k[0]];
            GeneralSettings.TradingDefaults.Account = defAcc;
        }

        return defAcc;
    }

    public getDefaultProductType (): ProductType {
        return GeneralSettings.TradingDefaults.ProductType;
    }

    public static createVariantsForProductTypes (array: any[]): any[] {
        const res = [];
        const len = array.length;
        for (let i = 0; i < len; i++) {
            const val = array[i];
            res.push({
                value: +val,
                text: Resources.getResource('ProductType.' + ProductType[val])
            });
        }
        return res;
    }

    public static createVariantsForOrderTypes (array: any[]): any[] {
        const limit = array.indexOf(3);
        const stop = array.indexOf(2);
        if (stop != -1) {
            if (limit != -1) {
                const temp = array[stop];
                array[stop] = array[limit];
                array[limit] = temp;
            }
        }
        const res = [];
        const len = array.length;
        for (let i = 0; i < len; i++) {
            const val = array[i];
            const lockKey = OrderUtils.getOrderTypeLocalizationKey(val);
            if (!Resources.isHidden('DefaultSettings.' + lockKey)) {
                res.push({
                    value: val,
                    text: Resources.getResource(lockKey)
                });
            }
        }
        return res;
    }

    // TODO доделать как ОЕ заменим
    public static createVariantsForOrderTypesAdvanced (array: any[]): any[] {
        const res = [];
        const len = array.length;
        const allowedKeys = DataCache.OrderParameterContainer.OrderTypes;
        for (let i = 0; i < len; i++) {
            const val = array[i];
            res.push({
                value: allowedKeys[OrderTypeMap[val]],
                text: Resources.getResource(OrderUtils.getOrderTypeLocalizationKey(val))
            });
        }
        return res;
    }

    public checkTifInArray (array: any[], type): any {
        if (array.includes(type)) {
            return type;
        } else if (array.length > 0) {
            return array[0];
        } else {
            return type;
        }
    }

    // TODO. Implement Multivendor scheme.
    public getDefaultTifForOrderType (ordType: OrderType): OrderTif {
        switch (ordType) {
        case OrderType.Market:
            return GeneralSettings.TradingDefaults.MarketValidity;
        case OrderType.Limit:
        case OrderType.StopLimit:
            return GeneralSettings.TradingDefaults.LimitAndStopLimitValidity;
        case OrderType.Stop:
        case OrderType.TrailingStop:
            return GeneralSettings.TradingDefaults.StopValidity;
        }
    }

    public OnBoardingWasShown (): boolean {
        return GeneralSettings.General.OnboardInFirstTimeShowed;
    }

    public LogSnapshotHasBeenSent (): boolean // #111072
    {
        return GeneralSettings.General.LogSnapshotHasBeenSent;
    }

    public GetTriggers (): SLTPTrigger {
        return GeneralSettings.TradingDefaults.SlTpTriggers;
    }

    public GetWatchListDefaultItem () { return DataCache.GetWatchListDefaultItem(); }

    public createDefaultWatchlistListIfNeeded (): void {
        if (isNullOrUndefined(this.watchlistSheets)) {
            SessionSettings.watchlistSheets = {};
        }
        if (!isValidArray(this.watchlistSheets.itemsValue)) {
            this.watchlistSheets.itemsValue = [];
        }
        if (this.watchlistSheets.itemsValue.length === 0) {
            this.watchlistSheets.itemsValue.push(this.GetWatchListDefaultItem());
        }
    }

    private clearSessionSettings (): void {
        this.watchlistSheets = this.chartSheets = this.savedOrdersSheets = {};
        GeneralSettings.ApplyDefaults();
        // TODO (perhaps it's not all)
    }

    private async handleFirstLoginAndFetchWatchlist (jsonSettings): Promise<[]> {
        if (jsonSettings?.length > 0 && jsonSettings[jsonSettings.length - 1].IsDefaultSettings === true) {
            jsonSettings.pop();

            const watchListItemsValue = await DataCache.SendDefaultAndCustomListRequest();
            this.setSheetsData('watchlistSheets', watchListItemsValue);
        }

        return jsonSettings;
    }

    public async saveOnLogout (): Promise<any> {
        return await this._settingsSynchronizer.logoutSave();
    }
}

class SettingsSynchronizer {
    private readonly Interval10Min = 600000;
    private saveIntervalId: NodeJS.Timeout; ;
    constructor () {
        this.saveIntervalId = null;
    }

    public async settingSaveProcess (settingsArray: any): Promise<any> {
        this.startSaveLoop();
        void SettingsDBManager.SaveSettings(settingsArray);
    }

    public async logoutSave (): Promise<any> {
        this.stopSaveLoop();
        return await this.sendSettingsToServer();
    }

    public async handleLoadedSettings (settingsServer: SettingsDataResult): Promise<any> {
        const settingsDB = await SettingsDBManager.GetSettings();
        if (settingsDB === null || settingsServer.updateTimeSpan > settingsDB.updateTimeSpan) {
            void SettingsDBManager.SaveSettings(settingsServer.settings);
            return settingsServer.settings;
        }

        return settingsDB.settings;
    }

    private startSaveLoop (): void {
        if (!isNullOrUndefined(this.saveIntervalId)) return;
        clearInterval(this.saveIntervalId);
        this.saveIntervalId = setInterval(() => { void this.sendSettingsToServer(); }, this.Interval10Min);
    };

    private stopSaveLoop (): void {
        clearInterval(this.saveIntervalId);
        this.saveIntervalId = null;
    };

    private async sendSettingsToServer (): Promise<any> {
        const settingsData = await SettingsDBManager.GetSettingsForSend();
        if (isNullOrUndefined(settingsData)) return false;
        return await UserWebStorageInstance.settings
            .post(settingsData)
            .catch(() => {
                const ex = new CustomErrorClass('SessionSettings save error', 'SessionSettings.save', 'save -> UserWebStorage.settings');
                ErrorInformationStorage.GetException(ex);
                return false;
            });
    }
}

export const SessionSettings = new _SessionSettings();

export type SessionSettingsType = _SessionSettings;
