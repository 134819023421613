// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { TerceraButton } from '../elements/TerceraButton';

export class MDButton extends TerceraButton {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();

        this.observe(
            'tradingAllowed tradingForbiddenReason',
            this.onTradingRulesChanged);

        this.observe('locKey', this.localize);
        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public localize (): void {
        void this.set('text', Resources.getResource(this.get('locKey')));
    }

    public onTradingRulesChanged (): void {
        const tradingAllowed: boolean = this.get('tradingAllowed');
        const tradingForbiddenReason = this.get('tradingForbiddenReason');

        void this.set({
            enabled: tradingAllowed,
            tooltip:
                tradingAllowed || !tradingForbiddenReason
                    ? ''
                    : tradingForbiddenReason
        });
    }
}

TerceraButton.extendWith(MDButton, {
    data: function () {
        return {
            left: 0,
            right: 0,
            height: 25,
            tradingAllowed: false,
            tradingForbiddenReason: '',
            locKey: '',
            style: ''
        };
    }
});
