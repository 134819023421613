export class UnderlierOptionInfo {
    public readonly name: string;
    public readonly instrumentGroupId: number;
    public readonly instrumentId: number;

    constructor (name: string, instrumentGroupId: number, instrumentId: number) {
        this.name = name;
        this.instrumentGroupId = instrumentGroupId;
        this.instrumentId = instrumentId;
    }
}
