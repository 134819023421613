// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { TerceraAccountLookup } from './Lookup/TerceraAccountLookup';
import { type Rectangle } from '../../Commons/Geometry';
import { type Account } from '../../Commons/cache/Account';

export class QuickTableAccountLookup extends TerceraAccountLookup {
    private _isProcessedCallback: boolean = false;
    tag: any;
    callback: (value: Account | undefined, tag: any) => void;
    isFormShowed: boolean;
    textBox: any;

    constructor (initProps?, data?, callback?: (value: Account | undefined, tag: any) => void, rect?: Rectangle) {
        super();

        this.tag = data;
        this.callback = callback;
        this.setBounds(rect.X, rect.Y, rect.Width, rect.Height);
        void this.set('isPosAbsolute', true);
    }

    oncomplete (): void {
        super.oncomplete();
        const selectedAccount: Account = this.tag.cell.QuickTableEditingInfo.GetDataHandler();
        this.selectItem(selectedAccount.userPin);
        void this.set('visible', true);
        // const timeout = setTimeout(() => {
        //     this.Controls.lookupTB.setFocus();
        //     clearTimeout(timeout);
        // }, 50);
    }

    runCallback (account: Account): void {
        this._isProcessedCallback = true;
        this.callback(account, this.tag);
    }

    onLostFocus (): void {
        if (!this.isFormShowed) {
            this.callback(undefined, this.tag);
        }
    }

    closeForm (): void {
        super.closeForm();
        if (!this._isProcessedCallback) {
            this.onLostFocus();
        }
    }
}

TerceraAccountLookup.extendWith(QuickTableAccountLookup, { });
