// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType, PriceTypeMap } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class EMA extends IndicatorScriptBase {
    public MaPeriod: number;
    public SourcePrice: number;

    constructor () {
        super();
        this.ProjectName = 'Exponential Moving Average';
        this.Comments = 'TheExponential Moving Average weighted price calculation for the last N periods';
        super.SetIndicatorLine('Line', Color.Blue, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.MaPeriod = 2;
        super.InputParameter(new InputParameterInteger('MaPeriod', 'Period of Exponential Moving Average', 0, 1, 9999));

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Sources prices for MA', 1,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        this.SetIndexDrawBegin(0, this.MaPeriod);
    };

    public override UpdateIndexDrawBegin (): void {
        this.SetIndexDrawBegin(0, this.MaPeriod);
    };

    public override GetIndicatorShortName (): string {
        return 'EMA(' + this.MaPeriod + ';' + PriceTypeMap[this.SourcePrice].toLowerCase() + ')';
    };

    public override getCustomName (): string {
        return 'EMA (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        super.OnQuote();
        // Getting current price
        const price = this.CurrentData.GetPrice(this.SourcePrice);
        // Checking, if current amount of bars
        // more, than period of moving average. If it is
        // then the calculation is possible
        if (this.CurrentData.Count > this.MaPeriod) {
        // Calculation of a coefficient
            const k = 2 / (this.MaPeriod + 1);
            // Getting previous EMA
            const prevEMA = this.GetValue(0, 1);
            this.SetValue(0, 0, prevEMA + k * (price - prevEMA));
        } else {
            if (this.CurrentData.Count === this.MaPeriod) {
            // first value
                let summa = 0; // Sum of prices
                for (let i = 0; i < this.MaPeriod; i++) { summa += this.CurrentData.GetPrice(this.SourcePrice, i); };
                // Setting the current value of the indicator
                this.SetValue(0, 0, summa / this.MaPeriod);
            }
        }
    };
}
