// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { ContainerControl } from '../ContainerControl';
import { VerticalDetailsGroupTemplate } from '../../../templates';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';
import { VerticalDetailsRowItem } from '../../../Commons/cache/VerticalPanel/VerticalDetailsRowItem';
import { VerticalDetailsTypePointer } from '../../../Commons/cache/VerticalPanel/VerticalDetailsTypePointer';
import { type BaseGroupItem } from '../../../Commons/cache/VerticalPanel/BaseGroupItem';

export class VerticalDetailsGroup<GroupItemsType, GroupsType> extends ContainerControl {
    private group: BaseGroupItem<GroupItemsType, GroupsType> = null;
    private rowItems: Array<VerticalDetailsRowItem<GroupItemsType, GroupsType>> = [];

    public getType (): ControlsTypes {
        return ControlsTypes.VerticalDetailsGroup;
    };

    constructor () {
        super();
    }

    oncomplete (): void {
        super.oncomplete();
        this.on('clickHeader', this.changeStateCollapse);
        this.observe('groupItem', this.createGroupController);
    }

    onteardown (): void {
        this.group.unsubscribeOnUpDateEmit(this.onUpdate);
    }

    private createGroupController (value: BaseGroupItem<GroupItemsType, GroupsType>): void {
        if (isNullOrUndefined(value)) {
            return;
        };

        this.group = value;
        this.group.subscribeOnUpDateEmit(this.onUpdate);
        const groupName = this.group.getGroupName();
        const isOpen = this.group.getOpenedState();
        void this.set({ groupName, isOpen });
        this.createRowItem();
    }

    private createRowItem (): void {
        const controller = this.group.getController();
        const groupType = this.group.getType();
        const groupItemsTypes = controller.getGroupItemsTypes();
        this.rowItems = [];

        for (const itemType of groupItemsTypes) {
            if (controller.isHiddenItemRow(itemType)) {
                continue;
            }

            const typePointer = new VerticalDetailsTypePointer(itemType, groupType);
            const rowItem = new VerticalDetailsRowItem(typePointer, controller);
            if (!rowItem.getItemValue().isValid()) {
                continue;
            }

            this.rowItems.push(rowItem);
        }

        if (this.rowItems.length === 0) {
            void this.set({ visibleGroup: false });
            return;
        }

        void this.set({ rowItems: this.rowItems, visibleGroup: true });
    }

    private changeStateCollapse (): void {
        const isOpen: boolean = this.get('isOpen');
        const type: GroupsType = this.group.getType();
        void this.set({ isOpen: !isOpen });
        this.group.onChangeOpenedStateEmit(type, !isOpen);
    }

    private readonly onUpdate = (): void => {
        if ((isNullOrUndefined(this.rowItems)) || this.rowItems.length === 0) {
            return;
        }

        for (const row of this.rowItems) {
            row.onUpDateEmit();
        }
    };
}

ContainerControl.extendWith(VerticalDetailsGroup,
    {
        template: VerticalDetailsGroupTemplate,
        data: function () {
            return {
                groupItem: null,
                groupName: '',
                rowItems: null,
                isOpen: true,
                visibleGroup: false
            };
        }
    });
