// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { MsgDecoder } from './Factory/MsgDecoder';
import { DataBuffer } from './DataBuffer';
import { SocketDataStream } from './BufferedProcessor/SocketDataStream';
import { FieldsFactory } from './Factory/FieldsFactory';
import { PFixFieldSet } from './Fields/FieldsImport';

import * as vendorMessage from './Messages/MessagesImport';

import * as vendorGroup from './Groups/GroupsImport';

import * as directMessage from '../../../Utils/DirectMessages/DirectMessagesImport';

import type * as vendorProcessors from './MessageProcessors/ProcessorsImport';

import { type DirectReportResponseMessage, Message, type DirectReportGroupResponse } from '../../../Utils/DirectMessages/DirectMessagesImport';

import { HistoryType } from '../../../Utils/History/HistoryType';
import { FINISH_BLOCK_TRANSFER } from '../../../Utils/CommonConst';
import { OrderType } from '../../../Utils/Trading/OrderType';

import { DirectPropertyOwner, InformationMessageType, SlTpPriceType, SubscribeType, SubscriptionSource } from '../../../Utils/Enums/Constants';

import { CustomErrorClass, ErrorInformationStorage } from '../../../Commons/ErrorInformationStorage';

import { OperationType } from '../../../Utils/Trading/OperationType';
import { TIF } from '../../../Utils/Trading/OrderTif';
import { OrderTif } from '../../../Utils/Trading/OrderTifEnum';
import { PlacedFrom } from '../../../Utils/Trading/PlacedFrom';
import { InstrumentTypes } from '../../../Utils/Instruments/InstrumentTypes';
import { NO_BIND_TO } from '../../../Utils/Trading/OrderConstants';
import { StrategySubscriptionStatus } from '../../../Utils/TradingIdeas/StrategySubscriptionStatus';
import { RulesSet } from '../../../Utils/Rules/RulesSet';
import { SLTPTrigger } from '../../../Utils/SlTpTrigger';
import { Quantity } from '../../../Utils/Trading/Quantity';
import { OrderEditBaseUtils, OrderEditRequestData } from '../../../Utils/Trading/OrderEditBaseUtils';
import { TradingLockUtils } from '../../../Utils/TradingLockUtils';
import { PriceLimitValidation } from '../../../Utils/Instruments/PriceLimitValidation';
import { ClientType } from '../../../Utils/ClientType';
import { UUID } from '../../../Utils/UUID';
import { ClusterNodeGroupContainer } from './GroupUtils/ClusterNodeGroupContainer';
import { OrderStatus } from '../../../Utils/Trading/OrderStatus';
import { type TextMessageType } from '../../../Utils/Notifications/TextMessageType';
import { type BrokerMessageResponseType } from '../../../Utils/Notifications/BrokerMessageResponseType';
import { type Order } from '../../../Commons/cache/Order';
import { type Position } from '../../../Commons/cache/Position';
import { MathUtils } from '../../../Utils/MathUtils';
import { type IMarginRequestParameters } from '../../Interfaces/IMarginRequestParameters';
import { ProXOREncryptionUtility, EncodeKey_AES_GCM_NoPadding, EncrTypes } from '../../../Utils/EncryptionUtils';
import { ExternalResource } from '../../../Utils/Trading/ExternalResourceEnum';
import { OrderLinkType } from '../../../Utils/Trading/OrderLinkTypeEnum';
import { ServerMessaeContainer } from '../../../Utils/FullLogs/ServerMessageContainer';
import { ServerMessaeSide } from '../../../Utils/FullLogs/ServerMessageSide';
import { type MultiplyPlaceOrderWrapperItem } from '../../../Commons/Trading/MultiplyPlaceOrderWrapperItem';

export class ProcessorWorker {
    public dataStream: any = null;
    public isSSL = false;
    public siteAddres = '';
    public StreamMode: any;

    public StreamKey: any = null;

    public InstrumentsSubscribeFaltureTable: any = {};

    public readonly FMsgDecoder = new MsgDecoder(this);
    public OnNewMessageDelegate: any = null;
    public OnDisconnect: any = null;

    public SessionId = '<Empty>';

    public OnConnectionLost: any = null;
    public OnError: any = null;

    // TODO. Implement or remove?
    public parent: any = null;

    public symbolSubscriptionDict: any = {};

    public finished = false;
    public nonFixedList = false;

    public StreamModeStringName = '';

    public FullLogHandler: any = null;

    public WrappersHandlers: any = {};
    public WrappersHandlersTrading: any = {};

    // public RouteQuoteId_VS_RouteId : any = {};

    public RoutesSupported: any = {};
    public Address = '';
    public QuoteAddresses = [];

    public serverMaxFieldNumber = Number.MAX_VALUE;

    public DelayedMessages = [];
    public reconnect: any;
    public useProductType: any;
    public MyPrommisseHandler: any;

    constructor (mode) {
        this.StreamMode = mode;// || ProcessorWorker.MODE_QUOTESTREAM;

        this.InstrumentsSubscribeFaltureTable.Count = 0;

        this.CreateStringStreamModeName();
    }

    // #region stream main work

    public SendMessage (pfixmsg: vendorMessage.PFixMessage): void {
        const buffer = new DataBuffer();
        const fieldSet = pfixmsg.FieldSet;
        const code = pfixmsg.Code;
        fieldSet.Write(buffer, this.serverMaxFieldNumber);

        const length = buffer.Length();
        const dv = new DataView(new ArrayBuffer(length + 4));

        // size
        dv.setInt32(0, length);

        // data
        const bytes = buffer.ReadAllBytes();
        for (let i = 0; i < length; i++) {
            dv.setInt8(i + 4, bytes[i]);
        }

        this.dataStream.SendData(dv.buffer, code);

        if (this.FullLogHandler) // 4th arg for outgoing message
        {
            this.FullLogs(fieldSet, true, length, true);
        }
    }

    // Moved to worker
    public AppendData (data): void {
        const dv = new DataView(data);

        let currIndex = 0;
        while (currIndex < data.byteLength) {
        // длина мессаджа - 4 байта
            if ((data.byteLength - currIndex) < 4) {
                return;
            }

            const length = dv.getInt32(currIndex);
            currIndex += 4;

            // Копируем из массива данных бинарное сообщение и отправляем его
            const msg = this.CopyData(dv, currIndex, length);
            if (msg != null) {
                currIndex += length;
                this.Push(msg);
            } else {
            // в буфере не осталось достаточно символов - выходим
                currIndex -= 4;
                break;
            }
        }
    }

    // Moved to worker
    public CopyData (dv: DataView, curIndex, count): number[] | null {
        if ((curIndex + count) <= dv.byteLength) {
            const newData: number[] = [];
            for (let i = 0; i < count; i++) {
                newData[i] = dv.getInt8(curIndex++);
            }

            return newData;
        } else {
            return null;
        }
    }

    // Moved to worker
    public GetFieldSet (msg): PFixFieldSet {
        const fieldSet = new PFixFieldSet();
        const buffer = new DataBuffer(msg);
        fieldSet.Read(buffer);
        return fieldSet;
    }

    public Push (bytes: number[]): void {
        const fs = this.GetFieldSet(bytes);

        if (this.FullLogHandler) {
            this.FullLogs(fs, false, bytes.length);
        }

        const messages = this.FMsgDecoder.Decode(fs);

        const rqi = fs.GetField(FieldsFactory.FIELD_REQUEST_ID);
        if (rqi) {
            if (this.CheckIsHandled(rqi.Value, messages)) {
                return;
            }
        }

        if (Array.isArray(messages)) {
            for (let i = 0; i < messages.length; i++) {
                if (messages[i]) {
                    if (!messages[i].IsDirect) {
                        const rqi = messages[i].FieldSet.GetField(FieldsFactory.FIELD_REQUEST_ID);
                        if (rqi) {
                            if (this.CheckIsHandled(rqi.Value, messages[i])) {
                                continue;
                            }
                        }
                    } else if (messages[i].OrderNumber || messages[i].OrderId) {
                        this.CheckIsTraidingHandled(messages[i]);
                    } else if (messages[i].RequestID) {
                        this.CheckIsHandled(messages[i].RequestID, messages[i]);
                    }

                    this.Notify(messages[i]);
                }
            }
        }
    }

    public static async SendMessageToVendor (url, isSSL, msgType, dataForMsg, FullLogHandler): Promise<any> {
        if (!ProcessorWorker.prototype[msgType]) {
            return await Promise.resolve(null);
        }

        const stream = new ProcessorWorker(ProcessorWorker.MODE_TRADESTREAM);
        if (FullLogHandler) {
            stream.FullLogHandler = FullLogHandler;
        }

        stream.siteAddres = url;
        stream.isSSL = isSSL;
        return await new Promise(async (resolve, reject) => {
            stream.OnError = function () {
                resolve(null);
            };
            return await stream.OpenWS().then(function (opened) {
                if (!opened) {
                    return Promise.resolve(null);
                }

                return stream[msgType](dataForMsg)
                    .then(function (result) {
                        stream.Disconnect();
                        resolve(result);
                    });
            });
        });
    }

    public CheckIsTraidingHandled (msg): boolean {
        let rqi = msg.OrderNumber || msg.OrderId;
        let handler = this.WrappersHandlersTrading[rqi];
        if (!handler) {
            rqi = msg.ClientOrderId;
            handler = this.WrappersHandlersTrading[rqi];
        }

        if (!handler) {
            return false;
        }

        if (msg.OrderStatus === OrderStatus.PENDING_NEW || msg.Status === OrderStatus.PENDING_NEW) {
            return false;
        }

        handler(msg);
        delete this.WrappersHandlersTrading[rqi];

        return true;
    }

    public CheckIsHandled (rqi: number, msg): boolean {
        const handler = this.WrappersHandlers[rqi];
        if (!handler) {
            return false;
        }

        handler(msg);
        delete this.WrappersHandlers[rqi];
        return true;
    }
    // #endregion

    // #region  connect disconnect
    public async ConnectToServer (serverAdress, params): Promise<any> {
        if (!serverAdress) {
            return await Promise.reject(this.CreateOpenWSError('No Server Adress', 14, 1003));
        }

        return await this.ConnectionInProcess(serverAdress, params);
    }

    public async ConnectionInProcess (serverAdress, params): Promise<any> {
        const self = this;

        self.isSSL = params.isSSL;
        self.siteAddres = serverAdress;
        self.reconnect = params.reconnect;
        return await self.OpenWS()
            .then(async function (opened) {
                if (opened) {
                    return await self.Connect(params);
                } else {
                    return await Promise.reject(self.CreateOpenWSError("Can't open stream", 13, 100));
                }
            })
            .catch(async function (connerr) {
                return await Promise.reject(self.CreateOpenWSError(connerr.message, connerr.code, 1001));
            })
            .then(async function (connectResult) {
                return await Promise.resolve(connectResult);
            });
    };

    public CreateOpenWSError (errorText, logoutType, errorCode): any {
        const result: any = {};
        result.Text = errorText;
        result.LogoutType = logoutType;
        result.ErrorCode = errorCode;
        result.Connected = false;
        result.ErrorInConnection = true;
        return result;
    }

    // Moved to worker
    public async OpenWS (sert?): Promise<any> {
        const self = this;
        return await new Promise(async function (resolve, reject) {
            const delegates: any = {};
            delegates.OnOpenDelegate = self.StreamOpened.bind(self, resolve);
            delegates.OnCloseDelegate = self.StreamClosed.bind(self, resolve);
            delegates.OnErrorDelegate = self.StreamError.bind(self, resolve);
            delegates.OnMessageDelegate = self.AppendData.bind(self);

            let addresString = self.isSSL ? 'wss://' : 'ws://';
            addresString += self.siteAddres;
            addresString += '/websocket';
            // const ws = sert ? new WebSocket(addresString, { ca: sert }) : new WebSocket(addresString);
            const ws = await self.createWebSocket(addresString, sert);
            ws.binaryType = 'arraybuffer';

            self.dataStream = new SocketDataStream(ws, delegates);
        });
    }

    private async createWebSocket (addresString, sert): Promise<any> {
        if (typeof window === 'undefined') {
            // Node.js environment
            const { default: WebSocket } = await import('ws');
            return sert ? new WebSocket(addresString, sert) : new WebSocket(addresString);
        } else {
            // Browser environment
            return sert ? new WebSocket(addresString, sert) : new WebSocket(addresString);
        }
    }

    public Disconnect (isNoNActivity?): void {
        if (this.dataStream) {
            const disconnectMSG = new vendorMessage.LogoutMessage();
            disconnectMSG.setText(isNoNActivity ? 'Disconnect due to inactivity' : 'Disconnect.');
            this.SendMessage(disconnectMSG);
            this.dataStream.Dispose();
        }
    }

    public CloseStream (): void {
        if (this.dataStream) {
            this.dataStream.CloseStream();
        }
    }

    // Moved to worker
    public StreamOpened (resolve, isOpen): any {
        return resolve(true);
    }

    // Moved to worker
    public StreamClosed (resolve): any {
    // this.Disconnect();
        if (!this.reconnect) {
            return resolve(false);
        }
    }

    // Moved to worker
    public StreamError (resolve, isOpen): void {
        if (this.OnConnectionLost && this.finished) {
            this.OnConnectionLost();
        }

        if (this.OnError) // TODO необходимо довести reject в ответ Promise-ов с помощью resolve(false);
        {
            this.OnError(resolve);
        }
    }
    // #endregion

    public async Connect (connectionsParameters, accessToken?): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const hello = new vendorMessage.HelloMessage();
            hello.setHello('hello');

            ProcessorWorker.AddProtocolVersonAndMaxField(hello);

            me.serverMaxFieldNumber = hello.getMaxFieldNumber();

            const rqi = hello.getSequance();
            hello.setRequestId(rqi);
            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(hello);
        })
            .then(async function (helloAnswer) {
                return await me.SendLogonMessage(connectionsParameters, helloAnswer[0], accessToken);
            })
            .then(function (logonAnswer) {
                return me.LogonAnswerProcess(logonAnswer[0]);
            });
    }

    public async SendLogonMessage (connectionsParameters, helloAnswer, accessToken): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const hello = helloAnswer;

            const login = connectionsParameters.login || '';

            const Mode = me.StreamMode;
            const clientType = ClientType.getClientType();
            const additionalPassword = connectionsParameters.verificationPassword || null;
            const useGetInitDataRequest = Mode === ProcessorWorker.MODE_TRADESTREAM;
            const dateOfBuild = connectionsParameters.build_date_day || '16.04.2019';

            const prommises = [];
            const EncrType = hello.EncryptionType();
            const logon = new vendorMessage.LogonMessage();
            logon.setLogin(login);
            if (connectionsParameters.accessToken) {
                logon.setAccessToken(connectionsParameters.accessToken);
            } else {
                const pass = connectionsParameters.pass || '';
                let pr = null;
                if (EncrType !== EncrTypes.AES_GCM_NoPadding) {
                    pr = ProXOREncryptionUtility.Encode(pass, hello.getHello())
                        .then((encrPass) => {
                            logon.setPassword(encrPass);
                            return true;
                        });
                } else {
                    pr = EncodeKey_AES_GCM_NoPadding(pass, hello.getHello())
                        .then((encrPass) => {
                            logon.setPassword(encrPass);
                            return true;
                        });
                }
                prommises.push(pr);
            }

            if (connectionsParameters.brandingKey) {
                let pr = null;
                if (EncrType !== EncrTypes.AES_GCM_NoPadding) {
                    pr = ProXOREncryptionUtility.Encode(connectionsParameters.brandingKey, hello.getHello())
                        .then((encrPass) => {
                            logon.setBrandingKey(encrPass);
                            return true;
                        });
                } else {
                    pr = EncodeKey_AES_GCM_NoPadding(connectionsParameters.brandingKey, hello.getHello())
                        .then((encrPass) => {
                            logon.setBrandingKey(encrPass);
                            return true;
                        });
                }
                prommises.push(pr);
            }

            if (connectionsParameters.ipURL) {
                logon.setIP(connectionsParameters.ipURL);
            }

            // new
            logon.setConnectionMode(Mode);
            logon.setClientType(clientType);
            if (additionalPassword) {
                logon.setVerificationPassword(additionalPassword);
            }

            if (connectionsParameters.ExternalResourse) {
                logon.setExternalResourse(connectionsParameters.ExternalResourse);
            }

            if (connectionsParameters.TokenID) {
                logon.setTokenID(connectionsParameters.TokenID);
            }

            logon.setCanISendGetInitDataRequest(useGetInitDataRequest);
            logon.setDateOfBuild(dateOfBuild);

            if (connectionsParameters.customerNo) {
                logon.SetCustomerNo(connectionsParameters.customerNo);
                logon.setExternalResourse(ExternalResource.OPTIMUS);
            }

            logon.SetAppInstallId(UUID.getAppInstallID());

            me.WrappersHandlers.logonResolve = resolve;

            Promise.all(prommises).then(() => { me.SendMessage(logon); });
        });
    }

    public HandleLogon (msg): any {

    }

    public LogonAnswerProcess (logonAnswer, useGetInitDataRequest?): any {
        if (logonAnswer.Code === Message.CODE_PFIX_LOGON_MESSAGE) {
            return this.LogonWorking(logonAnswer, useGetInitDataRequest);
        }

        if (logonAnswer.Code === Message.CODE_PFIX_LOGOUT_MESSAGE) {
            return this.LogoutWorking(logonAnswer, useGetInitDataRequest);
        }
    }

    public LogonWorking (logon, useGetInitDataRequest): any {
        const userSessionId = logon.getSessionId();
        const login = logon.getLogin();
        const result: any = { userSessionId, login };

        this.SessionId = userSessionId;

        const clusterNodeGroups = logon.FieldSet.GetGroups(FieldsFactory.CLUSTER_NODE_GROUP, vendorGroup.ClusterNodeGroup);
        const clusterNodeGroupsContainer = new ClusterNodeGroupContainer(clusterNodeGroups);

        /// /////Start////////
        const mess = new directMessage.DirectPropertyMessage();
        mess.Name = 'startblocktransfer';
        mess.Value = 'true';
        this.OnNewMessageDelegate(mess);

        const tradingUnlockLogic = logon.getTradingUnlockLogic();

        if (tradingUnlockLogic) // #89340 & #94446
        {
            TradingLockUtils.IsUseLockTradingByPassword = tradingUnlockLogic > 0;
        }

        if (tradingUnlockLogic === 2 || tradingUnlockLogic === 3) // use one time password
        {
            const useOnTimepassword = new directMessage.DirectPropertyMessage();
            useOnTimepassword.Name = RulesSet.FUNCTION_USE_ONE_TIME_PASSWORD;
            this.DelayedMessages.push(useOnTimepassword);
        }

        if (this.StreamMode === ProcessorWorker.MODE_TRADESTREAM) {
            this.getInitData(logon, useGetInitDataRequest);
        }

        // version 1.9
        const accessToken = logon.GetAccessToken();
        const accessTokenLifeTime = logon.GetAccessTokenLifeTime();
        const refreshToken = logon.GetRefreshToken();
        const refreshTokenLifeTime = logon.GetRefreshTokenLifeTime();

        result.Connected = !!userSessionId;
        result.CngContainer = clusterNodeGroupsContainer;
        result.ErrorInConnection = false;
        result.isPasswordExpired = logon.isPasswordExpired();
        if (result.isPasswordExpired) {
            result.UserID = logon.getFieldValue(FieldsFactory.FIELD_USER_ID);
        }

        result.verificationMode = logon.getVerificationMode();

        result.accessToken = accessToken;
        result.accessTokenLifeTime = accessTokenLifeTime;
        result.refreshToken = refreshToken;
        result.refreshTokenLifeTime = refreshTokenLifeTime;

        return result;
    }

    public LogoutWorking (logout, useGetInitDataRequest): any {
        const result: any = {};

        const sessionId = logout.getSessionId();
        const errorText = logout.getText();
        const logoutType = logout.getLogoutType();
        const errorCode = logout.getErrorCode();

        result.Text = errorText;
        result.LogoutType = logoutType;
        result.ErrorCode = errorCode;
        result.Connected = false;
        result.ErrorInConnection = true;

        return result;
    }

    public getInitData (logon, useGetInitDataRequest): void {
        const nonFixedList = logon.getIsUnfixedList();
        if (nonFixedList === null)
        // сервер не поддерживает запрос
        { return; }

        // this.SendExchanges()

        const pMsg = new directMessage.DirectPropertyMessage();
        pMsg.Name = RulesSet.FUNCTION_NON_FIXED_LIST;
        pMsg.Value = nonFixedList ? 'true' : 'false';
        this.nonFixedList = nonFixedList;
        this.Notify(pMsg);

        const request = new vendorMessage.InitDataRequest();
        request.setMaxFieldNumber(FieldsFactory.getInstance().MaxFieldNumber);

        if (nonFixedList) {
        // remove
            const symbols = [];// ['AUD/CAD']
            if (symbols.length > 0) {
                const instumentsGroup = new vendorGroup.InitDataRequestInstrumentsGroup();
                request.FieldSet.AddGroup(instumentsGroup);
                for (let i = 0; i < symbols.length; i++) {
                    const gr = new vendorGroup.InstrumentNamesGroup();
                    gr.setName(symbols[i]);
                    instumentsGroup.AddGroup(gr);
                }
            }
        }
        this.SendMessage(request);
    }

    public Notify (message): void {
        const code = message.Code;
        if (message.IsDirect) {
            switch (message.Code) {
            case Message.CODE_ASSET_TYPE_MESSAGE:
                this.FMsgDecoder.AssetDecodeMap[message.Id] = message.Name;
                break;

            case Message.CODE_INSTRUMENT:{
                const insID = message.ForwardBaseInstrumentID;
                const isOptionInNonFixedList = message.InstrType === InstrumentTypes.OPTIONS && insID !== null && insID !== -1 && this.nonFixedList;
                const instrumentMsgAlreadyReceived = this.FMsgDecoder.GetSymbolById(insID); // #113128
                if (isOptionInNonFixedList && !instrumentMsgAlreadyReceived) {
                    void this.getInstrumentById(insID).then((messages: any) => {
                        for (let i = 0; i < messages.length; i++) {
                            if (messages[i].Code === Message.CODE_INSTRUMENT) {
                                this.OnNewMessageDelegate(messages[i]);
                            }
                        }
                    });
                    return;
                }
                break;
            }
            case Message.CODE_PFIX_PROPERTY_MESSAGE:
                this.handlePropertyMessage(message);
                return;

            case Message.CODE_ACCOUNTSTATUS:
            case Message.CODE_TEXT_MESSAGE:
                message.ClusterNode = this.StreamKey;
                break;
            }

            this.OnNewMessageDelegate(message);
        } else {
            switch (code) {
            case Message.CODE_PFIX_HELLO_MESSAGE:
            case Message.CODE_PFIX_LOGON_MESSAGE:
            case Message.CODE_PFIX_LOGOUT_MESSAGE:{
                const handle = this.WrappersHandlers.logonResolve;
                if (handle) {
                    handle([message]);
                }

                delete this.WrappersHandlers.logonResolve;
                this.HandleLogon(message);

                if (Message.CODE_PFIX_LOGOUT_MESSAGE !== message.Code || handle) {
                    break;
                }

                const msg = new directMessage.DirectPropertyMessage();
                msg.Name = 'LOGOUT';
                msg.Value = {
                    LogoutType: message.getLogoutType(),
                    ErrorCode: message.getErrorCode(),
                    Text: message.getText()
                };

                this.OnNewMessageDelegate(msg);
                break;
            }

            case Message.CODE_PFIX_XML_MESSAGE:
                this.OnNewMessageDelegate(
                    ProcessorWorker.createDirectReportResponseMessage(message));
                break;
            }
        }
    }

    // TODO. Refactor.
    public handlePropertyMessage (msg): void {
        const isFinishBlockTransfer = msg.Name === FINISH_BLOCK_TRANSFER;
        if (isFinishBlockTransfer) {
            this.finished = true;
            if (this.DelayedMessages?.length) {
                for (let i = 0; i < this.DelayedMessages.length; i++) {
                    this.OnNewMessageDelegate(this.DelayedMessages[i]);
                }
                this.DelayedMessages.length = 0;
            }
            this.trySendPropertyMessage(
                ProcessorWorker.createLocalRule(
                    RulesSet.VALUE_THROTLING_PANEL_LIMIT_AGGRESSOR_BALANCE, '1'));

            if (this.useProductType) {
                this.trySendPropertyMessage(
                    ProcessorWorker.createLocalRule(
                        RulesSet.FUNCTION_USE_PRODUCT_TYPE, '1'));
            }
        }

        this.trySendPropertyMessage(msg);
    }

    public trySendPropertyMessage (msg): boolean {
        if (!msg) {
            return false;
        }

        const tradeStream = this.StreamMode & ProcessorWorker.MODE_TRADESTREAM;
        const isFinishBlockTransfer = msg.Name === FINISH_BLOCK_TRANSFER;

        if (isFinishBlockTransfer || tradeStream) {
            this.OnNewMessageDelegate(msg);
            return true;
        }

        return false;
    }

    public static createLocalRule (name, value): directMessage.DirectPropertyMessage {
        const pm = new directMessage.DirectPropertyMessage();
        pm.OwnerType = DirectPropertyOwner.Local;
        pm.Name = 'RULE';
        pm.Value = name + '=' + value;
        return pm;
    }

    // TODO. PFixMsgProcessor.cs->FindRequestTime()
    public FindRequestTime (msg): number {
        return 0;
    }

    public CreateStringStreamModeName (): void {
        let mode = '';
        if ((this.StreamMode & ProcessorWorker.MODE_QUOTESTREAM) > 0) {
            mode += 'Quote;';
        }

        if ((this.StreamMode & ProcessorWorker.MODE_TRADESTREAM) > 0) {
            mode += 'Trade;';
        }

        if ((this.StreamMode & ProcessorWorker.MODE_HISTORYSTREAM) > 0) {
            mode += 'History;';
        }

        if ((this.StreamMode & ProcessorWorker.MODE_NEWSTREAM) > 0) {
            mode += 'News;';
        }

        this.StreamModeStringName = mode;
    }

    // #region IQuote

    // TODO. Generate lvl1 from lvl2, unsubscribe from lvl1 if lv2 sub exists.
    // TODO. Are HistoryType constants ok here?
    public SubscribeQuote (InstrumentId, route, service, forse, isDataSource): void {
        if (isDataSource) {
            return;
        }

        if (!forse && this.subscribed(InstrumentId, route, service)) {
            return;
        }

        const sent = this.trySendSubscriptionMessage(InstrumentId, route, service, SubscribeType.SUBSCRIBE);
        if (!sent) return;

        // if (this.subscribed(InstrumentId, route, service))
        //    return;

        // DayBar message subscription #57649
        if (this.subscribed(InstrumentId, route, HistoryType.QUOTE_INSTRUMENT_DAY_BAR)) {
            return;
        }

        if (!this.subscribed(InstrumentId, route, HistoryType.QUOTE_LEVEL1) &&
        !this.subscribed(InstrumentId, route, HistoryType.QUOTE_LEVEL2)) {
            return;
        }

        this.trySendSubscriptionMessage(InstrumentId, route, HistoryType.QUOTE_INSTRUMENT_DAY_BAR, SubscribeType.SUBSCRIBE);
    }

    // TODO. Generate lvl1 from lvl2, unsubscribe from lvl1 if lv2 sub exists.
    public UnsubscribeQuote (InstrumentId, route, service, forse, isDataSource): void {
        if (isDataSource) {
            return;
        }

        const sent = this.trySendSubscriptionMessage(InstrumentId, route, service, SubscribeType.UNSUBSCRIBE);
        if (!sent) return;

        // if (!this.subscribed(InstrumentId, route, service))
        //    return;

        // DayBar message unsubscription #57649
        if (!this.subscribed(InstrumentId, route, HistoryType.QUOTE_INSTRUMENT_DAY_BAR)) {
            return;
        }

        if (this.subscribed(InstrumentId, route, HistoryType.QUOTE_LEVEL1) ||
        this.subscribed(InstrumentId, route, HistoryType.QUOTE_LEVEL2)) {
            return;
        }

        this.trySendSubscriptionMessage(InstrumentId, route, HistoryType.QUOTE_INSTRUMENT_DAY_BAR, SubscribeType.UNSUBSCRIBE);
    }

    // TODO. Rename.
    public trySendSubscriptionMessage (InstrumentId, route, service, subscriptionActionType: SubscribeType, isDataSource?, targetTrInsID?): boolean {
        const group = this.createSubscribeGroup(InstrumentId, route, service, subscriptionActionType, isDataSource, targetTrInsID);
        if (!group) return false;

        if (!this.trySetBaseSubscriptionArguments(group, InstrumentId, route, service)) {
            return false;
        }

        group.setGroupID(0);
        group.InstrumentTradableID = InstrumentId;
        group.Route = route;
        group.Service = service;

        if (service === 2 || service === 32) {
            group.setSubscriptionPeriodicity(1);
        }

        const message = new vendorMessage.SubscribeMessage();
        message.setSubscriptionAction(subscriptionActionType);
        message.FieldSet.AddGroup(group);

        const me = this;
        const sendSubsHandler = new Promise(function (resolve, reject) {
            const rqi = message.getSequance();
            message.setRequestId(rqi);
            me.WrappersHandlers[rqi] = resolve;
            me.SendMessage(message);
        })
            .then(function (message) {
                const m = message[0] || message;
                if (!m) { return; }

                m.InstrumentId = InstrumentId;
                m.Route = route;

                // if (!m.ErrorCode)
                me.Notify(m);

                if (m.ErrorCode) {
                    me.CheckErrorSubscribeResponse(group);
                } else if (group.Service === directMessage.DirectQuoteMessage.QUOTE_LEVEL2) {
                    me.CheckSuccessSubcribeResponse(group);
                }
            });

        this.updateSymbolSubscriptionDict(InstrumentId, route, service, subscriptionActionType === SubscribeType.SUBSCRIBE);

        return true;
    }

    public CheckErrorSubscribeResponse (group): null {
        if ((group.Service & directMessage.DirectQuoteMessage.QUOTE_LEVEL2) !== directMessage.DirectQuoteMessage.QUOTE_LEVEL2) {
            return null;
        }

        const tradableID = group.getTradableInstrumentId();
        const route = group.getRouteId();

        let dict = this.InstrumentsSubscribeFaltureTable[tradableID];
        if (!dict) {
            dict = {};
            this.InstrumentsSubscribeFaltureTable[tradableID] = dict;
            this.InstrumentsSubscribeFaltureTable.Count = Object.keys(this.InstrumentsSubscribeFaltureTable).length - 1;
        }
        dict[route] = directMessage.DirectQuoteMessage.QUOTE_LEVEL2;

        const processor = this.FMsgDecoder.FProcTable[Message.CODE_QUOTE] as vendorProcessors.Quote1MessageProcessor;
        if (processor) {
            const q1Dic = processor.Quote1ValidDictionary;
            if (!q1Dic) {
                return null;
            }

            const rDict = q1Dic[route];
            if (!rDict) {
                return null;
            }

            const quote = rDict[tradableID];
            if (!quote) {
                return null;
            }

            quote.TradableId = tradableID;
            quote.QuoteRouteId = route;

            const q2 = directMessage.DirectQuote2Message.GetQute2MessagesFromQuote1ByDenyTable(quote, this.InstrumentsSubscribeFaltureTable);
            if (q2) {
                this.Notify(q2);
            }
        }

        return null;
    }

    public CheckSuccessSubcribeResponse (group: vendorGroup.SubscribeGroup): void {
        const tradableInstrumentId = group.getTradableInstrumentId();
        const SubsrDict = this.InstrumentsSubscribeFaltureTable[tradableInstrumentId];
        if (SubsrDict) {
            const route = group.getRouteId();
            const subscription = SubsrDict[route];
            if (subscription) {
                if (subscription === directMessage.DirectQuoteMessage.QUOTE_LEVEL2) {
                    SubsrDict[route] = null;
                    delete SubsrDict[route];
                }
            }

            if (Object.keys(SubsrDict).length === 0) {
                delete this.InstrumentsSubscribeFaltureTable[tradableInstrumentId];
            }
        }
    }

    public GetAvancedTable (): any {
        return this.InstrumentsSubscribeFaltureTable;
    }

    public createSubscribeGroup (InstrumentId, route, services, subscriptionActionType, isDataSource, targetTrInsID): vendorGroup.SubscribeGroup {
    // TODO. Option SubscribeGroup.
    // ...

        const group = new vendorGroup.SubscribeGroup();

        if (isDataSource) {
            group.setDataSource(SubscriptionSource.DATASOURCE);
            group.setTargetTradableId(targetTrInsID, SubscriptionSource.DATASOURCE, subscriptionActionType);
        }

        return group;
    }

    public SendDataSourceSubscriptionMessage (InstrumentId, route, service, subscriptionActionType, targetTrInsID): void {
        const toSubscribe = subscriptionActionType == SubscribeType.SUBSCRIBE;
        if (this.subscribed(InstrumentId, route, service) == toSubscribe) {
            return;
        }

        this.trySendSubscriptionMessage(InstrumentId, route, service, subscriptionActionType, true, targetTrInsID);
    }

    public trySetBaseSubscriptionArguments (group, InstrumentId, route, service): boolean {
        const msgDecoder = this.FMsgDecoder;

        group.setTradableInstrumentId(InstrumentId);

        // id требуемого роута
        const routeId = route;
        // id соотв котировочного роута
        const quoteId = msgDecoder.GetRouteQuoteId(routeId);

        // if (routeId !== quoteId)
        //    this.RouteQuoteId_VS_RouteId[quoteId] = route;

        group.setRouteId(quoteId);
        group.setSubscriptionType(service);
        group.setLight(false);

        return true;
    }

    // TODO. Rename.
    public updateSymbolSubscriptionDict (InstrumentId, route, service, subscribed): void {
        const symSubsDict = this.symbolSubscriptionDict;
        if (!symSubsDict[route]) {
            symSubsDict[route] = {};
        }

        let insItem = symSubsDict[route][InstrumentId];

        if (!insItem) {
            insItem = {};
            symSubsDict[route][InstrumentId] = insItem;
        }

        if (subscribed) {
            insItem[service] = true;
        } else {
            delete insItem[service];
        }
    }

    public subscribed (InstrumentId, route, service): boolean {
        const symSubsDict = this.symbolSubscriptionDict;
        const serviceSetRoute = symSubsDict[route];
        if (!serviceSetRoute) {
            return false;
        }

        const serviceSetInstrumentId = serviceSetRoute[InstrumentId];
        if (!serviceSetInstrumentId) {
            return false;
        }

        return !!serviceSetInstrumentId[service];
    }

    // #endregion IQuote

    public FullLogs (fs: PFixFieldSet, output: boolean, msgLength: number, isOutgoingMessage?: boolean): void {
        const container = new ServerMessaeContainer();
        container.MessageCode = fs.GetField(0).Value;
        container.MessageName = FieldsFactory.GetMsgTypeName(container.MessageCode);
        container.Text = fs.toString();
        container.TextNew = fs.ToStringView();
        container.DataForTreeView = fs.GetData();
        container.Side = output ? ServerMessaeSide.Output : ServerMessaeSide.Input;
        container.Length = msgLength;
        container.Mode = this.StreamMode;
        container.StringMode = this.StreamModeStringName;
        container.IsOutgoingMSG = !!isOutgoingMessage;

        // TODO
        // if (FDialogHolder != null)
        // {
        container.Session = this.SessionId;
        container.NodeAddress = this.siteAddres;
        // }
        this.FullLogHandler(container);
    }

    public SubscribeCrossRates (): void {
        const message = new vendorMessage.SubscribeCrossRateMessage();
        message.setSubscriptionAction(SubscribeType.SUBSCRIBE);
        this.SendMessage(message);
    }

    /// /////History Work

    // #region History Work

    public ApplySpreadToBaseInterval (historyParams, bidBaseIntervals, askBaseIntervals, instr): void {
        if (instr == null) {
            return;
        }

        if (bidBaseIntervals == null && askBaseIntervals == null) {
            return;
        }

        const spreadPlan = historyParams.Plan;
        const period = historyParams.TimeFrameInfo.Periods;

        if (bidBaseIntervals == null || askBaseIntervals == null) // кейс, когда не нужна вторая часть истории для спредирования
        {
            const intervalsToSpread = bidBaseIntervals || askBaseIntervals;
            const historyType = bidBaseIntervals != null ? HistoryType.QUOTE_LEVEL1 : HistoryType.QUOTE_ASK;
            for (let i = 0; i < intervalsToSpread.length; i++) {
                intervalsToSpread[i].ApplySpread(spreadPlan, instr, period, historyType, null);
            }

            return;
        }

        let askIndex = 0;
        if (historyParams.Plan != null) {
            for (let i = 0; i < bidBaseIntervals.length; i++) {
                const bidBi = bidBaseIntervals[i];
                const askBi = askBaseIntervals.length > askIndex ? askBaseIntervals[askIndex] : null;

                if (bidBi != null && askBi != null && bidBi.FLeftTimeTicks == askBi.FLeftTimeTicks) {
                // hsa: почему мы брали HistoryType из TimeFrameInfo?
                // ведь в случае запросы истории по аскам мы спредировали данные наоборот - биды как аски, а аски как биды
                    bidBi.ApplySpread(spreadPlan, instr, period, HistoryType.QUOTE_LEVEL1, askBi);
                    askIndex++;
                }
            }
        }
    }

    // TODO. Ugly. Refactor.
    // Details are in XMLMessageProcessor.
    public static createDirectReportResponseMessage (pfixXmlMessage: vendorMessage.PFixXmlMessage): directMessage.DirectReportResponseMessage {
        const msg = new directMessage.DirectReportResponseMessage();
        // if (pfixXmlMessage.length && pfixXmlMessage[0].Code === 14) {
        //     return null;
        // }

        msg.xmlString = pfixXmlMessage.getFieldValue(FieldsFactory.FIELD_XML_BODY) ||
        vendorGroup.LineGroup.getLongText(pfixXmlMessage.FieldSet);

        return msg;
    }

    public static createDirectReportGroupResponseMessage (pfixMessage: vendorMessage.ReportGroupResponseMessage): directMessage.DirectReportGroupResponse {
        if (!pfixMessage) {
            return null;
        }

        pfixMessage = pfixMessage[0] || pfixMessage;
        if (!pfixMessage) {
            return null;
        }

        const msg = pfixMessage.getDirectReportGroupResponse();
        return msg;
    }

    public async generateReportPromise (reportId, params): Promise<DirectReportResponseMessage | DirectReportGroupResponse> {
        if (this.serverMaxFieldNumber >= FieldsFactory.FIELD_REPORT_ID) {
            return await this.requestReportPromise(reportId, params)
                .then(ProcessorWorker.createDirectReportGroupResponseMessage);
        } else {
            return await this.requestOldReportPromise(reportId, params) // старая схема
                .then(ProcessorWorker.createDirectReportResponseMessage);
        }
    }

    public async requestReportPromise (reportId, params): Promise<any> {
        const request = new vendorMessage.ReportGroupRequestMessage();

        const reqId = request.getSequance();
        request.setRequestId(reqId);

        if (params.locale) {
            request.setFieldValue(FieldsFactory.FIELD_LANG, params.locale);
        }

        if (params.reportTypeObj?.id) {
            request.setReportId(params.reportTypeObj.id);
        }

        request.setIsTechnicalReport(!!params.IsTechnical);

        const pDict = params.mainParamDict;
        for (const key in pDict) {
            const paramGroup = new vendorGroup.KeyValueGroup();
            paramGroup.setKey(key);
            paramGroup.setValue(pDict[key]);

            request.addGroup(paramGroup);
        }

        const self = this;
        return await new Promise(function (resolve, reject) {
            self.WrappersHandlers[reqId] = resolve;
            self.SendMessage(request);
        });
    }

    public async requestOldReportPromise (reportId, params): Promise<any> {
        const request = new vendorMessage.ReportRequestMessage();

        const reqId = request.getSequance();
        request.setRequestId(reqId);
        // +++ server should return time always in UTC
        request.setFieldValue(FieldsFactory.FIELD_TYPE, 1);
        // по умолчанию реквестим имя инструмента с алиасами, но в некоторых случаях,
        // нам нужно истинное, серверное имя
        if (params.locale) {
            request.setFieldValue(FieldsFactory.FIELD_LANG, params.locale);
        }

        request.setTemplateName(reportId);

        if (params.reportTypeObj?.id) {
            const repTypeGroup = new vendorGroup.KeyValueGroup();
            repTypeGroup.setKey('reporttype');
            repTypeGroup.setValue(params.reportTypeObj.id.toString());

            request.addGroup(repTypeGroup);
        }

        const pDict = params.mainParamDict;
        for (const key in pDict) {
            const paramGroup = new vendorGroup.KeyValueGroup();
            paramGroup.setKey(key);
            paramGroup.setValue(pDict[key]);

            request.addGroup(paramGroup);
        }

        const self = this;
        return await new Promise(function (resolve, reject) {
            self.WrappersHandlers[reqId] = resolve;
            self.SendMessage(request);
        });
    }

    // #endregion History Work

    /// /////////TODO////////////
    // public FillParamsTable  (stream, historyParams, mode, paramsTable)
    // {
    //     if (paramsTable != null)
    //     {
    //         if (string.IsNullOrEmpty(historyParams.AnotherConnectionURL) && FProcessor != null)
    //             FProcessor.CreateHistoryParams(historyParams, "history", paramsTable);
    //         else if (!string.IsNullOrEmpty(historyParams.AnotherConnectionURL))
    //         {
    //             PFSFXMsgProcessor.CreateHistoryParams(historyParams.AnotherConnectionURL, historyParams.AnotherConnectionURL.StartsWith("https:"), historyParams.AnotherConnectionInstrumentID, historyParams, "history", paramsTable);
    //             paramsTable["l"] = historyParams.AnotherConnectionLogin;
    //             paramsTable["md5p"] = EncryptPassword(historyParams.AnotherConnectionPassword, historyParams.CryptType);
    //         }

    //         if (paramsTable.ContainsKey("r")) //#48726 - мы должны получить ссылку на котировочный роут, иначе данные не загрузятся
    //         {
    //             let processor = stream;
    //             if (processor != null)
    //                 paramsTable["r"] = processor.GetQuoteRouteName(paramsTable["r"]);
    //         }

    //         paramsTable["workMode"] = mode;
    //     }
    // }

    public static GetUnifiedAddress (address: string, secure = null): string {
        let default_port = '80';
        // отрезаем приставки, мешающие правильному разбору
        let hostName = address.trim();
        if (hostName.startsWith('http://')) {
            hostName = hostName.slice(0, 7);
        }

        // +++ http://tp3.pfsoft.lan/entity/23449
        if (secure !== null) {
            if (secure) {
                if (hostName.startsWith('https://')) {
                    hostName = hostName.slice(0, 8);
                }
                default_port = '443';
            }
        } else if (hostName.startsWith('https://')) {
            hostName = hostName.slice(0, 8);
            default_port = '443';
        }

        if (hostName.startsWith('www.')) {
            hostName = hostName.slice(0, 4);
        }

        const index = hostName.indexOf('/');
        if (index > 0) {
            hostName = hostName.slice(index);
        }

        // определяем порт
        const parts = hostName.split(':');
        const hostNameOrIP = parts[0];
        const port = parts.length > 1 ? parts[1] : default_port;

        // TODO кажется ненужно, перформанс немного подъедает
        // let regex = new RegExp("^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$");
        // if (!regex.test(hostNameOrIP))
        // {
        //    try
        //    {
        //        console.log("Host Name Or IP");
        //        ////преобразуем символьное имя в IP
        //        //let entry = Dns.Resolve(hostNameOrIP);
        //        //if (entry.AddressList.length > 0)
        //        //    hostNameOrIP = entry.AddressList[0].ToString();
        //    }
        //    catch (ex)
        //    {
        //        //Utils.log(ex);
        //    }
        // }

        return hostNameOrIP + ':' + port;
    }

    public async AccountOperationRequest (data): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.AccountOperationRequestMessage();

            message.setAccountId(data.accountId);
            message.setCounterId(data.counterId);
            message.setAmount(data.amount);
            message.setComment(data.comment);
            message.setOperationType(data.operationType, me.serverMaxFieldNumber);

            if (data.assetId) {
                message.assetId(data.assetId);
            }

            const rqi = message.getSequance();
            message.setRequestId(rqi);
            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        }).then(function (msg) {
            return msg;
        });
    }

    // #region NFL - NonFixedList

    public async GetInstrumentList (patern, exchangeIDs, instrumentTypes, aliasLanguage, isOldLookupType): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.NonFixedInstumentListRequest(null, me.serverMaxFieldNumber, aliasLanguage);

            message.setFilterName(patern);
            message.setExchangeIDs(exchangeIDs);
            message.setInstrumentTypes(instrumentTypes);
            message.setSymbolLookupType(isOldLookupType); // пока сервер не обновился нельзя добавлять данное поле иначе ломается запрос

            const rqi = message.getSequance();
            message.setRequestId(rqi);

            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        });
    }

    public async GetOptionsList (patern, exchangeIDs, instrumentTypes, aliasLanguage): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.NonFixedInstumentListUnderlierRequest(null, me.serverMaxFieldNumber, aliasLanguage);

            message.setFilterName(patern);
            message.setExchangeIDs(exchangeIDs);
            message.setInstrumentTypes(instrumentTypes);

            const rqi = message.getSequance();
            message.setRequestId(rqi);

            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        });
    }

    public async GetYieldRate (instrumentID, routeID): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.YTMRequestMessage();
            message.setInstrumentId(instrumentID);
            message.setRouteId(routeID);

            const rqi = message.getSequance();
            message.setRequestId(rqi);
            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        });
    }

    public SendExerciseRequest (positionID, status): void {
        const message = new vendorMessage.ExerciseRequestMessage();

        message.setPositionID(positionID);
        message.setStatus(status);

        this.SendMessage(message);
    }

    public SendInactivityPeriodState (msgText): void {
        const message = new vendorMessage.InformationMessage();
        message.setMsgType('info');
        message.setContent([JSON.stringify({ info: msgText })]);
        message.setTimeSpan(new Date());
        this.SendMessage(message);
    }

    public SendLogsWhenSettingsWasChanged (propsForLog): void {
        const message = new vendorMessage.InformationMessage();
        message.setMsgTypeId(InformationMessageType.Settings);
        message.setContent([JSON.stringify(propsForLog)]);
        message.setTimeSpan(new Date()); // #111674
        this.SendMessage(message);
    }

    public CreateMarginGroup (parameters: IMarginRequestParameters, operationMode: number, marginMode: number): vendorGroup.MarginGroup {
        const group = new vendorGroup.MarginGroup();

        group.setAccountId(parameters.accountId);
        group.setTradableInstrumentID(parameters.instrumentID);
        group.setRouteId(parameters.routeID);
        group.setAmount(parameters.amount);

        if (isValidNumber(parameters.price)) {
            group.setPrice(parameters.price);
        }

        if (isValidNumber(parameters.stopPrice)) {
            group.setStopPrice(parameters.stopPrice);
        }

        // #104724 risks&fees инфо может приходить и без orderType
        if (!isNullOrUndefined(parameters.orderType)) {
            group.setOrderType(parameters.orderType);
        }

        group.setOperationMode(operationMode);
        group.setMarginMode(marginMode);
        group.setProductType(parameters.productType);

        if (!isNullOrUndefined(parameters.leverage)) {
            group.setLeverage(parameters.leverage);
        }

        return group;
    }

    public async SendMarginRequest (parameters: IMarginRequestParameters): Promise<directMessage.DirectMarginMessage[]> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.MarginRequestMessage(); ;

            const group1 = me.CreateMarginGroup(parameters, 1, 7);
            const group2 = me.CreateMarginGroup(parameters, 2, 7);

            message.addGroup(group1);
            message.addGroup(group2);

            const rqi = message.getSequance();
            message.setRequestId(rqi);
            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        });
    }

    public async SendMultiMarginRequest (parameters: IMarginRequestParameters[], type: number = 0): Promise<any> {
        return await new Promise((resolve, reject) => {
            const message = new vendorMessage.MarginRequestMessage();
            message.setType(type);

            for (let i = 0; i < parameters.length; i++) {
                const parmeters = parameters[i];
                message.addGroup(this.CreateMarginGroup(parmeters, parmeters.operation, 3));
            }
            const rqi = message.getSequance();
            message.setRequestId(rqi);
            this.WrappersHandlers[rqi] = resolve;
            this.SendMessage(message);
        });
    }

    public async SendPortfolioStatisticsRequest (accountId, type, startTime, endTime, routeId, instrumentTradableID): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.PortfolioStatisticsRequest();

            message.setAccountId(accountId);
            message.setType(type);
            message.setStartTime(startTime);
            message.setEndTime(endTime);
            message.setRouteId(routeId);
            message.setInstrumentTradableId(instrumentTradableID);

            const rqi = message.getSequance();
            message.setRequestId(rqi);
            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        });
    }

    public SendNewsSubscribeMessage (routeIDArr, subscribeType: SubscribeType): void {
        const message = new vendorMessage.NewsSubscribeMessage();
        message.setSubscriptionAction(subscribeType);

        if (routeIDArr) {
            for (let i = 0; i < routeIDArr.length; i++) {
                message.addRouteId(routeIDArr[i]);
            }
        }

        this.SendMessage(message);
    }

    public async SendNewsRequestMessage (routeID, number, startTime, endTime): Promise<any> {
        return await new Promise((resolve, reject) => {
            const message = new vendorMessage.NewsRequestMessage();

            message.setRouteId(routeID);
            message.setNumber(number);
            message.setStartTime(startTime);
            message.setEndTime(endTime);

            message.setSymbol('');
            message.setSource('');
            message.setNewsCategory('');
            message.setPriority(0);
            message.setTheme('');
            message.setIsUpperCaseSearch(true);
            message.setIsContentSearch(false);
            message.setIsEventSearch(false);

            const rqi = message.getSequance();
            message.setRequestId(rqi);
            this.WrappersHandlers[rqi] = resolve;

            this.SendMessage(message);
        }).then((msg: any) => {
            return msg.length ? msg[0] : msg;
        });
    }

    public async SendCustomListRequest (userID, listType, timeout = 3000): Promise<any> {
        const message = new vendorMessage.CustomListRequestMessage();

        message.setUserId(userID);
        message.setListType(listType);

        const rqi = message.getSequance();
        message.setRequestId(rqi);

        const resArray = [];

        const responsePromise = new Promise((resolve, _reject) => {
            this.WrappersHandlers[rqi] = resolve;
            this.SendMessage(message);
        }).then(async (msg) => {
            return await this.SendCustomListResponseCollect(msg, rqi, resArray);
        });

        const timeoutPromise = new Promise((_resolve, reject) =>
            setTimeout(() => { reject(new Error('Request timed out')); }, timeout)
        );

        return await Promise.race([responsePromise, timeoutPromise]);
    }

    public async SendCustomListResponseCollect (msg, rqi, resArray: any[]): Promise<any> {
        const m = msg[0];
        resArray.push(m);
        if (m.ResponseEnd === true) {
            return resArray;
        }

        return await new Promise((resolve, _reject) => {
            this.WrappersHandlers[rqi] = resolve;
        }).then(async (newMsg) => {
            return await this.SendCustomListResponseCollect(newMsg, rqi, resArray);
        });
    }

    public SendPortfolioModelRequest (): void {
        const message = new vendorMessage.PortfolioModelRequest();
        this.SendMessage(message);
    }

    public async AlgorithmSubscribeMessage (subscribe, algorithmId): Promise<void> {
        const msg = new vendorMessage.AlgorithmSubscribeMessage();
        msg.setAlgorithmId(algorithmId);
        msg.setSubscribeAction(subscribe);

        const rqi = msg.getSequance();
        msg.setRequestId(rqi);

        const me = this;
        const sub = subscribe;
        const algID = algorithmId;
        await new Promise(function (resolve, reject) {
            me.WrappersHandlers[rqi] = resolve;
            me.SendMessage(msg);
        }).then(function (msgArr: any) {
            if (!msgArr) {
                return;
            }

            const msg = msgArr.length ? msgArr[0] : msgArr;
            if (msg.BusinessRejectCode == vendorMessage.BusinessRejectMessage.REQUEST_CODE_IS_NOT_SUPPORTED) // https://tp.traderevolution.com/entity/109058 COMMENT
            {
                // ????
                // Connection.vendor.AlgorithmSubscribeMessage(sub, algID, false);
            }
        });
    }

    public SendTradingSignalRequest (): void {
        const message = new vendorMessage.TradingSignalRequestMessage();
        this.SendMessage(message);
    }

    public SendTradingSignalSeenRequest (tradingSignalId: number): void {
        const message = new vendorMessage.TradingSignalSeenRequestMessage();
        message.setTradingSignalId(tradingSignalId);
        this.SendMessage(message);
    }

    public SendSubscriptionStrategyRequest (tradingSystemId: number): void {
        const message = new vendorMessage.SubscriptionStrategyRequestMessage();
        message.setTradingSystemId(tradingSystemId);
        message.setTradingStrategySubscriptionStatus(StrategySubscriptionStatus.Accepted);
        this.SendMessage(message);
    }

    public SendSnapshotRequestMessage (tradableID: number, routeID: number): void {
        const message = new vendorMessage.SnapshotRequestMessage();
        message.setInstrumentTradableID(tradableID);
        message.setRouteId(routeID);
        this.SendMessage(message);
    }

    public async GetBrandingRules (brandingKey: string): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.BrandingRulesRequestMessage();
            message.setBrandingKey(brandingKey);
            ProcessorWorker.AddProtocolVersonAndMaxField(message);
            const rqi = message.getSequance();
            message.setRequestId(rqi);
            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        });
    }

    public SendBrokerMessageReport (inputMessage, type: TextMessageType): void // закоментовано, згодом відновлено  згідно з #110904 (для urgent BM було додано логіювання у #84587, судячи з усього зараз ця логіка вже є застарілою і непотрібною)
    {
        const message = new vendorMessage.BrokerMessage();
        message.setType(type);
        message.setSenderId(inputMessage.TargetID);
        message.setTargetId(inputMessage.SenderID);
        message.setId(inputMessage.ID);
        message.setDate(inputMessage.Date);

        const msgText = '<status refobj="message"' + ' refid="' + inputMessage.ID + '"><field name="reacted" value="true"/></status>';
        message.setText(msgText);

        this.SendMessage(message);
    }

    public async SendBrokerResponseMessage (brokerMsgID: number, value: string, userID: number, userName: string, responseType: BrokerMessageResponseType): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.BrokerResponseMessage();

            message.setId(brokerMsgID);
            message.setValue(value);
            message.setUserID(userID);
            message.setUsername(userName);
            if (responseType !== null) {
                message.setResponseType(responseType);
            }

            ProcessorWorker.AddProtocolVersonAndMaxField(message);

            const rqi = message.getSequance();
            message.setRequestId(rqi);

            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        });
    }

    public async SendBrokerMessageHistoryRequest (userID: number): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.BMHistoryRequestMessage();

            message.setUserID(userID);

            ProcessorWorker.AddProtocolVersonAndMaxField(message);

            const rqi = message.getSequance();
            message.setRequestId(rqi);

            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        }).then(function (msg) {
            return msg;
        });
    }

    public async GetNonFixedInstrumentStrikes (ContractID: number): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.NonFixedInstumentStrikesRequest();

            message.setContractID(ContractID);

            const rqi = message.getSequance();
            message.setRequestId(rqi);

            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        });
    }

    public async getInstrumentByNameNFL (instrumentName: string): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.NonFixedNewInstumentRequest();

            message.setInstrumentName(instrumentName);

            const rqi = message.getSequance();
            message.setRequestId(rqi);

            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        }).then(function (msg: any) {
            if (!msg.length) {
                return null;
            }

            if (msg[0].Code !== Message.CODE_INSTRUMENT) {
                return null;
            }

            const message = msg[0];
            if (message.InstrType === InstrumentTypes.OPTIONS && message.ForwardBaseInstrumentID !== null && message.ForwardBaseInstrumentID !== -1) {
                return me.getInstrumentById(message.ForwardBaseInstrumentID).then(function (msgBaseID: any) {
                    return msgBaseID.concat(msg);
                });
            }

            return msg;
        });
    }

    public async getInstrumentByInstrumentTradableID_NFL (InstrumentTradableID: number): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.NonFixedNewInstumentRequest();

            message.setInstrumentTradableID(InstrumentTradableID);

            const rqi = message.getSequance();
            message.setRequestId(rqi);

            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        }).then(function (msg: any) {
            if (!msg.length) {
                return null;
            }

            if (msg[0].Code !== Message.CODE_INSTRUMENT) {
                return null;
            }

            const message = msg[0];
            if (message.InstrType === InstrumentTypes.OPTIONS && message.ForwardBaseInstrumentID !== null && message.ForwardBaseInstrumentID !== -1) {
                return me.getInstrumentById(message.ForwardBaseInstrumentID).then(function (msgBaseID: any) {
                    return msgBaseID.concat(msg);
                });
            }

            return msg;
        });
    }

    public async GetNonFixedInstrumentListByAssetName (AsssetName: string): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.NonFixedInstumentListRequest(null, me.serverMaxFieldNumber);

            message.setAssetName(AsssetName);

            const rqi = message.getSequance();
            message.setRequestId(rqi);

            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        });
    }

    public async getInstrumentById (instrumentID: number): Promise<any> {
        return await new Promise((resolve, reject) => {
            const message = new vendorMessage.NonFixedNewInstumentRequest();
            message.setInstrumentID(instrumentID);

            const rqi = message.getSequance();
            message.setRequestId(rqi);

            this.WrappersHandlers[rqi] = resolve;
            this.SendMessage(message);
        });
    }

    public GetInstrument (): any {

    }

    // #endregion NFL

    public async SendPing (): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.PingMessage();
            const rqi = message.getSequance();
            message.setRequestId(rqi);
            me.WrappersHandlers[rqi] = resolve;
            me.SendMessage(message);
        });
    }

    public async SendRefreshTokenMsg (token: string): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.RefreshTokenMesage();
            const rqi = message.getSequance();
            message.setRequestId(rqi);
            message.SetRefreshToken(token);
            ProcessorWorker.AddProtocolVersonAndMaxField(message);
            me.WrappersHandlers[rqi] = resolve;
            me.SendMessage(message);
        })
            .then(function (msg) {
                const msg0 = msg[0];
                if (!msg0) {
                    return null;
                }

                if (msg0.Code === Message.CODE_PFIX_REFRESH_TOKEN) {
                    const TokenDataObj: any = {};
                    TokenDataObj.Valid = true;
                    TokenDataObj.StreamOpened = true;
                    TokenDataObj.accessToken = msg0.GetAccessToken();
                    TokenDataObj.accessTokenLifeTime = msg0.GetAccessTokenLifeTime();
                    TokenDataObj.refreshToken = msg0.GetRefreshToken();
                    TokenDataObj.refreshTokenLifeTime = msg0.GetRefreshTokenLifeTime();
                    return TokenDataObj;
                }

                return { Valid: false, StreamOpened: true };
            });
    }

    public async ChangePassword (curPwd: string, newPwd: string, UserID: number, verificationPassword: string, accessToken: string): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.ChangePasswordMessage();
            message.setUserId(UserID);
            message.setPassword(curPwd);
            message.setNewPassword(newPwd);

            if (accessToken) {
                message.setAccessToken(accessToken);
            } // #92068

            ProcessorWorker.AddProtocolVersonAndMaxField(message);

            if (verificationPassword) {
                message.setVerificationPassword(verificationPassword);
            }

            const rqi = message.getSequance();
            message.setRequestId(rqi);
            me.WrappersHandlers[rqi] = resolve;
            me.SendMessage(message);
        }).then(function (message) {
            if (!message) {
                return null;
            }

            const mess = message[0];
            if (mess.IsDirect && mess.Data) {
                return { Data: mess.Data, RejectCode: mess.BusinessRejectCode, status: 10000, errText: mess.Data[0][1] };
            }

            const status = mess.getFieldValue(FieldsFactory.FIELD_CHANGE_PWD_STATUS);
            const minLength = mess.getFieldValue(FieldsFactory.FIELD_MIN_PASSWORD_LENGTH);
            const maxLength = mess.getFieldValue(FieldsFactory.FIELD_MAX_PASSWORD_LENGTH);
            if (status !== null) {
                return { Status: status, MinLength: minLength, MaxLength: maxLength };
            }

            return null;
        }).catch(function () {
            const ex = new CustomErrorClass('ProcessorWorker changePassword error', 'ProcessorWorker.ChangePassword', 'ChangePassword -> Promise');
            ErrorInformationStorage.GetException(ex);

            return null;
        });
    }

    public async ChangeTradingPassword (curPwd: string, newPwd: string): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.ChangeTradingPasswordMessage();
            message.setPassword(curPwd);
            message.setNewPassword(newPwd);

            ProcessorWorker.AddProtocolVersonAndMaxField(message);

            const rqi = message.getSequance();
            message.setRequestId(rqi);
            me.WrappersHandlers[rqi] = resolve;
            me.SendMessage(message);
        }).then(function (message) {
            if (!message) {
                return null;
            }

            const mess = message[0];
            if (mess.IsDirect && mess.Data) {
                return { Data: mess.Data, RejectCode: mess.BusinessRejectCode, status: 10000, errText: mess.Data[0][1] };
            }

            const status = mess.getFieldValue(FieldsFactory.FIELD_CHANGE_PWD_STATUS);
            const minLength = mess.getFieldValue(FieldsFactory.FIELD_MIN_PASSWORD_LENGTH);
            const maxLength = mess.getFieldValue(FieldsFactory.FIELD_MAX_PASSWORD_LENGTH);
            if (status !== null) {
                return { Status: status, MinLength: minLength, MaxLength: maxLength };
            }

            return null;
        }).catch(function () {
            const ex = new CustomErrorClass('ProcessorWorker changeTradingPassword error', 'ProcessorWorker.ChangeTradingPassword', 'ChangeTradingPassword -> Promise');
            ErrorInformationStorage.GetException(ex);

            return null;
        });
    }

    public SendInformationMessage (data): void {
        const message = new vendorMessage.InformationMessage();
        message.setMsgType(data.msgType);
        message.setContent([JSON.stringify(data.content)]);
        message.setTimeSpan(data.timestamp);

        this.SendMessage(message);
    }
    // #region Trading

    public async reversePosition (data): Promise<void> {
        this.placeOrderGroups(this.createReversePositionGroups(data), data.placedFrom);
    }

    public createReversePositionGroups (data): vendorGroup.OrderGroup[] {
        const closeInitOrderGroup = this.createOrderGroup(data, data.placedFrom);
        closeInitOrderGroup.setOpen(false);

        const reversedOrderGroup = this.createOrderGroup(data, data.placedFrom);
        reversedOrderGroup.setOperationType(data.side === OperationType.Buy ? OperationType.Sell : OperationType.Buy);

        reversedOrderGroup.setOrderIdToLink(closeInitOrderGroup.getClientOrderId());
        reversedOrderGroup.setOrderLinkType(OrderLinkType.ORDER_LINK_EXECUTE_AFTER);

        const orderIdGroup = new vendorGroup.OrderIdGroup();
        orderIdGroup.setOrderId(BigInt(data.position.PositionId));
        closeInitOrderGroup.AddGroup(orderIdGroup);

        this.setOrderGroupPrices(data, OrderType.Market, closeInitOrderGroup);
        this.setOrderGroupPrices(data, OrderType.Market, reversedOrderGroup);

        return [closeInitOrderGroup, reversedOrderGroup];
    }

    // TODO. Answer. Promise.
    // Refactor.
    public modifyPosition (data): any {
        let changed = false;
        const newSLTP = data.parameterDict.sltp;
        const position = data.position;

        const positionHasSL = !!position.SLOrder;
        const positionHasTP = !!position.TPOrder;

        const setNewSL = !isNaN(newSLTP.StopLossPriceValue);
        const setNewTP = !isNaN(newSLTP.TakeProfitPriceValue);
        const setTrailingStop = newSLTP.StopLossPriceType === SlTpPriceType.TrOffset;

        // TODO. Ugly. Details are at the top OrderEditBase
        const canEditSLOrTrailingStop = data.canEditSLOrTrailingStop;
        const canEditTP = data.canEditTP;

        const promises = [];

        // Cancel SL.
        if (canEditSLOrTrailingStop && positionHasSL && !setNewSL) {
            this.cancelOrder(position.SLOrder, data.placedFrom);
            changed = changed || true;
        }

        // Cancel TP.
        if (canEditTP && positionHasTP && !setNewTP) {
            this.cancelOrder(position.TPOrder, data.placedFrom);
            changed = changed || true;
        }

        const orderTypes = OrderType;
        const stopLossLimitValue = newSLTP.StopLossLimitPriceValue;
        const needSetSLL = stopLossLimitValue != null;
        const stopOrderType = needSetSLL ? orderTypes.StopLimit : orderTypes.Stop; // #91413

        // Place SL.
        if (canEditSLOrTrailingStop && setNewSL && !positionHasSL) {
            const slData = this.createDataForPlacingPositionSLTPOrder(
                data,
                setTrailingStop ? orderTypes.TrailingStop : stopOrderType);

            slData.parameterDict[setTrailingStop ? OrderEditBaseUtils.TRAILING_STOP_PARAM : OrderEditBaseUtils.STOP_PRICE_PARAM] =
            newSLTP.StopLossPriceValue;

            if (needSetSLL) {
                slData.parameterDict[OrderEditBaseUtils.LIMIT_PRICE_PARAM] = stopLossLimitValue;
            }

            if (newSLTP.RealTrStopPrice) {
                slData.parameterDict.RealTrStopPrice = newSLTP.RealTrStopPrice;
            }

            slData.parameterDict[OrderEditBaseUtils.SLTP_TRIGGER] = SLTPTrigger.GetRawValue(newSLTP.SLTPTriggerShortValue, data.side, true);

            this.placeSLTPOrderForPosition(slData, position, data.placedFrom);

            changed = changed || true;
        }

        // Place TP.
        if (canEditTP && setNewTP && !positionHasTP) {
            const tpData = this.createDataForPlacingPositionSLTPOrder(
                data,
                orderTypes.Limit);

            tpData.parameterDict.limitPrice = newSLTP.TakeProfitPriceValue;
            tpData.parameterDict[OrderEditBaseUtils.SLTP_TRIGGER] = SLTPTrigger.GetRawValue(newSLTP.SLTPTriggerShortValue, data.side, false);

            this.placeSLTPOrderForPosition(tpData, position, data.placedFrom);

            changed = changed || true;
        }

        //  Replace SL.
        if (canEditSLOrTrailingStop && setNewSL && positionHasSL && data.isSLchanged) {
            const slData = this.createDataForReplacingPositionSLTPOrder(position.SLOrder);
            slData.orderTypeId = setTrailingStop
                ? orderTypes.TrailingStop
                : stopOrderType;

            slData.parameterDict[setTrailingStop ? OrderEditBaseUtils.TRAILING_STOP_PARAM : OrderEditBaseUtils.STOP_PRICE_PARAM] =
            newSLTP.StopLossPriceValue;

            if (needSetSLL) {
                slData.parameterDict[OrderEditBaseUtils.LIMIT_PRICE_PARAM] = stopLossLimitValue;
            }

            if (newSLTP.RealTrStopPrice) {
                slData.parameterDict.RealTrStopPrice = newSLTP.RealTrStopPrice;
            }

            slData.placedFrom = data.placedFrom;
            promises.push(this.replaceOrder(slData));
        }

        //  Replace TP.
        if (canEditTP && setNewTP && positionHasTP && data.isTPchanged) {
            const tpData = this.createDataForReplacingPositionSLTPOrder(position.TPOrder);
            tpData.orderTypeId = orderTypes.Limit;
            tpData.parameterDict.limitPrice = newSLTP.TakeProfitPriceValue;

            tpData.placedFrom = data.placedFrom;
            promises.push(this.replaceOrder(tpData));
        }

        if (promises.length > 0) {
            return Promise.all(promises).then((results) => {
                for (const r of results) {
                    if (!r) {
                        return false;
                    }
                }
                return true;
            });
        } else {
            return changed || data.UseSkipNoChange;
        }
    }

    // TODO. Refactor.
    // Метод, который позволяет определить доступный TIF для закрывающих ордеров
    public static getDefaultSLTPValidity (order: Order, orderType: OrderType): TIF {
        const tifEnum = OrderTif;

        if (!order) {
            return new TIF(tifEnum.GTC);
        }

        const route = order.DataCache.getRouteByName(order.Route);
        if (!route) {
            return new TIF(tifEnum.GTC);
        }

        if (route.IsAllowableTif(tifEnum.GTC, orderType)) {
            return new TIF(tifEnum.GTC);
        }

        // #37958 - По умолчанию на СЛ/ТП всегда ставим GTC,
        // если установить GTC невозможно, ставим для sl/tp ТИФ ордера
        if (route.IsAllowableTif(order.TimeInForce, orderType)) {
            return new TIF(order.TimeInForce);
        }

        if (route.IsAllowableTif(tifEnum.Day, orderType)) {
            return new TIF(tifEnum.Day);
        }

        if (route.IsAllowableTif(tifEnum.IOC, orderType)) {
            return new TIF(tifEnum.IOC);
        }

        if (route.IsAllowableTif(tifEnum.FOK, orderType)) {
            return new TIF(tifEnum.FOK);
        }

        if (route.IsAllowableTif(tifEnum.GTD, orderType)) {
            return new TIF(tifEnum.GTD, new Date(order.ExpireAt));
        }

        return new TIF(tifEnum.GTC);
    }

    // TODO. Refactor. Ugly.
    public createDataForPlacingPositionSLTPOrder (positionModifyData, sltpOrderType): OrderEditRequestData {
        const position = positionModifyData.position;
        const sltpSide =
        position.BuySell === OperationType.Buy
            ? OperationType.Sell
            : OperationType.Buy;

        const data = new OrderEditRequestData();
        data.orderTypeId = sltpOrderType;
        data.instrument = positionModifyData.instrument;
        data.account = positionModifyData.account;
        data.quote = positionModifyData.quote;
        data.side = sltpSide;
        data.productType = positionModifyData.productType;
        // TODO. Ugly.
        data.tif = ProcessorWorker.getDefaultSLTPValidity(position, sltpOrderType);

        return data;
    }

    // TODO. Refactor. Ugly.
    public createDataForReplacingPositionSLTPOrder (order: Order): OrderEditRequestData {
        const data = new OrderEditRequestData();
        data.instrument = order.Instrument;
        data.account = order.Account;
        data.side = order.BuySell;
        // TODO. Ugly. Remove?
        data.tif = new TIF(order.TimeInForce, new Date(order.ExpireAt));
        // TODO. Ugly. Remove?
        data.order = order;

        return data;
    }

    // TODO. Refactor. Ugly.
    public placeSLTPOrderForPosition (sltpData, position: Position, placedFrom): void {
        const sltpGroup = this.createOrderGroup(sltpData, placedFrom);
        if (!sltpGroup) {
            return;
        }

        sltpGroup.setOpen(false);
        sltpGroup.setBoundTo(-1);
        this.setOrderGroupPrices(sltpData, sltpData.orderTypeId, sltpGroup);

        const boundToPositionGroup = new vendorGroup.OrderIdGroup();
        boundToPositionGroup.setOrderId(position.PositionId);
        sltpGroup.AddGroup(boundToPositionGroup);

        this.placeOrderGroups([sltpGroup], placedFrom);
    }

    // TODO. Answer. Promise.
    public placeOrder (data): void {
        let orderGroups = null;

        const ordTypes = OrderType;
        const ordType = data.orderTypeId;

        if (ordType === ordTypes.OCO) {
            orderGroups = this.createOCOGroups(data);
        } else {
            const orderGroup = this.createOrderGroup(data, data.placedFrom);
            if (!orderGroup) {
                return;
            }

            this.setOrderGroupPrices(data, ordType, orderGroup);
            orderGroups = [orderGroup];
        }

        this.placeOrderGroups(orderGroups, data.placedFrom);
    }

    public async placeOrderAsync (data): Promise<any> {
        const orderGroups = this.createPlaceOrderGroups(data);
        if (orderGroups === null) { return await Promise.reject(); }
        return await this.placeOrderGroupsAsync(orderGroups, data.placedFrom, false);
    }

    private createPlaceOrderGroups (data): vendorGroup.OrderGroup[] {
        let orderGroups = null;
        const ordType = data.orderTypeId;

        if (ordType === OrderType.OCO) {
            orderGroups = this.createOCOGroups(data);
        } else {
            const orderGroup = this.createOrderGroup(data, data.placedFrom);
            if (!orderGroup) {
                return null;
            }

            this.setOrderGroupPrices(data, ordType, orderGroup);
            orderGroups = [orderGroup];
        }

        return orderGroups;
    }

    public async multiplyPlaceOrdersAsync (multiplyOrders: MultiplyPlaceOrderWrapperItem[], placedFrom: PlacedFrom): Promise<any> {
        const orderGroups = [];
        const requestData = multiplyOrders;
        for (const data of requestData) {
            const og = this.createPlaceOrderGroups(data.orderEditBase.getDataForRequest());
            if (og === null) { continue; }
            orderGroups.push(...og);
        }

        return await this.placeOrderGroupsAsync(orderGroups, placedFrom, true);
    }

    // TODO. Answer. Promise.
    public placeOrderGroups (orderGroups, placedFrom): void {
        const orderMessage = new vendorMessage.NewOrderMessage();
        orderMessage.setRequestId(orderMessage.getSequance());

        if (orderGroups) {
            for (let i = 0, len = orderGroups.length; i < len; i++) {
                orderMessage.FieldSet.AddGroup(orderGroups[i]);
            }
        }

        orderMessage.setPlacedFrom(placedFrom || PlacedFrom.WEB_OTHER);

        this.SendMessage(orderMessage);
    }

    public async placeOrderGroupsAsync (orderGroups: vendorGroup.OrderGroup[], placedFrom: PlacedFrom, isMultiply: boolean): Promise<any> {
        const orderMessage = new vendorMessage.NewOrderMessage();
        const reqId = orderMessage.getSequance();
        orderMessage.setRequestId(reqId);
        orderMessage.setPlacedFrom(placedFrom ?? PlacedFrom.WEB_OTHER);
        const waiters = [];
        if (orderGroups) {
            for (let i = 0, len = orderGroups.length; i < len; i++) {
                orderMessage.FieldSet.AddGroup(orderGroups[i]);
                const pr = new Promise((resolve) => {
                    this.WrappersHandlersTrading[orderGroups[i].getClientOrderId()] = resolve;
                });
                waiters.push(pr);
            }
        }

        const rejectCase = new Promise((resolve) => {
            this.WrappersHandlers[reqId] = resolve;
        });

        this.SendMessage(orderMessage);
        const res = await Promise.race([Promise.all(waiters), rejectCase]);

        if (Array.isArray(res)) {
            for (const response of res) { this.Notify(response); }
        }
        if (isMultiply) { return res; }
        const response = res[0] ?? res;
        return response;
    }

    // TODO. Ugly. Details are in OCOOrderEdit
    // TODO. Doesn't support StopLimit orders.
    // TODO. Code duplication.
    public createOCOGroups (data): vendorGroup.OrderGroup[] | null {
        const ordTypes = OrderType;

        // TODO. Ewww. UGLY.
        const ocoCustomData = data.ocoCustomOrdersData;

        const firstOrderSide = ocoCustomData ? ocoCustomData.firstOrderSide : data.side;
        const secondOrderSide = ocoCustomData ? ocoCustomData.secondOrderSide : data.side;

        const firstOrderTif = ocoCustomData ? ocoCustomData.firstOrderTif : data.tif;
        const secondOrderTif = ocoCustomData ? ocoCustomData.secondOrderTif : data.tif;

        const firstOrderType = ocoCustomData ? ocoCustomData.firstOrderType : ordTypes.Limit;
        const secondOrderType = ocoCustomData ? ocoCustomData.secondOrderType : (data.useStopLimitInsteadStop ? ordTypes.StopLimit : ordTypes.Stop);

        // TODO. Doesn't support StopLimit.
        const firstOrderPrice = data.parameterDict.limitPrice;
        const secondOrderPrice = data.parameterDict.stopPrice;

        // First order ugly data mod.
        data.side = firstOrderSide;
        data.tif = firstOrderTif;
        // TODO. UGLY.
        data.parameterDict.limitPrice = ocoCustomData?.firstOrderLimitPriceForStopLimit ? ocoCustomData.firstOrderLimitPriceForStopLimit : firstOrderPrice;
        data.parameterDict.stopPrice = firstOrderPrice;

        const firstOrderGroup = this.createOrderGroup(data, data.placedFrom);
        if (!firstOrderGroup) {
            return null;
        }

        firstOrderGroup.setOrderType(firstOrderType);
        this.setOrderGroupPrices(data, firstOrderType, firstOrderGroup);

        // Second order ugly data mod.
        data.side = secondOrderSide;
        data.tif = secondOrderTif;
        // TODO. UGLY.
        data.parameterDict.limitPrice = data.limitPriceForStopLimitOrder && secondOrderType === ordTypes.StopLimit ? data.limitPriceForStopLimitOrder : secondOrderPrice;
        if (ocoCustomData?.secondOrderLimitPriceForStopLimit) {
            data.parameterDict.limitPrice = ocoCustomData.secondOrderLimitPriceForStopLimit;
        }

        data.parameterDict.stopPrice = secondOrderPrice;

        const secondOrderGroup = this.createOrderGroup(data, data.placedFrom);
        if (!secondOrderGroup) {
            return null;
        }

        secondOrderGroup.setOrderType(secondOrderType);
        this.setOrderGroupPrices(data, secondOrderType, secondOrderGroup);

        secondOrderGroup.setOrderIdToLink(firstOrderGroup.getClientOrderId());
        secondOrderGroup.setOrderLinkType(OrderLinkType.ORDER_LINK_CANCEL);

        firstOrderGroup.setOrderIdToLink(secondOrderGroup.getClientOrderId());
        firstOrderGroup.setOrderLinkType(OrderLinkType.ORDER_LINK_CANCEL);

        return [firstOrderGroup, secondOrderGroup];
    }

    // TODO. Rename. Refactor.
    public createOrderGroup (data, placedFrom): vendorGroup.OrderGroup {
        if (PriceLimitValidation.SendRejectIfNotValidateLimits(data)) { // #111427
            return null;
        }

        const acc = data.account;
        const ins = data.instrument;

        const group = new vendorGroup.OrderGroup();
        // TODO. Instead of object.GetHashCode()
        group.setClientOrderId(MathUtils.Round(Math.random() * 1000000, 0).toString());
        group.setOrderType(data.orderTypeId);
        group.setAccountId(parseInt(acc.AcctNumber));
        group.setProductType(parseInt(data.productType));
        group.setInstrumentTradableID(ins.InstrumentTradableID);
        group.setRouteId(ins.getRoute());
        group.setOperationType(data.side);

        if (data.quantity) {
            group.setAmount(Quantity.toLots(data.quantity, ins));
        }

        if (data.disclosedQuantity) {
            const dQty = data.disclosedQuantity.value != null ? Quantity.toLots(data.disclosedQuantity, ins) : data.disclosedQuantity;
            group.setDisclosedQty(dQty);
        } else if (data.quantity) // подробнее почему так: https://tp.traderevolution.com/entity/105398
        {
            group.setDisclosedQty(Quantity.toLots(data.quantity, ins));
        }

        const tif = data.tif;
        group.setValidity(tif.type);
        if (tif.type === OrderTif.GTD) {
            group.setTIFExpireDate(tif.expirationTime);
        }

        if (data.leverageValue) {
            group.setLeverage(parseInt(data.leverageValue));
        }

        const orderParamDict = data.parameterDict;
        const sltp = orderParamDict.sltp;
        if (sltp) {
            if (!isNaN(sltp.StopLossPriceValue)) {
                group.setSLPriceType(sltp.StopLossPriceType);
                group.setSLPrice(sltp.StopLossPriceValue);

                if (sltp.RealTrStopPrice && !orderParamDict.RealTrStopPrice) {
                    group.setPrice(sltp.RealTrStopPrice);
                }

                if (sltp.SLTPTriggerShortValue !== null) {
                    group.setSLTriggerPrice(SLTPTrigger.GetRawValue(sltp.SLTPTriggerShortValue, data.side, true));
                }
            }

            if (sltp.StopLossLimitPriceValue != null) {
                group.setSLLimitPrice(sltp.StopLossLimitPriceValue);
            }

            if (!isNaN(sltp.TakeProfitPriceValue)) {
                group.setTPPriceType(sltp.TakeProfitPriceType);
                group.setTPPrice(sltp.TakeProfitPriceValue);

                if (sltp.SLTPTriggerShortValue !== null) {
                    group.setTPTriggerPrice(SLTPTrigger.GetRawValue(sltp.SLTPTriggerShortValue, data.side, false));
                }
            }
        }

        group.setOpen(true);
        group.setByBroker(false);
        group.setIsByStrategy(false);
        group.setPlacedFrom(placedFrom || PlacedFrom.WEB_OTHER);

        if (orderParamDict.RealTrStopPrice) {
            group.setPrice(orderParamDict.RealTrStopPrice);
        }

        if (data.comment) {
            group.setComment(data.comment);
        }

        return group;
    }

    public async replaceOrder (data): Promise<boolean> {
        const msg = this.createReplaceOrderMessage(data);
        if (!msg) return;

        const me = this;
        return await new Promise(function (resolve, reject) {
            const rqi = msg.getSequance();
            msg.setRequestId(rqi);

            me.WrappersHandlers[rqi] = resolve;
            me.WrappersHandlersTrading[msg.getOrderId().toString()] = resolve;
            me.SendMessage(msg);
        }).then(function (msg) {
            const m = msg[0] || msg;
            me.Notify(m);
            return m.Code !== Message.CODE_BUSINESS_REJECT_MESSAGE;
        });
    }

    // TODO. Ugly. Refactor. Promise?
    public createReplaceOrderMessage (data): vendorMessage.OrderReplaceMessage {
        if (PriceLimitValidation.SendRejectIfNotValidateLimits(data)) // #111427
        {
            return null;
        }

        const acc = data.account;
        const ins = data.instrument;
        const order = data.order;
        const orderType = data.orderTypeId;
        const orderParamDict = data.parameterDict;

        const msg = new vendorMessage.OrderReplaceMessage();

        msg.setOrderId(BigInt(order.OrderNumber));
        msg.setOrderType(orderType);
        msg.setOperationType(data.side);
        msg.setAccountId(parseInt(acc.AcctNumber));
        msg.setInstrumentTradableID(ins.InstrumentTradableID);
        msg.setRouteId(ins.getRoute());
        msg.setPlacedFrom(data.placedFrom || PlacedFrom.WEB_OTHER);

        if (data.quantity) {
            msg.setAmount(Quantity.toLots(data.quantity, ins));
        }

        if (data.disclosedQuantity) {
            msg.setDisclosedQty(Quantity.toLots(data.disclosedQuantity, ins));
        }

        const tif = data.tif;
        msg.setValidity(tif.type);
        if (tif.type === OrderTif.GTD) {
            msg.setTIFExpireDate(tif.expirationTime);
        }

        const orderTypes = OrderType;

        // Setting up prices.
        switch (orderType) {
        case orderTypes.TrailingStop:
            msg.setFieldValue(FieldsFactory.FIELD_TR_OFFSET, orderParamDict.trailingStop);
            if (orderParamDict.RealTrStopPrice) {
                msg.setPrice(orderParamDict.RealTrStopPrice);
            }
            break;
        case orderTypes.Stop:
            msg.setPrice(orderParamDict.stopPrice);
            break;
        case orderTypes.StopLimit:
            msg.setPrice(orderParamDict.limitPrice);
            msg.setStopPrice(orderParamDict.stopPrice);
            break;
        case orderTypes.Limit:
            msg.setPrice(orderParamDict.limitPrice);
            break;
        }

        // Setting up sl/tp.
        const sltp = orderParamDict.sltp;
        if (sltp) {
            msg.setFieldValue(FieldsFactory.FIELD_SL_PRICE_TYPE, sltp.StopLossPriceType);
            msg.setFieldValue(FieldsFactory.FIELD_SL_PRICE, sltp.StopLossPriceValue);
            msg.setFieldValue(FieldsFactory.FIELD_TP_PRICE_TYPE, sltp.TakeProfitPriceType);
            msg.setFieldValue(FieldsFactory.FIELD_TP_PRICE, sltp.TakeProfitPriceValue);
            if (sltp.StopLossLimitPriceValue != null && !isNaN(sltp.StopLossPriceValue)) {
                msg.setSLLimitPrice(sltp.StopLossLimitPriceValue === null ? NaN : sltp.StopLossLimitPriceValue);
            } // шлём на сервер NaN вместо null

            if (sltp.SLTPTriggerShortValue) {
                if (!isNaN(sltp.StopLossPriceValue) || sltp.StopLossLimitPriceValue != null) {
                    msg.setSLTriggerPrice(SLTPTrigger.GetRawValue(sltp.SLTPTriggerShortValue, data.side, true));
                }

                if (!isNaN(sltp.TakeProfitPriceValue)) {
                    msg.setTPTriggerPrice(SLTPTrigger.GetRawValue(sltp.SLTPTriggerShortValue, data.side, false));
                }
            }
        }

        let boundToVal = 'boundTo' in orderParamDict
            ? orderParamDict.boundTo
            : -1;

        if (boundToVal === NO_BIND_TO) {
            boundToVal = -1;
        }

        msg.setBoundTo(boundToVal);

        // TODO.
        const group = new vendorGroup.OrderGroup();
        group.setByBroker(false);
        msg.FieldSet.AddGroup(group);

        return msg;
    }

    // TODO. Rename. Refactor.
    public setOrderGroupPrices (data, orderType: OrderType, group): void {
        const orderParamDict = data.parameterDict;
        const ordTypes = OrderType;

        switch (orderType) {
        case ordTypes.Stop:
            group.setPrice(orderParamDict.stopPrice);
            group.setStopPrice(0);
            break;
        case ordTypes.StopLimit:
            group.setPrice(orderParamDict.limitPrice);
            group.setStopPrice(orderParamDict.stopPrice);
            break;
        case ordTypes.Limit:
            group.setPrice(orderParamDict.limitPrice);
            group.setStopPrice(0);
            break;
        case ordTypes.TrailingStop:
        // let linkPrice = 0
        // if (quote)
        //     linkPrice = buy
        //         ? quote.AskSpread_SP_Ins(DataCache.GetSpreadPlan(), ins)
        //         : quote.BidSpread_SP_Ins(DataCache.GetSpreadPlan(), ins)

            if (orderParamDict.RealTrStopPrice) {
                group.setPrice(orderParamDict.RealTrStopPrice);
            }

            // group.setPrice(linkPrice || 0)
            group.setTrailingOffset(orderParamDict.trailingStop);
            break;
        }

        const trigger = orderParamDict[OrderEditBaseUtils.SLTP_TRIGGER];
        if (trigger) {
            group.setSLTPTriggerPrice(trigger);
        }
    }

    // TODO. Answer. Promise.
    public cancelOrder (order: Order, placedFrom): void {
        if (!order) return;

        const message = new vendorMessage.CancelOrderMessage();
        message.setOrderId(BigInt(order.OrderNumber));
        message.setAccountId(order.Account.BstrAccount);
        message.setInstrumentTradableID(order.Instrument.InstrumentTradableID);
        message.setPlacedFrom(placedFrom || PlacedFrom.WEB_OTHER);

        // let fromName = GetCorrectFromName(ord.Account, fromName)
        // let isBrokerRequest = IsBrokerRequest(ord.Account, fromName);
        const isBrokerRequest = false;
        message.setIsBrokerRequest(isBrokerRequest);

        message.setRequestId(message.getSequance());

        this.SendMessage(message);
    }

    // TODO. Answer. Promise.
    public closePositions (closePositionData, placedFrom): void {
        const positions = closePositionData.positions;
        const tifsObj = closePositionData.closingOrderTifDict;
        const mutualPositionsIds = closePositionData.mutualPositionIDs;

        const msg = new vendorMessage.NewOrderMessage();

        for (const posID in positions) {
            this.addOrderGroupToClosePosition(msg, positions[posID], closePositionData.quantityToClose, tifsObj[posID], placedFrom, mutualPositionsIds);
        }

        msg.setRequestId(msg.getSequance());
        this.SendMessage(msg);
    }

    public addOrderGroupToClosePosition (msg, pos: Position, quantityToClose, tifObj, placedFrom: PlacedFrom, mutualPositionsIds): void {
        const acc = pos.Account;
        const ins = pos.Instrument;
        const quote = ins.LastQuote;

        if (mutualPositionsIds && mutualPositionsIds[0] != pos.PositionId) {
            return;
        }

        const group = new vendorGroup.OrderGroup();
        msg.addGroup(group);

        // TODO. Instead of object.GetHashCode()
        group.setClientOrderId(MathUtils.Round(Math.random() * 1000000, 0).toString());
        group.setInstrumentTradableID(ins.InstrumentTradableID);
        group.setAccountId(parseInt(acc.BstrAccount));
        group.setOperationType(pos.BuySell);
        group.setProductType(pos.ProductType);
        // group.setLeverage(pos.leverageValue)
        group.setBoundTo(-1);

        group.setValidity(tifObj.type);
        if (tifObj.type === OrderTif.GTD) {
            group.setTIFExpireDate(tifObj.expirationTime);
        }

        let price = 0;
        if (quote) {
            const sp = pos.DataCache.GetSpreadPlan(acc);
            price = pos.BuySell === OperationType.Buy
                ? quote.BidSpread_SP_Ins(sp, ins)
                : quote.AskSpread_SP_Ins(sp, ins);

            if (isNaN(price)) {
                price = 0;
            }
        }

        group.setPrice(price);
        group.setStopPrice(0);

        const lotsToClose = quantityToClose ? Quantity.toLots(quantityToClose, ins) : pos.Amount;
        group.setAmount(lotsToClose);

        group.setOrderType(OrderType.Market);
        // TODO.
        group.setByBroker(false);
        group.setOpen(false);
        group.setIsByStrategy(false);
        group.setRouteId(-1);
        group.setPlacedFrom(placedFrom || PlacedFrom.WEB_OTHER);

        const posList = mutualPositionsIds || [pos.OrderNumber];

        for (let i = 0, len = posList.length; i < len; i++) {
            const posId = BigInt(posList[i]);
            const orderIdGroup = new vendorGroup.OrderIdGroup();
            orderIdGroup.setOrderId(posId);
            group.AddGroup(orderIdGroup);
        }
    }

    // TODO. Answer. Promise.
    // TODO. Refactor. Move to modifyOrder()?
    public changeOrderToMarket (order: Order, placedFrom: PlacedFrom): void {
        const msg = new vendorMessage.OrderReplaceMessage();

        msg.setOrderType(OrderType.Market);
        msg.setOrderId(BigInt(order.OrderNumber));
        msg.setRequestId(msg.getSequance());
        msg.setPlacedFrom(placedFrom || PlacedFrom.WEB_OTHER);

        this.SendMessage(msg);
    }

    public async modifyPositionProductType (positionId, productType, quantity): Promise<void> {
        const msg = new vendorMessage.ModifyPositionMessage();

        msg.setPositionId(BigInt(positionId));
        msg.setProductType(parseInt(productType));
        const rqi = msg.getSequance();
        msg.setRequestId(rqi);
        msg.setQuantity(quantity);
        await new Promise(
            (resolve, reject) => {
                this.WrappersHandlers[rqi] = resolve;
                this.SendMessage(msg);
            }).then((msg: any) => {
            msg = msg[0];
            if (!msg) {
                return;
            }

            if (msg.IsDirect && msg.Code === Message.CODE_BUSINESS_REJECT_MESSAGE) {
                msg.Name = 'Product type modification rejected';
            }
            this.Notify(msg);
        });
    }

    public async sendTradePassword (pass, ExternalResourse): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const message = new vendorMessage.PfixOneTimePasswordMessage();

            const rqi = message.getSequance();
            message.setRequestId(rqi);

            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(message);
        }).then(function (msg: any) {
            msg = msg[0];

            const message = new vendorMessage.TradingUnlockMessage();

            if (ExternalResourse) {
                message.setExternalResourse(ExternalResourse);
            }

            const rqi = message.getSequance();
            message.setRequestId(rqi);

            let pr = null;
            const EncrType = msg.EncryptionType();
            if (EncrType !== EncrTypes.AES_GCM_NoPadding) {
                pr = ProXOREncryptionUtility.Encode(pass, msg.GetPassword())
                    .then((encrPass) => {
                        message.SetPassword(encrPass);
                        return true;
                    });
            } else {
                pr = EncodeKey_AES_GCM_NoPadding(pass, msg.GetPassword())
                    .then((encrPass) => {
                        message.SetPassword(encrPass);
                        return true;
                    });
            }
            return pr.then(async () => {
                return await new Promise(function (resolve, reject) {
                    me.WrappersHandlers[rqi] = resolve;
                    me.SendMessage(message);
                });
            });
        }).then(function (msg) {
            msg = msg[0];
            return msg.getTradingUnlocked();
        });
    }

    public async SendSOAPRequest (request): Promise<any> {
        const me = this;
        return await new Promise(function (resolve, reject) {
            const soapReqMsg = new vendorMessage.SOAPReqMessage();
            ProcessorWorker.AddProtocolVersonAndMaxField(soapReqMsg);
            soapReqMsg.setName('');
            soapReqMsg.setFieldValue(FieldsFactory.FIELD_MAX_PFIX_FIELD_INDEX, FieldsFactory.getInstance().MaxFieldNumber);

            if (request === null) {
                reject('Bad Arguments');
            }

            const keys = Object.keys(request);

            if (keys.length === 0) {
                reject('Bad Arguments');
            }

            for (let i = 0; i < keys.length; i++) {
                const parName = keys[i];
                const parValue = request[parName];
                const group = new vendorGroup.KeyValueAggregateGroup();
                group.setKey(parName);
                group.setValue(parValue);
                soapReqMsg.FieldSet.AddGroup(group);
            }

            const rqi = soapReqMsg.getSequance();
            soapReqMsg.setRequestId(rqi);

            me.WrappersHandlers[rqi] = resolve;

            me.SendMessage(soapReqMsg);
        })
            .then(function (msg: any) {
                msg = msg[0];
                const gr = msg.FieldSet.GetGroups(FieldsFactory.LINE_GROUP_NAME);
                if (!gr.length) {
                    return 'Bad Arguments';
                }

                return gr[0].GetValue(FieldsFactory.FIELD_TEXT);
            });
    }

    public SendVerifySessionMsg (sessId: string): void {
        const msg = new vendorMessage.TokenVerifyRequestMessage();
        msg.SetUserSessionId(sessId);
        ProcessorWorker.AddProtocolVersonAndMaxField(msg);
        this.SendMessage(msg);
    }

    public ProcessVerifySessionMsg (msg) {
        const answer = { IsExpired: true, Login: null, Opened: true };

        // if (msg.Code === Message.CODE_BUSINESS_REJECT_MESSAGE)
        if (msg.Code === Message.CODE_PFIX_TOKEN_VERIFY_RESPONSE) {
            answer.IsExpired = msg.IsExpired;
            answer.Login = msg.Login;
        }

        return answer;
    }

    public static async SendTokenVerifyMessage (url, isSSL, sessId, sert): Promise<any> {
        const myStream = ProcessorWorker.CreateMyObj(url, isSSL);

        return await myStream.OpenWS(sert).then(function (opened) {
            if (!opened) { return { IsExpired: true, Login: null, Opened: false }; }
            myStream.SendVerifySessionMsg(sessId);
            return myStream.MyPrommisseHandler;
        });
    // TODO
    // .CloseStream();
    }

    public static async GetSessionLessBrandingRules (url: string, isSSL: boolean, brandingKey: string, sert): Promise<{ Opened: boolean, Data: any } | { Opened: boolean }> {
        const myStream = ProcessorWorker.CreateMyObj(url, isSSL);

        return await myStream.OpenWS(sert).then(async function (opened) {
            if (!opened) {
                return { Opened: false };
            }

            return await myStream.GetBrandingRules(brandingKey).then(function (msg) {
                let data = null;
                if (msg) {
                    data = msg[0] || msg;
                }

                if (data.Code === Message.CODE_BUSINESS_REJECT_MESSAGE) {
                    data = null;
                }

                return { Opened: true, Data: data };
            });
        });

    // TODO
    // .CloseStream();
    }

    public static CreateMyObj (url: string, isSSL: boolean): ProcessorWorker {
        const stream = new ProcessorWorker(ProcessorWorker.MODE_AUTHENTICATION_REQUEST);
        stream.siteAddres = url;
        stream.isSSL = isSSL;
        stream.MyPrommisseHandler = new Promise(function (resolve, reject) {
            stream.OnError = reject;
            stream.OnNewMessageDelegate = function (msg) {
                resolve(stream.ProcessVerifySessionMsg(msg));
            };
        }).catch(function (errorResovingFunc) {
            errorResovingFunc(false);
        });
        return stream;
    }

    public SendAlertToServer (alertData): void {
        const isNew = alertData.AlertId === null;

        let msgToSend: vendorMessage.CreateAlertMessage | vendorMessage.ReplaceAlertMessage = null;
        if (isNew) {
            msgToSend = new vendorMessage.CreateAlertMessage();
        } else {
            msgToSend = new vendorMessage.ReplaceAlertMessage();
        }

        if (isNew) {

        }// немогут определиться
        // msgToSend.setUserId(alertData.Account.userPin)
        else {
            (msgToSend as vendorMessage.ReplaceAlertMessage).setAlertId(alertData.AlertId);
        }

        msgToSend.setAlertAccountId(alertData.Account.AcctNumber);
        msgToSend.setTradableInstrumentId(alertData.Instrument.InstrumentTradableID);
        msgToSend.setRouteId(alertData.Instrument.Route);
        msgToSend.setAlertType(alertData.AlertType);
        msgToSend.setAlertCondition(alertData.Condition);
        msgToSend.setAlertValue(alertData.Value);
        msgToSend.setAlertImportance(alertData.Importance);
        msgToSend.setAlertDate(alertData.Expiration);
        msgToSend.setAlertAfterExecute(alertData.AfterExecute);
        msgToSend.setAlertAction(alertData.Action);
        msgToSend.setAlertNotification(alertData.Notification);
        msgToSend.setAlertText(alertData.MessageText);

        if (alertData.leverageValue) {
            msgToSend.setAlertLeverage(alertData.leverageValue);
        }

        const data = alertData.OrderPlaceParametrs;
        if (data) {
            let orderGroups = null;
            const ordTypes = OrderType;
            const ordType = data.orderTypeId;

            if (ordType === ordTypes.OCO) {
                orderGroups = this.createOCOGroups(data);
            } else {
                const orderGroup = this.createOrderGroup(data, data.placedFrom);
                if (!orderGroup) {
                    return;
                }

                this.setOrderGroupPrices(data, ordType, orderGroup);
                orderGroups = [orderGroup];
            }

            for (let i = 0, len = orderGroups.length; i < len; i++) {
                msgToSend.FieldSet.AddGroup(orderGroups[i]);
            }
        }

        msgToSend.setFieldValue(FieldsFactory.FIELD_CLIENT_PANEL, PlacedFrom.WEB_ALERT);
        this.SendMessage(msgToSend);
    }

    public MakeAlertAction (alertActionInfo): void {
        const msg = new vendorMessage.ControlAlertCommandMessage();
        msg.setAlertId(alertActionInfo.AlertId);
        msg.setAlertCommand(alertActionInfo.Command);
        this.SendMessage(msg);
    }

    public async SendProductSubscriptionRequest (Data): Promise<void> {
        const pfixSubscribeRequestMsg = new vendorMessage.PFixEntitlementSubscriptionRequestMessage();
        pfixSubscribeRequestMsg.setProductId(Data.ProductId);
        pfixSubscribeRequestMsg.setRequestType(Data.RequestType);

        for (let i = 0; i < Data.EntitlmentDocuments.length; i++) {
            const doc = Data.EntitlmentDocuments[i];
            const docGroup = vendorGroup.EntitlementDocumentGroup.CreateDocument(doc.Id, doc.Name, doc.Content);
            pfixSubscribeRequestMsg.AddDocument(docGroup);
        }

        const rqi = pfixSubscribeRequestMsg.getSequance();
        pfixSubscribeRequestMsg.setRequestId(rqi);

        const me = this;
        await new Promise(function (resolve, reject) {
            me.WrappersHandlers[rqi] = resolve;
            me.SendMessage(pfixSubscribeRequestMsg);
        }).then(function (newMsg: any) {
            if (newMsg) {
                for (let i = 0; i < newMsg.length; i++) {
                    const msg = newMsg[i];
                    if (msg.Code === Message.CODE_BUSINESS_REJECT_MESSAGE) {
                        msg.ReqProductId = Data.ProductId;
                    }
                    me.Notify(msg);
                }
            }
        });
    }

    public async SendProductDocumentRequest (ProductId): Promise<any> {
        const pfixGetDocRequestMsg = new vendorMessage.PFixEntitlementProductDocumentRequestMessage();
        pfixGetDocRequestMsg.setProductId(ProductId);

        const rqi = pfixGetDocRequestMsg.getSequance();
        pfixGetDocRequestMsg.setRequestId(rqi);

        const me = this;
        return await new Promise(function (resolve, reject) {
            me.WrappersHandlers[rqi] = resolve;
            me.SendMessage(pfixGetDocRequestMsg);
        });
    }

    public async SendSubscriptionHistoryReques (from: Date, to: Date): Promise<any> {
        const pfixSubscriptionHistory = new vendorMessage.PfixEntitlementSubscriptionHistoryRequestMessage();
        pfixSubscriptionHistory.setFromUtc(from);
        pfixSubscriptionHistory.setToUtc(to);

        const rqi = pfixSubscriptionHistory.getSequance();
        pfixSubscriptionHistory.setRequestId(rqi);

        const me = this;
        const resArray = [];
        return await new Promise(function (resolve, reject) {
            me.WrappersHandlers[rqi] = resolve;
            me.SendMessage(pfixSubscriptionHistory);
        }).then(async function (msg) {
            return await me.SendSubscriptionHistoryResponseCollect(msg, rqi, resArray);
        });
    }

    public async SendSubscriptionHistoryResponseCollect (msg, rqi, resArray: any[]): Promise<any> {
        const m = msg[0];
        resArray.push(m);
        if (m.ResponseEnd) {
            return await Promise.resolve(resArray);
        }

        const me = this;
        return await new Promise(function (resolve, reject) {
            me.WrappersHandlers[rqi] = resolve;
        }).then(async function (newMsg) {
            return await me.SendSubscriptionHistoryResponseCollect(newMsg, rqi, resArray);
        });
    }

    public static AddProtocolVersonAndMaxField (msg): void {
        if (!msg?.setFieldValue) {
            return;
        }

        const protocolVerson = '1.13';
        msg.setFieldValue(FieldsFactory.FIELD_PROTOCOL_ID, protocolVerson);
        msg.setFieldValue(FieldsFactory.FIELD_MAX_PFIX_FIELD_INDEX, FieldsFactory.getInstance().MaxFieldNumber);

        const clientType = ClientType.getClientType();
        msg.setFieldValue(FieldsFactory.FIELD_CLIENT_TYPE, clientType);
    }

    // #endregion Trading

    public async SendPasswordRecoveryChangeRequest (data) {
        const recoveryReq = new vendorMessage.PasswordRecoveryChangeRequest();
        if (data.newPassword) {
            recoveryReq.setPassword(data.newPassword);
        }

        if (data.login) {
            recoveryReq.setLogin(data.login);
        }

        if (data.accessToken) {
            recoveryReq.setAccessToken(data.accessToken);
        } // #92068

        recoveryReq.setKey(data.key);
        ProcessorWorker.AddProtocolVersonAndMaxField(recoveryReq);

        const rqi = recoveryReq.getSequance();
        recoveryReq.setRequestId(rqi);
        return await new Promise((resolve, reject) => {
            this.WrappersHandlers[rqi] = resolve;
            this.SendMessage(recoveryReq);
        }).then(async (msg: any) => {
            msg = msg[0];
            if (msg.IsDirect) {
                return await Promise.resolve({ status: 10000, BusinessRejectCode: msg.BusinessRejectCode, errText: msg.Data[0][1] });
            } // years

            const status = msg.getStatus();
            const minLength = msg.getMinLength();
            const maxLength = msg.getFieldValue(FieldsFactory.FIELD_MAX_PASSWORD_LENGTH) || 128;

            return await Promise.resolve({ status, minLength, MaxLength: maxLength });
        });
    }

    public async SendRequestRecoveryPassword (data) {
        const recoveryReq = new vendorMessage.RequestRecoveryPassword();
        if (data.login) {
            recoveryReq.setLogin(data.login);
        }

        if (data.email) {
            recoveryReq.setEmail(data.email);
        }

        ProcessorWorker.AddProtocolVersonAndMaxField(recoveryReq);

        const rqi = recoveryReq.getSequance();
        recoveryReq.setRequestId(rqi);
        return await new Promise((resolve, reject) => {
            this.WrappersHandlers[rqi] = resolve;
            this.SendMessage(recoveryReq);
        }).then(async (msg: any) => {
            msg = msg[0];
            if (msg.IsDirect) {
                return await Promise.resolve({ status: 10000, BusinessRejectCode: msg.BusinessRejectCode, errText: msg.Data[0][1] });
            } // years

            const status = msg.getStatus();
            const minLength = msg.getMinLength();
            const maxLength = msg.getFieldValue(FieldsFactory.FIELD_MAX_PASSWORD_LENGTH) || 128;

            return await Promise.resolve({ status, minLength, MaxLength: maxLength });
        });
    }

    public async SendCloseAccountRequestMessage (): Promise<any> {
        return await new Promise((resolve, reject) => {
            const message = new vendorMessage.CloseAccountRequestMessage();
            const rqi = message.getSequance();

            message.setRequestId(rqi);
            this.WrappersHandlers[rqi] = resolve;

            this.SendMessage(message);
        }).then((msgArr) => {
            const msg = msgArr[0] || msgArr;
            this.Notify(msg);
            return msgArr;
        });
    }

    public async SendCloseAccountCancelRequestMessage (closeAccountRequestID: number): Promise<any> {
        return await new Promise((resolve, reject) => {
            const message = new vendorMessage.CloseAccountCancelRequestMessage();
            message.setCloseAccountRequestID(closeAccountRequestID);

            this.SendMessage(message);
        });
    }

    // #region Constants

    public static readonly CONNECTED = 0;
    public static readonly RECONNETING = 1;
    public static readonly DISCONNECTED = 2;

    public static readonly SOKET_MESSAGE_MODE_INFO = 0;
    public static readonly SOKET_MESSAGE_MODE_UPLOAD = 2;
    public static readonly SOKET_MESSAGE_MODE_DELETE = 3;
    public static readonly SOKET_MESSAGE_MODE_NEW_FORMAT = 4;

    public static readonly HistorySortOrder_Ascending = 0;
    public static readonly HistorySortOrder_Descending = 1;
    public static readonly MAX_BARS_TO_READ = 400000;

    /// <summary>
    /// Режим работы вендора - только котировки
    /// </summary>
    public static readonly MODE_AUTHENTICATION_REQUEST = 0x00;
    /// <summary>
    /// Режим работы вендора - только котировки
    /// </summary>
    public static readonly MODE_QUOTESTREAM = 0x01;
    /// <summary>
    /// Режим работы вендора - торговля
    /// </summary>
    public static readonly MODE_TRADESTREAM = 0x02;
    /// <summary>
    /// Режим работы вендора - история
    /// </summary>
    public static readonly MODE_HISTORYSTREAM = 0x04;
    /// <summary>
    /// Режим работы вендора - новости
    /// </summary>
    public static readonly MODE_NEWSTREAM = 0x08;

    /// <summary>
    /// Редим работы вендора - один сервер на все
    /// </summary>
    public static readonly MODE_SINGLE_SERVER = ProcessorWorker.MODE_QUOTESTREAM | ProcessorWorker.MODE_TRADESTREAM | ProcessorWorker.MODE_HISTORYSTREAM;
}
