// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type ProductType } from '../../../Utils/Instruments/ProductType';
import { MathUtils } from '../../../Utils/MathUtils';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { type Account } from '../Account';
import { type Instrument } from '../Instrument';
import { type SavedOrder } from './SavedOrder';
import { IsAllowed } from '../../../Commons/IsAllowed';

class _SavedOrderValidator {
    isValidInstrument (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        if (isNullOrUndefined(savedOrder.Instrument)) {
            return { isValid: false, error: 'panel.savedOrders.Error.SymbolIsUnknown' };
        } else {
            return { isValid: true, error: '' };
        }
    }

    isValidAccount (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        if (isNullOrUndefined(savedOrder.Account)) {
            return { isValid: false, error: 'panel.savedOrders.Error.AccountIsUnknown' };
        } else {
            return { isValid: true, error: '' };
        }
    }

    isValidQuantity (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        const instrument: Instrument = savedOrder.Instrument;
        const account: Account = savedOrder.Account;
        const productType: ProductType = savedOrder.ProductType;
        if (isNullOrUndefined(account)) {
            return { isValid: false, error: 'panel.savedOrders.Error.AccountIsUnknown' };
        }
        if (isNullOrUndefined(instrument)) {
            return { isValid: false, error: 'panel.savedOrders.Error.SymbolIsUnknown' };
        }

        const minLot: number = instrument.getMinLot(productType, account);
        const maxLot: number = instrument.getMaxLot(productType, account);
        const lotStep: number = instrument.getLotStep(productType, account);
        const precision: number = MathUtils.getPrecision(lotStep);

        if (savedOrder.QuantityLots < minLot) {
            return { isValid: false, error: 'panel.savedOrders.Error.QuantityLessMin' };
        } else if (savedOrder.QuantityLots > maxLot) {
            return { isValid: false, error: 'panel.savedOrders.Error.QuantityMoreMax' };
        } else if (!MathUtils.isValueMultipleToStep(savedOrder.QuantityLots, lotStep, precision)) {
            return { isValid: false, error: 'panel.savedOrders.Error.QuantityNotMultiple' };
        } else {
            return { isValid: true, error: '' };
        }
    }

    isValidPrice (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        const instrument: Instrument = savedOrder.Instrument;
        if (isNullOrUndefined(instrument)) {
            return { isValid: false, error: 'panel.savedOrders.Error.SymbolIsUnknown' };
        }
        const bid: number = savedOrder.Bid;
        const ask: number = savedOrder.Ask;

        if (savedOrder.OrderType === OrderType.Limit || savedOrder.OrderType === OrderType.OCO) {
            if (savedOrder.Operation === OperationType.Buy && savedOrder.Price >= ask) {
                return { isValid: false, error: 'general.trading.limitBuyLessAsk' };
            } else if (savedOrder.Operation === OperationType.Sell && savedOrder.Price <= bid) {
                return { isValid: false, error: 'general.trading.limitSellMoreBid' };
            } else {
                return this.isValidTrading(savedOrder);
            }
        } else if (savedOrder.OrderType === OrderType.Market) {
            if (!isValidNumber(instrument.GetMarketPrice(savedOrder.Account, savedOrder.Operation))) {
                return { isValid: false, error: 'general.trading.noPriceForMarketOrder' };
            } else {
                return { isValid: true, error: '' };
            }
        }
        return { isValid: true, error: '' };
    }

    isValidStopPrice (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        const instrument: Instrument = savedOrder.Instrument;
        if (isNullOrUndefined(instrument)) {
            return { isValid: false, error: 'panel.savedOrders.Error.SymbolIsUnknown' };
        }
        const bid: number = savedOrder.Bid;
        const ask: number = savedOrder.Ask;

        if (savedOrder.OrderType === OrderType.Stop || savedOrder.OrderType === OrderType.OCO) {
            if (savedOrder.Operation === OperationType.Buy && savedOrder.StopPrice <= ask) {
                return { isValid: false, error: 'general.trading.stopBuyMoreAsk' };
            } else if (savedOrder.Operation === OperationType.Sell && savedOrder.StopPrice >= bid) {
                return { isValid: false, error: 'general.trading.stopSellLessBid' };
            } else {
                return this.isValidTrading(savedOrder);
            }
        } else if (savedOrder.OrderType === OrderType.StopLimit) {
            if (savedOrder.Operation === OperationType.Buy && savedOrder.StopPrice < ask) {
                return { isValid: false, error: 'general.trading.stopBuyMoreAsk' };
            } else if (savedOrder.Operation === OperationType.Sell && savedOrder.StopPrice > bid) {
                return { isValid: false, error: 'general.trading.stopSellLessBid' };
            } else {
                return this.isValidTrading(savedOrder);
            }
        }
        return { isValid: true, error: '' };
    }

    isValidSl (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        if (isNaN(savedOrder.SlPrice)) {
            return { isValid: true, error: '' };
        } else {
            const isAllowedResponse = IsAllowed.IsTradingAllowed([savedOrder.Account], savedOrder.Instrument, OrderType.Stop);
            if (!isAllowedResponse.Allowed) {
                return { isValid: false, error: isAllowedResponse.ReasonLocalizationKey };
            } else {
                return this.isValidTrading(savedOrder);
            }
        }
    }

    isValidSll (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        if (isNaN(savedOrder.SllPrice)) {
            return { isValid: true, error: '' };
        } else {
            return this.isValidSl(savedOrder);
        }
    }

    isValidTp (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        if (isNaN(savedOrder.TpPrice)) {
            return { isValid: true, error: '' };
        } else {
            const isAllowedResponse = IsAllowed.IsTradingAllowed([savedOrder.Account], savedOrder.Instrument, OrderType.Limit);
            if (!isAllowedResponse.Allowed) {
                return { isValid: false, error: isAllowedResponse.ReasonLocalizationKey };
            } else {
                return this.isValidTrading(savedOrder);
            }
        }
    }

    isValidTrading (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        const isAllowedResponse = IsAllowed.IsTradingAllowed([savedOrder.Account], savedOrder.Instrument, savedOrder.OrderType);
        return {
            isValid: isAllowedResponse.Allowed,
            error: isAllowedResponse.ReasonLocalizationKey
        };
    }

    isAllowSl (savedOrder: SavedOrder): boolean {
        const isAllowedResponse = IsAllowed.IsSLTPAllowed(savedOrder.Instrument, savedOrder.Account, true);
        return isAllowedResponse.Allowed;
    }

    isAllowTp (savedOrder: SavedOrder): boolean {
        const isAllowedResponse = IsAllowed.IsSLTPAllowed(savedOrder.Instrument, savedOrder.Account, false);
        return isAllowedResponse.Allowed;
    }
}

export const SavedOrderValidator = new _SavedOrderValidator();
