// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { MAMode, MODE_MAIN, MODE_SIGNAL } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iSTO extends iBuildInIndicator {
    public FHighBuffer: ExpandDoubleVector = new ExpandDoubleVector();
    public FLowBuffer: ExpandDoubleVector = new ExpandDoubleVector();
    public FSignalBuffer: ExpandDoubleVector = new ExpandDoubleVector();
    public FKPeriod: any;
    public FDPeriod: any;
    public FSlowing: any;
    public FMaMode: MAMode;
    public FPriceField: any;
    public begin1: number;
    public begin2: number;

    constructor (kPeriod, dPeriod, slowing, maMode: MAMode, priceField) {
        super(2);

        this.FKPeriod = kPeriod;
        this.FDPeriod = dPeriod;
        this.FSlowing = slowing;
        this.FMaMode = maMode;
        this.FPriceField = priceField;

        if (this.FMaMode == 1) {
            this.begin1 = this.FKPeriod + this.FSlowing; // ?
            this.begin2 = this.FKPeriod + this.FSlowing; // ?
        } else {
            this.begin1 = this.FKPeriod + this.FSlowing;
            this.begin2 = this.FKPeriod + this.FSlowing + this.FDPeriod - 1;
        }
    }

    override get Name (): string { return 'iSTO'; }

    override get Key (): string { return this.DefaultKey + this.FKPeriod + this.FDPeriod + this.FSlowing + this.FMaMode; }

    public override OnQuote (): void {
        this.FHighBuffer[this.FHighBuffer.Length - 1] = 0;
        this.FLowBuffer[this.FLowBuffer.Length - 1] = 0;
        this.FSignalBuffer[this.FSignalBuffer.Length - 1] = 0; ;
        if (this.FCount < this.FKPeriod) {
            return;
        }

        let high = -1.0;
        let low = 10000000.0;
        if (this.FPriceField == 0) {
            for (let i = 0; i < this.FKPeriod; i++) {
                let price = this.GetPrice(PriceType.High, i);
                if (price > high) {
                    high = price;
                }
                price = this.GetPrice(PriceType.Low, i);
                if (price < low) {
                    low = price;
                }
            }
        } else {
            for (let i = 0; i < this.FKPeriod; i++) {
                const price = this.GetPrice(PriceType.Close, i);
                if (price > high) {
                    high = price;
                }
                if (price < low) {
                    low = price;
                }
            }
        }

        this.FLowBuffer[this.FLowBuffer.Length - 1] = this.GetPrice(PriceType.Close, 0) - low;
        this.FHighBuffer[this.FHighBuffer.Length - 1] = high - low;

        // Calculation of smoothed curve
        if (this.FCount > this.begin1) {
            const sumhigh = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FHighBuffer, this.FCount - 1);
            const sumlow = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FLowBuffer, this.FCount - 1);

            const value = sumhigh == 0 ? 100 : sumlow / sumhigh * 100;
            this.SetValue(MODE_MAIN, 0, value);
            this.FSignalBuffer[this.FSignalBuffer.Length - 1] = value;
        }

        if (this.FCount > this.begin2) {
            const signal = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FSignalBuffer, this.FCount - 1, this.begin2);
            this.SetValue(MODE_SIGNAL, 0, signal);
        }
    }

    public override NextBar (callBound): void {
        super.NextBar(callBound);
        this.FHighBuffer.Add(0.0);
        this.FLowBuffer.Add(0.0);
        this.FSignalBuffer.Add(0.0);
    }

    public override Refresh (count, newThread): void {
        this.FHighBuffer.Dispose();
        this.FLowBuffer.Dispose();
        this.FSignalBuffer.Dispose();
        this.FHighBuffer = new ExpandDoubleVector();
        this.FLowBuffer = new ExpandDoubleVector();
        this.FSignalBuffer = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }
}
