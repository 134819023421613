// Copyright TraderEvolution Global LTD. © 2017-2023. All rights reserved.
import { type HistoricalBM } from '../../../../Commons/cache/HistoricalBM';
import { NotificationsItemSideBarTemplate } from '../../../../templates';
import { ControlsTypes } from '../../../UtilsClasses/FactoryConstants';
import { Control } from '../../Control';

export class NotificationsItemSideBar extends Control {
    public Notification: HistoricalBM = null;
    public ItemID: number = null;

    constructor () {
        super();
    }

    oncomplete (): void {
        super.oncomplete();
        this.setDataNotifications();

        this.on('cardClick', this.onCardClick);
        this.observe('notification', this.setDataNotifications);
    }

    private setDataNotifications (): void {
        const notification: HistoricalBM = this.get('notification');
        if (isNullOrUndefined(notification)) { return; }

        this.Notification = notification;
        this.ItemID = notification.ID;

        const Title = notification.Title;
        const Date = notification.Date;
        const OnlyText = notification.OnlyText;
        const isUnread = notification.IsUnread;
        const isSelected = notification.IsSelected;
        const rgbColor = this.hexToRgba(notification.colorHEXstr);

        void this.set({
            Title,
            Date,
            OnlyText,
            rgbColor,
            isUnread,
            isSelected
        });
    };

    private hexToRgba (hex): string {
        hex = hex.replace('#', '');

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }

    public getType (): ControlsTypes {
        return ControlsTypes.NotificationsItemSideBar;
    }

    private onCardClick (): void {
        this.fire('onCardClick', this);
    }
}

Control.extendWith(NotificationsItemSideBar,
    {
        template: NotificationsItemSideBarTemplate,
        data: function () {
            return {
                notification: null,
                Title: null,
                Date: null,
                OnlyText: null,
                rgbColor: 'transparent',
                isUnread: true,
                isSelected: false
            };
        }
    });
