// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { InstrumentSpecificType } from '../Utils/Instruments/InstrumentSpecificType';
import { InstrumentTypes } from '../Utils/Instruments/InstrumentTypes';
import { CurrentLang } from './properties/Resources';
import { type Instrument } from './cache/Instrument';
import { DataCache } from './DataCache';
import { SearchHelper } from './SearchHelper';

export class TerceraSymbolLookupBaseDataProvider {
    public async getInstrumentsList (patern, exchangeIDs = null, instrumentTypes: InstrumentTypes[] | null = null, generateStrikes = false, oldType = false): Promise<Instrument[]> {
        if (!instrumentTypes) {
            instrumentTypes = [
                InstrumentTypes.EQUITIES_CFD,
                InstrumentTypes.FUTURES,
                InstrumentTypes.FOREX,
                InstrumentTypes.INDICIES,
                InstrumentTypes.CRYPTO,
                InstrumentTypes.SPREADBET,
                InstrumentTypes.EQUITIES,
                InstrumentTypes.CFD_FUTURES,
                InstrumentTypes.BOND,
                InstrumentTypes.ETF,
                InstrumentTypes.TBILL,
                InstrumentTypes.SPOT,
                InstrumentTypes.OPTIONS,
                InstrumentTypes.FORWARD,
                InstrumentTypes.CORPORATE
            ];
        }

        if (instrumentTypes.includes(InstrumentTypes.EQUITIES_CFD) && !instrumentTypes.includes(InstrumentTypes.CFD_FUTURES)) {
            instrumentTypes.push(InstrumentTypes.CFD_FUTURES);
        }

        const aliasLanguage = CurrentLang;

        return await DataCache.GetInstrumentList(patern, exchangeIDs, instrumentTypes, generateStrikes, aliasLanguage, oldType)
            .then(function (IInstrumentsList) {
                return IInstrumentsList;
            });
    }

    public async getOptionsList (patern, exchangeIDs = null): Promise<Instrument[]> {
        const aliasLanguage = CurrentLang;
        const optionsList = await DataCache.GetOptionsList(patern, exchangeIDs, aliasLanguage);
        return optionsList;
    }

    public async getInstrument (instrumentItem: SearchHelper): Promise<Instrument> {
        if (instrumentItem.isHide) {
            return await Promise.resolve(null);
        }

        let newIns = DataCache.getInstrumentByName(instrumentItem.GetInteriorID());
        const me = this;
        if (newIns) {
            return await Promise.resolve(newIns);
        } else {
        // For ContinuousContract searching
            if (instrumentItem.Id && instrumentItem.InstrumentSpecificType === InstrumentSpecificType.ContinuousContract) {
            // try fixedList
                newIns = DataCache.getInstrumentByInstrumentIdAndRoute(instrumentItem.Id, instrumentItem.Route);

                if (!newIns) // ok non fixedlist
                {
                    return await me.getContinuousInstrumentById(instrumentItem.Id, instrumentItem.Route);
                }

                return newIns;
            } else {
                return await me.getInstrumentByInstrumentTradableID_NFL(instrumentItem.InstrumentTradableID, instrumentItem.Route, instrumentItem.GetInteriorID());
            }
        }
    }

    public async getOption (instrument: Instrument): Promise<Instrument> {
        let newIns = DataCache.OptionsCache.getOptionFromCache(instrument);
        if (!isNullOrUndefined(newIns)) {
            return newIns;
        }
        await DataCache.getOptionById(instrument.Id);
        if (instrument.isFakeOption) {
            newIns = DataCache.OptionsCache.getFirstOption(instrument);
        } else {
            newIns = DataCache.OptionsCache.getOptionFromCache(instrument);
        }
        return newIns;
    }

    // TODO. Refactor.
    public async getInstrumentByName (instrumentName: string): Promise<Instrument> {
        return await this.getInstrument(new SearchHelper(instrumentName));
    }

    public async getInstrumentByNameNFL (instrumentName): Promise<Instrument> {
        return await DataCache.getInstrumentByNameNFL(instrumentName)
            .then(function (IInstrument) {
                return IInstrument;
            });
    }

    public async getContinuousInstrumentById (Id: number, Route: number): Promise<Instrument | null> {
        return await DataCache.getInstrumentById(Id)
            .then(function (found) {
                if (found) {
                    return DataCache.getInstrumentByInstrumentIdAndRoute(Id, Route);
                }

                return null;
            });
    }

    public async getInstrumentByInstrumentTradableID_NFL (InstrumentTradableID: number, Route: number, interiorID: string): Promise<Instrument | null> {
        return await DataCache.getInstrumentByInstrumentTradableID_NFL(InstrumentTradableID, Route, interiorID);
    }

    public getStrikesList (instrument: Instrument): Instrument[] {
        if (instrument.ForwardBaseInstrument.isFuturesSymbol) {
            const underlierTradableId = instrument.ForwardBaseInstrument.InstrumentTradableID;
            return DataCache.GetInstrumentsByInstrumentIdRouteId(instrument.Id, instrument.Route).filter(ins => ins.InstrType === InstrumentTypes.OPTIONS && ins.ForwardBaseInstrument.InstrumentTradableID === underlierTradableId);
        } else {
            return DataCache.GetInstrumentsByInstrumentIdRouteId(instrument.Id, instrument.Route).filter(ins => ins.InstrType === InstrumentTypes.OPTIONS);
        }
    }

    public async GetNonFixedInstrumentStrikes (instrument: Instrument): Promise<Instrument[]> {
        return await DataCache.GetNonFixedInstrumentStrikes(instrument)
            .then(function (IInstrumentsList) {
                return IInstrumentsList;
            });
    }

    public dispose (): void {

    }
}
