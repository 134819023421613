// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources, LOCALE_ES, LOCALE_EN, CurrentLang } from '../../Commons/properties/Resources';
import { HistoryType } from '../../Utils/History/HistoryType';
import { TerceraChartAction, TerceraChartActionEnum } from '../../Chart/TerceraChartAction';
import { LinkedSystem } from '../misc/LinkedSystem';
import { ModelDataType, TerceraChartMVCCommand } from '../../Chart/TerceraChartMVC';
import { TradingCentralTemplate } from '../../templates.js';
import { TradingCentralItem } from '../cache/TradingCentralItem';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TradingToolsScreen } from '../screen/TradingToolsScreen';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { LookupDropDownShowParams } from '../UtilsClasses/LookupDropDownShowParams';
import { PeriodDesc, Periods } from '../../Utils/History/TFInfo';
import { DynProperty } from '../../Commons/DynProperty';
import { IndicatorManager } from '../../Commons/cache/indicators/IndicatorManager';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { UserWebStorageInstance } from '../../user-web-storage';
import { DataCache } from '../../Commons/DataCache';
import { PanelItemsFactory } from './PanelItemsFactory';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { TerceraIndicatorLookupDropDownForm } from '../elements/Lookup/TerceraIndicatorLookupDropDownForm';
import { PanelSettingsScreen } from '../screen/PanelSettingsScreen';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type TerceraChartRactive } from '../elements/TerceraChartRactive/TerceraChartRactive';

export class TradingCentral extends ApplicationPanelWithTable<any> {
    public static technicalAnalysis = 0;
    public static alerts = 1;
    public static candlestick = 2;
    public static tabHeaderKeys = ['Technical analysis', 'Technical alerts', 'Candlestick patterns'];
    public static Term = ['panel.tradingCentral.term.All', 'panel.tradingCentral.term.Intraday', 'panel.tradingCentral.term.Short term', 'panel.tradingCentral.term.Middle term'];
    public static TermRequestValue = ['null', 'Intraday', 'ST', 'MT'];

    public settings: any = null;
    public activeTab: any = null;
    public language: any = null;
    public previousTermSetting: any = null;
    public terceraChartRactive: TerceraChartRactive | null = null;
    public TradingCentralData: any;

    constructor () {
        super();

        this.headerLocaleKey = 'panel.tradingCentral';
        this.NeedCalculateRowCount = false;
        this.skipSomeProperties = true;
    }

    public override getType (): PanelNames { return PanelNames.TradingCentral; }

    public override oninit (): void {
        super.oninit();

        this.on('indicatorButton_btnClick', this.onIndicatorBtnClick);

        this.observe('instrument', this.onInstrumentChanged);
        this.observe('width', this.onWidthChanged);
        this.observe('showToolsPanel', this.layoutTable, { init: false });
        this.observe('noData', this.layoutTable);
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.initChart();
        this.themeChange();
        this.jbInit();
        this.populateTableContextMenu();

        if (!this.settings) {
            this.applyDefaultTableSettings();
        }

        this.on('screenBtnClick', this.onScreenBtnClick);
        this.on('chartBtnClick', this.onChartBtnClick);
        this.on('noDataMouseDown', this.noDataMouseDown);

        if (this.Controls.tabs) {
            const tabs = this.Controls.tabs;
            tabs.onTabChange.Subscribe(this.selectTab, this);
            tabs.onCBItemClickEvent.Subscribe(this.selectDate, this);

            tabs.observe('selectedItem', function (newVal) {
                this.activeTab = newVal;
            }.bind(this));
        }

        GeneralSettings.SettingsChanged.Subscribe(this.applicationSettingsChanged, this);
        DataCache.ExternalLinksCache.TradingCentralExternalToolChanged.Subscribe(this.updateRequest, this);

        this.language = CurrentLang === LOCALE_ES ? LOCALE_ES : LOCALE_EN;

        this.onInstrumentChanged(this.get('instrument'), null); // this.infoRequest(TradingCentral.technicalAnalysis)
    }

    public override dispose (): void {
        if (this.Controls.tabs) {
            const tabs = this.Controls.tabs;
            tabs.onTabChange.UnSubscribe(this.selectTab, this);
            tabs.onCBItemClickEvent.UnSubscribe(this.selectDate, this);
        }

        DataCache.ExternalLinksCache.TradingCentralExternalToolChanged.UnSubscribe(this.updateRequest, this);
        GeneralSettings.SettingsChanged.UnSubscribe(this.applicationSettingsChanged, this);

        super.dispose();
    }

    public selectDate (selectedItem): void {
        if (!this.Controls.quickTableRactive) {
            return;
        }

        const qt = this.Controls.quickTableRactive.quickTable;

        qt.ClearRows();

        if (selectedItem.length !== undefined) {
            if (selectedItem.length) {
                selectedItem = selectedItem[0];
            } else {
                return;
            }
        }

        let imgURL;

        if (selectedItem.tag?.imgURL) {
            imgURL = selectedItem.tag.imgURL;
        }

        if (imgURL) {
            void this.set('screenImgURL', imgURL);

            if (this.Controls.screenIMG) {
                const img = this.Controls.screenIMG.find('img');
                if (img) { img.onload = function () { this.layoutTable(); }.bind(this); }
            }
        }

        if (selectedItem.tag?.period && !selectedItem.tag.indicators) // indicators exist -> technical alalysis -> need change chart settings
        {
            this.onChartTermChanged(selectedItem.tag.period);
        }

        const item = JSON.parse(JSON.stringify(selectedItem.tag));

        item.imgURL = null;

        if (item.indicators) {
            const indicators = item.indicators;
            for (let i = 0; i < indicators.length; i++) {
                const ind = indicators[i];
                item['indicator_' + ind.name] = ind.name + ': ' + ind.trend;
            }

            item.indicators = null;
        }

        let chartData;
        if (item.chartData) {
            chartData = JSON.parse(JSON.stringify(item.chartData));
            item.chartData = null;
        }

        for (const key in item) {
            if (item[key] !== null) {
                item.activeKey = key;

                const rows = TradingCentralItem.lineBreak(qt.width, qt.context, item[key], key);

                for (let i = 0; i < rows.length; i++) {
                    qt.AddItem(new TradingCentralItem(rows[i], qt.context));
                }
            }
        }

        if (qt.needRedraw || qt.rowCountChanged) {
            qt.Draw();
            qt.needRedraw = false;
            qt.rowCountChanged = false;
        }

        const chart = this.terceraChartRactive ? this.terceraChartRactive.terceraChart : null;
        if (chart) {
            chart.setDataForTradingCentralRenderer(chartData);
        }
    }

    public selectTab (selectedTab): void {
        const tabs = TradingCentral.tabHeaderKeys;

        const qt = this.Controls.quickTableRactive.quickTable;
        qt.ClearRows();
        void this.set('noDataInTable', true);

        switch (selectedTab) {
        case tabs[TradingCentral.technicalAnalysis]:
            this.parseTechnicalAnalysisResponse();
            break;

        case tabs[TradingCentral.alerts]:
            this.parseAlertsResponse();
            break;

        case tabs[TradingCentral.candlestick]:
            this.parseCandlestickResponse();
            break;
        }
    }

    // Requests & Response region

    public infoRequest (tableType): void // tableType - 0,1,2 в соответствии с константами TradingCentral.technicalAnalysis (alerts,candlestick)
    {
        const tool = DataCache.ExternalLinksCache.TradingCentralExternalTool;
        const settings = this.settings;

        if (!tool || !settings) {
            void this.set('noData', true);
            return;
        }

        void this.set('loading', true);

        const term = TradingCentral.TermRequestValue[TradingCentral.Term.indexOf(settings.technicalAnalysis.term)];

        const culture = CurrentLang === LOCALE_ES ? 'es-ES' : 'en-US';

        const ins = this.get('instrument');

        if (!ins?.InstrumentAdditionalInfo['Ticker for TC']) {
            void this.set({
                loading: false,
                noData: true
            });
            return;
        } else {
            void this.set('noData', false);
        }

        const product = ins.InstrumentAdditionalInfo['Ticker for TC'];
        const me = this;

        const technicalAnalysisURLPart = tool.technicalAnalysis + (tool.technicalAnalysis.indexOf('?') == -1 ? '?' : ''); // #97257
        const alertsURLPart = tool.alerts + (tool.alerts.indexOf('?') == -1 ? '?' : '');
        const candlestickURLPart = tool.candlestick + (tool.candlestick.indexOf('?') == -1 ? '?' : '');

        const technicalAnalysisURL = technicalAnalysisURLPart + 'culture=' + culture + '&type_product=null&product=' + product + '&term=' + term + '&days=' + settings.technicalAnalysis.days + '&last_ta=' + settings.technicalAnalysis.lastOnly.toString() + '&partner=' + tool.partnerID + '&token=' + tool.token;
        const alertsURL = alertsURLPart + 'culture=' + culture + '&type_product=null&product=' + product + '&days=' + settings.alerts.days + '&last_only=' + settings.alerts.lastOnly.toString() + '&partner=' + tool.partnerID + '&token=' + tool.token;
        const candlestickURL = candlestickURLPart + 'culture=' + culture + '&type_product=null&product=' + product + '&days=' + settings.candlestick.days + '&last_only=' + settings.candlestick.lastOnly.toString() + '&partner=' + tool.partnerID + '&token=' + tool.token;

        if (tableType == TradingCentral.technicalAnalysis) {
            UserWebStorageInstance.externalResourse.post(technicalAnalysisURL).then(function (res) {
                me.TradingCentralData.technicalAnalysis = res;
                me.parseTechnicalAnalysisResponse();
            })
                .finally(function () {
                    void me.set('loading', false);
                });

            UserWebStorageInstance.externalResourse.post(alertsURL).then(function (res) { me.TradingCentralData.alerts = res; });
            UserWebStorageInstance.externalResourse.post(candlestickURL).then(function (res) { me.TradingCentralData.candlestick = res; });
        }

        if (tableType == TradingCentral.alerts) {
            UserWebStorageInstance.externalResourse.post(alertsURL).then(function (res) {
                me.TradingCentralData.alerts = res;
                me.parseAlertsResponse();
            })
                .finally(function () {
                    void me.set('loading', false);
                });

            UserWebStorageInstance.externalResourse.post(technicalAnalysisURL).then(function (res) {
                me.TradingCentralData.technicalAnalysis = res;
                void me.set('screenImgURL', me.parseForImage());
            });
            UserWebStorageInstance.externalResourse.post(candlestickURL).then(function (res) { me.TradingCentralData.candlestick = res; });
        }

        if (tableType == TradingCentral.candlestick) {
            UserWebStorageInstance.externalResourse.post(candlestickURL).then(function (res) {
                me.TradingCentralData.candlestick = res;
                me.parseCandlestickResponse();
            })
                .finally(function () {
                    void me.set('loading', false);
                });

            UserWebStorageInstance.externalResourse.post(technicalAnalysisURL).then(function (res) {
                me.TradingCentralData.technicalAnalysis = res;
                void me.set('screenImgURL', () => { me.parseForImage(); });
            });
            UserWebStorageInstance.externalResourse.post(alertsURL).then(function (res) {
                me.TradingCentralData.alerts = res;
            });
        }
    }

    public updateRequest (): void {
        if (this.activeTab) {
            const tab = this.activeTab.data;

            this.infoRequest(TradingCentral.tabHeaderKeys.indexOf(tab));
        }
    }

    public parseTechnicalAnalysisResponse (): void {
        const res = this.TradingCentralData.technicalAnalysis;

        if (!res) {
            return;
        }

        const xml = res.Text;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'text/xml');

        const table: any = xmlDoc.childNodes[0];

        const articles = table.getElementsByTagName('article');

        const technicalAnalysis = []; const alreadyAdded = {};

        for (let i = 0; i < articles.length; i++) {
            const art = articles[i];
            const title = art.getElementsByTagName('title')[0].textContent;
            const dateS = DateTimeConvertor.getTimeStringInCurrentOffset(DateTimeUtils.getDateFromYYYYMMDD(art.getElementsByTagName('date')[0].textContent), art.getElementsByTagName('hour')[0].textContent);
            const period = art.getElementsByTagName('term')[0].textContent;
            let ourPreference; let alternativeScenario; let comment;
            const expectedMove1 = art.getElementsByTagName('expectedmove1_percent')[0].textContent;
            const expectedMove2 = art.getElementsByTagName('expectedmove2_percent')[0].textContent;
            const pivot = parseFloat(art.getElementsByTagName('pivot')[0].textContent);
            const last = art.getElementsByTagName('last')[0].textContent;
            const resistance1 = parseFloat(art.getElementsByTagName('resistance1')[0].textContent);
            const resistance2 = parseFloat(art.getElementsByTagName('resistance2')[0].textContent);
            const resistance3 = parseFloat(art.getElementsByTagName('resistance3')[0].textContent);
            const support1 = parseFloat(art.getElementsByTagName('support1')[0].textContent);
            const support2 = parseFloat(art.getElementsByTagName('support2')[0].textContent);
            const support3 = parseFloat(art.getElementsByTagName('support3')[0].textContent);

            const imgURL = art.getElementsByTagName('media')[0].textContent;

            const paragraphes = art.getElementsByTagName('paragraph');
            for (let j = 0; j < paragraphes.length; j++) {
                const par = paragraphes[j].textContent;
                if (par.indexOf('Our preference') >= 0) {
                    ourPreference = par;
                } else
                    if (par.indexOf('Alternative scenario') >= 0) {
                        alternativeScenario = par;
                    } else if (par.indexOf('Comment') >= 0) {
                        comment = par;
                    }
            }

            const item = {
                imgURL,
                title: 'Title: ' + title,
                date: 'Date: ' + dateS,
                period: 'Period: ' + period,
                ourPreference,
                alternativeScenario,
                comment,
                // expectedMove: 'Expected moves: E1 ' + expectedMove1 + '; E2 ' + expectedMove2 + ';',
                expectedMove1: 'Expected move 1: ' + expectedMove1,
                expectedMove2: 'Expected move 2: ' + expectedMove2,
                pivot: 'Pivot: ' + pivot,
                last: 'Last: ' + last,
                // resistance: 'Resistances: R1 ' + resistance1 + '; R2 ' + resistance2 + '; R3 ' + resistance3 + ';',
                resistance1: 'Resistance 1: ' + resistance1,
                resistance2: 'Resistance 2: ' + resistance2,
                resistance3: 'Resistance 3: ' + resistance3,
                // support: 'Supports: S1 ' + support1 + '; S2 ' + support2 + '; S3 ' + support3 + ';'
                support1: 'Supports 1: ' + support1,
                support2: 'Supports 2: ' + support2,
                support3: 'Supports 3: ' + support3,
                chartData: {
                    resistanceValues: [resistance1, resistance2, resistance3],
                    supportValues: [support1, support2, support3],
                    pivotValue: pivot
                }
            };

            const cbItem = {
                value: item,
                text: dateS
            };

            if (!alreadyAdded[item.date]) // скипаю если уже есть данные за эту дату
            {
                technicalAnalysis.push(cbItem);
                alreadyAdded[item.date] = true;
            }
        }

        const tabs = this.Controls.tabs;
        if (tabs) {
            const cbItems = tabs.get('cbItems');

            cbItems[TradingCentral.technicalAnalysis] = technicalAnalysis;

            tabs.set('cbItems', cbItems);

            void this.set('noDataInTable', technicalAnalysis.length === 0);

            tabs.onComboItemClicked(TradingCentral.technicalAnalysis, null, technicalAnalysis);
        }
    }

    public parseAlertsResponse (): void {
        const res = this.TradingCentralData.alerts;

        if (!res) {
            return;
        }

        const xml = res.Text;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'text/xml');

        const table: any = xmlDoc.childNodes[0];

        const articles = table.getElementsByTagName('article');

        const alerts = [];

        for (let i = 0; i < articles.length; i++) {
            const art = articles[i];
            const dateS = DateTimeConvertor.getTimeStringInCurrentOffset(DateTimeUtils.getDateFromYYYYMMDD(art.getElementsByTagName('date')[0].textContent), art.getElementsByTagName('hour')[0].textContent);
            const period = art.getElementsByTagName('period_unit')[0].textContent + ' ' + art.getElementsByTagName('period_unit_count')[0].textContent;
            const indicators = [];

            const trends = art.getElementsByTagName('trend');
            for (let j = 0; j < trends.length; j++) {
                const trend = trends[j];
                if (trend.textContent != 0) {
                    const ind = {
                        name: trend.attributes.type.value,
                        trend: trend.textContent
                    };
                    indicators.push(ind);
                }
            }

            const volatilities = art.getElementsByTagName('volatility');
            for (let j = 0; j < volatilities.length; j++) {
                const volatility = volatilities[j];
                if (volatility.textContent != 0) {
                    const ind = {
                        name: volatility.attributes.type.value,
                        trend: volatility.textContent
                    };
                    indicators.push(ind);
                }
            }

            const momentums = art.getElementsByTagName('momentum');
            for (let j = 0; j < momentums.length; j++) {
                const momentum = momentums[j];
                if (momentum.textContent != 0) {
                    const ind = {
                        name: momentum.attributes.type.value,
                        trend: momentum.textContent
                    };
                    indicators.push(ind);
                }
            }

            const strengths = art.getElementsByTagName('strength');
            for (let j = 0; j < strengths.length; j++) {
                const strength = strengths[j];
                if (strength.textContent != 0) {
                    const ind = {
                        name: strength.attributes.type.value,
                        trend: strength.textContent
                    };
                    indicators.push(ind);
                }
            }

            const item = {
                date: 'Date: ' + dateS,
                period: 'Period: ' + period,
                indicators
            };

            const cbItem = {
                value: item,
                text: dateS
            };
            alerts.push(cbItem);
        }

        const tabs = this.Controls.tabs;
        if (tabs) {
            const cbItems = tabs.get('cbItems');

            cbItems[TradingCentral.alerts] = alerts;

            tabs.set('cbItems', cbItems);

            void this.set('noDataInTable', alerts.length === 0);

            tabs.onComboItemClicked(TradingCentral.alerts, null, alerts);
        }
    }

    public parseCandlestickResponse (): void {
        const res = this.TradingCentralData.candlestick;

        if (!res) {
            return;
        }

        const xml = res.Text;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'text/xml');

        const table: any = xmlDoc.childNodes[0];

        const articles = table.getElementsByTagName('article');

        const candlestick = [];

        for (let i = 0; i < articles.length; i++) {
            const art = articles[i];
            const title = art.getElementsByTagName('title')[0].textContent;
            const dateS = DateTimeConvertor.getTimeStringInCurrentOffset(DateTimeUtils.getDateFromYYYYMMDD(art.getElementsByTagName('date')[0].textContent), art.getElementsByTagName('hour')[0].textContent);
            const candlestickName = art.getElementsByTagName('candlestick')[0].textContent;
            const lastPrice = art.getElementsByTagName('last')[0].textContent;
            const invalidationPrice = art.getElementsByTagName('invalidation')[0].textContent;
            const trend = art.getElementsByTagName('watch')[0].textContent;
            const indicators = [{ name: 'Trend', trend }];

            const item = {
                title: 'Title: ' + title,
                date: 'Date: ' + dateS,
                candlestickName: 'Candlestick name: ' + candlestickName,
                indicators,
                lastPrice: 'Last price: ' + lastPrice,
                invalidationPrice: 'Invalidation price: ' + invalidationPrice
            };

            const cbItem = {
                value: item,
                text: dateS
            };
            candlestick.push(cbItem);
        }

        const tabs = this.Controls.tabs;
        if (tabs) {
            const cbItems = tabs.get('cbItems');

            cbItems[TradingCentral.candlestick] = candlestick;

            tabs.set('cbItems', cbItems);

            void this.set('noDataInTable', candlestick.length === 0);

            tabs.onComboItemClicked(TradingCentral.candlestick, null, candlestick);
        }
    }

    public parseForImage (): void {
        const res = this.TradingCentralData.technicalAnalysis;

        if (!res) { return; }

        const xml = res.Text;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'text/xml');

        const table: any = xmlDoc.childNodes[0];

        const articles = table.getElementsByTagName('article');

        for (let i = 0; i < articles.length; i++) {
            const art = articles[i];

            const imgURL = art.getElementsByTagName('media')[0].textContent;

            if (imgURL) {
                return imgURL;
            }
        }

        return null;
    }

    public onWidthChanged (newVal, oldVal): void {
        if (!newVal || newVal == oldVal) {
            return;
        }

        const tabs = this.Controls.tabs;
        if (tabs && newVal > 350) {
            const selected = tabs.get('selectedCBItem');
            if (selected) {
                this.selectDate(selected);
            }
        }
    }

    public populateTableContextMenu (): void {
        const items = [];

        items.push(
            {
                text: Resources.getResource('panel.tradingCentral.settings'),
                event: function () {
                    let header = '';
                    const tabs = this.Controls.tabs;
                    if (tabs) {
                        const selected = tabs.get('selectedItem');
                        if (selected?.data) {
                            header = selected.data;
                        }
                    }
                    PanelSettingsScreen.EditProperties.call(this, this, DynProperty.DATA_STYLE_GROUP, header);
                }.bind(this)
            }
        );

        this.Controls.quickTableRactive.quickTable.setTableContextMenuItems(items);
    }

    public applyDefaultTableSettings (): void {
        const sett = { // настройки таблиц по умолчанию
            technicalAnalysis: {
                term: TradingCentral.Term[0],
                days: 7,
                lastOnly: false
            },
            alerts: {
                days: 14,
                lastOnly: false
            },
            candlestick: {
                days: 14,
                lastOnly: false
            }
        };

        this.settings = sett;
    }

    public override Properties (forActiveTableOnly = false): DynProperty[] {
        const properties = super.Properties();

        const tabs = TradingCentral.tabHeaderKeys;

        for (let i = 0; i < tabs.length; i++) {
            let dp; let currentTab = tabs[i];

            if (forActiveTableOnly && this.activeTab) // для получения настроек только активной таблицы
            {
                currentTab = this.activeTab.data;
            }

            const isTechnicalAnalysisTab = currentTab === tabs[TradingCentral.technicalAnalysis];
            let settings; let settingsName = 'panel.tradingCentral.settings';

            switch (currentTab) {
            case tabs[TradingCentral.technicalAnalysis]:
                settingsName += '.technicalAnalysis';
                settings = this.settings.technicalAnalysis;
                break;
            case tabs[TradingCentral.alerts]:
                settingsName += '.alerts';
                settings = this.settings.alerts;
                break;
            case tabs[TradingCentral.candlestick]:
                settingsName += '.candlestick';
                settings = this.settings.candlestick;
                break;
            }

            if (settings.term) {
                dp = new DynProperty(settingsName + '.term', settings.term, DynProperty.COMBOBOX_COMBOITEM, DynProperty.PARAM_GROUP);
                dp.objectVariants = [];

                const terms = TradingCentral.Term;
                const len = terms.length;
                for (let i = 0; i < len; i++) {
                    const l = terms[i];
                    dp.objectVariants.push({
                        value: l,
                        text: Resources.getResource(l)
                    });
                }

                dp.COMBOBOX_TYPE = DynProperty.STRING;
                dp.sortIndex = 1;
                properties.push(dp);
            }

            dp = new DynProperty(settingsName + '.days', settings.days, DynProperty.INTEGER, DynProperty.PARAM_GROUP);
            dp.minimalValue = 1;
            dp.maximalValue = isTechnicalAnalysisTab ? 7 : 14;
            dp.sortIndex = 2;
            properties.push(dp);

            properties.push(new DynProperty(settingsName + '.lastOnly', settings.lastOnly, DynProperty.BOOLEAN, DynProperty.PARAM_GROUP));

            if (forActiveTableOnly) // для получения настроек только активной таблицы
            {
                return properties;
            }
        }

        return properties;
    }

    public override callBack (properties, settingsWasChanged?): void {
        super.callBack(properties);

        if (!this.settings) {
            this.applyDefaultTableSettings();
        }

        if (settingsWasChanged === false) {
            return;
        }

        const settings = this.settings;

        let dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.technicalAnalysis.term');
        if (dp?.value) {
            settings.technicalAnalysis.term = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.technicalAnalysis.lastOnly');
        if (dp?.value !== undefined && dp.value !== null) {
            settings.technicalAnalysis.lastOnly = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.technicalAnalysis.days');
        if (dp?.value) {
            settings.technicalAnalysis.days = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.alerts.lastOnly');
        if (dp?.value !== undefined && dp.value !== null) {
            settings.alerts.lastOnly = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.alerts.days');
        if (dp?.value) {
            settings.alerts.days = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.candlestick.lastOnly');
        if (dp?.value !== undefined && dp.value !== null) {
            settings.candlestick.lastOnly = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, 'panel.tradingCentral.settings.candlestick.days');
        if (dp?.value) {
            settings.candlestick.days = dp.value;
        }

        if (this.activeTab) {
            this.infoRequest(TradingCentral.tabHeaderKeys.indexOf(this.activeTab.data));
        }
    }

    public applicationSettingsChanged (): void {
        const newLang = CurrentLang === LOCALE_ES ? LOCALE_ES : LOCALE_EN;
        if (this.language != newLang && this.activeTab) {
            this.language = newLang;
            this.infoRequest(TradingCentral.tabHeaderKeys.indexOf(this.activeTab.data));
        }
    }

    public onInstrumentChanged (newIns, oldIns): void {
        if (!newIns || newIns === oldIns || !this.completed) {
            return;
        }

        if (this.terceraChartRactive?.terceraChart) {
            this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Instrument), newIns);
            this.unsubscribe(oldIns);
            this.subscribe(newIns);
        }
        this.symbolLink_Out(false, newIns);

        if (this.activeTab) {
            const tab = this.activeTab.data;

            this.infoRequest(TradingCentral.tabHeaderKeys.indexOf(tab));
        }
    }

    public override layoutTable (): void {
        if (this.terceraChartRactive === null) {
            return;
        }

        this.layoutTableResize(this.terceraChartRactive);

        const qt = this.Controls.quickTableRactive;
        if (qt) {
            qt.quickTable.onResize();
        }
    }

    public override TickAsync (): void {
        if (this.terceraChartRactive?.terceraChart?.needRedraw) {
            this.terceraChartRactive.terceraChart.needRedraw = false;
            this.terceraChartRactive.terceraChart.Draw();
        }

        if (!this.Controls.quickTableRactive) {
            return;
        }

        const qt = this.Controls.quickTableRactive.quickTable;
        if (qt.needRedraw || qt.rowCountChanged) {
            qt.Draw();
            qt.needRedraw = false;
            qt.rowCountChanged = false;
        }
    }

    public override jbInit (): void {
        const me = this;

        const qt = this.Controls.quickTableRactive.quickTable;
        const panelInfo = PanelItemsFactory.GetPanelItem(me.getType());
        qt.showHeader = false;
        qt.InitializeDirect(new panelInfo());

        qt.UpdateSortedColumns();

        me.OnResize.Subscribe(function () { me.layoutTable(); }, me);

        me.layoutTable();
        me.localize();
    }

    // Chart region
    public subscribe (instrument): void {
        if (!instrument) {
            return;
        }

        const qc = DataCache.FQuoteCache;
        const chart = this.terceraChartRactive.terceraChart;

        qc.addListener(instrument, chart, HistoryType.QUOTE_LEVEL1);
        qc.addListener(instrument, chart, HistoryType.QUOTE_TRADES);
    }

    public unsubscribe (instrument): void {
        if (!instrument) {
            return;
        }

        const qc = DataCache.FQuoteCache;
        const chart = this.terceraChartRactive.terceraChart;

        qc.removeListener(instrument, chart, HistoryType.QUOTE_LEVEL1);
        qc.removeListener(instrument, chart, HistoryType.QUOTE_TRADES);
    }

    public override themeChange (): void {
        const Controls = this.Controls;

        if (this.terceraChartRactive) {
            this.terceraChartRactive.themeChange();
        }

        const toolsPanel = Controls.toolsPanel;
        if (toolsPanel) {
            toolsPanel.themeChange();
        }
    }

    public initChart (): void {
        this.terceraChartRactive = this.Controls.chart;

        if (!this.terceraChartRactive?.terceraChart) {
            return;
        }

        const terceraChart = this.terceraChartRactive.terceraChart;
        const controller = terceraChart.chartController;

        controller.ExecuteCommand(
            new TerceraChartMVCCommand(ModelDataType.Instrument),
            this.get('instrument'));

        controller.ExecuteCommand(
            new TerceraChartMVCCommand(ModelDataType.Account),
            DataCache.getPrimaryAccount());

        this.Controls.toolsPanel.set('actionProcessor', terceraChart.TerceraChartActionProcessor);

        if (terceraChart.TradingCentralRenderer) {
            terceraChart.TradingCentralRenderer.Visible = true;
        }

        (terceraChart as any).GetContextMenu = function () { };
        controller.SuspendRefreshChart = false;
        terceraChart.RefreshChart();

        terceraChart.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(
            TerceraChartActionEnum.AutoScale, terceraChart.mainWindow));
    }

    public onChartTermChanged (termStr): void {
        if (!this.terceraChartRactive) {
            return;
        }

        termStr = termStr.substr(termStr.indexOf(':') + 2);

        if (this.previousTermSetting !== termStr) {
            let termInt, period;

            switch (termStr) {
            case 'INTRADAY':
                termInt = 30;
                period = new PeriodDesc(3, Periods.MONTH);
                break;
            case 'ST':
                termInt = 1440;
                period = new PeriodDesc(1, Periods.YEAR);
                break;
            case 'MT':
                termInt = 10080;
                period = new PeriodDesc(3, Periods.YEAR);
                break;
            }

            const model = this.terceraChartRactive.terceraChart.model;
            const oldTFInfo = model.GetTimeFrameInfo();
            const controller = this.terceraChartRactive.terceraChart.chartController;
            controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.TFI), oldTFInfo.Copy({ period: termInt }));
            controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Range), period);
        }

        this.previousTermSetting = termStr;
    }

    public applyCursor (cursor): void {
        if (this.terceraChartRactive) {
            this.terceraChartRactive.setCursor(cursor);
        }
    }

    public onIndicatorBtnClick (): void {
        const params = new LookupDropDownShowParams();
        params.callBack = this.indicatorLookupCB.bind(this);
        TerceraIndicatorLookupDropDownForm.ShowForm(params);
    }

    public indicatorLookupCB (selectedItem): void {
        if (typeof selectedItem === 'object' && selectedItem.FullName) {
            this.terceraChartRactive.terceraChart.AddIndicator(IndicatorManager.GetIndicator(selectedItem.FullName));
        }
    }

    public onScreenBtnClick (): void {
        void this.set({
            screenBtnStyle: 'js-button-switchOn',
            chartBtnStyle: 'js-button-switchOff',
            showChart: false
        });
    }

    public onChartBtnClick (): void {
        void this.set({
            screenBtnStyle: 'js-button-switchOff',
            chartBtnStyle: 'js-button-switchOn',
            showChart: true
        });
    }

    public override symbolLink_In (symbolName): void {
        const currentInstrument = this.get('instrument');

        const newInstr = DataCache.getInstrumentByName(symbolName);
        if (newInstr) {
        // убираем не нужный апдейт
            if (currentInstrument && currentInstrument.GetInteriorID() === symbolName) {
                return;
            }
            void this.set('instrument', newInstr);
        }
    }

    public override symbolLink_Out (newSubscriber, instrument): void {
        if (!instrument) {
            const ins = this.get('instrument');
            if (!ins) return;
            instrument = ins;
        }
        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber);
        }
    }

    public noDataMouseDown (event): void {
        window.open(TradingToolsScreen.getTradingCentralLink());
    }
}

ApplicationPanelWithTable.extendWith(TradingCentral, {
    partials: { bodyPartial: TradingCentralTemplate },
    data: function () {
        return {
            canLinkByAccount: false,
            isSymbolLinkShow: true,
            instrument: null,
            showChart: false, // если false - отображается screen c img
            showToolsPanel: false,
            showSwitcher: !Resources.isHidden('panel.tradingCentral.ScreenButton'), // есть возможность скрыть переключатель между чартом и скрином через ключ
            screenImgURL: null,
            noData: false,
            noDataInTable: false,
            loading: false,
            tabHeaderKeys: TradingCentral.tabHeaderKeys,
            screenBtnText: Resources.getResource('panel.tradingCentral.screenBtn.text'),
            chartBtnText: Resources.getResource('panel.tradingCentral.chartBtn.text'),
            screenBtnStyle: 'js-button-switchOn',
            chartBtnStyle: 'js-button-switchOff'
        };
    },
    computed: {
        terceraChartPanelContext: {
            get: function () { return this; },
            set: function (value) { }
        }
    },

    TradingCentralData:
        {
            technicalAnalysis: null,
            alerts: null,
            candlestick: null
        }
});
