// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { Rectangle } from '../../Commons/Geometry';
import { HistoryType } from '../../Utils/History/HistoryType';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { AlertUtils } from '../../Utils/Alert/AlertUtils';
import { AlertAction, AlertType, AlertImportance } from '../../Utils/Alert/AlertConstants';
import { type Alert, AlertSendToServerItem } from '../../Commons/cache/Alert';
import { ControlsUtils } from '../UtilsClasses/ControlsUtils';
import { CreateAlertTemplate } from '../../templates.js';
import { TerceraMenu } from '../elements/TerceraMenu';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { AdvancedOrderEntry } from './AdvancedOrderEntry';
import { PlacedFrom } from '../../Utils/Trading/PlacedFrom';

import { Quantity } from '../../Utils/Trading/Quantity';
import { OrderEditUpdateData } from '../../Utils/Trading/OrderEditUpdateData';
import { DataCache } from '../../Commons/DataCache';
import { PriceLimitValidation } from '../../Utils/Instruments/PriceLimitValidation';
import { ApplicationPanel } from './ApplicationPanel';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Account } from '../../Commons/cache/Account';
import { type OrderEditBase } from '../../Commons/cache/OrderParams/order-edit/OrderEditBase';
import { type OEQuantity } from '../trading/OE/OEQuantity';

export class CreateAlertPanel extends ApplicationPanel {
    public headerLocaleKey: string = 'screen.Alerts.Header.Create';
    public myAlert: Alert | null = null;
    public NeedCalculateRowCount: boolean = false;
    public skipOnSave: boolean = true;
    public CallerPanel: any = null;
    public MyTimeLookupOpened: boolean = false;
    public afterSetFocus: any = null;
    public OnCloseHandler: any = null;
    public NeedQuote: boolean;
    public QuoteSkip: boolean;
    public isOE_Opened: boolean;

    public override getType (): PanelNames { return PanelNames.CreateAlertPanel; }

    public override oninit (): void {
        super.oninit();

        this.on('btnCreateOrApplyClick', this.onCreateOrApplyClick);
        this.on('btnCancelClick', this.onCancelClick);
        this.on('showOrderEntryEvent', this.showOrderEntry);
        this.on('timeLookupOpened', () => { this.MyTimeLookupOpened = true; });
        this.on('timeLookupClosed', () => { this.MyTimeLookupOpened = false; });
        this.observe('alertActionValue', this.showOrderParametrs);
        this.observe('alertImportanceValue', this.setButtonStyle);
        this.observe('OrderEditForPlace', this.orderParamAndText);
        this.observe('messageText', this.ValidationMessageText);
        this.observe('alertTypeValue', () => { this.ValidateBTN(); });
        this.observe('expirationTime', () => { this.ValidateBTN(); });
        this.observe('account', this.onAccountChanged);
        this.observe('instrument', this.onInstrumentChanged, { init: false });
        this.observe('isSymbolInfoOpened', (opened: boolean) => { if (!opened) this.setFocus(); }, { init: false });
        this.getAlertType();
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.setDefaultProperties();

        if (this.myAlert != null) { // to fix replacing acc by linking
            void this.set('account', this.myAlert.Account);
        }
    }

    public onInstrumentChanged (n: Instrument, o: Instrument): void {
        this.NeedQuote = false;
        if (!isNullOrUndefined(o)) {
            this.unsubscribeQuotes(o);
        }

        if (this.QuoteSkip) {
            return;
        }

        if (!isNullOrUndefined(n)) {
            this.subscribeQuotes(n);
        }
        this.NeedQuote = true;
        this.ValidateBTN();
    }

    public onAccountChanged (account: Account): void {
        if (isNullOrUndefined(account)) {
            return;
        }

        this.updateValue();
        this.accountLink_Out();
    }

    public setDefaultProperties (): void {
        if (!isNullOrUndefined(this.myAlert)) {
            return;
        }

        const props = this.CallerPanel.getProperties();
        void this.set('alertNotifyValue', props.NotificationVariable);
        void this.set('alertTypeValue', props.AlertTypeVariable);
        void this.set('alertConditionValue', props.ConditionVariable);
        void this.set('alertImportanceValue', props.ImportanceVariable);
        void this.set('alertExecuteValue', props.AfterExecuteVariable);
    }

    public showOrderParametrs (newValue): void {
        if (newValue === AlertAction.PLACE_ORDER_NOTIFY) {
            void this.set({ showOrderParametrs: true, visibleInfoBtn: true });
            if (this.get('OrderEditForPlace')) {
                void this.set('enabled', true);
            }
        } else {
            void this.set({ showOrderParametrs: false, visibleInfoBtn: false });
        }
        this.ValidateBTN();
    }

    public ValidationMessageText (newValue): void {
    // let tooltip = Resources.getResource('screen.Alerts.Button.Create.Tooltip')
    // if (!newValue.length)
    //     this.set({ enabledCreateAlert: false, createBtnTooltip: tooltip })
    // else
    //     this.set({ enabledCreateAlert: true, createBtnTooltip: '' })
        this.ValidateBTN();
    }

    public async orderParamAndText (newValue: OrderEditBase): Promise<void> {
        if (!isNullOrUndefined(newValue)) {
            this.CorrectingPieceOfShit(newValue);
            let text = await newValue.getConfirmationText();
            // remove fucking ' ?'
            text = text.replace(' ?', '');
            void this.set({ infoBtnTooltip: text });
        } else {
            void this.set({ infoBtnTooltip: '' });
        }

        this.ValidateBTN();
    }

    public ValidateBTN (): boolean {
        const btnEnabled = this.getBtnEnability();
        void this.set({
            enabledCreateAlert: btnEnabled,
            createBtnTooltip: this.getBtnToolTip()
        });

        return btnEnabled;
    }

    public getBtnEnability (): boolean {
        const dataOE = this.get('OrderEditForPlace');
        const dataText: string = this.get('messageText');
        const alertType: AlertType = this.get('alertTypeValue');
        const instrument: Instrument = this.get('instrument');
        const expirationTime: Date = this.get('expirationTime');

        if (this.get('alertActionValue') === AlertAction.PLACE_ORDER_NOTIFY) {
            if (!dataOE || dataText.isEmpty()) {
                return false;
            }
        }

        if (dataText.isEmpty()) {
            return false;
        }

        if (AlertType.VOLUME === alertType && instrument?.isForexSymbol()) {
            return false;
        }

        if (expirationTime <= new Date()) {
            return false;
        }

        return true;
    }

    public getBtnToolTip (): string {
        const dataOE = this.get('OrderEditForPlace');
        const dataText: string = this.get('messageText');
        const alertType: AlertType = this.get('alertTypeValue');
        const instrument: Instrument = this.get('instrument');
        const expirationTime: Date = this.get('expirationTime');

        if (this.get('alertActionValue') === AlertAction.PLACE_ORDER_NOTIFY && !dataOE) {
            return Resources.getResource('alert.specifyOrder.Error');
        }

        if (dataText.isEmpty()) {
            return Resources.getResource('screen.Alerts.Button.Create.Tooltip');
        }

        if (AlertType.VOLUME === alertType && instrument?.isForexSymbol()) {
            return Resources.getResource('alert.specifyAlertType.Error');
        }

        if (expirationTime <= new Date()) {
            return Resources.getResource('alert.specifyExpirationTime.Error');
        }

        return '';
    }

    public showOrderEntry (): void {
        const me = this;
        if (me.isOE_Opened) {
            return;
        }

        const myData = this.get('OrderEditForPlace');
        const myOrderEntryPanel: AdvancedOrderEntry = MainWindowManager.Factory.addPanel(PanelNames.AdvancedOrderEntry, null, function (panel) {
            const location = ControlsUtils.getAbsoluteLocation(me.Controls.CreateAlertOrderParametrsSelector);
            panel.observe('height', function () {
                const h = this.find('*').offsetHeight;
                const newLoc = TerceraMenu.CorrectPopupLocation(
                    new Rectangle(location.X + 181, location.Y + 30, panel.get('width'), h));
                panel.setLocation(newLoc.newX, newLoc.newY);
            });
            panel.set({
                accountVisible: false,
                canLinkByAccount: false,
                isCreateOrderParmeters: true,
                placeButtonLocalizationKey: 'panel.newOrderEntry.AddOrder',
                placeButtonAdditionalClass: 'alertStyle',
                movable: false,
                showCustomFullscreenCloud: true,
                zIndex: 290
            });
            if (!myData) {
                panel.set({ instrument: me.get('instrument') });
                return;
            }
            const data = me.PieceOfShit(myData);
            me.CorrectingPieceOfShit(data);
            panel.set({ instrument: data.instrument }).then(function () {
                // panel.setOrderEdit(data)
                panel.selectOrderType(data.getOrderTypeId());
                panel.setOrderEdit(data);

                const leverage = data.leverageValue;
                panel.set({
                    side: data.side,
                    defaultTif: data.tif,
                    productType: data.productType
                // defaultQuantity: new Quantity(data.quantity.value, data.quantity.inLots)
                }).then(() => { panel.set('leverageValue', leverage); }); // product type needs to be set first

                const OEQty: OEQuantity = !isNullOrUndefined(panel.Controls) ? panel.Controls.OEQuantity : null;
                if (!isNullOrUndefined(OEQty)) {
                    void OEQty.set('defaultQuantity', new Quantity(data.quantity.value, data.quantity.inLots));
                }
            });
        }) as AdvancedOrderEntry;
        myOrderEntryPanel.skipOnSave = true;

        me.isOE_Opened = true;
        myOrderEntryPanel.close = function () {
        // FOCUS MAGIC
            AdvancedOrderEntry.prototype.close.call(this);
            me.isOE_Opened = false;
            me.setFocus();
        };

        myOrderEntryPanel.OnComplete.Subscribe(this.onOrderEntryComplete.bind(this, myOrderEntryPanel), this);

        myOrderEntryPanel.NewParametrsHandler = function (orderEdit) {
        // параметры ОЕ дальше сетим где нужно
        // нам нужен для всех параметров orderEdit
        // я его сюда докинул, из него текст заебашить в i и данные для сохранения, а также этот обьект хранить в алерте OrderEditForPlace
        // там его серелиализуем для хранения на сервере и в обраточку заебашим для плейса при загрузке. изи катка)
        // из него будем ОЕ заполнять при переоткрытии как в assets panel

            // потом просто вызываешь плейс и всё
            // DataCache.FOrderExecutor.placeOrderPromise(orderEdit)
            if (PriceLimitValidation.SendRejectIfNotValidateLimitsByOrderEdit(orderEdit)) // #112368
            {
                return;
            }

            const myEdit = me.PieceOfShit(orderEdit);
            void me.set({ OrderEditForPlace: myEdit });
        };
    }

    public override setFocus (): void {
        super.setFocus();
        if (!isNullOrUndefined(this.afterSetFocus)) {
            this.afterSetFocus();
        }
    }

    public onOrderEntryComplete (oe): void {
        oe.OnComplete.UnSubscribe(this.onOrderEntryComplete, this);

        const acc: Account = this.get('account');
        if (!isNullOrUndefined(oe) && !isNullOrUndefined(acc)) {
            oe.set('account', acc);
        }
    }

    // public  onOrderEntryClose  (oe)
    // {
    //     oe.OnClose.UnSubscribe(this.onOrderEntryClose, this);
    //     this.isOE_Opened = false;
    //     this.setFocus();
    // }

    // GetCopy
    public PieceOfShit (orderEdit: OrderEditBase): OrderEditBase {
        const newEdit: OrderEditBase = DataCache.OrderParameterContainer.OrderTypes[orderEdit.getOrderTypeId()].createOrderEditObject({
            dataCache: DataCache
        });

        // TODO КОСТЫЛИЩЕ!!!! ЕБАНОЕ
        const edit = new OrderEditUpdateData(null, orderEdit.getTradingData());
        newEdit.placedFrom = PlacedFrom.WEB_ALERT;
        newEdit.updateParameters(edit);

        const copiedSLTP = JSON.parse(JSON.stringify(orderEdit.sltp));
        newEdit.sltp.sl = copiedSLTP.sl;
        newEdit.sltp.tp = copiedSLTP.tp;
        newEdit.sltp.trailingStop = copiedSLTP.trailingStop;

        newEdit.limitPrice = orderEdit.limitPrice;
        newEdit.stopPrice = orderEdit.stopPrice;
        newEdit.trailingStop = orderEdit.trailingStop;
        newEdit.AlerEdit = true;

        return newEdit;
    }

    // Корректирует значения слтп
    public CorrectingPieceOfShit (orderEdit: OrderEditBase): void {
        const hold = orderEdit.getRawSLTP();
        orderEdit.sltp.updateOffsetTypes();
        orderEdit.sltp.updateAllowedRules(orderEdit.instrument, orderEdit.account);
        orderEdit.setSLTP(hold);
    }

    public setButtonStyle (): void {
        const importance = this.get('alertImportanceValue');

        if (importance === AlertImportance.HIGH) {
            void this.set('advancedButtonStyle', 'js-button-create-alert-red');
        }
        if (importance === AlertImportance.MEDIUM) {
            void this.set('advancedButtonStyle', 'js-button-create-alert-yellow');
        }
        if (importance === AlertImportance.LOW) {
            void this.set('advancedButtonStyle', 'js-button-create-alert-green');
        }
    }

    public getAlertType (): void {
        void this.set({ alertTypeValue: AlertType.BID });
    }

    public onCancelClick (): void {
        this.close();
    }

    public override close (): void {
        const insDDF = MainWindowManager.TerceraInstrumentLookupDropDownForm;
        const accDDF = MainWindowManager.TerceraAccountLookupDropDownForm;
        if ((insDDF?.get<boolean>('visible')) ||
        (accDDF?.get<boolean>('visible'))) // #119762
        {
            return;
        }

        // if (this.CallerPanel)
        //     this.CallerPanel.set('visible', true).
        //         then(function ()
        //         {
        //             this.CallerPanel.setFocus();
        //         }.bind(this));

        super.close();
        if (!isNullOrUndefined(this.OnCloseHandler)) {
            this.OnCloseHandler();
        }
    }

    public override lostFocus (): void {
        super.lostFocus();
        // FOCUS MAGIC
        if (this.checkPossibilityClosing()) {
            this.close();
        }
    }

    public checkPossibilityClosing (): boolean {
        return !this.isOE_Opened && !this.MyTimeLookupOpened && !this.get<boolean>('isSymbolInfoOpened');
    }

    public onCreateOrApplyClick (): void {
        const enabled = this.ValidateBTN();
        if (!enabled) {
            return;
        }

        const paramDataToSend = new AlertSendToServerItem();
        paramDataToSend.Account = this.get('account');
        paramDataToSend.Instrument = this.get('instrument');
        paramDataToSend.AlertType = this.get('alertTypeValue');
        paramDataToSend.Condition = this.get('alertConditionValue');
        paramDataToSend.Value = this.get('alertValue');
        paramDataToSend.Importance = this.get('alertImportanceValue');
        paramDataToSend.AfterExecute = this.get('alertExecuteValue');
        paramDataToSend.Action = this.get('alertActionValue');
        paramDataToSend.Notification = this.get('alertNotifyValue');
        paramDataToSend.Expiration = this.get('expirationTime');
        paramDataToSend.MessageText = this.get('messageText');
        paramDataToSend.MessageText = paramDataToSend.MessageText.trim();
        paramDataToSend.OrderPlaceParametrs = null;
        paramDataToSend.Active = true;

        const okClick = function () {
            if (!isNullOrUndefined(this.myAlert)) {
                paramDataToSend.AlertId = this.myAlert.AlertId;
            }
            DataCache.AlertManager.SendAlertToServer(paramDataToSend);
            this.close();
        }.bind(this);

        const cancelClick = function () {
            this.isOE_Opened = false;
            this.setFocus();
        }.bind(this);
        if (paramDataToSend.Action === AlertAction.PLACE_ORDER_NOTIFY) {
            const OrderPlaceParametrs = this.get('OrderEditForPlace');
            paramDataToSend.OrderPlaceParametrs = OrderPlaceParametrs.getDataForRequest();
            this.isOE_Opened = true;
            okClick();
        } else {
            okClick();
        }
    }

    public setUpdateParams (alert: Alert): void {
        this.QuoteSkip = true;
        void this.set('account', alert.Account).then(() => {
            void this.set('instrument', alert.Instrument).then(() => {
                void this.set('alertNotifyValue', alert.Notification);
                void this.set('alertTypeValue', alert.AlertType);
                void this.set('alertConditionValue', alert.Condition);
                void this.set('alertValue', alert.Value);
                void this.set('alertImportanceValue', alert.Importance);
                void this.set('alertExecuteValue', alert.AfterExecute);

                let action = alert.Action;
                const allowPlace = AlertUtils.AllowPlaceOrder();
                if (action === AlertAction.PLACE_ORDER_NOTIFY && !allowPlace) {
                    action = AlertAction.NOTIFY;
                }

                void this.set('alertActionValue', action);
                void this.set('messageText', alert.MessageText);
                void this.set('expirationTime', alert.Expiration);

                void this.set('isUpdate', true);
                void this.set('header', Resources.getResource('screen.Alerts.Header.Edit'));
                this.QuoteSkip = false;
                if (allowPlace) {
                    void this.set({ OrderEditForPlace: alert.OrderPlaceParametrs });
                }
            });
        });

        this.myAlert = alert;
    }

    public override localize (): void {
        super.localize();

        void this.set('buttonCreateText', Resources.getResource('screen.Alerts.Button.Create'));
        void this.set('buttonApplyText', Resources.getResource('screen.PanelSettingsScreen.applyButton'));
        void this.set('buttonCancelText', Resources.getResource('general.messageBox.cancel'));
    }

    public subscribeQuotes (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }

        this.newQuote(instrument.LastQuote);
        DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_TRADES);
    }

    public unsubscribeQuotes (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }

        DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_TRADES);
    }

    public newQuote (quote): void {
        if (!isNullOrUndefined(quote) && quote.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR) {
            return;
        }
        if (this.NeedQuote) {
            const instrument = this.get('instrument');
            const value = this.getAlertValue();

            this.NeedQuote = false;
            this.unsubscribeQuotes(instrument);
            if (!isNaN(value) && value !== null) {
                void this.set({ alertValue: value });
            }
        }
    }

    public updateValue (): void {
        const val = this.getAlertValue();
        if (val != null) {
            void this.set('alertValue', val);
        }
    }

    public getAlertValue (): number {
        const instrument: Instrument = this.get('instrument');
        const account: Account = this.get('account');

        if (instrument == null || account == null) {
            return null;
        }

        const Level1 = instrument.Level1;
        const alertTypeValue = this.get('alertTypeValue');
        let value = 0;
        switch (alertTypeValue) {
        case AlertType.BID:
            value = Level1.GetBid(account);
            break;
        case AlertType.ASK:
            value = Level1.GetAsk(account);
            break;
        case AlertType.LAST:
            value = Level1.GetLastPrice(account);
            break;
        case AlertType.CHANGE:
            value = Level1.GetChangePercent(account);
            break;
        case AlertType.VOLUME:
            value = Level1.GetVolume();
            break;
        }
        return value;
    }

    public override accountLink_In (): boolean { return false; }
    public override accountLink_Out (): void { }
}

ApplicationPanel.extendWith(CreateAlertPanel, {
    data: function () {
        return {
            width: 335,
            showFooter: false,
            resizable: false,
            dockablePanel: false,
            showHeader: true,
            account: null,
            instrument: null,
            isUpdate: false,
            movable: false,
            closeBtnVisible: false,
            showOrderParametrs: false,
            visibleInfoBtn: false,
            style_addition_header: 'js-PropertySetupScreen-AdditionalHeader',
            style_addition_jsWin: 'create-alert-additional-jsWin',
            OrderEditForPlace: null,
            enabledCreateAlert: true,
            infoBtnTooltip: '',
            createBtnTooltip: '',
            expirationTime: new Date(),
            isAlertActionSelectorVisible: true,
            isSymbolInfoOpened: false,
            canFilterByAccount: false,
            canLinkByAccount: false
        };
    },
    partials: {
        bodyPartial: CreateAlertTemplate
    }
});
