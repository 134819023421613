// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../../Commons/properties/Resources';
import { QuickTableColumnResizer } from './Resizers/QuickTableColumnResizer';

export class QuickTableColumn {
    // main
    public width = 100;
    public headerText = 'Header';
    public visible = true;
    public hidden = false;
    public allowSorting = true;
    public allowGrouping = false;
    public displayedIndex = 1;
    public sortRule = QTSortRuleType.Text;
    public aligment = QTAligment.Center;

    // Colors
    public BackColor = null;
    public color = null;
    public ForeColor = null;

    public beginGroup = false;
    public MenuIndex = 1000;

    //
    // Private fields (прячем в специальном объекте)
    //
    public PRIVATE: any = {};

    public ColouringMode = ColouringModes.None;
    public ValueUpBackColor = null;
    public ValueUpForeColor = null;
    public ValueDownBackColor = null;
    public ValueDownForeColor = null;
    public headerKey: string;
    public sortIndex: number;
    public tooltipKey: string;
    public ShowTotal: boolean;
    public CanEdit: boolean;
    public columnType: number;
    public FormulaKey: string;
    public visibleDefault: any;
    public ToolTipHeaderText: string;
    public EmptyValue: any;
    public maxFilterIndex: number;
    public AutoGenerated: boolean;

    public resizer = new QuickTableColumnResizer(this);

    constructor () {
        this.PRIVATE.drawingWidth = 0;
        this.PRIVATE.drawingX = 0;
    }

    public setParameters (columnParams): void {
        this.headerText = Resources.getResource(columnParams.headerKey);
        this.headerKey = columnParams.headerKey;
        this.visible = columnParams.visible;
        this.hidden = columnParams.hidden;
        this.allowGrouping = columnParams.allowGrouping;
        this.sortIndex = columnParams.sortIndex;
        this.displayedIndex = columnParams.sortIndex;
        this.width = columnParams.width;

        // this.sortRule = columnParams.sortRule;
        this.beginGroup = columnParams.beginGroup;
        this.MenuIndex = columnParams.MenuIndex;

        const tooltipKey = this.headerKey + '.descr';
        this.tooltipKey = Resources.IsResourcePresent(tooltipKey)
            ? tooltipKey
            : this.headerKey;
    }
}

export enum ColouringModes {
    None = 0,
    Previous = 1,
    Signed = 2
}

export enum QTSortRuleType {
    Text = 1,
    Numeric = 2,
    Data = 3
}

export enum QTAligment {
    Left = 'Left',
    Center = 'Center',
    Right = 'Right'
}
