// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectSessionMessage } from '../../../../Utils/DirectMessages/DirectSessionMessage';

import { SessionOperations } from '../../../../Utils/Enums/Constants';
import { TimeSpan, TimeSpanPeriods } from '../../../../Utils/Time/TimeSpan';
import { HolidayDescription, TradingSession, TradingSessionBase } from '../../../../Utils/Session/Sessions';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class SessionMessageProcessor extends MessageProcessorBase {
// TODO.
    private getTimePoint (startUTC, offsetUTC) {
        const endUTC = new Date(startUTC.getTime() + offsetUTC.getTime());
        return {
            timeOfDayMillis: TimeSpan.FromUTCTimeOfDay(endUTC).getTime(),
            differentDays: startUTC.getDate() != endUTC.getDate()
        };
    }

    public override decode (fieldSet: PFixFieldSet): DirectSessionMessage[] {
        const msg = new DirectSessionMessage();

        const timeZoneID = fieldSet.GetValue(FieldsFactory.FIELD_TIME_ZONE_ID);

        msg.Id = fieldSet.GetValue(FieldsFactory.FIELD_ID);
        msg.Name = fieldSet.GetValue(FieldsFactory.FIELD_NAME);
        msg.CurrentSessionId = fieldSet.GetValue(FieldsFactory.FIELD_TRADE_SESSION_CURRENT_PERIOD_ID);
        msg.TimeZoneID = timeZoneID;
        msg.TimeOffset = fieldSet.GetValue(FieldsFactory.FIELD_TIME_OFFSET);
        msg.FirstDayOfWeek = fieldSet.GetValue(FieldsFactory.FIELD_DAY_INDEX) || 1; // Monday by default

        const fieldTimeZoneOffset = fieldSet.GetValue(FieldsFactory.FIELD_TIME_ZONE_OFFSET);
        if (fieldTimeZoneOffset !== null) {
        // мсек -> часы
            msg.TimeZoneOffset = fieldTimeZoneOffset / TimeSpanPeriods.MillisPerHour;
        }

        msg.UseIntradayMarginCoef = fieldSet.GetValue(FieldsFactory.FIELD_SESSIOSN_IS_INTRADAY);
        msg.BlockTrading = fieldSet.GetValue(FieldsFactory.FIELD_BLOCK_TRADING);
        // текущая дата на сервере.
        msg.ServerBeginDayDate = fieldSet.GetValue(FieldsFactory.FIELD_BEGIN_DAY_DATE);

        // HOLIDAYS тут получаем ниже на 15 строк записываем в сессии
        const holidays = fieldSet.GetGroups(FieldsFactory.HOLIDAY_GROUP);
        const sessions = fieldSet.GetGroups(FieldsFactory.SESSION_GROUP_NAME);

        if ((sessions.length === 1 && sessions[0].Count() === 0) || sessions.length === 0) {
            msg.Sessions = null;
        } else {
            msg.Sessions = [];
            const tradingSessArr = msg.Sessions;
            const len = sessions.length;
            for (let i = 0; i < len; i++) {
                const tradingSess = this.getTradingSession(sessions[i], holidays, timeZoneID, msg);
                tradingSessArr.push(tradingSess);
            }
        }

        return [msg];
    }

    private getTradingSessionBase (session, timeZoneID, msg): TradingSessionBase {
        const times = session.GetGroups(FieldsFactory.TRADE_SESSION_PERIOD_TIME_GROUP);
        const begins = new Array(times.length);
        const ends = new Array(times.length);

        const beginsFromServer = new Array(times.length);
        const endsFromServer = new Array(times.length);

        for (let i = 0, len = times.length; i < len; i++) {
        // #25967. Добавить обработку субботней сессии
        // 0 - sunday ... 5 - friday, 6-saturday, 7-shorted.
        // (раньше был: index == 6 - shortened day - by Dima kovalenko)
            const time = times[i];
            const index = time.GetValue(FieldsFactory.FIELD_DAY_INDEX) || 0; // https://tp.traderevolution.com/entity/106776 (comment dated 28.07.22)
            // к началу дня в УТС добавляем оффсет(во времени) начала/конца сессии получаем время -> UTC;
            // timeZoneID - которую присылает сервер для сессии не пользуем
            const sessionPeriodBegin = time.GetValue(FieldsFactory.FIELD_SESSION_BEGIN); // 09:00 как время задано в настройках сессии без оффсета
            const sessionPeriodEnd = time.GetValue(FieldsFactory.FIELD_SESSION_END);

            let res = this.getTimePoint(msg.ServerBeginDayDate, sessionPeriodBegin);
            let differentDays = res.differentDays;
            begins[index] = res.timeOfDayMillis;

            res = this.getTimePoint(msg.ServerBeginDayDate, sessionPeriodEnd);
            differentDays = res.differentDays;
            ends[index] = res.timeOfDayMillis;

            if (differentDays) {
                begins[index] += 1; // millis
            }

            beginsFromServer[index] = sessionPeriodBegin;
            endsFromServer[index] = sessionPeriodEnd;
        }

        const tradingSession = new TradingSessionBase(
            session.GetValue(FieldsFactory.FIELD_NAME),
            session.GetValue(FieldsFactory.FIELD_SESSION_DAY_PERIOD),
            begins,
            ends,
            beginsFromServer,
            endsFromServer);

        // текущая дата на сервере
        tradingSession.ServerBeginDayDate = msg.ServerBeginDayDate;
        tradingSession.TimeZoneID = timeZoneID;
        tradingSession.TimeOffset = msg.TimeOffset;
        tradingSession.FirstDayOfWeek = msg.FirstDayOfWeek;

        let sessionPeriodType = session.GetValue(FieldsFactory.FIELD_SESSION_PERIOD_TYPE);
        if (sessionPeriodType > 128) { sessionPeriodType = 256 - sessionPeriodType; } // подробности в #101476
        tradingSession.MainType = sessionPeriodType;

        tradingSession.SubType = session.GetValue(FieldsFactory.FIELD_SESSION_SUB_PERIOD_TYPE);
        tradingSession.Id = session.GetValue(FieldsFactory.FIELD_ID);

        tradingSession.isIntraday = session.GetValue(FieldsFactory.FIELD_SESSIOSN_IS_INTRADAY);

        tradingSession.DecreaseOnlyPositionCount =
        session.GetValue(FieldsFactory.FIELD_IS_DECREASE_ONLY_POSITION_COUNT);

        tradingSession.AllowOptionsExerciseOnLastTradeDate =
        session.GetValue(FieldsFactory.FIELD_TRADE_SESSION_OPTION_EXERCISE);

        return tradingSession;
    }

    private getTradingSession (session, holidays, timeZoneID, msg): TradingSession {
        const tradingSession = TradingSession.CreateByBase(this.getTradingSessionBase(session, timeZoneID, msg));

        this.processHolidayList(tradingSession, holidays, timeZoneID, msg);

        return tradingSession;
    }

    private processHolidayList (tradingSession, holidays, timeZoneID, msg): void {
        if (!tradingSession) {
            return;
        }

        const holidaysList = tradingSession.HolidaysList;
        for (let i = 0, len = holidays.length; i < len; i++) {
            const holiday = holidays[i];

            const name = holiday.GetValue(FieldsFactory.FIELD_NAME);
            const day = holiday.GetValue(FieldsFactory.FIELD_EXP_DAY);
            const month = holiday.GetValue(FieldsFactory.FIELD_EXP_MONTH);
            const year = holiday.GetValue(FieldsFactory.FIELD_EXP_YEAR);

            const date = day && month && year
                ? new Date(Date.UTC(year, month - 1, day))
                : null;

            // //Convert to UTC
            // let millisPerMin = 60 * 1000;
            // let dateUTC0DifferenceInMillis = date.getTimezoneOffset() * millisPerMin;
            // date = new Date(date.getTime() + dateUTC0DifferenceInMillis)

            const holidayDescr = new HolidayDescription();
            holidayDescr.Name = name;
            holidayDescr.Date = date;
            holidayDescr.HolidayType = holiday.GetValue(FieldsFactory.FIELD_DAY_TYPE);
            holidayDescr.ShortenedDaySessionPeriods = this.getShortenedDaySessionPeriods(holiday, timeZoneID, msg);

            holidaysList.push(holidayDescr);
        }
    }

    private getShortenedDaySessionPeriods (holidayMsgPart, timeZoneID, msg): TradingSessionBase[] {
        const result: TradingSessionBase[] = [];

        const holidayScheduleGroups = holidayMsgPart.GetGroups(FieldsFactory.SESSION_GROUP_NAME);
        for (let i = 0, len = holidayScheduleGroups.length; i < len; i++) {
            const holiday = holidayScheduleGroups[i];

            result.push(this.getTradingSessionBase(holiday, timeZoneID, msg));
        }

        return result;
    }
}
