// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraSeparatorTemplate } from '../../templates.js';
import { Control } from './Control';

export class TerceraSeparator extends Control {
    constructor () { super(); }

    public override getType (): string { return 'TerceraSeparator'; };
}

Control.extendWith(TerceraSeparator, {

    template: TerceraSeparatorTemplate,
    data: function () { return { isVertical: false }; }
});
