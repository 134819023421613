// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { TerceraCollapseLinkTemplate } from '../../templates.js';
import { Control } from './Control';
import { ThemeManager } from '../misc/ThemeManager';

export class TerceraCollapseLink extends Control {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();

        this.on('click', this.onClick);
        this.on('mouseOver', this.onMouseOver);
        this.on('mouseLeave', this.onMouseLeave);

        this.observe('hover', this.updateColor);
        this.observe('collapsed', this.updateImage);
        this.observe('locKey', this.updateText);

        ThemeManager.onThemeChange.Subscribe(this.themeChange, this);
        Resources.onLocaleChange.Subscribe(this.localize, this);
    }

    public override dispose (): void {
        ThemeManager.onThemeChange.UnSubscribe(this.themeChange, this);
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public onMouseOver (): boolean {
        void this.set('hover', true);
        return false;
    }

    public override onMouseLeave (): void {
        void this.set('hover', false);
    }

    public override onClick (): boolean {
        void this.toggle('collapsed');
        return false;
    }

    public updateImage (): void {
        const image = ThemeManager.getImageFullUrl(
            this.get<boolean>('collapsed')
                ? 'common_icons/16x16_chevron_horizontal_show_hover.png'
                : 'common_icons/16x16_chevron_horizontal_hide_default.png'
        );

        void this.set('image', image);
    }

    public updateColor (): void {
        const color = ThemeManager.CurrentTheme[
            this.get<boolean>('hover')
                ? 'OE_HoveredLabelTextColor'
                : 'OE_RegularLabelTextColor'
        ];

        void this.set('color', color);
    }

    public updateText (): void {
        void this.set('text', Resources.getResource(this.get('locKey')));
    }

    public themeChange (): void {
        this.updateImage();
        this.updateColor();
    };

    public localize (): void {
        this.updateText();
    };
}

Control.extendWith(TerceraCollapseLink, {
    data: function () {
        return {
            width: 0,
            height: 16,
            collapsed: true,
            hover: false,
            color: '',
            text: '',
            image: '',
            locKey: ''
        };
    },
    template: TerceraCollapseLinkTemplate
});
