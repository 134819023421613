// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// Контрол отображения счётчика обратного отсчета в панеле Trading Ideas

import { Resources } from '../../Commons/properties/Resources';
import { TimeSpanPeriods } from '../../Utils/Time/TimeSpan';
import { DateTimeLabelTemplate } from '../../templates.js';
import { Control } from './Control';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';

export class DateTimeLabel extends Control {
    public daysLocKey: string = 'dayTimeLabel.days'; // ключи локализации надписей plural
    public hoursLocKey: string = 'dayTimeLabel.hours';
    public minutesLocKey: string = 'dayTimeLabel.minutes';
    public secondsLocKey: string = 'dayTimeLabel.seconds';

    public dayLocKey: string = 'dayTimeLabel.day'; // ключи локализации в единственном числе
    public hourLocKey: string = 'dayTimeLabel.hour';
    public minuteLocKey: string = 'dayTimeLabel.minute';
    public secondLocKey: string = 'dayTimeLabel.second';

    public daysLabelLocalized: string = ''; // Resources.getResource вызываем в localize и храним потом переводы в этих переменных
    public hoursLabelLocalized: string = '';
    public minutesLabelLocalized: string = '';
    public secondsLabelLocalized: string = '';

    public dayLabelLocalized: string = '';
    public hourLabelLocalized: string = '';
    public minuteLabelLocalized: string = '';
    public secondLabelLocalized: string = '';

    public lifeTimeLocKey: string = 'dayTimeLabel.lifeTime';
    public lifeTimeExpiredLocKey: string = 'dayTimeLabel.lifeTimeExpired';

    public override oninit (): void {
        super.oninit();

        this.observe('ticks', this.onTicksChanged, { init: false });
        this.localize();
    }

    public localize (): void {
        this.daysLabelLocalized = Resources.getResource(this.daysLocKey);
        this.hoursLabelLocalized = Resources.getResource(this.hoursLocKey);
        this.minutesLabelLocalized = Resources.getResource(this.minutesLocKey);
        this.secondsLabelLocalized = Resources.getResource(this.secondsLocKey);

        this.dayLabelLocalized = Resources.getResource(this.dayLocKey);
        this.hourLabelLocalized = Resources.getResource(this.hourLocKey);
        this.minuteLabelLocalized = Resources.getResource(this.minuteLocKey);
        this.secondLabelLocalized = Resources.getResource(this.secondLocKey);

        void this.set({
            lifeTimeLabel: Resources.getResource(this.lifeTimeLocKey),
            lifeTimeExpiredLabel: Resources.getResource(this.lifeTimeExpiredLocKey),
            daysLabel: this.daysLabelLocalized,
            hoursLabel: this.hoursLabelLocalized,
            minutesLabel: this.minutesLabelLocalized,
            secondsLabel: this.secondsLabelLocalized
        });
    }

    public onTicksChanged (ticks): void {
        if (ticks?.length) return; // #94294

        const difTime = new Date(ticks);
        const utsShiftTime = difTime.getTimezoneOffset() * 60000;
        const resultTime = new Date(ticks + utsShiftTime);
        const timeLeft = ticks > TimeSpanPeriods.TicksPerSecond;

        if (timeLeft) {
            const dateTimeStr = DateTimeUtils.formatDate(resultTime, 'DD:HH:mm:ss');
            const dateTimeArr = dateTimeStr.split(':');

            const days = parseInt(dateTimeArr[0]) - 1;
            const hours = parseInt(dateTimeArr[1]);
            const minutes = parseInt(dateTimeArr[2]);
            const seconds = parseInt(dateTimeArr[3]);

            this.correctLabelEndings(days, hours, minutes, seconds);

            void this.set({ days, hours, minutes, seconds });
        }

        void this.set('expired', !timeLeft);
    }

    public correctLabelEndings (days: number, hours: number, minutes: number, seconds: number): void {
        if (days < 2) {
            void this.set('daysLabel', days === 1 ? this.dayLabelLocalized : this.daysLabelLocalized);
        }

        if (hours < 2) {
            void this.set('hoursLabel', hours === 1 ? this.hourLabelLocalized : this.hoursLabelLocalized);
        }

        if (minutes < 2) {
            void this.set('minutesLabel', minutes === 1 ? this.minuteLabelLocalized : this.minutesLabelLocalized);
        }

        if (seconds < 2) {
            void this.set('secondsLabel', seconds === 1 ? this.secondLabelLocalized : this.secondsLabelLocalized);
        }
    }
}

Control.extendWith(DateTimeLabel, {
    template: DateTimeLabelTemplate,
    data: function () {
        return {
            centered: false, // необходимо ли центрирование
            marginTop: 0, // отступ по высоте для открытой идеи
            ticks: 0, // время в тиках для отображения
            expired: false, // при окончании времени надпись Lifetime окрашивается в красный цвет

            days: NaN, // отображаемое кол-во дней
            hours: NaN, // отображаемое кол-во часов
            minutes: NaN, // отображаемое кол-во минут
            seconds: NaN, // отображаемое кол-во секунд  Задал им NaN, а не null потому что null >= 0 и при открытии идеи на секунду показывается кружочек секунд

            lifeTimeLabel: '', // Lifetime надпись
            lifeTimeExpiredLabel: '',
            daysLabel: '', // надпись - days
            hoursLabel: '', // надпись - hours
            minutesLabel: '', // надпись - minutes
            secondsLabel: '' // надпись - seconds
        };
    }
});
