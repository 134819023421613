// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { TerceraColorStyleWidthPickerTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';

export class TerceraColorStyleWidthPicker extends ContainerControl {
    public ColorStyleWidthChanged: CustomEvent;

    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.ColorStyleWidthChanged = new CustomEvent();
        this.observe('selectedLineStyleItem selectedLineWidthItem', this.updateLineStyleAndWidth);
        this.observe('colorStyleWidth', this.onColorStyleWidthChanged);
        this.observe('colorStyleWidth', this.onColorStyleWidthChanged);
    }

    public updateLineStyleAndWidth (): void {
        const colorStyleWidth = this.get('colorStyleWidth');
        if (isNullOrUndefined(colorStyleWidth)) return;

        const selectedLineStyleItem = this.get('selectedLineStyleItem');
        if (!isNullOrUndefined(selectedLineStyleItem)) {
            void this.set('colorStyleWidth.Style', selectedLineStyleItem.value);
        }

        const selectedLineWidthItem = this.get('selectedLineWidthItem');
        if (!isNullOrUndefined(selectedLineWidthItem)) {
            void this.set('colorStyleWidth.Width', selectedLineWidthItem.value);
        }
    }

    public onColorStyleWidthChanged (newCSW, oldCSW, keyPath): void {
        if (newCSW && newCSW !== oldCSW) {
            void this.set({
                defaultLineStyle: newCSW.Style,
                defaultLineWidth: newCSW.Width
            });
        }

        this.ColorStyleWidthChanged.Raise(this, newCSW);
    }
}

ContainerControl.extendWith(TerceraColorStyleWidthPicker, {
    data: function () {
        return {
            height: 25,
            labelText: '',
            colorStyleWidth: null,
            selectedLineStyleItem: null,
            selectedLineWidthItem: null,
            defaultLineStyle: null,
            defaultLineWidth: null,
            styleItems: [],
            useStyleProperties: false
        };
    },
    template: TerceraColorStyleWidthPickerTemplate
});
