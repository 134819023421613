import { DataCache } from '../../Commons/DataCache';
import { type SessionSettings } from '../../Commons/SessionSettings';
import { type Account } from '../../Commons/cache/Account';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type OrderTypeBase } from '../../Commons/cache/OrderParams/order-type/OrderTypeBase';
import { OrderTypeBaseParameters } from '../../Commons/cache/OrderParams/order-type/OrderTypeBaseParameters';
import { type SavedOrder } from '../../Commons/cache/SavedOrders/SavedOrder';
import { LinkedPriceType } from '../../Commons/cache/SavedOrders/SavedOrderEnums';
import { Resources } from '../../Commons/properties/Resources';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { Intervals } from '../../Utils/Instruments/Intervals';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { NumericUtils } from '../../Utils/NumericUtils';
import { OperationType } from '../../Utils/Trading/OperationType';
import { TIF } from '../../Utils/Trading/OrderTif';
import { OrderTifMap, sortTifs } from '../../Utils/Trading/OrderTifEnum';
import { type OrderType, sortOrderTypes } from '../../Utils/Trading/OrderType';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { Quantity } from '../../Utils/Trading/Quantity';
import { BaseItem } from './BaseItem';
import { EventEmitter } from 'events';

export abstract class SavedOrderItemBase<T extends SavedOrder> extends BaseItem {
    private readonly _eventEmitter: EventEmitter = new EventEmitter();
    public readonly savedOrder: T;
    public QuickTableEditingInfoMap: object;

    constructor (savedOrder: T, sessionSettings: typeof SessionSettings) {
        super(sessionSettings);
        this.savedOrder = savedOrder;
        if (!isNullOrUndefined(this.savedOrder)) {
            this.initEditingInfo();
            this.updateEditingInfo();
            this.subscribe();
        }
    }

    private subscribe (): void {
        if (isNullOrUndefined(this.savedOrder)) {
            return;
        }
        this.savedOrder.SubscribeOnUpdate(this.onUpdateSavedOrder);
        this.savedOrder.SubscribeOnUpdateInstrument(this.onUpdateSavedOrderInstrument);
        this.savedOrder.SubscribeOnUpdateDefaultPrices(this.onUpdateSavedOrderDefaultPrices);
    }

    private unsubscribe (): void {
        if (isNullOrUndefined(this.savedOrder)) {
            return;
        }
        this.savedOrder.UnsubscribeOnUpdate(this.onUpdateSavedOrder);
        this.savedOrder.UnsubscribeOnUpdateInstrument(this.onUpdateSavedOrderInstrument);
        this.savedOrder.UnSubscribeOnUpdateDefaultPrices(this.onUpdateSavedOrderDefaultPrices);
    }

    protected readonly onUpdateSavedOrder = (): void => {
        super.fireUpdate();
        this._eventEmitter.emit('update', this);
    };

    protected readonly onUpdateSavedOrderInstrument = (): void => {
        this.updateEditingInfo();
    };

    protected readonly onUpdateSavedOrderDefaultPrices = (): void => {
        this.updateEditingInfo();
    };

    protected readonly getOpeationComboboxItems = (): Array<{ text: string, value: number }> => {
        const operationComboboxItems: Array<{ text: string, value: number }> = [];
        operationComboboxItems.push({ text: Resources.getResource('general.trading.Buy'), value: OperationType.Buy });
        operationComboboxItems.push({ text: Resources.getResource('general.trading.Sell'), value: OperationType.Sell });
        return operationComboboxItems;
    };

    protected readonly getOrderTypeComboboxItems = (): Array<{ text: string, value: number }> => {
        const orderTypesComboboxItems: Array<{ text: string, value: number }> = [];
        const orderTypes: object = DataCache.OrderParameterContainer.OrderTypes;
        const supportedParamObj = new OrderTypeBaseParameters(this.savedOrder.Instrument, this.savedOrder.Account);
        let supportedOrderTypes: number[] = [];
        for (const key in orderTypes) {
            const orderType: OrderTypeBase = orderTypes[key];
            const isSupportedOrderType: boolean = orderType.IsSupported(supportedParamObj);
            if (!isSupportedOrderType || !this.isAllowOrderType(orderType.id())) {
                continue;
            };
            supportedOrderTypes.push(orderType.id());
        }
        supportedOrderTypes = sortOrderTypes(supportedOrderTypes);
        for (const supportedOrderType of supportedOrderTypes) {
            orderTypesComboboxItems.push({ text: Resources.getResource(`property.${OrderUtils.getOrderTypeLocalizationKey(supportedOrderType)}`), value: supportedOrderType });
        }
        return orderTypesComboboxItems;
    };

    protected readonly getTifComboboxItems = (): { items: Array<{ text: string, value: number }>, selectedItem: any } => {
        const tifComboboxItems: Array<{ text: string, value: number }> = [];
        const selectedTif = new TIF(this.savedOrder.TIF);

        if (isNullOrUndefined(this.savedOrder.Instrument) || isNullOrUndefined(this.savedOrder.OrderType)) {
            return { items: tifComboboxItems, selectedItem: selectedTif };
        }

        if (DataCache.OrderParameterContainer === null) {
            return { items: tifComboboxItems, selectedItem: selectedTif };
        }

        const supportedParamObj = new OrderTypeBaseParameters(this.savedOrder.Instrument, this.savedOrder.Account);
        const currentOrderType = DataCache.OrderParameterContainer.GetOrderType(this.savedOrder.OrderType);
        if (isNullOrUndefined(currentOrderType)) {
            return { items: tifComboboxItems, selectedItem: selectedTif };
        };
        const isSupportedOrderType: boolean = currentOrderType.IsSupported(supportedParamObj);
        if (!isSupportedOrderType) {
            return { items: tifComboboxItems, selectedItem: selectedTif };
        }

        const route = DataCache.getRouteByName(this.savedOrder.Instrument.Route);
        const routeTifsMap = route.DictOrderTypeTifs[this.savedOrder.OrderType];
        if (isNullOrUndefined(routeTifsMap)) {
            return { items: tifComboboxItems, selectedItem: selectedTif };
        }
        const tifs = sortTifs(Object.values(routeTifsMap));
        for (const tif of tifs) {
            tifComboboxItems.push({ text: Resources.getResource(OrderTifMap[tif]), value: tif });
        }

        return { items: tifComboboxItems, selectedItem: selectedTif };
    };

    protected readonly getProductTypeComboboxItems = (): Array<{ text: string, value: number }> => {
        const account: Account = this.savedOrder.Account;
        const instrument: Instrument = this.savedOrder.Instrument;
        const productTypesComboboxItems: Array<{ text: string, value: number }> = [];

        if (isNullOrUndefined(account) || isNullOrUndefined(instrument)) {
            return productTypesComboboxItems;
        }

        const dict: ProductType[] = !isNullOrUndefined(account.RiskPlan) ? account.RiskPlan.GetRisksForInstrument(instrument.GetInteriorID()).availableProductTypes : InstrumentUtils.getAllowedProductTypeDict(instrument);
        if (dict.length === 0) {
            return productTypesComboboxItems;
        }
        if (dict.length === 1 && Number(dict[0]) === ProductType.General) {
            return productTypesComboboxItems;
        }

        for (const productType of dict) {
            productTypesComboboxItems.push({ text: InstrumentUtils.GetLocalizedProductType(instrument, productType), value: productType });
        }
        return productTypesComboboxItems;
    };

    protected readonly getLinkedPriceTypeComboboxItems = (): Array<{ text: string, value: number }> => {
        const linkedPriceTypeComboboxItems: Array<{ text: string, value: number }> = [];
        linkedPriceTypeComboboxItems.push({ text: SavedOrderItemBase.LocalizeLinkPriceType(LinkedPriceType.None), value: LinkedPriceType.None });
        linkedPriceTypeComboboxItems.push({ text: SavedOrderItemBase.LocalizeLinkPriceType(LinkedPriceType.Bid), value: LinkedPriceType.Bid });
        linkedPriceTypeComboboxItems.push({ text: SavedOrderItemBase.LocalizeLinkPriceType(LinkedPriceType.Ask), value: LinkedPriceType.Ask });
        linkedPriceTypeComboboxItems.push({ text: SavedOrderItemBase.LocalizeLinkPriceType(LinkedPriceType.Last), value: LinkedPriceType.Last });
        return linkedPriceTypeComboboxItems;
    };

    protected readonly getLeverageComboboxItems = (): Array<{ text: string, value: number }> => {
        const account: Account = this.savedOrder.Account;
        const instrument: Instrument = this.savedOrder.Instrument;
        const productType: ProductType = this.savedOrder.ProductType;
        const leverageComboboxItems: Array<{ text: string, value: number }> = [];

        if (isNullOrUndefined(account) || isNullOrUndefined(instrument)) {
            return leverageComboboxItems;
        }

        const isLeverageVisible: boolean = instrument.isLeverageVisible(account, productType);
        if (isLeverageVisible) {
            const leverages: number[] = instrument.getLeverages(account, productType);
            for (const leverage of leverages) {
                leverageComboboxItems.push({ text: leverage.toString(), value: leverage });
            }
        }

        return leverageComboboxItems;
    };

    protected isAllowOrderType (orderType: OrderType): boolean {
        return true;
    }

    protected updatePriceNumeric (index: number, minValue: number = undefined): void {
        const instrument = this.savedOrder.Instrument;
        let min: number = isValidNumber(minValue) ? minValue : 0;
        let decimalPlaces: number = 0;
        let increment: number = 1;
        let intervals: any = null;
        if (!isNullOrUndefined(instrument)) {
            const numericLimits = NumericUtils.getNumericsOffsetModeViewParams(instrument, false, false);
            intervals = Intervals.GetIntervalsFromInstrument(instrument, NumericUtils.MAXVALUE);
            if (!isValidNumber(minValue)) {
                min = numericLimits.step;
            }
            decimalPlaces = numericLimits.numericsPrec;
            increment = numericLimits.step;
        }
        const priceNumeric = this.QuickTableEditingInfoMap[index];
        priceNumeric.Min = min;
        priceNumeric.Max = NumericUtils.MAXVALUE;
        priceNumeric.Inc = increment;
        priceNumeric.DecimalPlaces = decimalPlaces;
        priceNumeric.IntervalsListForNumeric = intervals;
    }

    protected updateOffsetNumeric (index: number, isAllowNegative: boolean = false, isTrailingStop: boolean = false, minValue: number = undefined): void {
        const instrument = this.savedOrder.Instrument;
        let min: number = isAllowNegative ? -NumericUtils.MAXVALUE : 1;
        if (isValidNumber(minValue)) {
            min = minValue;
        }
        let decimalPlaces: number = 0;
        let increment: number = 1;
        if (!isNullOrUndefined(instrument)) {
            const numericLimits = NumericUtils.getNumericsOffsetModeViewParams(instrument, true, isTrailingStop);
            if (!isValidNumber(minValue) && !isAllowNegative) {
                min = numericLimits.step;
            }
            decimalPlaces = numericLimits.numericsPrec;
            increment = numericLimits.step;
        }
        const priceNumeric = this.QuickTableEditingInfoMap[index];
        priceNumeric.Min = min;
        priceNumeric.Max = NumericUtils.MAXVALUE;
        priceNumeric.Inc = increment;
        priceNumeric.DecimalPlaces = decimalPlaces;
    }

    protected updateAmountNumeric (index: number): void {
        const account = this.savedOrder.Account;
        const instrument = this.savedOrder.Instrument;
        const productType = this.savedOrder.ProductType;
        const quantityNumericSettings = Quantity.getQuantityNumericSettings(instrument, account, GeneralSettings.View.displayAmountInLots(), productType);
        const amountNumeric = this.QuickTableEditingInfoMap[index];
        amountNumeric.Min = quantityNumericSettings.minValue;
        amountNumeric.Max = quantityNumericSettings.maxValue;
        amountNumeric.Inc = quantityNumericSettings.step;
        amountNumeric.DecimalPlaces = quantityNumericSettings.decimalPrecision;
    }

    public abstract initEditingInfo (): void;
    public abstract updateEditingInfo (): void;
    public GetCurrentAccount (): any { return this.savedOrder?.Account; }
    public GetCurrentInstrument (): any { return this.savedOrder?.Instrument; }
    public subsribeOnUpdate (callback: (item: SavedOrderItemBase<T>) => void): void {
        this._eventEmitter.on('update', callback);
    }

    public unsubsribeOnUpdate (callback: (item: SavedOrderItemBase<T>) => void): void {
        this._eventEmitter.off('update', callback);
    }

    public Dispose (): void {
        this.unsubscribe();
        super.Dispose();
    }

    static LocalizeLinkPriceType (type: LinkedPriceType): string {
        switch (type) {
        case LinkedPriceType.None:
            return Resources.getResource('property.None');
        case LinkedPriceType.Bid:
            return Resources.getResource('property.Bid');
        case LinkedPriceType.Ask:
            return Resources.getResource('property.Ask');
        case LinkedPriceType.Last:
            return Resources.getResource('property.Last');
        default:
            return '';
        }
    }
}
