import { type IOptionCalculator } from '../../OptionCalculator/IOptionCalculator';
import { type Greeks } from '../Greeks';

export class VolatilityLabChartDataInputParameters {
    public readonly underlierPriceStep: number;
    public readonly underlierPricePrecision: number;
    public readonly underlierPrice: number;
    public readonly optionCalculator: IOptionCalculator;
    public readonly seriesMapInputParameters: Map<Date, StrikeSeriesInfo> = new Map<Date, StrikeSeriesInfo>();

    constructor (underlierPriceStep: number, underlierPricePrecision: number, underlierPrice: number, optionCalculator: IOptionCalculator, seriesMapInputParameters: Map<Date, StrikeSeriesInfo>) {
        this.underlierPriceStep = underlierPriceStep;
        this.underlierPricePrecision = underlierPricePrecision;
        this.underlierPrice = underlierPrice;
        this.optionCalculator = optionCalculator;
        this.seriesMapInputParameters = seriesMapInputParameters;
    }
}

export class StrikeSeriesInfo {
    public readonly minATMStrike: number;
    public readonly maxATMStrike: number;
    public readonly strikesInfo: StrikeInfo[];

    constructor (minATMStrike: number, maxATMStrike: number, strikesInfo: StrikeInfo[]) {
        this.minATMStrike = minATMStrike;
        this.maxATMStrike = maxATMStrike;
        this.strikesInfo = strikesInfo;
    }
}

export class StrikeInfo {
    public readonly strikePrice: number;
    public readonly callEnabled: boolean;
    public readonly putEnabled: boolean;
    public readonly putGreeks: Greeks;
    public readonly callGreeks: Greeks;

    constructor (strikePrice: number, callEnabled: boolean, putEnabled: boolean, callGreeks: Greeks, putGreeks: Greeks) {
        this.strikePrice = strikePrice;
        this.callEnabled = callEnabled;
        this.putEnabled = putEnabled;
        this.putGreeks = putGreeks;
        this.callGreeks = callGreeks;
    }
}
