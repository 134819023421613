import { ApplicationPanelWithTable } from '../ApplicationPanelWithTable';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { OptionChainPanelTemplate } from '../../../templates.js';
import { type QuickTableRactive } from '../../elements/QuickTable/QuickTableRactive';
import { QuickTableZone, type QuickTable } from '../../elements/QuickTable/QuickTable';
import { type QuickTableRow } from '../../elements/QuickTable/QuickTableRow';
import { OptionChainTableItem } from '../../cache/OptionMaster/OptionChainTableItem';
import { OptionChainStrikesListTableItem } from '../../cache/OptionMaster/OptionChainStrikesListTableItem';
import { Resources } from '../../../Commons/properties/Resources';
import { ColouringModes } from '../../elements/QuickTable/QuickTableColumn';
import { ThemeManager } from '../../misc/ThemeManager';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { Quantity } from '../../../Utils/Trading/Quantity';
import { OptionPutCall } from '../../../Utils/Instruments/OptionPutCall';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { DynProperty, DynPropertyRelationType } from '../../../Commons/DynProperty';
import { StrikesAggregationStyle } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionChain/StrikesAggregationStyle';
import { StrikesColoringMethod } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionChain/StrikesColoringMethod';
import { HistoricalVolatilityPriceType } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionChain/HistoricalVolatilityPriceType';
import { OptionTraderUtils } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTraderUtils';
import { type HitTestInfo } from '../../elements/QuickTable/WorkingModels/HitTestInfo';
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { type Instrument } from '../../../Commons/cache/Instrument';
import { type Account } from '../../../Commons/cache/Account';
import { CustomerAccess } from '../../../Commons/CustomerAccess/CustomerAccess';

export class OptionChainPanel extends ApplicationPanelWithTable<OptionChainTableItem> {
    private _isInitialized: boolean = false;
    private _isSkipColumnsVisibleChangedEvent: boolean = false;
    private _isRecolorRows: boolean = true;
    private _isRecenterTable: boolean = true;
    private _callsQuickTable: QuickTable;
    private _putsQuickTable: QuickTable;
    private _strikesListQuickTable: QuickTable;

    private _optionTrader: OptionTrader;

    constructor () {
        super();
        this.Name = 'OptionChainPanel';
        this.headerLocaleKey = 'panel.optionChain';
        this.NeedCalculateRowCount = false;
    }

    // #region Overrides
    public override getType (): PanelNames { return PanelNames.OptionChainPanel; }

    public override oninit (): void {
        super.oninit();
        this.onInstrumentChanged = this.onInstrumentChanged.bind(this);
        this.populateStrikes = this.populateStrikes.bind(this);
        this.onQuoteChanged = this.onQuoteChanged.bind(this);
        super.observe('selectedSerieComboboxItem', this.onSelectedSerieComboboxItemChanged);
        super.observe('visible', this.onVisibleChanged);
    }

    public override onteardown (): void {
        this.teardownPutCallQuickTable(this._putsQuickTable);
        this.teardownPutCallQuickTable(this._callsQuickTable);
        this._strikesListQuickTable.OnSelectionChanged.UnSubscribe(this.onQuickTableSelectionChanged, this);
        this._strikesListQuickTable.scroll.OnValueChange.UnSubscribe(this.onQuickTableScroll, this);
        this._optionTrader.unsubscribeOnInstrumentChanged(this.onInstrumentChanged);
        this._optionTrader.unsubscribeOnSeriesDateChanged(this.populateStrikes);
        this._optionTrader.unsubscribeOnQuoteChanged(this.onQuoteChanged);
        super.onteardown();
    }

    public override jbInit (): void {
        this.initializeVariables();
        super.jbInit();
        this.initPutCallQuickTable(this._putsQuickTable);
        this.initPutCallQuickTable(this._callsQuickTable);
        this._strikesListQuickTable.allowGroupBy = false;
        this._strikesListQuickTable.lockManualSorting = true;
        this._strikesListQuickTable.canShowHeaderMenu = false;
        this._strikesListQuickTable.CustomTextAlign = 'center';
        this._strikesListQuickTable.InitializeDirect(new OptionChainStrikesListTableItem(-1));
        this._strikesListQuickTable.UpdateSortedColumns();
        this._strikesListQuickTable.SetScrollHidden(true);
        this._strikesListQuickTable.OnSelectionChanged.Subscribe(this.onQuickTableSelectionChanged, this);
        this._strikesListQuickTable.scroll.OnValueChange.Subscribe(this.onQuickTableScroll, this);
    }

    public override layoutTable (): void {
        super.layoutTable();
        for (const table of super.findAllComponents('quickTableRactive')) {
            super.layoutTableResize(table);
        }
    }

    public override localize (): void {
        super.localize();
        this._callsQuickTable.localize();
        this._putsQuickTable.localize();
        this._strikesListQuickTable.localize();

        super.set('callsLabel', Resources.getResource('panel.optionChain.call'));
        super.set('putsLabel', Resources.getResource('panel.optionChain.put'));
    }

    public override themeChange (): void {
        super.themeChange();
        if (!this._isInitialized) {
            return;
        }

        const optionChain = this._optionTrader.optionChain;
        optionChain.inTheMoneyColor = ThemeManager.CurrentTheme.OptionMasterInTheMoneyColor;
        optionChain.outTheMoneyColor = ThemeManager.CurrentTheme.OptionMasterOutTheMoneyColor;
        optionChain.atmStrikeColor = ThemeManager.CurrentTheme.OptionMasterATMStrikeColor;
        optionChain.standardDeviationColor = ThemeManager.CurrentTheme.OptionMasterStandardDeviationColor;
        optionChain.secondStandardDeviationColor = ThemeManager.CurrentTheme.OptionMasterSecondStandardDeviationColor;
        optionChain.askColor = ThemeManager.CurrentTheme.OptionMasterAskColor;
        optionChain.bidColor = ThemeManager.CurrentTheme.OptionMasterBidColor;

        const longForegroundColor = ThemeManager.CurrentTheme.TableLongBuyColor;
        const shortForegroundColor = ThemeManager.CurrentTheme.TableShortSellColor;
        const paperPostionBackColor = ThemeManager.CurrentTheme.OptionMasterPaperPositionBackgroundColor;
        super.ProcessSetColumnsColouringMode(this._callsQuickTable, OptionChainTableItem.signedColumnsIndexes, ColouringModes.Signed, longForegroundColor, shortForegroundColor, paperPostionBackColor, paperPostionBackColor);
        super.ProcessSetColumnsColouringMode(this._callsQuickTable, OptionChainTableItem.prevValueColumnsIndexes, ColouringModes.Previous);
        super.ProcessSetColumnsColouringMode(this._putsQuickTable, OptionChainTableItem.signedColumnsIndexes, ColouringModes.Signed, longForegroundColor, shortForegroundColor, paperPostionBackColor, paperPostionBackColor);
        super.ProcessSetColumnsColouringMode(this._putsQuickTable, OptionChainTableItem.prevValueColumnsIndexes, ColouringModes.Previous);

        this._callsQuickTable.themeChange();
        this._putsQuickTable.themeChange();
        this._strikesListQuickTable.themeChange();

        const strikesTableBackColor = ThemeManager.CurrentTheme.OptionMasterStrikeRowBackgroundColor;
        this._strikesListQuickTable.BackColor = strikesTableBackColor;
        this._strikesListQuickTable.rowBackColor = strikesTableBackColor;
        this._strikesListQuickTable.alternatingRowBackColor = strikesTableBackColor;
        this._isRecolorRows = true;
    }

    public override TickAsync (): void {
        super.TickAsync();
        if (!this._isInitialized) {
            return;
        }
        const isVisible: boolean = this.get<boolean>('visible');
        if (!isVisible) {
            return;
        }
        if (this._isRecolorRows) {
            this._isRecolorRows = false;
            this.recolorRows(this._callsQuickTable);
            this.recolorRows(this._putsQuickTable);
        }
        if (this._isRecenterTable) {
            const centerPrice = this.getCenterPrice();
            if (isValidNumber(centerPrice)) {
                this._isRecenterTable = false;
                this.recenterTable(centerPrice);
            }
        }
        this._callsQuickTable.Draw();
        this._callsQuickTable.needRedraw = false;
        this._callsQuickTable.needRedrawBackground = true;
        this._putsQuickTable.Draw();
        this._putsQuickTable.needRedraw = false;
        this._putsQuickTable.needRedrawBackground = true;
        this._strikesListQuickTable.Draw();
        this._strikesListQuickTable.needRedraw = false;
    }

    public override repopulate (): void {
        super.repopulate();
        this.populateSeriesCombobox();
    }

    public override updateSettings (): void {
        super.updateSettings();
        this.updateTableEditingInfo(this._callsQuickTable);
        this.updateTableEditingInfo(this._putsQuickTable);
    }

    public override Properties (): DynProperty[] {
        const properties = super.Properties();
        let index = 0;
        const separatorGroup = '#0#' + Resources.getResource('property.View');
        let dp = new DynProperty('property.StrikesAggregationStyle', this._optionTrader.optionChain.strikesAggregationStyle, DynProperty.COMBOBOX, DynProperty.OPTION_CHAIN_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.StrikesAggregationStyle.FromATMStrike'), value: StrikesAggregationStyle.FromATMStrike },
            { text: Resources.getResource('property.StrikesAggregationStyle.CustomUnderlierPrice'), value: StrikesAggregationStyle.CustomUnderlierPrice }
        ];
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.assignedProperty = ['property.CustomUnderlierPrice'];
        dp.DynPropertyRelationType = DynPropertyRelationType.Visibility;
        properties.push(dp);

        dp = new DynProperty('property.CustomUnderlierPrice', this._optionTrader.optionChain.customUnderlierPrice, DynProperty.DOUBLE, DynProperty.OPTION_CHAIN_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.enabilityValue = StrikesAggregationStyle.CustomUnderlierPrice;
        dp.minimalValue = 0;
        dp.maximalValue = 99999999;
        dp.increment = 0.01;
        dp.decimalPlaces = 2;
        properties.push(dp);

        dp = new DynProperty('property.ColoringMethod', this.getStrikesColoringMethod(), DynProperty.COMBOBOX, DynProperty.OPTION_CHAIN_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.ColoringMethod.Classic'), value: StrikesColoringMethod.Classic },
            { text: Resources.getResource('property.ColoringMethod.HistoricalVolatility'), value: StrikesColoringMethod.HistoricalVolatility }
        ];
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.assignedProperty = ['property.InTheMoneyColor', 'property.OutTheMoneyColor', 'property.CurrentHV', 'property.Price', 'property.ATMStrikeColor', 'property.StandardDeviationColor', 'property.SecondStandardDeviationColor'];
        dp.DynPropertyRelationType = DynPropertyRelationType.Visibility;
        dp.visible = this.isAllowHVColoring();
        properties.push(dp);

        dp = new DynProperty('property.InTheMoneyColor', this._optionTrader.optionChain.inTheMoneyColor, DynProperty.COLOR, DynProperty.OPTION_CHAIN_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.enabilityValue = StrikesColoringMethod.Classic;
        properties.push(dp);

        dp = new DynProperty('property.OutTheMoneyColor', this._optionTrader.optionChain.outTheMoneyColor, DynProperty.COLOR, DynProperty.OPTION_CHAIN_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.enabilityValue = StrikesColoringMethod.Classic;
        properties.push(dp);

        dp = new DynProperty('property.CurrentHV', this._optionTrader.optionChain.currentHVPercent, DynProperty.DOUBLE_WITH_REFRESH_BUTTON, DynProperty.OPTION_CHAIN_GROUP);
        dp.minimalValue = 0;
        dp.maximalValue = 1000;
        dp.increment = 0.1;
        dp.decimalPlaces = 1;
        dp.callback = async () => await this._optionTrader.optionChain.CalculateHistoricalVolatilityAsync(this._optionTrader.account, this._optionTrader.underlier);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.enabilityValue = StrikesColoringMethod.HistoricalVolatility;
        properties.push(dp);

        dp = new DynProperty('property.Price', this._optionTrader.optionChain.historicalVolatilityPriceType, DynProperty.COMBOBOX, DynProperty.OPTION_CHAIN_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.Last'), value: HistoricalVolatilityPriceType.Last },
            { text: Resources.getResource('property.BidAskAverage'), value: HistoricalVolatilityPriceType.BidAsk },
            { text: Resources.getResource('property.OHLCAverage'), value: HistoricalVolatilityPriceType.OHLC },
            { text: Resources.getResource('property.OHLAverage'), value: HistoricalVolatilityPriceType.OHL }
        ];
        dp.enabilityValue = StrikesColoringMethod.HistoricalVolatility;
        properties.push(dp);

        dp = new DynProperty('property.ATMStrikeColor', this._optionTrader.optionChain.atmStrikeColor, DynProperty.COLOR, DynProperty.OPTION_CHAIN_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.enabilityValue = StrikesColoringMethod.HistoricalVolatility;
        properties.push(dp);

        dp = new DynProperty('property.StandardDeviationColor', this._optionTrader.optionChain.standardDeviationColor, DynProperty.COLOR, DynProperty.OPTION_CHAIN_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.enabilityValue = StrikesColoringMethod.HistoricalVolatility;
        properties.push(dp);

        dp = new DynProperty('property.SecondStandardDeviationColor', this._optionTrader.optionChain.secondStandardDeviationColor, DynProperty.COLOR, DynProperty.OPTION_CHAIN_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.enabilityValue = StrikesColoringMethod.HistoricalVolatility;
        properties.push(dp);

        dp = new DynProperty('property.AskColor', this._optionTrader.optionChain.askColor, DynProperty.COLOR, DynProperty.OPTION_CHAIN_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        properties.push(dp);

        dp = new DynProperty('property.BidColor', this._optionTrader.optionChain.bidColor, DynProperty.COLOR, DynProperty.OPTION_CHAIN_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        properties.push(dp);

        return properties;
    }

    public override callBack (properties: DynProperty[]): void {
        super.callBack(properties);
        const group = DynProperty.OPTION_CHAIN_GROUP;
        let dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.StrikesAggregationStyle');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.strikesAggregationStyle = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.CustomUnderlierPrice');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.customUnderlierPrice = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.ColoringMethod');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.coloringMethod = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.InTheMoneyColor');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.inTheMoneyColor = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.OutTheMoneyColor');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.outTheMoneyColor = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.CurrentHV');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.currentHVPercent = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.Price');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.historicalVolatilityPriceType = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.ATMStrikeColor');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.atmStrikeColor = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.StandardDeviationColor');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.standardDeviationColor = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.SecondStandardDeviationColor');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.secondStandardDeviationColor = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.AskColor');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.askColor = dp.value;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.BidColor');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.optionChain.bidColor = dp.value;
        }
        this._isRecolorRows = true;
        this._isRecenterTable = true;
    }

    public updateData (): void {
        this._callsQuickTable.updateRowsCellsValues();
        this._putsQuickTable.updateRowsCellsValues();
    }
    // #endregion

    // #region  EventHandlers

    private onInstrumentChanged (): void { this.populateSeriesCombobox(); }

    private onSelectedSerieComboboxItemChanged (newSerieComboboxItem: any, prevSerieComboboxItem: any): void {
        if (!this._isInitialized) {
            return;
        }
        if (+newSerieComboboxItem === +prevSerieComboboxItem) {
            return;
        }
        const date = !isNullOrUndefined(newSerieComboboxItem) ? newSerieComboboxItem.value : undefined;
        this._optionTrader.seriesDate = date;
    }

    private onVisibleChanged (): void {
        if (!this._isInitialized) {
            return;
        }
        this.layoutTable();
    }

    private onQuickTableScroll (index): void {
        this._callsQuickTable.scroll.moveScrollToElement(index);
        this._putsQuickTable.scroll.moveScrollToElement(index);
        this._strikesListQuickTable.scroll.moveScrollToElement(index);
    }

    private onQuickTableSelectionChanged (fromMouseClick: boolean, sender: QuickTable): void {
        const selectedRowIndexes = sender.getSelectedRowsIndexes();
        if (this._callsQuickTable !== sender) {
            this._callsQuickTable.setSelectedRowsByIndexes(selectedRowIndexes);
        }
        if (this._putsQuickTable !== sender) {
            this._putsQuickTable.setSelectedRowsByIndexes(selectedRowIndexes);
        }
        if (this._strikesListQuickTable !== sender) {
            this._strikesListQuickTable.setSelectedRowsByIndexes(selectedRowIndexes);
        }
    }

    private onQuickTableVisibleColumnChanged (indexes: number[], sender: QuickTable): void {
        if (this._isSkipColumnsVisibleChangedEvent) {
            return;
        }
        this._isSkipColumnsVisibleChangedEvent = true;
        if (sender === this._callsQuickTable) {
            this._putsQuickTable.setVisibleColumn(indexes);
        } else {
            this._callsQuickTable.setVisibleColumn(indexes);
        }
        this._isSkipColumnsVisibleChangedEvent = false;
    }

    private onAfterEditItem (data): void {
        const tableItem: OptionChainTableItem = data.row.item;
        switch (data.realColumnIndex) {
        case OptionChainTableItem.PAPER_POSITION_COL_INDEX:
            {
                const instrument = tableItem.instrument;
                const newAmount = data.newValue;
                if (newAmount !== 0) {
                    let paperPosition = this._optionTrader.getPaperPosition(instrument);
                    if (isNullOrUndefined(paperPosition)) {
                        this._optionTrader.createPaperPosition(instrument);
                        paperPosition = this._optionTrader.getPaperPosition(instrument);
                    }
                    const lots = Quantity.convertAmountToLots(newAmount, instrument);
                    paperPosition.QuantityLots = Math.abs(lots);
                    paperPosition.Operation = lots > 0 ? OperationType.Buy : OperationType.Sell;
                    if (paperPosition.OrderType === OrderType.Limit) {
                        paperPosition.Price = paperPosition.Bid;
                    }
                    this._optionTrader.updatePaperPosition(instrument);
                } else {
                    this._optionTrader.removePaperPosition(instrument);
                }
            }
            break;
        }
    }

    private onColumnDisplayIndexChanged (sender: QuickTable, index: number): void {
        const columnsCount = sender.columns.length;
        const mirrorTable = sender === this._callsQuickTable ? this._putsQuickTable : this._callsQuickTable;
        for (let i = 0; i < columnsCount; i++) {
            mirrorTable.columns[i].displayedIndex = columnsCount - sender.columns[i].displayedIndex - 1;
        }
        mirrorTable.UpdateSortedColumns();
        mirrorTable.needRedrawBackground = true;
        mirrorTable.needRedraw = true;
    }

    private onTableMouseDoubleClick (sender: QuickTable, hitTestInfo: HitTestInfo): void {
        const selectedItem: OptionChainTableItem = sender.getSelectedTableItems()[0];
        if (isNullOrUndefined(selectedItem) || hitTestInfo.zone !== QuickTableZone.Table) {
            return;
        }
        const columnIndex = hitTestInfo.realColumnIndex;
        if (columnIndex !== OptionChainTableItem.BID_COL_INDEX && columnIndex !== OptionChainTableItem.ASK_COL_INDEX && columnIndex !== OptionChainTableItem.LAST_PRICE_COL_INDEX) {
            return;
        }
        const price = selectedItem.getColumnValue(columnIndex);
        const account = this._optionTrader.account;
        const instrument = selectedItem.instrument;
        MainWindowManager.Factory?.addPanel(PanelNames.AdvancedOrderEntry, null, async (panel) => {
            await panel.set({ account });
            await panel.set({ instrument });
            await panel.set({ side: OperationType.Buy });
            panel.orderEdit.setBasePrice(price);
        });
    }

    private readonly onBeforeTableContextMenuShowing = (sender: QuickTable): void => {
        const selectedItem: OptionChainTableItem = sender.getSelectedTableItems()[0];
        sender.setTableContextMenuItems([]);
        if (isNullOrUndefined(selectedItem)) {
            return;
        }
        const account: Account = this._optionTrader.account;
        const instrument: Instrument = selectedItem.instrument;
        const contextMenuItems = [];
        if (CustomerAccess.panelAllowed(PanelNames.AdvancedOrderEntry)) {
            contextMenuItems.push({
                text: Resources.getResource('panel.neworder'),
                event: () => {
                    MainWindowManager.Factory?.addPanel(PanelNames.AdvancedOrderEntry, null, (panel) => {
                        panel.set({
                            account,
                            instrument
                        });
                    });
                }
            });
        }
        if (CustomerAccess.panelAllowed(PanelNames.ChartPanel)) {
            contextMenuItems.push({
                text: Resources.getResource('panel.chart'),
                event: () => {
                    MainWindowManager.Factory?.addPanel(PanelNames.ChartPanel, null, (panel) => {
                        panel.set({
                            accountItem: account,
                            instrumentItem: instrument
                        });
                    });
                }
            });
        }
        if (contextMenuItems.length > 0) {
            sender.setTableContextMenuItems(contextMenuItems);
        }
    };

    private onQuoteChanged (): void {
        this._isRecolorRows = true;
    }
    // #endregion

    public setOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
        this._optionTrader.subscribeOnInstrumentChanged(this.onInstrumentChanged);
        this._optionTrader.subscribeOnSeriesDateChanged(this.populateStrikes);
        this._optionTrader.subscribeOnQuoteChanged(this.onQuoteChanged);
    }

    // #region Heper methods
    private initPutCallQuickTable (quickTable: QuickTable): void {
        const isPut = quickTable === this._putsQuickTable;
        quickTable.allowGroupBy = false;
        quickTable.lockManualSorting = true;
        quickTable.InitializeDirect(new OptionChainTableItem(isPut ? OptionPutCall.OPTION_PUT_VANILLA : OptionPutCall.OPTION_CALL_VANILLA, null, null));
        quickTable.UpdateSortedColumns();
        if (!isPut) {
            quickTable.SetScrollHidden(true);
        }
        quickTable.beforeTableContextMenuShowing = this.onBeforeTableContextMenuShowing;
        quickTable.OnSelectionChanged.Subscribe(this.onQuickTableSelectionChanged, this);
        quickTable.OnVisibleColumnChanged.Subscribe(this.onQuickTableVisibleColumnChanged, this);
        quickTable.scroll.OnValueChange.Subscribe(this.onQuickTableScroll, this);
        quickTable.AfterEditItem.Subscribe(this.onAfterEditItem, this);
        quickTable.ColumnDisplayIndexChanged.Subscribe(this.onColumnDisplayIndexChanged, this);
        quickTable.onTableMouseDoubleClick.Subscribe(this.onTableMouseDoubleClick, this);

        // Mirror the table
        if (isPut) {
            for (let i = 0; i < quickTable.columns.length; i++) {
                const column = quickTable.columns[i];
                column.displayedIndex = quickTable.columns.length - column.displayedIndex - 1;
            }
            quickTable.UpdateSortedColumns();
            quickTable.needRedrawBackground = true;
            quickTable.needRedraw = true;
        }
    }

    private teardownPutCallQuickTable (quickTable: QuickTable): void {
        quickTable.OnSelectionChanged.UnSubscribe(this.onQuickTableSelectionChanged, this);
        quickTable.OnVisibleColumnChanged.UnSubscribe(this.onQuickTableVisibleColumnChanged, this);
        quickTable.scroll.OnValueChange.UnSubscribe(this.onQuickTableScroll, this);
        quickTable.AfterEditItem.UnSubscribe(this.onAfterEditItem, this);
        quickTable.ColumnDisplayIndexChanged.UnSubscribe(this.onColumnDisplayIndexChanged, this);
        quickTable.onTableMouseDoubleClick.UnSubscribe(this.onTableMouseDoubleClick, this);
    }

    private initializeVariables (): void {
        const tables: Array<QuickTableRactive<OptionChainTableItem>> = super.findAllComponents('quickTableRactive');
        for (let i = 0; i < tables.length; i++) {
            const quickTableRactive = tables[i];
            switch (quickTableRactive.get('name')) {
            case 'callsTable':
                this._callsQuickTable = quickTableRactive.quickTable;
                break;
            case 'putsTable':
                this._putsQuickTable = quickTableRactive.quickTable;
                break;
            case 'strikesListTable':
                this._strikesListQuickTable = quickTableRactive.quickTable;
                break;
            }
        }
        this._isInitialized = true;
    }

    private populateSeriesCombobox (): void {
        const seriesDates = this._optionTrader.seriesDates;
        const newSeriesComboboxItems = [];
        let selectedSerieComboboxItem = null;
        let selectedSerieDaysTillExpiration = null;
        for (let i = 0; i < seriesDates.length; i++) {
            const seriesDate = new Date(seriesDates[i]);
            const daysToExpiration = OptionTraderUtils.getDaysToExpirationForDate(seriesDate);
            const comboboxItem = {
                text: `${OptionTraderUtils.formatExpirationDate(seriesDate)} (${daysToExpiration})`,
                value: seriesDate
            };
            newSeriesComboboxItems.push(comboboxItem);
            if (selectedSerieComboboxItem !== null) {
                if (daysToExpiration === 0) {
                    selectedSerieComboboxItem = comboboxItem;
                    selectedSerieDaysTillExpiration = daysToExpiration;
                } else if (selectedSerieDaysTillExpiration < 0) {
                    if (daysToExpiration > selectedSerieDaysTillExpiration) {
                        selectedSerieDaysTillExpiration = daysToExpiration;
                        selectedSerieComboboxItem = comboboxItem;
                    }
                } else if (selectedSerieDaysTillExpiration > 0) {
                    if (daysToExpiration > 0 && daysToExpiration < selectedSerieDaysTillExpiration) {
                        selectedSerieDaysTillExpiration = daysToExpiration;
                        selectedSerieComboboxItem = comboboxItem;
                    }
                }
            } else {
                selectedSerieComboboxItem = comboboxItem;
                selectedSerieDaysTillExpiration = daysToExpiration;
            }
        }
        void super.set('seriesComboboxItems', newSeriesComboboxItems);
        void super.set('selectedSerieComboboxItem', selectedSerieComboboxItem);
    }

    private populateStrikes (): void {
        if (!this._isInitialized) {
            return;
        }

        this._callsQuickTable.ClearAll();
        this._putsQuickTable.ClearAll();
        this._strikesListQuickTable.ClearAll();

        const options = this._optionTrader.options;
        for (let i = 0; i < options.length; i++) {
            const option = options[i];
            const putTableItem = new OptionChainTableItem(OptionPutCall.OPTION_PUT_VANILLA, option.put, this._optionTrader);
            const callTableItem = new OptionChainTableItem(OptionPutCall.OPTION_CALL_VANILLA, option.call, this._optionTrader);
            const strikeListTableItem = new OptionChainStrikesListTableItem(option.strikePrice);
            this._putsQuickTable.AddItem(putTableItem);
            this._callsQuickTable.AddItem(callTableItem);
            this._strikesListQuickTable.AddItem(strikeListTableItem);
        }
        this._isRecolorRows = true;
        this._isRecenterTable = true;
    }

    private updateTableEditingInfo (table: QuickTable): void {
        const rows: QuickTableRow[] = table.rowsArray;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const item: OptionChainTableItem = rows[i].item;
            item.updateEditingInfo();
        }
    }

    private isAllowHVColoring (): boolean {
        return this._optionTrader.optionChain.isAllowHVColoring(this._optionTrader.fakeOption);
    }

    private getStrikesColoringMethod (): StrikesColoringMethod {
        return this.isAllowHVColoring() ? this._optionTrader.optionChain.coloringMethod : StrikesColoringMethod.Classic;
    }

    private recolorRows (quickTable: QuickTable): void {
        const coloringMode = this.getStrikesColoringMethod();
        switch (coloringMode) {
        case StrikesColoringMethod.Classic:
            this.recolorClassic(quickTable);
            break;
        case StrikesColoringMethod.HistoricalVolatility:
            this.recolorHistoricalVolatility(quickTable);
            break;
        }
    }

    private recolorClassic (quickTable: QuickTable): void {
        const optionChain = this._optionTrader.optionChain;
        const account = this._optionTrader.account;
        const underlier = this._optionTrader.underlier;
        const rows = quickTable.rowsArray;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const row: QuickTableRow = rows[i];
            const item: OptionChainTableItem = row.item;
            if (isNullOrUndefined(item.instrument)) {
                continue;
            }
            row.BackColor = optionChain.getColorInOutMoney(account, underlier, item.instrument);
            row.cells[OptionChainTableItem.ASK_COL_INDEX].BackColor = optionChain.askColor;
            row.cells[OptionChainTableItem.BID_COL_INDEX].BackColor = optionChain.bidColor;
        }
    }

    private recolorHistoricalVolatility (quickTable: QuickTable): void {
        const optionChain = this._optionTrader.optionChain;
        const rows = quickTable.rowsArray;
        const historicalVolatilityData = optionChain.getHistoricalVolatilityData(this._optionTrader.account, this._optionTrader.underlier);
        let bottomNearest: QuickTableRow;
        let topNearest: QuickTableRow;
        let equal: QuickTableRow;
        // Level1/Level2
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isGroupRow) {
                continue;
            }
            const row: QuickTableRow = rows[i];
            const item: OptionChainTableItem = row.item;
            if (isNullOrUndefined(item.instrument)) {
                continue;
            }
            row.BackColor = null;
            row.cells[OptionChainTableItem.ASK_COL_INDEX].BackColor = optionChain.askColor;
            row.cells[OptionChainTableItem.BID_COL_INDEX].BackColor = optionChain.bidColor;
            const strike = item.instrument.StrikePrice;
            if (strike >= historicalVolatilityData.lvl2Left && strike <= historicalVolatilityData.lvl2Right) {
                row.BackColor = optionChain.secondStandardDeviationColor;
            }
            if (strike >= historicalVolatilityData.lvl1Left && strike <= historicalVolatilityData.lvl1Right) {
                row.BackColor = optionChain.standardDeviationColor;
            }

            if (!isNullOrUndefined(equal) && strike === historicalVolatilityData.lastPrice) {
                equal = row;
                bottomNearest = undefined;
                topNearest = undefined;
            }

            if (isNullOrUndefined(equal) && isNullOrUndefined(topNearest)) {
                if (historicalVolatilityData.lastPrice - strike > 0) {
                    bottomNearest = row;
                } else {
                    topNearest = row;
                }
            }
        }

        if (!isNullOrUndefined(equal)) {
            equal.BackColor = optionChain.atmStrikeColor;
        } else if (!isNullOrUndefined(topNearest) && !isNullOrUndefined(bottomNearest)) {
            topNearest.BackColor = optionChain.atmStrikeColor;
            bottomNearest.BackColor = optionChain.atmStrikeColor;
        }
    }

    private recenterTable (centerPrice: number): void {
        let centerIndex = 0;
        const rowsCountByHeight = this._strikesListQuickTable.getRowsCountByHeight();
        const rowsArray = this._strikesListQuickTable.rowsArray;
        for (let i = 0; i < rowsArray.length; i++) {
            if (rowsArray[i].isGroupRow) {
                continue;
            }
            const item: OptionChainStrikesListTableItem = rowsArray[i].item;
            if (item.strike <= centerPrice) {
                centerIndex = i;
            } else {
                break;
            }
        }
        centerIndex = Math.max(0, Math.floor(centerIndex - (rowsCountByHeight / 2)));
        this.onQuickTableScroll(centerIndex);
    }

    private getCenterPrice (): number {
        if (!this._isInitialized) {
            return NaN;
        }
        const optionChain = this._optionTrader.optionChain;
        return optionChain.strikesAggregationStyle === StrikesAggregationStyle.CustomUnderlierPrice ? optionChain.customUnderlierPrice : this._optionTrader.getUnderlierPrice();
    }
    // #endregion
}

ApplicationPanelWithTable.extendWith(OptionChainPanel, {
    template: OptionChainPanelTemplate,
    data: function () {
        return {
            callsLabel: '',
            putsLabel: '',
            seriesComboboxItems: [],
            selectedSerieComboboxItem: null
        };
    }
});
