// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DockSystemInstance } from '../Controls/DockSystem';
import { MainWindowManager } from '../Controls/UtilsClasses/MainWindowManager';
import { WorkSpaceManager } from '../Controls/WorkSpace/WorkSpaceManager';
import { Control } from '../Controls/elements/Control';
import { DateTimeUtils } from '../Utils/Time/DateTimeUtils';
import { RecalculateBalances } from './RecalculateBalances';

export function GlobalAnimationFrameHandler (): void {
    DateTimeUtils.SetDateTimeNow();
    WorkSpaceManager.TickAsync();

    if (DockSystemInstance) {
        DockSystemInstance.proccess();
    }

    Control.Ticker.Raise();

    if (MainWindowManager.FullLogPanel) {
        MainWindowManager.FullLogPanel.TickAsync();
    }

    RecalculateBalances();

    requestAnimationFrame(GlobalAnimationFrameHandler);
}
