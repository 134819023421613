// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DataCache } from '../../../DataCache';
import { Account } from '../../../cache/Account';
import { Resources } from '../../../properties/Resources';
import { AccountFeature } from '../../../../Utils/Account/AccountFeature';
import { GeneralGroupEnum } from '../../../../Utils/Enums/AccountDetailsEnum';
import { RulesSet } from '../../../../Utils/Rules/RulesSet';
import { BaseAccountGroupController } from '../BaseControllers/BaseAccountGroupController';
import { type IGroupController } from '../../Interfaces/IGroupController';
import { RowItemDataNumberPair, RowItemDataStringPair } from '../../../cache/VerticalPanel/AccountDetails/RowItemData';
import { VerticalPanelColoringTypes } from '../../VerticalPanelColoringTypes';

export class GeneralGroupController<GroupItemsType=GeneralGroupEnum> extends BaseAccountGroupController<GroupItemsType> implements IGroupController<GroupItemsType> {
    public getGroupItemsTypes (): GroupItemsType[] {
        return [
            GeneralGroupEnum.Balance,
            GeneralGroupEnum.CurBalance,
            GeneralGroupEnum.BalancePlusAllRisks,
            // GeneralGroupEnum.AccountValue,
            GeneralGroupEnum.CreditValue,
            GeneralGroupEnum.AvailableMargin,
            GeneralGroupEnum.LockedBalance,
            GeneralGroupEnum.CashBalance,
            // GeneralGroupEnum.EstimateValue,
            GeneralGroupEnum.UnsettledCash,
            GeneralGroupEnum.IncomingFunds,
            GeneralGroupEnum.WithdrawalAvailable,
            GeneralGroupEnum.InterestRate,
            GeneralGroupEnum.StocksValue,
            GeneralGroupEnum.OptionValue,
            GeneralGroupEnum.StocksLiquidity,
            GeneralGroupEnum.Collateral,
            GeneralGroupEnum.BuyingPower,
            GeneralGroupEnum.GivenLeverage,
            GeneralGroupEnum.OpenBalance,
            GeneralGroupEnum.AvailableCash
        ] as GroupItemsType[];
    }

    public getItemValue (type: GroupItemsType): RowItemDataNumberPair {
        const curAccount = this.Account;
        const assetBalance = this.AssetBalance;
        const useAccCurrency = true; // TODO remove

        switch (type) {
        case GeneralGroupEnum.Balance:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.Balance, curAccount, assetBalance));
        case GeneralGroupEnum.CurBalance:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.ProjectedBalance, curAccount, assetBalance));
        case GeneralGroupEnum.BalancePlusAllRisks:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.BalancePlusAllRisks, curAccount, assetBalance));
        case GeneralGroupEnum.AccountValue:
            return new RowItemDataNumberPair(NaN);
        case GeneralGroupEnum.CreditValue:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.CreditValue, curAccount, assetBalance));
        case GeneralGroupEnum.AvailableMargin:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.AvailableFunds, curAccount, assetBalance));
        case GeneralGroupEnum.LockedBalance:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.BlockedBalance, curAccount, assetBalance));
        case GeneralGroupEnum.CashBalance:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.CashBalance, curAccount, assetBalance));
        case GeneralGroupEnum.WithdrawalAvailable:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.WithdrawalAvailable, curAccount, assetBalance));
        case GeneralGroupEnum.InterestRate:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.InterestRatePercent, curAccount, assetBalance));
        case GeneralGroupEnum.EstimateValue:
            return new RowItemDataNumberPair(NaN);
        case GeneralGroupEnum.UnsettledCash:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.UnsettledCash, curAccount, assetBalance));
        case GeneralGroupEnum.IncomingFunds:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.IncomingFunds, curAccount, assetBalance));
        case GeneralGroupEnum.StocksValue:
            return new RowItemDataNumberPair(useAccCurrency ? curAccount.StockValue : curAccount.StockValueCrossPrice);
        case GeneralGroupEnum.OptionValue:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.OptionValue, curAccount, assetBalance));
        case GeneralGroupEnum.StocksLiquidity:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.StocksLiquidity, curAccount, assetBalance));
        case GeneralGroupEnum.Collateral:
            return new RowItemDataNumberPair(assetBalance.Collateral);
        case GeneralGroupEnum.BuyingPower:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.BuyingPower, curAccount, assetBalance));
        case GeneralGroupEnum.GivenLeverage:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.GivenLeverage, curAccount, assetBalance));
        case GeneralGroupEnum.OpenBalance:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.OpenBalance, curAccount, assetBalance));
        case GeneralGroupEnum.TodaysPnLPercent:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.TodaysPnLPercent, curAccount, assetBalance));
        case GeneralGroupEnum.AvailableCash:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.AvailableCash, curAccount, assetBalance));
        }
    }

    public getItemFormattedValue (type: GroupItemsType): RowItemDataStringPair {
        const curAccount = this.Account;
        const assetBalance = this.AssetBalance;
        const value = this.getItemValue(type);

        switch (type) {
        case GeneralGroupEnum.Balance:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.Balance, curAccount, assetBalance));
        case GeneralGroupEnum.CurBalance:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.ProjectedBalance, curAccount, assetBalance));
        case GeneralGroupEnum.BalancePlusAllRisks:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.BalancePlusAllRisks, curAccount, assetBalance));
        case GeneralGroupEnum.AccountValue:
            return new RowItemDataStringPair('');
        case GeneralGroupEnum.CreditValue:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.CreditValue, curAccount, assetBalance));
        case GeneralGroupEnum.AvailableMargin:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.AvailableFunds, curAccount, assetBalance));
        case GeneralGroupEnum.LockedBalance:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.BlockedBalance, curAccount, assetBalance));
        case GeneralGroupEnum.CashBalance:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.CashBalance, curAccount, assetBalance));
        case GeneralGroupEnum.WithdrawalAvailable:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.WithdrawalAvailable, curAccount, assetBalance));
        case GeneralGroupEnum.InterestRate:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.InterestRatePercent, curAccount, assetBalance));
        case GeneralGroupEnum.EstimateValue:
            return new RowItemDataStringPair('');
        case GeneralGroupEnum.UnsettledCash:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.UnsettledCash, curAccount, assetBalance));
        case GeneralGroupEnum.IncomingFunds:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.IncomingFunds, curAccount, assetBalance));
        case GeneralGroupEnum.StocksValue:
            return new RowItemDataStringPair(assetBalance.formatPriceExactly(value.firstValue));
        case GeneralGroupEnum.OptionValue:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.OptionValue, curAccount, assetBalance));
        case GeneralGroupEnum.StocksLiquidity:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.StocksLiquidity, curAccount, assetBalance));
        case GeneralGroupEnum.Collateral:
            return new RowItemDataStringPair(assetBalance.formatPrice(assetBalance.Collateral));
        case GeneralGroupEnum.BuyingPower:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.BuyingPower, curAccount, assetBalance));
        case GeneralGroupEnum.GivenLeverage:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.GivenLeverage, curAccount, assetBalance));
        case GeneralGroupEnum.OpenBalance:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.OpenBalance, curAccount, assetBalance));
        case GeneralGroupEnum.TodaysPnLPercent:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.TodaysPnLPercent, curAccount, assetBalance));
        case GeneralGroupEnum.AvailableCash:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.AvailableCash, curAccount, assetBalance));
        }
    }

    public getItemNameKey (type: GroupItemsType): string {
        switch (type) {
        case GeneralGroupEnum.Balance:
            return 'panel.accounts.Balance';
        case GeneralGroupEnum.CurBalance:
            return 'panel.accounts.CurBalance';
        case GeneralGroupEnum.BalancePlusAllRisks:
            return 'panel.accounts.BalancePlusAllRisks';
        case GeneralGroupEnum.AccountValue:
            return 'panel.accounts.AccountValue';
        case GeneralGroupEnum.CreditValue:
            return 'panel.accounts.CreditValue';
        case GeneralGroupEnum.AvailableMargin:
            return 'panel.accounts.AvailableMargin';
        case GeneralGroupEnum.LockedBalance:
            return 'panel.accounts.LockedBalance';
        case GeneralGroupEnum.CashBalance:
            return 'panel.accounts.CashBalance';
        case GeneralGroupEnum.WithdrawalAvailable:
            return 'panel.accounts.WithdrawalAvailable';
        case GeneralGroupEnum.InterestRate:
            return 'panel.accounts.InterestRate';
        case GeneralGroupEnum.EstimateValue:
            return 'panel.accounts.Estimate value';
        case GeneralGroupEnum.UnsettledCash:
            return 'panel.accounts.UnsettledCash';
        case GeneralGroupEnum.IncomingFunds:
            return 'panel.accounts.IncomingFunds';
        case GeneralGroupEnum.StocksValue:
            return 'panel.accounts.StocksValue';
        case GeneralGroupEnum.OptionValue:
            return 'panel.accounts.OptionValue';
        case GeneralGroupEnum.StocksLiquidity:
            return 'panel.accounts.StocksLiquidity';
        case GeneralGroupEnum.Collateral:
            return 'panel.accounts.Collateral';
        case GeneralGroupEnum.BuyingPower:
            return 'panel.accounts.BuyingPower';
        case GeneralGroupEnum.GivenLeverage:
            return 'panel.accounts.GivenLeverage';
        case GeneralGroupEnum.OpenBalance:
            return 'panel.accounts.OpenBalance';
        case GeneralGroupEnum.AvailableCash:
            return 'panel.accounts.AvailableCash';
        };
    }

    public isHiddenItemRow (type: GroupItemsType): boolean {
        const itemNameKey = this.getItemNameKey(type);
        if (Resources.isHidden(itemNameKey)) {
            return true;
        }

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_EXT_RISK)) {
            return true;
        }
    }

    public getColoringMethod (type: GroupItemsType): VerticalPanelColoringTypes {
        return VerticalPanelColoringTypes.Default;
    }
}
