// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class CMO extends IndicatorScriptBase {
    SourcePrice: number;
    MAPeriod: number;

    constructor () {
        super();
        this.ProjectName = 'Chande Momentum Oscillator';
        this.Comments = 'Chande Momentum Oscillator';
        super.SetIndicatorLine('CMO', Color.Purple, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.SetLevelLine('Up', 50, Color.Gray, 1, LineStyle.ShapedChart);
        this.SetLevelLine('Down', -50, Color.Gray, 1, LineStyle.ShapedChart);

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Sources prices for MA', 0,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.MAPeriod = 8;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of MA for envelopese', 1, 1, 99999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'CMO(' + this.MAPeriod + ')';
    }

    public override getCustomName (): string {
        return 'CMO (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count <= this.MAPeriod) { return; }

        let sum1 = 0.0;
        let sum2 = 0.0;
        for (let i = 0; i < this.MAPeriod; i++) {
            const diff = this.CurrentData.GetPrice(this.SourcePrice, i) - this.CurrentData.GetPrice(this.SourcePrice, i + 1);
            if (diff > 0) { sum1 += diff; } else { sum2 -= diff; }
        }
        this.SetValue(0, 0, 100.0 * ((sum1 - sum2) / (sum1 + sum2)));
    }
}
