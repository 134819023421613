// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { CustomEvent } from '../../Utils/CustomEvents';
import { TerceraTabContainerTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';

export class TerceraTabContainer extends ContainerControl {
    public onTabChange: CustomEvent | null = null;
    public onCBItemClickEvent: CustomEvent | null = null;

    public override getType (): string { return 'TerceraTabContainer'; }

    public override oninit (): void {
        super.oninit();

        this.on('tabStripItem', this.tabStripItemChange);
        this.on('onComboItemClicked', this.onComboItemClicked);

        this.onTabChange = new CustomEvent();
        this.onCBItemClickEvent = new CustomEvent();
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.populateTabStrip();

        const items: TerceraTabContainerItem[] = this.get('items');

        if (isValidArray(items)) {
            items[0].active = true;
            void this.set({
                items,
                selectedItem: items[0]
            });
        }
    }

    public populateTabStrip (): void {
        const tabs = this.get('tabHeaderKeys');
        const items: TerceraTabContainerItem[] = [];

        if (!isValidArray(tabs)) {
            return;
        }

        for (let i = 0; i < tabs.length; i++) {
            const newItem = new TerceraTabContainerItem();
            newItem.text = Resources.getResource(tabs[i]);
            newItem.data = tabs[i];
            items.push(newItem);
        }

        void this.set('items', items);
    }

    public tabStripItemChange (event, index: number): void {
        const items: TerceraTabContainerItem[] = this.get('items');
        const selectedItem: TerceraTabContainerItem = this.get('selectedItem');

        const newSelectedItem = items[index];
        if (newSelectedItem === selectedItem) { return; }

        if (!isNullOrUndefined(selectedItem)) {
            selectedItem.active = false;
        }

        newSelectedItem.active = true;

        void this.set({
            items,
            selectedItem: newSelectedItem
        });

        this.onTabChange.Raise(newSelectedItem.data);
    }

    // TODO: Need to check order of parameters
    public onComboItemClicked (context, dataType, selectedItem): void {
        void this.set('selectedCBItem', selectedItem);
        this.onCBItemClickEvent.Raise(selectedItem);
    }
}

export class TerceraTabContainerItem {
    public text: string = '';
    public data: string = '';
    public active: boolean = false;
};

ContainerControl.extendWith(TerceraTabContainer, {

    template: TerceraTabContainerTemplate,
    data: function () {
        return {
            tabWidth: 20,
            items: [],
            selectedItem: null,
            selectedCBItem: null,
            cbItems: [],
            tabHeaderKeys: null
        };
    }
});
