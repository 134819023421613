// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type VerticalPanelDataProvider } from '../../Controls/cache/VerticalPanel/VerticalPanelDataProvider';
import { AccountDetailsControllersDistributor } from '../VerticalGroupedPanels/Distributors/AccountDetailsControllersDistributor';
import { type IGroupController } from '../VerticalGroupedPanels/Interfaces/IGroupController';
import { CustomEvent } from '../../Utils/CustomEvents';
import { type AccountDetailsGroupItemsEnumType, type AccountDetailsGroupsEnum } from '../../Utils/Enums/AccountDetailsEnum';
import { type Account } from '../cache/Account';
import { type AccountWidgetCoordinates } from './AccountWidgetCoordinates';
import { type AssetBalance } from '../cache/AssetBalance';

class _AccountWidgetAccountDetailsPanelController {
    public DataSource: AccountDetailsControllersDistributor = null;
    public AccountDetailsPanel = null;
    public ShowAccountDetailsPanel: CustomEvent = new CustomEvent();
    public HideAccountDetailsPanel: CustomEvent = new CustomEvent();

    public HasPanel (): boolean {
        return !isNullOrUndefined(this.AccountDetailsPanel);
    }

    public doHideAccountDetailsPanel (): void {
        this.HideAccountDetailsPanel.Raise();
    }

    getDataProvider (): VerticalPanelDataProvider {
        if (this.getDataProviderHandler !== null) {
            return this.getDataProviderHandler();
        }

        return null;
    }

    public getDataSource (type: AccountDetailsGroupsEnum): IGroupController<AccountDetailsGroupItemsEnumType> {
        if (isNullOrUndefined(this.DataSource)) {
            return null;
        }

        return this.DataSource.getGroupController(type);
    }

    public setDataSourceAccount (value: Account): void {
        if (isNullOrUndefined(this.DataSource)) {
            this.DataSource = new AccountDetailsControllersDistributor(value);
        } else {
            this.DataSource.Account = value;
        }
    }

    public setDataSourceAsset (value: AssetBalance): void {
        if (!isNullOrUndefined(value) && !isNullOrUndefined(this.DataSource)) {
            this.DataSource.AssetBalance = value;
        }
    }

    public accountLinkOut (newSubscriber: boolean, account?: Account, sameAccs?: boolean): void {
        this.onAccountLinkOut.Raise(newSubscriber, account, sameAccs);
    }

    public onAccountLinkOut: CustomEvent = new CustomEvent();
    public onRePopulatedPanel: CustomEvent = new CustomEvent();
    public onAssetSet: CustomEvent = new CustomEvent();

    public onTableMouseUP: (hittestInfo) => void;
    public onTableMouseDown: (hittestInfo) => void;
    public onTableMouseMove: (hittestInfo) => void;
    public onPanelOpen: () => void = () => {};
    public onPanelClose: () => void = () => {};
    public isMouseOnPanelHandler: () => boolean = null;

    public getDataProviderHandler: () => VerticalPanelDataProvider = null;
    public getDataSourceHandler: () => any[] = null;

    public raiseonRePopulatedPanel (): void {
        this.onRePopulatedPanel.Raise();
    }

    public raiseonAssetSet (id: number): void {
        this.onAssetSet.Raise(id);
    }

    public doShowAccountDetailsPanel (coordinates: AccountWidgetCoordinates): void {
        this.ShowAccountDetailsPanel.Raise(coordinates);
    }

    public isMouseOnPanel (): boolean {
        if (this.isMouseOnPanelHandler !== null) {
            return this.isMouseOnPanelHandler();
        }

        return false;
    }
}

export const AccountWidgetAccountDetailsPanelController = new _AccountWidgetAccountDetailsPanelController();
