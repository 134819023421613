// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { type iBuildInIndicator } from '../iBuildInIndicator';

export class OBV extends IndicatorScriptBase {
    public ExpirationDate: number;
    public Password: string;
    public sourcePrices: number;

    private obv: iBuildInIndicator;

    constructor () {
        super();
        // #region Initialization
        this.Author = 'Joe Granville';
        this.Comments = 'On Balance Volume (OBV) measures buying and selling pressure as a cumulative indicator that adds volume on up days and subtracts volume on down days';
        this.Company = 'TraderEvolution';
        this.Copyrights = '(c) TraderEvolution';
        this.DateOfCreation = '26.11.2015';
        this.ExpirationDate = 0;
        this.Version = '';
        this.Password = '66b4a6416f59370e942d353f08a9ae36';
        this.ProjectName = 'On Balance Volume';
        // #endregion

        this.SetIndicatorLine('OBV', Color.DodgerBlue, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.sourcePrices = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('sourcePrices', 'Sources prices for OBV', 0,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName('OBV');
    };

    public override GetIndicatorShortName (): string {
        return 'OBV';
    };

    public override getCustomName (): string {
        return 'OBV (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.obv = this.Indicators.iOBV(this.CurrentData, this.sourcePrices);
        return [this.obv];
    };

    public OnQuote (): void {
        super.OnQuote();
        this.SetValue(0, this.obv.GetValue());
    };
}
