// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
// TODO хуже панель нужно поискать
// TODO рождена в муках
// TODO живёт в муках
// TODO и будет страдать в муках, каждый кто будет ее править

import { Resources } from '../../Commons/properties/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { AccountWidgetTemplate } from '../../templates.js';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { ACCOUNT_WIDGET_MENU_TOP_OFFSET } from '../UtilsClasses/SizeConstants';
import { Control } from './Control';
import { ContainerControl } from './ContainerControl';
import { Account } from '../../Commons/cache/Account';
import { DynProperty } from '../../Commons/DynProperty';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { CustomerAccess } from '../../Commons/CustomerAccess/CustomerAccess';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';
import { type AccountWidgetItem } from './AccountWidget/AccountWidgetItem';
import { AccountWidgetAssetsController } from '../../Commons/AccountWidget/AccountWidgetAssetsController';
import { AccountWidgetAccountsController } from '../../Commons/AccountWidget/AccountWidgetAccountsController';
import { AccountWidgetMenu } from './AccountWidget/AccountWidgetMenu';
import { AccountWidgetAccountDetailsPanelController } from '../../Commons/AccountWidget/AccountWidgetAccountDetailsPanelController';
import { AccountWidgetDragAndDrop } from './AccountWidget/AccountWidgetDragAndDrop';
import { AccountUtils } from '../../Commons/cache/AccountUtils';
import { Rectangle } from '../../Commons/Geometry';
import { InstrumentTypesColor, InstrumentTypesShortName } from '../../Utils/Instruments/InstrumentTypes';
import { type Timeout } from 'react-number-format/types/types';
import { AccountWidgetCoordinates } from '../../Commons/AccountWidget/AccountWidgetCoordinates';
import { type NewAccountDetailsPanel } from '../panels/VerticalPanels/NewAccountDetailsPanel';
import { GeneralGroupEnum, MarginGroupEnum, AccountDetailsGroupsEnum, type AccountDetailsGroupItemsEnumType } from '../../Utils/Enums/AccountDetailsEnum';
import { VerticalDetailsTypePointer } from '../../Commons/cache/VerticalPanel/VerticalDetailsTypePointer';

export class AccountWidget extends ContainerControl {
    public assetComboBoxValue: number = null;
    public accLinkingActive: boolean = false;
    public isMouseOnButton: boolean = false;
    public isMouseOnInfoBlock: boolean = false;

    public myAccountDetailsPanel: NewAccountDetailsPanel;
    public clickedRowTypePointer: VerticalDetailsTypePointer<AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum>;
    clickedRowIndex: number;// todo remove

    public AccountId: number;
    public DragNDropInProcess: boolean = false;
    private DragNDropTile: AccountWidgetDragAndDrop = null;

    public rectangleBtwBlockAndPanel: Rectangle = null;
    private timeoutId: Timeout = null;
    private DetailsGroupItemStateSetting: string = null;

    private readonly defTilesTypes: Array<VerticalDetailsTypePointer<AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum>> = [
        new VerticalDetailsTypePointer(GeneralGroupEnum.CurBalance, AccountDetailsGroupsEnum.General),
        new VerticalDetailsTypePointer(GeneralGroupEnum.Balance, AccountDetailsGroupsEnum.General),
        new VerticalDetailsTypePointer(MarginGroupEnum.MaintMargin, AccountDetailsGroupsEnum.Margin)];

    public oncomplete (): void {
        super.oncomplete();
        this.myAccountDetailsPanel = null;
        WorkSpaceManager.OnWorkSpaceChanged.Subscribe(this.AccountPanelReinit, this);

        // this.on('openTableEvt', this.openTable);
        this.on('itemDragNDropEnd', this.itemDragNDropEnd);

        this.on('accWidgetBtnContainerOver', this.accWidgetBtnContainerOver);
        this.on('accWidgetBtnContainerLeave', this.accWidgetBtnContainerLeave);
        this.on('accWidgetBtnMouseDown', this.accWidgetBtnMouseDown.bind(this));

        this.on('accWidgetInfoBlockContainerOver', this.accWidgetInfoBlockContainerOver);
        this.on('accWidgetInfoBlockContainerLeave', this.accWidgetInfoBlockContainerLeave);

        Control.Ticker.Subscribe(this.UpdateData, this);
        SessionSettings.AdditionalPropertiesHandlers.push(this.Properties.bind(this));
        SessionSettings.AdditionalCallBacksHandlers.push(this.callBack.bind(this));
        Resources.onLocaleChange.Subscribe(this.Localize, this);

        AccountWidgetAccountsController.subscribeOnAccountChanged(this.onAccountChanged);

        AccountWidgetAssetsController.subscribeOnAssetChanged(() => { SessionSettings.save(); });

        AccountWidgetAccountDetailsPanelController.onTableMouseUP = (hittestInfo) => { this.onTableMouseUP(hittestInfo); };
        AccountWidgetAccountDetailsPanelController.onTableMouseDown = (hittestInfo) => { this.onTableMouseDown(hittestInfo); };
        AccountWidgetAccountDetailsPanelController.onTableMouseMove = (hittestInfo) => { this.onTableMouseMove(hittestInfo); };
        AccountWidgetAccountDetailsPanelController.onPanelOpen = () => { void this.set('hovered', true); };
        AccountWidgetAccountDetailsPanelController.onPanelClose = () => { void this.set('hovered', false); };

        AccountWidgetAccountDetailsPanelController.onRePopulatedPanel.Subscribe(this.UpdateTilesDataProvider, this);

        MainWindowManager.MainWindow.onDisconnectCalled.Subscribe(() => { this.activateBtn(false); }, this);

        const tileArr: AccountWidgetItem[] = this.getTilesArray();
        for (let i = 0; i < tileArr.length; i++) {
            const item = tileArr[i];
            item.TileTypePointer = this.defTilesTypes[i];
        }

        MainWindowManager.MainWindow.onMouseMoveEvt.Subscribe(this.onMouseMoveEvtProcess, this);

        document.addEventListener('mouseleave', () => {
            AccountWidgetAccountDetailsPanelController.doHideAccountDetailsPanel();
            this.stopTimer();
        });
    }

    private UpdateTilesDataProvider (): void {
        const tileArr: AccountWidgetItem[] = this.getTilesArray();
        for (const item of tileArr) {
            item.Controller = AccountWidgetAccountDetailsPanelController.getDataSource(item.TileTypePointer.GroupType);
            item.Validate();
        }
    }

    private installTimer (callBack: () => void): void {
        if (isNullOrUndefined(this.timeoutId)) {
            this.startTimer(callBack, 500);
        } else {
            this.stopTimer();
            this.startTimer(callBack, 500);
        }
    }

    private startTimer (callBack: () => void, delay: number): void {
        this.timeoutId = setTimeout(() => {
            callBack();
            this.timeoutId = null;
        }, delay);
    }

    private stopTimer (): void {
        if (!isNullOrUndefined(this.timeoutId)) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }

    private accWidgetBtnContainerOver (containerContext): void {
        if (this.get('DragNDropInProcess')) {
            return null;
        }
        const callBack = (): void => {
            AccountWidgetMenu.ShowContextMenu(containerContext);
            this.isMouseOnButton = true;
        };

        this.installTimer(callBack);
    }

    private accWidgetBtnContainerLeave (containerContext): void {
        if (this.get('DragNDropInProcess')) { return; }
        this.stopTimer();
        this.isMouseOnButton = false;
        this.stopTimer();
    }

    private accWidgetBtnMouseDown (): void {
        if (this.get('DragNDropInProcess')) { return; }
        if (DataCache.EnableForceLinkingByAccount()) { return; }

        if (!DataCache.allAccountsIsOwnedByUser()) { return; }

        const accWidgetBtnSelectedClass = this.get('accWidgetBtnSelectedClass');
        const accBtnActive = accWidgetBtnSelectedClass === '';
        this.activateBtn(accBtnActive);
        this.activateLinkedSystem(accBtnActive, AccountWidgetAccountsController.Account?.BstrAccount);
    }

    private activateBtn (value: boolean): void {
        if (!DataCache.allAccountsIsOwnedByUser()) { return; }
        if (value) { void this.set({ accWidgetBtnSelectedClass: 'selected' }); } else { void this.set({ accWidgetBtnSelectedClass: '' }); }
    }

    private activateLinkedSystem (value: boolean, accountStr: string): void {
        LinkedSystem.accLinkingActive = value;
        if (value) { LinkedSystem.accLinkingOn(accountStr); } else { LinkedSystem.accLinkingOff(); }
    }

    private accWidgetInfoBlockContainerOver (containerContext): void {
        this.isMouseOnInfoBlock = true;
        if (this.myAccountDetailsPanel?.isShowed() as boolean) {
            return null;
        }

        const callBack = (): void => {
            const node = containerContext.node.parentNode;
            const x = node.offsetLeft;
            const y = 60;
            const btnHeight = node.offsetHeight - 1;
            const yForPanel = node.offsetHeight + node.offsetTop + ACCOUNT_WIDGET_MENU_TOP_OFFSET;
            this.rectangleBtwBlockAndPanel = new Rectangle(x, node.offsetTop + btnHeight, node.offsetWidth, y - btnHeight);
            const coordinates = new AccountWidgetCoordinates(x, yForPanel);
            this.ShowAccountDetailsPanel(coordinates);
        };

        this.installTimer(callBack);
    }

    private accWidgetInfoBlockContainerLeave (containerContext): void {
        this.isMouseOnInfoBlock = false;
        this.stopTimer();
    }

    public TryToClosePanel (mouseEvent): void {
        if (this.isAccountDetailsPanelShoudClose(mouseEvent)) {
            AccountWidgetAccountDetailsPanelController.doHideAccountDetailsPanel();
        }
    }

    public isAccountDetailsPanelShoudClose (mouseContext): boolean {
        if (!this.myAccountDetailsPanel?.isShowed() || !isNullOrUndefined(this.clickedRowIndex)) {
            return false;
        }

        return !this.isMouseOnInfoBlock &&
        !AccountWidgetAccountDetailsPanelController.isMouseOnPanel() &&
        !this.rectangleBtwBlockAndPanel.Contains(mouseContext.event.clientX, mouseContext.event.clientY);
    }

    private onMouseMoveEvtProcess (event): void {
        this.TryToClosePanel(event);
        this.DragNDropTile?.updatePosition(event);
    }

    private itemDragNDropEnd (sender: any): void {
        const item: AccountWidgetItem = sender.component;
        item.Controller = AccountWidgetAccountDetailsPanelController.getDataSource(this.clickedRowTypePointer.GroupType);
        item.TileTypePointer = this.clickedRowTypePointer;
        item.SetDataToWork();
        SessionSettings.save();
    }

    private readonly onAccountChanged = (newAccount: Account, oldAccount: Account): void => {
        if (isNullOrUndefined(newAccount)) return;
        if (Account.IsEqualAccount(newAccount, oldAccount)) { return; }

        if (!isNullOrUndefined(oldAccount)) {
            oldAccount.OnUpdateAssetBalanceData.UnSubscribe(this.AccountPanelReinit, this);
            oldAccount.OnDayTraderPatternChanged.UnSubscribe(this.AccountPanelReinit, this);
        }
        if (!isNullOrUndefined(newAccount)) {
            newAccount.OnUpdateAssetBalanceData.Subscribe(this.AccountPanelReinit, this);
            newAccount.OnDayTraderPatternChanged.Subscribe(this.AccountPanelReinit, this);
            const AccName = newAccount.toString();

            void this.set({
                AccountTooltip: AccName,
                AccountValue: AccName,
                AccountModeStr: AccountUtils.GetAccountModeText(newAccount),
                InsTypeShortName: InstrumentTypesShortName[newAccount.PreferredInstrumentType],
                InstrumentColor: InstrumentTypesColor[newAccount.PreferredInstrumentType]
            });

            AccountWidgetAccountDetailsPanelController.setDataSourceAccount(newAccount);
        }

        const accountStr = newAccount.BstrAccount;
        if (LinkedSystem.accLinkingActive) { LinkedSystem.accLinkingOn(accountStr); }

        this.UpdateTilesDataProvider();

        SessionSettings.save();
    };

    public Properties (): DynProperty[] {
        const properties: DynProperty[] = [];

        const tileArr: AccountWidgetItem[] = this.getTilesArray();
        for (const item of tileArr) {
            properties.push(...item.Properties());
        }

        const account = AccountWidgetAccountsController.Account;
        if (!isNullOrUndefined(account)) { properties.push(new DynProperty('AccountId', account.AcctNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP)); }

        const accLinkingActive = LinkedSystem.accLinkingActive;
        properties.push(new DynProperty('accLinkingActive', accLinkingActive, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        const asset = AccountWidgetAssetsController.SelectedAsset;
        if (!isNullOrUndefined(asset)) { properties.push(new DynProperty('assetComboBoxValue', asset.Id, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP)); }

        return properties;
    }

    public callBack (newProperties: DynProperty[]): void {
        const tileArr: AccountWidgetItem[] = this.getTilesArray();
        for (const item of tileArr) {
            item.callBack(newProperties);
        }

        this.AccountId = DynProperty.getPropValue(newProperties, 'AccountId') ?? DataCache.getPrimaryAccount().AcctNumber;
        this.assetComboBoxValue = DynProperty.getPropValue(newProperties, 'assetComboBoxValue');
        this.accLinkingActive = DynProperty.getPropValue(newProperties, 'accLinkingActive');
        this.DetailsGroupItemStateSetting = DynProperty.getPropValue(newProperties, 'DetailsGroupItemStateMap');
        this.UpdateDataRelation();
    }

    public Localize (): void {
        void this.set({
            accBtnText: Resources.getResource('accountDetailsWidget.DropdownButton'),
            accBtnTooltip: Resources.getResource('accountDetailsWidget.DropdownButton.Tooltip'),
            AccountHeader: Resources.getResource('accountDetailsWidget.DropdownButton')?.toLocaleUpperCase()
        });
    }

    public UpdateDataRelation (): void {
        if (isNullOrUndefined(this.myAccountDetailsPanel)) return;

        if (!isNullOrUndefined(this.AccountId)) { this.myAccountDetailsPanel.accountLink_In(this.AccountId); }

        if (!isNullOrUndefined(this.DetailsGroupItemStateSetting)) { this.myAccountDetailsPanel.setSavedSetting(this.DetailsGroupItemStateSetting); }

        if (!isNullOrUndefined(this.assetComboBoxValue)) { AccountWidgetAccountDetailsPanelController.raiseonAssetSet(this.assetComboBoxValue); }

        if (!isNullOrUndefined(WorkSpaceManager.currentWorkspace)) {
            LinkedSystem.accLinkingActive = this.accLinkingActive || DataCache.EnableForceLinkingByAccount();
            this.activateBtn(LinkedSystem.accLinkingActive);
            this.activateLinkedSystem(LinkedSystem.accLinkingActive, AccountWidgetAccountsController.Account?.BstrAccount);
        }

        const tileArr: AccountWidgetItem[] = this.getTilesArray();
        for (const item of tileArr) {
            item.UpdateRelations();
        }
    }

    public UpdateData (): void {
        if (!DataCache.Loaded) { return; }

        const tileArr: AccountWidgetItem[] = this.getTilesArray();
        for (const item of tileArr) {
            item.UpdateData();
        }
    }

    private getTilesArray (): AccountWidgetItem[] {
        return this.findAllComponents('accountWidgetItem');
    }

    public AccountPanelReinit (): void {
        if (!DataCache.Loaded) {
            DataCache.OnReintialize.Subscribe(this.AccountPanelReinit, this);
            return;
        }

        const noPanell = !this.myAccountDetailsPanel;
        const disposed = false;// this.myAccountDetailsPanel?.torndown;
        void this.set({ visible: CustomerAccess.panelAllowed(PanelNames.NewAccountDetailsPanel) });

        if (noPanell || disposed) {
            this.myAccountDetailsPanel = AccountWidgetAccountDetailsPanelController.AccountDetailsPanel = MainWindowManager.Factory.createPanel(PanelNames.NewAccountDetailsPanel) as NewAccountDetailsPanel;
            AccountWidgetAssetsController.Account = AccountWidgetAccountsController.Account = DataCache.MainAccountNew;
            MainWindowManager.AccountDetailsPanel = this.myAccountDetailsPanel;

            if (isNullOrUndefined(this.myAccountDetailsPanel)) { return null; }
            this.addControl(this.myAccountDetailsPanel);

            this.myAccountDetailsPanel.on('complete', () => { this.UpdateDataRelation(); });
        } else {
            this.callBack(this.Properties());
        }
    }

    public onTableMouseUP (hittestInfo): void {
        this.clickedRowIndex = null;
        this.DragNDropInProcess = false;
        void this.set({ DragNDropInProcess: false });
        if (this.DragNDropTile !== null) {
            this.removeControl(this.DragNDropTile);
            this.DragNDropTile = null;
        }
    }

    public onTableMouseDown (typePointer: VerticalDetailsTypePointer<AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum>): void {
        if (isNullOrUndefined(typePointer)) {
            return null;
        }

        this.clickedRowTypePointer = typePointer;
    }

    public onTableMouseMove (hittestInfo): void {
        if (isNullOrUndefined(this.clickedRowTypePointer)) {
            return;
        }

        if (!this.DragNDropInProcess) {
            const typePointer = this.clickedRowTypePointer;
            this.DragNDropInProcess = true;
            void this.set({ DragNDropInProcess: true });
            const DnD = new AccountWidgetDragAndDrop();
            const controller = AccountWidgetAccountDetailsPanelController.getDataSource(typePointer.GroupType);
            DnD.setData(typePointer.ItemType, controller);
            this.addControl(DnD);
            this.DragNDropTile = DnD;
        }
    }

    public ShowAccountDetailsPanel (coordinates: AccountWidgetCoordinates): void {
        AccountWidgetAccountDetailsPanelController.doShowAccountDetailsPanel(coordinates);
        const sizes = this.myAccountDetailsPanel.getBounds();
        this.rectangleBtwBlockAndPanel.Width = sizes.width;
        this.rectangleBtwBlockAndPanel.Height += sizes.height;

        this.setFocus();
    }

    private getRowsMap () {
        return {};// this.myAccountDetailsPanel.quickTableRactive.quickTable.rows;
    }

    private getRowsArray () {
        return [];// this.myAccountDetailsPanel.quickTableRactive.quickTable.rowsArray;
    }

    public getFirstVisibleRowWithItem (): number | null {
        if (isNullOrUndefined(this.myAccountDetailsPanel)) { return; }

        const rowsArr = this.getRowsArray();

        for (let i = 0; i < rowsArr.length; i++) {
            if (rowsArr[i].visible && rowsArr[i].item) { return i; }
        } // return i may fix problem with loading KYRREX #87035

        return null;
    }

    // public lostFocus (): void {
    //     if (!isNullOrUndefined(this.myAccountDetailsPanel)) { this.myAccountDetailsPanel.hide(); }
    // }

    public dispose (): void {
        Control.Ticker.UnSubscribe(this.UpdateData, this);
        DataCache.OnReintialize.UnSubscribe(this.AccountPanelReinit, this);
        Resources.onLocaleChange.UnSubscribe(this.Localize, this);
    };
}

ContainerControl.extendWith(AccountWidget, {
    data: function () {
        return {
            DragNDropInProcess: false,
            accBtnText: Resources.getResource('accountDetailsWidget.DropdownButton'),
            accBtnTooltip: Resources.getResource('accountDetailsWidget.DropdownButton.Tooltip'),
            AccountHeader: Resources.getResource('accountDetailsWidget.DropdownButton')?.toUpperCase(),
            AccountValue: '',
            visible: true,
            focusedAccWidget: false,
            AccountModeStr: 'Demo',
            InsTypeShortName: '',
            InstrumentColor: '',
            accWidgetBtnSelectedClass: '',
            hovered: false
        };
    },
    template: AccountWidgetTemplate
});
