// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { InstrumentLookupComparer } from '../../../Utils/Instruments/InstrumentLookupComparer';
import { InstrumentSpecificType } from '../../../Utils/Instruments/InstrumentSpecificType';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { DataCache } from '../../DataCache';
import { TerceraSymbolLookupBaseDataProvider } from '../../NoNFixedListCore';
import { type Instrument } from '../../cache/Instrument';
import { TvSymbolConvertor } from '../Convertors/TvSymbolConvertor';
import { TvExchangesManger } from './TvExchangesManger';
import { TvInstrumentTypesManager } from './TvInstrumentTypesManager';

export class TvSearchSymbolManager {
    private readonly dataProvider: TerceraSymbolLookupBaseDataProvider;

    constructor () {
        this.dataProvider = new TerceraSymbolLookupBaseDataProvider();
    }

    public async searchSymbols (userInput: string, exchange: string, symbolType: string): Promise<Instrument[]> {
        let filteredInstrumentList: Instrument[] = [];
        const findStr = !isNullOrUndefined(userInput) ? userInput.toLowerCase() : '';

        const exchanges = !isNullOrUndefined(exchange) && exchange !== TvExchangesManger.AllExchanges
            ? [exchange]
            : null;

        const instrumentTypes = !isNullOrUndefined(symbolType) &&
            symbolType !== TvInstrumentTypesManager.AllTypes &&
            symbolType !== ''
            ? TvSymbolConvertor.getInstrumentTypes(symbolType)
            : null;

        if (DataCache.NonFixedList) {
            filteredInstrumentList = await this.getFilteredNonFixedList(findStr, exchanges, instrumentTypes);
        } else {
            filteredInstrumentList = await this.getFilteredFixedList(findStr, exchanges, instrumentTypes);
        }

        const sortedInstrumentList = InstrumentLookupComparer.sortInstrumentList(filteredInstrumentList, findStr);
        sortedInstrumentList.sort((first: Instrument, second: Instrument) => {
            if (first.isFuturesSymbol && second.isFuturesSymbol &&
                first.ExpDate !== second.ExpDate) {
                return first.ExpDate > second.ExpDate ? 1 : -1;
            }

            const searchText = findStr.toLowerCase();
            const firstSymbolName = first.DisplayName().toLowerCase();
            const secondSymbolName = second.DisplayName().toLowerCase();
            const firstSymbolDescription = !isNullOrUndefined(first.DescriptionValue()) ? first.DescriptionValue().toLowerCase() : '';
            const secondSymbolDescription = !isNullOrUndefined(second.DescriptionValue()) ? second.DescriptionValue().toLowerCase() : '';

            return InstrumentLookupComparer.sortBySymbolDescription(firstSymbolName,
                secondSymbolName,
                firstSymbolDescription,
                secondSymbolDescription,
                searchText);
        });
        return sortedInstrumentList;
    }

    private async getFilteredNonFixedList (filter: string, exchanges: string[], instrumentTypes: number []): Promise<Instrument[]> {
        const nonFixedList = await this.dataProvider.getInstrumentsList(filter,
            exchanges,
            instrumentTypes,
            true,
            false);
        return nonFixedList;
    }

    private async getFilteredFixedList (filter: string, exchanges: string[], instrumentTypes: number []): Promise<Instrument[]> {
        return await new Promise((resolve, reject) => {
            const defaultInstruments = DataCache.Instruments;
            const filteredInstrumentList: Instrument[] = [];

            const filterExchange = exchanges !== null && exchanges.length > 0 ? exchanges[0] : null;

            for (const insK in defaultInstruments) {
                const ins: Instrument = defaultInstruments[insK];
                if (!ins || ins.NeedToHide()) {
                    continue;
                }

                if (filterExchange !== null && filterExchange !== ins.TradingExchange) {
                    continue;
                }

                if (instrumentTypes !== null && !instrumentTypes.includes(ins.InstrType)) {
                    continue;
                }

                if (ins.InstrumentSpecificType === InstrumentSpecificType.ContinuousContract) {
                    continue;
                }

                let curItemFindStr = ins.DisplayName();
                const descr = ins.DescriptionValue();
                curItemFindStr = (curItemFindStr + (!isNullOrUndefined(descr) ? ' (' + descr + ')' : '')).toLowerCase();
                if (!curItemFindStr.toLowerCase().includes(filter)) { continue; }

                filteredInstrumentList.push(ins);
            }
            resolve(filteredInstrumentList);
        });
    }

    public getSymbolByName (nameWithExchange: string): Instrument | null {
        if (!nameWithExchange) {
            return null;
        }

        const devidedParts = InstrumentUtils.DivideFullName(nameWithExchange);
        const name = devidedParts.name;
        const exchange = devidedParts.route;

        // кейс, когда видно несколько роутов
        // здесь непонятно как разруливать, поэтому просто берем первый инструмент
        // Нам инструмент заходит в виде EUR/AUD:LMAX,
        // После разделителя это не роут а биржа, в том виде, в котором он отображается в лукапе TV чарта
        const defaultInstruments = DataCache.Instruments;

        for (const insK in defaultInstruments) {
            const ins = defaultInstruments[insK];
            if (!ins || ins.NeedToHide()) {
                continue;
            }

            if (exchange) {
                const tradingExchage = ins.TradingExchange;
                if (exchange !== tradingExchage) { continue; }
            }

            if (ins.DisplayName() === name) {
                return ins;
            }
        }
        return null;
    }

    public dispose (): void {
        this.dataProvider.dispose();
    }
}
