// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources } from '../../Commons/properties/Resources';
import { OrderTypeBase } from '../../Commons/cache/OrderParams/order-type/OrderTypeBase';
import { AdvancedChartOrderEntryTemplate, OrderEntryChartSellButtonsTemplate, OrderEntryChartBuyButtonsTemplate } from '../../templates.js';
import { ContainerControl } from '../elements/ContainerControl';
import { OrderEntryBase } from '../OrderEntryBase';
import { OETIFSelector } from '../trading/OE/OETIFSelector';
import { SlTpPriceType } from '../../Utils/Enums/Constants';
import { OperationType } from '../../Utils/Trading/OperationType';
import { OrderType } from '../../Utils/Trading/OrderType';
import { PlacedFrom } from '../../Utils/Trading/PlacedFrom';
import { Account } from '../../Commons/cache/Account';
import { TIF } from '../../Utils/Trading/OrderTif';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { NumericUtils } from '../../Utils/NumericUtils';
import { TradingLockUtils } from '../../Utils/TradingLockUtils';
import { IsAllowed } from '../../Commons/IsAllowed';
import { Quantity } from '../../Utils/Trading/Quantity';
import { SlTpHolder } from '../../Utils/Trading/SlTpHolder';
import { OrderEditUpdateData } from '../../Utils/Trading/OrderEditUpdateData';
import { SLTPTriggerUtils } from '../../Commons/cache/OrderParams/SLTPTriggerUtils';
import { OCOCustomOrdersEdit } from '../../Commons/cache/OrderParams/order-edit/OCOCustomOrdersEdit';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { TradingNumericErrorChecker } from '../../Commons/Trading/TradingNumericErrorChecker';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type IOrderParams } from '../../Utils/Trading/OrderParams';

export class AdvancedChartOrderEntry extends ContainerControl {
    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.AdvancedChartOrderEntry; }

    // http://tp.pfsoft.net/entity/57421
    // TODO.
    public override oncomplete (): void {
        super.oncomplete();
        void this.set({
            accountItem: this.get('accountItem'),
            instrumentItem: this.get('instrumentItem')
        });
        this.observe('instrumentItem', this.onInstrumentItemChanged);
        this.observe('accountItem', function (newItem, oldItem, key) { });
        this.observe('showSLTP', (newValue, oldVal) => {
            this.showHideSLTP(newValue);
            // this.onrender();
        });
        this.observe('enabledSL trStopChecked', this.sLLVisibleCheck);

        this.localize();
    }

    public showHideSLTP (isShow: boolean): void {
        const myHeigth = 500;
        if (isShow) {
            void this.set({ height: myHeigth });
            // разместить размер с вдимыми слтп
        } else {
            void this.set({ height: myHeigth - 85 });
            // разместить размер с не вдимыми слтп
        }
    }

    public override oninit (): void {
        super.oninit();

        void this.set('numericsErrors', {});

        void this.set('reverseButtons', GeneralSettings.View.ReverseButtonsOrder);

        this.observe('accountItem instrumentItem', this.updateTradingAllowedStuff);
        this.observe('instrumentItem defaultTif', this.repopulateTIFComboBox);
        this.observe('selectedItem gtdDate', this.updateTIF);
        this.observe('productType', this.onProductTypeChanged);

        TradingLockUtils.TradingLock.TradingLockChanged.Subscribe(this.updateTradingAllowedStuff, this);
        Account.TradeStatusChanged.Subscribe(this.updateTradingAllowedStuff, this);

        GeneralSettings.InsDefSettingsStorage.DefaultSettingsChanged.Subscribe(this.onDefaultSettingsChanged, this);
        DataCache.OnUpdateInstrument.Subscribe(this.onUpdateInstrument, this);

        this.on('add_Ask', this.addAskButton_Click);
        this.on('sell_Bid', this.sellBidButton_Click);
        this.on('sell_market', this.sellMarketButton_Click);
        this.on('buy_market', this.buyMarketButton_Click);
        this.on('buy_Ask', this.buyAskButton_Click);
        this.on('add_Bid', this.addBidButton_Click);
    }

    public override dispose (): void {
        TradingLockUtils.TradingLock.TradingLockChanged.UnSubscribe(this.updateTradingAllowedStuff, this);
        Account.TradeStatusChanged.UnSubscribe(this.updateTradingAllowedStuff, this);
        GeneralSettings.InsDefSettingsStorage.DefaultSettingsChanged.UnSubscribe(this.onDefaultSettingsChanged, this);
        DataCache.OnUpdateInstrument.UnSubscribe(this.onUpdateInstrument, this);

        const instrument: Instrument = this.get('instrumentItem');
        if (!isNullOrUndefined(instrument)) {
            instrument.RiskSettingsUpdated.UnSubscribe(this.updateTradingAllowedStuff, this);
        }

        super.dispose();
    }

    public updateSettings (): void {
        void this.set('reverseButtons', GeneralSettings.View.ReverseButtonsOrder);

        this.onInstrumentItemChanged(this.get('instrumentItem'));

        this.sLLVisibleCheck();
    }

    public onInstrumentItemChanged (newItem: Instrument, prewItem?: Instrument): void {
        if (isNullOrUndefined(newItem)) {
            return;
        }

        this.subscribeRiskSettingsUpdate(newItem, prewItem);

        this.clearNumerics();
        const settings = NumericUtils.getNumericsOffsetModeViewParams(newItem, true);
        void this.set({
            tpNumericStep: settings.step,
            slNumericStep: settings.step,
            tpNumericPrecision: settings.numericsPrec,
            slNumericPrecision: settings.numericsPrec,
            minSLPrice: settings.step,
            minTPPrice: settings.step,
            priceSL: settings.step,
            priceSLL: settings.step,
            priceTP: settings.step

        });

        this.setDefaultSLTP();

        // this.set('productTypeShow', newItem.isProductTypeVisible(this.get('accountItem')))
    }

    public subscribeRiskSettingsUpdate (instrument: Instrument, lastInstrument: Instrument): void {
        if (!isNullOrUndefined(lastInstrument)) {
            lastInstrument.RiskSettingsUpdated.UnSubscribe(this.updateTradingAllowedStuff, this);
        }

        if (!isNullOrUndefined(instrument)) {
            instrument.RiskSettingsUpdated.Subscribe(this.updateTradingAllowedStuff, this);
        }
    }

    public onUpdateInstrument (instrument: Instrument): void {
        const myInstrument: Instrument = this.get('instrumentItem');
        if (!isNullOrUndefined(myInstrument) && myInstrument === instrument) {
            this.updateTradingAllowedStuff();
        }
    }

    public updateTradingAllowedStuff (): void {
        const acc: Account = this.get('accountItem');
        const ins: Instrument = this.get('instrumentItem');
        if (isNullOrUndefined(acc) || isNullOrUndefined(ins)) {
            return;
        }

        this.PopulateOrderTypes();

        const tradingMarketAllowed = IsAllowed.IsTradingAllowed([acc], ins, OrderType.Market);
        const tradingLimitAllowed = OrderTypeBase.IsSupported(OrderType.Limit, ins);
        const tradingLimitAllowedS = IsAllowed.IsTradingAllowed([acc], ins, OrderType.Limit);

        const slVisible = IsAllowed.IsSLTPAllowed(ins, acc, true).Allowed;
        const tpVisible = IsAllowed.IsSLTPAllowed(ins, acc, false).Allowed;
        const sltpVisible = slVisible || tpVisible;
        const sltpHeight = slVisible && tpVisible;

        const newProductTypeShow = ins.isProductTypeVisible(acc);
        if (this.get<boolean>('productTypeShow') !== newProductTypeShow) {
            void this.set('productTypeShow', newProductTypeShow);
        }

        void this.set({
            isSlVisible: slVisible,
            isTPVisible: tpVisible,
            isSlTPVisible: sltpVisible,
            isSLTPheight: sltpHeight,
            tradingMarketAllowed: tradingMarketAllowed.Allowed,
            tradingMarketAllowedReason: tradingMarketAllowed.ReasonText,
            tradingLimitAllowed: tradingLimitAllowedS.Allowed && tradingLimitAllowed,
            tradingLimitAllowedReason: tradingLimitAllowedS.ReasonText,
            trStopAllowed: IsAllowed.IsTrailingStopForSLAllowed(ins, acc).Allowed
        });
    }

    public PopulateOrderTypes (): void {
        const ins: Instrument = this.get('instrumentItem');
        if (isNullOrUndefined(ins)) return;

        if (DataCache.OrderParameterContainer === null) {
            return;
        }

        const orderTypes = OrderEntryBase.PopulateOrderTypes(this.get('accountItem'), ins, null, GeneralSettings.TradingDefaults.OrderType, false);

        const marketAllow = orderTypes.includes(OrderType.Market);
        const limitAllow = orderTypes.includes(OrderType.Limit);
        const ShowLimitsBtns = !Resources.isHidden('panel.newOrderEntry.LimitOrderButtons.Visibility');
        void this.set({
            buyMarketButtonOnSmallOE: marketAllow,
            buyAskButtonOnSmallOE: limitAllow && ShowLimitsBtns,
            addBidButtonOnSmallOE: limitAllow && ShowLimitsBtns,
            sellMarketButtonOnSmallOE: marketAllow,
            sellBidButtonOnSmallOE: limitAllow && ShowLimitsBtns,
            addAskButtonOnSmallOE: limitAllow && ShowLimitsBtns
        });
    }

    public clearNumerics (): void {
        void this.set({
            tpNumericStep: 0,
            slNumericStep: 0,
            tpNumericPrecision: 0,
            slNumericPrecision: 0,
            minSLPrice: 0,
            minTPPrice: 0,
            priceSL: 0,
            priceTP: 0
        });
    }

    public localize (): void {
        void this.set({
            addAskText: Resources.getResource('panel.newOrderEntry.AddAskButton'),
            sellBidText: Resources.getResource('panel.newOrderEntry.SellBidButton'),
            sellMarketText: Resources.getResource('panel.newOrderEntry.SellMarketButton'),
            buyMarketText: Resources.getResource('panel.newOrderEntry.BuyMarketButton'),
            buyAskText: Resources.getResource('panel.newOrderEntry.BuyAskButton'),
            addBidText: Resources.getResource('panel.newOrderEntry.AddBitButton'),
            sllLabelTooltip: 'panel.newOrderEntry.stopLimitLimitOffset.tt'
        });
    }

    public onProductTypeChanged (productType): void {
        const ins: Instrument = this.get('instrumentItem');
        const acc: Account = this.get('accountItem');

        void this.set('isLeverageVisible', !isNullOrUndefined(ins) ? ins.isLeverageVisible(acc, productType) : false);
    }

    public onDefaultSettingsChanged (): void {
        void this.update('instrumentItem');
    }

    public addBidButton_Click (): void {
        const ins: Instrument = this.get('instrumentItem');
        this.placeFromSmallBtn(OrderType.Limit, OperationType.Buy, ins.Level1.GetBid());
    }

    public addAskButton_Click (): void {
        const ins: Instrument = this.get('instrumentItem');
        this.placeFromSmallBtn(OrderType.Limit, OperationType.Sell, ins.Level1.GetAsk());
    }

    public buyAskButton_Click (): void {
        const ins: Instrument = this.get('instrumentItem');
        this.placeFromSmallBtn(OrderType.Limit, OperationType.Buy, ins.Level1.GetAsk());
    }

    public sellBidButton_Click (): void {
        const ins: Instrument = this.get('instrumentItem');
        this.placeFromSmallBtn(OrderType.Limit, OperationType.Sell, ins.Level1.GetBid());
    }

    public buyMarketButton_Click (): void {
        this.placeFromSmallBtn(OrderType.Market, OperationType.Buy);
    }

    public sellMarketButton_Click (): void {
        this.placeFromSmallBtn(OrderType.Market, OperationType.Sell);
    }

    // TODO. UGLY.
    public placeFromSmallBtn (type: OrderType, side: OperationType, price?: number): void {
        const params = {
            dPrice: price,
            dStopPrice: price,
            lOrdType: type,
            lBuySell: side,
            lTif: this.get('selectedItem').value,
            expireTime: this.get('gtdDate'),
            productType: this.get('productType'),
            leverageValue: this.get('leverageValue'),
            placedFrom: PlacedFrom.WEB_CHART_OE
        };
        void this.OrderParamsSet(params);
    }

    public repopulateTIFComboBox (): void {
        OETIFSelector.GetAdvancedComboBoxItems([OrderType.Limit, OrderType.Market], this);
    }

    public updateTIF (): void {
        OETIFSelector.GetAdvancedUpdateTIF(this);
    }

    public async OrderParamsSet (orderParams: IOrderParams): Promise<void> {
        if (TradingNumericErrorChecker.HasErrors(this)) {
            return;
        }

        if (isNullOrUndefined(orderParams)) {
            return;
        }

        const orderType = orderParams.lOrdType;
        const orderTypeObj = DataCache.OrderParameterContainer.OrderTypes[orderType];

        const orderEditCtorData = {
            dataCache: DataCache,
            // TODO. UGLY. Details are in OrderEditBase
            forceSLTPOffset: true
        };

        // TODO. Ugly.
        const orderEdit = orderType === OrderType.OCO
            ? new OCOCustomOrdersEdit(orderEditCtorData)
            : orderTypeObj.createOrderEditObject(orderEditCtorData);

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            {
                account: this.get('accountItem'),
                instrument: this.get('instrumentItem'),
                side: orderParams.lBuySell,

                quantity: new Quantity(
                    orderParams.quantity ?? this.get('quantity'),
                    GeneralSettings.View.displayAmountInLots()),

                tif: new TIF(orderParams.lTif, orderParams.expireTime),
                productType: orderParams.productType,
                leverageValue: orderParams.leverageValue,
                placedFrom: orderParams.placedFrom
            }, SessionSettings));

        const chartSLTPHolder = this.getChartSLTPHolder();

        // TODO. Ugly.
        switch (orderType) {
        case OrderType.Limit:
            orderEdit.setLimitPrice(orderParams.dPrice);
            break;
        case OrderType.Stop:
            orderEdit.setStopPrice(orderParams.dStopPrice);
            break;
        case OrderType.StopLimit:
            orderEdit.setBasePrice(orderParams.dStopPrice);
            break;
        case OrderType.OCO:
            this.setOCOOrderEdit(orderEdit, orderParams);
            break;
        }

        // TODO. Ugly.
        orderEdit.setSLTP(chartSLTPHolder);

        return await DataCache.FOrderExecutor.placeOrderPromise(orderEdit)
            .catch(function () {
                const ex = new CustomErrorClass('AdvancedChartOrderEntry error', 'AdvancedChartOrderEntry.OrderParamsSet', 'OrderParamsSet -> placeOrderPromise');
                ErrorInformationStorage.GetException(ex);
            })
            .finally(function () {
                // TODO. Refactor.
                orderEdit.dispose();
            });
    }

    public setOCOOrderEdit (orderEdit, orderParams: IOrderParams): void {
        orderEdit.setOrdersTypes(
            orderParams.OCO_firstOrder_OrderType,
            orderParams.OCO_secondOrder_OrderType);

        orderEdit.setOrdersSides(
            orderParams.lBuySell,
            orderParams.OCO_secondOrder_Operation);

        // TODO.
        orderEdit.setOrdersPrices(
            orderParams.dPrice,
            orderParams.dStopPrice);

        let firstTif, secondTif;

        if (!isNullOrUndefined(orderParams.lTif)) {
            firstTif = secondTif = new TIF(orderParams.lTif, orderParams.expireTime);
        } else {
            const ins = this.get('instrumentItem');

            firstTif = new TIF(
                SessionSettings.getDefaultTifForOrderType(orderParams.OCO_firstOrder_OrderType),
                TIF.getDefaultExpireTimeForOrderType(orderParams.OCO_firstOrder_OrderType, ins));

            secondTif = new TIF(
                SessionSettings.getDefaultTifForOrderType(orderParams.OCO_secondOrder_OrderType),
                TIF.getDefaultExpireTimeForOrderType(orderParams.OCO_secondOrder_OrderType, ins));
        }

        orderEdit.setOrdersTifs(firstTif, secondTif);
    }

    public getChartSLTPHolder (): SlTpHolder {
        // Chart SL/TP are always offset.
        const offsetType = GeneralSettings.TradingDefaults.ShowOffsetIn;
        const instrument: Instrument = this.get('instrumentItem');

        const sltpHolder = new SlTpHolder();

        const slEnabled = this.get<boolean>('enabledSL') && !this.get<boolean>('trStopChecked');
        const trStopEnabled = this.get<boolean>('enabledSL') && this.get<boolean>('trStopChecked');

        if (slEnabled || trStopEnabled) {
            sltpHolder.StopLossPriceValue =
                OrderUtils.toRawTicks(this.get('priceSL'), offsetType, instrument);

            sltpHolder.StopLossPriceType = trStopEnabled
                ? SlTpPriceType.TrOffset
                : SlTpPriceType.Offset;

            if (this.get<boolean>('isSLLVisible')) {
                sltpHolder.StopLossLimitPriceValue =
                    OrderUtils.toRawTicks(this.get('priceSLL'), offsetType, instrument);
            }
        }

        const tpEnabled: boolean = this.get('enabledTP');
        if (tpEnabled) {
            sltpHolder.TakeProfitPriceValue =
                OrderUtils.toRawTicks(this.get('priceTP'), offsetType, instrument);

            sltpHolder.TakeProfitPriceType = SlTpPriceType.Offset;
        }

        const anyEnabled = slEnabled || trStopEnabled || tpEnabled;
        if (anyEnabled) // #110841
        {
            sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetTriggersShortForInstrument(instrument);
        }

        return sltpHolder;
    }

    public sLLVisibleCheck (): void {
        const slEnabled: boolean = this.get('enabledSL');
        const trStopChecked: boolean = this.get('trStopChecked');
        const useStopLimitInsteadStop = GeneralSettings.TradingDefaults.UseStopLimitInsteadStop;

        const sllNewVisibility = slEnabled && !trStopChecked && useStopLimitInsteadStop;

        void this.set('isSLLVisible', sllNewVisibility);
    }

    public setDefaultSLTP (): void {
        const offsetType = GeneralSettings.TradingDefaults.ShowOffsetIn; // Chart SL/TP are always offset.

        const instrument = this.get('instrumentItem');
        if (isNullOrUndefined(instrument)) return;

        const insDefSettings = GeneralSettings.InsDefSettingsStorage.GetInstrumentSettings(instrument);
        const basePrice = null;

        const slTickOffset = !isNullOrUndefined(insDefSettings) ? insDefSettings.SLDefaultOffsetTicksDecimal : 1;
        const defaultSL = OrderUtils.ConvertTickOffset(instrument, offsetType, basePrice, slTickOffset);

        const tpTickOffset = !isNullOrUndefined(insDefSettings) ? insDefSettings.TPDefaultOffsetTicksDecimal : 1;
        const defaultTP = OrderUtils.ConvertTickOffset(instrument, offsetType, basePrice, tpTickOffset);

        void this.set({
            priceSL: defaultSL,
            priceSLL: defaultSL,
            priceTP: defaultTP
        });
    }
}

ContainerControl.extendWith(AdvancedChartOrderEntry, {
    template: AdvancedChartOrderEntryTemplate,
    partials: {
        chartSellButtons: OrderEntryChartSellButtonsTemplate,
        chartBuyButtons: OrderEntryChartBuyButtonsTemplate
    },
    data: function () {
        return {
            accountItem: null,
            instrumentItem: null,

            oeSmall: true,
            reverseButtons: false,
            enabledSL: false,
            enabledTP: false,

            isSlTPVisible: true,
            isSlVisible: true,
            isTPVisible: true,
            isSLTPheight: true,
            isSLLVisible: false,

            priceSL: 0.1,
            priceSLL: 0.1, // #91413
            priceTP: 0.1,
            minSLPrice: 0.1,
            maxSLPrice: 99999999,
            minSLLPrice: -99999999,
            minTPPrice: 0.1,
            maxTPPrice: 99999999,
            slNumericStep: 0.00001,
            tpNumericStep: 0.00001,
            slNumericPrecision: 2,
            tpNumericPrecision: 2,

            trStopChecked: false,
            trStopAllowed: true,
            numericsErrors: {},

            sellMarketButtonOnSmallOE: true,
            sellBidButtonOnSmallOE: true,
            addAskButtonOnSmallOE: true,

            _userPreferredShowBuySellMarket: true,
            _userPreferredShowSellBidBuyAsk: true,
            _userPreferredShowAddBidAsk: true,

            addAskText: 'Add Ask',
            sellBidText: 'Sell Bid',
            sellMarketText: 'Sell Market',
            buyMarketText: 'Buy Market',
            buyAskText: 'Buy Ask',
            addBidText: 'Add Bid',
            sllLabelTooltip: '',

            tif: null,
            items: null,
            gtdDate: null,
            orderTypeId: null,
            selectedItem: null,
            productType: null,
            productTypeShow: false,
            isLeverageVisible: false,

            // TODO. Remove. Ugly.
            defaultTif: null
        };
    }
});
