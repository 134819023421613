// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type Account } from '../../Commons/cache/Account';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type OrderEditBase } from '../../Commons/cache/OrderParams/order-edit/OrderEditBase';
import { Resources } from '../../Commons/properties/Resources';
import { DirectReportMessage } from '../DirectMessages/DirectReportMessage';
import { SlTpPriceType } from '../Enums/Constants';
import { MathUtils } from '../MathUtils';
import { OperationType } from '../Trading/OperationType';
import { OrderExecutionType } from '../Trading/OrderExecutionType';
import { OrderHistoryUtils } from '../Trading/OrderHistoryUtils';
import { OrderType } from '../Trading/OrderType';
import { ProductType } from './ProductType';

export class PriceLimitValidation {
    public static SendRejectIfNotValidateLimitsByOrderEdit (orderEdit: OrderEditBase | null): boolean {
        if (!orderEdit?.getDataForRequest) {
            return false;
        }

        return PriceLimitValidation.SendRejectIfNotValidateLimits(orderEdit.getDataForRequest());
    }

    public static SendRejectIfNotValidateLimits (data): boolean {
        if (!data?.parameterDict) {
            return false;
        }

        const ins = data.instrument;
        const acc = data.account;
        const orderType = data.orderTypeId;
        const side = data.side;
        const orderParamDict = data.parameterDict;
        let price = orderParamDict.limitPrice;
        const stopPrice = orderParamDict.stopPrice;
        const sltp = orderParamDict.sltp;
        const productType = data.productType;

        if (MathUtils.IsNullOrUndefined(price) && MathUtils.IsNullOrUndefined(stopPrice)) {
            price = ins.GetMarketPrice(acc, side);
        }

        if (!PriceLimitValidation.ValidateLimitsByPrices(ins, acc, orderType, side, price, stopPrice, sltp)) {
            PriceLimitValidation.SendRejectAfterPriveValidation(ins, acc, productType); // #111427
            return true;
        }

        return false;
    }

    public static ValidateLimitsByPrices (instrument: Instrument | null, acc: Account, orderType: OrderType, side, price, stopPrice, sltpHolder): boolean {
        if (!instrument) {
            return true;
        }

        const isLimitOrderType = orderType == OrderType.Limit;

        if (sltpHolder) // FOR SL/TP
        {
            if (!PriceLimitValidation.ValidateSlTpLimits(instrument, acc, orderType, side, price, stopPrice, sltpHolder)) {
                return false;
            }
        }

        if (isLimitOrderType || orderType == OrderType.Stop) {
            const p = isLimitOrderType ? price : stopPrice;
            return instrument.Limits.ValidatePriceOnFrontEnd(p);
        } else if (orderType == OrderType.StopLimit || orderType == OrderType.OCO) {
            return instrument.Limits.ValidatePriceOnFrontEnd(price) &&
                instrument.Limits.ValidatePriceOnFrontEnd(stopPrice);
        }

        return true;
    }

    public static ValidateSlTpLimits (instrument: Instrument, acc: Account, orderType: OrderType, side, price, stopPrice, sltpHolder): boolean {
        if (!sltpHolder) {
            return true;
        }

        const validateSl = !isNaN(sltpHolder.StopLossPriceValue);
        const validateTp = !isNaN(sltpHolder.TakeProfitPriceValue);

        let isValideSl = true;
        let isValideTp = true;

        const isBuy = side == OperationType.Buy;

        if (validateSl) {
            let p = instrument.GetMarketPrice(acc, side);
            let slPrice = sltpHolder.StopLossPriceValue;

            if (orderType === OrderType.Limit) {
                p = price;
            }

            if (orderType === OrderType.Stop) {
                p = stopPrice;
            }

            if (sltpHolder.StopLossPriceType != SlTpPriceType.Absolute) {
                slPrice = instrument.CalculatePrice(p, slPrice * (isBuy ? -1 : 1));
            }

            isValideSl = PriceLimitValidation.ValidateLimitsByPrices(instrument, acc, OrderType.Limit, side, slPrice, 0, null);
        }

        if (validateTp) {
            let p = instrument.GetMarketPrice(acc, side);
            let tpPrice = sltpHolder.TakeProfitPriceValue;

            if (orderType === OrderType.Limit) {
                p = price;
            }

            if (orderType === OrderType.Stop) {
                p = stopPrice;
            }

            if (sltpHolder.TakeProfitPriceType != SlTpPriceType.Absolute) {
                tpPrice = instrument.CalculatePrice(p, tpPrice * (isBuy ? 1 : -1));
            }

            isValideTp = PriceLimitValidation.ValidateLimitsByPrices(instrument, acc, OrderType.Stop, side, 0, tpPrice, null);
        }

        return isValideSl && isValideTp;
    }

    public static SendRejectAfterPriveValidation (instrument: Instrument, acc: Account, pT: ProductType): void // #111427
    {
        const pMsg = new DirectReportMessage();

        pMsg.Name = OrderHistoryUtils.localizeOrderExecutionType(OrderExecutionType.REFUSED);
        pMsg.Data = [['Error', Resources.getResource('AdditionalProperty.ErrorPriceOutOfTradingLimits')]];
        pMsg.reject = true;
        pMsg.account = acc;
        pMsg.productType = pT;
        pMsg.Instrument = instrument;

        instrument.DataCache.NewMessage(pMsg);
    }
}
