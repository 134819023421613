// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { TerceraLookup } from './TerceraLookup';
import { InsDefItem, InsDefSettings } from '../../../Commons/cache/InstrumentDefaults';
import { InstrumentsDefaultsLookupTemplate } from '../../../templates.js';
import { Resources } from '../../../Commons/properties/Resources';
import { ContainerControl } from '../ContainerControl';
import { TerceraInstrumentLookupDropDownForm } from './TerceraInstrumentLookupDropDownForm';
import { LookupDropDownShowParams } from '../../UtilsClasses/LookupDropDownShowParams';
import { contextMenuHandler } from '../../../Utils/AppHandlers';
import { InstrumentLookupManager } from './InstrumentLookupManager';
import { InstrumentTypes } from '../../../Utils/Instruments/InstrumentTypes';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { Instrument } from '../../../Commons/cache/Instrument';
import { DataCache } from '../../../Commons/DataCache';
import { TerceraSymbolLookupBaseDataProvider } from '../../../Commons/NoNFixedListCore';
import { SearchHelper } from '../../../Commons/SearchHelper';
import { InstrumentLookupProperties, InstrumentLookupType } from './InstrumentLookupManagerProps';

export class InstrumentsDefaultsLookup extends ContainerControl {
    public viewedInstruments: any | null = null;
    public lastTimeoutHandlerId: any = null;
    public dataProvider: TerceraSymbolLookupBaseDataProvider;

    public override oninit (): void {
        super.oninit();

        this.dataProvider = new TerceraSymbolLookupBaseDataProvider();

        this.timeoutCallBack = this.timeoutCallBack.bind(this);
        this.instrumentsDropDownCallback = this.instrumentsDropDownCallback.bind(this);
        this.removeSelectedInstrument = this.removeSelectedInstrument.bind(this);

        void this.set('itemSettings', InstrumentsDefaultsLookup.createInsDefItemNullStub());

        this.observe('searchText', this.onSearchTextChanged);
        this.observe('allSettings', this.onAllSettingsChanged);

        this.on('instrumentSelection', this.onInstrumentSelection);
        this.on('openInstrumentLookup', this.openInstrumentLookup);
        this.on('quickTreeAfterMouseDown', this.quickTreeAfterMouseDown);
    }

    public override oncomplete (): void {
        super.oncomplete();

        const qtr = this.Controls.quickTreeRactive;
        if (!isNullOrUndefined(qtr?.quickTree)) {
            qtr.quickTree.isSmall = true;
            qtr.quickTree.rowHeigth = 35;
            qtr.quickTree.reInitScrollHeigth();
        }
    }

    public removeSelectedInstrument (menuItem): void {
        const nodeToDelete = menuItem.tag;
        if (isNullOrUndefined(nodeToDelete)) return;

        const instrument: Instrument = nodeToDelete.tag;
        delete this.viewedInstruments[instrument.GetInteriorID()];
        this.get('allSettings').RemoveInstrumentSettings(instrument);
        void this.set('itemSettings', InstrumentsDefaultsLookup.createInsDefItemNullStub());

        this.Repopulate();
    }

    public quickTreeAfterMouseDown (event): void {
        const e = event.original;
        if (e.button !== 2) return;

        const qt = this.Controls.quickTreeRactive.quickTree;

        const selectedNode = qt.selectedNode;
        if (isNullOrUndefined(selectedNode)) return;

        const instrument = selectedNode.tag;
        if (!(instrument instanceof Instrument)) {
            return;
        }

        contextMenuHandler.Show([{
            text: Resources.getResource('panel.menu.Remove'),
            event: this.removeSelectedInstrument,
            tag: selectedNode
        }],
        e.clientX, e.clientY);
    }

    public onInstrumentSelection (): void {
        const selectedNode = this.Controls.quickTreeRactive.quickTree.selectedNode;
        if (isNullOrUndefined(selectedNode)) return;

        void this.set('itemSettings', InstrumentsDefaultsLookup.createInsDefItemNullStub());

        const tag = selectedNode.tag;
        if (isNullOrUndefined(tag)) { return; }
        if (tag instanceof Instrument) {
            this.UpdateNumeric(tag.InstrType);

            void this.set('itemSettings',
                this.get('allSettings').GetInstrumentSettings(tag) ||
            InstrumentsDefaultsLookup.createInsDefItemNullStub());
        } else {
            const instrTypeString = InsDefSettings.INSTRUMENT_TYPE;
            if (!tag.indexOf || tag.indexOf(instrTypeString) !== 0) {
                return;
            }

            const type = tag.slice(-(tag.length - instrTypeString.length));

            this.UpdateNumeric(parseInt(type));

            void this.set('itemSettings',
                this.get('allSettings').GetTypeSettings(type) ||
            InstrumentsDefaultsLookup.createInsDefItemNullStub());
        }
    }

    public UpdateNumeric (type: InstrumentTypes): void {
        let step = 1;
        let minVal = 1;
        let decPrec = 0;
        if (type === InstrumentTypes.FOREX && GeneralSettings.TradingDefaults.IsTicksFractionalForForex()) {
            step = 0.1;
            minVal = 0.1;
            decPrec = 1;
        }
        if (!isNullOrUndefined(MainWindowManager.TypesManagerScreen)) {
            MainWindowManager.TypesManagerScreen.set({
                sltpStep: step,
                sltpMinValue: minVal,
                sltpDecimalPrecision: decPrec
            });
        }
    }

    public onAllSettingsChanged (newSettings): void {
        if (isNullOrUndefined(newSettings)) { return; }

        this.viewedInstruments = {};
        void this.set('searchText', '');
        this.AddInstrumentsPrommise(newSettings.InstrumentsNames);
    }

    public onSearchTextChanged (): void {
        clearTimeout(this.lastTimeoutHandlerId);
        setTimeout(this.timeoutCallBack, 300);
    }

    public timeoutCallBack (): void {
        this.Repopulate();
    }

    public openInstrumentLookup (): void {
        const obj: any = {};
        (TerceraLookup.prototype.setItems.bind(obj))(DataCache.Instruments);

        const params = new LookupDropDownShowParams();
        params.items = obj.items;
        params.callBack = this.instrumentsDropDownCallback;
        params.isMultiSelect = true;
        // params.isMultiSelectMode = true;
        params.isCentered = true;
        params.autoClose = false;
        params.parentPanel = this;
        params.dataProvider = this.dataProvider;
        TerceraInstrumentLookupDropDownForm.ShowForm(params);
    }

    public AddInstrumentsPrommise (InstrumentsNames): void {
        const instrumentsNamesResult = [];
        const len = InstrumentsNames.length;
        for (let i = 0; i < len; i++) {
            const instrumentItem = new SearchHelper(InstrumentsNames[i]);
            instrumentsNamesResult.push(DataCache.getInstrumentByInstrumentTradableID_NFL(instrumentItem.InstrumentTradableID, instrumentItem.Route, instrumentItem.GetInteriorID()));
        }

        void Promise.all(instrumentsNamesResult).then(function (instruments) {
            this.AddInstruments(instruments);
        }.bind(this));
    }

    public instrumentsDropDownCallback (instruments: Instrument[]): void {
        const instrumentsNamesResult = [];
        const len = instruments.length;
        for (let i = 0; i < len; i++) {
            const instrumentItem = instruments[i];
            instrumentsNamesResult.push(DataCache.getInstrumentByInstrumentTradableID_NFL(instrumentItem.InstrumentTradableID, instrumentItem.Route, instrumentItem.GetInteriorID()));
        }

        void Promise.all(instrumentsNamesResult).then(function (instruments) {
            this.AddInstruments(instruments);
        }.bind(this));
    }

    // TODO. Non fixed list.
    public AddInstruments (instruments: Instrument[]): void {
        for (let i = 0; i < instruments.length; i++) {
            const instrument = instruments[i];
            this.AddInstrumentInternal(instrument);
        }
        this.Repopulate();
    }

    public AddInstrument (instrument: Instrument): void {
        this.AddInstrumentInternal(instrument);
        this.Repopulate();
    }

    private AddInstrumentInternal (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const viewedInstruments = this.viewedInstruments;
        const Id = instrument.GetInteriorID();
        if (!isNullOrUndefined(instrument) && !viewedInstruments.hasOwnProperty(Id)) {
            viewedInstruments[Id] = instrument;
            this.get('allSettings').CreateInstrumentSettingsIfNotExist(instrument);
        }
    }

    public Repopulate (): void {
        const instruments: Instrument[] = this.GetSortedViewedInstruments();
        const qtr = this.Controls.quickTreeRactive;
        const properties = new InstrumentLookupProperties(InstrumentLookupType.Big, instruments, qtr.quickTree, this.get('searchText'));
        properties.isInstrumentDefaults = true;
        properties.selectedInstruments = instruments[instruments.length - 1];
        properties.existingInstrumentTypes = DataCache.ExistingInstrumentTypes;
        InstrumentLookupManager.fillTree(properties);
        qtr.setSizes();
        // TEST.
        qtr.quickTree.Draw(true);
    }

    public GetSortedViewedInstruments (): Instrument[] {
        const instruments: Instrument[] = [];
        const viewedInstruments = this.viewedInstruments;
        if (!viewedInstruments) {
            return instruments;
        }
        for (const key in viewedInstruments) {
            const instrument: Instrument = viewedInstruments[key];
            if (instrument.NeedToHide()) {
                continue;
            }
            instruments.push(viewedInstruments[key]);
        }

        // instruments.sort();
        return instruments;
    }

    public themeChange (): void {
        const addBtn = this.Controls.add;
        if (!isNullOrUndefined(addBtn)) {
            addBtn.set('terceraButtonStyle', 'js-addBtn-Instruments');
        }

        const qt = this.Controls.quickTreeRactive;
        if (!isNullOrUndefined(qt)) qt.themeChange();
    }

    // HACK. Ractive doesn't like keypaths like ".itemSettings.SLDefaultOffsetTicksDecimal"
    // when itemSettings is null and two-way binding is used.
    public static createInsDefItemNullStub (): InsDefItem {
        const insDefItem = new InsDefItem();
        insDefItem.readOnly = true;
        return insDefItem;
    }
}

ContainerControl.extendWith(InstrumentsDefaultsLookup, {
    data: function () {
        return {
            allSettings: null,
            itemSettings: null,
            searchText: ''
        };
    },

    template: InstrumentsDefaultsLookupTemplate
});
