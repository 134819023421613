// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ErrorInformationStorage } from './ErrorInformationStorage';
import { Resources } from './properties/Resources';
import { OrderType } from '../Utils/Trading/OrderType';
import { ProductType } from '../Utils/Instruments/ProductType';
import { TradingMode } from '../Utils/Instruments/TradingMode';
import { TradingPositionType } from '../Utils/Instruments/TradingPositionType';
import { SessionOperations } from '../Utils/Enums/Constants';
import { OrderTif } from '../Utils/Trading/OrderTifEnum';
import { OrderStatus } from '../Utils/Trading/OrderStatus';
import { AccountTradeStatus } from '../Utils/Account/AccountTradeStatus';
import { AccountType } from '../Utils/Account/AccountType';
import { Route } from './cache/Route';
import { RulesSet } from '../Utils/Rules/RulesSet';
import { ExternalOrderStatus } from '../Utils/Trading/ExternalOrderStatus';
import { RiskPlan } from './cache/RiskPlan';
import { Instrument } from './cache/Instrument';
import { GeneralSettings } from '../Utils/GeneralSettings/GeneralSettings';
import { TradingLockUtils } from '../Utils/TradingLockUtils';
import { InstrumentUtils } from '../Utils/Instruments/InstrumentUtils';
import { ApplicationInfo } from './ApplicationInfo';
import { type Position } from './cache/Position';
import { type Order } from './cache/Order';
import { type Account } from './cache/Account';
import { IsAllowedResponceReason, IsAllowedResponceReasonName } from './IsAllowedResponceReason';
import { type Quantity } from '../Utils/Trading/Quantity';

let DataCache = null;
export class IsAllowed {
    constructor (dataCache) {
        DataCache = dataCache;
    }

    public static IsCloseAllAllowed (isPosition: boolean, isUseLockTrading = true): IsAllowedResponce {
        try {
            if (ApplicationInfo.isExploreMode) {
                return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByExploreMode);
            }

            if (!DataCache.Loaded) {
                return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotLoadCache);
            }

            const IsCheckTradingNotLocked = isUseLockTrading ? !TradingLockUtils.TradingLock.tradingLocked : true; // не проверяем залочена ли торговля, если isUseLockTrading = false;

            if (TradingLockUtils.TradingLock.LockTradingByPassword) {
                return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTradingByPassword);
            }

            if (!IsCheckTradingNotLocked) {
                return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTrading);
            }

            if (!DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING)) {
                return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleTrading);
            }

            if (DataCache.isAllowedForMyUser(RulesSet.TERMINAL_HTML)) {
                return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleAdministration);
            }

            if (DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_POSITIONSADMIN)) {
                return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.PositionsAdmin);
            }

            if (isPosition) {
            // +++yura - проверка рула "RulesSet.FUNCTION_CLOSE_POSITIONS_DISABLE" по рулам аккаунта, а не по всем рулам
                const activeAccount = DataCache.ActiveUser != null ? DataCache.ActiveUser.Account : null;
                let isClosePositionsDisable = true;
                if (activeAccount != null) {
                    isClosePositionsDisable = DataCache.isAllowedForAccount(RulesSet.FUNCTION_CLOSE_POSITIONS_DISABLE, activeAccount);
                } else {
                    isClosePositionsDisable = DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_CLOSE_POSITIONS_DISABLE);
                }

                if (isClosePositionsDisable) {
                    return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleClosePositionDisable);
                }
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.Unknown);
        }
        // TODO
        return IsAllowedResponce.AllowedResponce();
    }

    public static IsRollbackAllowed (): IsAllowedResponce {
        if (ApplicationInfo.isExploreMode) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByExploreMode);
        }
        // TODO
        return IsAllowedResponce.AllowedResponce();
    }

    public static IsAccountAllowOCO (accounts): boolean {
        if (DataCache == null) {
            return true;
        }

        for (let i = 0; i < accounts.length; i++) {
            const acc = accounts[i];
            if (acc === null) {
                continue;
            }

            const allowed = DataCache.isAllowedForAccount(RulesSet.FUNCTION_BINDEDORDERS, acc);
            if (allowed && (acc.AccountTradeStatus === AccountTradeStatus.ACTIVE_STATUS || acc.AccountTradeStatus === AccountTradeStatus.LIQUIDATION_ONLY_STATUS)) // +++ !!AccountTradeStatus
            {
                continue;
            }

            return false;
        }
        return true;
    }

    public static IsPositionClosingAllowed (pos: Position | null, ignoreLockTrading = false, ignorepositionAdmin = false, ignoreInvalidQuote = true): IsAllowedResponce {
    // let CurrentRule = IsAllowedRule.IsPositionClosingAllowed;

        if (pos == null) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        if (ApplicationInfo.isExploreMode) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByExploreMode);
        }

        if (pos.IsPendingExerciseOptionStatus()) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OptionPendingExerciseStatus);
        }

        // 106414
        if (pos.ProductType == ProductType.CorporateAction) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedCorporateActionPositions);
        }

        if (TradingLockUtils.TradingLock.LockTradingByPassword) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTradingByPassword);
        }

        // Analyze "LOCK_TRADING"
        if (!ignoreLockTrading && TradingLockUtils.TradingLock.tradingLocked) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTrading);
        }

        // Analyze "FUNCTION_TRADING" rule
        if (!DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleTrading);
        }

        /// / Analyze "FUNCTION_TRADING" rule  ++ AccountTradeStatus
        if (!IsAllowed.Check_FUNCTION_TRADING_BY_ACCOUNT_Rule(pos.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionTrading);
        }

        // Analyze "FUNCTION_SELF_TRADING_Rule" rule
        //
        if (!IsAllowed.Check_FUNCTION_SELF_TRADING_Rule(pos.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionSelfTrading);
        }

        // Analyze instrument trading mode: open or closed
        if (!IsAllowed.Check_Instrument_TradingMode(pos.Instrument, false)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentTradingMode);
        }

        if (!IsAllowed.Check_Session_OrderType(pos.Instrument, OrderType.Market)) // https://tp.traderevolution.com/entity/87716
        {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OrderTypeNotAllowedBySession);
        }

        return IsAllowedResponce.AllowedResponce();
    }

    public static IsOrderCancelingAllowed (order: Order | null, ignoreLockTrading = false, ignoreCheckByAccount = false, ignoreCheckBySymbol = false): IsAllowedResponce {
    // CurrentRule = IsAllowedRule.IsOrderCancelingAllowed;
        if (order == null) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        if (ApplicationInfo.isExploreMode) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByExploreMode);
        }

        if (TradingLockUtils.TradingLock.LockTradingByPassword) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTradingByPassword);
        }

        // Analyze "LOCK_TRADING"
        if (!ignoreLockTrading && TradingLockUtils.TradingLock.tradingLocked) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTrading);
        }

        // Analyze "FUNCTION_TRADING" rule
        if (!DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleTrading);
        }

        // Analyze "FUNCTION_TRADING" rule  ++ AccountTradeStatus
        if (!ignoreCheckByAccount && !IsAllowed.Check_FUNCTION_TRADING_BY_ACCOUNT_Rule(order.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionTrading);
        }

        // Analyze "FUNCTION_SELF_TRADING_Rule" rule
        if (!ignoreCheckByAccount && !IsAllowed.Check_FUNCTION_SELF_TRADING_Rule(order.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionSelfTrading);
        }

        // Analyze instrument trading mode: open or closed
        if (!ignoreCheckBySymbol && !IsAllowed.Check_Instrument_TradingMode(order.Instrument, true)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentTradingMode);
        }

        // Analyze instrument delivery method and account type
        if (!ignoreCheckBySymbol && !ignoreCheckByAccount && !IsAllowed.Check_Instrument_For_Account_Delivery(order.Instrument, order.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentForAccountDelivery);
        }

        // Session - operation Cancel
        if (!ignoreCheckBySymbol && !IsAllowed.Check_Session_Operation(order.Instrument, SessionOperations.Cancel) && order.Status != OrderStatus.OFF_MARKET) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OperationNotAllowedBySession);
        }

        // Check route status
        if (!ignoreCheckBySymbol && !IsAllowed.Check_RouteRunning(order.Instrument) && order.Status != OrderStatus.OFF_MARKET) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RouteNotRunning);
        }

        // PENDING_NEW
        if (!IsAllowed.Check_OrderStatus(order)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByOrderStaus);
        }

        // #118545
        if (order.Instrument.BlockTradingBySession || order.Instrument.CurrentTradingSession === null) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OperationNotAllowedBySession);
        }

        // Allowed
        return IsAllowedResponce.AllowedResponce();
    }

    public static IsPositionModifyingAllowed (pos: Position | null): IsAllowedResponce {
    // CurrentRule = IsAllowedRule.IsPositionModifyingAllowed;
        if (pos == null) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        if (ApplicationInfo.isExploreMode) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByExploreMode);
        }

        if (TradingLockUtils.TradingLock.LockTradingByPassword) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTradingByPassword);
        }

        // Analyze "LOCK_TRADING"
        if (TradingLockUtils.TradingLock.tradingLocked) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTrading);
        }

        // Analyze "FUNCTION_TRADING" rule
        if (!DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleTrading);
        }
        // Analyze "FUNCTION_TRADING" rule  ++ AccountTradeStatus
        if (!IsAllowed.Check_FUNCTION_TRADING_BY_ACCOUNT_Rule(pos.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionTrading);
        }

        // Analyze "FUNCTION_SELF_TRADING_Rule" rule
        if (!IsAllowed.Check_FUNCTION_SELF_TRADING_Rule(pos.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionSelfTrading);
        }

        // Analyze instrument trading mode: open or closed
        if (!IsAllowed.Check_Instrument_TradingMode(pos.Instrument, false)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentTradingMode);
        }

        // // Session - operation modify
        if (!IsAllowed.Check_Session_Operation(pos.Instrument, SessionOperations.Modify)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OperationNotAllowedBySession);
        }

        // Check "FUNCTION_SLTP" rule
        if (!DataCache.isAllowedForAccount(RulesSet.FUNCTION_SLTP, pos.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleSlTpNotAllowed);
        }

        // Route
        if (!IsAllowed.IsSLTPAllowed(pos.Instrument, pos.Account, true).Allowed && !IsAllowed.IsSLTPAllowed(pos.Instrument, pos.Account, false).Allowed) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedRouteOrderType);
        }

        if (pos.SuperPositionId != '-1' && !pos.isSuperPosition) // Cant modify FIFO sub position #80010
        {
            return IsAllowedResponce.NotAllowedResponce();
        }

        if (pos.IsPendingExerciseOptionStatus()) // #93127 Изменения на клиенте связанные со статусом Pending exercise: В контекстном меню блокируем возможность: Modify position, Modify SL to breakevent, Quick SL/TP,  Close position
        {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OptionPendingExerciseStatus);
        }

        if (pos.ProductType == ProductType.CorporateAction) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedCorporateActionPositions);
        }

        if (IsAllowed.IsMaxLotHasBeenReached(pos)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedMaxLotHasBeenReached);
        }

        // Allowed
        return IsAllowedResponce.AllowedResponce();
    }

    public static IsPositionProductTypeModifyingAllowed (posArr): IsAllowedResponce {
        const posArrKeys = Object.keys(posArr);

        // Пункт должен быть задисейблен, если выбрано несколько Positions.
        if (posArrKeys.length != 1) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        const pos = posArr[posArrKeys[0]];

        if (!pos) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        // Пункт должен быть задисейблен, если выбрана SuperPosition позиция.
        if (pos.isSuperPosition) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        const instrument = pos.Instrument;

        if (!instrument) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        const allowedProductType = InstrumentUtils.getAllowedProductTypeDict(instrument);

        // Пункт должен быть задисейблен, если для выбранной Position доступен только один Product type.
        if (allowedProductType.length <= 1) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        return IsAllowed.IsModifyProductTypeAllowed(pos);
    }

    public static IsPositionExerciseOptionAllowed (posArr): IsAllowedResponce // #93127 метод возвращает видимость пункта контекстного меню панели Positions
    {
        if (ApplicationInfo.isExploreMode) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByExploreMode);
        }

        const posArrKeys = Object.keys(posArr);

        for (let i = 0; i < posArrKeys.length; i++) {
            const pos = posArr[posArrKeys[i]];

            if (!pos || pos.IsPendingExerciseOptionStatus()) {
                return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OptionPendingExerciseStatus);
            }

            if (pos.isTradePosition) // 93127: Для позиций с типом неттинга FIFO пункт Exercise position должен появляться только для Суперпозиции
            { return IsAllowedResponce.NotAllowedResponce(); }

            const instrument = pos.Instrument;

            if (!instrument) {
                return IsAllowedResponce.NotAllowedResponce();
            }

            if (!instrument.IsAmericanExerciseStyle() && !instrument.AllowOptionsExerciseOnLastTradeDate()) // Пункт отображается и не активен, если в панели выбрано несколько позиций по опционам с Exercise style = American.
            {
                return IsAllowedResponce.NotAllowedResponce();
            }
        }

        return posArrKeys.length > 0 ? IsAllowedResponce.AllowedResponce() : IsAllowedResponce.NotAllowedResponce(/* ? */);
    }

    public static IsPositionExerciseCancelAllowed (posArr): IsAllowedResponce // #93127 метод возвращает enabled пункта контекстного меню панели Positions
    {
        if (ApplicationInfo.isExploreMode) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByExploreMode);
        }

        if (!IsAllowed.IsPositionExerciseCancelVisible(posArr).Allowed) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        const posArrKeys = Object.keys(posArr);

        if (posArrKeys.length != 1) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.MoreThanOnePosSelected);
        }

        const pos = posArr[posArrKeys[0]];

        if (!pos) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        const instrument = pos.Instrument;

        if (!instrument || !IsAllowed.Check_Session_Operation(instrument, SessionOperations.Open)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OpenNotAllowedInTrSession);
        }

        return posArrKeys.length > 0 ? IsAllowedResponce.AllowedResponce() : IsAllowedResponce.NotAllowedResponce(/* ? */);
    }

    public static IsPositionExerciseCancelVisible (posArr): IsAllowedResponce // #93127 метод возвращает видимость пункта контекстного меню панели Positions
    {
        const posArrKeys = Object.keys(posArr);

        for (let i = 0; i < posArrKeys.length; i++) {
            const pos = posArr[posArrKeys[i]];

            if (!pos || pos.isTradePosition || !pos.IsPendingExerciseOptionStatus()) // на самом деле тут причина в том что НЕ OptionPendingExerciseStatus но за ненадобностью пока не создавал новый идентификатор причины
            {
                return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OptionPendingExerciseStatus);
            }

            const instrument = pos.Instrument;

            if (!instrument) {
                return IsAllowedResponce.NotAllowedResponce();
            }

            if (!instrument.IsAmericanExerciseStyle() && !instrument.AllowOptionsExerciseOnLastTradeDate()) // Пункт отображается и активен, если в панели выбрана позиция по опциону со статусом Pending exercise.
            {
                return IsAllowedResponce.NotAllowedResponce();
            }
        }

        return posArrKeys.length > 0 ? IsAllowedResponce.AllowedResponce() : IsAllowedResponce.NotAllowedResponce(/* ? */);
    }

    public static IsPositionsReversingAllowed (positions: Record<string, Position>, checkAccountByAdmin: boolean = false): IsAllowedResponce {
        let atLeastOnePositionPresent: boolean = false;
        for (const posID in positions) {
            atLeastOnePositionPresent = true;
            const position = positions[posID];
            const allowedResp = IsAllowed.IsPositionReversingAllowed(position, false, checkAccountByAdmin);
            if (!allowedResp.Allowed) {
                return allowedResp;
            }
        }
        return atLeastOnePositionPresent ? IsAllowedResponce.AllowedResponce() : IsAllowedResponce.NotAllowedResponce();
    }

    public static IsPositionReversingAllowed (pos: Position, ignoreCheckByAccount: boolean = false, checAccountByAdmin: boolean = false): IsAllowedResponce { // Проверить доступность реверса позиции
        // CurrentRule = IsAllowedRule.IsPositionReversingAllowed;
        if (pos == null) { return IsAllowedResponce.NotAllowedResponce(); }

        if (pos.isTradePosition) { return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedForTrades); }

        if (TradingLockUtils.TradingLock.LockTradingByPassword) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTradingByPassword);
        }

        if (checAccountByAdmin /* !Utils.RunByAutotest && */ && DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_POSITIONSADMIN) === true) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.PositionsAdmin);
        }

        if (TradingLockUtils.TradingLock.tradingLocked) { // Analyze "LOCK_TRADING"
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTrading);
        }

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING) === false) { // Analyze "FUNCTION_TRADING" rule
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleTrading);
        }

        const route: Route = DataCache.getRouteById(pos.Instrument.getRoute());
        if (!(route.IsAllowableTif(OrderTif.Day, OrderType.Market) ||
            route.IsAllowableTif(OrderTif.GTC, OrderType.Market) ||
            route.IsAllowableTif(OrderTif.IOC, OrderType.Market) ||
            route.IsAllowableTif(OrderTif.GTD, OrderType.Market))) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotSupportedTIF);
        }

        if (!ignoreCheckByAccount && !IsAllowed.Check_FUNCTION_TRADING_BY_ACCOUNT_Rule(pos.Account)) { // Analyze "FUNCTION_TRADING" rule
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionTrading);
        }

        if (!ignoreCheckByAccount && !IsAllowed.Check_FUNCTION_SELF_TRADING_Rule(pos.Account)) { // Analyze "FUNCTION_SELF_TRADING_Rule" rule
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionSelfTrading);
        }

        if (!IsAllowed.Check_Instrument_TradingMode(pos.Instrument, false)) { // Analyze instrument trading mode: open or closed
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentTradingMode);
        }

        if (!IsAllowed.Check_Last_Quote(pos.Instrument, OrderType.Market, true)) { // Last Quote: for Market order Type trading not allowed without quotes
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NoLastQuote);
        }

        if (!IsAllowed.Check_Session_OrderType(pos.Instrument, OrderType.Market)) { // Session - Market OrderType
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OrderTypeNotAllowedBySession);
        }

        if (!IsAllowed.Check_RouteRunning(pos.Instrument)) { // Check route status
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RouteNotRunning);
        }

        if (!ignoreCheckByAccount && !IsAllowed.Check_Instrument_For_Account_Visibility([pos.Account], pos.Instrument)) { // Check instrument/route visibility for account
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotVisibleInstrumentRoute);
        }

        if (!ignoreCheckByAccount && DataCache.isAllowedForAccount(RulesSet.FUNCTION_CLOSE_POSITIONS_DISABLE, pos.Account) === true) { // Check "FUNCTION_CLOSE_POSITIONS_DISABLE" rule
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleClosePositionDisable);
        }

        if (pos.Instrument.isMultiPositionPerSide()) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.WrongTraidingPositionType);
        }

        if (pos.IsPendingExerciseOptionStatus()) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OptionPendingExerciseStatus);
        }

        if (pos.ProductType === ProductType.CorporateAction) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedCorporateActionPositions);
        }

        return IsAllowedResponce.AllowedResponce();
    }

    public static IsOrderModifyingAllowed (order: Order): IsAllowedResponce {
        if (ApplicationInfo.isExploreMode) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByExploreMode);
        }

        // CurrentRule = IsAllowedRule.IsOrderModifyingAllowed;
        if (order == null) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        if (TradingLockUtils.TradingLock.LockTradingByPassword) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTradingByPassword);
        }

        // Analyze "LOCK_TRADING"
        if (TradingLockUtils.TradingLock.tradingLocked) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTrading);
        }

        // Analyze "FUNCTION_TRADING" rule
        if (!DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleTrading);
        }

        // // Analyze "FUNCTION_TRADING" rule  ++ AccountTradeStatus
        if (!IsAllowed.Check_FUNCTION_TRADING_BY_ACCOUNT_Rule(order.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionTrading);
        }

        // Analyze "FUNCTION_SELF_TRADING_Rule" rule
        if (!IsAllowed.Check_FUNCTION_SELF_TRADING_Rule(order.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionSelfTrading);
        }

        // Analyze instrument trading mode: open or closed
        if (!IsAllowed.Check_Instrument_TradingMode(order.Instrument, false)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentTradingMode);
        }

        // Analyze instrument delivery method and account type
        if (!IsAllowed.Check_Instrument_For_Account_Delivery(order.Instrument, order.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentForAccountDelivery);
        }

        // Session - operation modify
        if (!IsAllowed.Check_Session_Operation(order.Instrument, SessionOperations.Modify) && order.Status != OrderStatus.OFF_MARKET) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OperationNotAllowedBySession);
        }

        // // Check route status
        if (!IsAllowed.Check_RouteRunning(order.Instrument) && order.Status != OrderStatus.OFF_MARKET) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RouteNotRunning);
        }

        // Market - modify not allowed but // https://tp.traderevolution.com/entity/88385
        if (order.OrderType === OrderType.Market && !IsAllowed.isOrderStatusAllowMarketOrderModification(order)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OperationNotAllowedByOrderType);
        }

        if (order.OrderType === OrderType.OCO || (order.BoundTo != '-1' && order.BoundTo)) {
            if (!IsAllowed.IsAccountAllowOCO([order.Account])) {
                return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleOCO);
            }
        }

        // Analyse "Replaceable" for non SLTp orders
        if (!order.DataCache.IsSLTP(order) && !order.Replaceable) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        if (order.PositionId != '-1' && !order.Active) {
            const allowedResult = IsAllowed.IsPositionModifyingAllowed(DataCache.getPositionById(order.PositionId));
            if (!allowedResult.Allowed) {
                return allowedResult;
            }
        }

        // PENDING_NEW
        if (!IsAllowed.Check_OrderStatus(order)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByOrderStaus);
        }

        // #118545
        if (order.Instrument.BlockTradingBySession || order.Instrument.CurrentTradingSession === null) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OperationNotAllowedBySession);
        }

        // #99540
        // No trading blocking when OnlyPositionClosing:
        // https://docs.google.com/document/d/1QTBbM_I0jRQ5H29fJYokuc_Cy7XfiCbWNIOypm6Vn7Q/edit
        // if (order.Instrument.IsPositionCloseOnly(order.ProductType, order.Account) && !isPosition)
        //     return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedPositionCloseOnly);

        // Allowed
        return IsAllowedResponce.AllowedResponce();
    }

    public static IsOrderExecutingAllowed (order: Order): IsAllowedResponce {
    // CurrentRule = IsAllowedRule.IsOrderExecutingAllowed;
        if (order == null) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        if (ApplicationInfo.isExploreMode) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByExploreMode);
        }

        if (TradingLockUtils.TradingLock.LockTradingByPassword) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTradingByPassword);
        }

        // Analyze "LOCK_TRADING"
        if (TradingLockUtils.TradingLock.tradingLocked) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTrading);
        }

        // Analyze "FUNCTION_TRADING" rule
        if (!DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleTrading);
        }

        // Analyze "FUNCTION_TRADING" rule  ++ AccountTradeStatus
        if (!IsAllowed.Check_FUNCTION_TRADING_BY_ACCOUNT_Rule(order.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionTrading);
        }

        // Analyze "FUNCTION_SELF_TRADING_Rule" rule
        if (!IsAllowed.Check_FUNCTION_SELF_TRADING_Rule(order.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionSelfTrading);
        }

        //  Analyze instrument trading mode: open or closed
        if (!IsAllowed.Check_Instrument_TradingMode(order.Instrument, false)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentTradingMode);
        }

        // Analyze instrument delivery method and account type
        if (!IsAllowed.Check_Instrument_For_Account_Delivery(order.Instrument, order.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentForAccountDelivery);
        }

        // Session - operation modify
        if (!IsAllowed.Check_Session_Operation(order.Instrument, SessionOperations.Modify) && order.Status != OrderStatus.OFF_MARKET) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OperationNotAllowedBySession);
        }

        // // Session - Market OrderType
        if (!IsAllowed.Check_Session_OrderType(order.Instrument, OrderType.Market)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OrderTypeNotAllowedBySession);
        }

        if (order.Instrument.BlockTradingBySession || order.Instrument.CurrentTradingSession == null) // #103032
        {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OperationNotAllowedBySession);
        }

        // // Check route status
        if (!IsAllowed.Check_RouteRunning(order.Instrument) && order.Status != OrderStatus.OFF_MARKET) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RouteNotRunning);
        }

        // // Check instrument/route visibility for account
        if (!IsAllowed.Check_Instrument_For_Account_Visibility([order.Account], order.Instrument)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotVisibleInstrumentRoute);
        }

        // Market - modify not allowed
        if (order.OrderType === OrderType.Market) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OperationNotAllowedByOrderType);
        }

        // nicky. Если запрещен тип маркет, или запрещено менять тип ордера,
        // скрывается возможность делать экзекьют ас маркет
        const rout = DataCache.getRouteByName(order.Route);
        if (rout !== null && (!rout.IsAllowableOrderType(OrderType.Market) || !rout.AllowTypeModify)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType);
        }

        // SL/TP нельзя выполнить как маркет
        if (order.DataCache.IsSLTP(order)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp);
        }

        // OCO нельзя выполнить как маркет, надо сначала снять баунд ту
        if (order.BoundTo?.length && order.BoundTo !== '-1') {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders);
        }

        // PENDING_NEW
        if (!IsAllowed.Check_OrderStatus(order)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByOrderStaus);
        }

        // #99540
        // No trading blocking when OnlyPositionClosing:
        // https://docs.google.com/document/d/1QTBbM_I0jRQ5H29fJYokuc_Cy7XfiCbWNIOypm6Vn7Q/edit
        // if (pos.Instrument.IsPositionCloseOnly(pos.ProductType, pos.Account))
        //     return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedPositionCloseOnly);

        // Allowed
        return IsAllowedResponce.AllowedResponce();
    }

    // TODO.
    public static IsTradingAllowed (accs: Account[] | null, ins: Instrument | null, firstOrderType: number, sessOperation: any = undefined, productType: any = undefined, forPositionSLTP: any = undefined): IsAllowedResponce {
        if (!ins || !accs?.length) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        if (ApplicationInfo.isExploreMode) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByExploreMode);
        }

        if (TradingLockUtils.TradingLock.LockTradingByPassword) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTradingByPassword);
        }

        // Analyze "LOCK_TRADING"
        if (TradingLockUtils.TradingLock.tradingLocked) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTrading);
        }

        // Analyze "FUNCTION_SELF_TRADING_Rule" rule
        if (!IsAllowed.Check_FUNCTION_SELF_TRADING_Rule(accs[0])) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionSelfTrading);
        }

        // Analyze instrument trading mode: open or closed
        if (firstOrderType !== -1 && !IsAllowed.Check_Instrument_TradingMode(ins, false)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentTradingMode);
        }

        // Analyze instrument delivery method and account type
        if (!IsAllowed.Check_Instrument_For_Account_Delivery(ins, accs)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentForAccountDelivery);
        }

        const routeRunning = IsAllowed.Check_RouteRunning(ins);

        if (firstOrderType !== -1 && !IsAllowed.Check_Session_OrderType(ins, firstOrderType)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OrderTypeNotAllowedBySession);
        }

        if (!IsAllowed.Check_Session_Operation(ins, sessOperation == undefined ? SessionOperations.Open : sessOperation)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OperationNotAllowedBySession);
        }

        if (!DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleTrading);
        }

        if (!IsAllowed.Check_FUNCTION_TRADING_BY_ACCOUNT_Rule(accs[0])) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionTrading);
        }

        // #99540
        // No trading blocking when OnlyPositionClosing:
        // https://docs.google.com/document/d/1QTBbM_I0jRQ5H29fJYokuc_Cy7XfiCbWNIOypm6Vn7Q/edit
        // if (ins.IsPositionCloseOnly(productType, accs[0]) && !forPositionSLTP)
        //     return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedPositionCloseOnly);

        return routeRunning
            ? IsAllowedResponce.AllowedResponce()
            : IsAllowedResponce.NotAllowedResponce();
    }

    public static IsOrderSLTPAllowed (pos: Order): IsAllowedResponce {
    // CurrentRule = IsAllowedRule.IsOrderSLTPAllowed;
        if (pos == null) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        //
        // Управление SLTP на уровне ордера, используется пока только в интеграциях
        //
        // if (!pos.SLTPAllowed)
        //     return IsAllowedResponce.NotAllowedResponce();

        if (TradingLockUtils.TradingLock.LockTradingByPassword) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTradingByPassword);
        }

        // Analyze "LOCK_TRADING"
        if (TradingLockUtils.TradingLock.tradingLocked) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.LockTrading);
        }

        // Analyze "FUNCTION_TRADING" rule
        if (!DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleTrading);
        }

        // Analyze "FUNCTION_TRADING" rule  ++ AccountTradeStatus
        if (!IsAllowed.Check_FUNCTION_TRADING_BY_ACCOUNT_Rule(pos.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionTrading);
        }

        //
        // Analyze "FUNCTION_SELF_TRADING_Rule" rule
        //
        if (!IsAllowed.Check_FUNCTION_SELF_TRADING_Rule(pos.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleFunctionSelfTrading);
        }

        // Analyze instrument trading mode: open or closed
        //
        if (!IsAllowed.Check_Instrument_TradingMode(pos.Instrument, false)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentTradingMode);
        }

        //
        // Analyze instrument delivery method and account type
        //
        if (!IsAllowed.Check_Instrument_For_Account_Delivery(pos.Instrument, pos.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.InstrumentForAccountDelivery);
        }

        // Session - operation modify
        // if (!Check_Session_Operation(pos.instrument, SessionOpeartions.Modify) && !CheckOffMarket(pos.Account))
        //     return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.OperationNotAllowedBySession);

        // Check route status
        if (!IsAllowed.Check_RouteRunning(pos.Instrument) && pos.Status != OrderStatus.OFF_MARKET) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RouteNotRunning);
        }

        // // Check instrument/route visibility for account
        // if (!Check_Instrument_For_Account_Visibility(new List<Account>(new Account[] { pos.Account }), pos.instrument))
        //     return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotVisibleInstrumentRoute);

        // Check rule "FUNCTION_SLTP"
        if (!pos.DataCache.isAllowedForAccount(RulesSet.FUNCTION_SLTP, pos.Account)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.RuleSlTpNotAllowed);
        }

        //
        // PENDING_NEW
        //
        if (!IsAllowed.Check_OrderStatus(pos)) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedByOrderStaus);
        }

        //
        // Route
        //
        if (!IsAllowed.IsSLTPAllowed(pos.Instrument, pos.Account, true).Allowed && !IsAllowed.IsSLTPAllowed(pos.Instrument, pos.Account, false).Allowed) {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedRouteOrderType);
        }

        // #99540
        // No trading blocking when OnlyPositionClosing:
        // https://docs.google.com/document/d/1QTBbM_I0jRQ5H29fJYokuc_Cy7XfiCbWNIOypm6Vn7Q/edit
        // if (pos.Instrument.IsPositionCloseOnly(pos.ProductType, pos.Account) && !pos.isPosition)
        //     return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedPositionCloseOnly);

        if (IsAllowed.IsMaxLotHasBeenReached(pos as Position)) // #107427 doc 2.1) https://docs.google.com/document/d/1roxsr6hYqGs_9kqR3S7DUt1PrIYr7CVF4RxT3yLDlxk/edit#heading=h.vv29hgs84duy
        {
            return IsAllowedResponce.NotAllowedResponce(IsAllowedResponceReason.NotAllowedMaxLotHasBeenReached);
        }

        // Allowed
        return IsAllowedResponce.AllowedResponce();
    }

    public static isOrderStatusAllowMarketOrderModification (order): boolean // https://tp.traderevolution.com/entity/88385
    {
        const status = order.Status;
        const statuses = OrderStatus;

        return status === statuses.NEW || status === statuses.PART_FILLED || status === statuses.WAITING_MARKET || status === statuses.OFF_MARKET || status === statuses.UNPLACED;
    }

    public static Check_OrderStatus (order): boolean {
        return order.Status !== OrderStatus.PENDING_NEW &&
        order.Status !== OrderStatus.PENDING_CANCEL &&
        order.Status !== OrderStatus.PENDING_REPLACE &&
        order.ExternalStatus !== ExternalOrderStatus.New &&
        order.ExternalStatus !== ExternalOrderStatus.PendingCancel &&
        order.ExternalStatus !== ExternalOrderStatus.PendingModification;
    }

    // Source C# Utils.IsAllowedSlTp
    public static IsSLTPAllowed (instrument: Instrument | null, account: Account | null, isSL): IsAllowedResponce {
        try {
            if (instrument === null) {
                return IsAllowedResponce.NotAllowedResponce();
            }

            if (IsAllowed.IsProtectiveOrders()) // #108472 <- 108200
            {
                return IsAllowedResponce.AllowedResponce();
            }

            // Отключаем SLTP если правило не разрешает
            let isEnable = false;
            if (account !== null) // #32868 скрыть Sl/Tp для мультивалютных аккаунтов
            { isEnable = account.AccountType !== AccountType.MultiAsset && DataCache.isAllowedForAccount(RulesSet.FUNCTION_SLTP, account); } else {
                isEnable = DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_SLTP);
            }

            if (!isEnable) {
                return IsAllowedResponce.NotAllowedResponce();
            }

            const route = DataCache.getRouteByName(instrument.getRoute());

            // #31120 (Trading) выставление Sl/Tp/TR stop
            let isSlSuportedByTif = false;
            let isTpSuportedByTif = false;
            const stopOrderType = OrderType[GeneralSettings.TradingDefaults.UseStopLimitInsteadStop ? 'StopLimit' : 'Stop']; // #91382

            const tifs = OrderTif;
            if (route !== null) {
                for (const key in tifs) {
                    if (route.IsAllowableTif(tifs[key], stopOrderType)) { isSlSuportedByTif = true; }

                    if (route.IsAllowableTif(tifs[key], OrderType.Limit)) { isTpSuportedByTif = true; }
                }
            }

            let resBool = true;
            if (isSL) {
                resBool = isSlSuportedByTif && IsAllowed.Check_Session_OrderType(instrument, stopOrderType);
            } else {
                resBool = isTpSuportedByTif && IsAllowed.Check_Session_OrderType(instrument, OrderType.Limit);
            }

            return (isEnable && resBool) ? IsAllowedResponce.AllowedResponce() : IsAllowedResponce.NotAllowedResponce();
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
            return IsAllowedResponce.NotAllowedResponce();
        }
    }

    // Source C# AdvancedOE.IsAllowableTS_ForSL
    // проверка доступности TrailingStop для StopLoss
    public static IsTrailingStopForSLAllowed (instrument: Instrument | null, account: Account | null, isPosition = false): IsAllowedResponce {
    // #31120 - на смену тифа - если тр.стоп недоступен в сл-нумерике убираем кнопку
        let isTsSuportedByTif = false;
        let allowTS = false;

        const route = instrument !== null ? DataCache.getRouteByName(instrument.getRoute()) : null;
        const tifs = OrderTif;

        if (route !== null)
        // все тифы
        {
            for (const key in tifs) {
                if (route.IsAllowableTif(tifs[key], OrderType.TrailingStop)) {
                    isTsSuportedByTif = true;
                    break;
                }
            }
        }

        if (account != null) {
            allowTS = (isTsSuportedByTif && IsAllowed.IsTradingAllowed([account], instrument, OrderType.TrailingStop, null, null, isPosition).Allowed ||
            DataCache.isAllowedForAccount(RulesSet.FUNCTION_DONT_USE_ROUTE_SETTING_FOR_CLOSE_TS_ORDER, account)) &&
            DataCache.isAllowedForAccount(RulesSet.FUNCTION_TRAILING_STOP, account);
        } // && IsAllowableTSForRoute();
        else {
            allowTS = (isTsSuportedByTif && IsAllowed.IsTradingAllowed([account], instrument, OrderType.TrailingStop, null, null, isPosition).Allowed ||
            DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_DONT_USE_ROUTE_SETTING_FOR_CLOSE_TS_ORDER));
        } // && IsAllowableTSForRoute()

        return (allowTS && isTsSuportedByTif) ? IsAllowedResponce.AllowedResponce() : IsAllowedResponce.NotAllowedResponce();
    }

    /// <summary>
    /// Is Protective Orders Allowed 107651
    /// </summary>
    public static IsProtectiveOrders (): boolean {
        return DataCache.isAllowedFeatureFlag(RulesSet.PROTECTIVE_ORDERS);
    }

    /// <summary>
    /// Check instrument/route visibility for account
    /// </summary>
    public static Check_Instrument_For_Account_Visibility (accs, ins: Instrument | null): boolean {
        if (ins == null || accs == null) {
            return true;
        }

        let insRouteVisibility = false;
        for (let i = 0; i < accs.length; i++) {
            insRouteVisibility = IsAllowed.CheckVisibilityForAccount(accs[i], ins) || insRouteVisibility;
        }

        return insRouteVisibility;
    }

    /// <summary>
    /// Check if instrument/route visible for account (RulesSet.VISIBILITY_ROUTE)
    /// </summary>
    public static CheckVisibilityForAccount (acc: Account | null, instrument: Instrument | null): boolean {
        if (acc == null || !instrument) {
            return true;
        }

        if (!instrument.Route)
        // аггрегатор не торговый по определению
        {
            return false;
        }

        const routesVisibilityRule = DataCache.getRuleStringValueForAccount(RulesSet.VISIBILITY_ROUTE, acc, null);

        if (!routesVisibilityRule) {
            return false;
        }

        if (routesVisibilityRule != RulesSet.ALLOWED_FOR_ALL) // String.Empty - допустимое значение для рула, нельзя игнорить
        {
        // ! старые(ПТ2) сервера шлют через ',', новые(ПТ3) через '|'
            const routes = routesVisibilityRule.split('|');
            const r = DataCache.getRouteByName(instrument.Route);
            if (r != null) {
                const routeID = r.RouteId.toString();
                if (routes.indexOf(routeID) == -1) {
                    return false;
                }
            }
        }

        if (!IsAllowed.CheckVisibilityForAccountInstrType(acc, instrument.TypeId)) {
            return false;
        }

        return true;
    }

    public static CheckVisibilityForAccountInstrType (acc: Account, TypeId): boolean {
        const instrTypeVisibilityRule = DataCache.getRuleStringValueForAccount(RulesSet.VISIBILITY_INSTRUMENT_TYPE, acc, null);

        if (instrTypeVisibilityRule == null) {
            return false;
        }

        if (instrTypeVisibilityRule != RulesSet.ALLOWED_FOR_ALL)// String.Empty - допустимое значение для рула, нельзя игнорить
        {
        // ! старые(ПТ2) сервера шлют через ',', новые(ПТ3) через '|'
            const instrTypes = instrTypeVisibilityRule.split('|');
            const typeId = TypeId.toString();
            if (instrTypes.indexOf(typeId) == -1) {
                return false;
            }
        }

        return true;
    }

    public static Check_FUNCTION_TRADING_BY_ACCOUNT_Rule (acc: Account): boolean {
        return acc !== null && DataCache.isAllowedForAccount(RulesSet.FUNCTION_TRADING, acc) &&
        (acc.AccountTradeStatus === AccountTradeStatus.ACTIVE_STATUS ||
            acc.AccountTradeStatus === AccountTradeStatus.LIQUIDATION_ONLY_STATUS ||
            acc.AccountTradeStatus === AccountTradeStatus.FINRA_DAY_TRADER_PATTERN_STATUS);
    }

    public static Check_FUNCTION_SELF_TRADING_Rule (acc: Account): boolean {
        const allowed = DataCache.isAllowedForAccount(RulesSet.SELF_TRADING, acc);
        if (allowed) {
            return true;
        }

        return false;
    }

    /// <summary>
    /// Analyze instrument trading mode: open or closed or tradingHalt
    /// </summary>
    public static Check_Instrument_TradingMode (ins: Instrument | null, forCanceling): boolean {
        if (ins == null) {
            return true;
        }// ??

        if (ins.TradingMode === TradingMode.FullyOpen ||
        ins.TradingMode === TradingMode.LiquidationOnly || // #113662
        (forCanceling && ins.TradingMode === TradingMode.TradingHalt)) {
            return true;
        } else {
            return false;
        } //  TradingMode.TradingHalt - only cancel
    }

    /// <summary>
    /// Analyze instrument delivery method and account type
    /// </summary>
    public static Check_Instrument_For_Account_Delivery (ins: Instrument | null, accs): boolean {
        if (ins == null || accs == null) {
            return true;
        }// ??

        const checkForOneAccount = (ins, acc) => {
            if (!ins || !acc) {
                return true;
            }

            if ((ins.DeliveryMethod === Instrument.Delivery_Physical && acc.AccountType != AccountType.MultiAsset) || (ins.DeliveryMethod == Instrument.Delivery_Cash && acc.AccountType == AccountType.MultiAsset)) {
                return false;
            }

            return true;
        };

        if (accs.length) // если передан массив аккаунтов
        {
            for (let i = 0; i < accs.length; i++) {
                const acc = accs[i];
                if (!checkForOneAccount(ins, acc)) {
                    return false;
                }
            }

            return true;
        }

        if (!checkForOneAccount(ins, accs)) // если передан один аккаунт
        {
            return false;
        }

        return true;
    }

    /// <summary>
    /// Analyze instrument trading mode: open or closed
    /// </summary>
    public static Check_Last_Quote (ins: Instrument | null, orderType: OrderType, ignoreInvalidQuoteForMarketOrder /* = true */): boolean {
        if (ins == null /* || ins.InstrType == Instrument.OPTIONS */) {
            return true;
        }

        if (orderType === OrderType.TrailingStop && ins.LastQuote == null) {
            return false;
        }

        if (orderType === OrderType.Market && !ignoreInvalidQuoteForMarketOrder && ins.LastQuote == null) {
            return false;
        }

        return true;
    }

    /// <summary>
    ///
    /// </summary>
    public static Check_Session_OrderType (ins: Instrument | null, lOrderType): boolean {
        if (ins == null) // для портфолио сессии не проверяем, или надо по каждому подинструменту
        {
            return true;
        }

        return ins.Check_Session_OrderType(lOrderType);
    }

    public static Check_Session_Operation (ins: Instrument, operation): boolean {
        const allowedOperations = Instrument.GetAllowedOperations(ins);

        return (allowedOperations & operation) == operation || DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_TRADING_OUT_OF_SESSION);
    }

    // TODO.
    public static Check_RouteRunning (ins: Instrument | null): boolean {
        if (!ins) return false;

        const dc = ins.DataCache;
        const route = dc.getRouteByName(ins.getRoute());

        return (route && route.RouteStatus === Route.ROUTE_RUNNING) || DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_TRADING_OUT_OF_SESSION);
    }

    public static LocalizationKeyIsAllowedResponceReason (isAllowedResponce): string {
        if (!isAllowedResponce?.Reason) {
            return '';
        }

        const reasonName = IsAllowedResponceReasonName[isAllowedResponce.Reason];
        return reasonName ? `IsAllowedResponceReason.${reasonName}` : '';
    }

    public static LocalizeIsAllowedResponceReason (isAllowedResponce): string // from IsAllowedResponce.NotAllowedResponce(reason) to localized text
    {
        const localizetionKey = IsAllowed.LocalizationKeyIsAllowedResponceReason(isAllowedResponce);
        return localizetionKey ? Resources.getResource(localizetionKey) : '';
    }

    /// <summary>
    /// Проверить доступность модификации Product type позиции
    /// </summary>
    public static IsModifyProductTypeAllowed (position): IsAllowedResponce {
        if (RiskPlan.GetAvailableProductTypes(/* position.Account, */position.Instrument).length >= 2 &&
        DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING_MODE_MODIFICATION) &&
        position.Instrument.TradingPositionType == TradingPositionType.OnePosition) {
            return IsAllowedResponce.AllowedResponce();
        } else {
            return IsAllowedResponce.NotAllowedResponce();
        }
    }

    public static MaxOrderAmountHasBeenReached (account: Account | null, instrument: Instrument | null, quantity: Quantity, productType: ProductType): IsAllowedResponce {
        if (!account || !instrument || !quantity) {
            return IsAllowedResponce.NotAllowedResponce();
        }

        if (account.MaxOrderAmount < 0) {
            return IsAllowedResponce.AllowedResponce();
        }

        let maxOrderAmount = account.MaxOrderAmount;
        if (quantity.inLots) {
            maxOrderAmount = maxOrderAmount / instrument.LotSize;
        }

        if (maxOrderAmount >= quantity.value) {
            return IsAllowedResponce.AllowedResponce();
        }

        const notAllowed = IsAllowedResponce.NotAllowedResponce();
        notAllowed.ReasonText = Resources.getResource('Web.Mobile.advancedOE.OEButtonDisabled.more') + InstrumentUtils.formatAmountValue(maxOrderAmount, instrument, account, productType);

        return notAllowed;
    }

    public static IsMaxLotHasBeenReached (position: Position | null, qtyToClose: any = undefined): boolean {
        if (!position) {
            return false;
        }

        if (!position.DataCache.getValidateMaxLotCloseOrderBoolRule()) {
            return false;
        }

        const maxLot = position.Instrument.getMaxLot(position.ProductType, position.Account);
        const amount = qtyToClose || position.Amount;
        return amount > maxLot;
    }
}

//
// прриходят с консоли enum IsAllowedRule in WebConsole.core.IsAllowedWEB
//
// IsAllowed.RequestsTypes =
// {
//     //PositionModifying = 1,
//     //OrderModifying = 2,
//     //ClosePosition = 3,
//     //ReversePosition = 4,
//     //MutualClose = 5,
//     //OrderExecuting = 6,
//     //OrderCancelling = 7,
//     //IsTradingAllowed = 8,
//     //IsAllowSLTP = 9,
//     //IsAllowStopLimit = 10,
//     //IsAllowStop = 11,
//     //IsAllowLimit = 12,
//     //ReverseByInstrument = 13,
//     //CancellAll = 14,
//     //IsTralingStopAllow = 15,
//     //IsOCOAllowed = 16,
//     //IsRollbackAllowed = 17,

//     //IsCloseAllPositionsAllowed = 18,
//     //IsCloseAllOrdersAllowed = 19,
//     //IsCloseAllAllowed_True_False = 20,
//     //isAllowed_TERMINAL_HTML = 21,
//     //isAllowedForMyUser_FUNCTION_FIFO_POSITIONS = 22,
//     //isAllowedForMyUser_FUNCTION_CHART_VOLUME_BARS = 23,
//     //isAllowed_FUNCTION_CLOSE_POSITIONS_DISABLE = 24,
//     //isAllowed_FUNCTION_MAM = 25,
//     //isAllowedForMyUser_FUNCTION_POSITIONSADMIN = 26,
//     //isAllowedForMyUser_FUNCTION_MUTUAL_CLOSE = 27,
//     //isAllowed_FUNCTION_MUTUAL_CLOSE = 28,
//     //isAllowedForMyUser_FUNCTION_SLTP = 29,
//     //isAllowed_FUNCTION_NEWS=30

// };

export class IsAllowedResponce {
    public Allowed = false;
    public Reason = undefined;
    public ReasonLocalizationKey = '';
    public ReasonText = '';

    public static AllowedResponce () {
        const res = new IsAllowedResponce();
        res.Allowed = true;
        return res;
    }

    public static NotAllowedResponce (reason = IsAllowedResponceReason.Unknown) {
        const res = new IsAllowedResponce();
        res.Allowed = false;
        res.Reason = reason;
        res.ReasonLocalizationKey = IsAllowed.LocalizationKeyIsAllowedResponceReason(res);
        res.ReasonText = IsAllowed.LocalizeIsAllowedResponceReason(res);

        // TODO
        // if (reason != IsAllowedResponceReason.Unknown)
        //    IsAllowed.SetReason(res.ReasonText);

        return res;
    }
}
