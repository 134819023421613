// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { HistoryType } from '../../../Utils/History/HistoryType';
import { ReloadHistoryParams } from '../../../Utils/History/ReloadHistoryParams';
import { Periods, TFInfo } from '../../../Utils/History/TFInfo';
import { SessionInfo } from '../../../Utils/History/SessionInfo';
import { TimeSpanPeriods } from '../../../Utils/Time/TimeSpan';
import { DataCache } from '../../DataCache';
import { CashItem } from '../../cache/History/CashItem';
import { TvHistoryConvertor } from '../Convertors/TvHistoryConvertor';
import { TvHistoryMetadata } from '../TradingViewPrimitives/TvHistoryMetadata';
import { TvHistorySubscriptionsContainer } from '../Containers/TvHistorySubscriptionsContainer';
import { type TvPeriodParams } from '../TradingViewPrimitives/TvPeriodParams';
import { type Bar, type LibrarySymbolInfo, type QuotesCallback, type ResolutionString } from '../charting_library';
import { type Instrument } from '../../cache/Instrument';
import { TvTimeZoneHelper } from '../Helpers/TvTimeZoneHelper';
import { type Account } from '../../cache/Account.js';
import { TvSymbolConvertor } from '../Convertors/TvSymbolConvertor';
import { TvInteriorIdCache } from '../Caches/TvInteriorIdCache';
import { TvSessionIdEnum } from '../TradingViewPrimitives/TvEnums';
import { CashItemUtils } from '../../../Utils/History/CashItemUtils';
import { Decimal } from 'decimal.js';

export class TvHistoryManager {
    // #loadHistoryAbortController = null;
    private account: Account | null = null;
    private readonly historyConvertor = new TvHistoryConvertor();
    subscriptionsContainer = new TvHistorySubscriptionsContainer();

    public setCurrentAccount (account): void {
        this.account = account;
        this.subscriptionsContainer.resetCacheByNewAccount();
    }

    async getBars (symbolInfo: LibrarySymbolInfo,
        resolution: string,
        periodParams: TvPeriodParams,
        onHistoryCallback,
        onErrorCallback) {
        const interiorId = TvInteriorIdCache.getInteriorId(symbolInfo.ticker);

        if (TvSymbolConvertor.isFakeSymbolName(interiorId)) {
            setTimeout(() => {
                onHistoryCallback([], new TvHistoryMetadata(true));
            }, 0);
            return;
        }

        const instrument: Instrument = DataCache.getInstrumentByName(interiorId);
        if (!instrument) {
            setTimeout(() => {
                onErrorCallback(`[TvHistoryManager::getBars]: Instrument ${interiorId} not found`);
            }, 0);
            return;
        }

        // this.#loadHistoryAbortController?.abort();
        // this.#loadHistoryAbortController = new AbortController();
        // const signal = this.#loadHistoryAbortController.signal;
        const historyParams = this.GetHistoryParams(periodParams, resolution, symbolInfo.subsession_id, instrument);
        const isFirstDataRequest = periodParams.firstDataRequest;

        const newCashItem = await CashItem.CreateWithHistory(instrument, historyParams, /* signal */null, isFirstDataRequest);

        if (!newCashItem) {
            setTimeout(() => {
                onHistoryCallback([], new TvHistoryMetadata(true));
            }, 0);
            return;
        }
        const period = newCashItem.TimeFrameInfo.Periods;
        const baseIntervals = newCashItem.FNonEmptyCashArray;
        const timeOffset = this.#getSessionOffset(instrument);
        const timeZone = this.getSessionTimeZone(instrument);
        const tvBars: Bar[] = this.historyConvertor.convertBaseIntervalsToTvBars(baseIntervals, period, timeOffset, timeZone);

        if (tvBars.length > 0) {
            const requestedFrom = this.convertTime(periodParams.from);
            const realFrom = new Date(tvBars[0].time);

            if (tvBars.length >= periodParams.countBack && requestedFrom.getTime() < realFrom.getTime()) {
                const additionalBars = await this.downloadBarsIfNeeded(instrument, resolution, periodParams, symbolInfo.subsession_id, realFrom);
                tvBars.unshift(...additionalBars);
            }
        }

        if (isFirstDataRequest) {
            this.subscriptionsContainer.insertCashItem(interiorId, resolution, newCashItem, timeOffset, timeZone);
        } else {
            newCashItem.Dispose();
        }

        const requestedBarsCount = periodParams.countBack;
        const isNoData = tvBars.length < requestedBarsCount;

        if (isNoData) {
            console.log(`[TvHistoryManager::getBars]: No data for ${instrument.ShortName} ${resolution} ${this.convertTime(periodParams.from)} - ${this.convertTime(periodParams.to)}`);
        }

        if (tvBars.length > requestedBarsCount) {
            // на всякий случай самый первый бар, который потенциально может быть неправильно агрегирован
            tvBars.shift();
        }

        onHistoryCallback(tvBars, new TvHistoryMetadata(isNoData));
    }

    private async downloadBarsIfNeeded (instrument: Instrument,
        resolution: string,
        periodParams: TvPeriodParams,
        subsessionId: string,
        realFrom: Date): Promise<Bar[]> {
        const requestedFrom = this.convertTime(periodParams.from);
        const period = this.convertResolutionToPeriod(resolution);
        const basePeriod = Periods.TranslateToParsePeriod(period);
        if (basePeriod !== Periods.MIN) {
            return [];
        }

        const data: number = realFrom.getTime() - requestedFrom.getTime();
        const barLength = period * TimeSpanPeriods.TicksPerMinute;
        const periodsCountFractional: Decimal = new Decimal(data).div(barLength);
        const periodsCount: number = periodsCountFractional.trunc().toNumber();

        const correctedPeriodParams: TvPeriodParams = {
            ...periodParams,
            to: realFrom.getTime() / TimeSpanPeriods.TicksPerSecond,
            countBack: periodsCount + 1
        };
        const historyParams = this.GetHistoryParams(correctedPeriodParams, resolution, subsessionId, instrument);
        const newCashItem = await CashItem.CreateWithHistory(instrument, historyParams, /* signal */null, false);
        const baseIntervals = newCashItem.FNonEmptyCashArray;
        const timeOffset = this.#getSessionOffset(instrument);
        const timeZone = this.getSessionTimeZone(instrument);
        const tvBars: Bar[] = this.historyConvertor.convertBaseIntervalsToTvBars(baseIntervals, period, timeOffset, timeZone);
        return tvBars;
    }

    // Изначально я хотел создать отдельный класс для подписок
    // Но посчитал это затратным потому что нужно создавать отдельный кэшайтем и реквестить один бар истории
    // Это дополнительный запрос истории и накладные расходы
    subscribeBars (symbolInfo: LibrarySymbolInfo,
        resolution: ResolutionString,
        onRealtimeCallback: QuotesCallback,
        subscriberUID: string,
        onResetCacheNeededCallback: () => void) {
        const ticker = TvInteriorIdCache.getInteriorId(symbolInfo.ticker);
        if (TvSymbolConvertor.isFakeSymbolName(ticker)) {
            return;
        }

        this.subscriptionsContainer.subscribeBars(ticker,
            resolution,
            subscriberUID,
            onRealtimeCallback,
            onResetCacheNeededCallback);
    }

    unsubscribeBars (subscriberUID: string) {
        this.subscriptionsContainer.unsubscribeBars(subscriberUID);
    }

    GetHistoryParams (periodParams: TvPeriodParams,
        resolution: string,
        subsessionId: string,
        instrument: Instrument): ReloadHistoryParams {
        // hsa: в реквесте нужно указывать данные залогиненного аккаунта
        const acc: Account = DataCache.MainAccountNew || this.account;

        let rigthBorder;
        if (periodParams.firstDataRequest) {
            const now = new Date(); // like on chart
            rigthBorder = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
        } else {
            rigthBorder = this.convertTime(periodParams.to);
        }

        const tfInfo = this.getTimeFrameInfo(resolution, subsessionId, instrument);
        const basePeriod = Periods.TranslateToParsePeriod(tfInfo.Periods);
        const leftBorderInYears = basePeriod === Periods.DAY ? 10 : 3;

        let leftBorder = this.convertTime(periodParams.from);
        leftBorder.setFullYear(leftBorder.getFullYear() - leftBorderInYears); // 100 years ago?

        if (leftBorder.getTime() < 0) {
            leftBorder = new Date(0);
        }

        if (rigthBorder.getTime() < 0) {
            rigthBorder = new Date(0);
        }

        // correct right date border, beacuse this border include existing bar
        const rightDateInMillis = rigthBorder.getTime();
        rigthBorder.setTime(rightDateInMillis - 1);

        // почему-то сервер иногда возвращает на 1 бар меньше, хотя история еще есть, поэтому запрашиваю еще один
        // также последний бар может быть неполный (неправильно сагрегированный) из-за приблизительного указания количества баров
        // такой бар удаляется в конце
        const requestedBarsCount = periodParams.countBack + 2;

        const rhp = new ReloadHistoryParams();
        rhp.updateWithProps({
            instrument,
            account: acc,
            TimeFrameInfo: tfInfo,
            FromTime: leftBorder,
            ToTime: rigthBorder,
            count: requestedBarsCount,
            UseDefaultInstrumentHistoryType: true
        });

        return rhp;
    }

    private convertTime (timeInSeconds: number): Date {
        const millisecondsInSecond = TimeSpanPeriods.TicksPerSecond;
        const timeInTicks = timeInSeconds * millisecondsInSecond;
        return new Date(timeInTicks);
    }

    getBasePeriodFromResolution (resolution) {
        const r = resolution; // resolution.toUpperCase();
        if (isNaN(r)) {
            if (r.indexOf('D') !== -1) { return Periods.DAY; }
            if (r.indexOf('W') !== -1) { return Periods.WEEK; }
            if (r.indexOf('M') !== -1) { return Periods.MONTH; }
            if (r.indexOf('Y') !== -1) { return Periods.YEAR; }
        }

        return Periods.MIN;
    }

    convertResolutionToPeriod (resolution: string) {
        const num = parseInt(resolution) || 1;
        const basePeriod = this.getBasePeriodFromResolution(resolution);

        let res = num * basePeriod;
        if (res > 500000) { res = Periods.YEAR; }

        return res;
    }

    convertPeriodToResolution (period) {
        if (period >= Periods.YEAR) {
            const yearsNum = period / Periods.YEAR;
            return yearsNum * 12 + 'M';
        }
        if (period >= Periods.MONTH) {
            const monthsNum = period / Periods.MONTH;
            return monthsNum + 'M';
        }
        if (period >= Periods.WEEK) {
            const weeksNum = period / Periods.WEEK;
            return weeksNum + 'W';
        }
        if (period >= Periods.DAY) {
            const daysNum = period / Periods.DAY;
            return daysNum + 'D';
        }

        return period.toString(); // minutes period equals to minutes resolution
    }

    getTimeFrameInfo (resolution: string, subsessionId: string, instrument: Instrument): TFInfo {
        const tfInfo = new TFInfo();
        tfInfo.Periods = this.convertResolutionToPeriod(resolution);
        tfInfo.HistoryType = this.getHistoryType(instrument);
        tfInfo.SessionInfo = new SessionInfo(subsessionId === TvSessionIdEnum.regular);
        tfInfo.Plan = DataCache.GetSpreadPlan(this.account);
        return tfInfo;
    }

    getHistoryType (instrument) {
        return instrument ? instrument.DefaultChartHistoryType : HistoryType.BID;
    }

    dispose () {
        this.subscriptionsContainer.dispose();
    }

    #getSessionOffset (instrument) {
        const firstSessionPeriod = instrument?.GetZeroTradingSession();
        if (!firstSessionPeriod) {
            return 0;
        }

        return firstSessionPeriod.TimeOffset;
    }

    private getSessionTimeZone (instrument): string {
        const firstSessionPeriod = instrument?.GetZeroTradingSession();
        if (!firstSessionPeriod) {
            return '';
        }

        return TvTimeZoneHelper.getTimeZoneForTV(firstSessionPeriod);
    }
}
