import { OptionAnalyzerChart } from '../../../Chart/OptionAnalyzerChart';
import { BaseChartRactive } from './BaseChartRactive';

export class OptionAnalyzerChartRactive extends BaseChartRactive<OptionAnalyzerChart> {
    public override getType (): string { return 'OptionAnalyzerChart'; }
    public override onteardown (): void {
        this.terceraChart.detachOptionTrader();
        super.onteardown();
    }

    public override createChart (): OptionAnalyzerChart {
        return new OptionAnalyzerChart(this.context, super.get('terceraChartPanelContext'));
    }

    /*     public refreshProbabilityLines (): void {
        this.terceraChart.refreshProbabilityLines();
    }

    public refreshProbabilityValues (): void {
        void this.terceraChart.refreshProbabilityValuesAsync();
    } */
}
