// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { AccountSeal } from '../../Commons/cache/AccountEnums';
import { type AccountTradeStatus } from '../Account/AccountTradeStatus';
import { type AccountType } from '../Account/AccountType';
import { type AccountAdditionalInfoItem } from '../Account/AdditionalInfoItem';
import { AssetTradingMode, CalculationMethod } from '../Asset/AssetConst';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';
import { type StatisticsGroupMessage } from './StatisticsGroupMessage';

export class DirectAccountStatusMessage extends DirectMessageBaseClass {
    public BstrAccount: string | null = null;
    public Email: string | null = null;
    public TelephoneNumber: string | null = null;
    public FirstName: string | null = null;
    public MiddleName: string | null = null;
    public LastName: string | null = null;
    public UserID: string | null = null;
    public UserLogin: string | null = null;
    public AllowedRoutes: string | null = null;

    /// добавили специально для интеграций, храним всякую доп. инфу
    public AccountDescription: any = null;

    public AllowOvernightTrading: any = null;

    public CommisionId: number | null = null;
    public SpreadPlanId: number | null = null;
    public CrossratesPlanId: number | null = null;
    public RiskPlanId: number | null = null;
    public SwapPlanId: number | null = null;
    public ChargingPlanId: number | null = null;
    public FundingRateMarkupPlanId: number | null = null;
    public TodayTrades: number | null = null;

    // UserId того, кто залочил этого пользователя
    public LockedBy: number | null = null;

    public AccountMode = AccountSeal.Unspecified;

    // VISIBILITY_FLAG_OWN = 1 - собственный акаунт
    // VISIBILITY_FLAG_GROUP = 2 - видимость по группе
    // VISIBILITY_FLAG_LINK = 4 - видимость по линке
    // VISIBILITY_FLAG_MAM = 5 - видимость по мам группе
    public AccountVisibilityFlag = 0;
    public MaxRelativeDrawDownLevel: number | null;

    public ClientType = 5; // TODO. User.CLIENT_NET
    public GroupId = -1;
    public FundType = -1;
    // public MaxOrdersPerDay: any = null
    public MaxPositions: number | null = null;
    public MaxPendingOrders: number | null = null;

    /// #50403
    public MaxPendingOrdersValue: number | null = null;

    public IsMasterAccount = false;

    // +++ AlexB: признак того что юзер подписна сертификатом
    public IsSigned = false;

    public IntradayRiskManagement: boolean | null = null;
    public AllowTradingOnPrepostMarket: any = null;

    public TradingLevel: number | null = null;
    public WarningLevel: number | null = null;
    public MarginLevel: number | null = null;
    public WaivedMargin: number | null;

    public TodayVolume: number | null = null;
    public TodayTurnover: any = null;

    public MaxDailyLossLimit: number | null = null;
    public MaxDayVolume: any = null;
    public MaxOrderCapital: number | null = null;
    public MaxDrawdownLevel: number | null = null;

    public Status = 1;
    public Role = 0; // TODO. User.ROLE_USER
    public AccountTradeStatus: AccountTradeStatus = null;
    public StopTradingReason: any = null;

    // #49740 - для связи аккаунта с интерест планом
    public InterestsOnBalancePlanID: number | null = null;

    public AssetSharesNames: string[] | null = null;

    public AccountType: AccountType = null;

    public AssetBalances: AssetBalanceMessage[] = [];
    public AssetInfoData: AssetInfoDataMessage[] = [];

    public AccountAdditionalInfo: any = null;

    public MaxWeeklyLossLimit: number | null = null;
    public TotalMaxPositionsQty: number | null = null;
    public MaxUrealizedLossLimit: number | null = null;
    public MaxOrderAmount: number | null;

    public AssetAdditionalInfo: any = null;
    public ClusterNode: any = null;

    public ExtendedFields: any = {};

    // #97383
    public DayTraderPattern: boolean | null = null;
    public DayTraderPatternProtection: boolean | null = null;
    public AvailableDayTrades: number | null = null;
    public UserAdditionalInfo: any = null;

    public StatisticsGroup: StatisticsGroupMessage | null = null;

    public PreferredInstrumentType: number = -1;
    public CustodialPlanId: number;
    public LossLimitPerTrade: number;

    private pin: string | null = null;
    private login: string | null = null;

    // #120451
    public RelativeDailyLossLimit: number | null = null;
    public CalculatedRelativeDailyLossLimit: number | null = null;
    public RelativeOfRelativeDailyLossLimit: number | null = null;

    constructor () {
        super(Message.CODE_ACCOUNTSTATUS);
    }

    get Pin (): string | null {
        return this.pin || this.BstrAccount;
    }

    set Pin (value: string | null) {
        this.pin = value;
    }

    get Login (): string | null {
        return this.login || this.BstrAccount;
    }

    set Login (value: string | null) {
        this.login = value;
    }
}

export class AssetInfoDataMessage {
    // Asset name
    public Name = '';
    // Total qty
    public TotalQuantity = 0;
    // Today traded qty (передает один раз при логине, лдальше должны расчитывать сами)
    public TodayTradedQty = 0;
    // Qty for margin available
    public TotalQuantityForMarginAvailable = 0;
    // Calculation method (0 - LAST, 1 - PREV_CLOSE)
    public CalculationMethod = CalculationMethod.Last;
    // Режим торговли (General/Delivery)
    public AssetTradingMode = AssetTradingMode.Delivery;
}

export class AssetBalanceMessage {
    public AssetId = 0;
    public AvailableMargin: number | null = null;
    public Balance: number | null = null;
    public BeginBalance: number | null = null;
    public BlockedForOrders: number | null = null;
    // public LockedForPAMM: any = null
    public BlockedSum: number | null = null;
    public CashBalance: number | null = null;
    public CreditValue: number | null = null;
    public RealizedPnl: number | null = null;
    public MaintanceMargin: number | null = null;
    public MarginDeficiency: number | null = null;
    public MarginSurplus: number | null = null;
    public ReservedBalance: number | null = null;
    public TodayFees: number | null = null;
    public UsedMargin: number | null = null;
    public TodayTurnover: number | null = null;
    public TodayTrades: number | null = null;
    public TodayVolume: number | null = null;
    public TodayRebates: number | null = null;
    public UnsettledProfit: number | null = null;
    public UnsettledLoss: number | null = null;
    public TodaySwaps: any = null;
    public CurrentDailyLoss: number | null = null;
    public CurrentWeeklyLoss: number | null = null;
    public TrailingDrawdownLevel: number | null = null;
    public StockOrdersReq: number | null = null;
    public UsedUnsettledNegativePremium: number | null = null;
    public UnusedUnsettledNegativePremium: number | null = null;
    public UnsettledPositivePremium: number | null = null;
    public UnsettledPremiumFromOpenSell: number | null = null;
    public UsedPremiumFromOpenSell: number | null = null;
    public UnusedPremiumFromOpenSell: number | null = null;
    public UsedUnsettledNegativeCashForStocks: number | null = null;
    public UnusedUnsettledNegativeCashForStocks: number | null = null;
    public UnsettledPositiveCashForStocks: number | null = null;
    public StocksLiquidity: number | null = null;
    public IsEmpty = false;
    public OptionPremiumReq: number | null = null;
    public UnsettledLossAvailableForWithdrawal: number | null = null;
    public WithdrawableCashForStocks: number | null = null;
    public WithdrawableUnsettledPnL: number | null = null;
    public WaivedMargin: any = null;
    public WarningMarginRequirement: number | null = null;
    public MarginBeforeWarning: number | null = null;
    public InitialMarginWithoutWaived: number | null = null;

    /// <summary>
    /// #82717
    /// </summary>
    public UnsettledCollateral: number | null = null;
    public WithdrawableUnsettledCollateral: number | null = null;
    public Collateral: number | null = null;

    /// #98161
    public UnusedSettledCashForStocks: number | null = null;
    public UnusedSettledCollateral: number | null = null;
    public UnsettledDeposit: number | null = null;

    /// #98625
    public UnusedIntradayInitialMargin: number | null = null;
    public UsedOvernightInitialMarginForIntraday: number | null = null;

    public AccruedDividends: number | null = null;
    public MaxRelativeDrawDownLevel: any = null;

    public LossLimitPerTrade: any = null;
    public WithdrawableOptionsPremium: number | null = null;

    public WithdrawalAvailable: number | null = null; // #110792

    public MaintenanceMarginReqPercent: number | null = null;
    public InitialMarginReqPercent: number | null = null;
    public WarningMarginReqPercent: number | null = null;
    public StopOutValue: number | null = null; // #119981
    public EODTrailingDrawdownLevel: number | null = null; // #121135

    public BuyingPowerAvailableFunds: any = null; // #106952
    public AssetAdditionalInfo: Record<string, AccountAdditionalInfoItem>; // new Dictionary<string, AccountAdditionalInfoItem>();

    // #120451
    public RelativeDailyLossLimit: number | null = null;
    public CalculatedRelativeDailyLossLimit: number | null = null;
    public RelativeOfRelativeDailyLossLimit: number | null = null;
    public AvailableCash: number | null = null;
    public TotalPositionsValue: number | null = null;
}
