// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { EntitlementProduct, EntitlementProductDataType, EntitlementProductRequestStatus, EntitlementProductRequestType, EntitlementProductRequestTypeMap } from './EntitlementPrimitives';
import { Message } from '../../../Utils/DirectMessages/DirectMessagesImport';
import { Connection } from '../../Connection';
import { CustomEvent } from '../../../Utils/CustomEvents';
import { ErrorInformationStorage } from '../../ErrorInformationStorage';
import { RulesSet } from '../../../Utils/Rules/RulesSet';
import { DynProperty } from '../../DynProperty';
import { entitlementSubscribeDocumentScreenHandler, workspaceManagerHandler, messageBoxHandler } from '../../../Utils/AppHandlers';
import { Resources } from '../../properties/Resources';
import { ApplicationInfo } from '../../ApplicationInfo';
import { BasisType } from './EntitlmentProductDefinition';
import { type Instrument } from '../Instrument';

export class EntitlementManager {
    public DataCache: any;
    public Products: EntitlementProduct[] = [];
    public ProductsCache: Record<string, EntitlementProduct> = {};
    public NotificationProducts = {};
    public OnAddOrUpdate = new CustomEvent();
    public requestArr: any;
    public lastRequestProductID: any;
    public myDocumentScreen: any;

    constructor (dataCache) {
        this.DataCache = dataCache;
    }

    public Clear (): void {
        this.Products = [];
        this.ProductsCache = {};
        this.NotificationProducts = {};
        this.OnAddOrUpdate = new CustomEvent();
    }

    public NewMessage (message): void {
        switch (message.Code) {
        case Message.CODE_PFIX_PRODUCT_MESSAGE:
            this.ProcessProduct(message);
            break;
        case Message.CODE_PFIX_PRODUCT_DOCUMENT_RESP:
            this.ProcessDocuments(message);
            break;
        case Message.CODE_PFIX_PRODUCT_SUBSCRIPTION_MANAGEMENT_RESP:
            this.ProcessSubsResp(message);
            break;
        }
    }

    public ProcessProduct (message): void {
        let prod = this.ProductsCache[message.Id];
        if (!prod) {
            prod = new EntitlementProduct(message);
            this.ProductsCache[message.Id] = prod;
            this.Products.push(prod);
        } else {
            prod.UpdateProduct(message);
        }

        this.UpdateNotificationProductsCache(prod); // #109924

        try {
            prod.Image = message.ImageBytes.length
                ? 'data:image/svg+xml;base64,' + btoa(String.fromCharCode.apply(null, new Uint8Array(message.ImageBytes)))
                : null;
        } catch (ex) {
            prod.Image = null;
            ErrorInformationStorage.GetException(ex);
        }
        this._OnAddOrUpdate(message, false);
    }

    public ProcessSubsResp (message): void {
        const pr = this.ProductsCache[message.Id];
        if (!pr) {
            return;
        }

        pr.UpdateProductStatus(message);
        this._OnAddOrUpdate(message, true);
        if ((message.ReqType == EntitlementProductRequestType.Subscribe || message.ReqType == EntitlementProductRequestType.Unsubscribe) &&
        message.ReqStatus == EntitlementProductRequestStatus.Approved &&
        pr.ProductDefinitions != null && pr.ProductDefinitions.length > 0 &&
        this.DataCache.Loaded) {
            this.Resubscribe(pr.ProductDefinitions);
        }

        if (message.ReqStatus == EntitlementProductRequestStatus.Approved) {
            this.ProcessRequestArr();
        }

        if (!message.ErrorCode) {
            return;
        }

        const evData: any = {};

        evData.ErrorCode = message.ErrorCode;
        evData.ReqProductId = message.Id;

        this.DataCache.OnMessageForTicketViewer.Raise(evData);
    }

    public Resubscribe (entitlmentProductDefinitions): void {
        let instruments = [];
        for (let i = 0; i < entitlmentProductDefinitions.length; i++) { instruments = instruments.concat(this.FindInstrumentsForResubscribe(entitlmentProductDefinitions[i])); }

        this.DataCache.FQuoteCache.reSubscribe(false, instruments);
        this.DataCache.FQuoteCache.reSubscribe(true, instruments);

        if (!isNullOrUndefined(workspaceManagerHandler) && !isNullOrUndefined(workspaceManagerHandler.RefreshPanelsWithInstrumentDescriptions)) {
            workspaceManagerHandler.RefreshPanelsWithInstrumentDescriptions(instruments);
        }
    }

    public FindInstrumentsForResubscribe (entitlmentProductDefinition): Instrument[] {
        const instrumentsForResubscribe: Instrument[] = [];
        switch (entitlmentProductDefinition.Type) {
        case BasisType.Instrument:
            const ids = entitlmentProductDefinition.InstrumentIds;
            for (let i = 0; i < ids.length; i++) {
                const ins = this.DataCache.GetInstrumentsByInstrumentId(ids[i]);
                if (ins) {
                    instrumentsForResubscribe.push(ins);
                }
            }
            break;
        case BasisType.InstrumentGroup:
            const instrumentGroups = entitlmentProductDefinition.InstrumentGroups;
            const instruments = this.DataCache.Instruments;
            if (!instrumentGroups.length) {
                break;
            }

            for (const insK in instruments) {
                const ins = instruments[insK];
                if (ins && instrumentGroups.indexOf(ins.TypeId) !== -1) {
                    instrumentsForResubscribe.push(ins);
                }
            }
            break;
        case BasisType.TradingExchange:
            var tradingExchanges = entitlmentProductDefinition.TradingExchanges;
            if (!tradingExchanges.length) {
                break;
            }
            const instrumentsT = this.DataCache.Instruments;
            for (const insK in instrumentsT) {
                const ins = instrumentsT[insK];
                if (ins && tradingExchanges.indexOf(ins.TradingExchange) !== -1) {
                    instrumentsForResubscribe.push(ins);
                }
            }
            break;
        }
        return instrumentsForResubscribe;
    }

    public _OnAddOrUpdate (message, needHistItem): void {
        const pr = this.ProductsCache[message.Id];
        if (this.DataCache.Loaded && pr) {
            this.OnAddOrUpdate.Raise(pr, needHistItem);
        }
    }

    // Absolete
    public ProcessDocuments (message): any {

    }

    public IsDataSourceAvailable (instrument: Instrument | null): boolean {
        if (!instrument) {
            return false;
        }

        const ID = instrument.Id;
        for (let p = 0; p < this.Products.length; p++) {
            const prod = this.Products[p];
            if (prod.IsSubscribed()) {
                const defs = prod.ProductDefinitions;
                for (let j = 0; j < defs.length; j++) {
                    if (defs[j].IsDataSource) {
                        const instruments = this.FindInstrumentsForResubscribe(defs[j]);
                        for (let i = 0; i < instruments.length; i++) {
                            if (instruments[i].Id == ID) {
                                return true;
                            }
                        }
                    }
                }
            }
        }

        return false;
    }

    public IsExistSubscription (isDelayed: boolean, instrumentId: string, instrumentGroupId: number, tradingExchange: string): boolean {
        for (const prod of this.Products) {
            if (prod.IsSubscribed()) {
                for (const productDefinition of prod.ProductDefinitions) {
                    if (!productDefinition.IsExistDefinition(instrumentId, instrumentGroupId, tradingExchange)) {
                        continue;
                    }

                    if ((productDefinition.IsL3 && !isDelayed) || (productDefinition.IsDelay && isDelayed)) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    public GetProductCountByFilter (entitlementProductFilter): number // получить общее количество продуктов по фильтру
    {
        const products = this.ProductsCache;
        let result = 0;

        for (const productId in products) {
            const product = products[productId];
            const productBelongToFilter = product.IsBelongToFilter(entitlementProductFilter);

            if (productBelongToFilter && !product.ShowInSettings) // #110465
            {
                result++;
            }
        }

        return result;
    }

    public GetCategoriesProductCountByFilter (entitlementProductFilter): any // получить количество продуктов по фильтру, по каждой категории, а также общее кол-во в поле с ключом EntitlementManager.GET_PRODUCT_COUNT_BY_FILTER_ALL_KEY; метод пока используем только для проверки корректности подсчета в ProductsPanel + можно будет заюзать его в юниттестах
    {
        const products = this.ProductsCache;
        const numByCategory = {}; // количество по категориям с учетом фильтра entitlementProductFilter
        let result = 0; // общее количество с учетом фильтра entitlementProductFilter

        for (const productId in products) {
            const product = products[productId];
            const category = product.Category;
            const productBelongToFilter = product.IsBelongToFilter(entitlementProductFilter);

            if (productBelongToFilter) {
                if (!numByCategory[category]) {
                    numByCategory[category] = 0;
                }

                numByCategory[category]++;
                result++;
            }
        }

        if (!numByCategory[EntitlementManager.GET_PRODUCT_COUNT_BY_FILTER_ALL_KEY]) {
            numByCategory[EntitlementManager.GET_PRODUCT_COUNT_BY_FILTER_ALL_KEY] = result;
        } else {
            console.log("BINGO! There is product's category with 'unique' key as a result of EntitlementManager.GetProductCountByFilter");
        } // сюда не должно попасть никогда, надеюсь)

        return numByCategory;
    }

    public UpdateNotificationProductsCache (product): void {
        if (!product) {
            return;
        }

        const cache = this.NotificationProducts;

        if (product.ProductDataType == EntitlementProductDataType.Notifications) {
            if (!cache[product.Id]) {
                cache[product.Id] = product;
            }
        } else {
            if (cache[product.Id]) {
                delete cache[product.Id];
            } // пока нет возможности поменять DataType у существующего продукта но пусть будет
        }
    }

    public ProductsAllowed (): boolean // is allowed for user in BO -> Customer Access -> Products #110438
    {
        return this.DataCache.isAllowedForMyUser(RulesSet.FUNCTION_PRODUCTS);
    }

    public AddNotificationProperties (properties): void // add DynProperties than should be shown in Settings panel #109924
    {
        const notifications = this.NotificationProducts;

        if (!notifications) {
            return;
        }

        let sortIndex = 0;
        for (const id in notifications) {
            const p = notifications[id];
            if (p?.ShowInSettings) {
                const subscribed = p.IsSubscribed();
                if (!sortIndex) {
                    properties.push(new DynProperty('', '', DynProperty.STRING, DynProperty.SEPARATOR_GROUP2));

                    ++sortIndex; // vvv commented out due to #110518
                // let dp = new DynProperty("property.NotificationType", "", DynProperty.GROUP_SEPARATOR, DynProperty.NOTIFICATIONS_GROUP)
                // dp.sortIndex = ++sortIndex
                // properties.push(dp)
                }

                const dp = new DynProperty(p.Name, subscribed, DynProperty.BOOLEAN_EVT, DynProperty.NOTIFICATIONS_GROUP);
                dp.enabled = !(subscribed && !p.IsUnsubscribeAllowed) && !ApplicationInfo.isExploreMode; // если Unsubscribing scheme - prohibited, тогда чекбокс задизейблен
                dp.sortIndex = ++sortIndex; // пока про порядок ничего не указано, оставляю как есть (по порядку id продукта)
                dp.BeforeChangeEvent = function (dynProp, product, newCheck, resolver) {
                    newCheck && product.HasDocument && !dynProp.value
                        ? EntitlementManager.GetDocuments(product.Id).then(this.ShowDocumentsSubscr.bind(this, dynProp, product, resolver))
                        : resolver(newCheck);
                }.bind(this, dp, p);
                properties.push(dp);
            }
        }
    }

    public ShowDocumentsSubscr (dynProp, product, resolver, documents): void // for subscribing from Settings panel to products with Notification type #109924
    {
        if (!documents) {
            return;
        }

        if (this.myDocumentScreen) {
            return;
        }

        const documentScreen = entitlementSubscribeDocumentScreenHandler.Show(product.Id, documents);
        this.myDocumentScreen = documentScreen;
        documentScreen.needSendRequestOnDoneClick = false;
        documentScreen.customDisposeHandler = function (p) {
            const docScr = this.myDocumentScreen;
            const wasDone = docScr.doneWasClicked;
            if (wasDone) {
                p.DocumentArray = docScr.DocumentArray;
            }

            resolver(wasDone);
            this.myDocumentScreen = null;
        }.bind(this, product);
    }

    public callBackNotificationProperties (properties, fromJson): void // products with Notification type that could be subscribed from Settings panel #109924
    {
        const notifications = this.NotificationProducts;

        if (!notifications || fromJson) {
            return;
        }

        const requestArr = [];
        for (const id in notifications) {
            const p = notifications[id];
            if (p?.ShowInSettings) {
                const dp = DynProperty.getPropertyByName(properties, p.Name);
                if (DynProperty.isCorrect(dp) && p.IsSubscribed() != dp.value) {
                    requestArr.push({ subscribe: dp.value, Id: p.Id, documents: p.DocumentArray || null });
                    if (p.DocumentArray) {
                        p.DocumentArray = null;
                    }
                }
            }
        }

        if (requestArr.length > 0) {
            this.ProcessRequestArr(requestArr);
        }
    }

    public ProcessRequestArr (reqArr?): void {
        const arr = reqArr || this.requestArr;

        if (!arr?.length) {
            return;
        }

        const reqData = arr.pop();
        this.requestArr = arr;
        this.lastRequestProductID = reqData.Id;

        const dataSubs: any = {};
        dataSubs.ProductId = reqData.Id;
        dataSubs.RequestType = reqData.subscribe
            ? EntitlementProductRequestType.Subscribe
            : EntitlementProductRequestType.Unsubscribe;
        dataSubs.EntitlmentDocuments = reqData.documents || [];

        Connection.vendor.SendProductSubscriptionRequest(dataSubs);
    }

    public IsExistSubsctiption (isDelayed, instrumentId, instrumentGroupId, tradingExchange): boolean {
        for (let i = 0; i < this.Products.length; i++) {
            const product = this.Products[i];
            if (!product.IsSubscribed) {
                continue;
            }
            for (let i = 0; i < product.ProductDefinitions.length; i++) {
                const productDefinition = product.ProductDefinitions[i];
                if (!productDefinition.IsExistDefinition(instrumentId, instrumentGroupId, tradingExchange)) {
                    continue;
                }

                let isExist = false;
                if (productDefinition.IsL3) {
                    isExist = !isDelayed || isExist;
                } else if (productDefinition.IsDelay) {
                    isExist = isDelayed || isExist;
                }

                if (isExist) {
                    return true;
                }
            }
        }

        return false;
    }

    public GetSubscribedExchanges (): string [] {
        const exchanges: string[] = [];
        for (const product of this.Products) {
            if (!product.IsSubscribed()) {
                continue;
            }
            for (const productDefinition of product.ProductDefinitions) {
                if (productDefinition.Type !== BasisType.TradingExchange) continue;
                exchanges.push(...productDefinition.TradingExchanges);
            }
        }

        // remove duplicates and sort
        return [...new Set(exchanges)].sort((a, b) => a.localeCompare(b));
    }

    public GetQuoteDelay (instrument: Instrument): number {
        const instrumentId = instrument.Id;
        const instrumentGroupId = instrument.TypeId;
        const tradingExchange = instrument.TradingExchange;
        let delay = Number.MAX_VALUE;

        for (const product of this.Products) {
            if (!product.IsSubscribed()) {
                continue;
            }
            for (const productDefinition of product.ProductDefinitions) {
                if (!productDefinition.IsExistDefinition(instrumentId, instrumentGroupId, tradingExchange)) {
                    continue;
                }

                if (productDefinition.IsDelay &&
                    (productDefinition.IsL1 || productDefinition.IsL3)) {
                    delay = Math.min(product.QuoteDelay, delay);
                }
            }
        }

        if (delay === Number.MAX_VALUE || delay === -1) { delay = 0; }

        return delay;
    }

    public static ProductSubscribeRequest (Id, documents): void {
        const dataSubs: any = {};
        dataSubs.ProductId = Id;
        dataSubs.RequestType = EntitlementProductRequestType.Subscribe;
        dataSubs.EntitlmentDocuments = documents || [];
        Connection.vendor.SendProductSubscriptionRequest(dataSubs);
    }

    public static ProductUnSubscribeRequest (Id): void {
        const dataSubs: any = {};
        dataSubs.ProductId = Id;
        dataSubs.RequestType = EntitlementProductRequestType.Unsubscribe;
        dataSubs.EntitlmentDocuments = [];
        Connection.vendor.SendProductSubscriptionRequest(dataSubs);
    }

    public static ProductCancelSubscriptionRequest (Id): void {
        const dataSubs: any = {};
        dataSubs.ProductId = Id;
        dataSubs.RequestType = EntitlementProductRequestType.Cancel;
        dataSubs.EntitlmentDocuments = [];
        Connection.vendor.SendProductSubscriptionRequest(dataSubs);
    }

    public static SendSubscriptionHistoryReques (from, to): any {
        return Connection.vendor.SendSubscriptionHistoryReques(from, to);
    }

    public static GetDocuments (Id): any {
        return Connection.vendor.SendProductDocumentRequest(Id).then(function (msg) {
            if (!msg?.length) {
                return Promise.resolve(null);
            }

            if (msg.Code === 14) {
                return Promise.resolve(null);
            }

            return msg[0].GetDocuments();
        });
    }

    public static GET_PRODUCT_COUNT_BY_FILTER_ALL_KEY = '$ALL_PRODUCTS_NUM_BY_FILTER$'; // надеюсь, такой никогда не совпадет с названием категории, но можно еще чуток усложнить, например, нашими именами =D (можно конечно проверять наличие категории с таким названием и если что дублировать его пока не получится уникальное название, но также придется делать и в месте получения результата подсчета, короче хоть и 100% защита но немного вычурно... )

    public static GetReplacedStringWithProductData (data, textLocalKey): string // заменяет все свойственные ProductData вхождения в локализированной строке по ключу textLocalKey из объекта data (<date/time> заменяется на отформатиров. data.ReqTime; <requestType> на строковую константу из EntitlementProductRequestTypeMap; вместо <productName> подставляется data.Name)
    {
        if (!data || !textLocalKey) {
            return null;
        }

        const dateObj = data.ReqTime ? new Date(data.ReqTime) : null; // we can't use ReqTimeCreated from EntitlementProduct because it doesn't exist in EntitlementHistory (see comment below at Utils.GetMessageBoxForProductCancelRequest)
        const dateTime = dateObj ? dateObj.toLocaleString() : '';
        const reqType = EntitlementProductRequestTypeMap[data.ReqType];
        const name = data.Name;

        let text = Resources.getResource(textLocalKey);

        if (dateTime) {
            text = text.replace('<date/time>', dateTime);
        }

        if (reqType) {
            text = text.replace('<requestType>', reqType);
        }

        if (name) {
            text = text.replace('<productName>', name);
        }

        return text;
    }

    public static GetMessageBoxForProductCancelRequest (caller, data): any // Warning!: argument data can be type of EntitlementHistory or EntitlementProduct depending on the caller since the fields that are used have the same name (Id,Name,ReqType,ReqTime)
    {
        if (!caller || !data?.Id) {
            return null;
        }

        const OkCb = function () {
            EntitlementManager.ProductCancelSubscriptionRequest(data.Id);
        };
        const msgBox = messageBoxHandler.Show(
            Resources.getResource('panel.Products.Request.CancelRequest'),
            EntitlementManager.GetReplacedStringWithProductData(data, 'panel.Products.Request.CancelRequest.Message'),
            messageBoxHandler.msgType.Question,
            OkCb,
            null
        );

        msgBox.customDisposeHandler = function () { caller.myMsgBox = null; };

        return msgBox;
    }
}
