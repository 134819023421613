// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { VideoWidgetPanelSideBar } from '../../elements/SideBar/SideBarPanels/VideoWidgetPanelSideBar';
import { TerceraSideBarButton } from '../../elements/SideBar/TerceraSideBarButton';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';

export class VideoWidgetButton extends TerceraSideBarButton {
    override readonly tooltipKey: string = 'panel.VideoWidget';
    constructor () {
        super();
    }

    getType (): ControlsTypes {
        return ControlsTypes.VideoWidgetButton;
    }

    oncomplete (): void {
        super.oncomplete();

        VideoWidgetPanelSideBar.OnChangeBtnStateEvents.Subscribe(this.ChangeBlinkStatus, this);
        this.observe('liveStreamOnAir', this.onLiveStreamOnAirChanged);
    }

    onLiveStreamOnAirChanged (value: boolean): void {
        void this.set({ svgStyle: value ? 'videoWidgetBtnLive' : 'videoWidgetBtn' });
    }

    ChangeBlinkStatus (liveStreamOnAir): void {
        void this.set('liveStreamOnAir', liveStreamOnAir);
    }

    onteardown (): void {
        VideoWidgetPanelSideBar.OnChangeBtnStateEvents.UnSubscribe(this.ChangeBlinkStatus, this);
    }
}

TerceraSideBarButton.extendWith(VideoWidgetButton,
    {
        data: function () {
            return {
                text: '',
                liveStreamOnAir: false, // If there are live streams now, then the button will have a different text and view: js-button-video-widget.live
                svgStyle: 'videoWidgetBtn'
            };
        }
    });
