// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { DataCache } from '../../Commons/DataCache';
import { type ParamType } from '../../Commons/cache/AllowedReportConstants';
import { Resources } from '../../Commons/properties/Resources';
import { TerceraComboBox } from './TerceraComboBox';

export class TerceraAssetComboBox extends TerceraComboBox {
    public paramType: ParamType;

    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.observe('allItemVisible', this.repopulateItems);
    }

    public repopulateItems (): void {
        const items = [];
        const assets = DataCache.getAssetDict();
        for (const key in assets) {
            const asset = assets[key];
            items.push({ text: asset.Name, value: asset.Id });
        }

        items.sort(TerceraAssetComboBox.compareAssets);

        if (this.get('allItemVisible')) {
            items.splice(0, 0, {
                text: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.All'),
                value: 'All'
            });
        }

        void this.set('items', items);
    }

    public static compareAssets (a0, a1): number {
        return a0.text.localeCompare(a1.text);
    };
}

TerceraComboBox.extendWith(TerceraAssetComboBox, {
    data: function () {
        return { allItemVisible: true };
    }
});
