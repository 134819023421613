// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class HiloEscandina extends IndicatorScriptBase {
    public ExpirationDate: number;
    public Password: string;
    public priBarCal: boolean;
    public mode: any[];
    public tendency: { Rise: number, Nuetral: number, Fall: number };
    public Period: number;
    public MAType: number;
    public MAShift: number;
    public TendencyControl: number;

    private indMA_Max: iBuildInIndicator;
    private indMA_Min: iBuildInIndicator;
    constructor () {
        super();
        // #region Initialization
        this.Copyrights = '(c) TraderEvolution';
        this.DateOfCreation = '02.05.2020';
        this.ExpirationDate = 0;
        this.Version = '';
        this.Password = '66b4a6416f59370e942d353f08a9ae36';
        this.ProjectName = 'HiloEscandina';
        // #endregion

        this.SetIndicatorLine('Trend Up', Color.Blue, 2, LineStyle.Ladder);
        this.SetIndicatorLine('Trend Down', Color.Red, 2, LineStyle.Ladder);

        this.SeparateWindow = false;

        this.priBarCal = false;
        this.mode = [];
        this.tendency = {
            Rise: 1,
            Nuetral: 0,
            Fall: -1
        };

        this.Period = 14;
        super.InputParameter(new InputParameterInteger('Period', 'Period', 0, 1, 99999));

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of Moving Average', 1,
            [
                { SMA: MAMode.SMA },
                { EMA: MAMode.EMA },
                { SMMA: MAMode.SMMA },
                { LWMA: MAMode.LWMA }
            ]));

        this.MAShift = 1;
        super.InputParameter(new InputParameterCombobox('MAShift', 'Adjusted Period', 2,
            [
                { 'Shift 0': 0 },
                { 'Shift 1': 1 },
                { 'Shift 2': 2 },
                { 'Shift 3': 3 },
                { 'Shift 4': 4 },
                { 'Shift 5': 5 },
                { 'Shift 6': 6 },
                { 'Shift 7': 7 }
            ]));

        this.TendencyControl = 1;
        super.InputParameter(new InputParameterCombobox('TendencyControl', 'Tendency control', 3,
            [
                { 'Can not return': false },
                { 'Can return': true }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName('HE');
        this.priBarCal = true;
    };

    public override GetIndicatorShortName (): string {
        return 'HE';
    };

    public override getCustomName (): string {
        return 'HE (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.indMA_Max = this.Indicators.iMA(this.CurrentData, this.Period, this.MAType, this.MAShift, PriceType.High);
        this.indMA_Min = this.Indicators.iMA(this.CurrentData, this.Period, this.MAType, this.MAShift, PriceType.Low);
        return [this.indMA_Max, this.indMA_Min];
    };

    public OnQuote (): void {
        super.OnQuote();

        if (this.CurrentData.Count <= this.Period) { return; };

        const GraPreClo = this.CurrentData.GetPrice(PriceType.Close, 0);
        const GraPreOpe = this.CurrentData.GetPrice(PriceType.Open, 0);
        let ValIndMax = this.indMA_Max.GetValue();
        let ValIndMin = this.indMA_Min.GetValue();

        if (this.priBarCal) {
            this.priBarCal = false;

            this.SetValue(0, 0, ValIndMax);

            this.SetValue(1, 0, ValIndMin);

            if (GraPreClo > GraPreOpe) {
                this.mode[this.mode.length - 1] = this.tendency.Rise;
            } else {
                this.mode[this.mode.length - 1] = this.tendency.Fall;
            }
        } else {
            const prevHigh = this.GetValue(0, 1);
            const prevLow = this.GetValue(1, 1);
            const prevTendency = this.mode[this.mode.length - 1 - 1];

            if (prevTendency > this.tendency.Nuetral) {
                if ((GraPreClo < prevHigh) || (GraPreOpe < prevHigh)) {
                    this.SetValue(0, 0, 0);
                    this.SetValue(1, 0, ValIndMax);
                    this.mode[this.mode.length - 1] = this.tendency.Fall;
                } else {
                    ValIndMin = this.TendencyControl
                        ? ValIndMin
                        : Math.max(ValIndMin, prevHigh);

                    this.SetValue(0, 0, ValIndMin);
                    this.SetValue(1, 0, 0);
                    this.mode[this.mode.length - 1] = this.tendency.Rise;
                }
            } else {
                if ((GraPreClo > prevLow) || (GraPreOpe > prevLow)) {
                    this.SetValue(0, 0, ValIndMin);
                    this.SetValue(1, 0, 0);
                    this.mode[this.mode.length - 1] = this.tendency.Rise;
                } else {
                    ValIndMax = this.TendencyControl
                        ? ValIndMax
                        : Math.min(ValIndMax, prevLow);
                    this.SetValue(0, 0, 0);
                    this.SetValue(1, 0, ValIndMax);
                    this.mode[this.mode.length - 1] = this.tendency.Fall;
                }
            }
        }
    };

    public NextBar (): void {
        super.NextBar();

        this.mode.push(0);
    };
}
