// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { HistoryType } from '../../Utils/History/HistoryType';
import { ProcessorWorker } from './WebFix/ProcessorWorker';
import { DirectVendorBase } from './WebFix/DirectVendorBase';

import { ConectionResultEnum, ConnectionErrorsEnum } from '../../Commons/ConnectionEnums';
import { FINISH_BLOCK_TRANSFER } from '../../Utils/CommonConst';
import { ReloadHistoryParams } from '../../Utils/History/ReloadHistoryParams';
import { TFInfo } from '../../Utils/History/TFInfo';
import { type DirectReportResponseMessage, Message, type DirectReportGroupResponse, type DirectMarginMessage } from '../../Utils/DirectMessages/DirectMessagesImport';
import { HistoryProcessorWorker } from './WebFix/HistoryProcessorWorker';
import { ClusterNodeGroupContainer } from './WebFix/GroupUtils/ClusterNodeGroupContainer';
import { type BaseInterval } from '../../Utils/History/BaseInterval';
import { type SubscribeType } from '../../Utils/Enums/Constants';
import { type TextMessageType } from '../../Utils/Notifications/TextMessageType';
import { type BrokerMessageResponseType } from '../../Utils/Notifications/BrokerMessageResponseType';
import { type IMarginRequestParameters } from '../Interfaces/IMarginRequestParameters';
import { type IMarginResponse } from '../../Utils/Interfaces/Response/IMarginResponse';
import { type MultiplyPlaceOrderWrapper } from '../../Commons/Trading/MultiplyPlaceOrderWrapper';

export class PFSVendor extends DirectVendorBase {
    public stream: ProcessorWorker | null = null;
    public EPStream: any = null;
    public tradeStreams: any = {};
    public quoteStreams: any = {};

    // Full logingng
    public FullLogSubscribed = false;
    public FullLogHandler: any = null;

    public ClusterNodesDict = new ClusterNodeGroupContainer([]);

    public loginParams: any;
    public in: number;
    public out: number;

    constructor () {
        super();

        this.vendorName = 'PFIX';
    }

    public override async ConnectProcessPromise (params): Promise<any> {
        const me = this;
        this.login = params.login;
        this.loginParams = params;

        return await this.GetConnectionCredentials(params)
            .then(function (Authentication_Request_ResultData) {
                if (me.EPStream) {
                    me.EPStream.Disconnect();
                }

                if (Authentication_Request_ResultData.ErrorInConnection) {
                    return Authentication_Request_ResultData;
                }

                if (Authentication_Request_ResultData.verificationMode) {
                    return Authentication_Request_ResultData;
                }

                me.ClusterNodesDict = Authentication_Request_ResultData.CngContainer;

                const itemTradeStreams = Authentication_Request_ResultData.CngContainer.getClusterNodeGroupsByMode(ProcessorWorker.MODE_TRADESTREAM);
                if (itemTradeStreams.length === 0) {
                    return { Connected: false, ConnectionStateCode: ConectionResultEnum.Error, ErrorCode: ConnectionErrorsEnum.ERROR_UNKNOWN, Text: 'connect.connection.tradestream.error', ErrorInConnection: true };
                }

                params.accessToken = Authentication_Request_ResultData.accessToken;

                const TradeStreamsArr: any = [];

                for (let i = 0, len = itemTradeStreams.length; i < len; i++) {
                    const urlPair = itemTradeStreams[i].GetUrlsPair();
                    const url = params.isSSL ? urlPair.useSSL : urlPair.noSSL;
                    const stream = new ProcessorWorker(ProcessorWorker.MODE_TRADESTREAM);
                    stream.OnNewMessageDelegate = me.MessageProcess.bind(me);
                    me.tradeStreams[url] = stream;

                    stream.StreamKey = url;
                    stream.OnConnectionLost = me.ConnectionLost.bind(me);
                    if (me.stream) {
                        const prevStreamDecoder = me.stream.FMsgDecoder;
                        const newStreamDecoder = stream.FMsgDecoder;

                        me.CopyStreamLinks(prevStreamDecoder, newStreamDecoder);
                    }
                    me.stream = stream;

                    // Full logingng
                    if (me.FullLogSubscribed) {
                        stream.FullLogHandler = me.FullLogHandler;
                    }

                    const arrElement = stream.ConnectToServer(url, params);
                    TradeStreamsArr.push(arrElement);
                }

                const QuoteStreamsArr: any = [];
                const quoteTradeStreams = Authentication_Request_ResultData.CngContainer.getClusterNodeGroupsByMode(ProcessorWorker.MODE_QUOTESTREAM);

                if (quoteTradeStreams.length === 0) {
                    Authentication_Request_ResultData.Connected = true;
                    return Authentication_Request_ResultData;
                }

                for (let i = 0, len = quoteTradeStreams.length; i < len; i++) {
                    const urlPair = quoteTradeStreams[i].GetUrlsPair();
                    const url = params.isSSL ? urlPair.useSSL : urlPair.noSSL;
                    const stream = new ProcessorWorker(ProcessorWorker.MODE_QUOTESTREAM);
                    stream.OnNewMessageDelegate = me.MessageProcess.bind(me);
                    me.quoteStreams[url] = stream;

                    stream.StreamKey = url;

                    // Full logingng
                    if (me.FullLogSubscribed) {
                        stream.FullLogHandler = me.FullLogHandler;
                    }

                    const tradeMsgDecoder = me.stream?.FMsgDecoder;
                    const historyMsgDecoder = stream.FMsgDecoder;
                    me.CopyStreamLinks(tradeMsgDecoder, historyMsgDecoder);

                    const arrElement = stream.ConnectToServer(url, params);
                    QuoteStreamsArr.push(arrElement);
                }

                let prTA: Promise<any[]> | null = null;
                let prQA: Promise<any[]> | null = null;
                if (TradeStreamsArr.length > 0) {
                    prTA = Promise.all(TradeStreamsArr);
                }

                if (QuoteStreamsArr.length > 0) {
                    prQA = Promise.all(QuoteStreamsArr);
                }

                return Promise.all([prTA, prQA]).then(function (fuckingResulus) {
                    const TradeConnectionResultData = fuckingResulus[0];
                    const connectionResults = fuckingResulus[1];
                    let TradeConnected = true;
                    if (!isNullOrUndefined(TradeConnectionResultData)) {
                        for (let i = 0, len = TradeConnectionResultData.length; i < len; i++) {
                            TradeConnected = TradeConnectionResultData[i].Connected && TradeConnected;
                        }

                        if (!TradeConnected) {
                            return TradeConnectionResultData[0];
                        }

                        me.userSessionId = TradeConnectionResultData[0].userSessionId;
                        me.login = TradeConnectionResultData[0].login;
                    }

                    if (!isNullOrUndefined(connectionResults)) {
                        let quoteConnected = true;
                        for (let i = 0, len = connectionResults.length; i < len; i++) {
                            quoteConnected = connectionResults[i].Connected && quoteConnected;
                        }
                        Authentication_Request_ResultData.Connected = !!quoteConnected;
                    }

                    return Authentication_Request_ResultData;
                });
            })
            .then(function (ConnectionResultData) {
                if (ConnectionResultData.isPasswordExpired) {
                    ConnectionResultData.ConnectionStateCode = ConectionResultEnum.NeedChangePassword;
                    return ConnectionResultData;
                } else if (ConnectionResultData.ErrorInConnection) {
                    ConnectionResultData.ConnectionStateCode = ConectionResultEnum.Error;
                    return ConnectionResultData;
                } else {
                    ConnectionResultData.ConnectionStateCode = ConectionResultEnum.Connected;
                    return ConnectionResultData;
                }
            })
            .catch(function (connectionError) {
                if (ConnectionErrorsEnum.ERROR_NO_WORKING_SERVERS === connectionError) {
                    return { Connected: false, ConnectionStateCode: ConectionResultEnum.Error, ErrorCode: ConnectionErrorsEnum.ERROR_NO_WORKING_SERVERS, Text: 'screen.LoginScreen.Error.noWorkingServers', ErrorInConnection: true };
                }

                return { Connected: false, ConnectionStateCode: ConectionResultEnum.Error, ErrorCode: ConnectionErrorsEnum.ERROR_UNKNOWN, Text: 'connect.connection.unknown.error', ErrorInConnection: connectionError };
            });
    }

    public CopyStreamLinks (firstDecoder, secondDecoder): void {
        secondDecoder.FInstrumentDecodeMap = firstDecoder.FInstrumentDecodeMap;
        secondDecoder.FSymbolDecodeMap = firstDecoder.FSymbolDecodeMap;
        secondDecoder.FRouteDecodeMap = firstDecoder.FRouteDecodeMap;
        secondDecoder.FAssetDecodeMap = firstDecoder.FAssetDecodeMap;
        secondDecoder.FIdRouteDecodeMap = firstDecoder.FIdRouteDecodeMap;
        secondDecoder.FQuoteRouteMap = firstDecoder.FQuoteRouteMap;
        secondDecoder.FSourceDecodeMap = firstDecoder.FSourceDecodeMap;
    }

    public async GetConnectionCredentials (params): Promise<any> {
        this.login = params.login;
        this.loginParams = params;
        const stream = new ProcessorWorker(ProcessorWorker.MODE_AUTHENTICATION_REQUEST);
        stream.OnNewMessageDelegate = this.MessageProcess.bind(this);
        stream.OnError = this.ErrorOccurred.bind(this);
        this.EPStream = stream;

        // Full logingng
        if (this.FullLogSubscribed) {
            this.EPStream.FullLogHandler = this.FullLogHandler;
        }

        return await this.TryGetConnectionCredentials(stream, params, 0);
    }

    public async TryGetConnectionCredentials (stream: ProcessorWorker, params, connectionNumber: number): Promise<any> {
        const me = this;
        return await new Promise(async function (resolve, reject) {
            stream.OnError = reject;
            await stream.ConnectToServer(params.ServerAdressesArray[connectionNumber], params).then(function (data) {
                resolve(data);
            });
        }).catch(async function () {
            if (params.ServerAdressesArray.length - 1 !== connectionNumber) {
                return await me.TryGetConnectionCredentials(stream, params, ++connectionNumber);
            }

            return await Promise.reject(ConnectionErrorsEnum.ERROR_NO_WORKING_SERVERS);
        });
    }

    public async RefreshAccessToken (ConnectParams, fromReconnect): Promise<any> {
        const params = ConnectParams.ConnectParams;

        const stream = new ProcessorWorker(ProcessorWorker.MODE_AUTHENTICATION_REQUEST);

        if (this.FullLogSubscribed) {
            stream.FullLogHandler = this.FullLogHandler;
        }

        stream.isSSL = params.isSSL;

        return await this.InProcessRefreshAccessToken(stream, ConnectParams, fromReconnect, 0);
    }

    public async InProcessRefreshAccessToken (stream: ProcessorWorker, ConnectParams, fromReconnect, connectionNumber: number): Promise<any> {
        const me = this;
        return await new Promise(async function (resolve, reject) {
            stream.siteAddres = ConnectParams.ConnectParams.ServerAdressesArray[connectionNumber];

            stream.OnError = reject;
            await stream.OpenWS().then(async function (opened) {
                if (opened) {
                    return await stream.SendRefreshTokenMsg(ConnectParams.ConnectResultData.refreshToken)
                        .then(function (TokenDataObj) {
                            stream.Disconnect();
                            return TokenDataObj;
                        });
                } else { return { StreamOpened: false }; }
            }).then(function (data) {
                resolve(data);
            });
        }).catch(async function (cResolve) {
            if (ConnectParams.ConnectParams.ServerAdressesArray.length - 1 !== connectionNumber) {
                return await me.InProcessRefreshAccessToken(stream, ConnectParams, fromReconnect, ++connectionNumber);
            }

            return await Promise.resolve({ StreamOpened: false });
        });
    }

    public override async DisconnectProcessPromise (isNoNActivity): Promise<void> {
        if (this.EPStream) {
            this.EPStream.Disconnect(isNoNActivity);
        }

        this.EPStream = null;

        let keys = Object.keys(this.tradeStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (this.tradeStreams[keys[i]]) {
                this.tradeStreams[keys[i]].Disconnect(isNoNActivity);
                this.tradeStreams[keys[i]] = null;
            }
        }

        keys = Object.keys(this.quoteStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (this.quoteStreams[keys[i]]) {
                this.quoteStreams[keys[i]].Disconnect(isNoNActivity);
                this.quoteStreams[keys[i]] = null;
            }
        }

        await Promise.resolve();
    }

    public async CloseStreams (): Promise<void> {
        if (this.EPStream) {
            this.EPStream.CloseStream();
        }

        this.EPStream = null;

        let keys = Object.keys(this.tradeStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (this.tradeStreams[keys[i]]) {
                this.tradeStreams[keys[i]].CloseStream();
                this.tradeStreams[keys[i]] = null;
            }
        }

        keys = Object.keys(this.quoteStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (this.quoteStreams[keys[i]]) {
                this.quoteStreams[keys[i]].CloseStream();
                this.quoteStreams[keys[i]] = null;
            }
        }

        await Promise.resolve();
    }

    public override async GetHistoryPromise (historyParams, ConnectParams, signal): Promise<any[]> {
        if (historyParams.TimeFrameInfo.HistoryType === HistoryType.BIDASK_AVG) {
            return await this.CreateHistoryRequest_BIDASK_AVG(historyParams, historyParams.route, ConnectParams, signal);
        }

        const intervals = await this.CreateHistoryRequest_Normal(historyParams, historyParams.route, ConnectParams, signal);
        return intervals;
    }

    public async SendRequestToServer (stream: HistoryProcessorWorker, historyParams, routeId, signal): Promise<any> {
        stream.OnNewMessageDelegate = this.MessageProcess.bind(this);
        return await stream.SendRequestToServer(historyParams, routeId, signal)
            .then(async function (result) {
                stream.Disconnect();
                return await Promise.resolve(result);
            });
    }

    public async CreateHistoryRequest_Normal (historyParams, routeId, ConnectParams, signal): Promise<any[]> {
        if (historyParams.NeedApplySpreadPlan) {
            if (historyParams.NeedLoadSecondPartOfHistory) {
                return await this.RequestWithApplyingSpreadPlan(historyParams, routeId, ConnectParams, signal);
            }

            const stream = this.PrepareHistoryStream(ConnectParams);
            const opened = await stream.OpenWS();
            if (opened) {
                this.CopyStreamLinks(this.stream?.FMsgDecoder, stream.FMsgDecoder);
                const intervals = await this.SendRequestToServer(stream, historyParams, routeId, signal);

                let instr = null;
                const item = historyParams.Plan.GetItem(historyParams.InstrumentFullName);

                instr = item.SpreadInstrument;

                if (historyParams.TimeFrameInfo.HistoryType === HistoryType.BID) {
                    stream.ApplySpreadToBaseInterval(historyParams, intervals, null, instr);
                } else {
                    stream.ApplySpreadToBaseInterval(historyParams, null, intervals, instr);
                }

                return intervals;
            }
            return [];
        } else {
            const stream = this.PrepareHistoryStream(ConnectParams);
            const opened = await stream.OpenWS();

            if (opened) {
                this.CopyStreamLinks(this.stream?.FMsgDecoder, stream.FMsgDecoder);
                const intervals = await this.SendRequestToServer(stream, historyParams, routeId, signal);
                return intervals;
            }
            return [];
        }
    }

    public async RequestWithApplyingSpreadPlan (historyParams, routeId, ConnectParams, signal): Promise<any[]> {
        let instr = null;
        const item = historyParams.Plan.GetItem(historyParams.InstrumentFullName);

        instr = item.SpreadInstrument;

        const bidStream = this.PrepareHistoryStream(ConnectParams);
        const askStream = this.PrepareHistoryStream(ConnectParams);

        const okAstream = askStream.OpenWS()
            .then(function (opened) {
                if (opened) {
                    this.CopyStreamLinks(this.stream.FMsgDecoder, askStream.FMsgDecoder);

                    const tmpHistoryParams = new ReloadHistoryParams(historyParams);
                    const tf = new TFInfo();
                    tf.Periods = historyParams.TimeFrameInfo.Periods;
                    tf.HistoryType = HistoryType.ASK;
                    tmpHistoryParams.TimeFrameInfo = tf;

                    return this.SendRequestToServer(askStream, tmpHistoryParams, routeId, signal);
                }
                return Promise.resolve([]);
            }.bind(this));

        const okBstream = bidStream.OpenWS()
            .then(function (opened) {
                if (opened) {
                    this.CopyStreamLinks(this.stream.FMsgDecoder, bidStream.FMsgDecoder);
                    const tmpHistoryParams = new ReloadHistoryParams(historyParams);
                    const tf = new TFInfo();
                    tf.Periods = historyParams.TimeFrameInfo.Periods;
                    tf.HistoryType = HistoryType.BID;
                    tmpHistoryParams.TimeFrameInfo = tf;

                    return this.SendRequestToServer(bidStream, tmpHistoryParams, routeId, signal);
                }
                return Promise.resolve([]);
            }.bind(this));

        return await Promise.resolve(
            Promise.all([okBstream, okAstream])
                .then(function (results) {
                    const BidsCollection = results[0];
                    const AsksCollection = results[1];
                    askStream.ApplySpreadToBaseInterval(historyParams, BidsCollection, AsksCollection, instr);
                    if (historyParams.TimeFrameInfo.HistoryType == HistoryType.ASK) {
                        return AsksCollection;
                    } else {
                        return BidsCollection;
                    }
                })
        );
    }

    public async CreateHistoryRequest_BIDASK_AVG (historyParams, routeId, ConnectParams, signal): Promise<BaseInterval[]> {
        const originalHistoryType = historyParams.TimeFrameInfo.HistoryType;

        const bidStream = this.PrepareHistoryStream(ConnectParams);
        const askStream = this.PrepareHistoryStream(ConnectParams);
        const okBstream = bidStream.OpenWS()
            .then(function (opened) {
                if (opened) {
                    historyParams.TimeFrameInfo.HistoryType = HistoryType.BID;
                    this.CopyStreamLinks(this.stream.FMsgDecoder, bidStream.FMsgDecoder);
                    return this.SendRequestToServer(bidStream, historyParams, routeId, signal);
                }
                return Promise.resolve(Promise.resolve([]));
            }.bind(this));

        const okAstream = askStream.OpenWS()
            .then(function (opened) {
                if (opened) {
                    historyParams.TimeFrameInfo.HistoryType = HistoryType.ASK;
                    this.CopyStreamLinks(this.stream.FMsgDecoder, askStream.FMsgDecoder);
                    return this.SendRequestToServer(askStream, historyParams, routeId, signal);
                }
                return Promise.resolve(Promise.resolve([]));
            }.bind(this));

        return await Promise.resolve(
            Promise.all([okBstream, okAstream])
                .then(async function (results) {
                    const BidsCollection = results[0];
                    const AsksCollection = results[1];
                    const res = askStream.MergeBIDASKAVG(BidsCollection, AsksCollection, historyParams);
                    historyParams.TimeFrameInfo.HistoryType = originalHistoryType;
                    return await Promise.resolve(res);
                })
        );
    }

    public PrepareHistoryStream (ConnectParams): HistoryProcessorWorker {
        const params = ConnectParams.ConnectParams;
        const stream = new HistoryProcessorWorker();

        if (this.FullLogSubscribed) {
            stream.FullLogHandler = this.FullLogHandler;
        }

        const isSSL = params.isSSL;
        const [historyNodeAddress, supportedAggregations] = this.GetHistoryNode(isSSL);

        stream.isSSL = isSSL;
        stream.siteAddres = historyNodeAddress;
        stream.setSupportedAggregations(supportedAggregations);

        return stream;
    }

    public GetHistoryNode (isSSL): any[] | '' {
        const histClusterNodeGroups = this.ClusterNodesDict.getClusterNodeGroupsByMode(ProcessorWorker.MODE_HISTORYSTREAM);

        if (histClusterNodeGroups.length === 0) {
            return '';
        }

        const firstHistoryClusterNodeGroup = histClusterNodeGroups[0];

        const nodeUrlPair = firstHistoryClusterNodeGroup.GetUrlsPair();

        if (!nodeUrlPair) {
            return '';
        }

        const historyNodeAddress = isSSL ? nodeUrlPair.useSSL : nodeUrlPair.noSSL;
        const supportedAggregations = firstHistoryClusterNodeGroup.SupportedAggregationsGroups();

        return [historyNodeAddress, supportedAggregations];
    }

    public override async GenerateReportPromise (reportId, paramDict): Promise<DirectReportResponseMessage | DirectReportGroupResponse> {
    // TODO.
        return await this.stream?.generateReportPromise(reportId, paramDict);
    }

    public GetRouteIdByName (name): number {
        if (this.stream?.FMsgDecoder.IdRouteDecodeMap[name]) {
            return this.stream.FMsgDecoder.IdRouteDecodeMap[name];
        }
        return -1;
    }

    public MessageProcess (message): void {
        if (
            message.Code === Message.CODE_PFIX_PROPERTY_MESSAGE &&
        message.Name === FINISH_BLOCK_TRANSFER) {
            const finished = this.CheckAllFinishBlockTransfer();

            if (finished) {
                this.SubscribeCrossRates();
            }

            if (!finished) {
                return;
            }
        }

        if (message.Code === Message.CODE_PFIX_PROPERTY_MESSAGE && message.Name == 'LOGOUT') {
            this.Disconnect(message.Value);
        }

        if (message.Code === Message.CODE_ROUTE) {
            let urlFromMSG = '';

            if (message.QuoteServerAddress) {
                urlFromMSG = message.QuoteServerAddress.split('=')[0];
            }

            const QSI = message.QuoteServerInfo;
            let urlItem: any = null;

            if (QSI) {
                const quoteNode = this.ClusterNodesDict.getClusterNodeGroupsById(ProcessorWorker.MODE_QUOTESTREAM, QSI.NodeId);
                if (quoteNode) {
                    urlItem = quoteNode.GetUrlsPair();
                }
            }

            if (urlItem) {
                if (this.loginParams.isSSL) {
                    urlFromMSG = urlItem.useSSL;
                } else {
                    urlFromMSG = urlItem.noSSL;
                }
            }

            const item = this.quoteStreams[urlFromMSG];

            if (item) {
                item.RoutesSupported[message.Name] = true;
            } else {
                const streamsKeys = Object.keys(this.quoteStreams);

                if (streamsKeys.length === 1) // single server fix
                {
                    const item = this.quoteStreams[streamsKeys[0]];

                    if (item) {
                        item.RoutesSupported[message.Name] = true;
                    }
                }
            }
        }

        this.NotifyListeners(message);
    }

    public SubscribeCrossRates (): void {
        const validQuoteStream = this.GetValidQuoteStreamForSubscribeCrossRates();

        if (validQuoteStream !== null) {
            validQuoteStream.IsSubcribeOnCrossRates = true;
            validQuoteStream.SubscribeCrossRates();
        }
    }

    public GetValidQuoteStreamForSubscribeCrossRates (): any {
        let validQuoteStream = null;
        const streams = this.quoteStreams;
        const keys = Object.keys(streams);
        if (keys.length > 0) {
            const cPfixMsg = streams[keys[0]];
            if (cPfixMsg.IsSubcribeOnCrossRates) {
                return null;
            }
            validQuoteStream = cPfixMsg;
        }
        return validQuoteStream;
    }

    public CheckAllFinishBlockTransfer (): boolean {
        let finished = true;

        let keys = Object.keys(this.tradeStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (!this.tradeStreams[keys[i]].finished) {
                finished = false;
            }
        }

        keys = Object.keys(this.quoteStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (!this.quoteStreams[keys[i]].finished) {
                finished = false;
            }
        }

        return finished;
    }

    public override SubscribeSymbol (InstrumentId, route, quoteType, forse, isDataSource): void {
        const qS = this.GetQuoteStreamByRoute(route);
        if (qS) {
            qS.SubscribeQuote(InstrumentId, route, quoteType, forse, isDataSource);
        }
    }

    public override UnsubscribeSymbol (InstrumentId, route, quoteType, forse, isDataSource): void {
        const qS = this.GetQuoteStreamByRoute(route);
        if (qS) {
            qS.UnsubscribeQuote(InstrumentId, route, quoteType, forse, isDataSource);
        }
    }

    public GetQuoteStreamByRoute (route): any {
        const keys = Object.keys(this.quoteStreams);
        let qS: any = null;

        if (!this.stream) {
            return null;
        }

        const decoder = this.stream.FMsgDecoder;
        const necessaryRoute = decoder.GetRouteById(decoder.GetRouteQuoteId(route));
        for (let i = 0, len = keys.length; i < len; i++) {
            if (this.quoteStreams[keys[i]].RoutesSupported[necessaryRoute]) {
                qS = this.quoteStreams[keys[i]];
                break;
            }
        }
        return qS;
    }

    public SubscribeOnFullLoging (handler): void {
        let keys = Object.keys(this.tradeStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (this.tradeStreams[keys[i]]) {
                this.tradeStreams[keys[i]].FullLogHandler = handler;
            }
        }

        keys = Object.keys(this.quoteStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (this.quoteStreams[keys[i]]) {
                this.quoteStreams[keys[i]].FullLogHandler = handler;
            }
        }

        this.FullLogHandler = handler;
        this.FullLogSubscribed = true;
    }

    public UnSubscribeOnFullLoging (): void {
        let keys = Object.keys(this.tradeStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (this.tradeStreams[keys[i]]) {
                this.tradeStreams[keys[i]].FullLogHandler = null;
            }
        }

        keys = Object.keys(this.quoteStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (this.quoteStreams[keys[i]]) {
                this.quoteStreams[keys[i]].FullLogHandler = null;
            }
        }

        this.FullLogHandler = null;
        this.FullLogSubscribed = false;
    }

    public GetMessagesList (): Record<string, string> {
        return Message.CodesString;
    }

    public override async SendPing (): Promise<any> {
        let pingPromise = null;
        let keys = Object.keys(this.tradeStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (this.tradeStreams[keys[i]]) {
                pingPromise = this.tradeStreams[keys[i]].SendPing();
            }
        }

        keys = Object.keys(this.quoteStreams);
        for (let i = 0, len = keys.length; i < len; i++) {
            if (this.quoteStreams[keys[i]]) {
                this.quoteStreams[keys[i]].SendPing();
            }
        }

        if (pingPromise) {
            return pingPromise;
        } else {
            return await Promise.resolve(0);
        }
    }

    public async ChangePassword (curPwd, newPwd, UserID, verificationPassword, accessToken): Promise<any> {
        if (this.stream) {
            return await this.stream.ChangePassword(curPwd, newPwd, UserID, verificationPassword, accessToken);
        }

        return await Promise.resolve(null);
    }

    public static async SendSOAPRequest (request, connectTo, FullLogHandler): Promise<any> {
        const stream = new ProcessorWorker(ProcessorWorker.MODE_TRADESTREAM);
        if (FullLogHandler) {
            stream.FullLogHandler = FullLogHandler;
        }

        stream.isSSL = connectTo.isSSL;
        stream.siteAddres = connectTo.address;

        return await stream.OpenWS().then(async function (opened) {
            if (opened) {
                return await stream.SendSOAPRequest(request)
                    .then(async function (result) {
                        stream.Disconnect();
                        return await Promise.resolve(result);
                    });
            }
            return await Promise.resolve(null);
        });
    }

    public static async SendMessageToVendor (url, isSSL, msgType, dataForMsg, FullLogHandler): Promise<any> {
        return await ProcessorWorker.SendMessageToVendor(url, isSSL, msgType, dataForMsg, FullLogHandler);
    }

    public async ChangeTradingPassword (curPwd, newPwd): Promise<any> {
        if (this.stream) {
            return await this.stream.ChangeTradingPassword(curPwd, newPwd);
        }

        return await Promise.resolve(null);
    }

    public async AccountOperationRequest (data): Promise<any> {
        if (this.stream) {
            return await this.stream.AccountOperationRequest(data);
        }

        return await Promise.resolve(null);
    }

    public async SendAlertToServer (alertData): Promise<any> {
        if (!alertData.Account) {
            await Promise.resolve(); return;
        }

        const stream = this.getTradeStream(alertData.Account.ClusterNode);
        if (stream) {
            stream.SendAlertToServer(alertData); return;
        }

        await Promise.resolve();
    }

    public async MakeAlertAction (alertData): Promise<any> {
        if (!alertData.Account) {
            await Promise.resolve(); return;
        }

        const stream = this.getTradeStream(alertData.Account.ClusterNode);
        if (stream) {
            stream.MakeAlertAction(alertData); return;
        }

        await Promise.resolve();
    }
    // #region Trading

    public async placeOrder (data): Promise<any> {
        const stream = this.getTradeStream(data.account.ClusterNode);
        if (stream) {
            stream.placeOrder(data); return;
        }

        await Promise.resolve();
    }

    public async placeOrderAsync (data): Promise<any> {
        const stream = this.getTradeStream(data.account.ClusterNode);
        if (stream) {
            return await stream.placeOrderAsync(data);
        } else {
            await Promise.resolve();
        }
    }

    public async multiplyPlaceOrdersAsync (multiplyOrdersWrapper: MultiplyPlaceOrderWrapper): Promise<any> {
        const requestsArray = [];
        const accountsArray = multiplyOrdersWrapper.GetAllAccounts();
        for (const account of accountsArray) {
            const stream = this.getTradeStream(account.ClusterNode);
            if (stream !== null) {
                const req = stream.multiplyPlaceOrdersAsync(multiplyOrdersWrapper.GetArrayByAccount(account), multiplyOrdersWrapper.placedFrom);
                requestsArray.push(req);
            } else {
                requestsArray.push(Promise.resolve([]));
            }
        }
        // Promise.all returns array of results. so we have [[],[],...[]];
        // need concat in sigle array [...]
        const response = await Promise.all(requestsArray);
        const result = [];
        for (const resp of response) { result.push(...resp); }
        return result;
    }

    public async SendInformationMessage (data): Promise<any> {
        if (this.stream?.SendInformationMessage) {
            this.stream.SendInformationMessage(data);
            return;
        }

        await Promise.resolve();
    }

    public getTradeStream (key: string): ProcessorWorker {
        return this.tradeStreams[key] ?? null;
    }

    // TODO. Interface.
    public async cancelOrder (order, placedFrom): Promise<void> {
        const stream = this.getTradeStream(order.Account.ClusterNode);
        if (stream) {
            this.stream?.cancelOrder(order, placedFrom);
            return;
        }

        await Promise.resolve();
    }

    public async closePositions (closePositionData, placedFrom): Promise<void | any[]> {
        const promises: any = [];
        const clusterNodeDict = closePositionData.groupedByClusterNodeClosePositionData;
        for (const clusterNode in clusterNodeDict) {
            const closePositionDataByClusterNode = clusterNodeDict[clusterNode];
            const stream = this.getTradeStream(clusterNode);
            if (stream) {
                promises.push(stream?.closePositions(closePositionDataByClusterNode, placedFrom));
            }
        }

        return (promises.length > 0) ? await Promise.all(promises) : await Promise.resolve();
    }

    public async modifyPosition (data): Promise<any> {
        const stream = this.getTradeStream(data.account.ClusterNode);
        if (stream) {
            return stream.modifyPosition(data);
        }

        await Promise.resolve();
    }

    public async reversePosition (data): Promise<any> {
        const stream = this.getTradeStream(data.account.ClusterNode);
        if (stream != null) {
            await stream.reversePosition(data); return;
        }

        await Promise.resolve();
    }

    public async modifyOrder (data): Promise<any> {
        const stream = this.getTradeStream(data.account.ClusterNode);
        if (stream) {
            return await stream.replaceOrder(data);
        }

        await Promise.resolve();
    }

    // TODO. Refactor. Move to modifyOrder()?
    public override async changeOrderToMarket (order, placedFrom): Promise<any> {
        const stream = this.getTradeStream(order.Account.ClusterNode);
        if (stream) {
            stream.changeOrderToMarket(order, placedFrom); return;
        }

        await Promise.resolve();
    }

    public async modifyPositionProductType (pos, productType, quantity): Promise<any> {
        const stream = this.getTradeStream(pos.Account.ClusterNode);
        if (stream) {
            await stream.modifyPositionProductType(pos.PositionId, productType, quantity); return;
        }

        await Promise.resolve();
    }

    public async sendTradePassword (pass, ExternalResourse): Promise<any> {
        if (this.stream) {
            return await this.stream.sendTradePassword(pass, ExternalResourse);
        } else {
            return await Promise.resolve(null);
        }
    }

    // #endregion Trading

    // #region NFL - NonFixedList

    public async GetInstrumentList (patern, exchangeIDs, instrumentTypes, aliasLanguage, oldLookupType): Promise<any> {
        if (this.stream) {
            return await this.stream.GetInstrumentList(patern, exchangeIDs, instrumentTypes, aliasLanguage, oldLookupType);
        }
    }

    public async GetOptionsList (patern, exchangeIDs, instrumentTypes, aliasLanguage): Promise<any> {
        if (this.stream) {
            return await this.stream.GetOptionsList(patern, exchangeIDs, instrumentTypes, aliasLanguage);
        }
    }

    public async GetNonFixedInstrumentStrikes (ContractID: number): Promise<any> {
        if (this.stream) {
            return await this.stream.GetNonFixedInstrumentStrikes(ContractID);
        }
    }

    public async getInstrumentById (instrumentID: number): Promise<any> {
        if (this.stream) {
            return await this.stream.getInstrumentById(instrumentID);
        }
    }

    public async getInstrumentByNameNFL (instrumentName: string): Promise<any> {
        if (this.stream) {
            return await this.stream.getInstrumentByNameNFL(instrumentName);
        }
    }

    public async getInstrumentByInstrumentTradableID_NFL (InstrumentTradableID: number): Promise<any> {
        if (this.stream) {
            return await this.stream.getInstrumentByInstrumentTradableID_NFL(InstrumentTradableID);
        }
    }

    public async GetNonFixedInstrumentListByAssetName (AsssetName: string): Promise<any> {
        if (this.stream) {
            return await this.stream.GetNonFixedInstrumentListByAssetName(AsssetName);
        }
    }

    public async GetYieldRate (instrumentID, routeID): Promise<any> {
        if (this.stream) {
            return await this.stream.GetYieldRate(instrumentID, routeID);
        }
    }

    public SendExerciseRequest (positionID, status): void {
        if (this.stream) {
            this.stream.SendExerciseRequest(positionID, status);
        }
    }

    public SendInactivityPeriodState (msgText: string): void {
        if (this.stream) {
            this.stream.SendInactivityPeriodState(msgText);
        }
    }

    public SendLogsWhenSettingsWasChanged (propsForLog): void {
        if (this.stream) {
            this.stream.SendLogsWhenSettingsWasChanged(propsForLog);
        }
    }

    public SendNewsSubscribeMessage (routeIDArr, subscribeType: SubscribeType): void {
        if (this.stream) {
            this.stream.SendNewsSubscribeMessage(routeIDArr, subscribeType);
        }
    }

    public async SendNewsRequestMessage (routeID, number, startTime, endTime): Promise<any> {
        if (this.stream) {
            return await this.stream.SendNewsRequestMessage(routeID, number, startTime, endTime);
        }
    }

    public async SendCustomListRequest (userID, listType): Promise<any> {
        if (this.stream) {
            return await this.stream.SendCustomListRequest(userID, listType);
        }
    }

    public SendTradingSignalRequest (): void {
        if (this.stream) {
            this.stream.SendTradingSignalRequest();
        }
    }

    public SendTradingSignalSeenRequest (tradingSignalId): void {
        if (this.stream) {
            this.stream.SendTradingSignalSeenRequest(tradingSignalId);
        }
    }

    public SendSubscriptionStrategyRequest (tradingSystemId): void {
        if (this.stream) {
            this.stream.SendSubscriptionStrategyRequest(tradingSystemId);
        }
    }

    public SendSnapshotRequestMessage (tradableID, routeID): void {
        if (this.stream) {
            this.stream.SendSnapshotRequestMessage(tradableID, routeID);
        }
    }

    public async SendMarginRequest (parmeters: IMarginRequestParameters): Promise<DirectMarginMessage[]> {
        if (this.stream) {
            return await this.stream.SendMarginRequest(parmeters);
        }
    }

    public async SendMultiMarginRequestAsync (parmeters: IMarginRequestParameters[], type: number = 0): Promise<IMarginResponse[]> {
        if (this.stream) {
            return await this.stream.SendMultiMarginRequest(parmeters, type);
        }
    }

    public async SendPortfolioStatisticsRequest (accountId, type, startTime, endTime, routeId, instrumentTradableID): Promise<any> {
        if (this.stream) {
            return await this.stream.SendPortfolioStatisticsRequest(accountId, type, startTime, endTime, routeId, instrumentTradableID);
        }
    }

    public SendPortfolioModelRequest (): void {
        if (this.stream) {
            this.stream.SendPortfolioModelRequest();
        }
    }

    public async AlgorithmSubscribeMessage (subscribe, algorithmId, toQuoteStream = true): Promise<void> {
        let stream = this.stream;

        if (toQuoteStream) // https://tp.traderevolution.com/entity/109058 comments
        {
            for (const s in this.quoteStreams) {
                stream = this.quoteStreams[s];
                break;
            }
        }

        if (stream) {
            await stream.AlgorithmSubscribeMessage(subscribe, algorithmId);
        }
    }

    public async GetBrandingRules (brandingKey: string): Promise<any> {
        if (this.stream) {
            return await this.stream.GetBrandingRules(brandingKey);
        }
    }

    public SendBrokerMessageReport (inputMessage, type: TextMessageType): void // закоментовано, згодом відновлено  згідно з #110904 (для urgent BM було додано логіювання у #84587, судячи з усього зараз ця логіка вже є застарілою і непотрібною)
    {
        if (this.stream) {
            this.stream.SendBrokerMessageReport(inputMessage, type);
        }
    }

    public async SendBrokerResponseMessage (brokerMsgID: number, value: string, userID: number, userName: string, responseType: BrokerMessageResponseType, clusterNode): Promise<any> {
        let stream = this.stream;
        if (clusterNode) {
            stream = this.getTradeStream(clusterNode) || stream;
        }

        if (stream) {
            return await stream.SendBrokerResponseMessage(brokerMsgID, value, userID, userName, responseType);
        }
    }

    public async SendBrokerMessageHistoryRequest (userID: number): Promise<any> {
        if (this.stream) {
            return await this.stream.SendBrokerMessageHistoryRequest(userID);
        }
    }

    public GetInstrument (): any {

    }

    // #endregion NFL

    public async SendProductDocumentRequest (Id): Promise<any> {
        const stream = this.stream;
        if (stream) {
            return await stream.SendProductDocumentRequest(Id);
        }

        await Promise.resolve();
    }

    public async SendProductSubscriptionRequest (subsData): Promise<void> {
        const stream = this.stream;
        if (stream) {
            await stream.SendProductSubscriptionRequest(subsData);
            return;
        }

        await Promise.resolve();
    }

    public async SendSubscriptionHistoryReques (from: Date, to: Date): Promise<any> {
        const stream = this.stream;
        if (stream) {
            return await stream.SendSubscriptionHistoryReques(from, to);
        }

        await Promise.resolve();
    }

    public GetFirstTradeStreamData () {
        let stream: any = null;

        for (const _stream in this.tradeStreams) {
            stream = this.tradeStreams[_stream];
            break;
        }
        if (!stream) {
            return null;
        }

        return { isSSL: stream.isSSL, url: stream.siteAddres };
    }

    public SendDataSourceSubscriptionMessage (InstrumentId, route, service, subscriptionActionType, targetTrInsID): any {
        const qS = this.GetQuoteStreamByRoute(route);
        if (qS) {
            return qS.SendDataSourceSubscriptionMessage(InstrumentId, route, service, subscriptionActionType, targetTrInsID);
        }
    }

    public async SendCloseAccountRequestMessage (): Promise<any> {
        if (this.stream) {
            return await this.stream.SendCloseAccountRequestMessage();
        }
    }

    public async SendCloseAccountCancelRequestMessage (closeAccountRequestID: number): Promise<any> {
        if (this.stream) {
            return await this.stream.SendCloseAccountCancelRequestMessage(closeAccountRequestID);
        }
    }
}
