// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

export enum SlTpPriceType {
    Absolute = 0,
    Offset = 1,
    TrOffset = 2,
    ProtectiveAbsolute = 3
}

export enum DirectPropertyOwner {
    Unspecified = -1,
    Settings = 0,
    Group = 1,
    User = 2,
    Branding = 3,
    Account = 4,
    Local = 5,
    FeautreFlags = 97
}

export enum Leve2QuoteType {
    /// <summary>
    ///  обычная котировка
    /// </summary>
    None = 0,

    /// <summary>
    /// индикативная будет использоваться в B3 и Bovespa market data роутах - означает, что при сортировке такие котировки подымаются на верх стакана
    /// </summary>
    Indicative = 1,

    /// <summary>
    /// перемещаем вниз стакана ( прочерк ) используется на индийском рынке.
    /// </summary>
    WithoutPrice = 2
}

export enum Level2Update {
    Incremental = 0,
    FullRefresh = 1
}

// 0-бай, 1-селл
export enum AgressorFlagType {
    None = -1,
    Ask = 0,
    Bid = 1,
    CrossTrade = 2,
    Auction = 3,
    RLP = 4,
    NotSet = 255
}

export enum ReportMessageImportanceLevel {
    Normal = 0,
    Important = 1,
    Critical = 2
}

export enum ReportMessageSource {
    Unknown,
    BusinessRejectMessage,
    OpenOrderMessage,
    MarginCallMessage,
    AccountOperationMessage,
    AccountStatusMessage
}

export enum SessionOperations {
    None = 0,
    Open = 1,
    Modify = 2,
    Cancel = 4
}

export enum InformationMessageType {
    Info = 0,
    ErrorReport = 1,
    Settings = 2
}

export enum RevenuePlanMessageType {
    Spread = 0,
    Comission = 1
}

export enum RevenueCommissionType {
    None = 0,
    FixedFee = 1,
    Percentage = 2,
    FixedAndPercentage = 3
}

export enum CommissionDefaultAsset {
    INSTRUMENT_QUOTING_CCY = -2,
    INSTRUMENT_BASE_CCY = -1
}

export enum SubscribeType {
    SUBSCRIBE = 0,
    UNSUBSCRIBE = 1
}

export enum SubscriptionSource {
    NORMAL = 0,
    DATASOURCE = 1
}

export enum MarginModes {
    USE_LOSS = 0,
    USE_PROFIT_LOSS = 1,
    USE_PROFIT_IN_MARGIN_AVAILABLE = 2
    /* USE_PROFIT_LOSS,
    USE_PROFIT,
    USE_LOSS,
    DO_NOT_USE_PROFIT_LOSS */
}

export const SEPARATOR = ':';

export enum CloseAccountResponseStatus {
    New = 0,
    Approve = 1,
    Rejected = 2,
    Cancelled = 3
}

export enum CrossRateForPositions {
    Default = 0,
    LatestBidAsk = 1
}
