// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { CustomEvent } from '../../Utils/CustomEvents';
import { Resources } from '../../Commons/properties/Resources';
import { ControlsUtils } from '../UtilsClasses/ControlsUtils';
import { TerceraRangeControlTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';

export class TerceraRangeControl extends ContainerControl {
    public static readonly RIGHT_BORDER = 198;
    public static readonly START = 'start';
    public static readonly END = 'end';

    public objStartValue: any = null;
    public objEndValue: any = null;
    public RangeValueChanged: CustomEvent;
    public MovingNow: boolean;
    public PressedElement: string;
    public captured: any;

    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.RangeValueChanged = new CustomEvent();

        this.on('onMouseDownMain', this.onMouseDownMain);
        this.on('onMouseDownStart', this.onMouseDownStart);
        this.on('onMouseDownEnd', this.onMouseDownEnd);
        this.on('onMouseUpStart', this.onMouseUp);
        this.on('onMouseUpEnd', this.onMouseUp);
        this.observe('valueClockStart valueClockEnd', this.onRangeValueChanged);

        this.ReverseStyle();
        this.TriangleCorrection();
        this.ClockCorrection();
        this.setValue();
    }

    public onRangeValueChanged (): void {
        if (isNullOrUndefined(this.objStartValue)) {
            return;
        }
        if (isNullOrUndefined(this.objEndValue)) {
            return;
        }

        const rangeControlValue = new TerceraRangeControlRangeData();
        rangeControlValue.StartHours = this.objStartValue.Hours;
        rangeControlValue.StartMins = this.objStartValue.Mins;
        rangeControlValue.EndHours = this.objEndValue.Hours;
        rangeControlValue.EndMins = this.objEndValue.Mins;

        this.RangeValueChanged.Raise(this, rangeControlValue);
    }

    public setInitValue (propValue: TerceraRangeControlRangeData): void {
        if (isNullOrUndefined(propValue)) {
            return;
        }

        const start = this.getInitPosition(propValue.StartHours, propValue.StartMins);
        const end = this.getInitPosition(propValue.EndHours, propValue.EndMins);

        if (isNaN(start) || isNaN(end)) {
            return;
        }

        void this.set('slid_position_start', start);
        void this.set('slid_position_end', end);

        this.ReverseStyle();
        this.TriangleCorrection();
        this.ClockCorrection();
        this.setValue();
    }

    public onMouseDownMain (ev): void {
        if (!ev.original.notSkip) {
            return;
        }

        if (!this.MovingNow) {
            ControlsUtils.Capture.SetCapture(this, ev.original);
        }

        this.MovingNow = true;
    };

    public onMouseDownStart (ev): void {
        this.PressedElement = TerceraRangeControl.START;
        this.switchFocus(true);
        this.onMouseDown(ev);
    }

    public onMouseDownEnd (ev): void {
        this.PressedElement = TerceraRangeControl.END;
        this.switchFocus(false);
        this.onMouseDown(ev);
    }

    public switchFocus (focus: boolean): void {
        void this.set('focusedStart', focus);
        void this.set('focusedEnd', !focus);
    }

    public override onMouseDown (ev): void {
        const event: any = new Event('mousedown');
        const variableName = 'slid_position_' + this.PressedElement;
        this.MovingNow = false;

        const evO = ev.original;
        event.pageX = evO.pageX;
        event.offsetX = this.get(variableName);
        event.pageY = evO.pageY;
        event.offsetY = evO.offsetY;
        event.notSkip = true;
        ev.original.currentTarget.parentElement.dispatchEvent(event);
    }

    public override onMouseUp (ev): void {
        this.MovingNow = false;
    }

    public override onMouseMove (ev): void {
        if (!this.MovingNow) {
            return;
        }

        this.processEVT(ev);
    }

    public processEVT (ev): void {
        if (this.captured && ev.original) {
            return;
        }

        const variableName = 'slid_position_' + this.PressedElement;
        let oldV = this.get(variableName);
        let newV = ev.offsetX;

        if (newV <= 0) {
            newV = 0;
        }
        if (newV >= TerceraRangeControl.RIGHT_BORDER) {
            newV = TerceraRangeControl.RIGHT_BORDER;
        }

        if (oldV !== 0 && oldV !== TerceraRangeControl.RIGHT_BORDER && newV !== 0 && newV !== TerceraRangeControl.RIGHT_BORDER) {
            oldV = newV;
        }

        void this.set(variableName, newV);

        if (oldV !== newV) {
            this.TriangleCorrection();
        }

        this.ReverseStyle();
        this.ClockCorrection();
        this.setValue();
    }

    public TriangleCorrection (): void {
        const positionStart = this.get('slid_position_start');
        const positionEnd = this.get('slid_position_end');

        const arrElem = [TerceraRangeControl.START, TerceraRangeControl.END];
        const arrNum = [positionStart, positionEnd];

        for (let i = 0; i < arrElem.length; i++) {
            const elem = arrElem[i];
            const num = arrNum[i];
            const variableName = 'triangleType_' + elem;

            if (num > 0 && num < TerceraRangeControl.RIGHT_BORDER) {
                void this.set(variableName, 'down');
            }
            if (num === 0) {
                void this.set(variableName, 'left');
            }
            if (num === TerceraRangeControl.RIGHT_BORDER) {
                void this.set(variableName, 'right');
            }
        }
    }

    public ClockCorrection (): void {
        const revers: boolean = this.get('isRevers');

        const positionStart = revers ? this.get('slid_position_end') : this.get('slid_position_start');
        const positionEnd = revers ? this.get('slid_position_start') : this.get('slid_position_end');

        const typeStartClock = revers ? 'clock_position_end' : 'clock_position_start';
        const typeEndClock = revers ? 'clock_position_start' : 'clock_position_end';

        this.CalculatePositionClock(positionStart, typeStartClock);
        this.CalculatePositionClock(positionEnd, typeEndClock);
    }

    public CalculatePositionClock (position, typeClock): void {
        const edgeLeft = 16;
        const edgeRight = 182;
        const halfClock = -16;

        const positionClock = TerceraRangeControl.RIGHT_BORDER - position;
        const indent = 2 * halfClock + positionClock;

        if (position < edgeLeft) {
            void this.set(typeClock, -position);
        } else if (position > edgeRight) {
            void this.set(typeClock, indent);
        } else {
            void this.set(typeClock, halfClock);
        }
    }

    public ReverseStyle (): void {
        const posStart = this.get('slid_position_start');
        const posEnd = this.get('slid_position_end');

        let isRevers = false;
        if (posStart >= posEnd) {
            isRevers = true;
        }

        void this.set('isRevers', isRevers);
    }

    public setValue (): void {
        const posStart = this.get('slid_position_start');
        const posEnd = this.get('slid_position_end');

        this.objStartValue = this.getFormattedValue(posStart);
        this.objEndValue = this.getFormattedValue(posEnd);

        if (!isNullOrUndefined(this.objStartValue)) {
            void this.set('valueClockStart', this.objStartValue.FormatedValue);
        }

        if (!isNullOrUndefined(this.objEndValue)) {
            void this.set('valueClockEnd', this.objEndValue.FormatedValue);
        }
    }

    public getFormattedValue (left): any {
        let mins = 0;
        let hours = 0;

        /* кусок черной магии
    9 отступ слева для расчёта по 3 пикселя на 15 минут
    196 отступ справа для расчёта до этого значения по 2 пикселя на 15 минут
    197 граница после которой время ставим 23:59
    всё из-за гавна с размером в 198 пикселей
    192 пикселя было б топычем! и без магии */

        if (left < 9 && left % 3 === 0) {
            mins = left / 3 % 4 * 15;
            hours = Math.trunc(left / 3 / 4);
        } else if (left >= 9 && left < 196 && left % 2 === 0) {
            mins = left / 2 % 4 * 15 + 30;
            if (mins >= 60) {
                mins = mins - 60;
                hours = 1;
            }
            hours += Math.trunc(left / 2 / 4) - 1;
        } else if (left > 197) {
            mins = 59;
            hours = 23;
        } else { return null; }

        const objValue: any = {};
        objValue.Hours = hours;
        objValue.Mins = mins;
        objValue.FormatedValue = this.FormatClockValue(hours, mins);

        return objValue;
    }

    public getInitPosition (hours: number, mins: number): number {
    // еще один кусок черной магии
        let plus = mins / 15;
        if (hours === 0 && mins > 30 || hours !== 0) {
            plus = 4;
        }
        let res = (hours * 4 + mins / 15) * 2 + plus;
        if (hours === 23 && mins === 59) {
            res = 198;
        }

        return res;
    }

    public FormatClockValue (hours: number, mins: number): string {
        let hoursStr = hours.toString();
        if (hours < 10) {
            hoursStr = '0' + hours;
        }

        let minsStr = mins.toString();
        if (!mins) {
            minsStr = mins + '0';
        }

        const resultValue = hoursStr + ':' + minsStr;

        return resultValue;
    }
}

export class TerceraRangeControlRangeData {
    public StartHours: number;
    public StartMins: number;
    public EndHours: number;
    public EndMins: number;

    constructor (startHours?: number, startMins?: number, endHours?: number, endMins?: number) {
        this.StartHours = startHours ?? 0;
        this.StartMins = startMins ?? 0;
        this.EndHours = endHours === 0 ? 0 : (endHours ?? 23);
        this.EndMins = endMins === 0 ? 0 : (endMins ?? 59);
    }
}

ContainerControl.extendWith(TerceraRangeControl, {
    data: function () {
        return {
            width: 200,
            height: 10,

            slid_position_start: 0,
            slid_position_end: 198,

            triangleType_start: '',
            triangleType_end: '',

            isRevers: false,
            focusedStart: false,
            focusedEnd: false,

            startDayTooltip: Resources.getResource('RangeControl.StartDaySlide.Tooltip'),
            endDayTooltip: Resources.getResource('RangeControl.EndDaySlide.Tooltip')
        };
    },
    template: TerceraRangeControlTemplate
});
