// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { type iBuildInIndicator } from '../iBuildInIndicator';

export class MD extends IndicatorScriptBase {
    public Periods: number;
    public Smoothing: number;
    public sourcePrices: number;

    private ema: iBuildInIndicator;

    constructor () {
        super();
        // #region Initialization
        this.ProjectName = 'McGinley Dynamic';
        this.Comments = 'McGinley Dynamic Indicator';
        // #endregion

        this.SetIndicatorLine('MD', Color.Red, 2, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.Periods = 12;
        super.InputParameter(new InputParameterInteger('Periods', 'Periods', 0, 1, 99999, 0, 1));

        this.Smoothing = 125;
        super.InputParameter(new InputParameterInteger('Smoothing', 'Smoothing', 0, 1, 99999, 0, 1));

        this.sourcePrices = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('sourcePrices', 'Sources prices for MA', 0,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        this.UpdateIndexDrawBegin();
    }

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.ema = this.Indicators.iMA(this.CurrentData, this.Periods, MAMode.EMA, 0, this.sourcePrices);
        return [this.ema];
    }

    public override GetIndicatorShortName (): string {
        return 'MD(' + this.Periods + ')';
    }

    public override getCustomName (): string {
        return 'MD (' + this.ProjectName + ')';
    };

    public override UpdateIndexDrawBegin (): void {
        this.SetIndexDrawBegin(0, this.Periods);
    }

    public OnQuote (): void {
        super.OnQuote();

        if (this.CurrentData.Count < 2) { return; };

        const value = this.ema.GetValue(0, 1);
        const price = this.CurrentData.GetPrice(this.sourcePrices, 0);

        this.SetValue(0, 0, value + (price - value) / (price / value * this.Smoothing));
    }
}
