// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { EntitlementHistory, type EntitlementProduct, EntitlementProductFilterComboBoxVariants, EntitlementProductRequestStatus, EntitlementProductRequestType } from '../../Commons/cache/Entitlement/EntitlementPrimitives';
import { EntitlementCategoryProduct, NO_NAME_CATEGORY } from '../../Commons/cache/Entitlement/EntitlementCategoryProduct';
import { HtmlScroll } from '../../Commons/HtmlScroll';
import { TerceraProductsPanelTemplate } from '../../templates.js';
import { EntitlementProductsItem } from '../cache/EntitlementProductsItem';
import { DockPlaceConst, DockSystemInstance } from '../DockSystem';
import { TerceraHeaderMenuControl } from '../elements/TerceraHeaderMenuControl';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { ThemeManager } from '../misc/ThemeManager';
import { DynProperty } from '../../Commons/DynProperty';
import { EntitlementManager } from '../../Commons/cache/Entitlement/EntitlementManager';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';
import { Enum } from '../../Utils/Enum';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type RangeSelectPanel } from './RangeSelectPanel';
import { type DirectEntitlementProductSubscriptionResponseMessage } from '../../Utils/DirectMessages/DirectMessagesImport';
import $ from 'jquery';
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';

export class ProductsPanel extends ApplicationPanelWithTable<EntitlementProductsItem> {
    public static readonly HEADER_PANEL = 25;
    public static readonly SWITCH_MENU = 35;
    public static readonly TOOLBAR_HEIGHT = 32;
    public static readonly HEIGHT_REQUEST_TOP_PANEL = 40;

    public NeedCalculateRowCount: boolean = false;
    public headerLocaleKey: string = 'screen.products.Products';
    public rangeSelectPanel: RangeSelectPanel | null = null;
    public toolbarSelectedFilter: number = -1;
    public cachedAllProductCount: number | null = null;
    public myMsgBox: any = null;
    public Dettach: any;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): PanelNames { return PanelNames.ProductsPanel; }

    public override oncomplete (): void {
        super.oncomplete();

        this.addRangeSelectPanel();

        this.on('clickCheck', this.clickCheck);
        // this.on("headerMenuClick", this.headerMenuClick);
        this.on('scrolling_left_Click', this.scrollingLeftClick);
        this.on('scrolling_right_Click', this.scrollingRightClick);
        this.observe('checkedCategories', this.observeActive);
        this.observe('checkedRequest', this.observeRequest);
        // this.observe('categories', this.sortingProductCatalog);   // вот тут точно что то сломаю но так часто сортировать нельзя
        this.on('onCollapserClick', this.onCollapserClick);

        this.setProductsData();
        this.addScroll();

        this.createToolbar();
        this.localize();
        this.layoutTable();
        this.center();

        DataCache.EntitlementManager.OnAddOrUpdate.Subscribe(this.OnAddOrUpdateProduct, this);

        const qt = this.getQuickTable();
        qt.AddToEnd = false;
        qt.OnPaintedPictureButtonClick.Subscribe(this.CancelBTNClick, this);
    }

    public addRangeSelectPanel (): void {
        const topPanel = this.Controls.productPanelRangeSelect;
        topPanel.getReportEvent.Subscribe(this.getProductRequests, this);
        topPanel.updateSelectedRange();
        this.rangeSelectPanel = topPanel;
    }

    public override layoutTable (noChanges = false): void {
        if (noChanges) {
            return;
        }

        const showHeader: boolean = this.get('showHeader');
        const heightHeader = showHeader ? ProductsPanel.HEADER_PANEL : 0;

        const heightPanel = this.get('height');
        const height = heightPanel - heightHeader - ProductsPanel.SWITCH_MENU - ProductsPanel.TOOLBAR_HEIGHT;
        void this.set({ heightSubscriptionsPanel: height });

        if (this.quickTableRactive === null) {
            return;
        }

        const topMargin = this.topPanelHeight + ProductsPanel.SWITCH_MENU + ProductsPanel.HEIGHT_REQUEST_TOP_PANEL;
        const scrWidth = this.get('width');
        const scrHeight = this.get('height') + 1;

        this.quickTableRactive.setBounds(
            0,
            0,
            Math.abs(scrWidth),
            Math.abs(scrHeight - topMargin - heightHeader));

        const categories: Record<string, EntitlementCategoryProduct> = this.get('categories');

        if (!isNullOrUndefined(categories)) {
            for (const object in categories) {
                categories[object].SetAvaliableWidth(this.get('width') - 30 - 10);
            }

            void this.set({ categories });
        }
    }

    public setProductsData (): void {
        const arr = DataCache.EntitlementManager.Products;
        let categories: Record<string, EntitlementCategoryProduct> = {};

        for (let i = 0; i < arr.length; i++) {
            const prod = arr[i];
            const wObj = categories;
            this.AddToProductsCatalog(prod, wObj);
        }
        categories = this.sortingProductCatalog(categories, null, 'categories');

        let needCollapse = true;
        for (const object in categories) {
            const c = categories[object];
            if ((c.Array.length > 0) && needCollapse) {
                c.IsHide = false;
                needCollapse = false;
            }
            c.SetAvaliableWidth(this.get('width') - 30);
        }

        void this.set({ categories });

        this.UpdateCategoriesTextNum();
    }

    public UpdateCategoriesTextNum (): void {
        const newValue = this.GetCachedProductCount(true);
        const newTextValue = ' (' + newValue + ')';
        void this.set({
            categoriesTextNum: newTextValue,
            noData: newValue === 0
        });
    }

    public GetCachedProductCount (needRecalc: boolean): number {
        if (needRecalc || this.cachedAllProductCount === null) {
            this.cachedAllProductCount = DataCache.EntitlementManager.GetProductCountByFilter(this.toolbarSelectedFilter);
        }

        return this.cachedAllProductCount;
    }

    public OnAddOrUpdateProduct (product: EntitlementProduct, needHistItem: boolean): void {
        const categories: Record<string, EntitlementCategoryProduct> = this.get('categories');
        let updateScroll = false;
        const belongToCurrentFilter = product.IsBelongToFilter(this.toolbarSelectedFilter);
        if (belongToCurrentFilter) {
            updateScroll = this.AddToProductsCatalog(product, categories);
        } else {
            updateScroll = this.RemoveFromProductsCatalog(product, categories);
        }

        void this.set('categories', categories);

        if (updateScroll) {
            this.UpdateCategoriesTextNum();
        }

        if (needHistItem) {
            this.AddHistoryItem(product);
        }
    }

    public sortingProductCatalog (catalog: Record<string, EntitlementCategoryProduct>, old, ev): Record<string, EntitlementCategoryProduct> {
        const keys = Object.keys(catalog);
        if (keys.length === 0) {
            return {};
        }

        const keysIndexesBeforeUpperCase = {};
        const keysUpperCase = keys.map((key) => key.toUpperCase()); // устраняем неалфавитную сортировку в случае различных регистров символов

        for (let i = 0; i < keys.length; i++) {
            keysIndexesBeforeUpperCase[keysUpperCase[i]] = i;
        }

        keysUpperCase.sort();

        const newCatalog: Record<string, EntitlementCategoryProduct> = {};
        for (let k = 0; k < keys.length; k++) {
            const indexInKeys = keysIndexesBeforeUpperCase[keysUpperCase[k]];
            const key = keys[indexInKeys];

            newCatalog[key] = catalog[key];
        }

        void this.set(ev, newCatalog);

        return newCatalog;
    }

    public AddToProductsCatalog (product: EntitlementProduct, catalog: Record<string, EntitlementCategoryProduct>): boolean {
        if (product.ShowInSettings) // #110443 (docs paragraph 3)
        {
            return false;
        }

        let hasNewCategory = false;
        const prodCategory = isValidString(product.Category) ? product.Category : NO_NAME_CATEGORY;

        if (isNullOrUndefined(catalog[prodCategory])) {
            catalog[prodCategory] = new EntitlementCategoryProduct(prodCategory);
            hasNewCategory = true;
        }

        const filter = this.toolbarSelectedFilter;
        if (!product.IsBelongToFilter(filter)) {
            return false;
        }

        const catalogTmp = catalog[prodCategory];
        if (!catalogTmp.IsExists(product)) {
            catalogTmp.AddProduct(product);
            this.UpdateCategoriesTextNum();
        }

        return hasNewCategory;
    }

    public RemoveFromProductsCatalog (product: EntitlementProduct, catalog: Record<string, EntitlementCategoryProduct>): boolean {
        let isRemoved = false;
        const prodCategory = product.Category ?? NO_NAME_CATEGORY;
        const category = catalog[prodCategory];
        if (!isNullOrUndefined(category)) {
            if (category.IsExists(product)) {
                category.RemoveProduct(product);
                this.UpdateCategoriesTextNum();
                isRemoved = true;
            }
        // if (category.IsEmpty())
        //     delete catalog[prodCategory];
        }

        return isRemoved;
    }

    public override localize (): void {
        super.localize();

        void this.set({
            categoriesText: Resources.getResource('screen.products.Categories'),
            // categoriesTextToolTip: Resources.getResource('screen.products.Categories.ToolTip'),
            requestText: Resources.getResource('screen.products.Request'),
            requestTextToolTip: Resources.getResource('screen.products.Request.ToolTip'),
            noDataText: Resources.getResource('screen.products.Categories.NoData')
        });

        this.localizeFilterComboBox();
    }

    public clickCheck (sender, data): void {
        void this.set('checked' + data, true);
        this.addScroll();
    }

    public observeActive (n, o): void {
        if (!n) {
            return;
        }

        void this.set({
            checkedRequest: false
        });
    }

    public observeRequest (n, o): void {
        if (!isNullOrUndefined(this.quickTableRactive)) {
            void this.quickTableRactive.set('visible', n);
            void this.rangeSelectPanel.set('visible', n);
        }

        if (!n) {
            void this.set('loading', false);
            return;
        }

        void this.set({
            checkedCategories: false
        });
    }

    public AddHistoryItem (product: EntitlementProduct): void {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        const historyItem = new EntitlementHistory(product.Id, product.Name);
        const productRequestItem = product.GenerateSubscriptionResponseMessage();
        historyItem.UpdateHistoryStatus(productRequestItem);
        const colors = this.GetColorRow(productRequestItem);
        const row = qt.AddItem(new EntitlementProductsItem(historyItem, SessionSettings, colors));
    }

    public GetColorRow (productRequestItem: DirectEntitlementProductSubscriptionResponseMessage): { 1: any, 2: any } {
        const result = { 1: null, 2: null };

        if (isNullOrUndefined(productRequestItem)) {
            return result;
        }

        switch (productRequestItem.ReqType) {
        case EntitlementProductRequestType.Subscribe:
            result['1'] = ThemeManager.CurrentTheme.ProductHistoryItemSubscribeForegroundColor;
            break;
        }
        switch (productRequestItem.ReqStatus) {
        case EntitlementProductRequestStatus.Approved:
            result['2'] = ThemeManager.CurrentTheme.ProductHistoryItemApprovedForegroundColor;
            break;
        case EntitlementProductRequestStatus.Rejected:
            result['2'] = ThemeManager.CurrentTheme.ProductHistoryItemRejectedForegroundColor;
            break;
        case EntitlementProductRequestStatus.Canceled:
            result['2'] = ThemeManager.CurrentTheme.ProductHistoryItemCancelledForegroundColor;
            break;
        }
        return result;
    }

    public CancelBTNClick (data): void {
        if (isNullOrUndefined(data?.row?.item?.requestData)) {
            return;
        }

        if (isNullOrUndefined(this.myMsgBox)) {
            return;
        }

        this.myMsgBox = EntitlementManager.GetMessageBoxForProductCancelRequest(this, data.row.item.requestData);
    }

    public getProductRequests (startTime: number, finishTime: number): void {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        const rq: boolean = this.get('checkedRequest');
        if (rq) {
            void this.set('loading', true);
        }

        EntitlementManager.SendSubscriptionHistoryReques(new Date(startTime), new Date(finishTime))
            .then((msgs: DirectEntitlementProductSubscriptionResponseMessage[]) => {
                if (rq) { void this.set('loading', false); }
                qt.ClearAll();

                if (msgs.length === 0) {
                    return;
                }

                const fsort = (a, b): number => { return a.ReqTime - b.ReqTime; };

                msgs.sort(fsort);
                for (let i = 0; i < msgs.length; i++) {
                    const requestData: DirectEntitlementProductSubscriptionResponseMessage = msgs[i];
                    const product = DataCache.EntitlementManager.ProductsCache[requestData.Id];
                    if (isNullOrUndefined(product)) {
                        continue;
                    }

                    const historyItem = new EntitlementHistory(product.Id, product.Name);
                    historyItem.UpdateHistoryStatus(requestData);
                    const colors = this.GetColorRow(requestData);
                    const row = qt.AddItem(new EntitlementProductsItem(historyItem, SessionSettings, colors));
                }
            });
    }

    public onCollapserClick (sender, categoryName: string): void {
        const cat: Record<string, EntitlementCategoryProduct> = this.get('categories');
        const c = cat[categoryName];

        if (!isNullOrUndefined(c)) {
            c.IsHide = !c.IsHide;
        }

        void this.set('categories', cat);

        if (!c.IsHide) {
            this.scrollTo(c.Name);
        }
    }

    public scrollingLeftClick (sender, categoryName: string): void {
        const cat: Record<string, EntitlementCategoryProduct> = this.get('categories');

        if (!isNullOrUndefined(cat[categoryName])) {
            cat[categoryName].LeftArrowClick();
        }

        void this.set('categories', cat);
    }

    public scrollingRightClick (sender, categoryName: string): void {
        const cat: Record<string, EntitlementCategoryProduct> = this.get('categories');

        if (!isNullOrUndefined(cat[categoryName])) {
            cat[categoryName].RightArrowClick();
        }

        void this.set('categories', cat);
    }

    public createToolbar (): void {
        this.fillFilterComboBox();
        this.on('onSelectedFilterChange', this.onSelectedFilterChange);
    }

    public onSelectedFilterChange (context, cbItem): void {
        const value = cbItem.value ? cbItem.value : cbItem;
        this.toolbarSelectedFilter = value;

        this.setProductsData();
        this.resetScroll();
    }

    public localizeFilterComboBox (): void {
        const filterComboBox = !isNullOrUndefined(this.Controls) ? this.Controls.filterComboBox : null;
        if (isNullOrUndefined(filterComboBox)) return;

        const items = filterComboBox.get('items');
        let selectedCBItem = null;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            item.text = Resources.getResource(item.key);

            if (this.toolbarSelectedFilter === item.value) {
                selectedCBItem = item;
            }
        }

        filterComboBox.set({ items });

        if (!isNullOrUndefined(selectedCBItem)) {
            filterComboBox.set('selectedItem', selectedCBItem);
        }
    }

    public fillFilterComboBox (): void {
        const filterVariants = Enum.TakeKeysFromEnum(EntitlementProductFilterComboBoxVariants);
        const items = [];

        for (const filterVariant of filterVariants) {
            const value = EntitlementProductFilterComboBoxVariants[filterVariant];
            const cbItem = { key: 'screen.products.toolbar.filterCB.' + filterVariant, value };

            items.push(cbItem);
        }

        const filterComboBox = !isNullOrUndefined(this.Controls) ? this.Controls.filterComboBox : null;
        if (isNullOrUndefined(filterComboBox)) return;

        filterComboBox.set({ items });

        this.localizeFilterComboBox();
    }

    public override dispose (): void {
        DataCache.EntitlementManager.OnAddOrUpdate.UnSubscribe(this.OnAddOrUpdateProduct, this);
        this.rangeSelectPanel.getReportEvent.UnSubscribe(this.getProductRequests, this);
        super.dispose();
    }

    public headerMenuClick (clickedItem): void {
        if (!isNullOrUndefined(this.Dettach) && clickedItem.type === TerceraHeaderMenuControl.DETTACH) {
            this.Dettach();
            const newPanel = MainWindowManager.Factory.createPanel(PanelNames.ProductsPanel);
            void newPanel.set({ dockablePanel: false, showHeader: true, resizable: true });

            MainWindowManager.Factory.dockSystemOperations(newPanel, false);
            MainWindowManager.Factory.addPanelToWs(newPanel);
        }
        if (!this.get<boolean>('dockablePanel') && clickedItem.type === TerceraHeaderMenuControl.ATTACH) {
            void this.set({ dockablePanel: true, showHeader: false, resizable: false });
            DockSystemInstance.addPanelOnDock(this, DockPlaceConst.Down, false);
            WorkSpaceManager.currentWorkspace.addPanel(this);
        }
    }

    public addScroll (): void {
        HtmlScroll.addScroll(this.find('.js-products-main'));
    }

    public removeScroll (): void {
        HtmlScroll.removeScroll(this.find('.js-products-main'));
    }

    public resetScroll (): void {
        this.removeScroll();
        this.addScroll();
    }

    public scrollTo (elementID: string): void {
        if (!isValidString(elementID) || elementID === NO_NAME_CATEGORY) {
            return;
        }

        $(this.find('.js-products-main')).mCustomScrollbar('scrollTo', '#' + elementID);
    }

    public override Properties (): DynProperty[] {
        const properties = super.Properties();
        // properties.push(new DynProperty("attached", this.get("dockablePanel"), DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('toolbarSelectedFilter', this.toolbarSelectedFilter, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    public override validateProperties (properties: DynProperty[]): boolean { // help in migration process: checking if properties for callback are correct and suitable for current version
        if (DynProperty.getPropValue(properties, 'attached') !== null) { // old version of panel
            MainWindowManager.Factory.addPanel(PanelNames.ProductsPanel);
            this.close();
            return false;
        }

        return true;
    }

    public override callBack (newProperties: DynProperty[]): void {
        super.callBack(newProperties);

        this.toolbarSelectedFilter = DynProperty.getPropValue(newProperties, 'toolbarSelectedFilter');
    // let attached = DynProperty.getPropValue(newProperties, "attached");
    // this.set({ dockablePanel: attached, showHeader: !attached })
    // if (!attached)
    // {
    //     this.unrender()
    //     Factory.dockSystemOperations(this, false);
    //     Factory.addPanelToWs(this);
    // }
    }
}

ApplicationPanelWithTable.extendWith(ProductsPanel, {
    data: function () {
        return {
            width: 653,
            height: 480,

            movable: true,
            resizable: false,
            showHeader: true,
            showFooter: false,
            focused: true,
            dockablePanel: false,
            // isHeaderMenuButtonShow: true,

            canLinkByAccount: false,

            heightSubscriptionsPanel: null,
            heightRequestTopPanel: ProductsPanel.HEIGHT_REQUEST_TOP_PANEL,

            categoriesText: '', // локазирированное название вкладки Subscriptions
            categoriesTextNum: '', // общее кол-во продуктов в заглавии вкладки с учетом фильтра вместе со скобками

            checkedCategories: true,
            checkedRequest: false,
            categories: {}
        };
    },
    partials: { bodyPartial: TerceraProductsPanelTemplate }
});
