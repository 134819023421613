// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OEAccountLookupTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Commons/properties/Resources';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';

export class OEAccountLookup extends ContainerControl {
    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.OEAccountLookup; }

    public override oninit (): void {
        super.oninit();
        Resources.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public override setFocus (): void {
        const enabled: boolean = this.get('enabled');
        if (!enabled) {
            return;
        }
        super.setFocus();
        if (!isNullOrUndefined(this.Controls.TerceraAccountLookup)) {
            this.Controls.TerceraAccountLookup.setFocus();
        }
    }

    public localize (): void {
        void this.set('label', Resources.getResource('panel.newOrderEntry.accountLabel'));
    }
}

ContainerControl.extendWith(OEAccountLookup, {
    template: OEAccountLookupTemplate,
    data: function () {
        return {
            label: '',
            name: ControlsTypes.OEAccountLookup,
            account: null
        };
    }
});
