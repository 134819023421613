// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Rectangle } from '../../Commons/Geometry';
import { contextMenuWithOverHandler, contextMenuHandler } from '../../Utils/AppHandlers';
import { ControlEvents } from './Control';
import { TerceraMenu } from './TerceraMenu';

export class TerceraMenuWithOver extends TerceraMenu {
    public isMouseOnMenu: boolean = false;
    public activeMainMenuBtn: any = null;
    public rectangleBtwMenuAndBtn: Rectangle = null;

    constructor () {
        super();

        this.menuHandler = contextMenuWithOverHandler;
    }

    public getType (): string { return 'TerceraMenuWithOver'; }

    public oninit (): void {
        super.oninit();

        this.on('onmenuEnter', this.private_MenuEnter);
        this.on('onmenuLeave', this.private_MenuLeave);
    }

    public private_MenuEnter (): void {
        this.isMouseOnMenu = true;
    }

    public private_MenuLeave (context): void {
        this.isMouseOnMenu = false;
        this.TryToCloseMenu(context);
    }

    public handlerOnMainBtnOver (btnContext, menuItems: any[]): void {
        const x = btnContext.node.offsetLeft;
        const y = 45;
        const btnHeight = btnContext.node.offsetHeight;
        this.rectangleBtwMenuAndBtn = new Rectangle(x, btnContext.node.offsetTop + btnHeight, btnContext.node.offsetWidth, y - btnHeight);

        const focusedButton = (): void => {
            this.menuHandler.off(ControlEvents.LostFocus, focusedButton);
            this.ResetMainMenuFlags();
            btnContext.set('focused', false);
        };

        this.menuHandler.on(ControlEvents.LostFocus, focusedButton);
        this.ShowWithClosingAnotherContextMenu(menuItems, x, y);

        if (!isNullOrUndefined(this.activeMainMenuBtn)) {
            this.activeMainMenuBtn.set('focused', false);
        }

        this.activeMainMenuBtn = btnContext;
        this.activeMainMenuBtn.set('focused', true);
    }

    public isMainMenuShoudClose (mouseContext): boolean {
        if (!this.isShowed) {
            return false;
        }

        return !this.isMouseOnMenu &&
        !this.activeMainMenuBtn?.ractive.isMouseOnButton &&
        !this.rectangleBtwMenuAndBtn.Contains(mouseContext.event.clientX, mouseContext.event.clientY);
    }

    public ResetMainMenuFlags (): void {
        this.activeMainMenuBtn = null;
        this.rectangleBtwMenuAndBtn = null;
    }

    public TryToCloseMenu (mouseEvent): void {
        if (this.isMainMenuShoudClose(mouseEvent)) {
            this.Close();
        }
    }

    public Close (): void {
        this.activeMainMenuBtn.set('focused', false);
        this.ResetMainMenuFlags();
        this.Hide();
    }

    public ShowWithClosingAnotherContextMenu (items: any[], posX: number, posY: number, addParamsObj?): void {
        if (contextMenuHandler.isShowed()) {
            contextMenuHandler.Hide();
        }

        this.Show(items, posX, posY, addParamsObj);
    }

    public static override ContextMenuHandlerInitialize (): void {
        const _contextMenuWithOver = new TerceraMenuWithOver();
        super.ContextMenuHandlerInitialize(contextMenuWithOverHandler, _contextMenuWithOver);
        contextMenuWithOverHandler.isMouseOnMenu = () => { return _contextMenuWithOver.isMouseOnMenu; };
        contextMenuWithOverHandler.handlerOnMainBtnOver = _contextMenuWithOver.handlerOnMainBtnOver.bind(_contextMenuWithOver);
        contextMenuWithOverHandler.TryToCloseMenu = _contextMenuWithOver.TryToCloseMenu.bind(_contextMenuWithOver);
        contextMenuWithOverHandler.Close = _contextMenuWithOver.Close.bind(_contextMenuWithOver);
    }
}

TerceraMenu.extendWith(TerceraMenuWithOver);
