import { Control } from './Control';
import { ResizeSplitterTemplate } from '../../templates';
import { MathUtils } from '../../Utils/MathUtils';

export class ResizeSplitter extends Control {
    private _isMouseMove: boolean = false;
    private _isValid: boolean = false;
    private _prevElement: HTMLElement;
    private _splitterElement: HTMLElement;
    private _nextElement: HTMLElement;
    private _lastX: number = 0;
    private _lastY: number = 0;
    private _lastSiblingHeight: number = 0;
    private _lastSiblingWidth: number = 0;
    private _lastPrevSiblingPercent: number;
    private _lastNextSiblingPercent: number;

    public override oninit (): void {
        super.oninit();
        this.observe('isActive', this.onIsActiveChanged);
    }

    public override oncomplete (): void {
        super.oncomplete();
        this._splitterElement = this.find<HTMLElement>('*');
        this._splitterElement.addEventListener('mousedown', this.mouseDownHandler);
        this._prevElement = this._splitterElement.previousElementSibling as HTMLElement;
        this._nextElement = this._splitterElement.nextElementSibling as HTMLElement;
        if (isNullOrUndefined(this._prevElement) || isNullOrUndefined(this._nextElement)) {
            return;
        }

        this._isValid = true;
    }

    public override onteardown (): void {
        this._splitterElement.removeEventListener('mousedown', this.mouseDownHandler);
        super.onteardown();
    }

    // Handle the mousedown event
    // that's triggered when user drags the resizer
    private readonly mouseDownHandler = (e): void => {
        if (!this._isValid) {
            return;
        }
        const isActive: boolean = super.get('isActive');
        const isAutoActiveChange: boolean = this.get('activationDirection') !== ActivationDirection.NONE;
        if (!isActive && !isAutoActiveChange) {
            return;
        }
        this._isMouseMove = true;
        // Get the current mouse position
        this._lastX = e.clientX;
        this._lastY = e.clientY;
        const rect = this._prevElement.getBoundingClientRect();
        this._lastSiblingHeight = rect.height;
        this._lastSiblingWidth = rect.width;

        // Attach the listeners to document
        document.addEventListener('mousemove', this.mouseMoveHandler);
        document.addEventListener('mouseup', this.mouseUpHandler);
    };

    private readonly mouseMoveHandler = (e): void => {
        const result = this.processMouseMove(e);
        const isActive: boolean = super.get('isActive');
        if (!isActive && !result.isDeactivate) {
            this.fire('splitterActivate');
        } else if (isActive && result.isDeactivate) {
            this.fire('splitterDeactivate');
        }
    };

    private processMouseMove (e): { isDeactivate: boolean } {
        // How far the mouse has been moved
        const dx = e.clientX - this._lastX;
        const dy = e.clientY - this._lastY;
        const isHorizontal: boolean = super.get('isHorizontal');
        const activationDirection = super.get('activationDirection');
        const parentRect = this.getParentSize();
        const totalPercent = this.getTotalPercent(isHorizontal, parentRect);
        let isDeactivate: boolean = false;
        if (isHorizontal) {
            const topHeightPercent = this.correntMinMax(((this._lastSiblingHeight + dy) * 100) / parentRect.height, totalPercent);
            if (activationDirection === ActivationDirection.TOP && this.isMinReached(topHeightPercent)) {
                isDeactivate = true;
            }
            const bottomHeightPercent = totalPercent - topHeightPercent;
            if (activationDirection === ActivationDirection.BOTTOM && this.isMinReached(bottomHeightPercent)) {
                isDeactivate = true;
            }
            this.setElementsSize(topHeightPercent, bottomHeightPercent);
        } else {
            const leftWidthPercent = this.correntMinMax(((this._lastSiblingWidth + dx) * 100) / parentRect.width, totalPercent);
            if (activationDirection === ActivationDirection.LEFT && this.isMinReached(leftWidthPercent)) {
                isDeactivate = true;
            }
            const rightWidthPercent = totalPercent - leftWidthPercent;
            if (activationDirection === ActivationDirection.RIGHT && this.isMinReached(rightWidthPercent)) {
                isDeactivate = true;
            }
            this.setElementsSize(leftWidthPercent, rightWidthPercent);
        }
        const cursor = isHorizontal ? 'row-resize' : 'col-resize';
        this._splitterElement.style.cursor = cursor;
        document.body.style.cursor = cursor;
        this._prevElement.style.userSelect = 'none';
        this._prevElement.style.pointerEvents = 'none';
        this._nextElement.style.userSelect = 'none';
        this._nextElement.style.pointerEvents = 'none';
        this.fire('splitterResize');
        return { isDeactivate };
    }

    private getTotalPercent (isHorizontal: boolean, parentRect: ElementSize): number {
        let totalPercent = 100;

        if (isHorizontal) {
            const lastPrevHeightPercent: number = this._prevElement.offsetHeight;
            const lastNextHeightPercent: number = this._nextElement.offsetHeight;
            totalPercent = (lastNextHeightPercent + lastPrevHeightPercent) * 100 / parentRect.height;
        } else {
            const lastPrevWidth: number = this._prevElement.offsetWidth;
            const lastNextWidth: number = this._nextElement.offsetWidth;
            totalPercent = (lastNextWidth + lastPrevWidth) * 100 / parentRect.width;
        }

        return MathUtils.Round(totalPercent, 4);
    }

    private getParentSize (): ElementSize {
        const parentNodeRect = (this._splitterElement.parentNode as HTMLDivElement);
        const computedStyle = getComputedStyle(parentNodeRect);
        const countOfSplitters = parentNodeRect.querySelectorAll('.resize-splitter').length;
        const splitterSize = this.get('size');

        let elementHeight = parentNodeRect.clientHeight; // height with padding
        const paddingY = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
        const borderY = parseFloat(computedStyle.borderTopWidth) + parseFloat(computedStyle.borderBottomWidth);
        elementHeight = parentNodeRect.offsetHeight - paddingY - borderY - countOfSplitters * splitterSize;

        let elementWidth = parentNodeRect.clientWidth; // width with padding
        const paddingX = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
        const borderX = parseFloat(computedStyle.borderLeftWidth) + parseFloat(computedStyle.borderRightWidth);
        elementWidth = parentNodeRect.offsetWidth - paddingX - borderX - countOfSplitters * splitterSize;

        return new ElementSize(elementWidth, elementHeight);
    }

    private readonly mouseUpHandler = (e): void => {
        this._isMouseMove = false;
        this._splitterElement.style.removeProperty('cursor');
        document.body.style.removeProperty('cursor');
        this._prevElement.style.removeProperty('user-select');
        this._prevElement.style.removeProperty('pointer-events');
        this._nextElement.style.removeProperty('user-select');
        this._nextElement.style.removeProperty('pointer-events');
        document.removeEventListener('mousemove', this.mouseMoveHandler);
        document.removeEventListener('mouseup', this.mouseUpHandler);
    };

    private isMinReached (value: number): boolean {
        const minPercent = super.get('minPercent');
        return value <= minPercent;
    }

    private correntMinMax (value: number, totalPercent: number): number {
        const minPercent = super.get('minPercent');
        const maxPercent = super.get('maxPercent');
        if (value < minPercent) {
            return minPercent;
        } else if (totalPercent - value < minPercent) {
            return totalPercent - minPercent;
        } else if (value > maxPercent) {
            return maxPercent;
        } else {
            return value;
        }
    }

    private deactivateByDirection (): void {
        // Save percent on start moving and deactivate move process
        this.processMouseMove({ clientX: this._lastX, clientY: this._lastY });
        this.mouseUpHandler({});
    }

    private restorePercent (element: HTMLElement): void {
        if (isNullOrUndefined(element)) {
            return;
        }
        const percent = element === this._prevElement ? this._lastPrevSiblingPercent : this._lastNextSiblingPercent;
        if (isNullOrUndefined(percent)) {
            return;
        }
        element.style.height = percent + '%';
    }

    private removePercent (element: HTMLElement): void {
        if (isNullOrUndefined(element)) {
            return;
        }
        element.style.removeProperty('height');
    }

    private onIsActiveChanged (value: boolean): void {
        if (!this._isValid) {
            return;
        }
        if (value) {
            this.restorePercent(this._prevElement);
            this.restorePercent(this._nextElement);
        } else {
            const isDeactivateByDirection: boolean = this.get('activationDirection') !== ActivationDirection.NONE && this._isMouseMove;
            if (isDeactivateByDirection) {
                this.deactivateByDirection();
            }
            this.removePercent(this._prevElement);
            this.removePercent(this._nextElement);
        }
        this.fire('splitterResize');
    }

    public getElementsSize (): { prevSize: number, nextSize: number } {
        const isHorizontal: boolean = this.get('isHorizontal');
        const prevStyleProperty = isHorizontal ? 'height' : 'width';
        const nextStyleProperty = isHorizontal ? 'height' : 'width';

        const prevSize = parseFloat(this._prevElement.style[prevStyleProperty].replace('%', ''));
        const nextSize = parseFloat(this._nextElement.style[nextStyleProperty].replace('%', ''));

        return {
            prevSize: isNaN(prevSize) ? 0 : prevSize,
            nextSize: isNaN(nextSize) ? 0 : nextSize
        };
    }

    public setElementsSize (prevSize: number, nextSize: number): void {
        this._lastPrevSiblingPercent = isNaN(prevSize) ? 0 : prevSize;
        this._lastNextSiblingPercent = isNaN(nextSize) ? 0 : nextSize;

        const isHorizontal: boolean = this.get('isHorizontal');
        const prevStyleProperty = isHorizontal ? 'height' : 'width';
        const nextStyleProperty = isHorizontal ? 'height' : 'width';

        this._prevElement.style[prevStyleProperty] = this._lastPrevSiblingPercent + '%';
        this._nextElement.style[nextStyleProperty] = this._lastNextSiblingPercent + '%';
    }
}

Control.extendWith(ResizeSplitter, {
    template: ResizeSplitterTemplate,
    data: function () {
        return {
            isVisible: true,
            isActive: true,
            isHorizontal: true,
            activationDirection: ActivationDirection.NONE,
            minPercent: 10,
            maxPercent: 90,
            size: 4
        };
    }
});

class ElementSize {
    public width: number;
    public height: number;

    constructor (width: number, height: number) {
        this.width = width;
        this.height = height;
    }
}

export enum ActivationDirection {
    NONE = 0,
    LEFT = 1,
    RIGHT = 2,
    TOP = 3,
    BOTTOM = 4
}
