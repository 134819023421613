import { MathUtils } from '../../Utils/MathUtils';
import { TerceraMultiLineLabelTemplate } from '../../templates.js';
import { TerceraLabel } from './TerceraLabel';
import $ from 'jquery';

// Multi Line Label
export class TerceraMultiLineLabel extends TerceraLabel {
    constructor () { super(); }

    public override oncomplete (): void {
        super.oncomplete();

        this.observe('text', function (newValue) {
            void this.set({ showTooltip: false });
            if (!newValue) {
                void this.set({ lines: [] });
                return;
            }

            const tmpdiv = document.createElement('div');

            tmpdiv.innerHTML = newValue;
            tmpdiv.style.height = 'auto';
            tmpdiv.style.width = 'auto';
            tmpdiv.style.whiteSpace = 'nowrap';
            tmpdiv.style.display = 'none';
            tmpdiv.classList.add('js-tercera-label');
            document.body.appendChild(tmpdiv);

            let myWidth = this.get('width');
            if (myWidth === '') {
                const divContainer = this.find('.js-tercera-multilineLabel-container');
                if (divContainer != null) {
                    myWidth = $(divContainer).width();
                }
            }

            const startWidth = $(tmpdiv).width();
            const resultArr = [];
            if (myWidth < startWidth) {
                const lineCount = MathUtils.trunc(this.get('height') / $(tmpdiv).height());
                const splitedMsg = newValue.split(' ');
                let i;
                const len = splitedMsg.length;

                let tmpLineSring = '';
                tmpdiv.innerHTML = '';
                for (i = 0; i < len; i++) {
                    tmpLineSring = splitedMsg[i];
                    tmpdiv.innerHTML += splitedMsg[i];
                    if (myWidth < $(tmpdiv).width()) {
                        resultArr.push(tmpLineSring);
                        if (resultArr.length === lineCount) {
                            tmpLineSring = newValue.substring(newValue.indexOf(tmpLineSring));
                            resultArr[lineCount - 1] = tmpLineSring;
                            void this.set({ showTooltip: true });
                            break;
                        }
                        tmpdiv.innerHTML = splitedMsg[i];
                    }
                    tmpLineSring = tmpdiv.innerHTML;
                    tmpdiv.innerHTML += ' ';
                }
                if (!resultArr.includes(tmpLineSring)) {
                    resultArr.push(tmpLineSring);
                }
            } else {
                resultArr.push(newValue);
            }
            document.body.removeChild(tmpdiv);
            void this.set({ lines: resultArr });
        });
    }
}

TerceraLabel.extendWith(TerceraMultiLineLabel, {
    template: TerceraMultiLineLabelTemplate,
    data: function () {
        return {
            text: '',
            lines: []
        };
    }
});
