// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type SLTPError } from '../../Commons/cache/OrderParams/SLTPError';
import { Resources } from '../../Commons/properties/Resources';
import { DPSLTPControlTemplate } from '../../templates.js';
import { DynPropertyControl } from '../elements/DynPropertyControl';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';

export class DPSLTPControl extends DynPropertyControl {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): string { return ControlsTypes.DPSLTPControl; }

    public override oninit (): void {
        super.oninit();

        this.observe(
            'dp.sl.inOffset dp.tp.inOffset dp.trailingStop.enabled',
            this.localize);
    }

    public override oncomplete (): void {
        super.oncomplete();
        void this.set('SLNumericLink', this.Controls.SLNumeric);
        void this.set('TPNumericLink', this.Controls.TPNumeric);
    }

    public override localize (): void {
        super.localize();
        const slLocKey =
            this.get<boolean>('dp.sl.inOffset') ||
            this.get<boolean>('dp.trailingStop.enabled')
                ? 'panel.newOrderEntry.stopLossCheckBox.offset'
                : 'panel.newOrderEntry.stopLossCheckBox';

        const sllLocKey =
            this.get<boolean>('dp.sl.inOffset')
                ? 'panel.newOrderEntry.stopLimitLimitOffset'
                : 'panel.newOrderEntry.stopLimitLimitPrice';

        const tpLocKey =
            this.get<boolean>('dp.tp.inOffset')
                ? 'panel.newOrderEntry.takeProfitCheckBox.offset'
                : 'panel.newOrderEntry.takeProfitCheckBox';

        const slLocKeyOffset =
            this.get<boolean>('dp.sl.inOffset') ||
            this.get<boolean>('dp.trailingStop.enabled')
                ? 'panel.newOrderEntry.stopLossCheckBox'
                : 'panel.newOrderEntry.stopLossCheckBox.offset';

        const tpLocKeyOffset =
            this.get<boolean>('dp.tp.inOffset')
                ? 'panel.newOrderEntry.takeProfitCheckBox'
                : 'panel.newOrderEntry.takeProfitCheckBox.offset';

        const slTooltipKey =
            this.get<boolean>('dp.tp.inOffset')
                ? 'panel.newOrderEntry.stopLossNumeric.ToolTip.offset'
                : 'panel.newOrderEntry.stopLossNumeric.ToolTip';

        const tpTooltipKey =
            this.get<boolean>('dp.tp.inOffset')
                ? 'panel.newOrderEntry.takeProfitNumeric.ToolTip.offset'
                : 'panel.newOrderEntry.takeProfitNumeric.ToolTip';

        void this.set({
            slLabel: Resources.getResource(slLocKey),
            sllLabel: Resources.getResource(sllLocKey),
            sllLabelTooltip: Resources.getResource(sllLocKey + '.tt'),
            tpLabel: Resources.getResource(tpLocKey),
            slLabelOffset: Resources.getResource(slLocKeyOffset),
            tpLabelOffset: Resources.getResource(tpLocKeyOffset),
            slTooltip: Resources.getResource(slTooltipKey),
            tpTooltip: Resources.getResource(tpTooltipKey)
        });
    }
}

DynPropertyControl.extendWith(DPSLTPControl, {
    template: DPSLTPControlTemplate,
    data: function () {
        return {
            slLabel: '',
            tpLabel: '',
            slLabelOffset: '',
            sllLabel: '', // #91413
            sllLabelTooltip: '', // #91413
            tpLabelOffset: '',
            slTooltip: '',
            tpTooltip: '',
            SLNumericLink: null,
            TPNumericLink: null,
            side: null,
            additionalClassName: '',
            SLTPParams:
            {
                width: 200,
                left: 100,
                margin: 6
            }
        };
    },
    computed: {
        slValue: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.value'
                        : 'dp.sl.value');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.value'
                        : 'dp.sl.value',
                    value);
            }
        },

        slMin: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.minimalValue'
                        : 'dp.sl.minimalValue');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.minimalValue'
                        : 'dp.sl.minimalValue',
                    value);
            }
        },

        slMax: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.maximalValue'
                        : 'dp.sl.maximalValue');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.maximalValue'
                        : 'dp.sl.maximalValue',
                    value);
            }
        },

        slStep: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.increment'
                        : 'dp.sl.increment');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.increment'
                        : 'dp.sl.increment',
                    value);
            }
        },

        slPrecision: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.decimalPlaces'
                        : 'dp.sl.decimalPlaces');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.decimalPlaces'
                        : 'dp.sl.decimalPlaces',
                    value);
            }
        },

        slCustomError: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.error'
                        : 'dp.sl.error');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.error'
                        : 'dp.sl.error',
                    value);
            }
        },

        sllMin: function () {
            return this.get<boolean>('dp.sl.inOffset') ? -this.get('dp.sl.maximalValue') : this.get('dp.sl.minimalValue');
        },
        sllValue: { // #91413
            get: function () {
                let value = this.get('dp.sl.sllValue');
                if (isNaN(value) || value === null) {
                    value = this.get('dp.sl.value');
                    if (isNaN(value) || value === null) { value = 0; }
                }
                return value;
            },
            set: function (value) {
                if (this.get<boolean>('dp.sllValueEnabled')) {
                    void this.set('dp.sl.sllValue', value);
                }
            }
        },
        sllMax: function () {
            return this.get('dp.sl.maximalValue');
        },
        sllPrecision: {
            get: function () {
                return this.get('dp.sl.decimalPlaces');
            },
            set: function (value) {
                if (this.get<boolean>('dp.trailingStop.enabled')) { return; }
                void this.set('dp.sl.decimalPlaces', value);
            }
        },
        sllStep: {
            get: function () {
                return this.get('dp.sl.increment');
            },
            set: function (value) {
                if (this.get<boolean>('dp.trailingStop.enabled')) { return; }
                void this.set('dp.sl.increment', value);
            }
        },

        sllVisible: function () {
            return !!(this.get<boolean>('dp.sl.enabled') && this.get<boolean>('dp.sllValueEnabled') && !this.get<boolean>('dp.trailingStop.enabled'));
        },

        sltpTriggerShortValue: { // #109798
            get: function () {
                return this.get('dp.sltpTriggerShort');
            },
            set: function (value) {
                void this.set('dp.sltpTriggerShort', value);
            }
        }
    }
});
