// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { HistoryType } from '../../Utils/History/HistoryType';
import { type RevenueSpreadItem, type RevenuePlanMessage } from '../../Vendors/PFSVendor/WebFix/Messages/RevenuePlanMessage';
import { type DataCache } from '../DataCache';
import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { type Instrument } from './Instrument';
import { SpreadItem } from './SpreadItem';

export class SpreadPlan {
    public Id: string;
    public Name: string;
    public itemsByType: Record<number, RevenueSpreadItem> = {};
    public itemsBySymbol: Record<string, RevenueSpreadItem> = {};
    private defaultItem: RevenueSpreadItem = null;
    private readonly _dataCache: DataCache = null;
    /// <summary>
    /// List of spread settings for each instrument
    /// </summary>

    private items: Record<string, SpreadItem> = {};

    constructor (dc: DataCache) {
        this._dataCache = dc;
    }

    public InitBy (message: RevenuePlanMessage): void {
        this.Id = message.Id.toString();
        this.Name = message.Name;
        const listOfTyped: RevenueSpreadItem[] = [];
        const copyByType: Record<number, RevenueSpreadItem> = {};
        const copyBySymbol: Record<string, RevenueSpreadItem> = {};

        let len = message.SpreadItems.length;

        for (let i = 0; i < len; i++) {
            const si = message.SpreadItems[i];
            if (isNullOrUndefined(si.InstrumentId)) {
                listOfTyped.push(si);
            } else {
                copyBySymbol[si.InstrumentId] = si;
            }
        }

        // TODO IMPLEMENT
        // listOfTyped.Sort(new TypeHierarchyComparer<RevenueSpreadItem>(dc));

        len = listOfTyped.length;
        for (let i = 0; i < len; i++) {
            const si = listOfTyped[i];
            if (si.InstrumentTypeId === -1) {
                copyByType[-1] = si;
                this.defaultItem = si;
            } else {
                const list = [];
                this._dataCache.FindInstrumentTypes(si.InstrumentTypeId, list);
                const lenJ = list.length;
                for (let j = 0; j < lenJ; j++) {
                    copyByType[list[j]] = si;
                }
            }
        }

        this.itemsBySymbol = copyBySymbol;
        this.itemsByType = copyByType;

        this.items = {};
        this.ApplyForInstruments(this._dataCache.getCachedInstruments());
    }

    private ApplyForInstruments (instruments: Instrument[]): void {
        const len = instruments.length;
        for (let i = 0; i < len; i++) {
            this.ApplyForInstrument(instruments[i]);
        }
    }

    private ApplyForInstrument (instrument: Instrument): void {
        try {
            if (this.itemsBySymbol != null) {
                let item = this.itemsBySymbol[instrument.Id];
                if (isNullOrUndefined(item)) { // by symbol
                    if (this.itemsByType != null) { // by type
                        item = this.itemsByType[instrument.TypeId];
                        if (isNullOrUndefined(item)) {
                            item = this.itemsByType[-1];
                            if (isNullOrUndefined(item)) { // default
                                return;
                            }
                        }
                    }
                }

                const si = SpreadPlan.CreateSpreadItem(item, instrument);
                this.items[instrument.GetInteriorID()] = si;
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public GetItem (name: string): SpreadItem | null {
        const spi = this.items[name];
        if (!isNullOrUndefined(spi)) { return spi; }

        if (!isNullOrUndefined(this.defaultItem)) {
            const instrument = this._dataCache.getInstrumentByName(name);
            if (!isNullOrUndefined(instrument)) { return SpreadPlan.CreateSpreadItem(this.defaultItem, instrument); }
        }

        return null;
    }

    public IsNotEmptyPlan (name: string): boolean {
        const item = this.GetItem(name);
        if (item === null) {
            return false;
        }

        if (item.SpreadMode !== SpreadPlan.NOT_FIXED_SPREADMODE || item.BidShift !== 0 || item.AskShift !== 0) {
            return true;
        } else {
            return false;
        }
    }

    /// <summary>
    /// Расчёт цен
    /// </summary>
    public CalcBid (bid: number, ask: number, ins: Instrument, round: boolean = true): number {
        // No spread rules for this instrument?
        const spreadItem = this.GetItem(ins.GetInteriorID());
        if (isNullOrUndefined(spreadItem)) {
            return bid;
        }

        return spreadItem.CalcBid(bid, ask, ins, round);
    }

    public CalcAsk (bid: number, ask: number, ins: Instrument, round: boolean = true): number {
        // No spread rules for this instrument?
        const spreadItem = this.GetItem(ins.GetInteriorID());
        if (isNullOrUndefined(spreadItem)) {
            return ask;
        }

        return spreadItem.CalcAsk(bid, ask, ins, round);
    }

    private static CreateSpreadItem (item: RevenueSpreadItem, instrument: Instrument | null): SpreadItem {
        const si = new SpreadItem(instrument);
        si.AskShift = item.AskShift;
        si.BidShift = item.BidShift;
        si.Spread = item.Spread;
        si.SpreadCoef = SpreadPlan.getSpreadCoef(instrument, item.SpreadMeasure);
        si.SpreadMode = (item.SpreadMode === SpreadPlan.NOT_FIXED_SPREADMODE &&
            (item.SpreadMeasure === SpreadMeasureMode.SPREAD_IN_BASIC_POINTS || item.SpreadMeasure === SpreadMeasureMode.SPREAD_IN_BASIC_POINTS_POST_EXECUTION))
            ? SpreadPlan.DYNAMIC_SPREADMODE
            : item.SpreadMode;
        si.SpreadMeasure = item.SpreadMeasure;
        return si;
    }

    private static getSpreadCoef (inst: Instrument, spreadMeasure: SpreadMeasureMode): number {
        switch (spreadMeasure) {
        case SpreadMeasureMode.SPREAD_IN_PIPS:
            return inst.PointSize;

        case SpreadMeasureMode.SPREAD_IN_BASIC_POINTS:
        case SpreadMeasureMode.SPREAD_IN_BASIC_POINTS_POST_EXECUTION:
            return 0.0001;

        case SpreadMeasureMode.SPREAD_IN_CURRENCY:
        case SpreadMeasureMode.SPREAD_IN_CURRENCY_POST_EXECUTION:
        default:
            return 1;
        }
    }

    public static NOT_FIXED_SPREADMODE = 0;
    public static ASK_SPREADMODE = 1;
    public static BID_SPREADMODE = 2;
    public static BID_ASK_SPREADMODE = 3;
    public static LIMEN_SPREADMODE = 4;
    public static DYNAMIC_SPREADMODE = 1000; // вымышленный тип под динамический спред в базовых пунктах.

    public IsNeedLoadHistoryForSpread (instrDescr: string, historyType: number): boolean {
        // дополнительные проверки, нужно ли закачивать историю
        const item = this.GetItem(instrDescr);

        if (isNullOrUndefined(item)) { return false; }

        if (item.SpreadMode === SpreadPlan.ASK_SPREADMODE && historyType === HistoryType.QUOTE_ASK) {
            return false;
        }

        if (item.SpreadMode === SpreadPlan.BID_SPREADMODE && historyType === HistoryType.QUOTE_LEVEL1) {
            return false;
        }

        if (item.SpreadMode === SpreadPlan.DYNAMIC_SPREADMODE) {
            return false;
        }

        if (item.SpreadMode === SpreadPlan.NOT_FIXED_SPREADMODE) {
            return false;
        }

        return true;
    }
}

export enum SpreadMeasureMode {
    SPREAD_IN_PIPS = 0,
    SPREAD_IN_CURRENCY = 1,
    SPREAD_IN_BASIC_POINTS = 2,
    SPREAD_IN_BASIC_POINTS_POST_EXECUTION = 3,
    SPREAD_IN_CURRENCY_POST_EXECUTION = 4
}
