// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iAD extends iBuildInIndicator {
    constructor () {
        super(1);
    }

    override get Name (): string { return 'iAD'; }

    public override OnQuote (): void {
        const Parent = this.Parent;
        if (!Parent) {
            return;
        }

        const close = this.GetPrice(PriceType.Close, 0);
        const high = this.GetPrice(PriceType.High, 0);
        const low = this.GetPrice(PriceType.Low, 0);
        const volume = this.GetVolume(0);

        if (high !== low) {
            this.SetValue(0, 0, (2 * close - high - low) * volume / (high - low) + this._GetValue(0, 1));
        }
    }
}
