import { HtmlScroll } from '../../Commons/HtmlScroll';
import { propertySetupScreenForIndicatorHandler } from '../../Utils/AppHandlers';
import { DialogResultsStuct } from '../../Commons/UtilsClasses/DialogResultsStuct';
import { PropertySetupScreen } from './PropertySetupScreen';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { Resources } from '../../Commons/properties/Resources';

export class PropertySetupScreenForIndicator extends PropertySetupScreen {
    constructor () { super(); }

    public override okClick (): void {
        const callback = this.get('callback');
        if (callback) {
            const res = new DialogResultsStuct();
            res.Data = this.arrayProperties;
            res.Button = DialogResultsStuct.CallBackInitiator.okClick;
            callback(res);
        }
        this.close(true);
    }

    public override closeClick (): void {
        const callback = this.get('callback');
        if (callback) {
            const res = new DialogResultsStuct();
            res.Button = DialogResultsStuct.CallBackInitiator.cancelClick;
            callback(res);
        }
        this.close(true);
    }

    public override close (fromBtn?): void {
        HtmlScroll.removeScroll(this.find('.js-right-panel'));
        if (!fromBtn) {
            const callback = this.get('callback');
            if (callback) {
                const res = new DialogResultsStuct();
                res.Button = DialogResultsStuct.CallBackInitiator.closeClick;
                callback(res);
            }
        }
        super.close();
    }

    public static override editProperty (caller, callBack, headerKey): void {
        if (!caller?.Properties) { return; }

        const propScreen = caller.propertiesScreen;
        if (propScreen) {
            propScreen.set('focused', true);
            return;
        }

        const scr = new PropertySetupScreenForIndicator();

        MainWindowManager.MainWindow.addControl(scr);
        scr.PopulateProperties(caller.Properties());
        void scr.set({
            callback: callBack.bind(caller),
            name: scr._guid,
            header: Resources.getResource(headerKey),
            focused: true
        });

        scr.setOnClosedCallback(function () {
            delete caller.propertiesScreen;
        });

        caller.propertiesScreen = scr;
        scr.setParentPanel(caller.chart.terceraChartPanelContext);
    }

    public static PropertySetupScreenForIndicatorHandlerInitialize (): void {
        propertySetupScreenForIndicatorHandler.editProperty = PropertySetupScreenForIndicator.editProperty;
    }
}

PropertySetupScreen.extendWith(PropertySetupScreenForIndicator, { });
