// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources } from '../../Commons/properties/Resources';
import { ModifyOrderScreenTemplate, OrderEditControlsTemplate, ModifyOrderScreenFooterTemplate } from '../../templates.js';
import { Control } from '../elements/Control';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';
import { OrderEditViewBase } from '../panels/OrderEditViewBase';
import { TIF } from '../../Utils/Trading/OrderTif';
import { Quantity } from '../../Utils/Trading/Quantity';
import { modifyOrderScreenHandler } from '../../Utils/AppHandlers';
import { OrderEditUpdateData } from '../../Utils/Trading/OrderEditUpdateData';
import { DataCache } from '../../Commons/DataCache';
import { TradingNumericErrorChecker } from '../../Commons/Trading/TradingNumericErrorChecker';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';

// TODO. Refactor. Duplicate code of ModifyPositionScreen.
export class ModifyOrderScreen extends OrderEditViewBase {
    public override getType (): ScreensNames {
        return ScreensNames.ModifyOrderScreen;
    }

    public override oninit (): void {
        super.oninit();

        this.on('modifyBtnClick', this.modify);
        this.on('cancelBtnClick', this.close);
        this.on('onBreakevenClick', this.setBreakevenPrice);

        this.observe('order', this.onOrder);
        this.observe('tradingAllowed', this.onTradingAllowed);
        this.observe('quantity tif disclosedQuantity', this.onTradingDataChanged);
        // TODO. Refactor.
        // TickAsync/Localize doesn't work for panels added with addScreen method.
        Resources.onLocaleChange.Subscribe(this.localize, this);
        Control.Ticker.Subscribe(this.TickAsync, this);
    }

    // TODO. Refactor.
    public override oncomplete (): void {
        super.oncomplete();

        this.onTradingAllowed(this.get('tradingAllowed'));
        void this.set('QNumericLink', this.Controls.quantity);
        this.localize();
        this.center();
    }

    public override dispose (): void {
        Resources.onLocaleChange.UnSubscribe(this.localize, this);
        Control.Ticker.UnSubscribe(this.TickAsync, this);

        super.dispose();
    }

    public override localize (): void {
        OrderEditViewBase.prototype.localize.call(this);

        this.updatePanelHeader();
        void this.set({
            disclosedLabel: Resources.getResource('panel.newOrderEntry.disclosedLabel'),
            modifyBtnText: Resources.getResource('screen.modifyOrder.modify'),
            cancelBtnText: Resources.getResource('general.messageBox.cancel')
        });
    }

    // TODO. UGLY.
    public onOrder (order): void {
        if (!order) { return; }

        const orderTypeDict = DataCache.OrderParameterContainer.OrderTypes;
        const orderTypeObj = orderTypeDict[order.OrderType];

        this.setOrderEdit(orderTypeObj.createModifyOrderObject({
            dataCache: DataCache,
            order
        }));

        void this.set({ account: order.Account, instrument: order.Instrument }).then(function () {
        // TEST.
            void this.set({
                side: order.BuySell,
                orderType: orderTypeObj,
                productType: order.ProductType,
                // TODO. Remove. Ugly.
                defaultTif: new TIF(order.TimeInForce, new Date(order.ExpireAt)),
                // TODO. Remove. Ugly.
                defaultQuantity: new Quantity(order.Amount, true)
            });
        }.bind(this));

        this.updatePanelHeader();
    }

    public onTradingDataChanged (newVal, oldVal, key): void {
        const orderEdit = this.orderEdit;
        if (!orderEdit) return;

        const tradingDataDict = {};
        tradingDataDict[key] = newVal;

        this.addDisclosedQuantityIfNeed(key);

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            tradingDataDict));
    }

    public onTradingAllowed (allowed): void {
        void this.set('enabledBtn', allowed);
    }

    public setBreakevenPrice (context, price): void {
        const orderEdit = this.orderEdit;
        if (orderEdit) {
            orderEdit.setBreakevenPrice(price);
        }
    }

    public override updatePanelHeader (): void {
        const ord = this.get('order');
        if (!ord) return;

        void this.set('header',
            Resources.getResource('screen.modifyOrder.Change order') +
        ' (' +
        ord.OrderNumber +
        ')');
    }

    public override layout (): void {
        this.updateOrderEditParametersVisibility();
    }

    public modify (): void {
        if (TradingNumericErrorChecker.HasErrors(this)) {
            return;
        }

        this.orderEdit.placedFrom = this.get('placedFrom');

        const self = this;
        DataCache.FOrderExecutor
            .modifyOrderPromiseWithCallbacks(this.orderEdit, self.close.bind(self), self.focusWarningNumeric.bind(self))
            .then(function () { })
            .catch(function () {
                const ex = new CustomErrorClass('ModifyOrderScreen error', 'ModifyOrderScreen.modify', 'modify -> modifyOrderPromiseWithCallbacks');
                ErrorInformationStorage.GetException(ex);
            });
    }

    public static getScreenByOrderID (orderID): any {
        const keys = Object.keys(MainWindowManager.MainWindow.Controls);
        const ModifyOrderScreenType = ScreensNames.ModifyOrderScreen;

        for (let i = keys.length - 1; i >= 0; i--) {
            const control = MainWindowManager.MainWindow.Controls[keys[i]];

            if (control.getType() == ModifyOrderScreenType) {
                const order = control.get('order');

                if (order && order.OrderNumber == orderID) {
                    return control;
                }
            }
        }

        return null;
    }

    public static show (orderId, placedFrom, numericLinkName): void {
        const alreadyOpenedScreen = ModifyOrderScreen.getScreenByOrderID(orderId);
        let oldLocation = null; let notSkipNextTime = false;

        if (alreadyOpenedScreen) {
            oldLocation = { x: alreadyOpenedScreen.get('left'), y: alreadyOpenedScreen.get('top') };
            notSkipNextTime = alreadyOpenedScreen.NotSkipNextTime;
            alreadyOpenedScreen.close();
        }

        const screen = MainWindowManager.Factory.addScreen(ScreensNames.ModifyOrderScreen);
        screen.set({
            order: DataCache.getOrderById(orderId),
            placedFrom
        });

        if (numericLinkName) {
            screen.activateNumeric(numericLinkName);
        }

        if (oldLocation) {
            screen.setLocation(oldLocation.x, oldLocation.y);
        }

        screen.NotSkipNextTime = notSkipNextTime; // TODO костыль ебаный
        screen.set('disableProductType', true);
    }

    public static modifyOrderScreenHandlerInitialize (): void {
        modifyOrderScreenHandler.show = ModifyOrderScreen.show;
    }
}

OrderEditViewBase.extendWith(ModifyOrderScreen, {
    data: function () {
        return {
            resizable: false,
            width: 325,
            dockablePanel: false,
            showHeader: true,
            showFooter: true,
            header: '',
            order: null,
            tif: null,
            // TODO. Remove. Ugly.
            defaultTif: null,
            quantity: null,
            // TODO. Remove. Ugly.
            defaultQuantity: null,
            instrument: null,
            account: null,
            side: null,
            orderType: null,

            disclosedQuantityShow: false,
            disclosedQuantity: null,
            dqMinValue: null,
            dqMaxValue: null,
            dqTooltip: 'panel.newOrderEntry.disclosedLabel.tt',
            dqLessMinLocalKey: 'UserControl.TerceraDQNumeric.ValueLessMin',
            dqGreaterMaxLocalKey: 'UserControl.TerceraDQNumeric.ValueGreaterMax',
            style_addition_footer: 'modify-order-screen-footer',

            productType: null,
            disableProductType: null,
            SLNumericLink: null,
            TPNumericLink: null,
            PriceNumericLink: null,
            StopPriceNumericLink: null,
            QNumericLink: null
            // showFullscreenCloud: true //TODO 83829
        };
    },
    partials: {
        // TODO. Refactor.
        bodyPartial: ModifyOrderScreenTemplate,
        orderEditControls: OrderEditControlsTemplate,
        footerPartial: ModifyOrderScreenFooterTemplate
    }
});
