// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type Rectangle } from '../../Commons/Geometry';
import { TerceraComboBox } from './TerceraComboBox';

export class QuickTableComboBox extends TerceraComboBox {
    public tag: any;
    public callBack: (value, data) => void;

    constructor (initProps, data?, callback?: (value, data) => void, rect?: Rectangle) {
        super(initProps);
        const rowData = data.cell.QuickTableEditingInfo.GetDataHandler(data.row);
        const items = [];
        let selectedValue = null;
        for (let i = 0; i < rowData.length; i++) {
            items.push({ text: rowData[i].text, value: rowData[i].value });
            const isSelected: boolean = rowData[i].selected;
            if (!isNullOrUndefined(isSelected) && isSelected) {
                selectedValue = rowData[i].value;
            }
        }

        void super.set({ items });
        if (!isNullOrUndefined(selectedValue)) {
            super.setItembyValue(selectedValue);
        } else {
            super.setItembyValue(data.cell.value);
        }
        this.tag = data;
        this.callBack = callback;
        super.setBounds(rect.X, rect.Y, rect.Width, rect.Height);
        void super.set('isPosAbsolute', true);
    }

    public override oncomplete (): void {
        super.setFocus();
        super.MenuShow();
    }

    public private_OnMenuItemSelected (menuItem): void {
        super.private_OnMenuItemSelected(menuItem);
        this.lostFocus();
    }

    public override lostFocus (): void {
        super.lostFocus();
        const selectedItem = this.get('selectedItem');
        if (isNullOrUndefined(selectedItem) || selectedItem.value === this.tag.cell.value) {
            this.callBack(null, this.tag);
        } else {
            this.callBack(selectedItem.value, this.tag);
        }
    }
}

TerceraComboBox.extendWith(QuickTableComboBox, { });
