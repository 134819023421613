import { ProductType } from '../../Utils/Instruments/ProductType';
import { type RiskPlanItem } from '../../Utils/RiskPlan/RiskPlanItem';
import { RiskPlan } from './RiskPlan';

export class RiskPlanSettings {
    // <ProductType, RiskPlanItem>
    public cache: any = {};

    get availableProductTypes (): ProductType[] {
        return Object.keys(this.cache).sort((a, b) => ProductType[a].localeCompare(ProductType[b])).map(p => Number(p));
    }

    public get (productType: ProductType | string): RiskPlanItem | null {
        return this.cache[productType] || null;
    }

    public addItem (item): void {
        if (!item) return null;

        if (item.ProductType) {
            RiskPlan.showProductType = true;
        }

        const cache = this.cache;
        if (cache[item.ProductType]?.mergeWith) {
            cache[item.ProductType].mergeWith(item);
        } else {
            cache[item.ProductType] = item;
        }
    }

    public mergeWith (settings): void {
        if (!settings) {
            return;
        }

        const types = this.availableProductTypes;
        for (let i = 0, len = types.length; i < len; i++) {
            this.addItem(settings.get(types[i]));
        }
    }

    public getCopy (): RiskPlanSettings {
        const copy = new RiskPlanSettings();

        const cache = this.cache;
        for (const key in cache) {
            copy.addItem(cache[key].getCopy());
        }

        return copy;
    }

    public getValue (productType, literal): any {
        const c = this.get(productType);
        if (!c?.getValue) {
            return null;
        }

        return c.getValue(literal);
    }
}
