// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { TerceraBoolNumericTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';

export class TerceraBoolNumeric extends ContainerControl {
    public BoolNumericDataChanged: CustomEvent;

    constructor () { super(); }

    public override oninit (): void {
        super.oninit();

        this.BoolNumericDataChanged = new CustomEvent();
        this.observe('boolNumericData', this.onBoolNumericDataChanged);
    }

    public onBoolNumericDataChanged (newVal): void {
        this.BoolNumericDataChanged.Raise(this, newVal);
    }
}

ContainerControl.extendWith(TerceraBoolNumeric, {
    data: function () {
        return {
            labelText: '',
            boolNumericData: null
        };
    },
    template: TerceraBoolNumericTemplate
});
