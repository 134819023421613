// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TerceraRadioButtonTemplate } from '../../templates.js';
import { Control } from './Control';

export class TerceraRadioButton extends Control {
    constructor () { super(); }

    public override oncomplete (): void {
    // this.observe('checked', this.private_OnCheckedChanged);
    // this.on('radioBtnClick', this.private_RadioClick);
    }

    public override oninit (): void {
        super.oninit();
        this.observe('checked', this.private_OnCheckedChanged);
        this.on('radioBtnClick', this.private_RadioClick);
    }

    public private_RadioClick (me): void {
        if (!me.checked) {
            void this.set('checked', true);
        }
    }

    public private_OnCheckedChanged (newValue, oldValue, keypath): void {
        if (!newValue && !oldValue) {
            return;
        }

        this.fire(RadioButtonEvents.RadioBtnStateChange, this, newValue, oldValue);
    }
}

enum RadioButtonEvents {
    RadioBtnStateChange = 'RBStateChange'
}

Control.extendWith(TerceraRadioButton, {
    template: TerceraRadioButtonTemplate,
    data: function () {
        return {
            checked: false,
            text: ''
        };
    }
});
