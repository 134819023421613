// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';

export class CustomListRequestMessage extends PFixMessage {
    constructor () {
        super(Message.CUSTOM_LIST_REQUEST);
    }

    public setUserId (userID: number): void {
        this.setFieldValue(FieldsFactory.FIELD_USER_ID, userID);
    }

    public setListType (listType: number): void {
        this.setFieldValue(FieldsFactory.FIELD_LIST_TYPE, listType);
    }
}
