// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { type Instrument } from './Instrument';

export interface MarketConsensusData {
    TradableId: number
    RouteId: number
    InstrumentName: string
    InstrumentNameTypeId: number
    Percent: number
    MarketConsensusPercentType: number
    IsExpertChoice: boolean
}

export class MarketConsensusDataItem {
    props: MarketConsensusData;
    constructor (data: MarketConsensusData) {
        this.props = Object.assign({}, data);
    }
}

export interface InstrumentDataResult {
    instr: Instrument | null
    item: MarketConsensusData
}

export enum MarketConsensusPercentTypeEnum {
    LONG = 0,
    SHORT = 1
}

// colors from design layout:
const RowColorsOriginal = ['#1564C0', '#1DE9B6', '#0E91BF', '#465A65', '#F8A725', '#00E675', '#FF417E', '#01C853', '#E040FC', '#5E7E8C'];
// "Дело в том, что в макете 48% прозрачность, поэтому они темнее"(c) Kalgin
// same colors with 48% opacity: (4 color is differ from design cuz its similar with selected row color so: "По поводу ногвого ховера - не вижу в этом большой проблемы, но при необходимости можно заменить цвет 4-й строки. Например, на #091820"(c) Kalgin )
const RowColors = ['rgba(21, 100, 192, 0.48)', 'rgba(29, 233, 182, 0.48)', 'rgba(14, 145, 191, 0.48)', /* 'rgba(9, 24, 32, 1)' */ 'rgba(70, 90, 101, 0.48)', 'rgba(248, 167, 37, 0.48)', 'rgba(0, 230, 117, 0.48)', 'rgba(255, 65, 126, 0.48)', 'rgba(1, 200, 83, 0.48)', 'rgba(224, 64, 252, 0.48)', 'rgba(94, 126, 140, 0.48)'];

export function GetColorByRowNumber (index: number): string {
    const colors = RowColors;
    return colors[index % colors.length];
};
