// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class MOMENTUM extends IndicatorScriptBase {
    public MomPeriod: number;

    constructor () {
        super();
        // #region Initialization
        this.ProjectName = 'Momentum';
        this.Comments = 'Momentum';
        // #endregion

        this.SetIndicatorLine('MOMENTUM', Color.Red, 1, LineStyle.SimpleChart);
        this.SetLevelLine('Zero', 0, Color.Gray, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.MomPeriod = 20;
        super.InputParameter(new InputParameterInteger('MomPeriod', 'Period of momentum:', 0, 1, 99999, 0, 1));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'MOMENTUM(' + this.MomPeriod + ')';
    }

    public override getCustomName (): string {
        return 'MOMENTUM (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count <= this.MomPeriod) { return; };

        this.SetValue(0, 0, this.CurrentData.GetPrice(PriceType.Close, 0) -
        (this.CurrentData.GetPrice(PriceType.Close, this.MomPeriod)));
    }
}
