// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iForce extends iBuildInIndicator {
    public FMaPeriod: any;
    public FMaMethod: any;
    public ma: ExpandDoubleVector = new ExpandDoubleVector();
    public maValuePrev: number = 0;

    constructor (maPeriod, maMethod, priceType) {
        super(1);
        this.FMaPeriod = maPeriod;
        this.FMaMethod = maMethod;
        this.FPriceType = priceType;
    }

    override get Name (): string { return 'iForce'; }

    public override NextBar (callBound): void {
        super.NextBar(callBound);
        this.ma.Add(0.0);
    }

    public override Refresh (count, newThread): void {
        this.ma.Dispose();
        this.ma = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }

    public override OnQuote (): void {
        const price = this.GetPrice(this.FPriceType, 0);
        this.ma[this.ma.Length - 1] = price;
        const Parent = this.Parent;
        if (!Parent || this.FCount < this.FMaPeriod) {
            return;
        }

        const maValue = IndicatorFunctions.CallMovingFunction(this.FMaMethod, this.ma, this.FMaPeriod, 1);
        this.SetValue(Parent.GetVolume(0) * (maValue - this.maValuePrev));
        this.maValuePrev = maValue;
    }
}
