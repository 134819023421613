// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { EventSource, EventType } from '../../Commons/cache/EventConstants';
import { Resources } from '../../Commons/properties/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraBaseWindowTemplate, TerceraTicketScreenTemplate, TerceraTicketScreenFooterTemplate } from '../../templates.js';
import { TicketData } from '../UtilsClasses/TicketData';
import { TerceraWindowBase } from './TerceraWindowBase';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { ConfirmationTypesEnum } from '../../Utils/Trading/ConfirmationTypesEnum';
import $ from 'jquery';

export class TerceraTicketScreen extends TerceraWindowBase {
    public static ScreensSet = [];

    constructor () { super(); }

    public override getType (): string { return 'TerceraTicketScreen'; }

    public override oncomplete (): void {
        super.oncomplete();

        this.observe('checked', function (newVal) {
            GeneralSettings.Confirmations.updateConfirmation(ConfirmationTypesEnum.DealTickets, newVal);
        }, { init: false });

        GeneralSettings.SettingsChanged.Subscribe(this.updateChecked, this);
    }

    public override dispose (): void {
        GeneralSettings.SettingsChanged.UnSubscribe(this.updateChecked, this);
        const index = TerceraTicketScreen.ScreensSet.indexOf(this);
        if (index != -1) {
            TerceraTicketScreen.ScreensSet.splice(index, 1);
        }
        super.dispose();
    }

    public updateChecked (): void {
        void this.set('checked', GeneralSettings.Confirmations.OpenDialTicketsOnWorkspace);
    }

    // TODO. UGLY. Refactor.
    public static show (ticket): void {
        const screen = new TerceraTicketScreen();
        const visibleFooter = ticket.isTrade;
        const heightFooter = visibleFooter ? 47 : 0;

        void screen.set('header', ticket.screenHeader || ticket.header);

        if (ticket.sortedEntries.length === 1) {
            const singleText = ticket.text || ticket.sortedEntries[0].text || ticket.sortedEntries[0].caption;
            void screen.set('singleRowText', singleText);
        } else {
            void screen.set('entries', ticket.sortedEntries);
        }

        void screen.set({
            checked: ticket.showNextTime,
            showFooter: visibleFooter,
            text: Resources.getResource('general.messageBox.showNextTime')
        });

        screen.on('complete', function () {
            const $root = $(this.find('.js-ticket-screen-additional-body'));
            void this.set('height', $root.height() + 24 + heightFooter);
            void this.set('width', $root.width());
            screen.center();
            if (TerceraTicketScreen.ScreensSet.length > 1) {
                const prew = TerceraTicketScreen.ScreensSet[TerceraTicketScreen.ScreensSet.length - 2];
                let l = prew.get('left');
                let t = prew.get('top');
                if (MainWindowManager.MainWindow.height - t < 300 || MainWindowManager.MainWindow.width - l < 300) {
                    t = l = 50;
                }
                void this.set({ left: l + 25, top: t + 25 });
            }
        });

        TerceraTicketScreen.ScreensSet.push(screen);

        MainWindowManager.MainWindow.addControl(screen);
    }

    // #region New Direct Methods

    public static TicketFromEvent (ev): TicketData {
        const reportLocKey = 'reports.' + ev.Action;

        const title = Resources.hasResource(reportLocKey)
            ? Resources.getResource(reportLocKey)
            : Resources.getResource(ev.Action);

        const entries = [];

        const descriptionItems = ev.DescriptionItems;
        const len = descriptionItems.length;
        for (let i = 0; i < len; i++) {
            const item = descriptionItems[i];
            entries.push({
                caption: item.Header,
                text: item.Value
            });
        }

        const ticket = new TicketData();
        ticket.header = title;
        ticket.sortedEntries = entries;
        ticket.eventSource = ev.Source;
                                                                                                // #121757
        if (ev.EventType === EventType.Trading && ev.Source !== EventSource.Refused && ev.Source !== EventSource.MarginCall) {
            ticket.isTrade = true;
        }

        return ticket;
    }

    // #endregion New Direct Methods
}

TerceraWindowBase.extendWith(TerceraTicketScreen, {
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            resizable: false,
            showFooter: false,
            entries: [],
            style_addition_body: 'js-ticket-screen-additional-body',
            style_addition_header: 'js-ticket-screen-additional-header',
            style_addition_footer: 'js-ticket-screen-additional-footer'
        };
    },
    partials: {
        bodyPartial: TerceraTicketScreenTemplate,
        footerPartial: TerceraTicketScreenFooterTemplate
    }
});
