// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { Resources } from '../../properties/Resources';
import { GeneralSettings } from '../../../Utils/GeneralSettings/GeneralSettings';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { DataCache } from '../../DataCache';
import { OrderTif, OrderTifMap } from '../../../Utils/Trading/OrderTifEnum';
import { RulesSet } from '../../../Utils/Rules/RulesSet';
import { DateTimeUtils } from '../../../Utils/Time/DateTimeUtils';
import { DateTimeConvertor } from '../../../Utils/Time/DateTimeConvertor';
import { SLTPTrigger } from '../../../Utils/SlTpTrigger';
import { OrderStatus } from '../../../Utils/Trading/OrderStatus';
import { SlTpPriceType } from '../../../Utils/Enums/Constants';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { type Order } from '../Order';
import { type ProductType } from '../../../Utils/Instruments/ProductType';

export class OrderFormatter {
    static OrderId (order: Order): any { return order.OrderNumber; }

    static AccountStr (order: Order): string | undefined { return order.Account?.toString(); }

    static SymbolStr (order: Order): string | undefined { return order.Instrument?.DisplayName(); }

    static IsLong (order: Order): boolean { return order.BuySell === OperationType.Buy; }

    static OrderType (order: Order): OrderType { return order.OrderType; }
    static OrderTypeStr (order: Order, isShowSlOrTp = false): string {
        let parentOrder: Order | null = null;
        const parentPosition = DataCache.getPositionById(order.BoundTo !== '-1' ? order.BoundTo : order.PositionId);
        if (!parentPosition) {
            parentOrder = DataCache.getOrderById(order.BoundTo);
        } else {
            parentOrder = parentPosition;
        }

        if (isShowSlOrTp && parentOrder) {
            if (parentOrder.SLOrder !== null && parentOrder.SLOrder.OrderNumber === order.OrderNumber && parentOrder.SLOrder.OrderType === OrderType.TrailingStop) {
                return Resources.getResource('Web.mobile.active.Order.TrStopLoss');
            } else if (parentOrder.SLOrder !== null && parentOrder.SLOrder.OrderNumber === order.OrderNumber) {
                return Resources.getResource('Web.mobile.active.Order.StopLoss');
            } else if (parentOrder.TPOrder !== null && parentOrder.TPOrder.OrderNumber === order.OrderNumber) {
                return Resources.getResource('Web.mobile.active.Order.TakeProfit');
            }
        }

        const orderParameterContainer = DataCache.OrderParameterContainer;
        const orderTypeObj = orderParameterContainer.GetOrderType(order.OrderType);
        return Resources.getResource('property.' + orderTypeObj.localizationKey());
    }

    static Operation (order: Order): any { return order.BuySell; }
    static OperationStr (order: Order): string | null { return order.GetFullOperationName(true); }

    static ValidityStr (order: Order): string {
        return OrderFormatter.GetFormattedValidityStr(order.TimeInForce, order.ExpireAt);
    }

    public static GetFormattedValidityStr (validity: number, date: Date): string {
        let tif = Resources.getResource('property.' + OrderTifMap[validity]);
        if (validity === OrderTif.GTD) {
            const dtString = DataCache.isAllowedForMyUser(RulesSet.FUNCTION_SHOW_TIME_FOR_GTD)
                ? DateTimeUtils.formatDate(date, 'DD.MM.YYYY')
                : date.toLocaleDateString();
            tif += ' (' + dtString + ')';
        }
        return tif;
    }

    static Amount (order: Order): number {
        const amount = order.Amount;
        if (GeneralSettings.View.DisplayQuantityInLots || order.Instrument === null) {
            return amount;
        } else {
            return amount * order.Instrument.LotSize;
        }
    }

    static AmountStr (order: Order): string {
        const amount = this.Amount(order);
        return DataCache.formatVolume(order.Instrument, Math.abs(amount), GeneralSettings.View.DisplayQuantityInLots, order.ProductType, order.Account);
    }

    static Price (order: Order): number { return order.Price; }
    static PriceStr (order: Order): string | undefined { return order.Instrument?.formatPrice(this.Price(order)); }

    static StopPrice (order: Order): number { return order.StopLimit; }
    static StopPriceStr (order: Order): string | undefined { return order.Instrument?.formatPrice(this.StopPrice(order)); }

    static CurrentPrice (order: Order): number { return order.CurPriceOpen || order.CurPriceClose; }
    static CurrentPriceStr (order: Order): string | undefined { return order.Instrument?.formatPrice(this.CurrentPrice(order)); }

    static IsSlOrTp (order: Order): boolean { return order.PositionId != '-1'; }
    static IsStopLossInOffset (order: Order): boolean {
        return order.StopLossPriceType !== SlTpPriceType.Absolute;
    }

    static IsStopLossTrailingStop (order: Order): boolean {
        return order.StopLossPriceType === SlTpPriceType.TrOffset;
    }

    static HasStopLossPrice (order: Order): boolean {
        return !isNaN(order.StopLossPriceValue);
    }

    static StopLossPrice (order: Order): number {
        if (!isNaN(order.StopLossPriceValue)) {
            return order.GetStopLossInPriceValue();
        }
        return NaN;
    }

    static StopLossPriceStr (order: Order, defaultStr = ''): string | undefined {
        const stopLossPrice = this.StopLossPrice(order);
        if (isNaN(stopLossPrice)) {
            return defaultStr;
        }

        const stopLossPriceStr = order.Instrument?.formatPrice(stopLossPrice);
        const trigger = order.TriggerSL || order.TriggerSLTP;
        if (trigger) {
            return stopLossPriceStr + ' ' + SLTPTrigger.ConvertBidAskRawValueToStr(trigger);
        } else {
            return stopLossPriceStr;
        }
    }

    static StopLossOffset (order: Order): number {
        if (!isNaN(order.StopLossPriceValue)) {
            return order.GetStopLossInOffsetValue();
        }
        return NaN;
    }

    static StopLossOffsetStr (order: Order, defaultStr = ''): string | undefined {
        const stopLossOffset = this.StopLossOffset(order);
        if (isNaN(stopLossOffset)) {
            return defaultStr;
        }

        const stopLossOffsetStr = order.Instrument?.formatOffset(stopLossOffset);
        const trigger = order.TriggerSL || order.TriggerSLTP;
        if (trigger) {
            return stopLossOffsetStr + ' ' + SLTPTrigger.ConvertBidAskRawValueToStr(trigger);
        } else {
            return stopLossOffsetStr;
        }
    }

    static IsTakeProfitInOffset (order: Order): boolean {
        return order.TakeProfitPriceType !== SlTpPriceType.Absolute;
    }

    static HasTakeProfitPrice (order: Order): boolean {
        return !isNaN(order.TakeProfitPriceValue);
    }

    static TakeProfitPrice (order: Order): number {
        if (!isNaN(order.TakeProfitPriceValue)) {
            return order.GetTakeProfitInPriceValue();
        } else {
            return NaN;
        }
    }

    static TakeProfitPriceStr (order: Order, defaultStr = ''): string | undefined {
        const takeProfitPrice = this.TakeProfitPrice(order);
        if (isNaN(takeProfitPrice)) { return defaultStr; }
        const takeProfitPriceStr = order.Instrument?.formatPrice(takeProfitPrice);
        const trigger = order.TriggerTP || order.TriggerSLTP;
        if (trigger) {
            return takeProfitPriceStr + ' ' + SLTPTrigger.ConvertBidAskRawValueToStr(trigger);
        } else {
            return takeProfitPriceStr;
        }
    }

    static TakeProfitOffset (order: Order): number {
        if (!isNaN(order.TakeProfitPriceValue)) {
            return order.GetTakeProfitInOffsetValue();
        }

        return NaN;
    }

    static TakeProfitOffsetStr (order: Order, defaultStr = ''): string | undefined {
        const takeProfitOffset = this.TakeProfitOffset(order);
        if (isNaN(takeProfitOffset)) {
            return defaultStr;
        }

        const takeProfitOffsetStr = order.Instrument?.formatOffset(takeProfitOffset);
        const trigger = order.TriggerTP || order.TriggerSLTP;
        if (trigger) {
            return takeProfitOffsetStr + ' ' + SLTPTrigger.ConvertBidAskRawValueToStr(trigger);
        } else {
            return takeProfitOffsetStr;
        }
    }

    static QuantityAverageFilledPrice (order: Order): number {
        return order.EverageFilledPrice;
    }

    static QuantityFilled (order: Order): number {
        const amountFilled = order.SharesFilled;
        if (GeneralSettings.View.DisplayQuantityInLots || order.Instrument === null) {
            return amountFilled;
        } else {
            return amountFilled * order.Instrument.LotSize;
        }
    }

    static QuantityFilledStr (order: Order): string {
        const quantityFilledValue = this.QuantityFilled(order);
        return DataCache.formatVolume(order.Instrument, Math.abs(quantityFilledValue), GeneralSettings.View.DisplayQuantityInLots, order.ProductType, order.Account);
    }

    static QuantityRemaining (order: Order): number {
        const amountRemaining = order.AmountRemaining;
        if (GeneralSettings.View.DisplayQuantityInLots || order.Instrument === null) {
            return amountRemaining;
        } else {
            return amountRemaining * order.Instrument.LotSize;
        }
    }

    static QuantityRemainingStr (order: Order): string {
        const quantityRemainingValue = this.QuantityRemaining(order);
        return DataCache.formatVolume(order.Instrument, Math.abs(quantityRemainingValue), GeneralSettings.View.DisplayQuantityInLots, order.ProductType, order.Account);
    }

    static BoundToStr (order: Order, defaultStr = ''): string {
        if (order.BoundTo != '-1') {
            return order.BoundTo;
        } else if (order.PositionId != '-1') {
            return order.PositionId;
        } else {
            return defaultStr;
        }
    }

    static DateTime (order: Order): Date | null {
        return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(order.UTCDateTime);
    }

    static DateTimeStr (order: Order): string {
        const dateTime = this.DateTime(order);
        return DateTimeUtils.formatDate(dateTime, 'DD.MM.YYYY  HH:mm:ss');
    }

    static OrderStatus (order: Order): number { return order.Status; }
    static OrderStatusStr (order: Order): string { return this.localizeOrderStatus(this.OrderStatus(order)); }
    static ProductType (order: Order): ProductType { return order.ProductType; }
    static ProductTypeStr (order: Order): string { return InstrumentUtils.GetLocalizedProductType(order.Instrument, order.ProductType); }
    static TradingExchangeStr (order: Order): any { return order.Instrument?.TradingExchange; }

    // #endregion Helpers
    private static localizeOrderStatus (status: OrderStatus): string {
        let key = 'UNKNOWN';
        switch (status) {
        case OrderStatus.UNKNOWN:
            key = 'UNKNOWN';
            break;
        case OrderStatus.REPLACED:
            key = 'REPLACED';
            break;
        case OrderStatus.PENDING_NEW:
            key = 'PENDING_NEW';
            break;
        case OrderStatus.PENDING_EXECUTION:
            key = 'PENDING_EXECUTION';
            break;
        case OrderStatus.PENDING_CANCEL:
            key = 'PENDING_CANCEL';
            break;
        case OrderStatus.PENDING_REPLACE:
            key = 'PENDING_REPLACE';
            break;
        case OrderStatus.PENDING_REPLACE_NOT_ACTIVE:
            key = 'PENDING_REPLACE_NOT_ACTIVE';
            break;
        case OrderStatus.NEW:
            key = 'NEW';
            break;
        case OrderStatus.ACCEPTED:
            key = 'ACCEPTED';
            break;
        case OrderStatus.PART_FILLED:
            key = 'PART_FILLED';
            break;
        case OrderStatus.FILLED:
            key = 'FILLED';
            break;
        case OrderStatus.CANCELED:
            key = 'CANCELED';
            break;
        case OrderStatus.REFUSED:
            key = 'REFUSED';
            break;
        case OrderStatus.WAITING_MARKET:
            key = 'WAITING_MARKET';
            break;
        case OrderStatus.OFF_MARKET:
            key = 'OFF_MARKET';
            break;
        case OrderStatus.UNPLACED:
            key = 'UNPLACED';
            break;
        }

        return Resources.getResource('Order.OrderStatus.STATUS_' + key);
    }
    // #endregion
}
