import { DynProperty, PairColor } from '../../../Commons/DynProperty';
import { Point, Rectangle, Size } from '../../../Commons/Geometry';
import { SolidBrush, type Font } from '../../../Commons/Graphics';
import { Resources } from '../../../Commons/properties/Resources';
import { MouseButtons } from '../../../Controls/UtilsClasses/ControlsUtils';
import { ThemeManager } from '../../../Controls/misc/ThemeManager';
import { type TerceraChartBase } from '../../TerceraChartBase';
import { type TerceraChartMouseEventArgs } from '../../Utils/TerceraChartMouseEventArgs';
import { type TerceraChartWindowBase } from '../../Windows/TerceraChartWindowBase';
import { TerceraChartBaseRenderer } from '../TerceraChartBaseRenderer';
import { InfoWindowDockingType } from './InfoWindowDockingType';
import { InfoWindowItem } from './InfoWindowItem';

export class TerceraChartBaseInfoWindowRenderer<Chart extends TerceraChartBase = TerceraChartBase> extends TerceraChartBaseRenderer<Chart> {
    private readonly _infoWindowItems: InfoWindowItem[] = [];
    private _dockingState: InfoWindowDockingType = InfoWindowDockingType.Top;
    private _isMoving: boolean = false;
    private _isUpdateInfoWindow: boolean = true;
    private _prevMousePosition: Point = Point.Empty();
    private _newMousePosition: Point = Point.Empty();
    private _lastDataX: number = NaN;
    private _lastDataY: number = NaN;
    private readonly font: Font = ThemeManager.Fonts.Font_11F25_regular;

    public BackgroundColor: string = ThemeManager.CurrentTheme.Chart_InfoWindowBackColor;
    public ForegroundColor: string = ThemeManager.CurrentTheme.Chart_InfoWindowForeColor;
    public isNeedForceUpdate: boolean = false;

    private readonly PADDING_LEFT = 8;
    private readonly PADDING_RIGHT = 8;
    private readonly PADDING_TOP = 4;
    private readonly PADDING_BOTTOM = 4;
    private readonly HORIZONTAL_OFFSET = 10;
    private readonly VERTICAL_OFFSET = 4;
    private readonly LABEL_OFFSET = 4;

    protected initializeItems (ids: number[]): void {
        for (let i = 0; i < ids.length; i++) {
            this._infoWindowItems.push(new InfoWindowItem(ids[i]));
        }
    }

    // #region Overrides
    public override Draw (gr: CanvasRenderingContext2D, window: TerceraChartWindowBase, windowsContainer: any, advParams: any): void {
        if (!this.Visible) {
            return;
        }

        if (this._isUpdateInfoWindow) {
            this.updateRectangle(gr, window);
            this._isUpdateInfoWindow = false;
        }

        const infoWindowRectangle = this.Rectangle;
        gr.save();
        gr.beginPath();
        gr.rect(infoWindowRectangle.X, infoWindowRectangle.Y, infoWindowRectangle.Width, infoWindowRectangle.Height);
        gr.clip();
        const backgroundBrush = new SolidBrush(this.BackgroundColor);
        gr.FillRect(backgroundBrush, infoWindowRectangle.X, infoWindowRectangle.Y, infoWindowRectangle.Width, infoWindowRectangle.Height);
        const location = new Point(infoWindowRectangle.X + this.PADDING_LEFT, infoWindowRectangle.Y + this.PADDING_TOP);
        const labelForegroundBrush = new SolidBrush(this.ForegroundColor);
        let maxLabelWidth = 0;
        for (let i = 0; i < this._infoWindowItems.length; i++) {
            const infoWindowItem = this._infoWindowItems[i];
            if (!infoWindowItem.isVisible) {
                continue;
            }
            const label = isValidString(infoWindowItem.label) ? `${infoWindowItem.label}:` : '';
            const labelSize = gr.GetTextSize(label, this.font, true);
            maxLabelWidth = Math.max(maxLabelWidth, labelSize.width);
        }
        for (let i = 0; i < this._infoWindowItems.length; i++) {
            const infoWindowItem = this._infoWindowItems[i];
            if (!infoWindowItem.isVisible) {
                continue;
            }
            const label = isValidString(infoWindowItem.label) ? `${infoWindowItem.label}:` : '';
            const values = infoWindowItem.formattedValues;
            const valuesForegroundColors: string[] = this.getItemValueForegroundColors(infoWindowItem);
            const labelSize = gr.GetTextSize(label, this.font, true);

            if (isValidString(label)) {
                gr.DrawString(label, this.font, labelForegroundBrush, location.X, location.Y);
            }
            for (let j = 0; j < values.length; j++) {
                const value = values[j];
                const valueForegroundBrush = isValidString(valuesForegroundColors[j]) ? new SolidBrush(valuesForegroundColors[j]) : labelForegroundBrush;
                const valueSize = gr.GetTextSize(values[j], this.font, true);
                if (this._dockingState !== InfoWindowDockingType.None) {
                    const xOffset = j === 0 ? labelSize.width + this.LABEL_OFFSET : 0;
                    location.X += xOffset;
                    gr.DrawString(value, this.font, valueForegroundBrush, location.X, location.Y);
                    location.X += valueSize.width + this.HORIZONTAL_OFFSET;
                } else {
                    location.X += maxLabelWidth + this.LABEL_OFFSET;
                    gr.DrawString(value, this.font, valueForegroundBrush, location.X, location.Y);
                    location.X = infoWindowRectangle.X + this.PADDING_LEFT;
                    location.Y += valueSize.height + this.VERTICAL_OFFSET;
                }
            }
        }

        gr.restore();
    }

    public override Localize (): void {
        super.Localize();
        this.localizeItems();
    }

    public override ThemeChanged (): void {
        super.ThemeChanged();
        this.BackgroundColor = ThemeManager.CurrentTheme.Chart_InfoWindowBackColor;
        this.ForegroundColor = ThemeManager.CurrentTheme.Chart_InfoWindowForeColor;
    }

    public override Properties (): DynProperty[] {
        const props = super.Properties();
        // #region hidden
        props.push(new DynProperty('infowindow_Docked', this._dockingState, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        props.push(new DynProperty('infowindow_fx', this.Rectangle.X, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        props.push(new DynProperty('infowindow_fy', this.Rectangle.Y, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        // #endregion
        let sortIndex = 0;
        const infoWindowItems = this._infoWindowItems;
        const infoWindowItemsProps = [];
        const visibilitySeparatorGroup = '#0#' + Resources.getResource('property.VisibilitySeparatorGroup');
        const viewSeparatorGroup = '#1#' + Resources.getResource('property.ViewSeparatorGroup');
        const colorsSeparatorGroup = '#2#' + Resources.getResource('property.ColorsSeparatorGroup');

        let prop = new DynProperty('infowindow_visible', this.Visible, DynProperty.BOOLEAN, this.getDynPropertyGroup());
        prop.sortIndex = sortIndex++;
        prop.separatorGroup = visibilitySeparatorGroup;
        prop.assignedProperty = [];
        for (let i = 0; i < infoWindowItems.length; i++) {
            const infoWindowItem = infoWindowItems[i];
            const propName = `infoWindowItem_${infoWindowItem.id}`;
            prop.assignedProperty.push(propName);
            const dp = new DynProperty(propName, infoWindowItem.isVisible, DynProperty.BOOLEAN, this.getDynPropertyGroup());
            dp.propertyComment = infoWindowItem.label;
            dp.sortIndex = sortIndex++;
            dp.separatorGroup = viewSeparatorGroup;
            dp.enabled = this.Visible;
            infoWindowItemsProps.push(dp);
        }
        props.push(prop);
        props.push(...infoWindowItemsProps);

        prop = new DynProperty('infoWindow_fore_back_color', new PairColor(this.ForegroundColor, this.BackgroundColor, 'Fore', 'Back'), DynProperty.PAIR_COLOR, this.getDynPropertyGroup());
        prop.sortIndex = sortIndex++;
        prop.separatorGroup = colorsSeparatorGroup;
        prop.enabled = this.Visible;
        props.push(prop);
        return props;
    }

    public override callBack (properties: DynProperty[]): void {
        super.callBack(properties);
        let dp = DynProperty.getPropertyByName(properties, 'infowindow_Docked');
        if (!isNullOrUndefined(dp?.value)) {
            this._dockingState = dp.value as InfoWindowDockingType;
        }
        dp = DynProperty.getPropertyByName(properties, 'infowindow_fx');
        if (!isNullOrUndefined(dp?.value)) {
            this.Rectangle.X = dp.value as number;
        }
        dp = DynProperty.getPropertyByName(properties, 'infowindow_fy');
        if (!isNullOrUndefined(dp?.value)) {
            this.Rectangle.Y = dp.value as number;
        }
        dp = DynProperty.getPropertyByName(properties, 'infowindow_visible');
        if (!isNullOrUndefined(dp?.value)) {
            this.Visible = dp.value as boolean;
        }
        const infoWindowItems = this._infoWindowItems;
        for (let i = 0; i < infoWindowItems.length; i++) {
            const infoWindowItem = infoWindowItems[i];
            dp = DynProperty.getPropertyByName(properties, `infoWindowItem_${infoWindowItem.id}`);
            if (!isNullOrUndefined(dp?.value)) {
                infoWindowItem.isVisible = dp.value as boolean;
            }
        }
        dp = DynProperty.getPropertyByName(properties, 'infoWindow_fore_back_color');
        if (!isNullOrUndefined(dp?.value)) {
            const pairColor = dp.value as PairColor;
            this.ForegroundColor = pairColor.Color1;
            this.BackgroundColor = pairColor.Color2;
        }
    }

    public override ProcessMouseDown (e: TerceraChartMouseEventArgs): boolean {
        let processed = super.ProcessMouseDown(e);
        if (!this.Visible || e.Button !== MouseButtons.Left) {
            return processed;
        }

        if (this.Rectangle.Contains(e.Location.X, e.Location.Y)) {
            this._prevMousePosition = e.Location;
            this._isMoving = true;
            processed = true;
        } else {
            this._prevMousePosition = Point.Empty();
            this._isMoving = false;
        }

        return processed || this._isMoving;
    }

    public override ProcessMouseMove (e: TerceraChartMouseEventArgs): boolean {
        const processed = super.ProcessMouseMove(e);
        if (!this.Visible || isNullOrUndefined(e.window)) {
            return processed;
        }
        const dataX = e.window.PointsConverter.GetDataX(e.Location.X);
        const dataY = e.window.PointsConverter.GetDataY(e.Location.Y);
        this.updateItems(dataX, dataY);
        if (this._isMoving) {
            this._newMousePosition = e.Location;
        }
        this._isUpdateInfoWindow = true;
    }

    public override ProcessMouseUp (e: TerceraChartMouseEventArgs): boolean {
        let processed = super.ProcessMouseUp(e);
        if (this.Visible && this._isMoving) {
            this._prevMousePosition = this._newMousePosition = Point.Empty();
            this._isMoving = false;
            processed = true;
        }
        return processed;
    }

    public override IsNeedForceUpdate (): boolean {
        let isUpdated = false;
        if (this.isNeedForceUpdate) {
            this.isNeedForceUpdate = false;
            if (!isNaN(this._lastDataX) && !isNaN(this._lastDataY)) {
                this.updateItems(this._lastDataX, this._lastDataY);
                this._isUpdateInfoWindow = true;
                isUpdated = true;
            }
        }
        return isUpdated;
    }
    // #endregion

    // #region Protected
    protected getItemById (id: number): InfoWindowItem {
        for (let i = 0; i < this._infoWindowItems.length; i++) {
            if (this._infoWindowItems[i].id === id) {
                return this._infoWindowItems[i];
            }
        }
        return null;
    }

    protected updateItem (item: InfoWindowItem, dataX: number, dataY: number): void { }
    protected localizeItem (item: InfoWindowItem): void { }
    protected getItemValueForegroundColors (item: InfoWindowItem): string[] { return ['']; }
    protected getDynPropertyGroup (): string { return DynProperty.DATA_BOX_GROUP; }
    // #endregion

    protected updateItems (dataX: number, dataY: number): void {
        this._lastDataX = dataX;
        this._lastDataY = dataY;
        for (let i = 0; i < this._infoWindowItems.length; i++) {
            this.updateItem(this._infoWindowItems[i], dataX, dataY);
        }
    }

    private localizeItems (): void {
        for (let i = 0; i < this._infoWindowItems.length; i++) {
            this.localizeItem(this._infoWindowItems[i]);
        }
    }

    private updateRectangle (gr: CanvasRenderingContext2D, window: TerceraChartWindowBase): void {
        const windowClientRectangle = window.ClientRectangle;
        let infoWindowRectangle = new Rectangle(this.Rectangle.X, this.Rectangle.Y, this.Rectangle.Width, this.Rectangle.Height);
        if (this._isMoving) {
            const xDelta = this._newMousePosition.X - this._prevMousePosition.X;
            const yDelta = this._newMousePosition.Y - this._prevMousePosition.Y;
            const newInfoWindowRectangle = new Rectangle(infoWindowRectangle.X + xDelta, infoWindowRectangle.Y + yDelta, infoWindowRectangle.Width, infoWindowRectangle.Height);
            if (infoWindowRectangle.Top() > windowClientRectangle.Top() && newInfoWindowRectangle.Top() <= windowClientRectangle.Top()) {
                this._dockingState = InfoWindowDockingType.Top;
            } else if (infoWindowRectangle.Bottom() < windowClientRectangle.Bottom() && newInfoWindowRectangle.Bottom() >= windowClientRectangle.Bottom()) {
                this._dockingState = InfoWindowDockingType.Bottom;
            } else if (this._dockingState === InfoWindowDockingType.Top && newInfoWindowRectangle.Top() > windowClientRectangle.Top()) {
                this._dockingState = InfoWindowDockingType.None;
                newInfoWindowRectangle.X = this._newMousePosition.X;
            } else if (this._dockingState === InfoWindowDockingType.Bottom && newInfoWindowRectangle.Bottom() < windowClientRectangle.Bottom()) {
                this._dockingState = InfoWindowDockingType.None;
                newInfoWindowRectangle.X = this._newMousePosition.X;
            }
            infoWindowRectangle = newInfoWindowRectangle;
            this._prevMousePosition = this._newMousePosition;
        }
        const infoWindowSize: Size = this.calculateInfoWindowSize(gr);
        infoWindowRectangle.Width = infoWindowSize.Width;
        infoWindowRectangle.Height = infoWindowSize.Height;
        switch (this._dockingState) {
        case InfoWindowDockingType.Top:
            infoWindowRectangle.X = windowClientRectangle.X;
            infoWindowRectangle.Y = windowClientRectangle.Y;
            break;
        case InfoWindowDockingType.Bottom:
            infoWindowRectangle.X = windowClientRectangle.X;
            infoWindowRectangle.Y = windowClientRectangle.Bottom() - infoWindowSize.Height;
            break;
        }
        this.Rectangle = this.correctInfoWindowRectangle(infoWindowRectangle, windowClientRectangle);
    }

    private calculateInfoWindowSize (gr: CanvasRenderingContext2D): Size {
        const size = new Size(0, 0);
        const isDocked = this._dockingState !== InfoWindowDockingType.None;
        let maxLabelWidth = 0;
        let maxValueWidth = 0;
        for (let i = 0; i < this._infoWindowItems.length; i++) {
            const infoWindowItem = this._infoWindowItems[i];
            if (!infoWindowItem.isVisible) {
                continue;
            }
            const label = isValidString(infoWindowItem.label) ? `${infoWindowItem.label}:` : '';
            const values = infoWindowItem.formattedValues;
            const labelSize = gr.GetTextSize(label, this.font, true);
            for (let j = 0; j < values.length; j++) {
                const value = values[j];
                const valueSize = gr.GetTextSize(value, this.font, true);
                if (isDocked) {
                    const offset = j === 0 ? labelSize.width + this.LABEL_OFFSET : 0;
                    const textSize = new Size(offset + valueSize.width, Math.max(labelSize.height, valueSize.height));
                    size.Width += (textSize.Width + this.HORIZONTAL_OFFSET);
                    size.Height = Math.max(size.Height, textSize.Height);
                } else {
                    maxLabelWidth = Math.max(maxLabelWidth, labelSize.width);
                    maxValueWidth = Math.max(maxValueWidth, valueSize.width);
                    size.Height += (Math.max(labelSize.height, valueSize.height) + this.VERTICAL_OFFSET);
                }
            }
        }
        if (isDocked) {
            size.Width -= this.HORIZONTAL_OFFSET;
        } else {
            size.Width = maxLabelWidth + this.LABEL_OFFSET + maxValueWidth;
            size.Height -= this.VERTICAL_OFFSET;
        }
        size.Width += (this.PADDING_LEFT + this.PADDING_RIGHT);
        size.Height += (this.PADDING_TOP + this.PADDING_BOTTOM);
        return size;
    }

    private correctInfoWindowRectangle (infoWindowRectangle: Rectangle, windowClientRectangle: Rectangle): Rectangle {
        const rect = infoWindowRectangle;
        if (rect.X < windowClientRectangle.X) {
            rect.X = windowClientRectangle.X;
        }
        if (rect.Y < windowClientRectangle.Y) {
            rect.Y = windowClientRectangle.Y;
        }
        if (rect.Right() > windowClientRectangle.Right()) {
            if (windowClientRectangle.Width >= infoWindowRectangle.Width) {
                const delta = rect.Right() - windowClientRectangle.Right();
                rect.X -= delta;
            } else {
                infoWindowRectangle.Width = windowClientRectangle.Width;
            }
        }
        if (rect.Bottom() > windowClientRectangle.Bottom()) {
            if (windowClientRectangle.Height >= infoWindowRectangle.Height) {
                const delta = rect.Bottom() - windowClientRectangle.Bottom();
                rect.Y -= delta;
            } else {
                infoWindowRectangle.Height = windowClientRectangle.Height;
            }
        }
        return rect;
    }
}
