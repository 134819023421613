// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { EventItem } from '../cache/EventItem';
import { TerceraMenu } from '../elements/TerceraMenu';
import { ReportMessageTooltip } from '../../Utils/ReportMessageTooltip';
import { TerceraTicketScreen } from '../screen/TerceraTicketScreen';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { EventType } from '../../Commons/cache/EventConstants';
import { EventCache } from '../../Commons/cache/EventCache';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type Event } from '../../Commons/cache/Event';

export class EventsPanel extends ApplicationPanelWithTable<EventItem> {
    public static readonly REMOVE_ALL = 'REMOVE_ALL';

    public headerLocaleKey: string = 'panel.EventLog.NamePanel';
    public skipOnSave: boolean = true;
    public NeedCalculateRowCount: boolean = false;
    public allowResizers: any = {
        left: true,
        top: true,
        right: false,
        bottom: false
    };

    public EventColoring: any;

    public override getType (): PanelNames { return PanelNames.EventsPanel; }

    public override oncomplete (): void {
        super.oncomplete();

        this.localize();
        const qt = this.getQuickTable();
        qt.AddToEnd = false;
        qt.isMoveColumnAllowed = false;
        qt.canShowHeaderMenu = false; // #88411

        this.populateTableContextMenu();

        qt.OnPaintedPictureButtonClick.Subscribe(this.getEventTicket, this);

        const evtCache = EventCache;
        evtCache.OnAddEvent.Subscribe(this.OnAddEvent, this);
        evtCache.OnRemoveEvent.Subscribe(this.OnRemoveEvent, this);
        evtCache.OnClearEvents.Subscribe(this.OnClearEvents, this);
    }

    public override dispose (): void {
        super.dispose();

        const evtCache = EventCache;

        evtCache.OnAddEvent.UnSubscribe(this.OnAddEvent, this);
        evtCache.OnRemoveEvent.UnSubscribe(this.OnRemoveEvent, this);
        evtCache.OnClearEvents.UnSubscribe(this.OnClearEvents, this);
    }

    public override populateItemsDirect (): void {
        super.populateItemsDirect();

        const eventArr = EventCache.GetEvents();
        const len = eventArr.length;
        for (let i = 0; i < len; i++) {
            this.OnAddEvent(eventArr[i]);
        }
    }

    public OnAddEvent (ev: Event): void {
        const qt = this.getQuickTable();

        const colors = ev.GetColorRow(this.EventColoring);

        const row = qt.AddItem(new EventItem(ev, SessionSettings, DataCache, colors.ForeColor, colors.BackColor));
    }

    public OnRemoveEvent (id: number | string): void {
        const qt = this.getQuickTable();
        qt.RemoveItem(id);
    }

    public OnClearEvents (): void {
        const qt = this.getQuickTable();
        if (!isNullOrUndefined(qt)) {
            qt.ClearAll();
        }
    }

    public getEventTicket (data): void {
        const row = data.row;
        if (isNullOrUndefined(row)) return;

        const event: Event = row.item.ev;

        const ticket = TerceraTicketScreen.TicketFromEvent(event);
        // TODO. UGLY.
        if (event.EventType === EventType.Trading) {
            ReportMessageTooltip.fixEntries(
                ticket.sortedEntries,
                SessionSettings,
                event.Instrument, event.Account, event.ProductType);
        }

        ReportMessageTooltip.localizeEntries(ticket.sortedEntries);
        TerceraTicketScreen.show(ticket);
    }

    public populateTableContextMenu (): void {
        const items = [
            {
                text: Resources.getResource('panel.EventLog.menu.Clear'),
                event: this.onRemoveAll.bind(this),
                enabled: false,
                tag: EventsPanel.REMOVE_ALL
            }
        ];

        this.menuTagDict = TerceraMenu.createTagDictionary(items);
        this.getQuickTable().setTableContextMenuItems(items);
    }

    public onRemoveAll (): void {
        const qtRactive = this.quickTableRactive;
        const qt = !isNullOrUndefined(qtRactive) ? qtRactive.quickTable : null;
        if (isNullOrUndefined(qt)) {
            return;
        }

        qt.ClearRows();
    }

    public override preparePopup (): void {
        super.preparePopup();

        this.menuTagDict[EventsPanel.REMOVE_ALL].enabled =
        this.getQuickTable().rowsArray.length > 0;
    }
}

ApplicationPanelWithTable.extendWith(EventsPanel,
    {
        data: function () {
            return {
                zIndex: 1300,
                showHeader: true,
                showFooter: false,
                dockablePanel: false,
                resizable: true,
                width: 680,
                height: 300,
                closeBtnVisible: false,
                movable: false,
                canLinkByAccount: false
            };
        },
        headerLocaleKey: 'panel.EventLog.NamePanel',
        skipOnSave: true,
        NeedCalculateRowCount: false,
        allowResizers: {
            left: true,
            top: true,
            right: false,
            bottom: false
        }
    });
