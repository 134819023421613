// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DataCache } from '../../Commons/DataCache';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { TerceraSideBarButton } from './SideBar/TerceraSideBarButton';

export class IdeasButton extends TerceraSideBarButton {
    override readonly tooltipKey: string = 'panel.Ideas';
    constructor () {
        super();
    }

    getType (): ControlsTypes {
        return ControlsTypes.IdeasButton;
    };

    oncomplete (): void {
        super.oncomplete();

        DataCache.OnTradingIdeaAdd.Subscribe(this.onIdeasNumberChanged, this);
        DataCache.OnTradingIdeaCancel.Subscribe(this.decrementCounter, this);
        DataCache.OnTradingSignalRequestSend.Subscribe(this.onIdeasNumberChanged, this);
        DataCache.OnTradingIdeaFirstOpen.Subscribe(this.decrementCounter, this);

        DataCache.OnTradingSystemUnsubscribe.Subscribe(this.onIdeasNumberChanged, this);
    };

    dispose (): void {
        DataCache.OnTradingIdeaAdd.UnSubscribe(this.onIdeasNumberChanged, this);
        DataCache.OnTradingIdeaCancel.Subscribe(this.decrementCounter, this);
        DataCache.OnTradingSignalRequestSend.UnSubscribe(this.onIdeasNumberChanged, this);
        DataCache.OnTradingIdeaFirstOpen.UnSubscribe(this.decrementCounter, this);

        DataCache.OnTradingSystemUnsubscribe.UnSubscribe(this.onIdeasNumberChanged, this);

        super.dispose();
    }

    onIdeasNumberChanged (): void {
        const oldValue = this.get('counter');
        const newValue = this.getNotSeenIdeasNumber();

        // if (newValue > 0)
        //     this.startCounterBlinking()      // comment out due to #112700

        void super.set('counter', newValue);
    }

    decrementCounter (idea): void {
        const oldValue = super.get('counter');

        if (!oldValue) { return; } // can't be less than zero

        if (idea && !idea.wasNotRead) { return; } // to need to decrement counter if idea has been read

        void super.set('counter', oldValue - 1);
    }

    getNotSeenIdeasNumber (): number {
        const ideas = DataCache.tradingIdeas;
        const keys = Object.keys(ideas);
        let result = 0;

        for (let i = 0; i < keys.length; i++) {
            const idea = ideas[keys[i]];

            if (idea.wasNotRead) { result++; }
        }

        return result;
    }
}

TerceraSideBarButton.extendWith(IdeasButton, {});
