// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { OrderExecutionType } from '../../Utils/Trading/OrderExecutionType';
import { Resources } from '../properties/Resources';
import { type ParamType } from './AllowedReportConstants';

// TODO. Inherit from DataCacheBusinessObject.
export class AllowedReport {
    public key: string;
    public id: any;
    public params: ReportParam[];

    constructor (key, id, rawParams) {
        this.key = key;
        this.id = id;
        this.params = AllowedReport.parseRawParams(rawParams);
    }

    public static REPORT_KEY_PREFIX = '_REPORTKEY_';
    public static TECHNICAL_REPORT_KEY_PREFIX = '_TECHNICALREPORTKEY_';
    public static REPORT_ORDERBOOK = 'Order Book Report';
    public static REPORT_TRADES = 'Trades Report';
    public static REPORT_STATEMENT = 'Statement report';
    public static REPORT_MAMACCOUNTSUMMARY = 'MAM account summary';
    public static TOTAL_ROW_LABEL = 'Totals:';

    get keyForServer (): string {
        const key = this.key;
        if (!key) return '';

        return key.replace(
            key.includes(AllowedReport.REPORT_KEY_PREFIX)
                ? AllowedReport.REPORT_KEY_PREFIX
                : AllowedReport.TECHNICAL_REPORT_KEY_PREFIX,
            '');
    }

    public toString (): string {
        const realKey = this.key.replace(AllowedReport.REPORT_KEY_PREFIX, '');
        const reportKey = 'reports.' + realKey;
        return Resources.getResource(
            Resources.hasResource(reportKey)
                ? reportKey
                : realKey);
    }

    public static parseRawParams (rawParams): ReportParam[] {
        const newParams = [];

        for (let i = 0, len = rawParams.length; i < len; i++) {
            const rawParam = rawParams[i];
            const allowedParam = new ReportParam(rawParam.name, rawParam.type);

            const rawOptionsStr = rawParam.params;
            if (rawOptionsStr) {
                const splits = rawOptionsStr.split(',');
                for (let j = 0, j_len = splits.length; j < j_len; j++) {
                    const split = splits[j];
                    if (!split) continue;

                    const optionParts = split.split(':');
                    const val = optionParts[0];
                    const name = optionParts[1];

                    allowedParam.addValue(name, parseInt(val));
                }
            }

            newParams.push(allowedParam);
        }

        return newParams;
    }

    public static EventFromString (strEventType): OrderExecutionType {
        let realEvent = strEventType;
        if (AllowedReport.OrderBookEventsLocalized[strEventType]) {
            realEvent = AllowedReport.OrderBookEventsLocalized[strEventType];
        }

        switch (realEvent) {
        case AllowedReport.SERVER_ORDER_BOOK_EVENT_NEW_10:
            return OrderExecutionType.NEW;

        case AllowedReport.SERVER_ORDER_BOOK_EVENT_REPLACE_20:
            return OrderExecutionType.REPLACED;

        case AllowedReport.SERVER_ORDER_BOOK_EVENT_RESTATE_42:
            return OrderExecutionType.RESTATED;

        case AllowedReport.SERVER_ORDER_BOOK_EVENT_ACTIVATED_43:
            return OrderExecutionType.ACTIVATED;

        case AllowedReport.SERVER_ORDER_BOOK_EVENT_ACCEPTED_11:
            return OrderExecutionType.ACCEPTED;

        case AllowedReport.SERVER_ORDER_BOOK_EVENT_PARTIALLY_FILLED_30:
            return OrderExecutionType.PART_FILLED;

        case AllowedReport.SERVER_ORDER_BOOK_EVENT_FILLED_31:
            return OrderExecutionType.FILLED;

        case AllowedReport.SERVER_ORDER_BOOK_EVENT_CANCELLED_40:
            return OrderExecutionType.CANCELED;

        case AllowedReport.SERVER_ORDER_BOOK_EVENT_REFUSED_41:
            return OrderExecutionType.REFUSED;

        default:
            return null;
        }
    }

    public static SERVER_ORDER_BOOK_EVENT_NEW_10 = 'Created';
    public static SERVER_ORDER_BOOK_EVENT_REPLACE_20 = 'Modified';
    public static SERVER_ORDER_BOOK_EVENT_RESTATE_42 = 'Restated';
    public static SERVER_ORDER_BOOK_EVENT_ACTIVATED_43 = 'Activated';
    public static SERVER_ORDER_BOOK_EVENT_ACCEPTED_11 = 'Accepted';
    public static SERVER_ORDER_BOOK_EVENT_PARTIALLY_FILLED_30 = 'Partially filled';
    public static SERVER_ORDER_BOOK_EVENT_FILLED_31 = 'Filled';
    public static SERVER_ORDER_BOOK_EVENT_CANCELLED_40 = 'Cancelled';
    public static SERVER_ORDER_BOOK_EVENT_REFUSED_41 = 'Rejected';

    public static OrderBookEventsLocalized = null;

    public static Localize (): void {
        AllowedReport.OrderBookEventsLocalized = {};
        AllowedReport.OrderBookEventsLocalized[Resources.getResource('reports.' + AllowedReport.SERVER_ORDER_BOOK_EVENT_NEW_10)] = AllowedReport.SERVER_ORDER_BOOK_EVENT_NEW_10;
        AllowedReport.OrderBookEventsLocalized[Resources.getResource('reports.' + AllowedReport.SERVER_ORDER_BOOK_EVENT_REPLACE_20)] = AllowedReport.SERVER_ORDER_BOOK_EVENT_REPLACE_20;
        AllowedReport.OrderBookEventsLocalized[Resources.getResource('reports.' + AllowedReport.SERVER_ORDER_BOOK_EVENT_RESTATE_42)] = AllowedReport.SERVER_ORDER_BOOK_EVENT_RESTATE_42;
        AllowedReport.OrderBookEventsLocalized[Resources.getResource('reports.' + AllowedReport.SERVER_ORDER_BOOK_EVENT_ACTIVATED_43)] = AllowedReport.SERVER_ORDER_BOOK_EVENT_ACTIVATED_43;
        AllowedReport.OrderBookEventsLocalized[Resources.getResource('reports.' + AllowedReport.SERVER_ORDER_BOOK_EVENT_ACCEPTED_11)] = AllowedReport.SERVER_ORDER_BOOK_EVENT_ACCEPTED_11;
        AllowedReport.OrderBookEventsLocalized[Resources.getResource('reports.' + AllowedReport.SERVER_ORDER_BOOK_EVENT_PARTIALLY_FILLED_30)] = AllowedReport.SERVER_ORDER_BOOK_EVENT_PARTIALLY_FILLED_30;
        AllowedReport.OrderBookEventsLocalized[Resources.getResource('reports.' + AllowedReport.SERVER_ORDER_BOOK_EVENT_FILLED_31)] = AllowedReport.SERVER_ORDER_BOOK_EVENT_FILLED_31;
        AllowedReport.OrderBookEventsLocalized[Resources.getResource('reports.' + AllowedReport.SERVER_ORDER_BOOK_EVENT_CANCELLED_40)] = AllowedReport.SERVER_ORDER_BOOK_EVENT_CANCELLED_40;
        AllowedReport.OrderBookEventsLocalized[Resources.getResource('reports.' + AllowedReport.SERVER_ORDER_BOOK_EVENT_REFUSED_41)] = AllowedReport.SERVER_ORDER_BOOK_EVENT_REFUSED_41;
    }
}

export class ReportParam {
    public name: any;
    public type: ParamType;
    public options: any[];

    constructor (name, type: ParamType) {
        this.name = name;
        this.type = type;
        this.options = [];
    }

    public addValue (text, value): void {
        const locText = Resources.getResource('reports.' + text);
        this.options.push({ text: locText, value });
    }

    public toString (): string {
        const key = 'reports.' + this.name;
        return Resources.IsResourcePresent(key) ? Resources.getResource(key) : this.name;
    }
}

Resources.AddStaticLocalization(AllowedReport.Localize);
