// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { DataCache } from '../../Commons/DataCache';
import { Resources } from '../../Commons/properties/Resources';
import { type DirectCloseAccountResponseMessage } from '../../Utils/DirectMessages/DirectCloseAccountResponseMessage';
import { CloseAccountResponseStatus } from '../../Utils/Enums/Constants';
import { MessageBoxType, TerceraMessageBox } from './TerceraMessageBox';
import { TerceraButtonStyle } from '../../Utils/Enums/ButtonEnums';
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CloseAccountScreen {
    static messageBoxHandler: TerceraMessageBox | null = null;
    static subscribedToChangeRequestIDEvent: boolean = false;

    static ShowMsg (isInvesting: boolean = false): void {
        if (DataCache.closeAccountApproved) {
            CloseAccountScreen.ShowApproveCloseAccountScreen(); // The approval screen will be displayed if the 'CloseAccountScreen' was opened or on the next click of the 'Close account' menu item.
            return;
        }

        const requestSent: boolean = DataCache.closeAccountRequestID > 0;
        const additionalData = {
            style_addition_header: '',
            btnOKStyle: TerceraButtonStyle[requestSent ? 'BorderLess' : 'Blue'],
            okText: requestSent
                ? Resources.getResource('screen.closeAccount.cancelText')
                : Resources.getResource('screen.closeAccount.okBtnText'),
            cancelText: Resources.getResource('screen.closeAccount.closeBtnText')
        };

        if (isInvesting) {
            if (requestSent) {
                DataCache.SendCloseAccountCancelRequestMessage(DataCache.closeAccountRequestID);
            } else {
                DataCache.SendCloseAccountRequestMessage();
            }

            return;
        }

        if (!isInvesting && !CloseAccountScreen.subscribedToChangeRequestIDEvent) {
            DataCache.OnChangeCloseAccountRequestID.Subscribe(CloseAccountScreen.OnChangeCloseAccountRequestID.bind(this), this);
            CloseAccountScreen.subscribedToChangeRequestIDEvent = true;
        }

        CloseAccountScreen.messageBoxHandler = TerceraMessageBox.Show(
            Resources.getResource('screen.closeAccount.header'),
            Resources.getResource(requestSent ? 'screen.closeAccount.submitText' : 'screen.closeAccount.confirmText'),
            MessageBoxType.YellowInfo,
            CloseAccountScreen.SendRequestHandler,
            () => { },
            false,
            false,
            null,
            additionalData,
            false
        );
    }

    static OnChangeCloseAccountRequestID (message: DirectCloseAccountResponseMessage): void {
        const approved = message.status === CloseAccountResponseStatus.Approve;
        const msgBox: TerceraMessageBox | null = CloseAccountScreen.messageBoxHandler;
        if (msgBox === null || msgBox.torndown === true) {
            // Will not show the 'Approve Close Account' screen if this screen was closed, but it will recognize the received approval in order to display it the next time it is opened.

            // return;
        }

        msgBox?.dispose(); // better use msgBox.close() (but ESLint: property 'close' does not exist on type 'TerceraMessageBox'.) Can use onCloseButtonClick();

        if (approved) {
            CloseAccountScreen.ShowApproveCloseAccountScreen();
        } else {
            CloseAccountScreen.ShowMsg();
        }
    }

    static async SendRequestHandler (): Promise<void> {
        const reqID = DataCache.closeAccountRequestID;
        const needToCancelRequest = reqID > 0;
        const msgs: DirectCloseAccountResponseMessage[] = (needToCancelRequest ? await DataCache.SendCloseAccountCancelRequestMessage(reqID) : await DataCache.SendCloseAccountRequestMessage());

        if (msgs?.length > 0) {
            const msg: DirectCloseAccountResponseMessage = msgs[0];
            if (msg !== undefined) {
                switch (msg.status) {
                case CloseAccountResponseStatus.New:
                    if (CloseAccountScreen.messageBoxHandler == null) {
                        CloseAccountScreen.messageBoxHandler = TerceraMessageBox.Show(
                            Resources.getResource('screen.closeAccount.header'), // <-header,
                            Resources.getResource('screen.closeAccount.submitText'),
                            MessageBoxType.YellowInfo,
                            CloseAccountScreen.SendRequestHandler,
                            () => { },
                            false,
                            false,
                            null,
                            {
                                style_addition_header: '',
                                btnOKStyle: TerceraButtonStyle.BorderLess,
                                okText: Resources.getResource('screen.closeAccount.cancelText'),
                                cancelText: Resources.getResource('screen.closeAccount.closeBtnText') // <-close button text
                            },
                            false
                        );
                    }
                    break;
                case CloseAccountResponseStatus.Approve:
                    CloseAccountScreen.ShowApproveCloseAccountScreen();
                    break;
                case CloseAccountResponseStatus.Rejected:
                case CloseAccountResponseStatus.Cancelled:
                    CloseAccountScreen.ShowMsg();
                    break;
                }
            }
        }
    }

    static ShowApproveCloseAccountScreen (): void {
        CloseAccountScreen.messageBoxHandler = TerceraMessageBox.Show(
            Resources.getResource('screen.closeAccount.header'),
            Resources.getResource('screen.closeAccount.approveText'),
            MessageBoxType.Approve,
            () => { },
            () => { },
            false,
            true,
            null,
            { style_addition_header: '', okText: Resources.getResource('screen.closeAccount.closeBtnText') },
            false
        );
    }
}
