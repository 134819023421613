// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { TimeZoneInfo } from '../Time/TimeZoneInfo';
import { TimeSpanPeriods } from '../Time/TimeSpan';
import { DateTimeUtils } from '../Time/DateTimeUtils';
import { SessionOperations } from '../Enums/Constants';
import { DateTime } from 'luxon';

export class SessionsContainer {
    public Id: number;
    public Name: string;
    public Sessions: TradingSession[];
    public CurrentSessionId: number;
    public UseIntradayMarginCoef: boolean;
    public TimeZoneOffset: number;
    public BlockTrading: boolean;
    public ServerBeginDayDate: Date;
    public ServerBeginDayDateCorrected: Date;
    public TimeOffset: number;

    constructor (id, name) {
        this.Id = id;
        this.Name = name;
        this.Sessions = [];

        this.CurrentSessionId = 0;
        this.UseIntradayMarginCoef = false;
        this.TimeZoneOffset = 0;
        this.BlockTrading = false;
        this.ServerBeginDayDate = null;
        // TODO think about this... ServerBeginDayDate
        this.ServerBeginDayDateCorrected = null;
        this.TimeOffset = 0;
    }

    public GetSessionByID (id: number): TradingSession {
        const sessions = this.Sessions;
        const len = sessions.length;
        for (let i = 0; i < len; i++) {
            const session = sessions[i];
            if (session.Id === id) { return session; }
        }

        return null;
    }
}

export class TradingSessionBase {
    public Id: number;
    public MainType: number;
    public SubType: number;
    public Description: string;
    public DayPeriod: number;
    public Begin: number;
    public End: number;
    public begins: number[];
    public ends: number[];
    public beginsFromServer: Date[];
    public endsFromServer: Date[];
    public TimeZoneID: string;
    public ServerBeginDayDate: Date;
    public ServerBeginDayDateCorrected: number;
    public TimeOffset: number;
    public HolidaysList: HolidayDescription[];
    public isIntraday: boolean;
    public DecreaseOnlyPositionCount: boolean;
    public AllowOptionsExerciseOnLastTradeDate: boolean = false;
    public FirstDayOfWeek: number = 1; // Monday by default (0 - Sunday, 1 - Monday, 2 - Tuesday, 3 - Wednesday, 4 - Thursday, 5 - Friday, 6 - Saturday)

    constructor (
        description: string,
        dayPeriod: number,
        begin: number[],
        end: number[],
        beginFromServer: Date[],
        endFromServer: Date[]) {
        this.Id = 0;
        this.isIntraday = false;
        this.DecreaseOnlyPositionCount = false;

        this.Description = description;
        this.DayPeriod = dayPeriod;

        // 0 - sunday ... 5 - friday, 6-saturday, 7-shorted
        this.begins = begin;
        this.ends = end;
        this.beginsFromServer = beginFromServer;
        this.endsFromServer = endFromServer;
        this.Begin = this.GetBegin(DateTimeUtils.DateTimeUtcNow().getUTCDay(), false);
        this.End = this.GetEnd(DateTimeUtils.DateTimeUtcNow().getUTCDay(), false);

        this.MainType = MainTypes.AUCTION_PRE_OPEN;
        this.SubType = SubTypes.AUCTION_PRE_CROSS;

        // UTC.
        this.ServerBeginDayDate = null;
        this.ServerBeginDayDateCorrected = null;
        this.TimeZoneID = null;
        this.TimeOffset = 0;
    }

    public GetBegin (dayOfWeek, isShort) {
        let result = this.begins[isShort ? 7 : dayOfWeek];

        if (result === undefined) { result = this.begins[0]; }; // https://tp.traderevolution.com/entity/106776 (comment dated 28.07.22)

        return result;
    }

    public GetEnd (dayOfWeek, isShort) {
        let result = this.ends[isShort ? 7 : dayOfWeek];

        if (result === undefined) { result = this.ends[0]; }; // https://tp.traderevolution.com/entity/106776 (comment dated 28.07.22)

        return result;
    }

    public GetBeginFromServer (dayOfWeek, isShort) {
        let result = this.beginsFromServer[isShort ? 7 : dayOfWeek];

        if (result === undefined) { result = this.beginsFromServer[0]; }; // https://tp.traderevolution.com/entity/106776 (comment dated 28.07.22)

        return result;
    }

    public GetEndFromServer (dayOfWeek, isShort) {
        let result = this.endsFromServer[isShort ? 7 : dayOfWeek];

        if (result === undefined) { result = this.endsFromServer[0]; }; // https://tp.traderevolution.com/entity/106776 (comment dated 28.07.22)

        return result;
    }

    public IsMain (): boolean {
        return this.DayPeriod === DayPeriods.MAIN;
    }

    public isBeforeOrAfterMarket (): boolean {
        return this.DayPeriod === DayPeriods.BEFORE_MARKET || this.DayPeriod === DayPeriods.AFTER_MARKET;
    };

    public static IsPreType (type): boolean {
        return (type === MainTypes.AUCTION_PRE_OPEN || type === MainTypes.CONTINUOUS_PRE || type === MainTypes.AUCTION_PRE_CLOSE || type === MainTypes.AUCTION_PRE_MAIN_OPEN);
    }

    public static IsMainType (type): boolean {
        return (type === MainTypes.AUCTION_OPEN || type === MainTypes.MAIN_CONTINUOUS1 || type === MainTypes.IN_BETWEEN_CLEARING || type === MainTypes.MAIN_CONTINUOUS2 || type === MainTypes.AUCTION_CLOSE || type === MainTypes.CLEARING);
    }

    public static IsPostType (type): boolean {
        return (type === MainTypes.SETTLEMENT_CLEARING || type === MainTypes.AUCTION_POST_OPEN || type === MainTypes.CONTINUOUS_POST || type === MainTypes.AUCTION_POST_CLOSE || type === MainTypes.SETTLEMENT_CLEARING);
    }

    // public static BeforeOrAfterMarket (time: number, ins): boolean {
    //     if (!ins) return false;

    //     const date = new Date(time);
    //     const [sessionPeriods, sessDate, dayOfWeek] = TradingSessionBase.GetSessionPeriodForDate(
    //         date,
    //         ins.TradingSessionsList);

    //     if (sessionPeriods.length === 0) { return true; }

    //     for (let i = sessionPeriods.length - 1; i >= 0; i--) {
    //         const sesionPeriod = sessionPeriods[i];
    //         const startTime = sesionPeriod.GetBeginFromServer(dayOfWeek, false);
    //         const endTime = sesionPeriod.GetEndFromServer(dayOfWeek, false);
    //         const begin = sesionPeriod.ConvertSessionTimeToDate(sessDate, startTime);
    //         const end = sesionPeriod.ConvertSessionTimeToDate(sessDate, endTime);
    //         if (begin <= date && date <= end) { return sesionPeriod.isBeforeOrAfterMarket(); }
    //     }

    //     return false;
    // }

    public ConvertTimeUtcToSessionDateTime (date: Date): DateTime {
        const datetimeInSessionTimeZone: DateTime = DateTime.fromMillis(date.getTime(), { zone: this.TimeZoneID });
        const datetimeInSessionTimeZoneWithOffset: DateTime = datetimeInSessionTimeZone.plus({ minutes: -this.TimeOffset });
        return datetimeInSessionTimeZoneWithOffset;
    }

    public ConvertSessionTimeToUtcDateTime (dateTimeInSessionTimeZoneWithOffset: DateTime): Date {
        const datetimeInSessionTimeZoneWithoutOffset: DateTime = dateTimeInSessionTimeZoneWithOffset.plus({ minutes: this.TimeOffset });
        return datetimeInSessionTimeZoneWithoutOffset.toJSDate();
    }

    public ConvertTimeUtcToSessionDate (date: Date): Date {
        return this.ConvertTimeUtcToSessionDateTime(date).toJSDate();
    }

    public ConvertSessionTimeToDate (sessDate: DateTime, sessTime: Date): Date {
        const year = sessDate.year;
        const month = sessDate.month;
        const day = sessDate.day;

        const sessionDay = DateTime.fromObject(
            { year, month, day },
            { zone: this.TimeZoneID }
        );
        const sessionTime = sessionDay.plus({ milliseconds: sessTime.getTime() });
        const sessionTimeWithOffset = sessionTime.plus({ minutes: this.TimeOffset });
        const jsDate = sessionTimeWithOffset.toJSDate();
        return jsDate;
    }

    public static GetSessionPeriodForDate (date: Date,
        TradingSessionsList: TradingSessionBase[]): [TradingSessionBase[], DateTime, number] {
        let sessionPeriods = TradingSessionsList;
        const firstPeriod = TradingSessionsList[0];
        const sessDate: DateTime = firstPeriod.ConvertTimeUtcToSessionDateTime(date);
        const holidaysList: HolidayDescription[] = firstPeriod.HolidaysList;

        const year = sessDate.year;
        const month = sessDate.month;
        const day = sessDate.day;
        const sessionDateForHoliday = new Date(Date.UTC(year, month - 1, day));
        const startOfHolidayDateTime = sessionDateForHoliday.getTime();

        // TODO: use Map
        if (holidaysList) {
            for (const holidayDesciption of holidaysList) {
                if (holidayDesciption.Date.getTime() === startOfHolidayDateTime &&
                    holidayDesciption.HolidayType === DayType.SHORTED) {
                    sessionPeriods = holidayDesciption.ShortenedDaySessionPeriods;
                    break;
                }
            }
        }

        const dayOfWeek = sessDate.weekday;
        return [sessionPeriods, sessDate, dayOfWeek];
    }

    public static GetSessionPeriodRange (date: Date,
        TradingSessionsList: TradingSessionBase[],
        showExtendedSession: boolean): [Date, Date] {
        const [sessionPeriods, sessDate, dayOfWeek] = TradingSessionBase.GetSessionPeriodForDate(date, TradingSessionsList);

        let allowedPeriods = sessionPeriods.filter(period => !period.isBeforeOrAfterMarket());
        if (!showExtendedSession) {
            allowedPeriods = allowedPeriods.filter(period => period.IsMain());
        }

        if (allowedPeriods.length === 0) { return [new Date(0), new Date(0)]; }

        const firstSessionPeriod = allowedPeriods[0];
        const lastSessionPeriod = allowedPeriods[allowedPeriods.length - 1];

        const startFirstSession = firstSessionPeriod.GetBeginFromServer(dayOfWeek, false);
        const endLastSession = lastSessionPeriod.GetEndFromServer(dayOfWeek, false);

        const start = firstSessionPeriod.ConvertSessionTimeToDate(sessDate, startFirstSession);
        const end = lastSessionPeriod.ConvertSessionTimeToDate(sessDate, endLastSession);

        return [start, end];
    }

    public GetStartSession (dateTimeUtc: Date, useOffset = false) {
        let serverOffset = 0;
        if (useOffset) { serverOffset = this.TimeOffset || 0; };
        const startOfDateTime = new Date(dateTimeUtc.getFullYear(), dateTimeUtc.getMonth(), dateTimeUtc.getDate()).getTime();

        let description = null;

        if (this.HolidaysList) {
            for (let i = 0; i < this.HolidaysList.length; i++) {
                if (this.HolidaysList[i].Date.getTime() == startOfDateTime) {
                    description = this.HolidaysList[i];
                    break;
                }
            }
        }

        const shorted = description != null && description.HolidayType == DayType.SHORTED;

        if (useOffset) { serverOffset += this.getDSTdif(dateTimeUtc); };

        const time = this.GetBegin(dateTimeUtc.getDay(), shorted);
        return new Date(startOfDateTime + time - (dateTimeUtc.getTimezoneOffset() + serverOffset) * TimeSpanPeriods.TicksPerMinute);
    }

    public getDSTdif (dateTimeInSessionTimeZone) {
        const _j = new Date(dateTimeInSessionTimeZone.getFullYear(), 0, 1).getTimezoneOffset();

        return dateTimeInSessionTimeZone.getTimezoneOffset() - _j;
    }

    // TODO.
    public CheckSession (datetime) {
        return true;
    }

    public get ServerTodayDate (): Date {
        return new Date(this.ServerBeginDayDate);
    }

    public get ServerTodayDateCorrected (): Date {
        if (!this.ServerBeginDayDateCorrected) {
            // let st = this.ServerBeginDayDate.getTimezoneOffset();
            // let jan = (new Date(new Date(this.ServerBeginDayDate).setMonth(0))).getTimezoneOffset();
            // let isSummer = st !== jan
            this.ServerBeginDayDateCorrected = +this.ServerBeginDayDate + (TimeZoneInfo.ListOfTimeZone[this.TimeZoneID]/* + (isSummer ? 1 : 0) */) * 60 * 60 * 1000;
        }
        return new Date(this.ServerBeginDayDateCorrected);
    }
}

export class TradingSession extends TradingSessionBase {
    constructor (description, dayPeriod, begin, end, beginFromServer, endFromServer) {
        super(description, dayPeriod, begin, end, beginFromServer, endFromServer);
        this.HolidaysList = [];
    }

    public static CreateByBase (base: TradingSessionBase): TradingSession {
        const tradingSession = new TradingSession(
            base.Description,
            base.DayPeriod,
            base.begins,
            base.ends,
            base.beginsFromServer,
            base.endsFromServer);
        for (const key in base) { tradingSession[key] = base[key]; };

        return tradingSession;
    }

    public GetNextHoliday (): HolidayDescription {
        let holiday: HolidayDescription = null;
        const std = this.ServerTodayDateCorrected;
        std.setHours(0); std.setMinutes(0);
        const holidays = this.getSortedHolidays();
        for (const h of holidays) {
            const dt = h.Date;
            dt.setHours(0); dt.setMinutes(0);
            if (dt >= std) {
                holiday = h;
                break;
            }
        }

        return holiday;
    }

    public getSortedHolidays (): HolidayDescription[] {
        const sortedHolidays = this.HolidaysList.sort((a, b) => a.Date.getTime() - b.Date.getTime());
        return sortedHolidays;
    }

    public GetTodayHoliday (): HolidayDescription {
        const todayServerDate = this.ServerTodayDate;
        const todayMonth = todayServerDate.getMonth(); // месяц
        const todayDay = todayServerDate.getDate(); // число

        const HolidaysList = this.HolidaysList;
        for (let i = 0, len = HolidaysList.length; i < len; i++) {
            const h = HolidaysList[i];
            const holidayDate = h.Date; // example: 01.01.20XX
            const holidayDay = holidayDate.getDate(); // 1 - число
            const holidayMonth = holidayDate.getMonth(); // 0 - месяц

            if (holidayDay == todayDay && holidayMonth == todayMonth) { return h; };
        }

        return null;
    }
}

export class TradeSessionStatus {
    public Id: number;
    public Name: string;
    public AllowedOrderTypes: any; // ?
    public AllowedOperations: any; // ?
    public IsDecreaseOnlyPositionCount: boolean;

    constructor (msg) {
        this.Id = msg.Id;
        this.Name = '';
        this.AllowedOrderTypes = null;
        this.AllowedOperations = SessionOperations.None;
        this.IsDecreaseOnlyPositionCount = false;
        this.update(msg);
    }

    public update (msg) {
        if (!msg) { return; };

        this.Name = msg.Name;
        this.IsDecreaseOnlyPositionCount = msg.IsDecreaseOnlyPositionCount;
        this.AllowedOperations = msg.AllowedOperations;
        this.AllowedOrderTypes = msg.AllowedOrderTypes;
    }
}

// #endregion TradeSessionStatus

class _MainTypes {
    public readonly AUCTION_PRE_OPEN = 0;
    public readonly MAIN_CONTINUOUS1 = 1;
    public readonly MAIN_CONTINUOUS2 = 2;
    public readonly CONTINUOUS_PRE = 3;
    public readonly AUCTION_PRE_CLOSE = 4;
    public readonly AUCTION_PRE_MAIN_OPEN = 5;
    public readonly AUCTION_OPEN = 6;
    public readonly IN_BETWEEN_CLEARING = 7;
    public readonly AUCTION_CLOSE = 8;
    public readonly CLEARING = 9;
    public readonly SETTLEMENT_CLEARING = 10;
    public readonly FINAL_SETTLEMENT_CLEARING = 11;
    public readonly AUCTION_POST_OPEN = 12;
    public readonly AUCTION_POST_CLOSE = 13;
    public readonly CONTINUOUS_POST = 14;
}

export const MainTypes = new _MainTypes();

export enum SubTypes {
    AUCTION_PRE_CROSS = 0,
    AUCTION_FREEZE = 1,
    CONTINUOUS = 2,
    IN_BETWEEN_CLEARING = 3,
    SETTLEMENT_CLEARING = 4
}

export enum DayPeriods {
    PRE_OPEN = 0,
    MAIN = 1,
    POST_CLOSE = 2,
    BEFORE_MARKET = 3,
    AFTER_MARKET = 4
}

export enum DayType {
    NOT_WORKING = 0,
    SHORTED = 1,
    WORKING = 2
}

export class HolidayDescription {
    public Name: string | null;
    public Date: Date | null;
    public HolidayType: number;
    public ShortenedDaySessionPeriods: TradingSessionBase[];
    constructor () {
        this.Name = null;
        this.Date = null;
        this.HolidayType = DayType.NOT_WORKING;
        // Array that contains some or single variable of TradingSessionBase type which specify session periods for shortened day when it's holiday #106782
        this.ShortenedDaySessionPeriods = null;
    }

    public GetTradingSessionsList () { return this.ShortenedDaySessionPeriods; };
    public IsNotWorking (): boolean { return this.HolidayType === DayType.NOT_WORKING; };
    public IsShorted () { return this.HolidayType === DayType.SHORTED; };
    public IsWorking () { return !this.IsNotWorking(); }; // may be usefull
}
