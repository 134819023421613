// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { TerceraRadioButtonComponent, RadioButtonComponentEvents } from './TerceraRadioButtonComponent';

export class TerceraRadioComboItemGroup extends TerceraRadioButtonComponent {
    public SelectedValueChanged: CustomEvent;

    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.TerceraRadioComboItemGroup; }

    public override oninit (): void {
        super.oninit();

        this.SelectedValueChanged = new CustomEvent();
        this.on(RadioButtonComponentEvents.StateChange, this.onStateChanged);
    }

    public onStateChanged (sender, selectedRBIdx: number): void {
        this.SelectedValueChanged.Raise(this, this.get('radioItems')[selectedRBIdx].value);
    }

    public setItems (selectedValue, items: any[]): void {
        if (!isValidArray(items)) return;

        const len = items.length;
        for (let i = 0; i < len; i++) {
            const item = items[i];
            if (item.value === selectedValue) {
                item.checked = true;
                break;
            }
        }

        void this.set('radioItems', items);
    }
}

TerceraRadioButtonComponent.extendWith(TerceraRadioComboItemGroup, {});
