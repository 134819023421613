// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../properties/Resources';

export class Algorithm {
    AlgorithmId: number | null = null;
    AlgorithmName: string | null = null;
    AlgorithmFilterName: string | null = null;
    InstrumentGroupIDs: any = null;
    RouteIDs: any = null;

    AlgorithmType: number = AlgorithmType.Unknown;
    AlgorithmStatus: number = AlgorithmStatus.Unknown;
    AlgorithmContainerType: number = AlgorithmContainerType.Unknown;

    AvailableRanges: AlgorithmDataRange[] = [];

    CountryId: any = null;

    constructor (data: any) {
        this.UpdateByMessage(data);
    }

    UpdateByMessage = (msg: any): void => {
        if (msg == null) { return; }

        this.AlgorithmId = msg.AlgorithmId ?? this.AlgorithmId;
        this.AlgorithmType = msg.AlgorithmType ?? this.AlgorithmType;
        this.AlgorithmName = msg.AlgorithmName ?? this.AlgorithmName;
        this.AlgorithmStatus = msg.AlgorithmStatus ?? this.AlgorithmStatus;
        this.AlgorithmContainerType = msg.AlgorithmContainerType ?? this.AlgorithmContainerType;
        this.AlgorithmFilterName = msg.AlgorithmFilterName ?? this.AlgorithmFilterName;
        this.InstrumentGroupIDs = msg.InstrumentGroupIDs ?? this.InstrumentGroupIDs;
        this.RouteIDs = msg.RouteIDs ?? this.RouteIDs;
        this.CountryId = msg.CountryId ?? this.CountryId;
        this.AvailableRanges = msg.AvailableRanges ?? this.AvailableRanges;
    };

    public static getRangeString = (range: AlgorithmDataRange): string => {
        switch (range.Type) {
        case AlgorithmRangeType.Day:
            return `${range.Value} ${range.Value === 1 ? Resources.getResource('period.Day') : Resources.getResource('period.Days')}`;
        case AlgorithmRangeType.Week:
            return `${range.Value} ${range.Value === 1 ? Resources.getResource('period.Week') : Resources.getResource('period.Weeks')}`;
        case AlgorithmRangeType.Month:
            return `${range.Value} ${range.Value === 1 ? Resources.getResource('period.Month') : Resources.getResource('period.Months')}`;
        default:
            return '';
        }
    };
}

export const AlgorithmType = {
    Unknown: -1,
    HighLow: 0,
    GainersLosers: 1,
    MarketConsensus: 2,
    TopVolume: 3,
    HistoricalGainersLosersTop: 4,
    MostTraded: 5,
    Heatmap: 6
};

export const AlgorithmContainerType = {
    Unknown: -1,
    None: 0,
    Country: 1,
    Custom: 2
};

export const AlgorithmStatus = {
    Unknown: -1,
    Running: 0,
    Stopped: 1,
    Inactive: 2
};

export const AlgorithmSubscribeType = {
    Subscribe: 0,
    Unsubscribe: 1
};

export enum AlgorithmRangeType { Day, Week, Month }

export class AlgorithmDataRange {
    private _type: AlgorithmRangeType;
    private _value: number;

    constructor (type: AlgorithmRangeType, value: number) {
        this._type = type;
        this._value = value;
    }

    get Type (): AlgorithmRangeType { return this._type; }
    set Type (value: AlgorithmRangeType) { this._type = value; }

    get Value (): number { return this._value; }
    set Value (value: number) { this._value = value; }

    isEqual (otherRange: AlgorithmDataRange): boolean { return this.Value === otherRange.Value && this.Type === otherRange.Type; }

    toString (): string { return `${this.Type} ${this.Value}`; }
    static fromString (str: string): AlgorithmDataRange {
        const splitArr = str.split(' ');
        const type = parseInt(splitArr[0]);
        const value = parseInt(splitArr[1]);

        return new AlgorithmDataRange(type, value);
    }
}
