// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.
import { type DirectMessageBaseClass } from '../../../../Utils/DirectMessages/DirectMessageBase';
import { DirectOpenOrderMessage } from '../../../../Utils/DirectMessages/DirectOpenOrderMessage';
import { DirectOrderDialogMessage } from '../../../../Utils/DirectMessages/DirectOrderDialogMessage';
import { SlTpPriceType } from '../../../../Utils/Enums/Constants';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectOrderHistoryMessage } from '../../../../Utils/DirectMessages/DirectOrderHistoryMessage';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { generateReportMessageHandler } from '../../../../Utils/AppHandlers';
import { OrderStatus } from '../../../../Utils/Trading/OrderStatus';
import { OrderExecutionType } from '../../../../Utils/Trading/OrderExecutionType';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { DataCacheHolder } from '../../../../Commons/cache/DataCacheHolder';

export class OpenOrderMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    public static dataCache: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectMessageBaseClass[] {
        const message = new DirectOpenOrderMessage();

        const fieldOrderStatusVal = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_STATUS);
        const status = fieldOrderStatusVal === null ? -1 : fieldOrderStatusVal;

        const orderType = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_TYPE);

        message.OrderWasManual = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_IS_WAS_MANUAL);
        // Order ID
        message.OrderNumber = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_ID).toString();
        message.ClientOrderId = fieldSet.GetValue(FieldsFactory.FIELD_CLIENT_ORDER_ID);
        // Order type
        message.OrderType = orderType;
        // Operation type
        const buysell = fieldSet.GetValue(FieldsFactory.FIELD_OPERATION_TYPE);
        message.BuySell = buysell;

        // Order amount
        const quantity = fieldSet.GetValue(FieldsFactory.FIELD_AMOUNT);
        const disclosedQty = fieldSet.GetValue(FieldsFactory.FIELD_DISCLOSED_QUANTITY);
        const sharesFilled = fieldSet.GetValue(FieldsFactory.FIELD_FILLED_AMOUNT);
        const lastAmount = fieldSet.GetValue(FieldsFactory.FIELD_LAST_AMOUNT);
        message.Quantity = quantity;
        message.DisClosedQuantity = disclosedQty;
        message.SharesFilled = sharesFilled;
        message.LastAmount = lastAmount;
        // Instrument ID
        // let instrumentID = fieldSet.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID)

        const fieldPositionId = fieldSet.GetValue(FieldsFactory.FIELD_POSITION_ID);
        message.PositionId = fieldPositionId === null ? '-1' : fieldPositionId.toString();

        message.OrderStatus = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_STATUS);
        message.ExecutionType = fieldSet.GetValue(FieldsFactory.FIELD_EXECUTION_TYPE);

        // Bound to Order ID
        message.BoundToOrderId = fieldSet.GetValue(FieldsFactory.FIELD_BOUNDTOORDERID).toString();
        // PIN
        message.Account = fieldSet.GetValue(FieldsFactory.FIELD_ACCOUNT_ID).toString();
        message.EverageFilledPrice = fieldSet.GetValue(FieldsFactory.FIELD_AVERAGE_FILLED_PRICE);

        const routeId = fieldSet.GetValue(FieldsFactory.FIELD_ROUTE_ID);
        message.Route = routeId; // decoder.GetRouteById(routeId)

        // Is active
        const fieldActiveFlag = fieldSet.GetValue(FieldsFactory.FIELD_ACTIVE_FLAG);
        const fieldIsActive = fieldSet.GetValue(FieldsFactory.FIELD_IS_ACTIVE);
        const fieldIsOpen = fieldSet.GetValue(FieldsFactory.FIELD_IS_OPEN);
        message.Active = fieldActiveFlag === 1;
        if (!message.Active && fieldIsActive !== null) {
            message.Active = fieldIsActive;
        }
        // #34674 был заведен потому что Active изначально использовался неверно,
        // т.к. он изменяется в зависимости от поля FIELD_IS_OPEN
        message.RealActive = fieldActiveFlag === 1;
        message.IsOpen = fieldIsOpen;
        if (fieldIsOpen !== null && !fieldIsOpen) {
            message.Active = false;
        }

        const comment = fieldSet.GetValue(FieldsFactory.FIELD_COMMENT);

        message.Comment = comment;
        message.LackOfMargin = fieldSet.GetValue(FieldsFactory.FIELD_LACK_OF_MARGIN);
        message.ClientPanelId = fieldSet.GetValue(FieldsFactory.FIELD_CLIENT_PANEL);
        message.TrStopOffset = fieldSet.GetValue(FieldsFactory.FIELD_TR_OFFSET);
        // Price
        message.Price = fieldSet.GetValue(FieldsFactory.FIELD_PRICE);
        message.LastPrice = fieldSet.GetValue(FieldsFactory.FIELD_LAST_PRICE);
        const groupId = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_GROUP_ID);
        message.OrderGroupId = groupId;
        // master id
        const masterID = fieldSet.GetValue(FieldsFactory.FIELD_MASTER_ID);
        message.MasterID = masterID.toString();
        message.StopLimit = fieldSet.GetValue(FieldsFactory.FIELD_STOP_PRICE);
        message.OrderCreatedByUser = fieldSet.GetValue(FieldsFactory.FIELD_LOGIN);
        // Date of creation
        message.UTCDateTime = fieldSet.GetValue(FieldsFactory.FIELD_CREATED_AT);
        message.RemoteID = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_REF_ID);
        // Validity
        const tif = fieldSet.GetValue(FieldsFactory.FIELD_VALIDITY);
        message.TimeInForce = tif;

        message.ExpireTime = fieldSet.GetValue(FieldsFactory.FIELD_EXPIRE_AT);
        message.LastUpdateTime = fieldSet.GetValue(FieldsFactory.FIELD_TIMESTAMP);
        message.InitMargin = fieldSet.GetValue(FieldsFactory.FIELD_INIT_MARGINGSIZE) || 0;
        // message.InstrumentID = instrumentID
        // message.Symbol = decoder.GetSymbolById(instrumentID)
        message.Replaceable = true;
        message.AutoTradeOrder = fieldSet.GetValue(FieldsFactory.FIELD_IS_AUTOTRADE_ORDER);
        message.Roundtrip = this.decoder.FindRequestTime(message);

        message.ExternalTradeId = fieldSet.GetValue(FieldsFactory.FIELD_EXT_ID);
        message.ExternalOrderId = fieldSet.GetValue(FieldsFactory.FIELD_EXTERNAL_ORDER_ID);
        message.ExternalStatus = fieldSet.GetValue(FieldsFactory.FIELD_EXTERNAL_ORDER_STATUS);

        const fieldTPPrice = fieldSet.GetValue(FieldsFactory.FIELD_TP_PRICE);
        message.TakeProfitPriceValue = fieldTPPrice === null ? NaN : fieldTPPrice;
        const fieldSLPrice = fieldSet.GetValue(FieldsFactory.FIELD_SL_PRICE);
        message.StopLossPriceValue = fieldSLPrice === null ? NaN : fieldSLPrice;

        message.StopLossLimit = fieldSet.GetValue(FieldsFactory.FIELD_SL_LIMIT_PRICE);
        if (isNaN(message.StopLossLimit)) {
            message.StopLossLimit = null;
        }

        message.IsActivated = fieldSet.GetValue(FieldsFactory.FIELD_IS_ACTIVATED);

        message.TakeProfitPriceType = fieldSet.GetValue(FieldsFactory.FIELD_TP_PRICE_TYPE);
        message.StopLossPriceType = fieldSet.GetValue(FieldsFactory.FIELD_SL_PRICE_TYPE);

        if (message.StopLossPriceType === SlTpPriceType.ProtectiveAbsolute) {
            message.StopLossPriceType = SlTpPriceType.Absolute;
        }

        if (message.TakeProfitPriceType === SlTpPriceType.ProtectiveAbsolute) {
            message.TakeProfitPriceType = SlTpPriceType.Absolute;
        }

        message.ProductType = fieldSet.GetValue(FieldsFactory.FIELD_PRODUCT_TYPE);
        message.TradingSignalId = fieldSet.GetValue(FieldsFactory.FIELD_TRADING_SIGNAL_ID);

        message.TriggerSL = fieldSet.GetValue(FieldsFactory.FIELD_SL_TRIGGER_PRICE);
        message.TriggerTP = fieldSet.GetValue(FieldsFactory.FIELD_TP_TRIGGER_PRICE);
        message.TriggerSLTP = fieldSet.GetValue(FieldsFactory.FIELD_SL_TP_TRIGGER_PRICE);

        message.Leverage = fieldSet.GetValue(FieldsFactory.FIELD_LEVERAGE_SHORT);

        message.InstrumentTradableID = fieldSet.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);

        let orderHistoryMessage: DirectOrderHistoryMessage = null;

        if (status === OrderStatus.CANCELED || status === OrderStatus.REFUSED) {
            const cancelMessage = new DirectOrderDialogMessage();

            cancelMessage.OrderId = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_ID).toString();
            cancelMessage.CancelTime = fieldSet.GetValue(FieldsFactory.FIELD_TIMESTAMP);

            cancelMessage.BoundToOrderId = fieldSet.GetValue(FieldsFactory.FIELD_BOUNDTOORDERID).toString();
            // если сессия не торговая и т.п. Дима обящал слать только режект, и не слать сюда. // #24353 Sessions - шлем реджекты если в рамках сессии запрещена отправка отмена или модификация
            cancelMessage.Comment = comment;

            cancelMessage.OrderType = orderType;
            cancelMessage.Account = fieldSet.GetValue(FieldsFactory.FIELD_ACCOUNT_ID).toString();

            cancelMessage.Price = NaN;
            cancelMessage.DialogId = -1;

            cancelMessage.ProductType = fieldSet.GetValue(FieldsFactory.FIELD_PRODUCT_TYPE);

            cancelMessage.Leverage = message.Leverage;
            cancelMessage.OrderStatus = status;
            cancelMessage.ClientOrderId = message.ClientOrderId;
            cancelMessage.Quantity = quantity;
            cancelMessage.DisClosedQuantity = disclosedQty;
            cancelMessage.SharesFilled = sharesFilled;
            cancelMessage.LastAmount = lastAmount;

            if (status === OrderStatus.CANCELED) {
                orderHistoryMessage = this.CreateOrderHistoryMessage(cancelMessage, fieldSet);
            }

            // список возвращаемых месседжей
            let list = [];

            if (fieldIsOpen !== null && !fieldIsOpen) {
            // для отработки клоуз позишн мессадж
                const refuse = new DirectOrderDialogMessage();
                // чтобы ссылаться на позицию, а не на закрывающий ордер,
                // о ктором скрин ничего не знает
                refuse.OrderId = fieldSet.GetValue(FieldsFactory.FIELD_BOUNDTOORDERID) >= 0
                    ? cancelMessage.BoundToOrderId
                    : cancelMessage.OrderId;

                refuse.BoundToOrderId = cancelMessage.BoundToOrderId;
                refuse.Comment = comment;
                refuse.OrderStatus = status;
                refuse.ClientOrderId = message.ClientOrderId;
                refuse.Quantity = quantity;
                refuse.DisClosedQuantity = disclosedQty;
                refuse.SharesFilled = sharesFilled;
                refuse.LastAmount = lastAmount;
                refuse.CancelTime = cancelMessage.CancelTime;

                list.push(refuse);
                list.push(cancelMessage);
                if (orderHistoryMessage) {
                    list.push(orderHistoryMessage);
                }
                list = list.concat(generateReportMessageHandler.GenerateReportMessage(message));

                return list;
            } else {
                list.push(cancelMessage);

                if (status === OrderStatus.REFUSED) {
                    orderHistoryMessage = this.CreateOrderHistoryMessageFromOOM(message);
                }
                if (orderHistoryMessage) {
                    list.push(orderHistoryMessage);
                }
                list = list.concat(generateReportMessageHandler.GenerateReportMessage(message));

                return list;
            }
        }

        if (message.ExecutionType !== null) {
            const eventType = message.ExecutionType;
            if (eventType !== OrderExecutionType.PENDING_NEW &&
            eventType !== OrderExecutionType.PENDING_EXECUTION &&
            eventType !== OrderExecutionType.PENDING_CANCEL &&
            eventType !== OrderExecutionType.PENDING_REPLACE) {
                orderHistoryMessage = this.CreateOrderHistoryMessageFromOOM(message);
            }
        }

        // OrderHistoryMessage
        if (
            status === OrderStatus.NEW ||
        status === OrderStatus.REPLACED ||
        status === OrderStatus.PART_FILLED ||
        status === OrderStatus.FILLED ||
        status === OrderStatus.ACCEPTED ||
        status === OrderStatus.WAITING_MARKET ||
        status === OrderStatus.PENDING_REPLACE ||
        status === OrderStatus.REMOVED) {
            orderHistoryMessage = this.CreateOrderHistoryMessageFromOOM(message);
        }

        if (orderHistoryMessage) {
            let msgs = [];
            msgs.push(message);
            msgs.push(orderHistoryMessage);
            msgs = msgs.concat(generateReportMessageHandler.GenerateReportMessage(message));
            return msgs;
        } else if (status === -1) {
            return [message];
        } else {
            let msgs = [];
            msgs.push(message);
            msgs = msgs.concat(generateReportMessageHandler.GenerateReportMessage(message));
            return msgs;
        }
    }

    public CreateOrderHistoryMessage (cancelMessage: DirectOrderDialogMessage, fieldSet: PFixFieldSet): DirectOrderHistoryMessage {
        const orderHistMess = new DirectOrderHistoryMessage();
        orderHistMess.Account = cancelMessage.Account;
        // Instrument ID
        const instrumentID = fieldSet.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID);
        orderHistMess.Symbol = this.decoder.GetSymbolById(instrumentID);

        orderHistMess.Status = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_STATUS);
        // Date of creation
        orderHistMess.UTCDateTime = fieldSet.GetValue(FieldsFactory.FIELD_CREATED_AT);
        // Operation type
        const buysell = fieldSet.GetValue(FieldsFactory.FIELD_OPERATION_TYPE);
        orderHistMess.BuySell = buysell;
        // Order ID
        orderHistMess.OrderNumber = cancelMessage.OrderId;
        // Order type
        orderHistMess.OrderType = cancelMessage.OrderType;
        // Is active
        // говорил с димой - какая ситуация с этими Active и Open. Актив = false
        // когда слтп у ордера стоит (он неактив, активиряется при ипол ордера) или при кенселе, т.е. те ордера
        // которе не обрабатываются системой
        // Open - показывает открывающий или закрывающий ордер.
        // Поэтому несмотря на противоречивое название, для нас приоритетнее для поля Active брать FIELD_IS_OPEN
        const fieldIsOpen = fieldSet.GetValue(FieldsFactory.FIELD_IS_OPEN);
        if (fieldIsOpen === null) {
            orderHistMess.Active = fieldSet.GetValue(FieldsFactory.FIELD_ACTIVE_FLAG) === 1;
        } else {
            orderHistMess.Active = fieldIsOpen;
        }
        // Bound to Order ID
        orderHistMess.BoundToOrderId = cancelMessage.BoundToOrderId;
        // Price
        orderHistMess.Price = fieldSet.GetValue(FieldsFactory.FIELD_PRICE);
        // Order amount
        orderHistMess.Quantity = fieldSet.GetValue(FieldsFactory.FIELD_AMOUNT);

        orderHistMess.DisClosedQuantity = fieldSet.GetValue(FieldsFactory.FIELD_DISCLOSED_QUANTITY);

        const routeId = fieldSet.GetValue(FieldsFactory.FIELD_ROUTE_ID);
        orderHistMess.Route = routeId;// decoder.GetRouteById(routeId)

        orderHistMess.RemoteId = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_REF_ID);
        orderHistMess.StopLimit = fieldSet.GetValue(FieldsFactory.FIELD_STOP_PRICE);

        // Validity
        const tif = fieldSet.GetValue(FieldsFactory.FIELD_VALIDITY);
        orderHistMess.TimeInForce = tif;

        orderHistMess.OrderCreatedByUser = fieldSet.GetValue(FieldsFactory.FIELD_LOGIN);
        orderHistMess.LastUpdateTime = cancelMessage.CancelTime;
        orderHistMess.ProductType = cancelMessage.ProductType;

        orderHistMess.InstrumentTradableID = fieldSet.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);

        // TODO. Refactor. Ugly. Code duplication.
        // fieldSet is OpenOrderMessage.
        const evType = fieldSet.GetValue(FieldsFactory.FIELD_EXECUTION_TYPE);
        if (evType != null) {
            orderHistMess.EventType = evType;
        }

        orderHistMess.Leverage = fieldSet.GetValue(FieldsFactory.FIELD_LEVERAGE_SHORT);

        return orderHistMess;
    }

    public CreateOrderHistoryMessageFromOOM (message: DirectOpenOrderMessage): DirectOrderHistoryMessage {
        const orderHistMess = new DirectOrderHistoryMessage();
        orderHistMess.Account = message.Account;
        // Instrument ID
        orderHistMess.Symbol = message.Symbol;

        let isPartallyCancelled = false;
        let refusedAmount = 0;
        if (message.OrderStatus !== null) {
        // #44200
            if ((message.OrderStatus === OrderStatus.FILLED || message.OrderStatus === OrderStatus.PART_FILLED) &&
            message.ExecutionType === OrderExecutionType.CANCELED) {
                const lastAmount = message.LastAmount === null
                    ? message.Quantity
                    : message.LastAmount;

                const ord = DataCacheHolder.getDataCache().getOrderById(message.OrderNumber);
                if (ord) {
                    refusedAmount = ord.Amount - lastAmount;
                }

                if (refusedAmount !== 0) {
                    isPartallyCancelled = true;
                }
            }

            orderHistMess.Status = isPartallyCancelled
                ? OrderStatus.CANCELED
                : message.OrderStatus;
        }
        orderHistMess.Comment = message.Comment;

        // Date of creation
        orderHistMess.UTCDateTime = message.UTCDateTime;
        orderHistMess.BuySell = message.BuySell;
        // Order ID
        orderHistMess.OrderNumber = message.OrderNumber;
        orderHistMess.ClientOrderId = message.ClientOrderId;
        orderHistMess.OrderType = message.OrderType;
        orderHistMess.Active = message.Active;
        orderHistMess.BoundToOrderId = message.BoundToOrderId;
        orderHistMess.PositionId = message.PositionId;
        // Price
        orderHistMess.Price = (
            orderHistMess.Status === OrderStatus.FILLED ||
        orderHistMess.Status === OrderStatus.PART_FILLED
        ) &&
        message.LastPrice !== null
            ? message.LastPrice
            : message.Price;

        // Order amount
        if ((orderHistMess.Status === OrderStatus.FILLED ||
        orderHistMess.Status === OrderStatus.PART_FILLED) &&
        message.LastAmount !== null) {
            orderHistMess.Quantity = message.LastAmount;
        } else if (isPartallyCancelled) {
        // #48220  - должен отображатся отмененный объем
            orderHistMess.Quantity = refusedAmount;
        } else {
            orderHistMess.Quantity = message.Quantity;
        }

        orderHistMess.Route = message.Route;
        orderHistMess.RemoteId = message.RemoteID;
        orderHistMess.StopLimit = message.StopLimit;
        // Validity
        orderHistMess.TimeInForce = message.TimeInForce;
        orderHistMess.OrderCreatedByUser = message.OrderCreatedByUser;
        orderHistMess.LastUpdateTime = message.LastUpdateTime;

        orderHistMess.InstrumentTradableID = message.InstrumentTradableID;
        orderHistMess.DisClosedQuantity = message.DisClosedQuantity;

        if (message.ExecutionType !== null) {
            orderHistMess.EventType = message.ExecutionType;
        }
        orderHistMess.ProductType = message.ProductType;
        orderHistMess.ExternalOrderId = message.ExternalOrderId;
        orderHistMess.ExternalTradeId = message.ExternalTradeId;

        orderHistMess.Leverage = message.Leverage;

        return orderHistMess;
    }
}
